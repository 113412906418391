import React from 'react';
import { BarItemActionButtons } from '../../../../../_shared/components/BarItems/BarItemActionButtons.tsx';
import { BarItemExpandedSimpleHeader } from '../../../../../_shared/components/BarItems/BarItemExpandedSimpleHeader.tsx';
import { HotkeysHandler } from '../../../../../_shared/components/Hotkeys/HotkeysHandler.tsx';
import { ShortcutSymbols } from '../../../../../_shared/constants/shortcutSymbols.ts';
import { useScrollIntoViewOnMount } from '../../../../../_shared/hooks/useScrollIntoViewOnMount.ts';
import { HookFormProps } from '../../../../../_shared/types/hookFormProps.type.ts';
import {
  DataUiAction,
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ISitemapFormShape } from '../../models/ISitemapFormShape.type.ts';
import { NodeFormFields } from './NodeFormFields.tsx';

type Props = {
  readonly formProps: HookFormProps<ISitemapFormShape>;
  readonly onCancel: () => void;
  readonly onSubmit: () => void;
};

export const NodeCreator: React.FC<Props> = ({ formProps, onCancel, onSubmit }) => {
  const scrollRef = useScrollIntoViewOnMount<HTMLDivElement>();
  return (
    <HotkeysHandler
      className="bar-item__wrapper"
      handlers={{
        onEscape: onCancel,
        onEnter: onSubmit,
      }}
      {...getDataUiElementAttribute(DataUiElement.BarItemForm)}
    >
      <div className="bar-item__pane bar-item__pane--is-expanded" ref={scrollRef}>
        <BarItemExpandedSimpleHeader dataUiObjectName="" isDraggable renderTitle={() => ''} />
        <NodeFormFields formProps={formProps} />
        <BarItemActionButtons
          secondaryAction={{
            handler: onCancel,
            text: 'Cancel',
            dataUIActionName: DataUiAction.Cancel,
            shortcut: ShortcutSymbols.Escape,
          }}
          primaryAction={{
            handler: onSubmit,
            text: 'Save',
            dataUIActionName: DataUiAction.Save,
            shortcut: ShortcutSymbols.Enter,
          }}
        />
      </div>
    </HotkeysHandler>
  );
};

NodeCreator.displayName = 'NodeCreator';
