import React from 'react';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { isUrlPatternSetForEditedContentItemSelector } from '../../../../../selectors/isUrlPatternSetForEditedContentItem.ts';
import { EditingActionItemsProps } from '../../../components/editingActions/EditingActionSubmenuItems.tsx';
import { EditingAction } from '../../../components/editingActions/actions/EditingAction.tsx';
import { ActionsMenuItem } from '../../../components/editingActions/actionsMenu/ActionsMenuItem.tsx';
import { ActionType } from '../../../constants/editingActions/staticDetails.ts';
import { useAvailableContentItemEditingActions } from '../../../hooks/useAvailableContentItemEditingActions.ts';
import { EditingAction as EditingActionEnum } from '../../../models/EditingAction.ts';

const duplicationActions = [
  EditingActionEnum.duplicateItemWithContent,
  EditingActionEnum.duplicateItemWithoutContent,
];

export const DuplicationOptions: React.FC<EditingActionItemsProps> = ({ actionOrigin }) => {
  const isUrlPatternSetForEditedContentItem = useSelector(
    isUrlPatternSetForEditedContentItemSelector,
  );
  const availableActions = useAvailableContentItemEditingActions(
    isUrlPatternSetForEditedContentItem,
  );
  const actionsToRender = availableActions.filter((action) => duplicationActions.includes(action));

  return actionsToRender.map((action) => (
    <EditingAction action={action} key={action}>
      {(details) => (
        <ActionsMenuItem
          id={details.caption}
          badgeColor={details.badgeColor}
          badgeValue={details.badgeValue}
          dataAttributes={details.dataUiAttribute}
          disabled={details.isDisabled}
          iconName={details.iconName}
          isDestructive={details.actionType === ActionType.Destructive}
          onClick={() => details.performAction(actionOrigin)}
          outwardLinkUrl={details.outwardUrl}
          text={details.caption}
          tooltip={details.tooltip}
        />
      )}
    </EditingAction>
  ));
};
