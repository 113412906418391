import { Icons } from '@kontent-ai/component-library/Icons';
import { ControlAltShortcutTemplate } from '../../../../../_shared/constants/shortcutSymbols.ts';
import {
  DataUiRteAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  RTEToolbarIconButton,
  ToggleButtonProps,
} from '../../toolbars/components/buttons/RTEToolbarButton.tsx';

export const ImageButton = (props: ToggleButtonProps) => (
  <RTEToolbarIconButton
    {...props}
    icon={Icons.Picture}
    shortcut={ControlAltShortcutTemplate('A')}
    tooltipPlacement={props.tooltipPlacement}
    tooltipText={props.tooltipText ?? (props.disabled ? 'Images are not allowed' : 'Insert image')}
    {...getDataUiActionAttribute(DataUiRteAction.AddImage)}
  />
);
