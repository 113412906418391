import { AvatarSize } from '@kontent-ai/component-library/Avatar';
import React, { ComponentProps } from 'react';
import { LinkDataTableCell } from '../../../../../_shared/components/DataTable/LinkDataTableCell.tsx';
import { UserAvatar } from '../../../../../_shared/components/users/UserAvatar.tsx';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { Task } from '../../../../../_shared/models/Task.ts';
import { MissionControlTrackedWidgetName } from '../../../../../_shared/models/TrackUserEventData.ts';
import { getCurrentProjectId } from '../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { getContentItemPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { createUnknownContributorInfo } from '../../../../../_shared/utils/users/usersUtils.ts';
import { useLivePreviewPreferenceStorage } from '../../../../../localStorages/useLivePreviewPreferenceStorage.ts';
import { TableCellDueDate } from '../../../../contentInventory/content/features/ContentItemInventory/components/TableCellDueDate.tsx';
import {
  StyledDataTableCell,
  StyledDataTableRow,
} from '../../../../missionControl/widgets/shared/components/StyledDataTable.tsx';
import { useTrackWidgetOverviewItemClick } from '../../../../missionControl/widgets/shared/hooks/tracking.ts';
import { useIsDemoModeEnabled } from '../../../contexts/MissionControlDemoContext.tsx';

interface IMostOverdueTasksWidgetRowProps {
  readonly task: Task;
  readonly rowFocusLinkAriaLabel: string;
}

export const MostOverdueTasksWidgetRow: React.FC<IMostOverdueTasksWidgetRowProps> = ({
  rowFocusLinkAriaLabel,
  task,
}) => {
  const isDemoModeEnabled = useIsDemoModeEnabled();
  const currentProjectId = useSelector(getCurrentProjectId);
  const { isLivePreviewPreferred } = useLivePreviewPreferenceStorage(currentProjectId);

  const trackWidgetOverviewItemClick = useTrackWidgetOverviewItemClick(
    MissionControlTrackedWidgetName.OverdueTasks,
  );
  const commonCellProps = {
    linkPath: isDemoModeEnabled
      ? null // Disable redirect in demo mode
      : getContentItemPath(
          window.location.pathname,
          task.contentItemId.itemId,
          isLivePreviewPreferred,
          task.contentItemId.variantId,
        ),
    onClick: isDemoModeEnabled ? undefined : trackWidgetOverviewItemClick,
  } satisfies ComponentProps<typeof LinkDataTableCell>;

  const usersById = useSelector((s) => s.data.users.usersById);
  const owner = usersById.get(task.owner) ?? createUnknownContributorInfo(task.owner);

  return (
    <StyledDataTableRow dataUiObjectName={task.description} id={task.id}>
      <StyledDataTableCell focusableRowLinkAriaLabel={rowFocusLinkAriaLabel} {...commonCellProps}>
        {task.description}
      </StyledDataTableCell>
      <StyledDataTableCell {...commonCellProps}>
        <UserAvatar user={owner} size={AvatarSize.S} />
      </StyledDataTableCell>
      <StyledDataTableCell {...commonCellProps}>
        <TableCellDueDate dueDate={task.dueDate} />
      </StyledDataTableCell>
    </StyledDataTableRow>
  );
};

MostOverdueTasksWidgetRow.displayName = 'MostOverdueTasksWidgetRow';
