import { getCurrentProjectPlan } from '../../../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { getWorkflow } from '../../../../../../../_shared/selectors/workflowSelectors.ts';
import { IStore } from '../../../../../../../_shared/stores/IStore.type.ts';
import { canDuplicateItem } from '../../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { getCurrentUserRoleForCollectionForLanguageForUser } from '../../../../../../../_shared/utils/permissions/getContributorRole.ts';
import { canRoleDoSomethingInStep } from '../../../../../../../_shared/utils/permissions/roleInWorkflowStepUtils.ts';
import { shouldApplyMultipleWorkflowsGovernance } from '../../../../../../../_shared/utils/workflow/shouldApplyMultipleWorkflowsGovernance.ts';

const canDuplicateEditedItem = (state: IStore) => {
  const {
    contentApp: { editedContentItem, editedContentItemVariant },
  } = state;

  if (!editedContentItemVariant) {
    return false;
  }

  const {
    id: { variantId },
  } = editedContentItemVariant;

  return (
    !!editedContentItem &&
    canDuplicateItem(state, variantId, editedContentItem.editedContentItemTypeId)
  );
};

const canCreateItemViaDuplication = (state: IStore) => {
  if (
    !shouldApplyMultipleWorkflowsGovernance(state.data.workflows.byId, getCurrentProjectPlan(state))
  ) {
    return true;
  }

  const {
    contentApp: { editedContentItem, editedContentItemVariant },
    data: { user },
    sharedApp: { currentProjectId },
  } = state;

  if (!editedContentItemVariant) {
    return false;
  }

  const {
    assignment: {
      workflowStatus: { workflowId },
    },
    id: { variantId },
  } = editedContentItemVariant;

  const workflow = getWorkflow(state, workflowId);
  const firstStepId = workflow?.steps[0].id;

  if (!firstStepId || !editedContentItem) return false;

  const userRole = getCurrentUserRoleForCollectionForLanguageForUser(
    user,
    currentProjectId,
    editedContentItem.collectionId,
    variantId,
  );

  return canRoleDoSomethingInStep(userRole?.id, firstStepId, workflow);
};

export const isDuplicateItemWithContentAvailable = (state: IStore) => canDuplicateEditedItem(state);

export const isDuplicateItemWithoutContentAvailable = (state: IStore) =>
  canDuplicateEditedItem(state) && canCreateItemViaDuplication(state);

export const isDuplicateItemAvailable = (state: IStore) =>
  isDuplicateItemWithContentAvailable(state) || isDuplicateItemWithoutContentAvailable(state);
