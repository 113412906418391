import { colorPrimary, colorTextDefault } from '@kontent-ai/component-library/tokens';
import { Link } from 'react-router-dom';
import {
  ItemDetailSection,
  getDataUiObjectNameAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getTimeAndAuthorOfEntityChange } from '../../../../../../_shared/utils/entityChangeUtils.ts';
import { IProjectContributor } from '../../../../../../data/models/users/ProjectContributor.ts';
import { SectionTitle } from './SectionTitle.tsx';
import { SidebarSectionLinkWrapper } from './SidebarSectionLinkWrapper.tsx';

type Props = {
  readonly contentLastUpdatedAt: DateTimeStamp | null;
  readonly contentLastUpdatedBy: string;
  readonly currentUserId: string;
  readonly onClick: () => void;
  readonly projectContributors: ReadonlyMap<Uuid, IProjectContributor>;
  readonly revisionComparisonPath: string | null;
};

export const LastUpdatedSection = ({
  contentLastUpdatedAt,
  contentLastUpdatedBy,
  currentUserId,
  projectContributors,
  revisionComparisonPath,
  onClick,
}: Props) => {
  const lastContentChangeString = getTimeAndAuthorOfEntityChange({
    currentUserId,
    projectContributors,
    entityChangedAt: contentLastUpdatedAt,
    entityChangedBy: contentLastUpdatedBy,
  });

  return (
    <div
      className="sidebar__section"
      {...getDataUiObjectNameAttribute(ItemDetailSection.LastUpdated)}
    >
      <SectionTitle>Last content change</SectionTitle>
      <SidebarSectionLinkWrapper
        color={revisionComparisonPath ? colorPrimary : colorTextDefault}
        title={lastContentChangeString}
      >
        {revisionComparisonPath ? (
          <Link
            to={revisionComparisonPath}
            onClick={onClick}
            className="sidebar__section-item-link"
          >
            {lastContentChangeString}
          </Link>
        ) : (
          lastContentChangeString
        )}
      </SidebarSectionLinkWrapper>
    </div>
  );
};
