import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Label, LabelSize } from '@kontent-ai/component-library/Label';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { Spacing, colorTextHint } from '@kontent-ai/component-library/tokens';
import React, { useState } from 'react';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../utils/dataAttributes/DataUiAttributes.ts';
import {
  FeedbackFeature,
  FeedbackReasons,
  FeedbackType,
  NegativeFeedbackPayload,
} from '../structuredFeedback.ts';
import { NegativeFeedbackModal } from './NegativeFeedbackModal.tsx';

export type StructuredFeedbackButtonsProps = {
  readonly feature: FeedbackFeature;
  readonly feedbackReasons: FeedbackReasons;
  readonly label?: string;
  readonly onSubmitNegative: (payload: NegativeFeedbackPayload) => void;
  readonly onSubmitPositive: () => void;
};

export const StructuredFeedbackButtons: React.FC<StructuredFeedbackButtonsProps> = ({
  feature,
  feedbackReasons,
  label,
  onSubmitNegative,
  onSubmitPositive,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [feedback, setFeedback] = useState<FeedbackType | null>(null);

  const onPositive = () => {
    setFeedback(FeedbackType.Positive);
    onSubmitPositive();
  };

  const onNegative = () => {
    setFeedback(FeedbackType.Negative);
    setIsModalOpen(true);
  };

  const handleCloseNegativeModal = () => {
    setFeedback(null);
    setIsModalOpen(false);
  };

  const handleSubmitNegativeModal = (payload: NegativeFeedbackPayload) => {
    setIsModalOpen(false);
    onSubmitNegative(payload);
  };

  return (
    <>
      <Tooltip
        text={
          feedback ? 'You’ve already shared your thoughts. Thanks, much appreciated!' : undefined
        }
        placement="top"
      >
        <Inline align="center" spacing={Spacing.S}>
          {label && (
            <Label size={LabelSize.M} color={colorTextHint}>
              {label}
            </Label>
          )}
          <QuinaryButton
            activated={feedback === FeedbackType.Positive}
            aria-pressed={feedback === FeedbackType.Positive}
            disabled={!!feedback}
            onClick={onPositive}
            tooltipText={feedback ? '' : 'The result is great'}
            {...getDataUiActionAttribute(DataUiAction.ThumbsUp)}
          >
            <QuinaryButton.Icon icon={Icons.ThumbsUp} />
          </QuinaryButton>
          <QuinaryButton
            activated={feedback === FeedbackType.Negative}
            aria-pressed={feedback === FeedbackType.Negative}
            disabled={!!feedback}
            onClick={onNegative}
            tooltipText={feedback ? '' : 'The result could be better'}
            {...getDataUiActionAttribute(DataUiAction.ThumbsDown)}
          >
            <QuinaryButton.Icon icon={Icons.ThumbsDown} />
          </QuinaryButton>
        </Inline>
      </Tooltip>
      <NegativeFeedbackModal
        feature={feature}
        feedbackReasons={feedbackReasons}
        isOpen={isModalOpen}
        onClose={handleCloseNegativeModal}
        onSubmit={handleSubmitNegativeModal}
      />
    </>
  );
};
