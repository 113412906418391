import { Box } from '@kontent-ai/component-library/Box';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Inline } from '@kontent-ai/component-library/Inline';
import { BaseColor, IconSize, Spacing, Typography } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { StructuredFeedbackButtons } from '../../../../../_shared/features/StructuredFeedback/containers/StructuredFeedbackButtons.tsx';
import {
  FeedbackReasons,
  SpecificFeedbackReason,
  createFeedbackReasons,
  getAiFeedbackFeature,
} from '../../../../../_shared/features/StructuredFeedback/structuredFeedback.ts';
import { TrackingAiActionName } from '../../../../../_shared/models/events/AiActionEventData.type.ts';

const feedbackReasons: FeedbackReasons = [
  ...createFeedbackReasons([
    SpecificFeedbackReason.SuggestedLinkedItemsIrrelevant,
    SpecificFeedbackReason.SuggestedLinkedItemsNotEnough,
  ]),
  {
    reason: SpecificFeedbackReason.ResultSlow,
    label: 'Takes too long to suggest items',
  },
];

type Props = {
  readonly itemCount: number;
};

export const RecommendedLinkedItemsModalStatus: React.FC<Props> = ({ itemCount }) => (
  <Box paddingLeft={Spacing.L} typography={Typography.SubheadlineLarge} color={BaseColor.Black}>
    <Inline align="center" noWrap spacingX={Spacing.S}>
      <Icons.Sparkles size={IconSize.S} />
      {itemCount > 0
        ? `The following ${pluralizeItemIsSuggestion(
            itemCount,
          )} from AI. Let us know if you find them helpful:`
        : 'Do you find the Suggest linked items with AI helpful?'}
      <StructuredFeedbackButtons
        feature={getAiFeedbackFeature(TrackingAiActionName.SuggestLinkedItems)}
        feedbackReasons={feedbackReasons}
      />
    </Inline>
  </Box>
);

RecommendedLinkedItemsModalStatus.displayName = 'RecommendedLinkedItemsModalStatus';

const pluralizeItemIsSuggestion = (itemCount: number): string =>
  itemCount === 1 ? 'item is a suggestion' : 'items are suggestions';
