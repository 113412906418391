import { Placement } from '@kontent-ai/component-library/types';
import { identity } from '@kontent-ai/utils';
import React from 'react';
import { getDropDown } from '../../../../../../../_shared/components/DropDown.tsx';
import { DataUiElement } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  AssetFileTypeOption,
  ResponsiveImagesExplanation,
  RichTextImageTypes,
  getAssetFileTypeOptionName,
} from '../../../../../../contentInventory/content/models/assetFileTypeOptions.ts';
import { ITypeElementDataWithAssetTypeLimitation } from '../../../../models/elements/types/TypeElementData.ts';
import { TypeElementConfigurationSection } from '../TypeElementConfigurationSection.tsx';

const DropDown = getDropDown<AssetFileTypeOption>();

type Props = {
  readonly onChange: (data: string) => void;
  readonly readOnly?: boolean;
  readonly title: string;
  readonly tooltipPlacement?: Placement;
  readonly tooltipText?: string;
  readonly typeElementData: ITypeElementDataWithAssetTypeLimitation;
  readonly uiElement?: DataUiElement;
};

export const LimitImageType: React.FC<Props> = ({
  onChange,
  readOnly,
  title,
  tooltipPlacement,
  tooltipText,
  typeElementData,
  uiElement,
}) => (
  <TypeElementConfigurationSection
    title={title}
    uiElement={uiElement}
    tooltip={ResponsiveImagesExplanation}
  >
    <DropDown
      className="combo-box"
      selectedOption={typeElementData.fileType}
      options={RichTextImageTypes}
      onSelect={onChange}
      renderOptionName={getAssetFileTypeOptionName}
      getOptionId={identity}
      disabled={readOnly}
      disabledTooltipMessage={tooltipText}
      disabledTooltipPlacement={tooltipPlacement}
    />
  </TypeElementConfigurationSection>
);

LimitImageType.displayName = 'LimitImageType';
