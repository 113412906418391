import { StatusBarActions } from '../../../../../_shared/components/StatusBar/StatusBarActions.tsx';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import { isAssetTypeEnabled } from '../../../../../_shared/selectors/enhancedAssetManagement.ts';
import { getCurrentProjectPlan } from '../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { validateTypeElement } from '../../../shared/actions/thunkSharedContentModelsActions.ts';
import { isTypeValid } from '../../../shared/utils/typeValidationUtils.ts';
import { assetTypeInvalidElementShowWarning } from '../../actions/assetTypeActions.ts';
import { saveEditedAssetType } from '../../actions/thunkAssetTypeActions.ts';

export const AssetTypeEditorToolbarActions = () => {
  const isBeingSaved = useSelector((s) => s.contentModelsApp.typeEditor.typeIsBeingSaved);
  const isLoading = useSelector(
    (s) => s.contentModelsApp.typeEditor.loadingStatus !== LoadingStatus.Loaded,
  );
  const isVisible = useSelector((state) =>
    isAssetTypeEnabled(getCurrentProjectPlan(state), state.data.assetTypes.defaultAssetType),
  );
  const editedAssetType = useSelector((state) => state.contentModelsApp.typeEditor.editedType);

  const dispatch = useDispatch();

  const saveContentType = async (): Promise<void> => {
    const validationResults = new Map(
      editedAssetType.typeElements.map((element) => [
        element.elementId,
        dispatch(validateTypeElement(element)),
      ]),
    );
    if (isTypeValid(validationResults)) {
      await dispatch(saveEditedAssetType(editedAssetType));
    } else {
      dispatch(assetTypeInvalidElementShowWarning());
    }
  };

  if (!isVisible) {
    return null;
  }

  return (
    <StatusBarActions isBeingSaved={isBeingSaved} isLoading={isLoading} onSave={saveContentType} />
  );
};
