import { Box } from '@kontent-ai/component-library/Box';
import { InputCaption, InputState, InputType } from '@kontent-ai/component-library/Input';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { FormGroup } from '../../../../../_shared/components/input/FormGroup.tsx';
import { ValidatedInput } from '../../../../../_shared/components/input/ValidatedInput.tsx';
import { ValidatedTextArea } from '../../../../../_shared/components/input/ValidatedTextArea.tsx';
import { HookFormProps } from '../../../../../_shared/types/hookFormProps.type.ts';
import { AiGuidelinesFormShape } from '../types/AiGuidelinesFormShape.type.ts';

type AiGuidelinesFormProps = {
  readonly formProps: HookFormProps<AiGuidelinesFormShape>;
};

export const AiGuidelinesForm = ({ formProps }: AiGuidelinesFormProps) => (
  <Stack spacing={Spacing.XL}>
    <FormGroup>
      <ValidatedInput<AiGuidelinesFormShape>
        autoFocus
        formProps={formProps}
        label="Name"
        name="name"
        placeholder="Name the guidelines, e.g., Brand writing style"
        type={InputType.Text}
      />
    </FormGroup>
    <FormGroup>
      <ValidatedTextArea<AiGuidelinesFormShape>
        autoFocus
        formProps={formProps}
        label="Review guidelines"
        maxRows={20}
        minRows={3}
        name="guidelines"
        placeholder="Write instructions for the AI on how the content should be written…"
      />
      <Box paddingTop={Spacing.S}>
        <InputCaption $inputState={InputState.Default}>
          For best reviews, create instructions that are explicit and with examples of what’s
          correct and incorrect. You can mention target audience, preferred language, voice and
          tone, and more.
        </InputCaption>
      </Box>
    </FormGroup>
  </Stack>
);
