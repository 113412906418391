import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { Callout } from '@kontent-ai/component-library/Callout';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { documentationLinks } from '../../../_shared/constants/documentationLinks.ts';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { getCurrentProjectPlan } from '../../../_shared/selectors/userProjectsInfoSelectors.ts';
import {
  areContentCollectionsEnabledForCurrentProjectPlan,
  areSpacesEnabledForCurrentProject,
} from '../../environmentSettings/utils/allowedFeaturesUtils.ts';
import {
  CodenameMacro,
  CollectionMacro,
  ItemIdMacro,
  SpaceMacro,
  UrlSlugMacro,
  VariantMacro,
} from '../constants/macros.ts';

export const PreviewUrlsQuickTipSection: React.FC = () => {
  const areCollectionsEnabled = useSelector((state) =>
    areContentCollectionsEnabledForCurrentProjectPlan(getCurrentProjectPlan(state)),
  );
  const areSpacesEnabled = useSelector((state) =>
    areSpacesEnabledForCurrentProject(getCurrentProjectPlan(state)),
  );

  const exampleLinkDomain = areSpacesEnabled ? SpaceMacro : 'mysite.com';

  return (
    <Callout calloutType="quickTip" headline="Specify a full URL by using these macros">
      <Stack spacing={Spacing.XL}>
        <ul className="preview-settings-macros">
          {areSpacesEnabled && (
            <li>
              <strong>{SpaceMacro}</strong> – resolves to the space domain.
            </li>
          )}
          <li>
            <strong>{UrlSlugMacro}</strong> – resolves to the value of a URL slug element in a
            content item.
          </li>
          <li>
            <strong>{VariantMacro}</strong> – resolves to the codename of a language variant.
          </li>
          <li>
            <strong>{CodenameMacro}</strong> – resolves to the codename of a content item.
          </li>
          <li>
            <strong>{ItemIdMacro}</strong> – resolves to the internal ID of a content item.
          </li>
          {areCollectionsEnabled && (
            <li>
              <strong>{CollectionMacro}</strong> – resolves to the codename of the content item’s
              collection.
            </li>
          )}
        </ul>
        <p>
          For example,{' '}
          <em>{`https://${exampleLinkDomain}/${VariantMacro}/articles/${UrlSlugMacro}`}</em>{' '}
          becomes&nbsp;<em>https://mysite.com/en-us/articles/on-roasts</em>.
        </p>
        <p>
          See{' '}
          <OutwardLink href={documentationLinks.configuringPreview}>
            how to set up preview
          </OutwardLink>{' '}
          for your project.
        </p>
      </Stack>
    </Callout>
  );
};

PreviewUrlsQuickTipSection.displayName = 'PreviewUrlsQuickTipSection';
