import { Button } from '@kontent-ai/component-library/Button';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { FC } from 'react';
import { BrowseButton } from '../../../../../_shared/components/BrowseButton.tsx';
import { ModalDialog } from '../../../../../_shared/components/ModalDialog/ModalDialog.tsx';
import { OptionWithInputAttribute } from '../../../../../_shared/components/Options/Option.tsx';
import { Warning } from '../../../../../_shared/components/infos/Warning.tsx';
import { OptionMode } from '../../../../../_shared/models/optionMode.ts';
import {
  DataUiAction,
  DataUiInput,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { FileWithThumbnail } from '../../models/FileWithThumbnail.type.ts';

type Props = {
  readonly isConfirmed: boolean;
  readonly isImageCustomizationEnabled: boolean;
  readonly onClose: () => void;
  readonly onReplace: (files: ReadonlyArray<FileWithThumbnail>) => void;
  readonly onToggleConfirmation: () => void;
};

export const AssetReplaceDialog: FC<Props> = ({
  isConfirmed,
  isImageCustomizationEnabled,
  onReplace,
  onToggleConfirmation,
  onClose,
}) => (
  <ModalDialog
    onClose={onClose}
    headerContent="Want to replace this asset?"
    bodyContent={
      <div className="replace-asset-dialog">
        <div className="replace-asset-dialog__warning-section">
          <Warning>
            Make sure you understand what happens next:
            <ul>
              <li>
                Replacing an asset affects all content items using it, including published content.
              </li>
              <li>
                The asset will be replaced in all content types using it for default values and
                guidelines.
              </li>
              <li>
                The asset URL changes after replacement. This affects any uses of or links to the
                asset.
              </li>
              <li>
                The replaced asset is removed from the UI but not from storage and remains available
                at its original asset URL.
              </li>
              <li>
                The replacement ignores content type limitations for the file type, file size, and
                image resolution.
              </li>
              {isImageCustomizationEnabled && (
                <li>
                  The replacement keeps existing asset renditions. This can result in incorrectly
                  customized images. You may need to check them manually afterward.
                </li>
              )}
              <li>
                The replace is immediate and <b>cannot be undone</b>.
              </li>
            </ul>
          </Warning>
        </div>
        <div className="replace-asset-dialog__confirmation-section">
          <OptionWithInputAttribute
            ariaLabel="Confirm. I know what I’m doing and want to continue."
            dataUiAttribute={DataUiInput.Confirm}
            isSelected={isConfirmed}
            label="I know what I’m doing and want to continue"
            mode={OptionMode.Multiple}
            onOptionSelected={onToggleConfirmation}
          />
        </div>
      </div>
    }
    footerContentRight={
      <>
        <Button buttonStyle="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Tooltip
          text={
            isConfirmed ? undefined : 'To replace the asset, confirm that you understand the risks.'
          }
          placement="top-end"
        >
          <div className="btn-wrapper">
            <BrowseButton
              buttonStyle="primary"
              dataUiAction={DataUiAction.SelectFiles}
              buttonText="Replace"
              multiple={false}
              onUpload={onReplace}
              supportClick={isConfirmed}
            />
          </div>
        </Tooltip>
      </>
    }
  />
);
