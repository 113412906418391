import React, { forwardRef, memo, useCallback } from 'react';
import { useLocation } from 'react-router';
import { HideOutsideViewport } from '../../../../../_shared/components/HideOutsideViewport.tsx';
import { DefaultVariantId } from '../../../../../_shared/constants/variantIdValues.ts';
import { useDataSelector } from '../../../../../_shared/hooks/useDataSelector.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { MemoizedContentItemId } from '../../../../../_shared/models/ContentItemId.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import {
  memoizeContentItemId,
  stringifyContentItemId,
} from '../../../../../_shared/models/utils/contentItemIdUtils.ts';
import { getLanguageName } from '../../../../../_shared/selectors/getLanguageName.ts';
import { getListingContentItem } from '../../../../../_shared/selectors/getListingContentItem.ts';
import { getSelectedLanguageId } from '../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { getCurrentProjectId } from '../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { ScrollTableCellSkeleton } from '../../../../../_shared/uiComponents/ScrollTable/ScrollTableCellSkeleton.tsx';
import { ScrollTableRow } from '../../../../../_shared/uiComponents/ScrollTable/ScrollTableRow.tsx';
import { getCannotCreateMessageForItem } from '../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { hasCapabilityInLanguage } from '../../../../../_shared/utils/permissions/capabilitiesInLanguageUtils.ts';
import { Capability } from '../../../../../_shared/utils/permissions/capability.ts';
import { getCannotViewItemMessage } from '../../../../../_shared/utils/permissions/getCannotViewItemMessage.ts';
import { getContentItemPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { useLivePreviewPreferenceStorage } from '../../../../../localStorages/useLivePreviewPreferenceStorage.ts';
import { CascadeNodeId } from '../../../../contentInventory/content/stores/IContentAppStoreState.ts';
import { addDefaultLanguageSuffix } from '../../../../environmentSettings/localization/utils/languageUtils.ts';
import { nodeCollapsed, nodeDeselected } from '../actions/cascadeModalActions.ts';
import { expandNode } from '../actions/thunkCascadeModalActions.ts';
import { ChildRow as ChildRowComponent } from '../components/ChildRow.tsx';
import { getMessageForCannotPublishReason } from '../constants/cannotPublishReason.ts';
import { getSelectedItems } from '../selectors/getSelectedItems.ts';
import { getTransitiveDependencyRequiredMessage } from '../selectors/getTransitiveDependencyRequiredMessage.ts';
import { getCannotPublishReasonForContext } from '../utils/getCannotPublishReason.ts';
import { getCascadeNodeId } from '../utils/getCascadeNodeId.ts';
import { hasChildItemAnyPublishingDependencies } from '../utils/hasChildItemAnyPublishingDependencies.ts';
import { sortChildContentItemItemIds } from '../utils/sortChildContentItemItemIds.ts';

type ChildRowProps = {
  readonly contentItemId: MemoizedContentItemId;
  readonly depth: number;
  readonly disabledMessage?: string;
  readonly isExpanded: boolean;
  readonly nodeId: string;
  readonly parentNodeId?: CascadeNodeId;
};

const areChildNodesLoaded = (state: IStore, nodeId: CascadeNodeId): boolean =>
  state.contentApp.editorUi.cascadePublish.nodesLoadingStatus.get(nodeId) === LoadingStatus.Loaded;

export const ChildRow = forwardRef<HTMLDivElement, ChildRowProps>(
  ({ contentItemId, depth, disabledMessage, isExpanded, nodeId }, ref) => {
    const { itemId, variantId } = contentItemId;

    const dispatch = useDispatch();
    const location = useLocation();

    const currentProjectId = useSelector(getCurrentProjectId);
    const { isLivePreviewPreferred } = useLivePreviewPreferenceStorage(currentProjectId);

    const memoizedContentItemId = memoizeContentItemId(contentItemId);

    const defaultLanguage = useDataSelector((data) =>
      addDefaultLanguageSuffix(data.languages.defaultLanguage),
    );
    const cannotPublishReasonMessage = useSelector((s) =>
      getMessageForCannotPublishReason(
        getCannotPublishReasonForContext(s.contentApp.editorUi.itemEditingModalDialog.type)(
          s,
          contentItemId,
        ),
        defaultLanguage.name,
        getLanguageName(s, variantId) ?? '',
      ),
    );
    const item = useSelector((s) => getListingContentItem(s, contentItemId));
    const contentType = useSelector(
      (s) => s.data.contentTypes.byId.get(item?.item.typeId ?? '') ?? null,
    );
    const itemType = useSelector(
      (s) => s.contentApp.loadedContentItemTypes.get(item?.item.typeId ?? '') ?? null,
    );
    const canViewDefaultVariant = useSelector((s) =>
      hasCapabilityInLanguage(s, Capability.ViewContent, DefaultVariantId),
    );

    const isSelected = useSelector((s) =>
      getSelectedItems(
        s,
        getCannotPublishReasonForContext(s.contentApp.editorUi.itemEditingModalDialog.type),
      ).includes(memoizedContentItemId),
    );

    const isLoadingChildNodes = useSelector((s) => !areChildNodesLoaded(s, nodeId) && isExpanded);

    const childContentItemIds = useSelector((s) => {
      const selectedLanguageId = getSelectedLanguageId(s);
      return selectedLanguageId
        ? sortChildContentItemItemIds(
            new Set(
              s.contentApp.editorUi.cascadePublish.expandedNodesData.get(nodeId)
                ?.childContentItemIds || [],
            ),
            s.data.listingContentItems.byId,
            s.data.listingContentItems.defaultById,
            selectedLanguageId,
            itemId,
          )
        : null;
    });

    const transitiveDependencyRequiredMessage = useSelector((s) =>
      getTransitiveDependencyRequiredMessage(s, contentItemId),
    );

    const hasAnyChildNodes = useSelector((s) =>
      hasChildItemAnyPublishingDependencies(
        item,
        itemType,
        s.contentApp.editedContentItem?.id ?? '',
        canViewDefaultVariant,
      ),
    );

    const onNodeExpanded = useCallback(
      () =>
        dispatch(
          expandNode({
            itemId,
            nodeId,
            variantId,
            shouldSelectSelf: false,
          }),
        ),
      [nodeId, itemId, variantId],
    );

    const onNodeSelected = useCallback(
      () =>
        dispatch(
          expandNode({
            itemId,
            nodeId,
            variantId,
            shouldSelectSelf: true,
          }),
        ),
      [nodeId, itemId, variantId],
    );

    const onNodeCollapsed = useCallback(() => dispatch(nodeCollapsed(nodeId)), [nodeId]);

    const onNodeDeselected = useCallback(
      () => dispatch(nodeDeselected(contentItemId)),
      [contentItemId],
    );

    const hideOutsideViewport = (childContentItemIds?.length ?? 0) > 10;

    const renderChildRow = useCallback(
      (id: MemoizedContentItemId) => (
        <LazyChildRow
          key={stringifyContentItemId(id)}
          contentItemId={id}
          depth={depth + 1}
          hideOutsideViewport={hideOutsideViewport}
          parentNodeId={nodeId}
        />
      ),
      [depth, nodeId, hideOutsideViewport],
    );

    if (!childContentItemIds) {
      return null;
    }

    const cannotViewMessage = item ? getCannotViewItemMessage(item) : undefined;
    const cannotOpenMessage = item ? getCannotCreateMessageForItem(item) : undefined;

    return (
      <ChildRowComponent
        cannotOpenMessage={cannotOpenMessage}
        cannotViewMessage={cannotViewMessage}
        childContentItemIds={childContentItemIds}
        contentType={contentType}
        depth={depth}
        disabledMessage={
          cannotPublishReasonMessage || transitiveDependencyRequiredMessage || disabledMessage
        }
        hasAnyChildNodes={hasAnyChildNodes}
        isExpanded={isExpanded}
        isLoadingChildNodes={isLoadingChildNodes}
        isSelected={isSelected}
        item={item}
        itemPath={getContentItemPath(location.pathname, itemId, isLivePreviewPreferred, variantId)}
        onItemCollapsed={onNodeCollapsed}
        onItemDeselected={onNodeDeselected}
        onItemExpanded={onNodeExpanded}
        onItemSelected={onNodeSelected}
        ref={ref}
        renderChildRow={renderChildRow}
        variantId={variantId}
      />
    );
  },
);

ChildRow.displayName = 'ChildRowContainer';

type LazyChildRowProps = Omit<ChildRowProps, 'isExpanded' | 'nodeId'> & {
  readonly hideOutsideViewport: boolean;
};

export const LazyChildRow: React.FC<LazyChildRowProps> = memo(
  ({ hideOutsideViewport, ...otherProps }) => {
    const nodeId = getCascadeNodeId({
      ...otherProps.contentItemId,
      parentNodeId: otherProps.parentNodeId,
    });
    const isExpanded = useSelector((s) =>
      s.contentApp.editorUi.cascadePublish.expandedNodesData.has(nodeId),
    );

    if (!hideOutsideViewport) {
      return <ChildRow isExpanded={isExpanded} nodeId={nodeId} {...otherProps} />;
    }

    return (
      <HideOutsideViewport
        alwaysVisible={isExpanded}
        renderVisible={(ref) => (
          <ChildRow ref={ref} isExpanded={isExpanded} nodeId={nodeId} {...otherProps} />
        )}
        renderHidden={(ref) => (
          <ScrollTableRow objectName={otherProps.contentItemId.itemId} isSkeleton ref={ref}>
            {Array(otherProps.depth + 1)
              .fill(null)
              .map((_val, index) => (
                <ScrollTableCellSkeleton key={index} isEmpty size={1} />
              ))}
            <ScrollTableCellSkeleton size={10} />
            <ScrollTableCellSkeleton size={5} />
            <ScrollTableCellSkeleton size={5} />
          </ScrollTableRow>
        )}
        rootMargin="200px 0px 200px 0px"
      />
    );
  },
);

LazyChildRow.displayName = 'LazyChildRow';
