import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { getCurrentProject } from '../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import {
  LocalizationEditor_Init_Finished,
  LocalizationEditor_Init_Started,
} from '../../constants/localizationActionTypes.ts';

interface IDeps {
  readonly loadLanguagesForEditor: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadLanguageUsages: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadSubscriptionUsage: (subscriptionId: Uuid, abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadItemVariantCount: (projectId: Uuid, abortSignal?: AbortSignal) => ThunkPromise;
}

const languagesEditorInitStarted = () =>
  ({
    type: LocalizationEditor_Init_Started,
  }) as const;

const languagesEditorInitFinished = () => ({ type: LocalizationEditor_Init_Finished }) as const;

export type InitLanguagesEditorActionsType = ReturnType<
  typeof languagesEditorInitStarted | typeof languagesEditorInitFinished
>;

export const createInitLanguagesEditorAction =
  (deps: IDeps) =>
  (abortSignal: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    dispatch(languagesEditorInitStarted());

    const { subscriptionId, projectId } = getCurrentProject(getState());

    await Promise.all([
      dispatch(deps.loadLanguagesForEditor(abortSignal)),
      dispatch(deps.loadLanguageUsages(abortSignal)),
      dispatch(deps.loadSubscriptionUsage(subscriptionId, abortSignal)),
      dispatch(deps.loadItemVariantCount(projectId, abortSignal)),
    ]);

    dispatch(languagesEditorInitFinished());
  };
