import classNames from 'classnames';
import React, { useRef } from 'react';
import { CreateNewBarItemHidingButton } from '../../../../_shared/components/BarItems/CreateNewBarItemHidingButton.tsx';
import { useScrollOnDragEvents } from '../../../../_shared/hooks/useScrollOnDragEvents.ts';
import { ISitemap } from '../../../../data/models/contentModelsApp/sitemap/Sitemap.ts';
import { SitemapNode } from '../containers/SitemapNode.tsx';
import { NodeCreator } from '../containers/nodeEditors/NodeCreator.tsx';

type Props = {
  readonly isCreateNewFormDisplayed: boolean;
  readonly isCreatingAllowed: boolean;
  readonly isDragging: boolean;
  readonly onCreateNewButtonClicked: () => void;
  readonly sitemap: ISitemap | null;
};

export const SitemapNodesList: React.FC<Props> = ({
  isCreateNewFormDisplayed,
  isCreatingAllowed,
  isDragging,
  onCreateNewButtonClicked,
  sitemap,
}) => {
  const dndScrollAreaRef = useRef<HTMLUListElement>(null);
  useScrollOnDragEvents(dndScrollAreaRef);

  if (!sitemap) {
    return null;
  }

  const renderedNodes = sitemap.childIds.map((nodeId: Uuid) => (
    <SitemapNode isBeingDragged={false} key={nodeId} nodeId={nodeId} />
  ));

  return (
    <ul
      className={classNames('bar-item__list', {
        'bar-item__list--is-dragging': isDragging,
      })}
      data-hj-suppress=""
      ref={dndScrollAreaRef}
    >
      {renderedNodes}
      <CreateNewBarItemHidingButton
        isCreatingAllowed={isCreatingAllowed}
        isFormDisplayed={isCreateNewFormDisplayed}
        itemName="Sitemap item"
        onCreateNewClick={onCreateNewButtonClicked}
      >
        <li className="bar-item__node">
          <NodeCreator />
        </li>
      </CreateNewBarItemHidingButton>
    </ul>
  );
};

SitemapNodesList.displayName = 'SitemapNodesList';
