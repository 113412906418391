import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';

type Props = Readonly<{
  onClick: () => void;
}>;

export const RotatePreviewButton = ({ onClick }: Props) => (
  <QuinaryButton
    aria-label="Rotate preview"
    onClick={onClick}
    tooltipPlacement="top"
    tooltipText="Rotate preview"
  >
    <QuinaryButton.Icon icon={Icons.DeviceRotate} />
  </QuinaryButton>
);
