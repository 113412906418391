import { memoize } from '@kontent-ai/memoization';
import { Collection } from '@kontent-ai/utils';
import {
  areContentCollectionsPermissionsEnabledForCurrentProjectPlan,
  areSpacesInCollectionsEnabled,
} from '../../applications/environmentSettings/selectors/allowedFeaturesSelectors.ts';
import { areContentCollectionsEnabledForCurrentProjectPlan } from '../../applications/environmentSettings/utils/allowedFeaturesUtils.ts';
import { CollectionsMap, ICollection } from '../../data/models/collections/Collection.ts';
import { IStore } from '../stores/IStore.type.ts';
import { isCollectionsConfigurationVisible } from '../utils/collections/isCollectionsConfigurationVisible.ts';
import { Capability } from '../utils/permissions/capability.ts';
import { filterCollectionsByCapability } from '../utils/permissions/filterCollectionsByCapability.ts';
import { getCurrentProjectId, getCurrentProjectPlan } from './userProjectsInfoSelectors.ts';

export const getCollections = memoize.maxOne((collectionsMap: CollectionsMap) =>
  Collection.getValues(collectionsMap),
);

export const areCollectionPermissionsEnabledForCurrentProject = (state: IStore): boolean =>
  areContentCollectionsEnabledForCurrentProjectPlan(getCurrentProjectPlan(state)) &&
  areContentCollectionsPermissionsEnabledForCurrentProjectPlan(state);

export const areCollectionsVisibleForCurrentUser = (state: IStore): boolean => {
  const {
    data: { collections, user },
  } = state;

  const projectId = getCurrentProjectId(state);
  const collectionsWithViewCapability = filterCollectionsByCapability(
    Capability.ViewContent,
    collections.byId,
    projectId,
    user,
  );

  return collectionsWithViewCapability.length > 1;
};

export const areCollectionsVisibleForAssets = (
  state: IStore,
  collections: ReadonlyArray<ICollection>,
): boolean => isCollectionsConfigurationVisible(getCurrentProjectPlan(state), collections);

export const areCollectionsVisibleForSpaces = (
  state: IStore,
  collections: ReadonlyArray<ICollection>,
): boolean =>
  isCollectionsConfigurationVisible(getCurrentProjectPlan(state), collections) &&
  areSpacesInCollectionsEnabled(state);
