import { Button } from '@kontent-ai/component-library/Button';
import React from 'react';
import { closeNotificationBar } from '../../../../_shared/actions/sharedActions.ts';
import {
  NotificationBar,
  NotificationBarType,
} from '../../../../_shared/components/NotificationBar.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { TypeElementValidationResult } from '../utils/typeElementValidators/types/TypeElementValidationResult.type.ts';
import { isTypeValid } from '../utils/typeValidationUtils.ts';

interface IContentModelsNotificationsProps {
  readonly onFocusFirstInvalidElement: () => void;
  readonly validationResults: ReadonlyMap<Uuid, TypeElementValidationResult>;
}

export const ContentModelNotifications: React.FC<IContentModelsNotificationsProps> = ({
  onFocusFirstInvalidElement,
  validationResults,
}) => {
  const dispatch = useDispatch();
  const notificationBarText = useSelector((s) => s.sharedApp.notificationBar.text);

  const showInvalidElementsButton = !isTypeValid(validationResults);

  const handleClosePanel = () => dispatch(closeNotificationBar());

  return (
    <div className="content-model-notifications">
      <NotificationBar
        message={notificationBarText}
        tools={
          showInvalidElementsButton && (
            <Button
              buttonStyle="primary-inverse"
              size="small"
              onClick={onFocusFirstInvalidElement}
              {...getDataUiElementAttribute(DataUiElement.InvalidElements)}
            >
              Show invalid elements ↓
            </Button>
          )
        }
        type={NotificationBarType.Error}
        uiElement={DataUiElement.NotificationMessageError}
        onClosePanel={handleClosePanel}
      />
    </div>
  );
};

ContentModelNotifications.displayName = 'ContentModelNotifications';
