import React from 'react';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { isSelfOrAnyChildBeingEdited } from '../../../../../_shared/utils/treeUtils.ts';
import {
  addCollapsedNode,
  removeCollapsedNode,
  startDraggingNode,
  startEditingNode,
} from '../../actions/sitemapActions.ts';
import { dropNode } from '../../actions/thunkSitemapActions.ts';
import { DraggableNodeBar as DraggableNodeBarComponent } from '../../components/nodeBarItems/DraggableNodeBar.tsx';

export interface IDraggableNodeBarContainerOwnProps {
  readonly nodeId: Uuid;
}

export const DraggableNodeBar: React.FC<IDraggableNodeBarContainerOwnProps> = (props) => {
  const dispatch = useDispatch();

  const isCollapsed = useSelector((state) =>
    state.sitemapApp.collapsedNodeIds.includes(props.nodeId),
  );
  const node = useSelector((state) => state.sitemapApp.sitemap?.nodes.get(props.nodeId) ?? null);
  const isEditable = useSelector(
    ({ sitemapApp }) => !sitemapApp.editedNodeId && !sitemapApp.isCreateNewFormDisplayed,
  );
  const isBeingDragged = useSelector((state) => state.sitemapApp.draggedNodeId === props.nodeId);
  const isExpandCollapseButtonDisabled = useSelector((state) => {
    const getChildren = (id: Uuid): ReadonlyArray<Uuid> =>
      state.sitemapApp.sitemap?.nodes.get(id)?.childIds ?? [];
    return isSelfOrAnyChildBeingEdited(props.nodeId, state.sitemapApp.editedNodeId, getChildren);
  });

  if (!node) {
    return null;
  }

  return (
    <DraggableNodeBarComponent
      nodeData={node}
      isCollapsed={isCollapsed}
      isEditable={isEditable}
      isBeingDragged={isBeingDragged}
      isExpandCollapseButtonDisabled={isExpandCollapseButtonDisabled}
      onCollapse={() => dispatch(addCollapsedNode(props.nodeId))}
      onExpand={() => dispatch(removeCollapsedNode(props.nodeId))}
      onStartEditing={() => dispatch(startEditingNode(props.nodeId))}
      onNodePickedUp={() => dispatch(startDraggingNode(props.nodeId))}
      onNodeDropped={() => dispatch(dropNode())}
      {...props}
    />
  );
};

DraggableNodeBar.displayName = 'DraggableNodeBar';
