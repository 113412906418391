import { AiOperationState } from '../../../../../../../_shared/features/AI/types/AiOperationState.ts';
import { AiError } from '../../../../../../../_shared/features/AI/types/aiErrors.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { getSelectedLanguageId } from '../../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { getCurrentProjectId } from '../../../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { ElementReference } from '../../../../../../itemEditor/features/ContentItemEditing/containers/hooks/useItemElementReference.ts';
import { getSourceLanguagesForVariantTranslation } from '../../../../../../itemEditor/features/ContentItemEditing/containers/selectors/getSourceLanguagesForVariantTranslation.ts';
import { AiTranslateAction as AiTranslateActionComponent } from '../components/AiTranslateAction.tsx';

type Props = {
  readonly aiOperationState: AiOperationState;
  readonly isEmpty: boolean;
  readonly element: ElementReference;
  readonly error: AiError | null;
  readonly perform: (sourceLanguageId: string | null) => void;
};

export const AiTranslateAction: React.FC<Props> = (props) => {
  const availableLanguages = useSelector((store) => {
    const collectionId = store.contentApp.editedContentItem?.collectionId ?? null;

    return getSourceLanguagesForVariantTranslation(
      store.data.languages,
      getSelectedLanguageId(store),
      store.contentApp.contentItemVariants,
      store.data.user,
      getCurrentProjectId(store),
      collectionId,
    );
  });

  return (
    <AiTranslateActionComponent
      {...props}
      availableLanguages={availableLanguages}
      perform={props.perform}
    />
  );
};
