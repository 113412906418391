import { noOperation } from '@kontent-ai/utils';
import { useCallback } from 'react';
import { useDataSelector } from '../../../../../../../_shared/hooks/useDataSelector.ts';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { addDefaultLanguageSuffix } from '../../../../../../environmentSettings/localization/utils/languageUtils.ts';
import { closeDefaultVariantOverwrittenNotification } from '../../../../../actions/contentActions.ts';
import { getExtendedContentChange } from '../../../selectors/getExtendedContentChange.ts';
import { OverwriteNotificationBar } from './OverwriteNotificationBar.tsx';

export const DefaultVariantOverwriteNotificationBar = () => {
  const dispatch = useDispatch();
  const usersById = useDataSelector((data) => data.users.usersById);
  const overwrittenLanguageName = useDataSelector(
    (data) => addDefaultLanguageSuffix(data.languages.defaultLanguage).name,
  );
  const onClose = useCallback(() => {
    dispatch(closeDefaultVariantOverwrittenNotification());
  }, []);
  const contentChange = useSelector((s) =>
    getExtendedContentChange(s, s.contentApp.defaultVariantOverwritten),
  );
  const changeByUser = contentChange.changeBy ? usersById.get(contentChange.changeBy) : undefined;

  return (
    <OverwriteNotificationBar
      contentChange={contentChange}
      changeByUser={changeByUser}
      overwrittenLanguageName={overwrittenLanguageName}
      onClose={onClose}
      onDisplay={noOperation}
      onRefresh={noOperation}
    />
  );
};
