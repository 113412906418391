import { UserFeedbackTimestamps } from '../../data/models/user/UserFeedbackTimestamps.type.ts';
import { IUser } from '../../data/reducers/user/IUser.type.ts';
import { IStore } from '../stores/IStore.type.ts';
import { isMonthsPeriodPassed, isTimeInPast } from '../utils/dateTime/timeUtils.ts';
import { isEmployee } from '../utils/subscriptionUsageUtils.ts';
import { getCurrentUser } from './getCurrentUser.ts';
import { isProjectTrial } from './userProjectsInfoSelectors.ts';

const isNewUser = (user: IUser, relativeToDate: Date) =>
  !isMonthsPeriodPassed(1, relativeToDate, user.info.createdAt);

const hasAlreadySeenSurvey = (
  userFeedback: UserFeedbackTimestamps,
  surveyAvailableFrom: Date,
): boolean =>
  !!userFeedback.displayedAt && !isTimeInPast(surveyAvailableFrom, userFeedback.displayedAt);

const hasAlreadySubmitted = (
  userFeedback: UserFeedbackTimestamps,
  surveyAvailableFrom: Date,
): boolean =>
  !!userFeedback.answeredAt && !isTimeInPast(surveyAvailableFrom, userFeedback.answeredAt);

const isHelpUsImproveSurveyAvailable = (state: IStore, relativeToDate: Date): boolean => {
  const {
    sharedApp: { generalProperties, currentProjectId, userProperties },
  } = state;

  return (
    !!generalProperties.enableUmuxSurvey &&
    isTimeInPast(relativeToDate, generalProperties.enableUmuxSurvey) &&
    !isEmployee(getCurrentUser(state).email) &&
    !isProjectTrial(state, currentProjectId) &&
    !hasAlreadySubmitted(userProperties.userFeedback, new Date(generalProperties.enableUmuxSurvey))
  );
};

export const shouldShowHelpUsImproveSurveyAutomatically = (
  state: IStore,
  relativeToDate: Date,
): boolean => {
  const {
    sharedApp: { userProperties, generalProperties },
    data: { user },
  } = state;

  return (
    !!generalProperties.enableUmuxSurvey &&
    isHelpUsImproveSurveyAvailable(state, relativeToDate) &&
    !isNewUser(user, relativeToDate) &&
    !hasAlreadySeenSurvey(userProperties.userFeedback, new Date(generalProperties.enableUmuxSurvey))
  );
};

export const shouldShowHelpUsImproveSurveyReminder = (
  state: IStore,
  currentTime: Date,
): boolean => {
  const {
    sharedApp: { userProperties, generalProperties },
    data: { user },
  } = state;

  return (
    !!generalProperties.enableUmuxSurvey &&
    isHelpUsImproveSurveyAvailable(state, currentTime) &&
    !isNewUser(user, currentTime) &&
    hasAlreadySeenSurvey(userProperties.userFeedback, new Date(generalProperties.enableUmuxSurvey))
  );
};
