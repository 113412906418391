import { Dispatch } from '../../../../../@types/Dispatcher.type.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { generateUniqueCodename } from '../../../../../_shared/utils/codenameGenerator.ts';
import { ISitemapNode } from '../../../../../data/models/contentModelsApp/sitemap/Sitemap.ts';
import { Sitemap_ConfirmEditedNode } from '../../constants/sitemapActionTypes.ts';
import { ISaveSitemap } from '../../models/ISaveSitemapNode.type.ts';
import { ISitemapFormShape } from '../../models/ISitemapFormShape.type.ts';
import { getNodeCodenamesWithoutEditedNodeCodename } from '../../utils/sitemapUtils.ts';

interface IDropNodeDependencies {
  readonly updateSitemap: ISaveSitemap;
}

export const confirmEditedNode = (node: ISitemapNode) =>
  ({
    type: Sitemap_ConfirmEditedNode,
    payload: {
      node,
    },
  }) as const;

export type ConfirmNodeEditingActionsType = ReturnType<typeof confirmEditedNode>;

export const confirmNodeEditingCreator =
  (deps: IDropNodeDependencies) =>
  (nodeId: Uuid, values: ISitemapFormShape) =>
  async (dispatch: Dispatch, getState: () => IStore): Promise<void> => {
    const {
      data: {
        sitemap: {
          data: { nodes },
        },
      },
      sitemapApp: { sitemap },
    } = getState();

    if (!sitemap) {
      throw new Error('No sitemap found.');
    }
    const originalNode = nodes.get(nodeId);
    const nameChanged = originalNode?.name !== values.name;
    const nodeCodenames = getNodeCodenamesWithoutEditedNodeCodename(nodes, originalNode?.id);
    const newCodename = nameChanged
      ? generateUniqueCodename(values.name, nodeCodenames)
      : originalNode.codename;
    const originalEditedNode = sitemap.nodes.get(nodeId);
    if (!originalEditedNode) {
      return;
    }

    const editedNode: ISitemapNode = {
      ...originalEditedNode,
      name: values.name,
      codename: newCodename,
    };

    dispatch(confirmEditedNode(editedNode));

    const updatedSitemap = getState().sitemapApp.sitemap;
    if (updatedSitemap) {
      await dispatch(deps.updateSitemap(updatedSitemap));
    }
  };
