import { InputState } from '@kontent-ai/component-library/Input';
import {
  OptionsError,
  TriggerError,
  TypeElementConditionValidationResult,
} from '../../../../../../utils/typeElementValidators/types/TypeElementWithConditionValidationResult.ts';

const inactiveConfigurationTooltipText = 'Enable conditional visibility first.';
const noTriggerTooltipText =
  'To set visibility conditions, add another multiple choice element to this content type.';
const noOptionsInTriggerTooltipText = 'Create some options first.';
const triggerCannotHaveConditionsTooltipText = 'This element is used in another condition.';

type ConditionState = {
  isDisabled: boolean;
  tooltipText: string | undefined;
};

type SelectState = {
  inputState: InputState.Disabled | InputState.Default;
  tooltipText: string | undefined;
};

type SelectWithAlertState = {
  inputState: InputState.Disabled | InputState.Default | InputState.Alert;
  tooltipText: string | undefined;
};

type UiState = {
  condition: ConditionState;
  visibility: SelectState;
  trigger: SelectWithAlertState;
  operator: SelectState;
  triggerOptions: SelectWithAlertState;
};

export const getUiState = (
  isElementTriggerInOtherCondition: boolean,
  isConditionActive: boolean,
  noTrigger: boolean,
  noOptionsInSelectedTrigger: boolean,
  conditionValidationResult: TypeElementConditionValidationResult,
): UiState => {
  if (isElementTriggerInOtherCondition) {
    return getEverythingDisabledState(triggerCannotHaveConditionsTooltipText);
  }

  if (noTrigger && !isConditionActive) {
    return getEverythingDisabledState(noTriggerTooltipText);
  }

  if (!isConditionActive) {
    return {
      ...getEverythingDisabledState(inactiveConfigurationTooltipText),
      condition: {
        isDisabled: false,
        tooltipText: undefined,
      },
    };
  }

  if (noOptionsInSelectedTrigger) {
    return {
      ...initialState,
      triggerOptions: {
        inputState: InputState.Disabled,
        tooltipText: noOptionsInTriggerTooltipText,
      },
    };
  }

  return {
    ...initialState,
    trigger: {
      inputState:
        conditionValidationResult.triggerError !== TriggerError.None
          ? InputState.Alert
          : InputState.Default,
      tooltipText: undefined,
    },
    triggerOptions: {
      inputState:
        conditionValidationResult.optionsError !== OptionsError.None
          ? InputState.Alert
          : InputState.Default,
      tooltipText: undefined,
    },
  };
};

const initialState: UiState = {
  condition: {
    isDisabled: false,
    tooltipText: undefined,
  },
  visibility: {
    inputState: InputState.Default,
    tooltipText: undefined,
  },
  trigger: {
    inputState: InputState.Default,
    tooltipText: undefined,
  },
  operator: {
    inputState: InputState.Default,
    tooltipText: undefined,
  },
  triggerOptions: {
    inputState: InputState.Default,
    tooltipText: undefined,
  },
};

const getEverythingDisabledState = (tooltipText: string): UiState => ({
  condition: {
    isDisabled: true,
    tooltipText,
  },
  visibility: {
    inputState: InputState.Disabled,
    tooltipText,
  },
  trigger: {
    inputState: InputState.Disabled,
    tooltipText,
  },
  operator: {
    inputState: InputState.Disabled,
    tooltipText,
  },
  triggerOptions: {
    inputState: InputState.Disabled,
    tooltipText,
  },
});
