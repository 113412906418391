import React from 'react';
import { DragSource } from '../../../../../_shared/components/DragDrop/DragSource.tsx';
import { DndTypes } from '../../../../../_shared/constants/dndTypes.ts';
import { ITaxonomyTerm } from '../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyTerm.ts';
import {
  ITermBarBaseProps,
  ITermBarDispatchProps,
  ITermBarStateProps,
  TermBar,
} from './TermBar.tsx';

export interface IDraggableTermBarOwnProps {
  readonly isCollapsed: boolean;
  readonly taxonomyTerm: ITaxonomyTerm;
}

interface IDraggableTermBarProps
  extends IDraggableTermBarOwnProps,
    ITermBarBaseProps,
    ITermBarStateProps,
    ITermBarDispatchProps {
  readonly onTermDropped: () => void;
  readonly onTermPickedUp: () => void;
}

export const DraggableTermBar: React.FC<IDraggableTermBarProps> = (props) => {
  const { onTermDropped, onTermPickedUp, ...termBarProps } = props;

  return (
    <DragSource
      parentId=""
      sourceId={termBarProps.taxonomyTerm.id}
      onDragStart={onTermPickedUp}
      onDragEnd={onTermDropped}
      renderDraggable={(connectDragSource, isDragging) => (
        <TermBar {...termBarProps} connectDragSource={connectDragSource} isDragging={isDragging} />
      )}
      renderPreview={() => (
        <TermBar {...termBarProps} isBeingDragged={false} isCollapsed isDragging={false} />
      )}
      type={DndTypes.Taxonomy_Node_Move}
    />
  );
};

DraggableTermBar.displayName = 'DraggableTermBar';
