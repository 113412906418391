import React from 'react';
import { DragSource } from '../../../../../_shared/components/DragDrop/DragSource.tsx';
import { DropTarget } from '../../../../../_shared/components/DragDrop/DropTarget.tsx';
import { DndTypes } from '../../../../../_shared/constants/dndTypes.ts';
import { isWithinTargetInset20 } from '../../../../../_shared/utils/dragDrop/hoveringCollisionStrategies.ts';
import {
  ContentTypeContentGroupTab,
  IContentTypeContentGroupTabDispatchProps,
  IContentTypeContentGroupTabStateProps,
} from './ContentTypeContentGroupTab.tsx';

interface IDraggableContentTypeContentGroupTabProps
  extends IContentTypeContentGroupTabStateProps,
    IContentTypeContentGroupTabDispatchProps {
  readonly id: Uuid;
  readonly onGroupDropped: () => void;
  readonly onGroupPickedUp: () => void;
  readonly onMove: (movedContentGroupId: Uuid, targetContentGroupId: Uuid) => void;
}

export const DraggableContentTypeContentGroupTab: React.FC<
  IDraggableContentTypeContentGroupTabProps
> = (props) => {
  const { onMove, onGroupDropped, onGroupPickedUp, id, ...groupTabProps } = props;

  return (
    <DropTarget<HTMLDivElement>
      accept={DndTypes.Content_Group_Move}
      canDrop
      onMove={({ sourceId, targetId }) => onMove(sourceId, targetId)}
      parentId=""
      hoveringCollisionStrategy={isWithinTargetInset20}
      renderDroppable={(ref) => (
        <div className="content-type-group-tab__wrapper" ref={ref}>
          <DragSource
            parentId=""
            sourceId={id}
            onDragStart={onGroupPickedUp}
            onDragEnd={onGroupDropped}
            renderDraggable={(connectDragSource, isDragging) => (
              <ContentTypeContentGroupTab
                {...groupTabProps}
                connectDragSource={connectDragSource}
                isDragging={isDragging}
              />
            )}
            renderPreview={() => (
              <ContentTypeContentGroupTab {...groupTabProps} isDragging={false} />
            )}
            type={DndTypes.Content_Group_Move}
          />
        </div>
      )}
      targetId={id}
    />
  );
};

DraggableContentTypeContentGroupTab.displayName = 'DraggableContentTypeContentGroupTab';
