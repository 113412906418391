import { assert } from '@kontent-ai/utils';
import { History } from 'history';
import { ThunkFunction, ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { ModalDialogType } from '../../../../_shared/constants/modalDialogType.ts';
import { getModalDialogProperties } from '../../../../_shared/selectors/getModalDialogProperties.ts';
import { redirectToLinkedItem } from '../../../itemEditor/features/ContentItemEditing/actions/thunks/redirectToLinkedItem.ts';
import { ICreateAndLinkContentItemActionParams } from './createAndLinkContentItem.ts';

const getModalProps = getModalDialogProperties(ModalDialogType.NewContentItemDialogForWebSpotlight);

type Deps = {
  readonly createAndLinkContentItem: (
    params: ICreateAndLinkContentItemActionParams,
  ) => ThunkPromise<Uuid>;
  readonly prepareNewContentItemVariantInit: () => ThunkFunction;
};

export const createNewContentItemFromWebSpotlightCreator =
  (deps: Deps) =>
  (history: History, isLivePreviewPreferred: boolean): ThunkPromise =>
  async (dispatch, getState): Promise<void> => {
    const modalProperties = getModalProps(getState());

    assert(
      modalProperties,
      () => 'Modal properties were not set for new content item dialog in Web Spotlight.',
    );

    dispatch(deps.prepareNewContentItemVariantInit());

    const createdItemId = await dispatch(
      deps.createAndLinkContentItem({
        itemId: modalProperties.itemId,
        parentPath: modalProperties.itemPath,
        subpagesElementId: modalProperties.subpagesElementId,
        variantId: modalProperties.variantId,
      }),
    );

    dispatch(
      redirectToLinkedItem(
        modalProperties.itemPath,
        createdItemId,
        isLivePreviewPreferred,
        history,
      ),
    );
  };
