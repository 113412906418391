import { InvariantException } from '@kontent-ai/errors';
import { useCallback } from 'react';
import { useLocation } from 'react-router';
import { trackUserEvent } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { useDataSelector } from '../../../../../_shared/hooks/useDataSelector.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { CalendarEventTypes } from '../../../../../_shared/models/TrackUserEventData.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { getCurrentProjectId } from '../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { getWorkflow } from '../../../../../_shared/selectors/workflowSelectors.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { isScheduledToPublishWorkflowStep } from '../../../../../_shared/utils/contentItemUtils.ts';
import { getContentItemPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { getUsersInfo } from '../../../../../_shared/utils/users/usersUtils.ts';
import { hasProjectMultipleWorkflows } from '../../../../../_shared/utils/workflow/hasProjectMultipleWorkflows.ts';
import { useLivePreviewPreferenceStorage } from '../../../../../localStorages/useLivePreviewPreferenceStorage.ts';
import { navigateBackFromCalendarItemDetails } from '../../actions/calendarActions.ts';
import { ItemDetails as ItemDetailsComponent } from '../../components/Popups/ItemDetails.tsx';
import { AgendaItemStatus } from '../../constants/AgendaItemStatus.ts';
import {
  IAgendaItem,
  IListingContentItemWithEnsuredVariant,
} from '../../models/CalendarModels.type.ts';
import { itemHasVariant } from '../../selectors/calendarSelectors.tsx';
import { getAgendaStateForDueItems, getCurrentDate } from '../../utils/calendarUtils.ts';

const getStatus = ({ variant }: IListingContentItemWithEnsuredVariant) => {
  if (variant.assignment.due) {
    return getAgendaStateForDueItems(variant.assignment.due, getCurrentDate);
  }

  if (isScheduledToPublishWorkflowStep(variant.assignment.workflowStatus)) {
    return AgendaItemStatus.Scheduled;
  }

  return AgendaItemStatus.Published;
};

export type ItemDetailsContainerProps = Readonly<{
  onDismiss: () => void;
}>;

export const ItemDetails = ({ onDismiss }: ItemDetailsContainerProps) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const isNavigationBackEnabled = useSelector((s) => !!s.calendarApp.drillDownDate);
  const selectedItem = useSelector(getContentItemForSelectedAgenda);
  const assignees = useSelector((s) =>
    getUsersInfo(selectedItem.variant.assignment.assignees, s.data.users.usersById),
  );

  const isWorkflowNameShown = useDataSelector((state) =>
    hasProjectMultipleWorkflows(state.workflows.byId),
  );
  const workflowName = useSelector(
    (s) => getWorkflow(s, selectedItem.variant.assignment.workflowStatus.workflowId)?.name,
  );

  const onItemSelected = useCallback(() => {
    dispatch(
      trackUserEvent(TrackedEvent.Calendar, {
        action: CalendarEventTypes.ContentItemSelected,
      }),
    );
  }, []);
  const onBack = useCallback(() => dispatch(navigateBackFromCalendarItemDetails()), []);

  const currentProjectId = useSelector(getCurrentProjectId);
  const { isLivePreviewPreferred } = useLivePreviewPreferenceStorage(currentProjectId);
  const selectedLanguageId = useSelector(getSelectedLanguageIdOrThrow);
  const path = getContentItemPath(
    location.pathname,
    selectedItem.item.id,
    isLivePreviewPreferred,
    selectedLanguageId,
  );
  const item: IAgendaItem = {
    id: {
      itemId: selectedItem.item.id,
      variantId: selectedLanguageId,
    },
    name: selectedItem.item.name,
    status: getStatus(selectedItem),
    assignment: selectedItem.variant.assignment,
    lastModified: selectedItem.variant.lastModified,
    lastPublishedAt: selectedItem.variant.lastPublishedAt,
    publishingState: selectedItem.variant.publishingState,
    isWorkflowNameShown,
    workflowName,
  };

  return (
    <ItemDetailsComponent
      assignees={assignees}
      isNavigationBackEnabled={isNavigationBackEnabled}
      item={item}
      onBack={onBack}
      onDismiss={onDismiss}
      onItemSelected={onItemSelected}
      pathToItem={path}
    />
  );
};

const getContentItemForSelectedAgenda = (state: IStore): IListingContentItemWithEnsuredVariant => {
  const itemDetails = state.calendarApp.itemDetails;
  if (!itemDetails) {
    throw InvariantException('ItemDetails.tsx: item id or variant id not assigned');
  }
  const contentItem = state.data.listingContentItems.byId.get(itemDetails.id);
  if (!contentItem || !itemHasVariant(contentItem)) {
    throw InvariantException('ItemDetails.tsx: item without variant encountered');
  }
  return contentItem;
};
