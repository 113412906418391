import styled from 'styled-components';
import { assetTileBorderRadius } from '../../decisionTokens.ts';

export const StyledAssetTileToolbar = styled.div`
  z-index: 2;
  position: relative;
  border-top-left-radius: ${assetTileBorderRadius};
  border-top-right-radius: ${assetTileBorderRadius};
  cursor: default;
`;
