import { IAsset } from '../../../../data/models/assets/Asset.ts';

export const getAssetCodenamesWithoutEditedAsset = (
  assetsById: Immutable.Map<Uuid, IAsset>,
  editedAsset: IAsset,
): ReadonlySet<string> => {
  const assetsWithoutEditedOne = assetsById.remove(editedAsset.id);
  const codenameArray = assetsWithoutEditedOne
    .toArray()
    .map((a) => a.codename)
    .filter((codename): codename is string => codename !== null);
  return new Set(codenameArray);
};

export const isCodenameUnique = (
  codename: string | null,
  relatedCodenames?: ReadonlySet<string>,
): boolean => codename !== null && !!relatedCodenames && !relatedCodenames.has(codename);
