import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../@types/Dispatcher.type.ts';
import { modalOpened } from '../../../../_shared/actions/sharedActions.ts';
import { ModalDialogType } from '../../../../_shared/constants/modalDialogType.ts';
import { getCurrentProject } from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { getUserCapability } from '../../../../_shared/utils/permissions/capability.ts';
import { environmentSettingsRequiredCapabilities } from '../../../environmentSettings/utils/environmentSettingsMenuStructureUtils.tsx';
import { showRelationsRootSelector } from '../actions/relationsActions.ts';
import { loadDefaultRelationsRoot, resetRelationsRoot } from '../actions/thunkRelationsActions.ts';
import {
  IRelationsActionsDispatchProps,
  IRelationsActionsStateProps,
  RelationsActions as RelationsActionsComponent,
} from '../components/RelationsActions.tsx';

const mapStateToProps = (state: IStore): IRelationsActionsStateProps => {
  const {
    relationsApp: {
      defaultRootId,
      isDefaultRootBeingChanged,
      isDefaultRootBeingFetched,
      selectedRootId,
    },
  } = state;

  const currentProject = getCurrentProject(state);
  const capability = getUserCapability(currentProject);
  const canChangeSettings = capability.canOneOf(environmentSettingsRequiredCapabilities());
  const isDefaultSelected = !!selectedRootId && selectedRootId === defaultRootId;
  const hasProjectDefault = !!defaultRootId;

  return {
    canChangeSettings,
    hasProjectDefault,
    isDefaultSelected,
    isDefaultRootBeingChanged,
    isDefaultRootBeingFetched,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IRelationsActionsDispatchProps => ({
  onBackToDefaultClick: () => dispatch(resetRelationsRoot()),
  onChangeClick: () => dispatch(showRelationsRootSelector()),
  onMenuOpen: () => dispatch(loadDefaultRelationsRoot()),
  onSetAsDefaultClick: () =>
    dispatch(modalOpened(ModalDialogType.ChangeDefaultRootConfirmationDialog)),
});

export const RelationsActions: React.ComponentType = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RelationsActionsComponent);
