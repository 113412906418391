import { memoize } from '@kontent-ai/memoization';
import { ICommentThread, IInlineCommentThread } from '../../models/comments/CommentThreads.ts';
import { ILinkedItemsElement } from '../../models/contentItemElements/modularItems/ILinkedItemsElement.ts';
import {
  CommentThreadWithLocation,
  EmptyThreadReferences,
  getCommentedLinkedItemSelector,
  isThreadInline,
} from '../commentUtils.ts';
import { IItemElementCommentManager } from './types/IItemElementCommentManager.type.ts';

const getLinkedItemCommentThreadReferences = memoize.weak(
  (
    element: ILinkedItemsElement,
    contentComponentId: Uuid | null,
    commentThreads: ReadonlyArray<ICommentThread>,
  ): ReadonlyArray<CommentThreadWithLocation> => {
    if (commentThreads.length === 0) {
      return EmptyThreadReferences;
    }

    const elementThreads = commentThreads.filter(
      (commentThread: IInlineCommentThread) =>
        commentThread.elementId === element.elementId &&
        commentThread.contentComponentId === contentComponentId,
    );

    const threadReferences = element.value.flatMap((linkedItemId) =>
      elementThreads
        .filter(isThreadInline)
        .filter((commentThread) => commentThread.externalSegmentId === linkedItemId)
        .map((commentThread) => ({
          commentThread,
          componentPath: null,
        })),
    );

    return threadReferences;
  },
);

const getCommentTriggerCssSelector = (commentThread: ICommentThread): string | null =>
  getCommentedLinkedItemSelector(commentThread);

export const linkedItemCommentManager: IItemElementCommentManager<ILinkedItemsElement> = {
  getCommentThreadReferences: (element, threads, _loadedContentItemTypes, contentComponentId) =>
    getLinkedItemCommentThreadReferences(element, contentComponentId, threads),
  getCommentTriggerCssSelector,
};
