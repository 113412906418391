import React, { ReactElement, useMemo } from 'react';
import { renderDatetimeString } from '../../utils/dateTime/timeUtils.ts';
import {
  CardinalLocalTimeZoneId,
  ensureBrowserCompatibleTimeZone,
  getFormattedTimeZoneOffset,
} from '../../utils/dateTime/timeZoneUtils.ts';

export const Caption: React.FC<{
  readonly renderStyledText: (text: string) => ReactElement;
  readonly date: string | null;
  readonly timeZone: string | null;
}> = ({ date, timeZone, renderStyledText }) => {
  const ensuredBrowserCompatibleTimeZone = useMemo(
    () => timeZone && ensureBrowserCompatibleTimeZone(timeZone),
    [timeZone],
  );
  const text =
    date && ensuredBrowserCompatibleTimeZone && timeZone !== CardinalLocalTimeZoneId
      ? getSelectedDateAndTimeZoneCaption(date, ensuredBrowserCompatibleTimeZone)
      : null;

  return text ? renderStyledText(text) : null;
};

Caption.displayName = 'Caption';

const getSelectedDateAndTimeZoneCaption = (dateTime: DateTimeStamp, timeZoneId: string): string =>
  `You’ve selected UTC ${getFormattedTimeZoneOffset(
    dateTime,
    timeZoneId,
  )} – this means ${renderDatetimeString(dateTime)} in your local time.`;
