import { Box } from '@kontent-ai/component-library/Box';
import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';

interface IExpandCollapseAllProps {
  readonly onCollapseAll: () => void;
  readonly onExpandAll: () => void;
}

export const ExpandCollapseAll: React.FC<IExpandCollapseAllProps> = ({
  onCollapseAll,
  onExpandAll,
}) => (
  <Box display="flex" gap={Spacing.S} justifyContent="flex-end" marginBottom={Spacing.M}>
    <QuinaryButton onClick={onExpandAll} tooltipText="Expand all">
      Expand all
    </QuinaryButton>
    <QuinaryButton onClick={onCollapseAll} tooltipText="Collapse all">
      Collapse all
    </QuinaryButton>
  </Box>
);

ExpandCollapseAll.displayName = 'ExpandCollapseAll';
