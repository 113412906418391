import { IStore } from '../../../../../../../_shared/stores/IStore.type.ts';
import { getTopLevelContentComponentIdsFromElements } from '../../contentComponentUtils.ts';

export const isOpenComponentsAvailable = ({
  contentApp: { editedContentItemVariantElements, editorUi },
}: IStore) => {
  const topLevelContentComponentIds = getTopLevelContentComponentIdsFromElements(
    editedContentItemVariantElements,
  );
  return (
    !topLevelContentComponentIds.isEmpty() &&
    topLevelContentComponentIds.subtract(editorUi.collapsedContentComponents).isEmpty()
  );
};

export const isCloseComponentsAvailable = ({
  contentApp: { editedContentItemVariantElements, editorUi },
}: IStore) => {
  const topLevelContentComponentIds = getTopLevelContentComponentIdsFromElements(
    editedContentItemVariantElements,
  );
  return (
    !topLevelContentComponentIds.isEmpty() &&
    !topLevelContentComponentIds.subtract(editorUi.collapsedContentComponents).isEmpty()
  );
};
