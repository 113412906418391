import { Box } from '@kontent-ai/component-library/Box';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { animated, useTransition } from '@react-spring/web';
import React from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

export interface ITypeElementConfigurationPaneProps {
  readonly children: React.ReactNode;
  readonly showConfig: boolean;
}

export const TypeElementConfigurationPane: React.FC<ITypeElementConfigurationPaneProps> = ({
  children,
  showConfig,
}) => {
  const transitions = useTransition(showConfig, {
    config: {
      duration: 300,
    },
    from: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
    },
    leave: {
      opacity: 0,
    },
  });

  return transitions(
    (style, item) =>
      item && (
        <animated.div style={style}>
          <Box
            marginTop={Spacing.S}
            {...getDataUiElementAttribute(DataUiElement.ContentElementConfigPane)}
          >
            {children}
          </Box>
        </animated.div>
      ),
  );
};

TypeElementConfigurationPane.displayName = 'TypeElementConfigurationPane';
