import React, { ComponentPropsWithRef } from 'react';
import { withAutoDispatcher } from '../../../../_shared/components/AutoDispatcher.tsx';
import { NameEditorElement } from '../../../../_shared/components/NameEditorElement.tsx';
import { ValidationConstants } from '../../../../_shared/constants/validationConstants.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { getCurrentProjectContainer } from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { DataUiInput } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { projectNameUpdated } from '../actions/generalProjectSettingsActions.ts';
import { saveProjectNameToServer } from '../actions/thunkGeneralSettingsActions.ts';
import { UntitledProjectSurrogateName } from '../constants/UIConstants.ts';

const ProjectNameEditorContainer: React.FC = () => {
  const dispatch = useDispatch();
  const editedProjectName = useSelector((s) => s.projectsApp.editedProjectName);

  return (
    <NameEditorElement
      autoFocus
      className="name-editor--is-plain"
      dataUiInputName={DataUiInput.EntityName}
      maxLength={ValidationConstants.ProjectNameMaxLength}
      placeholder={UntitledProjectSurrogateName}
      value={editedProjectName === UntitledProjectSurrogateName ? '' : editedProjectName}
      onChange={(name: string) => dispatch(projectNameUpdated(name))}
    />
  );
};

export type ProjectObservedState = {
  readonly projectName: string;
};

const mapObservedState = (state: IStore): ProjectObservedState => {
  return {
    projectName: state.projectsApp.editedProjectName,
  };
};

const shouldDispatch = (oldState: IStore, newState: IStore): boolean => {
  const nextProjectName = newState.projectsApp.editedProjectName;
  const currentProject = getCurrentProjectContainer(newState);
  const oldProject = getCurrentProjectContainer(oldState);

  const isProjectSwitched = currentProject.projectId !== oldProject.projectId;
  const isProjectNameChanged = currentProject.projectName !== nextProjectName;

  return !isProjectSwitched && isProjectNameChanged;
};

export const ProjectNameEditorElement = withAutoDispatcher<
  ComponentPropsWithRef<typeof ProjectNameEditorContainer>,
  ProjectObservedState
>(
  mapObservedState,
  saveProjectNameToServer,
  1000,
  shouldDispatch,
)(ProjectNameEditorContainer);
