import React, { useState } from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { pluralizeWithCount } from '../../../../../_shared/utils/stringUtils.ts';
import { IContentGroup } from '../../../../contentInventory/content/models/contentTypeElements/types/ContentGroup.ts';
import { ContentGroupDialogState, ContentGroupNameDialog } from './ContentGroupNameDialog.tsx';
import { SendToContentGroupDropdown } from './SendToContentGroupDropdown.tsx';

export interface IContentGroupSelectionBarStateProps {
  readonly contentTypeGroupsCount: number;
  readonly destinationContentGroups: ReadonlyArray<IContentGroup>;
  readonly selectedTypeElementCount: number;
  readonly typeElementCount: number;
}

export interface IContentGroupSelectionBarDispatchProps {
  readonly onSendToContentGroup: (contentGroupId: Uuid) => void;
  readonly onSendToNewContentGroup: (contentGroupName: string) => void;
}

export type ContentGroupSelectionBarProps = IContentGroupSelectionBarStateProps &
  IContentGroupSelectionBarDispatchProps;

export const ContentGroupSelectionBar: React.FC<ContentGroupSelectionBarProps> = (props) => {
  const [showSendToNewGroupDialog, setShowSendToNewGroupDialog] = useState(false);

  const onSendToNewGroupConfirmed = (contentGroupName: string): void => {
    props.onSendToNewContentGroup(contentGroupName);
    setShowSendToNewGroupDialog(false);
  };

  const containsElements = props.typeElementCount > 0;
  const showNoElementsInGroupMessage = props.contentTypeGroupsCount > 0;
  const showSelectedElementCount = props.selectedTypeElementCount > 0;
  const showElementCount = !showSelectedElementCount && containsElements;

  if (!containsElements) {
    if (showNoElementsInGroupMessage) {
      return (
        <div className="content-group-selection-bar">
          <div
            className="content-group-selection-bar__element-count"
            {...getDataUiElementAttribute(DataUiElement.ContentGroupsInfoMessage)}
          >
            There are no elements in this group.
          </div>
        </div>
      );
    }

    return null;
  }

  return (
    <div className="content-group-selection-bar">
      {showElementCount && (
        <div
          className="content-group-selection-bar__element-count"
          {...getDataUiElementAttribute(DataUiElement.ContentGroupsInfoMessage)}
        >
          {pluralizeWithCount('element', props.typeElementCount)} in this content group
        </div>
      )}
      {showSelectedElementCount && (
        <>
          <div
            className="content-group-selection-bar__element-count content-group-selection-bar__element-count--selected"
            {...getDataUiElementAttribute(DataUiElement.ContentGroupsInfoMessage)}
          >
            {pluralizeWithCount('element', props.selectedTypeElementCount)} selected in this content
            group
          </div>
          <div className="content-group-selection-bar__actions">
            <SendToContentGroupDropdown
              contentGroups={props.destinationContentGroups}
              onSendToContentGroup={props.onSendToContentGroup}
              onSendToNewContentGroup={() => setShowSendToNewGroupDialog(true)}
            />
          </div>
        </>
      )}
      {showSendToNewGroupDialog && (
        <ContentGroupNameDialog
          onConfirm={onSendToNewGroupConfirmed}
          onCancel={() => setShowSendToNewGroupDialog(false)}
          state={ContentGroupDialogState.New}
        />
      )}
    </div>
  );
};

ContentGroupSelectionBar.displayName = 'ContentGroupSelectionBar';
