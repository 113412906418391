import { useEffect } from 'react';
import { trackUserEvent } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { isWebSpotlightEnabled } from '../../../../webSpotlight/selectors/webSpotlightSelectors.ts';

export const ContentItemEditorTracker = () => {
  const dispatch = useDispatch();
  const isWebSpotlightActive = useSelector(isWebSpotlightEnabled);

  useEffect(() => {
    dispatch(
      trackUserEvent(TrackedEvent.ContentItemEditingOpened, {
        'is-web-spotlight-enabled': isWebSpotlightActive,
      }),
    );
  }, [isWebSpotlightActive]);

  return <noscript />;
};
