import { ReactNode, useEffect } from 'react';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import { HotjarTag, triggerRecording } from '../../../../_shared/utils/hotjarUtils.ts';
import { initRelations } from '../actions/thunkRelationsActions.ts';

type Props = {
  readonly children: ReactNode;
};

export const EnsureRelationsInitialized = (props: Props) => {
  const [isInitThunkDone] = useThunkPromise(initRelations);
  const isEnsured = useSelector((s) => isInitThunkDone && s.relationsApp.initialized);

  useEffect(() => {
    triggerRecording(HotjarTag.RelationsTabOpened);
  }, []);

  return isEnsured ? props.children : <Loader />;
};
