import { ShortcutsConfig, useHotkeys } from '@kontent-ai/component-library/hooks';
import { useAttachRef } from '@kontent-ai/hooks';
import classNames from 'classnames';
import React, { CSSProperties, forwardRef } from 'react';
import { ObjectWithDataAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';

interface IDropDownOptionListProps {
  readonly children: React.ReactNode;
  readonly className?: string;
  readonly longer?: boolean;
  readonly hasTrailingElements?: boolean;
  readonly optionListDataUiAttributes: ObjectWithDataAttribute;
  readonly onEscape?: () => void;
  readonly style?: CSSProperties;
  readonly role?: string;
}

export const DropDownOptionList = forwardRef<HTMLElement, IDropDownOptionListProps>(
  (props, ref) => {
    const { refObject, refToForward } = useAttachRef(ref);
    useHotkeys(
      {
        [ShortcutsConfig.Escape]: (e) => {
          e.stopPropagation();
          e.preventDefault();
          props.onEscape?.();
        },
      },
      { ref: refObject },
    );

    return (
      <div
        className={classNames(props.className, 'dropdown-options')}
        data-hj-suppress=""
        style={props.style}
        aria-label="option list"
        ref={refToForward}
      >
        <div
          className={classNames('dropdown-options__pane', {
            'dropdown-options__pane--longer': props.longer,
            'dropdown-options__pane--has-trailing-elements': props.hasTrailingElements,
          })}
          role={props.role}
          {...props.optionListDataUiAttributes}
        >
          {props.children}
        </div>
      </div>
    );
  },
);
