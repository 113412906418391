import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import React from 'react';
import { getUpperBoundedNumber } from '../../../../_shared/utils/getUpperBoundedNumber.ts';
import { maxNumberOfContentTypeUsage } from '../../shared/constants/uiConstants.ts';
import { UsageNumberStatus } from './UsageNumberStatus.tsx';

interface IUsageNumberStatusWithModalIcon {
  readonly usage: number;
  readonly onClick: () => void;
}

export const UsageNumberStatusWithModalIcon: React.FC<IUsageNumberStatusWithModalIcon> = ({
  usage,
  onClick,
}) =>
  usage <= 0 ? (
    <UsageNumberStatus usage={0} />
  ) : (
    <QuinaryButton
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      tooltipText="Show items using components of this type."
    >
      {getUpperBoundedNumber(usage, maxNumberOfContentTypeUsage)}
      <QuinaryButton.Icon icon={Icons.CollapseScheme} />
    </QuinaryButton>
  );
