import { Icons } from '@kontent-ai/component-library/Icons';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { Spacing, colorTextLowEmphasis } from '@kontent-ai/component-library/tokens';
import styled from 'styled-components';
import { useSelector } from '../../../../../../../../_shared/hooks/useSelector.ts';
import { getEditedContentItem } from '../../../../../../../../_shared/selectors/getEditedContentItem.ts';
import { ItemEditingForms } from '../../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { useSpaceData } from '../../../../../../../environmentSettings/spaces/hooks/useSpaceData.ts';
import {
  LimitedSpaceInfo,
  defaultAllSpacesPlaceholder,
  shouldCollapseSpaces,
} from '../../../../../../../environmentSettings/spaces/utils/spacesUtils.ts';
import { SpacesSectionEmptyState } from '../../../../components/details/Spaces/SpacesSectionEmptyState.tsx';
import { NonClickableSection } from '../../../../components/sidebar/sectionTypes/NonClickableSection.tsx';

const spaceNamesToDisplayCount = 2;

const getTooltipText = (spaces: ReadonlyArray<LimitedSpaceInfo>) =>
  `Spaces: ${spaces.map((space) => space.name).join(', ')}`;

const StyledName = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;  
`;

export const NonClickableSpacesSection = () => {
  const currentCollectionId = useSelector((s) => getEditedContentItem(s).collectionId);

  const spaceData = useSpaceData(currentCollectionId);
  const spacesToDisplay = spaceData.usedSpaces.slice(0, spaceNamesToDisplayCount);
  const showAllSpacesShortcut = shouldCollapseSpaces(spaceData);

  const showEllipsis =
    !showAllSpacesShortcut && spaceData.usedSpaces.length > spaceNamesToDisplayCount;
  const showTooltip = showEllipsis || showAllSpacesShortcut;

  return (
    <NonClickableSection dataUiNameAttribute={ItemEditingForms.Spaces}>
      <Tooltip
        visible={showTooltip ? undefined : false} // undefined makes the tooltip show on hover. false prevents that.
        placement="left-start"
        text={getTooltipText(spaceData.usedSpaces)}
      >
        <Stack spacing={Spacing.S}>
          {showAllSpacesShortcut ? (
            <StyledName>{defaultAllSpacesPlaceholder}</StyledName>
          ) : (
            spacesToDisplay.map((space) => (
              <StyledName key={space.id} title={space.name}>
                {space.name}
              </StyledName>
            ))
          )}
          {spacesToDisplay.length === 0 && <SpacesSectionEmptyState />}
          {showEllipsis && <Icons.Ellipsis color={colorTextLowEmphasis} />}
        </Stack>
      </Tooltip>
    </NonClickableSection>
  );
};

NonClickableSpacesSection.displayName = 'NonClickableSpacesSection';
