import React, { useCallback, useMemo } from 'react';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { getCurrentProject } from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { compose } from '../../../../_shared/utils/func/compose.ts';
import { getUserCapability } from '../../../../_shared/utils/permissions/capability.ts';
import { TypeElementType } from '../../../contentInventory/content/models/ContentItemElementType.ts';
import {
  hideTypeElementConfiguration,
  showTypeElementConfiguration,
} from '../../contentTypes/actions/contentTypesActions.ts';
import {
  moveTypeElement,
  typeElementDropped,
  typeElementPickedUp,
} from '../../shared/actions/sharedContentModelsActions.ts';
import {
  addNewTypeElement,
  insertNewTypeElementBefore,
  removeTypeElementWithDependencies,
  updateTypeElement,
} from '../../shared/actions/thunkSharedContentModelsActions.ts';
import { TypeElementList } from '../../shared/components/TypeElementList.tsx';
import { ContentTypeKind } from '../../shared/constants/contentTypeKind.ts';
import { OriginalTypeContextProvider } from '../../shared/contexts/OriginalTypeContext.tsx';
import { IBaseTypeElementData } from '../../shared/models/elements/types/TypeElementData.ts';
import { getElementIds } from '../../shared/selectors/typeEditorSelectors.ts';

export const AssetTypeElementList: React.FC = () => {
  const capability = useSelector(compose(getUserCapability, getCurrentProject));
  const isDragging = useSelector((state) => !!state.contentModelsApp.typeEditor.draggedElementId);
  const elementIds = useSelector(getElementIds);
  const firstIncompleteElementId = useSelector(
    (state) => state.contentModelsApp.typeEditor.firstInvalidElementId,
  );
  const lastAddedElementId = useSelector(
    (state) => state.contentModelsApp.typeEditor.lastAddedElementId,
  );
  const showConfigurationForElements = useSelector(
    (state) => state.contentModelsApp.contentTypes.editor.showConfigurationForElements,
  );
  const originalAssetTypeElements =
    useSelector((s) => s.data.assetTypes.defaultAssetType?.contentElements) ?? [];
  const dispatch = useDispatch();

  const addContentElement = useCallback((elementType: TypeElementType) => {
    dispatch(addNewTypeElement(elementType, { isNonLocalizable: true }));
  }, []);

  const insertContentElementBefore = useCallback(
    (elementType: TypeElementType, insertBeforeElement: IBaseTypeElementData) => {
      dispatch(
        insertNewTypeElementBefore(elementType, insertBeforeElement, { isNonLocalizable: true }),
      );
    },
    [],
  );

  // Callbacks are memoized to improve the performance while typing, otherwise all elements are re-rendered together with the whole list on each keystroke.
  const moveContentElement = useMemo(() => compose(dispatch, moveTypeElement), []);
  const onDragStart = useMemo(() => compose(dispatch, typeElementPickedUp), []);
  const onDragEnd = useMemo(() => compose(dispatch, typeElementDropped), []);
  const removeContentElement = useMemo(
    () => compose(dispatch, removeTypeElementWithDependencies),
    [],
  );
  const updateContentElement = useMemo(() => compose(dispatch, updateTypeElement), []);
  const onHideConfig = useMemo(() => compose(dispatch, hideTypeElementConfiguration), []);
  const onShowConfig = useMemo(() => compose(dispatch, showTypeElementConfiguration), []);

  return (
    <OriginalTypeContextProvider originalTypeElements={originalAssetTypeElements}>
      <TypeElementList
        addContentElement={addContentElement}
        capability={capability}
        contentTypeKind={ContentTypeKind.AssetType}
        elementIds={elementIds}
        firstIncompleteElementId={firstIncompleteElementId}
        insertContentElementBefore={insertContentElementBefore}
        isDragging={isDragging}
        lastAddedElementId={lastAddedElementId}
        moveContentElement={moveContentElement}
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
        removeContentElement={removeContentElement}
        updateContentElement={updateContentElement}
        showConfigurationForElements={showConfigurationForElements}
        onHideConfig={onHideConfig}
        onShowConfig={onShowConfig}
      />
    </OriginalTypeContextProvider>
  );
};

AssetTypeElementList.displayName = 'AssetTypeElementList';
