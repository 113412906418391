import React from 'react';
import { CreateAutoScrollId } from '../../../../../../_shared/components/AutoScroll/AutoScrollId.ts';
import { DisabledUsageInItemVariant } from '../../../../../../_shared/components/Usages/DisabledUsageInItemVariant.tsx';
import { EnabledUsageInItemVariant } from '../../../../../../_shared/components/Usages/EnabledUsageInItemVariant.tsx';
import { UsageItemReference } from '../../../../../../_shared/utils/usage/usageUtils.ts';
import { IContentItemVariantReference } from '../../../../models/contentItem/ContentItemVariantReference.ts';

type SingleContentItemUsageProps = Readonly<{
  buildLink: (variantReference: IContentItemVariantReference) => string;
  contentItemId: Uuid | undefined;
  disabledMessage: string | undefined;
  onNavigateToItem: () => void;
  typeName: string | null;
  usage: UsageItemReference;
}>;

export const SingleContentItemUsage: React.FC<SingleContentItemUsageProps> = ({
  buildLink,
  contentItemId,
  disabledMessage,
  onNavigateToItem,
  typeName,
  usage,
}) => {
  // Scroll to the first usage of this content item
  if (!disabledMessage && contentItemId) {
    const scrollId = CreateAutoScrollId.forUsageItem(usage.primary.id.itemId);
    const toScrollId = CreateAutoScrollId.forContentItem(contentItemId);

    return (
      <EnabledUsageInItemVariant
        buildLink={buildLink}
        onNavigateToItem={onNavigateToItem}
        scrollId={scrollId}
        toScrollId={toScrollId}
        typeName={typeName}
        usage={usage}
      />
    );
  }

  return (
    <DisabledUsageInItemVariant message={disabledMessage || ''} variantReference={usage.primary} />
  );
};
