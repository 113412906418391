import { isElementFullyVisible } from '@kontent-ai/DOM';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import classNames from 'classnames';
import React, { MouseEvent, ReactNode } from 'react';
import { TagColor } from '../../../data/constants/tagColor.ts';
import { IconName } from '../../constants/iconEnumGenerated.ts';
import { Icon } from '../../uiComponents/Icon/Icon.tsx';
import { getDataUiObjectNameAttribute } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { Indent } from '../Indent.tsx';
import { HighlightedOption } from './HighlightedOption.tsx';

export enum OptionType {
  Category = 'category',
  Item = 'item',
}

export enum OptionExpansionState {
  None = 'None',
  Expanded = 'Expanded',
  ExpandedDisabled = 'ExpandedDisabled',
  Collapsed = 'Collapsed',
}

interface IMultipleOptionSelectDropDownOptionDataProps<T> {
  readonly highlightedPattern: string;
  readonly isHighlighted: boolean;
  readonly isSelected: boolean;
  readonly option: T;
  readonly optionExpansionState?: OptionExpansionState;
  readonly showOptionWithWorkflowStepColorIndicator?: boolean;
}

interface IMultipleOptionSelectDropDownOptionCallbackProps<T> {
  readonly getOptionColor?: (option: T) => TagColor;
  readonly getOptionIndent?: (option: T) => number;
  readonly getOptionName: (option: T) => string;
  readonly getOptionTooltip?: (option: T) => string | undefined;
  readonly getOptionType?: (option: T) => OptionType;
  readonly getOptionId: (option: T) => string;
  readonly isOptionDisabled?: (option: T) => boolean;
  readonly onClick: (option: T) => void;
  readonly onHover: (option: T) => void;
  readonly onOptionCollapse?: (option: T) => void;
  readonly onOptionExpand?: (option: T) => void;
  readonly shouldSearchedOptionBeHighlighted?: (searchPhrase: string, option: T) => boolean;
}

export interface IMultipleOptionSelectDropDownOptionProps<T>
  extends IMultipleOptionSelectDropDownOptionDataProps<T>,
    IMultipleOptionSelectDropDownOptionCallbackProps<T> {}

export class MultipleOptionSelectDropDownOption<T> extends React.PureComponent<
  IMultipleOptionSelectDropDownOptionProps<T>
> {
  static displayName = 'MultipleSelectDropDownOption';

  private readonly optionRef = React.createRef<HTMLDivElement>();
  private readonly isCollapsibleDropDown: boolean;

  constructor(props: IMultipleOptionSelectDropDownOptionProps<T>) {
    super(props);

    this.isCollapsibleDropDown = !!props.onOptionCollapse && !!props.onOptionExpand;
  }

  componentDidUpdate(): void {
    if (this.props.isHighlighted) {
      const optionElement = this.optionRef.current;

      if (optionElement && !isElementFullyVisible(optionElement)) {
        optionElement.scrollIntoView({ block: 'nearest' });
      }
    }
  }

  private readonly _onClick = (event: MouseEvent<HTMLDivElement>): void => {
    event.stopPropagation();

    this.props.onClick(this.props.option);
  };

  private readonly _onHover = (): void => {
    this.props.onHover(this.props.option);
  };

  private readonly _onToggleExpand = (event: MouseEvent<HTMLDivElement>): void => {
    event.stopPropagation(); // onClick won't be fired

    switch (this.props.optionExpansionState) {
      case OptionExpansionState.Expanded: {
        this.props.onOptionCollapse?.(this.props.option);
        return;
      }

      case OptionExpansionState.Collapsed: {
        this.props.onOptionExpand?.(this.props.option);
        return;
      }

      default:
        return;
    }
  };

  private readonly _renderOptionName = (): ReactNode | ReactNode[] => {
    const optionName = this.props.getOptionName(this.props.option);
    const optionType = this.props.getOptionType?.(this.props.option);

    if (optionType === OptionType.Category) {
      return optionName;
    }

    const optionTooltip = this.props.getOptionTooltip?.(this.props.option);
    const shouldBeHighlighted =
      this.props.shouldSearchedOptionBeHighlighted?.(
        this.props.highlightedPattern,
        this.props.option,
      ) ?? true;

    return (
      <Tooltip text={optionTooltip} placement="top-start">
        <div className="multi-select__dropdown-option-name" title={optionName}>
          {shouldBeHighlighted ? (
            <HighlightedOption optionName={optionName} pattern={this.props.highlightedPattern} />
          ) : (
            optionName
          )}
        </div>
      </Tooltip>
    );
  };

  private readonly _renderCollapsibleOption = () => {
    const { optionExpansionState } = this.props;

    return (
      <div className="multi-select__collapsible-dropdown-option">
        {!optionExpansionState || optionExpansionState === OptionExpansionState.None ? (
          <div className="multi-select__dropdown-option-caret-placeholder" />
        ) : (
          <Tooltip
            text={
              optionExpansionState === OptionExpansionState.ExpandedDisabled
                ? 'You can’t hide filter items while searching'
                : undefined
            }
            placement="top-start"
          >
            <div
              className={classNames('multi-select__dropdown-option-caret', {
                'multi-select__dropdown-option-caret--is-disabled':
                  optionExpansionState === OptionExpansionState.ExpandedDisabled,
              })}
              onClick={this._onToggleExpand}
            >
              <Icon
                iconName={
                  optionExpansionState === OptionExpansionState.Expanded ||
                  optionExpansionState === OptionExpansionState.ExpandedDisabled
                    ? IconName.ChevronDown
                    : IconName.ChevronRight
                }
              />
            </div>
          </Tooltip>
        )}
        {this._renderOptionName()}
      </div>
    );
  };

  render(): JSX.Element {
    const {
      getOptionColor,
      getOptionIndent,
      getOptionName,
      getOptionType,
      isHighlighted,
      isOptionDisabled,
      isSelected,
      option,
      showOptionWithWorkflowStepColorIndicator,
    } = this.props;

    const isDisabled = isOptionDisabled?.(option);
    const optionName = getOptionName(option);
    const optionColor = getOptionColor?.(option);
    const type = getOptionType ? getOptionType(option) : OptionType.Item;
    const isCategory = type === OptionType.Category;
    const indent = getOptionIndent ? getOptionIndent(option) : 0;
    const isHighlightedOption = !isDisabled && !isCategory && isHighlighted;
    const showWorkflowStepColorIndicator = optionColor && showOptionWithWorkflowStepColorIndicator;

    return (
      <div
        className={classNames('multi-select__dropdown-option', {
          'multi-select__dropdown-option--is-category': isCategory,
          'multi-select__dropdown-option--is-selected': isSelected,
          'multi-select__dropdown-option--is-disabled': isDisabled,
          'multi-select__dropdown-option--is-highlighted': isHighlightedOption,
          [`multi-select__dropdown-option--${optionColor}`]: showWorkflowStepColorIndicator,
        })}
        {...getDataUiObjectNameAttribute(optionName)}
        onClick={isCategory ? undefined : this._onClick}
        onMouseOver={this._onHover}
        onFocus={this._onHover}
        ref={this.optionRef}
      >
        <Indent level={indent}>
          {this.isCollapsibleDropDown ? this._renderCollapsibleOption() : this._renderOptionName()}
        </Indent>
      </div>
    );
  }
}
