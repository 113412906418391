import { Box } from '@kontent-ai/component-library/Box';
import { ShortcutsConfig, useHotkeys } from '@kontent-ai/component-library/hooks';
import { preventDefault } from '@kontent-ai/utils';
import React, { useRef } from 'react';
import { IconName } from '../../../../../../../_shared/constants/iconEnumGenerated.ts';
import { Icon } from '../../../../../../../_shared/uiComponents/Icon/Icon.tsx';
import {
  DataUiAction,
  DataUiWorkflowAction,
  ItemEditingForms,
  getDataUiActionAttribute,
  getDataUiObjectNameAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { compose } from '../../../../../../../_shared/utils/func/compose.ts';

interface ISectionProps {
  readonly dataUiActionAttribute: DataUiWorkflowAction | DataUiAction;
  readonly dataUiNameAttribute: ItemEditingForms;
  readonly onEditClick: () => void;
}

export const ClickableSection: React.FC<React.PropsWithChildren<ISectionProps>> = ({
  children,
  dataUiActionAttribute,
  dataUiNameAttribute,
  onEditClick,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  useHotkeys(
    {
      [ShortcutsConfig.Enter]: compose(onEditClick, preventDefault),
      [ShortcutsConfig.Space]: compose(onEditClick, preventDefault),
    },
    { ref },
  );

  return (
    <div
      className="sidebar__section-item sidebar__section-item--is-clickable"
      onClick={onEditClick}
      ref={ref}
      role="button"
      tabIndex={0}
      {...getDataUiActionAttribute(dataUiActionAttribute)}
    >
      <Box width="100%" {...getDataUiObjectNameAttribute(dataUiNameAttribute || '')}>
        {children}
      </Box>
      <Icon iconName={IconName.Edit} className="content-item-sidebar__icon-edit" />
    </div>
  );
};

ClickableSection.displayName = 'ClickableSection';
