import { Button } from '@kontent-ai/component-library/Button';
import {
  FocusVisibilityInfoProvider,
  RootPortalContainerContextProvider,
} from '@kontent-ai/component-library/context';
import { RootPortalContainer } from '@kontent-ai/component-library/globals';
import { GlobalFonts } from '@kontent-ai/component-library/styles';
import { usePrevious } from '@kontent-ai/hooks';
import { OverlayProvider } from '@react-aria/overlays';
import { ReactNode, useEffect, useRef } from 'react';
import { DndProvider } from 'react-dnd';
import { useLocation } from 'react-router';
import { ActiveMenuContextProvider } from '../../applications/richText/plugins/inlineAi/instructions/ActiveMenuContext.tsx';
import { getHighestPlan } from '../../applications/subscriptionManagement/shared/utils/planUtils.ts';
import { UserState } from '../../data/models/user/CurrentUserInfo.ts';
import { trackUserEvent } from '../actions/thunks/trackUserEvent.ts';
import { ReturnAutoScrollHandler } from '../components/AutoScroll/ReturnAutoScrollHandler.tsx';
import { DisableIframeInteractionOnMouseDown } from '../components/DisableIframeInteractionOnMouseDown.tsx';
import { GoogleTagManager } from '../components/GoogleTagManager.tsx';
import { Header } from '../components/Header.tsx';
import { MainLayoutGrid } from '../components/MainLayoutGrid.tsx';
import { SkipToMainContent } from '../components/SkipToMainContent.tsx';
import { TrackedEvent } from '../constants/trackedEvent.ts';
import { AiMessageBufferProvider } from '../contexts/AiMessageBufferProvider.tsx';
import { AiTaskManagerProvider } from '../contexts/AiTaskManagerProvider.tsx';
import { LayoutSynchronizationContextProvider } from '../contexts/LayoutSynchronizationContext.tsx';
import { MainNavigationContextProvider } from '../contexts/MainNavigationContext.tsx';
import { TranslationTaskManagerProvider } from '../contexts/TranslationTaskManagerProvider.tsx';
import { useDispatch } from '../hooks/useDispatch.ts';
import { useIntercomBoot } from '../hooks/useIntercomBoot.ts';
import { useSelector } from '../hooks/useSelector.ts';
import { getCurrentUser } from '../selectors/getCurrentUser.ts';
import {
  getCurrentProjectPlan,
  getCurrentProjectSubscription,
} from '../selectors/userProjectsInfoSelectors.ts';
import { CustomDndBackend } from '../services/CustomDndBackend.ts';
import { goToLogin } from '../utils/authorization/authorization.ts';
import { trackCampaign } from '../utils/bootstrap.ts';
import { CardinalLocalTimeZoneId, LocalTimeZoneId } from '../utils/dateTime/timeZoneUtils.ts';
import { getEmailDomain } from '../utils/emailUtils.ts';
import { getIntercomUtils } from '../utils/intercomUtils.ts';
import { formatUserName } from '../utils/users/usersUtils.ts';
import { Amplitude } from './Amplitude.tsx';
import { ChurnZero } from './ChurnZero.tsx';
import { FeatureToggleProviders } from './FeatureToggleProviders.tsx';
import { MainLayoutGridAppView } from './MainLayoutGridAppView.tsx';
import { MainMenu } from './MainMenu/MainMenu.tsx';
import { ModalDialogSelector } from './ModalDialog/ModalDialogSelector.tsx';
import { OnboardingNotificationsSelector } from './OnboardingNotifications/OnboardingNotificationsSelector.tsx';
import { UiBlockingMessage } from './UiBlockingMessage.tsx';

type Props = {
  readonly children: ReactNode;
};

export const AppWrapper = (props: Props) => {
  useFireTrackEvents();
  useIntercom();
  const portalContainerRef = useRef<HTMLDivElement>(null);
  const isLoggedIn = useSelector((s) => s.sharedApp.isLoggedIn);
  if (!isLoggedIn) {
    return <YouHaveBeenSignedOut />;
  }

  return (
    <DndProvider backend={CustomDndBackend}>
      <ActiveMenuContextProvider>
        <FocusVisibilityInfoProvider>
          <OverlayProvider>
            <RootPortalContainerContextProvider
              portalContainerRef={portalContainerRef}
              overlayPortalContainerRef={portalContainerRef}
            >
              <LayoutSynchronizationContextProvider>
                <AiMessageBufferProvider>
                  <AiTaskManagerProvider>
                    <TranslationTaskManagerProvider>
                      <FeatureToggleProviders>
                        <MainNavigationContextProvider>
                          <MainLayoutGrid>
                            <SkipToMainContent />
                            <GlobalFonts />
                            <MainMenu />
                            <Header />
                            <MainLayoutGridAppView>
                              {props.children}
                              <OnboardingNotificationsSelector />
                              <UiBlockingMessage />
                              <ModalDialogSelector />
                              <ReturnAutoScrollHandler />
                              <DisableIframeInteractionOnMouseDown />
                            </MainLayoutGridAppView>
                            <Amplitude />
                            <GoogleTagManager />
                            <ChurnZero />
                          </MainLayoutGrid>
                        </MainNavigationContextProvider>
                      </FeatureToggleProviders>
                    </TranslationTaskManagerProvider>
                  </AiTaskManagerProvider>
                </AiMessageBufferProvider>
              </LayoutSynchronizationContextProvider>
            </RootPortalContainerContextProvider>
          </OverlayProvider>
          <RootPortalContainer ref={portalContainerRef} />
        </FocusVisibilityInfoProvider>
      </ActiveMenuContextProvider>
    </DndProvider>
  );
};

const YouHaveBeenSignedOut = () => {
  return (
    <div className="utility-message utility-message--centered sign-out">
      <div className="sign-out__logo-wrapper">
        <img
          src="/images/logotypes/kontent-ai-black.svg"
          alt="Kontent.ai"
          className="header__logo"
        />
      </div>
      <div className="sign-out__title">You have been signed out.</div>
      <Button buttonStyle="primary" onClick={goToLogin}>
        Sign in
      </Button>
    </div>
  );
};

const useIntercom = () => {
  const { pathname } = useLocation();
  const currentProjectPlan = useSelector(getCurrentProjectPlan);
  const highestPlan = useSelector((state) =>
    getHighestPlan(state.data.plans.byId, state.data.subscriptions.byId),
  );
  const currentProjectSubscription = useSelector(getCurrentProjectSubscription);
  const businessRole = useSelector((state) => state.sharedApp.userProperties.businessRole);
  const businessType = useSelector((state) => state.sharedApp.userProperties.businessType);
  const companyName = useSelector((state) => state.sharedApp.userProperties.companyName);
  const filledBusinessRole = useSelector(
    (state) => state.sharedApp.userProperties.filledBusinessRole,
  );
  const phoneNumber = useSelector((state) => state.sharedApp.userProperties.phoneNumber);
  const userInfo = useSelector(getCurrentUser);

  const areIntercomInteractionsDisabled = useSelector(
    (s) => s.sharedApp.userProperties.areIntercomInteractionsDisabled,
  );
  const intercomUtils = getIntercomUtils(areIntercomInteractionsDisabled);
  useIntercomBoot(intercomUtils, userInfo.userId, userInfo.userHash);

  const previousPathname = usePrevious(pathname);
  useEffect(() => {
    if (previousPathname !== pathname) {
      intercomUtils.pathUpdate();
    }
  }, [intercomUtils, pathname, previousPathname]);

  useEffect(() => {
    if (userInfo.state === UserState.Complete) {
      trackCampaign(userInfo.userId, intercomUtils.update);
    }
  }, [userInfo.state, userInfo.userId, intercomUtils.update]);

  useEffect(() => {
    if (areIntercomInteractionsDisabled) {
      // Intercom gets included statically via intercom.js file. This shuts it down, preventing
      // its default widget from displaying in the bottom right corner.
      getIntercomUtils(false).shutdown();
    }
  }, [areIntercomInteractionsDisabled]);

  useEffect(() => {
    intercomUtils.update({
      app_id: self._envConfig.intercomAppId,
      user_id: userInfo.userId,
      user_hash: userInfo.userHash,
      name: formatUserName(userInfo),
      email: userInfo.email,
      created_at: Math.floor(Date.parse(userInfo.createdAt) / 1000),
      sign_up_date: userInfo.createdAt,
      amplitude_link: `${self._envConfig.amplitudeSearchUrl}/${userInfo.userId}`,
      business_type: businessType,
      business_role: businessRole,
      provided_company_name: companyName,
      business_role_other: filledBusinessRole,
      'subscription-plan': currentProjectPlan.name,
      'subscription-plan__trial--started-at': currentProjectSubscription.currentPlan.startAt,
      phone: phoneNumber,
      highest_plan: highestPlan,
    });
  }, [
    businessRole,
    businessType,
    companyName,
    currentProjectPlan.name,
    currentProjectSubscription.currentPlan.startAt,
    filledBusinessRole,
    highestPlan,
    intercomUtils,
    phoneNumber,
    userInfo,
  ]);
};

const useFireTrackEvents = () => {
  const dispatch = useDispatch();

  const highestPlan = useSelector(
    (s) => getHighestPlan(s.data.plans.byId, s.data.subscriptions.byId) || undefined,
  );
  const emailDomain = useSelector((s) => getEmailDomain(s.data.user.info.email));
  const signUpDate = useSelector((s) => s.data.user.info.createdAt);
  const businessRole = useSelector((s) => s.sharedApp.userProperties.businessRole);
  const filledBusinessRole = useSelector((s) => s.sharedApp.userProperties.filledBusinessRole);
  const businessType = useSelector((s) => s.sharedApp.userProperties.businessType);
  const companyName = useSelector((s) => s.sharedApp.userProperties.companyName);

  useEffect(() => {
    dispatch(
      trackUserEvent(TrackedEvent.AppLoaded, {
        plan: highestPlan,
        'business-role': businessRole,
        'filled-business-role': filledBusinessRole,
        'business-type': businessType,
        'email-domain': emailDomain,
        'provided-company-name': companyName,
        'sign-up-date': signUpDate,
      }),
    );
  }, [
    highestPlan,
    businessRole,
    filledBusinessRole,
    businessType,
    emailDomain,
    companyName,
    signUpDate,
  ]);

  useEffect(() => {
    if (CardinalLocalTimeZoneId === null) {
      dispatch(
        trackUserEvent(TrackedEvent.ClientTimeZoneUnsupported, {
          localTimeZoneId: LocalTimeZoneId,
        }),
      );
    }
  }, []);
};
