import { useCallback } from 'react';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { SidebarNames } from '../../../../../../_shared/models/SidebarNames.ts';
import { getDiscussionsThreads } from '../../../../utils/commentUtils.ts';
import { discussionsSidebarClosed } from '../../actions/contentItemEditingActions.ts';
import { blurCommentThread } from '../../actions/thunkContentItemEditingActions.ts';
import { DiscussionsSidebar as DiscussionsSidebarComponent } from '../../components/comments/DiscussionsSidebar.tsx';
import { getCommentsOnRemovedContent } from '../../selectors/inlineCommentSelectors.ts';

export const DiscussionsSidebar = () => {
  const dispatch = useDispatch();

  const isOpened = useSelector((s) => {
    const { isVisible, sidebarName } = s.sharedApp.sidebarStatus;
    return isVisible && sidebarName === SidebarNames.DiscussionsSidebar;
  });

  const commentThreads = useSelector((s) =>
    getDiscussionsThreads(s.contentApp.editedContentItemVariantComments.commentThreads),
  );
  const commentsOnRemovedContent = useSelector(getCommentsOnRemovedContent);

  const isAnyCommentThreadFocused = useSelector((s) => {
    const focusedThreadId = s.contentApp.editedContentItemVariantComments.focusedCommentThreadId;

    return (
      commentThreads.some((thread) => thread.id === focusedThreadId) ||
      commentsOnRemovedContent.some((thread) => thread.id === focusedThreadId)
    );
  });

  const onClose = useCallback(() => {
    if (isAnyCommentThreadFocused) {
      dispatch(blurCommentThread());
    }
    dispatch(discussionsSidebarClosed());
  }, [isAnyCommentThreadFocused]);

  return (
    <DiscussionsSidebarComponent
      commentsOnRemovedContent={commentsOnRemovedContent}
      commentThreads={commentThreads}
      isAnyCommentThreadFocused={isAnyCommentThreadFocused}
      isOpened={isOpened}
      onClose={onClose}
    />
  );
};
