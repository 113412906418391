import { Button, IconButton } from '@kontent-ai/component-library/Button';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Menu } from '@kontent-ai/component-library/Menu';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { FC, RefObject } from 'react';
import { IconName } from '../../../../../../../_shared/constants/iconEnumGenerated.ts';
import {
  CancelScheduling,
  ChangeWorkflowStep,
  CreateNew,
  Delete,
  MoveToCollection,
  Publish,
  Restore,
  RestoreFromArchivedStep,
  Unpublish,
} from '../../../../../../../_shared/constants/itemActions.ts';
import { Icon } from '../../../../../../../_shared/uiComponents/Icon/Icon.tsx';
import {
  DataUiAction,
  DataUiCollection,
  DataUiWorkflowAction,
  getDataUiActionAttribute,
  getDataUiCollectionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { CancelBulkSchedulePublishConfirmationDialog } from '../../../../components/cancelConfirmation/CancelBulkSchedulePublishConfirmationDialog.tsx';
import { BulkMoveItemsToCollectionDialog } from '../../containers/BulkMoveItemsToCollectionDialog.tsx';
import { BulkMoveItemsToCollectionFailedItemsDialog } from '../../containers/BulkMoveItemsToCollectionFailedItemsDialog.tsx';

type DataTableAction = {
  readonly text: string;
  readonly onAction: () => void;
  readonly isDestructive: boolean;
  readonly disabledState: IDisabledState;
  readonly dataUiAction: DataUiAction | DataUiWorkflowAction;
};

export interface IDisabledState {
  readonly isDisabled: boolean;
  readonly disabledMessage?: string;
}

export enum MoveToCollectionDialogType {
  None = 'None',
  Move = 'Move',
  Fail = 'Fail',
}

export type ContentItemScrollTableActionsSharedProps = {
  readonly moveToCollectionDialogType: MoveToCollectionDialogType;
  readonly onMoveToCollectionDialogClose: () => void;
  readonly onMoveToCollectionDialogFail: () => void;
  readonly onMoveToCollectionDialogOpen: () => void;
};

type ContentItemScrollTableActionsProps = ContentItemScrollTableActionsSharedProps & {
  readonly areCollectionsVisible: boolean;
  readonly cancelDisabledState: IDisabledState;
  readonly canCreate: boolean;
  readonly changeWorkflowStepDisabledState: IDisabledState;
  readonly deleteDisabledState: IDisabledState;
  readonly isRemoveSchedulePublishConfirmationDialogOpen: boolean;
  readonly loadingOperationStatuses: boolean;
  readonly moveToCollectionDisabledState: IDisabledState;
  readonly publishDisabledState: IDisabledState;
  readonly restoreFromArchivedStepDisabledState: IDisabledState;
  readonly restoreToStepName: string;
  readonly showOtherActions: boolean;
  readonly unPublishDisabledState: IDisabledState;

  readonly checkOperations: () => void;
  readonly onCancelScheduleSelected: () => void;
  readonly onChangeWorkflowStepSelected: () => void;
  readonly onCreateNew: () => void;
  readonly onDeleteSelected: () => void;
  readonly onPublishSelected: () => void;
  readonly onRemoveSchedulePublishCancel: () => void;
  readonly onRemoveSchedulePublishConfirm: () => void;
  readonly onRestoreFromArchivedStep: () => void;
  readonly onUnpublishSelected: () => void;
};

const getMenuItemState = (isDisabled: boolean, isDestructive: boolean) => {
  if (isDisabled) {
    return 'disabled';
  }

  if (isDestructive) {
    return 'destructive';
  }

  return 'default';
};

export const ContentItemScrollTableActions: FC<ContentItemScrollTableActionsProps> = ({
  areCollectionsVisible,
  cancelDisabledState,
  canCreate,
  changeWorkflowStepDisabledState,
  checkOperations,
  deleteDisabledState,
  isRemoveSchedulePublishConfirmationDialogOpen,
  loadingOperationStatuses,
  moveToCollectionDialogType,
  moveToCollectionDisabledState,
  onCancelScheduleSelected,
  onChangeWorkflowStepSelected,
  onCreateNew,
  onDeleteSelected,
  onMoveToCollectionDialogClose,
  onMoveToCollectionDialogFail,
  onMoveToCollectionDialogOpen,
  onPublishSelected,
  onRemoveSchedulePublishCancel,
  onRemoveSchedulePublishConfirm,
  onRestoreFromArchivedStep,
  onUnpublishSelected,
  publishDisabledState,
  restoreFromArchivedStepDisabledState,
  restoreToStepName,
  showOtherActions,
  unPublishDisabledState,
}) => {
  const keyToPreventAnimation = showOtherActions ? 'secondary' : 'primary';

  const actions: ReadonlyArray<DataTableAction> = [
    {
      text: ChangeWorkflowStep,
      onAction: onChangeWorkflowStepSelected,
      disabledState: changeWorkflowStepDisabledState,
      isDestructive: false,
      dataUiAction: DataUiWorkflowAction.ChangeWorkflowStep,
    },
    {
      text: restoreFromArchivedStepDisabledState.isDisabled
        ? Restore
        : RestoreFromArchivedStep(restoreToStepName),
      onAction: onRestoreFromArchivedStep,
      disabledState: restoreFromArchivedStepDisabledState,
      isDestructive: false,
      dataUiAction: DataUiWorkflowAction.RestoreFromArchivedStep,
    },
    {
      text: Publish,
      onAction: onPublishSelected,
      disabledState: publishDisabledState,
      isDestructive: false,
      dataUiAction: DataUiWorkflowAction.Publish,
    },
    {
      text: Unpublish,
      onAction: onUnpublishSelected,
      disabledState: unPublishDisabledState,
      isDestructive: false,
      dataUiAction: DataUiWorkflowAction.Unpublish,
    },
    {
      text: CancelScheduling,
      onAction: onCancelScheduleSelected,
      disabledState: cancelDisabledState,
      isDestructive: false,
      dataUiAction: DataUiAction.Cancel,
    },
    ...(areCollectionsVisible
      ? [
          {
            text: MoveToCollection,
            onAction: onMoveToCollectionDialogOpen,
            disabledState: moveToCollectionDisabledState,
            isDestructive: false,
            dataUiAction: DataUiAction.MoveItemsToCollection,
          },
        ]
      : []),
    {
      text: Delete,
      onAction: onDeleteSelected,
      disabledState: deleteDisabledState,
      isDestructive: true,
      dataUiAction: DataUiAction.Delete,
    },
  ];

  return (
    <div className="scroll-table__actions">
      <Inline spacingX={Spacing.S}>
        {canCreate && (
          <Button
            buttonStyle={showOtherActions ? 'secondary' : 'primary'}
            key={keyToPreventAnimation}
            onClick={onCreateNew}
            {...getDataUiActionAttribute(DataUiAction.CreateNew)}
          >
            {CreateNew}
          </Button>
        )}
        {showOtherActions && (
          <Menu
            onOpenChange={(isOpen) => {
              if (isOpen) {
                checkOperations();
              }
            }}
          >
            <Menu.Trigger>
              {(ref: RefObject<HTMLButtonElement>, triggerProps, isOpen) => (
                <IconButton
                  activated={isOpen}
                  aria-label="Open more actions"
                  buttonStyle="primary"
                  iconName="ThreeDotsVertical"
                  ref={ref}
                  size="medium"
                  tooltipPlacement="top-end"
                  tooltipText={isOpen ? '' : 'More actions'}
                  {...triggerProps}
                  {...getDataUiActionAttribute(DataUiAction.MoreActions)}
                />
              )}
            </Menu.Trigger>
            <Menu.List {...getDataUiCollectionAttribute(DataUiCollection.MoreActionsDropdown)}>
              {actions.map((action: DataTableAction) => {
                const { isDisabled } = action.disabledState;
                return (
                  <Menu.Item
                    id={`action-${action.text}`}
                    key={action.text}
                    label={action.text}
                    aria-busy={loadingOperationStatuses}
                    onAction={() => {
                      if (!isDisabled) {
                        action.onAction();
                      }
                    }}
                    menuItemState={getMenuItemState(isDisabled, action.isDestructive)}
                    tooltipText={action.disabledState.disabledMessage}
                    tooltipPlacement="left-start"
                    trailingElements={
                      loadingOperationStatuses && (
                        <Icon
                          iconName={IconName.Spinner}
                          className="dropdown-option__icon dropdown-option__icon--loading"
                        />
                      )
                    }
                    {...getDataUiActionAttribute(action.dataUiAction as DataUiAction)}
                  />
                );
              })}
            </Menu.List>
          </Menu>
        )}
        <BulkMoveItemsToCollectionDialog
          isOpen={moveToCollectionDialogType === MoveToCollectionDialogType.Move}
          onClose={onMoveToCollectionDialogClose}
          onFail={onMoveToCollectionDialogFail}
        />
        <BulkMoveItemsToCollectionFailedItemsDialog
          isOpen={moveToCollectionDialogType === MoveToCollectionDialogType.Fail}
          onClose={onMoveToCollectionDialogClose}
        />
        <CancelBulkSchedulePublishConfirmationDialog
          isOpen={isRemoveSchedulePublishConfirmationDialogOpen}
          onCancel={onRemoveSchedulePublishCancel}
          onConfirm={onRemoveSchedulePublishConfirm}
        />
      </Inline>
    </div>
  );
};

ContentItemScrollTableActions.displayName = 'ContentItemScrollTableActions';
