import { trackUserEvent } from '../../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { isWebSpotlightEnabled } from '../../../../../../webSpotlight/selectors/webSpotlightSelectors.ts';
import { OpenInNewTabQuinaryButton as OpenInNewTabQuinaryButtonComponent } from '../components/OpenInNewTabQuinaryButton.tsx';
import { usePreviewSpace } from '../context/PreviewSpaceContext.tsx';
import { useWebSpotlightInItemEditing } from '../context/WebSpotlightInItemEditingContext.tsx';

type Props = Readonly<{
  isCompact: boolean;
}>;

export const OpenInNewTabQuinaryButton = ({ isCompact }: Props) => {
  const dispatch = useDispatch();
  const isWebSpotlightActive = useSelector(isWebSpotlightEnabled);
  const url = useWebSpotlightInItemEditing().previewMetadata.url;
  const { spaceId } = usePreviewSpace();

  if (!url) {
    return null;
  }

  return (
    <OpenInNewTabQuinaryButtonComponent
      isCompact={isCompact}
      trackButtonClick={() =>
        dispatch(
          trackUserEvent(TrackedEvent.SpacePreviewOpened, {
            'space-id': spaceId ?? '',
            'is-web-spotlight-enabled': isWebSpotlightActive,
          }),
        )
      }
      url={url}
    />
  );
};
