import { PredefinedRoles } from '../../../applications/environmentSettings/roles/constants/predefinedRoles.ts';
import { IUserProjectInfo } from '../../../data/models/user/UserProjectInfo.ts';
import { IUser } from '../../../data/reducers/user/IUser.type.ts';
import { DefaultCollectionId, DefaultVariantId } from '../../constants/variantIdValues.ts';
import { getCurrentOrStorageEnvironmentId } from '../getCurrentOrStorageEnvironmentId.ts';
import { getCurrentUserRoleForCollectionForLanguageForUser } from './getContributorRole.ts';

export const isProjectManager = (user: IUser, userProjectInfo: IUserProjectInfo): boolean =>
  isUserProjectManager(user, getCurrentOrStorageEnvironmentId(userProjectInfo.projectId));

export const isUserProjectManager = (user: IUser, projectId: Uuid): boolean =>
  // This is the reason why Innovation lab tab is not available when user opens Project settings as first page
  // Theoretically, we can check whether user is project manager by the capabilities, but by the default collection role
  getCurrentUserRoleForCollectionForLanguageForUser(
    user,
    projectId,
    DefaultCollectionId,
    DefaultVariantId,
  )?.codename === PredefinedRoles.ProjectManager;
