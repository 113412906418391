import { AvatarSize } from '@kontent-ai/component-library/Avatar';
import { Box } from '@kontent-ai/component-library/Box';
import { Button } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { BoxShadow } from '@kontent-ai/component-library/tokens';
import classNames from 'classnames';
import { useCallback } from 'react';
import { useLocation } from 'react-router';
import { UserAvatar } from '../../../../../../../_shared/components/users/UserAvatar.tsx';
import { IconName } from '../../../../../../../_shared/constants/iconEnumGenerated.ts';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { Icon } from '../../../../../../../_shared/uiComponents/Icon/Icon.tsx';
import {
  DataUiAction,
  DataUiElement,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { formatUserName } from '../../../../../../../_shared/utils/users/usersUtils.ts';
import { IProjectContributor } from '../../../../../../../data/models/users/ProjectContributor.ts';
import { loadListingItems } from '../../../../LoadedItems/actions/thunkLoadedItemsActions.ts';
import { startContentItemElementRefresh } from '../../../actions/thunkContentItemEditingActions.ts';

type Props = {
  readonly elementId: Uuid;
  readonly isOutdated: boolean;
  readonly isRefreshPending: boolean;
  readonly itemId: Uuid | null;
  readonly lockedByUser: IProjectContributor | null;
};

export const ItemElementSimultaneousEditingStatus = (props: Props) => {
  const { elementId, isOutdated, isRefreshPending, itemId, lockedByUser } = props;

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const refreshElement = useCallback(
    () =>
      Promise.all([
        dispatch(startContentItemElementRefresh({ elementId, pathname })),
        itemId ? dispatch(loadListingItems([itemId])) : Promise.resolve(),
      ]),
    [itemId, elementId, pathname],
  );

  const isLocked = !!lockedByUser;
  const isUnlockedOutdated = !isLocked && isOutdated;
  if (!isLocked && !isOutdated) {
    return null;
  }

  return (
    <div
      className={classNames('content-item-element__editing-status', {
        'content-item-element__editing-status--unlocked-outdated': isUnlockedOutdated,
      })}
    >
      {isLocked && (
        <>
          <UserAvatar user={lockedByUser} size={AvatarSize.S} boxShadow={BoxShadow.M} />
          <div className="content-item-element__editing-status-text-wrapper">
            <span className="content-item-element__editing-status-username">
              {formatUserName(lockedByUser)}
            </span>
            <span
              className={classNames('content-item-element__editing-status-text', {
                'content-item-element__editing-status-text--is-last': !isOutdated,
              })}
            >
              {' is editing this right now'}
            </span>
          </div>
        </>
      )}
      {isUnlockedOutdated && (
        <>
          <Icon className="content-item-element__editing-status-icon" iconName={IconName.ICircle} />
          <div className="content-item-element__editing-status-text">
            This element has been changed recently
          </div>
        </>
      )}
      {isOutdated && (
        <Box flexGrow={2}>
          <Button
            tooltipText={
              isRefreshPending
                ? 'Refreshing the element'
                : 'Refresh this element to see the latest changes'
            }
            tooltipPlacement="bottom-end"
            buttonStyle="tertiary"
            size="small"
            disabled={isRefreshPending}
            onClick={refreshElement}
            {...getDataUiActionAttribute(DataUiAction.Refresh)}
          >
            {isRefreshPending ? (
              <Button.ProgressIcon
                {...getDataUiElementAttribute(DataUiElement.SavingInProgressButtonIcon)}
              />
            ) : (
              <Button.Icon icon={Icons.RotateDoubleRight} />
            )}
            <Button.Label>Refresh</Button.Label>
          </Button>
        </Box>
      )}
    </div>
  );
};
