import React from 'react';
import { connect } from 'react-redux';
import { ChurnZeroComponent, IChurnZeroComponentDataProps } from '../components/ChurnZero.tsx';
import { getCurrentUser } from '../selectors/getCurrentUser.ts';
import { getCurrentProjectSubscription } from '../selectors/userProjectsInfoSelectors.ts';
import { IStore } from '../stores/IStore.type.ts';

const mapStateToProps = (state: IStore): IChurnZeroComponentDataProps => {
  const currentSubscription = getCurrentProjectSubscription(state);
  return {
    email: getCurrentUser(state).email,
    subscriptionId: currentSubscription.subscriptionId,
  };
};

const ChurnZeroContainer: React.ComponentType = connect(mapStateToProps)(ChurnZeroComponent);
export { ChurnZeroContainer as ChurnZero };
