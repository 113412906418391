import { Location } from 'history';
import {
  EnvironmentRouteParams,
  EnvironmentSettingsRoute,
  SubscriptionEnvironmentSettingsRoute,
  SubscriptionEnvironmentSettingsRouteParams,
} from '../../../../_shared/constants/routePaths.ts';
import { Breadcrumb } from '../../../../_shared/models/Breadcrumb.type.ts';
import { IBreadcrumbResolverInfo } from '../../../../_shared/utils/breadcrumbs/breadcrumbResolvers.ts';
import { buildPath, matchPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { EnvironmentSettingsAppNames } from '../../root/constants/EnvironmentSettingsAppNames.ts';

export const aiAcceleratorsRouteSegment = 'ai-accelerators';
export const AiAcceleratorsRoute = `${EnvironmentSettingsRoute}/${aiAcceleratorsRouteSegment}`;
export const SubscriptionEnvironmentAiAcceleratorsRoute = `${SubscriptionEnvironmentSettingsRoute}/${aiAcceleratorsRouteSegment}`;

const createAiAcceleratorBreadcrumbResolverInfo = (
  resolver: (location: Location) => Promise<ReadonlyArray<Breadcrumb>>,
): IBreadcrumbResolverInfo => ({
  pattern: new RegExp(`^${aiAcceleratorsRouteSegment}$`),
  resolver,
  childResolvers: [],
});

export const AiAcceleratorsBreadcrumbResolverInfo: IBreadcrumbResolverInfo =
  createAiAcceleratorBreadcrumbResolverInfo((location) => {
    const match = matchPath<EnvironmentRouteParams>(location.pathname, AiAcceleratorsRoute) ?? {};
    const breadcrumb = {
      title: EnvironmentSettingsAppNames.AiAccelerators,
      path: buildPath(AiAcceleratorsRoute, match),
      useProjectIdPrefix: false,
    };
    const breadcrumbs = [breadcrumb];

    return Promise.resolve(breadcrumbs);
  });

export const SubscriptionAiAcceleratorsBreadcrumbResolverInfo: IBreadcrumbResolverInfo =
  createAiAcceleratorBreadcrumbResolverInfo((location) => {
    const match =
      matchPath<SubscriptionEnvironmentSettingsRouteParams>(
        location.pathname,
        SubscriptionEnvironmentAiAcceleratorsRoute,
      ) ?? {};
    const breadcrumb = {
      title: EnvironmentSettingsAppNames.AiAccelerators,
      path: buildPath(SubscriptionEnvironmentAiAcceleratorsRoute, match),
      useProjectIdPrefix: false,
    };
    const breadcrumbs = [breadcrumb];

    return Promise.resolve(breadcrumbs);
  });
