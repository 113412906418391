import { useEffect } from 'react';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import {
  getSelectedSubscription,
  getSubscriptionPlan,
} from '../../../../_shared/selectors/subscriptionSelectors.ts';
import {
  allFiltersCleared,
  subscriptionUsersLeft,
} from '../actions/subscriptionUserListingActions.ts';
import { initSubscriptionUsers } from '../actions/thunkSubscriptionUsersActions.ts';
import { SubscriptionUsers as SubscriptionUsersComponent } from '../components/SubscriptionUsers.tsx';
import { SubscriptionCapabilitiesContextProvider } from '../context/SubscriptionCapabilitiesContext.tsx';
import { getSubscriptionProjectsAndEnvironments } from '../selectors/getSubscriptionProjectsAndEnvironments.ts';
import { SubscriptionUserListingConfig } from './SubscriptionUserListingConfig.tsx';

export const SubscriptionUsers = () => {
  const selectedSubscriptionId = useSelector(
    (state) => getSelectedSubscription(state)?.subscriptionId ?? '',
  );
  const subscriptionUsersUsage = useSelector((s) => s.data.subscriptions.subscriptionUsersUsage);
  const subscriptionProjectsAndEnvironments = useSelector(getSubscriptionProjectsAndEnvironments);
  const filter = useSelector((s) => s.subscriptionApp.users.listingUi.filter);
  const subscriptionPlan = useSelector((s) => getSubscriptionPlan(s, selectedSubscriptionId));

  useThunkPromise(initSubscriptionUsers);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(subscriptionUsersLeft());
    };
  }, []);

  const onClearFilter = () => {
    dispatch(allFiltersCleared());
  };

  return (
    <SubscriptionCapabilitiesContextProvider subscriptionPlan={subscriptionPlan}>
      <SubscriptionUserListingConfig />
      <SubscriptionUsersComponent
        filter={filter}
        onClearFilter={onClearFilter}
        subscriptionId={selectedSubscriptionId}
        subscriptionPlan={subscriptionPlan}
        subscriptionProjectsAndEnvironments={subscriptionProjectsAndEnvironments}
        subscriptionUsersUsage={subscriptionUsersUsage}
      />
    </SubscriptionCapabilitiesContextProvider>
  );
};
