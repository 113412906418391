import { Callout } from '@kontent-ai/component-library/Callout';
import React, { memo } from 'react';
import { IUrlSlugTypeElementData } from '../../../models/elements/UrlSlugTypeElementData.ts';
import { IBaseTypeElementData } from '../../../models/elements/types/TypeElementData.ts';
import {
  ITypeElementDataProps,
  ITypeElementOwnProps,
} from '../../../types/ITypeElementProps.type.ts';
import { UrlSlugTypeElementValidationResult } from '../../../utils/typeElementValidators/types/UrlSlugTypeElementValidationResult.type.ts';
import { TypeElementConfigurationCategory } from '../shared/TypeElementConfigurationCategory.tsx';
import { TypeElementConfigurationSection } from '../shared/TypeElementConfigurationSection.tsx';
import { TypeElementRegex } from '../shared/TypeElementRegex.tsx';
import { TypeElementWithTypedName } from '../shared/TypeElementWithTypedName.tsx';
import { DependentTextDropdown } from './DependentTextDropdown.tsx';

interface IUrlSlugTypeElementDataProps
  extends ITypeElementDataProps<IUrlSlugTypeElementData, UrlSlugTypeElementValidationResult> {
  readonly selectedOption?: IBaseTypeElementData;
  readonly autogenerateFromOptions: ReadonlyArray<IBaseTypeElementData>;
}

type UrlSlugTypeElementProps = IUrlSlugTypeElementDataProps &
  ITypeElementOwnProps<IUrlSlugTypeElementData, UrlSlugTypeElementValidationResult>;

const newHint =
  'The URL slug element specifies an SEO-friendly text that you can use in the URLs of your content items. The element generates its value based on the text element of your choice.';
const oldHint =
  'The URL slug element specifies an SEO-friendly text that you can use in the URLs of your content items. The element generates its value based on the text element of your choice or the content item name (deprecated).';

const UrlSlugTypeElement: React.FC<UrlSlugTypeElementProps> = (props: UrlSlugTypeElementProps) => {
  const { autogenerateFromOptions, disabled, onChange, typeElementData, selectedOption } = props;

  return (
    <TypeElementWithTypedName
      {...props}
      hintContent={typeElementData._canBeGeneratedFromDeprecatedItemName ? oldHint : newHint}
    >
      <TypeElementConfigurationCategory>
        <TypeElementConfigurationSection title="Auto-generate from">
          <DependentTextDropdown
            selectedOption={selectedOption}
            onSelect={(textElement) =>
              onChange({
                ...typeElementData,
                dependedOnTextElementId: textElement?.elementId ?? null,
              })
            }
            options={autogenerateFromOptions}
            disabled={!!disabled}
            defaultText="Select a text content element"
          />
        </TypeElementConfigurationSection>
      </TypeElementConfigurationCategory>

      {selectedOption?.isNonLocalizable && !typeElementData.isNonLocalizable && (
        <Callout calloutType="quickTip" headline="Auto-generated from a non-localizable element">
          <p>
            You set the URL slug to be localizable but to auto-generate from a non-localizable
            element:
          </p>
          <ul>
            <li>By default, the slug’s value will be the same across all the languages.</li>
            <li>You’ll be able to change the slug’s value in each language manually.</li>
          </ul>
        </Callout>
      )}

      {typeElementData.isNonLocalizable && (
        <Callout calloutType="quickTip" headline="Non-localizable URL slug">
          <p>
            You set the URL slug to be non-localizable. That means it’s going to have the same value
            across all the languages even though it’s generated from a localizable element.
          </p>
        </Callout>
      )}

      <TypeElementRegex {...props} showPredefinedRegexes={false} />
    </TypeElementWithTypedName>
  );
};

UrlSlugTypeElement.displayName = 'UrlSlugTypeElement';

const UrlSlugTypeElementMemo = memo(UrlSlugTypeElement);
export { UrlSlugTypeElementMemo as UrlSlugTypeElement };
