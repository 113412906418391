import { memoize } from '@kontent-ai/memoization';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../../@types/Dispatcher.type.ts';
import { trackUserEvent } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { IMultipleChoiceOptionData } from '../../../../../_shared/models/MultipleChoiceOption.type.ts';
import {
  CodenameEventType,
  CodenameTargetType,
} from '../../../../../_shared/models/TrackUserEventData.ts';
import { getOrderedOptions } from '../../../../../_shared/selectors/multipleChoiceOptions.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { IContentType } from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import {
  IMultipleChoiceTypeElementDispatchProps,
  IMultipleChoiceTypeElementOwnProps,
  IMultipleChoiceTypeElementStateProps,
  MultipleChoiceTypeElement as MultipleChoiceTypeElementComponent,
} from '../../../shared/components/typeElements/individualTypeElements/multipleChoice/MultipleChoiceTypeElement.tsx';
import { IMultipleChoiceTypeElementData } from '../../../shared/models/elements/MultipleChoiceTypeElementData.ts';
import { isMultipleChoiceTypeElement } from '../../../shared/types/typeElementTypeGuards.ts';
import { getMultipleChoiceOptionCodenames } from '../../../shared/utils/typeCodenameUtils.ts';
import {
  changeContentTypeMultipleChoiceOptionCodename,
  createMultipleChoiceOption,
  deleteMultipleChoiceOption,
  moveMultipleChoiceOption,
  multipleChoiceOptionDeselected,
  multipleChoiceOptionDropped,
  multipleChoiceOptionLabelChanged,
  multipleChoiceOptionPickedUp,
  multipleChoiceOptionSelected,
  updateMultipleChoiceOption,
} from '../../actions/contentTypesActions.ts';

const getOriginalTypeElementData = memoize.maxOne(
  (
    originalContentType: IContentType,
    elementId: Uuid,
  ): IMultipleChoiceTypeElementData | undefined =>
    originalContentType.typeElements
      .filter(isMultipleChoiceTypeElement)
      .find((element) => element.elementId === elementId),
);

const mapStateToProps = (
  {
    contentModelsApp: {
      contentTypes: {
        data: { originalContentType },
        editor,
      },
    },
  }: IStore,
  { typeElementData, validationResult }: IMultipleChoiceTypeElementOwnProps,
): IMultipleChoiceTypeElementStateProps => {
  return {
    allMultipleChoiceOptionCodenames: getMultipleChoiceOptionCodenames(typeElementData.options),
    editedMultipleChoiceOptionId: editor.editedMultipleChoiceElement.optionId,
    editedMultipleChoiceOptionLabel: editor.editedMultipleChoiceElement.optionLabel,
    isDragging: !!editor.draggedMultipleChoiceOptionId,
    originalTypeElementData: getOriginalTypeElementData(
      originalContentType,
      typeElementData.elementId,
    ),
    sortedMultipleChoiceOptions: getOrderedOptions(
      typeElementData.options,
      typeElementData.optionsOrder,
    ),
    typeElementData,
    validationResult,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IMultipleChoiceTypeElementDispatchProps => ({
  onCodenameSave: (codename: string, elementId: Uuid, optionId: Uuid, onSaved: () => void) => {
    dispatch(changeContentTypeMultipleChoiceOptionCodename(codename, elementId, optionId));
    onSaved();
  },
  onCodenameCopy: () =>
    dispatch(
      trackUserEvent(TrackedEvent.Codename, {
        type: CodenameEventType.Copy,
        target: CodenameTargetType.ContentElementOption,
      }),
    ),
  onCodenameEdit: () =>
    dispatch(
      trackUserEvent(TrackedEvent.Codename, {
        type: CodenameEventType.Edit,
        target: CodenameTargetType.ContentElementOption,
      }),
    ),
  createOption: (option: IMultipleChoiceOptionData, elementId: Uuid) =>
    dispatch(createMultipleChoiceOption(option, elementId)),
  updateOption: (option: IMultipleChoiceOptionData, elementId: Uuid) =>
    dispatch(updateMultipleChoiceOption(option, elementId)),
  deleteOption: (elementId: Uuid, optionId: Uuid) =>
    dispatch(deleteMultipleChoiceOption(optionId, elementId)),
  deselectEditedOption: () => dispatch(multipleChoiceOptionDeselected()),
  moveOption: (draggedOptionId: Uuid, targetOptionId: Uuid, elementId: Uuid) =>
    dispatch(moveMultipleChoiceOption(draggedOptionId, targetOptionId, elementId)),
  onPickUpMultipleChoiceOption: (optionId: Uuid) =>
    dispatch(multipleChoiceOptionPickedUp(optionId)),
  onDropMultipleChoiceOption: () => dispatch(multipleChoiceOptionDropped()),
  onLabelChange: (label: string) => dispatch(multipleChoiceOptionLabelChanged(label)),
  onSelect: (option: IMultipleChoiceOptionData, elementId: Uuid) =>
    dispatch(multipleChoiceOptionSelected(option, elementId)),
});

export const MultipleChoiceTypeElement: React.ComponentType<IMultipleChoiceTypeElementOwnProps> =
  connect(mapStateToProps, mapDispatchToProps)(MultipleChoiceTypeElementComponent);
