import { isAdminOnSubscription } from '../../../../_shared/selectors/subscriptionSelectors.ts';
import { getCurrentProjectContainer } from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';

export const isProjectGeneralSettingsTabVisible = (state: IStore): boolean => {
  const currentProject = getCurrentProjectContainer(state);
  const currentSubscriptionId = currentProject.subscriptionId;

  return isAdminOnSubscription(state, currentSubscriptionId);
};
