import {
  GridListMenu,
  VerticalMenuItem,
  useGridListMenuState,
} from '@kontent-ai/component-library/VerticalMenu';
import { ComponentLibraryGlobals } from '@kontent-ai/component-library/globals';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { SelectGroupRenderMode } from '../../../../../component-library/components/StatusBar/Selects/types/SelectGroupRenderMode.type.ts';
import { StatefulDropDown } from '../../../uiComponents/DropDown/StatefulDropDown.tsx';
import {
  DataUiAction,
  DataUiCollection,
  ObjectWithDataAttribute,
  getDataUiActionAttribute,
  getDataUiCollectionAttribute,
} from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { StatusBarDropDownSelected } from '../../StatusBar/StatusBarDropDownSelected.tsx';
import { optionMaxWidth, projectMenuTippyOptions } from '../constants.ts';
import { MenuItem } from '../types.type.ts';

export type SpaceMenuItem = MenuItem &
  VerticalMenuItem<SpaceMenuItem> & {
    readonly dataAttributes: ObjectWithDataAttribute;
    readonly isSelected: boolean;
    readonly rootItemId?: Uuid;
  };

type SpaceSelectProps = {
  readonly renderMode: SelectGroupRenderMode;
  readonly selectedSpaceName: string | null;
  readonly spaceItems: ReadonlyArray<SpaceMenuItem> | null;
};

export const SpaceSelect: React.FC<SpaceSelectProps> = ({
  spaceItems,
  selectedSpaceName,
  renderMode,
}) => {
  const history = useHistory();
  const { state, items } = useGridListMenuState([
    {
      id: 'spaces',
      items: spaceItems ?? [],
      label: 'Spaces',
      type: 'section',
    },
  ]);

  const [transitionFinished, setTransitionFinished] = useState(true);
  const handleTransitionEnd = () => {
    setTransitionFinished(true);
  };

  return (
    <StatefulDropDown
      optionListDataUiAttributes={getDataUiCollectionAttribute(DataUiCollection.Spaces)}
      onClose={() => setTransitionFinished(!!ComponentLibraryGlobals.skipAnimation)}
      renderContent={(closeSpacesList) =>
        transitionFinished && (
          <GridListMenu
            pinnedItemId="spaces-pinned"
            aria-label="Space select"
            state={state}
            items={items}
            maxWidth={optionMaxWidth}
            renderItem={({ item }) => (
              <GridListMenu.Item
                item={item}
                state={state}
                onPress={() => {
                  closeSpacesList();
                  if (item.value) {
                    item.value.onClick?.();
                    history.push(item.value.link);
                  }
                }}
              />
            )}
          />
        )
      }
      renderSelectedOption={(ref, toggleSpaceList, isOpen) => (
        <StatusBarDropDownSelected
          dataUiAttributes={getDataUiActionAttribute(DataUiAction.OpenSpacesMenu)}
          isExpanded={isOpen}
          onClick={toggleSpaceList}
          ref={ref}
          renderMode={renderMode}
          onChevronTransitionEnd={handleTransitionEnd}
        >
          {selectedSpaceName ?? ''}
        </StatusBarDropDownSelected>
      )}
      tippyOptions={projectMenuTippyOptions}
    />
  );
};

SpaceSelect.displayName = 'SpaceSelect';
