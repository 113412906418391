import { Menu } from '@kontent-ai/component-library/Menu';
import { FC } from 'react';
import { IconName } from '../../../../../../../_shared/constants/iconEnumGenerated.ts';
import { DropDownOption } from '../../../../../../../_shared/uiComponents/DropDown/DropDownOption.tsx';
import { DropDownOptionName } from '../../../../../../../_shared/uiComponents/DropDown/DropDownOptionName.tsx';
import { Icon } from '../../../../../../../_shared/uiComponents/Icon/Icon.tsx';
import { PreviewLink } from '../../../containers/selectors/selectPreviewLinks.ts';

type Props = {
  readonly previewLinks: ReadonlyArray<PreviewLink>;
  readonly onPreviewOpened: (spaceId: Uuid | null) => void;
  readonly isQuickActionsMenu?: boolean;
};

export const PreviewLinks: FC<Props> = ({ previewLinks, onPreviewOpened, isQuickActionsMenu }) =>
  previewLinks
    .filter(({ url }) => !!url)
    .map((link) =>
      isQuickActionsMenu ? (
        <DropDownOption
          isOutwardLink
          linkPath={link.url}
          key={link.spaceId ?? 'no-space'}
          onClick={() => onPreviewOpened(link.spaceId)}
        >
          <div className="actions-menu-item">
            <Icon iconName={IconName.ArrowRightTopSquare} className="actions-menu-item__icon" />
            <DropDownOptionName text={link.spaceName ?? 'No space'} />
          </div>
        </DropDownOption>
      ) : (
        <Menu.OutwardLink
          menuItemState="default"
          id={link.spaceId ?? 'no-space'}
          onAction={() => onPreviewOpened(link.spaceId)}
          key={link.spaceId ?? 'no-space'}
          href={link.url ?? ''}
          label={link.spaceName ?? 'No space'}
        />
      ),
    );
