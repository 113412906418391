import { getNormalizedRolesWithSettingsForUser } from '../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { getAllActiveLanguagesForCurrentUserInAnyCollection } from '../../../_shared/utils/languageUtils.ts';
import { Capability } from '../../../_shared/utils/permissions/capability.ts';
import { getAllApplicableRolesForLanguage } from '../../../_shared/utils/permissions/getContributorRole.ts';
import { IContentType } from '../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { ILanguagesData } from '../../../data/reducers/languages/ILanguagesData.type.ts';
import { IUser } from '../../../data/reducers/user/IUser.type.ts';
import { filterContentTypesByCapability } from '../../contentModels/shared/utils/typeUtils.ts';

export const getAllUniqueContentTypesWithViewCapability = (
  user: IUser,
  projectId: Uuid,
  allLanguages: ILanguagesData,
  allContentTypes: ReadonlyArray<IContentType>,
): ReadonlyArray<IContentType> => {
  const languagesWithViewCapability = getAllActiveLanguagesForCurrentUserInAnyCollection(
    user,
    projectId,
    allLanguages,
    Capability.ViewContent,
  );

  const userRoles = getNormalizedRolesWithSettingsForUser(user, projectId);
  const rolesInAllLanguages = languagesWithViewCapability
    .toArray()
    .flatMap((language) =>
      getAllApplicableRolesForLanguage(userRoles.collectionGroups, language.id),
    );

  const uniqueRolesInAllLanguages = new Set(rolesInAllLanguages);
  const uniqueAvailableContentTypes = new Set(
    [...uniqueRolesInAllLanguages].flatMap((role) =>
      filterContentTypesByCapability(allContentTypes, role.settings, Capability.ViewContent),
    ),
  );

  return [...uniqueAvailableContentTypes];
};
