import { colorDiffNegative, colorDiffPositive } from '@kontent-ai/component-library/tokens';
import { AssetTileReadonlyState } from './enums/assetTileReadonlyStateEnum.ts';

export const getBackgroundColorForDiffState = (readonlyState: AssetTileReadonlyState) => {
  switch (readonlyState) {
    case AssetTileReadonlyState.Default:
      return 'inherit';
    case AssetTileReadonlyState.Positive:
      return colorDiffPositive;
    case AssetTileReadonlyState.Negative:
      return colorDiffNegative;
  }
};
