import { memoize } from '@kontent-ai/memoization';
import React, { useCallback } from 'react';
import { IconName } from '../../../../_shared/constants/iconEnumGenerated.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { ITaxonomyGroups } from '../../../../data/reducers/taxonomyGroups/ITaxonomyGroups.type.ts';
import {
  ElementType,
  TypeElementType,
} from '../../../contentInventory/content/models/ContentItemElementType.ts';
import {
  typeElementDropped,
  typeElementPickedUp,
} from '../../shared/actions/sharedContentModelsActions.ts';
import { addNewTypeElement } from '../../shared/actions/thunkSharedContentModelsActions.ts';
import {
  TypeElementItemConfig,
  TypeElementLibrary as TypeElementLibraryComponent,
} from '../../shared/components/TypeElementLibrary.tsx';
import { NewElementId } from '../../shared/reducers/typeEditor/draggedElementId.ts';
import { elementTypeNameMap } from '../../shared/utils/typeElementsUtils.ts';

const getAuxiliaryElementsConfig = memoize.maxOne(
  (taxonomyGroups: ITaxonomyGroups): ReadonlyArray<TypeElementItemConfig> => {
    const taxonomyGroupExists = !taxonomyGroups.byId.isEmpty();

    const baseElements = [
      {
        name: elementTypeNameMap[ElementType.Taxonomy],
        elementType: ElementType.Taxonomy,
        iconName: IconName.Drawers,
        isDisabled: !taxonomyGroupExists,
        disabledMessage: 'Create at least one taxonomy group\nto use this element.',
      },
    ];

    return baseElements;
  },
);

export const AssetTypeElementLibrary: React.FC = () => {
  const dispatch = useDispatch();
  const taxonomyGroups = useSelector((state) => state.data.taxonomyGroups);

  const onContentElementClicked = useCallback((elementType: TypeElementType) => {
    dispatch(addNewTypeElement(elementType, { isNonLocalizable: true }));
  }, []);

  const onDragEnd = useCallback(() => dispatch(typeElementDropped()), []);
  const onDragStart = useCallback(() => dispatch(typeElementPickedUp(NewElementId)), []);

  return (
    <TypeElementLibraryComponent
      auxiliaryElementToolbarItemConfigurations={getAuxiliaryElementsConfig(taxonomyGroups)}
      onContentElementClicked={onContentElementClicked}
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}
    />
  );
};

AssetTypeElementLibrary.displayName = 'AssetTypeElementLibrary';
