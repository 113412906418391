import { ActiveCapabilityType } from '../../../../../../../_shared/models/activeCapability.type.ts';
import { getEditedContentItemVariant } from '../../../../../../../_shared/selectors/getEditedContentItemVariant.ts';
import { getWorkflow } from '../../../../../../../_shared/selectors/workflowSelectors.ts';
import { IStore } from '../../../../../../../_shared/stores/IStore.type.ts';
import {
  isArchivedWorkflowStepSelected,
  isPublishedWorkflowStep,
  isPublishingStepSelected,
} from '../../../../../../../_shared/utils/contentItemUtils.ts';
import {
  canChangeWorkflowForEditedItem,
  hasActiveVariantCapabilityForEditedItem,
} from '../../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { hasProjectMultipleWorkflows } from '../../../../../../../_shared/utils/workflow/hasProjectMultipleWorkflows.ts';
import { IListingContentItem } from '../../../../../../../data/models/listingContentItems/IListingContentItem.ts';
import {
  getSelectableWorkflowStepsForEditedItemDefaultVariant,
  getSelectableWorkflowStepsForEditedVariant,
} from '../../../../../../contentInventory/content/features/ContentItemInventory/selectors/getSelectableWorkflowSteps.ts';
import { getWorkflowsCurrentUserCanMoveEditedVariantTo } from '../../../../../selectors/workflows/getWorkflowsCurrentUserCanMoveEditedVariantTo.ts';

const canUpdateAssignment = (state: IStore) =>
  hasActiveVariantCapabilityForEditedItem(ActiveCapabilityType.UpdateAssignment, state);

const isVariantInEditableWorkflowStep = (state: IStore) => {
  const variant = getEditedContentItemVariant(state);
  return (
    !isPublishingStepSelected(variant?.assignment) &&
    !isArchivedWorkflowStepSelected(variant?.assignment)
  );
};

const isPublishStepAvailable = (state: IStore): boolean => {
  const variant = getEditedContentItemVariant(state);
  if (!variant) {
    return false;
  }

  const workflow = getWorkflow(state, variant.assignment.workflowStatus.workflowId);

  return getSelectableWorkflowStepsForEditedVariant(variant, workflow ?? null).some(
    isPublishedWorkflowStep,
  );
};

export const isPublishingStepAvailableForEditedItemDefaultVariant = (
  listingItemWithDefaultVariant: IListingContentItem | null,
  state: IStore,
): boolean => {
  if (!listingItemWithDefaultVariant?.variant) {
    return false;
  }

  const workflow = getWorkflow(
    state,
    listingItemWithDefaultVariant.variant.assignment.workflowStatus.workflowId,
  );
  return getSelectableWorkflowStepsForEditedItemDefaultVariant(
    listingItemWithDefaultVariant,
    workflow ?? null,
  ).some(isPublishedWorkflowStep);
};

export const isAssignPeopleAvailable = (state: IStore) => canUpdateAssignment(state);

export const isChangeDueDateAvailable = (state: IStore) => {
  const variant = getEditedContentItemVariant(state);
  return (
    isVariantInEditableWorkflowStep(state) &&
    canUpdateAssignment(state) &&
    !!variant?.assignment.due
  );
};

export const isSetDueDateAvailable = (state: IStore) => {
  const variant = getEditedContentItemVariant(state);
  return (
    isVariantInEditableWorkflowStep(state) && canUpdateAssignment(state) && !variant?.assignment.due
  );
};

export const isChangeNoteAvailable = (state: IStore) => {
  const variant = getEditedContentItemVariant(state);
  return canUpdateAssignment(state) && !!variant?.assignment.note;
};

export const isSetNoteAvailable = (state: IStore) => {
  const variant = getEditedContentItemVariant(state);
  return canUpdateAssignment(state) && !variant?.assignment.note;
};

export const isChangeWorkflowAvailable = (state: IStore) =>
  canChangeWorkflowForEditedItem(state) &&
  hasProjectMultipleWorkflows(state.data.workflows.byId) &&
  areThereAnyWorkflowsUserCanMoveVariantTo(state);

const areThereAnyWorkflowsUserCanMoveVariantTo = (state: IStore): boolean =>
  getWorkflowsCurrentUserCanMoveEditedVariantTo(state).length > 0;

export const isChangeWorkflowStepAvailable = (state: IStore) =>
  isVariantInEditableWorkflowStep(state) && canUpdateAssignment(state);

export const isPublishAvailable = (state: IStore) =>
  isVariantInEditableWorkflowStep(state) &&
  canUpdateAssignment(state) &&
  isPublishStepAvailable(state);
