import React from 'react';
import { Loader, LoaderType } from '../../../../_shared/components/Loader.tsx';
import {
  ContentItemRouteParams,
  ContentItemsAppRouteSegment,
} from '../../../../_shared/constants/routePaths.ts';
import { DefaultVariantId } from '../../../../_shared/constants/variantIdValues.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { getCurrentProjectId } from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { hasCapabilityInLanguage } from '../../../../_shared/utils/permissions/capabilitiesInLanguageUtils.ts';
import { Capability } from '../../../../_shared/utils/permissions/capability.ts';
import { getCannotViewItemMessage } from '../../../../_shared/utils/permissions/getCannotViewItemMessage.ts';
import {
  buildPath,
  pickContentItemRoute,
} from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { useLivePreviewPreferenceStorage } from '../../../../localStorages/useLivePreviewPreferenceStorage.ts';
import { getNewContentItemForm } from '../../../itemEditor/features/NewContentItem/selectors/newContentItemDialog.ts';
import { RootItemBar as RootItemBarComponent } from '../components/RootItemBar.tsx';

type Props = {
  readonly isLoading: boolean;
  readonly itemId: Uuid | null;
  readonly onUnlink: () => void;
};

export const RootItemBar: React.FC<Props> = ({ isLoading, itemId, onUnlink }) => {
  const currentProjectId = useSelector(getCurrentProjectId);
  const { isLivePreviewPreferred } = useLivePreviewPreferenceStorage(currentProjectId);

  const canViewDefaultLanguageItems = useSelector((s) =>
    hasCapabilityInLanguage(s, Capability.ViewContent, DefaultVariantId),
  );
  const contentItem = useSelector((s) =>
    itemId ? s.data.listingContentItems.byId.get(itemId) : null,
  );
  const contentItemName = useSelector(
    (s) => contentItem?.item.name ?? getNewContentItemForm(s).name,
  );
  const contentTypeName = useSelector(
    (s) =>
      s.data.contentTypes.byId.get(s.webSpotlightApp.configuration?.rootTypeId ?? '')?.name ?? '',
  );

  const cannotViewDisabledMessage = contentItem && getCannotViewItemMessage(contentItem);
  const isRedacted = !canViewDefaultLanguageItems || !!cannotViewDisabledMessage;

  const openInNewTabPath = useSelector(
    (s) =>
      itemId &&
      buildPath<ContentItemRouteParams<UuidArray>>(pickContentItemRoute(isLivePreviewPreferred), {
        app: ContentItemsAppRouteSegment.Content,
        contentItemIds: [itemId],
        projectId: s.sharedApp.currentProjectId,
        variantId: DefaultVariantId,
        spaceId: undefined,
      }),
  );

  if (isLoading) {
    return <Loader type={LoaderType.Narrow} />;
  }

  return (
    <RootItemBarComponent
      contentItemName={contentItemName}
      contentTypeName={contentTypeName}
      isRedacted={isRedacted}
      onUnlink={onUnlink}
      openInNewTabPath={openInNewTabPath}
    />
  );
};

RootItemBar.displayName = 'RootItemBarContainer';
