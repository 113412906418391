import { getSelfOrParent, isElement } from '@kontent-ai/DOM';
import { RequiredA11yLabellingProps } from '@kontent-ai/component-library/component-utils';
import { ShortcutsConfig, useHotkeys } from '@kontent-ai/component-library/hooks';
import { Direction } from '@kontent-ai/types';
import { getFocusableTreeWalker } from '@react-aria/focus';
import { useInteractOutside, useInteractionModality } from '@react-aria/interactions';
import { HTMLAttributes, RefObject } from 'react';
import { ElementAttributes } from '../../../../constants/elementAttributes.ts';

type Props = RequiredA11yLabellingProps &
  Readonly<{
    isDisabled?: boolean;
    onLeave: (returnFocus: boolean, isOutsideInteraction: boolean) => void;
  }>;

export const useAccessibleCommentThread = <TInstance extends HTMLElement>(
  { 'aria-label': ariaLabel, 'aria-labelledby': ariaLabelledby, isDisabled, onLeave }: Props,
  commentThreadRef: RefObject<TInstance>,
) => {
  const modality = useInteractionModality();

  useHotkeys(
    {
      [ShortcutsConfig.ShiftTab]: (e) => {
        moveFocus(e, commentThreadRef, Direction.Backward);
      },
      [ShortcutsConfig.Tab]: (e) => {
        moveFocus(e, commentThreadRef, Direction.Forward);
      },
      [ShortcutsConfig.Escape]: () => {
        onLeave?.(modality !== 'pointer', false);
      },
    },
    { ref: commentThreadRef, isDisabled },
  );

  useInteractOutside({
    ref: commentThreadRef,
    onInteractOutside: (event) => {
      if (!isElement(event.target)) {
        return;
      }

      const isBlurHandlingDisabled = getSelfOrParent(
        event.target,
        (element) => element.getAttribute(ElementAttributes.BlurCommentThreadOnClick) === 'false',
      );

      if (!isBlurHandlingDisabled) {
        onLeave(modality !== 'pointer', true);
      }
    },
    isDisabled,
  });

  const ariaProps = {
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledby,
    role: 'article',
    tabIndex: -1,
  } satisfies HTMLAttributes<HTMLElement>;

  return {
    commentThreadProps: ariaProps,
    onClose: onLeave,
  };
};

const moveFocus = <TInstance extends HTMLElement>(
  e: KeyboardEvent,
  commentThreadRef: RefObject<TInstance>,
  direction: Direction,
): void => {
  if (commentThreadRef.current) {
    const treeWalker = getFocusableTreeWalker(commentThreadRef.current);

    const firstFocusableNode = treeWalker.firstChild();
    treeWalker.currentNode = commentThreadRef.current;

    const lastFocusableNode = treeWalker.lastChild();
    treeWalker.currentNode = commentThreadRef.current;

    const lastNodeAtDirection =
      direction === Direction.Backward ? firstFocusableNode : lastFocusableNode;
    const nextNodeAtDirection =
      direction === Direction.Backward ? lastFocusableNode : firstFocusableNode;

    const isFirstFocusableNodeCurrentlyFocused = lastNodeAtDirection?.isSameNode(
      document.activeElement,
    );
    if (isFirstFocusableNodeCurrentlyFocused) {
      e.preventDefault();

      if (isElement(nextNodeAtDirection)) {
        nextNodeAtDirection.focus();
      }
      return;
    }

    const isCommentThreadCurrentlyFocused = document.activeElement === commentThreadRef.current;
    if (isCommentThreadCurrentlyFocused) {
      e.preventDefault();

      if (isElement(nextNodeAtDirection)) {
        nextNodeAtDirection.focus();
      }
    }
  }
};
