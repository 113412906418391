import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import classNames from 'classnames';
import React from 'react';
import { IconName } from '../../../../../../_shared/constants/iconEnumGenerated.ts';
import { Icon } from '../../../../../../_shared/uiComponents/Icon/Icon.tsx';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface ITypeElementConfigurationSectionProps {
  readonly title?: string;
  readonly className?: string;
  readonly contentWidth?: 'small' | 'medium' | 'wide';
  readonly labelFor?: string;
  readonly tooltip?: string;
  readonly uiElement?: DataUiElement;
}

export const TypeElementConfigurationSection: React.FC<
  React.PropsWithChildren<ITypeElementConfigurationSectionProps>
> = ({ children, className, contentWidth = 'small', labelFor, title, tooltip, uiElement }) => (
  <div
    className={classNames(className, 'content-element__config-section', {
      'col-xs-24 col-sm-24 col-md-12 col-lg-8': contentWidth === 'small',
      'col-xs-24 col-sm-24 col-md-24 col-lg-12': contentWidth === 'medium',
      'col-xs-24 col-sm-24 col-md-24 col-lg-24': contentWidth === 'wide',
    })}
    {...getDataUiElementAttribute(DataUiElement.ConfigSection)}
  >
    {title && (
      <div
        className={classNames('content-element__config-section-title', {
          'content-element__config-section-title--with-tooltip': !!tooltip,
        })}
      >
        <label className="content-element__config-section-title-label" htmlFor={labelFor}>
          {title}
        </label>
        {tooltip && (
          <Tooltip placement="top" text={tooltip}>
            <div className="content-element__config-section-title-tooltip">
              <Icon iconName={IconName.QuestionCircle} />
            </div>
          </Tooltip>
        )}
      </div>
    )}
    <div
      className="content-element__config-section-control"
      {...(uiElement ? getDataUiElementAttribute(uiElement) : null)}
    >
      {children}
    </div>
  </div>
);

TypeElementConfigurationSection.displayName = 'TypeElementConfigurationSection';
