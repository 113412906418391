import React from 'react';
import { useDataSelector } from '../../../../../../_shared/hooks/useDataSelector.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { getCurrentProjectPlan } from '../../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { Capability } from '../../../../../../_shared/utils/permissions/capability.ts';
import { hasProjectMultipleWorkflows } from '../../../../../../_shared/utils/workflow/hasProjectMultipleWorkflows.ts';
import { ICollection } from '../../../../../../data/models/collections/Collection.ts';
import { areSpacesInCollectionsEnabled } from '../../../../../environmentSettings/selectors/allowedFeaturesSelectors.ts';
import { areSpacesEnabledForCurrentProject } from '../../../../../environmentSettings/utils/allowedFeaturesUtils.ts';
import { moveSelectedContentItemsToCollection } from '../actions/thunkContentItemInventoryActions.ts';
import { BulkMoveToCollectionDialog } from '../components/BulkMoveToCollectionDialog.tsx';
import {
  useAvailableCollections,
  useAvailableCollectionsForSelectedLanguage,
} from '../selectors/useAvailableCollections.ts';

type Props = {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly onFail: () => void;
};

export const BulkMoveItemsToCollectionDialog: React.FC<Props> = ({ isOpen, onClose, onFail }) => {
  const collectionsUserCanView = useAvailableCollections(Capability.ViewContent);
  const collectionsUserCanEdit = useAvailableCollectionsForSelectedLanguage(
    Capability.UpdateContent,
  );
  const showWorkflowWarning = useDataSelector((state) =>
    hasProjectMultipleWorkflows(state.workflows.byId),
  );
  const spacesMap = useSelector((s) => s.data.spaces.byId);
  const areSpacesEnabled = useSelector(
    (s) =>
      areSpacesEnabledForCurrentProject(getCurrentProjectPlan(s)) &&
      areSpacesInCollectionsEnabled(s),
  );
  const getCollectionDisabledMessage = (collection: ICollection): string | undefined =>
    collectionsUserCanEdit.some((c) => c.id === collection.id)
      ? undefined
      : 'Your role can’t edit items in this collection.';

  const dispatch = useDispatch();
  const moveItems = (collectionId: Uuid) =>
    dispatch(moveSelectedContentItemsToCollection(collectionId, onFail));

  return (
    <BulkMoveToCollectionDialog
      getCollectionDisabledMessage={getCollectionDisabledMessage}
      isOpen={isOpen}
      onClose={onClose}
      onMove={moveItems}
      options={collectionsUserCanView}
      showWorkflowWarning={showWorkflowWarning}
      spacesMap={areSpacesEnabled ? spacesMap : null}
    />
  );
};

BulkMoveItemsToCollectionDialog.displayName = 'BulkMoveItemsToCollectionDialog';
