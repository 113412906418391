import React, { useMemo } from 'react';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useUndoKeyPress } from '../../../../_shared/hooks/useUndoKeyPress.tsx';
import { getCurrentProject } from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { compose } from '../../../../_shared/utils/func/compose.ts';
import { getUserCapability } from '../../../../_shared/utils/permissions/capability.ts';
import {
  EmptyContentTypeUsage,
  IContentTypeUsage,
} from '../../../../data/models/contentModelsApp/contentTypes/ContentTypeUsage.ts';
import {
  focusElementMovedToPreviousPosition,
  moveTypeElement,
  typeElementDropped,
  typeElementPickedUp,
} from '../../shared/actions/sharedContentModelsActions.ts';
import {
  addNewTypeElement,
  insertNewTypeElementBefore,
  removeTypeElementWithDependencies,
  updateTypeElement,
} from '../../shared/actions/thunkSharedContentModelsActions.ts';
import { TypeElementList } from '../../shared/components/TypeElementList.tsx';
import { ContentTypeKind } from '../../shared/constants/contentTypeKind.ts';
import { OriginalTypeContextProvider } from '../../shared/contexts/OriginalTypeContext.tsx';
import { getElementIds } from '../../shared/selectors/typeEditorSelectors.ts';
import { LastActionType } from '../../shared/types/LastActionTypes.ts';
import {
  hideTypeElementConfiguration,
  showTypeElementConfiguration,
} from '../actions/contentTypesActions.ts';

const getNumbersOfItemsUsedIn = (state: IStore): IContentTypeUsage => {
  const { editedType } = state.contentModelsApp.typeEditor;
  const { contentTypeUsages } = state.data;

  return contentTypeUsages.byId.get(editedType.id) || EmptyContentTypeUsage;
};

const ContentTypeElementListContainer: React.FC = () => {
  const capability = useSelector(compose(getUserCapability, getCurrentProject));
  const isDragging = useSelector((state) => !!state.contentModelsApp.typeEditor.draggedElementId);
  const elementIds = useSelector(getElementIds);
  const firstIncompleteElementId = useSelector(
    (state) => state.contentModelsApp.typeEditor.firstInvalidElementId,
  );
  const lastAction = useSelector((state) => state.contentModelsApp.typeEditor.lastAction);
  const lastAddedElementId = useSelector(
    (state) => state.contentModelsApp.typeEditor.lastAddedElementId,
  );
  const lastMovedElementId = useSelector(
    (state) => state.contentModelsApp.typeEditor.lastMovedElementId,
  );
  const isInGroup = useSelector(
    (state) => !!state.contentModelsApp.contentTypes.editor.selectedContentGroupId,
  );
  const numbersOfItemsUsedIn = useSelector(getNumbersOfItemsUsedIn);
  const showConfigurationForElements = useSelector(
    (state) => state.contentModelsApp.contentTypes.editor.showConfigurationForElements,
  );
  const originalTypeElements =
    useSelector(
      (s) =>
        s.data.contentTypes.byId.get(s.contentModelsApp.typeEditor.editedType.id)?.typeElements,
    ) ?? [];

  const dispatch = useDispatch();
  // Callbacks are memoized to improve the performance while typing, otherwise all elements are re-rendered together with the whole list on each keystroke.
  const addContentElement = useMemo(() => compose(dispatch, addNewTypeElement), []);
  const insertContentElementBefore = useMemo(
    () => compose(dispatch, insertNewTypeElementBefore),
    [],
  );
  const moveContentElement = useMemo(() => compose(dispatch, moveTypeElement), []);
  const onDragStart = useMemo(() => compose(dispatch, typeElementPickedUp), []);
  const onDragEnd = useMemo(() => compose(dispatch, typeElementDropped), []);
  const onHideConfig = useMemo(() => compose(dispatch, hideTypeElementConfiguration), []);
  const onShowConfig = useMemo(() => compose(dispatch, showTypeElementConfiguration), []);
  const removeContentElement = useMemo(
    () => compose(dispatch, removeTypeElementWithDependencies),
    [],
  );
  const updateContentElement = useMemo(() => compose(dispatch, updateTypeElement), []);
  const focusContentElementMovedToPreviousPosition = useMemo(
    () => compose(dispatch, focusElementMovedToPreviousPosition),
    [],
  );

  const onUndoPress = () => {
    if (lastAction === null) {
      return;
    }

    switch (lastAction.type) {
      case LastActionType.ElementAddition: {
        removeContentElement(lastAction.id);
        break;
      }

      case LastActionType.ElementMovement: {
        moveContentElement(lastAction.source, lastAction.target);
        focusContentElementMovedToPreviousPosition(lastAction.source);
        break;
      }
    }
  };

  useUndoKeyPress(onUndoPress, lastAction);

  return (
    <OriginalTypeContextProvider originalTypeElements={originalTypeElements}>
      <TypeElementList
        addContentElement={addContentElement}
        capability={capability}
        contentTypeKind={ContentTypeKind.ContentType}
        elementIds={elementIds}
        firstIncompleteElementId={firstIncompleteElementId}
        insertContentElementBefore={insertContentElementBefore}
        isDragging={isDragging}
        isInGroup={isInGroup}
        lastAddedElementId={lastAddedElementId}
        lastMovedElementId={lastMovedElementId}
        moveContentElement={moveContentElement}
        numbersOfItemsUsedIn={numbersOfItemsUsedIn}
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
        onHideConfig={onHideConfig}
        onShowConfig={onShowConfig}
        removeContentElement={removeContentElement}
        showConfigurationForElements={showConfigurationForElements}
        updateContentElement={updateContentElement}
      />
    </OriginalTypeContextProvider>
  );
};

ContentTypeElementListContainer.displayName = 'ContentTypeElementListContainer';

export { ContentTypeElementListContainer as ContentTypeElementList };
