import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { useHover } from '@react-aria/interactions';
import classNames from 'classnames';
import React, { ReactNode, useEffect, useState } from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ResponsiveImageExtensionsMessage } from '../../../../../../constants/responsiveImageSupportedTypes.ts';

export enum ItemElementLimitType {
  AssetCount = 'AssetCount',
  ModularItemCount = 'ModularItemCount',
  TaxonomyItemCount = 'TaxonomyItemCount',
  ImageHeight = 'ImageHeight',
  ImageWidth = 'ImageWidth',
  WordCount = 'Word',
  CharacterCount = 'CharacterCount',
  ResponsiveImagesOnly = 'ResponsiveImagesOnly',
}

interface IItemCountLimitStatusMessageProps {
  readonly id?: string;
  readonly isLimitValueMet: boolean;
  readonly min?: number | null;
  readonly max?: number | null;
  readonly type: ItemElementLimitType;
  readonly currentValue?: number;
  readonly getTooltipText?: () => string;
  readonly isTooltipEnabled?: boolean;
}

const getMinMaxExactMessage = (
  min: number | null | undefined,
  max: number | null | undefined,
  dimensionName: string,
): string => {
  if (min && max && min === max) {
    return `${dimensionName} exactly `;
  }
  if (min) {
    return `min. ${dimensionName} `;
  }
  if (max) {
    return `max. ${dimensionName} `;
  }
  return '';
};

const _getIntroPart = (
  min: number | null | undefined,
  max: number | null | undefined,
  type: ItemElementLimitType,
): string => {
  switch (type) {
    case ItemElementLimitType.ImageWidth:
      return getMinMaxExactMessage(min, max, 'width');
    case ItemElementLimitType.ImageHeight:
      return getMinMaxExactMessage(min, max, 'height');
    default:
      return '';
  }
};

const _getWarningPart = (
  min: number | null | undefined,
  max: number | null | undefined,
  currentValue: number | undefined,
  type: ItemElementLimitType,
): ReactNode => {
  switch (type) {
    case ItemElementLimitType.ImageWidth:
    case ItemElementLimitType.ImageHeight: {
      const limit = min || max;
      if (limit) {
        return <>{limit}&nbsp;px</>;
      }
      break;
    }
    case ItemElementLimitType.ResponsiveImagesOnly:
      return 'adjustable images only';
    case ItemElementLimitType.WordCount:
    case ItemElementLimitType.CharacterCount:
      return currentValue ? currentValue.toString() : '0';
    default: {
      if (min && max && min === max) {
        return `exactly ${min}`;
      }
      if (min) {
        return `min. ${min}`;
      }
      if (max) {
        return `max. ${max}`;
      }
    }
  }
  return '';
};

const _getFromPart = (max: number | null | undefined, type: ItemElementLimitType): string => {
  switch (type) {
    case ItemElementLimitType.WordCount:
    case ItemElementLimitType.CharacterCount:
      return max || max === 0 ? `/${max}` : '';
    default:
      return '';
  }
};

const _getEndPart = (isOne: boolean, type: ItemElementLimitType): string => {
  switch (type) {
    case ItemElementLimitType.ResponsiveImagesOnly: {
      return ` (${ResponsiveImageExtensionsMessage})`;
    }
    case ItemElementLimitType.ModularItemCount:
      return isOne ? ' item' : ' items';
    case ItemElementLimitType.WordCount:
      return isOne ? ' word' : ' words';
    case ItemElementLimitType.CharacterCount:
      return isOne ? ' character' : ' characters';
    case ItemElementLimitType.AssetCount:
      return isOne ? ' asset' : ' assets';
    case ItemElementLimitType.TaxonomyItemCount:
      return isOne ? ' term' : ' terms';
    default:
      return '';
  }
};

export const ItemLimitStatusMessage: React.FC<IItemCountLimitStatusMessageProps> = ({
  id,
  min,
  max,
  isLimitValueMet,
  isTooltipEnabled,
  type,
  currentValue,
  getTooltipText,
}) => {
  const [tooltipText, setTooltipText] = useState<string | null>(null);
  const { hoverProps, isHovered } = useHover({});

  useEffect(() => {
    if (isTooltipEnabled && isHovered && getTooltipText) {
      setTooltipText(getTooltipText());

      return () => setTooltipText(null);
    }

    return undefined;
  }, [getTooltipText, isHovered, isTooltipEnabled]);

  const limitValue = min || max;

  const innerComponents = (
    <span className="content-item-element__status-item" {...hoverProps} id={id}>
      {_getIntroPart(min, max, type)}
      <span
        className={classNames('content-item-element__status-fragment', {
          'content-item-element__status-fragment--is-invalid': !isLimitValueMet,
        })}
        {...getDataUiElementAttribute(DataUiElement.ElementStatusPane)}
      >
        {_getWarningPart(min, max, currentValue, type)}
      </span>
      {_getFromPart(max, type) + _getEndPart(limitValue === 1, type)}
    </span>
  );

  if (isTooltipEnabled) {
    return (
      <Tooltip text={tooltipText} placement="top-end" visible={isHovered}>
        {innerComponents}
      </Tooltip>
    );
  }

  return innerComponents;
};

ItemLimitStatusMessage.displayName = 'ItemLimitStatusMessage';
