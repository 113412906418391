import { Box } from '@kontent-ai/component-library/Box';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, gridUnit } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { FilterButton } from '../../../../_shared/components/FilterButton.tsx';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useIsRefinedNavigationFeatureEnabled } from '../../../refinedNavigation/contexts/RefinedNavigationContext.tsx';
import { FullTextSearchStatus } from '../../shared/reducers/IContentInventoryStoreState.type.ts';
import { ContentItemsTextFilter } from '../containers/ContentItemTextFilter.tsx';
import { ContentItemsSearchScope } from '../containers/ContentItemsSearchScope.tsx';
import { FilterInconsistentWithSemanticSearchWarning } from '../containers/FilterInconsistentWithSemanticSearchWarning.tsx';

type Props = {
  readonly isInDialog: boolean;
  readonly isFullTextSearchForceDisabled?: boolean;
  readonly onToggleFilter: () => void;
  readonly isFilterActive: boolean;
};

export const ContentItemsSearchArea: React.FC<Props> = ({
  isInDialog,
  isFullTextSearchForceDisabled,
  isFilterActive,
  onToggleFilter,
}) => {
  const fullTextSearchStatusState = useSelector(
    (state) => state.contentInventory.fullTextSearchStatus,
  );
  const fullTextSearchStatus = isFullTextSearchForceDisabled
    ? FullTextSearchStatus.Disabled
    : fullTextSearchStatusState;
  const isRefinedNavigationFeatureEnabled = useIsRefinedNavigationFeatureEnabled();

  return (
    <Box marginBottom={Spacing.L}>
      <Stack spacing={Spacing.XL}>
        <FilterInconsistentWithSemanticSearchWarning />
        <Inline spacing={Spacing.S}>
          {isRefinedNavigationFeatureEnabled && (
            <FilterButton isActive={isFilterActive} onPress={onToggleFilter} />
          )}
          <Box minWidth={38 * gridUnit} flexGrow={1}>
            <ContentItemsTextFilter
              isInDialog={isInDialog}
              fullTextSearchStatus={fullTextSearchStatus}
              allowAiSearch={!isFullTextSearchForceDisabled}
            />
          </Box>
          {fullTextSearchStatus !== FullTextSearchStatus.Disabled && (
            <Box width={260} minWidth={25 * gridUnit}>
              <ContentItemsSearchScope fullTextSearchStatus={fullTextSearchStatus} />
            </Box>
          )}
        </Inline>
      </Stack>
    </Box>
  );
};
