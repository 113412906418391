import { memoize } from '@kontent-ai/memoization';
import { assert, areMembersEqual } from '@kontent-ai/utils';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { shallowEqual } from '../../../../../_shared/utils/shallowEqual.ts';
import { getSortedContentTypesByName } from '../../../../contentModels/shared/utils/typeUtils.ts';
import { workflowContentTypesScopeChanged } from '../../actions/workflowActions.ts';
import {
  ContentTypeOption,
  ContentTypesLimitations as ContentTypesLimitationsComponent,
} from '../../components/limitations/ContentTypesLimitations.tsx';
import { getSelectedContentTypes } from '../../utils/getSelectedContentTypes.ts';

interface IContentTypesLimitationsContainerProps {
  readonly label?: string;
  readonly scopeId: Uuid | undefined;
}

const getSelectedContentTypesMemoized = memoize.maxOne(getSelectedContentTypes);

export const ContentTypesLimitations: React.FC<IContentTypesLimitationsContainerProps> = ({
  label,
  scopeId,
}) => {
  const contentTypes = useSelector(
    (state) =>
      getSortedContentTypesByName(state.data.contentTypes.byId).map((contentType) => ({
        id: contentType.id,
        label: contentType.name,
        isArchived: contentType.isArchived,
      })),
    shallowEqual,
  );
  const selectedContentTypes = useSelector((state) =>
    getSelectedContentTypesMemoized(
      state.workflowsApp.editorUi.editedWorkflowScopesById,
      scopeId,
      contentTypes,
    ),
  );

  const [allContentTypes, setAllContentTypes] = useState<ContentTypeOption[]>([]);

  useEffect(() => {
    const deletedContentTypes = selectedContentTypes.filter(
      (c) => !contentTypes.find((t) => t.id === c.id),
    );
    const updatedAllContentTypes = [...contentTypes, ...deletedContentTypes];

    if (
      !areMembersEqual(
        updatedAllContentTypes.map((c) => c.id),
        allContentTypes.map((c) => c.id),
      )
    ) {
      setAllContentTypes(updatedAllContentTypes);
    }
  }, [contentTypes, selectedContentTypes, allContentTypes]);

  const dispatch = useDispatch();

  const onSelectedContentTypesChanged = useCallback(
    (options: ReadonlySet<Uuid>) => {
      assert(scopeId, () => 'Cannot update options of undefined scope.');
      dispatch(workflowContentTypesScopeChanged(scopeId, options));
    },
    [scopeId],
  );

  return (
    <ContentTypesLimitationsComponent
      allContentTypes={allContentTypes}
      isDisabled={!scopeId}
      label={label}
      onSelectedContentTypesChanged={onSelectedContentTypesChanged}
      selectedContentTypesIds={new Set(selectedContentTypes.map((c) => c.id))}
      tooltipText={scopeId ? undefined : 'The default workflow can only have one generic rule.'}
    />
  );
};

ContentTypesLimitations.displayName = 'ContentTypesLimitationsContainer';
