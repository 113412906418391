import { RouterLinkButton } from '@kontent-ai/component-library/Button';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { createCompare, naturally } from '@kontent-ai/utils';
import Immutable from 'immutable';
import React, { memo, ReactNode } from 'react';
import { DataTable } from '../../../../_shared/components/DataTable/DataTable.tsx';
import { DataTableAction } from '../../../../_shared/components/DataTable/DataTableActions.tsx';
import {
  Column,
  DataTableHeadRow,
} from '../../../../_shared/components/DataTable/DataTableHeadRow.tsx';
import { DataTableRow } from '../../../../_shared/components/DataTable/DataTableRow.tsx';
import { LinkDataTableCell } from '../../../../_shared/components/DataTable/LinkDataTableCell.tsx';
import { EmptyState } from '../../../../_shared/components/EmptyState/EmptyState.tsx';
import { HtmlPageTitle } from '../../../../_shared/components/HtmlPageTitle.tsx';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { AppNames } from '../../../../_shared/constants/applicationNames.ts';
import {
  CreateNewTaxonomyGroupRoute,
  EditTaxonomyGroupRoute,
  EditTaxonomyGroupRouteParams,
  EnvironmentRouteParams,
} from '../../../../_shared/constants/routePaths.ts';
import { NotificationBar } from '../../../../_shared/containers/NotificationBar.tsx';
import { OrderByDirection } from '../../../../_shared/models/OrderBy.ts';
import { ListingMessage } from '../../../../_shared/uiComponents/ListingMessage/ListingMessage.tsx';
import {
  DataUiAction,
  DataUiAppName,
  DataUiCollection,
  getDataUiAppNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  toFuzzyDateDifference,
  toLocalTime,
} from '../../../../_shared/utils/dateTime/timeUtils.ts';
import { buildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { renderTaxonomyGroupName } from '../../../../_shared/utils/taxonomies/taxonomyUtils.ts';
import { formatUserName } from '../../../../_shared/utils/users/usersUtils.ts';
import { ITaxonomyGroup } from '../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import { IProjectContributor } from '../../../../data/models/users/ProjectContributor.ts';
import { IStatusInfoMessage } from '../../../contentInventory/assets/models/IStatusInfoMessage.type.ts';

const taxonomyGroupListingColumns: ReadonlyArray<Column> = [
  {
    columnName: 'Name',
    orderBy: OrderByDirection.None,
  },
  {
    columnName: 'Terms',
    className: 'data-table__column--4',
    orderBy: OrderByDirection.None,
  },
  {
    columnName: 'Updated',
    className: 'data-table__column--7',
    orderBy: OrderByDirection.None,
  },
];

type Props = {
  readonly currentProjectId: Uuid;
  readonly deletedTaxonomyGroupIds: Immutable.Set<Uuid>;
  readonly onTaxonomyGroupSelect: (groupIds: Immutable.Set<Uuid>) => void;
  readonly onTaxonomyGroupUnselect: (groupIds: Immutable.Set<Uuid>) => void;
  readonly onTaxonomyGroupsArchived: (groupIds: Immutable.Set<Uuid>) => void;
  readonly onTaxonomyGroupsRestored: ((groupIds: Immutable.Set<Uuid>) => void) | null;
  readonly selectedTaxonomyGroupIds: Immutable.Set<Uuid>;
  readonly taxonomyGroupListStatusInfoMessage: IStatusInfoMessage;
  readonly taxonomyGroups: ReadonlyArray<ITaxonomyGroup> | null;
  readonly users: ReadonlyMap<Uuid, IProjectContributor>;
};

const TaxonomyGroupListing: React.FC<Props> = ({
  currentProjectId,
  deletedTaxonomyGroupIds,
  onTaxonomyGroupSelect,
  onTaxonomyGroupUnselect,
  onTaxonomyGroupsArchived,
  onTaxonomyGroupsRestored,
  selectedTaxonomyGroupIds,
  taxonomyGroupListStatusInfoMessage,
  taxonomyGroups,
  users,
}) => {
  const createNewTaxonomyGroupLinkPath = buildPath<EnvironmentRouteParams>(
    CreateNewTaxonomyGroupRoute,
    { projectId: currentProjectId },
  );

  const onSelectAllCheckboxClick = (unselect: boolean): void => {
    if (!taxonomyGroups) {
      return;
    }

    const taxonomyGroupIds = Immutable.Set.of(
      ...taxonomyGroups.map((taxonomyGroup: ITaxonomyGroup) => taxonomyGroup.id),
    );
    if (unselect) {
      onTaxonomyGroupUnselect(taxonomyGroupIds);
    } else {
      onTaxonomyGroupSelect(taxonomyGroupIds);
    }
  };

  const renderTaxonomyGroupList = () => {
    if (!taxonomyGroups) {
      return;
    }

    return taxonomyGroups
      .toSorted(
        createCompare({
          compare: naturally,
          select: renderTaxonomyGroupName,
        }),
      )
      .map((taxonomyGroup) => {
        const localTime = toLocalTime(taxonomyGroup.lastModified);
        const fuzzyDate = localTime ? toFuzzyDateDifference(new Date(), localTime, false) : '';
        const author = users.get(taxonomyGroup.lastModifiedBy);
        const taxonomyGroupLink = buildPath<EditTaxonomyGroupRouteParams>(EditTaxonomyGroupRoute, {
          taxonomyGroupId: taxonomyGroup.id,
          projectId: currentProjectId,
        });
        const isSelected = selectedTaxonomyGroupIds.includes(taxonomyGroup.id);
        const selectionAction = isSelected
          ? () => onTaxonomyGroupUnselect(Immutable.Set.of<Uuid>(taxonomyGroup.id))
          : () => onTaxonomyGroupSelect(Immutable.Set.of<Uuid>(taxonomyGroup.id));
        return (
          <DataTableRow
            key={taxonomyGroup.id}
            id={taxonomyGroup.id}
            isSelected={isSelected}
            selectionDisabled={false}
            onSelectionChange={selectionAction}
            showCheckboxes
            dataUiObjectName={taxonomyGroup.name}
          >
            <LinkDataTableCell
              linkPath={taxonomyGroupLink}
              focusableRowLinkAriaLabel={`visit taxonomy group — ${taxonomyGroup.name}`}
            >
              {renderTaxonomyGroupName(taxonomyGroup)}
            </LinkDataTableCell>
            <LinkDataTableCell linkPath={taxonomyGroupLink}>
              {taxonomyGroup.terms.size}
            </LinkDataTableCell>
            <LinkDataTableCell linkPath={taxonomyGroupLink}>
              {author ? `${fuzzyDate} by ${formatUserName(author)}` : fuzzyDate}
            </LinkDataTableCell>
          </DataTableRow>
        );
      });
  };

  const undoArchiving = (): void => onTaxonomyGroupsRestored?.(deletedTaxonomyGroupIds);

  if (!taxonomyGroups) {
    return <Loader />;
  }

  const taxonomyGroupIds = Immutable.Set.of<Uuid>(
    ...taxonomyGroups.map((taxonomyGroup: ITaxonomyGroup) => taxonomyGroup.id),
  );
  const isMainCheckboxChecked =
    selectedTaxonomyGroupIds &&
    taxonomyGroupIds &&
    taxonomyGroupIds.isSubset(selectedTaxonomyGroupIds);

  const actions: ReadonlyArray<DataTableAction> = selectedTaxonomyGroupIds.isEmpty()
    ? []
    : [
        {
          dataUiAction: DataUiAction.Delete,
          iconName: 'Bin',
          isDestructive: true,
          isDisabled: false,
          onClick: () => {
            onTaxonomyGroupUnselect(selectedTaxonomyGroupIds);
            onTaxonomyGroupsArchived(selectedTaxonomyGroupIds);
          },
          text: 'Delete',
        },
      ];

  let tableHeadRow: ReactNode = null;
  let tableRows: ReactNode = null;
  let infoMessage: ReactNode = null;

  if (taxonomyGroups.length) {
    tableHeadRow = (
      <DataTableHeadRow
        columns={taxonomyGroupListingColumns}
        allEntriesSelected={isMainCheckboxChecked}
        onCheckboxChange={() => onSelectAllCheckboxClick(isMainCheckboxChecked)}
        showCheckboxes
      />
    );
    tableRows = renderTaxonomyGroupList();
  } else {
    infoMessage = (
      <EmptyState>
        <EmptyState.Title>Taxonomies act as tags for your content.</EmptyState.Title>
        <EmptyState.Content>
          Make it easy to navigate through your content items and create personalized content.
        </EmptyState.Content>
        <EmptyState.Footer>
          <RouterLinkButton buttonStyle="primary" to={createNewTaxonomyGroupLinkPath}>
            Create new Taxonomy group
          </RouterLinkButton>
        </EmptyState.Footer>
      </EmptyState>
    );
  }

  return (
    <div className="canvas__workspace">
      <div
        className="canvas__content"
        {...getDataUiAppNameAttribute(DataUiAppName.TaxonomyGroupListing)}
      >
        <HtmlPageTitle appName={AppNames.Taxonomies} />
        <div className="canvas__notifications">
          <NotificationBar />
        </div>
        <div className="canvas__content-pane taxonomy-management-pane">
          <div className="canvas__inner-section canvas__inner-section--restricted-width canvas__inner-section--centered">
            <Stack spacing={Spacing.XL}>
              <div className="row">
                <div className="col-sm-24">
                  <DataTable
                    createNewItemLinkPath={createNewTaxonomyGroupLinkPath}
                    actions={actions}
                    dataUiCollectionName={DataUiCollection.Taxonomies}
                    title={
                      <ListingMessage
                        statusInfoMessage={taxonomyGroupListStatusInfoMessage}
                        onUndo={deletedTaxonomyGroupIds.size ? undoArchiving : null}
                      />
                    }
                    header={tableHeadRow}
                    infoMessage={infoMessage}
                  >
                    {tableRows}
                  </DataTable>
                </div>
              </div>
            </Stack>
          </div>
        </div>
      </div>
    </div>
  );
};

TaxonomyGroupListing.displayName = 'TaxonomyGroupListing';

const TaxonomyGroupListingMemoized = memo(TaxonomyGroupListing);
export { TaxonomyGroupListingMemoized as TaxonomyGroupListing };
