import React from 'react';

type Props = {
  readonly title?: string;
};

const LimitationCategoryList: React.FC<React.PropsWithChildren<Props>> = ({ children, title }) => (
  <div className="content-element__config-category">
    {title && <div className="content-element__config-category-title">{title}</div>}
    {children}
  </div>
);

LimitationCategoryList.displayName = 'LimitationCategoryList';

export { LimitationCategoryList };
