import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { createFormValidationResolver } from '../../../../../_shared/utils/validation/createFormValidationResolver.ts';
import { hideCreateNewForm } from '../../actions/sitemapActions.ts';
import { createNode } from '../../actions/thunkSitemapActions.ts';
import { NodeCreator } from '../../components/nodeEditors/NodeCreator.tsx';
import { ISitemapFormShape } from '../../models/ISitemapFormShape.type.ts';
import { sitemapValidationConfig } from '../../validation/sitemapValidation.ts';

const NodeCreatorContainer: React.FC = () => {
  const dispatch = useDispatch();
  const onCancel = () => dispatch(hideCreateNewForm());

  const formProps = useForm<ISitemapFormShape>({
    defaultValues: {
      codename: null,
      name: '',
    },
    resolver: createFormValidationResolver(sitemapValidationConfig, {}),
  });

  const submitForm = formProps.handleSubmit((values) => dispatch(createNode(values.name)));

  return <NodeCreator formProps={formProps} onCancel={onCancel} onSubmit={submitForm} />;
};

NodeCreatorContainer.displayName = 'NodeCreatorContainer';
export { NodeCreatorContainer as NodeCreator };
