import { IStore } from 'client/app/_shared/stores/IStore.type.ts';
import { ActiveCapabilityType } from '../../../../../../../_shared/models/activeCapability.type.ts';
import { getEditedContentItemVariant } from '../../../../../../../_shared/selectors/getEditedContentItemVariant.ts';
import { isNewVersion } from '../../../../../../../_shared/utils/contentItemVariantUtils.ts';
import { hasActiveVariantCapabilityForEditedItem } from '../../../../../../../_shared/utils/permissions/activeCapabilities.ts';

export const isDiscardNewVersionAvailable = (state: IStore) => {
  const variant = getEditedContentItemVariant(state);
  return (
    hasActiveVariantCapabilityForEditedItem(ActiveCapabilityType.DiscardNewVersion, state) &&
    !!variant &&
    isNewVersion({
      workflowStatus: variant.assignment.workflowStatus,
      publishingState: variant.publishingState,
    })
  );
};
