import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { selectIntercomUtils } from '../../../../../_shared/selectors/selectIntercomUtils.ts';
import { SecureAssetsCard as SecureAssetsCardComponent } from '../../components/SecureAssetsCard.tsx';
import { useEnableFeatureIntercomMessage } from '../../hooks/useEnableFeatureIntercomMessage.ts';
import { InnovationLabCardProps } from '../../types/InnovationLabCardProps.type.ts';
import { isInnovationLabFeatureAvailable } from '../../utils/innovationLabFeatureStatusUtils.ts';

export const SecureAssetsCard = ({ state, info, name }: InnovationLabCardProps) => {
  const intercomUtils = useSelector(selectIntercomUtils);
  const featureState = info.status;
  const sendIntercomMessage = useEnableFeatureIntercomMessage(name);
  const onCardActionButtonClick = isInnovationLabFeatureAvailable(featureState)
    ? sendIntercomMessage
    : undefined;

  const isButtonDisabled = !isInnovationLabFeatureAvailable(featureState) && state !== 'active';

  return (
    <SecureAssetsCardComponent
      featureState={featureState}
      activationState={state}
      featureName={name}
      isButtonDisabled={isButtonDisabled}
      onContactSupport={intercomUtils.showIntercom}
      onCardActionButtonClick={onCardActionButtonClick}
    />
  );
};
