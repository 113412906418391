export enum SubscriptionTabName {
  MySubscriptions = 'My subscriptions',
  Admins = 'Admins',
  ApiKeys = 'Subscription API',
  BillingInfo = 'Billing information',
  BillingHistory = 'Billing history',
  General = 'General',
  Plans = 'Plans',
  Usage = 'Usage report',
  Transactions = 'Transactions',
  Projects = 'Projects',
  Users = 'Users',
}
