import React from 'react';
import { ConfirmationDialog } from '../../../../../_shared/components/ModalDialog/ConfirmationDialog.tsx';
import {
  IContentTypeUsage,
  isContentTypeUsed,
} from '../../../../../data/models/contentModelsApp/contentTypes/ContentTypeUsage.ts';
import { getUsageInfo } from '../../../shared/utils/typeElementMessagesUtils.ts';

type Props = {
  readonly childElementCount: number;
  readonly contentGroupName: string;
  readonly contentTypeUsage: IContentTypeUsage;
  readonly isLastContentGroup: boolean;
  readonly onClose: () => void;
  readonly onConfirm: () => void;
};

export const ContentGroupDeleteDialog: React.FC<Props> = ({
  childElementCount,
  contentGroupName,
  contentTypeUsage,
  isLastContentGroup,
  onClose,
  onConfirm,
}) => {
  const getDeleteMessage = (): JSX.Element => {
    if (childElementCount === 0) {
      return (
        <p className="confirmation-dialog__section">
          Do you want to delete the empty content group <strong>{contentGroupName}</strong>?
        </p>
      );
    }

    if (isLastContentGroup) {
      return (
        <>
          <p className="confirmation-dialog__section">
            Do you want to delete your last content group?
          </p>
          <p className="confirmation-dialog__section">
            The elements it contains will be retained in the content type.
          </p>
        </>
      );
    }

    return (
      <>
        {isContentTypeUsed(contentTypeUsage) && (
          <p className="confirmation-dialog__section">
            This content group contains elements used in{' '}
            {getUsageInfo(contentTypeUsage.itemCount, contentTypeUsage.contentComponentCount)}.
          </p>
        )}
        <p className="confirmation-dialog__section">
          Do you want to delete the content group and its elements?
        </p>
      </>
    );
  };

  return (
    <ConfirmationDialog
      alert
      confirmButtonText="Delete content group"
      headerContent={`Delete the ${contentGroupName} content group`}
      isOpen
      onClose={onClose}
      onConfirm={onConfirm}
    >
      {getDeleteMessage()}
    </ConfirmationDialog>
  );
};

ContentGroupDeleteDialog.displayName = 'ContentGroupDeleteDialog';
