import { VariantCompletionStatus } from '../../../../../_shared/utils/contentItemVariantUtils.ts';
import { VariantCompletionStatusListingFilterOption } from './ListingFilterOption.type.ts';

export interface IContentItemStatus {
  readonly unfinished: boolean;
  readonly ready: boolean;
  readonly notTranslated: boolean;
  readonly allDone: boolean;
}

export const isContentItemStatus = (input: unknown): input is IContentItemStatus => {
  const contentItemStatusKeys = Object.keys(emptyContentItemStatus);
  return input instanceof Object && contentItemStatusKeys.every((key) => Object.hasOwn(input, key));
};

export const emptyContentItemStatus: IContentItemStatus = {
  unfinished: false,
  ready: false,
  notTranslated: false,
  allDone: false,
};

const UnfinishedOption = {
  id: VariantCompletionStatus.unfinished,
  label: 'Unfinished',
} satisfies VariantCompletionStatusListingFilterOption;

const ReadyOption = {
  id: VariantCompletionStatus.ready,
  label: 'Ready',
} satisfies VariantCompletionStatusListingFilterOption;

const NotTranslatedOption = {
  disabledTooltipText: '‘Not translated’ filter is not available with semantic search.',
  id: VariantCompletionStatus.notTranslated,
  label: 'Not translated',
} satisfies VariantCompletionStatusListingFilterOption;

const AllDoneOption = {
  id: VariantCompletionStatus.allDone,
  label: 'All done',
} satisfies VariantCompletionStatusListingFilterOption;

export const ContentItemStatusWithTranslationOptions = [
  NotTranslatedOption,
  UnfinishedOption,
  ReadyOption,
  AllDoneOption,
] as const;

export const ContentItemStatusWithoutTranslationOptions = [
  UnfinishedOption,
  ReadyOption,
  AllDoneOption,
] as const;

export function checkEqualityOfContentItemStatus(
  first: IContentItemStatus,
  second: IContentItemStatus,
): boolean {
  return (
    first.ready === second.ready &&
    first.unfinished === second.unfinished &&
    first.notTranslated === second.notTranslated &&
    first.allDone === second.allDone
  );
}

export function getContentItemStatusFromIds(
  contentItemStatusIds: ReadonlySet<string>,
): IContentItemStatus {
  return {
    ready: contentItemStatusIds.has(ReadyOption.id),
    unfinished: contentItemStatusIds.has(UnfinishedOption.id),
    notTranslated: contentItemStatusIds.has(NotTranslatedOption.id),
    allDone: contentItemStatusIds.has(AllDoneOption.id),
  };
}

export const isContentItemStatusEmpty = (status: IContentItemStatus): boolean =>
  !(status.ready || status.unfinished || status.notTranslated || status.allDone);

export function convertContentItemStatusToQueryParams(
  status: IContentItemStatus,
): ReadonlyArray<VariantCompletionStatus> {
  const used: VariantCompletionStatus[] = [];

  if (status.ready) {
    used.push(ReadyOption.id);
  }

  if (status.unfinished) {
    used.push(UnfinishedOption.id);
  }

  if (status.notTranslated) {
    used.push(NotTranslatedOption.id);
  }

  if (status.allDone) {
    used.push(AllDoneOption.id);
  }

  return used;
}
