import React, { useCallback } from 'react';
import {
  NotificationBar,
  NotificationBarType,
} from '../../../../../../_shared/components/NotificationBar.tsx';
import { DefaultVariantId } from '../../../../../../_shared/constants/variantIdValues.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { ActiveCapabilityType } from '../../../../../../_shared/models/activeCapability.type.ts';
import { getEditedContentItemDefaultVariant } from '../../../../../../_shared/selectors/getEditedContentItemDefaultVariant.ts';
import { getSelectedLanguageId } from '../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { getSelectedLanguageNameOrPlaceholder } from '../../../../../../_shared/selectors/getSelectedLanguageNameOrPlaceholder.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { DataUiElement } from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { hasActiveVariantCapability } from '../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { addDefaultLanguageSuffix } from '../../../../../environmentSettings/localization/utils/languageUtils.ts';
import { getEditedContentItemType } from '../../../../selectors/getEditedContentItemType.ts';
import { closeIncompleteDefaultVariantNotification } from '../../actions/contentItemEditingActions.ts';
import { DefaultVariantRequiredButIncompleteMessage } from '../../constants/uiConstants.ts';
import { isPublishingStepAvailableForEditedItemDefaultVariant } from '../../utils/editingActions/availabilityUtils/areAssignmentChangesAvailable.ts';
import { isDefaultVariantBlockingPublish } from '../../utils/itemValidationUtils.ts';

const getIncompleteDefaultVariantNotificationText = (state: IStore): string | null => {
  const editedContentItemType = getEditedContentItemType(state);

  if (!editedContentItemType) {
    return null;
  }

  const selectedLanguageId = getSelectedLanguageId(state);
  const defaultLanguage = addDefaultLanguageSuffix(state.data.languages.defaultLanguage);
  const selectedLanguageName = getSelectedLanguageNameOrPlaceholder(state);

  const editedContentItemDefaultVariant = getEditedContentItemDefaultVariant(state);
  const isPublishingStepAvailableForDefaultVariant =
    isPublishingStepAvailableForEditedItemDefaultVariant(editedContentItemDefaultVariant, state);
  const canPublishDefaultVariant =
    isPublishingStepAvailableForDefaultVariant &&
    hasActiveVariantCapability(
      ActiveCapabilityType.UpdateAssignment,
      editedContentItemDefaultVariant,
    );

  const canViewDefaultVariant = hasActiveVariantCapability(
    ActiveCapabilityType.ViewContent,
    editedContentItemDefaultVariant,
  );
  const isEditingDefaultVariant = selectedLanguageId === DefaultVariantId;

  const defaultVariantBlocksPublishing = isDefaultVariantBlockingPublish(
    editedContentItemType,
    editedContentItemDefaultVariant,
    isEditingDefaultVariant,
    canViewDefaultVariant,
    canPublishDefaultVariant,
  );

  if (!defaultVariantBlocksPublishing) {
    return null;
  }

  return DefaultVariantRequiredButIncompleteMessage(
    selectedLanguageName,
    defaultLanguage.name,
    canPublishDefaultVariant,
    isPublishingStepAvailableForDefaultVariant,
  );
};

export const IncompleteDefaultVariantNotificationBar: React.FC = () => {
  const message = useSelector(getIncompleteDefaultVariantNotificationText);
  const dispatch = useDispatch();

  const onClosePanel = useCallback(() => dispatch(closeIncompleteDefaultVariantNotification()), []);

  return (
    <NotificationBar
      type={NotificationBarType.Error}
      uiElement={DataUiElement.NotificationMessageError}
      message={message}
      onClosePanel={onClosePanel}
    />
  );
};

IncompleteDefaultVariantNotificationBar.displayName = 'IncompleteDefaultVariantNotificationBar';
