import { Box } from '@kontent-ai/component-library/Box';
import { Divider, DividerDirection } from '@kontent-ai/component-library/Dividers';
import { AnimatedProgressIcon, Icons } from '@kontent-ai/component-library/Icons';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Paper, PaperLevel } from '@kontent-ai/component-library/Paper';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import {
  BorderRadius,
  IconSize,
  Spacing,
  Typography,
  colorTextDefault,
  colorTextHint,
  colorTextLowEmphasis,
} from '@kontent-ai/component-library/tokens';
import { animated, useTransition } from '@react-spring/web';
import { Property } from 'csstype';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { AiActionName } from '../../../../repositories/serverModels/ai/AiActionName.type.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../utils/dataAttributes/DataUiAttributes.ts';
import { StructuredFeedbackButtons } from '../../StructuredFeedback/containers/StructuredFeedbackButtons.tsx';
import {
  FeedbackReasons,
  getAiFeedbackFeature,
} from '../../StructuredFeedback/structuredFeedback.ts';

type AiResultProps = {
  readonly actionName: AiActionName;
  readonly actionTitle: string;
  readonly feedbackReasons: FeedbackReasons;
  readonly isLoading: boolean;
  readonly renderResult: () => React.ReactNode;
  readonly width?: Property.MaxWidth<number | string>;
};

export const AiResult = forwardRef<HTMLDivElement, AiResultProps>(
  ({ actionName, actionTitle, feedbackReasons, isLoading, renderResult, width }, ref) => {
    const result = renderResult();
    const resultRef = useRef<HTMLDivElement>(null);

    return (
      <Paper
        borderRadius={BorderRadius.M}
        component="section"
        level={PaperLevel.Popout}
        ref={ref}
        width={width}
        {...getDataUiElementAttribute(DataUiElement.AiResult)}
      >
        <Stack>
          {result && (
            <>
              <Box
                maxHeight="50vh"
                overflowX="hidden"
                overflowY="auto"
                padding={Spacing.L}
                ref={resultRef}
              >
                {result}
              </Box>
              <Divider
                direction={DividerDirection.Horizontal}
                offsetBefore={Spacing.None}
                offsetAfter={Spacing.None}
              />
            </>
          )}
          <ActionStatus
            actionName={actionName}
            actionTitle={actionTitle}
            feedbackReasons={feedbackReasons}
            isLoading={isLoading}
          />
        </Stack>
      </Paper>
    );
  },
);

AiResult.displayName = 'AiResult';

type ActionStatusProps = {
  readonly actionName: AiActionName;
  readonly actionTitle: string;
  readonly feedbackReasons: FeedbackReasons;
  readonly isLoading: boolean;
};

const ActionStatus: React.FC<ActionStatusProps> = ({
  actionName,
  actionTitle,
  feedbackReasons,
  isLoading,
}) => (
  <Box paddingX={Spacing.L}>
    <Row
      alignX="end"
      alignY="center"
      css="justify-content: space-between"
      noWrap
      spacingX={Spacing.M}
      spacingY={Spacing.M}
    >
      <Column>
        <Box paddingY={Spacing.L}>
          <Tooltip text={`Above is the AI suggestion for action ${actionTitle}`} placement="top">
            <Box typography={Typography.LabelLarge} color={colorTextHint}>
              <Inline align="center" noWrap spacingX={Spacing.S}>
                <Icons.Sparkles size={IconSize.S} />
                {isLoading ? <LoadingStatus actionTitle={actionTitle} /> : actionTitle}
              </Inline>
            </Box>
          </Tooltip>
        </Box>
      </Column>
      {!isLoading && (
        <Column width="content">
          <StructuredFeedbackButtons
            feature={getAiFeedbackFeature(actionName)}
            feedbackReasons={feedbackReasons}
          />
        </Column>
      )}
    </Row>
  </Box>
);

ActionStatus.displayName = 'ActionStatus';

type LoadingStatusProps = {
  readonly actionTitle: string;
};

const longMessageTransition = {
  from: {
    opacity: 0,
    paddingLeft: Spacing.XXL,
  },
  enter: {
    opacity: 1,
    paddingLeft: 0,
  },
  leave: {
    opacity: 0,
    paddingLeft: Spacing.XXL,
  },
  config: {
    duration: 750,
    tension: 350,
    friction: 12,
  },
};

const LoadingStatus: React.FC<LoadingStatusProps> = ({ actionTitle }) => {
  const [isLongLoadingMessageVisible, setIsLongLoadingMessageVisible] = useState(false);
  const debouncedSetIsLongLoadingMessageVisible = useDebouncedCallback(
    () => setIsLongLoadingMessageVisible(true),
    15000,
  );

  useEffect(() => {
    debouncedSetIsLongLoadingMessageVisible();
    return debouncedSetIsLongLoadingMessageVisible.cancel;
  }, [debouncedSetIsLongLoadingMessageVisible]);

  const transitions = useTransition(isLongLoadingMessageVisible, longMessageTransition);

  return (
    <Box color={isLongLoadingMessageVisible ? colorTextDefault : colorTextHint}>
      <Inline spacingX={Spacing.S} align="center">
        {actionTitle}…{!isLongLoadingMessageVisible && <Loader />}
        {isLongLoadingMessageVisible &&
          transitions(
            (style, isVisible) =>
              isVisible && (
                <animated.div style={style}>
                  <Inline spacingX={Spacing.S} align="center">
                    this might take a while, even minutes
                    <Loader />
                  </Inline>
                </animated.div>
              ),
          )}
      </Inline>
    </Box>
  );
};

const Loader: React.FC = () => (
  <Box color={colorTextLowEmphasis}>
    <AnimatedProgressIcon />
  </Box>
);
