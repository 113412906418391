import { memoize } from '@kontent-ai/memoization';
import { AppNames } from '../../../../_shared/constants/applicationNames.ts';
import {
  AssetTypesRoute,
  ContentTypeSnippetsRoute,
  ContentTypesRoute,
  SitemapRoute,
  TaxonomyGroupsRoute,
} from '../../../../_shared/constants/routePaths.ts';
import {
  DataUiAppName,
  ObjectWithDataAttribute,
  getDataUiObjectNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { Capability } from '../../../../_shared/utils/permissions/capability.ts';
import { checkGivenRoutesForPathNameMatch } from '../../../../_shared/utils/routing/navigationRouteUtils.ts';
import { ContentModelsTabName } from '../types/contentModelsTabName.ts';

export type ContentModelsTabInfo = {
  readonly tabName: ContentModelsTabName;
  readonly dataUiAttributes: ObjectWithDataAttribute;
  readonly tabText: string;
  readonly requiredCapability: Capability;
  readonly linkRoute: string;
  readonly isActive?: boolean;
};

export type ContentModelsTabsStructure = ReadonlyArray<ContentModelsTabInfo>;

export const getContentModelsTabsStructure = memoize.maxOne(
  (showSitemap: boolean, pathname: string = ''): ContentModelsTabsStructure => [
    {
      tabName: ContentModelsTabName.Types,
      dataUiAttributes: getDataUiObjectNameAttribute(DataUiAppName.ContentTypeListing),
      tabText: AppNames.ContentTypes,
      requiredCapability: Capability.ConfigureContentTypes,
      linkRoute: ContentTypesRoute,
      isActive: checkGivenRoutesForPathNameMatch(pathname, [ContentTypesRoute]),
    },
    {
      tabName: ContentModelsTabName.Snippets,
      dataUiAttributes: getDataUiObjectNameAttribute(DataUiAppName.SnippetListing),
      tabText: AppNames.ContentTypeSnippets,
      requiredCapability: Capability.ConfigureContentTypes,
      linkRoute: ContentTypeSnippetsRoute,
      isActive: checkGivenRoutesForPathNameMatch(pathname, [ContentTypeSnippetsRoute]),
    },
    {
      tabName: ContentModelsTabName.AssetType,
      dataUiAttributes: getDataUiObjectNameAttribute(DataUiAppName.AssetType),
      tabText: AppNames.AssetType,
      requiredCapability: Capability.ConfigureContentTypes,
      linkRoute: AssetTypesRoute,
      isActive: checkGivenRoutesForPathNameMatch(pathname, [AssetTypesRoute]),
    },
    {
      tabName: ContentModelsTabName.Taxonomies,
      dataUiAttributes: getDataUiObjectNameAttribute(DataUiAppName.TaxonomyGroupListing),
      tabText: AppNames.Taxonomies,
      requiredCapability: Capability.ConfigureTaxonomy,
      linkRoute: TaxonomyGroupsRoute,
      isActive: checkGivenRoutesForPathNameMatch(pathname, [TaxonomyGroupsRoute]),
    },
    ...(showSitemap
      ? [
          {
            tabName: ContentModelsTabName.Sitemap,
            dataUiAttributes: getDataUiObjectNameAttribute(DataUiAppName.SitemapListing),
            tabText: AppNames.Sitemap,
            requiredCapability: Capability.ConfigureSitemap,
            linkRoute: SitemapRoute,
            isActive: checkGivenRoutesForPathNameMatch(pathname, [SitemapRoute]),
          },
        ]
      : []),
  ],
);

export const getContentModelsRequiredCapabilities = (
  showSitemap: boolean,
): ReadonlyArray<Capability> =>
  getContentModelsTabsStructure(showSitemap).map((tabInfo) => tabInfo.requiredCapability);
