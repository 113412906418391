import { Card } from '@kontent-ai/component-library/Card';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { ReactNode } from 'react';
import { InnovationLabActivationState } from '../types/InnovationLabActivationState.type.ts';
import { InnovationLabFeatureAccess } from '../types/InnovationLabFeatureAccess.ts';
import { InnovationLabFeatureButton } from './InnovationLabFeatureButton.tsx';
import { InnovationLabFeatureGenericallyUnavailable } from './InnovationLabFeatureGenericallyUnavailable.tsx';
import { InnovationLabStatus } from './InnovationLabFeatureStatus.tsx';

type Props = {
  readonly activationState: InnovationLabActivationState;
  readonly buttonFeatureName?: string;
  readonly featureName: string;
  readonly featureState: InnovationLabFeatureAccess;
  readonly isActionButtonLoading: boolean;
  readonly onCardActionButtonClick?: () => Promise<void>;
  readonly onContactSupport: () => void;
  readonly renderCardBody: () => ReactNode;
  readonly renderFooterBody?: () => ReactNode;
  readonly canBeButtonDeactivated?: boolean;
  readonly buttonDeactivationUnavailableExplanation?: string;
};

export const InnovationLabFeatureCard = ({
  activationState,
  buttonFeatureName,
  featureName,
  featureState,
  isActionButtonLoading,
  onCardActionButtonClick,
  onContactSupport,
  renderCardBody,
  renderFooterBody,
  canBeButtonDeactivated,
  buttonDeactivationUnavailableExplanation,
}: Props) => (
  <Card component="section">
    <Card.Headline
      renderAuxiliaryElements={() => <InnovationLabStatus activationState={activationState} />}
    >
      {featureName}
    </Card.Headline>

    <Card.Body>
      <Stack spacing={Spacing.M}>
        {renderCardBody()}
        <InnovationLabFeatureGenericallyUnavailable
          activationState={activationState}
          featureState={featureState}
          onContactSupport={onContactSupport}
        />
      </Stack>
    </Card.Body>

    <Card.Footer>
      <Stack spacing={Spacing.XL}>
        <InnovationLabFeatureButton
          activationState={activationState}
          featureName={buttonFeatureName ?? featureName}
          featureState={featureState}
          isLoading={isActionButtonLoading}
          onClick={onCardActionButtonClick}
          canBeDeactivated={canBeButtonDeactivated}
          deactivationUnavailableExplanation={buttonDeactivationUnavailableExplanation}
        />
        {renderFooterBody?.()}
      </Stack>
    </Card.Footer>
  </Card>
);
