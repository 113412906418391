import { SortBy, Status } from '../constants/filters.ts';
import { InnovationLabCardProps } from '../types/InnovationLabCardProps.type.ts';
import { InnovationLabFeatureCodename } from '../types/InnovationLabFeatureCodenames.ts';
import { InnovationLabFeatureMetadata } from '../types/InnovationLabFeatureMetadata.type.ts';
import { InnovationLabFeatureSet } from '../types/InnovationLabFeatureSet.type.ts';

const sortFeatures =
  (sortBy: SortBy) =>
  <T extends { readonly releaseDate: string; readonly name: string }>(
    featureA: T,
    featureB: T,
  ): number => {
    switch (sortBy) {
      case SortBy.Newest:
        return -featureA.releaseDate.localeCompare(featureB.releaseDate);
      case SortBy.Oldest:
        return featureA.releaseDate.localeCompare(featureB.releaseDate);
      case SortBy.ByNameAsc:
        return featureA.name.localeCompare(featureB.name);
      case SortBy.ByNameDesc:
        return -featureA.name.localeCompare(featureB.name);
    }
  };

const filterByStatus =
  (
    status: Status,
    codenameToMetadata: ReadonlyRecord<InnovationLabFeatureCodename, InnovationLabFeatureMetadata>,
  ) =>
  <T extends { readonly codename: InnovationLabFeatureCodename }>(feature: T): boolean => {
    switch (status) {
      case Status.All:
        return true;
      case Status.Active:
        return codenameToMetadata[feature.codename].state === 'active';
      case Status.Inactive:
        return codenameToMetadata[feature.codename].state !== 'active';
    }
  };

export const getInnovationLabFeatures = (
  featureSet: InnovationLabFeatureSet,
  sortBy: SortBy,
  status: Status,
  codenameToMetadata: ReadonlyRecord<InnovationLabFeatureCodename, InnovationLabFeatureMetadata>,
): ReadonlyArray<InnovationLabCardProps & { readonly codename: InnovationLabFeatureCodename }> =>
  Object.entries(featureSet)
    .map(([codename, featureInfo]) =>
      featureInfo ? { ...codenameToMetadata[codename], codename, info: featureInfo } : null,
    )
    .filter((item) => !!item)
    .filter((item) => !(item?.shouldBeHidden ?? false))
    .filter(filterByStatus(status, codenameToMetadata))
    .sort(sortFeatures(sortBy));
