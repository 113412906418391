import React, { useMemo } from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { isWebSpotlightRootType } from '../../../../webSpotlight/utils/webSpotlightUtils.ts';
import { LinkedItemsTypeElement as LinkedItemsTypeElementComponent } from '../../components/typeElements/individualTypeElements/linkedItems/LinkedItemsTypeElement.tsx';
import { ContentTypeKind } from '../../constants/contentTypeKind.ts';
import { ILinkedItemsTypeElementData } from '../../models/elements/LinkedItemsTypeElementData.ts';
import { getAllowedContentTypesSelectedOptions } from '../../selectors/contentTypeElementSelector.ts';
import { ITypeElementOwnProps } from '../../types/ITypeElementProps.type.ts';
import { ModularContentTypeElementValidationResult } from '../../utils/typeElementValidators/types/ModularContentTypeElementValidationResult.type.ts';
import { getSortedContentTypesByName } from '../../utils/typeUtils.ts';

export const LinkedItemsTypeElement: React.FC<
  ITypeElementOwnProps<ILinkedItemsTypeElementData, ModularContentTypeElementValidationResult>
> = ({ contentTypeKind, typeElementData, ...otherProps }) => {
  const isContentTypeSnippet = contentTypeKind === ContentTypeKind.ContentSnippet;

  const editedTypeId = useSelector((state) => state.contentModelsApp.typeEditor.editedType.id);
  const contentTypes = useSelector((state) => state.data.contentTypes.byId);
  const isWebSpotlightRoot = useSelector(
    (state) =>
      !!editedTypeId && !isContentTypeSnippet && isWebSpotlightRootType(state, editedTypeId),
  );

  const sortedContentTypes = useMemo(
    () => getSortedContentTypesByName(contentTypes.toArray()),
    [contentTypes],
  );

  return (
    <LinkedItemsTypeElementComponent
      isWebSpotlightRootType={isWebSpotlightRoot}
      contentTypeKind={contentTypeKind}
      typeElementData={typeElementData}
      selectedAllowedContentTypes={getAllowedContentTypesSelectedOptions(
        contentTypes,
        typeElementData.allowedTypes,
      )}
      allContentTypes={sortedContentTypes}
      {...otherProps}
    />
  );
};
