import { Box } from '@kontent-ai/component-library/Box';
import React from 'react';
import { HtmlPageTitle } from '../../../../../_shared/components/HtmlPageTitle.tsx';
import { AppNames } from '../../../../../_shared/constants/applicationNames.ts';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
  getDataUiObjectNameAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { AssetLibrary } from '../../containers/AssetListing/AssetLibrary.tsx';

type Props = {
  readonly clearAssetSelection: () => void;
  readonly deselectAssets: (assetIds: ReadonlySet<Uuid>) => void;
  readonly onAssetClick: (assetId: Uuid) => void;
  readonly openedFolderName: string | null;
  readonly selectAssets: (assets: ReadonlyArray<Uuid>) => void;
  readonly selectedAssets: ReadonlySet<Uuid>;
  readonly toggleAssetSelection: (assetId: Uuid, isShiftSelection: boolean) => void;
};

export const AssetListing: React.FC<Props> = ({
  clearAssetSelection,
  deselectAssets,
  onAssetClick,
  openedFolderName,
  selectAssets,
  selectedAssets,
  toggleAssetSelection,
}) => (
  <div
    className="canvas__workspace asset-listing-container"
    {...getDataUiAppNameAttribute(DataUiAppName.AssetListing)}
    {...getDataUiObjectNameAttribute(openedFolderName || '')}
  >
    <HtmlPageTitle appName={AppNames.Assets} />
    <Box display="flex" flexBasis="auto" flexGrow={1} flexShrink={1}>
      <AssetLibrary
        allowSelect
        clearAssetSelection={clearAssetSelection}
        deselectAssets={deselectAssets}
        isWithAnchor
        onAssetClick={onAssetClick}
        selectAssets={selectAssets}
        selectedAssets={selectedAssets}
        shouldPersistOptions
        toggleAssetSelection={toggleAssetSelection}
      />
    </Box>
  </div>
);

AssetListing.displayName = 'AssetListing';
