import { Clipboard } from '@kontent-ai/component-library/Clipboard';
import { ConditionalWrapper } from '@kontent-ai/component-library/component-utils';
import { createRef, forwardRef, useEffect } from 'react';
import {
  DataUiInput,
  getDataUiInputAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { useIsRefinedNavigationFeatureEnabled } from '../../../../../../refinedNavigation/contexts/RefinedNavigationContext.tsx';

type Props = {
  readonly link: string | null;
  readonly loading?: boolean;
  readonly onCreateLinkedFilter: () => void;
  readonly onCopyCompleted?: () => void;
};

export const ShareFilterLinkForm = forwardRef<HTMLInputElement, Props>(
  ({ link, loading, onCreateLinkedFilter, onCopyCompleted }, ref) => {
    const containerRef = createRef<HTMLDivElement>();
    const isRefinedNavigationFeatureEnabled = useIsRefinedNavigationFeatureEnabled();

    useEffect(() => {
      if (!link && !loading) {
        onCreateLinkedFilter();
      }
    }, [onCreateLinkedFilter, link, loading]);

    return (
      <ConditionalWrapper
        condition={!isRefinedNavigationFeatureEnabled}
        wrapper={(children) => <div className="filter-share-link">{children}</div>}
      >
        <div ref={containerRef}>
          <Clipboard
            containerRef={containerRef}
            label="Filter link"
            onCopyCompleted={onCopyCompleted}
            overlayMessage={loading ? 'Loading…' : undefined}
            ref={ref}
            value={link || ''}
            {...getDataUiInputAttribute(DataUiInput.Url)}
          />
        </div>
      </ConditionalWrapper>
    );
  },
);
