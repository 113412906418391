import { alphabetically, createCompare, noOperation } from '@kontent-ai/utils';
import { useCallback, useContext } from 'react';
import { DefaultVariantId } from '../../../../_shared/constants/variantIdValues.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { ContentItemFilterOrigin } from '../../../../_shared/models/events/ContentItemFilterEventData.type.ts';
import { getSelectedLanguageId } from '../../../../_shared/selectors/getSelectedLanguageId.ts';
import { getIsAiSearchUsed } from '../../../../paperModels/aiSearch/utils/getIsAiSearchUsed.ts';
import {
  onCollectionFilterChanged,
  onContentItemStatusFilterChanged,
  onContentTypeFilterChanged,
  onContributorsFilterChanged,
  onPublishingStatusFilterChanged,
  onSitemapFilterChanged,
  onSpaceFilterChanged,
  onTaxonomyGroupsFilterChanged,
  onWorkflowStatusFilterChanged,
} from '../../../contentInventory/content/features/ListingFilter/actions/listingFilterActions.ts';
import {
  createLinkedFilter,
  saveFilter,
} from '../../../contentInventory/content/features/ListingFilter/actions/thunkListingFilterActions.ts';
import { ContentItemFilter as ContentItemFilterComponent } from '../../../contentInventory/content/features/ListingFilter/components/ContentItemFilter.tsx';
import { PreselectedFilter } from '../../../contentInventory/content/features/ListingFilter/hooks/useSetUpContentItemFilter.ts';
import { isFullTextSearchUnavailableOrDisabled } from '../../../contentInventory/shared/selectors/fullTextSearchStatus.ts';
import { ContentTypeAsComponentUsageDialogContext } from '../../../contentModels/contentTypes/context/ContentTypeAsComponentUsageDialogContext.tsx';
import { useIsRefinedNavigationFeatureEnabled } from '../../../refinedNavigation/contexts/RefinedNavigationContext.tsx';
import {
  ContentStatusFilterSelectors,
  ContentStatusFilterSelectorsOld,
} from '../components/ContentStatusFilter/ContentStatusFilterSelectors.tsx';

type Props = Readonly<{
  clearFilter: () => void;
  forceContentTypesTooltipText?: string | undefined;
  forcedContentTypeIds?: ReadonlySet<Uuid>;
  hideVariantSpecificInfo?: boolean;
  preselectedFilter?: PreselectedFilter;
}>;

export const ContentStatusFilter = ({ clearFilter: clearFilterFromProps, ...props }: Props) => {
  const savedFiltersUi = useSelector((s) => s.contentApp.listingUi.savedFiltersUi);
  const filtersById = useSelector((s) => s.data.filters.byId);
  const storedFilters = Array.from(filtersById.values()).sort(
    createCompare({
      compare: alphabetically,
      select: (t) => t.name,
    }),
  );
  const filter = useSelector((s) => s.contentApp.listingUi.filter);
  const isFilterBeingCreated = useSelector(
    (s) => s.contentApp.listingUi.savedFiltersUi.isFilterBeingCreated,
  );
  const user = useSelector((s) => s.data.user);

  const isInContentTypeAsComponentUsageDialog = useContext(
    ContentTypeAsComponentUsageDialogContext,
  );
  const displayTaxonomyWarningStatus = useSelector((s) => {
    const fullTextSearchUnavailableOrDisabled = isFullTextSearchUnavailableOrDisabled(s);
    const isSelectedLanguageDefault = getSelectedLanguageId(s) === DefaultVariantId;
    return (
      fullTextSearchUnavailableOrDisabled &&
      (!isSelectedLanguageDefault || isInContentTypeAsComponentUsageDialog)
    );
  });

  const isAiSearchUsed = useSelector((s) => getIsAiSearchUsed(s.contentApp.listingUi.filter));

  const dispatch = useDispatch();

  const clearFilter = useCallback(() => {
    clearFilterFromProps();
  }, [clearFilterFromProps]);

  const isRefinedNavigationFeatureEnabled = useIsRefinedNavigationFeatureEnabled();

  return (
    <ContentItemFilterComponent
      filter={filter}
      filters={storedFilters}
      forcedContentTypeIds={props.forcedContentTypeIds}
      hideVariantSpecificInfo={props.hideVariantSpecificInfo}
      isFilterBeingCreated={isFilterBeingCreated}
      onClearFilter={clearFilter}
      onCreateLinkedFilter={() => dispatch(createLinkedFilter())}
      onSaveFilter={(name) => dispatch(saveFilter({ name }))}
      origin={ContentItemFilterOrigin.ContentStatus}
      renderSelectors={() =>
        isRefinedNavigationFeatureEnabled ? (
          <ContentStatusFilterSelectors
            displayTaxonomyWarningStatus={displayTaxonomyWarningStatus}
            filter={filter}
            forceContentTypesTooltipText={props.forceContentTypesTooltipText}
            forcedContentTypeIds={props.forcedContentTypeIds}
            isAiSearchUsed={isAiSearchUsed}
            onCollectionSelectionChanged={(ids) => dispatch(onCollectionFilterChanged(ids))}
            onContentItemStatusSelectionChanged={(status) =>
              dispatch(onContentItemStatusFilterChanged(status))
            }
            onContentTypesSelectionChanged={
              props.forcedContentTypeIds
                ? noOperation
                : (ids) => dispatch(onContentTypeFilterChanged(ids))
            }
            onContributorsSelectionChanged={(ids) => dispatch(onContributorsFilterChanged(ids))}
            onPublishingStatusSelectionChanged={(publishingStatus) =>
              dispatch(onPublishingStatusFilterChanged(publishingStatus))
            }
            onSitemapSelectionChanged={(selectedSitemapNodes) =>
              dispatch(onSitemapFilterChanged(selectedSitemapNodes))
            }
            onSpaceSelectionChanged={(ids) => dispatch(onSpaceFilterChanged(ids))}
            onTaxonomySelectionChanged={(groupToTerms) =>
              dispatch(onTaxonomyGroupsFilterChanged(groupToTerms))
            }
            onWorkflowsSelectionChanged={(...args) =>
              dispatch(onWorkflowStatusFilterChanged(...args))
            }
            user={user}
          />
        ) : (
          <ContentStatusFilterSelectorsOld
            displayTaxonomyWarningStatus={displayTaxonomyWarningStatus}
            filter={filter}
            forceContentTypesTooltipText={props.forceContentTypesTooltipText}
            forcedContentTypeIds={props.forcedContentTypeIds}
            isAiSearchUsed={isAiSearchUsed}
            onCollectionSelectionChanged={(ids) => dispatch(onCollectionFilterChanged(ids))}
            onContentItemStatusSelectionChanged={(status) =>
              dispatch(onContentItemStatusFilterChanged(status))
            }
            onContentTypesSelectionChanged={
              props.forcedContentTypeIds
                ? noOperation
                : (ids) => dispatch(onContentTypeFilterChanged(ids))
            }
            onContributorsSelectionChanged={(ids) => dispatch(onContributorsFilterChanged(ids))}
            onPublishingStatusSelectionChanged={(publishingStatus) =>
              dispatch(onPublishingStatusFilterChanged(publishingStatus))
            }
            onSitemapSelectionChanged={(selectedSitemapNodes) =>
              dispatch(onSitemapFilterChanged(selectedSitemapNodes))
            }
            onSpaceSelectionChanged={(ids) => dispatch(onSpaceFilterChanged(ids))}
            onTaxonomySelectionChanged={(groupToTerms) =>
              dispatch(onTaxonomyGroupsFilterChanged(groupToTerms))
            }
            onWorkflowsSelectionChanged={(...args) =>
              dispatch(onWorkflowStatusFilterChanged(...args))
            }
            user={user}
          />
        )
      }
      savedFilters={filtersById}
      savedFiltersUi={savedFiltersUi}
    />
  );
};
