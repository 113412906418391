import Immutable from 'immutable';
import { Loader, LoaderType } from '../../../../_shared/components/Loader.tsx';
import { SimpleScrollTable } from '../../../../_shared/uiComponents/ScrollTable/SimpleScrollTable.tsx';
import { DataUiCollection } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IContentType } from '../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IListingContentItem } from '../../../../data/models/listingContentItems/IListingContentItem.ts';
import { RelationsListingMessage } from '../containers/RelationsListingMessage.tsx';
import { IExpandedNodeData } from '../reducers/expandedNodesData.ts';
import { RelationsScrollTableHeadRow } from './RelationsScrollTableHeadRow.tsx';
import { RelationsScrollTableRowWithChildren } from './RelationsScrollTableRowWithChildren.tsx';

interface IRelationsScrollTableStateProps {
  readonly contentItems: Immutable.Map<Uuid, IListingContentItem>;
  readonly contentTypes: Immutable.Map<Uuid, IContentType>;
  readonly expandedNodesData: Immutable.Map<string, IExpandedNodeData>;
  readonly getCannotViewReasonForItem: (itemId: Uuid) => string | undefined;
  readonly getCannotOpenReasonForItem: (itemId: Uuid) => string | undefined;
  readonly getLinkForItem: (itemId: Uuid) => string;
  readonly isRootBeingPrepared?: boolean;
  readonly nodesBeingProcessed: Immutable.Set<string>;
}

interface IRelationsScrollTableDispatchProps {
  readonly onNodeCollapsed: (nodeId: string) => void;
  readonly onNodeExpanded: (nodeId: string, itemId: Uuid) => void;
}

export interface IRelationsScrollTableOwnProps {
  readonly rootId: Uuid;
}

type RelationsScrollTableProps = IRelationsScrollTableStateProps &
  IRelationsScrollTableDispatchProps &
  IRelationsScrollTableOwnProps;

export const RelationsScrollTable = ({
  contentItems,
  getCannotViewReasonForItem,
  getCannotOpenReasonForItem,
  contentTypes,
  expandedNodesData,
  isRootBeingPrepared,
  nodesBeingProcessed,
  onNodeCollapsed,
  onNodeExpanded,
  rootId,
  getLinkForItem,
}: RelationsScrollTableProps) => (
  <SimpleScrollTable
    fillAvailableSpace
    noScroll
    collectionName={DataUiCollection.ContentItems}
    renderTitle={() => <RelationsListingMessage />}
    renderHead={(showScrollBar: boolean) => (
      <RelationsScrollTableHeadRow showScrollBar={showScrollBar} />
    )}
  >
    {isRootBeingPrepared ? (
      <Loader type={LoaderType.Narrow} />
    ) : (
      <RelationsScrollTableRowWithChildren
        contentItems={contentItems}
        contentTypes={contentTypes}
        depth={0}
        expandedNodesData={expandedNodesData}
        itemId={rootId}
        nodesBeingProcessed={nodesBeingProcessed}
        onNodeCollapsed={onNodeCollapsed}
        onNodeExpanded={onNodeExpanded}
        getCannotViewMessageForItem={getCannotViewReasonForItem}
        getCannotOpenReasonForItem={getCannotOpenReasonForItem}
        getLinkForItem={getLinkForItem}
      />
    )}
  </SimpleScrollTable>
);
