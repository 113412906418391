import { forwardRef, memo } from 'react';
import { ScrollTableCell } from '../../../../_shared/uiComponents/ScrollTable/ScrollTableCell.tsx';
import { ScrollTableRow } from '../../../../_shared/uiComponents/ScrollTable/ScrollTableRow.tsx';
import { PlaceholderForCheckboxCell } from '../../../itemEditor/features/CascadePublish/components/PlaceholderForCheckboxCell.tsx';
import { EmptyCellContent } from '../../content/features/ContentItemInventory/components/ItemInventoryScrollTable/Cells/ContentItemScrollTableCellsContents.tsx';

interface IRelationsScrollTableOmittedRowProps {
  readonly depth: number;
  readonly objectName: string;
  readonly text: string;
  readonly title?: string;
}

export const RelationsScrollTableOmittedRow = memo(
  forwardRef<HTMLDivElement, IRelationsScrollTableOmittedRowProps>(
    ({ depth, objectName, text, title }, ref) => (
      <ScrollTableRow objectName={objectName} isPlaceholder ref={ref}>
        {Array(depth + 1)
          .fill(null)
          .map((_val, index) => (
            <PlaceholderForCheckboxCell key={index} />
          ))}

        <ScrollTableCell size={15} isGrowing title={title || text}>
          <div className="scroll-table__item-name">
            <div className="scroll-table__item-name-text scroll-table__item-name-text--placeholder">
              {text}
            </div>
          </div>
        </ScrollTableCell>

        <ScrollTableCell size={5}>
          <EmptyCellContent />
        </ScrollTableCell>

        <ScrollTableCell size={5}>
          <EmptyCellContent />
        </ScrollTableCell>

        <ScrollTableCell size={5}>
          <EmptyCellContent />
        </ScrollTableCell>
      </ScrollTableRow>
    ),
  ),
);

RelationsScrollTableOmittedRow.displayName = 'RelationsScrollTableOmittedRow';
