import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../../../@types/Dispatcher.type.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { AssetFileTypeOption } from '../../../../../contentInventory/content/models/assetFileTypeOptions.ts';
import {
  TopLevelBlockCategoryFeature,
  allTableBlockCategoryFeatures,
  allTextBlockTypeFeatures,
  allTextFormattingFeatures,
} from '../../../../../richText/plugins/apiLimitations/api/editorLimitationUtils.ts';
import {
  ITypeElementSummaryCallbackProps,
  ITypeElementSummaryDataProps,
  TypeElementSummary as TypeElementSummaryComponent,
} from '../../../components/typeElements/shared/TypeElementSummary.tsx';
import { LimitationsSetSummary } from '../../../constants/summaryMessageTemplates.ts';
import { IRichTextTypeElementData } from '../../../models/elements/RichTextTypeElementData.ts';
import { IBaseTypeElementSummaryProps } from '../../../types/IBaseTypeElementSummaryProps.type.ts';
import {
  getBaseTypeElementSummarySegments,
  joinSummarySegments,
} from '../../../utils/typeElementMessagesUtils.ts';

interface ITextTypeElementSummaryProps
  extends IBaseTypeElementSummaryProps<IRichTextTypeElementData> {}

export function getRichTextTypeElementSummary(typeElement: IRichTextTypeElementData): string {
  const isTextAllowed = typeElement.allowedBlocks.has(TopLevelBlockCategoryFeature.Text);
  const isTableAllowed = typeElement.allowedBlocks.has(TopLevelBlockCategoryFeature.Tables);
  const isImageAllowed = typeElement.allowedBlocks.has(TopLevelBlockCategoryFeature.Images);
  const isComponentAndItemAllowed = typeElement.allowedBlocks.has(
    TopLevelBlockCategoryFeature.ComponentsAndItems,
  );

  const hasTextLimitations =
    typeElement.allowedTextBlocks.size !== allTextBlockTypeFeatures.size ||
    typeElement.allowedTextFormatting.size !== allTextFormattingFeatures.size ||
    typeElement.allowedItemLinkTypes?.length ||
    typeElement.maxChars ||
    typeElement.maxWords;
  const hasTableLimitations =
    typeElement.allowedTableBlocks.size !== allTableBlockCategoryFeatures.size ||
    typeElement.allowedTableTextBlocks.size !== allTextBlockTypeFeatures.size ||
    typeElement.allowedTableTextFormatting.size !== allTextFormattingFeatures.size;
  const hasImageLimitations =
    typeElement.fileSize ||
    typeElement.minWidth ||
    typeElement.maxWidth ||
    typeElement.minHeight ||
    typeElement.maxHeight ||
    typeElement.fileType === AssetFileTypeOption.ResponsiveImages;
  const hasComponentAndItemLimitations = typeElement.allowedTypes?.length;

  const hasAnyLimitations =
    !isTextAllowed ||
    hasTextLimitations ||
    !isTableAllowed ||
    hasTableLimitations ||
    !isImageAllowed ||
    hasImageLimitations ||
    !isComponentAndItemAllowed ||
    hasComponentAndItemLimitations;

  const summarySegments: Array<string> = [...getBaseTypeElementSummarySegments(typeElement)];

  if (hasAnyLimitations) {
    summarySegments.push(LimitationsSetSummary);
  }

  return joinSummarySegments(summarySegments);
}

function mapStateToProps(
  _state: IStore,
  ownProps: ITextTypeElementSummaryProps,
): ITypeElementSummaryDataProps {
  const typeElement = ownProps.typeElementData;

  const summaryText = getRichTextTypeElementSummary(typeElement);

  return {
    isActive: ownProps.isActive,
    summaryText,
  };
}

function mapDispatchToProps(
  _dispatch: Dispatch,
  ownProps: ITextTypeElementSummaryProps,
): ITypeElementSummaryCallbackProps {
  return ownProps;
}

export const RichTextTypeElementSummary: React.ComponentType<ITextTypeElementSummaryProps> =
  connect(mapStateToProps, mapDispatchToProps)(TypeElementSummaryComponent);
