import { Box } from '@kontent-ai/component-library/Box';
import React, { PropsWithChildren } from 'react';
import { useThrottledCallback } from 'use-debounce';
import useResizeObserver from 'use-resize-observer';
import { useWebSpotlightInItemEditing } from '../context/WebSpotlightInItemEditingContext.tsx';

const rescaleThrottleIntervalMs = 200;

export const ContentItemPreviewResizer: React.FC<PropsWithChildren> = ({ children }) => {
  const { previewPaneRef, rescalePreviewToFit } = useWebSpotlightInItemEditing();
  const onResize = useThrottledCallback(rescalePreviewToFit, rescaleThrottleIntervalMs);

  useResizeObserver({
    ref: previewPaneRef,
    onResize,
  });

  return (
    <Box ref={previewPaneRef} width="100%" height="100%">
      {children}
    </Box>
  );
};
