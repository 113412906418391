import { getCurrentProjectPlan } from '../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { IStore } from '../../../_shared/stores/IStore.type.ts';

export const areLanguageRolesEnabledForCurrentProject = (state: IStore): boolean =>
  getCurrentProjectPlan(state).features.areLanguageRolesEnabled;

export const areContentCollectionsPermissionsEnabledForCurrentProjectPlan = (
  state: IStore,
): boolean => getCurrentProjectPlan(state).features.areContentCollectionsPermissionsEnabled;

export const isAssetCollectionMandatoryForCurrentSubscription = (state: IStore): boolean =>
  state.sharedApp.subscriptionProperties.AssetCollectionMandatory === 'Enabled';

export const isAssetCollectionMandatoryForCurrentSubscriptionOrProject = (state: IStore): boolean =>
  state.sharedApp.projectProperties.AssetCollectionMandatory === 'Enabled' ||
  isAssetCollectionMandatoryForCurrentSubscription(state);

export const areSpacesInCollectionsEnabled = (state: IStore): boolean =>
  state.sharedApp.generalProperties.enableSpacesInCollections ||
  state.sharedApp.projectProperties.SpacesInCollections === 'Enabled';
