import { memoize } from '@kontent-ai/memoization';
import { createCompare, naturally } from '@kontent-ai/utils';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { ITaxonomyGroup } from '../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import {
  ITaxonomyOption,
  TaxonomyTypeElement as TaxonomyTypeElementComponent,
} from '../../components/typeElements/individualTypeElements/taxonomy/TaxonomyTypeElement.tsx';
import { INumberLimit } from '../../components/typeElements/shared/configuration/NumberLimit.tsx';
import { ITaxonomyTypeElementData } from '../../models/elements/TaxonomyTypeElementData.ts';
import { ITypeElementOwnProps } from '../../types/ITypeElementProps.type.ts';
import { TaxonomyTypeElementValidationResult } from '../../utils/typeElementValidators/types/TaxonomyTypeElementValidationResult.type.ts';

const getSortedTaxonomyGroups = memoize.weak(
  (byId: Immutable.Map<Uuid, ITaxonomyGroup>): ReadonlyArray<ITaxonomyGroup> => {
    return byId
      .sort(
        createCompare({
          compare: naturally,
          select: (t) => t.name,
        }),
      )
      .toArray();
  },
);

export const TaxonomyTypeElement: React.FC<
  ITypeElementOwnProps<ITaxonomyTypeElementData, TaxonomyTypeElementValidationResult>
> = (props) => {
  const { onChange, typeElementData } = props;

  const { taxonomyGroupId } = typeElementData;

  const sortedTaxonomyGroups = useSelector((s) =>
    getSortedTaxonomyGroups(s.data.taxonomyGroups.byId),
  );
  const selectedTaxonomyGroup = sortedTaxonomyGroups.find(
    (group: ITaxonomyGroup) => group.id === taxonomyGroupId,
  );
  const selectedTaxonomyGroupName = selectedTaxonomyGroup?.name ?? '';
  const isElementNameInSyncWithTaxonomyGroupName =
    typeElementData.name === selectedTaxonomyGroupName;

  const taxonomyGroupsOptions = useMemo((): ReadonlyArray<ITaxonomyOption> => {
    return sortedTaxonomyGroups.map((taxonomyGroup: ITaxonomyGroup): ITaxonomyOption => {
      return {
        id: taxonomyGroup.id,
        label: taxonomyGroup.name,
        taxonomyGroup,
      };
    });
  }, [sortedTaxonomyGroups]);

  const updateTermsLimit = useCallback(
    (updatedLimit: INumberLimit): void => {
      onChange({
        ...typeElementData,
        _quantityUnitOption: updatedLimit._limitOption,
        maxItems: updatedLimit.maxLimit,
        minItems: updatedLimit.minLimit,
      });
    },
    [onChange, typeElementData],
  );

  const updateDefaultValue = useCallback(
    (defaultValue: UuidArray) => {
      onChange({
        ...typeElementData,
        defaultValue,
      });
    },
    [onChange, typeElementData],
  );

  const updateTaxonomyGroup = useCallback(
    (updatedTaxonomyGroupId: string | null, name: string | undefined): void => {
      onChange({
        ...typeElementData,
        defaultValue: [],
        ...(name ? { name } : {}),
        taxonomyGroupId: updatedTaxonomyGroupId,
      });
    },
    [onChange, typeElementData],
  );

  const handleTaxonomyChange = useCallback(
    (nodeId: string | null, selectedTaxonomyOptionNode: ITaxonomyOption): void => {
      if (!nodeId) {
        updateTaxonomyGroup(null, '');
      }

      const updatedTaxonomyGroup = selectedTaxonomyOptionNode.taxonomyGroup;
      const taxonomyGroupName = isElementNameInSyncWithTaxonomyGroupName
        ? updatedTaxonomyGroup.name
        : undefined;

      updateTaxonomyGroup(updatedTaxonomyGroup.id, taxonomyGroupName);
    },
    [updateTaxonomyGroup, isElementNameInSyncWithTaxonomyGroupName],
  );

  return (
    <TaxonomyTypeElementComponent
      onDefaultValueChange={updateDefaultValue}
      onTaxonomyGroupChange={handleTaxonomyChange}
      onTermsLimitChange={updateTermsLimit}
      selectedTaxonomyGroup={selectedTaxonomyGroup}
      taxonomyGroupsOptions={taxonomyGroupsOptions}
      {...props}
    />
  );
};
