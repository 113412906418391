import { Icons } from '@kontent-ai/component-library/Icons';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import React from 'react';

export const NotTranslatedBadge: React.FC<{
  readonly size?: number;
  readonly tooltipText?: string;
}> = ({ size, tooltipText }) => (
  <Tooltip text={tooltipText ?? 'Not translated'} placement="top">
    <Icons.CircleDashed fontSize={size} />
  </Tooltip>
);

NotTranslatedBadge.displayName = 'NotTranslatedBadge';
