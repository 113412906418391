import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { noOperation } from '@kontent-ai/utils';
import classNames from 'classnames';
import React from 'react';
import { RedactedItemName } from '../../../contentInventory/content/constants/uiConstants.ts';
import { TreeItemExpandButton } from './TreeItemExpandButton.tsx';

interface IWebSpotlightRedactedTreeItemProps {
  readonly isSelected: boolean;
  readonly tooltip: string;
}

export const WebSpotlightRedactedTreeItem: React.FC<IWebSpotlightRedactedTreeItemProps> = ({
  tooltip,
  isSelected,
}) => (
  <div
    className={classNames('web-spotlight-tree__item', 'web-spotlight-tree__item--is-disabled', {
      'web-spotlight-tree__item--is-selected': isSelected,
    })}
  >
    <TreeItemExpandButton
      hasSubpages={false}
      isExpanded={false}
      isVariantTranslated={false}
      onClick={noOperation}
    />
    <Tooltip text={tooltip} placement="bottom-start">
      <div className="web-spotlight-tree__item-name web-spotlight-tree__item-name--is-redacted">
        <span className="web-spotlight-tree__item-name-text">{RedactedItemName}</span>
      </div>
    </Tooltip>
  </div>
);

WebSpotlightRedactedTreeItem.displayName = 'WebSpotlightRedactedTreeItem';
