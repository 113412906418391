import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import classNames from 'classnames';
import React from 'react';
import { IconName } from '../../../../../../_shared/constants/iconEnumGenerated.ts';
import { Icon } from '../../../../../../_shared/uiComponents/Icon/Icon.tsx';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface ITypeElementConfigurationButtonProps {
  readonly isActive: boolean;
  readonly onClick: () => void;
}

export const TypeElementConfigurationButton: React.FC<ITypeElementConfigurationButtonProps> = ({
  isActive,
  onClick,
}) => (
  <Tooltip text={isActive ? 'Hide configuration' : 'Show configuration'} placement="top-end">
    <div
      className={classNames('content-element__action', {
        'content-element__action--is-active': isActive,
      })}
    >
      <Icon
        iconName={IconName.Cogwheel}
        onClick={onClick}
        dataAttribute={getDataUiActionAttribute(DataUiAction.Configure)}
      />
    </div>
  </Tooltip>
);

TypeElementConfigurationButton.displayName = 'TypeElementConfigurationButton';
