import { Box } from '@kontent-ai/component-library/Box';
import { Inline } from '@kontent-ai/component-library/Inline';
import { SingleSelect } from '@kontent-ai/component-library/SingleSelect';
import { Spacing, Typography, colorTextDefault } from '@kontent-ai/component-library/tokens';
import React, { useMemo } from 'react';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { getCurrentProjectId } from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { getAllUniqueContentTypesWithViewCapability } from '../../utils/getAllUniqueContentTypesWithViewCapability.ts';
import { getContentPaceTypeSelectOptions } from '../utils/getContentPaceTypeSelectOptions.ts';

interface IContentPaceContentTypeSelectorProps {
  readonly selectedContentTypeId: Uuid | null;
  readonly onSelectionChange: (selectedContentTypeId: Uuid | null) => void;
}

export const ContentPaceContentTypeSelector: React.FC<IContentPaceContentTypeSelectorProps> = ({
  selectedContentTypeId,
  onSelectionChange,
}) => {
  const user = useSelector((s) => s.data.user);
  const currentProjectId = useSelector(getCurrentProjectId);
  const languages = useSelector((s) => s.data.languages);
  const contentTypesById = useSelector((s) => s.data.contentTypes.byId);
  const contentTypes = useMemo(() => contentTypesById.valueSeq().toArray(), [contentTypesById]);

  const options = useMemo(() => {
    return getContentPaceTypeSelectOptions(
      getAllUniqueContentTypesWithViewCapability(user, currentProjectId, languages, contentTypes),
    );
  }, [user, currentProjectId, languages, contentTypes]);

  if (contentTypes.length < 2) {
    return null;
  }

  return (
    <Inline align="center" spacingX={Spacing.S}>
      <Box<'label'>
        component="label"
        color={colorTextDefault}
        htmlFor="content-type"
        typography={Typography.BodyMedium}
      >
        Content type:
      </Box>
      <SingleSelect
        aria-label="Select a content type"
        id="content-type"
        items={options}
        selectedItemId={selectedContentTypeId}
        onSelectionChange={onSelectionChange}
        placeholder="All content types"
      />
    </Inline>
  );
};

ContentPaceContentTypeSelector.displayName = 'ContentPaceContentTypeSelector';
