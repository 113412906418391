import { memoize } from '@kontent-ai/memoization';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../@types/Dispatcher.type.ts';
import { trackUserEvent } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import { IconName } from '../../../../_shared/constants/iconEnumGenerated.ts';
import { TrackedEvent } from '../../../../_shared/constants/trackedEvent.ts';
import { IFeatures } from '../../../../_shared/models/Features.ts';
import { GuidelinesEventTypes } from '../../../../_shared/models/TrackUserEventData.ts';
import { getProjectPlan } from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { ISnippets } from '../../../../data/reducers/snippets/ISnippets.type.ts';
import { ITaxonomyGroups } from '../../../../data/reducers/taxonomyGroups/ITaxonomyGroups.type.ts';
import {
  ElementType,
  TypeElementType,
} from '../../../contentInventory/content/models/ContentItemElementType.ts';
import { isWebSpotlightEnabled } from '../../../webSpotlight/selectors/webSpotlightSelectors.ts';
import {
  typeElementDropped,
  typeElementPickedUp,
} from '../../shared/actions/sharedContentModelsActions.ts';
import { addNewTypeElement } from '../../shared/actions/thunkSharedContentModelsActions.ts';
import {
  TypeElementLibrary as ContentTypeElementLibraryComponent,
  ITypeElementLibraryDispatchProps,
  ITypeElementLibraryStateProps,
  TypeElementItemConfig,
} from '../../shared/components/TypeElementLibrary.tsx';
import { NewElementId } from '../../shared/reducers/typeEditor/draggedElementId.ts';
import { isSnippetTypeElement } from '../../shared/types/typeElementTypeGuards.ts';
import { elementTypeNameMap } from '../../shared/utils/typeElementsUtils.ts';

const getItemElementsConfigs = memoize.maxOne(
  (features: IFeatures): ReadonlyArray<TypeElementItemConfig> => {
    return [
      {
        name: elementTypeNameMap[ElementType.Text],
        elementType: ElementType.Text,
        iconName: IconName.ParagraphShort,
      },
      {
        name: elementTypeNameMap[ElementType.RichText],
        elementType: ElementType.RichText,
        iconName: IconName.Paragraph,
      },
      {
        name: elementTypeNameMap[ElementType.Number],
        elementType: ElementType.Number,
        iconName: IconName.Octothorpe,
      },
      {
        name: elementTypeNameMap[ElementType.MultipleChoice],
        elementType: ElementType.MultipleChoice,
        iconName: IconName.CbCheck,
      },
      {
        name: elementTypeNameMap[ElementType.DateTime],
        elementType: ElementType.DateTime,
        iconName: IconName.Calendar,
      },
      {
        name: elementTypeNameMap[ElementType.Asset],
        elementType: ElementType.Asset,
        iconName: IconName.Picture,
      },
      {
        name: elementTypeNameMap[ElementType.LinkedItems],
        elementType: ElementType.LinkedItems,
        iconName: IconName.Puzzle,
      },
      {
        name: elementTypeNameMap[ElementType.Custom],
        elementType: ElementType.Custom,
        iconName: IconName.CustomElement,
        isDisabled: !features.areCustomElementsEnabled,
        disabledMessage:
          'Custom elements are not available \nin your plan.\nUpgrade your subscription.',
      },
    ];
  },
);

const getAuxiliaryElementsConfig = memoize.maxOne(
  (
    taxonomyGroups: ITaxonomyGroups,
    snippets: ISnippets,
    subpagesElementEnabled: boolean,
    contentTypeContainsUrlSlug: boolean,
    contentTypeContainsSubpages: boolean,
    allSnippetsAlreadyUsed: boolean,
  ): ReadonlyArray<TypeElementItemConfig> => {
    const taxonomyGroupExists = !taxonomyGroups.byId.isEmpty();
    const isSnippetListEmpty = snippets.byId.isEmpty();
    const snippetDisabledMessage = isSnippetListEmpty
      ? 'Create at least one content type snippet\nto use this element.'
      : 'You can add this element only as\nmany times as there are snippets.';

    const baseElements: Array<TypeElementItemConfig> = [
      {
        name: elementTypeNameMap[ElementType.Guidelines],
        elementType: ElementType.Guidelines,
        iconName: IconName.SchemePathCircles,
      },
      {
        name: elementTypeNameMap[ElementType.Taxonomy],
        elementType: ElementType.Taxonomy,
        iconName: IconName.Drawers,
        isDisabled: !taxonomyGroupExists,
        disabledMessage: 'Create at least one taxonomy group\nto use this element.',
      },
      {
        name: elementTypeNameMap[ElementType.UrlSlug],
        elementType: ElementType.UrlSlug,
        iconName: IconName.Chain,
        isDisabled: contentTypeContainsUrlSlug,
        disabledMessage: 'You can have only one URL slug\nelement in a content type.',
      },
      {
        name: elementTypeNameMap[ElementType.ContentTypeSnippet],
        elementType: ElementType.ContentTypeSnippet,
        iconName: IconName.LListArticle,
        isDisabled: isSnippetListEmpty || allSnippetsAlreadyUsed,
        disabledMessage: snippetDisabledMessage,
      },
    ];

    if (subpagesElementEnabled) {
      return baseElements.concat({
        name: elementTypeNameMap[ElementType.Subpages],
        elementType: ElementType.Subpages,
        iconName: IconName.ParentChildScheme,
        isDisabled: contentTypeContainsSubpages,
        disabledMessage: 'You can have only one Subpages\nelement in a content type.',
      });
    }

    return baseElements;
  },
);

function mapStateToProps(state: IStore): ITypeElementLibraryStateProps {
  const {
    sharedApp: { currentProjectId },
    contentModelsApp: {
      typeEditor: {
        editedType: { typeElements },
      },
    },
    data: { taxonomyGroups, snippets },
  } = state;

  const currentProjectPlan = getProjectPlan(state, currentProjectId);
  const subpagesElementEnabled = isWebSpotlightEnabled(state);

  const contentTypeContainsUrlSlug = typeElements.some(
    (e) => !!e && e.type === ElementType.UrlSlug,
  );
  const contentTypeContainsSubpages = typeElements.some(
    (e) => !!e && e.type === ElementType.Subpages,
  );
  const allSnippetsAlreadyUsed = snippets.byId
    .keySeq()
    .every((snippetId) =>
      typeElements.some((el) => isSnippetTypeElement(el) && el.snippetId === snippetId),
    );

  return {
    contentElementToolbarItemConfigurations: getItemElementsConfigs(currentProjectPlan.features),
    auxiliaryElementToolbarItemConfigurations: getAuxiliaryElementsConfig(
      taxonomyGroups,
      snippets,
      subpagesElementEnabled,
      contentTypeContainsUrlSlug,
      contentTypeContainsSubpages,
      allSnippetsAlreadyUsed,
    ),
  };
}

function mapDispatchToProps(dispatch: Dispatch): ITypeElementLibraryDispatchProps {
  return {
    onContentElementClicked: (elementType: TypeElementType) => {
      if (elementType === ElementType.Guidelines) {
        dispatch(trackUserEvent(TrackedEvent.Guidelines, { action: GuidelinesEventTypes.Created }));
      }

      dispatch(addNewTypeElement(elementType));
    },
    onDragEnd: () => dispatch(typeElementDropped()),
    onDragStart: () => dispatch(typeElementPickedUp(NewElementId)),
  };
}

export const ContentTypeElementLibrary: React.ComponentType = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContentTypeElementLibraryComponent);
