import {
  BorderRadius,
  BoxShadow,
  colorBackgroundDragged,
  px,
} from '@kontent-ai/component-library/tokens';
import styled, { css } from 'styled-components';

type StyledDraggingPlaceholderProps = {
  readonly isDragging?: boolean;
};

export const StyledDraggingPlaceholder = styled.div<StyledDraggingPlaceholderProps>`
${({ isDragging }) =>
  isDragging &&
  css`
   background-color: ${colorBackgroundDragged};
   background-image: none;
   border-radius: ${px(BorderRadius.M)};
   box-shadow: ${BoxShadow.M};

   > * {
     opacity: 0;
   }`}`;
