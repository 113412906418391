import { isElement } from '@kontent-ai/DOM';
import { getFocusableTreeWalker } from '@react-aria/focus';
import { useFocus, useInteractionModality } from '@react-aria/interactions';
import { ReactNode, useRef } from 'react';
import { defaultTabbingOptions } from '../utils/focusUtils.ts';

type Props = {
  readonly children: ReactNode;
};

export const TabSkipContainer = ({ children }: Props) => {
  const firstRef = useRef<HTMLDivElement>(null);
  const lastRef = useRef<HTMLDivElement>(null);
  const modality = useInteractionModality();

  const { focusProps: firstFocusProps } = useFocus({
    onFocus: () => {
      if (firstRef.current === null || lastRef.current === null || modality !== 'keyboard') return;

      const walker = getFocusableTreeWalker(document.documentElement, defaultTabbingOptions);
      walker.currentNode = lastRef.current;

      const nextNode = walker.nextNode();
      if (isElement(nextNode)) {
        nextNode.focus();
      }
    },
  });

  const { focusProps: lastFocusProps } = useFocus({
    onFocus: () => {
      if (firstRef.current === null || lastRef.current === null || modality !== 'keyboard') return;

      const walker = getFocusableTreeWalker(document.documentElement, defaultTabbingOptions);
      walker.currentNode = firstRef.current;

      const previousNode = walker.previousNode();
      if (isElement(previousNode)) {
        previousNode.focus();
      }
    },
  });

  return (
    <>
      <div {...firstFocusProps} aria-hidden ref={firstRef} tabIndex={0} />
      {children}
      <div {...lastFocusProps} aria-hidden ref={lastRef} tabIndex={0} />
    </>
  );
};
