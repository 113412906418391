import React from 'react';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { getCurrentProjectName } from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { showRelationsRootSelector } from '../actions/relationsActions.ts';
import { RelationsEmptyState as RelationsEmptyStateComponent } from '../components/RelationsEmptyState.tsx';

export const RelationsEmptyState: React.FC = () => {
  const projectName = useSelector(getCurrentProjectName);
  const dispatch = useDispatch();
  return (
    <RelationsEmptyStateComponent
      projectName={projectName}
      onCallToAction={() => dispatch(showRelationsRootSelector())}
    />
  );
};
