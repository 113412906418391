import {
  FileSizeUnit,
  getFileSizeOptionName,
} from '../../../../app/applications/contentInventory/content/models/fileSizeOptions.ts';
import { FormattedFileSize } from '../../../../app/applications/contentModels/shared/utils/typeElementDataConverters/utils/types/IFormattedFileSize.type.ts';

const metadataFileTypeMaxLength = 4;
const metadataFileSizeLength = 4;

export const formatFileSize = (fileSizeObject: FormattedFileSize | null) => {
  if (!fileSizeObject) {
    return `0 ${getFileSizeOptionName(FileSizeUnit.Byte)}`;
  }

  const size = Number.parseFloat(fileSizeObject.fileSize).toPrecision(metadataFileSizeLength);
  const units = getFileSizeOptionName(fileSizeObject.unit);
  return `${Number.parseFloat(size)} ${units}`;
};

export const formatFileType = (fileType: string) => {
  return fileType.substring(0, metadataFileTypeMaxLength).toUpperCase();
};

export const computeAssetSizeLabelLength = (width: number | null, height: number | null) => {
  const widthString = width?.toString() ?? '0';
  const heightString = height?.toString() ?? '0';
  return widthString.length + heightString.length;
};

export const parseSizeValue = (value: number | null, maxLength: number) => {
  const valueAsString = value?.toString() ?? '0';
  const isLengthExceeded = valueAsString.length > maxLength;
  return {
    value: isLengthExceeded ? `${valueAsString.substr(0, maxLength)}…` : valueAsString,
    isTruncated: isLengthExceeded,
  };
};
