import { useContext } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { ItemEditingNewDesignContext } from '../../../context/ItemEditingNewDesignContext.tsx';
import { getSpacesForPreviewUrls } from '../../../utils/getAvailableSpacesForPreviewUrls.ts';
import {
  OldSpaceSelector,
  SpaceSelector as SpaceSelectorComponent,
} from '../components/SpaceSelector.tsx';
import { usePreviewSpace } from '../context/PreviewSpaceContext.tsx';
import { updateRouteWithNewRootItemId } from '../utils/updateRouteWithNewRootItemId.ts';

export const SpaceSelector = () => {
  const availableSpaces = useSelector(getSpacesForPreviewUrls);
  const isNewDesign = useContext(ItemEditingNewDesignContext);
  const { spaceId, setSpaceId } = usePreviewSpace();

  const history = useHistory();

  const updateSpace = (newSpaceId: Uuid) => {
    setSpaceId(newSpaceId);

    const newRootItemId = availableSpaces.find(
      (space) => space.id === newSpaceId,
    )?.webSpotlightRootItemId;
    if (newRootItemId) {
      updateRouteWithNewRootItemId(history, newRootItemId);
    }
  };

  if (!availableSpaces.length) return null;

  const spaceMenuItems = availableSpaces.map((space) => ({
    id: space.id,
    label: space.name,
  }));

  if (!isNewDesign) {
    return (
      <OldSpaceSelector
        onSelectionChange={updateSpace}
        selectedSpaceId={spaceId}
        spaceMenuItems={spaceMenuItems}
      />
    );
  }

  return (
    <SpaceSelectorComponent
      onSelectionChange={updateSpace}
      selectedSpaceId={spaceId}
      spaceMenuItems={spaceMenuItems}
    />
  );
};
