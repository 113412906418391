import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { Box } from '@kontent-ai/component-library/Box';
import { Button } from '@kontent-ai/component-library/Button';
import { Card } from '@kontent-ai/component-library/Card';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { documentationLinks } from '../../../../_shared/constants/documentationLinks.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
  getDataUiObjectNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { CardStatus } from './CardStatus.tsx';

const getButtonText = (
  isSettingToggleInProgress: boolean,
  isLivePreviewEnabled: boolean,
): string =>
  isSettingToggleInProgress
    ? getButtonTextInTransition(isLivePreviewEnabled)
    : getButtonTextAtRest(isLivePreviewEnabled);

const getButtonTextInTransition = (isLivePreviewEnabled: boolean) =>
  isLivePreviewEnabled ? 'Deactivating' : 'Activating';

const getButtonTextAtRest = (isLivePreviewEnabled: boolean) =>
  isLivePreviewEnabled ? 'Deactivate live preview' : 'Activate live preview';

type Props = {
  readonly isSettingToggleInProgress: boolean;
  readonly isLivePreviewEnabled: boolean;
  readonly toggleLivePreviewStatus: () => void;
};

export const LivePreviewStatusTile: React.FC<Props> = ({
  isSettingToggleInProgress,
  isLivePreviewEnabled,
  toggleLivePreviewStatus,
}) => {
  return (
    <Card
      component="section"
      {...(LivePreviewStatusTile.displayName &&
        getDataUiObjectNameAttribute(LivePreviewStatusTile.displayName))}
    >
      <Card.Headline
        renderAuxiliaryElements={() => <CardStatus isEnabled={isLivePreviewEnabled} />}
      >
        Live preview
      </Card.Headline>
      <Card.Body>
        <Stack spacing={Spacing.XL}>
          <Box>
            Live preview provides a real-time view of your content across your channels. Quickly
            review how your content looks while editing by switching on the toggle.
            <br />
            To set up the live preview, see the{' '}
            <OutwardLink href={documentationLinks.previewSecurityRequirements}>
              security requirements
            </OutwardLink>
            .
          </Box>
          <Box>
            <Button
              buttonStyle="secondary"
              buttonDisplay="inline"
              destructive={isLivePreviewEnabled}
              destructiveIcon={Icons.Drawers}
              disabled={isSettingToggleInProgress}
              onClick={toggleLivePreviewStatus}
              size="small"
              {...getDataUiActionAttribute(DataUiAction.ToggleLivePreviewFeature)}
            >
              {isSettingToggleInProgress && <Button.ProgressIcon />}
              <Button.Label>
                {getButtonText(isSettingToggleInProgress, isLivePreviewEnabled)}
              </Button.Label>
            </Button>
          </Box>
        </Stack>
      </Card.Body>
    </Card>
  );
};

LivePreviewStatusTile.displayName = 'LivePreviewStatusTile';
