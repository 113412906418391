import { useCallback } from 'react';
import { useHistory, useParams } from 'react-router';
import { AccessibleLoader } from '../../../../../_shared/components/AccessibleLoader.tsx';
import { AssetRouteParams } from '../../../../../_shared/constants/routePaths.ts';
import { HandleUnsavedFormOnNavigation } from '../../../../../_shared/containers/HandleUnsavedFormOnNavigation.tsx';
import { useIsAiWorkingOnImageDescription } from '../../../../../_shared/hooks/Assets/useIsAiWorkingOnImageDescription.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../../_shared/hooks/useThunkPromise.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import { isAiImageTaggingEnabled } from '../../../../../_shared/selectors/aiSelectors.ts';
import { getBreadcrumbsOrigin } from '../../../../../_shared/selectors/breadcrumbs/getBreadcrumbsOrigin.ts';
import { getPreviouslyOpenAssetFolderPath } from '../../../../../_shared/utils/breadcrumbs/getPreviouslyOpenAssetFolderPath.ts';
import { IAsset } from '../../../../../data/models/assets/Asset.ts';
import { setEditedAsset } from '../../actions/assetLibraryActions.ts';
import {
  deleteEditedAsset,
  initializeAssetEditing,
  trackUserActionOnAssetUpdate,
  updateAsset,
} from '../../actions/thunkAssetLibraryActions.ts';
import { AssetEditingPage as AssetEditingPageComponent } from '../../components/AssetEditing/AssetEditingPage.tsx';
import { aiIsWorkingOnDescription } from '../../constants/aiConstants.ts';
import { RootFolderId } from '../../constants/assetFolderConstants.ts';
import { getAssetAlreadyModifiedSaveButtonTooltip } from '../../utils/getAssetAlreadyModifiedMessageUtils.ts';

interface Props {
  readonly assetId: Uuid;
}

export const AssetEditingPage = ({ assetId }: Props) => {
  const history = useHistory();

  const [isInitThunkDone] = useThunkPromise(initializeAssetEditing, assetId, history);

  const { assetEditorLoadingStatus, editedAsset, isEditedAssetBeingSaved, isEditedAssetModified } =
    useSelector((state) => state.assetLibraryApp.assetUiEditor);

  const dispatch = useDispatch();

  const originalAsset = useSelector((state) => state.data.assets.byId.get(assetId));

  const { projectId } = useParams<AssetRouteParams>();
  const originFolderPath = useSelector((s) =>
    getPreviouslyOpenAssetFolderPath(getBreadcrumbsOrigin(s), projectId, RootFolderId),
  );

  const onChange = useCallback(
    (updatedAsset: IAsset) => dispatch(setEditedAsset(updatedAsset)),
    [],
  );

  const onDeleteAsset = useCallback(async () => {
    await dispatch(deleteEditedAsset());
    history.push(originFolderPath);
  }, [history, originFolderPath]);

  const onSaveAsset = useCallback(async (): Promise<void> => {
    if (!editedAsset) return;

    await dispatch(updateAsset(editedAsset));
    dispatch(trackUserActionOnAssetUpdate(editedAsset));
  }, [editedAsset]);

  const unsavedNavigationHandler = useCallback(
    async (onSuccess: () => void, onFail: () => void): Promise<void> => {
      try {
        await onSaveAsset();
        onSuccess();
      } catch {
        onFail();
      }
    },
    [onSaveAsset],
  );

  const isAiWorkingOnImageDescription = useIsAiWorkingOnImageDescription(assetId);
  const hasConflict = useSelector((s) => s.assetLibraryApp.assetUiEditor.hasConflict);
  const isAiImageRecognitionEnabled = useSelector(isAiImageTaggingEnabled);

  if (!isInitThunkDone || assetEditorLoadingStatus !== LoadingStatus.Loaded) {
    return <AccessibleLoader screenReaderText="Loading asset editing" />;
  }

  return (
    <>
      <HandleUnsavedFormOnNavigation
        hasUnsavedChanges={isEditedAssetModified}
        isBeingSaved={isEditedAssetBeingSaved}
        onSaveChanges={unsavedNavigationHandler}
      />
      <AssetEditingPageComponent
        assetTitle={editedAsset?.title ?? null}
        assetTitlePlaceholder={originalAsset?.filename ?? null}
        disabledSaveButtonTooltip={getDisabledSaveButtonTooltip({
          hasConflict,
          isAiWorkingOnImageDescription,
          isAiImageRecognitionEnabled,
        })}
        hasUnsavedChanges={isEditedAssetModified}
        isBeingSaved={isEditedAssetBeingSaved}
        onDeleteAsset={onDeleteAsset}
        onChange={onChange}
        onSaveAsset={onSaveAsset}
      />
    </>
  );
};

const getDisabledSaveButtonTooltip = ({
  hasConflict,
  isAiImageRecognitionEnabled,
  isAiWorkingOnImageDescription,
}: {
  readonly hasConflict: boolean;
  readonly isAiImageRecognitionEnabled: boolean;
  readonly isAiWorkingOnImageDescription: boolean;
}): string | undefined => {
  if (isAiWorkingOnImageDescription) {
    return aiIsWorkingOnDescription;
  }

  if (hasConflict) {
    return getAssetAlreadyModifiedSaveButtonTooltip(isAiImageRecognitionEnabled);
  }

  return undefined;
};
