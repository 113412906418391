import { Button } from '@kontent-ai/component-library/Button';
import { Checkbox } from '@kontent-ai/component-library/Checkbox';
import { Collection, createGuid } from '@kontent-ai/utils';
import classNames from 'classnames';
import { useState } from 'react';
import { ConfirmationDialog } from '../../../../../../../_shared/components/ModalDialog/ConfirmationDialog.tsx';
import { ValidationConstants } from '../../../../../../../_shared/constants/validationConstants.ts';
import { MultipleChoiceMode } from '../../../../../../../_shared/models/MultipleChoiceModeEnum.ts';
import { IMultipleChoiceOptionData } from '../../../../../../../_shared/models/MultipleChoiceOption.type.ts';
import {
  DataUiAction,
  DataUiInput,
  getDataUiActionAttribute,
  getDataUiInputAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { isContentTypeUsed } from '../../../../../../../data/models/contentModelsApp/contentTypes/ContentTypeUsage.ts';
import { untitledMultipleChoiceOptionName } from '../../../../constants/uiConstants.ts';
import { IMultipleChoiceTypeElementData } from '../../../../models/elements/MultipleChoiceTypeElementData.ts';
import {
  ITypeElementDataProps,
  ITypeElementOwnProps,
} from '../../../../types/ITypeElementProps.type.ts';
import { MultipleChoiceTypeElementValidationResult } from '../../../../utils/typeElementValidators/types/MultipleChoiceTypeElementValidationResult.type.ts';
import { TypeElementConfigurationCategory } from '../../shared/TypeElementConfigurationCategory.tsx';
import { TypeElementConfigurationSection } from '../../shared/TypeElementConfigurationSection.tsx';
import { TypeElementWithTypedName } from '../../shared/TypeElementWithTypedName.tsx';
import { DraggableMultipleChoiceOption } from './DraggableMultipleChoiceOption.tsx';
import { MultipleChoiceDefaultValueSelector } from './MultipleChoiceDefaultValueSelector.tsx';

const modeChangeMessage = `Switching from checkboxes to a single choice drop-down will affect existing content items or components.
     When you switch, only the first selected option will remain selected in the existing content items or components.`;

const optionRemoveMessage =
  'By deleting a multiple choice option from the content type you also delete the option from existing content items or components.';

export interface IMultipleChoiceTypeElementStateProps
  extends ITypeElementDataProps<
    IMultipleChoiceTypeElementData,
    MultipleChoiceTypeElementValidationResult
  > {
  readonly allMultipleChoiceOptionCodenames: ReadonlySet<string>;
  readonly isDragging: boolean;
  readonly editedMultipleChoiceOptionId: string;
  readonly editedMultipleChoiceOptionLabel: string;
  readonly originalTypeElementData?: IMultipleChoiceTypeElementData;
  readonly sortedMultipleChoiceOptions: ReadonlyArray<IMultipleChoiceOptionData>;
}

export interface IMultipleChoiceTypeElementDispatchProps {
  readonly createOption: (option: IMultipleChoiceOptionData, elementId: Uuid) => void;
  readonly deleteOption: (elementId: Uuid, optionId: Uuid) => void;
  readonly deselectEditedOption: () => void;
  readonly moveOption: (draggedOptionId: Uuid, targetOptionId: Uuid, elementId: Uuid) => void;
  readonly onCodenameSave: (
    codename: string,
    elementId: Uuid,
    optionId: Uuid,
    onSaved: () => void,
  ) => void;
  readonly onCodenameCopy?: () => void;
  readonly onCodenameEdit?: () => void;
  readonly onDropMultipleChoiceOption: () => void;
  readonly onLabelChange: (label: string) => void;
  readonly onPickUpMultipleChoiceOption: (optionId: Uuid) => void;
  readonly onSelect: (option: IMultipleChoiceOptionData, elementId: Uuid) => void;
  readonly updateOption: (option: IMultipleChoiceOptionData, elementId: Uuid) => void;
}

export type IMultipleChoiceTypeElementOwnProps = ITypeElementOwnProps<
  IMultipleChoiceTypeElementData,
  MultipleChoiceTypeElementValidationResult
>;

type Props = IMultipleChoiceTypeElementStateProps &
  IMultipleChoiceTypeElementDispatchProps &
  IMultipleChoiceTypeElementOwnProps;

export const MultipleChoiceTypeElement = (props: Props) => {
  const [optionIdToDeleteAfterConfirmation, setOptionIdToDeleteAfterConfirmation] =
    useState<Uuid | null>(null);
  const [isModeDialogShown, setIsModeDialogShown] = useState(false);
  const [isRemoveOptionDialogShown, setIsRemoveOptionDialogShown] = useState(false);

  const showRemoveOptionDialog = (optionId: Uuid): void => {
    setIsRemoveOptionDialogShown(true);
    setOptionIdToDeleteAfterConfirmation(optionId);
  };

  const hideRemoveOptionDialog = (): void => setIsRemoveOptionDialogShown(false);

  const changeOptionName = (
    originalOption: IMultipleChoiceOptionData,
    newName: string,
  ): IMultipleChoiceOptionData => ({
    ...originalOption,
    label: newName,
  });

  const addNewOption = (): void => {
    const option: IMultipleChoiceOptionData = {
      codename: null,
      id: createGuid(),
      label: '',
    };

    props.createOption(option, props.typeElementData.elementId);
    props.onSelect(option, props.typeElementData.elementId);
  };
  const optionCount = Object.keys(props.typeElementData.options).length;
  const isOptionLimitReached = optionCount >= ValidationConstants.MaxNumberOfMultipleChoiceOptions;

  const updateOption = (
    option: IMultipleChoiceOptionData,
    newName: string,
    createAnotherOption: boolean,
  ): void => {
    props.updateOption(changeOptionName(option, newName), props.typeElementData.elementId);
    // After name of option is updated, reset edited option ID
    props.deselectEditedOption();

    if (createAnotherOption && !isOptionLimitReached && !option.label) {
      addNewOption();
    }
  };

  const cancelChanges = (option: IMultipleChoiceOptionData): void => {
    if (option.label) {
      props.deselectEditedOption();
    } else {
      deleteOption(option.id);
    }
  };

  const deleteOption = (optionId: Uuid): void => {
    const originalOption = props.originalTypeElementData?.options[optionId];
    if (isContentTypeUsed(props.numbersOfItemsUsedIn) && originalOption) {
      showRemoveOptionDialog(optionId);
    } else {
      props.deleteOption(props.typeElementData.elementId, optionId);
    }
  };

  const deleteOptionAfterConfirmation = (): void => {
    props.deleteOption(props.typeElementData.elementId, optionIdToDeleteAfterConfirmation || '');
    hideRemoveOptionDialog();
  };

  const moveOption = (draggedOptionId: Uuid, targetOptionId: Uuid): void =>
    props.moveOption(draggedOptionId, targetOptionId, props.typeElementData.elementId);

  const optionToComponent = (option: IMultipleChoiceOptionData): JSX.Element => {
    const blurValue = () => {
      if (!option.label && !props.editedMultipleChoiceOptionLabel.trim()) {
        deleteOption(option.id);
      } else {
        updateOption(
          option,
          props.editedMultipleChoiceOptionLabel.trim() || untitledMultipleChoiceOptionName,
          false,
        );
      }
    };

    const submitLabel = () => {
      updateOption(
        option,
        props.editedMultipleChoiceOptionLabel.trim() || untitledMultipleChoiceOptionName,
        true,
      );
    };

    return (
      <DraggableMultipleChoiceOption
        key={option.id}
        editedMultipleChoiceOptionLabel={props.editedMultipleChoiceOptionLabel}
        isBeingEdited={props.editedMultipleChoiceOptionId === option.id}
        multipleChoiceOption={option}
        onBlur={blurValue}
        onCancelChanges={() => cancelChanges(option)}
        onCodenameSave={(codename, onSaved) =>
          props.onCodenameSave(codename, props.typeElementData.elementId, option.id, onSaved)
        }
        onCodenameCopy={props.onCodenameCopy}
        onCodenameEdit={props.onCodenameEdit}
        onDelete={deleteOption}
        onDropMultipleChoiceOption={props.onDropMultipleChoiceOption}
        onLabelChange={props.onLabelChange}
        onLabelSubmit={submitLabel}
        onMove={moveOption}
        onPickUpMultipleChoiceOption={props.onPickUpMultipleChoiceOption}
        onSelect={(selectedOption) =>
          props.onSelect(selectedOption, props.typeElementData.elementId)
        }
        placeholder={untitledMultipleChoiceOptionName}
        relatedCodenames={
          option.codename
            ? Collection.remove(props.allMultipleChoiceOptionCodenames, option.codename)
            : props.allMultipleChoiceOptionCodenames
        }
      />
    );
  };

  const updateMode = (selectedMode: MultipleChoiceMode): void => {
    props.onChange({
      ...props.typeElementData,
      defaultValue:
        selectedMode === MultipleChoiceMode.Multiple
          ? props.typeElementData.defaultValue
          : props.typeElementData.defaultValue.slice(0, 1),
      mode: selectedMode,
    });

    hideModeDialog();
  };

  const isModeChangeSafe = (selectedMode: MultipleChoiceMode): boolean => {
    const { originalTypeElementData } = props;
    return (
      selectedMode === 'multiple' ||
      !originalTypeElementData ||
      (originalTypeElementData && originalTypeElementData.mode === MultipleChoiceMode.Single)
    );
  };

  const showModeDialog = (): void => setIsModeDialogShown(true);
  const hideModeDialog = (): void => setIsModeDialogShown(false);

  const renderedOptions = props.sortedMultipleChoiceOptions.map(optionToComponent);
  const defaultValueOptions = props.sortedMultipleChoiceOptions.filter(
    (option: IMultipleChoiceOptionData) => !!option.label,
  );

  return (
    <TypeElementWithTypedName {...props}>
      <Checkbox
        checkboxState="default"
        checked={props.typeElementData.mode === MultipleChoiceMode.Single}
        onToggle={(newChecked) => {
          const modeOptionId = newChecked ? MultipleChoiceMode.Single : MultipleChoiceMode.Multiple;

          if (isContentTypeUsed(props.numbersOfItemsUsedIn) && !isModeChangeSafe(modeOptionId)) {
            showModeDialog();
          } else {
            updateMode(modeOptionId);
          }
        }}
        {...getDataUiInputAttribute(DataUiInput.MultipleChoiceMode)}
      >
        Limit to a single choice
      </Checkbox>
      <TypeElementConfigurationCategory>
        <TypeElementConfigurationSection
          title="Options"
          className="content-element__config-section-control--full-width"
        >
          <div className="row">
            <div className="col-lg-12 col-md-16 col-xs-24">
              <ul
                className={classNames('bar-item__list', {
                  'bar-item__list--is-dragging': props.isDragging,
                })}
              >
                {renderedOptions}
                {!isOptionLimitReached && (
                  <div
                    className={classNames('content-element__config-new-option-creator', {
                      'content-element__config-new-option-creator--has-options': optionCount > 0,
                    })}
                  >
                    <Button
                      buttonStyle="tertiary"
                      onClick={addNewOption}
                      {...getDataUiActionAttribute(DataUiAction.CreateNewOption)}
                    >
                      Create new option
                    </Button>
                  </div>
                )}
              </ul>
            </div>
          </div>
        </TypeElementConfigurationSection>
        <TypeElementConfigurationSection title="Default value">
          <MultipleChoiceDefaultValueSelector
            onChange={(defaultValue) =>
              props.onChange({
                ...props.typeElementData,
                defaultValue,
              })
            }
            options={defaultValueOptions}
            optionsMode={props.typeElementData.mode}
            selectedOptionIds={props.typeElementData.defaultValue}
          />
        </TypeElementConfigurationSection>
      </TypeElementConfigurationCategory>
      {isModeDialogShown && (
        <ConfirmationDialog
          confirmButtonText="Change"
          headerContent="Change how this element displays options?"
          isOpen
          onConfirm={() => updateMode(MultipleChoiceMode.Single)}
          onClose={hideModeDialog}
        >
          {modeChangeMessage}
        </ConfirmationDialog>
      )}
      {isRemoveOptionDialogShown && (
        <ConfirmationDialog
          alert
          confirmButtonText="Delete"
          headerContent="Delete this option?"
          isOpen
          onConfirm={deleteOptionAfterConfirmation}
          onClose={hideRemoveOptionDialog}
        >
          {optionRemoveMessage}
        </ConfirmationDialog>
      )}
    </TypeElementWithTypedName>
  );
};
