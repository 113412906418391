import { changeContentTypeElementCodename } from '../../../applications/contentModels/contentTypes/actions/contentTypesActions.ts';
import { ContentTypeKind } from '../../../applications/contentModels/shared/constants/contentTypeKind.ts';
import { getElementCodenamesWithoutEditedCodename } from '../../../applications/contentModels/shared/utils/typeCodenameUtils.ts';
import { changeContentTypeSnippetElementCodename } from '../../../applications/contentModels/snippets/actions/snippetsActions.ts';
import { trackUserEvent } from '../../actions/thunks/trackUserEvent.ts';
import { CodenameContentTypeButton } from '../../components/Codename/CodenameContentTypeButton.tsx';
import { TrackedEvent } from '../../constants/trackedEvent.ts';
import { useDispatch } from '../../hooks/useDispatch.ts';
import { useSelector } from '../../hooks/useSelector.ts';
import { CodenameEventType, CodenameTargetType } from '../../models/TrackUserEventData.ts';
import { IStore } from '../../stores/IStore.type.ts';

interface Props {
  readonly codename: string | null;
  readonly elementId: Uuid;
  readonly contentTypeKind: ContentTypeKind;
}

const getRelatedCodenames = (
  s: IStore,
  isForSnippet: boolean,
  elementId: string,
): ReadonlySet<string> => {
  return isForSnippet
    ? getElementCodenamesWithoutEditedCodename(
        s.contentModelsApp.snippets.editor.editedContentTypeSnippet.typeElements,
        elementId,
      )
    : getElementCodenamesWithoutEditedCodename(
        s.contentModelsApp.typeEditor.editedType.typeElements,
        elementId,
        s.data.snippets.byId,
      );
};

export const CodenameContentElementButton = ({ codename, elementId, contentTypeKind }: Props) => {
  const editedContentTypeSnippetCodename = useSelector(
    (s) => s.contentModelsApp.snippets.editor.editedContentTypeSnippet.codename,
  );

  const isForSnippet = contentTypeKind === ContentTypeKind.ContentSnippet;
  const relatedCodenames = useSelector((s) => getRelatedCodenames(s, isForSnippet, elementId));

  const dispatch = useDispatch();
  const onCodenameSave = (newCodename: string, onSaved: () => void): void => {
    if (contentTypeKind === ContentTypeKind.ContentSnippet) {
      dispatch(changeContentTypeSnippetElementCodename(newCodename, elementId));
    } else {
      dispatch(changeContentTypeElementCodename(newCodename, elementId));
    }
    onSaved();
  };

  const onCodenameCopy = () =>
    dispatch(
      trackUserEvent(TrackedEvent.Codename, {
        type: CodenameEventType.Copy,
        target: CodenameTargetType.ContentTypeElement,
      }),
    );

  const onCodenameEdit = () =>
    dispatch(
      trackUserEvent(TrackedEvent.Codename, {
        type: CodenameEventType.Edit,
        target: CodenameTargetType.ContentTypeElement,
      }),
    );

  return (
    <CodenameContentTypeButton
      codename={codename}
      isCodenameEditable
      onCodenameSave={onCodenameSave}
      onCodenameCopy={onCodenameCopy}
      onCodenameEdit={onCodenameEdit}
      popoverDialogClassNames="popover__dialog--is-over-content-element"
      relatedCodenames={relatedCodenames}
      snippetCodename={isForSnippet ? editedContentTypeSnippetCodename : null}
      tooltipPlacement="top-end"
      triggerClassNames="content-element__action content-element__codename-pane"
    />
  );
};
