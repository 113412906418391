import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { Box } from '@kontent-ai/component-library/Box';
import { Callout } from '@kontent-ai/component-library/Callout';
import { AnimatedProgressIcon } from '@kontent-ai/component-library/Icons';
import {
  NotificationBarAlert,
  NotificationBarWarning,
} from '@kontent-ai/component-library/NotificationBar';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, gridUnit, px } from '@kontent-ai/component-library/tokens';
import { not } from '@kontent-ai/utils';
import React, { useState } from 'react';
import { DialogState } from '../../../../../component-library/components/Dialogs/DialogStateEnum.ts';
import { ModalDialog } from '../../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import { documentationLinks } from '../../../../_shared/constants/documentationLinks.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import {
  DataUiAction,
  DataUiElement,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { compose } from '../../../../_shared/utils/func/compose.ts';
import { isEmptyOrWhitespace } from '../../../../_shared/utils/stringUtils.ts';
import { PredefinedRoles } from '../../../environmentSettings/roles/constants/predefinedRoles.ts';
import { CustomAssetDomainCreateNewWarningText, MinDialogWidth } from '../constants/uiConstants.ts';
import { IRoleOption } from '../containers/CreateEnvironmentDialog.tsx';
import { IBaseEnvironment } from '../models/IBaseEnvironment.type.ts';
import { CopyDataOption } from '../types/copyDataOption.ts';
import { CreateEnvironmentForm } from './CreateEnvironmentForm.tsx';

export interface ICreateEnvironmentDialogProps {
  readonly cloneFromId: Uuid;
  readonly cloneFromName: string;
  readonly creatingEnvironment: boolean;
  readonly creatingEnvironmentFailed: boolean;
  readonly environments: ReadonlyArray<IBaseEnvironment>;
  readonly isCustomAssetDomainSet: boolean;
  readonly isNewEnvironment: boolean;
  readonly loadingRolesStatus: LoadingStatus;
  readonly onCloseCreateEnvironmentDialog: () => void;
  readonly onCreateEnvironment: (
    cloneFromId: Uuid,
    name: string,
    rolesToActivate: ReadonlyArray<Uuid>,
    copyDataOptions: ReadonlyArray<CopyDataOption>,
  ) => void;
  readonly onSelectRoles: (selectedRoles: ReadonlySet<Uuid>) => void;
  readonly onSourceEnvironmentChanged: (cloneFromId: Uuid | null) => void;
  readonly roleOptions: ReadonlyArray<IRoleOption>;
  readonly selectedRoleIds: ReadonlySet<Uuid>;
  readonly showDialog: boolean;
}

export const CreateEnvironmentDialog: React.FC<ICreateEnvironmentDialogProps> = ({
  cloneFromId,
  cloneFromName,
  creatingEnvironment,
  creatingEnvironmentFailed,
  environments,
  isCustomAssetDomainSet,
  isNewEnvironment,
  loadingRolesStatus,
  onCloseCreateEnvironmentDialog,
  onCreateEnvironment,
  onSelectRoles,
  onSourceEnvironmentChanged,
  roleOptions,
  selectedRoleIds,
  showDialog,
}) => {
  const [environmentName, setEnvironmentName] = useState('');
  const [selectedCopyDataOptions, setSelectedCopyDataOptions] = useState<
    ReadonlyArray<CopyDataOption>
  >([CopyDataOption.CopyContent]);

  const projectManagerRolePredicate = (role: IRoleOption) =>
    role.codename === PredefinedRoles.ProjectManager;
  const preselectedRoles = roleOptions.filter(projectManagerRolePredicate);
  const selectableRoleOptions = roleOptions.filter(compose(not, projectManagerRolePredicate));

  const isNameValid = !isEmptyOrWhitespace(environmentName);

  const createEnvironment = (): void => {
    if (isNameValid) {
      const preselectedRoleIds = preselectedRoles.map((role) => role.id);
      onCreateEnvironment(
        cloneFromId,
        environmentName,
        [...preselectedRoleIds, ...selectedRoleIds],
        selectedCopyDataOptions,
      );
    }
  };

  const getConfirmButtonText = (): string =>
    isNewEnvironment
      ? creatingEnvironment
        ? 'Creating new environment'
        : 'Create new environment'
      : creatingEnvironment
        ? 'Cloning environment'
        : 'Clone environment';

  return (
    <ModalDialog
      isDismissable
      shouldCloseOnBlur={false}
      shouldCloseOnInteractOutside={() => false}
      isOpen={showDialog}
      minWidth={px(MinDialogWidth).toString()}
      state={creatingEnvironment ? DialogState.InProgress : DialogState.Default}
      headline={isNewEnvironment ? 'Create new environment' : `Clone environment ${cloneFromName}`}
      primaryAction={{
        text: getConfirmButtonText(),
        tooltipText: isNameValid ? undefined : 'Environment name cannot be empty.',
        disabled: !isNameValid || loadingRolesStatus !== LoadingStatus.Loaded,
        onClick: createEnvironment,
        iconBefore: creatingEnvironment ? AnimatedProgressIcon : undefined,
        ...getDataUiActionAttribute(DataUiAction.CreateNew),
      }}
      onClose={onCloseCreateEnvironmentDialog}
      renderNotificationBar={() => (
        <NotificationBar
          creatingEnvironmentFailed={creatingEnvironmentFailed}
          selectedCopyDataOptions={selectedCopyDataOptions}
        />
      )}
      {...getDataUiElementAttribute(DataUiElement.CreateEnvironmentDialog)}
    >
      <Box maxWidth={MinDialogWidth + 14 * gridUnit}>
        <Stack spacing={Spacing.XL}>
          {isCustomAssetDomainSet && <CustomAssetDomainCallout />}
          <Row spacingX={Spacing.XL}>
            <Column width="1/2">
              <CreateEnvironmentForm
                cloneFromId={cloneFromId}
                environments={environments}
                isNewEnvironment={isNewEnvironment}
                onSelectRoles={onSelectRoles}
                onSourceEnvironmentChanged={onSourceEnvironmentChanged}
                preselectedRoles={preselectedRoles}
                selectableRoleOptions={selectableRoleOptions}
                selectedCopyDataOptions={selectedCopyDataOptions}
                selectedRoleIds={selectedRoleIds}
                setEnvironmentName={setEnvironmentName}
                setSelectedCopyDataOptions={setSelectedCopyDataOptions}
              />
            </Column>
            <Column width="1/2">
              <CreatingEnvironmentCallout />
            </Column>
          </Row>
        </Stack>
      </Box>
    </ModalDialog>
  );
};

CreateEnvironmentDialog.displayName = 'CreateEnvironmentDialog';

const NotificationBar: React.FC<{
  readonly creatingEnvironmentFailed: boolean;
  readonly selectedCopyDataOptions: readonly CopyDataOption[];
}> = ({ creatingEnvironmentFailed, selectedCopyDataOptions }) => {
  if (creatingEnvironmentFailed) {
    return (
      <NotificationBarAlert onDismiss={() => undefined}>
        Something went wrong. Please try again. If this keeps happening, let us know.
      </NotificationBarAlert>
    );
  }

  if (selectedCopyDataOptions.includes(CopyDataOption.CopyTimelines)) {
    return (
      <NotificationBarWarning>
        Including version history significantly increases the cloning time.
      </NotificationBarWarning>
    );
  }

  return null;
};

const CustomAssetDomainCallout: React.FC = () => (
  <Callout calloutType="friendlyWarning">
    <p>{CustomAssetDomainCreateNewWarningText}</p>
  </Callout>
);

const CreatingEnvironmentCallout: React.FC = () => (
  <Callout calloutType="quickTip" hideSubheadline>
    <Stack spacing={Spacing.XL}>
      <div>
        <p>When creating a new environment:</p>
        <ul>
          <li>Content & assets, content model, and environment settings are cloned by default.</li>
          <li>Webhooks are cloned but deactivated in the new environment.</li>
          <li>The new environment has a unique ID and can use existing API keys.</li>
        </ul>
      </div>
      <p>
        Learn more about{' '}
        <OutwardLink href={documentationLinks.environments}>working with environments</OutwardLink>.
      </p>
    </Stack>
  </Callout>
);
