import { Sidebar } from '../../../../../../_shared/components/Sidebar/Sidebar.tsx';
import { QuickTip } from '../../../../../../_shared/components/infos/QuickTip.tsx';
import {
  DataAttributes,
  getDataAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataAttributes.ts';
import {
  DataUiCollection,
  DataUiElement,
  getDataUiCollectionAttribute,
  getDataUiElementAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  ICommentThread,
  IInlineCommentThread,
} from '../../../../models/comments/CommentThreads.ts';
import { NewCommentThread } from '../../containers/comments/threadItem/NewCommentThread.tsx';
import { Comments } from './Comments.tsx';

type Props = {
  readonly commentsOnRemovedContent: ReadonlyArray<IInlineCommentThread>;
  readonly commentThreads: ReadonlyArray<ICommentThread>;
  readonly isAnyCommentThreadFocused: boolean;
  readonly isOpened: boolean;
  readonly onClose: () => void;
};

export const DiscussionsSidebar = (props: Props) => {
  const hasCommentsOnRemovedContent = !!props.commentsOnRemovedContent.length;
  const displayTip = !props.commentThreads.length && !hasCommentsOnRemovedContent;

  return (
    <Sidebar
      dataUiAttribute={getDataUiElementAttribute(DataUiElement.DiscussionSidebar)}
      isVisible={props.isOpened}
      onClose={props.onClose}
      titleElement="Discussion"
    >
      <div
        {...getDataUiCollectionAttribute(DataUiCollection.CommentThreads)}
        className="sidebar__section"
      >
        <NewCommentThread autoFocus={!props.isAnyCommentThreadFocused} />
        {displayTip && (
          <QuickTip>
            <p>Start discussion around a content item to engage other contributors.</p>
          </QuickTip>
        )}
        <Comments threads={props.commentThreads} loaderText="Loading comments" />
        {hasCommentsOnRemovedContent && (
          <>
            <h3
              {...getDataAttribute(
                DataAttributes.CommentsOnRemovedContentHeader,
                DataAttributes.CommentsOnRemovedContentHeader,
              )}
              className="sidebar__subtitle"
            >
              Comments on removed content
            </h3>
            <Comments threads={props.commentsOnRemovedContent} isInlineThreadWithRemovedContent />
          </>
        )}
      </div>
    </Sidebar>
  );
};
