import { Placement } from '@kontent-ai/component-library/types';
import { identity } from '@kontent-ai/utils';
import React, { useId } from 'react';
import { getDropDown } from '../../../../../../../_shared/components/DropDown.tsx';
import { TextField } from '../../../../../../../_shared/uiComponents/TextField/TextField.tsx';
import { DataUiElement } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  QuantityOption,
  QuantityOptions,
  getQuantityOptionName,
} from '../../../../../../contentInventory/content/models/quantityOptions.ts';
import { TypeElementConfigurationSection } from '../TypeElementConfigurationSection.tsx';

const DropDown = getDropDown<QuantityOption>();

export interface INumberLimit {
  readonly minLimit: string | null;
  readonly maxLimit: string | null;
  readonly _limitOption: QuantityOption;
}

type Props = {
  readonly isValid: boolean;
  readonly limit: INumberLimit;
  readonly onChange: (newLimit: INumberLimit) => void;
  readonly placeholder: string;
  readonly readOnly?: boolean;
  readonly title: string;
  readonly tooltipPlacement?: Placement;
  readonly tooltipText?: string;
  readonly uiElement?: DataUiElement;
};

export const NumberLimit: React.FC<Props> = ({
  isValid,
  limit,
  onChange,
  placeholder,
  readOnly,
  title,
  tooltipPlacement = 'top',
  tooltipText,
  uiElement,
}) => {
  const inputId = useId();

  const numberLimit = limit.minLimit || limit.maxLimit;
  const unitOption = limit._limitOption;

  const updateLimit = (event: any): void => {
    const updatedLimit = updateLimitValue(limit, event.target.value);

    onChange(updatedLimit);
  };

  const updateUnit = (newUnit: QuantityOption): void => {
    const limitWithNewUnit: INumberLimit = {
      ...limit,
      _limitOption: newUnit,
    };

    const updatedLimit = updateLimitValue(limitWithNewUnit, numberLimit);

    onChange(updatedLimit);
  };

  return (
    <TypeElementConfigurationSection title={title} uiElement={uiElement} labelFor={inputId}>
      <div className="content-element__config-number-limit">
        <div className="content-element__config-number-limit-item">
          <DropDown
            className="combo-box"
            selectedOption={unitOption}
            options={QuantityOptions}
            onSelect={updateUnit}
            renderOptionName={getQuantityOptionName}
            getOptionId={identity}
            disabled={readOnly}
            disabledTooltipMessage={tooltipText}
            disabledTooltipPlacement={tooltipPlacement}
          />
        </div>
        <div className="content-element__config-number-limit-item">
          <TextField
            hasError={!isValid}
            onChange={updateLimit}
            placeholder={placeholder}
            value={numberLimit || ''}
            inputId={inputId}
            readOnly={readOnly}
            tooltipText={tooltipText}
            tooltipPlacement={tooltipPlacement}
          />
        </div>
      </div>
    </TypeElementConfigurationSection>
  );
};

NumberLimit.displayName = 'NumberLimit';

const updateLimitValue = (numberLimit: INumberLimit, newLimit: string | null): INumberLimit => {
  const option = numberLimit._limitOption;

  switch (option) {
    case QuantityOption.Minimum:
      return {
        minLimit: newLimit,
        maxLimit: null,
        _limitOption: option,
      };

    case QuantityOption.Exactly:
      return {
        minLimit: newLimit,
        maxLimit: newLimit,
        _limitOption: option,
      };

    case QuantityOption.Maximum:
      return {
        minLimit: null,
        maxLimit: newLimit,
        _limitOption: option,
      };
  }
};
