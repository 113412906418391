import { assert, createGuid } from '@kontent-ai/utils';
import { isString } from '../../../../../../_shared/utils/stringUtils.ts';
import { isUuid } from '../../../../../../_shared/utils/validation/typeValidators.ts';
import { CONTENT_COMPONENT_ID_KEY } from '../../../../../itemEditor/features/ContentComponent/constants/contentComponentConstants.ts';
import { IContentComponentServerModel } from '../../../../../itemEditor/models/contentItem/ContentComponentServerModel.type.ts';
import { BaseBlockType, BlockType } from '../../../../utils/blocks/blockType.ts';
import { IRawBlockInput, createRawBlock } from '../../../../utils/blocks/editorBlockUtils.ts';
import { ObjectDataType } from '../../../../utils/export/html/elements/objects.ts';
import { MaxListDepth } from '../../../../utils/general/editorContentUtils.ts';
import { EntityType } from '../../../entityApi/api/Entity.ts';
import { getEntityDataFromAttributes } from '../../../entityApi/api/editorEntityUtils.ts';
import {
  DraftJSInlineStyle,
  MutuallyExclusiveStyles,
  isMutuallyExclusiveStyleKey,
} from '../../../inlineStyles/api/inlineStyles.ts';
import { getDepthFromCoordinates } from '../../../tables/api/depthCoordinatesConversion.ts';
import { getRawTableCellBlocks } from '../../../tables/api/tableGenerator.ts';
import { RawStateBuilder } from './RawStateBuilder.ts';
import { StackItem, TableContext, TextBlockContext } from './StackItem.ts';

export enum TagNames {
  A = 'A',
  B = 'B',
  BR = 'BR',
  DIV = 'DIV',
  FIGURE = 'FIGURE',
  H1 = 'H1',
  H2 = 'H2',
  H3 = 'H3',
  H4 = 'H4',
  H5 = 'H5',
  H6 = 'H6',
  I = 'I',
  IMG = 'IMG',
  LI = 'LI',
  OBJECT = 'OBJECT',
  OL = 'OL',
  P = 'P',
  TABLE = 'TABLE',
  TD = 'TD',
  TR = 'TR',
  TH = 'TH',
  UL = 'UL',
  SPAN = 'SPAN',
  PRE = 'PRE',
}

const headerNodeTags: ReadonlyArray<string> = [
  TagNames.H1,
  TagNames.H2,
  TagNames.H3,
  TagNames.H4,
  TagNames.H5,
  TagNames.H6,
];

const supportedHeaderNodeTags: ReadonlyArray<string> = [
  TagNames.H1,
  TagNames.H2,
  TagNames.H3,
  TagNames.H4,
  TagNames.H5,
  TagNames.H6,
];

const tableCellNodeTags: ReadonlyArray<string> = [TagNames.TD, TagNames.TH];

const textBlockNodeTags: ReadonlyArray<string> = [
  TagNames.P,
  ...supportedHeaderNodeTags,
  TagNames.LI,
  ...tableCellNodeTags,
];

const headerBlockTypeMap: Record<string, BaseBlockType> = {
  [TagNames.H1]: BlockType.HeadingOne,
  [TagNames.H2]: BlockType.HeadingTwo,
  [TagNames.H3]: BlockType.HeadingThree,
  [TagNames.H4]: BlockType.HeadingFour,
  [TagNames.H5]: BlockType.HeadingFive,
  [TagNames.H6]: BlockType.HeadingSix,
} as const;

export const OutOfFragmentLockKey = 'OutOfFragment';
const generateLockKey = (node: Node): string => `${node.nodeName}_${createGuid()}`;

const isCommentNode = (node: Node): boolean => node.nodeType === document.COMMENT_NODE;
const isElementNode = (node: Node): node is HTMLElement => node.nodeType === document.ELEMENT_NODE;
const isTextNode = (node: Node): boolean => node.nodeType === document.TEXT_NODE;

const msOfficeListNoneRegex = /mso-list:[\s]*none/;
const msOfficeListIgnoreRegex = /mso-list:[\s]*ignore/;
const isMSOfficeListParagraph = (node: Node): node is HTMLParagraphElement => {
  if (node.nodeName !== TagNames.P) {
    return false;
  }

  const nodeStyle = ((node as HTMLParagraphElement).getAttribute('style') || '').toLowerCase();
  return (
    nodeStyle.includes('mso-list') &&
    !msOfficeListNoneRegex.test(nodeStyle) &&
    !msOfficeListIgnoreRegex.test(nodeStyle)
  );
};

const isBlockElement = (node: Node): node is HTMLElement => {
  if (isElementNode(node)) {
    const computedStyles = getComputedStyle(node);
    return !!computedStyles.display && computedStyles.display.includes('block');
  }
  return false;
};

const shouldPreserveWhiteSpaces = (node: Node): boolean => {
  const styledElement = isElementNode(node) ? node : node.parentElement;
  const computedStyles = styledElement ? getComputedStyle(styledElement) : undefined;
  return !!computedStyles?.whiteSpace?.includes('pre');
};

const getDraftInlineStyles = (node: Node, top: StackItem): ReadonlyArray<DraftJSInlineStyle> => {
  const { parentNode } = node;
  const {
    inlineStyleContext: { allowedStyles, contextualStyles },
  } = top;
  const computedStyles = parentNode ? getComputedStyle(node.parentNode as HTMLElement) : undefined;
  const styles: Array<DraftJSInlineStyle> = [];
  if (computedStyles) {
    if (allowedStyles.includes(DraftJSInlineStyle.Bold)) {
      if (computedStyles.fontWeight && Number.parseInt(computedStyles.fontWeight, 10) > 500) {
        styles.push(DraftJSInlineStyle.Bold);
      }
    }

    if (allowedStyles.includes(DraftJSInlineStyle.Italic)) {
      if (computedStyles.fontStyle && computedStyles.fontStyle.toLowerCase() === 'italic') {
        styles.push(DraftJSInlineStyle.Italic);
      }
    }

    if (
      allowedStyles.includes(DraftJSInlineStyle.Superscript) &&
      contextualStyles.includes(DraftJSInlineStyle.Superscript)
    ) {
      styles.push(DraftJSInlineStyle.Superscript);
    }

    if (
      allowedStyles.includes(DraftJSInlineStyle.Subscript) &&
      contextualStyles.includes(DraftJSInlineStyle.Subscript)
    ) {
      styles.push(DraftJSInlineStyle.Subscript);
    }

    if (allowedStyles.includes(DraftJSInlineStyle.Code)) {
      const lowercaseFontFamily = computedStyles.fontFamily.toLowerCase();

      if (
        (parentNode && parentNode.nodeName.toLowerCase() === 'code') ||
        lowercaseFontFamily.includes('monospace') ||
        lowercaseFontFamily.includes('courier') ||
        lowercaseFontFamily.includes(' mono')
      ) {
        styles.push(DraftJSInlineStyle.Code);
      }
    }
  }

  return styles;
};

export const isNodeStartFragment = (node: Node): boolean =>
  isCommentNode(node) && !!node.nodeValue && node.nodeValue.toLowerCase() === 'startfragment';

export const isNodeEndFragment = (node: Node): boolean =>
  isCommentNode(node) && !!node.nodeValue && node.nodeValue.toLowerCase() === 'endfragment';

export type IStartHandler = (node: Node, top: StackItem, output: RawStateBuilder) => StackItem;

export const startHandlers = ((): ReadonlyArray<IStartHandler> => {
  const fragmentStart_enablesWriting = (node: Node, top: StackItem): StackItem => {
    if (isNodeStartFragment(node)) {
      return {
        ...top,
        newBlockLock: top.newBlockLock.remove(OutOfFragmentLockKey),
        writeLock: top.writeLock.remove(OutOfFragmentLockKey),
      };
    }

    return top;
  };

  const getInlineStyleFromVerticalAlign = (
    style: CSSStyleDeclaration,
  ): DraftJSInlineStyle | null => {
    if (style && style.verticalAlign === 'sub') {
      return DraftJSInlineStyle.Subscript;
    }
    if (style && style.verticalAlign === 'super') {
      return DraftJSInlineStyle.Superscript;
    }
    return null;
  };

  const superscriptOrSubscript_setsInlineStyleContext = (node: Node, top: StackItem): StackItem => {
    if (node.nodeType === document.ELEMENT_NODE) {
      const computedStyle = getComputedStyle(node as HTMLElement);
      const {
        inlineStyleContext: { contextualStyles, allowedStyles },
      } = top;
      const inlineStyle = getInlineStyleFromVerticalAlign(computedStyle);
      if (inlineStyle) {
        const isStyleAllowed = allowedStyles.includes(inlineStyle);
        if (isStyleAllowed) {
          const exclude: ReadonlyArray<DraftJSInlineStyle> = isMutuallyExclusiveStyleKey(
            inlineStyle,
          )
            ? MutuallyExclusiveStyles[inlineStyle]
            : [];
          const newAllowedStyles = allowedStyles.filter((style) => !exclude.includes(style));

          return {
            ...top,
            inlineStyleContext: {
              ...top.inlineStyleContext,
              allowedStyles: newAllowedStyles,
              contextualStyles: [...contextualStyles, inlineStyle],
            },
          };
        }
      }
    }

    return top;
  };

  const blockElementNodeStart_setsWriteContext = (node: Node, top: StackItem): StackItem => {
    if (isBlockElement(node)) {
      top.writeContext.nextWriteToNewBlock();
    }

    return top;
  };

  const textBlockStart_newBlock_setsTextBlockContext = (
    node: Node,
    top: StackItem,
    output: RawStateBuilder,
  ): StackItem => {
    if (textBlockNodeTags.includes(node.nodeName)) {
      const element = node as HTMLElement;

      if (isMSOfficeListParagraph(element)) {
        return top;
      }

      // Empty text blocks are ignored.
      if (!element.clientHeight) {
        return top;
      }

      if (top.newBlockLock.isOpen()) {
        if (element.nodeName === TagNames.P) {
          output.addEmptyBlock(top.nestedContentContext.parentBlockTypes);
        }
        top.writeContext.resetWriteToNewBlock();
      } else {
        top.writeContext.nextWriteToNewBlock();
      }

      const preserveWhitespaces = shouldPreserveWhiteSpaces(element);
      const rawText = element.textContent || '';
      const text = preserveWhitespaces ? rawText : rawText.replace(/[\n\t\r\u0020]+/g, ''); // LF, TAB, CR, standard (breaking) space

      const containsText = !!text.length;
      // In HTML:
      // Text blocks without text has 0 lines by default and each line break adds another line.
      // If text is present, text block has one default line and each line break adds another.
      const initialLineCount = containsText || preserveWhitespaces ? 1 : 0;
      const textBlockContext = new TextBlockContext(containsText, initialLineCount);

      return {
        ...top,
        textBlockContext,
      };
    }

    return top;
  };

  const lineBreakStart_newBlockOrNewLine = (
    node: Node,
    top: StackItem,
    output: RawStateBuilder,
  ): StackItem => {
    if (node.nodeName === TagNames.BR && top.writeLock.isOpen()) {
      if (top.textBlockContext && !top.writeContext.shouldWriteToNewBlock()) {
        // BR inside a text block is represented as line break

        if (top.textBlockContext.containsText) {
          top.writeContext.executeWhitespaceWriteFromPreviousTextNode(output.getCurrentBlockKey());
          output.writeText('\n');
          top.textBlockContext.incrementLinesCount();
        } else {
          // Special handling for text blocks with no text. First line break (second line) is ignored when text block is without text in HTML.
          // Text blocks without text and line breaks are collapsed (ignored completely).
          // Text block with single line break is considered to only consist of one line (empty text block).
          // Text block with two line breaks is considered to consist two lines, etc...
          // Count all lines in text block and then write line breaks where count of line breaks = lines count -1
          top.textBlockContext.incrementLinesCount();
        }
      } else if (top.newBlockLock.isOpen()) {
        // BR without a text block is represented as new block (paragraph)
        output.addEmptyBlock(top.nestedContentContext.parentBlockTypes);
        top.writeContext.resetWriteToNewBlock();
      } else {
        top.writeContext.nextWriteToNewBlock();
      }
    }

    return top;
  };

  const textNodeStart_writesText = (
    node: Node,
    top: StackItem,
    output: RawStateBuilder,
  ): StackItem => {
    if (top.writeLock.isOpen() && isTextNode(node) && node.textContent) {
      const preserveWhiteSpaces = shouldPreserveWhiteSpaces(node);

      if (preserveWhiteSpaces) {
        if (top.writeContext.shouldWriteToNewBlock()) {
          if (top.newBlockLock.isOpen()) {
            output.addEmptyBlock(top.nestedContentContext.parentBlockTypes);
          } else if (!output.isCurrentBlockEmpty() && output.getLastBlockCharacter() !== '\u0020') {
            // If we cannot create new block and current block is not empty
            // add delimiter to separate text from other content.
            output.writeText(' ');
          }
        }

        top.writeContext.executeWhitespaceWriteFromPreviousTextNode(output.getCurrentBlockKey());
        output.writeText(node.textContent, getDraftInlineStyles(node, top));

        top.writeContext.resetWriteToNewBlock();
        return top;
      }

      // We are not preserving white spaces
      // We need to trim whitespace characters similarly as browsers do when rendering text
      // These whitespaces are present in formatted HTML with indentation and word wrapping
      // LF, CR, TAB => Space, Multiple Spaces => Space, Trim start + end of the text within the block
      // U+0020 is unicode for standard space, we don't want to replace unbreakable space

      const textWithoutFormatting = node.textContent
        .replace(/[\n\t\r]+/g, ' ') // LF, CR, TAB => Space
        .replace(/[\u0020]+/g, ' '); // Multiple Spaces => Space

      if (textWithoutFormatting.length) {
        const textWithoutSpaces = textWithoutFormatting.replace(/[\u0020]+/g, ''); // Space => ''
        if (top.writeContext.shouldWriteToNewBlock()) {
          if (!textWithoutSpaces.length) {
            // This is a start of a new block. We are suppose to trim text at start of the block.
            // If text does not contain any characters besides spaces then we don't want to create this block.
            // We don't know if it's gonna get filled in the future or this is it's only content.
            return top;
          }

          if (top.newBlockLock.isOpen()) {
            output.addEmptyBlock(top.nestedContentContext.parentBlockTypes);
          } else if (!output.isCurrentBlockEmpty() && output.getLastBlockCharacter() !== '\u0020') {
            // If we cannot create new block and current block is not empty
            // add delimiter to separate text from other content.
            output.writeText(' ');
          }
        }

        if (!output.isCurrentBlockEmpty() && textWithoutSpaces.length) {
          // Writes cannot leave spaces at the end of the block. If that write was the last, block would not be properly trimmed.
          // Previous write might left us a message to write a space with this write, not knowing whether the block was ending or not.
          // We need to pre-pend space that we have set aside in previous write, now that we know block has not ended with previous write.
          // But only if current block is not empty (trim start rule)
          // And only if current text contains non-white space characters (we won't end up with whitespace end the end of the block)
          top.writeContext.executeWhitespaceWriteFromPreviousTextNode(output.getCurrentBlockKey());
        }

        // If text node ends with space(s), we have to ensure the end of the block is properly trimmed.
        const withoutEndSpaces = textWithoutFormatting.replace(/[\u0020]+$/g, '');
        const endsWithSpace = withoutEndSpaces !== textWithoutFormatting;

        // We are finally writing this text node to output.
        // If last block is empty or ends with new line, we need to trim text at start
        // If last block contains text from previous text nodes, we need to ensure Multiple Spaces => Space
        // when previous text node ends with space and current node starts with space
        const trimStartLastBlockCharacters = [null, '\n', '\u0020'];
        const textToWrite = trimStartLastBlockCharacters.includes(output.getLastBlockCharacter())
          ? withoutEndSpaces.replace(/^[\u0020]+/g, '')
          : withoutEndSpaces;

        if (textToWrite.length) {
          output.writeText(textToWrite, getDraftInlineStyles(node, top));
        }

        if (endsWithSpace) {
          // We cannot write space at the end until we are certain that this text node is not the last node of the block.
          // We send a message to next write to include the space.
          // We do this at the very end because above writeText may still introduce a new block to write to
          top.writeContext.writeWhitespaceWithNextWriteToBlock(output.getCurrentBlockKey(), () =>
            output.writeText(' ', getDraftInlineStyles(node, top)),
          );
        }
      }

      top.writeContext.resetWriteToNewBlock();
    }

    return top;
  };

  const headerStart = (node: Node, top: StackItem, output: RawStateBuilder): StackItem => {
    if (headerNodeTags.includes(node.nodeName)) {
      if (!supportedHeaderNodeTags.includes(node.nodeName)) {
        top.writeContext.nextWriteToNewBlock();
        return top;
      }

      if (top.newBlockLock.isOpen()) {
        top.writeContext.resetWriteToNewBlock();
        const type = headerBlockTypeMap[node.nodeName];
        assert(
          !!type,
          () => `Header block type for index "${node.nodeName}" is falsy. Value: "${type}".`,
        );
        const headerBlock: IRawBlockInput = { type };
        output.addBlock(top.nestedContentContext.parentBlockTypes, headerBlock);
      }

      const newBlockLockKey = generateLockKey(node);

      return {
        ...top,
        newBlockLock: top.newBlockLock.add(newBlockLockKey),
        inlineStyleContext: {
          ...top.inlineStyleContext,
          allowedStyles: top.inlineStyleContext.allowedStyles.filter(
            (style) => style !== DraftJSInlineStyle.Bold,
          ),
        },
      };
    }

    return top;
  };

  const linkStart = (node: Node, top: StackItem, output: RawStateBuilder): StackItem => {
    if (node.nodeName === TagNames.A) {
      // Complete unfinished writes that occurred before the link
      if (
        !output.isCurrentBlockEmpty() &&
        !top.writeContext.shouldWriteToNewBlock() &&
        top.writeLock.isOpen()
      ) {
        top.writeContext.executeWhitespaceWriteFromPreviousTextNode(output.getCurrentBlockKey());
      }

      // If link is directly under TD, the content block is not yet started
      // But we need to start it at this point for the linkContext to refer to a correct block
      if (output.isCurrentBlockTableCell()) {
        top.writeContext.nextWriteToNewBlock();
      }

      if (top.writeContext.shouldWriteToNewBlock()) {
        return {
          ...top,
          linkContext: {
            blockKey: output.getNextBlockKey(),
            charOffset: 0,
          },
        };
      }

      return {
        ...top,
        linkContext: output.getCurrentBlockTextOffset(),
      };
    }

    return top;
  };

  const imageStart = (node: Node, top: StackItem, output: RawStateBuilder): StackItem => {
    if (node.nodeName === TagNames.IMG && top.sourceProjectId === top.currentProjectId) {
      if (top.newBlockLock.isOpen()) {
        const assetId = (node as HTMLImageElement).getAttribute('data-asset-id');
        if (isUuid(assetId)) {
          output.addEmptyBlock(top.nestedContentContext.parentBlockTypes);

          const imageBlock = createRawBlock({
            type: BlockType.Image,
            data: {
              guid: assetId,
            },
          });
          output.addBlock(top.nestedContentContext.parentBlockTypes, imageBlock);

          output.addEmptyBlock(top.nestedContentContext.parentBlockTypes);
          top.writeContext.nextWriteToNewBlock();
        }
      }

      return {
        ...top,
        newBlockLock: top.newBlockLock.add(generateLockKey(node)),
        writeLock: top.writeLock.add(generateLockKey(node)),
      };
    }

    return top;
  };

  const contentModuleStart = (node: Node, top: StackItem, output: RawStateBuilder): StackItem => {
    if (node.nodeName === TagNames.OBJECT && top.sourceProjectId === top.currentProjectId) {
      const objectElement = node as HTMLObjectElement;
      const dataType = objectElement.getAttribute('data-type');
      if (dataType === ObjectDataType.ContentItem) {
        if (top.newBlockLock.isOpen()) {
          output.addEmptyBlock(top.nestedContentContext.parentBlockTypes);

          const contentModuleBlock = createRawBlock({
            type: BlockType.ContentModule,
            data: {
              guid: objectElement.getAttribute('data-id'),
            },
          });
          output.addBlock(top.nestedContentContext.parentBlockTypes, contentModuleBlock);

          output.addEmptyBlock(top.nestedContentContext.parentBlockTypes);
          top.writeContext.nextWriteToNewBlock();
        }

        return {
          ...top,
          newBlockLock: top.newBlockLock.add(generateLockKey(node)),
          writeLock: top.writeLock.add(generateLockKey(node)),
        };
      }
    }

    return top;
  };

  const contentComponentStart = (
    node: Node,
    top: StackItem,
    output: RawStateBuilder,
  ): StackItem => {
    if (node.nodeName === TagNames.OBJECT && top.sourceProjectId === top.currentProjectId) {
      const objectElement = node as HTMLObjectElement;
      const dataType = objectElement.getAttribute('data-type');
      if (dataType === ObjectDataType.ContentComponent) {
        const componentValue = objectElement.getAttribute('data-value');
        if (componentValue) {
          if (top.newBlockLock.isOpen()) {
            const contentComponent: IContentComponentServerModel = JSON.parse(componentValue);

            const contentComponentId = output.addContentComponent(contentComponent);
            if (!contentComponentId) {
              return top;
            }

            output.addEmptyBlock(top.nestedContentContext.parentBlockTypes);

            const contentComponentBlock = createRawBlock({
              type: BlockType.ContentComponent,
              data: {
                [CONTENT_COMPONENT_ID_KEY]: contentComponentId,
              },
            });
            output.addBlock(top.nestedContentContext.parentBlockTypes, contentComponentBlock);

            output.addEmptyBlock(top.nestedContentContext.parentBlockTypes);
            top.writeContext.nextWriteToNewBlock();
          }
        }

        return {
          ...top,
          newBlockLock: top.newBlockLock.add(generateLockKey(node)),
          writeLock: top.writeLock.add(generateLockKey(node)),
        };
      }
    }

    return top;
  };

  const tableStart_setsTableContext_emptyBlockBeforeTable = (
    node: Node,
    top: StackItem,
    output: RawStateBuilder,
  ): StackItem => {
    if (node.nodeName === TagNames.TABLE) {
      if (top.newBlockLock.isOpen()) {
        output.addEmptyBlock(top.nestedContentContext.parentBlockTypes);
      }

      const rows = Array.from((node as HTMLTableElement).rows);
      const maxRowLength = rows.reduce((maxLength: number, row: HTMLTableRowElement) => {
        const currentLength = row.cells.length;
        return maxLength > currentLength ? maxLength : currentLength;
      }, 0);

      const writeLockKey = generateLockKey(node);

      return {
        ...top,
        writeLock: top.writeLock.add(writeLockKey),
        tableContext: new TableContext(maxRowLength, writeLockKey),
      };
    }

    return top;
  };

  const tableRowStart_updatesTableContext = (
    node: Node,
    top: StackItem,
    output: RawStateBuilder,
  ): StackItem => {
    if (top.tableContext === null || node.nodeName !== TagNames.TR) {
      return top;
    }

    // Add missing cells to previous row if needed
    if (top.tableContext.getRowIndex() > 0) {
      while (top.tableContext.maxRowLength > top.tableContext.getCellIndex() + 1) {
        top.tableContext.addCell();

        if (top.newBlockLock.isOpen()) {
          const coords = {
            x: top.tableContext.getCellIndex(),
            y: top.tableContext.getRowIndex(),
          };
          const tableCellBlocks = getRawTableCellBlocks(coords);
          tableCellBlocks.forEach((block) =>
            output.addBlock(top.nestedContentContext.parentBlockTypes, block),
          );
          top.writeContext.resetWriteToNewBlock();
        }
      }
    }

    top.tableContext.addRow();

    return top;
  };

  const tableCellStart_addsTableCellBlock = (
    node: Node,
    top: StackItem,
    output: RawStateBuilder,
  ): StackItem => {
    if (top.tableContext === null) {
      return top;
    }

    if (tableCellNodeTags.includes(node.nodeName)) {
      top.tableContext.addCell();

      if (top.newBlockLock.isOpen()) {
        const coords = {
          x: top.tableContext.getCellIndex(),
          y: top.tableContext.getRowIndex(),
        };
        const tableCellBlock = {
          type: BlockType.TableCell,
          depth: getDepthFromCoordinates(coords),
        };
        output.addBlock(top.nestedContentContext.parentBlockTypes, tableCellBlock);
        top.writeContext.resetWriteToNewBlock();
      } else {
        top.writeContext.nextWriteToNewBlock();
      }

      return {
        ...top,
        writeLock: top.writeLock.remove(top.tableContext.writeLockKey),
        nestedContentContext: {
          parentBlockTypes: [...top.nestedContentContext.parentBlockTypes, BlockType.TableCell],
        },
      };
    }

    return top;
  };

  const listStart_setsListContext = (node: Node, top: StackItem): StackItem => {
    if (node.nodeName === TagNames.UL || node.nodeName === TagNames.OL) {
      const blockType =
        node.nodeName === TagNames.UL ? BlockType.UnorderedListItem : BlockType.OrderedListItem;
      const writeLockKey = generateLockKey(node);

      const listContext = top.listContext
        ? {
            ...top.listContext,
            blockType,
            depth: top.listContext.depth + 1,
            writeLockKey,
          }
        : {
            blockType,
            depth: 0,
            writeLockKey,
          };

      // Nested list
      const writeLock = top.listContext
        ? top.writeLock.add(writeLockKey).remove(top.listContext.writeLockKey)
        : top.writeLock.add(writeLockKey);
      const newBlockLock = top.listContext?.newBlockLockKey
        ? top.newBlockLock.remove(top.listContext.newBlockLockKey)
        : top.newBlockLock;

      return {
        ...top,
        listContext,
        writeLock,
        newBlockLock,
      };
    }

    return top;
  };

  const listItemStart_addsListItemBlock = (
    node: Node,
    top: StackItem,
    output: RawStateBuilder,
  ): StackItem => {
    if (node.nodeName === TagNames.LI && top.listContext) {
      if (top.newBlockLock.isOpen()) {
        const depth = top.listContext.depth > MaxListDepth ? MaxListDepth : top.listContext.depth;
        const listItemBlock = {
          type: top.listContext.blockType,
          depth,
        };
        output.addBlock(top.nestedContentContext.parentBlockTypes, listItemBlock);
        top.writeContext.resetWriteToNewBlock();
      } else {
        top.writeContext.nextWriteToNewBlock();
      }

      const newBlockLockKey = generateLockKey(node);

      return {
        ...top,
        newBlockLock: top.newBlockLock.add(newBlockLockKey),
        writeLock: top.writeLock.remove(top.listContext.writeLockKey),
        listContext: {
          ...top.listContext,
          newBlockLockKey,
        },
      };
    }

    return top;
  };

  const wordListParagraphStart_addsListItemBlock = (
    node: Node,
    top: StackItem,
    output: RawStateBuilder,
  ): StackItem => {
    if (node.nodeName === TagNames.P) {
      if (isMSOfficeListParagraph(node)) {
        if (top.newBlockLock.isOpen()) {
          const nodeStyles = node.getAttribute('style') || '';
          const levelRegex = /level([0-9]+)/g;
          const match = levelRegex.exec(nodeStyles);
          const level = (match && isString(match[1]) && Number.parseInt(match[1], 10)) || 1;
          const depth = level - 1 > MaxListDepth ? MaxListDepth : level - 1;

          const text = node.textContent;
          const firstChar = text?.[0] ?? '*';
          const blockType = '0123456789'.includes(firstChar)
            ? BlockType.OrderedListItem
            : BlockType.UnorderedListItem;

          const listItemBlock = createRawBlock({
            type: blockType,
            depth,
          });
          output.addBlock(top.nestedContentContext.parentBlockTypes, listItemBlock);
          top.writeContext.resetWriteToNewBlock();
        } else {
          top.writeContext.nextWriteToNewBlock();
        }

        return {
          ...top,
          newBlockLock: top.newBlockLock.add(generateLockKey(node)),
        };
      }
    }

    return top;
  };

  const wordListItemIgnoreStart_ignoresNodeContent = (node: Node, top: StackItem): StackItem => {
    if (isElementNode(node)) {
      const nodeStyles = (node.getAttribute('style') || '').toLowerCase();
      if (msOfficeListIgnoreRegex.test(nodeStyles)) {
        return {
          ...top,
          writeLock: top.writeLock.add(generateLockKey(node)),
          newBlockLock: top.newBlockLock.add(generateLockKey(node)),
        };
      }
    }

    return top;
  };

  const powerpointListItemNode_addsListItemBlock = (
    node: Node,
    top: StackItem,
    output: RawStateBuilder,
  ): StackItem => {
    if (node.nodeName === TagNames.SPAN) {
      const element = node as HTMLSpanElement;
      const nodeStyles = element.getAttribute('style');
      if (nodeStyles?.includes('mso-special-format:') && nodeStyles.includes('bullet')) {
        if (top.newBlockLock.isOpen()) {
          const blockType = nodeStyles.includes('numbullet')
            ? BlockType.OrderedListItem
            : BlockType.UnorderedListItem;

          const depthRegex = /O([0-9])/g;
          const maxDeptClassParentNestedIndex = 3;
          let depth = Number.NaN;
          let listItemWrapper: HTMLElement | null = element;
          let i = 0;

          while (Number.isNaN(depth) && i < maxDeptClassParentNestedIndex) {
            listItemWrapper = listItemWrapper?.parentElement ?? null;
            const wrapperClass = listItemWrapper?.getAttribute('class') ?? '';

            const match = depthRegex.exec(wrapperClass);
            depth = (match && isString(match[1]) && Number.parseInt(match[1], 10)) || Number.NaN;
            i++;
          }

          const listItemBlock = createRawBlock({
            type: blockType,
            depth: depth || 0,
          });
          output.addBlock(top.nestedContentContext.parentBlockTypes, listItemBlock);
          top.writeContext.resetWriteToNewBlock();
        } else {
          top.writeContext.nextWriteToNewBlock();
        }

        return {
          ...top,
          writeLock: top.writeLock.add(generateLockKey(node)),
          newBlockLock: top.newBlockLock.add(generateLockKey(node)),
        };
      }
    }

    return top;
  };

  const nodeStart_ignoresNodesFromOtherNamespaces = (node: Node, top: StackItem): StackItem => {
    if (node.nodeName.includes(':')) {
      return {
        ...top,
        writeLock: top.writeLock.add(generateLockKey(node)),
        newBlockLock: top.newBlockLock.add(generateLockKey(node)),
      };
    }

    return top;
  };

  const metadataElementStart_ignoresNodeContent = (node: Node, top: StackItem): StackItem => {
    const metadataElements = ['TITLE', 'STYLE', 'META', 'LINK', 'SCRIPT', 'BASE'];
    if (metadataElements.includes(node.nodeName)) {
      return {
        ...top,
        writeLock: top.writeLock.add(generateLockKey(node)),
        newBlockLock: top.newBlockLock.add(generateLockKey(node)),
      };
    }

    return top;
  };

  return [
    fragmentStart_enablesWriting,
    superscriptOrSubscript_setsInlineStyleContext,
    blockElementNodeStart_setsWriteContext,
    textNodeStart_writesText,
    headerStart,
    linkStart,
    imageStart,
    contentModuleStart,
    contentComponentStart,
    textBlockStart_newBlock_setsTextBlockContext,
    lineBreakStart_newBlockOrNewLine,
    tableStart_setsTableContext_emptyBlockBeforeTable,
    tableRowStart_updatesTableContext,
    tableCellStart_addsTableCellBlock,
    listStart_setsListContext,
    listItemStart_addsListItemBlock,
    wordListParagraphStart_addsListItemBlock,
    wordListItemIgnoreStart_ignoresNodeContent,
    powerpointListItemNode_addsListItemBlock,
    nodeStart_ignoresNodesFromOtherNamespaces,
    metadataElementStart_ignoresNodeContent,
  ];
})();

export type IEndHandler = (node: Node, top: StackItem, output: RawStateBuilder) => void;

export const endHandlers = ((): ReadonlyArray<IEndHandler> => {
  const blockElementNodeEnd_setsWriteContext = (node: Node, top: StackItem): void => {
    if (isBlockElement(node)) {
      top.writeContext.nextWriteToNewBlock();
    }
  };

  const textBlockEnd_textBlockWithoutText_writesLineBreaks = (
    node: Node,
    top: StackItem,
    output: RawStateBuilder,
  ): void => {
    if (
      textBlockNodeTags.includes(node.nodeName) &&
      top.textBlockContext &&
      !top.textBlockContext.containsText
    ) {
      const linesCount = top.textBlockContext.getLinesCount();
      const requiredNewLineCharacterCount = linesCount > 0 ? linesCount - 1 : 0;
      const content = '\n'.repeat(requiredNewLineCharacterCount);
      output.writeText(content);
    }
  };

  const tableEnd_emptyBlockAfterTable = (
    node: Node,
    top: StackItem,
    output: RawStateBuilder,
  ): void => {
    if (node.nodeName === TagNames.TABLE) {
      if (top.newBlockLock.isOpen()) {
        output.addEmptyBlock(top.nestedContentContext.parentBlockTypes);
        top.writeContext.nextWriteToNewBlock();
      }
    }
  };

  const linkEnd = (node: Node, top: StackItem, output: RawStateBuilder): void => {
    if (node.nodeName === TagNames.A) {
      if (top.linkContext && top.writeLock.isOpen()) {
        if (top.linkContext.blockKey !== output.getCurrentBlockKey()) {
          return;
        }

        const currentBlockTextOffset = output.getCurrentBlockTextOffset();
        if (top.linkContext.charOffset === currentBlockTextOffset.charOffset) {
          return;
        }

        const linkEntityData = getEntityDataFromAttributes(
          node as HTMLLinkElement,
          EntityType.Link,
        );

        if (linkEntityData) {
          output.writeEntity({
            blockKey: top.linkContext.blockKey,
            data: linkEntityData,
            length: currentBlockTextOffset.charOffset - top.linkContext.charOffset,
            offset: top.linkContext.charOffset,
            type: EntityType.Link,
          });
        }
      }
    }
  };

  return [
    blockElementNodeEnd_setsWriteContext,
    textBlockEnd_textBlockWithoutText_writesLineBreaks,
    tableEnd_emptyBlockAfterTable,
    linkEnd,
  ];
})();
