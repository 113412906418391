import { pluralizeWithCount } from '../../../../_shared/utils/stringUtils.ts';
import {
  IsNonLocalizableSummary,
  IsRequiredSummary,
  VisibilityAffectedSummary,
} from '../constants/summaryMessageTemplates.ts';
import { IBaseTypeElementData } from '../models/elements/types/TypeElementData.ts';

export function getBaseTypeElementSummarySegments(
  typeElementData: IBaseTypeElementData,
): ReadonlyArray<string> {
  const summarySegments: Array<string> = [];

  if (typeElementData.condition?.isActive) {
    summarySegments.push(VisibilityAffectedSummary);
  }

  if (typeElementData.isRequired) {
    summarySegments.push(IsRequiredSummary);
  }

  if (typeElementData.isNonLocalizable) {
    summarySegments.push(IsNonLocalizableSummary);
  }

  return summarySegments;
}

export function joinSummarySegments(segments: ReadonlyArray<string>): string {
  return segments.join(' | ');
}

export function getUsageInfo(itemCount: number, componentCount: number) {
  const usageInfoForItems = itemCount > 0 ? pluralizeWithCount('content item', itemCount) : '';
  const usageInfoForComponents =
    componentCount > 0 ? pluralizeWithCount('content component', componentCount) : '';

  return [usageInfoForItems, usageInfoForComponents].filter((u) => !!u).join(' and ');
}

export const getDisallowedLimitationTooltipText = (
  limitationCategoryType: string,
  isAllowed: boolean,
): string | undefined => (isAllowed ? undefined : `Enable ${limitationCategoryType} first.`);
