import { trackUserEvent } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import {
  loadProjectContainerActiveUsers,
  loadProjects,
} from '../../../../data/actions/thunkDataActions.ts';
import { createApiKeyCreator } from './thunks/createApiKey.ts';
import { initApiKeyCreator } from './thunks/initApiKey.ts';
import { initApiKeyListingCreator } from './thunks/initApiKeyListing.ts';
import { initApiKeysSettingsCreator } from './thunks/initApiKeysSettings.ts';
import { initNewApiKeyCreator } from './thunks/initNewApiKey.ts';
import { loadApiKeysSettingsDataCreator } from './thunks/loadApiKeysSettingsData.ts';
import { loadApiStatusCreator } from './thunks/loadApiStatus.ts';
import { loadDapiKeyListingCreator } from './thunks/loadDapiKeyListing.ts';
import { loadMapiKeyListingCreator } from './thunks/loadMapiKeyListing.ts';
import { regenerateApiKeyCreator } from './thunks/regenerateApiKey.ts';
import { regenerateOldDapiKeyCreator } from './thunks/regenerateOldDapiKey.ts';
import { revokeApiKeyCreator } from './thunks/revokeApiKey.ts';
import { revokeApiKeyAndRedirectCreator } from './thunks/revokeApiKeyAndRedirect.ts';
import { updateApiKeyCreator } from './thunks/updateApiKey.ts';

const { apiKeysRepository, projectContainerRepository } = repositoryCollection;

const loadMapiKeyListing = loadMapiKeyListingCreator({
  apiKeysRepository,
});

const loadDapiKeyListing = loadDapiKeyListingCreator({
  apiKeysRepository,
});

const loadApiStatus = loadApiStatusCreator({
  projectContainerRepository,
});

export const initApiKeyListing = initApiKeyListingCreator({
  loadDapiKeyListing,
  loadMapiKeyListing,
  loadProjects,
});

const loadApiKeysSettingsData = loadApiKeysSettingsDataCreator({
  apiKeysRepository,
});

export const initApiKeysSettings = initApiKeysSettingsCreator({
  loadApiKeysSettingsData,
  loadProjects,
});

export const initNewApiKey = initNewApiKeyCreator({
  loadProjects,
  loadApiStatus,
  loadProjectContainerActiveUsers,
});

export const initApiKey = initApiKeyCreator({
  apiKeysRepository,
  loadMapiKeyListing,
  loadProjectContainerActiveUsers,
  loadProjects,
  loadApiStatus,
});

export const regenerateApiKey = regenerateApiKeyCreator({
  apiKeysRepository,
});

const revokeApiKey = revokeApiKeyCreator({
  apiKeysRepository,
});

export const revokeApiKeyAndRedirect = revokeApiKeyAndRedirectCreator({
  revokeApiKey,
  loadDapiKeyListing,
  loadMapiKeyListing,
});

export const updateApiKey = updateApiKeyCreator({
  apiKeysRepository,
});

export const createApiKey = createApiKeyCreator({
  apiKeysRepository,
  trackUserEvent,
});

export const regenerateOldDapiKey = regenerateOldDapiKeyCreator({
  revokeApiKey,
  createApiKey,
});
