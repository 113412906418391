import { History } from 'history';
import React, { useContext } from 'react';
import { Sidebar } from '../../../_shared/components/Sidebar/Sidebar.tsx';
import { SidebarSection } from '../../../_shared/components/Sidebar/SidebarSection.tsx';
import { SidebarSubmitButton } from '../../../_shared/components/Sidebar/SidebarSubmitButton.tsx';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { INewSubscriptionFormShape } from '../../subscriptionManagement/SubscriptionListing/components/NewSubscriptionForm.tsx';
import { NewSubscriptionForm } from '../../subscriptionManagement/SubscriptionListing/containers/NewSubscriptionForm.tsx';
import { SubscriptionFormContext } from '../../subscriptionManagement/shared/components/SubscriptionFormContext.tsx';

interface ITrialActivationModalProps {
  readonly onClose: () => void;
  readonly onFormSubmit: (values: INewSubscriptionFormShape, history: History) => void;
  readonly showSidebar: boolean;
  readonly startingTrialInProgress: boolean;
}

export const TrialActivationModal: React.FC<ITrialActivationModalProps> = ({
  showSidebar,
  startingTrialInProgress,
  onClose,
  onFormSubmit,
}) => {
  const { submitForm } = useContext(SubscriptionFormContext);
  const onSubmit = !submitForm || startingTrialInProgress ? undefined : submitForm;

  const submitButton = (
    <div className="sidebar__submit-action">
      <SidebarSubmitButton
        dataUiAttribute={getDataUiActionAttribute(DataUiAction.CreateNew)}
        disabled={startingTrialInProgress}
        isBeingSubmitted={startingTrialInProgress}
        onSubmit={onSubmit}
        text={startingTrialInProgress ? 'Creating trial' : 'Create trial'}
      />
    </div>
  );

  return (
    <Sidebar
      titleElement="Start your free trial"
      isVisible={showSidebar}
      onClose={onClose}
      onSubmit={onSubmit}
      submitElement={submitButton}
    >
      <SidebarSection>
        <NewSubscriptionForm onFormSubmit={onFormSubmit}>
          <p>What will happen?</p>
          <ul>
            <li>You will get a 30-day free trial.</li>
            <li>You will become an admin of the subscription.</li>
            <li>
              The subscription will come with a sample project to help you get familiar with
              Kontent.ai.
            </li>
          </ul>
        </NewSubscriptionForm>
      </SidebarSection>
    </Sidebar>
  );
};

TrialActivationModal.displayName = 'CreateNewSubscriptionModal';
