import React, { MouseEventHandler } from 'react';
import { FeatureStateTag } from '../../../../../../_shared/components/FeatureStateTag.tsx';
import { assetReplaceFeatureState } from '../../../../../../_shared/constants/featureStates.ts';
import { DropDownOption } from '../../../../../../_shared/uiComponents/DropDown/DropDownOption.tsx';
import { DropDownOptionName } from '../../../../../../_shared/uiComponents/DropDown/DropDownOptionName.tsx';
import { DropDownOptionSubmenuMark } from '../../../../../../_shared/uiComponents/DropDown/DropDownOptionSubmenuMark.tsx';
import { DropDownOptionsGroup } from '../../../../../../_shared/uiComponents/DropDown/DropDownOptionsGroup.tsx';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IAsset } from '../../../../../../data/models/assets/Asset.ts';

type Props = {
  readonly asset: IAsset;
  readonly assetDeleteDisabledMessage: string;
  readonly assetReplaceDisabledMessage: string | undefined;
  readonly deleteDisabled?: boolean;
  readonly isAssetReplaceAvailable: boolean;
  readonly isUserAuthorizedToEditAsset: boolean;
  readonly onClick: MouseEventHandler<HTMLElement>;
  readonly onCopyAssetUrl: () => void;
  readonly onDelete: () => void;
  readonly onDownloadClick: () => void;
  readonly onReplace: () => void;
  readonly onCodenameClick: () => void;
};

export const AssetActionDropdown: React.FC<Props> = ({
  asset,
  assetDeleteDisabledMessage,
  assetReplaceDisabledMessage,
  deleteDisabled,
  isAssetReplaceAvailable,
  isUserAuthorizedToEditAsset,
  onClick,
  onCopyAssetUrl,
  onDelete,
  onDownloadClick,
  onReplace,
  onCodenameClick,
}) => {
  const isCopyAssetUrlDisabled = (): boolean => {
    return asset._deliveryLink === null;
  };

  const copyAssetUrl = (): void => {
    if (!isCopyAssetUrlDisabled()) {
      onCopyAssetUrl();
    }
  };

  return (
    <DropDownOptionsGroup>
      <a
        className="dropdown-option"
        target="_blank"
        rel="noopener noreferrer"
        download={asset.filename}
        href={asset._downloadLink ? asset._downloadLink : undefined}
        onClick={(e) => {
          onDownloadClick();
          onClick(e);
        }}
        {...getDataUiActionAttribute(DataUiAction.DownloadFile)}
      >
        <div className="dropdown-option__pane">
          <DropDownOptionName text="Download file" />
        </div>
      </a>
      {isAssetReplaceAvailable && (
        <DropDownOption
          onClick={(e) => {
            onReplace();
            onClick(e);
          }}
          dataUiAttributes={getDataUiActionAttribute(DataUiAction.Replace)}
          isDisabled={!!assetReplaceDisabledMessage}
          tooltipText={assetReplaceDisabledMessage}
        >
          <DropDownOptionName text="Replace file">
            <FeatureStateTag featureState={assetReplaceFeatureState} />
          </DropDownOptionName>
        </DropDownOption>
      )}
      <DropDownOption
        onClick={copyAssetUrl}
        tooltipText={isCopyAssetUrlDisabled() ? 'Not available at the moment.' : undefined}
        isDisabled={isCopyAssetUrlDisabled()}
        dataUiAttributes={getDataUiActionAttribute(DataUiAction.CopyAssetUrl)}
      >
        <DropDownOptionName text="Copy asset URL" />
        <DropDownOptionSubmenuMark />
      </DropDownOption>
      {isUserAuthorizedToEditAsset && (
        <DropDownOption
          onClick={onCodenameClick}
          dataUiAttributes={getDataUiActionAttribute(DataUiAction.GetCodename)}
        >
          <DropDownOptionName text="Codename" />
          <DropDownOptionSubmenuMark />
        </DropDownOption>
      )}
      <DropDownOption
        onClick={(e) => {
          onDelete();
          onClick(e);
        }}
        tooltipText={deleteDisabled ? assetDeleteDisabledMessage : undefined}
        isDisabled={deleteDisabled}
        dataUiAttributes={getDataUiActionAttribute(DataUiAction.Delete)}
        isDestructive
      >
        <DropDownOptionName text="Delete" />
      </DropDownOption>
    </DropDownOptionsGroup>
  );
};
