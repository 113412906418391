import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { ContentItemId } from '../../../../../../_shared/models/ContentItemId.ts';
import { isCreatingRenditionsEnabled } from '../../../../../../_shared/selectors/enhancedAssetManagement.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { getCurrentProjectPlan } from '../../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { removeNonPersistentAssets } from '../../../../../../_shared/utils/assets/assetUtils.ts';
import { IAssetTypeElement } from '../../../../../contentInventory/content/models/contentTypeElements/AssetTypeElement.ts';
import { IAssetItemElement } from '../../../../models/contentItemElements/AssetItemElement.ts';
import { emptyItemElementErrorResult } from '../../../../utils/elementErrorCheckers/types/Errors.ts';
import { createValidationResult } from '../../../../utils/getItemElementValidationResult.ts';
import { getAssetItemElementValidationFriendlyWarning } from '../../../../utils/itemElementFriendlyWarningCheckers/assetElementFriendlyWarningChecker.ts';
import { getAssetItemElementValidationWarning } from '../../../../utils/itemElementWarningCheckers/assetItemElementWarningChecker.ts';
import { areIdsEquivalent } from '../../utils/itemEditingUtils.ts';
import { elementValueChanged } from '../contentItemEditingActions.ts';

export type IAssetElementValueChangedAction = (
  contentItemId: ContentItemId,
  elementData: IAssetItemElement,
  typeElement: IAssetTypeElement,
) => ThunkFunction<IAssetItemElement | null>;

export const assetElementValueChangedActionCreator =
  (): IAssetElementValueChangedAction =>
  (contentItemId, elementData, typeElement) =>
  (dispatch, getState) => {
    const state = getState();
    const {
      data: { assetRenditions, assets, languages },
      contentApp: { editedContentItemVariant },
    } = state;

    if (
      !editedContentItemVariant ||
      !areIdsEquivalent(contentItemId, editedContentItemVariant.id)
    ) {
      return null;
    }

    const selectedLanguageId = getSelectedLanguageIdOrThrow(state);
    const persistentElementData = removeNonPersistentAssets(elementData, assets.byId);

    const areAssetRenditionsEnabled = isCreatingRenditionsEnabled(getCurrentProjectPlan(state));

    const warningResult = getAssetItemElementValidationWarning({
      typeElement,
      itemElement: persistentElementData,
      loadedAssets: assets.byId,
      loadedAssetRenditions: assetRenditions.byId,
      areAssetRenditionsEnabled,
    });

    const friendlyWarningResult = getAssetItemElementValidationFriendlyWarning({
      itemElement: persistentElementData,
      loadedAssets: assets.byId,
      currentLanguageId: selectedLanguageId,
      languages: languages.byId,
    });

    const validationResult = createValidationResult(
      emptyItemElementErrorResult,
      warningResult,
      friendlyWarningResult,
      elementData.elementId,
    );

    dispatch(
      elementValueChanged({
        elementData,
        itemId: editedContentItemVariant.id.itemId,
        typeElement,
        validationResult,
      }),
    );

    return persistentElementData;
  };
