import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { getAllActiveLanguagesForCurrentUserForCollection } from '../../../../../../_shared/utils/languageUtils.ts';
import { Capability } from '../../../../../../_shared/utils/permissions/capability.ts';
import { ILanguagesData } from '../../../../../../data/reducers/languages/ILanguagesData.type.ts';
import { IUser } from '../../../../../../data/reducers/user/IUser.type.ts';
import { TypeElement } from '../../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import {
  isRichTextTypeElement,
  isTextTypeElement,
} from '../../../../../contentModels/shared/types/typeElementTypeGuards.ts';
import { IEditedContentItem } from '../../../../../itemEditor/models/contentItem/edited/EditedContentItem.ts';

export const selectShouldShowAiTranslateButton = (
  state: IStore,
  typeElement: TypeElement,
  contentComponentId: string | undefined,
): boolean => {
  const {
    sharedApp: { currentProjectId },
    contentApp: { editedContentItem },
    data: { languages, user },
  } = state;

  return shouldShowAiTranslateButton(
    currentProjectId,
    editedContentItem,
    languages,
    user,
    typeElement,
    contentComponentId,
  );
};

const shouldShowAiTranslateButton = (
  currentProjectId: Uuid,
  editedContentItem: IEditedContentItem | null,
  languages: ILanguagesData,
  user: IUser,
  typeElement: TypeElement,
  contentComponentId: string | undefined,
) => {
  const collectionId = editedContentItem?.collectionId ?? null;
  const activeLanguages = getAllActiveLanguagesForCurrentUserForCollection(
    user,
    currentProjectId,
    collectionId,
    languages,
    Capability.ViewContent,
  );
  const hasMoreThanOneActiveLanguage = activeLanguages.count() > 1;

  const isElementTranslatable =
    isTextTypeElement(typeElement) || isRichTextTypeElement(typeElement);

  return (
    isElementTranslatable &&
    hasMoreThanOneActiveLanguage &&
    (!typeElement.isNonLocalizable || !!contentComponentId)
  );
};
