import { IconButton } from '@kontent-ai/component-library/Button';
import { transition250 } from '@kontent-ai/component-library/tokens';
import styled, { css } from 'styled-components';

export const StyledPlayButton = styled(IconButton)<{ readonly isVisible: boolean }>`
  transition: opacity ${transition250};
  ${({ isVisible }) =>
    !isVisible &&
    css`
    opacity: 0;
  `};
`;
