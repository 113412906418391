import { ComponentProps } from 'react';
import { useLocation } from 'react-router';
import { CreateAutoScrollId } from '../../../../../_shared/components/AutoScroll/AutoScrollId.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { getCurrentProjectId } from '../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { getCannotViewItemMessage } from '../../../../../_shared/utils/permissions/getCannotViewItemMessage.ts';
import { createBuildLinkForVariantReference } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { useLivePreviewPreferenceStorage } from '../../../../../localStorages/useLivePreviewPreferenceStorage.ts';
import { openSingleAssetUsage } from '../../actions/thunkAssetLibraryActions.ts';
import { SingleAssetUsage as SingleAssetUsageComponent } from '../../components/AssetEditing/SingleAssetUsage.tsx';

type Props = Pick<ComponentProps<typeof SingleAssetUsageComponent>, 'scrollId' | 'usage'> & {
  readonly assetId: Uuid;
  readonly isInDialog: boolean;
  readonly projectId: string;
};

export const SingleAssetUsage = (props: Props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentProjectId = useSelector(getCurrentProjectId);
  const { isLivePreviewPreferred } = useLivePreviewPreferenceStorage(currentProjectId);

  const typeName = useSelector(
    (state) => state.data.contentTypes.byId.get(props.usage.primary.typeId)?.name ?? null,
  );

  return (
    <SingleAssetUsageComponent
      assetScrollId={CreateAutoScrollId.forAsset(props.assetId)}
      disabledMessage={getCannotViewItemMessage(props.usage.primary)}
      onUsageClick={props.isInDialog ? undefined : () => dispatch(openSingleAssetUsage())}
      typeName={typeName}
      usage={props.usage}
      variantReferenceBuildLink={createBuildLinkForVariantReference(
        props.projectId,
        location.pathname,
        isLivePreviewPreferred,
      )}
    />
  );
};
