import { createGuid } from '@kontent-ai/utils';
import { Dispatch, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventAction } from '../../../../../_shared/models/TrackUserEvent.type.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import {
  ISitemap,
  ISitemapNode,
  emptySitemapNode,
} from '../../../../../data/models/contentModelsApp/sitemap/Sitemap.ts';
import { Sitemap_NodeCreated } from '../../constants/sitemapActionTypes.ts';
import { SaveSitemapErrorMessage } from '../../constants/uiConstants.ts';
import { ISaveSitemap } from '../../models/ISaveSitemapNode.type.ts';
import { sitemapSavingFailed } from '../sitemapActions.ts';

interface ICreateNodeDependencies {
  readonly updateSitemap: ISaveSitemap;
  readonly createSitemap: ISaveSitemap;
  readonly trackUserEvent: TrackUserEventAction;
}

const nodeCreated = (node: ISitemapNode) =>
  ({
    type: Sitemap_NodeCreated,
    payload: node,
  }) as const;

export type CreateNodeActionsType = ReturnType<typeof nodeCreated>;

const createNewNode = (nodeName: string): ISitemapNode => ({
  ...emptySitemapNode,
  id: createGuid(),
  name: nodeName,
});

export const createNodeCreator =
  (deps: ICreateNodeDependencies) =>
  (newNodeName: string): ThunkPromise =>
  async (dispatch: Dispatch, getState: () => IStore): Promise<void> => {
    if (!newNodeName) {
      throw new Error('Provide a valid name of sitemap node.');
    }
    const currentSitemap = getState().sitemapApp.sitemap;
    const newNode = createNewNode(newNodeName);
    if (!currentSitemap) {
      throw new Error('Sitemap was not initialized.');
    }

    const newSitemap: ISitemap = {
      ...currentSitemap,
      childIds: [...currentSitemap.childIds, newNode.id],
      nodes: new Map(currentSitemap.nodes).set(newNode.id, newNode),
    };

    dispatch(nodeCreated(newNode));

    try {
      if (newSitemap.etag) {
        await dispatch(deps.updateSitemap(newSitemap));
      } else {
        await dispatch(deps.createSitemap(newSitemap));
      }
    } catch {
      dispatch(sitemapSavingFailed(SaveSitemapErrorMessage));
    }

    dispatch(deps.trackUserEvent(TrackedEvent.SitemapNodeCreated, { 'item-name': newNodeName }));
  };
