import { Dispatch, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { ISitemap } from '../../../../../data/models/contentModelsApp/sitemap/Sitemap.ts';
import { Sitemap_BackupOriginal, Sitemap_Deleted } from '../../constants/sitemapActionTypes.ts';
import { ISaveSitemap } from '../../models/ISaveSitemapNode.type.ts';
import { removeNode } from '../../utils/sitemapUtils.ts';
import { rollbackSitemap } from '../sitemapActions.ts';

interface IDeleteNodeDependencies {
  readonly updateSitemap: ISaveSitemap;
}

const saveOriginalSitemap = (sitemap: ISitemap) =>
  ({
    type: Sitemap_BackupOriginal,
    payload: {
      sitemap,
    },
  }) as const;

const sitemapDeleted = (message: string) =>
  ({
    type: Sitemap_Deleted,
    payload: { message },
  }) as const;

export type DeleteNodeActionsType = ReturnType<typeof saveOriginalSitemap | typeof sitemapDeleted>;

export const deleteNodeCreator =
  (deps: IDeleteNodeDependencies) =>
  (nodeId: Uuid): ThunkPromise => {
    return async (dispatch: Dispatch, getState: () => IStore): Promise<void> => {
      const originalSitemap = getState().sitemapApp.sitemap;
      if (!originalSitemap) {
        return Promise.resolve();
      }
      const newSitemap = removeNode(originalSitemap, nodeId);

      try {
        await dispatch(deps.updateSitemap(newSitemap));
        dispatch(saveOriginalSitemap(originalSitemap));
      } catch (error) {
        dispatch(rollbackSitemap(originalSitemap));
        throw error;
      }

      dispatch(sitemapDeleted('Sitemap item deleted.'));
    };
  };
