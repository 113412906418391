import { Box } from '@kontent-ai/component-library/Box';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import {
  BorderRadius,
  Spacing,
  colorBackgroundHover,
  colorWarningIcon,
} from '@kontent-ai/component-library/tokens';
import { useHover } from '@react-aria/interactions';
import React, { HTMLAttributes, useCallback } from 'react';
import { OpenItemInANewTabLink } from '../../../applications/itemEditor/features/MoveToCollectionDialog/components/OpenItemInANewTabLink.tsx';
import { WorkflowStatusTagForVariant } from '../../containers/Workflow/WorkflowStatusTagForVariant.tsx';
import { ActiveCapabilityType } from '../../models/activeCapability.type.ts';
import { ContentItemVariantWithLanguageName } from '../../utils/getContentItemVariantsWithLanguageNames.tsx';
import { hasActiveVariantCapability } from '../../utils/permissions/activeCapabilities.ts';

type ContentItemVariantLinkProps = {
  readonly variant: ContentItemVariantWithLanguageName;
  readonly tooltipText?: string;
};

export const ContentItemVariantLink: React.FC<ContentItemVariantLinkProps> = ({
  variant,
  tooltipText,
}) => {
  const hoverResult = useHover({});
  const boxProps: Omit<HTMLAttributes<HTMLElement>, 'color' | 'ref'> = {
    ...hoverResult.hoverProps,
  };
  const hasViewPermission = hasActiveVariantCapability(ActiveCapabilityType.ViewContent, variant);
  const isHovered = hoverResult.isHovered && hasViewPermission;

  const openVariantInNewTab = useCallback(() => {
    if (hasViewPermission) {
      window.open(variant.linkPath, '_blank');
    }
  }, [variant.linkPath, hasViewPermission]);

  return (
    <Box
      cursor={hasViewPermission ? 'pointer' : 'default'}
      paddingX={Spacing.L}
      paddingY={Spacing.S}
      borderRadius={BorderRadius.M}
      backgroundColor={isHovered ? colorBackgroundHover : 'transparent'}
      onClick={openVariantInNewTab}
      {...boxProps}
    >
      <Row spacing={Spacing.XL} noWrap>
        <Tooltip text={tooltipText} placement="right">
          <Column width="2/3">
            <Row spacing={Spacing.S} alignY="center" noWrap>
              {tooltipText && (
                <Column width="content">
                  <Icons.ExclamationTriangle color={colorWarningIcon} />
                </Column>
              )}
              <Column>{variant.languageName}</Column>
            </Row>
          </Column>
        </Tooltip>

        <Column width="content">
          <OpenItemInANewTabLink
            hasViewPermission={hasViewPermission}
            linkPath={variant.linkPath}
          />
        </Column>

        <Column>
          <WorkflowStatusTagForVariant
            workflowStatus={variant.assignment.workflowStatus}
            publishingState={variant.publishingState}
            scheduledToPublishAt={variant.assignment.scheduledToPublishAt}
            scheduledToUnpublishAt={variant.assignment.scheduledToUnpublishAt}
          />
        </Column>
      </Row>
    </Box>
  );
};

ContentItemVariantLink.displayName = 'ContentItemVariantLink';
