import { not } from '@kontent-ai/utils';
import React from 'react';
import { connect } from 'react-redux';
import { getPlanUserPaidMetric } from '../../../applications/subscriptionManagement/shared/utils/planUtils.ts';
import {
  SubscriptionPlanIncludedUserLimitReachedWarning as Component,
  SubscriptionPlanIncludedUserLimitReachedWarningOwnProps,
  SubscriptionPlanIncludedUserLimitReachedWarningStateProps,
} from '../../components/SubscriptionWarnings/SubscriptionPlanIncludedUserLimitReachedWarning.tsx';
import { IStore } from '../../stores/IStore.type.ts';
import { compose } from '../../utils/func/compose.ts';
import {
  getSelectedSubscriptionCurrentActiveUsers,
  getSubscriptionUserSlotStats,
  hasToUsePaidUserSlot,
  isEmployee,
} from '../../utils/subscriptionUsageUtils.ts';

function mapStateToProps(
  state: IStore,
  ownProps: SubscriptionPlanIncludedUserLimitReachedWarningOwnProps,
): SubscriptionPlanIncludedUserLimitReachedWarningStateProps {
  const {
    data: { plans },
  } = state;

  const subscriptionUsage = getSelectedSubscriptionCurrentActiveUsers(state);
  const plan = subscriptionUsage ? (plans.byId.get(subscriptionUsage.planId) ?? null) : null;
  const activeUsersPaidMetric = getPlanUserPaidMetric(plan);
  const isTrial = plan?.isTrial ?? false;
  const maxActiveUsers = plan?.features.maxActiveUsers ?? null;
  const userSlotStats = getSubscriptionUserSlotStats(state);

  return {
    isVisible: hasToUsePaidUserSlot(state, ownProps.newEmails),
    isInActivationMode: ownProps.isInActivationMode,
    extraUserSlotPrice: activeUsersPaidMetric?.extraPackagePrice ?? 0,
    isTrial,
    maxActiveUsers,
    includedUserSlotsCount: activeUsersPaidMetric?.includedUnits ?? 0,
    newUsersAboveIncludedUnitsCount:
      ownProps.newEmails.filter(compose(not, isEmployee)).length -
      userSlotStats.availableFreeUserSlots,
  };
}

export const SubscriptionPlanIncludedUserLimitReachedWarning: React.ComponentType<SubscriptionPlanIncludedUserLimitReachedWarningOwnProps> =
  connect(mapStateToProps)(Component);
