import { ReactNode, createContext, useContext, useMemo } from 'react';

type RefinedNavigation = {
  readonly isRefinedNavigationFeatureEnabled: boolean;
};

type Props = RefinedNavigation & {
  readonly children: ReactNode;
};

export const RefinedNavigationContext = createContext<RefinedNavigation>({
  isRefinedNavigationFeatureEnabled: false,
});
RefinedNavigationContext.displayName = 'RefinedNavigationContext';

export const RefinedNavigationContextProvider = (props: Props) => {
  const contextValue = useMemo(
    () => ({
      isRefinedNavigationFeatureEnabled: props.isRefinedNavigationFeatureEnabled,
    }),
    [props.isRefinedNavigationFeatureEnabled],
  );

  return (
    <RefinedNavigationContext.Provider value={contextValue}>
      {props.children}
    </RefinedNavigationContext.Provider>
  );
};

export const useIsRefinedNavigationFeatureEnabled = () => {
  const { isRefinedNavigationFeatureEnabled } = useContext(RefinedNavigationContext);
  return isRefinedNavigationFeatureEnabled;
};
