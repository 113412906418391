import { Box } from '@kontent-ai/component-library/Box';
import {
  Spacing,
  ZIndex,
  borderWidthDefault,
  colorBackgroundLowEmphasis,
  colorBorderLowEmphasis,
  gridUnit,
  px,
} from '@kontent-ai/component-library/tokens';
import { FC, ReactNode, useContext } from 'react';
import styled from 'styled-components';
import {
  MainNavigationContext,
  NavigationBarExpandedState,
} from '../../contexts/MainNavigationContext.tsx';
import { RefinedStandaloneMenuItems } from './MainMenuNavigationBar.tsx';

const RefinedNavigationBarWrapper = styled.nav<{
  readonly navigationState: NavigationBarExpandedState;
}>`
  position: ${({ navigationState }) => (navigationState === NavigationBarExpandedState.MinimizedExpanded ? 'absolute' : 'relative')};
  z-index: ${ZIndex.OneHundred};
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${({ navigationState }) => (navigationState === NavigationBarExpandedState.Minimized ? px(gridUnit * 6) : px(gridUnit * 28))};
  min-height: 0;
  height: 100%;

  border-right: ${px(borderWidthDefault)} solid ${colorBorderLowEmphasis};
  background: ${colorBackgroundLowEmphasis};
`;

const NavigationTopPane = styled.div`
  display: flex;
  min-height: 0;
  flex-direction: column;
  flex: 1;
`;

type Props = {
  readonly children?: ReactNode;
};

export const RefinedNavigationBar: FC<Props> = ({ children }) => {
  const { navigationBarExpandedState } = useContext(MainNavigationContext);
  return (
    <RefinedNavigationBarWrapper navigationState={navigationBarExpandedState}>
      <NavigationTopPane>{children}</NavigationTopPane>
      <Box paddingBottom={Spacing.XXL} paddingTop={Spacing.L}>
        <RefinedStandaloneMenuItems />
      </Box>
    </RefinedNavigationBarWrapper>
  );
};
