import { assert } from '@kontent-ai/utils';
import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { DefaultVariantId } from '../../../../../_shared/constants/variantIdValues.ts';
import { distinctFilter } from '../../../../../_shared/utils/arrayUtils/arrayUtils.ts';
import { hasCapabilityInLanguage } from '../../../../../_shared/utils/permissions/capabilitiesInLanguageUtils.ts';
import { Capability } from '../../../../../_shared/utils/permissions/capability.ts';
import { LoadListingContentItemsByIdsAction } from '../../../../../data/actions/thunks/listingContentItems/loadListingContentItemsByIds.ts';
import { createActiveCapabilitiesForVariantFromServerModel } from '../../../../../data/models/activeCapabilities.ts';
import { IListingContentItemServerModel } from '../../../../../repositories/serverModels/IListingContentItemServerModel.type.ts';
import {
  ContentModels_TypeEditor_InitialLinkedItemsDefaultValueReferences_Loaded,
  ContentModels_TypeEditor_InitialLinkedItemsDefaultValueReferences_Loading,
} from '../../constants/sharedContentModelsActionTypes.ts';
import { IBaseTypeElementData } from '../../models/elements/types/TypeElementData.ts';
import { IDefaultValueLinkedItem } from '../../reducers/IContentModelsAppStoreState.type.ts';
import { isTypeElementWithLinkedItems } from '../../types/typeElementTypeGuards.ts';

export type ILoadLinkedItemsDefaultValueReferencesAction = (
  typeElements: ReadonlyArray<IBaseTypeElementData>,
  abortSignal?: AbortSignal,
) => ThunkPromise;

type Deps = {
  readonly loadListingContentItemsByIds: LoadListingContentItemsByIdsAction;
};

const isLoading = () =>
  ({
    type: ContentModels_TypeEditor_InitialLinkedItemsDefaultValueReferences_Loading,
  }) as const;

const loaded = (defaultValueReferences: ReadonlyArray<IDefaultValueLinkedItem>) =>
  ({
    type: ContentModels_TypeEditor_InitialLinkedItemsDefaultValueReferences_Loaded,
    payload: {
      defaultValueReferences,
    },
  }) as const;

export type LoadLinkedItemsDefaultValueReferencesActionType = ReturnType<
  typeof loaded | typeof isLoading
>;

export const createLoadLinkedItemsDefaultValueReferencesAction =
  (deps: Deps): ILoadLinkedItemsDefaultValueReferencesAction =>
  (typeElements, abortSignal) =>
  async (dispatch, getState) => {
    dispatch(isLoading());

    const state = getState();
    const hasPermission = hasCapabilityInLanguage(state, Capability.ViewContent, DefaultVariantId);

    if (!hasPermission) {
      dispatch(loaded([]));
      return;
    }

    const linkedItemIds = typeElements
      .filter(isTypeElementWithLinkedItems)
      .flatMap((typeElement) => typeElement.defaultValue)
      .filter(distinctFilter);

    const linkedItems = await dispatch(
      deps.loadListingContentItemsByIds(DefaultVariantId, linkedItemIds, abortSignal),
    );

    dispatch(loaded(mapLinkedItems(linkedItems)));
  };

const mapLinkedItems = (
  result: ReadonlyArray<IListingContentItemServerModel>,
): ReadonlyArray<IDefaultValueLinkedItem> => {
  assert(result, () => 'Failed to retrieve linked content items by ids.');

  return result.map((item): IDefaultValueLinkedItem => {
    assert(item?.item?.type, () => `Linked item has invalid format. ${JSON.stringify(item)}.`);

    return {
      activeCapabilities: createActiveCapabilitiesForVariantFromServerModel(
        item.activeCapabilities,
      ),
      collectionId: item.item.collectionId,
      isItemArchived: item.item.archived,
      isTranslated: !!item.variant && !item.variant.archived,
      itemId: item.item.id ?? '',
      itemName: item.item.name ?? '',
      typeId: item.item.type?._id ?? '',
    };
  });
};
