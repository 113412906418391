import { History } from 'history';
import { trackUserEvent } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { ContentStatusEventActionTypes } from '../../../../../_shared/models/TrackUserEventData.ts';
import { getContentItemPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { createNewContentItem } from '../../../../itemEditor/features/NewContentItem/actions/thunkNewContentItemActions.ts';
import { createContentItemFromContentTypeWithSelectedLanguageCreator } from '../../../../itemEditor/features/NewContentItem/actions/thunks/createContentItemFromContentType.ts';

const redirectToItem = (
  history: History,
  itemId: Uuid,
  variantId: Uuid,
  isLivePreviewPreferred: boolean,
): void => {
  const contentItemPath = getContentItemPath(
    history.location.pathname,
    itemId,
    isLivePreviewPreferred,
    variantId,
  );
  history.push(contentItemPath);
};

export const createNewContentItemFromContentStatus =
  createContentItemFromContentTypeWithSelectedLanguageCreator({
    createNewContentItem,
    redirectToItem,
    trackItemCreated: (contentTypeSection, collectionSection) =>
      trackUserEvent(TrackedEvent.ContentStatusItemCreated, {
        action: ContentStatusEventActionTypes.CreateContentItem,
        'collection-section': collectionSection,
        'content-type-section': contentTypeSection,
      }),
  });
