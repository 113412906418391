import { ISelectItem } from '@kontent-ai/component-library/Selects';
import { DefaultTag, Tag } from '@kontent-ai/component-library/Tag';
import { colorAlertBackgroundInverse } from '@kontent-ai/component-library/tokens';
import { useMemo } from 'react';
import { useDataSelector } from '../../../../../../../_shared/hooks/useDataSelector.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { getCurrentProject } from '../../../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { DataUiCollection } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getContentTypesWithViewCapability } from '../../../../../../../_shared/utils/permissions/getContentTypesWithViewCapability.ts';
import { IContentType } from '../../../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IUser } from '../../../../../../../data/reducers/user/IUser.type.ts';
import { getSortedContentTypesByName } from '../../../../../../contentModels/shared/utils/typeUtils.ts';
import { ListingFilterCategorySelector } from '../../components/ListingFilterCategorySelector.tsx';

type Props = {
  readonly forceContentTypesTooltipText: string | undefined;
  readonly forcedContentTypeIds: ReadonlySet<Uuid> | undefined;
  readonly onContentTypesSelectionChanged: (ids: ReadonlySet<Uuid>) => void;
  readonly selectedContentTypeIds: ReadonlySet<Uuid>;
  readonly selectedLanguageId: Uuid;
  readonly user: IUser;
};

interface ITypeSelectItem extends ISelectItem<ITypeSelectItem> {
  readonly isArchived?: boolean;
}

const toSelectItem = ({ id, name: label, isArchived }: IContentType): ITypeSelectItem => ({
  id,
  label,
  isArchived,
});

export const ContentTypesFilterSelector = (props: Props) => {
  const { projectId } = useSelector(getCurrentProject);
  const contentTypesById = useDataSelector((data) => data.contentTypes.byId);
  const contentTypes = useMemo(() => contentTypesById.valueSeq().toArray(), [contentTypesById]);

  const options = useMemo(() => {
    const allowedContentTypes = getContentTypesWithViewCapability(
      projectId,
      props.selectedLanguageId,
      props.user,
      contentTypes,
    );
    return getSortedContentTypesByName(allowedContentTypes).map(toSelectItem);
  }, [projectId, contentTypes, props.selectedLanguageId, props.user]);

  return contentTypes.length ? (
    <ListingFilterCategorySelector<ITypeSelectItem>
      disabled={!!props.forcedContentTypeIds}
      onChange={props.onContentTypesSelectionChanged}
      options={options}
      collection={DataUiCollection.ContentTypes}
      placeholder={label}
      selectedOptionIds={
        props.forcedContentTypeIds ? props.forcedContentTypeIds : props.selectedContentTypeIds
      }
      title="Content type"
      titleTooltipText={props.forceContentTypesTooltipText}
      ariaLabel={label}
      renderSelectedOption={(_id, selectedItem, defaultTagProps) =>
        selectedItem.isArchived ? (
          <Tag background={colorAlertBackgroundInverse} {...defaultTagProps} />
        ) : (
          <DefaultTag {...defaultTagProps} />
        )
      }
    />
  ) : null;
};

const label = 'Select a content type';
