import { Badge } from '@kontent-ai/component-library/Badge';
import { Icons } from '@kontent-ai/component-library/Icons';
import { TabbableMenuItem, TabbableMenuOutwardLink } from '@kontent-ai/component-library/Menu';
import { NavigationBarQuinaryMenuButton } from '@kontent-ai/component-library/NavigationBar';
import React, { useContext } from 'react';
import { RefinedNavigationContext } from '../../../applications/refinedNavigation/contexts/RefinedNavigationContext.tsx';
import { modalOpened } from '../../actions/sharedActions.ts';
import { trackUserEvent } from '../../actions/thunks/trackUserEvent.ts';
import { feedbackLinks } from '../../constants/feedbackLinks.ts';
import { ModalDialogType } from '../../constants/modalDialogType.ts';
import { TrackedEvent } from '../../constants/trackedEvent.ts';
import { useDispatch } from '../../hooks/useDispatch.ts';
import { useSelector } from '../../hooks/useSelector.ts';
import { FeedbackTextModalActionType } from '../../models/TrackUserEventData.ts';
import { UmuxSurveyEventTypes } from '../../models/events/UmuxSurveyEventData.type.ts';
import { UserFeedbackSource } from '../../models/events/UserFeedbackEventData.type.ts';
import { shouldShowHelpUsImproveSurveyReminder } from '../../selectors/umuxSurveySelectors.ts';
import {
  DataUiAction,
  DataUiCollection,
  DataUiElement,
  DataUiExternalLinkName,
  getDataUiActionAttribute,
  getDataUiCollectionAttribute,
  getDataUiElementAttribute,
  getDataUiNavAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

export const FeedbackMenu: React.FC = () => {
  const shouldDisplayHelpUsItem = useSelector((s) =>
    shouldShowHelpUsImproveSurveyReminder(s, new Date()),
  );
  const { isRefinedNavigationFeatureEnabled } = useContext(RefinedNavigationContext);

  const dispatch = useDispatch();

  const onClickOnGiveUsFeedbackButton = () =>
    dispatch(trackUserEvent(TrackedEvent.FeatureUsed, { feature: 'feedback-button' }));

  const onClickHelpUsImproveKc = () => {
    dispatch(
      trackUserEvent(TrackedEvent.UmuxSurvey, {
        action: UmuxSurveyEventTypes.Open,
        source: UserFeedbackSource.HelpUsImprove,
      }),
    );
    dispatch(modalOpened(ModalDialogType.HelpUsImproveSurveyDialog));
  };
  const onClickOnRoadmap = () =>
    dispatch(trackUserEvent(TrackedEvent.ResourcesUsed, { resourceType: 'roadmap' }));
  const onClickOnUxFeedback = () =>
    dispatch(trackUserEvent(TrackedEvent.ResourcesUsed, { resourceType: 'ux-feedback' }));
  const onClickSendYourFeedback = () => {
    dispatch(
      trackUserEvent(TrackedEvent.FeedbackTextModal, {
        action: FeedbackTextModalActionType.Open,
      }),
    );
    dispatch(modalOpened(ModalDialogType.SendYourFeedbackDialog));
  };

  return (
    <NavigationBarQuinaryMenuButton
      menuDataAttributes={getDataUiCollectionAttribute(DataUiCollection.FeedbackMenuOptions)}
      onMenuOpenChange={onClickOnGiveUsFeedbackButton}
      showBadge={shouldDisplayHelpUsItem}
      trigger={{
        name: 'Give us feedback',
        icon: Icons.BubbleHeart,
        fullWidth: isRefinedNavigationFeatureEnabled,
        showName: isRefinedNavigationFeatureEnabled,
        ...getDataUiActionAttribute(DataUiAction.OpenFeedbackMenu),
      }}
      role="list"
    >
      {shouldDisplayHelpUsItem && (
        <TabbableMenuItem
          id="rate-your-experience"
          label="Rate your experience"
          onAction={onClickHelpUsImproveKc}
          trailingElements={<Badge badgeStyle="default" />}
          {...getDataUiElementAttribute(DataUiElement.UmuxFeedbackItem)}
        />
      )}
      <TabbableMenuOutwardLink
        id="vote-for-feature"
        label="Vote for a feature"
        href={feedbackLinks.roadmap}
        onAction={onClickOnRoadmap}
        {...getDataUiNavAttribute(DataUiExternalLinkName.Roadmap)}
      />
      <TabbableMenuOutwardLink
        id="join-expert-group"
        label="Join our expert group"
        href={feedbackLinks.uxFeedback}
        onAction={onClickOnUxFeedback}
        tooltipText="Try new features and share your thoughts to help us shape the future of Kontent.ai."
        {...getDataUiNavAttribute(DataUiExternalLinkName.UxFeedback)}
      />
      <TabbableMenuItem
        id="send-your-feedback"
        label="Send your feedback"
        onAction={onClickSendYourFeedback}
      />
    </NavigationBarQuinaryMenuButton>
  );
};

FeedbackMenu.displayName = 'FeedbackMenuContainer';
