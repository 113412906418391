import {
  ItemColumnCode,
  translateColumnCodeToTitle,
} from '../../../../_shared/constants/itemColumnCode.ts';
import { ScrollTableHeadColumn } from '../../../../_shared/uiComponents/ScrollTable/ScrollTableHeadColumn.tsx';
import { ScrollTableHeadRow } from '../../../../_shared/uiComponents/ScrollTable/ScrollTableHeadRow.tsx';
import { DataUiCollection } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type Props = {
  readonly showScrollBar: boolean;
};

export const RelationsScrollTableHeadRow = (props: Props) => (
  <ScrollTableHeadRow collectionName={DataUiCollection.SortOptions}>
    <ScrollTableHeadColumn name="" size={1} />
    <ScrollTableHeadColumn
      name={translateColumnCodeToTitle(ItemColumnCode.Name)}
      size={15}
      isGrowing
    />
    <ScrollTableHeadColumn
      name={translateColumnCodeToTitle(ItemColumnCode.WorkflowStep)}
      size={5}
    />
    <ScrollTableHeadColumn name={translateColumnCodeToTitle(ItemColumnCode.ContentType)} size={5} />
    <ScrollTableHeadColumn name={translateColumnCodeToTitle(ItemColumnCode.DueDate)} size={5} />
    {props.showScrollBar && <div className="scroll-table__head-scroll" />}
  </ScrollTableHeadRow>
);
