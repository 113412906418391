import { Inline } from '@kontent-ai/component-library/Inline';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React, { useRef, useLayoutEffect, RefObject, useState, useMemo, useContext } from 'react';
import useResizeObserver from 'use-resize-observer';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  MainMenuStructureQuickActionsKey,
  mainMenuStructure,
} from '../../constants/editingActions/mainMenuStructure.ts';
import { ItemEditingNewDesignContext } from '../../context/ItemEditingNewDesignContext.tsx';
import { EditingAction } from '../../models/EditingAction.ts';
import { EditingActionsMenu } from './EditingActionsMenu.tsx';
import { QuickAction } from './quickActions/QuickAction.tsx';

type Props = Readonly<{
  activatedAction: EditingAction;
  availableActions: ReadonlyArray<EditingAction>;
  isInPublishingOrArchivingStep: boolean;
  onDeactivateAction: () => void;
  onShowActionsMenu: () => void;
}>;

const AllQuickActions: ReadonlyArray<EditingAction> =
  mainMenuStructure.find((group) => group.key === MainMenuStructureQuickActionsKey)?.actions || [];

const SpacingBetweenButtons = Spacing.M;

export const EditingActions: React.FC<Props> = (props) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const availableQuickActions = AllQuickActions.filter(
    (action) => props.availableActions.includes(action) && !props.isInPublishingOrArchivingStep,
  );
  const lastVisibleItemIndex = useCalculateLastVisibleItemIndex(
    containerRef,
    availableQuickActions.join(','),
    SliceDirection.Horizontal,
    SpacingBetweenButtons,
  );

  const quickActions = availableQuickActions.filter((_, index) => index <= lastVisibleItemIndex);
  const menuActions = props.availableActions.filter((action) => !quickActions.includes(action));

  const getActivatedAction = (actions: ReadonlyArray<EditingAction>) =>
    actions.includes(props.activatedAction) ? props.activatedAction : EditingAction.none;

  const isNewDesign = useContext(ItemEditingNewDesignContext);

  return (
    <Row alignY="center" noWrap spacingX={SpacingBetweenButtons}>
      <Column>
        <Inline
          {...getDataUiCollectionAttribute(DataUiCollection.QuickActions)}
          noWrap
          ref={containerRef}
          spacingX={SpacingBetweenButtons}
          css={isNewDesign ? 'justify-content: flex-end;' : undefined}
        >
          {quickActions.map((action) => (
            <QuickAction
              action={action}
              activatedAction={getActivatedAction(quickActions)}
              key={action}
              onClickOutside={props.onDeactivateAction}
            />
          ))}
        </Inline>
      </Column>
      <Column width="content">
        <EditingActionsMenu
          activatedAction={getActivatedAction(menuActions)}
          actions={menuActions}
          onShowMenu={props.onShowActionsMenu}
          onHideMenu={props.onDeactivateAction}
          areAllActionsInMenu={quickActions.length === 0}
        />
      </Column>
    </Row>
  );
};

enum SliceDirection {
  Horizontal = 'Horizontal',
  Vertical = 'Vertical',
}

const useCalculateLastVisibleItemIndex = (
  containerRef: RefObject<HTMLElement>,
  itemsIdentity: string,
  direction: SliceDirection,
  spacing: number = 0,
): number => {
  const [containerSize, setContainerSize] = useState<number | null>(null);
  const [breakpoints, setBreakpoints] = useState<ReadonlyArray<number> | null>(null);
  const [previousValues, setPreviousValues] = useState({ itemsIdentity, direction, spacing });

  if (
    itemsIdentity !== previousValues.itemsIdentity ||
    direction !== previousValues.direction ||
    spacing !== previousValues.spacing
  ) {
    setContainerSize(null);
    setBreakpoints(null);
    setPreviousValues({ itemsIdentity, direction, spacing });
  }

  useLayoutEffect(
    function init() {
      if (!containerRef.current) {
        return;
      }

      if (containerSize !== null && breakpoints !== null) {
        return;
      }

      const container = containerRef.current;

      const newBreakpoints = calculateBreakpoints(container, direction, spacing);
      const availableContainerSize = getAvailableContainerSize(
        container.clientWidth,
        container.clientHeight,
        direction,
      );
      setBreakpoints(newBreakpoints);
      setContainerSize(availableContainerSize);
    },
    [breakpoints, containerRef, containerSize, direction, spacing],
  );

  useResizeObserver({
    onResize: ({ width = 0, height = 0 }) => {
      const availableContainerSize = getAvailableContainerSize(width, height, direction);
      setContainerSize(availableContainerSize);
    },
    ref: containerRef,
  });

  return useMemo(() => {
    if (containerSize === null || breakpoints === null) {
      return Number.MAX_VALUE;
    }
    return breakpoints.findLastIndex((breakpoint) => breakpoint <= containerSize);
  }, [breakpoints, containerSize]);
};

const getAvailableContainerSize = (
  containerWidth: number,
  containerHeight: number,
  direction: SliceDirection,
): number => (direction === SliceDirection.Horizontal ? containerWidth : containerHeight);

const calculateBreakpoints = (
  container: HTMLElement,
  direction: SliceDirection,
  spacing: number,
): ReadonlyArray<number> => {
  const getElementSize =
    direction === SliceDirection.Horizontal ? getElementWidth : getElementHeight;

  const childElements = Array.from(container.childNodes) as HTMLElement[];

  const breakpoints: number[] = [];
  childElements.forEach((element, i) => {
    const lastBreakpoint = breakpoints.at(-1) || 0;
    const spaceBetweenElements = i > 0 ? spacing : 0;
    breakpoints.push(lastBreakpoint + spaceBetweenElements + getElementSize(element));
  });

  return breakpoints;
};

const getElementWidth = (element: HTMLElement): number => element.offsetWidth;
const getElementHeight = (element: HTMLElement): number => element.offsetHeight;
