import { memoize } from '@kontent-ai/memoization';
import { getEditedContentItemVariantId } from '../../../../../_shared/selectors/getEditedContentItemVariant.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { logError } from '../../../../../_shared/utils/logError.ts';
import { doesRoleAllowViewContentGroup } from '../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { getContributorsAvailableAsAssigneesInTasks } from '../../../../../_shared/utils/permissions/getAvailableContributors.ts';
import { getApplicableContributorRoleId } from '../../../../../_shared/utils/permissions/getContributorRole.ts';
import { IRoleWithSettings } from '../../../../../data/models/roles/IRoleWithSettings.ts';
import { IProjectContributor } from '../../../../../data/models/users/ProjectContributor.ts';

const memoizeLatestAssignees = memoize.maxNWithTransformedArgs(
  (projectContributors: ReadonlyArray<IProjectContributor>): ReadonlyArray<IProjectContributor> =>
    projectContributors,
  (args) => args[0],
  1,
);

const getTaskAssigneesWithoutAccessMemoized = memoize.maxOne(
  (
    taskAssignees: ReadonlyArray<IProjectContributor>,
    assignedContributors: ReadonlyArray<IProjectContributor>,
    editedContentItemTypeId: string,
    rolesById: ReadonlyMap<Uuid, IRoleWithSettings>,
    variantId: Uuid,
    collectionId: Uuid,
  ) =>
    taskAssignees.filter(
      (taskAssignee) =>
        !doesRoleAllowViewContentGroup(
          null,
          editedContentItemTypeId,
          rolesById.get(
            getApplicableContributorRoleId(taskAssignee, variantId, collectionId) ?? '',
          ),
          assignedContributors.some(
            (assignedContributor) => assignedContributor.userId === taskAssignee.userId,
          ),
        ),
    ),
);

export const getTaskAssigneesWithoutAccess = (
  state: IStore,
  taskAssignees: ReadonlyArray<IProjectContributor>,
): readonly IProjectContributor[] => {
  const editedItem = state.contentApp.editedContentItem;
  if (!editedItem) {
    logError('editedItem is undefined');
    return [];
  }

  const editedVariantId = getEditedContentItemVariantId(state);
  if (!editedVariantId) {
    logError('editedVariantId is undefined');
    return [];
  }

  const { assigned: assignedContributors } = getContributorsAvailableAsAssigneesInTasks(state);
  const { rolesById } = state.data.roles;

  const taskAssigneesWithoutAccess = getTaskAssigneesWithoutAccessMemoized(
    taskAssignees,
    assignedContributors,
    editedItem.editedContentItemTypeId,
    rolesById,
    editedVariantId.variantId,
    editedItem.collectionId,
  );

  return memoizeLatestAssignees(taskAssigneesWithoutAccess);
};
