import { ReactNode, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { ThunkPromise } from '../../../@types/Dispatcher.type.ts';
import { initCustomAppListing } from '../../../applications/customApps/actions/thunkCustomAppActions.ts';
import { projectContainerContextLeft } from '../../../applications/projectSettings/root/actions/projectSettingsActions.ts';
import { clearSelectedSubscription } from '../../../applications/subscriptionManagement/shared/actions/subscriptionManagementActions.ts';
import { loadUserProjectsInfo } from '../../../data/actions/thunkDataActions.ts';
import { IUserProjectInfo } from '../../../data/models/user/UserProjectInfo.ts';
import { projectContainerIdStorage } from '../../../localStorages/projectContainerStorage.ts';
import { currentProjectContainerUpdated } from '../../actions/sharedActions.ts';
import {
  loadProjectFeatureFlags,
  loadProjectProperties,
  loadWebSpotlightConfiguration,
} from '../../actions/thunkSharedActions.ts';
import { trackUserEvent } from '../../actions/thunks/trackUserEvent.ts';
import { Loader } from '../../components/Loader.tsx';
import {
  ProjectRouteParams,
  ProjectSettingsRoute,
  ProjectsRoute,
} from '../../constants/routePaths.ts';
import { TrackedEvent } from '../../constants/trackedEvent.ts';
import { useDispatch } from '../../hooks/useDispatch.ts';
import { useSelector } from '../../hooks/useSelector.ts';
import { useThunkPromise } from '../../hooks/useThunkPromise.ts';
import { LoadingStatus } from '../../models/LoadingStatusEnum.ts';
import {
  getCurrentEnvironmentIdForProjectContainer,
  getCurrentProjectContainerId,
} from '../../selectors/userProjectsInfoSelectors.ts';
import { buildPath } from '../../utils/routing/routeTransitionUtils.ts';
import { isUuid } from '../../utils/validation/typeValidators.ts';

export const useProjectContainerRouteEntered = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearSelectedSubscription());

    return () => {
      dispatch(projectContainerContextLeft());
    };
  }, []);
};

export const useReconcileCurrentProjectContainerIdWithRouteParameter = (
  desiredProjectContainerId: Uuid | null,
  userProjects: Immutable.Map<string, IUserProjectInfo> | null,
) => {
  const history = useHistory();
  const projectToBeSet =
    ((desiredProjectContainerId &&
      userProjects?.filter((p) => p?.projectContainerId === desiredProjectContainerId)?.first()) ||
      userProjects?.first()) ??
    null;
  const projectContainerIdToBeSet = projectToBeSet?.projectContainerId;

  const dispatch = useDispatch();

  useEffect(() => {
    if (userProjects && !projectToBeSet) {
      history.push(ProjectsRoute);
    }
  }, [history, projectToBeSet, userProjects]);

  useEffect(() => {
    if (
      isUuid(projectContainerIdToBeSet) &&
      projectContainerIdToBeSet !== desiredProjectContainerId
    ) {
      history.push(
        buildPath<ProjectRouteParams>(ProjectSettingsRoute, {
          projectContainerId: projectContainerIdToBeSet,
        }),
      );
    }
  }, [desiredProjectContainerId, history, projectContainerIdToBeSet]);

  useEffect(() => {
    if (
      isUuid(projectContainerIdToBeSet) &&
      projectContainerIdToBeSet === desiredProjectContainerId
    ) {
      dispatch(currentProjectContainerUpdated(projectContainerIdToBeSet));
    }
  }, [desiredProjectContainerId, projectContainerIdToBeSet]);
};

type Props = {
  readonly children: ReactNode;
};

export const EnsureCurrentProjectContainer = (props: Props) => {
  const currentProjectContainerId = useSelector(getCurrentProjectContainerId);
  const userProjects = useSelector((state) => state.data.user.projectsInfoById);
  const customAppsLoadingStatus = useSelector((state) => state.data.userCustomApps.loadingStatus);

  const desiredProjectContainerId =
    useParams<ProjectRouteParams>().projectContainerId || projectContainerIdStorage.load();

  const areProjectContainersReconciled =
    isUuid(currentProjectContainerId) && currentProjectContainerId === desiredProjectContainerId;

  const currentEnvironmentId = useSelector((s) =>
    getCurrentEnvironmentIdForProjectContainer(s.data.user, desiredProjectContainerId),
  );

  useProjectContainerRouteEntered();
  useReconcileCurrentProjectContainerIdWithRouteParameter(desiredProjectContainerId, userProjects);

  const dispatch = useDispatch();

  useEffect(() => {
    if (areProjectContainersReconciled) {
      dispatch(trackUserEvent(TrackedEvent.CurrentProjectContainerUpdated));
      projectContainerIdStorage.save(currentProjectContainerId);
    }
  }, [areProjectContainersReconciled, currentProjectContainerId]);

  const [isLoadUserProjectsThunkDone] = useThunkPromise(loadUserProjectsInfo);

  const [isFetchProjectContainerDataThunkDone] = useThunkPromise(
    fetchProjectContainerData,
    currentEnvironmentId,
    {
      canRun: areProjectContainersReconciled,
    },
  );

  const isStateEnsured =
    isLoadUserProjectsThunkDone &&
    isFetchProjectContainerDataThunkDone &&
    areProjectContainersReconciled &&
    customAppsLoadingStatus === LoadingStatus.Loaded;

  return isStateEnsured ? props.children : <Loader />;
};

const fetchProjectContainerData =
  (environmentId: Uuid | null, abortSignal: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    if (!environmentId) return;
    await Promise.all([
      dispatch(loadProjectFeatureFlags(environmentId, abortSignal)),
      dispatch(loadProjectProperties(environmentId, abortSignal)),
      dispatch(loadWebSpotlightConfiguration(environmentId, abortSignal)),
      dispatch(initCustomAppListing(environmentId, abortSignal)),
    ]);
  };
