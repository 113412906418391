import { Icons } from '@kontent-ai/component-library/Icons';
import { ControlShortcutTemplate } from '../../../../../../_shared/constants/shortcutSymbols.ts';
import {
  DataUiRteAction,
  getDataUiActionAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  RTEToolbarIconButton,
  ToggleButtonProps,
} from '../../../toolbars/components/buttons/RTEToolbarButton.tsx';

export const LinkButton = (props: ToggleButtonProps) => (
  <RTEToolbarIconButton
    {...props}
    icon={Icons.Chain}
    shortcut={ControlShortcutTemplate('K')}
    tooltipPlacement={props.tooltipPlacement}
    tooltipText={props.tooltipText ?? 'Insert link'}
    {...getDataUiActionAttribute(DataUiRteAction.Link)}
  />
);
