import { Icons } from '@kontent-ai/component-library/Icons';
import {
  DataUiRteAction,
  getDataUiActionAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  RTEToolbarIconButton,
  ToggleButtonProps,
} from '../../../toolbars/components/buttons/RTEToolbarButton.tsx';

export const Heading4Button = (props: ToggleButtonProps) => (
  <RTEToolbarIconButton
    {...props}
    icon={Icons.H4}
    tooltipPlacement={props.tooltipPlacement}
    tooltipText={props.tooltipText ?? 'Heading 4'}
    {...getDataUiActionAttribute(DataUiRteAction.HeaderFour)}
  />
);
