import { trackUserEvent } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import { loadProjects, loadUserProjectsInfo } from '../../../../data/actions/thunkDataActions.ts';
import { createSaveEnvironmentNameToServerAction } from './thunks/saveEnvironmentNameToServer.ts';
import { setLivePreviewConfigurationCreator } from './thunks/setLivePreviewConfiguration.ts';

const { projectEnvironmentSettingsRepository, projectRepository } = repositoryCollection;

export const saveEnvironmentNameToServer = createSaveEnvironmentNameToServerAction({
  projectRepository,
  loadUserProjectsInfo,
  loadProjects,
  trackUserEvent,
});

export const setLivePreviewConfiguration = setLivePreviewConfigurationCreator({
  projectEnvironmentSettingsRepository,
});
