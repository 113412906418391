import {
  ScrollAlignment,
  ScrollElementsToViewOptions,
  scrollElementsToView,
} from '@kontent-ai/DOM';
import { notNull } from '@kontent-ai/utils';
import { useContext, useEffect, useRef } from 'react';
import { ScrollContainerContext } from '../../../../../../../component-library/components/ScrollContainer/ScrollContainerContext.tsx';
import { waitUntilFocusAndScrollAreNotDeferred } from '../../../../../../_shared/utils/autoScrollUtils.ts';
import { NavigatedFromData } from '../../../../../contentInventory/content/stores/IContentAppStoreState.ts';
import { ICommentThread } from '../../../../models/comments/CommentThreads.ts';
import {
  getCommentThreadElement,
  getCommentedFragmentCssSelector,
  isThreadInline,
  isThreadResolvedWithoutUndo,
} from '../../../../utils/commentUtils.ts';
import { ItemEditorScrollOptions } from '../../constants/uiConstants.ts';

type Props = {
  readonly focusedThread: ICommentThread | null;
  readonly navigatedFrom: NavigatedFromData | null;
};

export const FocusedCommentThreadScroller = ({ focusedThread, navigatedFrom }: Props) => {
  const { scrollContainerRef } = useContext(ScrollContainerContext);
  const lastScrolledRef = useRef<{
    readonly threadId: Uuid;
    readonly navigatedFrom: NavigatedFromData | null;
  } | null>(null);

  useEffect(() => {
    if (
      focusedThread &&
      // Scroll when entering the page via comment GOTO link
      (!lastScrolledRef.current ||
        // Scroll upon change in focused comment
        focusedThread.id !== lastScrolledRef.current.threadId ||
        // Scroll when navigating from sibling to already focused comment
        navigatedFrom !== lastScrolledRef.current.navigatedFrom)
    ) {
      scrollToFocusedComment(focusedThread, navigatedFrom, scrollContainerRef.current);
    }
    lastScrolledRef.current = focusedThread ? { threadId: focusedThread.id, navigatedFrom } : null;
  }, [focusedThread, navigatedFrom, scrollContainerRef]);

  return null;
};

const getFocusedFragmentElement = (focusedThread: ICommentThread): HTMLElement | null => {
  const cssSelector = getCommentedFragmentCssSelector(focusedThread);
  if (!cssSelector) {
    return null;
  }

  return document.querySelector(cssSelector);
};

const scrollToFocusedComment = waitUntilFocusAndScrollAreNotDeferred(
  (
    focusedThread: ICommentThread,
    navigatedFrom: NavigatedFromData | null,
    scrollParent: HTMLElement | null,
  ) => {
    const focusedFragmentElement = getFocusedFragmentElement(focusedThread);
    const focusedThreadElement = getCommentThreadElement(focusedThread.id);
    if (focusedThreadElement) {
      const navigatedFromOptions: ScrollElementsToViewOptions | undefined = navigatedFrom
        ? {
            // When the comment is being navigated to with arrows, we try to place it to the same position
            // as previous comment to make sure the next comment arrow lands under the mouse cursor
            idealTop: navigatedFrom?.threadClientTop ?? undefined,
            // If it doesn't fit, we at least align it to the bottom so the whole comment is visible
            alignment: ScrollAlignment.Bottom,
            // Unless it doesn't fit, in that case we prefer the arrows to be visible
            noFitAlignment: ScrollAlignment.Top,
          }
        : undefined;

      const threadScrollOptions: ScrollElementsToViewOptions = {
        ...ItemEditorScrollOptions,
        // When the comment is being replied to, we prefer bottom alignment so that the reply doesn't hide
        // behind the fold upon refocusing in case the comment thread is long
        alignment: focusedThread?.isReplying
          ? ScrollAlignment.Bottom
          : ItemEditorScrollOptions.alignment,
        scrollParent: scrollParent ?? undefined,
        ...navigatedFromOptions,
      };

      const isFocusedThreadInlineAndUnresolved =
        focusedThread &&
        isThreadInline(focusedThread) &&
        !isThreadResolvedWithoutUndo(focusedThread);
      if (isFocusedThreadInlineAndUnresolved) {
        scrollElementsToView(
          [focusedFragmentElement, focusedThreadElement].filter(notNull),
          threadScrollOptions,
        );
      } else {
        if (focusedFragmentElement) {
          scrollElementsToView([focusedFragmentElement], ItemEditorScrollOptions);
        }
        scrollElementsToView([focusedThreadElement], threadScrollOptions);
      }
    }
  },
);
