import { Collection } from '@kontent-ai/utils';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { TypeElementConfigurationCategory } from '../../../../../applications/contentModels/shared/components/typeElements/shared/TypeElementConfigurationCategory.tsx';
import { AiGuidelines } from '../../../data/models/aiGuidelines.ts';
import {
  getAiGuidelinesById,
  getAllAiGuidelines,
} from '../../../data/reducers/selectors/getAiGuidelines.ts';
import { createAiGuidelines, updateAiGuidelines } from '../actions/thunkAiGuidelinesActions.ts';
import { AiGuidelinesConfiguration as AiGuidelinesConfigurationComponent } from '../components/AiGuidelinesConfiguration.tsx';

const getSelectedAiGuidelines = (state: IStore, ids: ReadonlyArray<Uuid>): AiGuidelines | null => {
  const selectedAiGuidelinesId = Collection.getFirst(ids);

  return selectedAiGuidelinesId ? getAiGuidelinesById(state, selectedAiGuidelinesId) : null;
};

type AiGuidelinesConfigurationProps = {
  readonly onAiGuidelinesSelect: (AiGuidelines: ReadonlyArray<Uuid>) => void;
  readonly selectedAiGuidelinesIds: ReadonlyArray<Uuid>;
};

export const AiGuidelinesConfiguration = ({
  selectedAiGuidelinesIds,
  onAiGuidelinesSelect,
}: AiGuidelinesConfigurationProps) => {
  const dispatch = useDispatch();
  const aiGuidelines = useSelector(getAllAiGuidelines);
  const selectedAiGuidelines = useSelector((state) =>
    getSelectedAiGuidelines(state, selectedAiGuidelinesIds),
  );

  return (
    <TypeElementConfigurationCategory>
      <AiGuidelinesConfigurationComponent
        aiGuidelines={aiGuidelines}
        onAiGuidelinesCreate={async (aiGuidelinesFormShape) => {
          const id = await dispatch(createAiGuidelines(aiGuidelinesFormShape));
          onAiGuidelinesSelect([id]);
        }}
        onAiGuidelinesEdit={(id, aiGuidelinesFormShape) => {
          dispatch(updateAiGuidelines(id, aiGuidelinesFormShape));
        }}
        onAiGuidelinesSelect={onAiGuidelinesSelect}
        selectedAiGuidelines={selectedAiGuidelines}
      />
    </TypeElementConfigurationCategory>
  );
};
