import { Button, RouterLinkButton } from '@kontent-ai/component-library/Button';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import classNames from 'classnames';
import { Pathname } from 'history';
import Immutable from 'immutable';
import React, { memo } from 'react';
import { DataTable } from '../../../../_shared/components/DataTable/DataTable.tsx';
import {
  Column,
  DataTableHeadRow,
} from '../../../../_shared/components/DataTable/DataTableHeadRow.tsx';
import { DataTableRow } from '../../../../_shared/components/DataTable/DataTableRow.tsx';
import { LinkDataTableCell } from '../../../../_shared/components/DataTable/LinkDataTableCell.tsx';
import { EmptyState } from '../../../../_shared/components/EmptyState/EmptyState.tsx';
import { SearchPhraseHighlighter } from '../../../../_shared/components/Highlighting/SearchPhraseHighlighter.tsx';
import { HtmlPageTitle } from '../../../../_shared/components/HtmlPageTitle.tsx';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { TextFilter } from '../../../../_shared/components/TextFilter.tsx';
import { AppNames } from '../../../../_shared/constants/applicationNames.ts';
import {
  EditContentTypeRoute,
  EditContentTypeRouteParams,
} from '../../../../_shared/constants/routePaths.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { OrderByDirection } from '../../../../_shared/models/OrderBy.ts';
import { ListingMessage } from '../../../../_shared/uiComponents/ListingMessage/ListingMessage.tsx';
import {
  DataUiAction,
  DataUiAppName,
  DataUiCollection,
  getDataUiActionAttribute,
  getDataUiAppNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { buildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { isEmptyOrWhitespace } from '../../../../_shared/utils/stringUtils.ts';
import { IContentType } from '../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import {
  EmptyContentTypeUsage,
  IContentTypeUsage,
  isContentTypeUsed,
} from '../../../../data/models/contentModelsApp/contentTypes/ContentTypeUsage.ts';
import { IWebSpotlightConfiguration } from '../../../webSpotlight/reducers/configuration.ts';
import { AuditLogTip } from '../../shared/containers/AuditLogTip.tsx';
import { ContentTypeListingOperationStatus } from '../utils/contentTypeListingOperationStatus.ts';
import { UsageNumberStatus } from './UsageNumberStatus.tsx';
import { UsageNumberStatusWithModalIcon } from './UsageNumberWithModal.tsx';

const tableHeadColumns: ReadonlyArray<Column> = [
  {
    columnName: 'Name',
    orderBy: OrderByDirection.None,
  },
  {
    columnName: 'Components',
    orderBy: OrderByDirection.None,
    className: 'data-table__column--4',
    infoTooltipText: 'Items that contain components of a given type',
  },
  {
    columnName: 'Content items',
    orderBy: OrderByDirection.None,
    className: 'data-table__column--4',
  },
];

type ContentTypeListingProps = {
  readonly contentTypeListingLoadingStatus: LoadingStatus;
  readonly contentTypes: ReadonlyArray<IContentType>;
  readonly createNewTypePath: Pathname;
  readonly currentProjectId: Uuid;
  readonly filterSearchPhrase: string;
  readonly listingOperationStatus: ContentTypeListingOperationStatus;
  readonly onArchiveContentTypes: (contentTypeIds: Immutable.Set<Uuid>) => void;
  readonly onFilterContentTypes: (name: string) => void;
  readonly onOpenContentTypeAsComponentsUsageModal: (contentTypeId: Uuid) => void;
  readonly onSelectContentTypes: (selectedIds: Immutable.Set<Uuid>) => void;
  readonly onUndoOperation: (() => void) | undefined;
  readonly onUnselectContentTypes: (unselectedIds: Immutable.Set<Uuid>) => void;
  readonly selectedContentTypes: Immutable.Set<Uuid>;
  readonly usageCountPerContentType: Immutable.Map<Uuid, IContentTypeUsage>;
  readonly webSpotlightConfiguration: IWebSpotlightConfiguration | null;
};

const ContentTypeListing: React.FC<ContentTypeListingProps> = ({
  contentTypeListingLoadingStatus,
  contentTypes,
  createNewTypePath,
  currentProjectId,
  filterSearchPhrase,
  listingOperationStatus,
  onArchiveContentTypes,
  onFilterContentTypes,
  onOpenContentTypeAsComponentsUsageModal,
  onSelectContentTypes,
  onUndoOperation,
  onUnselectContentTypes,
  selectedContentTypes,
  usageCountPerContentType,
  webSpotlightConfiguration,
}) => {
  const selectableTypeIds = Immutable.Set<Uuid>(
    contentTypes
      .filter(
        (type) =>
          !!type &&
          !isContentTypeUsed(usageCountPerContentType.get(type.id, EmptyContentTypeUsage)),
      )
      .map((type: IContentType) => type.id),
  );

  const selectAll = () => onSelectContentTypes(selectableTypeIds);
  const deselectAll = () => onUnselectContentTypes(selectableTypeIds);

  const archiveContentTypes = (): void => {
    // Unselect archived Content types, since they can't be accessed via the UI yet
    onUnselectContentTypes(selectedContentTypes);
    onArchiveContentTypes(selectedContentTypes);
  };

  const renderTypesList = (): Array<JSX.Element> => {
    return contentTypes.map((listingContentTypeItem: IContentType) => {
      const typeId = listingContentTypeItem.id;
      // Selection data
      const isSelected = selectedContentTypes.includes(typeId);
      const selectionAction = isSelected
        ? () => onUnselectContentTypes(Immutable.Set.of<Uuid>(typeId))
        : () => onSelectContentTypes(Immutable.Set.of<Uuid>(typeId));

      const numbersOfItemsUsedIn = usageCountPerContentType.get(typeId, EmptyContentTypeUsage);
      const linkPath = buildPath<EditContentTypeRouteParams>(EditContentTypeRoute, {
        projectId: currentProjectId,
        contentTypeId: typeId,
      });

      const tableCellClassNames = classNames({ 'data-table__cell-item--is-disabled': !linkPath });

      const isWSLRootType =
        !!webSpotlightConfiguration &&
        webSpotlightConfiguration.isEnabled &&
        webSpotlightConfiguration.rootTypeId === typeId;
      const disabledTooltipText = isWSLRootType
        ? 'This type is used by Web Spotlight and cannot be deleted.'
        : 'Content items or components are using this type.';

      return (
        <DataTableRow
          dataUiObjectName={listingContentTypeItem.name}
          disabledCheckboxBalloonText={disabledTooltipText}
          id={typeId}
          isSelected={isSelected}
          key={typeId}
          onSelectionChange={selectionAction}
          selectionDisabled={isContentTypeUsed(numbersOfItemsUsedIn) || isWSLRootType}
          showCheckboxes
        >
          <LinkDataTableCell
            className={tableCellClassNames}
            linkPath={linkPath}
            focusableRowLinkAriaLabel={`visit content type — ${listingContentTypeItem.name}`}
          >
            <SearchPhraseHighlighter
              searchPhrase={filterSearchPhrase}
              text={listingContentTypeItem.name}
            />
          </LinkDataTableCell>
          <LinkDataTableCell className={tableCellClassNames} linkPath={linkPath}>
            <UsageNumberStatusWithModalIcon
              usage={numbersOfItemsUsedIn.contentComponentCount}
              onClick={() => onOpenContentTypeAsComponentsUsageModal(typeId)}
            />
          </LinkDataTableCell>
          <LinkDataTableCell className={tableCellClassNames} linkPath={linkPath}>
            <UsageNumberStatus usage={numbersOfItemsUsedIn.itemCount} />
          </LinkDataTableCell>
        </DataTableRow>
      );
    });
  };

  const renderDataTableGridComponent = (): JSX.Element => {
    const isMainCheckboxChecked =
      !selectableTypeIds.isEmpty() && selectableTypeIds.isSubset(selectedContentTypes);

    const showInfoMessage =
      contentTypeListingLoadingStatus === LoadingStatus.Loaded && !contentTypes.length;

    return (
      <DataTable
        createNewItemLinkPath={createNewTypePath}
        actions={
          selectedContentTypes.isEmpty()
            ? []
            : [
                {
                  dataUiAction: DataUiAction.Delete,
                  iconName: 'Bin',
                  isDestructive: true,
                  isDisabled: false,
                  onClick: archiveContentTypes,
                  text: 'Delete',
                },
              ]
        }
        dataUiCollectionName={DataUiCollection.ContentTypes}
        title={
          <ListingMessage
            statusInfoMessage={{
              text: listingOperationStatus.message,
              style: listingOperationStatus.messageStyle,
            }}
            onUndo={onUndoOperation}
          />
        }
        header={
          !showInfoMessage && (
            <DataTableHeadRow
              allEntriesSelected={isMainCheckboxChecked}
              columns={tableHeadColumns}
              isAllEntriesSelectorDisabled={selectableTypeIds.isEmpty()}
              onCheckboxChange={isMainCheckboxChecked ? deselectAll : selectAll}
              showCheckboxes
            />
          )
        }
        infoMessage={
          showInfoMessage &&
          (isEmptyOrWhitespace(filterSearchPhrase) ? (
            <EmptyState>
              <EmptyState.Title>No templates for your content yet.</EmptyState.Title>
              <EmptyState.Content>
                Content types act as templates for the content you can create. Start by defining the
                first one.
              </EmptyState.Content>
              <EmptyState.Footer>
                <RouterLinkButton
                  buttonStyle="primary"
                  to={createNewTypePath}
                  {...getDataUiActionAttribute(DataUiAction.CreateNew)}
                >
                  Create new Content type
                </RouterLinkButton>
              </EmptyState.Footer>
            </EmptyState>
          ) : (
            <EmptyState>
              <EmptyState.Title>No Content types found.</EmptyState.Title>
              <EmptyState.Content>
                Try rephrasing your search phrase or start over.
              </EmptyState.Content>
              <EmptyState.Footer>
                <Button
                  buttonStyle="primary"
                  onClick={() => onFilterContentTypes('')}
                  {...getDataUiActionAttribute(DataUiAction.ClearFilter)}
                >
                  Clear your search phrase
                </Button>
              </EmptyState.Footer>
            </EmptyState>
          ))
        }
      >
        {renderTypesList()}
      </DataTable>
    );
  };

  return (
    <div className="canvas__workspace">
      <div className="canvas__content">
        <div
          className="canvas__content-pane"
          {...getDataUiAppNameAttribute(DataUiAppName.ContentTypeListing)}
        >
          <HtmlPageTitle appName={AppNames.ContentTypes} />
          <div className="canvas__inner-section canvas__inner-section--restricted-width canvas__inner-section--centered">
            <Stack spacing={Spacing.XL}>
              {(contentTypes.length || !isEmptyOrWhitespace(filterSearchPhrase)) && (
                <TextFilter
                  onChange={onFilterContentTypes}
                  text={filterSearchPhrase}
                  autofocus
                  ariaLabel="Search content type"
                />
              )}
              {contentTypeListingLoadingStatus === LoadingStatus.Loaded ? (
                <>
                  {renderDataTableGridComponent()}
                  {!!contentTypes.length && <AuditLogTip />}
                </>
              ) : (
                <Loader />
              )}
            </Stack>
          </div>
        </div>
      </div>
    </div>
  );
};

ContentTypeListing.displayName = 'ContentTypeListing';

const ContentTypeListingMemoized = memo(ContentTypeListing);
export { ContentTypeListingMemoized as ContentTypeListing };
