import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../../@types/Dispatcher.type.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { ElementType } from '../../../../contentInventory/content/models/ContentItemElementType.ts';
import { typeElementSelectionToggled } from '../../../contentTypes/actions/contentTypesActions.ts';
import { firstInvalidElementFocused } from '../../actions/sharedContentModelsActions.ts';
import { DraggableTypeElement as DraggableTypeElementComponent } from '../../components/typeElements/shared/DraggableTypeElement.tsx';
import {
  ITypeElementDispatchProps,
  ITypeElementExtendedOwnProps,
  ITypeElementStateProps,
} from '../../components/typeElements/shared/TypeElement.tsx';
import { ContentTypeKind } from '../../constants/contentTypeKind.ts';
import { IBaseTypeElementData } from '../../models/elements/types/TypeElementData.ts';
import { BaseTypeElementValidationResult } from '../../utils/typeElementValidators/types/BaseTypeElementValidationResult.type.ts';

const mapStateToProps = (
  state: IStore,
  {
    typeElementData,
    firstIncompleteElementId,
    contentTypeKind,
  }: ITypeElementExtendedOwnProps<IBaseTypeElementData, BaseTypeElementValidationResult>,
): ITypeElementStateProps => {
  const {
    data: {
      contentTypeUsages: { loadingStatus: usagesLoadingStatus },
    },
    contentModelsApp: {
      typeEditor: {
        editedType: { contentGroups, id: editedContentTypeId },
        typeIsBeingSaved,
      },
      snippets: {
        editor: { contentTypeSnippetIsBeingSaved },
      },
      contentTypes: {
        editor: { selectedTypeElementIds },
      },
    },
  } = state;

  const isSnippetElement = typeElementData.type === ElementType.ContentTypeSnippet;
  const isExistingContentType = !!editedContentTypeId;
  const isForSnippet = contentTypeKind === ContentTypeKind.ContentSnippet;
  const isForAssetType = contentTypeKind === ContentTypeKind.AssetType;

  return {
    canBeSelected: !isForSnippet && !!contentGroups.length,
    isFocused: !!firstIncompleteElementId && firstIncompleteElementId === typeElementData.elementId,
    id: typeElementData.elementId,
    isSelected: selectedTypeElementIds.includes(typeElementData.elementId),
    isDeleteButtonDisabled:
      (!isForAssetType && isExistingContentType && usagesLoadingStatus !== LoadingStatus.Loaded) ||
      typeIsBeingSaved ||
      contentTypeSnippetIsBeingSaved,
    showCodename: !isSnippetElement,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ITypeElementDispatchProps => ({
  firstIncompleteElementFocused: () => dispatch(firstInvalidElementFocused()),
  onSelectedToggle: (elementId, value) => dispatch(typeElementSelectionToggled(elementId, value)),
});

export const DraggableTypeElement: React.ComponentType<
  React.PropsWithChildren<
    ITypeElementExtendedOwnProps<IBaseTypeElementData, BaseTypeElementValidationResult>
  >
> = connect(mapStateToProps, mapDispatchToProps)(DraggableTypeElementComponent);
