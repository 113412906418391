import { InvariantException } from '@kontent-ai/errors';
import React from 'react';
import { trackUserEvent } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { ActiveCapabilityType } from '../../../../../_shared/models/activeCapability.type.ts';
import { Asset, IAsset } from '../../../../../data/models/assets/Asset.ts';
import { AssetActionMenu as AssetActionMenuComponent } from '../../components/AssetEditing/AppBar/AssetActionMenu.tsx';
import { isAssetReplaceFeatureAvailable } from '../../selectors/isAssetReplaceFeatureAvailable.ts';

type Props = {
  readonly onDelete: () => void;
  readonly onReplace: () => void;
};

const getAssetReplaceDisabledMessage = (
  canUpdateAssets: boolean,
  isEditedAssetModified: boolean,
) => {
  if (canUpdateAssets) {
    return isEditedAssetModified ? 'Save your changes first.' : undefined;
  }

  return 'You don’t have permission to edit and replace assets in this collection.';
};

export const AssetActionMenu: React.FC<Props> = ({ onDelete, onReplace }) => {
  const dispatch = useDispatch();

  const editedAsset = useSelector((s) => s.assetLibraryApp.assetUiEditor.editedAsset);
  if (!editedAsset) {
    throw InvariantException('Edited asset is falsy');
  }

  const isEditedAssetModified = useSelector(
    (s) => s.assetLibraryApp.assetUiEditor.isEditedAssetModified,
  );
  const assetUsage = useSelector((s) => s.assetLibraryApp.usage.get(editedAsset.id));
  const isAssetReplaceAvailable = useSelector(isAssetReplaceFeatureAvailable);

  const canDeleteAssets = Asset.hasCapability(editedAsset, ActiveCapabilityType.DeleteAssets);
  const canUpdateAssets = Asset.hasCapability(editedAsset, ActiveCapabilityType.UpdateAssets);
  const isDeleteDisabled = (!!assetUsage && assetUsage.length > 0) || !canDeleteAssets;

  const assetReplaceDisabledMessage = getAssetReplaceDisabledMessage(
    canUpdateAssets,
    isEditedAssetModified,
  );

  const trackUserEventOnDownload = (asset: IAsset) => {
    dispatch(
      trackUserEvent(TrackedEvent.AssetDownloaded, {
        size: asset.fileSize,
        type: asset.type,
        'asset-id': asset.id,
      }),
    );
  };

  return (
    <AssetActionMenuComponent
      asset={editedAsset}
      deleteDisabled={isDeleteDisabled}
      isAssetReplaceAvailable={isAssetReplaceAvailable}
      assetDeleteDisabledMessage={
        canDeleteAssets
          ? 'Asset is currently used in a content item and cannot be deleted.'
          : 'You don’t have permission to delete assets in this collection.'
      }
      assetReplaceDisabledMessage={assetReplaceDisabledMessage}
      onDelete={onDelete}
      onDownload={trackUserEventOnDownload}
      onReplace={onReplace}
    />
  );
};

AssetActionMenu.displayName = 'AssetActionMenu';
