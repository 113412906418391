import React from 'react';
import { Warning } from '../../../../../../_shared/components/infos/Warning.tsx';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { getSelectedLanguageId } from '../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { ListingMessage } from '../../../../../../_shared/uiComponents/ListingMessage/ListingMessage.tsx';
import { DataUiElement } from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { compose } from '../../../../../../_shared/utils/func/compose.ts';
import { pluralizeWithCount } from '../../../../../../_shared/utils/stringUtils.ts';
import { IStatusInfoMessage } from '../../../../assets/models/IStatusInfoMessage.type.ts';
import { FailedItemsModal } from '../../../components/FailedItemsModal/FailedItemsModal.tsx';
import { StatusMessageStyle } from '../../../reducers/listingUi/statusInfo/selectors/statusMessageStyle.ts';
import { IStatusInfoState } from '../../../stores/IContentAppStoreState.ts';

const getStatusInfoMessage = ({
  failedIds,
  successfulIds,
  unmodifiedIds,
}: IStatusInfoState): IStatusInfoMessage => {
  const alItemsCount = failedIds.count() + successfulIds.count() + unmodifiedIds.count();
  const failedItemsCount = failedIds.count();

  return {
    style: StatusMessageStyle.Normal,
    text: `${failedItemsCount} of ${pluralizeWithCount('content item', alItemsCount)} failed`,
  };
};

const getLastBulkOperationState = (state: IStore) => state.contentApp.listingUi.statusInfo;

type Props = {
  readonly isOpen: boolean;
  readonly onClose: () => void;
};

export const BulkMoveItemsToCollectionFailedItemsDialog: React.FC<Props> = ({
  isOpen,
  onClose,
}) => {
  const failedItemIds = useSelector((s) => {
    const selectedLanguageId = getSelectedLanguageId(s);
    return selectedLanguageId
      ? getLastBulkOperationState(s)
          .failedIds.toArray()
          .map((itemId) => ({ itemId, variantId: selectedLanguageId }))
      : [];
  });

  const statusInfoMessage = useSelector(compose(getStatusInfoMessage, getLastBulkOperationState));

  return (
    <FailedItemsModal
      dataUiElement={DataUiElement.MoveItemsFailedDialog}
      failedItemIds={failedItemIds}
      isOpen={isOpen}
      modalTitle="Some items failed to change collection"
      onClose={onClose}
      renderScrollTableTitle={() => <ListingMessage statusInfoMessage={statusInfoMessage} />}
      renderWarning={() => (
        <Warning hideTitle>
          You don’t have permissions to move these items. To learn the details, open each item and
          try to move it.
        </Warning>
      )}
      showCollectionColumn
    />
  );
};

BulkMoveItemsToCollectionFailedItemsDialog.displayName =
  'BulkMoveItemsToCollectionFailedItemsDialog';
