import { useCallback } from 'react';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { selectIntercomUtils } from '../../../../_shared/selectors/selectIntercomUtils.ts';
import {
  getCurrentProjectId,
  getProjectInfo,
} from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';

export const useEnableFeatureIntercomMessage = (featureDisplayName: string): (() => void) => {
  const environmentId = useSelector(getCurrentProjectId);
  const environmentInfo = useSelector((state) => getProjectInfo(state, environmentId));
  const intercomUtils = useSelector(selectIntercomUtils);

  return useCallback(() => {
    intercomUtils.showIntercom();
    intercomUtils.showMessage(
      `Dear Kontent.ai support, I would like to enable ${featureDisplayName} on` +
        ` project environment "${environmentInfo.projectName} – ${environmentInfo.environmentName}"` +
        ` (ID ${environmentInfo.projectId}).`,
    );
  }, [environmentInfo, featureDisplayName, intercomUtils]);
};
