import { noOperation } from '@kontent-ai/utils';
import { TippyProps } from '@tippyjs/react';
import classNames from 'classnames';
import React, {
  ChangeEventHandler,
  FocusEventHandler,
  RefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { DateTime, createDateTime } from '../../models/DateTime.ts';
import {
  LocalTimeZoneId,
  UtcTimeZoneId,
  ensureBrowserCompatibleTimeZone,
  fromUtcToZonedTime,
  fromZonedTimeToUtc,
} from '../../utils/dateTime/timeZoneUtils.ts';
import { isEmptyOrWhitespace } from '../../utils/stringUtils.ts';
import { HotkeysHandler } from '../Hotkeys/HotkeysHandler.tsx';
import {
  DatetimePickerInput,
  IDatetimePickerInputHandles,
} from './InternalFiles/DatetimePickerInput.tsx';
import { ModalPicker } from './InternalFiles/ModalPicker.tsx';
import {
  formatDateTimeToReadable,
  isDatetimeWithinBounds,
  parseDatetime,
} from './InternalFiles/datetimeUtils.ts';

export type IDateTimePickerTippyOptions = Pick<
  TippyProps,
  'placement' | 'popperOptions' | 'appendTo'
>;

type DatetimePickerProps = {
  readonly autoFocus?: boolean;
  readonly className?: string;
  readonly datePickerId?: string;
  readonly defaultDate?: string;
  readonly defaultTime?: string;
  readonly disabled?: boolean;
  readonly errorMessage?: string | null;
  readonly hasError?: boolean;
  readonly hideValidationError?: boolean;
  readonly hideUtcLabel?: boolean;
  readonly isFullWidth?: boolean;
  readonly maxValue?: Date;
  readonly minValue?: Date;
  readonly onChange: (dateTime: DateTime) => void;
  readonly onOpenCalendar?: () => void;
  readonly placeholder?: string;
  readonly popperClassName?: string;
  readonly showCalendarNextToInput?: boolean;
  readonly showDataBalloon?: boolean;
  readonly timeZoneId?: string;
  readonly tippyOptions?: IDateTimePickerTippyOptions;
  readonly title?: string;
  readonly ariaLabel?: string;
  readonly value: string | null;
  readonly inline?: boolean;
};

export type DatetimePickerRefType = {
  readonly focusInputAtTheEnd: () => void;
  readonly focusInputAtTheStart: () => void;
};

export const DatetimePicker = React.forwardRef<DatetimePickerRefType, DatetimePickerProps>(
  (
    {
      autoFocus,
      className,
      datePickerId,
      defaultDate,
      defaultTime,
      disabled,
      errorMessage,
      hasError,
      hideValidationError,
      hideUtcLabel,
      isFullWidth,
      inline,
      placeholder,
      popperClassName,
      showDataBalloon,
      timeZoneId,
      tippyOptions,
      title,
      minValue,
      maxValue,
      ariaLabel,
      value,
      onChange,
      onOpenCalendar,
    },
    ref,
  ) => {
    const [isCalendarVisible, setIsCalendarVisible] = useState<boolean>(false);
    const [datetimeInputValue, setDatetimeInputValue] = useState<string>('');
    const datetimePickerInputRef = useRef<IDatetimePickerInputHandles>(null);
    const datetimePickerInputWrapperRef = useRef<HTMLDivElement>(null);
    const datetimeInputIsBeingEditedRef = useRef<boolean>(false);
    const dateTimeModalPickerRef = useRef<HTMLDivElement>(null);

    const { value: parsedValue, isValid: isParsedValueValid } = parseDatetime(value);
    const { isValid: isDateTimeInputValueValid } = parseDatetime(datetimeInputValue);

    const ensuredBrowserCompatibleTimeZone = useMemo(
      () => (timeZoneId ? ensureBrowserCompatibleTimeZone(timeZoneId) : LocalTimeZoneId),
      [timeZoneId],
    );

    const zonedTime =
      isParsedValueValid && parsedValue
        ? fromUtcToZonedTime(parsedValue, ensuredBrowserCompatibleTimeZone)
        : null;
    const zonedTimeFormatted = zonedTime ? formatDateTimeToReadable(zonedTime) : '';
    const isValueWithinBounds = isDatetimeWithinBounds(parsedValue, minValue, maxValue);
    const isDateTimeInputValid =
      isEmptyOrWhitespace(datetimeInputValue) || (isValueWithinBounds && isDateTimeInputValueValid);

    const zonedMinValue = minValue
      ? fromUtcToZonedTime(minValue, ensuredBrowserCompatibleTimeZone)
      : undefined;
    const zonedMaxValue = maxValue
      ? fromUtcToZonedTime(maxValue, ensuredBrowserCompatibleTimeZone)
      : undefined;

    useEffect(() => {
      if (!datetimeInputIsBeingEditedRef.current) {
        setDatetimeInputValue(zonedTimeFormatted);
      }
    }, [zonedTimeFormatted]);

    const propagateChange = useCallback(
      (datetime: Date | null, isValid: boolean) => {
        if (datetime) {
          const utcDateTime = fromZonedTimeToUtc(datetime, ensuredBrowserCompatibleTimeZone);
          const newValue = utcDateTime.toISOString();
          onChange({
            value: newValue,
            isValid,
          });
        } else {
          onChange({
            value: '',
            isValid: true,
          });
        }
      },
      [onChange, ensuredBrowserCompatibleTimeZone],
    );

    const focusInputAtTheStart = useCallback(() => {
      (
        datetimePickerInputRef as any as RefObject<IDatetimePickerInputHandles | null>
      ).current?.triggerFocusAtTheStart();
    }, []);

    const focusInputAtTheEnd = useCallback(() => {
      (
        datetimePickerInputRef as any as RefObject<IDatetimePickerInputHandles | null>
      ).current?.triggerFocusAtTheEnd();
    }, []);

    useImperativeHandle(ref, () => ({
      focusInputAtTheStart,
      focusInputAtTheEnd,
    }));

    const onDatetimeInputChange: ChangeEventHandler<HTMLInputElement> = useCallback(
      ({ target: { value: newValue } }) => {
        datetimeInputIsBeingEditedRef.current = true;
        setDatetimeInputValue(newValue);

        if (isEmptyOrWhitespace(newValue)) {
          propagateChange(null, true);
        } else {
          const newParsedValue = parseDatetime(newValue);
          if (newParsedValue.isValid) {
            propagateChange(newParsedValue.value, newParsedValue.isValid);
          } else {
            onChange(
              createDateTime({
                value: newValue,
                isValid: false,
              }),
            );
          }
        }
      },
      [onChange, propagateChange],
    );

    const openCalendar = useCallback((): void => {
      setIsCalendarVisible(true);
      datetimeInputIsBeingEditedRef.current = false;
      onOpenCalendar?.();
    }, [onOpenCalendar]);

    const closeCalendar = useCallback((): void => {
      setIsCalendarVisible(false);
      datetimeInputIsBeingEditedRef.current = false;
    }, []);

    const handleEnterPress = useCallback(
      (event: Event): void => {
        closeCalendar();
        event.stopPropagation();
        event.preventDefault();
      },
      [closeCalendar],
    );

    const handleEscapePress = useCallback(
      (event: Event): void => {
        closeCalendar();
        event.stopPropagation();
      },
      [closeCalendar],
    );

    const handlePickerChange = useCallback(
      (newValue: Date): void => {
        propagateChange(newValue, true);
      },
      [propagateChange],
    );

    const handleBlur = useCallback<FocusEventHandler>(
      (event) => {
        datetimeInputIsBeingEditedRef.current = false;
        if (!dateTimeModalPickerRef.current?.contains(event.relatedTarget)) {
          closeCalendar();
        }
      },
      [closeCalendar],
    );

    return (
      <HotkeysHandler
        className={classNames('datetime-picker', {
          'datetime-picker--inline': inline,
        })}
        handlers={{
          onEnter: isCalendarVisible ? handleEnterPress : undefined,
          onEscape: isCalendarVisible ? handleEscapePress : undefined,
        }}
      >
        <DatetimePickerInput
          autoFocus={!!autoFocus}
          className={className}
          currentStoredValue={zonedTime?.toISOString() ?? ''}
          disabled={disabled}
          errorMessage={errorMessage}
          isCalendarButtonActive={isCalendarVisible}
          isFullWidth={isFullWidth || inline}
          isValid={!!hideValidationError || (isDateTimeInputValid && !hasError)}
          onBlur={handleBlur}
          onChange={onDatetimeInputChange}
          onClick={openCalendar}
          onFocus={noOperation}
          placeholder={placeholder}
          ref={datetimePickerInputRef}
          showDataBalloon={showDataBalloon}
          title={title}
          utc={!hideUtcLabel && timeZoneId === UtcTimeZoneId}
          value={datetimeInputValue || ''}
          wrapperRef={datetimePickerInputWrapperRef}
          suffixes={inline ? [] : undefined}
          ariaLabel={ariaLabel}
        />
        <ModalPicker
          className={className}
          datePickerId={datePickerId}
          defaultDate={defaultDate}
          defaultTime={defaultTime}
          inputWrapperRef={datetimePickerInputWrapperRef}
          isVisible={!disabled && isCalendarVisible}
          maxValue={zonedMaxValue}
          minValue={zonedMinValue}
          onChange={handlePickerChange}
          onClose={closeCalendar}
          popperClassName={popperClassName}
          ref={dateTimeModalPickerRef}
          tippyOptions={tippyOptions}
          value={zonedTime}
          inline={inline}
        />
      </HotkeysHandler>
    );
  },
);

DatetimePicker.displayName = 'DatetimePicker';
