import { useForm } from 'react-hook-form';
import { ModalDialog } from '../../../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import { isEmptyOrWhitespace } from '../../../../../_shared/utils/stringUtils.ts';
import { createFormValidationResolver } from '../../../../../_shared/utils/validation/createFormValidationResolver.ts';
import { AiGuidelines as AiGuidelinesModel } from '../../../data/models/aiGuidelines.ts';
import { AllFieldsAreRequiredMessage, DialogWidth } from '../constants/uiConstants.ts';
import { AiGuidelinesFormShape } from '../types/AiGuidelinesFormShape.type.ts';
import { aiGuidelinesValidationConfig } from '../validation/aiGuidelinesValidation.ts';
import { AiGuidelinesForm } from './AiGuidelinesForm.tsx';

type CreateAiGuidelinesModalProps = {
  readonly aiGuidelines: ReadonlyArray<AiGuidelinesModel>;
  readonly onClose: () => void;
  readonly onCreate: (aiGuidelines: AiGuidelinesFormShape) => void;
};

export const CreateAiGuidelinesModal = ({
  aiGuidelines,
  onClose,
  onCreate,
}: CreateAiGuidelinesModalProps) => {
  const formProps = useForm<AiGuidelinesFormShape>({
    defaultValues: {
      name: '',
      guidelines: '',
    },
    resolver: createFormValidationResolver(aiGuidelinesValidationConfig, {
      existingAiGuidelines: aiGuidelines,
    }),
  });

  const { handleSubmit, watch } = formProps;
  const isAnyFieldEmpty =
    isEmptyOrWhitespace(watch('guidelines')) || isEmptyOrWhitespace(watch('name'));

  const submitForm = handleSubmit((guidelines) => {
    onCreate(guidelines);
  });

  return (
    <ModalDialog
      headline="Create new review guidelines"
      isOpen
      isDismissable
      primaryAction={{
        text: 'Create',
        onClick: submitForm,
        disabled: isAnyFieldEmpty,
        tooltipText: isAnyFieldEmpty ? AllFieldsAreRequiredMessage : null,
      }}
      cancelAction={{
        onClick: onClose,
      }}
      maxWidth={DialogWidth}
      minWidth={DialogWidth}
      onClose={onClose}
    >
      <AiGuidelinesForm formProps={formProps} />
    </ModalDialog>
  );
};
