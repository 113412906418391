import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Input } from '@kontent-ai/component-library/Input';
import { noOperation } from '@kontent-ai/utils';
import { defaultDropdownTippyOptions } from '../../../../../../component-library/components/DropDownMenu/DropDownMenuPositioner.tsx';
import { StatefulDropDown } from '../../../../../_shared/uiComponents/DropDown/StatefulDropDown.tsx';
import { IDropdownTippyOptions } from '../../../../../_shared/uiComponents/DropDown/dropDownTippyOptions.ts';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { AiGuidelines } from '../../../data/models/aiGuidelines.ts';

const tippyOptions: IDropdownTippyOptions = {
  ...defaultDropdownTippyOptions,
  placement: 'bottom-start',
};

type AiGuidelinesSelectProps = {
  readonly selectedAiGuidelines: AiGuidelines | null;
  readonly renderAiGuidelinesMenu: (onHideMenu: () => void) => JSX.Element;
};

export const AiGuidelinesSelect = ({
  selectedAiGuidelines,
  renderAiGuidelinesMenu,
}: AiGuidelinesSelectProps) => (
  <StatefulDropDown
    tippyOptions={tippyOptions}
    optionListDataUiAttributes={getDataUiCollectionAttribute(DataUiCollection.AiGuidelines)}
    renderContent={renderAiGuidelinesMenu}
    renderSelectedOption={(ref, toggleAiGuidelinesMenu, isMenuVisible) => (
      <Input
        aria-label="Select guidelines to use for reviewing this element’s content"
        inputFieldRef={ref}
        onChange={noOperation}
        onClick={toggleAiGuidelinesMenu}
        onKeyDown={(event) => {
          if (event.key === ' ' || event.key === 'Enter') {
            toggleAiGuidelinesMenu();
          }
        }}
        placeholder="Select guidelines to use for reviewing this element’s content"
        value={selectedAiGuidelines?.name ?? ''}
        suffixes={[
          <QuinaryButton key="chevron-button" onClick={toggleAiGuidelinesMenu} tooltipText="">
            <QuinaryButton.Chevron direction={isMenuVisible ? 'up' : 'down'} />
          </QuinaryButton>,
        ]}
      />
    )}
  />
);
