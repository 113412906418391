import { Box } from '@kontent-ai/component-library/Box';
import { Stack } from '@kontent-ai/component-library/Stack';
import {
  Spacing,
  spacingMainLayoutLeft,
  spacingMainLayoutTop,
} from '@kontent-ai/component-library/tokens';
import { useObserveElementPresence } from '@kontent-ai/hooks';
import { Collection } from '@kontent-ai/utils';
import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useContext,
  PropsWithChildren,
} from 'react';
import { createPortal } from 'react-dom';
import { FullScreenModalDialog } from '../../../../../component-library/components/Dialogs/ModalDialog/FullScreenModalDialog.tsx';
import { trackUserEvent } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import { HtmlPageTitle } from '../../../../_shared/components/HtmlPageTitle.tsx';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { AppNames } from '../../../../_shared/constants/applicationNames.ts';
import {
  FindRightContentTrackedEvent,
  TrackedEvent,
} from '../../../../_shared/constants/trackedEvent.ts';
import { DefaultVariantId } from '../../../../_shared/constants/variantIdValues.ts';
import { useDataSelector } from '../../../../_shared/hooks/useDataSelector.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import { ContentItemId } from '../../../../_shared/models/ContentItemId.ts';
import { ContentItemFilterOrigin } from '../../../../_shared/models/events/ContentItemFilterEventData.type.ts';
import { stringifyContentItemId } from '../../../../_shared/models/utils/contentItemIdUtils.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getItemListForScrollTable } from '../../../../_shared/utils/getItemListForScrollTable.ts';
import { isItemsInitialLoadFinished } from '../../../../_shared/utils/isItemsInitialLoadFinished.ts';
import { IListingContentItem } from '../../../../data/models/listingContentItems/IListingContentItem.ts';
import { clearContentItemListingFilter } from '../../../contentInventory/content/features/ContentItemInventory/actions/thunkContentItemInventoryActions.ts';
import { ContentItemScrollTable } from '../../../contentInventory/content/features/ContentItemInventory/containers/ItemInventoryScrollTable/ContentItemScrollTable.tsx';
import { getFindRightContentFilterEventData } from '../../../contentInventory/content/features/ContentItemInventory/utils/getFindRightContentTrackingPayloads.ts';
import {
  contentItemFilterLeft,
  contentItemFilterSetUp,
} from '../../../contentInventory/content/features/ListingFilter/actions/listingFilterActions.ts';
import { EnsureInventoryRelatedData } from '../../../contentInventory/content/features/ListingFilter/containers/EnsureInventoryRelatedData.tsx';
import { useFilterChanged } from '../../../contentInventory/content/features/ListingFilter/hooks/useFilterChanged.ts';
import { useSetUpContentItemFilter } from '../../../contentInventory/content/features/ListingFilter/hooks/useSetUpContentItemFilter.ts';
import { IListingFilter } from '../../../contentInventory/content/models/filter/IListingFilter.ts';
import { getNumberOfItemsInViewport } from '../../../contentInventory/content/reducers/listingUi/selectors/getNumberOfItemsInViewport.ts';
import { useIsRefinedNavigationFeatureEnabled } from '../../../refinedNavigation/contexts/RefinedNavigationContext.tsx';
import {
  filterContentItems,
  initializeContentStatus,
  loadListingContentItemsForContentStatus,
} from '../actions/thunkContentStatusActions.ts';
import { ContentStatusCommentsTable } from '../containers/ContentStatusCommentsTable.tsx';
import { ContentStatusFilter } from '../containers/ContentStatusFilter.tsx';
import { ContentStatusScrollTableEmptyState } from '../containers/ContentStatusScrollTable/ContentStatusScrollTableEmptyState.tsx';
import { ContentStatusScrollTableHead } from '../containers/ContentStatusScrollTable/ContentStatusScrollTableHead.tsx';
import { ContentStatusScrollTableRow } from '../containers/ContentStatusScrollTable/ContentStatusScrollTableRow.tsx';
import { ContentStatusTasksTable } from '../containers/ContentStatusTasksTable.tsx';
import { FilterArea } from '../containers/FilterArea.tsx';
import {
  LanguageFilterContext,
  LanguageFilterContextProvider,
} from '../contexts/LanguageFilter.tsx';
import { LanguagesFilterSelector } from './ContentStatusFilter/LanguagesFilterSelector.tsx';
import { languagesFilterInContentStatusWrapperId } from './LanguagesFilterSelectorWrapper.tsx';

type DetailType = 'tasks' | 'comments';

type OpenDetail = Readonly<{
  type: DetailType;
  contentItemId: ContentItemId;
  contentItemName: string;
}>;

const getItemListSelector = (state: IStore) =>
  getItemListForScrollTable(
    state.data.contentStatusContentItems,
    getNumberOfItemsInViewport(state.contentApp.listingUi.contentItemListingScrollTableState),
  );

export const ContentStatusTabContent: React.FC = () => {
  useThunkPromise(initializeContentStatus);

  const dispatch = useDispatch();
  const [openDetail, setOpenDetail] = useState<OpenDetail | null>(null);
  const contentPaneRef = useRef<HTMLDivElement>(null);
  const items = useSelector(getItemListSelector);
  const isInitialized = useSelector((s) =>
    isItemsInitialLoadFinished(s.data.contentStatusContentItems),
  );

  const openDetailModal = (type: DetailType, item: IListingContentItem) => {
    if (!item.variant?.id) {
      return;
    }

    setOpenDetail({
      type,
      contentItemId: item.variant?.id,
      contentItemName: item.item.name,
    });
  };

  const closeDialog = () => {
    setOpenDetail(null);
  };

  useEffect(() => {
    dispatch(trackUserEvent(TrackedEvent.ContentStatusOpened));
  }, []);

  const isRefinedNavigationFeatureEnabled = useIsRefinedNavigationFeatureEnabled();

  const { current: languageFilterWrapper } = useObserveElementPresence(
    languagesFilterInContentStatusWrapperId,
  );

  const clearFilter = () => dispatch(clearContentItemListingFilter());
  return (
    <>
      <Box className="canvas" paddingLeft={spacingMainLayoutLeft}>
        <div className="canvas__workspace">
          <EnsureInventoryRelatedData>
            <LanguageFilterContextProvider>
              {!isRefinedNavigationFeatureEnabled && (
                <SetupContentStatusFilter>
                  <ContentStatusFilter clearFilter={clearFilter} />
                </SetupContentStatusFilter>
              )}
              <div className="canvas__content">
                <Box
                  ref={contentPaneRef}
                  className="canvas__content-pane canvas__content-pane--no-bottom-offset canvas__content-pane--without-vertical-scrollbar"
                  padding={0}
                  paddingTop={spacingMainLayoutTop}
                  {...getDataUiAppNameAttribute(DataUiAppName.ContentStatus)}
                >
                  <HtmlPageTitle appName={AppNames.ContentStatus} />
                  <Stack spacing={Spacing.L}>
                    {isRefinedNavigationFeatureEnabled && (
                      <>
                        {languageFilterWrapper &&
                          createPortal(<LanguagesFilterSelector />, languageFilterWrapper)}
                        <FilterArea clearFilter={clearFilter} />
                      </>
                    )}
                    {isInitialized ? (
                      <ContentItemScrollTable
                        ariaLabel="Content status"
                        items={items}
                        onLoadContentItems={loadListingContentItemsForContentStatus({
                          scrollPositionChanged: true,
                        })}
                        parentContainerRef={contentPaneRef}
                        renderEmptyState={() => (
                          <ContentStatusScrollTableEmptyState isInDialog={false} />
                        )}
                        renderRowItem={(params) => {
                          const contentItemId: ContentItemId = params.item.variant?.id ?? {
                            itemId: params.item.item.id,
                            variantId: DefaultVariantId,
                          };
                          return (
                            <ContentStatusScrollTableRow
                              key={stringifyContentItemId(contentItemId)}
                              index={params.index}
                              item={params.item}
                              onTaskButtonClick={() => openDetailModal('tasks', params.item)}
                              onCommentButtonClick={() => openDetailModal('comments', params.item)}
                            />
                          );
                        }}
                        renderTableHead={() => <ContentStatusScrollTableHead />}
                        renderTableTitle={() => null}
                        withColumnSettings
                      />
                    ) : (
                      <Loader />
                    )}
                  </Stack>
                </Box>
              </div>
            </LanguageFilterContextProvider>
          </EnsureInventoryRelatedData>
        </div>
      </Box>
      <FullScreenModalDialog
        headline={`${openDetail?.type === 'tasks' ? 'Tasks' : 'Comments'} in ${openDetail?.contentItemName}`}
        isDismissable
        isOpen={!!openDetail}
        onClose={closeDialog}
      >
        {openDetail?.type === 'tasks' && (
          <ContentStatusTasksTable
            itemId={openDetail.contentItemId.itemId}
            variantId={openDetail.contentItemId.variantId}
          />
        )}
        {openDetail?.type === 'comments' && (
          <ContentStatusCommentsTable
            itemId={openDetail.contentItemId.itemId}
            variantId={openDetail.contentItemId.variantId}
          />
        )}
      </FullScreenModalDialog>
    </>
  );
};

const SetupContentStatusFilter = ({ children }: PropsWithChildren) => {
  const dispatch = useDispatch();
  const listingItemsLoadingStatus = useSelector(
    (s) => s.data.contentStatusContentItems.loadingStatus,
  );
  const workflows = useDataSelector((data) => data.workflows.byId);
  const filterSetUp = useCallback(
    (listingFilter: IListingFilter) => {
      dispatch(contentItemFilterSetUp(listingFilter, ContentItemFilterOrigin.ContentStatus));
      dispatch(
        trackUserEvent(TrackedEvent.FindRightContent, {
          name: FindRightContentTrackedEvent.OpenedContentItemInventoryPage,
          ...getFindRightContentFilterEventData(
            listingFilter,
            ContentItemFilterOrigin.ContentStatus,
            Collection.getValues(workflows),
          ),
        }),
      );
    },
    [workflows],
  );
  const filterLeft = useCallback(
    () => dispatch(contentItemFilterLeft(ContentItemFilterOrigin.ContentStatus)),
    [],
  );

  useSetUpContentItemFilter(
    ContentItemFilterOrigin.ContentStatus,
    filterSetUp,
    filterLeft,
    undefined,
    undefined,
  );

  const { selectedLanguageIds } = useContext(LanguageFilterContext);
  const onFilterChange = useCallback(
    () => dispatch(filterContentItems(selectedLanguageIds)),
    [selectedLanguageIds],
  );
  const filter = useSelector((s) => s.contentApp.listingUi.filter);
  useFilterChanged(onFilterChange, filter, listingItemsLoadingStatus);

  return children;
};
