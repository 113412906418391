import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../@types/Dispatcher.type.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { hideNotificationBar } from '../actions/sitemapActions.ts';
import { undoDeleteNode } from '../actions/thunkSitemapActions.ts';
import {
  ISitemapNotificationBarDispatchProps,
  ISitemapNotificationBarStateProps,
  SitemapNotificationBar as NotificationBar,
} from '../components/SitemapNotificationBar.tsx';

function mapStateToProps(state: IStore): ISitemapNotificationBarStateProps {
  const {
    sharedApp: {
      notificationBar: { text, type },
    },
    sitemapApp,
  } = state;

  return {
    message: text,
    showUndo: !!sitemapApp && !!sitemapApp.originalSitemap,
    type,
  };
}

function mapDispatchToProps(dispatch: Dispatch): ISitemapNotificationBarDispatchProps {
  return {
    onButtonClick: () => dispatch(undoDeleteNode()),
    onClosePanel: () => dispatch(hideNotificationBar()),
  };
}

export const SitemapNotificationBar: React.ComponentType = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationBar);
