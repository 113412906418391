import { AppNames } from '../../../../_shared/constants/applicationNames.ts';
import {
  ContentItemsAppRouteSegment,
  ContentItemsRoute,
  ContentItemsRouteParams,
  RelationsEntryRoute,
  RelationsEntryRouteParams,
} from '../../../../_shared/constants/routePaths.ts';
import {
  DataUiAppName,
  ObjectWithDataAttribute,
  getDataUiObjectNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  Capability,
  ICapability,
  getUserCapability,
} from '../../../../_shared/utils/permissions/capability.ts';
import { checkGivenRoutesForPathNameMatch } from '../../../../_shared/utils/routing/navigationRouteUtils.ts';
import { buildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { IUserProjectInfo } from '../../../../data/models/user/UserProjectInfo.ts';
import { buildAssetsRoutePath } from '../../assets/utils/assetsPathUtils.ts';
import {
  assetsRequiredCapabilities,
  contentRequiredCapabilities,
  relationsRequiredCapabilities,
} from './contentInventoryRequiredCapabilities.ts';

type ContentInventoryTab = {
  readonly dataUiAttributes: ObjectWithDataAttribute;
  readonly requiredCapabilities: ReadonlyArray<Capability>;
  readonly resolveLinkRoute: (params: ContentInventoryTabParams) => string;
  readonly tabText: AppNames;
};

type ContentInventoryTabParams = {
  readonly projectId: Uuid;
  readonly variantId: Uuid;
};

const contentListingTab: ContentInventoryTab = {
  dataUiAttributes: getDataUiObjectNameAttribute(DataUiAppName.ContentItemListing),
  requiredCapabilities: contentRequiredCapabilities,
  resolveLinkRoute: ({ variantId, projectId }) =>
    buildPath<ContentItemsRouteParams>(ContentItemsRoute, {
      app: ContentItemsAppRouteSegment.Content,
      projectId,
      variantId,
      spaceId: undefined,
    }),
  tabText: AppNames.ContentItems,
};

const relationsTab: ContentInventoryTab = {
  dataUiAttributes: getDataUiObjectNameAttribute(DataUiAppName.Relations),
  requiredCapabilities: relationsRequiredCapabilities,
  resolveLinkRoute: ({ projectId, variantId }) =>
    buildPath<RelationsEntryRouteParams>(RelationsEntryRoute, {
      projectId,
      variantId,
    }),
  tabText: AppNames.Relations,
};

const assetListingTab: ContentInventoryTab = {
  dataUiAttributes: getDataUiObjectNameAttribute(DataUiAppName.AssetListing),
  requiredCapabilities: assetsRequiredCapabilities,
  resolveLinkRoute: ({ projectId }) => buildAssetsRoutePath({ projectId }),
  tabText: AppNames.Assets,
};

const contentInventoryTabs = [contentListingTab, relationsTab, assetListingTab];

export type ContentInventoryTabsStructure = ReadonlyArray<{
  readonly id: string;
  readonly label: string;
  readonly path: string;
  readonly dataUiAttributes: ObjectWithDataAttribute;
  readonly isActive?: boolean;
}>;

export const getContentInventoryTabsStructure = (
  currentProject: IUserProjectInfo,
  selectedLanguageId: Uuid,
  pathname: string = '',
): ContentInventoryTabsStructure => {
  const capabilities = getUserCapability(currentProject);
  return contentInventoryTabs
    .filter((tab) => capabilities.canOneOf(tab.requiredCapabilities))
    .map((tab) => {
      const path = tab.resolveLinkRoute({
        projectId: currentProject.projectId,
        variantId: selectedLanguageId,
      });
      return {
        id: path,
        label: tab.tabText,
        path,
        dataUiAttributes: tab.dataUiAttributes,
        isActive: checkGivenRoutesForPathNameMatch(pathname, [path]),
      };
    });
};

export const getContentInventoryIndexTabRoute = (
  capability: ICapability,
  projectId: Uuid,
  variantId: Uuid,
): string | null => {
  const firstTab = contentInventoryTabs.find((tab) =>
    capability.canOneOf(tab.requiredCapabilities),
  );

  return (
    firstTab?.resolveLinkRoute({
      projectId,
      variantId,
    }) ?? null
  );
};
