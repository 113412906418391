import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { getSelectedLanguageId } from '../../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { getCurrentProject } from '../../../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { copyContentValuesFromDifferentExistingVariant } from '../../../actions/thunkContentItemEditingActions.ts';
import { EditingActionItemsProps } from '../../../components/editingActions/EditingActionSubmenuItems.tsx';
import { CopyFromLanguageOptions as CopyFromLanguageOptionsComponent } from '../../../components/editingActions/actionSubmenuOptions/CopyFromLanguageOptions.tsx';
import { getLanguageOptionsMemoized } from '../../selectors/copyContentFromVariantMenuOptions.ts';

export const CopyFromLanguageOptions: React.FC<EditingActionItemsProps> = ({ actionOrigin }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const menuOptions = useSelector((s) => {
    const selectedLanguageId = getSelectedLanguageId(s);
    const collectionId = s.contentApp.editedContentItem?.collectionId ?? null;

    return selectedLanguageId
      ? getLanguageOptionsMemoized(
          s.data.languages,
          selectedLanguageId,
          s.contentApp.contentItemVariants,
          s.data.user,
          getCurrentProject(s).projectId,
          collectionId,
        )
      : [];
  });

  return (
    <CopyFromLanguageOptionsComponent
      onSelectLanguage={(languageId) =>
        dispatch(copyContentValuesFromDifferentExistingVariant(history, languageId, actionOrigin))
      }
      menuOptions={menuOptions}
    />
  );
};

CopyFromLanguageOptionsComponent.displayName = 'CopyFromLanguageOptionsComponent';
