import { Box } from '@kontent-ai/component-library/Box';
import { Color, Typography, colorTextDefault } from '@kontent-ai/component-library/tokens';
import React from 'react';

type Props = {
  readonly children: React.ReactNode;
  readonly color?: Color;
  readonly title?: string;
};

export const SidebarSectionLinkWrapper = ({ color = colorTextDefault, children, title }: Props) => {
  const paragraphAttribute: Omit<JSX.IntrinsicElements['p'], 'color' | 'ref'> = {
    title,
  };

  return (
    <Box
      typography={Typography.BodyMedium}
      color={color}
      whiteSpace="nowrap"
      overflow="hidden"
      css="text-overflow: ellipsis"
      component="p"
      {...paragraphAttribute}
    >
      {children}
    </Box>
  );
};
