import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import React from 'react';
import { Tag } from '../../../../_shared/uiComponents/Tag/Tag.tsx';
import { TagColor } from '../../../../data/constants/tagColor.ts';
import {
  IMetricMetaData,
  getMetricFormattedValue,
  getMetricMetaData,
} from '../../shared/utils/subscriptionPlanUtils.ts';

export interface IMetricRow {
  readonly name: string;
  readonly usage: number;
  readonly included: number;
  readonly extra: number;
  readonly costs?: number;
}

interface ICurrentUsageTableDataProps {
  readonly columnNames: string[];
  readonly rows: ReadonlyArray<IMetricRow>;
  readonly isTrial?: boolean;
}

interface IMetricDescriptionLabel {
  readonly metric: IMetricMetaData;
}

const MetricWithDescriptionLabel: React.FC<IMetricDescriptionLabel> = ({ metric }) => (
  <>
    {metric.displayName}
    <Tooltip text={metric.description} placement="top">
      <Tag color={TagColor.Gray} customClass="current-usage__row-name-tag">
        {metric.type}
      </Tag>
    </Tooltip>
  </>
);

MetricWithDescriptionLabel.displayName = 'MetricWithDescriptionLabel';

interface ITableRow {
  readonly row: IMetricRow;
  readonly metric: IMetricMetaData | null;
  readonly isTrial?: boolean;
}

const TableRow: React.FC<ITableRow> = ({ row, metric, isTrial }) => {
  return isTrial ? (
    <tr key={row.name} className="current-usage__table-row">
      <td className="current-usage__row-name">
        {metric ? <MetricWithDescriptionLabel metric={metric} /> : row.name}
      </td>
      <td className="current-usage__row-value">{getMetricFormattedValue(row.name, row.usage)}</td>
    </tr>
  ) : (
    <tr key={row.name} className="current-usage__table-row">
      <td className="current-usage__row-name">
        {metric ? <MetricWithDescriptionLabel metric={metric} /> : row.name}
      </td>
      <td className="current-usage__row-value">
        {getMetricFormattedValue(row.name, row.included)}
      </td>
      <td className="current-usage__row-value">{getMetricFormattedValue(row.name, row.usage)}</td>
      <td className="current-usage__row-value">{getMetricFormattedValue(row.name, row.extra)}</td>
      <td className="current-usage__row-value">{row.costs !== undefined && `$${row.costs}`}</td>
      <td />
    </tr>
  );
};

TableRow.displayName = 'TableRow';

export const CurrentUsageTable: React.FC<ICurrentUsageTableDataProps> = ({
  columnNames,
  rows,
  isTrial,
}): JSX.Element => {
  return (
    <table className="current-usage__table">
      <thead className="current-usage__table-head">
        <tr className="current-usage__table-row">
          <th />
          {columnNames.map((col) => (
            <th key={col}>{col}</th>
          ))}
          {!isTrial && <th />}
        </tr>
      </thead>
      <tbody className="current-usage__table-body">
        {rows.map((row) => {
          if (!row) {
            return null;
          }
          return (
            <TableRow
              key={row.name}
              row={row}
              metric={getMetricMetaData(row.name)}
              isTrial={isTrial}
            />
          );
        })}
      </tbody>
    </table>
  );
};

CurrentUsageTable.displayName = 'CurrentUsageTable';
