import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { Collection } from '@kontent-ai/utils';
import React from 'react';
import {
  DataUiAction,
  DataUiCollection,
  getDataUiActionAttribute,
  getDataUiCollectionAttribute,
} from '../../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { logError } from '../../../../../../../../_shared/utils/logError.ts';
import {
  BaseTextFormattingFeature,
  FeatureNameMap,
  RichTextCommandFeature,
  TextBlockTypeFeature,
  TextFormattingFeature,
  isKnownFeatureNameMapKey,
} from '../../../../../../../richText/plugins/apiLimitations/api/editorLimitationUtils.ts';
import { BoldButton } from '../../../../../../../richText/plugins/inlineStyles/components/buttons/BoldButton.tsx';
import { CodeButton } from '../../../../../../../richText/plugins/inlineStyles/components/buttons/CodeButton.tsx';
import { ItalicButton } from '../../../../../../../richText/plugins/inlineStyles/components/buttons/ItalicButton.tsx';
import { SubscriptButton } from '../../../../../../../richText/plugins/inlineStyles/components/buttons/SubscriptButton.tsx';
import { SuperscriptButton } from '../../../../../../../richText/plugins/inlineStyles/components/buttons/SuperscriptButton.tsx';
import { LinkButton } from '../../../../../../../richText/plugins/links/components/buttons/LinkButton.tsx';
import { Heading1Button } from '../../../../../../../richText/plugins/textBlockTypes/components/buttons/Heading1Button.tsx';
import { Heading2Button } from '../../../../../../../richText/plugins/textBlockTypes/components/buttons/Heading2Button.tsx';
import { Heading3Button } from '../../../../../../../richText/plugins/textBlockTypes/components/buttons/Heading3Button.tsx';
import { Heading4Button } from '../../../../../../../richText/plugins/textBlockTypes/components/buttons/Heading4Button.tsx';
import { Heading5Button } from '../../../../../../../richText/plugins/textBlockTypes/components/buttons/Heading5Button.tsx';
import { Heading6Button } from '../../../../../../../richText/plugins/textBlockTypes/components/buttons/Heading6Button.tsx';
import { OrderedListButton } from '../../../../../../../richText/plugins/textBlockTypes/components/buttons/OrderedListButton.tsx';
import { ParagraphButton } from '../../../../../../../richText/plugins/textBlockTypes/components/buttons/ParagraphButton.tsx';
import { UnorderedListButton } from '../../../../../../../richText/plugins/textBlockTypes/components/buttons/UnorderedListButton.tsx';
import { EditorToolbarDivider } from '../../../../../../../richText/plugins/toolbars/components/buttons/EditorToolbarDivider.tsx';
import { ToggleButtonProps } from '../../../../../../../richText/plugins/toolbars/components/buttons/RTEToolbarButton.tsx';

const getTooltipText = (
  feature: RichTextCommandFeature,
  isAllowed: boolean,
  isTableFormatting?: boolean,
): string => {
  if (isKnownFeatureNameMapKey(feature)) {
    return `${isAllowed ? 'Disallow' : 'Allow'} ${FeatureNameMap[feature]} ${
      isTableFormatting ? 'in tables' : 'in text'
    }.`;
  }

  logError(`Unknown feature "${feature}".`);
  return '';
};

type FeatureButtonInputProps = {
  readonly disabled: boolean;
  readonly isTableFormatting?: boolean;
};

const getFeatureButtonProps = <T extends RichTextCommandFeature>(
  feature: T,
  allowedTextFeatures: ReadonlySet<T | TextFormattingFeature>,
  updateAllowedFeatures: (feature: T, isAllowed: boolean) => void,
  { disabled, isTableFormatting }: FeatureButtonInputProps,
  disabledTooltipText: string | undefined,
): ToggleButtonProps => {
  const isAllowed = allowedTextFeatures.has(feature);
  const tooltipText = disabled
    ? disabledTooltipText
    : getTooltipText(feature, isAllowed, isTableFormatting);

  return {
    disabled,
    hasHiddenShortcut: true,
    isActive: isAllowed,
    isFocusable: true,
    onClick: () => {
      updateAllowedFeatures(feature, !isAllowed);
    },
    tooltipText,
    ...getDataUiCollectionAttribute(DataUiCollection.LimitationFormatting),
  };
};

type InlineFormattingProps = {
  readonly allowedTextBlocks: ReadonlySet<TextBlockTypeFeature>;
  readonly allowedTextFormatting: ReadonlySet<TextFormattingFeature>;
  readonly disabled: boolean;
  readonly isTableFormatting?: boolean;
  readonly onDeselectAll: () => void;
  readonly onSelectAll: () => void;
  readonly onUpdateAllowedTextBlocks: (feature: TextBlockTypeFeature, isAllowed: boolean) => void;
  readonly onUpdateAllowedTextFormatting: (
    feature: BaseTextFormattingFeature,
    isAllowed: boolean,
  ) => void;
  readonly tooltipText?: string;
};

export const InlineFormatting: React.FC<InlineFormattingProps> = ({
  allowedTextBlocks,
  allowedTextFormatting,
  disabled,
  isTableFormatting,
  onDeselectAll,
  onSelectAll,
  onUpdateAllowedTextBlocks,
  onUpdateAllowedTextFormatting,
  tooltipText,
}) => {
  const featureButtonInputProps: FeatureButtonInputProps = {
    disabled,
    isTableFormatting,
  };

  const isSelectAllVisible =
    !allowedTextBlocks.size &&
    allowedTextFormatting.size === 1 &&
    Collection.getFirst(Collection.getValues(allowedTextFormatting)) ===
      TextFormattingFeature.Unstyled;

  const getTextBlockFeatureButtonProps = (feature: TextBlockTypeFeature) =>
    getFeatureButtonProps(
      feature,
      allowedTextBlocks,
      onUpdateAllowedTextBlocks,
      featureButtonInputProps,
      tooltipText,
    );
  const getTextFormattingFeatureButtonProps = (feature: BaseTextFormattingFeature) =>
    getFeatureButtonProps(
      feature,
      allowedTextFormatting,
      onUpdateAllowedTextFormatting,
      featureButtonInputProps,
      tooltipText,
    );

  const paragraphProps = getTextBlockFeatureButtonProps(TextBlockTypeFeature.Paragraph);
  const headingOneProps = getTextBlockFeatureButtonProps(TextBlockTypeFeature.HeadingOne);
  const headingTwoProps = getTextBlockFeatureButtonProps(TextBlockTypeFeature.HeadingTwo);
  const headingThreeProps = getTextBlockFeatureButtonProps(TextBlockTypeFeature.HeadingThree);
  const headingFourProps = getTextBlockFeatureButtonProps(TextBlockTypeFeature.HeadingFour);
  const headingFiveProps = getTextBlockFeatureButtonProps(TextBlockTypeFeature.HeadingFive);
  const headingSixProps = getTextBlockFeatureButtonProps(TextBlockTypeFeature.HeadingSix);
  const orderedListProps = getTextBlockFeatureButtonProps(TextBlockTypeFeature.OrderedList);
  const unorderedListProps = getTextBlockFeatureButtonProps(TextBlockTypeFeature.UnorderedList);

  const boldProps = getTextFormattingFeatureButtonProps(BaseTextFormattingFeature.Bold);
  const italicProps = getTextFormattingFeatureButtonProps(BaseTextFormattingFeature.Italic);
  const linkProps = getTextFormattingFeatureButtonProps(BaseTextFormattingFeature.Link);
  const subscriptProps = getTextFormattingFeatureButtonProps(BaseTextFormattingFeature.Subscript);
  const superscriptProps = getTextFormattingFeatureButtonProps(
    BaseTextFormattingFeature.Superscript,
  );
  const codeProps = getTextFormattingFeatureButtonProps(BaseTextFormattingFeature.Code);

  return (
    <div className="col-xs-24 content-element__config-section">
      <div className="content-element__formatting">
        <div className="content-element__formatting-header">
          <div className="content-element__formatting-header-title">Text formatting</div>
          {isSelectAllVisible ? (
            <QuinaryButton
              disabled={disabled}
              onClick={() => onSelectAll()}
              tooltipText=""
              {...getDataUiActionAttribute(DataUiAction.SelectAll)}
            >
              Select all
            </QuinaryButton>
          ) : (
            <QuinaryButton
              disabled={disabled}
              onClick={() => onDeselectAll()}
              tooltipText=""
              {...getDataUiActionAttribute(DataUiAction.DeselectAll)}
            >
              Deselect all
            </QuinaryButton>
          )}
        </div>

        <Inline spacing={Spacing.S}>
          <ParagraphButton {...paragraphProps} />
          <Heading1Button {...headingOneProps} />
          <Heading2Button {...headingTwoProps} />
          <Heading3Button {...headingThreeProps} />
          <Heading4Button {...headingFourProps} />
          <Heading5Button {...headingFiveProps} />
          <Heading6Button {...headingSixProps} />
          <EditorToolbarDivider />
          <OrderedListButton {...orderedListProps} />
          <UnorderedListButton {...unorderedListProps} />
          <EditorToolbarDivider />
          <BoldButton {...boldProps} />
          <ItalicButton {...italicProps} />
          <EditorToolbarDivider />
          <SubscriptButton {...subscriptProps} />
          <SuperscriptButton {...superscriptProps} />
          <CodeButton {...codeProps} />
          <LinkButton {...linkProps} />
        </Inline>
      </div>
    </div>
  );
};

InlineFormatting.displayName = 'InlineFormatting';
