import { getDefinedValueOrDefault } from '@kontent-ai/utils';
import { Action } from '../../../../@types/Action.type.ts';
import { Content_Editing_CurrentItemOverwritten } from '../../constants/editorActionTypes.ts';
import {
  ContentItemEditing_Init_FinishedNoVariant,
  ContentItemEditing_Init_Ready,
  ContentItemEditing_ItemName_Changed,
  ContentItemEditing_ItemSaving_Finished,
  ContentItemEditing_SitemapLocation_Toggled,
} from '../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import { ContentItemChangeReason } from '../../models/contentItem/ContentItemChangeReason.type.ts';
import {
  IEditedContentItem,
  mapEditedContentItemFromServerModel,
} from '../../models/contentItem/edited/EditedContentItem.ts';
import { shouldResetEditedItemState } from '../utils/shouldResetEditedItemState.ts';

const initialState: IEditedContentItem | null = null;

export function editedContentItem(state = initialState, action: Action): IEditedContentItem | null {
  if (shouldResetEditedItemState(action)) {
    return initialState;
  }

  switch (action.type) {
    case ContentItemEditing_Init_FinishedNoVariant:
    case ContentItemEditing_Init_Ready: {
      return action.payload.editedContentItem;
    }

    case ContentItemEditing_ItemName_Changed: {
      return (
        state && {
          ...state,
          name: action.payload.newName,
        }
      );
    }

    case ContentItemEditing_ItemSaving_Finished: {
      const savedItem = mapEditedContentItemFromServerModel(action.payload.itemServerModel);

      return (
        state && {
          ...state,
          codename: savedItem.codename,
          collectionId: savedItem.collectionId,
        }
      );
    }

    case ContentItemEditing_SitemapLocation_Toggled: {
      if (!state) {
        return state;
      }

      const toggledSitemapId = action.payload.selectedNodeId;

      if (state.sitemapLocation.some((id: Uuid) => id === toggledSitemapId)) {
        const updatedSitemap = state.sitemapLocation.delete(toggledSitemapId).toSet();
        return {
          ...state,
          sitemapLocation: updatedSitemap,
        };
      }
      const updatedSitemap = state.sitemapLocation.add(toggledSitemapId);
      return {
        ...state,
        sitemapLocation: updatedSitemap,
      };
    }

    case Content_Editing_CurrentItemOverwritten: {
      const changeReason = action.payload.params.changeReason;
      const difference = action.payload.params.difference;

      if (state && changeReason === ContentItemChangeReason.Update) {
        return {
          ...state,
          name: getDefinedValueOrDefault(difference.name, state.name),
          codename: getDefinedValueOrDefault(difference.codename, state.codename),
          sitemapLocation: getDefinedValueOrDefault(difference.sitemap, state.sitemapLocation),
        };
      }

      return state;
    }

    default: {
      return state;
    }
  }
}
