import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../../../@types/Dispatcher.type.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import {
  ITypeElementSummaryCallbackProps,
  ITypeElementSummaryDataProps,
  TypeElementSummary as TypeElementSummaryComponent,
} from '../../../components/typeElements/shared/TypeElementSummary.tsx';
import { GeneratesUrlSlugSummary } from '../../../constants/summaryMessageTemplates.ts';
import { IContentTypeSnippetTypeElementData } from '../../../models/elements/ContentTypeSnippetTypeElementData.ts';
import { IBaseTypeElementSummaryProps } from '../../../types/IBaseTypeElementSummaryProps.type.ts';
import { contentTypeSnippetElementGeneratesUrlSlug } from '../../../utils/urlSlugUtils.ts';

interface IContentTypeSnippetTypeElementSummaryProps
  extends IBaseTypeElementSummaryProps<IContentTypeSnippetTypeElementData> {}

export function getContentTypeSnippetTypeElementSummary(generatesUrlSlug: boolean): string {
  return generatesUrlSlug ? GeneratesUrlSlugSummary : '';
}

function mapStateToProps(
  state: IStore,
  ownProps: IContentTypeSnippetTypeElementSummaryProps,
): ITypeElementSummaryDataProps {
  const typeElement = ownProps.typeElementData;
  const contentElements = state.contentModelsApp.typeEditor.editedType.typeElements;
  const generatesUrlSlug = contentTypeSnippetElementGeneratesUrlSlug(
    contentElements,
    state.data.snippets.byId,
    typeElement.snippetId,
  );
  const summaryText = getContentTypeSnippetTypeElementSummary(generatesUrlSlug);

  return {
    isActive: ownProps.isActive,
    summaryText,
  };
}

function mapDispatchToProps(
  _dispatch: Dispatch,
  ownProps: IContentTypeSnippetTypeElementSummaryProps,
): ITypeElementSummaryCallbackProps {
  return ownProps;
}

export const ContentTypeSnippetTypeElementSummary: React.ComponentType<
  React.PropsWithChildren<IContentTypeSnippetTypeElementSummaryProps>
> = connect(mapStateToProps, mapDispatchToProps)(TypeElementSummaryComponent);
