import React from 'react';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { getCurrentProjectContainer } from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { getVisibleApiKeyTabs } from '../../apiKeys/selectors/getVisibleApiKeyTabs.ts';
import { ProjectSettings as ProjectSettingsComponent } from '../components/ProjectSettings.tsx';
import { EnvironmentsRouteSegment, GeneralRouteSegment } from '../constants/routeSegments.ts';
import { isProjectGeneralSettingsTabVisible } from '../selectors/projectSettingsMenuCustomVisibilities.ts';

export const ProjectSettings: React.FC = () => {
  const currentProject = useSelector(getCurrentProjectContainer);
  const areGeneralSettingsVisible = useSelector(isProjectGeneralSettingsTabVisible);
  const visibleApiKeyTabs = useSelector(getVisibleApiKeyTabs);
  const firstAccessibleRouteSegment = areGeneralSettingsVisible
    ? GeneralRouteSegment
    : EnvironmentsRouteSegment;

  return (
    <ProjectSettingsComponent
      areGeneralSettingsVisible={areGeneralSettingsVisible}
      firstAccessibleRouteSegment={firstAccessibleRouteSegment}
      project={currentProject}
      visibleApiKeyTabs={visibleApiKeyTabs}
    />
  );
};

ProjectSettings.displayName = 'ProjectSettings';
