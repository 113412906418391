import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { getCurrentProjectId } from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { archiveAssetFolder, openAssetFolder } from '../actions/thunkAssetLibraryActions.ts';
import { AssetsOrderBy } from '../types/orderBy.type.ts';
import { buildAssetListingRoutePath } from '../utils/assetsPathUtils.ts';

type Args = {
  readonly isInAssetSelector: boolean;
  readonly orderBy: AssetsOrderBy;
};

type ArchiveFolderArgs = {
  readonly folderId: Uuid;
  readonly parentFolderId: Uuid;
};

export const useArchiveAssetFolder = ({ isInAssetSelector, orderBy }: Args) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const projectId = useSelector(getCurrentProjectId);

  const goToParentFolder = useCallback(
    (parentFolderId: Uuid): void => {
      if (isInAssetSelector) {
        dispatch(openAssetFolder({ assetFolderId: parentFolderId, orderBy }));
      } else {
        const parentFolderPath = buildAssetListingRoutePath({
          projectId,
          assetFolderId: parentFolderId,
        });
        history.push(parentFolderPath);
      }
    },
    [history, isInAssetSelector, orderBy, projectId],
  );

  const archiveFolder = useCallback(
    async ({ folderId, parentFolderId }: ArchiveFolderArgs): Promise<void> => {
      const { archivedFoldersIds } = await dispatch(archiveAssetFolder({ folderId }));

      const wasCurrentFolderDeleted = archivedFoldersIds.includes(folderId);
      if (wasCurrentFolderDeleted) {
        goToParentFolder(parentFolderId);
      }
    },
    [goToParentFolder],
  );

  return archiveFolder;
};
