import { Typography } from '@kontent-ai/component-library/tokens';
import styled from 'styled-components';

export const StyledInfoBarTitle = styled.span<{ readonly maxLines: number }>`
  ${Typography.TitleLarge};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${({ maxLines }) => maxLines};
  -webkit-box-orient: vertical;
  word-break: break-word;
`;
