import { Collection } from '@kontent-ai/utils';
import { useCallback } from 'react';
import { modalDismissed } from '../../../../_shared/actions/sharedActions.ts';
import { trackUserEvent } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import {
  FindRightContentTrackedEvent,
  TrackedEvent,
} from '../../../../_shared/constants/trackedEvent.ts';
import { ModalContentItemSelectorLayout } from '../../../../_shared/features/ContentItemModalLayout/ModalContentItemSelectorLayout.tsx';
import { useDataSelector } from '../../../../_shared/hooks/useDataSelector.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { ContentItemFilterOrigin } from '../../../../_shared/models/events/ContentItemFilterEventData.type.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { capitalizeFirstLetter } from '../../../../_shared/utils/stringUtils.ts';
import { loadContentTypeAsComponentUsageCount } from '../../../../data/actions/thunkDataActions.ts';
import {
  clearContentItemListingFilter,
  filterItemsWithContentTypeAsComponentUsage,
} from '../../../contentInventory/content/features/ContentItemInventory/actions/thunkContentItemInventoryActions.ts';
import { filterSearchPhraseChanged } from '../../../contentInventory/content/features/ContentItemInventory/actions/thunks/updateFilterSearchPhrase.ts';
import { getFindRightContentFilterEventData } from '../../../contentInventory/content/features/ContentItemInventory/utils/getFindRightContentTrackingPayloads.ts';
import {
  contentItemFilterLeft,
  contentItemFilterSetUp,
} from '../../../contentInventory/content/features/ListingFilter/actions/listingFilterActions.ts';
import {
  ContentItemFilter,
  isContentItemFilterInitialized,
} from '../../../contentInventory/content/features/ListingFilter/containers/ContentItemFilter.tsx';
import { useFilterChanged } from '../../../contentInventory/content/features/ListingFilter/hooks/useFilterChanged.ts';
import { useSetUpContentItemFilter } from '../../../contentInventory/content/features/ListingFilter/hooks/useSetUpContentItemFilter.ts';
import { IListingFilter } from '../../../contentInventory/content/models/filter/IListingFilter.ts';
import { contentTypeAsComponentUsageModalDismissed } from '../actions/contentTypesActions.ts';
import { ContentTypeAsComponentUsageDialogProvider } from '../context/ContentTypeAsComponentUsageDialogContext.tsx';
import { getContentTypeIdInContentTypeAsComponentUsageDialog } from '../selectors/getContentTypeId.ts';
import { ContentTypeUsedAsComponentScrollTable } from './ContentTypeUsedAsComponentScrollTable.tsx';

const getContentTypeName = (state: IStore): string => {
  const contentTypeId = getContentTypeIdInContentTypeAsComponentUsageDialog(state);
  const contentType = state.data.contentTypes.byId.get(contentTypeId);

  return contentType ? contentType.name : '';
};

const isInitialLoading = (state: IStore): boolean => {
  const { loadingStatus, items } = state.data.contentTypeComponentsUsage;
  return loadingStatus === LoadingStatus.InitialEmpty || items === null;
};

export const ContentTypeUsedAsComponentItemListingModal = () => {
  const dispatch = useDispatch();
  const contentTypeId = useSelector(getContentTypeIdInContentTypeAsComponentUsageDialog);
  const contentTypeName = useSelector(getContentTypeName);
  const isInitRetrieving = useSelector(isInitialLoading);
  const loadingStatus = useSelector((s) => s.data.contentTypeComponentsUsage.loadingStatus);
  const renderScrollTable = useSelector(
    (s) =>
      !isInitRetrieving &&
      isContentItemFilterInitialized(
        ContentItemFilterOrigin.ModalContentItemSelector,
        s.contentApp.listingUi.filterStatus,
      ),
  );

  const closeModal = () => {
    dispatch(filterSearchPhraseChanged(''));
    dispatch(clearContentItemListingFilter());
    dispatch(modalDismissed());
    dispatch(contentTypeAsComponentUsageModalDismissed());
  };
  const filterChanged = () => dispatch(filterItemsWithContentTypeAsComponentUsage(contentTypeId));

  const filter = useSelector((s) => s.contentApp.listingUi.filter);
  const workflows = useDataSelector((data) => data.workflows.byId);
  const filterSetUp = useCallback(
    (listingFilter: IListingFilter) => {
      dispatch(
        contentItemFilterSetUp(listingFilter, ContentItemFilterOrigin.ModalContentItemSelector),
      );
      dispatch(
        trackUserEvent(TrackedEvent.FindRightContent, {
          name: FindRightContentTrackedEvent.OpenedContentItemInventoryPage,
          ...getFindRightContentFilterEventData(
            listingFilter,
            ContentItemFilterOrigin.ModalContentItemSelector,
            Collection.getValues(workflows),
          ),
        }),
      );
    },
    [workflows],
  );
  const filterLeft = useCallback(
    () => dispatch(contentItemFilterLeft(ContentItemFilterOrigin.ModalContentItemSelector)),
    [],
  );

  useSetUpContentItemFilter(
    ContentItemFilterOrigin.ModalContentItemSelector,
    filterSetUp,
    filterLeft,
    undefined,
    undefined,
  );

  useFilterChanged(filterChanged, filter, loadingStatus);

  useThunkPromise(loadContentTypeAsComponentUsageCount, contentTypeId);

  return (
    <ContentTypeAsComponentUsageDialogProvider>
      <ModalContentItemSelectorLayout
        titleBarText={`${capitalizeFirstLetter(
          contentTypeName,
        )} content type is used in these items as a component`}
        isFullTextSearchForceDisabled
        isInitRetrieving={isInitRetrieving}
        onCancel={closeModal}
        renderItemFilter={(hideFilter) => (
          <ContentItemFilter
            clearFilter={() => dispatch(clearContentItemListingFilter())}
            hideFilter={hideFilter}
            origin={ContentItemFilterOrigin.ModalContentItemSelector}
          />
        )}
        renderScrollTable={(ref) =>
          renderScrollTable ? (
            <ContentTypeUsedAsComponentScrollTable
              isInitRetrieving={isInitRetrieving}
              parentContainerRef={ref}
              closeModal={closeModal}
            />
          ) : (
            <Loader />
          )
        }
      />
    </ContentTypeAsComponentUsageDialogProvider>
  );
};
