import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import classNames from 'classnames';
import React from 'react';
import { ListingMessage } from '../../../../_shared/uiComponents/ListingMessage/ListingMessage.tsx';
import { IListingContentItem } from '../../../../data/models/listingContentItems/IListingContentItem.ts';
import { RedactedItemName } from '../../content/constants/uiConstants.ts';
import { RelationsActions } from '../containers/RelationsActions.tsx';

const getHomeItemName = (
  item: IListingContentItem | undefined,
  cannotViewMessage: string | undefined,
): JSX.Element => {
  return (
    <Tooltip text={cannotViewMessage} placement="top-start">
      <span
        className={classNames('relations-root-item__title', {
          'relations-root-item__title--is-redacted': !!cannotViewMessage,
        })}
        title={item?.item?.name}
      >
        {cannotViewMessage ? RedactedItemName : item?.item?.name}
      </span>
    </Tooltip>
  );
};

export interface IRelationsListingMessageProps {
  readonly cannotViewMessage: string | undefined;
  readonly currentRootItem: IListingContentItem | undefined;
}

export const RelationsListingMessage: React.FC<IRelationsListingMessageProps> = ({
  cannotViewMessage,
  currentRootItem,
}) => (
  <ListingMessage
    statusInfoMessage={{
      text: <>Showing relations for {getHomeItemName(currentRootItem, cannotViewMessage)}</>,
    }}
  >
    <div className="listing-message__undo">
      <RelationsActions />
    </div>
  </ListingMessage>
);

RelationsListingMessage.displayName = 'RelationsListingMessage';
