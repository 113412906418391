import { AvatarSize, ButtonAvatar } from '@kontent-ai/component-library/Avatar';
import { Divider, DividerDirection } from '@kontent-ai/component-library/Dividers';
import { Menu, TabbableMenuItem } from '@kontent-ai/component-library/Menu';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React, { RefObject, useContext } from 'react';
import { RefinedNavigationContext } from '../../../applications/refinedNavigation/contexts/RefinedNavigationContext.tsx';
import { ICurrentUserInfo } from '../../../data/models/user/CurrentUserInfo.ts';
import { AppNames } from '../../constants/applicationNames.ts';
import { ProjectsRoute, SubscriptionsRoute, UserProfileRoute } from '../../constants/routePaths.ts';
import {
  DataUiAction,
  DataUiAppName,
  DataUiCollection,
  getDataUiActionAttribute,
  getDataUiCollectionAttribute,
  getDataUiNavAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import {
  formatUserName,
  getExistingUserNewColorGradient,
  getGravatarUrl,
  getUserInitials,
} from '../../utils/users/usersUtils.ts';

export interface IUserMenuDataProps {
  readonly currentUser: ICurrentUserInfo;
  readonly usernameVisible?: boolean;
  readonly showSubscriptionLink: boolean;
}

export interface IUserMenuDispatchProps {
  readonly trackMenuOpened: () => void;
  readonly onSignOutClick: () => void;
}

interface IUserMenuProps extends IUserMenuDataProps, IUserMenuDispatchProps {}

export const UserMenu: React.FC<IUserMenuProps> = ({
  currentUser,
  onSignOutClick,
  usernameVisible,
  showSubscriptionLink,
  trackMenuOpened,
}) => {
  const gravatarUrl = getGravatarUrl(currentUser.gravatarHash);
  const { isRefinedNavigationFeatureEnabled } = useContext(RefinedNavigationContext);

  return (
    <Menu
      onOpenChange={(isOpen) => {
        if (isOpen) {
          trackMenuOpened();
        }
      }}
    >
      <Menu.Trigger>
        {(ref, triggerProps, isOpen) => (
          <ButtonAvatar
            activated={isOpen}
            backgroundGradient={getExistingUserNewColorGradient(currentUser)}
            label="Your profile"
            image={gravatarUrl}
            initials={getUserInitials(currentUser)}
            ref={ref as RefObject<HTMLButtonElement>}
            size={isRefinedNavigationFeatureEnabled ? AvatarSize.XS : AvatarSize.M}
            tooltipPlacement="right"
            name={usernameVisible ? formatUserName(currentUser) : undefined}
            fullWidth={usernameVisible}
            {...getDataUiActionAttribute(DataUiAction.OpenUserMenu)}
            {...triggerProps}
          />
        )}
      </Menu.Trigger>
      <Menu.List
        placement="right bottom"
        role="list"
        {...getDataUiCollectionAttribute(DataUiCollection.UserMenu)}
      >
        <TabbableMenuItem
          id="user-profile"
          label={AppNames.UserProfile}
          linkPath={UserProfileRoute}
          {...getDataUiNavAttribute(DataUiAppName.UserProfile)}
        />
        <TabbableMenuItem
          id="projects"
          label={AppNames.Projects}
          linkPath={ProjectsRoute}
          {...getDataUiNavAttribute(DataUiAppName.Projects)}
        />
        {showSubscriptionLink && (
          <TabbableMenuItem
            id="subscriptions"
            label={AppNames.Subscriptions}
            linkPath={SubscriptionsRoute}
            {...getDataUiNavAttribute(DataUiAppName.SubscriptionListing)}
          />
        )}
        <Divider
          direction={DividerDirection.Horizontal}
          offsetAfter={Spacing.M}
          offsetBefore={Spacing.M}
        />
        <TabbableMenuItem
          id="sign-out"
          label="Sign out"
          onAction={onSignOutClick}
          {...getDataUiActionAttribute(DataUiAction.Logout)}
        />
      </Menu.List>
    </Menu>
  );
};

UserMenu.displayName = 'UserMenu';
