import { InvariantException } from '@kontent-ai/errors';
import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { trackUserEvent } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { getSelectedLanguageId } from '../../../../_shared/selectors/getSelectedLanguageId.ts';
import { getCurrentProjectId } from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { getCannotCreateMessageForItem } from '../../../../_shared/utils/permissions/activeCapabilities.ts';
import { getCannotViewItemMessage } from '../../../../_shared/utils/permissions/getCannotViewItemMessage.ts';
import { getContentItemPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { useLivePreviewPreferenceStorage } from '../../../../localStorages/useLivePreviewPreferenceStorage.ts';
import { collapseRelationsNode } from '../actions/relationsActions.ts';
import { expandRelationsNode } from '../actions/thunkRelationsActions.ts';
import {
  IRelationsScrollTableOwnProps,
  RelationsScrollTable as RelationsScrollTableComponent,
} from '../components/RelationsScrollTable.tsx';

export const RelationsScrollTable = (props: IRelationsScrollTableOwnProps) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const contentItems = useSelector((s) => s.data.listingContentItems.byId);
  const contentTypes = useSelector((s) => s.data.contentTypes.byId);
  const expandedNodesData = useSelector((s) => s.relationsApp.expandedNodesData);
  const nodesBeingProcessed = useSelector((s) => s.relationsApp.nodesBeingProcessed);
  const selectedLanguageId = useSelector(getSelectedLanguageId);
  const currenPath = history.location.pathname;
  const currentProjectId = useSelector(getCurrentProjectId);
  const { isLivePreviewPreferred } = useLivePreviewPreferenceStorage(currentProjectId);

  const getLinkForItem = useCallback(
    (itemId: Uuid) =>
      getContentItemPath(
        currenPath,
        itemId,
        isLivePreviewPreferred,
        selectedLanguageId ?? undefined,
      ),
    [currenPath, selectedLanguageId, isLivePreviewPreferred],
  );

  const onNodeExpanded = useCallback((nodeId: string, itemId: Uuid) => {
    dispatch(
      expandRelationsNode({
        itemId,
        nodeId,
      }),
    );
    dispatch(trackUserEvent(TrackedEvent.RelationsTabItemExpanded));
  }, []);

  const onNodeCollapsed = useCallback(
    (nodeId: Uuid) => dispatch(collapseRelationsNode(nodeId)),
    [],
  );

  const getCannotViewReason = useCallback(
    (itemId: Uuid) => {
      const item = contentItems.get(itemId);

      if (!item) {
        throw InvariantException(`Item with id ${itemId} not found.`);
      }

      return getCannotViewItemMessage(item);
    },
    [contentItems],
  );

  const getCannotOpenReason = useCallback(
    (itemId: Uuid) => {
      const item = contentItems.get(itemId);

      if (!item) {
        throw InvariantException(`Item with id ${itemId} not found.`);
      }

      return getCannotCreateMessageForItem(item);
    },
    [contentItems],
  );

  if (!selectedLanguageId) {
    return null;
  }

  return (
    <RelationsScrollTableComponent
      contentItems={contentItems}
      contentTypes={contentTypes}
      expandedNodesData={expandedNodesData}
      getCannotOpenReasonForItem={getCannotOpenReason}
      getCannotViewReasonForItem={getCannotViewReason}
      getLinkForItem={getLinkForItem}
      nodesBeingProcessed={nodesBeingProcessed}
      onNodeCollapsed={onNodeCollapsed}
      onNodeExpanded={onNodeExpanded}
      rootId={props.rootId}
    />
  );
};
