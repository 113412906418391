import { IBaseSelectItem } from '@kontent-ai/component-library/Selects';
import { Collection } from '@kontent-ai/utils';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { createUnknownUserInfo } from '../../../../../_shared/models/UserInfo.ts';
import { repositoryCollection } from '../../../../../_shared/repositories/repositories.ts';
import { getCurrentProject } from '../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { getAllActiveLanguagesForCurrentUserInAnyCollection } from '../../../../../_shared/utils/languageUtils.ts';
import { Capability } from '../../../../../_shared/utils/permissions/capability.ts';
import { getContentItemPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { formatUserName } from '../../../../../_shared/utils/users/usersUtils.ts';
import { CollectionsMap } from '../../../../../data/models/collections/Collection.ts';
import { IContentType } from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { createPublishedListingContentItemRecordFromServerModel } from '../../../../../data/models/listingContentItems/PublishedListingContentItemRecord.ts';
import {
  ListingRequestOptionsModel,
  ListingServerResponseModel,
} from '../../../../../repositories/utils/ensureAllRequestedDataFetched.ts';
import { getLanguageOptionsArray } from '../../../../itemEditor/features/NewContentItem/selectors/getLanguageOptions.ts';
import { TimeInterval } from '../../../constants/timeIntervals.ts';
import { PublishedItemsServerModel } from '../../../repositories/serverModels/missionControlRepositoryServerModels.type.ts';
import { entityListDetailDisplayCount } from '../../shared/constants/widgetConstants.ts';
import { useWidgetListingDataFetcher } from '../../shared/hooks/useWidgetListingDataFetcher.tsx';
import { getLanguageNameFromVariantId } from '../../shared/utils/getLanguageNameFromVariantId.ts';
import { PublishedItemsWidgetDetail } from '../components/PublishedItemsWidgetDetail.tsx';
import { PublishedItemsWidgetDetailRow } from '../components/PublishedItemsWidgetDetailRow.tsx';
import { PublishedItemsCountRequestModel } from '../types/PublishedItemsCountRequestModel.type.ts';
import { PublishedItemsFilterModel } from '../types/PublishedItemsFilterModel.type.ts';
import { PublishedItemsListingRequestOptionsModel } from '../types/PublishedItemsListingRequestOptionsModel.type.ts';
import { getCollectionNameFromId } from '../utils/getCollectionNameFromId.ts';
import { getContentTypeNameFromId } from '../utils/getContentTypeNameFromId.ts';

type PublishedItemsWidgetDetailContainerProps = React.PropsWithChildren &
  Readonly<{
    ariaLabelledBy: string;
    collectionIds: CollectionsMap;
    contentTypeByIds: Immutable.Map<Uuid, IContentType>;
    isLivePreviewPreferred: boolean;
    onItemClick?: () => void;
    selectedTimeInterval: TimeInterval;
  }>;

export const PublishedItemsWidgetDetailContainer = ({
  ariaLabelledBy,
  collectionIds,
  contentTypeByIds,
  isLivePreviewPreferred,
  onItemClick,
  selectedTimeInterval,
}: PublishedItemsWidgetDetailContainerProps) => {
  const usersById = useSelector((s) => s.data.users.usersById);

  const { pathname } = useLocation();
  const getPublisherFromId = (publisherId: string) => {
    return usersById.get(publisherId) ?? createUnknownUserInfo(publisherId);
  };
  const availableUsers = Collection.getValues(usersById).map(
    (contributor): IBaseSelectItem => ({
      id: contributor.userId,
      label: formatUserName(contributor),
    }),
  );

  const defaultLanguage = useSelector((s) => s.data.languages.defaultLanguage);
  const languagesById = useSelector((s) => s.data.languages.byId);
  const availableLanguages = useSelector((s) => {
    const options = getAllActiveLanguagesForCurrentUserInAnyCollection(
      s.data.user,
      getCurrentProject(s).projectId,
      s.data.languages,
      Capability.ViewContent,
    );

    return getLanguageOptionsArray(options);
  });

  const [selectedFilter, setSelectedFilter] = useState<PublishedItemsFilterModel>({
    timeInterval: selectedTimeInterval,
    languageIds: null,
    userIds: null,
  });

  const clearFilters = () => {
    setSelectedFilter({
      userIds: null,
      languageIds: null,
      timeInterval: selectedTimeInterval,
    });
  };

  const itemsFetcher = useCallback(
    async (
      requestOptions: ListingRequestOptionsModel,
      abortSignal: AbortSignal,
    ): Promise<ListingServerResponseModel<PublishedItemsServerModel>> => {
      const filter: PublishedItemsFilterModel = {
        timeInterval: selectedFilter.timeInterval,
        languageIds: selectedFilter.languageIds,
        userIds: selectedFilter.userIds,
      };

      const options: PublishedItemsListingRequestOptionsModel = {
        ...requestOptions,
        filter,
      };

      return await repositoryCollection.missionControlRepository.getPublishedItems(
        options,
        abortSignal,
      );
    },
    [selectedFilter],
  );

  const {
    fetchInit,
    state: itemsState,
    data: publishedItems,
  } = useWidgetListingDataFetcher(
    itemsFetcher,
    createPublishedListingContentItemRecordFromServerModel,
  );

  const [publishedItemsCount, setPublishedItemsCount] = useState<number>(0);
  const publishedItemsCountCallback = useCallback(async (): Promise<number> => {
    const abortController = new AbortController();
    const filter: PublishedItemsFilterModel = {
      timeInterval: selectedFilter.timeInterval,
      languageIds: selectedFilter.languageIds,
      userIds: selectedFilter.userIds,
    };
    const query: PublishedItemsCountRequestModel = { filter };

    return await repositoryCollection.missionControlRepository.getPublishedItemsCount(
      query,
      abortController.signal,
    );
  }, [selectedFilter]);

  useEffect(() => {
    const fetchPublishedItemsCount = async () => {
      const count = await publishedItemsCountCallback();
      setPublishedItemsCount(count);
    };

    fetchPublishedItemsCount();
  }, [publishedItemsCountCallback]);

  useEffect(() => fetchInit(entityListDetailDisplayCount).cancel, [fetchInit]);

  return (
    <PublishedItemsWidgetDetail
      ariaLabelledBy={ariaLabelledBy}
      availableLanguages={availableLanguages}
      availableUsers={availableUsers}
      clearFilters={clearFilters}
      filter={selectedFilter}
      publishedItemsCount={publishedItemsCount}
      setFilter={setSelectedFilter}
      state={itemsState}
    >
      {publishedItems.map((item) => {
        const language = getLanguageNameFromVariantId(
          item.contentItemId.variantId,
          defaultLanguage,
          languagesById,
        );
        const path = getContentItemPath(
          pathname,
          item.contentItemId.itemId,
          isLivePreviewPreferred,
          item.contentItemId.variantId,
        );

        return (
          <PublishedItemsWidgetDetailRow
            collectionName={getCollectionNameFromId(collectionIds, item.collectionId)}
            contentTypeName={getContentTypeNameFromId(contentTypeByIds, item.contentTypeId)}
            item={item}
            key={item.id}
            language={language}
            onItemClick={onItemClick}
            pathToItem={path}
            publisher={getPublisherFromId(item.publishedBy)}
            rowFocusLinkAriaLabel={`visit content item — ${item.name}`}
          />
        );
      })}
    </PublishedItemsWidgetDetail>
  );
};
