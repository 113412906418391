import { Loader } from '../../../../_shared/components/Loader.tsx';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import {
  getCurrentProject,
  getCurrentProjectId,
  getCurrentProjectPlan,
} from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { isWebSpotlightEnabled as isWebSpotlightEnabledSelector } from '../../../webSpotlight/selectors/webSpotlightSelectors.ts';
import {
  canActivateApiStatus,
  canManageApiKey,
  canManageEnvironment,
  canManageWebSpotlight,
} from '../../utils/environmentSettingsMenuCustomVisibilities.ts';
import { loadEnvironmentApiStatus } from '../actions/thunkApiActivationStatusActions.ts';
import { GeneralSettings as GeneralSettingsComponent } from '../components/GeneralSettings.tsx';
import { ApiActivationStatusCard } from './ApiActivationStatusCard.tsx';
import { EnvironmentIdCard } from './EnvironmentIdCard.tsx';
import { EnvironmentNameCard } from './EnvironmentNameCard.tsx';
import { LivePreviewStatusTile } from './LivePreviewStatusTile.tsx';
import { WebSpotlightStatusTile } from './WebSpotlightStatusTile.tsx';

const canManageWebSpotlightSelector = (state: IStore): boolean => {
  const projectInfo = getCurrentProject(state);
  const currentProjectPlan = getCurrentProjectPlan(state);
  const isWebSpotlightEnabled = isWebSpotlightEnabledSelector(state);
  return canManageWebSpotlight(
    state.data.user,
    projectInfo,
    currentProjectPlan,
    isWebSpotlightEnabled,
  );
};

export const GeneralSettings = () => {
  const isEnvironmentNameVisible = useSelector((s) => canManageEnvironment(getCurrentProject(s)));
  const isEnvironmentIdVisible = useSelector((s) => canManageApiKey(getCurrentProject(s)));
  const isWebSpotlightVisible = useSelector(canManageWebSpotlightSelector);
  const isLivePreviewStatusVisible = useSelector((s) => canManageEnvironment(getCurrentProject(s)));
  const isApiActivationStatusVisible = useSelector((state) =>
    canActivateApiStatus(state.data.user, getCurrentProject(state)),
  );
  const currentEnvironmentId = useSelector(getCurrentProjectId);

  const [isLoadThunkDone] = useThunkPromise(loadEnvironmentApiStatus, currentEnvironmentId, {
    canRun: isApiActivationStatusVisible,
  });

  if (isApiActivationStatusVisible && !isLoadThunkDone) {
    return <Loader />;
  }

  return (
    <GeneralSettingsComponent
      renderBody={() => (
        <>
          {isEnvironmentNameVisible && <EnvironmentNameCard />}
          {isEnvironmentIdVisible && <EnvironmentIdCard />}
          {isApiActivationStatusVisible && <ApiActivationStatusCard />}
          {isLivePreviewStatusVisible && <LivePreviewStatusTile />}
          {isWebSpotlightVisible && <WebSpotlightStatusTile />}
        </>
      )}
    />
  );
};
