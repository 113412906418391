import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import React from 'react';
import { Link } from 'react-router-dom';
import { Warning } from '../../../../../_shared/components/infos/Warning.tsx';
import { documentationLinks } from '../../../../../_shared/constants/documentationLinks.ts';
import {
  EnvironmentRouteParams,
  TaxonomyGroupsRoute,
} from '../../../../../_shared/constants/routePaths.ts';
import { buildPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';

interface ISitemapSunsetWarningProps {
  readonly projectId: Uuid;
}

export const SitemapSunsetWarning: React.FC<ISitemapSunsetWarningProps> = ({ projectId }) => {
  const taxonomyGroupsLink = buildPath<EnvironmentRouteParams>(TaxonomyGroupsRoute, { projectId });

  return (
    <Warning subtitle="Discontinuation notice">
      <p>
        The sitemaps feature has been marked as obsolete and will be removed from the product in the
        future.
      </p>
      <p>
        Instead of Sitemaps, we recommend using <Link to={taxonomyGroupsLink}>taxonomy groups</Link>{' '}
        or{' '}
        <OutwardLink href={documentationLinks.buildingNavigation}>
          modeling navigation using content items
        </OutwardLink>
        . Before removing Sitemaps, we will provide you with a migration script for moving your
        sitemap information into taxonomy.
      </p>
    </Warning>
  );
};
