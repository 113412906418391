import React from 'react';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import {
  isArchivedWorkflowStepSelected,
  isPublishingStepSelected,
} from '../../../../../../_shared/utils/contentItemUtils.ts';
import { isUrlPatternSetForEditedContentItemSelector } from '../../../../selectors/isUrlPatternSetForEditedContentItem.ts';
import {
  activateContentItemEditingAction,
  deactivateContentItemEditingAction,
} from '../../actions/contentItemEditingActions.ts';
import { EditingActions as EditingActionsComponent } from '../../components/editingActions/EditingActions.tsx';
import { useAvailableContentItemEditingActions } from '../../hooks/useAvailableContentItemEditingActions.ts';
import { EditingAction } from '../../models/EditingAction.ts';

export const EditingActions: React.FC = () => {
  const dispatch = useDispatch();
  const activatedAction = useSelector((s) => s.contentApp.editorUi.editingActions.activatedAction);
  const isUrlPatternSetForEditedContentItem = useSelector(
    isUrlPatternSetForEditedContentItemSelector,
  );
  const availableActions = useAvailableContentItemEditingActions(
    isUrlPatternSetForEditedContentItem,
  );
  const isInPublishingOrArchivingStep = useSelector((s) => {
    const editedContentItemVariant = s.contentApp.editedContentItemVariant;
    return (
      !!editedContentItemVariant &&
      (isPublishingStepSelected(editedContentItemVariant.assignment) ||
        isArchivedWorkflowStepSelected(editedContentItemVariant.assignment))
    );
  });

  return (
    <EditingActionsComponent
      activatedAction={activatedAction}
      availableActions={availableActions}
      isInPublishingOrArchivingStep={isInPublishingOrArchivingStep}
      onDeactivateAction={() => dispatch(deactivateContentItemEditingAction())}
      onShowActionsMenu={() =>
        dispatch(activateContentItemEditingAction(EditingAction.showMainMenu))
      }
    />
  );
};
