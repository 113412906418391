import { BoxShadow, transition250 } from '@kontent-ai/component-library/tokens';
import styled from 'styled-components';
import { assetTileBorderRadius } from '../decisionTokens.ts';

interface IStyledShadowAnimationHelperProps {
  readonly isHovered: boolean;
}

export const StyledShadowAnimationHelper = styled.div<IStyledShadowAnimationHelperProps>`
  border-radius: ${assetTileBorderRadius};
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: ${BoxShadow.M};
  opacity: ${({ isHovered }) => (isHovered ? 1 : 0)};
  transition: opacity ${transition250};
`;
