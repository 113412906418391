import { ActiveCapabilityType } from '../../../../../../../_shared/models/activeCapability.type.ts';
import { getEditedContentItemVariant } from '../../../../../../../_shared/selectors/getEditedContentItemVariant.ts';
import { IStore } from '../../../../../../../_shared/stores/IStore.type.ts';
import { hasActiveVariantCapabilityForEditedItem } from '../../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { PublishingState } from '../../../../../../../data/constants/PublishingState.ts';
import { isUsedAsWebSpotlightRootItem } from '../../../../../../webSpotlight/utils/webSpotlightUtils.ts';

export const isDeleteItemAvailable = (state: IStore) => {
  const variant = getEditedContentItemVariant(state);
  return (
    !!variant &&
    hasActiveVariantCapabilityForEditedItem(ActiveCapabilityType.DeleteContent, state) &&
    variant.publishingState !== PublishingState.Published &&
    !isUsedAsWebSpotlightRootItem(state, variant.id.itemId)
  );
};

export const isPublishPreventsDeletingItemAvailable = (state: IStore) => {
  const variant = getEditedContentItemVariant(state);
  return (
    hasActiveVariantCapabilityForEditedItem(ActiveCapabilityType.DeleteContent, state) &&
    variant?.publishingState === PublishingState.Published
  );
};

export const isWebSpotlightPreventsDeletingItemAvailable = (state: IStore) => {
  const variant = getEditedContentItemVariant(state);
  return (
    !!variant &&
    hasActiveVariantCapabilityForEditedItem(ActiveCapabilityType.DeleteContent, state) &&
    variant.publishingState !== PublishingState.Published &&
    isUsedAsWebSpotlightRootItem(state, variant.id.itemId)
  );
};
