import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { loadProjectProperties } from '../../../../_shared/actions/thunkSharedActions.ts';
import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import { getCurrentProjectId } from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { logError } from '../../../../_shared/utils/logError.ts';
import { AnyInnovationLabFeatureCodename } from '../types/InnovationLabFeatureCodenames.ts';

const { innovationLabRepository } = repositoryCollection;

export const activateInnovationLabFeature =
  (featureName: AnyInnovationLabFeatureCodename): ThunkPromise =>
  async (dispatch, getState): Promise<void> => {
    try {
      const currentProjectId = getCurrentProjectId(getState());
      await innovationLabRepository.enableFeature(featureName);
      await dispatch(loadProjectProperties(currentProjectId));
    } catch (error) {
      logError('Failed to enable EAP feature or re-fetch project properties.', error);
    }
  };
