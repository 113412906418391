import { Icons } from '@kontent-ai/component-library/Icons';
import {
  SimpleStatusDefault,
  SimpleStatusSuccess,
} from '@kontent-ai/component-library/SimpleStatus';
import React from 'react';

type Props = {
  readonly isEnabled: boolean;
};

export const CardStatus: React.FC<Props> = ({ isEnabled }) =>
  isEnabled ? (
    <SimpleStatusSuccess icon={Icons.CheckCircle} label="Active" />
  ) : (
    <SimpleStatusDefault icon={Icons.ICircle} label="Inactive" />
  );

CardStatus.displayName = 'CardStatus';
