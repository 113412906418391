import { Box } from '@kontent-ai/component-library/Box';
import { Callout } from '@kontent-ai/component-library/Callout';
import { Stack } from '@kontent-ai/component-library/Stack';
import { ShortcutsConfig, useHotkeys } from '@kontent-ai/component-library/hooks';
import { Spacing, canvasInnerSectionRestrictedWidth } from '@kontent-ai/component-library/tokens';
import { useRef } from 'react';
import { HtmlPageTitle } from '../../../../_shared/components/HtmlPageTitle.tsx';
import { AppNames } from '../../../../_shared/constants/applicationNames.ts';
import { useUpdatePaperContext } from '../../../../_shared/contexts/EditorPaperContext.tsx';
import { useFixAutoCompleteOnScroll } from '../../../../_shared/hooks/useFixAutoCompleteOnScroll.ts';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IContentType } from '../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { ReadonlyTypeElement } from '../../shared/components/typeElements/shared/ReadonlyTypeElement.tsx';
import { AssetTypeElementLibrary } from '../containers/AssetTypeElementLibrary.tsx';
import { AssetTypeElementList } from '../containers/AssetTypeElementList.tsx';

interface Props {
  readonly areCollectionsVisible: boolean;
  readonly editedContentType: IContentType;
  readonly showWarning: boolean;
  readonly isBeingSaved: boolean;
  readonly onSaveContentType: () => void;
}

export const AssetTypeEditorPane = ({
  areCollectionsVisible,
  editedContentType,
  showWarning,
  isBeingSaved,
  onSaveContentType,
}: Props) => {
  const contentTypePane = useRef<HTMLDivElement>(null);

  useFixAutoCompleteOnScroll(contentTypePane);
  useUpdatePaperContext(contentTypePane);

  useHotkeys(
    {
      [ShortcutsConfig.ControlS]: (e: KeyboardEvent): void => {
        e.preventDefault();
        if (isBeingSaved) return;
        onSaveContentType();
      },
    },
    { ref: null },
  );

  return (
    <div className="canvas__wrapper">
      <div
        ref={contentTypePane}
        className="canvas__workspace canvas__workspace--detached-scrollbars canvas__workspace--in-content-model"
        {...getDataUiAppNameAttribute(DataUiAppName.AssetType)}
      >
        <HtmlPageTitle appName={AppNames.AssetType} customName={editedContentType.name} />
        <section className="canvas__content">
          <div className="canvas__content-pane">
            <div className="content-type__wrapper">
              <div className="canvas__inner-section canvas__inner-section--restricted-width">
                <Stack spacing={Spacing.XL}>
                  <Box paddingLeft={Spacing.XL} paddingRight={Spacing.XL}>
                    <Stack spacing={Spacing.XL}>
                      {showWarning && (
                        <Callout
                          maxWidth={canvasInnerSectionRestrictedWidth}
                          calloutType="friendlyWarning"
                          headline="Asset type is not available in your plan"
                        >
                          Advanced asset management is no longer part of your subscription plan. You
                          can only remove the existing asset taxonomies. If you’d like to keep using
                          the asset type, contact us.
                        </Callout>
                      )}
                      <ReadonlyTypeElement name="Asset title" />
                      <ReadonlyTypeElement name="Asset" />
                      {areCollectionsVisible && (
                        <ReadonlyTypeElement
                          name="Collection"
                          description="The collection of the asset."
                        />
                      )}
                      <ReadonlyTypeElement
                        name="Localized description"
                        description="The description will be used as Alt text."
                      />
                    </Stack>
                  </Box>
                  <AssetTypeElementList />
                </Stack>
              </div>
            </div>
          </div>
        </section>
        <div className="canvas__sidebar">
          <AssetTypeElementLibrary />
        </div>
      </div>
    </div>
  );
};
