import React from 'react';
import { DefaultCollectionId } from '../../../../../_shared/constants/variantIdValues.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { getCurrentProjectPlan } from '../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  ICollectionGroupRoles,
  IProjectContributorRole,
} from '../../../../../data/models/users/ProjectContributor.ts';
import { areContentCollectionsEnabledForCurrentProjectPlan } from '../../../utils/allowedFeaturesUtils.ts';
import { CollectionGroups } from '../../containers/collections/CollectionGroups.tsx';
import { RoleBuilder } from '../../containers/roleBuilder/RoleBuilder.tsx';
import { useProjectManagerSelectionValidation } from '../../hooks/useProjectManagerSelectionValidation.ts';

type Props = {
  readonly collectionGroups: readonly ICollectionGroupRoles[];
  readonly disabledTooltip: string | undefined;
  readonly initialCollectionGroups?: readonly ICollectionGroupRoles[];
  readonly onCollectionGroupsChange: (collectionGroups: readonly ICollectionGroupRoles[]) => void;
  readonly userId?: Uuid | undefined;
};

const containsCustomCollection = (collectionGroups?: readonly ICollectionGroupRoles[]): boolean =>
  !!collectionGroups &&
  (collectionGroups.length > 1 ||
    !!collectionGroups.find((g) => g.collectionIds.find((c) => c !== DefaultCollectionId)));

export const UserConfiguration: React.FC<Props> = ({
  collectionGroups,
  disabledTooltip,
  initialCollectionGroups,
  onCollectionGroupsChange,
  userId,
}) => {
  const areCollectionsEnabled = useSelector((state) =>
    areContentCollectionsEnabledForCurrentProjectPlan(getCurrentProjectPlan(state)),
  );
  const initiallyContainedCustomCollection = containsCustomCollection(initialCollectionGroups);
  const isProjectManagerSelectionValid = useProjectManagerSelectionValidation(collectionGroups);

  if (areCollectionsEnabled || initiallyContainedCustomCollection) {
    return (
      <div {...getDataUiElementAttribute(DataUiElement.UserConfiguration)}>
        <CollectionGroups
          collectionsGroups={collectionGroups}
          contributorId={userId}
          initiallyContainedCustomCollection={initiallyContainedCustomCollection}
          disabledTooltip={disabledTooltip}
          onCollectionGroupsChange={onCollectionGroupsChange}
          isProjectManagerSelectionValid={isProjectManagerSelectionValid}
        />
      </div>
    );
  }

  const collectionGroup = collectionGroups[0];
  const changeRoles = (roles: readonly IProjectContributorRole[]): void => {
    if (collectionGroup) {
      onCollectionGroupsChange([
        {
          ...collectionGroup,
          roles,
        },
      ]);
    }
  };

  return (
    <div
      className="collections-group"
      {...getDataUiElementAttribute(DataUiElement.UserConfiguration)}
    >
      <RoleBuilder
        contributorId={userId}
        disabledTooltip={disabledTooltip}
        isProjectManagerSelectionValid={isProjectManagerSelectionValid}
        onChange={changeRoles}
        userRoles={collectionGroup?.roles ?? []}
      />
    </div>
  );
};

UserConfiguration.displayName = 'UserConfiguration';
