import { Inline } from '@kontent-ai/component-library/Inline';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React, { useContext } from 'react';
import { IProjectContributor } from '../../../../../../data/models/users/ProjectContributor.ts';
import { EditableTypeElement } from '../../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { useItemElementReference } from '../../containers/hooks/useItemElementReference.ts';
import { ContentNestingContext } from '../../context/ContentNestingContext.tsx';
import { NonLocalizableItemElementInfo } from './NonLocalizableItemElementInfo.tsx';
import { ItemElementSimultaneousEditingStatus } from './simultaneousEditing/ItemElementSimultaneousEditingStatus.tsx';
import { ItemElementHeaderSubsection } from './subComponents/ItemElementHeaderSubsection.tsx';

interface IContentItemElementHeaderInfoProps {
  readonly hideNonLocalizableElementInfo?: boolean;
  readonly hideSimultaneousEditingStatus?: boolean;
  readonly isOutdated?: boolean;
  readonly isRefreshPending?: boolean;
  readonly lockedByUser: IProjectContributor | null;
  readonly typeElement: EditableTypeElement;
}

export const ContentItemElementHeaderInfo: React.FC<
  React.PropsWithChildren<IContentItemElementHeaderInfoProps>
> = ({
  children,
  hideNonLocalizableElementInfo,
  hideSimultaneousEditingStatus,
  isOutdated,
  isRefreshPending,
  lockedByUser,
  typeElement,
}) => {
  const { isCurrentLevelContentComponent } = useContext(ContentNestingContext);
  const element = useItemElementReference(typeElement);

  const renderItemElementHeaderInfo = () => {
    if (isCurrentLevelContentComponent) {
      return null;
    }

    if (!hideSimultaneousEditingStatus && (isOutdated || !!lockedByUser)) {
      return (
        <ItemElementSimultaneousEditingStatus
          isOutdated={!!isOutdated}
          isRefreshPending={!!isRefreshPending}
          itemId={element?.itemId?.itemId ?? null}
          elementId={typeElement.elementId}
          lockedByUser={lockedByUser}
        />
      );
    }

    if (!hideNonLocalizableElementInfo && typeElement.isNonLocalizable) {
      return <NonLocalizableItemElementInfo typeElement={typeElement} />;
    }

    return null;
  };

  const hasChildren = React.Children.toArray(children).length > 0;
  const itemElementHeaderInfo = renderItemElementHeaderInfo();

  if (!hasChildren && !itemElementHeaderInfo) {
    return null;
  }

  return (
    <ItemElementHeaderSubsection>
      <Inline align="center" spacingX={Spacing.L} spacingY={Spacing.S}>
        {itemElementHeaderInfo}
        {children}
      </Inline>
    </ItemElementHeaderSubsection>
  );
};

ContentItemElementHeaderInfo.displayName = 'ContentItemElementHeaderInfo';
