import { EditorProps, EditorState } from 'draft-js';
import { useCallback } from 'react';
import { getDataAttribute } from '../../../../_shared/utils/dataAttributes/DataAttributes.ts';
import { ElementAttributes } from '../../../itemEditor/constants/elementAttributes.ts';
import { CommentThreadState } from '../../../itemEditor/types/CommentThreadState.ts';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginComponent } from '../../editorCore/types/Editor.composition.type.ts';
import { None, WithoutProps } from '../../editorCore/types/Editor.contract.type.ts';
import { DecoratedEditor } from '../../editorCore/types/Editor.decorated.type.ts';
import { Apply, PluginState, Render } from '../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';
import { DraftJsEditorPlugin } from '../draftJs/DraftJsEditorPlugin.type.ts';
import { OnUpdate } from '../draftJs/DraftJsPlugin.type.ts';
import { mergeInlineStyles } from '../draftJs/utils/draftJsEditorUtils.ts';
import { StylesPlugin } from '../visuals/StylesPlugin.tsx';
import { getInlineStyleWithCommentIds } from './api/editorCommentStyleUtils.ts';
import { getSelectedThreadSegmentId } from './api/editorCommentUtils.ts';

export type DisplayCommentsPluginProps = {
  readonly commentThreadIdMapping: ReadonlyMap<Uuid, Uuid>;
  readonly commentThreads: ReadonlyMap<Uuid, CommentThreadState>;
  readonly focusedCommentThreadId: Uuid | null;
  readonly onBlurCommentThread: () => void;
  readonly onFocusCommentThread: (threadId: Uuid) => void;
};

export type DisplayCommentsPlugin = DraftJsEditorPlugin<
  None,
  DisplayCommentsPluginProps,
  None,
  None,
  [StylesPlugin]
>;

const EditorWithComments: DecoratedEditor<WithoutProps<DisplayCommentsPlugin>> = ({
  baseRender,
  state,
}) => {
  const baseCustomStyleFn = state.editorProps?.customStyleFn;
  const customStyleFn = useCallback<Required<EditorProps>['customStyleFn']>(
    (style, block) =>
      mergeInlineStyles(getInlineStyleWithCommentIds(style), baseCustomStyleFn?.(style, block)),
    [baseCustomStyleFn],
  );

  const stateWithComments: PluginState<DisplayCommentsPlugin> = {
    ...state,
    editorProps: {
      ...state.editorProps,
      customStyleFn,
    },
    rteInputProps: {
      ...state.rteInputProps,
      ...getDataAttribute(ElementAttributes.BlurCommentThreadOnClick, 'false'),
    },
  };

  return baseRender(stateWithComments);
};

export const DisplayCommentsPlugin: PluginComponent<DisplayCommentsPlugin> = (props) => {
  const {
    commentThreadIdMapping,
    commentThreads,
    focusedCommentThreadId,
    onBlurCommentThread,
    onFocusCommentThread,
  } = props;

  const focusSelectedCommentThread = useCallback(
    (newEditorState: EditorState): void => {
      const selection = newEditorState.getSelection();
      if (!selection.getHasFocus()) {
        return;
      }

      const focusedThreadIsUnsaved =
        !!focusedCommentThreadId &&
        commentThreads.get(focusedCommentThreadId) === CommentThreadState.Unsaved;
      if (focusedThreadIsUnsaved && selection.isCollapsed()) {
        // Do not blur new unsaved comment (selection is placed after the comment)
        return;
      }

      const content = newEditorState.getCurrentContent();
      const selectedThreadSegmentId = getSelectedThreadSegmentId(
        content,
        selection,
        commentThreads,
      );

      if (selectedThreadSegmentId) {
        const selectedThreadId = commentThreadIdMapping.get(selectedThreadSegmentId);

        if (focusedCommentThreadId !== selectedThreadId && selectedThreadId !== undefined) {
          onFocusCommentThread(selectedThreadId);
        }
      } else if (focusedCommentThreadId) {
        onBlurCommentThread();
      }
    },
    [
      onFocusCommentThread,
      onBlurCommentThread,
      commentThreads,
      commentThreadIdMapping,
      focusedCommentThreadId,
    ],
  );

  const onUpdate: Decorator<OnUpdate> = useCallback(
    (baseOnUpdate) => (params) => {
      baseOnUpdate?.(params);
      focusSelectedCommentThread(params.editorState);
    },
    [focusSelectedCommentThread],
  );

  const render: Decorator<Render<DisplayCommentsPlugin>> = useCallback(
    (baseRender) => (state) => <EditorWithComments state={state} baseRender={baseRender} />,
    [],
  );

  const apply: Apply<DisplayCommentsPlugin> = useCallback(
    (state) => {
      state.render.decorate(render);
      state.onUpdate.decorate(onUpdate);

      return {};
    },
    [onUpdate, render],
  );

  return useEditorWithPlugin(props, { apply });
};
