import { useEffect, useMemo } from 'react';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import { getSelectedSubscription } from '../../../../_shared/selectors/subscriptionSelectors.ts';
import { subscriptionUserListingConfigStorage } from '../../../../localStorages/subscriptionUserListingConfigStorage.ts';
import { loadSubscriptionUserListingConfig } from '../actions/thunkSubscriptionUsersActions.ts';

export const SubscriptionUserListingConfig = () => {
  const selectedSubscriptionId = useSelector(
    (state) => getSelectedSubscription(state)?.subscriptionId ?? '',
  );

  const filter = useSelector((s) => s.subscriptionApp.users.listingUi.filter);
  const sortBy = useSelector((s) => s.subscriptionApp.users.listingUi.sortBy);

  const config = useMemo(
    () => subscriptionUserListingConfigStorage.load(selectedSubscriptionId),
    [selectedSubscriptionId],
  );
  useThunkPromise(loadSubscriptionUserListingConfig, config);

  useEffect(() => {
    subscriptionUserListingConfigStorage.save(selectedSubscriptionId, { ...filter, ...sortBy });
  }, [selectedSubscriptionId, filter, sortBy]);

  return null;
};
