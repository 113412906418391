import { showIntercom } from '../../../applications/projects/actions/projectsActions.ts';
import { SubscriptionPlanMaxUserLimitReachedWarning } from '../../components/SubscriptionWarnings/SubscriptionPlanMaxUserLimitReachedWarning.tsx';
import { useDispatch } from '../../hooks/useDispatch.ts';
import { useSelector } from '../../hooks/useSelector.ts';
import { selectIntercomUtils } from '../../selectors/selectIntercomUtils.ts';
import { getSelectedSubscription } from '../../selectors/subscriptionSelectors.ts';
import { compose } from '../../utils/func/compose.ts';
import {
  getNumberOfUsersInActivation,
  getSubscriptionUsage,
  hasAvailableUserSlots,
} from '../../utils/subscriptionUsageUtils.ts';
import { isAdmin } from '../../utils/users/usersUtils.ts';

type Props = {
  readonly hideButton?: boolean;
};

const SubscriptionPlanMaxUserLimitReachedWarningContainer = (props: Props) => {
  const dispatch = useDispatch();

  const selectedSubscription = useSelector(getSelectedSubscription);
  const currentProject = useSelector((state) =>
    state.data.projects.byId.get(state.sharedApp.currentProjectId),
  );

  const subscriptionId = selectedSubscription
    ? selectedSubscription.subscriptionId
    : currentProject?.subscriptionId;

  const userId = useSelector((state) => state.data.user.info.userId);
  const currentUserIsAdmin =
    !!selectedSubscription ||
    (!!currentProject && isAdmin(currentProject.subscriptionAdmins, userId));

  const subscriptionUsages = useSelector((state) => state.data.subscriptions.subscriptionUsages);
  const plans = useSelector((state) => state.data.plans);

  const subscriptionUsage = getSubscriptionUsage(subscriptionUsages, subscriptionId);
  const plan = plans.byId.get(subscriptionUsage ? subscriptionUsage.planId : '');

  const numberOfUsersInActivation = useSelector(getNumberOfUsersInActivation);

  const intercomUtils = useSelector(selectIntercomUtils);

  if (!selectedSubscription && !currentProject) {
    return null;
  }

  return (
    <SubscriptionPlanMaxUserLimitReachedWarning
      closeInvitationModalDialog={compose(dispatch, showIntercom)}
      currentUserIsAdmin={currentUserIsAdmin}
      hideButton={!!props.hideButton}
      isVisible={!hasAvailableUserSlots(subscriptionUsage, plans.byId, numberOfUsersInActivation)}
      onContactSupport={intercomUtils.showMessage}
      planIsCustom={!!plan && plan.isCustom}
      subscriptionId={subscriptionId}
    />
  );
};

export {
  SubscriptionPlanMaxUserLimitReachedWarningContainer as SubscriptionPlanMaxUserLimitReachedWarning,
};
