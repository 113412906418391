import { Collection } from '@kontent-ai/utils';
import { useCallback, useMemo } from 'react';
import { useParams, useRouteMatch } from 'react-router';
import { modalOpened } from '../../../../../_shared/actions/sharedActions.ts';
import { Loader } from '../../../../../_shared/components/Loader.tsx';
import { AuthorizedSection } from '../../../../../_shared/components/routing/AuthorizedSection.tsx';
import { ModalDialogType } from '../../../../../_shared/constants/modalDialogType.ts';
import {
  ApiKeyDetailAccessDeniedRouteSegment,
  ApiKeyDetailRouteParams,
} from '../../../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../../_shared/hooks/useThunkPromise.ts';
import { ApiKeyType, DapiKeyTypes } from '../../../../../_shared/models/ApiKeyType.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import { getCurrentProjectContainer } from '../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { isProjectManagerInAnyEnvironment } from '../../../root/selectors/isProjectManagerInAnyEnvironment.ts';
import { apiKeyDetailsAlertDismissed } from '../../actions/apiKeysActions.ts';
import { initApiKey, updateApiKey } from '../../actions/thunkApiKeysActions.ts';
import { ApiKey, emptyApiKey } from '../../models/ApiKey.ts';
import { ApiKeyActionStatus } from '../../reducers/IApiKeysAppStoreState.type.ts';
import { getHiddenEnvironmentOption } from '../../selectors/getHiddenEnvironmentOption.ts';
import {
  AllEnvironmentsTagId,
  getAllEnvironmentOptions,
} from '../../selectors/getSelectedEnvironmentOptions.ts';
import { isSecureAccessAvailable } from '../../selectors/isSecuredDeliveryApiAvailable.ts';
import { DapiKeyFormShape } from '../../validation/dapiKeyValidationConfig.ts';
import { DapiKeyDetailForm } from './DapiKeyDetailForm.tsx';

export const DapiKeyDetail = () => {
  const dispatch = useDispatch();
  const { tokenSeedId } = useParams<ApiKeyDetailRouteParams>();
  const { url } = useRouteMatch();

  const [isInitThunkDone] = useThunkPromise(initApiKey, tokenSeedId, url);

  const loadingStatus = useSelector((state) => state.apiKeysApp.detailLoadingStatus);
  const actionStatus = useSelector((state) => state.apiKeysApp.detailActionStatus);
  const apiKey = useSelector((state) => state.data.apiKeys.keyDetail);
  const onRegenerate = useCallback(
    () => dispatch(modalOpened(ModalDialogType.ApiKeyRegenerationDialog)),
    [],
  );
  const isProjectManager = useSelector(isProjectManagerInAnyEnvironment);
  const currentProject = useSelector(getCurrentProjectContainer);
  const isDapiSecureAccessAvailable = useSelector(isSecureAccessAvailable);
  const allEnvironmentOptions = useSelector((state) =>
    getAllEnvironmentOptions(state, ApiKeyType.DAPI),
  );
  const hiddenEnvironmentOption = useSelector(getHiddenEnvironmentOption);
  const projectContainerActiveUsersById = useSelector(
    (state) => state.data.projectContainerActiveUsers.byId,
  );

  const projectContainerActiveUsers = useMemo(
    () => Collection.getValues(projectContainerActiveUsersById),
    [projectContainerActiveUsersById],
  );

  const getUpdatedApiKey = useCallback(
    (dapiKeyFormShape: DapiKeyFormShape): ApiKey => {
      const hasAccessToAllEnvironments =
        dapiKeyFormShape.environments.includes(AllEnvironmentsTagId);

      return {
        ...emptyApiKey,
        apiKey: apiKey.apiKey,
        environments: hasAccessToAllEnvironments ? [] : dapiKeyFormShape.environments,
        expiresAt: apiKey.expiresAt,
        hasAccessToAllEnvironments,
        hasPreviewDeliveryAccess: dapiKeyFormShape.access.hasPreviewDeliveryAccess,
        hasSecureDeliveryAccess: dapiKeyFormShape.access.hasSecureDeliveryAccess,
        name: dapiKeyFormShape.name,
        sharedWithUsers: dapiKeyFormShape.sharedWithUsers,
        tokenSeedId: apiKey.tokenSeedId,
        type: apiKey.type,
      };
    },
    [apiKey.apiKey, apiKey.expiresAt, apiKey.tokenSeedId, apiKey.type],
  );

  const onSave = useCallback(
    async (dapiKeyFormShape: DapiKeyFormShape): Promise<void> => {
      await dispatch(updateApiKey(getUpdatedApiKey(dapiKeyFormShape)));
    },
    [getUpdatedApiKey],
  );

  const unsavedNavigationHandler = useCallback(
    async (
      onSuccess: () => void,
      onFail: () => void,
      dapiKeyFormShape: DapiKeyFormShape,
    ): Promise<void> => {
      try {
        await dispatch(updateApiKey(getUpdatedApiKey(dapiKeyFormShape)));
        onSuccess();
      } catch {
        onFail();
      }
    },
    [getUpdatedApiKey],
  );

  const acceptableApiKeyTypes = [...DapiKeyTypes, emptyApiKey.type];
  if (
    !isInitThunkDone ||
    loadingStatus !== LoadingStatus.Loaded ||
    !acceptableApiKeyTypes.includes(apiKey.type)
  ) {
    return <Loader />;
  }

  const isLoadingFailed =
    loadingStatus === LoadingStatus.Loaded && actionStatus === ApiKeyActionStatus.LoadingFailed;

  return (
    <AuthorizedSection
      appName={ApiKeyDetailAccessDeniedRouteSegment}
      isAuthorized={!isLoadingFailed}
      projectId={currentProject.masterEnvironmentId}
      projectContainerId={currentProject.projectContainerId}
    >
      <DapiKeyDetailForm
        onUnsavedNavigation={unsavedNavigationHandler}
        apiKey={apiKey}
        apiKeyActionStatus={actionStatus}
        allEnvironmentOptions={allEnvironmentOptions}
        hiddenEnvironmentOption={hiddenEnvironmentOption}
        isProjectManager={isProjectManager}
        isSecureAccessAvailable={isDapiSecureAccessAvailable}
        projectContainerActiveUsers={projectContainerActiveUsers}
        onAlertDismiss={() => dispatch(apiKeyDetailsAlertDismissed())}
        onRegenerate={onRegenerate}
        onSave={onSave}
      />
    </AuthorizedSection>
  );
};
