import { MultiSelect } from '@kontent-ai/component-library/MultiSelect';
import { GeneralTag, IBaseSelectItem } from '@kontent-ai/component-library/Selects';
import { ComponentProps, useMemo } from 'react';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ICollection } from '../../../../../data/models/collections/Collection.ts';
import { useIsRefinedNavigationFeatureEnabled } from '../../../../refinedNavigation/contexts/RefinedNavigationContext.tsx';
import { notInCollectionSelectItem } from '../../../assets/constants/assetNullCollectionConstants.ts';

interface IProps {
  readonly collections: ReadonlyArray<ICollection>;
  readonly selectedCollections: ReadonlyArray<Uuid | null>;
  readonly onFilterChanged: (selectedCollections: ReadonlyArray<Uuid | null>) => void;
}

const toSelectOption = (collection: ICollection): IBaseSelectItem => ({
  id: collection.id,
  label: collection.name,
});

type GeneralTagProps = Pick<
  ComponentProps<typeof MultiSelect>,
  'generalTagThreshold' | 'renderGeneralTag'
>;

export const ConfigurableCollectionMultipleOptionSelect = ({
  collections,
  selectedCollections,
  onFilterChanged,
}: IProps) => {
  const items = useMemo(
    () => [notInCollectionSelectItem, ...collections.map(toSelectOption)],
    [collections],
  );

  const selectedCollectionIds = useMemo(
    () =>
      selectedCollections
        .filter((id) => id === null || collections.some((collection) => collection.id === id))
        .map((id) => id ?? notInCollectionSelectItem.id),
    [collections, selectedCollections],
  );

  const isRefinedNavigationFeatureEnabled = useIsRefinedNavigationFeatureEnabled();
  const refinedNavigationFeatureProps = isRefinedNavigationFeatureEnabled
    ? ({
        generalTagThreshold: 3,
        renderGeneralTag: (count, defaultTagProps) => (
          <GeneralTag
            countPlacement="before"
            countValue={count}
            label="Collections"
            {...defaultTagProps}
          />
        ),
      } satisfies GeneralTagProps)
    : {};

  return (
    <MultiSelect
      aria-label="Select a collection"
      placeholder="Select a collection"
      items={items}
      selectedItemIds={selectedCollectionIds}
      onSelectionChange={(selectedIds) =>
        onFilterChanged(
          Array.from(selectedIds).map((id) => (id !== notInCollectionSelectItem.id ? id : null)),
        )
      }
      {...refinedNavigationFeatureProps}
      {...getDataUiCollectionAttribute(DataUiCollection.ContentCollections)}
    />
  );
};
