import { ISitemap } from '../../../../data/models/contentModelsApp/sitemap/Sitemap.ts';
import {
  Sitemap_AddCollapsedNode,
  Sitemap_CancelNodeEditing,
  Sitemap_HideCreateNewForm,
  Sitemap_NotificationBar_Hide,
  Sitemap_RemoveCollapsedNode,
  Sitemap_RollbackSitemap,
  Sitemap_SavingFailed,
  Sitemap_SelectEditedNode,
  Sitemap_ShowCreateNewForm,
  Sitemap_StartDraggingNode,
} from '../constants/sitemapActionTypes.ts';

export const startEditingNode = (id: Uuid) =>
  ({
    type: Sitemap_SelectEditedNode,
    payload: {
      id,
    },
  }) as const;

export const sitemapSavingFailed = (errorMessage: string) =>
  ({
    type: Sitemap_SavingFailed,
    payload: {
      errorMessage,
    },
  }) as const;

export const cancelNodeEditing = (nodeId: Uuid) =>
  ({
    type: Sitemap_CancelNodeEditing,
    payload: {
      nodeId,
    },
  }) as const;

export const rollbackSitemap = (sitemap: ISitemap) =>
  ({
    type: Sitemap_RollbackSitemap,
    payload: {
      sitemap,
    },
  }) as const;

export const startDraggingNode = (nodeId: Uuid) =>
  ({
    type: Sitemap_StartDraggingNode,
    payload: {
      nodeId,
    },
  }) as const;

export const addCollapsedNode = (nodeId: Uuid) =>
  ({
    type: Sitemap_AddCollapsedNode,
    payload: {
      nodeId,
    },
  }) as const;

export const removeCollapsedNode = (nodeId: Uuid) =>
  ({
    type: Sitemap_RemoveCollapsedNode,
    payload: {
      nodeId,
    },
  }) as const;

export const showCreateNewForm = () =>
  ({
    type: Sitemap_ShowCreateNewForm,
  }) as const;

export const hideCreateNewForm = () =>
  ({
    type: Sitemap_HideCreateNewForm,
  }) as const;

export const hideNotificationBar = () =>
  ({
    type: Sitemap_NotificationBar_Hide,
  }) as const;
