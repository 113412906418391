import { memoize } from '@kontent-ai/memoization';
import { Collection } from '@kontent-ai/utils';
import { getWorkflowStepsById } from '../../../../../../_shared/selectors/workflowSelectors.ts';
import { isArchivedWorkflowStep } from '../../../../../../_shared/utils/contentItemUtils.ts';
import { isVariantPublished } from '../../../../../../_shared/utils/contentItemVariantUtils.ts';
import { IListingContentItem } from '../../../../../../data/models/listingContentItems/IListingContentItem.ts';
import { IListingVariantData } from '../../../../../../data/models/listingContentItems/IListingVariantData.ts';
import { Workflow } from '../../../../../../data/models/workflow/Workflow.ts';
import { IWorkflowStep } from '../../../../../../data/models/workflow/WorkflowStep.ts';
import { EditedContentItemVariant } from '../../../../../itemEditor/models/contentItem/edited/EditedContentItemVariant.ts';
import { IItemListingBulkActionsCheck } from '../../../models/ItemListingBulkActionStatus.ts';

const getSelectableWorkflowSteps = (
  variantData: EditedContentItemVariant | IListingVariantData | null,
  workflow: Workflow | null,
): ReadonlyArray<IWorkflowStep> => {
  if (!workflow || !variantData) {
    return [];
  }

  const originalWorkflowStep = variantData.assignment.workflowStatus;
  const allWorkflowSteps = getWorkflowStepsById(workflow);
  const selectableSteps = getExistingSteps(originalWorkflowStep.transitionsTo, allWorkflowSteps);
  const isPublished = isVariantPublished(variantData);

  if (isPublished) {
    return selectableSteps.filter((s) => !isArchivedWorkflowStep(s));
  }

  return selectableSteps;
};

export const getSelectableWorkflowStepsForEditedItemDefaultVariant = memoize.maxOne(
  (
    listingItemWithDefaultVariant: IListingContentItem | null,
    workflow: Workflow | null,
  ): ReadonlyArray<IWorkflowStep> =>
    getSelectableWorkflowSteps(listingItemWithDefaultVariant?.variant ?? null, workflow),
);

export const getSelectableWorkflowStepsForEditedVariant = memoize.maxOne(
  (
    editedContentItemVariant: EditedContentItemVariant | null,
    workflow: Workflow | null,
  ): ReadonlyArray<IWorkflowStep> => getSelectableWorkflowSteps(editedContentItemVariant, workflow),
);

export const getSelectableWorkflowStepsForBulk = memoize.maxOne(
  (
    bulkActionCheckResult: IItemListingBulkActionsCheck | null,
    workflow: Workflow | undefined,
  ): readonly IWorkflowStep[] => {
    if (!bulkActionCheckResult || !workflow) {
      return [];
    }

    const allWorkflowSteps = getWorkflowStepsById(workflow);
    const selectableStepIds = bulkActionCheckResult.updateWorkflowStep.availableTransitions;
    return getExistingSteps(selectableStepIds, allWorkflowSteps);
  },
);

const getExistingSteps = memoize.maxOne(
  (
    stepIds: ReadonlySet<Uuid>,
    allWorkflowSteps: ReadonlyMap<Uuid, IWorkflowStep>,
  ): readonly IWorkflowStep[] => {
    return Collection.getValues(allWorkflowSteps).filter((step: IWorkflowStep) =>
      stepIds.has(step.id),
    );
  },
);
