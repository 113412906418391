import { Button, QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { FC } from 'react';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type Props = {
  readonly isQuinary?: boolean;
  readonly isSidebarOpen: boolean;
  readonly isSidebarToggleActive: boolean;
  readonly onSidebarToggleClick: (isCollapsed: boolean) => void;
};

export const ContentDetailsButton: FC<Props> = ({
  isQuinary,
  isSidebarOpen,
  isSidebarToggleActive,
  onSidebarToggleClick,
}) => {
  return isQuinary ? (
    <QuinaryButton
      activated={isSidebarToggleActive}
      onClick={() => onSidebarToggleClick(isSidebarOpen)}
      tooltipText="Content details"
      {...getDataUiActionAttribute(DataUiAction.OpenContentItemSidebar)}
    >
      <QuinaryButton.Icon icon={Icons.ICircle} />
    </QuinaryButton>
  ) : (
    <Button
      activated={isSidebarToggleActive}
      buttonStyle="secondary"
      onClick={() => onSidebarToggleClick(isSidebarOpen)}
      {...getDataUiActionAttribute(DataUiAction.OpenContentItemSidebar)}
    >
      <Button.Icon icon={Icons.ICircle} />
      Content details
    </Button>
  );
};
