import { ValidationConstants } from '../../../../../_shared/constants/validationConstants.ts';
import { FormValidatorConfig } from '../../../../../_shared/utils/validation/createFormValidationResolver.ts';
import { nonEmptyValidationBuilder } from '../../../../../_shared/utils/validation/isEmptyOrWhitespace.ts';
import { uniquenessValidationBuilder } from '../../../../../_shared/utils/validation/isPropertyUnique.ts';
import { isShorterThanValidationBuilder } from '../../../../../_shared/utils/validation/isShorterThan.ts';
import { AiGuidelines } from '../../../data/models/aiGuidelines.ts';
import { AiGuidelinesFormShape } from '../types/AiGuidelinesFormShape.type.ts';

type AiGuidelinesValidationProps = {
  readonly existingAiGuidelines: ReadonlyArray<AiGuidelines>;
};

const shorterThan = isShorterThanValidationBuilder(ValidationConstants.AiGuidelinesMaxLength);
const nameUnique = uniquenessValidationBuilder<AiGuidelinesValidationProps, AiGuidelines>(
  'name',
  (aiGuidelines) => aiGuidelines.existingAiGuidelines,
  (aiGuidelines) => aiGuidelines.name,
);

export const aiGuidelinesValidationConfig: FormValidatorConfig<
  AiGuidelinesFormShape,
  AiGuidelinesValidationProps
> = {
  name: [nonEmptyValidationBuilder('name'), nameUnique],
  guidelines: [nonEmptyValidationBuilder('guidelines'), shorterThan],
};
