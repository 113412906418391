import React from 'react';
import { useLocation } from 'react-router';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { getSelectedLanguageId } from '../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { getCurrentProjectId } from '../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { getContentItemPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { useLivePreviewPreferenceStorage } from '../../../../../localStorages/useLivePreviewPreferenceStorage.ts';
import { ParentItemRow as ParentItemRowComponent } from '../components/ParentItemRow.tsx';

export const ParentItemRow: React.FC = () => {
  const currentProjectId = useSelector(getCurrentProjectId);
  const { isLivePreviewPreferred } = useLivePreviewPreferenceStorage(currentProjectId);
  const selectedLanguageId = useSelector(getSelectedLanguageId) ?? undefined;
  const listingContentItem = useSelector((s) =>
    s.data.listingContentItems.byId.get(s.contentApp.editedContentItem?.id || ''),
  );
  const itemType = useSelector((s) =>
    s.data.contentTypes.byId.get(listingContentItem?.item.typeId || ''),
  );

  const location = useLocation();

  if (!listingContentItem || !itemType) {
    return null;
  }

  return (
    <ParentItemRowComponent
      item={listingContentItem}
      itemType={itemType}
      itemPath={getContentItemPath(
        location.pathname,
        listingContentItem.item.id,
        isLivePreviewPreferred,
      )}
      selectedLanguageId={selectedLanguageId}
    />
  );
};
