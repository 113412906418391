import { Icons } from '@kontent-ai/component-library/Icons';
import { Menu } from '@kontent-ai/component-library/Menu';
import { useWebSpotlightInItemEditing } from '../../context/WebSpotlightInItemEditingContext.tsx';

export const ToggleAutoRefreshPreview = () => {
  const { isAutoRefreshEnabledByUser, setIsAutoRefreshEnabledByUser } =
    useWebSpotlightInItemEditing();

  const toggleAutoRefresh = isAutoRefreshEnabledByUser
    ? () => setIsAutoRefreshEnabledByUser(false)
    : () => setIsAutoRefreshEnabledByUser(true);

  return isAutoRefreshEnabledByUser ? (
    <Menu.Item
      id="disable-autorefresh"
      label="Disable autorefresh"
      leadingElement={<Icons.RotateDoubleRightSlash />}
      onAction={toggleAutoRefresh}
    />
  ) : (
    <Menu.Item
      id="enable-autorefresh"
      label="Enable autorefresh"
      leadingElement={<Icons.RotateDoubleRightA />}
      onAction={toggleAutoRefresh}
    />
  );
};
