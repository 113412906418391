import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { Button } from '@kontent-ai/component-library/Button';
import { Card } from '@kontent-ai/component-library/Card';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { InnovationLabActivationState } from '../types/InnovationLabActivationState.type.ts';
import { InnovationLabFeatureAccess } from '../types/InnovationLabFeatureAccess.ts';
import { InnovationLabFeatureCardWrapper } from './InnovationLabFeatureCardWrapper.tsx';
import { InnovationLabFeatureGenericallyUnavailable } from './InnovationLabFeatureGenericallyUnavailable.tsx';
import { InnovationLabStatus } from './InnovationLabFeatureStatus.tsx';

type Props = {
  readonly activationState: InnovationLabActivationState;
  readonly featureName: string;
  readonly featureState: InnovationLabFeatureAccess;
  readonly onCardActionButtonClick?: () => void;
  readonly onContactSupport: () => void;
  readonly isButtonDisabled: boolean;
};

export const SecureAssetsCard = ({
  activationState,
  featureName,
  featureState,
  onContactSupport,
  isButtonDisabled,
  onCardActionButtonClick,
}: Props) => (
  <InnovationLabFeatureCardWrapper featureState={featureState}>
    <Card component="section">
      <Card.Headline
        renderAuxiliaryElements={() => (
          /*
           * Note that support needs to mark the feature as enabled in the portal once it is set up on our side and the key is sent to customer
           */
          <InnovationLabStatus activationState={activationState} />
        )}
      >
        {featureName}
      </Card.Headline>

      <Card.Body>
        <Stack spacing={Spacing.M}>
          <p>
            Do you manage confidential assets or run an intranet portal?{' '}
            <b>Keep your assets secure and private.</b>
          </p>
          <p>
            <OutwardLink href="https://kontent.ai/learn/docs/security/secure-access/javascript#a-retrieve-assets-securely">
              Restrict access to your assets
            </OutwardLink>{' '}
            by requiring an API key. Once set up, only the apps that use your API key can fetch your
            assets.
          </p>
          <InnovationLabFeatureGenericallyUnavailable
            activationState={activationState}
            featureState={featureState}
            onContactSupport={onContactSupport}
          />
        </Stack>
      </Card.Body>
      <Card.Footer>
        <Button
          buttonStyle="tertiary"
          disabled={isButtonDisabled}
          onClick={onCardActionButtonClick}
          size="small"
          tooltipText={
            isButtonDisabled
              ? 'This feature is currently unavailable.'
              : 'You need to contact us to get this feature activated.'
          }
        >
          <Button.Label>Contact support</Button.Label>
        </Button>
      </Card.Footer>
    </Card>
  </InnovationLabFeatureCardWrapper>
);
