import { InvariantException } from '@kontent-ai/errors';
import { delay } from '@kontent-ai/utils';
import { Dispatch, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { DateTime } from '../../../../../_shared/models/DateTime.ts';
import { IApiKeysRepository } from '../../../../../repositories/interfaces/IApiKeysRepository.type.ts';
import {
  ApiKey,
  createApiKeyFromServerModel,
} from '../../../../projectSettings/apiKeys/models/ApiKey.ts';
import {
  SubscriptionApiKey_GenerationStatus_Reset,
  SubscriptionApiKey_Regeneration_Failed,
  SubscriptionApiKey_Regeneration_Finished,
  SubscriptionApiKey_Regeneration_Started,
} from '../../../shared/constants/subscriptionManagementActionTypes.ts';

const started = () =>
  ({
    type: SubscriptionApiKey_Regeneration_Started,
  }) as const;

const failed = () =>
  ({
    type: SubscriptionApiKey_Regeneration_Failed,
  }) as const;

const succeed = (apiKey: ApiKey) =>
  ({
    type: SubscriptionApiKey_Regeneration_Finished,
    payload: { apiKey },
  }) as const;

const statusReset = () =>
  ({
    type: SubscriptionApiKey_GenerationStatus_Reset,
  }) as const;

export type RegenerateSubscriptionApiKeyActionsType = ReturnType<
  typeof started | typeof failed | typeof succeed | typeof statusReset
>;

interface IRegenerateSubscriptionApiKeyDependencies {
  readonly apiKeysRepository: Pick<IApiKeysRepository, 'regenerateSubscriptionApiKey'>;
}

export const regenerateSubscriptionApiKeyActionCreator =
  ({ apiKeysRepository }: IRegenerateSubscriptionApiKeyDependencies) =>
  (subscriptionId: Uuid, tokenSeedId: Uuid, expirationDate: DateTime): ThunkPromise =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(started());
    try {
      const serverModel = await apiKeysRepository.regenerateSubscriptionApiKey(
        subscriptionId,
        tokenSeedId,
        { expires_at: expirationDate.value },
      );
      dispatch(succeed(createApiKeyFromServerModel(serverModel)));
      await delay(1000);
      dispatch(statusReset());
    } catch (error) {
      dispatch(failed());

      throw InvariantException(
        `Could not regenerate subscription api key because of the following error: ${JSON.stringify(
          error,
        )}`,
      );
    }
  };
