import { Typography } from '@kontent-ai/component-library/tokens';
import styled from 'styled-components';

export const StyledInfoBarMetadataDivider = styled.div`
  ${Typography.Subheadline};
  text-align: center;
  &::after {
    content: "\\00a0|\\00a0";
  }
`;
