import { assert } from '@kontent-ai/utils';
import React, { useContext, useMemo } from 'react';
import {
  DefaultCollectionId,
  DefaultVariantId,
} from '../../../../../_shared/constants/variantIdValues.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { ContentItemId } from '../../../../../_shared/models/ContentItemId.ts';
import { MissingItemId } from '../../../../../_shared/models/utils/contentItemIdUtils.ts';
import { getEditedContentItemVariantId } from '../../../../../_shared/selectors/getEditedContentItemVariant.ts';
import { untitledContentItem } from '../../../../contentInventory/content/constants/uiConstants.ts';
import { LanguageContextProvider } from './LanguageContext.tsx';

type IContentItemContextState = ContentItemId & {
  readonly itemCodename: string;
  readonly itemName: string;
  readonly itemCollectionId: Uuid;
};

const MissingCodename = '';

const defaultContext: IContentItemContextState = {
  itemId: MissingItemId,
  variantId: DefaultVariantId,
  itemCodename: MissingCodename,
  itemCollectionId: DefaultCollectionId,
  itemName: untitledContentItem,
};

/**
 * This context is primarily used to solve nesting – where there are multiple items rendered in one another (i.e. in linked items)
 * and most inner item IDs are required (e.g. in custom elements).
 *
 * **Also, make sure you absolutely need all the properties of this context!!!** The use of this context can cause performance problems.
 * If you need only item and/or variant ID, then use `useItemVariantId`.
 */
const ContentItemContext = React.createContext<IContentItemContextState>(defaultContext);

export const useContentItemInCustomElement = () => {
  const value = useContext(ContentItemContext);
  assert(value, () => `${ContentItemContext.displayName} is missing in the current tree`);
  return value;
};

export const useItemVariantId = () => {
  const itemVariantId = useContext(ContentItemVariantIdContext);
  assert(
    itemVariantId,
    () => `${ContentItemVariantIdContext.displayName} is missing in the current tree`,
  );
  return itemVariantId;
};

interface IContentItemContextProviderProps {
  readonly children: React.ReactNode | ReadonlyArray<React.ReactNode>;
  readonly itemCodename: string | null | undefined;
  readonly itemName: string | null | undefined;
  readonly itemId: Uuid;
  readonly itemCollectionId: Uuid | undefined;
  readonly variantId: Uuid | null | undefined;
}

export const ContentItemContextProvider: React.FC<IContentItemContextProviderProps> = ({
  children,
  itemCodename: nullableCodename,
  itemId,
  itemName: nullableName,
  variantId: nullableVariantId,
  itemCollectionId: nullableCollectionId,
}) => {
  const variantId = nullableVariantId || defaultContext.variantId;
  const itemCodename = nullableCodename || defaultContext.itemCodename;
  const itemName = nullableName || defaultContext.itemName;
  const itemCollectionId = nullableCollectionId || defaultContext.itemCollectionId;

  const contextState = useMemo<IContentItemContextState>(
    () => ({ itemId, variantId, itemCodename, itemCollectionId, itemName }),
    [itemId, variantId, itemCodename, itemCollectionId, itemName],
  );

  return (
    <ContentItemContext.Provider value={contextState}>
      <ContentItemVariantIdContextProvider itemId={itemId} variantId={variantId}>
        {children}
      </ContentItemVariantIdContextProvider>
    </ContentItemContext.Provider>
  );
};

ContentItemContextProvider.displayName = 'ContentItemContextProvider';

const ContentItemVariantIdContextProvider: React.FC<React.PropsWithChildren<ContentItemId>> = ({
  children,
  itemId,
  variantId,
}) => {
  const itemVariantId: ContentItemId = useMemo(() => ({ itemId, variantId }), [itemId, variantId]);

  return (
    <ContentItemVariantIdContext.Provider value={itemVariantId}>
      {children}
    </ContentItemVariantIdContext.Provider>
  );
};

ContentItemVariantIdContextProvider.displayName = 'ContentItemVariantIdContextProvider';

const defaultContentItemVariantIdContext: ContentItemId = {
  itemId: MissingItemId,
  variantId: DefaultVariantId,
};

const ContentItemVariantIdContext = React.createContext<ContentItemId>(
  defaultContentItemVariantIdContext,
);

export const EditedContentItemContextProvider: React.FC<React.PropsWithChildren<NoProps>> = ({
  children,
}) => {
  const itemId = useSelector(getEditedContentItemVariantId);
  const editedItem = useSelector((state) => state.contentApp.editedContentItem);

  return (
    <ContentItemContextProvider
      itemId={itemId?.itemId || defaultContext.itemId}
      variantId={itemId?.variantId}
      itemCodename={editedItem?.codename || defaultContext.itemCodename}
      itemCollectionId={editedItem?.collectionId || defaultContext.itemCollectionId}
      itemName={editedItem?.name || defaultContext.itemName}
    >
      <LanguageContextProvider languageId={itemId?.variantId ?? DefaultVariantId}>
        {children}
      </LanguageContextProvider>
    </ContentItemContextProvider>
  );
};

EditedContentItemContextProvider.displayName = 'EditedContentItemContextProvider';
