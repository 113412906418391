import { Box } from '@kontent-ai/component-library/Box';
import { Input, InputType } from '@kontent-ai/component-library/Input';
import { ShortcutsConfig, useHotkeys } from '@kontent-ai/component-library/hooks';
import { gridUnit } from '@kontent-ai/component-library/tokens';
import { clamp } from '@kontent-ai/utils';
import { ReactNode, useRef, useState } from 'react';
import {
  DataUiInput,
  getDataUiInputAttribute,
} from '../../../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

const inputMaxWidth = gridUnit * 14;

type Props = Readonly<{
  dataUiInput: DataUiInput;
  icon: ReactNode;
  initialValue: number;
  label: string;
  max: number;
  min: number;
  onChange: () => void;
  onSubmit: (value: number) => void;
}>;

export const PreviewSizeInput = ({
  dataUiInput,
  icon,
  initialValue,
  label,
  max,
  min,
  onChange,
  onSubmit,
}: Props) => {
  const [value, setValue] = useState(initialValue);
  const [isEditing, setIsEditing] = useState(false);

  const submit = () => {
    const newValue = clamp(value, min, max);

    setValue(newValue);
    onSubmit(newValue);
  };

  const inputRef = useRef(null);
  useHotkeys(
    {
      [ShortcutsConfig.Enter]: submit,
    },
    { ref: inputRef },
  );

  return (
    <Box maxWidth={inputMaxWidth}>
      <Input
        aria-label={label}
        max={max}
        min={min}
        onBlur={() => {
          setIsEditing(false);
          submit();
        }}
        onFocus={() => {
          setIsEditing(true);
          setValue(initialValue);
        }}
        onChange={(event) => {
          setValue(event.target.value ? Number.parseInt(event.target.value, 10) : 0);
          onChange();
        }}
        prefix={icon}
        ref={inputRef}
        step={1}
        type={InputType.Number}
        value={(isEditing ? value : initialValue).toFixed(0)}
        {...getDataUiInputAttribute(dataUiInput)}
      />
    </Box>
  );
};
