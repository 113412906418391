import { IBaseSelectItem } from '@kontent-ai/component-library/Selects';
import { Collection } from '@kontent-ai/utils';
import { useMemo } from 'react';
import { useDataSelector } from '../../../../../../../_shared/hooks/useDataSelector.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { getCurrentProject } from '../../../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { DataUiCollection } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IUser } from '../../../../../../../data/reducers/user/IUser.type.ts';
import { getAvailableSpacesForUser } from '../../../../../../itemEditor/features/ContentItemEditing/utils/getAvailableSpacesForUser.ts';
import { ListingFilterCategorySelector } from '../../components/ListingFilterCategorySelector.tsx';

type Props = {
  readonly onSpaceSelectionChanged: (ids: ReadonlySet<Uuid>) => void;
  readonly selectedLanguageId: Uuid;
  readonly selectedSpaces: ReadonlySet<Uuid>;
  readonly user: IUser;
};

export const SpacesFilterSelector = (props: Props) => {
  const { projectId } = useSelector(getCurrentProject);
  const collectionsById = useDataSelector((data) => data.collections.byId);
  const collections = Collection.getValues(collectionsById);
  const spacesById = useDataSelector((data) => data.spaces.byId);
  const spaces = Collection.getValues(spacesById);

  const availableSpaces = getAvailableSpacesForUser(
    props.selectedLanguageId,
    projectId,
    spaces,
    collections,
    props.user,
  );

  const selectedOptionIds: ReadonlySet<Uuid> = useMemo(
    () =>
      new Set(
        availableSpaces
          .filter((space) => props.selectedSpaces.has(space.id))
          .map((space) => space.id),
      ),
    [availableSpaces, props.selectedSpaces],
  );

  const options: ReadonlyArray<IBaseSelectItem> = availableSpaces.map((space) => ({
    id: space.id,
    label: space.name,
  }));

  return availableSpaces.length ? (
    <ListingFilterCategorySelector
      options={options}
      onChange={props.onSpaceSelectionChanged}
      collection={DataUiCollection.Spaces}
      placeholder={label}
      ariaLabel={label}
      selectedOptionIds={selectedOptionIds}
      title="Space"
    />
  ) : null;
};

const label = 'Select a space';
