import React from 'react';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { isWebSpotlightIntegrationEnabled } from '../../../../../../../_shared/selectors/isWebSpotlightIntegrationEnabled.tsx';
import {
  getCurrentProject,
  isProjectTrial,
} from '../../../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { IStore } from '../../../../../../../_shared/stores/IStore.type.ts';
import {
  urlStartsWithHttp,
  urlStartsWithHttps,
} from '../../../../../../../_shared/utils/urlUtils.ts';
import { PreviewError } from '../../../../../utils/previewUtils.ts';
import { PreviewContentItemInfo } from '../../../../../utils/selectDeepestItemWithPreview.ts';
import { PreviewErrors, ProjectType } from '../components/PreviewErrors.tsx';
import { WebSpotlightPreviewSandboxInItemEditing } from '../components/WebSpotlightPreviewSandboxInItemEditing.tsx';
import {
  PreviewMetadata,
  useWebSpotlightInItemEditing,
} from '../context/WebSpotlightInItemEditingContext.tsx';

type Props = Readonly<{
  previewContentItemInfo: PreviewContentItemInfo | null;
  sharedPreviewUrl: string | null;
  spaceId: Uuid | null;
}>;

export const WebSpotlightPreviewInItemEditingContent: React.FC<Props> = ({
  previewContentItemInfo,
  sharedPreviewUrl,
  spaceId,
}) => {
  const { previewMetadata } = useWebSpotlightInItemEditing();
  const previewError = getNormalizedPreviewError(previewMetadata, sharedPreviewUrl);
  const projectType = useSelector(getProjectType);
  const enabledWSLIntegration = useSelector(isWebSpotlightIntegrationEnabled);

  return previewError ? (
    <PreviewErrors
      hideDocumentationLink={!!sharedPreviewUrl}
      previewContentItemInfo={previewContentItemInfo}
      previewError={previewError}
      projectType={projectType}
      spaceId={spaceId}
    />
  ) : (
    <WebSpotlightPreviewSandboxInItemEditing isResizable={enabledWSLIntegration} />
  );
};

const getProjectType = (state: IStore): ProjectType => {
  const currentProject = getCurrentProject(state);

  if (isProjectTrial(state, currentProject.projectId)) {
    return ProjectType.trial;
  }

  return ProjectType.general;
};

const sharedPreviewUrlExcludedErrors: ReadonlyArray<PreviewError> = [
  PreviewError.MissingUrlSlugValue,
  PreviewError.MissingUrlSlugElement,
];

const getNormalizedPreviewError = (
  previewMetadata: PreviewMetadata,
  sharedPreviewUrl: string | null,
): PreviewError | null => {
  const { error: previewError, url: previewUrl } = previewMetadata;

  const isExcludedError =
    sharedPreviewUrl && previewError && sharedPreviewUrlExcludedErrors.includes(previewError);
  if (previewError && !isExcludedError) {
    return previewError;
  }

  if (!sharedPreviewUrl && !previewUrl) {
    return PreviewError.NoPreview;
  }

  const previewUrlStartsWithHttp = sharedPreviewUrl
    ? urlStartsWithHttp(sharedPreviewUrl)
    : urlStartsWithHttp(previewUrl);

  if (previewUrlStartsWithHttp) {
    return PreviewError.HttpInIFrame;
  }

  const selectedPreviewUrlStartsWithHttps = sharedPreviewUrl
    ? urlStartsWithHttps(sharedPreviewUrl)
    : urlStartsWithHttps(previewUrl);

  if (urlStartsWithHttps(document.location.href) && !selectedPreviewUrlStartsWithHttps) {
    return PreviewError.NotHttps;
  }

  return null;
};
