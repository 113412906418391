import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../../@types/Dispatcher.type.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { IContentGroup } from '../../../../contentInventory/content/models/contentTypeElements/types/ContentGroup.ts';
import { getTypeElementsInContentGroup } from '../../../shared/utils/typeElementsUtils.ts';
import {
  selectedTypeElementsSentToContentGroup,
  selectedTypeElementsSentToNewContentGroup,
} from '../../actions/contentTypesActions.ts';
import {
  ContentGroupSelectionBar as ContentGroupSelectionBarComponent,
  ContentGroupSelectionBarProps,
  IContentGroupSelectionBarStateProps,
} from '../../components/ContentGroups/ContentGroupSelectionBar.tsx';

interface IStateProps extends IContentGroupSelectionBarStateProps {
  readonly selectedTypeElementIds: UuidArray;
}

interface IDispatchProps {
  readonly onSendToContentGroup: (selectedTypeElementIds: UuidArray, contentGroupId: Uuid) => void;
  readonly onSendToNewContentGroup: (
    selectedTypeElementIds: UuidArray,
    contentGroupName: string,
  ) => void;
}

const mapStateToProps = (state: IStore): IStateProps => {
  const {
    typeEditor: { editedType },
    contentTypes: {
      editor: { selectedContentGroupId, selectedTypeElementIds },
    },
  } = state.contentModelsApp;

  const elementsInSelectedGroup =
    selectedContentGroupId &&
    getTypeElementsInContentGroup(editedType.typeElements, selectedContentGroupId);
  const destinationContentGroups = editedType.contentGroups.filter(
    (group: IContentGroup) => group.id !== selectedContentGroupId,
  );

  return {
    destinationContentGroups,
    typeElementCount: elementsInSelectedGroup?.length ?? 0,
    selectedTypeElementCount: selectedTypeElementIds.length,
    selectedTypeElementIds,
    contentTypeGroupsCount: editedType.contentGroups.length,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  onSendToContentGroup: (selectedTypeElementIds: Array<Uuid>, contentGroupId: Uuid) =>
    dispatch(selectedTypeElementsSentToContentGroup(selectedTypeElementIds, contentGroupId)),
  onSendToNewContentGroup: (selectedTypeElementIds: Array<Uuid>, contentGroupName: string) =>
    dispatch(selectedTypeElementsSentToNewContentGroup(selectedTypeElementIds, contentGroupName)),
});

const mergeProps = (
  stateProps: IStateProps,
  dispatchProps: IDispatchProps,
): ContentGroupSelectionBarProps => {
  const { selectedTypeElementIds } = stateProps;

  return {
    ...stateProps,
    onSendToContentGroup: (contentGroupId: Uuid) =>
      dispatchProps.onSendToContentGroup(selectedTypeElementIds, contentGroupId),
    onSendToNewContentGroup: (contentGroupName: string) =>
      dispatchProps.onSendToNewContentGroup(selectedTypeElementIds, contentGroupName),
  };
};

export const ContentGroupSelectionBar: React.ComponentType = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(ContentGroupSelectionBarComponent);
