import React from 'react';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { moveNode } from '../actions/thunkSitemapActions.ts';
import {
  ISitemapNodeOwnProps,
  SitemapNode as SitemapNodeComponent,
} from '../components/SitemapNode.tsx';

export const SitemapNode: React.FC<ISitemapNodeOwnProps> = (props) => {
  const isBeingDragged = useSelector(
    (s) => props.isBeingDragged || s.sitemapApp.draggedNodeId === props.nodeId,
  );
  const isBeingEdited = useSelector(
    ({ sitemapApp: { editedNodeId } }) => !!editedNodeId && editedNodeId === props.nodeId,
  );
  const isCollapsed = useSelector((s) => s.sitemapApp.collapsedNodeIds.includes(props.nodeId));
  const nodeData = useSelector((s) => s.sitemapApp.sitemap?.nodes.get(props.nodeId));

  const dispatch = useDispatch();

  return nodeData ? (
    <SitemapNodeComponent
      {...props}
      childNodeComponent={SitemapNode}
      isBeingDragged={isBeingDragged}
      isBeingEdited={isBeingEdited}
      isCollapsed={isCollapsed}
      nodeData={nodeData}
      onNodeMoved={(targetNodeId, direction) => dispatch(moveNode(targetNodeId, direction))}
    />
  ) : null;
};

SitemapNode.displayName = 'SitemapNode';
