import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { ILivePreviewConfiguration } from '../../../../../data/reducers/projectEnvironmentSettings/ILivePreviewConfiguration.type.ts';
import { IProjectEnvironmentSettingsRepository } from '../../../../../repositories/interfaces/IProjectEnvironmentSettingsRepository.type.ts';
import { Environment_LoadLivePreviewConfiguration_Finished } from '../../constants/generalSettingsActionTypes.ts';
import { convertToLivePreviewConfigurationDomainModel } from '../../utils/convertToLivePreviewConfigurationDomainModel.ts';

interface IDependencies {
  readonly projectEnvironmentSettingsRepository: IProjectEnvironmentSettingsRepository;
}

const finished = (livePreviewConfiguration: ILivePreviewConfiguration) =>
  ({
    type: Environment_LoadLivePreviewConfiguration_Finished,
    payload: {
      livePreviewConfiguration,
    },
  }) as const;

export type LoadLivePreviewConfigurationActionsType = ReturnType<typeof finished>;

export const loadLivePreviewConfigurationCreator =
  (deps: IDependencies) =>
  (projectId: Uuid, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    const projectEnvironmentSettings =
      await deps.projectEnvironmentSettingsRepository.getLivePreviewConfiguration(
        projectId,
        abortSignal,
      );
    dispatch(finished(convertToLivePreviewConfigurationDomainModel(projectEnvironmentSettings)));
  };
