//  TODO: If you fully remove this hook, update the HandlingOutsideInteraction story about the integration with `useOverlay`.
import { delay } from '@kontent-ai/utils';
import { RefObject, useCallback, useEffect, useRef } from 'react';
import { useEventListener } from './useEventListener.ts';

type IFrameFocusedCallback = (iFrameElement: HTMLIFrameElement) => void;

const isIFrameElement = (element: Element | null): element is HTMLIFrameElement =>
  element?.tagName === 'IFRAME';

// Clicking into IFRAME doesn't trigger mouse down on the parent document so we need to periodically check change of document.activeElement
// When it changes to IFRAME, we treat it as a click to the IFRAME element and evaluate it the same way as mouse down
class FocusedIFrameObserver {
  private readonly _handlers = new Set<IFrameFocusedCallback>();
  private _isObserving = false;
  private _lastIFrameFocused = false;

  private readonly _onIFrameFocused = (iFrameElement: HTMLIFrameElement) => {
    Array.from(this._handlers).forEach((handler) => handler(iFrameElement));
  };

  private readonly _checkIFrameFocused = () => {
    const activeElement = document.activeElement;
    if (isIFrameElement(activeElement)) {
      if (!this._lastIFrameFocused) {
        this._onIFrameFocused(activeElement);
        this._lastIFrameFocused = true;
      }
    } else {
      this._lastIFrameFocused = false;
    }
  };

  private readonly _deferredCheckIFrameFocused = () => {
    delay(0).then(this._checkIFrameFocused);
  };

  private readonly _startObserving = () => {
    if (!this._isObserving) {
      this._isObserving = true;
      window.addEventListener('focus', this._checkIFrameFocused);
      // We need to defer the blur check as Firefox doesn't have the activeElement updated during the blur yet
      window.addEventListener('blur', this._deferredCheckIFrameFocused);
    }
  };

  private readonly _stopObserving = () => {
    if (this._isObserving) {
      window.addEventListener('focus', this._checkIFrameFocused);
      window.addEventListener('blur', this._deferredCheckIFrameFocused);
      this._isObserving = false;
    }
  };

  subscribe(handler: IFrameFocusedCallback) {
    this._handlers.add(handler);
    this._startObserving();
  }

  unsubscribe(handler: IFrameFocusedCallback) {
    this._handlers.delete(handler);
    if (this._handlers.size === 0) {
      this._stopObserving();
    }
  }
}

const iFrameObserver = new FocusedIFrameObserver();

export type ClickOutsideEvent = {
  readonly target: EventTarget | null;
};

/**
 * @deprecated Use `useOverlay` instead. See https://storybook.global.ssl.fastly.net/?path=/docs/2%C2%B7education-5-%C2%B7-handling-outside-interaction--handling-outside-interaction.
 * Reacts to clicking outside of defined elements. Handles both left and right clicks that were initiated and completed outside.
 * @param observedRefs One or more element refs out of which the click event is triggered.
 * @param onClickOutside Callback with the captured event. It is triggered on each click outside of observed element refs.
 */
export const useOnClickOutside = <TRef extends HTMLElement>(
  observedRefs: OneOrMore<RefObject<TRef>>,
  onClickOutside: (event: ClickOutsideEvent) => void,
): void => {
  const clickStartedInsideRef = useRef<boolean>(false);
  const clickStartedWhileMountedRef = useRef<boolean>(false);

  const onMouseDown = (event: MouseEvent): void => {
    const refs = Array.isArray(observedRefs) ? observedRefs : [observedRefs];
    const clickedInside = refs.some(
      (ref) => !!ref.current && ref.current.contains(event.target as Node),
    );

    clickStartedInsideRef.current = clickedInside;
    clickStartedWhileMountedRef.current = true;
  };

  const onMouseUp = (event: MouseEvent): void => {
    if (clickStartedInsideRef.current) {
      clickStartedInsideRef.current = false;
    } else if (clickStartedWhileMountedRef.current) {
      onClickOutside(event);
    }
    clickStartedWhileMountedRef.current = false;
  };

  useEventListener('mousedown', onMouseDown, window, true);
  useEventListener('mouseup', onMouseUp, window, true);

  // We proxy the onFrameFocus handler with a ref to reduce the subscribe / unsubscribe handling
  const onFrameFocusRef = useRef<(event: ClickOutsideEvent) => void>();
  onFrameFocusRef.current = (event: ClickOutsideEvent): void => {
    const refs = Array.isArray(observedRefs) ? observedRefs : [observedRefs];
    const eventHappenedOutside = refs.every(
      (ref) => !!ref.current && !ref.current.contains(event.target as Node),
    );

    if (eventHappenedOutside) {
      onClickOutside(event);
    }
  };

  const onIFrameFocused = useCallback((iFrameElement: HTMLIFrameElement) => {
    onFrameFocusRef.current?.({
      target: iFrameElement,
    });
  }, []);

  useEffect(() => {
    iFrameObserver.subscribe(onIFrameFocused);
    return () => iFrameObserver.unsubscribe(onIFrameFocused);
  }, [onIFrameFocused]);
};
