import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { logError } from '../../../../_shared/utils/logError.ts';
import {
  IEntityWebhookSetting,
  createEntityWebhookSettingDomainModel,
} from '../../../../data/models/webhooks/EntityWebhookSetting.ts';
import { IEntityWebhookServerModel } from '../../../../repositories/serverModels/IEntityWebhookServerModel.type.ts';
import { EntityWebhooks_EnableWebhook_Finished } from '../../constants/entityWebhooksActionTypes.ts';

interface IDeps {
  readonly entityWebhookRepository: {
    readonly enable: (webhookId: Uuid) => Promise<IEntityWebhookServerModel>;
  };
}

const success = (webhook: IEntityWebhookSetting) =>
  ({
    type: EntityWebhooks_EnableWebhook_Finished,
    payload: { webhook },
  }) as const;

export type EnableEntityWebhookActionsType = ReturnType<typeof success>;

export const createEnableEntityWebhookAction =
  (deps: IDeps) =>
  (webhookId: Uuid): ThunkPromise =>
  async (dispatch) => {
    try {
      const serverModel = await deps.entityWebhookRepository.enable(webhookId);
      const domainModel = createEntityWebhookSettingDomainModel(serverModel);
      dispatch(success(domainModel));
    } catch (e) {
      logError(`Failed to enable webhook with id ${webhookId}.`, e);
      throw e;
    }
  };
