import React from 'react';
import styled from 'styled-components';

const splitLines = (text: string | null): ReadonlyArray<string> => (text || '').split(/[\r\n]/);

const StyledParagraph = styled.p`
  overflow-wrap: anywhere;
  word-break: break-word; // "normal" should be here to work with overflow-wrap desirably, however, due to lack of support in Safari…
`;

type Props = {
  readonly text: string | null;
  readonly className?: string;
};

export const TextToParagraphs = (props: Props) => {
  const lines = splitLines(props.text);
  return (
    <>
      {lines.map((paragraph, position) => (
        <StyledParagraph key={position} className={props.className}>
          {paragraph}
        </StyledParagraph>
      ))}
    </>
  );
};

export const TextToParagraph = (props: Props) => {
  const lines = splitLines(props.text);
  return (
    <StyledParagraph className={props.className}>
      {lines.map((line, position) => {
        const isFirst = !position;
        return (
          <React.Fragment key={position}>
            {!isFirst && <br />}
            {line}
          </React.Fragment>
        );
      })}
    </StyledParagraph>
  );
};
