import { memoize } from '@kontent-ai/memoization';
import { Collection } from '@kontent-ai/utils';
import { detect } from 'detect-browser';
import { EditorFeatureLimitations } from '../../plugins/apiLimitations/api/EditorFeatureLimitations.ts';
import {
  getAllDisallowedTableFeatures,
  getAllDisallowedTopLevelFeatures,
} from '../../plugins/apiLimitations/api/editorLimitationUtils.ts';

export const RichTextContentChangeCallbackDebounce = 300;
export const RichTextSelectionChangeCallbackDebounce = 100;

export const RichTextHighlightUpdateThrottleInterval = 10;

export const RteClassName = 'rte';

export const BlockClassName = 'rte__block';
export const SelectableContainerClassName = 'rte__selectable-container';

export const CustomBlockSleeveClassname = 'rte__custom-block-sleeve';
export const CustomBlockSleeveBeforeClassname = `${CustomBlockSleeveClassname}--is-before`;
export const CustomBlockSleeveAfterClassname = `${CustomBlockSleeveClassname}--is-after`;

export const UnstyledClassName = 'rte__paragraph';
export const TextBlockClassName = 'rte__text-block';
export const EmptyTextBlockClassName = `${TextBlockClassName}--is-empty`;
export const ListItemClassName = 'rte__list-item';
export const TableClassName = 'rte__table';
export const TableCellClassName = 'rte__table-cell';

export const TopLevelBlockClassName = 'rte__top-level-block';
export const LastBlockClassName = 'rte__block--is-last';
export const FirstBlockClassName = 'rte__block--is-first';

const browser = detect();

// Grammarly sets DOM selection and triggers paste with replacement, but the editor doesn't pick up the selection soon enough in Firefox
// It seems that Firefox fires the selectionchange event asynchronously with some delay
// We need to give it a bit of time to process the selectionchange event before handling the actual paste
export const ShouldDeferGrammarlyReplace = browser?.name === 'firefox';

export const getContentOverlayClass = (editorId: string) => {
  return `overlay_${editorId}`;
};

export const getContentOverlayId = (editorId: string, blockKey: string) => {
  return `overlay_${editorId}_${blockKey}`;
};

export type BlockClassNames = ReadonlyRecord<string, boolean>;

export function getEditorIdClassName(editorId: string): string {
  return `rte--id-${editorId}`;
}

export function getBlockIdClassName(blockKey: string): string {
  return `rte__block--id-${blockKey}`;
}

export function getBlockCssSelector(editorId: string, blockKey: string = ''): string {
  // Only highlight in the context of specific editor, as two editors could technically have blocks with the same block key
  return `.rte__content.${getEditorIdClassName(editorId)} .${getBlockIdClassName(blockKey)}`;
}

export const getDisallowedFeaturesClasses = memoize.weak(
  (limitations: EditorFeatureLimitations): ReadonlyArray<string> => {
    const allDisallowedTopLevelFeatures = getAllDisallowedTopLevelFeatures(limitations);
    const disallowedTopLevelFeaturesClasses = Collection.getValues(
      allDisallowedTopLevelFeatures,
    ).map((feature) => `rte__content--${feature}-forbidden`);

    const allDisallowedTableFeatures = getAllDisallowedTableFeatures(limitations);
    const disallowedTableFeaturesClasses = Collection.getValues(allDisallowedTableFeatures).map(
      (feature) => `rte__content--table-${feature}-forbidden`,
    );

    return [...disallowedTopLevelFeaturesClasses, ...disallowedTableFeaturesClasses];
  },
);

export function isRichText(element: Element): boolean {
  return element.classList.contains(RteClassName);
}

export function isContentBlockElement(element: Element): element is HTMLElement {
  return element.classList.contains(BlockClassName);
}
