import React, { useCallback } from 'react';
import { useEditorWithPlugin } from '../../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginComponent } from '../../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../../editorCore/types/Editor.contract.type.ts';
import { Apply } from '../../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../../editorCore/utils/decorable.ts';
import { DraftJsEditorPlugin } from '../../draftJs/DraftJsEditorPlugin.type.ts';
import { EntityDecoratorProps } from '../../entityApi/api/editorEntityUtils.ts';
import { DisplayLinksPlugin, GetLinkEntityComponent } from '../DisplayLinksPlugin.tsx';
import { isContentLink } from '../api/LinkEntity.ts';
import { LinkType } from '../api/LinkType.ts';
import { ContentLink } from './containers/ContentLink.tsx';

type DisplayContentLinksPluginProps = {
  readonly isViewOnly?: boolean;
  readonly elementId?: Uuid;
};

export type DisplayContentLinksPlugin = DraftJsEditorPlugin<
  None,
  DisplayContentLinksPluginProps,
  None,
  None,
  [DisplayLinksPlugin]
>;

const ContentLinkEntity: React.FC<
  React.PropsWithChildren<EntityDecoratorProps<DisplayContentLinksPluginProps>>
> = ({ children, contentState, decoratedText, elementId, entityKey, isViewOnly }) => {
  const entity = contentState.getEntity(entityKey);
  if (!isContentLink(entity)) {
    return children;
  }

  const { itemId } = entity.getData();

  return (
    <ContentLink
      disabled
      elementId={elementId}
      isViewOnly={isViewOnly}
      itemId={itemId}
      key={entityKey}
      text={decoratedText}
    >
      {children}
    </ContentLink>
  );
};

ContentLinkEntity.displayName = 'ContentLinkEntity';

export const DisplayContentLinksPlugin: PluginComponent<DisplayContentLinksPlugin> = (props) => {
  const { elementId, isViewOnly } = props;

  const apply: Apply<DisplayContentLinksPlugin> = useCallback(
    (state) => {
      const getLinkEntityComponent: Decorator<
        GetLinkEntityComponent<DisplayContentLinksPluginProps>
      > = (baseGetLinkEntityComponent) => (linkType) => {
        const customLinkProps: DisplayContentLinksPluginProps = {
          elementId,
          isViewOnly,
        };

        switch (linkType) {
          case LinkType.Content:
            return {
              component: ContentLinkEntity,
              props: customLinkProps,
            };

          default:
            return baseGetLinkEntityComponent(linkType);
        }
      };

      state.getLinkEntityComponent.decorate(getLinkEntityComponent);

      return {};
    },
    [elementId, isViewOnly],
  );

  return useEditorWithPlugin(props, { apply });
};
