import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { documentationLinks } from '../../../../../_shared/constants/documentationLinks.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { selectIntercomUtils } from '../../../../../_shared/selectors/selectIntercomUtils.ts';
import { InnovationLabFeatureCard } from '../../components/InnovationLabFeatureCard.tsx';
import { InnovationLabFeatureCardWrapper } from '../../components/InnovationLabFeatureCardWrapper.tsx';
import { InnovationLabFeatureDialogs } from '../../components/InnovationLabFeatureDialogs.tsx';
import { InnovationLabGenericActivationCallout } from '../../components/InnovationLabGenericActivationCallout.tsx';
import { InnovationLabGenericConsent } from '../../components/InnovationLabGenericConsent.tsx';
import { useFeatureActivationDialog } from '../../hooks/useFeatureActivationDialog.ts';
import { activateInnovationLabFeature } from '../../thunks/activateInnovationLabFeature.ts';
import { deactivateInnovationLabFeature } from '../../thunks/deactivateInnovationLabFeature.ts';
import { InnovationLabCardProps } from '../../types/InnovationLabCardProps.type.ts';

export const ElementAiTranslationCard = ({ state, info, name }: InnovationLabCardProps) => {
  const intercomUtils = useSelector(selectIntercomUtils);

  const {
    dialogDisplayed,
    isAsyncOperationInProgress,
    onCardActionButtonClick,
    onDialogActionClick,
    onDialogClose,
  } = useFeatureActivationDialog(
    info,
    state,
    activateInnovationLabFeature('ai-element-translation'),
    deactivateInnovationLabFeature('ai-element-translation'),
  );

  const featureState = info.status;

  return (
    <>
      <InnovationLabFeatureCardWrapper featureState={featureState}>
        <InnovationLabFeatureCard
          activationState={state}
          buttonFeatureName="Translate with AI"
          featureName={name}
          featureState={featureState}
          isActionButtonLoading={isAsyncOperationInProgress}
          onCardActionButtonClick={onCardActionButtonClick}
          onContactSupport={intercomUtils.showIntercom}
          renderCardBody={() => (
            <>
              <p>
                Expand your reach and connect with a global audience effortlessly, using{' '}
                <OutwardLink href={documentationLinks.aiPoweredTranslation}>
                  AI-powered translation directly in Kontent.ai
                </OutwardLink>
                . Translate your message and break language barriers with just a few clicks.
              </p>
            </>
          )}
        />
      </InnovationLabFeatureCardWrapper>
      <InnovationLabFeatureDialogs
        dialogDisplayed={dialogDisplayed}
        dialogFeatureName="AI translation"
        isAsyncOperationInProgress={isAsyncOperationInProgress}
        onDialogActionClick={onDialogActionClick}
        onDialogClose={onDialogClose}
        renderActivationDialogBody={() => (
          <>
            <InnovationLabGenericActivationCallout featureName="the AI translation feature." />
            <InnovationLabGenericConsent />
          </>
        )}
      />
    </>
  );
};
