import {
  sizeSidebarPlaceholderWidth,
  sizeSidebarWidth,
} from '@kontent-ai/component-library/tokens';
import { animated, useTransition } from '@react-spring/web';
import { RefObject, useCallback, useRef } from 'react';
import { useOnClickOutside } from '../../../../../../_shared/hooks/useOnClickOutside.ts';
import {
  DataUiAction,
  DataUiCollection,
  DataUiElement,
  getDataUiCollectionAttribute,
  getDataUiElementAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ContentItemDetails } from '../../containers/ContentItemDetails.tsx';
import { ContentItemSidebarButton } from './ContentItemSidebarButton.tsx';

type Props<TRef> = Readonly<{
  autofocus: boolean;
  hasPlaceholder?: boolean;
  isOpen: boolean;
  onClose: () => void;
  outsideClickElementRefs: OneOrMore<RefObject<TRef>>;
}>;

export const ContentItemSidebar = <TRef extends HTMLElement>({
  autofocus,
  hasPlaceholder,
  isOpen,
  onClose,
  outsideClickElementRefs,
}: Props<TRef>) => {
  const innerRef = useRef<HTMLDivElement>(null);

  const hiddenTransform = hasPlaceholder
    ? `translateX(${sizeSidebarWidth - sizeSidebarPlaceholderWidth}px)`
    : 'translateX(100%)';
  const visibleTransform = hasPlaceholder ? 'translateX(0px)' : 'translateX(0%)';

  const transitions = useTransition(isOpen, {
    initial: {
      transform: isOpen ? visibleTransform : hiddenTransform,
    },
    from: {
      transform: hiddenTransform,
    },
    enter: {
      transform: visibleTransform,
    },
    leave: {
      transform: hiddenTransform,
    },
    onRest: () => {
      if (isOpen && autofocus) {
        innerRef.current?.focus();
      }
    },
  });

  const closeOnClickOutside = useCallback(
    (event: MouseEvent): void => {
      const outsideClickElementRefsArray = Array.isArray(outsideClickElementRefs)
        ? outsideClickElementRefs
        : [outsideClickElementRefs];

      const wasEventTriggeredFromValidOutsideElement = outsideClickElementRefsArray.some(
        (ref) => !!ref.current?.contains(event.target as HTMLElement),
      );

      if (isOpen && wasEventTriggeredFromValidOutsideElement) {
        onClose();
      }
    },
    [isOpen, onClose, outsideClickElementRefs],
  );

  useOnClickOutside(innerRef, closeOnClickOutside);

  return transitions(
    (style, isOpenState) =>
      isOpenState && (
        <animated.div
          ref={innerRef}
          style={style}
          className="content-item-pane__sidebar"
          tabIndex={-1}
          {...getDataUiElementAttribute(DataUiElement.ContentItemDetailsSidebar)}
        >
          <div
            className="content-item-pane__sidebar-pane"
            {...getDataUiCollectionAttribute(DataUiCollection.ItemDetailSections)}
          >
            <ContentItemDetails />
          </div>
          <ContentItemSidebarButton
            className="content-item-pane__sidebar-btn-toggle"
            dataUiAction={DataUiAction.CloseSidebar}
            iconName="ChevronDoubleRight"
            onClick={onClose}
            tooltipText="Close content details sidebar"
          />
        </animated.div>
      ),
  );
};
