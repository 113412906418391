import React from 'react';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { showCreateNewForm } from '../actions/sitemapActions.ts';
import { SitemapNodesList as SitemapNodesListComponent } from '../components/SitemapNodesList.tsx';

export const SitemapNodesList: React.FC = () => {
  const isCreateNewFormDisplayed = useSelector((s) => s.sitemapApp.isCreateNewFormDisplayed);
  const isCreatingAllowed = useSelector((s) => !s.sitemapApp.editedNodeId);
  const isDragging = useSelector((state) => !!state.sitemapApp.draggedNodeId);
  const sitemap = useSelector((s) => s.sitemapApp.sitemap);

  const dispatch = useDispatch();

  return (
    <SitemapNodesListComponent
      isCreateNewFormDisplayed={isCreateNewFormDisplayed}
      isCreatingAllowed={isCreatingAllowed}
      isDragging={isDragging}
      onCreateNewButtonClicked={() => dispatch(showCreateNewForm())}
      sitemap={sitemap}
    />
  );
};
