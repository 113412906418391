import { isAbortError } from '@kontent-ai/errors';
import { assert } from '@kontent-ai/utils';
import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { getCannotViewItemMessage } from '../../../../../../_shared/utils/permissions/getCannotViewItemMessage.ts';
import { INodeParams } from '../../../../../webSpotlight/actions/thunks/expandWebSpotlightNode.ts';
import { UnableToLoadErrorMessage } from '../../../../../webSpotlight/constants/uiConstants.ts';
import { assertRootItem } from '../../../../../webSpotlight/utils/assertRootItem.ts';
import { getRootNodeId } from '../../../../../webSpotlight/utils/webSpotlightUtils.ts';
import {
  Content_NavigationTree_LoadFailed,
  Content_NavigationTree_LoadStarted,
} from '../../../../constants/editorActionTypes.ts';
import { ILoadListingItemsAction } from '../../../LoadedItems/actions/thunks/loadListingItems.ts';

type Deps = Readonly<{
  readonly expandSelectedWebSpotlightNodeAncestors: (
    currentPath: ReadonlyArray<Uuid>,
    abortSignal?: AbortSignal,
  ) => ThunkPromise;
  readonly expandWebSpotlightNode: (params: INodeParams, abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadListingItems: ILoadListingItemsAction;
  readonly loadSubpagesIds: (contentItemIds: UuidArray, abortSignal?: AbortSignal) => ThunkPromise;
}>;

const started = () =>
  ({
    type: Content_NavigationTree_LoadStarted,
  }) as const;

const failed = (errorMessage: string) =>
  ({
    type: Content_NavigationTree_LoadFailed,
    payload: {
      errorMessage,
    },
  }) as const;

export type NavigationTreeActionsType = ReturnType<typeof started | typeof failed>;

export const createLoadContentItemNavigationTreeAction =
  (deps: Deps) =>
  (nodeItemIdsTreePath: ReadonlyArray<Uuid>, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    try {
      dispatch(started());

      const rootItemId = nodeItemIdsTreePath[0];
      assertRootItemId(rootItemId);

      const state = getState();
      const {
        webSpotlightApp: { expandedNodesData },
      } = state;

      const itemsToLoad: ReadonlyArray<Uuid> = [
        ...expandedNodesData.toArray().flatMap((data) => [data.itemId, ...data.subpagesIds]),
        rootItemId,
      ];

      const [listingItems] = await Promise.all([
        dispatch(deps.loadListingItems(itemsToLoad, abortSignal)),
        dispatch(deps.loadSubpagesIds(itemsToLoad, abortSignal)),
      ]);

      const rootItem = listingItems?.find((i) => i.item.id === rootItemId);
      assertRootItem(
        rootItem,
        rootItemId,
        itemsToLoad,
        listingItems,
        getState().data.listingContentItems.byId,
      );

      const cannotViewMessage = getCannotViewItemMessage(rootItem);
      if (!cannotViewMessage) {
        const nodeId = getRootNodeId(rootItemId);
        await dispatch(
          deps.expandWebSpotlightNode(
            {
              itemId: rootItemId,
              nodeId,
            },
            abortSignal,
          ),
        );
      }

      await dispatch(
        deps.expandSelectedWebSpotlightNodeAncestors(nodeItemIdsTreePath, abortSignal),
      );
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(failed(UnableToLoadErrorMessage));
      }

      throw error;
    }
  };

function assertRootItemId(rootItemId: Uuid | undefined): asserts rootItemId {
  assert(rootItemId, () => 'Root item id not defined');
}
