import React from 'react';
import { TypeElementSummary } from '../../../components/typeElements/shared/TypeElementSummary.tsx';
import { DefaultValueSetSummary } from '../../../constants/summaryMessageTemplates.ts';
import { IMultipleChoiceTypeElementData } from '../../../models/elements/MultipleChoiceTypeElementData.ts';
import { IBaseTypeElementSummaryProps } from '../../../types/IBaseTypeElementSummaryProps.type.ts';
import { hasMultipleChoiceDefaultValue } from '../../../utils/typeElementDefaultValueUtils.ts';
import {
  getBaseTypeElementSummarySegments,
  joinSummarySegments,
} from '../../../utils/typeElementMessagesUtils.ts';

interface IMultipleChoiceTypeElementSummaryProps
  extends IBaseTypeElementSummaryProps<IMultipleChoiceTypeElementData> {}

export const getMultipleChoiceTypeElementSummary = (
  typeElementData: IMultipleChoiceTypeElementData,
): string => {
  const summarySegments: Array<string> = [...getBaseTypeElementSummarySegments(typeElementData)];

  if (hasMultipleChoiceDefaultValue(typeElementData)) {
    summarySegments.push(DefaultValueSetSummary);
  }

  return joinSummarySegments(summarySegments);
};

export const MultipleChoiceTypeElementSummary: React.FC<IMultipleChoiceTypeElementSummaryProps> = (
  props,
) => {
  const summaryText = getMultipleChoiceTypeElementSummary(props.typeElementData);

  return <TypeElementSummary summaryText={summaryText} {...props} />;
};
