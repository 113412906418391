import { DefaultWorkflowId } from '../../../../_shared/constants/variantIdValues.ts';
import { getWorkflow } from '../../../../_shared/selectors/workflowSelectors.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { getCurrentUserRoleForCollectionForLanguageForUser } from '../../../../_shared/utils/permissions/getContributorRole.ts';
import { Workflow } from '../../../../data/models/workflow/Workflow.ts';
import { WorkflowsByPermission, getWorkflowsByPermission } from './getWorkflowsByPermission.ts';
import { getWorkflowsSortedByName } from './getWorkflowsSortedByName.ts';

export const getWorkflowsCurrentUserCanCreateVariantIn = (
  state: IStore,
  languageId: Uuid,
  collectionId: Uuid | null,
  contentTypeId: Uuid | null,
): readonly Workflow[] => {
  const { allowedWorkflows } = getWorkflowsForCreatedVariantContentTypeAndUserRole(
    state,
    collectionId,
    languageId,
    contentTypeId,
  );

  return !languageId || !collectionId || !contentTypeId || allowedWorkflows.length > 0
    ? allowedWorkflows
    : fallbackToDefaultWorkflow(state);
};

const getWorkflowsForCreatedVariantContentTypeAndUserRole = (
  state: IStore,
  collectionId: string | null,
  languageId: string,
  contentTypeId: string | null,
): WorkflowsByPermission => {
  const allWorkflows = getWorkflowsSortedByName(state.data.workflows.byId);

  const userRole = getCurrentUserRoleForCollectionForLanguageForUser(
    state.data.user,
    state.sharedApp.currentProjectId,
    collectionId,
    languageId,
  );

  return getWorkflowsByPermission(
    allWorkflows,
    collectionId ?? undefined,
    contentTypeId ?? undefined,
    userRole?.id,
  );
};

const fallbackToDefaultWorkflow = (state: IStore): readonly Workflow[] => {
  const defaultWorkflow = getWorkflow(state, DefaultWorkflowId);

  return defaultWorkflow ? [defaultWorkflow] : [];
};

export enum WorkflowsCurrentUserCanCreateVariantInHint {
  None = 'None',
  RoleCannotCreateVariantInOtherWorkflows = 'RoleCannotCreateVariantInOtherWorkflows',
  VariantCanBeCreatedOnlyInOneWorkflow = 'VariantCanBeCreatedOnlyInOneWorkflow',
}

export const getHintForWorkflowsCurrentUserCanCreateVariantIn = (
  state: IStore,
  languageId: Uuid,
  collectionId: Uuid | null,
  contentTypeId: Uuid | null,
): WorkflowsCurrentUserCanCreateVariantInHint => {
  const { allowedWorkflows, forbiddenWorkflows } =
    getWorkflowsForCreatedVariantContentTypeAndUserRole(
      state,
      collectionId,
      languageId,
      contentTypeId,
    );

  if (allowedWorkflows.length <= 1 && forbiddenWorkflows.length > 0) {
    return WorkflowsCurrentUserCanCreateVariantInHint.RoleCannotCreateVariantInOtherWorkflows;
  }

  if (allowedWorkflows.length === 1 && forbiddenWorkflows.length === 0) {
    return WorkflowsCurrentUserCanCreateVariantInHint.VariantCanBeCreatedOnlyInOneWorkflow;
  }

  return WorkflowsCurrentUserCanCreateVariantInHint.None;
};
