import React, { useCallback } from 'react';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { emptyTaxonomyTerm } from '../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyTerm.ts';
import { DirectionItemEnum } from '../../sitemap/constants/DirectionItemEnum.ts';
import { moveTaxonomyTerm } from '../actions/thunkTaxonomyActions.ts';
import { TaxonomyTerm as TaxonomyTermComponent } from '../components/TaxonomyTerm.tsx';

interface ITaxonomyTermProps {
  readonly isBeingDragged?: boolean;
  readonly nodeId: Uuid;
}

export const TaxonomyTerm: React.FC<ITaxonomyTermProps> = (props) => {
  const taxonomyTerm = useSelector(
    (s) => s.taxonomyApp.editedTaxonomyGroup.terms.get(props.nodeId) ?? emptyTaxonomyTerm,
  );
  const isBeingDragged = useSelector(
    (s) => props.isBeingDragged || s.taxonomyApp.draggedTermId === taxonomyTerm.id,
  );
  const isBeingEdited = useSelector((s) => s.taxonomyApp.editedTaxonomyTermId === props.nodeId);
  const isCollapsed = useSelector(
    (s) =>
      !!s.sharedApp.collapsedTaxonomyTermIds
        .get(s.taxonomyApp.editedTaxonomyGroup.id)
        ?.has(taxonomyTerm.id),
  );

  const dispatch = useDispatch();
  const onTermMoved = useCallback(
    (targetTermId: Uuid, directions: ReadonlySet<DirectionItemEnum>) =>
      dispatch(moveTaxonomyTerm(targetTermId, directions)),
    [],
  );

  return (
    <TaxonomyTermComponent
      childNodeComponent={TaxonomyTerm}
      isBeingDragged={isBeingDragged}
      isBeingEdited={isBeingEdited}
      isCollapsed={isCollapsed}
      onTermMoved={onTermMoved}
      taxonomyTerm={taxonomyTerm}
    />
  );
};

TaxonomyTerm.displayName = 'TaxonomyTerm';
