import { Direction } from '@kontent-ai/types';
import { delay } from '@kontent-ai/utils';
import { announce } from '@react-aria/live-announcer';
import { ContentState, EditorState } from 'draft-js';
import { useCallback } from 'react';
import { DataDraftJsAttributes } from '../../../../_shared/utils/dataAttributes/DataDraftJsAttributes.ts';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginComponent } from '../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../editorCore/types/Editor.contract.type.ts';
import { Apply } from '../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';
import { TableCellClassName } from '../../editorCore/utils/editorComponentUtils.ts';
import {
  findParentBlock,
  isInTable,
  isTableCell,
  isTableCellContent,
} from '../../utils/blocks/blockTypeUtils.ts';
import { createContent, findBlockIndex } from '../../utils/blocks/editorBlockUtils.ts';
import { IBlockHierarchyNode, getBlockHierarchy } from '../../utils/blocks/editorHierarchyUtils.ts';
import { createSelection } from '../../utils/editorSelectionUtils.ts';
import { exportToScreenReaderPlainText } from '../../utils/export/plainText/exportToPlainText.ts';
import { getBlocks } from '../../utils/general/editorContentGetters.ts';
import { DraftJsEditorPlugin } from '../draftJs/DraftJsEditorPlugin.type.ts';
import {
  ExecuteCommand,
  KeyboardShortcutsPlugin,
} from '../keyboardShortcuts/KeyboardShortcutsPlugin.tsx';
import { RichTextInputCommand, TextInputCommand } from '../keyboardShortcuts/api/EditorCommand.ts';
import { getCommandDirection } from '../keyboardShortcuts/api/editorCommandUtils.ts';
import { TablesPlugin } from '../tables/TablesPlugin.tsx';
import {
  getCoordinatesFromDepth,
  getDepthFromCoordinates,
} from '../tables/api/depthCoordinatesConversion.ts';
import { WrapperPlugin } from '../visuals/WrapperPlugin.tsx';

export type CustomTableSelectionPlugin = DraftJsEditorPlugin<
  None,
  None,
  None,
  None,
  [WrapperPlugin, KeyboardShortcutsPlugin<RichTextInputCommand>, TablesPlugin]
>;

export const CustomTableSelectionPlugin: PluginComponent<CustomTableSelectionPlugin> = (props) => {
  const apply: Apply<CustomTableSelectionPlugin> = useCallback((state) => {
    const executeCommand: Decorator<ExecuteCommand<RichTextInputCommand>> =
      (baseExecuteCommand) => (command, isShiftPressed) => {
        switch (command) {
          case TextInputCommand.OpenContextMenu: {
            const editorState = state.getEditorState();

            const selection = editorState.getSelection();
            const focusBlockKey = selection.getFocusKey();
            const rteInputRef = state.getWrapperRef().current;

            if (!rteInputRef) {
              return baseExecuteCommand(command, isShiftPressed);
            }

            const currentCell = rteInputRef.querySelector(
              `[${DataDraftJsAttributes.OffsetKey}^="${focusBlockKey}-"]`,
            );
            const cellWrapper = currentCell?.closest(`.${TableCellClassName}`);
            if (!cellWrapper) {
              return baseExecuteCommand(command, isShiftPressed);
            }

            const event = new MouseEvent('contextmenu', {
              bubbles: true,
            });

            // We defer the custom contextmenu event, because in Firefox synchronously triggered event
            // it interferes with the native contextmenu event triggered by Shift+F10
            // This way we let it finish first including all involved cancelling
            // (not showing the default context menu) and then trigger the menu from the right place
            delay(0).then(() => {
              cellWrapper.dispatchEvent(event);
            });
            return true;
          }

          case RichTextInputCommand.MoveCaretToPreviousBlock:
          case RichTextInputCommand.MoveCaretToNextBlock: {
            const direction = getCommandDirection(command);
            const isBackwards = direction === Direction.Backward;

            const editorState = state.getEditorState();
            const content = editorState.getCurrentContent();
            const selection = editorState.getSelection();
            const focusKey = selection.getFocusKey();
            const focusBlock = content.getBlockForKey(focusKey);

            if (!isInTable(focusBlock)) {
              return baseExecuteCommand(command, isShiftPressed);
            }

            const adjacentCellBlock = isBackwards
              ? content.getBlockBefore(focusBlock.getKey())
              : content.getBlockAfter(focusBlock.getKey());

            if (!adjacentCellBlock || !isTableCell(adjacentCellBlock)) {
              return baseExecuteCommand(command, isShiftPressed);
            }

            const adjacentCellContentBlock = isBackwards
              ? content.getBlockBefore(adjacentCellBlock.getKey())
              : content.getBlockAfter(adjacentCellBlock.getKey());

            if (!adjacentCellContentBlock || !isTableCellContent(adjacentCellContentBlock)) {
              return baseExecuteCommand(command, isShiftPressed);
            }

            const blocks = getBlocks(content);

            const adjacentCellContextBlockIndex = findBlockIndex(
              blocks,
              adjacentCellContentBlock?.getKey(),
            );
            const cellBlock = findParentBlock(adjacentCellContextBlockIndex, blocks);

            if (!cellBlock?.block || !isTableCell(cellBlock?.block)) {
              return baseExecuteCommand(command, isShiftPressed);
            }

            const adjacentCellNode = getBlockHierarchy(blocks, cellBlock.index)[0];
            if (!adjacentCellNode) {
              return baseExecuteCommand(command, isShiftPressed);
            }

            const screenReaderCellText = exportToScreenReaderPlainText(
              createContent(adjacentCellNode.childNodes.map((child) => child.block)),
            );

            announce(
              `Edit ${screenReaderCellText}, Insertion at ${isBackwards ? 'end' : 'start'} of the text, table`,
            );

            return baseExecuteCommand(command, isShiftPressed);
          }

          case RichTextInputCommand.MoveCaretToBlockAbove:
          case RichTextInputCommand.MoveCaretToBlockBelow: {
            const direction = getCommandDirection(command);
            const isUpward = direction === Direction.Backward;

            state.executeChange((editorState) => {
              const content = editorState.getCurrentContent();
              const selection = editorState.getSelection();

              const targetNode = findVerticalAdjacentCellOrTableEdge(
                content,
                selection.getFocusKey(),
                direction,
              );

              if (!targetNode || !isTableCell(targetNode.block)) {
                return targetNode
                  ? EditorState.forceSelection(
                      editorState,
                      createSelection(
                        targetNode.block.getKey(),
                        Direction.Backward ? targetNode.block.getLength() : 0,
                      ),
                    )
                  : editorState;
              }

              const targetCellContentBlock =
                targetNode.childNodes[isUpward ? targetNode.childNodes.length - 1 : 0]?.block;

              if (!targetCellContentBlock) {
                return editorState;
              }

              const screenReaderCellText = exportToScreenReaderPlainText(
                createContent(targetNode.childNodes.map((child) => child.block)),
              );

              announce(
                `Edit ${screenReaderCellText}, Insertion at ${isUpward ? 'end' : 'start'} of the text, table`,
              );

              return EditorState.forceSelection(
                editorState,
                createSelection(
                  targetCellContentBlock.getKey(),
                  direction === Direction.Backward ? targetCellContentBlock.getLength() : 0,
                ),
              );
            });

            return true;
          }

          default:
            return baseExecuteCommand(command, isShiftPressed);
        }
      };

    state.executeCommand.decorate(executeCommand);
    return {};
  }, []);

  return useEditorWithPlugin(props, { apply });
};

export function findVerticalAdjacentCellOrTableEdge(
  content: ContentState,
  focusKey: string,
  direction: Direction,
): IBlockHierarchyNode | null {
  const focusBlock = content.getBlockForKey(focusKey);
  if (!isTableCellContent(focusBlock)) {
    return null;
  }

  const isUpward = direction === Direction.Backward;
  const blocks = getBlocks(content);

  const focusBlockIndex = findBlockIndex(blocks, focusKey);

  const parentBlock = findParentBlock(focusBlockIndex, blocks);

  if (!parentBlock || !isTableCell(parentBlock?.block)) {
    return null;
  }

  const currentCellBlock = parentBlock.block;
  const { x, y } = getCoordinatesFromDepth(currentCellBlock.getDepth());
  const newRowIndex = isUpward ? y - 1 : y + 1;
  const targetDepth = getDepthFromCoordinates({
    x,
    y: newRowIndex,
  });

  const nodesFromCurrentCellToEditorEdge = isUpward
    ? getBlockHierarchy(blocks, undefined, parentBlock.index).toReversed()
    : getBlockHierarchy(blocks, parentBlock.index);

  const verticalAdjacentCell = nodesFromCurrentCellToEditorEdge.find(
    ({ block }) => targetDepth === block.getDepth() || !isTableCell(block),
  );

  return verticalAdjacentCell ?? null;
}
