import { isElement, scrollToView } from '@kontent-ai/DOM';
import { ICancellablePromise, delay, swallowCancelledPromiseError } from '@kontent-ai/utils';
import React from 'react';
import { DefaultScrollOffset } from '../../../../../../_shared/hooks/useAutoScroll.ts';
import { waitUntilFocusAndScrollAreNotDeferred } from '../../../../../../_shared/utils/autoScrollUtils.ts';
import { IncompleteElementAttribute } from '../../../../utils/workflowUtils.ts';

export interface IIncompleteItemElementsNotificationOwnProps {
  readonly render: (
    highlightIncompleteElements: () => void,
    incompleteElementsCount: number,
  ) => React.ReactNode;
}

export interface IIncompleteItemElementsNotificationStateProps {
  readonly incompleteElementsCount: number;
  readonly isAnyIncompleteElementAccessible: boolean;
}

export interface IIncompleteItemElementsNotificationDispatchProps {
  readonly onAfterIncompleteNotificationClicked: () => void;
  readonly onIncompleteNotificationClick: () => void;
  readonly onShowInaccessibleElementsNotification: () => void;
}

type IncompleteItemElementsNotificationProps = IIncompleteItemElementsNotificationStateProps &
  IIncompleteItemElementsNotificationDispatchProps &
  IIncompleteItemElementsNotificationOwnProps;

export class IncompleteItemElementsNotification extends React.PureComponent<IncompleteItemElementsNotificationProps> {
  static displayName = 'IncompleteItemElementsNotification';

  private _highlightIncompleteElement: ICancellablePromise | null = null;
  private readonly _highlightFirstIncompleteElement = waitUntilFocusAndScrollAreNotDeferred(
    (): void => {
      const firstIncompleteElement: HTMLElement | null = document.querySelector(
        IncompleteElementAttribute,
      );
      if (isElement(firstIncompleteElement)) {
        scrollToView(firstIncompleteElement, 'start', 'auto', DefaultScrollOffset);
        firstIncompleteElement.focus();
        this.props.onAfterIncompleteNotificationClicked();
      }
    },
  );

  componentWillUnmount() {
    this._highlightFirstIncompleteElement.cancel();
    if (this._highlightIncompleteElement) {
      this._highlightIncompleteElement.cancel();
    }
  }

  private readonly _highlightIncompleteElements = (): void => {
    const { incompleteElementsCount } = this.props;

    if (incompleteElementsCount) {
      this.props.onIncompleteNotificationClick();
      // React needs some time to rerender collapsed components
      this._highlightIncompleteElement = delay(0)
        .then(() => this._highlightFirstIncompleteElement())
        .catch(swallowCancelledPromiseError);
    }
  };

  render() {
    const { incompleteElementsCount, isAnyIncompleteElementAccessible } = this.props;

    if (incompleteElementsCount < 1) {
      return null;
    }

    return isAnyIncompleteElementAccessible
      ? this.props.render(this._highlightIncompleteElements, incompleteElementsCount)
      : this.props.render(
          this.props.onShowInaccessibleElementsNotification,
          incompleteElementsCount,
        );
  }
}
