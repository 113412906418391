import styled from 'styled-components';
import { useDispatch } from '../../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../../_shared/hooks/useSelector.ts';
import { ContentItemEditingEventOrigins } from '../../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import {
  DataUiWorkflowAction,
  ItemEditingForms,
} from '../../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { openChangeWorkflowDialog } from '../../../../../ChangeWorkflowDialog/thunks/openChangeWorkflowDialog.ts';
import { ClickableSection } from '../../../../components/sidebar/sectionTypes/ClickableSection.tsx';
import { getEditedVariantWorkflowName } from '../../../selectors/getEditedVariantWorkflowName.ts';

const StyledName = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;  
`;

export const ClickableWorkflowSection = () => {
  const dispatch = useDispatch();
  const onClick = () =>
    dispatch(openChangeWorkflowDialog(ContentItemEditingEventOrigins.ItemDetails));
  const workflowName = useSelector(getEditedVariantWorkflowName);

  return (
    <ClickableSection
      dataUiActionAttribute={DataUiWorkflowAction.ChangeWorkflow}
      dataUiNameAttribute={ItemEditingForms.Workflow}
      onEditClick={onClick}
    >
      <StyledName title={workflowName}>{workflowName}</StyledName>
    </ClickableSection>
  );
};
