import { ComponentProps } from 'react';
import { CreateAutoScrollId } from '../../../../../_shared/components/AutoScroll/AutoScrollId.ts';
import { useDataSelector } from '../../../../../_shared/hooks/useDataSelector.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { getCurrentProjectId } from '../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { sortAndOrderAssetUsages } from '../../../../../_shared/utils/assets/assetUtils.ts';
import { AssetUsage as AssetUsageComponent } from '../../../../contentInventory/assets/components/AssetEditing/AssetUsage.tsx';
import { addDefaultLanguageSuffix } from '../../../../environmentSettings/localization/utils/languageUtils.ts';

export const AssetDetailUsage = ({
  assetId,
  isInDialog,
}: Pick<ComponentProps<typeof AssetUsageComponent>, 'isInDialog' | 'assetId'>) => {
  const assetUsage = useSelector((state) =>
    assetId ? state.assetLibraryApp.usage.get(assetId) : null,
  );

  const languages = useDataSelector((data) => data.languages.byId);

  const defaultLanguage = useDataSelector((data) =>
    addDefaultLanguageSuffix(data.languages.defaultLanguage),
  );
  const projectId = useSelector(getCurrentProjectId);

  const selectedLanguageId = useSelector(getSelectedLanguageIdOrThrow);
  const assetUsageForCurrentLanguage = (assetUsage || []).filter(
    (usageReference) => usageReference.primary.id.variantId === selectedLanguageId,
  );
  const sortedAndOrderedAssetUsage = sortAndOrderAssetUsages(
    assetUsageForCurrentLanguage,
    languages,
  );

  // When asset dialog is opened from content item, the remembered id is the id of the asset tile
  // This causes navigation back to content item scroll to the asset tile as the dialog is not displayed in that case
  const scrollId = CreateAutoScrollId.forAssetTile(assetId);

  return (
    <AssetUsageComponent
      assetId={assetId}
      defaultLanguage={defaultLanguage}
      isInDialog={isInDialog}
      isUsageLoaded={!!assetUsage}
      languages={languages}
      projectId={projectId}
      scrollId={scrollId}
      sortedLanguageUsage={sortedAndOrderedAssetUsage}
    />
  );
};
