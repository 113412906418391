import { ISelectItem, ItemId } from '@kontent-ai/component-library/Selects';
import { SingleSelect } from '@kontent-ai/component-library/SingleSelect';
import React, { useCallback, useMemo } from 'react';
import {
  DataUiCollection,
  DataUiElement,
  getDataUiCollectionAttribute,
  getDataUiElementAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IContentTypeSnippet } from '../../../../../../data/models/contentModelsApp/snippets/ContentTypeSnippet.ts';
import { DraggableTypeElement } from '../../../containers/typeElements/DraggableTypeElement.tsx';
import { IContentTypeSnippetTypeElementData } from '../../../models/elements/ContentTypeSnippetTypeElementData.ts';
import {
  ITypeElementDataProps,
  ITypeElementOwnProps,
} from '../../../types/ITypeElementProps.type.ts';
import { ContentTypeSnippetTypeElementValidationResult } from '../../../utils/typeElementValidators/types/ContentTypeSnippetTypeElementValidationResult.type.ts';

export interface IContentTypeSnippetTypeElementDataProps
  extends ITypeElementDataProps<
    IContentTypeSnippetTypeElementData,
    ContentTypeSnippetTypeElementValidationResult
  > {
  readonly generatesUrlSlug?: boolean;
  readonly possibleContentTypeSnippets: ReadonlyArray<IContentTypeSnippet>;
}

type ContentTypeSnippetTypeElementProps = IContentTypeSnippetTypeElementDataProps &
  ITypeElementOwnProps<
    IContentTypeSnippetTypeElementData,
    ContentTypeSnippetTypeElementValidationResult
  >;

interface IContentTypeSnippetOption extends ISelectItem<IContentTypeSnippetOption> {
  readonly snippet: IContentTypeSnippet;
}

const convertSnippetToOption = (snippet: IContentTypeSnippet): IContentTypeSnippetOption => {
  return {
    id: snippet.id,
    label: snippet.name,
    type: 'item',
    snippet,
  };
};

export const ContentTypeSnippetTypeElement: React.FC<ContentTypeSnippetTypeElementProps> = (
  props,
) => {
  const { possibleContentTypeSnippets, typeElementData, onChange } = props;

  const selectedSnippetChange = useCallback(
    (_snippetId: ItemId | null, snippetNode: IContentTypeSnippetOption | null): void => {
      const selectedContentTypeSnippet = snippetNode?.snippet;
      const newData: IContentTypeSnippetTypeElementData = {
        ...typeElementData,
        snippetId: selectedContentTypeSnippet?.id ?? null,
        name: selectedContentTypeSnippet?.name ?? '',
      };

      onChange(newData);
    },
    [onChange, typeElementData],
  );

  const snippetSelectorOptions = useMemo((): ReadonlyArray<IContentTypeSnippetOption> => {
    return possibleContentTypeSnippets.map(convertSnippetToOption);
  }, [possibleContentTypeSnippets]);

  return (
    <DraggableTypeElement {...props}>
      <div className="content-element__value content-element__value--without-guideline">
        <SingleSelect
          items={snippetSelectorOptions}
          aria-label="Select a content type snippet"
          placeholder="Select a content type snippet"
          verticalMenuDataAttributes={getDataUiCollectionAttribute(
            DataUiCollection.ContentElements,
          )}
          onSelectionChange={selectedSnippetChange}
          selectedItemId={typeElementData.snippetId}
        />
      </div>
      <div
        className="content-element__config-pane"
        {...getDataUiElementAttribute(DataUiElement.ContentElementConfigPane)}
      />
    </DraggableTypeElement>
  );
};

ContentTypeSnippetTypeElement.displayName = 'ContentTypeSnippetTypeElement';
