import { Placement } from '@kontent-ai/component-library/types';
import { identity } from '@kontent-ai/utils';
import React, { useId } from 'react';
import { getDropDown } from '../../../../../../../_shared/components/DropDown.tsx';
import { TextField } from '../../../../../../../_shared/uiComponents/TextField/TextField.tsx';
import {
  DataUiElement,
  DataUiInput,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  UnitOption,
  UnitOptions,
  getUnitOptionName,
} from '../../../../../../contentInventory/content/models/textLengthOptions.ts';
import { ITypeElementDataWithTextLimit } from '../../../../models/elements/types/TypeElementData.ts';
import { BaseTextTypeElementValidationResult } from '../../../../utils/typeElementValidators/types/BaseTextTypeElementValidationResult.type.ts';
import { TypeElementConfigurationSection } from '../TypeElementConfigurationSection.tsx';

const DropDown = getDropDown<UnitOption>();

const getLengthLimit = (typeElementData: ITypeElementDataWithTextLimit) =>
  typeElementData._lengthUnitOption === UnitOption.Characters
    ? typeElementData.maxChars
    : typeElementData.maxWords;

const updateLengthLimitInElement = (
  typeElementData: ITypeElementDataWithTextLimit,
  newLimit: string | null,
): ITypeElementDataWithTextLimit => {
  const lengthUnitOption = typeElementData._lengthUnitOption;
  if (lengthUnitOption === UnitOption.Words) {
    return {
      ...typeElementData,
      maxWords: newLimit,
      maxChars: null,
    };
  }
  if (lengthUnitOption === UnitOption.Characters) {
    return {
      ...typeElementData,
      maxWords: null,
      maxChars: newLimit,
    };
  }

  return typeElementData;
};

const updateLengthUnitInElement = (
  typeElementData: ITypeElementDataWithTextLimit,
  newUnit: UnitOption,
): ITypeElementDataWithTextLimit => {
  const typeElementDataWithUpdatedUnit: ITypeElementDataWithTextLimit = {
    ...typeElementData,
    _lengthUnitOption: newUnit,
  };

  return updateLengthLimitInElement(
    typeElementDataWithUpdatedUnit,
    getLengthLimit(typeElementData),
  );
};

type BaseTextTypeLimitProps = {
  readonly readOnly?: boolean;
  readonly typeElementData: ITypeElementDataWithTextLimit;
  readonly validationResult: BaseTextTypeElementValidationResult;
  readonly uiElement?: DataUiElement;
  readonly tooltipText?: string;
  readonly tooltipPlacement?: Placement;
  readonly onChange: (data: ITypeElementDataWithTextLimit) => void;
};

export const BaseTextTypeLimit: React.FC<BaseTextTypeLimitProps> = ({
  readOnly,
  typeElementData,
  uiElement,
  tooltipText,
  tooltipPlacement = 'top',
  validationResult: { isMaxCharsValid, isMaxWordsValid },
  onChange,
}) => {
  const id = useId();

  const updateLengthLimit = (event: any): void => {
    const newLimit = event.target.value;
    const updatedElement = updateLengthLimitInElement(typeElementData, newLimit);
    onChange(updatedElement);
  };

  const updateLengthUnit = (newUnit: UnitOption): void => {
    const updatedElementData = updateLengthUnitInElement(typeElementData, newUnit);
    onChange(updatedElementData);
  };

  return (
    <TypeElementConfigurationSection title="Limit length to" uiElement={uiElement} labelFor={id}>
      <div className="content-element__config-number-limit">
        <div className="content-element__config-number-limit-item">
          <TextField
            dataUiInputName={DataUiInput.NumberLimit}
            hasError={!isMaxCharsValid || !isMaxWordsValid}
            inputId={id}
            onChange={updateLengthLimit}
            placeholder="e.g., 50"
            readOnly={readOnly}
            tooltipPlacement={tooltipPlacement}
            tooltipText={tooltipText}
            value={getLengthLimit(typeElementData) || ''}
          />
        </div>
        <div className="content-element__config-number-limit-item">
          <DropDown
            className="combo-box"
            selectedOption={typeElementData._lengthUnitOption}
            options={UnitOptions}
            onSelect={updateLengthUnit}
            renderOptionName={getUnitOptionName}
            getOptionId={identity}
            disabled={readOnly}
            disabledTooltipMessage={tooltipText}
            disabledTooltipPlacement={tooltipPlacement}
          />
        </div>
      </div>
    </TypeElementConfigurationSection>
  );
};

BaseTextTypeLimit.displayName = 'BaseTextTypeLimit';
