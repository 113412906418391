import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { emptySelectionItem } from '@kontent-ai/component-library/Selects';
import { GridListMenu, useGridListMenuState } from '@kontent-ai/component-library/VerticalMenu';
import { alphabetically, createCompare } from '@kontent-ai/utils';
import { ItemSettings } from '../../../../../_shared/components/ProjectMenu/ItemSettings.tsx';
import {
  DataUiExternalLinkName,
  getDataUiNavAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { AiGuidelines } from '../../../data/models/aiGuidelines.ts';
import { optionMaxWidth, optionMinWidth } from '../constants/uiConstants.ts';
import {
  AiGuidelinesMenuItem,
  AiGuidelinesMenuSection,
} from '../types/aiGuidelinesMenuTypes.type.ts';

export const createNewMenuItemId = 'create-new';
export const emptySelectionMenuItemId = emptySelectionItem.id;

const createMenuItem: AiGuidelinesMenuSection = {
  id: 'create-ai-guideline',
  items: [
    {
      dataAttributes: getDataUiNavAttribute(DataUiExternalLinkName.KeyboardShortcuts),
      id: createNewMenuItemId,
      label: 'Create new review guidelines',
      type: 'item',
    },
  ],
  type: 'section',
};

const getEmptySelectionMenuItem = (
  selectedGuidelinesId: Uuid | undefined,
): AiGuidelinesMenuItem => ({
  dataAttributes: getDataUiNavAttribute(DataUiExternalLinkName.KeyboardShortcuts),
  id: emptySelectionMenuItemId,
  label: emptySelectionItem.label,
  type: 'item',
  isSelected: !selectedGuidelinesId,
});

type AiGuidelinesMenuProps = {
  readonly aiGuidelines: ReadonlyArray<AiGuidelines>;
  readonly onCreate: () => void;
  readonly onEdit: (id: Uuid) => void;
  readonly onSelect: (ids: ReadonlyArray<Uuid>) => void;
  readonly selectedAiGuidelinesId?: Uuid;
};

export const AiGuidelinesMenu = ({
  aiGuidelines,
  onCreate,
  onEdit,
  onSelect,
  selectedAiGuidelinesId,
}: AiGuidelinesMenuProps) => {
  const { state, items } = useGridListMenuState(
    aiGuidelines.length === 0
      ? [createMenuItem]
      : [
          createMenuItem,
          {
            id: 'ai-guidelines',
            items: [getEmptySelectionMenuItem(selectedAiGuidelinesId)].concat(
              aiGuidelines
                .map(
                  (guidelines): AiGuidelinesMenuItem => ({
                    dataAttributes: getDataUiNavAttribute(DataUiExternalLinkName.KeyboardShortcuts),
                    id: guidelines.id,
                    label: guidelines.name,
                    type: 'item',
                    isSelected: guidelines.id === selectedAiGuidelinesId,
                  }),
                )
                .sort(sortAlphabetically),
            ),
            type: 'section',
          },
        ],
  );

  return (
    <GridListMenu
      aria-label="Content review guidelines menu"
      items={items}
      minWidth={optionMinWidth}
      maxWidth={optionMaxWidth}
      state={state}
      renderItem={({ item }) => {
        if (!item.value) {
          return;
        }

        const { id: itemId } = item.value;

        switch (itemId) {
          case emptySelectionMenuItemId:
            return <GridListMenu.Item item={item} state={state} onPress={() => onSelect([])} />;
          case createNewMenuItemId:
            return (
              <GridListMenu.Item
                item={item}
                state={state}
                onPress={onCreate}
                leadingElement={<QuinaryButton.Icon icon={Icons.Plus} />}
              />
            );
          default:
            return (
              <GridListMenu.Item
                item={item}
                state={state}
                onPress={() => onSelect([itemId])}
                trailingElements={
                  <ItemSettings onClick={() => onEdit(itemId)} tooltipText="Edit" />
                }
              />
            );
        }
      }}
    />
  );
};

const sortAlphabetically = createCompare<AiGuidelinesMenuItem, string>({
  compare: alphabetically,
  select: (item) => item.label,
});
