import { Column, Row } from '@kontent-ai/component-library/Row';
import { useObserveElementPresence } from '@kontent-ai/hooks';
import { PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';
import { IUser } from '../../../../../data/reducers/user/IUser.type.ts';
import { ContributorsFilterSelector } from '../../../../contentInventory/content/features/ListingFilter/containers/filterCategory/ContributorsFilterSelector.tsx';
import { PublishingStatusFilterSelector } from '../../../../contentInventory/content/features/ListingFilter/containers/filterCategory/PublishingStatusFilterSelector.tsx';
import { SitemapFilterSelector } from '../../../../contentInventory/content/features/ListingFilter/containers/filterCategory/SitemapFilterSelector.tsx';
import { ItemsTaxonomyFilterSelector } from '../../../../contentInventory/content/features/ListingFilter/containers/filterCategory/TaxonomyFilterSelector.tsx';
import { WorkflowFilterSelector } from '../../../../contentInventory/content/features/ListingFilter/containers/filterCategory/WorkflowFilterSelector.tsx';
import { IContentItemStatus } from '../../../../contentInventory/content/models/filter/IContentItemStatus.ts';
import { IListingFilter } from '../../../../contentInventory/content/models/filter/IListingFilter.ts';
import { IPublishingState } from '../../../../contentInventory/content/models/filter/IPublishingState.ts';
import { languagesFilterInContentStatusWrapperId } from '../LanguagesFilterSelectorWrapper.tsx';
import { CollectionsFilterSelector } from './CollectionsFilterSelector.tsx';
import { ContentItemStatusSelector } from './ContentItemStatusSelector.tsx';
import { ContentTypesFilterSelector } from './ContentTypesFilterSelector.tsx';
import { LanguagesFilterSelector } from './LanguagesFilterSelector.tsx';
import { SpacesFilterSelector } from './SpacesFilterSelector.tsx';

type Props = Readonly<{
  displayTaxonomyWarningStatus: boolean;
  filter: IListingFilter;
  forceContentTypesTooltipText: string | undefined;
  forcedContentTypeIds: ReadonlySet<Uuid> | undefined;
  isAiSearchUsed: boolean;
  onCollectionSelectionChanged: (ids: ReadonlySet<Uuid>) => void;
  onContentItemStatusSelectionChanged: (status: IContentItemStatus) => void;
  onContentTypesSelectionChanged: (ids: ReadonlySet<Uuid>) => void;
  onContributorsSelectionChanged: (ids: ReadonlySet<Uuid>) => void;
  onPublishingStatusSelectionChanged: (status: IPublishingState) => void;
  onSitemapSelectionChanged: (selectedSitemapNodes: ReadonlySet<Uuid>) => void;
  onSpaceSelectionChanged: (selectedSpaceNodes: ReadonlySet<Uuid>) => void;
  onTaxonomySelectionChanged: (groupToTerms: ReadonlyMap<Uuid, ReadonlySet<Uuid>>) => void;
  onWorkflowsSelectionChanged: (
    workflowToSelectedStepIds: ReadonlyMap<Uuid, ReadonlySet<Uuid>>,
    workflowToAllStepIds: ReadonlyMap<Uuid, ReadonlyArray<Uuid>>,
  ) => void;
  user: IUser;
}>;

export const ContentStatusFilterSelectorsOld = (props: Props) => {
  const { current: languageFilterWrapper } = useObserveElementPresence(
    languagesFilterInContentStatusWrapperId,
  );

  return (
    <>
      <SpacesFilterSelector
        onSpaceSelectionChanged={props.onSpaceSelectionChanged}
        selectedSpaces={props.filter.selectedSpacesNodes}
        user={props.user}
      />
      <CollectionsFilterSelector
        onCollectionSelectionChanged={props.onCollectionSelectionChanged}
        selectedCollections={props.filter.selectedCollectionsNodes}
        user={props.user}
      />
      <ContentTypesFilterSelector
        forceContentTypesTooltipText={props.forceContentTypesTooltipText}
        forcedContentTypeIds={props.forcedContentTypeIds}
        onContentTypesSelectionChanged={props.onContentTypesSelectionChanged}
        selectedContentTypeIds={props.filter.selectedContentTypesNodes}
        user={props.user}
      />
      {languageFilterWrapper && createPortal(<LanguagesFilterSelector />, languageFilterWrapper)}
      <SitemapFilterSelector
        isAiSearchUsed={props.isAiSearchUsed}
        onSitemapSelectionChanged={props.onSitemapSelectionChanged}
        selectedSitemapNodes={props.filter.selectedSitemapNodes}
      />
      <WorkflowFilterSelector
        filter={props.filter}
        onWorkflowsSelectionChanged={props.onWorkflowsSelectionChanged}
      />
      <ContributorsFilterSelector
        currentUserId={props.user.info.userId}
        onContributorsSelectionChanged={props.onContributorsSelectionChanged}
        selectedContributors={props.filter.selectedContributorsNodes}
      />
      <ItemsTaxonomyFilterSelector
        displayWarningStatus={props.displayTaxonomyWarningStatus}
        onTaxonomySelectionChanged={props.onTaxonomySelectionChanged}
        selectedTaxonomyNodes={props.filter.selectedTaxonomyNodes}
      />
      <ContentItemStatusSelector
        onContentItemStatusSelectionChanged={props.onContentItemStatusSelectionChanged}
        selectedContentItemStatus={props.filter.selectedContentItemStatus}
        user={props.user}
      />
      <PublishingStatusFilterSelector
        onPublishingStatusSelectionChanged={props.onPublishingStatusSelectionChanged}
        selectedPublishingStateNodes={props.filter.selectedPublishingStateNodes}
      />
    </>
  );
};

export const ContentStatusFilterSelectors = (props: Props) => (
  <Row>
    <SelectorColumn>
      <SpacesFilterSelector
        onSpaceSelectionChanged={props.onSpaceSelectionChanged}
        selectedSpaces={props.filter.selectedSpacesNodes}
        user={props.user}
      />
    </SelectorColumn>
    <SelectorColumn>
      <CollectionsFilterSelector
        onCollectionSelectionChanged={props.onCollectionSelectionChanged}
        selectedCollections={props.filter.selectedCollectionsNodes}
        user={props.user}
      />
    </SelectorColumn>
    <SelectorColumn>
      <ContentTypesFilterSelector
        forceContentTypesTooltipText={props.forceContentTypesTooltipText}
        forcedContentTypeIds={props.forcedContentTypeIds}
        onContentTypesSelectionChanged={props.onContentTypesSelectionChanged}
        selectedContentTypeIds={props.filter.selectedContentTypesNodes}
        user={props.user}
      />
    </SelectorColumn>
    <SelectorColumn>
      <SitemapFilterSelector
        isAiSearchUsed={props.isAiSearchUsed}
        onSitemapSelectionChanged={props.onSitemapSelectionChanged}
        selectedSitemapNodes={props.filter.selectedSitemapNodes}
      />
    </SelectorColumn>
    <SelectorColumn>
      <WorkflowFilterSelector
        filter={props.filter}
        onWorkflowsSelectionChanged={props.onWorkflowsSelectionChanged}
      />
    </SelectorColumn>
    <SelectorColumn>
      <ContributorsFilterSelector
        currentUserId={props.user.info.userId}
        onContributorsSelectionChanged={props.onContributorsSelectionChanged}
        selectedContributors={props.filter.selectedContributorsNodes}
      />
    </SelectorColumn>
    <SelectorColumn>
      <ItemsTaxonomyFilterSelector
        displayWarningStatus={props.displayTaxonomyWarningStatus}
        onTaxonomySelectionChanged={props.onTaxonomySelectionChanged}
        selectedTaxonomyNodes={props.filter.selectedTaxonomyNodes}
      />
    </SelectorColumn>
    <SelectorColumn>
      <ContentItemStatusSelector
        onContentItemStatusSelectionChanged={props.onContentItemStatusSelectionChanged}
        selectedContentItemStatus={props.filter.selectedContentItemStatus}
        user={props.user}
      />
    </SelectorColumn>
    <SelectorColumn>
      <PublishingStatusFilterSelector
        onPublishingStatusSelectionChanged={props.onPublishingStatusSelectionChanged}
        selectedPublishingStateNodes={props.filter.selectedPublishingStateNodes}
      />
    </SelectorColumn>
  </Row>
);

const SelectorColumn = ({ children }: PropsWithChildren) => (
  <Column css="&:empty { display: none;}" width="1/3">
    {children}
  </Column>
);
