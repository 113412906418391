import { isAbortError } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../@types/Dispatcher.type.ts';
import { getActiveLanguageIds } from '../../../_shared/utils/languageUtils.ts';
import { IContentItemRepository } from '../../../repositories/interfaces/IContentItemRepository.type.ts';
import {
  Data_ListingContentItemsWithAllVariants_Failed,
  Data_ListingContentItemsWithAllVariants_Success,
} from '../../constants/dataActionTypes.ts';
import {
  IListingContentItem,
  getListingContentItemFromJS,
} from '../../models/listingContentItems/IListingContentItem.ts';

interface IDeps {
  readonly contentItemRepository: Pick<
    IContentItemRepository,
    'getListingItemsWithVariantsInActiveLanguagesByIds'
  >;
}

const success = (
  itemsByIdsWithAllVariants: ReadonlyMap<Uuid, ReadonlyArray<IListingContentItem>>,
) =>
  ({
    type: Data_ListingContentItemsWithAllVariants_Success,
    payload: { itemsByIdsWithAllVariants },
  }) as const;

const failure = () => ({ type: Data_ListingContentItemsWithAllVariants_Failed }) as const;

export type LoadListingItemsWithAllVariantsType = ReturnType<typeof success | typeof failure>;

export const createLoadListingItemsWithAllVariantsAction =
  ({ contentItemRepository }: IDeps) =>
  (itemIds: UuidArray, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const {
      data: { languages },
    } = getState();

    const activeLanguages = getActiveLanguageIds(languages).toArray();

    try {
      const listingItemsWithAllVariants =
        await contentItemRepository.getListingItemsWithVariantsInActiveLanguagesByIds(
          itemIds,
          activeLanguages,
          abortSignal,
        );
      const itemsByIdsWithAllVariants = new Map(
        itemIds.map((itemId) => [
          itemId,
          listingItemsWithAllVariants.data
            .filter((item) => item.item.id === itemId)
            .map(getListingContentItemFromJS),
        ]),
      );

      dispatch(success(itemsByIdsWithAllVariants));
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(failure());
      }

      throw error;
    }
  };
