import { ReactNode, useContext, useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import { RefinedNavigationContext } from '../../../refinedNavigation/contexts/RefinedNavigationContext.tsx';
import { ProjectSettingsWithMenuLayout } from '../../root/components/ProjectSettingsWithMenuLayout.tsx';
import { ProjectSettingsMenu } from '../../root/containers/ProjectSettingsMenu.tsx';
import { apiKeysListingLeft } from '../actions/apiKeysActions.ts';
import { initApiKeyListing } from '../actions/thunkApiKeysActions.ts';
import { areApiKeysLoaded } from '../selectors/apiKeysLoadedSelectors.ts';

type Props = {
  readonly children: ReactNode;
};

export const EnsureApiKeyListingInitialized = ({ children }: Props) => {
  const [isInitThunkDone] = useThunkPromise(initApiKeyListing);
  const isInitialized = useSelector(areApiKeysLoaded) && isInitThunkDone;

  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(apiKeysListingLeft());
    };
  }, []);
  const { isRefinedNavigationFeatureEnabled } = useContext(RefinedNavigationContext);
  const { url } = useRouteMatch();

  return isInitialized ? (
    children
  ) : (
    <ProjectSettingsWithMenuLayout
      renderContent={() => <Loader />}
      renderSettingsMenu={() =>
        isRefinedNavigationFeatureEnabled ? null : <ProjectSettingsMenu url={url} />
      }
    />
  );
};
