import React from 'react';
import { useWatch } from 'react-hook-form';
import { BarItemActionButtons } from '../../../../../_shared/components/BarItems/BarItemActionButtons.tsx';
import { BarItemExpandedSimpleHeader } from '../../../../../_shared/components/BarItems/BarItemExpandedSimpleHeader.tsx';
import { HotkeysHandler } from '../../../../../_shared/components/Hotkeys/HotkeysHandler.tsx';
import { ShortcutSymbols } from '../../../../../_shared/constants/shortcutSymbols.ts';
import { useScrollIntoViewOnMount } from '../../../../../_shared/hooks/useScrollIntoViewOnMount.ts';
import { HookFormProps } from '../../../../../_shared/types/hookFormProps.type.ts';
import {
  DataUiAction,
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ITaxonomyTermFormShape } from '../../models/ITaxonomyTermFormShape.type.ts';
import { TermFormFields } from './TermFormFields.tsx';

type Props = {
  readonly formProps: HookFormProps<ITaxonomyTermFormShape>;
  readonly onCancelEditing: () => void;
  readonly onRemove: () => void;
  readonly onSubmit: () => void;
};

export const TermEditor: React.FC<Props> = ({ formProps, onCancelEditing, onRemove, onSubmit }) => {
  const scrollRef = useScrollIntoViewOnMount<HTMLDivElement>();
  const taxonomyName = useWatch({ control: formProps.control, name: 'name' });

  return (
    <HotkeysHandler
      className="bar-item__wrapper"
      handlers={{
        onEscape: onCancelEditing,
        onEnter: onSubmit,
      }}
      {...getDataUiElementAttribute(DataUiElement.BarItemForm)}
    >
      <div className="bar-item__pane bar-item__pane--is-expanded" ref={scrollRef}>
        <BarItemExpandedSimpleHeader
          dataUiObjectName={taxonomyName}
          isDraggable
          renderTitle={() => taxonomyName}
        />
        <TermFormFields formProps={formProps} />
        <BarItemActionButtons
          secondaryAction={{
            handler: onCancelEditing,
            text: 'Cancel',
            dataUIActionName: DataUiAction.Cancel,
            shortcut: ShortcutSymbols.Escape,
          }}
          primaryAction={{
            text: 'Confirm',
            dataUIActionName: DataUiAction.Confirm,
            handler: onSubmit,
            shortcut: ShortcutSymbols.Enter,
          }}
          destructiveAction={{
            text: 'Remove',
            dataUIActionName: DataUiAction.Remove,
            handler: onRemove,
            icon: 'Bin',
          }}
        />
      </div>
    </HotkeysHandler>
  );
};

TermEditor.displayName = 'TermEditor';
