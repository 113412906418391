import { AutoScrollProps } from '../../../../../../_shared/components/AutoScroll/autoScrollProps.type.ts';
import { IListingContentItem } from '../../../../../../data/models/listingContentItems/IListingContentItem.ts';
import { LinkDetail } from '../../../../../itemEditor/utils/itemElementDataConverters/utils/LinkDetail.tsx';
import { EditorLinkStatus } from '../../../apiLimitations/api/EditorLinkStatus.ts';
import { NoPermissionsContentLink } from './ContentLinkDetailVariants.tsx';
import { SpecificContentLink } from './SpecificContentLink.tsx';

export type ContentLinkDetailProps = AutoScrollProps &
  Readonly<{
    disabled?: boolean;
    isViewOnly?: boolean;
    onEdit?: () => void;
    onUnlink?: () => void;
    status?: EditorLinkStatus;
  }>;

type ContentLinkDetailComponentProps = ContentLinkDetailProps &
  Readonly<{
    canCreateContent: boolean;
    cannotCreateDisabledMessage: string | undefined;
    cannotViewDisabledMessage: string | undefined;
    canViewContent: boolean;
    contentItem?: IListingContentItem;
    contentItemPath?: string;
    contentTypeName: string | null;
  }>;

export const ContentLinkDetail = ({
  alternativeScrollIds,
  canCreateContent,
  cannotCreateDisabledMessage,
  cannotViewDisabledMessage,
  canViewContent,
  contentItem,
  contentItemPath,
  contentTypeName,
  disabled,
  isViewOnly,
  onEdit,
  onUnlink,
  scrollId,
  scrollOptions,
  status,
}: ContentLinkDetailComponentProps) => {
  return (
    <LinkDetail
      disabled={disabled}
      onEdit={canViewContent ? onEdit : undefined}
      onUnlink={onUnlink}
      status={status}
    >
      {canViewContent ? (
        <SpecificContentLink
          alternativeScrollIds={alternativeScrollIds}
          canCreateContent={canCreateContent}
          cannotCreateDisabledMessage={cannotCreateDisabledMessage}
          cannotViewDisabledMessage={cannotViewDisabledMessage}
          contentItem={contentItem}
          contentItemPath={contentItemPath}
          contentTypeName={contentTypeName}
          isViewOnly={isViewOnly}
          scrollId={scrollId}
          scrollOptions={scrollOptions}
        />
      ) : (
        <NoPermissionsContentLink />
      )}
    </LinkDetail>
  );
};
