import { memoize } from '@kontent-ai/memoization';
import { add } from 'date-fns';
import { SampleProjectType } from '../../applications/projects/constants/sampleProjectType.ts';
import { ICurrentUserInfo } from '../../data/models/user/CurrentUserInfo.ts';
import {
  parseDatetime,
  shiftByUTCOffsetForward,
} from '../components/DatetimePicker/InternalFiles/datetimeUtils.ts';
import { getSampleProjectType } from '../models/utils/quickstartUtils.ts';
import { IStore } from '../stores/IStore.type.ts';

function hasAccountLessDaysThan(numberOfDays: number, userInfo: ICurrentUserInfo): boolean {
  const { isValid, value } = parseDatetime(userInfo.createdAt);
  if (!isValid) {
    return false;
  }

  return add(shiftByUTCOffsetForward(value), { days: numberOfDays }) > new Date();
}

export const isAccountLessThan30DaysOld = memoize.maxAge(
  (userInfo: ICurrentUserInfo): boolean => hasAccountLessDaysThan(30, userInfo),
  // Cache for 1 hour, so it can still change if the user account gets older while staying in the browser
  60 * 60 * 1000,
  { maxSize: 1 },
);

export enum QuickstartType {
  Kickstart = 'Kickstart',
}

export function getProjectQuickstartType(state: IStore): QuickstartType | null {
  switch (getSampleProjectType(state.sharedApp.projectProperties)) {
    case SampleProjectType.Kickstart:
      return QuickstartType.Kickstart;
    case SampleProjectType.HealthTech:
    case null:
      return null;
  }
}
