import classNames from 'classnames';
import React from 'react';

interface ITypeElementNameStatus {
  readonly currentValue: number;
  readonly maxLength: number;
  readonly isHidden: boolean;
}

export const TypeElementNameStatus: React.FC<ITypeElementNameStatus> = ({
  currentValue,
  maxLength,
  isHidden,
}) => {
  const isLimitValueMet = currentValue <= maxLength;

  return (
    <div
      className={classNames('content-element-name__status', {
        'content-element-name__status--is-hidden': isHidden,
      })}
    >
      <div className="content-element-name__status-pane content-element-name__status-pane--right">
        <span
          className={classNames('content-element-name__status-item', {
            'content-element-name__status-item--is-invalid': !isLimitValueMet,
          })}
        >
          {currentValue}
        </span>
        {`/${maxLength} characters`}
      </div>
    </div>
  );
};

TypeElementNameStatus.displayName = 'TypeElementNameStatus';
