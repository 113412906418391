import { Icons } from '@kontent-ai/component-library/Icons';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import {
  BaseColor,
  IconSize,
  Typography,
  colorAlertText,
  colorTextDefault,
  colorTextDisabled,
  colorTextLowEmphasis,
  colorWarningIcon,
} from '@kontent-ai/component-library/tokens';
import classNames from 'classnames';
import React, { ReactNode, forwardRef } from 'react';
import styled from 'styled-components';
import { DataUiElement } from '../../utils/dataAttributes/DataUiAttributes.ts';
import { IgnoreByGrammarly } from '../IgnoreByGrammarly.tsx';
import { BarItemContent } from './BarItemContent.tsx';

type BarItemTitleStyle =
  | 'primary'
  | 'primary-disabled'
  | 'primary-redacted'
  | 'secondary'
  | 'secondary-alert';

const primaryStyles: ReadonlyArray<BarItemTitleStyle> = [
  'primary',
  'primary-disabled',
  'primary-redacted',
];

const getColor = (titleStyle: BarItemTitleStyle): BaseColor => {
  switch (titleStyle) {
    case 'primary':
      return colorTextDefault;
    case 'secondary':
    case 'primary-redacted':
      return colorTextLowEmphasis;
    case 'primary-disabled':
      return colorTextDisabled;
    case 'secondary-alert':
      return colorAlertText;
  }
};

const StyledBarItemContent = styled(BarItemContent)<{
  readonly titleStyle: BarItemTitleStyle;
}>`
  ${Typography.Subheadline};
  color: ${({ titleStyle }) => getColor(titleStyle)};
  font-style: ${({ titleStyle }) => (titleStyle === 'primary-redacted' ? 'italic' : 'normal')};
`;

const StyledIgnoreByGrammarly = styled(IgnoreByGrammarly)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

type Props = {
  readonly children: ReactNode;
  readonly className?: string;
  readonly dataUiElement?: DataUiElement;
  readonly flexFactor?: number;
  readonly isUnfinished?: boolean;
  readonly onClick?: () => void;
  readonly suffix?: React.ReactNode;
  readonly title?: string;
  readonly titleStyle?: BarItemTitleStyle;
  readonly tooltipText?: string;
};

const SuffixWrapper = styled.div`
  color: ${colorTextDefault};
  display: inline-block;
`;

export const BarItemTitle = forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      className,
      dataUiElement,
      flexFactor,
      isUnfinished,
      onClick,
      title,
      titleStyle = 'primary',
      suffix,
      tooltipText,
    },
    ref,
  ) => {
    const isPrimary = primaryStyles.includes(titleStyle);

    return (
      <Tooltip text={tooltipText} placement="top-start">
        <StyledBarItemContent
          className={classNames(className, 'bar-item__title')}
          dataUiElement={dataUiElement}
          flexFactor={flexFactor ?? (isPrimary ? 2 : 1)}
          isCompact
          onClick={onClick}
          title={title}
          titleStyle={titleStyle}
          ref={ref}
        >
          {isUnfinished && <Icons.SmallDotCentered color={colorWarningIcon} size={IconSize.S} />}
          <StyledIgnoreByGrammarly
            className={classNames({
              'bar-item__title-name': isPrimary,
              'bar-item__title-name--is-redacted': titleStyle === 'primary-redacted',
            })}
          >
            {children}
          </StyledIgnoreByGrammarly>
          {suffix ? <SuffixWrapper>{suffix}</SuffixWrapper> : null}
        </StyledBarItemContent>
      </Tooltip>
    );
  },
);

BarItemTitle.displayName = 'BarItemTitle';
