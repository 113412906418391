import { Button } from '@kontent-ai/component-library/Button';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React, { useCallback, useMemo, useState } from 'react';
import { ModalViewer } from '../../../../_shared/components/Modal/ModalViewer.tsx';
import { ModalViewerPosition } from '../../../../_shared/components/Modal/ModalViewerPosition.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IContentType } from '../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { PreselectedFilter } from '../../../contentInventory/content/features/ListingFilter/hooks/useSetUpContentItemFilter.ts';
import { ItemIsAlreadyLinked } from '../../../features/ModalContentItemSelector/constants/uiConstants.ts';
import { ModalMultipleContentItemsSelector } from '../../../features/ModalContentItemSelector/containers/ModalMultipleContentItemsSelector.tsx';
import { insufficientPermissionsForLinkedItemsDefaultValue } from '../../shared/constants/uiConstants.ts';
import { ILinkedItemsTypeElementData } from '../../shared/models/elements/LinkedItemsTypeElementData.ts';

interface ILinkedItemsDefaultValueActionsPaneProps {
  readonly allowedContentTypes: Immutable.Set<IContentType>;
  readonly alreadyLinkedItemIds: ReadonlyArray<Uuid>;
  readonly canAddExistingItems: boolean;
  readonly onAddExistingItems: (itemsIds: ReadonlyArray<Uuid>) => void;
  readonly typeElementData: ILinkedItemsTypeElementData;
}

export const LinkedItemsDefaultValueActionsPane: React.FC<
  ILinkedItemsDefaultValueActionsPaneProps
> = ({
  allowedContentTypes,
  alreadyLinkedItemIds,
  canAddExistingItems,
  onAddExistingItems,
  typeElementData,
}) => {
  const [isLinkItemsModalOpened, setIsLinkItemsModalOpened] = useState(false);

  const onAddSelectedItems = (itemsIds: ReadonlyArray<Uuid>): void => {
    onAddExistingItems(itemsIds);
    closeLinkItemsModal();
  };

  const closeLinkItemsModal = useCallback((): void => {
    setIsLinkItemsModalOpened(false);
  }, []);

  const initiateLinkingExistingItems = useCallback((): void => {
    setIsLinkItemsModalOpened(true);
  }, []);

  const preselectedFilter: PreselectedFilter = useMemo(
    () => ({
      selectedContentTypesNodes: new Set(allowedContentTypes.map((type) => type.id).toArray()),
    }),
    [allowedContentTypes],
  );

  const alreadyLinkedItemIdsSet = useMemo(
    () => Immutable.Set<Uuid>(alreadyLinkedItemIds),
    [alreadyLinkedItemIds],
  );

  return (
    <>
      <Inline spacing={Spacing.S}>
        <Button
          tooltipText={
            canAddExistingItems ? undefined : insufficientPermissionsForLinkedItemsDefaultValue
          }
          tooltipPlacement="right"
          autoFocus
          disabled={!canAddExistingItems}
          buttonStyle="tertiary"
          size="small"
          onClick={initiateLinkingExistingItems}
          {...getDataUiActionAttribute(DataUiAction.Add)}
        >
          Select content items
        </Button>
      </Inline>
      <ModalViewer
        dialogClassName="dialog"
        isDialogVisible={isLinkItemsModalOpened}
        onClose={closeLinkItemsModal}
        position={ModalViewerPosition.Center}
      >
        <ModalMultipleContentItemsSelector
          alreadyLinkedItemIds={alreadyLinkedItemIdsSet}
          hideVariantSpecificInfo
          itemIsAlreadyLinkedMsg={ItemIsAlreadyLinked}
          onClose={closeLinkItemsModal}
          onSelect={onAddSelectedItems}
          preselectedFilter={preselectedFilter}
          submitButtonText="Add selected items as default value"
          titleBarText={`Select content items to use as default value in ${typeElementData.name}`}
        />
      </ModalViewer>
    </>
  );
};

LinkedItemsDefaultValueActionsPane.displayName = 'LinkedItemsDefaultValueActionsPane';
