import { useMemo } from 'react';
import { useHistory } from 'react-router';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import {
  EditContentTypeSnippetRoute,
  EditContentTypeSnippetRouteParams,
} from '../../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { buildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { getElementsWithAffectedLocalization } from '../../shared/utils/getElementsWithAffectedLocalization.ts';
import { snippetSetFirstInvalidElement } from '../actions/snippetsActions.ts';
import {
  createContentTypeSnippet,
  initContentTypeSnippetCreator,
} from '../actions/thunkSnippetsActions.ts';
import { ContentTypeSnippetEditor as ContentTypeSnippetCreatorComponent } from '../components/ContentTypeSnippetEditor.tsx';
import { getSnippetsAppEditor } from '../selectors/getSnippetsAppEditor.ts';

export const ContentTypeSnippetCreator = () => {
  const [isInitThunkDone] = useThunkPromise(initContentTypeSnippetCreator);

  const dispatch = useDispatch();

  const unsavedNavigationHandler = async (
    onSuccess: () => void,
    onFail: () => void,
  ): Promise<void> => {
    try {
      await dispatch(createContentTypeSnippet());
      onSuccess();
    } catch {
      onFail();
    }
  };

  const history = useHistory();
  const projectId = useSelector((s) => s.sharedApp.currentProjectId);

  const onSaveContentTypeSnippet = async (): Promise<void> => {
    try {
      const createdSnippet = await dispatch(createContentTypeSnippet());
      history.push(
        buildPath<EditContentTypeSnippetRouteParams>(EditContentTypeSnippetRoute, {
          projectId,
          contentTypeSnippetId: createdSnippet.id,
        }),
      );
    } catch {
      return;
    }
  };

  const onSetFirstInvalidElement = (elementId: Uuid) => {
    dispatch(snippetSetFirstInvalidElement(elementId));
  };

  const editedContentTypeSnippet = useSelector(
    (state) => getSnippetsAppEditor(state).editedContentTypeSnippet,
  );
  const hasUnsavedChanges = useSelector(
    (state) => getSnippetsAppEditor(state).editedContentTypeSnippetIsModified,
  );
  const isBeingSaved = useSelector(
    (state) => getSnippetsAppEditor(state).contentTypeSnippetIsBeingSaved,
  );
  const allSet = useSelector(
    (state) =>
      isInitThunkDone &&
      getSnippetsAppEditor(state).contentTypeSnippetEditingLoadingStatus === LoadingStatus.Loaded,
  );
  const showSnippetUsedWithPublishedItemWarning = useSelector(
    (state) => getSnippetsAppEditor(state).isEditedContentTypeSnippetUsedInPublishedItem,
  );
  const validationResults = useSelector((state) => getSnippetsAppEditor(state).validationResults);

  const editedTypeSnippet = useSelector(
    (s) => s.contentModelsApp.snippets.editor.editedContentTypeSnippet,
  );
  const originalTypeSnippetElements = useSelector(
    (s) => s.data.snippets.byId.get(editedTypeSnippet.id)?.typeElements,
  );

  const elementsWithAffectedLocalization = useMemo(
    () => getElementsWithAffectedLocalization(editedTypeSnippet, originalTypeSnippetElements),
    [editedTypeSnippet, originalTypeSnippetElements],
  );

  if (!allSet) {
    return <Loader />;
  }

  return (
    <ContentTypeSnippetCreatorComponent
      elementsWithAffectedLocalization={elementsWithAffectedLocalization}
      editedContentTypeSnippet={editedContentTypeSnippet}
      focusSnippetName
      hasUnsavedChanges={hasUnsavedChanges}
      isBeingSaved={isBeingSaved}
      onUnsavedNavigation={unsavedNavigationHandler}
      onSaveContentTypeSnippet={onSaveContentTypeSnippet}
      onSetFirstInvalidElement={onSetFirstInvalidElement}
      showSnippetUsedWithPublishedItemWarning={showSnippetUsedWithPublishedItemWarning}
      validationResults={validationResults}
    />
  );
};
