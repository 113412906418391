import React from 'react';
import { Sidebar } from '../../../../../_shared/components/Sidebar/Sidebar.tsx';
import { SidebarSection } from '../../../../../_shared/components/Sidebar/SidebarSection.tsx';
import { SidebarSubmitButtonWithErrorMessage } from '../../../../../_shared/components/Sidebar/SidebarSubmitButtonWithErrorMessage.tsx';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { SubscriptionUsers } from '../../containers/promoteUsersModal/SubscriptionUsers.tsx';

export interface IPromoteUsersModalStateProps {
  readonly showSidebar: boolean;
  readonly selectedSubscriptionUsers: ReadonlySet<UserId>;
  readonly subscriptionId: Uuid | null;
  readonly promotingSubscriptionAdmin: boolean;
  readonly errorMessage: string;
}

export interface IPromoteUsersModalDispatchProps {
  readonly closeDialog: () => void;
  readonly promoteUsers: (subscriptionId: Uuid, userIds: ReadonlySet<UserId>) => void;
}

type PromoteUsersModalProps = IPromoteUsersModalStateProps & IPromoteUsersModalDispatchProps;

export const PromoteUsersModal: React.FC<PromoteUsersModalProps> = ({
  subscriptionId,
  promoteUsers,
  selectedSubscriptionUsers,
  showSidebar,
  closeDialog,
  promotingSubscriptionAdmin,
  errorMessage,
}) => {
  if (!subscriptionId) {
    return <noscript />;
  }

  const isSubmittingDisabled = promotingSubscriptionAdmin || selectedSubscriptionUsers.size < 1;
  const onSubmit = () => promoteUsers(subscriptionId, selectedSubscriptionUsers);

  const title = (
    <>
      Invite subscription admins
      <br />
      to work with subscription
    </>
  );

  const submitButton = (
    <>
      <div className="sidebar__submit-details">
        <p>You can promote any of these users without biting off your user quota.</p>
      </div>
      <SidebarSubmitButtonWithErrorMessage
        onSubmit={onSubmit}
        buttonLabel={promotingSubscriptionAdmin ? 'Promoting users' : 'Promote users to admin'}
        dataUiAttribute={getDataUiActionAttribute(DataUiAction.PromoteUserToAdmin)}
        disabled={isSubmittingDisabled}
        errorMessage={errorMessage}
        isBeingSubmitted={promotingSubscriptionAdmin}
      />
    </>
  );

  return (
    <Sidebar
      isVisible={showSidebar}
      onClose={closeDialog}
      onSubmit={isSubmittingDisabled ? undefined : onSubmit}
      titleElement={title}
      submitElement={submitButton}
    >
      <SidebarSection title="Select users">
        <SubscriptionUsers />
      </SidebarSection>
    </Sidebar>
  );
};

PromoteUsersModal.displayName = 'PromoteUsersModal';
