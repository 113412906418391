import { noOperation } from '@kontent-ai/utils';
import {
  CompositeDecorator,
  DraftBlockRenderConfig,
  DraftHandleValue,
  Editor as DraftJSEditor,
  EditorProps as DraftJSEditorProps,
  EditorState,
} from 'draft-js';
import Immutable from 'immutable';
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import styled from 'styled-components';
import { DeferAutoScrollCssClass } from '../../../../_shared/utils/autoScrollUtils.ts';
import { useEditorApi } from '../../editorCore/hooks/useEditorApi.ts';
import { useEditorStateCallbacks } from '../../editorCore/hooks/useEditorStateCallbacks.ts';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { useGetEditorRef } from '../../editorCore/hooks/useGetEditorRef.ts';
import { CanUpdateContent, IsEditorLocked } from '../../editorCore/types/Editor.base.type.ts';
import { PluginComponent } from '../../editorCore/types/Editor.composition.type.ts';
import { Apply, Finalize, Render } from '../../editorCore/types/Editor.plugins.type.ts';
import {
  EditorChangeReason,
  internalChangeReasons,
} from '../../editorCore/types/EditorChangeReason.ts';
import { Decorator, decorable } from '../../editorCore/utils/decorable.ts';
import { draftJsEditorApi } from '../../plugins/draftJs/api/draftJsEditorApi.ts';
import { BaseBlockType } from '../../utils/blocks/blockType.ts';
import { emptyEditorState } from '../../utils/general/editorEmptyValues.ts';
import { applyUndoRedoStacks, disableUndo } from '../undoRedo/api/editorUndoUtils.ts';
import {
  ApplyEditorStateChanges,
  Change,
  DraftJsPlugin as DraftJsPluginType,
  ExecuteChange,
  ExecuteExternalAction,
  GetBaseBlockRenderMap,
  GetInitialState,
  InternalEditorProps,
  OnUpdate,
  RemoveInvalidState,
  UndoState,
} from './DraftJsPlugin.type.ts';
import { BaseBlockRenderMap } from './utils/draftJsEditorUtils.ts';

type DraftJsPlugin = DraftJsPluginType;

const invalidEditorState = EditorState.createEmpty();

export const DraftJsPlugin: PluginComponent<DraftJsPlugin> = (props) => {
  const {
    decorateWithEditorStateCallbacks,
    getInitialState,
    canUpdateContent,
    applyEditorStateChanges,
    onUpdate,
    getApi,
  } = useEditorStateCallbacks<DraftJsPlugin>();

  const init = useCallback(
    (editorState: EditorState) => {
      const completeInitState = getInitialState(editorState);

      const completeEditorState = EditorState.createWithContent(
        completeInitState.content,
        completeInitState.decorators.length
          ? new CompositeDecorator([...completeInitState.decorators])
          : undefined,
      );

      const withUndoStack =
        completeInitState.undo !== UndoState.DisabledDropHistory
          ? applyUndoRedoStacks(editorState, completeEditorState)
          : editorState;

      const withUndoFlag =
        completeInitState.undo !== UndoState.EnabledKeepHistory
          ? disableUndo(withUndoStack)
          : withUndoStack;

      updateEditorState(() => withUndoFlag);
    },
    [getInitialState],
  );

  const [editorState, setEditorState] = useState<EditorState>(invalidEditorState);

  // We need to keep reference to the last editor state because we don't want to rebuild methods that are just reading it upon every small change
  // We also need to read it in the executeChange pipeline because we can't trigger complex actions with side effects from setEditorState callback
  // which may violate rendering pre-conditions (render other components while Editor is in the middle of rendering phase)
  const editorStateRef = useRef(editorState ?? emptyEditorState);
  const updateEditorState = useCallback((change: Change) => {
    const newEditorState = change(editorStateRef.current);
    editorStateRef.current = newEditorState;

    setEditorState(newEditorState);
  }, []);

  const getEditorState = useCallback(() => editorStateRef.current, []);

  const initialEditorState = useRef(props.editorState).current;
  useLayoutEffect(() => {
    init(initialEditorState);
  }, [init, initialEditorState]);

  const [isExternalActionInProgress, setIsExternalActionInProgress] = useState(false);

  const getEditorRef = useGetEditorRef<DraftJSEditor>();

  const isEditorLockedDecorator: Decorator<IsEditorLocked> = useCallback(
    (baseIsEditorLocked) => () => isExternalActionInProgress || baseIsEditorLocked(),
    [isExternalActionInProgress],
  );

  const executeChange: ExecuteChange = useCallback(
    async (change, changeReason = EditorChangeReason.Regular) =>
      await new Promise<EditorState>((resolve, reject) => {
        const update = () =>
          updateEditorState((currentEditorState) => {
            try {
              const newEditorState = change(currentEditorState);
              const allowEditContent = canUpdateContent(changeReason);
              const allowedNewState = applyEditorStateChanges({
                newState: newEditorState,
                oldState: currentEditorState,
                allowEditContent,
                changeReason,
              });

              resolve(allowedNewState);

              if (allowedNewState !== currentEditorState) {
                onUpdate({
                  editorState: allowedNewState,
                  changeReason,
                });
              }

              return allowedNewState;
            } catch (e) {
              reject(e);
              throw e;
            }
          });

        if (changeReason === EditorChangeReason.Native) {
          // The internal change already has the editorState applied to the editor instance
          update();
        } else {
          // In case of explicit user action we need to force rendering to make sure that DraftJS editor
          // instance gets updated editorState before any of its own internal events
          // such as focus that triggers native change
          flushSync(update);
        }
      }),
    [onUpdate, canUpdateContent, applyEditorStateChanges, updateEditorState],
  );

  const onNativeChange: DraftJSEditorProps['onChange'] = useCallback(
    (newEditorState) => {
      executeChange(() => newEditorState, EditorChangeReason.Native);
    },
    [executeChange],
  );

  const executeExternalAction: ExecuteExternalAction = useCallback(
    async (action, changeReason = EditorChangeReason.Internal) => {
      // External action is an async action that modifies editorState. For the time of the action execution the editor is disabled
      setIsExternalActionInProgress(true);

      try {
        const newEditorState = await action(getEditorState());
        return await executeChange(() => newEditorState, changeReason);
      } finally {
        setIsExternalActionInProgress(false);
      }
    },
    [executeChange, getEditorState],
  );

  const handleBeforeInput = useCallback<Required<DraftJSEditorProps>['handleBeforeInput']>(
    (_, currentEditorState) => {
      const selection = currentEditorState.getSelection();
      if (!selection.getHasFocus() || !canUpdateContent()) {
        return 'handled';
      }

      return 'not-handled';
    },
    [canUpdateContent],
  );

  const baseApplyEditorStateChanges: ApplyEditorStateChanges = useCallback(
    ({ newState, oldState, allowEditContent, changeReason }) =>
      getApi().applyEditorStateChanges(newState, oldState, allowEditContent, changeReason),
    [getApi],
  );

  const canUpdateContentDecorator: Decorator<CanUpdateContent> = useCallback(
    (baseCanUpdateContent) => (changeReason) =>
      (internalChangeReasons.has(changeReason ?? EditorChangeReason.Regular) ||
        !isExternalActionInProgress) &&
      baseCanUpdateContent(changeReason),
    [isExternalActionInProgress],
  );

  const editorProps: InternalEditorProps = useMemo(
    () => ({
      blockRenderMap: initialBlockRenderMap,
      editorState,
      handleBeforeInput,
      handleDrop: disableDefaultDndBehaviour,
      handleDroppedFiles: disableDefaultDndBehaviour,
      onChange: onNativeChange,
      spellCheck: true,
      stripPastedStyles: true,
      ariaDescribedBy: props.ariaDescribedBy,
      ariaLabel: props.ariaLabel,
      ariaLabelledBy: props.ariaLabelledBy,
    }),
    [
      editorState,
      handleBeforeInput,
      onNativeChange,
      props.ariaDescribedBy,
      props.ariaLabel,
      props.ariaLabelledBy,
    ],
  );

  const apply: Apply<DraftJsPlugin> = useCallback(
    (state) => {
      decorateWithEditorStateCallbacks(state);
      state.canUpdateContent.decorate(canUpdateContentDecorator);
      state.isEditorLocked.decorate(isEditorLockedDecorator);
      state.render.decorate(renderDecorator);

      return {
        applyEditorStateChanges: decorable(baseApplyEditorStateChanges),
        executeExternalAction,
        executeChange,
        getBaseBlockRenderMap: decorable(baseGetInitialBaseBlockRenderMap),
        getEditorState,
        getEditorRef,
        getInitialState: decorable(baseGetInitialState),
        onUpdate: decorable<OnUpdate>(noOperation),
        reinit: init,
        removeInvalidState: decorable<RemoveInvalidState>(noOperation),
        editorState,
        editorProps,
      };
    },
    [
      baseApplyEditorStateChanges,
      canUpdateContentDecorator,
      decorateWithEditorStateCallbacks,
      editorProps,
      editorState,
      executeChange,
      executeExternalAction,
      getEditorRef,
      getEditorState,
      isEditorLockedDecorator,
      init,
    ],
  );

  const { getApiMethods } = useEditorApi<DraftJsPlugin>(draftJsEditorApi);

  return useEditorWithPlugin(props, { apply, finalize, getApiMethods });
};

const baseGetInitialState: GetInitialState = (initialEditorState) => ({
  initialEditorState,
  content: initialEditorState.getCurrentContent(),
  decorators: [],
  // For content components it is important to apply undo stack within initial component mount because it may be caused by undo of component deletion
  // for regular inputs we don't typically expect undo stack to be in source data, but for consistency we use it as well (just in case)
  undo: UndoState.EnabledKeepHistory,
});

const initialBlockRenderMap: BaseBlockRenderMap = Immutable.Map<
  BaseBlockType,
  DraftBlockRenderConfig
>({
  [BaseBlockType.Unstyled]: {
    element: 'div',
    aliasedElements: ['p'],
  },
});

const baseGetInitialBaseBlockRenderMap: GetBaseBlockRenderMap = () => initialBlockRenderMap;

const disableDefaultDndBehaviour = (): DraftHandleValue => 'handled';

const renderDecorator: Decorator<Render<DraftJsPlugin>> = (baseRender) => (state) => (
  <>
    {baseRender(state)}
    <DraftJSEditor key={state.getEditorId()} ref={state.getEditorRef()} {...state.editorProps} />
  </>
);

const finalize: Finalize<DraftJsPlugin> = (state) => {
  state.render.decorate(finalRenderDecorator);
};

const InvisibleDiv = styled.div`display: none`;

const finalRenderDecorator: Decorator<Render<DraftJsPlugin>> = (baseRender) => (state) => {
  if (state.editorState === invalidEditorState) {
    return <InvisibleDiv className={DeferAutoScrollCssClass} />;
  }

  const stateWithFinalRenderMap = {
    ...state,
    editorProps: {
      ...state.editorProps,
      blockRenderMap: state.getBaseBlockRenderMap(),
    },
  };

  return baseRender(stateWithFinalRenderMap);
};
