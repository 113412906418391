import { Button } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Inline } from '@kontent-ai/component-library/Inline';
import { NotificationBarAlert } from '@kontent-ai/component-library/NotificationBar';
import { Spacing } from '@kontent-ai/component-library/tokens';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ElementTranslationStatus } from '../../../../../../_shared/utils/translations/variantTranslationStatus.ts';
import { TypeElement } from '../../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';

const getTranslationErrorMessage = (
  translationStatusPerElement: ReadonlyMap<Uuid, ElementTranslationStatus>,
  typeElements: ReadonlyArray<TypeElement>,
) => {
  const failedElementIds = [...translationStatusPerElement]
    .filter(([, status]) => status === ElementTranslationStatus.Failed)
    .map(([id]) => id);
  const failedElementNames = typeElements
    .filter((typeElement) => failedElementIds.includes(typeElement.elementId))
    .map((typeElement) => typeElement.name);

  const failedElementMessage =
    failedElementIds.length === 1
      ? ` for the following element: ${failedElementNames[0]}`
      : ` for the following elements: ${failedElementNames.join(', ')}`;

  return `The AI couldn’t finish the translation ${failedElementMessage}`;
};

type Props = {
  readonly onRetry: () => void;
  readonly onClose: () => void;
  readonly translationStatusPerElement: ReadonlyMap<Uuid, ElementTranslationStatus> | null;
  readonly typeElements: ReadonlyArray<TypeElement>;
};

export const TranslationErrorNotificationBar = ({
  onClose,
  translationStatusPerElement,
  onRetry,
  typeElements,
}: Props) => (
  <NotificationBarAlert
    onDismiss={onClose}
    {...getDataUiElementAttribute(DataUiElement.NotificationBarInfo)}
  >
    <Inline noWrap spacing={Spacing.M} align="center">
      {translationStatusPerElement &&
        getTranslationErrorMessage(translationStatusPerElement, typeElements)}
      <Button buttonStyle="secondary-inverse" size="small" onClick={onRetry}>
        <Button.Icon icon={Icons.RotateLeft} />
        Retry
      </Button>
    </Inline>
  </NotificationBarAlert>
);
