import { Button, IconButton, QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Menu } from '@kontent-ai/component-library/Menu';
import { RefObject } from 'react';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { RefreshPreviewAction } from '../containers/previewActions/RefreshPreviewAction.tsx';
import { ToggleAutoRefreshPreview } from '../containers/previewActions/ToggleAutoRefreshPreview.tsx';
import { ToggleEditableElements } from '../containers/previewActions/ToggleEditableElements.tsx';

type Props = Readonly<{
  isCompact?: boolean;
  isWebSpotlightIntegrationEnabled: boolean;
}>;

const oldLabel = 'Actions';

export const OldPreviewActionsMenu = ({ isCompact, isWebSpotlightIntegrationEnabled }: Props) => {
  return (
    <Menu>
      <Menu.Trigger>
        {(ref: RefObject<HTMLButtonElement>, triggerProps, isOpen) =>
          isCompact ? (
            <IconButton
              aria-label={oldLabel}
              buttonStyle="secondary"
              iconName="Ellipsis"
              ref={ref}
              size="medium"
              tooltipPlacement="bottom-end"
              tooltipText={oldLabel}
              {...triggerProps}
              {...getDataUiActionAttribute(DataUiAction.OpenPreviewMoreActions)}
            />
          ) : (
            <Button
              activated={isOpen}
              buttonStyle="secondary"
              ref={ref}
              {...triggerProps}
              {...getDataUiActionAttribute(DataUiAction.OpenPreviewMoreActions)}
            >
              <Button.Icon icon={Icons.Ellipsis} />
              <Button.Label>{oldLabel}</Button.Label>
            </Button>
          )
        }
      </Menu.Trigger>
      <Menu.List>
        {isWebSpotlightIntegrationEnabled && <ToggleEditableElements />}
        <ToggleAutoRefreshPreview />
        <RefreshPreviewAction />
      </Menu.List>
    </Menu>
  );
};

const label = 'Preview actions';

export const PreviewActionsMenu = ({ isWebSpotlightIntegrationEnabled }: Props) => {
  return (
    <Menu>
      <Menu.Trigger>
        {(ref: RefObject<HTMLButtonElement>, triggerProps, isOpen) => {
          return (
            <QuinaryButton
              activated={isOpen}
              aria-label={label}
              ref={ref}
              tooltipPlacement="top"
              tooltipText={label}
              {...triggerProps}
              {...getDataUiActionAttribute(DataUiAction.OpenPreviewMoreActions)}
            >
              <QuinaryButton.Icon icon={Icons.Ellipsis} />
            </QuinaryButton>
          );
        }}
      </Menu.Trigger>
      <Menu.List>
        {isWebSpotlightIntegrationEnabled && <ToggleEditableElements />}
        <ToggleAutoRefreshPreview />
        <RefreshPreviewAction />
      </Menu.List>
    </Menu>
  );
};
