import { useContext } from 'react';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { isWebSpotlightIntegrationEnabled as isWebSpotlightIntegrationEnabledSelector } from '../../../../../../../_shared/selectors/isWebSpotlightIntegrationEnabled.tsx';
import { PreviewApiDataStatus } from '../../../../../../webSpotlight/models/PreviewApiDataStatus.ts';
import { getPreviewApiDataStatus } from '../../../../../../webSpotlight/selectors/webSpotlightSelectors.ts';
import { ItemEditingNewDesignContext } from '../../../context/ItemEditingNewDesignContext.tsx';
import {
  ContentItemPreviewActionBar as ContentItemPreviewActionBarComponent,
  OldContentItemPreviewActionBar,
} from '../components/ContentItemPreviewActionBar.tsx';
import { useWebSpotlightInItemEditing } from '../context/WebSpotlightInItemEditingContext.tsx';

type Props = Readonly<{
  spaceId: Uuid | null;
}>;

export const ContentItemPreviewActionBar = ({ spaceId }: Props) => {
  const { isAutoRefreshAvailable, isAutoRefreshEnabledByUser, supportedSmartLinkFeatures } =
    useWebSpotlightInItemEditing();

  const isWebSpotlightIntegrationEnabled = useSelector(isWebSpotlightIntegrationEnabledSelector);
  const isRefreshing = useSelector(
    (s) => getPreviewApiDataStatus(s) === PreviewApiDataStatus.Outdated,
  );
  const isNewDesign = useContext(ItemEditingNewDesignContext);

  if (!isNewDesign) {
    return (
      <OldContentItemPreviewActionBar
        spaceId={spaceId}
        isWebSpotlightIntegrationEnabled={isWebSpotlightIntegrationEnabled}
        isAutoRefreshAvailable={isAutoRefreshAvailable}
        isAutoRefreshEnabled={
          isAutoRefreshEnabledByUser && !!supportedSmartLinkFeatures?.refreshHandler
        }
        isRefreshing={isRefreshing}
      />
    );
  }

  return (
    <ContentItemPreviewActionBarComponent
      spaceId={spaceId}
      isWebSpotlightIntegrationEnabled={isWebSpotlightIntegrationEnabled}
      isAutoRefreshAvailable={isAutoRefreshAvailable}
      isAutoRefreshEnabled={
        isAutoRefreshEnabledByUser && !!supportedSmartLinkFeatures?.refreshHandler
      }
      isRefreshing={isRefreshing}
    />
  );
};
