import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Divider, DividerDirection } from '@kontent-ai/component-library/Dividers';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { memo } from 'react';
import { ContentItemId } from '../../../../../../_shared/models/ContentItemId.ts';
import {
  DataUiAction,
  DataUiCommentsAction,
  getDataUiActionAttribute,
  getDataUiCommentActionAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getCommentLink } from '../../../../utils/commentUtils.ts';
import { CommentContextMenu } from './CommentContextMenu.tsx';
import {
  CommentThreadNavigation,
  CommentThreadNavigationProps,
} from './threadItem/CommentThreadNavigation.tsx';

type Props = CommentThreadNavigationProps & {
  readonly currentProjectId: Uuid;
  readonly editedItemVariantId: ContentItemId | null;
  readonly onHideComments: () => void;
  readonly onLinkCopied: () => void;
  readonly onResolve: () => void;
  readonly statusId: string;
  readonly threadId: Uuid;
};

export const InlineCommentThreadHeader = memo(
  ({
    currentProjectId,
    editedItemVariantId,
    onHideComments,
    onLinkCopied,
    onResolve,
    statusId,
    threadId,
    ...navigationProps
  }: Props) => {
    return (
      <Divider direction={DividerDirection.Horizontal} offsetBefore={Spacing.L} offsetAfter={0}>
        <Row alignY="center">
          <Column>
            <CommentThreadNavigation statusId={statusId} threadId={threadId} {...navigationProps} />
          </Column>
          <Column width="content">
            <Inline spacing={Spacing.XS} noWrap>
              <QuinaryButton
                onClick={(e) => {
                  e.stopPropagation();
                  onResolve();
                }}
                tooltipText="Resolve"
                {...getDataUiCommentActionAttribute(DataUiCommentsAction.MarkAsResolved)}
              >
                Resolve
              </QuinaryButton>
              {editedItemVariantId && (
                <CommentContextMenu
                  commentLink={getCommentLink(currentProjectId, editedItemVariantId, threadId)}
                  onLinkCopied={onLinkCopied}
                />
              )}
              <QuinaryButton
                onClick={(e) => {
                  e.stopPropagation();
                  onHideComments();
                }}
                tooltipText="Hide comments"
                {...getDataUiActionAttribute(DataUiAction.HideComments)}
              >
                <QuinaryButton.Icon icon={Icons.Times} screenReaderText="Hide comments" />
              </QuinaryButton>
            </Inline>
          </Column>
        </Row>
      </Divider>
    );
  },
);

InlineCommentThreadHeader.displayName = 'InlineCommentThreadHeader';
