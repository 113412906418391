import { Loader } from '../../../../_shared/components/Loader.tsx';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import { getSelectedSubscription } from '../../../../_shared/selectors/subscriptionSelectors.ts';
import { emptySubscription } from '../../../../data/models/subscriptions/Subscription.ts';
import {
  loadSubscriptionBalance,
  loadSubscriptionTransactions,
} from '../actions/thunkTransactionsActions.ts';
import { Transactions as TransactionsComponent } from '../components/Transactions.tsx';

export const Transactions = () => {
  const selectedSubscription = useSelector((s) => getSelectedSubscription(s) ?? emptySubscription);

  const currentPlan = selectedSubscription.currentPlan;
  const isPrepaid = useSelector(
    (s) => s.data.plans.byId.get(currentPlan.planId)?.isPrepaid ?? false,
  );

  const transactions = useSelector((s) => s.data.subscriptions.subscriptionTransactions);
  const balance = useSelector((s) => s.data.subscriptions.subscriptionBalance);

  const [isLoadTransactionsThunkDone] = useThunkPromise(
    loadSubscriptionTransactions,
    selectedSubscription.subscriptionId,
  );

  useThunkPromise(loadSubscriptionBalance, selectedSubscription.subscriptionId);

  if (!isLoadTransactionsThunkDone || !transactions) {
    return <Loader />;
  }

  return (
    <TransactionsComponent
      balance={balance}
      endAt={currentPlan.endAt}
      isPrepaid={isPrepaid}
      transactions={transactions}
    />
  );
};
