import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { Box } from '@kontent-ai/component-library/Box';
import { Callout } from '@kontent-ai/component-library/Callout';
import {
  ICancellablePromise,
  makeCancellablePromise,
  swallowCancelledPromiseError,
} from '@kontent-ai/utils';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { documentationLinks } from '../../../../_shared/constants/documentationLinks.ts';
import {
  DapiKeyListingRoute,
  MapiKeyListingRoute,
  ProjectRouteParams,
} from '../../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { ApiStatus } from '../../../../_shared/models/ApiStatus.ts';
import { getCurrentProject } from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { buildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { isSecureAccessAvailable } from '../../selectors/isSecuredDeliveryApiAvailable.ts';
import {
  switchManagementApiStatus,
  switchSecuredDeliveryApiStatus,
} from '../actions/thunkApiActivationStatusActions.ts';
import { ApiActivationStatusCard as ApiActivationStatusCardComponent } from '../components/ApiActivationStatusCard.tsx';
import { ApiStatusSwitch } from '../components/ApiStatusSwitch.tsx';

const getApiStatus = (isApiEnabled: boolean, isStatusChanging: boolean): ApiStatus => {
  if (isStatusChanging) {
    return isApiEnabled ? ApiStatus.Deactivating : ApiStatus.Activating;
  }

  return isApiEnabled ? ApiStatus.Enabled : ApiStatus.Disabled;
};

export const ApiActivationStatusCard: React.FC = () => {
  const dispatch = useDispatch();

  const [isManagementApiStatusChanging, setIsManagementApiStatusChanging] = useState(false);
  const [isSecuredDeliveryApiStatusChanging, setIsSecuredDeliveryApiStatusChanging] =
    useState(false);
  const switchDeliveryApiStatusPromiseRef = useRef<ICancellablePromise | null>(null);
  const switchManagementApiStatusPromiseRef = useRef<ICancellablePromise | null>(null);

  const currentEnvironmentId = useSelector((s) => s.sharedApp.currentProjectId);

  const managementApiStatus = useSelector((s) => {
    const isApiEnabled =
      !!s.data.apiKeys.apiStatusPerEnvironment.get(currentEnvironmentId)?.isManagementApiEnabled;
    return getApiStatus(isApiEnabled, isManagementApiStatusChanging);
  });

  const isSecuredDeliveryApiAvailable = useSelector(isSecureAccessAvailable);
  const securedDeliveryApiStatus = useSelector((s) => {
    const isApiEnabled =
      !!s.data.apiKeys.apiStatusPerEnvironment.get(currentEnvironmentId)
        ?.isSecuredDeliveryApiEnabled;
    return getApiStatus(isApiEnabled, isSecuredDeliveryApiStatusChanging);
  });

  const { projectContainerId } = useSelector(getCurrentProject);
  const managementApiKeysPath = buildPath<ProjectRouteParams>(MapiKeyListingRoute, {
    projectContainerId,
  });
  const deliveryApiKeysPath = buildPath<ProjectRouteParams>(DapiKeyListingRoute, {
    projectContainerId,
  });

  useEffect(
    () => () => {
      switchDeliveryApiStatusPromiseRef.current?.cancel();
      switchManagementApiStatusPromiseRef.current?.cancel();
    },
    [],
  );

  const onSwitchManagementApiStatus = (newStatus: ApiStatus) => {
    if (!isManagementApiStatusChanging) {
      setIsManagementApiStatusChanging(true);
      switchManagementApiStatusPromiseRef.current = makeCancellablePromise(() =>
        dispatch(switchManagementApiStatus(currentEnvironmentId, newStatus)),
      )
        .then(() => setIsManagementApiStatusChanging(false))
        .catch(swallowCancelledPromiseError);
    }
  };

  const onSwitchSecuredDeliveryApiStatus = (newStatus: ApiStatus) => {
    if (!isSecuredDeliveryApiStatusChanging) {
      setIsSecuredDeliveryApiStatusChanging(true);
      switchDeliveryApiStatusPromiseRef.current = makeCancellablePromise(() =>
        dispatch(switchSecuredDeliveryApiStatus(currentEnvironmentId, newStatus)),
      )
        .then(() => setIsSecuredDeliveryApiStatusChanging(false))
        .catch(swallowCancelledPromiseError);
    }
  };

  return (
    <ApiActivationStatusCardComponent
      renderManagementApiStatusSwitch={() => (
        <ApiStatusSwitch
          header="Management API"
          isAvailable
          status={managementApiStatus}
          onChange={(newStatus: ApiStatus) => onSwitchManagementApiStatus(newStatus)}
          renderFooter={() => (
            <Box component="p">
              Manage your environment via{' '}
              <OutwardLink href={documentationLinks.apiContentManagement}>
                Management API
              </OutwardLink>
              .
            </Box>
          )}
        />
      )}
      renderSecuredDeliveryApiStatusSwitch={() => (
        <ApiStatusSwitch
          header="Secure access for Delivery API"
          isAvailable={isSecuredDeliveryApiAvailable}
          status={securedDeliveryApiStatus}
          tooltipText={
            isSecuredDeliveryApiAvailable
              ? ''
              : 'Secure access isn’t available for your subscription. To use secure access with this environment, upgrade your subscription plan.'
          }
          onChange={(newStatus: ApiStatus) => onSwitchSecuredDeliveryApiStatus(newStatus)}
          renderFooter={() => (
            <Box component="p">
              Restrict public access to your content with{' '}
              <OutwardLink href={documentationLinks.secureAccess}>secure access</OutwardLink>.
            </Box>
          )}
        />
      )}
      renderApiKeysCallout={() => (
        <Callout calloutType="quickTip" hideSubheadline headline="API keys">
          Go to Project settings or use these shortcuts to find your{' '}
          <Link to={deliveryApiKeysPath}>Delivery API keys</Link> and{' '}
          <Link to={managementApiKeysPath}>Management API keys</Link>.
        </Callout>
      )}
    />
  );
};

ApiActivationStatusCard.displayName = 'ApiActivationStatusCard';
