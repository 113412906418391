import classNames from 'classnames';
import React from 'react';
import { ConnectDragSource } from 'react-dnd';
import { BarItemDragAction } from '../../../../../_shared/components/BarItems/Actions/BarItemDragAction.tsx';
import { BarItemEditAction } from '../../../../../_shared/components/BarItems/Actions/BarItemEditAction.tsx';
import { BarItemToggleAction } from '../../../../../_shared/components/BarItems/Actions/BarItemToggleAction.tsx';
import { BarItemActions } from '../../../../../_shared/components/BarItems/BarItemActions.tsx';
import { BarItemBar } from '../../../../../_shared/components/BarItems/BarItemBar.tsx';
import { BarItemTitle } from '../../../../../_shared/components/BarItems/BarItemTitle.tsx';
import { CodenameBarItemButton } from '../../../../../_shared/components/Codename/CodenameBarItemButton.tsx';
import { ISitemapNode } from '../../../../../data/models/contentModelsApp/sitemap/Sitemap.ts';

export interface INodeBarOwnProps {
  readonly connectDragSource?: ConnectDragSource;
  readonly isDragging: boolean;
}

export interface INodeBarStateProps {
  readonly isBeingDragged: boolean;
  readonly isCollapsed: boolean;
  readonly isEditable: boolean;
  readonly isExpandCollapseButtonDisabled: boolean;
  readonly nodeData: ISitemapNode;
}

export interface INodeBarDispatchProps {
  readonly onCollapse: () => void;
  readonly onExpand: () => void;
  readonly onStartEditing: () => void;
}

export type INodeBarProps = INodeBarOwnProps & INodeBarStateProps & INodeBarDispatchProps;

export const NodeBar: React.FC<INodeBarProps> = (props: INodeBarProps) => {
  const {
    connectDragSource,
    isBeingDragged,
    isCollapsed,
    isDragging,
    isEditable,
    isExpandCollapseButtonDisabled,
    nodeData,
    onCollapse,
    onExpand,
    onStartEditing,
  } = props;

  const expandCollapseAction = (
    <BarItemActions>
      <BarItemToggleAction
        ariaLabel="node"
        disabled={isExpandCollapseButtonDisabled}
        isCollapsed={isCollapsed}
        onExpand={onExpand}
        onCollapse={onCollapse}
      />
    </BarItemActions>
  );

  return (
    <div className="bar-item__wrapper">
      <div
        className={classNames('bar-item__pane', {
          'bar-item__pane--is-dragging': isBeingDragged || isDragging,
        })}
      >
        <BarItemBar isDragging={isBeingDragged || isDragging} isClickable={isEditable}>
          <BarItemActions>
            <BarItemDragAction connectDragSource={connectDragSource} disabled={!isEditable} />
          </BarItemActions>
          {nodeData.childIds.length > 0 && expandCollapseAction}
          <BarItemTitle onClick={isEditable ? onStartEditing : undefined}>
            {nodeData.name}
          </BarItemTitle>
          <BarItemActions>
            <CodenameBarItemButton codename={nodeData.codename} />
            <BarItemActions>
              <BarItemEditAction onClick={onStartEditing} disabled={!isEditable} />
            </BarItemActions>
          </BarItemActions>
        </BarItemBar>
      </div>
    </div>
  );
};

NodeBar.displayName = 'NodeBar';
