import { Box } from '@kontent-ai/component-library/Box';
import { PropsWithChildren, ReactNode } from 'react';
import { EmptyState } from '../../../../../../../../_shared/components/EmptyState/EmptyState.tsx';

type Props = PropsWithChildren &
  Readonly<{
    actionButton: ReactNode;
    title: string;
  }>;

export const PreviewErrorState = ({ actionButton, children, title }: Props) => {
  return (
    <EmptyState size="fullOffset">
      <EmptyState.Title>{title}</EmptyState.Title>
      <EmptyState.Content>
        <EmptyState.ContentGroup>
          <Box>{children}</Box>
        </EmptyState.ContentGroup>
        {actionButton}
      </EmptyState.Content>
    </EmptyState>
  );
};
