import { IStore } from 'client/app/_shared/stores/IStore.type.ts';
import { IEditedContentItemThreads } from '../../../../../../contentInventory/content/stores/IContentAppStoreState.ts';
import { ICommentThread } from '../../../../../models/comments/CommentThreads.ts';
import { isThreadResolvedWithoutUndo, isThreadSaved } from '../../../../../utils/commentUtils.ts';
import { selectDisplayedInlineCommentThreads } from '../../../selectors/inlineCommentSelectors.ts';

const selectResolvedComments = (
  editedContentItemVariantComments: IEditedContentItemThreads,
): ReadonlyArray<ICommentThread> =>
  editedContentItemVariantComments.commentThreads.filter(
    (thread) => isThreadSaved(thread) && isThreadResolvedWithoutUndo(thread),
  );

export const isOpenResolvedCommentsAvailable = ({
  contentApp: { editedContentItemVariantComments },
}: IStore) => !!selectResolvedComments(editedContentItemVariantComments).length;

export const isOpenInlineCommentsAvailable = (state: IStore) =>
  !!selectDisplayedInlineCommentThreads(state).length && !state.contentApp.isCommentsPaneVisible;

export const isCloseInlineCommentsAvailable = (state: IStore) =>
  !!selectDisplayedInlineCommentThreads(state).length && state.contentApp.isCommentsPaneVisible;
