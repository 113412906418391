import React, { ComponentType } from 'react';
import { staticActionDetails } from '../../../constants/editingActions/staticDetails.ts';
import { AddTaskAction } from '../../../containers/editingActions/actions/individualActions/AddTaskAction.tsx';
import { AssignPeopleAction } from '../../../containers/editingActions/actions/individualActions/AssignPeopleAction.tsx';
import { ChangeCollectionAction } from '../../../containers/editingActions/actions/individualActions/ChangeCollectionAction.tsx';
import { ChangeDueDateAction } from '../../../containers/editingActions/actions/individualActions/ChangeDueDateAction.tsx';
import { ChangeNoteAction } from '../../../containers/editingActions/actions/individualActions/ChangeNoteAction.tsx';
import { ChangeStepAction } from '../../../containers/editingActions/actions/individualActions/ChangeStepAction.tsx';
import { ChangeWorkflowAction } from '../../../containers/editingActions/actions/individualActions/ChangeWorkflowAction.tsx';
import { CloseComponentsAction } from '../../../containers/editingActions/actions/individualActions/CloseComponentsAction.tsx';
import { CloseInlineCommentsAction } from '../../../containers/editingActions/actions/individualActions/CloseInlineCommentsAction.tsx';
import { CompareVersionsAction } from '../../../containers/editingActions/actions/individualActions/CompareVersionsAction.tsx';
import { CopyFromLanguageAction } from '../../../containers/editingActions/actions/individualActions/CopyFromLanguageAction.tsx';
import { DeleteItemAction } from '../../../containers/editingActions/actions/individualActions/DeleteItemAction.tsx';
import { DemonstrateDisabledPreviewAction } from '../../../containers/editingActions/actions/individualActions/DemonstrateDisabledPreviewAction.tsx';
import { DemonstrateDisabledTranslationAction } from '../../../containers/editingActions/actions/individualActions/DemonstrateDisabledTranslationAction.tsx';
import { DiscardNewVersionAction } from '../../../containers/editingActions/actions/individualActions/DiscardNewVersionAction.tsx';
import { DuplicateItemWithContentAction } from '../../../containers/editingActions/actions/individualActions/DuplicateItemWithContentAction.tsx';
import { DuplicateItemWithoutContentAction } from '../../../containers/editingActions/actions/individualActions/DuplicateItemWithoutContentAction.tsx';
import { OpenComponentsAction } from '../../../containers/editingActions/actions/individualActions/OpenComponentsAction.tsx';
import { OpenDiscussionAction } from '../../../containers/editingActions/actions/individualActions/OpenDiscussionAction.tsx';
import { OpenInlineCommentsAction } from '../../../containers/editingActions/actions/individualActions/OpenInlineCommentsAction.tsx';
import { OpenResolvedCommentsAction } from '../../../containers/editingActions/actions/individualActions/OpenResolvedCommentsAction.tsx';
import { PreviewItemAction } from '../../../containers/editingActions/actions/individualActions/PreviewItemAction.tsx';
import { PreviewItemMultipleSpacesAction } from '../../../containers/editingActions/actions/individualActions/PreviewItemInMultipleSpacesAction.tsx';
import { PublishItemAction } from '../../../containers/editingActions/actions/individualActions/PublishItemAction.tsx';
import { SetDueDateAction } from '../../../containers/editingActions/actions/individualActions/SetDueDateAction.tsx';
import { SetNoteAction } from '../../../containers/editingActions/actions/individualActions/SetNoteAction.tsx';
import { SetupPreviewAction } from '../../../containers/editingActions/actions/individualActions/SetupPreviewAction.tsx';
import { ShareLinkAction } from '../../../containers/editingActions/actions/individualActions/ShareLinkAction.tsx';
import { ShowDuplicationOptionsAction } from '../../../containers/editingActions/actions/individualActions/ShowDuplicationOptionsAction.tsx';
import { TranslateVariantAction } from '../../../containers/editingActions/actions/individualActions/TranslateVariantAction.tsx';
import { ViewCodenameAction } from '../../../containers/editingActions/actions/individualActions/ViewCodenameAction.tsx';
import { EditingAction as EditingActionEnum } from '../../../models/EditingAction.ts';
import {
  ContentItemActionDescriptor,
  mergeActionDetails,
} from '../../../utils/editingActions/mergeActionDetails.ts';
import {
  EditingActionDynamicDetailProvider,
  EditingActionDynamicDetailProviderOwnProps,
} from './EditingActionDynamicDetailProvider.tsx';

type EditingActionProps = {
  readonly action: EditingActionEnum;
  readonly children: (actionDescriptor: ContentItemActionDescriptor) => JSX.Element;
};

const performNoAction = () => undefined;
const NoAction: React.FC<EditingActionDynamicDetailProviderOwnProps> = ({ children }) => (
  <EditingActionDynamicDetailProvider performAction={performNoAction}>
    {children}
  </EditingActionDynamicDetailProvider>
);

type ActionsComponentMap = ReadonlyRecord<
  EditingActionEnum,
  ComponentType<EditingActionDynamicDetailProviderOwnProps>
>;

const dynamicDetailProvidersMap: ActionsComponentMap = {
  [EditingActionEnum.addTask]: AddTaskAction,
  [EditingActionEnum.assignPeople]: AssignPeopleAction,
  [EditingActionEnum.changeCollection]: ChangeCollectionAction,
  [EditingActionEnum.changeDueDate]: ChangeDueDateAction,
  [EditingActionEnum.changeNote]: ChangeNoteAction,
  [EditingActionEnum.changeStep]: ChangeStepAction,
  [EditingActionEnum.changeWorkflow]: ChangeWorkflowAction,
  [EditingActionEnum.closeComponents]: CloseComponentsAction,
  [EditingActionEnum.closeInlineComments]: CloseInlineCommentsAction,
  [EditingActionEnum.compareVersions]: CompareVersionsAction,
  [EditingActionEnum.copyFromLanguage]: CopyFromLanguageAction,
  [EditingActionEnum.deleteItem]: DeleteItemAction,
  [EditingActionEnum.demonstrateDisabledPreviewItem]: DemonstrateDisabledPreviewAction,
  [EditingActionEnum.demonstratePublishPreventsDeletingItem]: NoAction,
  [EditingActionEnum.demonstrateWebSpotlightPreventsDeletingItem]: NoAction,
  [EditingActionEnum.demonstrateDisabledTranslation]: DemonstrateDisabledTranslationAction,
  [EditingActionEnum.discardNewVersion]: DiscardNewVersionAction,
  [EditingActionEnum.duplicateItemWithContent]: DuplicateItemWithContentAction,
  [EditingActionEnum.duplicateItemWithoutContent]: DuplicateItemWithoutContentAction,
  [EditingActionEnum.none]: NoAction,
  [EditingActionEnum.openComponents]: OpenComponentsAction,
  [EditingActionEnum.openDiscussions]: OpenDiscussionAction,
  [EditingActionEnum.openInlineComments]: OpenInlineCommentsAction,
  [EditingActionEnum.openResolvedComments]: OpenResolvedCommentsAction,
  [EditingActionEnum.previewItem]: PreviewItemAction,
  [EditingActionEnum.previewItemInMultipleSpaces]: PreviewItemMultipleSpacesAction,
  [EditingActionEnum.publishItem]: PublishItemAction,
  [EditingActionEnum.setDueDate]: SetDueDateAction,
  [EditingActionEnum.setNote]: SetNoteAction,
  [EditingActionEnum.setupItemPreview]: SetupPreviewAction,
  [EditingActionEnum.shareLink]: ShareLinkAction,
  [EditingActionEnum.showDuplicationOptions]: ShowDuplicationOptionsAction,
  [EditingActionEnum.showMainMenu]: NoAction,
  [EditingActionEnum.translateVariant]: TranslateVariantAction,
  [EditingActionEnum.viewCodename]: ViewCodenameAction,
};

export const EditingAction: React.FC<EditingActionProps> = (props) => {
  const staticDetail = staticActionDetails[props.action];
  const DynamicDetailProvider: React.ComponentType<EditingActionDynamicDetailProviderOwnProps> =
    dynamicDetailProvidersMap[props.action];

  return (
    <DynamicDetailProvider>
      {(dynamicDetail) => props.children(mergeActionDetails(staticDetail, dynamicDetail))}
    </DynamicDetailProvider>
  );
};

EditingAction.displayName = 'EditingAction';
