import classNames from 'classnames';
import { useCallback, useId, useState } from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  getUserFriendlyDateString,
  toLocalTime,
} from '../../../../../../../_shared/utils/dateTime/timeUtils.ts';
import { formatUserName } from '../../../../../../../_shared/utils/users/usersUtils.ts';
import { IProjectContributor } from '../../../../../../../data/models/users/ProjectContributor.ts';
import {
  ICommentThreadItem,
  ICommentThreadItemContentModel,
} from '../../../../../models/comments/CommentThreadItem.ts';
import {
  CommentThreadType,
  ICommentThread,
} from '../../../../../models/comments/CommentThreads.ts';
import { isSuggestion } from '../../../../../models/comments/Suggestion.ts';
import { CommentThreadItemContent } from './CommentThreadItemContent.tsx';
import { CommentThreadItemEditor } from './CommentThreadItemEditor.tsx';
import { CommentThreadItemHeader } from './CommentThreadItemHeader.tsx';
import { CommentThreadItemSegmentInfo } from './CommentThreadItemSegmentInfo.tsx';

type Props = {
  readonly allowCopyLink: boolean;
  readonly allowEdit: boolean;
  readonly allowResolve: boolean;
  readonly approveInfo: string;
  readonly canApproveSuggestion: boolean;
  readonly className: string;
  readonly commentThread: ICommentThread;
  readonly commentThreadItem: ICommentThreadItem;
  readonly commentThreadType: CommentThreadType;
  readonly createdBy: IProjectContributor | null;
  readonly elementName: string | null;
  readonly elementSegment: string | null;
  readonly isInlineThreadWithRemovedContent?: boolean;
  readonly isLastApprovedSuggestion?: boolean;
  readonly isThreadRoot: boolean;
  readonly onApproveSuggestion: () => void;
  readonly onCancelEdit: () => void;
  readonly onDomReferenceCreated?: (ref: HTMLElement) => void;
  readonly onResolve: () => void;
  readonly onResolveAfterSuggestionWasApproved: () => void;
  readonly onSubmitEdit: (content: ICommentThreadItemContentModel) => Promise<void>;
  readonly shouldDisplaySegmentInfo: boolean;
  readonly suggestionApprovedBy: IProjectContributor | null;
};

export const CommentThreadItem = ({
  allowCopyLink,
  allowEdit,
  allowResolve,
  approveInfo,
  canApproveSuggestion,
  className,
  commentThread,
  commentThreadItem,
  commentThreadType,
  createdBy,
  elementName,
  elementSegment,
  isInlineThreadWithRemovedContent,
  isLastApprovedSuggestion,
  isThreadRoot,
  onApproveSuggestion,
  onCancelEdit,
  onDomReferenceCreated,
  onResolve,
  onResolveAfterSuggestionWasApproved,
  onSubmitEdit,
  shouldDisplaySegmentInfo,
  suggestionApprovedBy,
}: Props) => {
  const [isApproveAnimating, setIsApproveAnimating] = useState(false);

  const commentElementReferenceCreated = useCallback(
    (element: HTMLElement | null) => {
      if (onDomReferenceCreated && element) {
        onDomReferenceCreated(element);
      }
    },
    [onDomReferenceCreated],
  );

  const approveSuggestion = () => {
    setIsApproveAnimating(true);
    onApproveSuggestion();
  };

  const approveAnimationFinished = () => {
    setIsApproveAnimating(false);
    onResolveAfterSuggestionWasApproved();
  };

  const threadCreatedAt = toLocalTime(commentThreadItem.createdAt);
  const userFriendlyDate = threadCreatedAt
    ? getUserFriendlyDateString(threadCreatedAt, new Date())
    : null;

  const itemIsSuggestion = isSuggestion(commentThreadItem);

  const contentId = useId();

  const a11yAttributes = isThreadRoot
    ? {}
    : {
        'aria-label': `Reply by ${formatUserName(createdBy)}${userFriendlyDate ? ` from ${userFriendlyDate}` : ''}`,
        'aria-describedby': contentId,
        role: 'article',
      };

  return (
    <div
      className={classNames('comment', className, {
        'comment--is-suggestion': itemIsSuggestion,
        'comment--is-editing': commentThreadItem.isEditing,
      })}
      ref={commentElementReferenceCreated}
      {...a11yAttributes}
      {...getDataUiElementAttribute(DataUiElement.CommentThreadItem)}
    >
      <CommentThreadItemHeader
        allowCopyLink={allowCopyLink}
        allowEdit={allowEdit}
        allowResolve={allowResolve}
        approveInfo={approveInfo}
        commentThreadId={commentThread.id}
        commentThreadItem={commentThreadItem}
        createdBy={createdBy}
        isLastApprovedSuggestion={isLastApprovedSuggestion}
        onApproveSuggestion={canApproveSuggestion ? approveSuggestion : undefined}
        onResolve={onResolve}
      />
      <div id={contentId}>
        {shouldDisplaySegmentInfo && (
          <CommentThreadItemSegmentInfo
            elementName={elementName}
            commentThreadType={commentThreadType}
            elementSegment={elementSegment}
            isInlineThreadWithRemovedContent={isInlineThreadWithRemovedContent}
          />
        )}
        {commentThreadItem.isEditing ? ( // show edit form when editing comment message
          <CommentThreadItemEditor
            submitButtonText={commentThreadItem.isSubmitting ? 'Sending…' : 'Save'}
            commentThreadItem={commentThreadItem}
            isEditing={commentThreadItem.isEditing}
            isInputDisabled={false}
            isButtonDisabled={commentThreadItem.isSubmitting}
            onSubmit={onSubmitEdit}
            onCancel={onCancelEdit}
            areButtonsDisplayed
          />
        ) : (
          <CommentThreadItemContent
            commentThreadItem={commentThreadItem}
            commentThread={commentThread}
            isLastApprovedSuggestion={isLastApprovedSuggestion}
            suggestionApprovedBy={suggestionApprovedBy}
            isApproveAnimating={isApproveAnimating}
            onApproveAnimationFinished={approveAnimationFinished}
          />
        )}
      </div>
    </div>
  );
};
