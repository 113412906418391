import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DefaultLanguageId } from '../../../_shared/constants/variantIdValues.ts';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { getActiveLanguageIds } from '../../../_shared/utils/languageUtils.ts';
import { createFormValidationResolver } from '../../../_shared/utils/validation/createFormValidationResolver.ts';
import { getAllWorkflowSteps } from '../../../_shared/utils/workflow/getAllWorkflowSteps.ts';
import { IEntityWebhookSetting } from '../../../data/models/webhooks/EntityWebhookSetting.ts';
import { Workflow } from '../../../data/models/workflow/Workflow.ts';
import { entityWebhookNameChanged } from '../actions/entityWebhookActions.ts';
import { updateEntityWebhook } from '../actions/thunkEntityWebhookActions.ts';
import { EntityWebhookEditorForm as EntityWebhookEditorFormComponent } from '../components/EntityWebhookEditorForm.tsx';
import { anyCollectionCollection } from '../constants/anyCollectionCollection.ts';
import { anyContentTypeContentType } from '../constants/anyContentTypeContentType.ts';
import { anyLanguageLanguage } from '../constants/anyLanguageLanguage.ts';
import { anyTaxonomyTaxonomy } from '../constants/anyTaxonomyTaxonomy.ts';
import { anyWorkflowStepWorkflowStep } from '../constants/anyWorkflowStepWorkflowStep.ts';
import { IEntityWebhookFormShape } from '../models/IEntityWebhookFormShape.type.ts';
import { entityWebhookItemEditorFormValidationConfig } from '../validation/entityWebhookSettingValidation.ts';

type Props = {
  readonly entityWebhookSetting: IEntityWebhookSetting;
};

export const EntityWebhookEditorForm = ({ entityWebhookSetting }: Props) => {
  const dispatch = useDispatch();
  const workflows = useSelector((state) => state.data.workflows.byId);
  const contentTypes = useSelector((state) => state.data.contentTypes.byId);
  const collections = useSelector((state) => state.data.collections.byId);
  const activeLanguages = useSelector((s) => getActiveLanguageIds(s.data.languages));
  const taxonomies = useSelector((state) => state.data.taxonomyGroups.byId);

  const initFormValues: IEntityWebhookFormShape = useMemo(
    (): IEntityWebhookFormShape => ({
      name: entityWebhookSetting.name,
      secret: entityWebhookSetting.secret,
      headers: entityWebhookSetting.headers,
      triggers: {
        assetTrigger: {
          actions: entityWebhookSetting.triggers.assetTrigger.actions,
          checked: entityWebhookSetting.triggers.assetTrigger.enabled,
        },
        contentItemTrigger: {
          actions: entityWebhookSetting.triggers.contentItemTrigger.actions,
          checked: entityWebhookSetting.triggers.contentItemTrigger.enabled,
          collectionIds:
            entityWebhookSetting.triggers.contentItemTrigger.collectionFilters.length > 0
              ? entityWebhookSetting.triggers.contentItemTrigger.collectionFilters.filter(
                  (collection) => collections.has(collection),
                )
              : [anyCollectionCollection.id],
          contentTypeIds:
            entityWebhookSetting.triggers.contentItemTrigger.contentTypeFilters.length > 0
              ? entityWebhookSetting.triggers.contentItemTrigger.contentTypeFilters.filter(
                  (contentType) => contentTypes.has(contentType),
                )
              : [anyContentTypeContentType.id],
          languageIds:
            entityWebhookSetting.triggers.contentItemTrigger.languageFilters.length > 0
              ? entityWebhookSetting.triggers.contentItemTrigger.languageFilters.filter(
                  (language) => activeLanguages.has(language) || language === DefaultLanguageId,
                )
              : [anyLanguageLanguage.id],
          workflowSteps:
            entityWebhookSetting.triggers.contentItemTrigger.workflowSteps.length > 0
              ? entityWebhookSetting.triggers.contentItemTrigger.workflowSteps
                  .filter(
                    (ws) =>
                      workflows.has(ws.workflowId) &&
                      getAllWorkflowSteps(workflows.get(ws.workflowId) as Workflow).some(
                        (step) => step.id === ws.stepId,
                      ),
                  )
                  .map((ws) => (workflows.size > 1 ? `${ws.workflowId}/${ws.stepId}` : ws.stepId))
              : [anyWorkflowStepWorkflowStep.id],
        },
        contentTypeTrigger: {
          actions: entityWebhookSetting.triggers.contentTypeTrigger.actions,
          checked: entityWebhookSetting.triggers.contentTypeTrigger.enabled,
          contentTypeIds:
            entityWebhookSetting.triggers.contentTypeTrigger.contentTypeFilters.length > 0
              ? entityWebhookSetting.triggers.contentTypeTrigger.contentTypeFilters.filter(
                  (contentType) => contentTypes.has(contentType),
                )
              : [anyContentTypeContentType.id],
        },
        events: entityWebhookSetting.triggers.events,
        languageTrigger: {
          actions: entityWebhookSetting.triggers.languageTrigger.actions,
          checked: entityWebhookSetting.triggers.languageTrigger.enabled,
          languageIds:
            entityWebhookSetting.triggers.languageTrigger.languageFilters.length > 0
              ? entityWebhookSetting.triggers.languageTrigger.languageFilters.filter(
                  (language) => activeLanguages.has(language) || language === DefaultLanguageId,
                )
              : [anyLanguageLanguage.id],
        },
        slot: entityWebhookSetting.triggers.slot,
        taxonomyTrigger: {
          actions: entityWebhookSetting.triggers.taxonomyTrigger.actions,
          checked: entityWebhookSetting.triggers.taxonomyTrigger.enabled,
          taxonomyIds:
            entityWebhookSetting.triggers.taxonomyTrigger.taxonomyFilters.length > 0
              ? entityWebhookSetting.triggers.taxonomyTrigger.taxonomyFilters.filter((taxonomy) =>
                  taxonomies.has(taxonomy),
                )
              : [anyTaxonomyTaxonomy.id],
        },
      },
      url: entityWebhookSetting.url,
    }),
    [
      activeLanguages,
      collections,
      contentTypes,
      taxonomies,
      workflows,
      entityWebhookSetting.name,
      entityWebhookSetting.secret,
      entityWebhookSetting.headers,
      entityWebhookSetting.triggers.assetTrigger.actions,
      entityWebhookSetting.triggers.assetTrigger.enabled,
      entityWebhookSetting.triggers.contentItemTrigger.actions,
      entityWebhookSetting.triggers.contentItemTrigger.collectionFilters,
      entityWebhookSetting.triggers.contentItemTrigger.contentTypeFilters,
      entityWebhookSetting.triggers.contentItemTrigger.enabled,
      entityWebhookSetting.triggers.contentItemTrigger.languageFilters,
      entityWebhookSetting.triggers.contentItemTrigger.workflowSteps,
      entityWebhookSetting.triggers.contentTypeTrigger.actions,
      entityWebhookSetting.triggers.contentTypeTrigger.contentTypeFilters,
      entityWebhookSetting.triggers.contentTypeTrigger.enabled,
      entityWebhookSetting.triggers.events,
      entityWebhookSetting.triggers.languageTrigger.actions,
      entityWebhookSetting.triggers.languageTrigger.enabled,
      entityWebhookSetting.triggers.languageTrigger.languageFilters,
      entityWebhookSetting.triggers.slot,
      entityWebhookSetting.triggers.taxonomyTrigger.actions,
      entityWebhookSetting.triggers.taxonomyTrigger.enabled,
      entityWebhookSetting.triggers.taxonomyTrigger.taxonomyFilters,
      entityWebhookSetting.url,
    ],
  );

  const formProps = useForm<IEntityWebhookFormShape>({
    defaultValues: initFormValues,
    mode: 'onTouched',
    resolver: createFormValidationResolver(entityWebhookItemEditorFormValidationConfig, {}),
    shouldFocusError: false,
  });

  const [entityWebhookActionIsInProgress, setEntityWebhookActionIsInProgress] =
    useState<boolean>(false);
  const [displayNotificationBar, setDisplayNotificationBar] = useState<boolean>(false);

  const [isValid, setIsValid] = useState<boolean>(false);

  const { handleSubmit, watch, trigger, formState, reset, setValue, getValues } = formProps;

  useEffect(() => {
    const subscription = watch(async () => {
      const isActualFormValid = await trigger();
      if (displayNotificationBar && isActualFormValid) {
        setDisplayNotificationBar(false);
      }

      setIsValid(isActualFormValid);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [displayNotificationBar, trigger, watch]);

  useEffect(() => {
    const nameSubscription = watch((formValues: IEntityWebhookFormShape, { name }) => {
      if (name === 'name') {
        dispatch(entityWebhookNameChanged(formValues.name));
      }
    });

    return () => {
      nameSubscription.unsubscribe();
    };
  }, [watch]);

  const saveWebhook = async (formValues: IEntityWebhookFormShape): Promise<void> => {
    setEntityWebhookActionIsInProgress(true);
    await dispatch(updateEntityWebhook(formValues, entityWebhookSetting));
    reset(formValues, { keepValues: true, keepIsValid: true });
    setEntityWebhookActionIsInProgress(false);
  };

  const unsavedNavigationHandler = async (
    onSuccess: () => void,
    onFail: () => void,
  ): Promise<void> => {
    const submit = handleSubmit(async (formValues): Promise<void> => {
      if (!isValid) onFail();
      try {
        await saveWebhook(formValues);
        onSuccess();
      } catch {
        onFail();
      }
    }, onFail);

    await submit();
  };

  const submitForm = handleSubmit(
    async (formValues) => {
      await saveWebhook(formValues);
    },
    () => {
      getValues().headers.forEach((header, index) => {
        if (header.value === '') {
          setValue(`headers.${index}.value`, '', { shouldTouch: true });
        }
      });
      setDisplayNotificationBar(true);
    },
  );

  return (
    <EntityWebhookEditorFormComponent
      customName={entityWebhookSetting.name}
      enabled={entityWebhookSetting.enabled}
      entityWebhookId={entityWebhookSetting.id}
      entityWebhookActionIsInProgress={entityWebhookActionIsInProgress}
      formProps={formProps}
      hasUnsavedChanges={formState.isDirty}
      hasError={displayNotificationBar}
      onSetEntityWebhookActionIsInProgress={setEntityWebhookActionIsInProgress}
      onCloseNotificationBar={() => setDisplayNotificationBar(false)}
      onSubmit={submitForm}
      onUnsavedNavigation={unsavedNavigationHandler}
    />
  );
};
