import { matchPath } from './routeTransitionUtils.ts';

export const checkGivenRoutesForPathNameMatch = (
  pathName: string,
  routesToMatch: string[],
  exactMatch: boolean = false,
) => {
  return routesToMatch.some(
    (routeToMatch) => !!matchPath(pathName, { path: routeToMatch, exact: exactMatch }),
  );
};
