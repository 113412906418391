import React from 'react';
import { DataUiElement } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  limitationsCountPlaceholder,
  limitationsHeightPlaceholder,
  limitationsWidthPlaceholder,
} from '../../../../constants/uiConstants.ts';
import { IAssetTypeElementData } from '../../../../models/elements/AssetTypeElementData.ts';
import { AssetTypeElementValidationResult } from '../../../../utils/typeElementValidators/types/AssetTypeElementValidationResult.type.ts';
import { AssetType } from '../../shared/configuration/AssetType.tsx';
import { FileSizeLimit } from '../../shared/configuration/FileSizeLimit.tsx';
import { INumberLimit, NumberLimit } from '../../shared/configuration/NumberLimit.tsx';

type AssetTypeElementLimitationProps = {
  readonly typeElementData: IAssetTypeElementData;
  readonly validationResult: AssetTypeElementValidationResult;
  readonly onUpdateAssetWidth: (updatedLimit: INumberLimit) => void;
  readonly onUpdateAssetHeight: (updatedLimit: INumberLimit) => void;
  readonly onUpdateAssetCount: (updatedLimit: INumberLimit) => void;
  readonly onUpdateConfiguration: (updatedElement: IAssetTypeElementData) => void;
};

export const AssetTypeElementLimitation: React.FC<AssetTypeElementLimitationProps> = ({
  typeElementData,
  onUpdateAssetCount,
  onUpdateAssetHeight,
  onUpdateAssetWidth,
  onUpdateConfiguration,
  validationResult,
}) => {
  return (
    <>
      <NumberLimit
        title="Limit number of assets"
        uiElement={DataUiElement.ItemsCountLimitation}
        onChange={onUpdateAssetCount}
        limit={{
          minLimit: typeElementData.minItems,
          maxLimit: typeElementData.maxItems,
          _limitOption: typeElementData._quantityUnitOption,
        }}
        isValid={validationResult.isNumberOfItemsValid}
        placeholder={limitationsCountPlaceholder}
      />

      <FileSizeLimit
        onChange={onUpdateConfiguration}
        uiElement={DataUiElement.FileSizeLimitation}
        typeElementData={typeElementData}
        isValid={validationResult.isFileSizeValid}
      />

      <AssetType
        uiElement={DataUiElement.FileTypeLimitation}
        title="Limit file types"
        onChange={onUpdateConfiguration}
        typeElementData={typeElementData}
      />

      <NumberLimit
        title="Limit image width (px)"
        uiElement={DataUiElement.WidthLimitation}
        onChange={onUpdateAssetWidth}
        limit={{
          minLimit: typeElementData.minWidth,
          maxLimit: typeElementData.maxWidth,
          _limitOption: typeElementData._widthOption,
        }}
        isValid={validationResult.isWidthValid}
        placeholder={limitationsWidthPlaceholder}
      />

      <NumberLimit
        title="Limit image height (px)"
        uiElement={DataUiElement.HeightLimitation}
        onChange={onUpdateAssetHeight}
        limit={{
          minLimit: typeElementData.minHeight,
          maxLimit: typeElementData.maxHeight,
          _limitOption: typeElementData._heightOption,
        }}
        isValid={validationResult.isHeightValid}
        placeholder={limitationsHeightPlaceholder}
      />
    </>
  );
};

AssetTypeElementLimitation.displayName = 'AssetTypeElementLimitation';
