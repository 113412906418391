import { ShortcutsConfig, useHotkeys } from '@kontent-ai/component-library/hooks';
import { Direction } from '@kontent-ai/types';
import { getFocusableTreeWalker } from '@react-aria/focus';
import { RefObject } from 'react';
import { defaultTabbingOptions } from '../utils/focusUtils.ts';

type Props = {
  readonly isDisabled?: boolean;
  readonly onEscape?: () => void;
  readonly onLeave?: (e: KeyboardEvent, direction?: Direction) => void;
  readonly ref: RefObject<HTMLElement>;
};

export function useTabKeyNavigationWithin({ ref, isDisabled, onEscape, onLeave }: Props) {
  useHotkeys(
    {
      [ShortcutsConfig.Tab]: (e) => {
        const treeWalker = ref.current
          ? getFocusableTreeWalker(ref.current, defaultTabbingOptions)
          : null;

        if (!treeWalker || !ref.current) {
          return;
        }

        const lastFocusableNode = treeWalker.lastChild();
        treeWalker.currentNode = ref.current;

        const isLastFocusableNodeCurrentlyFocused = lastFocusableNode?.isSameNode(
          document.activeElement,
        );

        if (isLastFocusableNodeCurrentlyFocused && onLeave) {
          onLeave(e, Direction.Forward);
          return;
        }
      },
      [ShortcutsConfig.ShiftTab]: (e) => {
        const treeWalker = ref.current
          ? getFocusableTreeWalker(ref.current, defaultTabbingOptions)
          : null;

        if (!treeWalker || !ref.current) {
          return;
        }

        const firstFocusableNode = treeWalker.firstChild();
        treeWalker.currentNode = ref.current;

        const isFirstFocusableNodeCurrentlyFocused = firstFocusableNode?.isSameNode(
          document.activeElement,
        );

        if (isFirstFocusableNodeCurrentlyFocused && onLeave) {
          onLeave(e, Direction.Backward);
          return;
        }
      },
      [ShortcutsConfig.Escape]: (e): void => {
        e.stopPropagation();
        onEscape?.();
      },
    },
    { ref, isDisabled },
  );
}
