import { memoize } from '@kontent-ai/memoization';
import { Collection } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { filterContentTypesByCapability } from '../../../applications/contentModels/shared/utils/typeUtils.ts';
import {
  IContentType,
  emptyContentType,
} from '../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IRoleWithSettings } from '../../../data/models/roles/IRoleWithSettings.ts';
import { IProjectContributor } from '../../../data/models/users/ProjectContributor.ts';
import { Workflow } from '../../../data/models/workflow/Workflow.ts';
import {
  IAssignmentWorkflowStep,
  IWorkflowStep,
} from '../../../data/models/workflow/WorkflowStep.ts';
import { isWorkflowStepModalOpenedInItemEditing } from '../../features/ChangeWorkflowStepModal/utils/changeWorkflowStepModalUtils.ts';
import { IUserIdentifier } from '../../models/UserIdentifier.ts';
import { getSelectedLanguageIdOrThrow } from '../../selectors/getSelectedLanguageId.ts';
import { getWorkflow } from '../../selectors/workflowSelectors.ts';
import { IStore } from '../../stores/IStore.type.ts';
import { getUsersInfo, userLastNameComparer } from '../users/usersUtils.ts';
import { Capability } from './capability.ts';
import {
  getAllApplicableContributorRoleIdsForLanguage,
  getApplicableContributorRoleId,
} from './getContributorRole.ts';
import { canRoleDoSomethingInStep } from './roleInWorkflowStepUtils.ts';

export const getAllActiveContributors = memoize.maxOne(
  (users: ReadonlyMap<Uuid, IProjectContributor>): ReadonlyArray<IProjectContributor> =>
    Collection.getValues(users)
      .filter((user) => !user.inactive)
      .filter((user) => !user.isVirtual)
      .sort(userLastNameComparer),
);

export const getAvailableContributorsForEditing = memoize.maxOne(
  (
    workflowStep: IWorkflowStep,
    itemType: IContentType,
    allActiveContributors: ReadonlyArray<IProjectContributor>,
    allTypes: Immutable.Map<Uuid, IContentType>,
    allRoles: ReadonlyMap<Uuid, IRoleWithSettings>,
    workflow: Workflow | undefined,
    selectedLanguageId: Uuid,
    collectionId: Uuid | null,
  ): ReadonlyArray<IProjectContributor> =>
    allActiveContributors
      .filter((c) => {
        const contributorRoleId = getApplicableContributorRoleId(
          c,
          selectedLanguageId,
          collectionId,
        );
        const contributorRole = allRoles.get(
          getApplicableContributorRoleId(c, selectedLanguageId, collectionId) ?? '',
        );

        if (!contributorRole) {
          return false;
        }

        if (!workflow || !canRoleDoSomethingInStep(contributorRoleId, workflowStep.id, workflow)) {
          return false;
        }

        const typesWithViewCapability = filterContentTypesByCapability(
          allTypes.toArray(),
          contributorRole.settings,
          Capability.ViewContent,
        );

        return !!typesWithViewCapability.find((type) => type.id === itemType.id);
      })
      .sort(userLastNameComparer),
);

export const getAvailableContributorsForListing = memoize.maxOne(
  (
    workflowStep: IWorkflowStep,
    allActiveContributors: ReadonlyArray<IProjectContributor>,
    workflow: Workflow | undefined,
    selectedLanguageId: Uuid,
  ): ReadonlyArray<IProjectContributor> =>
    allActiveContributors
      .filter((c) => {
        const contributorLanguageRoleIds = getAllApplicableContributorRoleIdsForLanguage(
          c.collectionGroups,
          selectedLanguageId,
        );
        return (
          !!workflow &&
          contributorLanguageRoleIds.some((roleId) =>
            canRoleDoSomethingInStep(roleId, workflowStep.id, workflow),
          )
        );
      })
      .sort(userLastNameComparer),
);

export const getActiveAssignedContributors = memoize.maxOne(
  (
    assignees: ReadonlySet<IUserIdentifier>,
    allActiveContributors: ReadonlyArray<IProjectContributor>,
  ): ReadonlyArray<IProjectContributor> => {
    const assigneeIds = [...assignees].map((assignee) => assignee?.userId);
    return allActiveContributors
      .filter((contributor) => !!contributor && assigneeIds.includes(contributor.userId))
      .sort(userLastNameComparer);
  },
);

export const getAvailableAssignedContributors = memoize.maxOne(
  (
    assignedContributors: ReadonlyArray<IProjectContributor>,
    availableContributors: ReadonlyArray<IProjectContributor>,
  ): ReadonlyArray<IProjectContributor> => {
    return assignedContributors
      .filter((assignedContributor) =>
        availableContributors.some(
          (availableContributor) => availableContributor.userId === assignedContributor.userId,
        ),
      )
      .sort(userLastNameComparer);
  },
);

export interface IContributorsForWorkflow {
  readonly allActive: ReadonlyArray<IProjectContributor>;
  readonly assigned: ReadonlyArray<IProjectContributor>;
  readonly available: ReadonlyArray<IProjectContributor>;
  readonly availableAssigned: ReadonlyArray<IProjectContributor>;
}

const emptyContributors: IContributorsForWorkflow = {
  allActive: [],
  assigned: [],
  available: [],
  availableAssigned: [],
};

const getContributorsAvailableAsAssigneesMemoized = memoize.maxOne(
  (
    currentAssignees: ReadonlySet<IUserIdentifier>,
    allActive: ReadonlyArray<IProjectContributor>,
    available: ReadonlyArray<IProjectContributor>,
  ): IContributorsForWorkflow => {
    const assigned = getActiveAssignedContributors(currentAssignees, allActive);
    const availableAssigned = getAvailableAssignedContributors(assigned, available);

    return {
      allActive,
      assigned,
      available,
      availableAssigned,
    };
  },
);

const getContributorsAvailableAsAssigneesForEditing = (
  state: IStore,
  currentAssignees: ReadonlySet<IUserIdentifier>,
  currentWorkflowStep: IAssignmentWorkflowStep,
): IContributorsForWorkflow => {
  const {
    contentApp: { editedContentItem, editedContentItemVariant },
    data: { users, contentTypes, roles },
  } = state;

  if (!editedContentItem || !editedContentItemVariant) {
    return emptyContributors;
  }

  const allActive = getAllActiveContributors(users.usersById);
  const currentWorkflow = getWorkflow(state, currentWorkflowStep.workflowId);

  const availableContributors = getAvailableContributorsForEditing(
    currentWorkflowStep,
    contentTypes.byId.get(editedContentItem.editedContentItemTypeId) ?? emptyContentType,
    allActive,
    contentTypes.byId,
    roles.rolesById,
    currentWorkflow,
    editedContentItemVariant.id.variantId,
    editedContentItem.collectionId,
  );

  return getContributorsAvailableAsAssigneesMemoized(
    currentAssignees,
    allActive,
    availableContributors,
  );
};

const getContributorsAvailableAsAssigneesForListing = (
  state: IStore,
  currentAssignees: ReadonlySet<IUserIdentifier>,
  currentWorkflowStep: IAssignmentWorkflowStep,
) => {
  const { usersById: users } = state.data.users;

  const selectedLanguageId = getSelectedLanguageIdOrThrow(state);

  const allActive = getAllActiveContributors(users);
  const currentWorkflow = getWorkflow(state, currentWorkflowStep.workflowId);
  const availableContributors = getAvailableContributorsForListing(
    currentWorkflowStep,
    allActive,
    currentWorkflow,
    selectedLanguageId,
  );

  return getContributorsAvailableAsAssigneesMemoized(
    currentAssignees,
    allActive,
    availableContributors,
  );
};

export const getContributorsAvailableAsAssigneesInTasks = (
  state: IStore,
): IContributorsForWorkflow => {
  const {
    contentApp: { editedContentItemVariant },
    data: {
      users: { usersById },
    },
  } = state;

  if (!editedContentItemVariant) {
    return emptyContributors;
  }

  const { assignees, workflowStatus } = editedContentItemVariant.assignment;
  const assigneesInfo = getUsersInfo(assignees, usersById);

  return getContributorsAvailableAsAssigneesForEditing(state, assigneesInfo, workflowStatus);
};

export const getContributorsAvailableAsAssigneesInChangeWorkflowStepModal = (state: IStore) => {
  const { changeWorkflowStepModalData } = state.contentApp;

  return isWorkflowStepModalOpenedInItemEditing(changeWorkflowStepModalData)
    ? getContributorsAvailableAsAssigneesForEditing(
        state,
        changeWorkflowStepModalData.contributors,
        changeWorkflowStepModalData.workflowStep,
      )
    : getContributorsAvailableAsAssigneesForListing(
        state,
        changeWorkflowStepModalData.contributors,
        changeWorkflowStepModalData.workflowStep,
      );
};
