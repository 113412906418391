import { ISelectItem } from '@kontent-ai/component-library/Selects';
import { DefaultTag, Tag } from '@kontent-ai/component-library/Tag';
import { colorAlertBackgroundInverse } from '@kontent-ai/component-library/tokens';
import { useMemo } from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import {
  getCurrentProject,
  getNormalizedRolesWithSettingsForUser,
} from '../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { DataUiCollection } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getAllActiveLanguagesForCurrentUserInAnyCollection } from '../../../../../_shared/utils/languageUtils.ts';
import { Capability } from '../../../../../_shared/utils/permissions/capability.ts';
import { getAllApplicableRolesForLanguage } from '../../../../../_shared/utils/permissions/getContributorRole.ts';
import { IContentType } from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { ILanguage } from '../../../../../data/models/languages/Language.ts';
import { IUser } from '../../../../../data/reducers/user/IUser.type.ts';
import { ListingFilterCategorySelector } from '../../../../contentInventory/content/features/ListingFilter/components/ListingFilterCategorySelector.tsx';
import {
  filterContentTypesByCapability,
  getSortedContentTypesByName,
} from '../../../../contentModels/shared/utils/typeUtils.ts';

type Props = Readonly<{
  forceContentTypesTooltipText: string | undefined;
  forcedContentTypeIds: ReadonlySet<Uuid> | undefined;
  onContentTypesSelectionChanged: (ids: ReadonlySet<Uuid>) => void;
  selectedContentTypeIds: ReadonlySet<Uuid>;
  user: IUser;
}>;

interface ITypeSelectItem extends ISelectItem<ITypeSelectItem> {
  readonly isArchived?: boolean;
}

const toSelectItem = ({ id, name: label, isArchived }: IContentType): ITypeSelectItem => ({
  id,
  label,
  isArchived,
});

export const ContentTypesFilterSelector = (props: Props) => {
  const { projectId } = useSelector(getCurrentProject);
  const contentTypesById = useSelector((state) => state.data.contentTypes.byId);
  const contentTypes = useMemo(() => contentTypesById.valueSeq().toArray(), [contentTypesById]);

  const languagesWithViewCapability = useSelector((state) =>
    getAllActiveLanguagesForCurrentUserInAnyCollection(
      props.user,
      projectId,
      state.data.languages,
      Capability.ViewContent,
    ),
  );

  const options = useMemo(() => {
    const userRoles = getNormalizedRolesWithSettingsForUser(props.user, projectId);
    const rolesInAllLanguages = languagesWithViewCapability
      .toArray()
      .flatMap((language: ILanguage) =>
        getAllApplicableRolesForLanguage(userRoles.collectionGroups, language.id),
      );
    const uniqueRolesInAllLanguages = new Set(rolesInAllLanguages);
    const uniqueAllowedContentTypes = new Set(
      [...uniqueRolesInAllLanguages].flatMap((role) =>
        filterContentTypesByCapability(contentTypes, role.settings, Capability.ViewContent),
      ),
    );

    return getSortedContentTypesByName([...uniqueAllowedContentTypes]).map(toSelectItem);
  }, [languagesWithViewCapability, props.user, projectId, contentTypes]);

  return (
    <ListingFilterCategorySelector<ITypeSelectItem>
      disabled={!!props.forcedContentTypeIds}
      onChange={props.onContentTypesSelectionChanged}
      options={options}
      collection={DataUiCollection.ContentTypes}
      placeholder="Select a content type"
      selectedOptionIds={props.forcedContentTypeIds ?? props.selectedContentTypeIds}
      title="Content type"
      titleTooltipText={props.forceContentTypesTooltipText}
      renderSelectedOption={(_id, selectedItem, defaultTagProps) =>
        selectedItem.isArchived ? (
          <Tag background={colorAlertBackgroundInverse} {...defaultTagProps} />
        ) : (
          <DefaultTag {...defaultTagProps} />
        )
      }
    />
  );
};
