import { memoize } from '@kontent-ai/memoization';
import { createCompare, naturally } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { useMemo } from 'react';
import { modalWithPropertiesOpened } from '../../../../_shared/actions/sharedActions.ts';
import { ModalDialogType } from '../../../../_shared/constants/modalDialogType.ts';
import {
  CreateNewContentTypeRoute,
  EnvironmentRouteParams,
} from '../../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import { getCurrentProjectId } from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { filterEntitiesMap } from '../../../../_shared/utils/filter/nameFilterUtils.ts';
import { compose } from '../../../../_shared/utils/func/compose.ts';
import { buildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { IContentType } from '../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { contentTypeListingFilterSearchPhraseChanged } from '../actions/contentTypesActions.ts';
import {
  archiveContentTypes,
  initContentTypeListing,
  selectContentTypes,
  undoContentTypeListingOperation,
  unselectContentTypes,
} from '../actions/thunkContentTypesActions.ts';
import { ContentTypeListing as ContentTypeListingComponent } from '../components/ContentTypeListing.tsx';
import { ContentTypeListingOperationType } from '../utils/contentTypeListingOperationStatus.ts';

const getOrderedContentTypes = memoize.weak(
  (
    types: Immutable.Map<Uuid, IContentType>,
    filterSearchPhrase: string,
  ): ReadonlyArray<IContentType> => {
    const filteredContentTypes = filterEntitiesMap<IContentType>(filterSearchPhrase, types, [
      (type) => type.name,
    ]);

    return filteredContentTypes.toArray().sort(
      createCompare({
        compare: naturally,
        select: (t) => t.name,
      }),
    );
  },
);

const getContentTypesListing = (state: IStore) => state.contentModelsApp.contentTypes.listing;

export const ContentTypeListing = () => {
  const contentTypeListingLoadingStatus = useSelector(
    (state) => getContentTypesListing(state).loadingStatus,
  );
  const currentProjectId = useSelector(getCurrentProjectId);
  const filterSearchPhrase = useSelector(
    (state) => getContentTypesListing(state).filterSearchPhrase,
  );
  const listingOperationStatus = useSelector(
    (state) => getContentTypesListing(state).listingOperationStatus,
  );
  const orderedContentTypes = useSelector((state) =>
    getOrderedContentTypes(
      state.data.contentTypes.byId,
      state.contentModelsApp.contentTypes.listing.filterSearchPhrase,
    ),
  );
  const selectedContentTypes = useSelector(
    (state) => getContentTypesListing(state).selectedContentTypes,
  );
  const usageCountPerContentType = useSelector((state) => state.data.contentTypeUsages.byId);
  const webSpotlightConfiguration = useSelector((state) => state.webSpotlightApp.configuration);
  const createNewTypePath = buildPath<EnvironmentRouteParams>(CreateNewContentTypeRoute, {
    projectId: currentProjectId,
  });

  const dispatch = useDispatch();
  const onArchiveContentTypes = useMemo(() => compose(dispatch, archiveContentTypes), []);
  const onFilterContentTypes = useMemo(
    () => compose(dispatch, contentTypeListingFilterSearchPhraseChanged),
    [],
  );
  const onSelectContentTypes = useMemo(() => compose(dispatch, selectContentTypes), []);
  const onUnselectContentTypes = useMemo(() => compose(dispatch, unselectContentTypes), []);
  const onUndoOperation = useMemo(() => compose(dispatch, undoContentTypeListingOperation), []);

  const onOpenContentTypeAsComponentsUsageModal = useMemo(
    () =>
      compose(dispatch, (contentTypeId: Uuid) =>
        modalWithPropertiesOpened({
          dialogType: ModalDialogType.ContentTypeAsComponentUsageDialog,
          contentTypeId,
        }),
      ),
    [],
  );

  useThunkPromise(initContentTypeListing);

  return (
    <ContentTypeListingComponent
      contentTypeListingLoadingStatus={contentTypeListingLoadingStatus}
      contentTypes={orderedContentTypes}
      createNewTypePath={createNewTypePath}
      currentProjectId={currentProjectId}
      filterSearchPhrase={filterSearchPhrase}
      listingOperationStatus={listingOperationStatus}
      onArchiveContentTypes={onArchiveContentTypes}
      onFilterContentTypes={onFilterContentTypes}
      onOpenContentTypeAsComponentsUsageModal={onOpenContentTypeAsComponentsUsageModal}
      onSelectContentTypes={onSelectContentTypes}
      onUndoOperation={
        listingOperationStatus.operationType === ContentTypeListingOperationType.Archived
          ? onUndoOperation
          : undefined
      }
      onUnselectContentTypes={onUnselectContentTypes}
      selectedContentTypes={selectedContentTypes}
      usageCountPerContentType={usageCountPerContentType}
      webSpotlightConfiguration={webSpotlightConfiguration}
    />
  );
};
