import { Button } from '@kontent-ai/component-library/Button';
import React, { ComponentProps, PropsWithChildren } from 'react';

type ActivateFeatureButtonProps = {
  readonly featureName: string;
  readonly isActivating: boolean;
} & Omit<ComponentProps<typeof Button>, 'disabled' | 'size' | 'tooltipText' | 'buttonStyle'>;

export const ActivateFeatureButton: React.FC<PropsWithChildren<ActivateFeatureButtonProps>> = ({
  featureName,
  isActivating,
  ...restProps
}) => (
  <Button buttonStyle="tertiary" size="small" {...restProps} disabled={isActivating}>
    {isActivating && <Button.ProgressIcon />}
    <Button.Label>
      {isActivating ? 'Activating' : 'Activate'} {featureName}
    </Button.Label>
  </Button>
);
