import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React, { useCallback } from 'react';
import { ValidationConstants } from '../../../../../../_shared/constants/validationConstants.ts';
import { Capability } from '../../../../../../_shared/utils/permissions/capability.ts';
import { DraggableTypeElement } from '../../../containers/typeElements/DraggableTypeElement.tsx';
import { IBaseTypeElementData } from '../../../models/elements/types/TypeElementData.ts';
import {
  ITypeElementDataProps,
  ITypeElementOwnProps,
} from '../../../types/ITypeElementProps.type.ts';
import { convertToTypeElementConditionValidationResultOrNull } from '../../../utils/conditionUtils.ts';
import { BaseTypeElementValidationResult } from '../../../utils/typeElementValidators/types/BaseTypeElementValidationResult.type.ts';
import { TypeElementWithTypedNameValidationResult } from '../../../utils/typeElementValidators/types/TypeElementWithTypedNameValidationResult.type.ts';
import { TypeElementConfiguration } from './TypeElementConfiguration.tsx';
import { TypeElementHint } from './TypeElementHint.tsx';
import { TypeElementName } from './TypeElementName.tsx';

interface ITypeElementWithTypedNameDataProps
  extends ITypeElementDataProps<IBaseTypeElementData, TypeElementWithTypedNameValidationResult> {
  readonly hintContent?: React.ReactNode;
}

type TypeElementWithTypedNameProps = ITypeElementOwnProps<
  IBaseTypeElementData,
  BaseTypeElementValidationResult
> &
  ITypeElementWithTypedNameDataProps;

export const TypeElementWithTypedName: React.FC<
  React.PropsWithChildren<TypeElementWithTypedNameProps>
> = (props) => {
  const {
    children,
    capability,
    disabled,
    elementTypeName,
    hintContent,
    isConfigDisplayed,
    lastAddedElementId,
    typeElementData,
    validationResult,
    contentTypeKind,
    onChange,
  } = props;

  const handleChange = useCallback(
    (updatedData: Partial<IBaseTypeElementData>) => {
      onChange({
        ...typeElementData,
        ...updatedData,
      });
    },
    [onChange, typeElementData],
  );

  const updateName = useCallback(
    (name: string): void => {
      handleChange({ name });
    },
    [handleChange],
  );

  return (
    <DraggableTypeElement {...props}>
      <TypeElementName
        autoFocus={lastAddedElementId === typeElementData.elementId}
        elementTypeName={elementTypeName}
        highlightEmpty
        isNameValid={validationResult.isNameValid}
        name={typeElementData.name}
        onChange={updateName}
        maxLength={ValidationConstants.ElementNameMaxLength}
      />
      <Stack spacing={Spacing.XL}>
        {hintContent && <TypeElementHint>{hintContent}</TypeElementHint>}
        <TypeElementConfiguration
          contentTypeKind={contentTypeKind}
          conditionValidationResult={convertToTypeElementConditionValidationResultOrNull(
            validationResult,
          )}
          guidelinesDisabled={disabled || !capability.can(Capability.ConfigureContentTypes)}
          guidelinesValid={validationResult.isGuidelinesValid}
          onChange={handleChange}
          showConfig={isConfigDisplayed}
          typeElementData={typeElementData}
        >
          {children}
        </TypeElementConfiguration>
      </Stack>
    </DraggableTypeElement>
  );
};

TypeElementWithTypedName.displayName = 'TypeElementWithTypedName';
