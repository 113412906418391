import { Hint } from '@kontent-ai/component-library/Hint';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Toggle } from '@kontent-ai/component-library/Toggle';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React, { useContext } from 'react';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { getCurrentUser } from '../../../_shared/selectors/getCurrentUser.ts';
import { getCurrentProjectPlan } from '../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { isEmployee, isLearnRobot } from '../../../_shared/utils/subscriptionUsageUtils.ts';
import { isPartnerPlan } from '../../subscriptionManagement/shared/utils/planUtils.ts';
import { MissionControlDemoContext } from '../contexts/MissionControlDemoContext.tsx';

export const MissionControlDemoModeToggle: React.FC = () => {
  const currentUser = useSelector(getCurrentUser);
  const currentPlan = useSelector(getCurrentProjectPlan);

  const { isDemoModeEnabled, setIsDemoModeEnabled } = useContext(MissionControlDemoContext);

  if (
    !isEmployee(currentUser.email) &&
    !isLearnRobot(currentUser.email) &&
    !isPartnerPlan(currentPlan)
  ) {
    return null;
  }

  const tooltipText = isDemoModeEnabled
    ? 'Demo mode is enabled: Widgets display sample data to showcase functionality.'
    : 'Activate demo mode to fill widgets with sample data, showcasing functionality without real project metrics.';

  return (
    <Inline spacingX={Spacing.XS}>
      <Toggle
        status={isDemoModeEnabled ? 'on' : 'off'}
        labelText="Demo mode"
        onToggleOn={() => setIsDemoModeEnabled(true)}
        onToggleOff={() => setIsDemoModeEnabled(false)}
        tooltipText={tooltipText}
      />
      <Hint tooltipText={tooltipText} />
    </Inline>
  );
};

MissionControlDemoModeToggle.displayName = 'MissionControlDemoModeToggle';
