import { IListingContentItems } from '../../data/reducers/listingContentItems/IListingContentItems.type.ts';
import { LoadingStatus } from '../models/LoadingStatusEnum.ts';

export const isItemsInitialLoadFinished = (
  listingContentItems: Pick<IListingContentItems, 'allIds' | 'loadingStatus'>,
): boolean => {
  return (
    listingContentItems.loadingStatus !== LoadingStatus.InitialEmpty && !!listingContentItems.allIds
  );
};
