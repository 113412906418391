import { usePrevious } from '@kontent-ai/hooks';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useVariantTranslation } from '../../../../../../_shared/features/AI/hooks/useVariantTranslation.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { getCurrentUserId } from '../../../../../../_shared/selectors/getCurrentUser.ts';
import { getCurrentProjectId } from '../../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { getTranslationStatusCallback } from '../../../../../../_shared/utils/translations/getTranslationStatusCallback.ts';
import { VariantTranslationStatus } from '../../../../../../_shared/utils/translations/variantTranslationStatus.ts';
import { formatUserName } from '../../../../../../_shared/utils/users/usersUtils.ts';
import { variantTranslationFinished } from '../../actions/contentItemEditingActions.ts';
import { initContentItemEditing } from '../../actions/thunkContentItemEditingActions.ts';
import { VariantTranslationDialog as VariantTranslationDialogComponent } from '../../components/VariantTranslationDialog.tsx';
import { isTranslationCompletedWithSuccess } from '../../utils/variantTranslationUtils.ts';

const isDialogOpen = (
  translationStatus: VariantTranslationStatus | null,
  areElementsRefreshing: boolean,
): boolean => {
  if (!translationStatus) {
    return false;
  }

  if (areElementsRefreshing) {
    return (
      translationStatus === VariantTranslationStatus.Completed ||
      translationStatus === VariantTranslationStatus.PartiallyFailed
    );
  }

  return translationStatus === VariantTranslationStatus.InProgress;
};

const useGetDisabledStopButtonTooltipText = (
  translationStartedBy: string | null,
): string | null => {
  const userId = useSelector(getCurrentUserId);
  const isTranslationStartedByCurrentUser = translationStartedBy === userId;
  const user = useSelector((state) =>
    translationStartedBy ? state.data.users.usersById.get(translationStartedBy) : undefined,
  );

  if (!translationStartedBy || isTranslationStartedByCurrentUser) {
    return null;
  }

  const userName = formatUserName(user);

  return `${userName} is translating this content item. The translated content will be available soon.`;
};

type Props = {
  readonly itemId: Uuid;
  readonly variantId: Uuid;
};

export const VariantTranslationDialog = ({ itemId, variantId }: Props) => {
  const dispatch = useDispatch();
  const projectEnvironmentId = useSelector(getCurrentProjectId);

  const { translationStatus, cancelTranslation, translationStartedBy, fetchLatestProgress } =
    useVariantTranslation(projectEnvironmentId, itemId, variantId) ?? {};

  const disabledStopButtonTooltipText = useGetDisabledStopButtonTooltipText(translationStartedBy);

  const previousState = usePrevious(translationStatus);
  const [areElementsRefreshing, setAreElementsRefreshing] = useState(false);

  const onTranslationCancel = async () => {
    await cancelTranslation().then(() =>
      dispatch(variantTranslationFinished(projectEnvironmentId, itemId, variantId)),
    );
  };

  useEffect(() => {
    const fetchData = async () =>
      await fetchLatestProgress().then(
        (task) => task && dispatch(getTranslationStatusCallback(task)),
      );

    if (previousState !== translationStatus) {
      fetchData();
    }
  }, [fetchLatestProgress, previousState, translationStatus]);

  const history = useHistory();

  useEffect(() => {
    if (isTranslationCompletedWithSuccess(previousState, translationStatus)) {
      (async () => {
        setAreElementsRefreshing(true);
        await dispatch(initContentItemEditing(history, null));
        setAreElementsRefreshing(false);
      })();
    }
  }, [history, previousState, translationStatus]);

  return (
    <VariantTranslationDialogComponent
      isOpen={isDialogOpen(translationStatus, areElementsRefreshing)}
      disabledStopButtonTooltipText={disabledStopButtonTooltipText}
      onTranslationCancel={onTranslationCancel}
    />
  );
};
