import { memoize } from '@kontent-ai/memoization';
import { assert } from '@kontent-ai/utils';
import { CommonPlanName } from '../../data/constants/CommonPlanName.ts';
import { IPlan, emptyPlan } from '../../data/models/plans/Plan.ts';
import { ISubscription, emptySubscription } from '../../data/models/subscriptions/Subscription.ts';
import { IProjectLocation } from '../models/ProjectLocation.ts';
import { IStore } from '../stores/IStore.type.ts';

export function getSubscription(state: IStore, subscriptionId: Uuid): ISubscription {
  const {
    data: { subscriptions },
  } = state;

  return subscriptions.byId.get(subscriptionId) || emptySubscription;
}

export function getSubscriptionPlan(state: IStore, subscriptionId: Uuid): IPlan {
  const {
    data: { plans },
  } = state;
  const subscription = getSubscription(state, subscriptionId);

  return plans.byId.get(subscription.currentPlan.planId) || emptyPlan;
}

export function getSelectedSubscription(state: IStore): ISubscription | null {
  const {
    data: { subscriptions },
    subscriptionApp: { selectedSubscriptionId },
  } = state;

  if (!selectedSubscriptionId) {
    return null;
  }

  return subscriptions.byId.get(selectedSubscriptionId) || null;
}

export const getSelectedSubscriptionIdOrThrow = (state: IStore): Uuid => {
  const subscriptionId = getSelectedSubscription(state)?.subscriptionId;
  assert(subscriptionId, () => 'Selected subscription ID not loaded in the state');

  return subscriptionId;
};

export const getSelectedSubscriptionPlan = (state: IStore): IPlan | null => {
  const subscriptionId = getSelectedSubscription(state)?.subscriptionId;
  return subscriptionId ? getSubscriptionPlan(state, subscriptionId) : null;
};

export function isAdminOnSubscription(state: IStore, subscriptionId: Uuid): boolean {
  const {
    data: { subscriptions },
  } = state;

  return subscriptions.administratedIds.contains(subscriptionId);
}

export function hasEmployeePlan(state: IStore): boolean {
  const {
    data: { subscriptions },
  } = state;

  return subscriptions.administratedIds.some(
    (subscriptionId: Uuid) =>
      getSubscriptionPlan(state, subscriptionId).name === CommonPlanName.Employee,
  );
}

export function getSubscriptionAvailableProjectLocations(
  state: IStore,
  subscriptionId: Uuid,
): ReadonlyArray<IProjectLocation> {
  const {
    sharedApp: { availableProjectLocations },
  } = state;

  const subscription = getSubscription(state, subscriptionId);
  const { allowedProjectLocationIds } = subscription.subscriptionDetails;

  return getSubscriptionAvailableProjectLocationsMemoized(
    allowedProjectLocationIds,
    availableProjectLocations,
  );
}

export const getSubscriptionAvailableProjectLocationsMemoized = memoize.maxOne(
  (
    allowedProjectLocationIds: ReadonlyArray<Uuid>,
    availableProjectLocations: ReadonlyArray<IProjectLocation>,
  ): ReadonlyArray<IProjectLocation> => {
    const predicate: (loc: IProjectLocation) => boolean =
      allowedProjectLocationIds.length === 0
        ? (loc) => !loc.isDedicated
        : (loc) => allowedProjectLocationIds.includes(loc.projectLocationId);

    return availableProjectLocations.filter(predicate);
  },
);

export function getSubscriptionDefaultProjectLocation(
  state: IStore,
  subscriptionId: Uuid,
): IProjectLocation | null {
  const availableProjectLocations = getSubscriptionAvailableProjectLocations(state, subscriptionId);
  return (
    availableProjectLocations.find((loc: IProjectLocation) => loc.isDefault) ??
    availableProjectLocations[0] ??
    null
  );
}

export const getSubscriptionActiveProjectsCount = (state: IStore, subscriptionId: Uuid): number => {
  const {
    data: { projects },
  } = state;

  const subscriptionProjects = projects.byId.filter(
    (project) =>
      project?.subscriptionId === subscriptionId &&
      !project?.inactive &&
      project.projectId === project.masterEnvironmentId,
  );
  return subscriptionProjects.size;
};
