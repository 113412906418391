import { Card } from '@kontent-ai/component-library/Card';
import { Stack } from '@kontent-ai/component-library/Stack';
import { DefaultTag, Tag } from '@kontent-ai/component-library/Tag';
import { ShortcutsConfig, useHotkeys } from '@kontent-ai/component-library/hooks';
import { Spacing, colorAlertBackgroundInverse } from '@kontent-ai/component-library/tokens';
import React from 'react';
import {
  NotificationBar,
  NotificationBarType,
} from '../../../../_shared/components/NotificationBar.tsx';
import { ValidatedInput } from '../../../../_shared/components/input/ValidatedInput.tsx';
import { ValidatedMultipleOptionSelect } from '../../../../_shared/components/input/ValidatedMultipleOptionSelect.tsx';
import { ValidatedTextArea } from '../../../../_shared/components/input/ValidatedTextArea.tsx';
import { ValidationConstants } from '../../../../_shared/constants/validationConstants.ts';
import { HookFormProps } from '../../../../_shared/types/hookFormProps.type.ts';
import {
  DataUiAppName,
  DataUiCollection,
  DataUiElement,
  DataUiInput,
  getDataUiAppNameAttribute,
  getDataUiCollectionAttribute,
  getDataUiElementAttribute,
  getDataUiInputAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { RoleOption } from '../../../../data/models/roles/RoleOption.ts';
import { deletedRoleIdBase } from '../../constants/getDeletedRole.ts';
import { handleAllRolesRoleOnRolesChangedInFormInput } from '../../utils/allRolesRoleBehaviorUtils.ts';
import { CustomAppFormShape } from '../models/CustomAppFormShape.type.ts';

type CustomAppEditorFormProps = {
  readonly formProps: HookFormProps<CustomAppFormShape>;
  readonly hasError: boolean;
  readonly onCloseNotificationBar: () => void;
  readonly onSubmit: (e?: KeyboardEvent | React.BaseSyntheticEvent) => Promise<void>;
  readonly roles: ReadonlyArray<RoleOption>;
};

export const CustomAppEditorForm = ({
  formProps,
  hasError,
  onCloseNotificationBar,
  onSubmit,
  roles,
}: CustomAppEditorFormProps) => {
  useHotkeys(
    {
      [ShortcutsConfig.ControlS]: (e: KeyboardEvent): void => {
        onSubmit(e);
      },
    },
    { ref: null },
  );

  return (
    <div className="canvas">
      <div
        className="canvas__workspace canvas__workspace--detached-scrollbars canvas__workspace--in-custom-app"
        {...getDataUiAppNameAttribute(DataUiAppName.CustomAppsConfiguration)}
      >
        <div className="canvas__wrapper">
          <section className="canvas__content">
            {hasError && (
              <div className="canvas__notifications">
                <NotificationBar
                  message="Custom app cannot be saved. Correct the highlighted elements first."
                  onClosePanel={onCloseNotificationBar}
                  type={NotificationBarType.Error}
                />
              </div>
            )}
            <div className="canvas__content-pane">
              <div className="canvas__inner-section canvas__inner-section--restricted-width canvas__inner-section--centered">
                <Stack spacing={Spacing.XL}>
                  <Card cardLabel="General" component="section">
                    <Card.Headline {...getDataUiElementAttribute(DataUiElement.FormSection)}>
                      General
                    </Card.Headline>
                    <Card.Body>
                      <Stack spacing={Spacing.XL}>
                        <ValidatedInput<CustomAppFormShape>
                          autoFocus
                          name="name"
                          label="App name"
                          dataUiInputName={DataUiInput.EntityName}
                          formProps={formProps}
                          maxLength={ValidationConstants.CustomAppNameMaxLength}
                          placeholder="Provide custom app name"
                        />
                        <ValidatedInput<CustomAppFormShape>
                          name="sourceUrl"
                          label="Hosted code URL (HTTPS)"
                          dataUiInputName={DataUiInput.Url}
                          formProps={formProps}
                          maxLength={ValidationConstants.UrlMaxLength}
                          placeholder="https://example.com"
                        />
                      </Stack>
                    </Card.Body>
                  </Card>
                  <Card cardLabel="Settings" component="section">
                    <Card.Headline {...getDataUiElementAttribute(DataUiElement.FormSection)}>
                      Settings
                    </Card.Headline>
                    <Card.Body>
                      <Stack spacing={Spacing.XL}>
                        <ValidatedMultipleOptionSelect<CustomAppFormShape, RoleOption>
                          formProps={formProps}
                          items={roles}
                          label="Roles that can work with this app"
                          name="allowedRoleIds"
                          normalize={handleAllRolesRoleOnRolesChangedInFormInput}
                          placeholder="Select roles that can work with this custom app"
                          renderSelectedOption={(id, _role, defaultTagProps) => {
                            if (id.startsWith(deletedRoleIdBase)) {
                              return (
                                <Tag
                                  {...defaultTagProps}
                                  background={colorAlertBackgroundInverse}
                                />
                              );
                            }

                            return <DefaultTag {...defaultTagProps} />;
                          }}
                          verticalMenuDataAttributes={getDataUiCollectionAttribute(
                            DataUiCollection.RoleList,
                          )}
                          {...getDataUiInputAttribute(DataUiInput.CustomAppRoles)}
                        />
                        <ValidatedTextArea<CustomAppFormShape>
                          name="config"
                          label="Parameters {JSON}"
                          formProps={formProps}
                          placeholder="Place your JSON here"
                        />
                      </Stack>
                    </Card.Body>
                  </Card>
                </Stack>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};
