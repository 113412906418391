import { ReactNode, createContext, useMemo } from 'react';

type ContextProps = {
  readonly isProjectDataRestrictedToSpecificDatacenter: boolean;
};

type Props = ContextProps & {
  readonly children: ReactNode;
};

export const ProjectDataRestrictedToSpecificDatacenterContext = createContext<ContextProps>({
  isProjectDataRestrictedToSpecificDatacenter: false,
});
ProjectDataRestrictedToSpecificDatacenterContext.displayName =
  'IsProjectDataRestrictedToSpecificDatacenterContext';

export const ProjectDataRestrictedToSpecificDatacenterContextProvider = (props: Props) => {
  const contextValue = useMemo(
    () => ({
      isProjectDataRestrictedToSpecificDatacenter:
        props.isProjectDataRestrictedToSpecificDatacenter,
    }),
    [props.isProjectDataRestrictedToSpecificDatacenter],
  );

  return (
    <ProjectDataRestrictedToSpecificDatacenterContext.Provider value={contextValue}>
      {props.children}
    </ProjectDataRestrictedToSpecificDatacenterContext.Provider>
  );
};
