import React, { memo } from 'react';
import { DataUiElement } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  EditorProps,
  emptyPluginComposition,
  useEditor,
  usePluginComposition,
} from '../../editorCore/Editor.composition.tsx';
import { ModalsPlugin } from '../../plugins/ModalsPlugin.tsx';
import { ApiLimitationsPlugin } from '../../plugins/apiLimitations/ApiLimitationsPlugin.tsx';
import { EditAssetPlugin } from '../../plugins/assets/EditAssetPlugin.tsx';
import { CompositionModeAdjustmentsPlugin } from '../../plugins/behavior/CompositionModeAdjustmentsPlugin.tsx';
import { CustomCaretHandlingPlugin } from '../../plugins/behavior/CustomCaretHandlingPlugin.tsx';
import { FocusPlugin } from '../../plugins/behavior/FocusPlugin.tsx';
import { OnChangePlugin } from '../../plugins/behavior/OnChangePlugin.tsx';
import { SpellCheckPlugin } from '../../plugins/behavior/SpellCheckPlugin.tsx';
import { ClearFormattingPlugin } from '../../plugins/clearFormatting/ClearFormattingPlugin.tsx';
import { ClipboardPlugin } from '../../plugins/clipboard/ClipboardPlugin.tsx';
import { CustomBlocksPlugin } from '../../plugins/customBlocks/CustomBlocksPlugin.tsx';
import {
  CustomInputHandlingPlugin,
  EnterKeyBehavior,
} from '../../plugins/customInputHandling/CustomInputHandlingPlugin.tsx';
import { DraftJsPlugin } from '../../plugins/draftJs/DraftJsPlugin.tsx';
import { DragDropPlugin } from '../../plugins/dragDrop/DragDropPlugin.tsx';
import { EntityApiPlugin } from '../../plugins/entityApi/EntityApiPlugin.tsx';
import { ImagesPlugin } from '../../plugins/images/ImagesPlugin.tsx';
import { InlineStylesPlugin } from '../../plugins/inlineStyles/InlineStylesPlugin.tsx';
import { KeyboardShortcutsPlugin } from '../../plugins/keyboardShortcuts/KeyboardShortcutsPlugin.tsx';
import { richTextKeyCommandMap } from '../../plugins/keyboardShortcuts/api/editorKeyboardUtils.ts';
import { LinksPlugin } from '../../plugins/links/LinksPlugin.tsx';
import { AssetLinksPlugin } from '../../plugins/links/asset/AssetLinksPlugin.tsx';
import { ContentLinksPlugin } from '../../plugins/links/content/ContentLinksPlugin.tsx';
import { EmailLinksPlugin } from '../../plugins/links/email/EmailLinksPlugin.tsx';
import { PhoneLinksPlugin } from '../../plugins/links/phone/PhoneLinksPlugin.tsx';
import { AutomaticWebLinkConversionPlugin } from '../../plugins/links/web/AutomaticWebLinkConversionPlugin.tsx';
import { WebLinksPlugin } from '../../plugins/links/web/WebLinksPlugin.tsx';
import { TablesPlugin } from '../../plugins/tables/TablesPlugin.tsx';
import { TextApiPlugin } from '../../plugins/textApi/TextApiPlugin.tsx';
import { TextBlockTypesPlugin } from '../../plugins/textBlockTypes/TextBlockTypesPlugin.tsx';
import { BlockToolbarPlugin } from '../../plugins/toolbars/BlockToolbarPlugin.tsx';
import { InlineToolbarPlugin } from '../../plugins/toolbars/InlineToolbarPlugin.tsx';
import { UndoRedoPlugin } from '../../plugins/undoRedo/UndoRedoPlugin.tsx';
import { PlaceholderPlugin } from '../../plugins/visuals/PlaceholderPlugin.tsx';
import { StylesPlugin } from '../../plugins/visuals/StylesPlugin.tsx';
import { WrapperPlugin } from '../../plugins/visuals/WrapperPlugin.tsx';
import { RichTextInputStatusPlugin } from '../richText/plugins/RichTextInputStatusPlugin.tsx';
import { GuidelinesEditorLimitations } from './guidelinesEditorLimitations.ts';

const useComposition = () =>
  usePluginComposition(
    emptyPluginComposition,
    DraftJsPlugin,
    PlaceholderPlugin,
    WrapperPlugin,
    CompositionModeAdjustmentsPlugin,
    FocusPlugin,
    ApiLimitationsPlugin,
    KeyboardShortcutsPlugin,
    TextApiPlugin,
    ClipboardPlugin,
    UndoRedoPlugin,
    StylesPlugin,
    ModalsPlugin,
    InlineToolbarPlugin,
    BlockToolbarPlugin,
    OnChangePlugin,
    DragDropPlugin,
    CustomInputHandlingPlugin,
    TextBlockTypesPlugin,
    InlineStylesPlugin,
    EntityApiPlugin,
    LinksPlugin,
    ClearFormattingPlugin,
    CustomBlocksPlugin,
    EditAssetPlugin,
    ImagesPlugin,
    AutomaticWebLinkConversionPlugin,
    WebLinksPlugin,
    ContentLinksPlugin,
    AssetLinksPlugin,
    EmailLinksPlugin,
    PhoneLinksPlugin,
    TablesPlugin,
    SpellCheckPlugin,
    CustomCaretHandlingPlugin,
    RichTextInputStatusPlugin,
  );

export type GuidelinesInputProps = Omit<
  EditorProps<typeof useComposition>,
  'limitations' | 'hidesDisallowedFeatures'
>;

export const GuidelinesInput: React.FC<GuidelinesInputProps> = memo((props) => {
  const composition = useComposition();

  return useEditor(composition, {
    ...props,
    dataUiElement: props.dataUiElement ?? DataUiElement.RichTextEditor,
    enterKeyBehavior: EnterKeyBehavior.SoftNewLineWithShift,
    hidesDisallowedFeatures: true,
    keyCommandMap: richTextKeyCommandMap,
    limitations: GuidelinesEditorLimitations,
    placeholder: props.placeholder ?? 'Type your text here…',
    tabIndex: props.tabIndex ?? -1,
  });
});

GuidelinesInput.displayName = 'GuidelinesInput';
