import { getCurrentProjectId } from '../../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import {
  VariantTranslationTaskId,
  makeVariantTranslationTaskId,
} from '../../../../../../_shared/utils/translations/variantTranslationTaskId.ts';

export const getCurrentlyEditedVariantTranslationTaskId = (
  state: IStore,
): VariantTranslationTaskId | null => {
  const variant = state.contentApp.editedContentItemVariant;
  const environmentId = getCurrentProjectId(state);

  return (
    variant && makeVariantTranslationTaskId(environmentId, variant.id.itemId, variant.id.variantId)
  );
};
