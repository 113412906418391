import { Box } from '@kontent-ai/component-library/Box';
import React, { useState } from 'react';
import { DialogState } from '../../../../../../../component-library/components/Dialogs/DialogStateEnum.ts';
import { usePopoverDialog } from '../../../../../../../component-library/components/Dialogs/Popover/usePopoverDialog.tsx';
import { trackUserEvent } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { gridUnit } from '../../../../../../_shared/constants/styleConstants.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { getContentTypesWithUrlSlugIds } from '../../../../contentTypes/selectors/getContentTypesWithUrlSlug.ts';
import { SelectContentTypesWithUrlSlugButton } from '../../../components/typeElements/individualTypeElements/richText/SelectContentTypesWithUrlSlugButton.tsx';

type ContentTypesWithUrlSlugSelectorProps = {
  readonly allowedItemLinkTypes: ReadonlyArray<Uuid>;
  readonly isDisabled: boolean;
  readonly onUpdateContentTypes: (allowedItemLinkTypesOptions: ReadonlySet<Uuid>) => void;
  readonly tooltipText: string | undefined;
};

export const ContentTypesWithUrlSlugSelector: React.FC<ContentTypesWithUrlSlugSelectorProps> = ({
  allowedItemLinkTypes,
  isDisabled,
  onUpdateContentTypes,
  tooltipText,
}) => {
  const dispatch = useDispatch();
  const contentTypesWithUrlSlugIds = useSelector(getContentTypesWithUrlSlugIds);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const closePopover = () => setIsPopoverOpen(false);

  const updateLinkContentTypesWithUrlSlug = () => {
    onUpdateContentTypes(contentTypesWithUrlSlugIds);
    dispatch(trackUserEvent(TrackedEvent.ContentTypesWithURLSlugSelected));
  };

  const selectLinkContentTypesWithUrlSlug = () => {
    const isAnyContentTypeWithoutUrlSlugSelected = allowedItemLinkTypes.some(
      (id: Uuid) => !contentTypesWithUrlSlugIds.has(id),
    );

    if (isAnyContentTypeWithoutUrlSlugSelected) {
      setIsPopoverOpen(true);
    } else {
      updateLinkContentTypesWithUrlSlug();
    }
  };

  const { PopoverDialog, popoverDialogProps, targetProps } = usePopoverDialog({
    dialogState: DialogState.Default,
    headline: 'Replace the previous selection?',
    onClose: closePopover,
    primaryAction: {
      onClick: () => {
        closePopover();
        updateLinkContentTypesWithUrlSlug();
      },
      text: 'Replace',
    },
  });

  const isNoContentTypeWithUrlSlug = !contentTypesWithUrlSlugIds.size;
  const noContentTypeWithUrlSlugMessage = isNoContentTypeWithUrlSlug
    ? 'There are no content types with URL slug.'
    : undefined;

  return (
    <>
      <SelectContentTypesWithUrlSlugButton
        isDisabled={isDisabled || isPopoverOpen || isNoContentTypeWithUrlSlug}
        onClick={selectLinkContentTypesWithUrlSlug}
        tooltipText={tooltipText || noContentTypeWithUrlSlugMessage}
        targetProps={targetProps}
      />
      <PopoverDialog {...popoverDialogProps} isOpen={isPopoverOpen}>
        <Box maxWidth={gridUnit * 65}>
          <p>There are already some content types selected for the item links.</p>
          <p>
            Do you want to replace your previous selection with content types using URL slug
            elements?
          </p>
        </Box>
      </PopoverDialog>
    </>
  );
};
