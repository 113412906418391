import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { Callout } from '@kontent-ai/component-library/Callout';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { selectIntercomUtils } from '../../../../../_shared/selectors/selectIntercomUtils.ts';
import { InnovationLabFeatureCard } from '../../components/InnovationLabFeatureCard.tsx';
import { InnovationLabFeatureCardWrapper } from '../../components/InnovationLabFeatureCardWrapper.tsx';
import { InnovationLabFeatureDialogs } from '../../components/InnovationLabFeatureDialogs.tsx';
import { InnovationLabGenericConsent } from '../../components/InnovationLabGenericConsent.tsx';
import { useFeatureActivationDialog } from '../../hooks/useFeatureActivationDialog.ts';
import { activateInnovationLabFeature } from '../../thunks/activateInnovationLabFeature.ts';
import { deactivateInnovationLabFeature } from '../../thunks/deactivateInnovationLabFeature.ts';
import { InnovationLabCardProps } from '../../types/InnovationLabCardProps.type.ts';

export const AssetReplacementCard = ({ state, info, name }: InnovationLabCardProps) => {
  const intercomUtils = useSelector(selectIntercomUtils);

  const {
    dialogDisplayed,
    isAsyncOperationInProgress,
    onCardActionButtonClick,
    onDialogActionClick,
    onDialogClose,
  } = useFeatureActivationDialog(
    info,
    state,
    activateInnovationLabFeature('asset-replace'),
    deactivateInnovationLabFeature('asset-replace'),
  );

  const featureState = info.status;

  return (
    <>
      <InnovationLabFeatureCardWrapper featureState={featureState}>
        <InnovationLabFeatureCard
          activationState={state}
          featureName={name}
          featureState={featureState}
          isActionButtonLoading={isAsyncOperationInProgress}
          onCardActionButtonClick={onCardActionButtonClick}
          onContactSupport={intercomUtils.showIntercom}
          renderCardBody={() => (
            <>
              <p>
                Effortlessly replace{' '}
                <OutwardLink href="https://kontent.ai/learn/docs/assets/asset-library#a-fast-asset-replacement">
                  outdated assets
                </OutwardLink>{' '}
                with their new versions
              </p>
              <p>
                This feature eliminates the need to create a new asset and manually update each
                occurrence of the old one.
              </p>
            </>
          )}
        />
      </InnovationLabFeatureCardWrapper>
      <InnovationLabFeatureDialogs
        dialogDisplayed={dialogDisplayed}
        dialogFeatureName="fast asset replacement"
        isAsyncOperationInProgress={isAsyncOperationInProgress}
        onDialogActionClick={onDialogActionClick}
        onDialogClose={onDialogClose}
        renderActivationDialogBody={() => (
          <>
            <Callout calloutType="quickTip" hideSubheadline>
              <p>
                <b>Make sure you understand the effects of using the fast asset replacement:</b>
              </p>
              <ul>
                <li>
                  Replacing an asset affects all content items using it, including published
                  content.
                </li>
                <li>
                  The asset URL changes after replacement. This affects any uses of or links to the
                  asset.
                </li>
                <li>
                  The replaced asset is removed from the UI but not from storage and remains
                  available at its original asset URL.
                </li>
                <li>
                  The replacement ignores content type limitations for the file type, file size, and
                  image resolution.
                </li>
                <li>
                  The replacement keeps existing asset renditions. This can result in incorrectly
                  customized images. You may need to check them manually afterward.
                </li>
                <li>The replacement is immediate and cannot be undone.</li>
              </ul>
            </Callout>
            <InnovationLabGenericConsent />
          </>
        )}
      />
    </>
  );
};
