import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import classNames from 'classnames';
import React from 'react';
import {
  SimpleTabTitle,
  SimpleTabTitleModifier,
} from '../../../../../_shared/components/TabMenu/SimpleTabTitle.tsx';
import { TabBadgeType } from '../../../../../_shared/components/TabMenu/TabBadgeType.tsx';
import { TabWrapper } from '../../../../../_shared/components/TabMenu/TabWrapper.tsx';
import {
  DataUiElement,
  getDataUiElementAttribute,
  getDataUiObjectNameAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

import { IContentGroup } from '../../../../contentInventory/content/models/contentTypeElements/types/ContentGroup.ts';
import { getTooltipText } from '../utils/tooltipUilts.ts';
import { ContentGroupTabComments } from './ContentGroupTabComments.tsx';

export const ContentGroupTabCssClass = 'content-group-tab';
export const SelectedContentGroupTabCssClass = 'content-group-tab--is-selected';

export interface IContentGroupTabOwnProps {
  readonly badgeType?: TabBadgeType;
  readonly contentGroup: IContentGroup;
  readonly hideComments?: boolean;
  readonly incompleteElements: number;
  readonly selected: boolean;
  readonly onSelect: () => void;
}

interface IContentGroupTabProps extends IContentGroupTabOwnProps {
  readonly commentCount?: number;
  readonly onCommentsClick: () => void;
}

export const ContentGroupTab: React.FC<IContentGroupTabProps> = ({
  badgeType,
  commentCount,
  contentGroup,
  hideComments,
  incompleteElements,
  selected,
  onCommentsClick,
  onSelect,
}) => {
  const tooltipText = getTooltipText(incompleteElements, commentCount || 0);
  const showComments = !hideComments && !!commentCount;

  return (
    <Tooltip text={tooltipText} placement="top">
      <TabWrapper
        className={classNames(ContentGroupTabCssClass, {
          [SelectedContentGroupTabCssClass]: selected,
        })}
        dataUiAttributes={{
          ...getDataUiElementAttribute(DataUiElement.ContentGroup),
          ...getDataUiObjectNameAttribute(contentGroup.name),
        }}
        selected={selected}
        onClick={onSelect}
        badgeType={badgeType}
      >
        <div className="tabbed-navigation__tab-layout">
          <SimpleTabTitle
            title={contentGroup.name}
            modifier={SimpleTabTitleModifier.IsInContentGroup}
          />
          {showComments && (
            <ContentGroupTabComments
              commentCount={commentCount}
              onCommentsClick={onCommentsClick}
            />
          )}
        </div>
      </TabWrapper>
    </Tooltip>
  );
};

ContentGroupTab.displayName = 'ContentGroupTab';
