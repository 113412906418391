import { InvariantException } from '@kontent-ai/errors';
import { createGuid } from '@kontent-ai/utils';
import {
  Dispatch,
  GetState,
  ThunkPromise,
} from '../../../../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../../../../_shared/constants/trackedEvent.ts';
import { SchedulePublishErrorMessage } from '../../../../../../../../_shared/features/ChangeWorkflowStepModal/constants/uiConstants.ts';
import { TrackUserEventAction } from '../../../../../../../../_shared/models/TrackUserEvent.type.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { logError } from '../../../../../../../../_shared/utils/logError.ts';
import { IContentItemRepository } from '../../../../../../../../repositories/interfaces/IContentItemRepository.type.ts';
import { getItemListingOperationQueryFromFilter } from '../../../../../utils/contentItemListModelConverters.ts';
import {
  ContentListing_ScheduledPublishItems_Failed,
  ContentListing_ScheduledPublishItems_Finished,
  ContentListing_ScheduledPublishItems_Started,
} from '../../../constants/contentItemInventoryActionTypes.ts';
import { createBulkActionResult } from '../../../utils/createBulkActionResult.ts';
import {
  createBulkActionFailed,
  createBulkActionFinished,
  createBulkActionStarted,
} from '../../contentItemInventoryActions.ts';
import { setTimeoutForMessageChange } from './setTimeoutForMessageChange.ts';

interface ISchedulePublishSelectedContentItemsActionDependencies {
  readonly contentItemRepository: IContentItemRepository;
  readonly trackUserEvent: TrackUserEventAction;
  readonly loadListingContentItems: () => ThunkPromise;
}

const actionStarted = createBulkActionStarted<typeof ContentListing_ScheduledPublishItems_Started>(
  ContentListing_ScheduledPublishItems_Started,
);
const actionFinished = createBulkActionFinished<
  typeof ContentListing_ScheduledPublishItems_Finished
>(ContentListing_ScheduledPublishItems_Finished);
const actionFailed = createBulkActionFailed<typeof ContentListing_ScheduledPublishItems_Failed>(
  ContentListing_ScheduledPublishItems_Failed,
);

export type SchedulePublishSelectedContentItemsActionsType = ReturnType<
  typeof actionStarted | typeof actionFinished | typeof actionFailed
>;

export const schedulePublishSelectedContentItemsActionCreator =
  (deps: ISchedulePublishSelectedContentItemsActionDependencies) =>
  (): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const {
      scheduledToPublishAt,
      scheduledPublishDisplayTimeZone,
      scheduledToUnpublishAt,
      scheduledUnpublishDisplayTimeZone,
    } = getState().contentApp.changeWorkflowStepModalData;

    if (!scheduledToPublishAt) {
      throw InvariantException('scheduledToPublishAt is falsy');
    }

    const selectedLanguageId = getSelectedLanguageIdOrThrow(getState());
    const query = getItemListingOperationQueryFromFilter(getState());

    const operationId = createGuid();
    dispatch(actionStarted({ operationId }));
    dispatch(setTimeoutForMessageChange(operationId));

    try {
      const operationResult = await deps.contentItemRepository.scheduleVariantsPublish(
        selectedLanguageId,
        {
          listingOperationQuery: query,
          scheduledToPublishAt,
          scheduledPublishDisplayTimeZone,
          scheduledToUnpublishAt,
          scheduledUnpublishDisplayTimeZone,
        },
      );

      dispatch(actionFinished(createBulkActionResult(operationResult, selectedLanguageId)));

      dispatch(
        deps.trackUserEvent(TrackedEvent.ContentEntryBulkScheduled, {
          count: operationResult.modifiedItemIds.length,
        }),
      );
    } catch (error) {
      logError('Error while bulk scheduling publish', error);
      dispatch(actionFailed(SchedulePublishErrorMessage));
    }

    dispatch(deps.loadListingContentItems());
  };
