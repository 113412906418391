import { InputState, Textarea } from '@kontent-ai/component-library/Input';
import { ChangeEvent, memo } from 'react';
import { WhenAiReviewFeatureEnabled } from '../../../../../../_shared/features/AI/containers/WhenAiReviewFeatureEnabled.tsx';
import {
  DataUiElement,
  DataUiInput,
  getDataUiInputAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { AiGuidelinesConfiguration } from '../../../../../../paperModels/aiReview/contentModels/aiGuidelines/containers/AiGuidelinesConfiguration.tsx';
import { ITextTypeElementData } from '../../../models/elements/TextTypeElementData.ts';
import {
  ITypeElementDataProps,
  ITypeElementOwnProps,
} from '../../../types/ITypeElementProps.type.ts';
import { TextTypeElementValidationResult } from '../../../utils/typeElementValidators/types/TextTypeElementValidationResult.type.ts';
import { TypeElementConfigurationCategory } from '../shared/TypeElementConfigurationCategory.tsx';
import { TypeElementRegex } from '../shared/TypeElementRegex.tsx';
import { TypeElementWithTypedName } from '../shared/TypeElementWithTypedName.tsx';
import { BaseTextTypeLimit } from '../shared/configuration/BaseTextTypeLimit.tsx';

interface ITextTypeElementDataProps
  extends ITypeElementDataProps<ITextTypeElementData, TextTypeElementValidationResult> {
  readonly generatesUrlSlug?: boolean;
  readonly cannotBeDeleted?: boolean;
}

type TextTypeElementProps = ITypeElementOwnProps<
  ITextTypeElementData,
  TextTypeElementValidationResult
> &
  ITextTypeElementDataProps;

const TextTypeElement: React.FC<TextTypeElementProps> = (props: TextTypeElementProps) => {
  const { onChange, typeElementData, validationResult } = props;

  const updateElement = (updatedElement: ITextTypeElementData): void => onChange(updatedElement);
  const updateDefaultValue = (e: ChangeEvent<HTMLInputElement>) =>
    onChange({
      ...typeElementData,
      defaultValue: e.currentTarget.value,
    });
  const updateAiGuidelinesValue = (aiGuidelinesIds: ReadonlyArray<Uuid>) =>
    onChange({
      ...typeElementData,
      aiGuidelinesIds,
    });
  const selectedAiGuidelinesIds = typeElementData.aiGuidelinesIds;

  return (
    <TypeElementWithTypedName
      {...props}
      deleteButtonTooltip="Element is currently used to generate URL slug and cannot be deleted."
    >
      <WhenAiReviewFeatureEnabled>
        <AiGuidelinesConfiguration
          onAiGuidelinesSelect={updateAiGuidelinesValue}
          selectedAiGuidelinesIds={selectedAiGuidelinesIds}
        />
      </WhenAiReviewFeatureEnabled>
      <TypeElementConfigurationCategory>
        <BaseTextTypeLimit
          onChange={updateElement}
          uiElement={DataUiElement.TextLengthLimitation}
          typeElementData={typeElementData}
          validationResult={validationResult}
        />
      </TypeElementConfigurationCategory>
      <TypeElementRegex {...props} showPredefinedRegexes />
      <div className="content-element__config-category">
        <Textarea
          {...getDataUiInputAttribute(DataUiInput.DefaultValue)}
          inputState={validationResult.isDefaultValueValid ? InputState.Default : InputState.Alert}
          label="Default value"
          onChange={updateDefaultValue}
          placeholder="Set default value for this element"
          value={typeElementData.defaultValue}
        />
      </div>
    </TypeElementWithTypedName>
  );
};

TextTypeElement.displayName = 'TextTypeElement';

const TextTypeElementMemo = memo(TextTypeElement);
export { TextTypeElementMemo as TextTypeElement };
