import { createGuid } from '@kontent-ai/utils';
import { useEffect, useState } from 'react';
import { flushSync } from 'react-dom';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { Loader } from '../../../_shared/components/Loader.tsx';
import {
  SubscriptionEnvironmentWebhooksEditingRoute,
  SubscriptionEnvironmentWebhooksEditorRouteParams,
  WebhookEditorRouteParams,
  WebhooksEditingRoute,
} from '../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../_shared/hooks/useThunkPromise.ts';
import { getSelectedSubscription } from '../../../_shared/selectors/subscriptionSelectors.ts';
import { buildPath } from '../../../_shared/utils/routing/routeTransitionUtils.ts';
import { createFormValidationResolver } from '../../../_shared/utils/validation/createFormValidationResolver.ts';
import {
  EntityWebhookAction,
  EntityWebhookDeliverySlot,
  EntityWebhookEventsFilter,
  emptyEntityWebhook,
} from '../../../data/models/webhooks/EntityWebhookSetting.ts';
import { entityWebhookNameChanged } from '../actions/entityWebhookActions.ts';
import {
  initEntityWebhookCreator,
  insertEntityWebhook,
} from '../actions/thunkEntityWebhookActions.ts';
import { EntityWebhookEditorForm as EntityWebhookEditorFormComponent } from '../components/EntityWebhookEditorForm.tsx';
import { anyCollectionCollection } from '../constants/anyCollectionCollection.ts';
import { anyContentTypeContentType } from '../constants/anyContentTypeContentType.ts';
import { anyLanguageLanguage } from '../constants/anyLanguageLanguage.ts';
import { anyTaxonomyTaxonomy } from '../constants/anyTaxonomyTaxonomy.ts';
import { anyWorkflowStepWorkflowStep } from '../constants/anyWorkflowStepWorkflowStep.ts';
import { IEntityWebhookFormShape } from '../models/IEntityWebhookFormShape.type.ts';
import { entityWebhookItemEditorFormValidationConfig } from '../validation/entityWebhookSettingValidation.ts';

const initFormValues: IEntityWebhookFormShape = {
  headers: [],
  name: '',
  secret: '',
  triggers: {
    assetTrigger: {
      actions: [
        EntityWebhookAction.Changed,
        EntityWebhookAction.Deleted,
        EntityWebhookAction.Created,
        EntityWebhookAction.MetadataChanged,
      ],
      checked: true,
    },
    contentItemTrigger: {
      actions: [
        EntityWebhookAction.Changed,
        EntityWebhookAction.Deleted,
        EntityWebhookAction.Created,
        EntityWebhookAction.MetadataChanged,
        EntityWebhookAction.WorkflowStepChanged,
        EntityWebhookAction.Published,
        EntityWebhookAction.Unpublished,
      ],
      collectionIds: [anyCollectionCollection.id],
      contentTypeIds: [anyContentTypeContentType.id],
      checked: true,
      languageIds: [anyLanguageLanguage.id],
      workflowSteps: [anyWorkflowStepWorkflowStep.id],
    },
    contentTypeTrigger: {
      actions: [
        EntityWebhookAction.Changed,
        EntityWebhookAction.Deleted,
        EntityWebhookAction.Created,
      ],
      checked: true,
      contentTypeIds: [anyContentTypeContentType.id],
    },
    events: EntityWebhookEventsFilter.All,
    languageTrigger: {
      actions: [
        EntityWebhookAction.Changed,
        EntityWebhookAction.Deleted,
        EntityWebhookAction.Created,
      ],
      checked: true,
      languageIds: [anyLanguageLanguage.id],
    },
    slot: EntityWebhookDeliverySlot.Published,
    taxonomyTrigger: {
      actions: [
        EntityWebhookAction.Deleted,
        EntityWebhookAction.Created,
        EntityWebhookAction.MetadataChanged,
        EntityWebhookAction.TermChanged,
        EntityWebhookAction.TermCreated,
        EntityWebhookAction.TermDeleted,
        EntityWebhookAction.TermsMoved,
      ],
      checked: true,
      taxonomyIds: [anyTaxonomyTaxonomy.id],
    },
  },
  url: '',
};

export const EntityWebhookCreatorForm = () => {
  const [isInitThunkDone] = useThunkPromise(initEntityWebhookCreator);

  const dispatch = useDispatch();
  const history = useHistory();
  const projectId = useSelector((state) => state.sharedApp.currentProjectId);

  const subscriptionId = useSelector((state) => getSelectedSubscription(state)?.subscriptionId);

  const formProps = useForm<IEntityWebhookFormShape>({
    defaultValues: initFormValues,
    mode: 'onTouched',
    resolver: createFormValidationResolver(entityWebhookItemEditorFormValidationConfig, {}),
    shouldFocusError: false,
  });

  const [entityWebhookActionIsInProgress, setEntityWebhookActionIsInProgress] =
    useState<boolean>(false);
  const [displayNotificationBar, setDisplayNotificationBar] = useState<boolean>(false);

  const [isValid, setIsValid] = useState<boolean>(false);

  const { formState, handleSubmit, watch, trigger, reset, setValue, getValues } = formProps;

  useEffect(() => {
    const subscription = watch(async () => {
      // Prevents url and name from being validated before their inputs are touched
      const isUrlValid = !!formState.touchedFields.url && (await trigger('url'));
      const isNameValid = !!formState.touchedFields.name && (await trigger('name'));
      const isRestValid = await trigger(['headers', 'secret', 'triggers']);

      const isActualFormValid = isUrlValid && isNameValid && isRestValid;
      if (displayNotificationBar && isActualFormValid) {
        setDisplayNotificationBar(false);
      }

      setIsValid(isActualFormValid);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [
    displayNotificationBar,
    formState.touchedFields.name,
    formState.touchedFields.url,
    trigger,
    watch,
  ]);

  useEffect(() => {
    const nameSubscription = watch((formValues: IEntityWebhookFormShape, { name }) => {
      if (name === 'name') {
        dispatch(entityWebhookNameChanged(formValues.name));
      }
    });

    return () => {
      nameSubscription.unsubscribe();
    };
  }, [watch]);

  const createWebhook = async (formValues: IEntityWebhookFormShape): Promise<Uuid> => {
    setEntityWebhookActionIsInProgress(true);
    const newWebhookId = createGuid();
    await dispatch(insertEntityWebhook(formValues, newWebhookId));
    flushSync(() => {
      reset(formValues, { keepValues: true, keepIsValid: true });
    });
    setEntityWebhookActionIsInProgress(false);
    return newWebhookId;
  };

  const unsavedNavigationHandler = async (
    onSuccess: () => void,
    onFail: () => void,
  ): Promise<void> => {
    const submit = handleSubmit(async (formValues): Promise<void> => {
      if (!isValid) onFail();
      try {
        await createWebhook(formValues);
        onSuccess();
      } catch {
        onFail();
      }
    }, onFail);

    await submit();
  };

  const submitForm = handleSubmit(
    async (formValues) => {
      const webhookId = await createWebhook(formValues);

      if (subscriptionId) {
        history.push(
          buildPath<SubscriptionEnvironmentWebhooksEditorRouteParams>(
            SubscriptionEnvironmentWebhooksEditingRoute,
            {
              projectId,
              subscriptionId,
              webhookId,
            },
          ),
        );
      } else {
        history.push(
          buildPath<WebhookEditorRouteParams>(WebhooksEditingRoute, {
            projectId,
            webhookId,
          }),
        );
      }
    },
    () => {
      const values = getValues();
      values.headers.forEach((header, index) => {
        if (header.value === '') {
          setValue(`headers.${index}.value`, '', { shouldTouch: true });
        }
      });
      if (values.url === '') {
        setValue('url', '', { shouldTouch: true });
      }
      setDisplayNotificationBar(true);
    },
  );

  return isInitThunkDone ? (
    <EntityWebhookEditorFormComponent
      customName={emptyEntityWebhook.name}
      enabled={emptyEntityWebhook.enabled}
      entityWebhookId={emptyEntityWebhook.id}
      entityWebhookActionIsInProgress={entityWebhookActionIsInProgress}
      formProps={formProps}
      hasUnsavedChanges={formState.isDirty}
      hasError={displayNotificationBar}
      onSetEntityWebhookActionIsInProgress={setEntityWebhookActionIsInProgress}
      onCloseNotificationBar={() => setDisplayNotificationBar(false)}
      onSubmit={submitForm}
      onUnsavedNavigation={unsavedNavigationHandler}
    />
  ) : (
    <Loader />
  );
};
