import { memoize } from '@kontent-ai/memoization';
import { notNull } from '@kontent-ai/utils';
import { ProjectOrderBy } from '../../../_shared/constants/projectOrderBy.ts';
import { IProjectDetails } from '../../../data/models/projects/ProjectDetails.ts';
import { compareEnvironments } from '../../projectSettings/environments/utils/environmentsUtils.ts';
import { createCompareProjectDetails } from './projectUtils.ts';

export const getProjectsForListing = memoize.weak(
  (
    projects: Immutable.Map<Uuid, IProjectDetails>,
    orderBy: ProjectOrderBy,
    subscriptionId: Uuid | null,
  ): ReadonlyArray<IProjectDetails> => {
    const result = projects
      .valueSeq()
      .filter(
        (p: IProjectDetails) => subscriptionId === null || p.subscriptionId === subscriptionId,
      )
      .groupBy((project: IProjectDetails) => project.masterEnvironmentId)
      .toArray()
      .map(
        (groupedProjects: Immutable.Map<number, IProjectDetails>) =>
          groupedProjects
            .valueSeq()
            .sort((a: IProjectDetails, b: IProjectDetails) => compareEnvironments(a, b))
            .first() ?? null,
      )
      .filter(notNull)
      .sort(createCompareProjectDetails(orderBy));

    return result;
  },
);
