import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../../../@types/Dispatcher.type.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import {
  ITypeElementSummaryCallbackProps,
  ITypeElementSummaryDataProps,
  TypeElementSummary as TypeElementSummaryComponent,
} from '../../../components/typeElements/shared/TypeElementSummary.tsx';
import {
  DefaultValueSetSummary,
  GeneratesUrlSlugSummary,
  LimitationsSetSummary,
} from '../../../constants/summaryMessageTemplates.ts';
import { ITextTypeElementData } from '../../../models/elements/TextTypeElementData.ts';
import { IBaseTypeElementSummaryProps } from '../../../types/IBaseTypeElementSummaryProps.type.ts';
import { ContentModelsTabName } from '../../../types/contentModelsTabName.ts';
import { hasTextDefaultValue } from '../../../utils/typeElementDefaultValueUtils.ts';
import {
  getBaseTypeElementSummarySegments,
  joinSummarySegments,
} from '../../../utils/typeElementMessagesUtils.ts';
import {
  textElementGeneratesUrlSlug,
  textElementGeneratesUrlSlugInAnyContentType,
} from '../../../utils/urlSlugUtils.ts';

interface ITextTypeElementSummaryProps extends IBaseTypeElementSummaryProps<ITextTypeElementData> {}

export function getTextTypeElementSummary(
  typeElementData: ITextTypeElementData,
  generatesUrlSlug: boolean,
): string {
  const summarySegments = [];
  const hasAnyLimitations =
    typeElementData.validationRegex?.isActive ||
    typeElementData.maxChars ||
    typeElementData.maxWords;

  if (generatesUrlSlug) {
    summarySegments.push(GeneratesUrlSlugSummary);
  }

  summarySegments.push(...getBaseTypeElementSummarySegments(typeElementData));

  if (hasAnyLimitations) {
    summarySegments.push(LimitationsSetSummary);
  }

  if (hasTextDefaultValue(typeElementData)) {
    summarySegments.push(DefaultValueSetSummary);
  }

  return joinSummarySegments(summarySegments);
}

function mapStateToProps(
  state: IStore,
  ownProps: ITextTypeElementSummaryProps,
): ITypeElementSummaryDataProps {
  const typeElement = ownProps.typeElementData;

  let generatesUrlSlug = false;
  if (state.contentModelsApp.selectedTab === ContentModelsTabName.Types) {
    const contentElements = state.contentModelsApp.typeEditor.editedType.typeElements;
    generatesUrlSlug = textElementGeneratesUrlSlug(contentElements, typeElement.elementId);
  } else {
    generatesUrlSlug = textElementGeneratesUrlSlugInAnyContentType(
      state.data.contentTypes.byId,
      typeElement.elementId,
    );
  }

  const summaryText = getTextTypeElementSummary(typeElement, generatesUrlSlug);

  return {
    isActive: ownProps.isActive,
    summaryText,
  };
}

function mapDispatchToProps(
  _dispatch: Dispatch,
  ownProps: ITextTypeElementSummaryProps,
): ITypeElementSummaryCallbackProps {
  return ownProps;
}

export const TextTypeElementSummary: React.ComponentType<ITextTypeElementSummaryProps> = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TypeElementSummaryComponent);
