import React from 'react';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { IAsset } from '../../../../data/models/assets/Asset.ts';
import { AssetEditor, EditingLanguages } from './AssetEditor.tsx';

type Props = {
  readonly editingLanguages: EditingLanguages;
  readonly onChange: (updatedAsset: IAsset) => void;
};

export const AssetEditorWithEditedAsset: React.FC<Props> = ({ editingLanguages, onChange }) => {
  const editedAsset = useSelector((s) => s.assetLibraryApp.assetUiEditor.editedAsset);

  if (!editedAsset) {
    return null;
  }

  return (
    <AssetEditor asset={editedAsset} editingLanguages={editingLanguages} onChange={onChange} />
  );
};

AssetEditorWithEditedAsset.displayName = 'AssetEditorWithEditedAsset';
