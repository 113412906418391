import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../../../@types/Dispatcher.type.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { ISnippets } from '../../../../../../data/reducers/snippets/ISnippets.type.ts';
import {
  ITypeElementSummaryCallbackProps,
  ITypeElementSummaryDataProps,
  TypeElementSummary as TypeElementSummaryComponent,
} from '../../../components/typeElements/shared/TypeElementSummary.tsx';
import {
  AutoGeneratedBasedOnElementSummary,
  LimitationsSetSummary,
} from '../../../constants/summaryMessageTemplates.ts';
import { ITextTypeElementData } from '../../../models/elements/TextTypeElementData.ts';
import { IUrlSlugTypeElementData } from '../../../models/elements/UrlSlugTypeElementData.ts';
import { IBaseTypeElementData } from '../../../models/elements/types/TypeElementData.ts';
import { getSelectedTextElement } from '../../../selectors/contentTypeElementSelector.ts';
import { IBaseTypeElementSummaryProps } from '../../../types/IBaseTypeElementSummaryProps.type.ts';
import {
  getBaseTypeElementSummarySegments,
  joinSummarySegments,
} from '../../../utils/typeElementMessagesUtils.ts';

interface IUrlSlugTypeElementSummaryProps
  extends IBaseTypeElementSummaryProps<IUrlSlugTypeElementData> {}

export function getUrlSlugTypeElementSummary(
  typeElementData: IUrlSlugTypeElementData,
  typeElements: ReadonlyArray<IBaseTypeElementData>,
  snippets: ISnippets,
  selectTextElement: (
    typeElements: ReadonlyArray<IBaseTypeElementData>,
    snippets: ISnippets,
    dependedOnTextElementId: Uuid | null,
  ) => ITextTypeElementData | undefined,
): string {
  const summarySegments: Array<string> = [...getBaseTypeElementSummarySegments(typeElementData)];

  const dependentTextTypeElementId = typeElementData.dependedOnTextElementId;
  if (dependentTextTypeElementId) {
    const dependentElement = selectTextElement(typeElements, snippets, dependentTextTypeElementId);
    const dependentElementName = dependentElement ? dependentElement.name : '';
    if (dependentElementName) {
      summarySegments.push(AutoGeneratedBasedOnElementSummary(dependentElementName));
    }
  } else if (typeElementData._canBeGeneratedFromDeprecatedItemName) {
    summarySegments.push('Auto-generated based on content item name (deprecated)');
  }

  if (typeElementData.validationRegex?.isActive) {
    summarySegments.push(LimitationsSetSummary);
  }

  return joinSummarySegments(summarySegments);
}

function mapStateToProps(
  state: IStore,
  ownProps: IUrlSlugTypeElementSummaryProps,
): ITypeElementSummaryDataProps {
  const typeElement = ownProps.typeElementData;
  const typeElements = state.contentModelsApp.typeEditor.editedType.typeElements;
  const summaryText = getUrlSlugTypeElementSummary(
    typeElement,
    typeElements,
    state.data.snippets,
    getSelectedTextElement,
  );

  return {
    isActive: ownProps.isActive,
    summaryText,
  };
}

function mapDispatchToProps(
  _dispatch: Dispatch,
  ownProps: IUrlSlugTypeElementSummaryProps,
): ITypeElementSummaryCallbackProps {
  return ownProps;
}

export const UrlSlugTypeElementSummary: React.ComponentType<IUrlSlugTypeElementSummaryProps> =
  connect(mapStateToProps, mapDispatchToProps)(TypeElementSummaryComponent);
