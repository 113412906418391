import {
  AnimatedProgressIcon,
  AnimatedProgressIconWrapper,
  IconWrapper,
  Icons,
} from '@kontent-ai/component-library/Icons';
import { Tooltip, defaultTooltipTippyOptions } from '@kontent-ai/component-library/Tooltip';
import {
  BaseColor,
  FontSize,
  IconSize,
  Spacing,
  Typography,
  ZIndex,
  colorAlertHover,
  colorAlertIcon,
  colorAlertText,
  colorBackgroundTextSelection,
  colorIconHint,
  colorSuccessIcon,
  colorSuccessText,
  colorTextDefault,
  colorTextLowEmphasis,
  px,
  spacingPopupDistance,
} from '@kontent-ai/component-library/tokens';
import { preventDefault } from '@kontent-ai/utils';
import getBasePlacement from '@popperjs/core/lib/utils/getBasePlacement';
import classNames from 'classnames';
import React, { useContext, useRef } from 'react';
import styled, { css } from 'styled-components';
import { createAddInlinePositioning } from '../../../../../../component-library/components/Dialogs/Popover/utils/tippyOptionsUtils.ts';
import {
  DropDownMenuPositioner,
  DropdownTippyOptions,
} from '../../../../../../component-library/components/DropDownMenu/DropDownMenuPositioner.tsx';
import { usePreventOverflowFromScrollContainer } from '../../../../../../component-library/components/ScrollContainer/usePreventOverflowFromScrollContainer.ts';
import { AiErrorCode } from '../../../../../_shared/features/AI/types/aiErrors.ts';
import { baseErrorMessageByErrorCode } from '../../../../../_shared/features/AI/utils/baseErrorMessageByErrorCode.ts';
import { IsSelected, useIsSelected } from '../../../../../_shared/hooks/useIsSelected.ts';
import { aiActionTimeout } from '../../../../../_shared/utils/aiTasks/aiActionTimeout.ts';
import { getDataAttribute } from '../../../../../_shared/utils/dataAttributes/DataAttributes.ts';
import { DataDraftJsAttributes } from '../../../../../_shared/utils/dataAttributes/DataDraftJsAttributes.ts';
import { isTimeInPast } from '../../../../../_shared/utils/dateTime/timeUtils.ts';
import {
  InlineAiInstructionFailed,
  InlineAiInstructionFailedDisabled,
  InlineAiInstructionFinished,
  InlineAiInstructionFinishedDisabled,
  InlineAiInstructionInProgress,
  InlineAiInstructionInProgressDisabled,
} from '../constants/uiConstants.ts';
import { FinishedAiInstructionData } from '../utils/InstructionEntity.ts';
import { ActiveMenuContext } from './ActiveMenuContext.tsx';
import { InstructionError } from './InstructionError.tsx';
import { InstructionMenu } from './InstructionMenu.tsx';

const getText = (isFinished: boolean, hasError: boolean): string => {
  if (hasError) {
    return InlineAiInstructionFailed;
  }

  return isFinished ? InlineAiInstructionFinished : InlineAiInstructionInProgress;
};

const getDisabledText = (isFinished: boolean, hasError: boolean): string => {
  if (hasError) {
    return InlineAiInstructionFailedDisabled;
  }

  return isFinished ? InlineAiInstructionFinishedDisabled : InlineAiInstructionInProgressDisabled;
};

const getIconColor = (isFinished: boolean, hasError: boolean): BaseColor => {
  if (hasError) {
    return colorAlertIcon;
  }

  return isFinished ? colorSuccessIcon : colorIconHint;
};

const getTextColor = (isFinished: boolean, hasError: boolean): BaseColor => {
  if (hasError) {
    return colorAlertText;
  }

  return isFinished ? colorSuccessText : colorTextLowEmphasis;
};

const getHoverTextColor = (isFinished: boolean, hasError: boolean): BaseColor => {
  if (hasError) {
    return colorAlertHover;
  }

  return isFinished ? colorSuccessText : colorTextDefault;
};

const FinishedInstructionContent = styled.span<{
  readonly isClickable: boolean;
  readonly hasError: boolean;
  readonly isFinished: boolean;
  readonly isSelected: boolean;
  readonly text: string;
}>`
  ${(props) => (props.isClickable ? css`cursor: pointer;` : '')}
  position: relative;
  ${(props) => (props.isSelected ? css`background-color: ${colorBackgroundTextSelection};` : '')};
  margin-left: 2px;
  
  // Avoid native highlight in Safari, as we use explicit highlight
  & *::selection {
    background-color: transparent !important;
  }

  & > ${IconWrapper},
  & > ${AnimatedProgressIconWrapper} {
    vertical-align: middle;
    margin-right: ${px(Spacing.XS)};
  }

  & > ${IconWrapper} {
    display: inline-block;
    font-size: ${px(FontSize.L)};
    line-height: 14px;

    svg {
      display: inline-block;
      vertical-align: middle;
    }
  }

  // Instruction text
  & span[${DataDraftJsAttributes.Text}]::before {
    ${Typography.LabelLarge}
    line-height: 1;
    color: ${(props) => getTextColor(props.isFinished, props.hasError)};
    content: "${(props) => props.text}";
    padding: 0 ${px(Spacing.XS)} 0 0;
    vertical-align: middle;
  }

  &:hover {
    > ${AnimatedProgressIconWrapper} *,
    > ${IconWrapper} *,
    span[${DataDraftJsAttributes.Text}]::before {
      color: ${(props) => getHoverTextColor(props.isFinished, props.hasError)};
    }
  }
}
`;

interface FinishedInstructionProps {
  readonly children: React.ReactNode;
  readonly className?: string;
  readonly data: FinishedAiInstructionData;
  readonly disabled: boolean;
  readonly editorId: Uuid;
  readonly offsetKey: string;
  readonly onAccept?: () => void;
  readonly onClick?: () => void;
  readonly onDiscard?: () => void;
  readonly onEditInstruction?: () => void;
  readonly onTryAgain?: () => void;
  readonly snapshotTime: Date | null;
}

const shouldDisplayTimeoutError = (data: FinishedAiInstructionData, now: Date): boolean =>
  !data.isFinished &&
  !data.error &&
  (!data.lastModifiedAt ||
    isTimeInPast(new Date(now.getTime() - aiActionTimeout), data.lastModifiedAt));

export const FinishedInstruction: React.FC<FinishedInstructionProps> = ({
  children,
  className,
  data,
  disabled,
  editorId,
  onAccept,
  onClick,
  onDiscard,
  onEditInstruction,
  onTryAgain,
  offsetKey,
  snapshotTime,
}) => {
  const nowAtMount = useRef(new Date()).current;

  const dataToDisplay = shouldDisplayTimeoutError(data, snapshotTime ?? nowAtMount)
    ? {
        aiSessionId: data.aiSessionId,
        instruction: data.instruction,
        error: baseErrorMessageByErrorCode[AiErrorCode.InactivityTimeout],
        isFinished: true,
      }
    : data;

  const { aiSessionId, error, instruction, isFinished } = dataToDisplay;

  const contentRef = useRef<HTMLSpanElement>(null);
  const isSelected = useIsSelected(contentRef);

  const { activeMenuId, setActiveMenuId } = useContext(ActiveMenuContext);

  const menuId = getInstructionMenuId(editorId, aiSessionId);
  const isMenuOpen = activeMenuId === menuId;

  const { preventOverflowModifier } = usePreventOverflowFromScrollContainer();

  const detailTippyOptions: DropdownTippyOptions = createAddInlinePositioning(contentRef)({
    placement: 'top-start',
    offset: [0, Spacing.XS + spacingPopupDistance],
    popperOptions: {
      modifiers: [preventOverflowModifier],
    },
    zIndex: ZIndex.SevenHundred,
  });

  const text = (disabled ? getDisabledText : getText)(!!isFinished, !!error);
  const tooltipTippyOptions = createAddInlinePositioning(contentRef)(defaultTooltipTippyOptions);

  return (
    <DropDownMenuPositioner
      // Prevent flicker upon remounting the entity with menu open (while streaming)
      allowAnimation={false}
      isDropDownVisible={isMenuOpen}
      renderDropDown={(_, __, { placement }) => (
        <div
          // Prevent editor focus lost when interacting with the details
          onMouseDown={preventDefault}
          onMouseUp={preventDefault}
        >
          {error ? (
            <InstructionError
              disabled={disabled}
              error={error}
              isActionFinished={!!isFinished}
              onDiscard={onDiscard}
              onEditInstruction={onEditInstruction}
              onTryAgain={onTryAgain}
              preferMenuOnTop={!!placement && getBasePlacement(placement) === 'top'}
            />
          ) : (
            !disabled && (
              <InstructionMenu
                isActionFailed={!!error}
                isActionFinished={!!isFinished}
                onAccept={isFinished && !error ? onAccept : undefined}
                onDiscard={onDiscard}
                onEditInstruction={onEditInstruction}
              />
            )
          )}
        </div>
      )}
      renderTrigger={(triggerProps) => (
        <Tooltip
          tippyOptions={tooltipTippyOptions}
          placement="top"
          text={isMenuOpen ? undefined : instruction}
        >
          <FinishedInstructionContent
            {...triggerProps}
            className={classNames('rte__ai-instruction', className)}
            contentEditable={false}
            hasError={!!error}
            isClickable={!disabled || !!error}
            isFinished={!!isFinished}
            isSelected={isSelected === IsSelected.Fully}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();

              setActiveMenuId(isMenuOpen ? null : menuId);
              onClick?.();
            }}
            text={text}
            aria-label={text}
            {...getDataAttribute(DataDraftJsAttributes.OffsetKey, offsetKey)}
          >
            <Icons.Sparkles color={getIconColor(!!isFinished, !!error)} size={IconSize.S} />
            {children}
            {error && <Icons.ExclamationTriangle color={colorAlertIcon} />}
            {!error && isFinished && <Icons.CbCheck color={colorSuccessIcon} />}
            {!error && !isFinished && !snapshotTime && (
              <AnimatedProgressIcon
                color={colorTextLowEmphasis}
                display="inline-block"
                fontSize={FontSize.L}
              />
            )}
          </FinishedInstructionContent>
        </Tooltip>
      )}
      tippyOptions={detailTippyOptions}
      triggerRef={contentRef}
    />
  );
};

FinishedInstruction.displayName = 'FinishedInstruction';

const instructionMenuPrefix = 'ai-instruction-';

export const getInstructionMenuPrefix = (editorId: Uuid): string =>
  `${instructionMenuPrefix}${editorId}`;

export const getInstructionMenuId = (editorId: Uuid, aiSessionId: Uuid): string =>
  `${getInstructionMenuPrefix(editorId)}${aiSessionId}`;

export const isInstructionMenuId = (menuId: string | null): boolean =>
  !!menuId?.startsWith(instructionMenuPrefix);

export const isInstructionMenuForEditor = (menuId: string | null, editorId: string): boolean =>
  !!menuId?.startsWith(getInstructionMenuPrefix(editorId));
