import React from 'react';
import { ValidatedInput } from '../../../../../_shared/components/input/ValidatedInput.tsx';
import { ValidationConstants } from '../../../../../_shared/constants/validationConstants.ts';
import { HookFormProps } from '../../../../../_shared/types/hookFormProps.type.ts';
import { DataUiInput } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ITaxonomyTermFormShape } from '../../models/ITaxonomyTermFormShape.type.ts';

type Props = {
  readonly formProps: HookFormProps<ITaxonomyTermFormShape>;
};

export const TermFormFields: React.FC<Props> = ({ formProps }) => (
  <div className="bar-item__expansion">
    <ValidatedInput<ITaxonomyTermFormShape>
      autoFocus
      name="name"
      label="Taxonomy term name"
      maxLength={ValidationConstants.TaxonomyTermNameMaxLength}
      dataUiInputName={DataUiInput.TaxonomyTermName}
      formProps={formProps}
    />
  </div>
);

TermFormFields.displayName = 'TermFormFields';
