import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import React from 'react';
import { QuickTip } from '../../../../../../_shared/components/infos/QuickTip.tsx';
import { documentationLinks } from '../../../../../../_shared/constants/documentationLinks.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { isEnhancedAssetManagementEnabled } from '../../../../../../_shared/selectors/enhancedAssetManagement.ts';
import { getCurrentProjectPlan } from '../../../../../../_shared/selectors/userProjectsInfoSelectors.ts';

const OnboardingMessage: React.FC = () => (
  <QuickTip subtitle="Keep all your assets neatly organized!">
    <p>
      You can now{' '}
      <OutwardLink href={documentationLinks.tagAssets}>categorize your assets</OutwardLink> by your
      regions, teams, descriptive tags, and much more. Reach out to your manager to set up asset
      taxonomies to get you started.
    </p>
  </QuickTip>
);
OnboardingMessage.displayName = 'OnboardingMessage';

export const AssetTaxonomyOnboarding: React.FC = () => {
  const areAssetTaxonomiesEnabled = useSelector((state) =>
    isEnhancedAssetManagementEnabled(getCurrentProjectPlan(state)),
  );
  if (areAssetTaxonomiesEnabled) {
    return <OnboardingMessage />;
  }

  return null;
};
AssetTaxonomyOnboarding.displayName = 'AssetTaxonomyOnboarding';
