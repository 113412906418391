import { notUndefined } from '@kontent-ai/utils';
import { DefaultWorkflowId } from '../../../../_shared/constants/variantIdValues.ts';
import {
  WorkflowWithApplicableScopes,
  WorkflowsWithApplicableScopesByLimitations,
  doesWorkflowHaveLimitedScope,
  doesWorkflowHaveUnlimitedScope,
} from '../../../../_shared/utils/workflow/workflowLimitations.ts';
import { CollectionsMap } from '../../../../data/models/collections/Collection.ts';
import { Workflow, WorkflowScope } from '../../../../data/models/workflow/Workflow.ts';
import { getWorkflowsSortedByName } from '../../../itemEditor/selectors/workflows/getWorkflowsSortedByName.ts';

const doesWorkflowHaveLimitedScopeForAllCollections = (
  workflow: WorkflowWithApplicableScopes,
): boolean =>
  workflow.applicableScopes.some(
    (scope) => scope.contentTypes.size > 0 && scope.collections.size === 0,
  );

const isUsableForType = (scope: WorkflowScope, contentTypeId: Uuid): boolean =>
  scope.contentTypes.has(contentTypeId) || scope.contentTypes.size === 0;

const toWorkflowWithApplicableScopesForType = (
  workflow: Workflow,
  contentTypeId: Uuid,
): WorkflowWithApplicableScopes => ({
  id: workflow.id,
  name: workflow.name,
  applicableScopes: workflow.scopes.filter((s) => isUsableForType(s, contentTypeId)),
});

export const splitContentTypeApplicableWorkflows = (
  workflows: ReadonlyMap<Uuid, Workflow>,
  contentTypeId: Uuid,
): WorkflowsWithApplicableScopesByLimitations => {
  const sortedWorkflows = getWorkflowsSortedByName(workflows);

  const applicableWorkflows = sortedWorkflows
    .map((workflow) => toWorkflowWithApplicableScopesForType(workflow, contentTypeId))
    .filter(
      (workflow) => workflow.applicableScopes.length > 0 || workflow.id === DefaultWorkflowId,
    );

  const limitedWorkflows = applicableWorkflows.filter(doesWorkflowHaveLimitedScope);
  const unlimitedWorkflows = limitedWorkflows.some(doesWorkflowHaveLimitedScopeForAllCollections)
    ? []
    : applicableWorkflows.filter(doesWorkflowHaveUnlimitedScope);

  return { limitedWorkflows, unlimitedWorkflows };
};

export const getCollectionNames = (
  workflow: WorkflowWithApplicableScopes,
  allCollections: CollectionsMap,
): ReadonlySet<string> | null => {
  if (doesWorkflowHaveLimitedScopeForAllCollections(workflow)) {
    // An empty set means that the workflow is applicable to all collections
    return new Set();
  }

  const collectionNames = new Set(
    workflow.applicableScopes.flatMap((scope) =>
      [...scope.collections]
        .map((collectionId) => allCollections.get(collectionId)?.name)
        .filter(notUndefined),
    ),
  );

  // If the set is empty, applicable collections have been deleted and workflow is not applicable to any collection
  return collectionNames.size > 0 ? collectionNames : null;
};
