import { areMultipleWorkflowsEnabledForCurrentProject } from '../../../applications/environmentSettings/utils/allowedFeaturesUtils.ts';
import { IPlan } from '../../../data/models/plans/Plan.ts';
import { Workflow } from '../../../data/models/workflow/Workflow.ts';
import { hasProjectMultipleWorkflows } from './hasProjectMultipleWorkflows.ts';

export const shouldApplyMultipleWorkflowsGovernance = (
  workflows: ReadonlyMap<Uuid, Workflow>,
  currentProjectPlan: IPlan,
): boolean =>
  areMultipleWorkflowsEnabledForCurrentProject(currentProjectPlan) ||
  hasProjectMultipleWorkflows(workflows);
