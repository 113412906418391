import { Callout } from '@kontent-ai/component-library/Callout';
import { forwardRef, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { modalDismissed } from '../../../../_shared/actions/sharedActions.ts';
import { IAnimatedModalDialogProps } from '../../../../_shared/components/ModalDialog/IAnimatedModalDialogProps.type.ts';
import {
  getDefaultExpiration,
  getMaximalExpiration,
  getMinimalExpiration,
} from '../../../../_shared/constants/apiKeyExpirations.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { ApiKeyType, OldDapiKeyTypes } from '../../../../_shared/models/ApiKeyType.ts';
import { DateTime } from '../../../../_shared/models/DateTime.ts';
import { getCurrentProjectContainerId } from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { getExpirationDateErrorMessage } from '../../../../_shared/utils/apiKeys/getApiKeyErrorMessage.ts';
import { isTimeInPast } from '../../../../_shared/utils/dateTime/timeUtils.ts';
import { regenerateApiKey, regenerateOldDapiKey } from '../actions/thunkApiKeysActions.ts';
import { ApiKeyModalWithExpirationDate } from '../components/ApiKeyModalWithExpirationDate.tsx';
import { ApiKey } from '../models/ApiKey.ts';

const ensureTokenType = (apiKey: ApiKey): ApiKeyType => {
  if (OldDapiKeyTypes.includes(apiKey.type)) {
    return ApiKeyType.DAPI;
  }

  return apiKey.type;
};

export const ApiKeyRegenerationDialog = forwardRef<HTMLDivElement, IAnimatedModalDialogProps>(
  ({ isOpen }, ref) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const projectContainerId = useSelector(getCurrentProjectContainerId);
    const apiKey = useSelector((state) => state.data.apiKeys.keyDetail);
    const isApiKeyExpired = isTimeInPast(new Date(), apiKey.expiresAt);

    const onClose = useCallback(() => dispatch(modalDismissed()), []);

    const confirmDialog = useCallback(
      (dateTime: DateTime): void => {
        if (OldDapiKeyTypes.includes(apiKey.type)) {
          dispatch(regenerateOldDapiKey(apiKey, dateTime, history, projectContainerId));
        } else {
          dispatch(regenerateApiKey(apiKey.tokenSeedId, dateTime));
        }

        onClose();
      },
      [onClose, apiKey, history, projectContainerId],
    );

    const getDefaultExpirationDate = useMemo(
      () => () => getDefaultExpiration(ensureTokenType(apiKey)),
      [apiKey],
    );
    const getMinExpirationDate = useMemo(
      () => () => getMinimalExpiration(ensureTokenType(apiKey)),
      [apiKey],
    );
    const getMaxExpirationDate = useMemo(
      () => () => getMaximalExpiration(ensureTokenType(apiKey)),
      [apiKey],
    );

    return (
      <ApiKeyModalWithExpirationDate
        getDefaultExpirationDate={getDefaultExpirationDate}
        getMaxExpirationDate={getMaxExpirationDate}
        getMinExpirationDate={getMinExpirationDate}
        getInvalidDateError={getExpirationDateErrorMessage}
        headline={`Generate new "${apiKey.name}"?`}
        infoChildElement={
          !isApiKeyExpired && (
            <Callout calloutType="warning">
              <p>
                The current API key becomes invalid immediately. You won’t be able to recover it.
              </p>
            </Callout>
          )
        }
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={confirmDialog}
        ref={ref}
      />
    );
  },
);

ApiKeyRegenerationDialog.displayName = 'ApiKeyRegenerationDialog';
