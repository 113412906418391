import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import React, { memo } from 'react';
import { ConnectDragSource } from 'react-dnd';
import { BarItemDragAction } from '../../../../../../../_shared/components/BarItems/Actions/BarItemDragAction.tsx';
import { NonExistentBarItemActions } from '../../../../../../../_shared/components/BarItems/Actions/NonExistentBarItemActions.tsx';
import { BarItemActions } from '../../../../../../../_shared/components/BarItems/BarItemActions.tsx';
import { LinkedItemNode } from '../../../../../../../_shared/components/linkedItems/LinkedItemNode.tsx';
import { LinkedItemOpenEditingAction } from '../../../../../../../_shared/components/linkedItems/LinkedItemOpenEditingAction.tsx';
import {
  DataUiAction,
  getDataUiActionAttribute,
  getDataUiObjectNameAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IContentType } from '../../../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { ElementType } from '../../../../../../contentInventory/content/models/ContentItemElementType.ts';
import { getLinkedItemIdAttribute } from '../../../../../../itemEditor/constants/elementAttributes.ts';
import { IDefaultValueLinkedItem } from '../../../../reducers/IContentModelsAppStoreState.type.ts';
import { LinkedItemContent } from './LinkedItemContent.tsx';

export interface ILinkedItemSharedProps {
  readonly connectDragSource?: ConnectDragSource;
  readonly displayDragButton: boolean;
  readonly elementType: ElementType;
  readonly isDisabled: boolean;
  readonly isDragging: boolean;
  readonly onDelete: (contentItemId: Uuid) => void;
}

type LinkedItemProps = ILinkedItemSharedProps & {
  readonly canCreate: boolean;
  readonly cannotViewDisabledMessage: string | undefined;
  readonly canViewDefaultLanguageItems: boolean;
  readonly contentItem: IDefaultValueLinkedItem | undefined;
  readonly contentItemId: Uuid;
  readonly contentItemPath: string | undefined;
  readonly contentType: IContentType | undefined;
};

export const LinkedItem: React.FC<LinkedItemProps> = memo(
  ({
    canCreate,
    cannotViewDisabledMessage,
    canViewDefaultLanguageItems,
    connectDragSource,
    contentItem,
    contentItemId,
    contentItemPath,
    contentType,
    displayDragButton,
    elementType,
    isDisabled,
    isDragging,
    onDelete,
  }) => {
    const onRemoveItem = () => onDelete(contentItemId);

    const itemName = contentItem?.itemName;
    const isTranslated = !!contentItem?.isTranslated;
    const isAvailable = !!contentItem && !contentItem.isItemArchived && !cannotViewDisabledMessage;

    const isRemoveButtonDisplayed = !isDisabled;
    const isDraggingAllowed = !isDisabled && displayDragButton;

    return (
      <LinkedItemNode
        dataAttributes={{
          ...getDataUiObjectNameAttribute(itemName || contentItemId),
          ...getLinkedItemIdAttribute(contentItemId),
        }}
        cannotViewDisabledMessage={cannotViewDisabledMessage}
        isDisabled={isDisabled}
        isDragging={isDragging}
        isEntryTypeAllowed
        isTranslated={isTranslated}
      >
        {isDraggingAllowed && (
          <BarItemActions>
            <BarItemDragAction connectDragSource={connectDragSource} />
          </BarItemActions>
        )}
        <LinkedItemContent
          cannotViewDisabledMessage={cannotViewDisabledMessage}
          collectionId={contentItem?.collectionId}
          elementType={elementType}
          isArchived={!!contentItem && contentItem.isItemArchived}
          isInvalid={false}
          itemDoesntExist={canViewDefaultLanguageItems && !contentItem}
          itemName={itemName ?? ''}
          typeDoesntExist={canViewDefaultLanguageItems && !contentType}
          typeName={contentType?.name ?? ''}
        />
        <BarItemActions>
          <NonExistentBarItemActions count={[!isAvailable, isDisabled].filter(Boolean).length} />
          <LinkedItemOpenEditingAction
            canCreate={canCreate}
            dataUiAction={DataUiAction.OpenInNewTab}
            icon={Icons.ArrowRightTopSquare}
            isAvailable={isAvailable}
            isTranslated={isTranslated}
            renderClickableWrapper={({ action }) => (
              <OutwardLink
                href={contentItemPath || ''}
                {...getDataUiActionAttribute(DataUiAction.OpenInNewTab)}
              >
                {action}
              </OutwardLink>
            )}
            tooltipText="Open in new tab"
          />
          {isRemoveButtonDisplayed && (
            <QuinaryButton
              buttonStyle="destructive"
              onClick={onRemoveItem}
              tooltipPlacement="bottom"
              tooltipText="Remove"
            >
              <QuinaryButton.Icon icon={Icons.Times} screenReaderText="Remove" />
            </QuinaryButton>
          )}
        </BarItemActions>
      </LinkedItemNode>
    );
  },
);

LinkedItem.displayName = 'LinkedItem';
