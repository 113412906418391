import { Box } from '@kontent-ai/component-library/Box';
import { Paper } from '@kontent-ai/component-library/Paper';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { useWrongPrevious } from '@kontent-ai/hooks';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactCropper, { ReactCropperElement } from 'react-cropper';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { useEventListener } from '../../../../_shared/hooks/useEventListener.ts';
import { compose } from '../../../../_shared/utils/func/compose.ts';
import { ICropRectangle } from '../../../../data/models/assetRenditions/AssetRendition.ts';
import { Dimensions, Position } from './types/ImageEditorTypes.type.ts';

type ImageEditorProps = {
  readonly rectangle: ICropRectangle;
  readonly imageSrc: string;
  readonly onChange: (newData: ICropRectangle) => void;
  readonly zoom: number;
  readonly minZoom: number;
  readonly maxZoom: number;
  readonly onZoomChange: (newZoom: number) => void;
  readonly className?: string;
};

export type CropperEventName = 'ready' | 'cropstart' | 'cropmove' | 'cropend' | 'crop' | 'zoom';
export type CropperEventMap = {
  ready: Cropper.ReadyEvent<EventTarget>;
  crop: Cropper.CropEvent;
  cropend: Cropper.CropEndEvent;
  cropmove: Cropper.CropMoveEvent;
  cropstart: Cropper.CropStartEvent;
  zoom: Cropper.ZoomEvent;
};

const canvasPadding = 10;

export const ImageEditor: React.FC<ImageEditorProps> = ({ onChange, onZoomChange, ...props }) => {
  const isMountedRef = useRef<boolean>(false);

  const [cropperInstance, setCropperInstance] = useState<Cropper>();
  const [isCropperReady, setIsCropperReady] = useState(false);
  const [editorBaseZoom, setEditorBaseZoom] = useState(1);
  const imageRef = useRef<ReactCropperElement | null>(null);

  const propagateChange = useCallback(() => {
    if (cropperInstance && isCropperReady) {
      onChange(cropperInstance.getData(true));
    }
  }, [onChange, cropperInstance, isCropperReady]);

  useEventListener('cropend', propagateChange, imageRef.current);

  const onZoom = useCallback(
    (e: Cropper.ZoomEvent): void => {
      const zoom = e.detail.ratio / editorBaseZoom;
      const boundedZoom = Math.max(props.minZoom, Math.min(props.maxZoom, zoom));
      onZoomChange(boundedZoom);
      if (boundedZoom !== zoom) {
        // the current zoom action would mean breaking some boundary
        e.preventDefault();
      }
    },
    [onZoomChange, props.maxZoom, props.minZoom, editorBaseZoom],
  );

  useEventListener('zoom', onZoom, imageRef.current);

  const prevIsCropperReady = useWrongPrevious(isCropperReady);
  useEffect(() => {
    if (!cropperInstance || !isCropperReady || prevIsCropperReady === isCropperReady) {
      return;
    }

    performCanvasMove(cropperInstance, moveCanvasToInitialPosition, props.rectangle);
    const canvas = cropperInstance.getCanvasData();
    const newEditorBaseZoom = canvas.width / canvas.naturalWidth;
    setEditorBaseZoom(newEditorBaseZoom);

    performCanvasMove(
      cropperInstance,
      compose(centerCanvas, changeCanvasToMatchZoom(props.zoom * newEditorBaseZoom)),
      props.rectangle,
    );
  }, [cropperInstance, isCropperReady, prevIsCropperReady, props.rectangle, props.zoom]);

  const prevData = useWrongPrevious(props.rectangle);
  useEffect(() => {
    if (
      !cropperInstance ||
      !isCropperReady ||
      areCropRectanglesEqual(props.rectangle, prevData) ||
      areCropRectanglesEqual(props.rectangle, cropperInstance.getData(true))
    ) {
      return;
    }

    const newDimensions: Dimensions = {
      width: props.rectangle.width,
      height: props.rectangle.height,
    };

    const newPosition: Position = calculatePositionOfCropBoxInImageCenter({
      image: cropperInstance.getImageData(),
      data: newDimensions,
    });

    performCanvasMove(cropperInstance, moveCanvasToInitialPosition, {
      ...newDimensions,
      ...newPosition,
    });

    propagateChange();
  }, [prevData, propagateChange, cropperInstance, isCropperReady, props.rectangle]);

  const prevZoom = useWrongPrevious(props.zoom);
  const prevEditorBaseZoom = useWrongPrevious(editorBaseZoom);
  useEffect(() => {
    if (
      !cropperInstance ||
      !isCropperReady ||
      (props.zoom === prevZoom && editorBaseZoom === prevEditorBaseZoom)
    ) {
      return;
    }
    const cropBox = cropperInstance.getCropBoxData();
    const zoomPivot = { x: cropBox.left + cropBox.width / 2, y: cropBox.top + cropBox.height / 2 };
    cropperInstance.zoomTo(props.zoom * editorBaseZoom, zoomPivot);
    propagateChange();
  }, [
    prevZoom,
    props.zoom,
    cropperInstance,
    isCropperReady,
    propagateChange,
    editorBaseZoom,
    prevEditorBaseZoom,
  ]);

  const onReady = () => {
    // prevent cropper from doing setState on an unmounted component in case of a closed modal dialog
    if (isMountedRef.current) {
      setIsCropperReady(true);
    }
  };

  return (
    <Box
      ref={(instance) => {
        isMountedRef.current = !!instance;
      }}
      css="position: relative"
      className={props.className}
    >
      {!isCropperReady && (
        <Box css="position: absolute; height: 100%; width: 100%">
          <Loader />
        </Box>
      )}
      {isCropperReady && <Paper css="position: absolute; height: 100%; width: 100%" />}
      <Box css="height: 100%; width: 100%" padding={Spacing.L}>
        <ReactCropper
          ref={imageRef}
          css="height: 100%; overflow: hidden"
          cropBoxMovable
          cropBoxResizable={false}
          data={props.rectangle}
          dragMode="move"
          enable
          onInitialized={setCropperInstance}
          ready={onReady}
          rotatable={false}
          scalable={false}
          src={props.imageSrc}
          toggleDragModeOnDblclick={false}
          viewMode={1}
        />
      </Box>
    </Box>
  );
};

ImageEditor.displayName = 'ImageEditor';

const calculateCanvasCenterPosition = ({
  canvasDimensions,
  containerDimensions,
}: Readonly<{ canvasDimensions: Dimensions; containerDimensions: Dimensions }>) => ({
  left: (containerDimensions.width - canvasDimensions.width) / 2,
  top: (containerDimensions.height - canvasDimensions.height) / 2,
});

/***
 * In the initial position the image is fully visible inside the container and the shorter padding (between the container and the image) is exactly canvasPadding.
 */
const calculateCanvasInitialPosition = ({
  canvas,
  container,
}: Readonly<{
  canvas: Cropper.CanvasData;
  container: Cropper.ContainerData;
}>): Partial<Cropper.CanvasData> => {
  const targetCanvasHeight = container.height - 2 * canvasPadding;
  const targetCanvasWidth = container.width - 2 * canvasPadding;

  const aspectRation = canvas.width / canvas.height;
  const canvasHeightAfterChangingWidth = targetCanvasWidth / aspectRation;
  const canvasWidthAfterChangingHeight = targetCanvasHeight * aspectRation;

  if (canvasWidthAfterChangingHeight <= targetCanvasWidth) {
    const canvasDimensions = { height: targetCanvasHeight, width: canvasWidthAfterChangingHeight };
    const newPosition = calculateCanvasCenterPosition({
      canvasDimensions,
      containerDimensions: container,
    });
    return { height: targetCanvasHeight, ...newPosition };
  }

  const canvasDimensions = { height: canvasHeightAfterChangingWidth, width: targetCanvasWidth };
  const newPosition = calculateCanvasCenterPosition({
    canvasDimensions,
    containerDimensions: container,
  });
  return { width: targetCanvasWidth, ...newPosition };
};

const calculatePositionOfCropBoxInImageCenter = ({
  data,
  image,
}: Readonly<{ data: Dimensions; image: Cropper.ImageData }>): Position => {
  const imageCenter = { x: image.naturalWidth / 2, y: image.naturalHeight / 2 };

  return {
    x: imageCenter.x - data.width / 2,
    y: imageCenter.y - data.height / 2,
  };
};

const areCropRectanglesEqual = (data1: ICropRectangle, data2: ICropRectangle): boolean =>
  data1.x === data2.x &&
  data1.y === data2.y &&
  data1.width === data2.width &&
  data1.height === data2.height;

const performCanvasMove = (
  cropperInstance: Cropper,
  move: (instance: Cropper) => void,
  finalData: Partial<Cropper.Data>,
): void => {
  cropperInstance.clear();
  move(cropperInstance);
  cropperInstance.crop();
  cropperInstance.setData(finalData);
};

const moveCanvasToInitialPosition = (cropperInstance: Cropper): Cropper =>
  cropperInstance.setCanvasData(
    calculateCanvasInitialPosition({
      canvas: cropperInstance.getCanvasData(),
      container: cropperInstance.getContainerData(),
    }),
  );

const changeCanvasToMatchZoom =
  (newZoom: number) =>
  (cropperInstance: Cropper): Cropper => {
    const canvas = cropperInstance.getCanvasData();
    return cropperInstance.setCanvasData({ width: newZoom * canvas.naturalWidth });
  };

const centerCanvas = (cropperInstance: Cropper): Cropper =>
  cropperInstance.setCanvasData(
    calculateCanvasCenterPosition({
      canvasDimensions: cropperInstance.getCanvasData(),
      containerDimensions: cropperInstance.getContainerData(),
    }),
  );
