import React, { useCallback } from 'react';
import { AssetTypeElementLimitation as AssetTypeElementLimitationComponent } from '../../../components/typeElements/individualTypeElements/asset/AssetTypeElementLimitation.tsx';
import { INumberLimit } from '../../../components/typeElements/shared/configuration/NumberLimit.tsx';
import { IAssetTypeElementData } from '../../../models/elements/AssetTypeElementData.ts';
import { AssetTypeElementValidationResult } from '../../../utils/typeElementValidators/types/AssetTypeElementValidationResult.type.ts';

type AssetTypeElementLimitationProps = {
  readonly typeElementData: IAssetTypeElementData;
  readonly validationResult: AssetTypeElementValidationResult;
  readonly onChange: (typeElementData: IAssetTypeElementData) => void;
};

export const AssetTypeElementLimitation: React.FC<AssetTypeElementLimitationProps> = ({
  onChange,
  typeElementData,
  validationResult,
}) => {
  const updateConfiguration = useCallback(
    (updatedElement: IAssetTypeElementData): void => {
      onChange(updatedElement);
    },
    [onChange],
  );

  const updateAssetWidth = (updatedLimit: INumberLimit): void => {
    updateConfiguration({
      ...typeElementData,
      minWidth: updatedLimit.minLimit,
      maxWidth: updatedLimit.maxLimit,
      _widthOption: updatedLimit._limitOption,
    });
  };

  const updateAssetHeight = (updatedLimit: INumberLimit): void => {
    updateConfiguration({
      ...typeElementData,
      minHeight: updatedLimit.minLimit,
      maxHeight: updatedLimit.maxLimit,
      _heightOption: updatedLimit._limitOption,
    });
  };

  const updateAssetCount = (updatedLimit: INumberLimit): void => {
    updateConfiguration({
      ...typeElementData,
      minItems: updatedLimit.minLimit,
      maxItems: updatedLimit.maxLimit,
      _quantityUnitOption: updatedLimit._limitOption,
    });
  };

  return (
    <AssetTypeElementLimitationComponent
      typeElementData={typeElementData}
      validationResult={validationResult}
      onUpdateAssetCount={updateAssetCount}
      onUpdateAssetHeight={updateAssetHeight}
      onUpdateConfiguration={updateConfiguration}
      onUpdateAssetWidth={updateAssetWidth}
    />
  );
};
