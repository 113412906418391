import { PropsWithChildren } from 'react';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { getCurrentProjectId } from '../../../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { shallowEqual } from '../../../../../../../_shared/utils/shallowEqual.ts';
import { getSpacesForPreviewUrls } from '../../../utils/getAvailableSpacesForPreviewUrls.ts';
import { PreviewSpaceContextProvider } from '../context/PreviewSpaceContext.tsx';

export const PreviewSpaceContextProviderContainer = ({ children }: PropsWithChildren) => {
  const environmentId = useSelector(getCurrentProjectId);
  const availableSpaces = useSelector((s) => getSpacesForPreviewUrls(s), shallowEqual);

  return (
    <PreviewSpaceContextProvider environmentId={environmentId} availableSpaces={availableSpaces}>
      {children}
    </PreviewSpaceContextProvider>
  );
};
