import React from 'react';
import { ValidatedInput } from '../../../../../_shared/components/input/ValidatedInput.tsx';
import { ValidationConstants } from '../../../../../_shared/constants/validationConstants.ts';
import { HookFormProps } from '../../../../../_shared/types/hookFormProps.type.ts';
import { DataUiInput } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ISitemapFormShape } from '../../models/ISitemapFormShape.type.ts';

type Props = {
  readonly formProps: HookFormProps<ISitemapFormShape>;
};

export const NodeFormFields: React.FC<Props> = ({ formProps }) => (
  <div className="bar-item__expansion">
    <ValidatedInput<ISitemapFormShape>
      autoFocus
      name="name"
      label="Sitemap item name"
      maxLength={ValidationConstants.SitemapNameMaxLength}
      dataUiInputName={DataUiInput.SitemapNodeName}
      formProps={formProps}
    />
  </div>
);

NodeFormFields.displayName = 'NodeFormFields';
