import { InvariantException } from '@kontent-ai/errors';
import { createGuid } from '@kontent-ai/utils';
import {
  Dispatch,
  GetState,
  ThunkPromise,
} from '../../../../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../../../../_shared/constants/trackedEvent.ts';
import { ScheduleUnpublishErrorMessage } from '../../../../../../../../_shared/features/ChangeWorkflowStepModal/constants/uiConstants.ts';
import { TrackUserEventAction } from '../../../../../../../../_shared/models/TrackUserEvent.type.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { logError } from '../../../../../../../../_shared/utils/logError.ts';
import { IContentItemRepository } from '../../../../../../../../repositories/interfaces/IContentItemRepository.type.ts';
import { getItemListingOperationQueryFromFilter } from '../../../../../utils/contentItemListModelConverters.ts';
import {
  ContentListing_ScheduledUnpublishItems_Failed,
  ContentListing_ScheduledUnpublishItems_Finished,
  ContentListing_ScheduledUnpublishItems_Started,
} from '../../../constants/contentItemInventoryActionTypes.ts';
import { createBulkActionResult } from '../../../utils/createBulkActionResult.ts';
import {
  createBulkActionFailed,
  createBulkActionFinished,
  createBulkActionStarted,
} from '../../contentItemInventoryActions.ts';
import { setTimeoutForMessageChange } from './setTimeoutForMessageChange.ts';

interface IScheduleUnpublishSelectedContentItemsActionDependencies {
  readonly contentItemRepository: IContentItemRepository;
  readonly trackUserEvent: TrackUserEventAction;
  readonly loadListingContentItems: () => ThunkPromise;
}

const actionStarted = createBulkActionStarted<
  typeof ContentListing_ScheduledUnpublishItems_Started
>(ContentListing_ScheduledUnpublishItems_Started);
const actionFinished = createBulkActionFinished<
  typeof ContentListing_ScheduledUnpublishItems_Finished
>(ContentListing_ScheduledUnpublishItems_Finished);
const actionFailed = createBulkActionFailed<typeof ContentListing_ScheduledUnpublishItems_Failed>(
  ContentListing_ScheduledUnpublishItems_Failed,
);

export type ScheduleUnpublishSelectedContentItemsActionsType = ReturnType<
  typeof actionStarted | typeof actionFinished | typeof actionFailed
>;

export const scheduleUnpublishSelectedContentItemsActionCreator =
  (deps: IScheduleUnpublishSelectedContentItemsActionDependencies) =>
  (): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const { scheduledToUnpublishAt, scheduledUnpublishDisplayTimeZone } =
      getState().contentApp.changeWorkflowStepModalData;

    if (!scheduledToUnpublishAt) {
      throw InvariantException('scheduledToUnpublishAt is falsy');
    }

    const selectedLanguageId = getSelectedLanguageIdOrThrow(getState());
    const query = getItemListingOperationQueryFromFilter(getState());

    const operationId = createGuid();
    dispatch(actionStarted({ operationId }));
    dispatch(setTimeoutForMessageChange(operationId));

    try {
      const operationResult = await deps.contentItemRepository.scheduleVariantsUnpublish(
        selectedLanguageId,
        {
          listingOperationQuery: query,
          scheduledToUnpublishAt,
          scheduledUnpublishDisplayTimeZone,
        },
      );

      dispatch(actionFinished(createBulkActionResult(operationResult, selectedLanguageId)));

      dispatch(
        deps.trackUserEvent(TrackedEvent.ContentEntryBulkScheduleUnpublished, {
          count: operationResult.modifiedItemIds.length,
        }),
      );
    } catch (error) {
      logError('Error while bulk scheduling unpublish', error);
      dispatch(actionFailed(ScheduleUnpublishErrorMessage));
    }

    dispatch(deps.loadListingContentItems());
  };
