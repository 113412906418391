import { useContext } from 'react';
import { useLocation } from 'react-router';
import {
  ContentItemsAppRootRoute,
  ContentItemsAppRootRouteParams,
  ContentItemsAppRouteSegment,
} from '../../../../../_shared/constants/routePaths.ts';
import { matchPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { LivePreviewConfigurationContext } from '../features/contentItemPreview/context/LivePreviewConfigurationContext.tsx';

export const useIsLivePreviewFeatureAvailable = (
  isUrlPatternSetForEditedContentItem: boolean,
): boolean => {
  const location = useLocation();
  const { isLivePreviewEnabled } = useContext(LivePreviewConfigurationContext);

  return (
    isLivePreviewEnabled &&
    isUrlPatternSetForEditedContentItem &&
    isContentItemEditorPath(location.pathname)
  );
};

const isContentItemEditorPath = (path: string): boolean =>
  matchPath<ContentItemsAppRootRouteParams>(path, ContentItemsAppRootRoute)?.app ===
  ContentItemsAppRouteSegment.Content;
