import React from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { MissionControlTrackedWidgetName } from '../../../../../_shared/models/TrackUserEventData.ts';
import { IUserIdentifier } from '../../../../../_shared/models/UserIdentifier.ts';
import { getCurrentProjectId } from '../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { getUsersInfo } from '../../../../../_shared/utils/users/usersUtils.ts';
import { useLivePreviewPreferenceStorage } from '../../../../../localStorages/useLivePreviewPreferenceStorage.ts';
import { useTrackWidgetDetailItemClick } from '../../shared/hooks/tracking.ts';
import { WidgetListingState } from '../../shared/types/WidgetListingState.type.ts';
import { getLanguageNameFromVariantId } from '../../shared/utils/getLanguageNameFromVariantId.ts';
import { ListingContentItemWithRequiredVariant } from '../../unchangedUnpublishedContent/types/UnchangedUnpublishedItem.type.ts';
import { getVariantLinkPath } from '../../unchangedUnpublishedContent/utils/getVariantLinkPath.ts';
import { UnchangedPublishedItemsWidgetDetail } from '../components/UnchangedPublishedItemsWidgetDetail.tsx';
import { UnchangedPublishedItemsWidgetDetailRow } from '../components/UnchangedPublishedItemsWidgetDetailRow.tsx';

export type UnchangedPublishedItemsWidgetDetailContainerProps = Readonly<{
  ariaLabelledBy: string;
  dataState: WidgetListingState;
  unchangedItems: ReadonlyArray<ListingContentItemWithRequiredVariant>;
  useRowLinks: boolean;
}>;

export const UnchangedPublishedItemsWidgetDetailContainer: React.FC<
  UnchangedPublishedItemsWidgetDetailContainerProps
> = ({ ariaLabelledBy, dataState, unchangedItems, useRowLinks }) => {
  const currentProjectId = useSelector(getCurrentProjectId);
  const { isLivePreviewPreferred } = useLivePreviewPreferenceStorage(currentProjectId);
  const collectionsById = useSelector((s) => s.data.collections.byId);
  const usersById = useSelector((s) => s.data.users.usersById);

  const defaultLanguage = useSelector((s) => s.data.languages.defaultLanguage);
  const languagesById = useSelector((s) => s.data.languages.byId);

  const getContributors = (assignees: ReadonlySet<IUserIdentifier>) => {
    const userIdentifiers = assignees ?? new Set<IUserIdentifier>();
    return Array.from(getUsersInfo(userIdentifiers, usersById));
  };

  const getVariantName = (variantId: Uuid): string =>
    getLanguageNameFromVariantId(variantId, defaultLanguage, languagesById);

  const getCollectionName = (collectionId: Uuid): string =>
    collectionsById.get(collectionId)?.name ?? 'Unknown collection';

  const trackWidgetDetailItemClick = useTrackWidgetDetailItemClick(
    MissionControlTrackedWidgetName.UnchangedPublishedItems,
  );

  return (
    <UnchangedPublishedItemsWidgetDetail ariaLabelledBy={ariaLabelledBy} state={dataState}>
      {unchangedItems.map((itemWithVariant) => (
        <UnchangedPublishedItemsWidgetDetailRow
          collectionName={getCollectionName(itemWithVariant.item.collectionId)}
          contributors={getContributors(itemWithVariant.variant.assignment.assignees)}
          itemName={itemWithVariant.item.name}
          key={`${itemWithVariant.variant.id.itemId}${itemWithVariant.variant.id.variantId}`}
          linkPath={
            useRowLinks ? getVariantLinkPath(itemWithVariant.variant, isLivePreviewPreferred) : null
          }
          onClick={useRowLinks ? trackWidgetDetailItemClick : undefined}
          variant={itemWithVariant.variant}
          variantName={getVariantName(itemWithVariant.variant.id.variantId)}
        />
      ))}
    </UnchangedPublishedItemsWidgetDetail>
  );
};

UnchangedPublishedItemsWidgetDetailContainer.displayName =
  'UnchangedPublishedItemsWidgetDetailContainer';
