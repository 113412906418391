import { assert } from '@kontent-ai/utils';
import { getPreselectedStepForRestoreFromArchivedStepInEditing } from '../../../../../_shared/features/ChangeWorkflowStepModal/selectors/getPreselectedWorkflowStep.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { IAssignment } from '../../../models/contentItem/Assignment.ts';

export const createAssignmentWithFirstWorkflowStepFromArchived = (
  state: IStore,
): Partial<IAssignment> => {
  const { editedContentItemVariant } = state.contentApp;

  assert(editedContentItemVariant, () => 'Edited content item variant is falsy.');

  const stepWithWorkflow = getPreselectedStepForRestoreFromArchivedStepInEditing(
    state,
    editedContentItemVariant.assignment.workflowStatus.workflowId,
  );

  assert(stepWithWorkflow, () => 'Workflow step was not found.');

  return {
    workflowStatus: {
      ...stepWithWorkflow.step,
      workflowId: stepWithWorkflow.workflow.id,
    },
  };
};
