import React, { useMemo } from 'react';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { getCurrentProject } from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { compose } from '../../../../_shared/utils/func/compose.ts';
import { getUserCapability } from '../../../../_shared/utils/permissions/capability.ts';
import {
  EmptyContentTypeUsage,
  IContentTypeUsage,
} from '../../../../data/models/contentModelsApp/contentTypes/ContentTypeUsage.ts';
import { TypeElementList } from '../../shared/components/TypeElementList.tsx';
import { ContentTypeKind } from '../../shared/constants/contentTypeKind.ts';
import { OriginalTypeContextProvider } from '../../shared/contexts/OriginalTypeContext.tsx';
import { memoizeLastElementIds } from '../../shared/selectors/typeEditorSelectors.ts';
import {
  hideTypeElementConfiguration,
  moveTypeElement,
  showTypeElementConfiguration,
  typeElementDropped,
  typeElementPickedUp,
} from '../actions/snippetsActions.ts';
import {
  addNewSnippetTypeElementAction,
  insertNewSnippetTypeElementBeforeAction,
  removeSnippetElement,
  updateTypeSnippetElement,
} from '../actions/thunkSnippetsActions.ts';

const getElementIds = (state: IStore): ReadonlyArray<Uuid> => {
  const { typeElements } = state.contentModelsApp.snippets.editor.editedContentTypeSnippet;

  return memoizeLastElementIds(typeElements.map((element) => element.elementId));
};

const getNumbersOfItemsUsedIn = (state: IStore): IContentTypeUsage => {
  const { editedContentTypeSnippet } = state.contentModelsApp.snippets.editor;
  const { contentTypeUsages, snippets } = state.data;
  const contentTypesUsingSnippet = snippets.snippetUsage.get(editedContentTypeSnippet.id) || [];

  return contentTypesUsingSnippet.reduce(
    (total: IContentTypeUsage, contentTypeId: Uuid): IContentTypeUsage => {
      const usage = contentTypeUsages.byId.get(contentTypeId) || EmptyContentTypeUsage;

      return {
        id: total.id,
        itemCount: total.itemCount + usage.itemCount,
        contentComponentCount: total.contentComponentCount + usage.contentComponentCount,
      };
    },
    EmptyContentTypeUsage,
  );
};

export const ContentTypeSnippetElementList: React.FC = () => {
  const capability = useSelector(compose(getUserCapability, getCurrentProject));
  const elementIds = useSelector(getElementIds);
  const firstIncompleteElementId = useSelector(
    (state) => state.contentModelsApp.snippets.editor.firstInvalidElementId,
  );
  const isDragging = useSelector(
    (state) => !!state.contentModelsApp.snippets.editor.draggedElementId,
  );
  const lastAddedElementId = useSelector(
    (state) => state.contentModelsApp.snippets.editor.lastAddedElementId,
  );
  const numbersOfItemsUsedIn = useSelector(getNumbersOfItemsUsedIn);
  const showConfigurationForElements = useSelector(
    (state) => state.contentModelsApp.snippets.editor.showConfigurationForElements,
  );
  const snippetCodename = useSelector(
    (state) => state.contentModelsApp.snippets.editor.editedContentTypeSnippet.codename,
  );
  const originalTypeElements =
    useSelector(
      (s) =>
        s.data.snippets.byId.get(s.contentModelsApp.snippets.editor.editedContentTypeSnippet.id)
          ?.typeElements,
    ) ?? [];

  const dispatch = useDispatch();
  // Callbacks are memoized to improve the performance while typing, otherwise all elements are re-rendered together with the whole list on each keystroke.
  const addContentElement = useMemo(() => compose(dispatch, addNewSnippetTypeElementAction), []);
  const insertContentElementBefore = useMemo(
    () => compose(dispatch, insertNewSnippetTypeElementBeforeAction),
    [],
  );
  const moveContentElement = useMemo(() => compose(dispatch, moveTypeElement), []);
  const onDragStart = useMemo(() => compose(dispatch, typeElementPickedUp), []);
  const onDragEnd = useMemo(() => compose(dispatch, typeElementDropped), []);
  const onHideConfig = useMemo(() => compose(dispatch, hideTypeElementConfiguration), []);
  const onShowConfig = useMemo(() => compose(dispatch, showTypeElementConfiguration), []);
  const removeContentElement = useMemo(() => compose(dispatch, removeSnippetElement), []);
  const updateContentElement = useMemo(() => compose(dispatch, updateTypeSnippetElement), []);

  return (
    <OriginalTypeContextProvider originalTypeElements={originalTypeElements}>
      <TypeElementList
        addContentElement={addContentElement}
        capability={capability}
        contentTypeKind={ContentTypeKind.ContentSnippet}
        elementIds={elementIds}
        firstIncompleteElementId={firstIncompleteElementId}
        insertContentElementBefore={insertContentElementBefore}
        isDragging={isDragging}
        lastAddedElementId={lastAddedElementId}
        moveContentElement={moveContentElement}
        numbersOfItemsUsedIn={numbersOfItemsUsedIn}
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
        onHideConfig={onHideConfig}
        onShowConfig={onShowConfig}
        removeContentElement={removeContentElement}
        showConfigurationForElements={showConfigurationForElements}
        snippetCodename={snippetCodename}
        updateContentElement={updateContentElement}
      />
    </OriginalTypeContextProvider>
  );
};
