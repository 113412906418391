import { px } from '@kontent-ai/component-library/tokens';
import { forwardRef, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { ModalDialog } from '../../../../../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import { IAnimatedModalDialogProps } from '../../../../../../../_shared/components/ModalDialog/IAnimatedModalDialogProps.type.ts';
import { ShortcutSymbols } from '../../../../../../../_shared/constants/shortcutSymbols.ts';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { IUserIdentifier } from '../../../../../../../_shared/models/UserIdentifier.ts';
import { getEditedContentItemVariant } from '../../../../../../../_shared/selectors/getEditedContentItemVariant.ts';
import { getSelectedLanguageId } from '../../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { getWorkflow } from '../../../../../../../_shared/selectors/workflowSelectors.ts';
import { IStore } from '../../../../../../../_shared/stores/IStore.type.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  getActiveAssignedContributors,
  getAllActiveContributors,
  getAvailableContributorsForEditing,
} from '../../../../../../../_shared/utils/permissions/getAvailableContributors.ts';
import { getUsersInfo } from '../../../../../../../_shared/utils/users/usersUtils.ts';
import { createFormValidationResolver } from '../../../../../../../_shared/utils/validation/createFormValidationResolver.ts';
import { IContentType } from '../../../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IProjectContributor } from '../../../../../../../data/models/users/ProjectContributor.ts';
import { MinDialogWidth } from '../../../../../../projectSettings/environments/constants/uiConstants.ts';
import { itemEditingModalDismissed } from '../../../../../actions/contentActions.ts';
import { getModalDialogActionOrigin } from '../../../../../selectors/getModalDialogActionOrigin.ts';
import { abandonAssignmentSectionChange } from '../../../actions/contentItemEditingActions.ts';
import { updateContributors } from '../../../actions/thunkContentItemEditingActions.ts';
import { ContributorsFormBase } from '../../../components/details/Contributors/ContributorsForm/ContributorsFormBase.tsx';
import { AssignmentSections } from '../../../constants/AssignmentSections.ts';
import { IContributorsFormShape } from '../../../models/IContributorsFormShape.type.ts';
import {
  isOtherAssignmentSectionSubmitting as isOtherAssignmentSectionSubmittingSelector,
  isSectionSubmitting as isSectionSubmittingSelector,
} from '../../../selectors/isSectionSubmitting.ts';
import { contributorsFormBaseValidationConfig } from '../../../validation/contributorsFormBaseValidation.ts';

const getAvailableContributors = (
  state: IStore,
  activeContributors: ReadonlyArray<IProjectContributor>,
): ReadonlyArray<IProjectContributor> => {
  const selectedLanguageId = getSelectedLanguageId(state);
  const { contentTypes, roles } = state.data;
  const { editedContentItem, editedContentItemVariant } = state.contentApp;
  const typeId = editedContentItem?.editedContentItemTypeId ?? '';
  const itemType: IContentType | undefined = contentTypes.byId.get(typeId);
  const workflowStatus = editedContentItemVariant?.assignment.workflowStatus;

  if (!selectedLanguageId || !itemType || !workflowStatus) {
    return [];
  }

  const currentWorkflow = getWorkflow(state, workflowStatus.workflowId);

  return getAvailableContributorsForEditing(
    workflowStatus,
    itemType,
    activeContributors,
    contentTypes.byId,
    roles.rolesById,
    currentWorkflow,
    selectedLanguageId,
    editedContentItem?.collectionId ?? null,
  );
};

export const ContributorsFormDialog = forwardRef<HTMLDivElement, IAnimatedModalDialogProps>(
  ({ isOpen }, ref) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const usersById = useSelector((s) => s.data.users.usersById);
    const actionOrigin = useSelector(getModalDialogActionOrigin);

    const initialContributorsIdentifiers = useSelector((s) => {
      const editedContentItemVariant = s.contentApp.editedContentItemVariant;

      return editedContentItemVariant
        ? editedContentItemVariant.assignment.assignees
        : new Set<IUserIdentifier>();
    });

    const formRef = useRef<HTMLFormElement>(null);
    const activeContributors = useSelector((s) => getAllActiveContributors(s.data.users.usersById));
    const availableContributors = useSelector((s) =>
      getAvailableContributors(s, activeContributors),
    );
    const currentUserId = useSelector((s) => s.data.user.info.userId);

    const isOtherAssignmentSectionSubmitting = useSelector((s) =>
      isOtherAssignmentSectionSubmittingSelector(s, AssignmentSections.Contributors),
    );
    const isSectionSubmitting = useSelector((s) =>
      isSectionSubmittingSelector(s, AssignmentSections.Contributors),
    );
    const workflowStepName = useSelector(
      (s) => getEditedContentItemVariant(s)?.assignment.workflowStatus.name ?? '',
    );

    const initialContributors = getUsersInfo(initialContributorsIdentifiers, usersById);

    const defaultValues: IContributorsFormShape = {
      contributors: getActiveAssignedContributors(initialContributors, activeContributors),
      showAll: false,
    };

    const formProps = useForm<IContributorsFormShape>({
      defaultValues,
      resolver: createFormValidationResolver(contributorsFormBaseValidationConfig, {
        availableContributors,
        workflowStepName,
      }),
    });

    if (!initialContributorsIdentifiers) {
      return null;
    }

    const { handleSubmit, watch } = formProps;

    const formValues = watch();
    const submitForm = handleSubmit((values) =>
      dispatch(updateContributors(history, values.contributors, actionOrigin)),
    );
    const resetForm = () => {
      dispatch(abandonAssignmentSectionChange(AssignmentSections.Contributors));
      dispatch(itemEditingModalDismissed());
    };

    return (
      <ModalDialog
        autoFocusRef={formRef}
        headline="Assign contributors"
        isDismissable
        isOpen={isOpen}
        minWidth={px(MinDialogWidth)}
        onClose={resetForm}
        ref={ref}
        shouldCloseOnInteractOutside={() => false}
        primaryAction={{
          disabled: isSectionSubmitting || isOtherAssignmentSectionSubmitting,
          onClick: submitForm,
          text: isSectionSubmitting ? 'Assigning...' : 'Assign',
          tooltipText: 'Assign',
          type: 'button',
          tooltipShortcuts: ShortcutSymbols.Enter,
        }}
        cancelAction={{
          disabled: isSectionSubmitting,
          tooltipShortcuts: ShortcutSymbols.Escape,
        }}
        {...getDataUiElementAttribute(DataUiElement.ContentItemAssignContributorsDialog)}
      >
        <ContributorsFormBase
          activeContributors={activeContributors}
          availableContributors={availableContributors}
          currentUserId={currentUserId}
          formProps={formProps}
          formRef={formRef}
          isSectionSubmitting={isSectionSubmitting}
          onReset={resetForm}
          onSubmit={submitForm}
          showAll={formValues.showAll}
          workflowStepName={workflowStepName}
        />
      </ModalDialog>
    );
  },
);

ContributorsFormDialog.displayName = 'ContributorsFormDialog';
