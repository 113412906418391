import { Box } from '@kontent-ai/component-library/Box';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { useObserveElementPresence } from '@kontent-ai/hooks';
import { useCallback, useState } from 'react';
import { createPortal } from 'react-dom';
import { trackUserEvent } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import {
  HotkeysHandler,
  HotkeysMode,
} from '../../../../../_shared/components/Hotkeys/HotkeysHandler.tsx';
import { HtmlPageTitle } from '../../../../../_shared/components/HtmlPageTitle.tsx';
import { SaveChangesButton } from '../../../../../_shared/components/SaveChangesButton.tsx';
import { StatusBarRightPaneId } from '../../../../../_shared/components/StatusBar/StatusBarContainer.tsx';
import { AppNames } from '../../../../../_shared/constants/applicationNames.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { NotificationBar } from '../../../../../_shared/containers/NotificationBar.tsx';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IAsset } from '../../../../../data/models/assets/Asset.ts';
import { DefaultSkinSidebar } from '../../../shared/components/DefaultSkinSidebar.tsx';
import { AssetActionMenu } from '../../containers/AssetEditing/AssetActionMenu.tsx';
import { AssetEditorUsage } from '../../containers/AssetEditing/AssetEditorUsage.tsx';
import { AssetReplaceDialog } from '../../containers/AssetEditing/AssetReplaceDialog.tsx';
import { EditingLanguages } from '../../containers/AssetEditor.tsx';
import { AssetEditorDetail } from '../../containers/AssetEditorDetail.tsx';
import { AssetEditorWithEditedAsset } from '../../containers/AssetEditorWithEditedAsset.tsx';
import { useAssetLibrarySelection } from '../../context/AssetLibrarySelectionContext.tsx';
import { AssetEditorToolbarActions } from '../appToolbar/AssetEditorToolbarActions.tsx';

type Props = {
  readonly assetTitle: string | null;
  readonly assetTitlePlaceholder: string | null;
  readonly disabledSaveButtonTooltip?: string;
  readonly hasUnsavedChanges: boolean;
  readonly isBeingSaved: boolean;
  readonly onChange: (asset: IAsset) => void;
  readonly onDeleteAsset: () => Promise<void>;
  readonly onSaveAsset: () => Promise<void>;
};

export const AssetEditingPage = ({
  assetTitle,
  assetTitlePlaceholder,
  disabledSaveButtonTooltip,
  isBeingSaved,
  onChange,
  onDeleteAsset,
  hasUnsavedChanges,
  onSaveAsset,
}: Props) => {
  const onControlSHandler = useCallback(
    (e: KeyboardEvent): void => {
      e.preventDefault();

      if (!isBeingSaved) {
        onSaveAsset();
      }
    },
    [onSaveAsset, isBeingSaved],
  );

  const dispatch = useDispatch();
  const [isAssetReplaceDialogOpen, setIsAssetReplaceDialogOpen] = useState(false);

  const onReplace = () => {
    setIsAssetReplaceDialogOpen(true);
    dispatch(trackUserEvent(TrackedEvent.AssetReplaceModalOpened));
  };

  const { current: statusBarRightPane } = useObserveElementPresence(StatusBarRightPaneId);

  const { updateAssetId } = useAssetLibrarySelection();

  return (
    <>
      {statusBarRightPane &&
        createPortal(
          <>
            <AssetEditorToolbarActions
              renderSaveButton={() => (
                <SaveChangesButton
                  buttonStyle="inverse"
                  disabled={!!disabledSaveButtonTooltip || !hasUnsavedChanges}
                  isBeingSaved={isBeingSaved}
                  onSave={onSaveAsset}
                  tooltipText={disabledSaveButtonTooltip}
                />
              )}
              renderActionMenu={() => (
                <AssetActionMenu onDelete={onDeleteAsset} onReplace={onReplace} />
              )}
            />
            {isAssetReplaceDialogOpen && (
              <AssetReplaceDialog
                onClose={() => setIsAssetReplaceDialogOpen(false)}
                updateAssetId={updateAssetId}
              />
            )}
          </>,
          statusBarRightPane,
        )}
      <Box paddingRight={Spacing.XL} paddingLeft={Spacing.L}>
        <NotificationBar />
      </Box>
      <div className="canvas" {...getDataUiAppNameAttribute(DataUiAppName.AssetEditing)}>
        <HtmlPageTitle
          appName={AppNames.Assets}
          customName={assetTitle || assetTitlePlaceholder || undefined}
        />
        <div className="canvas__workspace canvas__workspace--detached-scrollbars">
          <section className="canvas__content">
            <div className="canvas__content-pane asset-library-pane">
              <div className="asset-library-pane__editor-pane">
                <HotkeysHandler
                  mode={HotkeysMode.Dual}
                  handlers={{
                    onControlS: onControlSHandler,
                  }}
                >
                  <AssetEditorWithEditedAsset
                    onChange={onChange}
                    editingLanguages={EditingLanguages.AllLanguages}
                  />
                </HotkeysHandler>
              </div>
            </div>
          </section>
          <section className="canvas__sidebar asset-library-sidebar">
            <DefaultSkinSidebar sidebarContentClassNames="asset-library-sidebar__content">
              <AssetEditorDetail />
              <AssetEditorUsage isInDialog={false} />
            </DefaultSkinSidebar>
          </section>
        </div>
      </div>
    </>
  );
};
