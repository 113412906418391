import React, { useCallback, useMemo } from 'react';
import { HoveringCollisionStrategy } from '../../../../_shared/components/DragDrop/dragDrop.type.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { ICapability } from '../../../../_shared/utils/permissions/capability.ts';
import { IContentTypeUsage } from '../../../../data/models/contentModelsApp/contentTypes/ContentTypeUsage.ts';
import {
  ElementType,
  TypeElementType,
} from '../../../contentInventory/content/models/ContentItemElementType.ts';
import { ContentTypeKind } from '../constants/contentTypeKind.ts';
import { IBaseTypeElementData } from '../models/elements/types/TypeElementData.ts';
import { getInitialTypeElementValidationResult } from '../utils/getInitialTypeElementValidationResult.ts';
import { getTypeElementUiItem } from '../utils/getTypeElementUiItem.ts';

type Props = {
  readonly capability: ICapability;
  readonly contentTypeKind: ContentTypeKind;
  readonly elementId: Uuid;
  readonly firstIncompleteElementId: Uuid | null;
  readonly hoveringCollisionStrategy: HoveringCollisionStrategy;
  readonly insertContentElementBefore: (
    elementType: TypeElementType,
    insertBeforeElement: IBaseTypeElementData,
  ) => void;
  readonly isConfigDisplayed: boolean;
  readonly isFirstListElement: boolean;
  readonly isInGroup: boolean;
  readonly lastAddedElementId: Uuid | null;
  readonly lastMovedElementId?: Uuid | null;
  readonly moveContentElement: (sourceId: Uuid, targetId: Uuid) => void;
  readonly numbersOfItemsUsedIn?: IContentTypeUsage;
  readonly onDragEnd: () => void;
  readonly onDragStart: (typeElementId: Uuid) => void;
  readonly onHideConfig: (typeElementId: Uuid) => void;
  readonly onShowConfig: (typeElementId: Uuid) => void;
  readonly removeContentElement: (typeElementId: Uuid) => void;
  readonly snippetCodename: string | null | undefined;
  readonly updateContentElement: (updatedElement: IBaseTypeElementData) => void;
};

export const TypeElement: React.FC<Props> = ({
  capability,
  contentTypeKind,
  elementId,
  firstIncompleteElementId,
  hoveringCollisionStrategy,
  insertContentElementBefore,
  isConfigDisplayed,
  isFirstListElement,
  isInGroup,
  lastAddedElementId,
  lastMovedElementId,
  moveContentElement,
  numbersOfItemsUsedIn,
  onDragEnd,
  onDragStart,
  onHideConfig,
  onShowConfig,
  removeContentElement,
  snippetCodename,
  updateContentElement,
}) => {
  const isItemForSnippet = contentTypeKind === ContentTypeKind.ContentSnippet;
  const getElement = useMemo(
    () => getTypeElementUiItem({ isItemForSnippet, snippetCodename }),
    [isItemForSnippet, snippetCodename],
  );

  const typeElement = useSelector((s) => {
    const { typeElements } = isItemForSnippet
      ? s.contentModelsApp.snippets.editor.editedContentTypeSnippet
      : s.contentModelsApp.typeEditor.editedType;

    return typeElements.find((t) => elementId === t.elementId);
  });

  const validationResult = useSelector((s) => {
    const { validationResults } = isItemForSnippet
      ? s.contentModelsApp.snippets.editor
      : s.contentModelsApp.typeEditor;

    return (
      validationResults.get(elementId) ||
      (typeElement && getInitialTypeElementValidationResult(typeElement.type))
    );
  });

  const handleOnDragStart = useCallback(
    (): void => onDragStart(elementId),
    [elementId, onDragStart],
  );

  const handleOnHideConfig = useCallback(
    (): void => onHideConfig(elementId),
    [elementId, onHideConfig],
  );

  const handleOnRemove = useCallback(
    (): void => removeContentElement(elementId),
    [elementId, removeContentElement],
  );

  const handleOnShowConfig = useCallback(
    (): void => onShowConfig(elementId),
    [elementId, onShowConfig],
  );

  const handleOnAdd = useCallback(
    (type: ElementType): void => typeElement && insertContentElementBefore(type, typeElement),
    [insertContentElementBefore, typeElement],
  );

  if (!typeElement) {
    return null;
  }

  const { component: Component, typeName } = getElement(typeElement.type);

  return (
    <Component
      capability={capability}
      contentTypeKind={contentTypeKind}
      elementTypeName={typeName}
      firstIncompleteElementId={firstIncompleteElementId}
      hoveringCollisionStrategy={hoveringCollisionStrategy}
      isConfigDisplayed={isConfigDisplayed}
      isFirstListElement={isFirstListElement}
      isInGroup={isInGroup}
      lastAddedElementId={lastAddedElementId}
      lastMovedElementId={lastMovedElementId}
      numbersOfItemsUsedIn={numbersOfItemsUsedIn}
      onAdd={handleOnAdd}
      onChange={updateContentElement}
      onDragEnd={onDragEnd}
      onDragStart={handleOnDragStart}
      onHideConfig={handleOnHideConfig}
      onMove={moveContentElement}
      onRemove={handleOnRemove}
      onShowConfig={handleOnShowConfig}
      typeElementData={typeElement}
      validationResult={validationResult}
    />
  );
};

TypeElement.displayName = 'TypeElement';
