import { ReactNode, useEffect } from 'react';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { assetsRouteLeft } from '../actions/assetLibraryActions.ts';
import { initializeAssetLibrary } from '../actions/thunkAssetLibraryActions.ts';
import { AssetQueryOrigin } from '../utils/assetLibraryQueryUtils.ts';

type Props = {
  readonly origin: AssetQueryOrigin;
  readonly children: ReactNode;
};

export const EnsureAssetsState = ({ children, origin }: Props) => {
  const [isInitThunkDone] = useThunkPromise(initializeAssetLibrary, origin);
  const allSet = useSelector(
    (s) =>
      isInitThunkDone && s.assetLibraryApp.queryLoadingStatus.get(origin) === LoadingStatus.Loaded,
  );

  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(assetsRouteLeft(origin));
    };
  }, [origin]);

  return allSet ? children : <Loader />;
};
