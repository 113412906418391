import { Button } from '@kontent-ai/component-library/Button';
import { FC } from 'react';
import {
  NotificationBar,
  NotificationBarType,
} from '../../../../_shared/components/NotificationBar.tsx';

export interface ISitemapNotificationBarStateProps {
  readonly message: string | null;
  readonly showUndo: boolean;
  readonly type: NotificationBarType;
}

export interface ISitemapNotificationBarDispatchProps {
  readonly onButtonClick: () => void;
  readonly onClosePanel: () => void;
}

type ISitemapNotificationBarProps = ISitemapNotificationBarStateProps &
  ISitemapNotificationBarDispatchProps;

interface IUndoButtonProps {
  readonly onClick: () => void;
}

const UndoButton: FC<IUndoButtonProps> = ({ onClick }) => (
  <Button onClick={onClick} size="small" buttonStyle="primary-inverse">
    Undo
  </Button>
);

export const SitemapNotificationBar: FC<ISitemapNotificationBarProps> = ({
  message,
  onButtonClick,
  onClosePanel,
  showUndo,
  type,
}) => (
  <div className="canvas__notifications">
    <NotificationBar
      message={message}
      onClosePanel={onClosePanel}
      tools={showUndo && onButtonClick && <UndoButton onClick={onButtonClick} />}
      type={type}
    />
  </div>
);

SitemapNotificationBar.displayName = 'SitemapNotificationBar';
