import React from 'react';

export interface ITypeElementSummaryDataProps {
  readonly isActive: boolean;
  readonly summaryText: string;
}

export interface ITypeElementSummaryCallbackProps {
  readonly onShowConfig: () => void;
  readonly onHideConfig: () => void;
}

interface ITypeElementSummaryProps
  extends ITypeElementSummaryDataProps,
    ITypeElementSummaryCallbackProps {}

export const TypeElementSummary: React.FC<ITypeElementSummaryProps> = (props) => (
  <div
    className="content-element__summary"
    onClick={props.isActive ? props.onHideConfig : props.onShowConfig}
  >
    {props.summaryText}
  </div>
);

TypeElementSummary.displayName = 'TypeElementSummary';
