import { px } from '@kontent-ai/component-library/tokens';
import styled from 'styled-components';
import { assetTilePreviewHeight } from '../decisionTokens.ts';

export const StyledPlayIconWrapper = styled.div`
  height: ${px(assetTilePreviewHeight)};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
`;
