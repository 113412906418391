import { IEditedContentItemThreads } from '../../../../contentInventory/content/stores/IContentAppStoreState.ts';
import { ICommentThread } from '../../../models/comments/CommentThreads.ts';

interface IDeps {
  threadComparer: (threadA: ICommentThread, threadB: ICommentThread) => number;
}

export const createGetCommentsForContentItemVariant =
  ({ threadComparer }: IDeps) =>
  (
    commentThreads: ReadonlyArray<ICommentThread>,
    focusedCommentThreadId: Uuid | null,
  ): IEditedContentItemThreads => {
    const sortedThreads = [...commentThreads].sort(threadComparer);

    return {
      commentThreads: sortedThreads,
      focusedCommentThreadId,
      navigatedFrom: null,
      newCommentThreadForm: {},
    };
  };
