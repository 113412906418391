import React from 'react';
import { PreviewContentItemInfo } from '../../../../../utils/selectDeepestItemWithPreview.ts';
import { WebSpotlightPreviewInItemEditingContent } from '../containers/WebSpotlightPreviewInItemEditingContent.tsx';
import { ContentItemPreviewResizer } from './ContentItemPreviewResizer.tsx';

type ContentItemPreviewCanvasProps = Readonly<{
  contentItemId: Uuid;
  spaceId: Uuid | null;
}>;

export const ContentItemPreviewCanvas: React.FC<ContentItemPreviewCanvasProps> = ({
  contentItemId,
  spaceId,
}) => {
  const previewContentItemInfo: PreviewContentItemInfo = {
    contentItemId,
    isForLastRouteItem: true,
    isPageContent: false,
  };

  return (
    <ContentItemPreviewResizer>
      <WebSpotlightPreviewInItemEditingContent
        previewContentItemInfo={previewContentItemInfo}
        sharedPreviewUrl={null}
        spaceId={spaceId}
      />
    </ContentItemPreviewResizer>
  );
};
