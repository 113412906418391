import { Placement } from '@kontent-ai/component-library/types';
import { identity } from '@kontent-ai/utils';
import React, { useId } from 'react';
import { getDropDown } from '../../../../../../../_shared/components/DropDown.tsx';
import { TextField } from '../../../../../../../_shared/uiComponents/TextField/TextField.tsx';
import { DataUiElement } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  FileSizeOptions,
  FileSizeUnit,
  getFileSizeOptionName,
} from '../../../../../../contentInventory/content/models/fileSizeOptions.ts';
import { ITypeElementDataWithFileSizeLimit } from '../../../../models/elements/types/TypeElementData.ts';
import { TypeElementConfigurationSection } from '../TypeElementConfigurationSection.tsx';

const DropDown = getDropDown<FileSizeUnit>();

type Props = {
  readonly isValid: boolean;
  readonly onChange: (data: ITypeElementDataWithFileSizeLimit) => void;
  readonly readOnly?: boolean;
  readonly title?: string;
  readonly tooltipPlacement?: Placement;
  readonly tooltipText?: string;
  readonly typeElementData: ITypeElementDataWithFileSizeLimit;
  readonly uiElement?: DataUiElement;
};

export const FileSizeLimit: React.FC<Props> = ({
  isValid,
  onChange,
  readOnly,
  title,
  tooltipPlacement = 'top',
  tooltipText,
  typeElementData,
  uiElement,
}) => {
  const inputId = useId();

  const updateFileSizeLimit = (event: any): void => {
    onChange({
      ...typeElementData,
      fileSize: event.target.value,
    });
  };

  const updateFileSizeUnit = (newUnit: FileSizeUnit): void => {
    onChange({
      ...typeElementData,
      fileSize: typeElementData.fileSize,
      _fileSizeUnitOption: newUnit,
    });
  };

  return (
    <TypeElementConfigurationSection
      title={title || 'Limit file size'}
      uiElement={uiElement}
      labelFor={inputId}
    >
      <div className="content-element__config-number-limit">
        <div className="content-element__config-number-limit-item">
          <TextField
            hasError={!isValid}
            onChange={updateFileSizeLimit}
            placeholder="e.g., 2.50"
            value={typeElementData.fileSize || ''}
            inputId={inputId}
            readOnly={readOnly}
            tooltipText={tooltipText}
            tooltipPlacement={tooltipPlacement}
          />
        </div>
        <div className="content-element__config-number-limit-item">
          <DropDown
            className="combo-box"
            selectedOption={typeElementData._fileSizeUnitOption}
            options={FileSizeOptions}
            onSelect={updateFileSizeUnit}
            renderOptionName={getFileSizeOptionName}
            getOptionId={identity}
            disabled={readOnly}
            disabledTooltipMessage={tooltipText}
            disabledTooltipPlacement={tooltipPlacement}
          />
        </div>
      </div>
    </TypeElementConfigurationSection>
  );
};

FileSizeLimit.displayName = 'FileSizeLimit';
