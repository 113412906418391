import { assert } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { IListingContentItem } from '../../../data/models/listingContentItems/IListingContentItem.ts';

export function assertRootItem(
  rootItem: IListingContentItem | undefined,
  rootItemId: string,
  itemIdsToLoad: readonly Uuid[],
  responseListingContentItems: readonly IListingContentItem[] | null,
  contentItemsByIdsStore: Immutable.Map<Uuid, IListingContentItem>,
): asserts rootItem {
  assert(rootItem, () => {
    const responseContentItemIds = responseListingContentItems?.map((i) => i.item.id) ?? [];
    const responseContentItemIdsNotFoundInStore = responseContentItemIds.filter(
      (id) => !contentItemsByIdsStore.has(id),
    );

    const responseContentItemIdsSet = new Set(responseContentItemIds);
    const contentItemIdsNotFoundInResponse = itemIdsToLoad.filter(
      (id) => !responseContentItemIdsSet.has(id),
    );

    return `Root item not found.
Requested root item id: ${rootItemId},
Count of requested content items: ${itemIdsToLoad.length}
Count of returned content items: ${responseContentItemIds.length}
Requested content item ids not found in response: [${contentItemIdsNotFoundInResponse}],
Returned content item ids not found in store: [${responseContentItemIdsNotFoundInStore}]`;
  });
}
