import { ISelectItem } from '@kontent-ai/component-library/Selects';
import { SingleSelect } from '@kontent-ai/component-library/SingleSelect';
import React, { memo } from 'react';
import {
  DataUiCollection,
  DataUiElement,
  getDataUiCollectionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ITaxonomyGroup } from '../../../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import { ContentTypeKind } from '../../../../constants/contentTypeKind.ts';
import { limitationsCountPlaceholder } from '../../../../constants/uiConstants.ts';
import { ITaxonomyTypeElementData } from '../../../../models/elements/TaxonomyTypeElementData.ts';
import { ITypeElementOwnProps } from '../../../../types/ITypeElementProps.type.ts';
import { TaxonomyTypeElementValidationResult } from '../../../../utils/typeElementValidators/types/TaxonomyTypeElementValidationResult.type.ts';
import { TypeElementConfigurationCategory } from '../../shared/TypeElementConfigurationCategory.tsx';
import { TypeElementConfigurationSection } from '../../shared/TypeElementConfigurationSection.tsx';
import { TypeElementWithTypedName } from '../../shared/TypeElementWithTypedName.tsx';
import { INumberLimit, NumberLimit } from '../../shared/configuration/NumberLimit.tsx';
import { TaxonomyDefaultValueSelector } from './TaxonomyDefaultValueSelector.tsx';

export interface ITaxonomyOption extends ISelectItem<ITaxonomyOption> {
  readonly taxonomyGroup: ITaxonomyGroup;
}

type TaxonomyTypeElementProps = {
  readonly onDefaultValueChange: (defaultValue: UuidArray) => void;
  readonly onTaxonomyGroupChange: (
    nodeId: string | null,
    selectedTaxonomyOptionNode: ITaxonomyOption,
  ) => void;
  readonly onTermsLimitChange: (limit: INumberLimit) => void;
  readonly selectedTaxonomyGroup: ITaxonomyGroup | undefined;
  readonly taxonomyGroupsOptions: ReadonlyArray<ITaxonomyOption>;
  readonly typeElementData: ITaxonomyTypeElementData;
  readonly validationResult: TaxonomyTypeElementValidationResult;
} & ITypeElementOwnProps<ITaxonomyTypeElementData, TaxonomyTypeElementValidationResult>;

const TaxonomyTypeElement: React.FC<TaxonomyTypeElementProps> = (props) => {
  const {
    contentTypeKind,
    onDefaultValueChange,
    onTaxonomyGroupChange,
    onTermsLimitChange,
    selectedTaxonomyGroup,
    taxonomyGroupsOptions,
    typeElementData,
    validationResult,
  } = props;

  return (
    <TypeElementWithTypedName {...props}>
      <TypeElementConfigurationCategory>
        <TypeElementConfigurationSection contentWidth="small" title="Taxonomy group">
          <SingleSelect
            aria-label="Select a taxonomy group"
            placeholder="Select a taxonomy group"
            onSelectionChange={onTaxonomyGroupChange}
            items={taxonomyGroupsOptions}
            selectedItemId={typeElementData.taxonomyGroupId}
            verticalMenuDataAttributes={getDataUiCollectionAttribute(DataUiCollection.Taxonomies)}
            inputDataAttributes={getDataUiCollectionAttribute(DataUiCollection.Taxonomies)}
          />
        </TypeElementConfigurationSection>
      </TypeElementConfigurationCategory>

      {contentTypeKind !== ContentTypeKind.AssetType && (
        <TypeElementConfigurationCategory>
          <NumberLimit
            title="Limit number of terms"
            uiElement={DataUiElement.ItemsCountLimitation}
            onChange={onTermsLimitChange}
            limit={{
              minLimit: typeElementData.minItems,
              maxLimit: typeElementData.maxItems,
              _limitOption: typeElementData._quantityUnitOption,
            }}
            isValid={validationResult.isNumberOfTermsValid}
            placeholder={limitationsCountPlaceholder}
          />
        </TypeElementConfigurationCategory>
      )}

      <TypeElementConfigurationCategory>
        <TypeElementConfigurationSection contentWidth="small" title="Default value">
          <TaxonomyDefaultValueSelector
            isDefaultValueValid={validationResult.isDefaultValueValid}
            selectedTermIds={typeElementData.defaultValue}
            onChange={onDefaultValueChange}
            taxonomyGroup={selectedTaxonomyGroup}
          />
        </TypeElementConfigurationSection>
      </TypeElementConfigurationCategory>
    </TypeElementWithTypedName>
  );
};

TaxonomyTypeElement.displayName = 'TaxonomyTypeElement';

const TaxonomyTypeElementMemo = memo(TaxonomyTypeElement);
export { TaxonomyTypeElementMemo as TaxonomyTypeElement };
