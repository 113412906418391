import { isAbortError, isXMLHttpRequest } from '@kontent-ai/errors';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { DefaultKickstartProjectName } from '../../../../_shared/constants/defaultNames.ts';
import { getDefaultProjectLocationId } from '../../../../_shared/constants/projectLocationIds.ts';
import { ProjectOrderBy } from '../../../../_shared/constants/projectOrderBy.ts';
import { useAuthToken } from '../../../../_shared/contexts/AuthTokenProvider.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import { IProjectLocation } from '../../../../_shared/models/ProjectLocation.ts';
import {
  getSubscription,
  getSubscriptionAvailableProjectLocations,
  getSubscriptionDefaultProjectLocation,
} from '../../../../_shared/selectors/subscriptionSelectors.ts';
import {
  getActiveSubscriptions,
  getAdministratedSubscriptions,
  getSubscriptionsSortedByName,
} from '../../../../_shared/utils/subscription/subscriptionUtils.ts';
import { createFormValidationResolver } from '../../../../_shared/utils/validation/createFormValidationResolver.ts';
import { nonEmptyValidationBuilder } from '../../../../_shared/utils/validation/isEmptyOrWhitespace.ts';
import {
  cloneProject,
  createEmptyProject,
  createProjectFromTemplate,
  createSampleProject,
} from '../../actions/thunkProjectsActions.ts';
import { validateProjectCopy } from '../../actions/thunks/validateProjectCopy.ts';
import { NewProjectModal as NewProjectModalComponent } from '../../components/projects/NewProjectModal.tsx';
import { ProjectDetailsForm } from '../../components/projects/ProjectDetailsForm.tsx';
import { SelectedSubscriptionProjectLimitReachedTooltipMessage } from '../../constants/UIConstants.ts';
import { InitialProjectType } from '../../constants/initialProjectType.ts';
import { SampleProjectType } from '../../constants/sampleProjectType.ts';
import { INewProjectFormShape } from '../../models/INewProjectFormShape.type.ts';
import { getCloneProjectFormDefaultValues } from '../../selectors/getCloneProjectFormDefaultValues.ts';
import { canProjectBeCopied, getProjectDetail } from '../../utils/copyProjectUtils.ts';
import { createProjectTemplateList } from '../../utils/createProjectTemplateList.ts';
import { getProjectsForListing } from '../../utils/getProjectsForListing.ts';
import { InitialProjectSelector, SelectableProjectType } from './InitialProjectSelector.tsx';

const emptyProjectLocations: IProjectLocation[] = [];

type Props = {
  readonly sourceProjectId: Uuid | null;
  readonly onClose: () => void;
  readonly fixedSubscriptionId?: Uuid;
};

export const NewProjectModal = (props: Props) => {
  const [activeStep, setActiveStep] = useState<Step>(
    props.sourceProjectId
      ? { type: 'projectDetails', projectType: InitialProjectType.Copy }
      : { type: 'projectType', projectType: null },
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [progressMessage, setProgressMessage] = useState<string | null>(null);

  const activeSubscriptions = useSelector((state) => {
    const { administratedIds, byId } = state.data.subscriptions;
    const administratedSubscriptions = getAdministratedSubscriptions(administratedIds, byId);
    const sortedAdministratedSubscriptions = getSubscriptionsSortedByName(
      administratedSubscriptions,
    );
    return getActiveSubscriptions(sortedAdministratedSubscriptions);
  });

  const availableProjectTemplates = useSelector((state) => {
    const { projects, user } = state.data;

    return createProjectTemplateList(
      user.info,
      getProjectsForListing(projects.byId, ProjectOrderBy.ProjectName, null),
    );
  });

  const selectedSubscriptionId =
    props.fixedSubscriptionId ?? activeSubscriptions[0]?.subscriptionId ?? '';

  const defaultProjectLocationId = useSelector((state) => {
    const defaultProjectLocation = getSubscriptionDefaultProjectLocation(
      state,
      selectedSubscriptionId,
    );

    return defaultProjectLocation?.projectLocationId || getDefaultProjectLocationId();
  });

  const dispatch = useDispatch();

  const defaultValues = useSelector<INewProjectFormShape>((s) => {
    switch (activeStep.projectType) {
      case InitialProjectType.Copy:
        return getCloneProjectFormDefaultValues(
          s,
          props.sourceProjectId ?? '',
          activeSubscriptions,
        );
      case InitialProjectType.Kickstart:
      case InitialProjectType.MultiSite:
      case InitialProjectType.Blank:
      case null:
        return {
          subscriptionId: selectedSubscriptionId,
          projectLocationId: getDefaultProjectLocationId(),
          includeContent: false,
          projectName: '',
          projectTemplateId: '',
        };
    }
  });

  const formProps = useForm<INewProjectFormShape>({
    defaultValues,
    resolver: createFormValidationResolver<INewProjectFormShape>(
      {
        projectName: [nonEmptyValidationBuilder('project name')],
      },
      {},
    ),
  });

  const { handleSubmit, setValue, getFieldState, control } = formProps;

  const { getAuthToken } = useAuthToken();

  const createProject = async (
    newProject: INewProjectFormShape,
    projectType: InitialProjectType,
  ) => {
    switch (projectType) {
      case InitialProjectType.Blank: {
        setProgressMessage('Creating project...');
        if (newProject.projectTemplateId) {
          await dispatch(createProjectFromTemplate(newProject));
          break;
        }
        await dispatch(createEmptyProject(newProject));
        break;
      }
      case InitialProjectType.MultiSite: {
        setProgressMessage('Creating sample project...');
        await dispatch(
          createSampleProject(
            newProject.subscriptionId,
            SampleProjectType.HealthTech,
            newProject.projectName,
            await getAuthToken(),
          ),
        );
        break;
      }
      case InitialProjectType.Kickstart: {
        setProgressMessage('Creating sample project...');
        await dispatch(
          createSampleProject(
            newProject.subscriptionId,
            SampleProjectType.Kickstart,
            newProject.projectName,
            await getAuthToken(),
          ),
        );
        break;
      }
      case InitialProjectType.Copy: {
        setProgressMessage('Copying project...');
        await dispatch(cloneProject(newProject));
        break;
      }
    }
  };

  const submitForm = handleSubmit(async (newProject) => {
    if (activeStep.type === 'projectType') {
      return;
    }

    const validationData = await dispatch(
      validateProjectCopy(newProject.projectTemplateId, newProject.subscriptionId),
    );

    if (validationData) {
      setProgressMessage(null);
      return;
    }

    try {
      await createProject(newProject, activeStep.projectType);

      props.onClose();
    } catch (error) {
      setProgressMessage(null);

      if (!isAbortError(error) && isXMLHttpRequest(error)) {
        setErrorMessage('The project wasn’t cloned as we’re unable to reach the servers.');
        return;
      }
      setErrorMessage(
        'An error occurred while creating the project. Please refresh the page and try again.',
      );
    }
  });

  const destinationSubscriptionId = useWatch({ control, name: 'subscriptionId' });
  const selectedProjectLocationId = useWatch({ control, name: 'projectLocationId' });
  const selectedProjectTemplateId = useWatch({ control, name: 'projectTemplateId' });

  const selectedProjectLocationIdWithFallback = useSelector((state) => {
    const availableProjectLocations = destinationSubscriptionId
      ? getSubscriptionAvailableProjectLocations(state, destinationSubscriptionId)
      : emptyProjectLocations;

    const isSelectedLocationAvailable = availableProjectLocations.find(
      (loc) => loc.projectLocationId === selectedProjectLocationId,
    );
    if (isSelectedLocationAvailable) {
      return selectedProjectLocationId;
    }

    return (
      availableProjectLocations.find((loc) => loc.projectLocationId === defaultProjectLocationId)
        ?.projectLocationId ??
      availableProjectLocations[0]?.projectLocationId ??
      defaultProjectLocationId
    );
  });

  useEffect(() => {
    // Ensure default or any available datacenter when location selection disabled or when destination subscription was changed and selected datacenter is not available
    if (selectedProjectLocationId !== selectedProjectLocationIdWithFallback) {
      setValue('projectLocationId', selectedProjectLocationIdWithFallback);
    }
  }, [selectedProjectLocationIdWithFallback, selectedProjectLocationId, setValue]);

  const destinationSubscription = useSelector((state) =>
    getSubscription(state, destinationSubscriptionId),
  );

  const isProjectTemplateSelected = useSelector((state) => {
    const selectedProjectTemplate = selectedProjectTemplateId
      ? getProjectDetail(state.data.projects.byId, selectedProjectTemplateId)
      : null;

    return !!selectedProjectTemplate;
  });

  const [, copyValidationData] = useThunkPromise(
    validateProjectCopy,
    selectedProjectTemplateId,
    destinationSubscriptionId,
    { canRun: activeStep.type === 'projectDetails' },
  );

  const subscriptionProjectsLimit = copyValidationData?.targetPlanLimits.maxSubscriptionProjects;
  const subscriptionProjectsLimitReached =
    copyValidationData?.validationErrors.maxSubscriptionProjectsReached;

  const getSubmitButtonTooltipText = (): string | null => {
    switch (activeStep.type) {
      case 'projectType': {
        if (!activeStep.projectType) {
          return 'Please select a project type first';
        }
        return null;
      }
      case 'projectDetails': {
        if (subscriptionProjectsLimitReached) {
          return SelectedSubscriptionProjectLimitReachedTooltipMessage(subscriptionProjectsLimit);
        }
        if (copyValidationData && !canProjectBeCopied(copyValidationData)) {
          return 'Limitations exceeded';
        }
        return progressMessage;
      }
    }
  };

  return (
    <NewProjectModalComponent
      errorMessage={errorMessage}
      onClose={progressMessage ? undefined : props.onClose}
      onGoBack={
        activeStep.type === 'projectDetails' && activeStep.projectType !== InitialProjectType.Copy // The back button is not present when cloning a project
          ? () =>
              setActiveStep((prev) =>
                prev.projectType !== InitialProjectType.Copy
                  ? { ...prev, type: 'projectType', projectType: prev.projectType }
                  : prev,
              )
          : null
      }
      onPrimaryActionClick={() => {
        switch (activeStep.type) {
          case 'projectType': {
            if (!activeStep.projectType) {
              return;
            }
            if (
              activeStep.projectType === InitialProjectType.Kickstart &&
              !getFieldState('projectName').isDirty
            ) {
              setValue('projectName', DefaultKickstartProjectName);
            }
            setActiveStep({ type: 'projectDetails', projectType: activeStep.projectType });
            break;
          }
          case 'projectDetails':
            submitForm();
            break;
        }
      }}
      onNotificationBarDismiss={() => setErrorMessage(null)}
      progressMessage={progressMessage}
      step={activeStep}
      submitButtonTooltipText={getSubmitButtonTooltipText()}
    >
      {activeStep.type === 'projectType' && (
        <InitialProjectSelector
          selectedProjectType={activeStep.projectType}
          onChange={(projectType) => setActiveStep((prev) => ({ ...prev, projectType }))}
        />
      )}
      {activeStep.type === 'projectDetails' && (
        <ProjectDetailsForm
          activeSubscriptions={activeSubscriptions}
          availableProjectTemplates={availableProjectTemplates}
          destinationSubscription={destinationSubscription}
          canChangeSubscription={!props.fixedSubscriptionId}
          formProps={formProps}
          isProjectTemplateSelected={isProjectTemplateSelected}
          creatingProject={!!progressMessage}
          onSubmit={submitForm}
          step={activeStep}
          validationErrors={copyValidationData?.validationErrors ?? null}
        />
      )}
    </NewProjectModalComponent>
  );
};

export type Step =
  | { readonly type: 'projectType'; readonly projectType: SelectableProjectType | null }
  | { readonly type: 'projectDetails'; readonly projectType: InitialProjectType };
