import { Button } from '@kontent-ai/component-library/Button';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, px } from '@kontent-ai/component-library/tokens';
import { useAutoFocus } from '../../../../../_shared/hooks/useAutoFocus.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import {
  DataUiAction,
  DataUiElement,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getActiveLanguageIds } from '../../../../../_shared/utils/languageUtils.ts';
import { IRole } from '../../../../../data/models/roles/IRole.ts';
import { IProjectContributorRole } from '../../../../../data/models/users/ProjectContributor.ts';
import {
  assignRoleTooltipText,
  roleBuilderAnyLanguageOptionId,
} from '../../constants/roleBuilder.ts';
import { getAnyLanguageOptionName } from '../../utils/getAnyLanguageOptionName.ts';
import { getUsedLanguageIds } from '../../utils/getUsedLanguageIds.ts';
import { getUsedRoleIds } from '../../utils/getUsedRoleIds.ts';
import { RoleBuilderHeaders } from './RoleBuilderHeaders.tsx';
import { RoleBuilderRow } from './RoleBuilderRow.tsx';

export interface IRoleBuilderStateProps {
  readonly areLanguageRolesEnabled: boolean;
  readonly disabledAddTooltip: string | undefined;
  readonly disabledLanguageDropDownTooltip: string | undefined;
  readonly remainingRoles: ReadonlyArray<IRole>;
}

export interface IRoleBuilderOwnProps {
  readonly contributorId?: Uuid | undefined;
  readonly disabledTooltip: string | undefined;
  readonly isProjectManagerSelectionValid: boolean;
  readonly onChange: (updatedUserRoles: readonly IProjectContributorRole[]) => void;
  readonly userRoles: readonly IProjectContributorRole[];
}

type Props = IRoleBuilderStateProps & IRoleBuilderOwnProps;

export const RoleBuilder: React.FC<Props> = ({
  areLanguageRolesEnabled,
  contributorId,
  disabledAddTooltip,
  disabledLanguageDropDownTooltip,
  disabledTooltip,
  isProjectManagerSelectionValid,
  onChange,
  remainingRoles,
  userRoles,
}) => {
  const { focus: focusLastRow, isFocused: isLastRowFocused } = useAutoFocus();

  const usedRoleIds = getUsedRoleIds(userRoles);
  const selectedLanguageOptionIds = getUsedLanguageIds(userRoles);
  const activeLanguageIds = useSelector((s) => getActiveLanguageIds(s.data.languages));

  const addNewUserRole = (): void => {
    const nextRoleId = remainingRoles[0]?.id ?? null;
    if (!nextRoleId) {
      return;
    }

    const noRoleHasAnyOrNoLanguageOption = userRoles.every(
      (userRole: IProjectContributorRole) =>
        !!userRole.languageIds.length &&
        !userRole.languageIds.includes(roleBuilderAnyLanguageOptionId),
    );

    const newUserRole: IProjectContributorRole = {
      roleId: nextRoleId,
      languageIds: noRoleHasAnyOrNoLanguageOption ? [roleBuilderAnyLanguageOptionId] : [],
    };

    onChange([...userRoles, newUserRole]);
    focusLastRow();
  };

  const removeUserRole = (userRoleToRemove: IProjectContributorRole): void =>
    onChange(userRoles.filter((userRole) => userRole !== userRoleToRemove));

  const changeRole = (roleId: Uuid, index: number): void =>
    onChange(
      userRoles.map((role, i) =>
        i === index
          ? {
              ...role,
              roleId,
            }
          : role,
      ),
    );

  const changeLanguages = (languageIds: ReadonlyArray<Uuid>, index: number): void =>
    onChange(
      userRoles.map((role, i) =>
        i === index
          ? {
              ...role,
              languageIds,
            }
          : role,
      ),
    );

  const hasOnlySingleUserRole = userRoles.length === 1;

  return (
    <div {...getDataUiElementAttribute(DataUiElement.RoleBuilder)}>
      <RoleBuilderHeaders />
      <Stack spacing={Spacing.XL}>
        {userRoles.map((userRole: IProjectContributorRole, index: number) => {
          const anyLanguageOptionName = getAnyLanguageOptionName({
            activeLanguageIds: new Set(activeLanguageIds.toArray()),
            selectedLanguageOptionIds,
            hasOnlySingleUserRole,
            userRoleLanguageIds: new Set(userRole.languageIds),
          });
          const isRemoveDisabled = index === 0 && hasOnlySingleUserRole;

          return (
            <RoleBuilderRow
              anyLanguageOptionName={anyLanguageOptionName}
              autoFocus={isLastRowFocused && index === userRoles.length - 1}
              contributorId={contributorId}
              disabledLanguageDropDownTooltip={disabledLanguageDropDownTooltip}
              disabledTooltip={disabledTooltip}
              isProjectManagerSelectionValid={isProjectManagerSelectionValid}
              key={userRole.projectUserRoleId ?? index}
              onLanguagesChange={(languageIds) => changeLanguages(languageIds, index)}
              onRemove={isRemoveDisabled ? undefined : () => removeUserRole(userRole)}
              onRoleChange={(roleId) => changeRole(roleId, index)}
              selectedLanguageOptionIds={selectedLanguageOptionIds}
              usedRoleIds={usedRoleIds}
              userRole={userRole}
            />
          );
        })}
      </Stack>
      {areLanguageRolesEnabled && (
        <Button
          tooltipText={disabledTooltip || disabledAddTooltip || assignRoleTooltipText}
          tooltipPlacement="top-start"
          buttonStyle="secondary"
          disabled={!!disabledTooltip || !!disabledAddTooltip}
          onClick={addNewUserRole}
          css={`
              margin-top: ${px(Spacing.XL)};
            `}
          {...getDataUiActionAttribute(DataUiAction.AssignLanguageRole)}
        >
          Assign another role
        </Button>
      )}
    </div>
  );
};

RoleBuilder.displayName = 'RoleBuilder';
