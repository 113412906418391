import { Box } from '@kontent-ai/component-library/Box';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, Typography, colorTextDefault } from '@kontent-ai/component-library/tokens';
import React, {} from 'react';
import { HtmlSettingsPageTitle } from '../../../../_shared/components/HtmlSettingsPageTitle.tsx';
import { PageTitle } from '../../../../_shared/components/PageTitle.tsx';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { EnvironmentSettingsAppNames } from '../../root/constants/EnvironmentSettingsAppNames.ts';
import { InnovationLabApplicationName } from '../constants/uiConstants.ts';
import { InnovationLab } from '../containers/InnovationLab.tsx';
import { InnovationLabAppFilterContextProvider } from './InnovationLabAppFilterContext.tsx';
import { InnovationLabFilters } from './InnovationLabFilters.tsx';

const Header = () => (
  <Stack spacing={Spacing.L}>
    <PageTitle>{InnovationLabApplicationName}</PageTitle>
    <Box typography={Typography.SubheadlineLarge} color={colorTextDefault}>
      Explore and activate our newest features before they go live to the public. You’ll get
      exclusive access to innovative capabilities that are still evolving. Your feedback is
      invaluable in shaping their development and ensuring they meet your needs. Once you choose to
      activate a feature, it will be accessible to all users in your environment.
    </Box>
  </Stack>
);

export const InnovationLabApp: React.FC = () => (
  <div
    className="canvas__inner-section canvas__inner-section--restricted-width"
    {...getDataUiAppNameAttribute(DataUiAppName.InnovationLab)}
  >
    <HtmlSettingsPageTitle
      settingsAppName={EnvironmentSettingsAppNames.InnovationLab}
      customName="Innovation Lab"
    />
    <InnovationLabAppFilterContextProvider>
      <Stack spacing={Spacing.XL}>
        <Header />
        <InnovationLabFilters />
        <InnovationLab />
      </Stack>
    </InnovationLabAppFilterContextProvider>
  </div>
);
