import { Input, InputType } from '@kontent-ai/component-library/Input';
import { Stack } from '@kontent-ai/component-library/Stack';
import { useHotkeys } from '@kontent-ai/component-library/hooks';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React, { useState, useRef } from 'react';
import ReactFocusLock from 'react-focus-lock';
import { Option } from '../../../../../../_shared/components/Options/Option.tsx';
import { OptionMode } from '../../../../../../_shared/models/optionMode.ts';
import { PopoverMessage } from '../../../../../../_shared/uiComponents/Popover/Popover.tsx';
import {
  DataUiElement,
  DataUiInput,
  getDataUiElementAttribute,
  getDataUiInputAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { WebLinkProperties } from '../../../../../itemEditor/models/contentItemElements/richText/WebLinkProperties.type.ts';
import { LinkDialogActionsBar } from '../../components/dialog/LinkDialogActionsBar.tsx';

interface IWebLinkDialogProps {
  readonly linkText: string;
  readonly linkTitle?: string;
  readonly linkUrl?: string;
  readonly onCancel: () => void;
  readonly onUnlink?: () => void;
  readonly onUpsert: (values: WebLinkProperties) => void;
  readonly openInNewWindow?: boolean;
}

export const WebLinkDialog: React.FC<IWebLinkDialogProps> = (props) => {
  const [linkText, setLinkText] = useState(props.linkText);
  const [linkUrl, setLinkUrl] = useState(props.linkUrl || '');
  const [linkTitle, setLinkTitle] = useState(props.linkTitle || '');
  const [openInNewWindow, setOpenInNewWindow] = useState(!!props.openInNewWindow);

  const isSubmittingDisabled = !linkText || !linkUrl;

  const onSubmit = (e: React.FormEvent<HTMLFormElement> | KeyboardEvent): void => {
    e.preventDefault();
    if (!isSubmittingDisabled) {
      props.onUpsert({
        linkText,
        linkUrl,
        linkTitle,
        openInNewWindow,
      });
    }
  };

  const submitButtonText = props.onUnlink ? 'Update' : 'Insert';
  const disabledSubmitButtonTooltipText = `Link text and URL must be filled before ${
    props.onUnlink ? 'update' : 'insertion'
  }`;

  const ref = useRef<HTMLDivElement>(null);
  useHotkeys(
    {
      escape: props.onCancel,
    },
    { ref },
  );

  return (
    <ReactFocusLock>
      <form onSubmit={onSubmit} {...getDataUiElementAttribute(DataUiElement.RteLinkDialog)}>
        <PopoverMessage ref={ref}>
          <Stack spacing={Spacing.XL}>
            <Input
              label="Link text"
              autoComplete="off"
              type={InputType.Text}
              name="text-input"
              value={linkText}
              onChange={(e) => setLinkText(e.target.value)}
              {...getDataUiInputAttribute(DataUiInput.Text)}
            />
            <Input
              autoFocus
              label="Link URL"
              autoComplete="on"
              type={InputType.Text}
              name="url-input"
              value={linkUrl}
              onChange={(e) => setLinkUrl(e.target.value)}
              {...getDataUiInputAttribute(DataUiInput.Url)}
            />
            <Input
              label="Link title"
              autoComplete="off"
              type={InputType.Text}
              name="title-input"
              value={linkTitle}
              onChange={(e) => setLinkTitle(e.target.value)}
              {...getDataUiInputAttribute(DataUiInput.Title)}
            />
          </Stack>
          <Option
            onOptionSelected={() => setOpenInNewWindow(!openInNewWindow)}
            label="Open link in a new window"
            isSelected={openInNewWindow}
            mode={OptionMode.Multiple}
            className="link-dialog__checkbox"
            {...getDataUiInputAttribute(DataUiInput.OpenInNewWindow)}
          />
        </PopoverMessage>
        <LinkDialogActionsBar
          disabledSubmitButtonTooltipText={disabledSubmitButtonTooltipText}
          isSubmittingDisabled={isSubmittingDisabled}
          onCancel={props.onCancel}
          onUnlink={props.onUnlink}
          submitButtonText={submitButtonText}
        />
      </form>
    </ReactFocusLock>
  );
};

WebLinkDialog.displayName = 'WebLinkDialog';
