import useLocalStorageState from 'use-local-storage-state';
import { LivePreviewPreferenceStorageKey } from '../_shared/constants/localStorageKeys.ts';

type LivePreviewPreference = Readonly<{
  isLivePreviewPreferred: boolean;
  setIsLivePreviewPreferred: (value: boolean) => void;
}>;

const getStorageKey = (environmentId: Uuid): string => {
  return `${LivePreviewPreferenceStorageKey}_${environmentId}`;
};

export const useLivePreviewPreferenceStorage = (environmentId: Uuid): LivePreviewPreference => {
  const storageKey = getStorageKey(environmentId);

  const [isLivePreviewPreferred, setIsLivePreviewPreferred] = useLocalStorageState<boolean>(
    storageKey,
    {
      defaultValue: false,
    },
  );

  return {
    isLivePreviewPreferred,
    setIsLivePreviewPreferred,
  };
};
