import { ActiveCapabilityType } from '../../../../../../../_shared/models/activeCapability.type.ts';
import { getEditedContentItemVariant } from '../../../../../../../_shared/selectors/getEditedContentItemVariant.ts';
import { IStore } from '../../../../../../../_shared/stores/IStore.type.ts';
import {
  isArchivedWorkflowStepSelected,
  isPublishingStepSelected,
} from '../../../../../../../_shared/utils/contentItemUtils.ts';
import { hasActiveVariantCapabilityForEditedItem } from '../../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { VariantTranslationCondition } from '../../../constants/VariantTranslationCondition.ts';
import { getFailedTranslationCondition } from '../../../selectors/getFailedTranslationCondition.ts';

enum TranslationActionState {
  Enabled = 0,
  Disabled = 1,
  Hidden = 2,
}

export const isVariantTranslationAvailable = (
  state: IStore,
  isTranslationsFeatureEnabled: boolean,
) =>
  getVariantTranslationActionState(state, isTranslationsFeatureEnabled) ===
  TranslationActionState.Enabled;

export const isVariantTranslationUnavailable = (
  state: IStore,
  isTranslationsFeatureEnabled: boolean,
) =>
  getVariantTranslationActionState(state, isTranslationsFeatureEnabled) ===
  TranslationActionState.Disabled;

const getVariantTranslationActionState = (
  state: IStore,
  isTranslationsFeatureEnabled: boolean,
): TranslationActionState => {
  if (shouldNotDisplayEitherOfAiTranslateActions(state, isTranslationsFeatureEnabled)) {
    return TranslationActionState.Hidden;
  }

  if (getFailedTranslationCondition(state) === VariantTranslationCondition.None) {
    return TranslationActionState.Enabled;
  }

  return TranslationActionState.Disabled;
};

const shouldNotDisplayEitherOfAiTranslateActions = (
  state: IStore,
  isTranslationsFeatureEnabled: boolean,
) => {
  const variant = getEditedContentItemVariant(state);
  return (
    !!variant &&
    (isPublishingStepSelected(variant.assignment) ||
      isArchivedWorkflowStepSelected(variant.assignment) ||
      !isTranslationsFeatureEnabled ||
      !hasActiveVariantCapabilityForEditedItem(ActiveCapabilityType.UpdateContent, state))
  );
};
