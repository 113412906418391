import { Box } from '@kontent-ai/component-library/Box';
import { gridUnit } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { ModalDialog } from '../../../../../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IBaseTypeElementData } from '../../../../models/elements/types/TypeElementData.ts';
import { withDefaultNameIfNotSet } from '../../../../utils/typeElementsUtils.ts';

interface IMarkElementAsLocalizableProps {
  readonly isModalOpen: boolean;
  readonly onClickPrimaryAction: () => void;
  readonly onClose: () => void;
  readonly typeElementData: IBaseTypeElementData;
}

export const MarkElementAsLocalizableModal: React.FC<IMarkElementAsLocalizableProps> = ({
  isModalOpen,
  onClickPrimaryAction,
  onClose,
  typeElementData,
}) => (
  <ModalDialog
    headline="Mark as localizable"
    isOpen={isModalOpen}
    isDismissable
    onClose={onClose}
    shouldCloseOnBlur
    primaryAction={{
      onClick: onClickPrimaryAction,
      text: 'Mark as localizable',
    }}
    {...getDataUiElementAttribute(DataUiElement.MarkElementAsLocalizableDialog)}
  >
    <Box maxWidth={70 * gridUnit}>
      When you mark the element &quot;{withDefaultNameIfNotSet(typeElementData).name}&quot; as
      localizable and save the content type, you won’t be able to change the element back to
      non-localizable in the future.
    </Box>
  </ModalDialog>
);

MarkElementAsLocalizableModal.displayName = 'MarkElementAsLocalizableModal';
