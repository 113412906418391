import { Collection } from '@kontent-ai/utils';
import React, { useCallback } from 'react';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  TableBlockCategoryFeature,
  TopLevelBlockCategoryFeature,
} from '../../../../../../richText/plugins/apiLimitations/api/editorLimitationUtils.ts';
import { ImageButton } from '../../../../../../richText/plugins/images/components/ImageButton.tsx';
import { IRichTextTypeElementData } from '../../../../models/elements/RichTextTypeElementData.ts';

export interface ITableBlocksProps {
  readonly disabled: boolean;
  readonly onChange: (data: IRichTextTypeElementData) => void;
  readonly typeElementData: IRichTextTypeElementData;
  readonly tooltipText?: string;
}

export const TableBlocks: React.FC<ITableBlocksProps> = (props) => {
  const { disabled, typeElementData, onChange, tooltipText } = props;
  const { allowedTableBlocks } = typeElementData;

  const _updateAllowedTableBlocks = useCallback(
    (tableBlockType: TableBlockCategoryFeature, isAllowed: boolean): void => {
      const updatedAllowedTableBlocks: ReadonlySet<TableBlockCategoryFeature> = isAllowed
        ? Collection.add(typeElementData.allowedTableBlocks, tableBlockType)
        : Collection.remove(typeElementData.allowedTableBlocks, tableBlockType);

      onChange({
        ...typeElementData,
        allowedTableBlocks: updatedAllowedTableBlocks,
      });
    },
    [onChange, typeElementData],
  );

  const imagesAllowed = allowedTableBlocks.has(TopLevelBlockCategoryFeature.Images);

  return (
    <div className="content-element__config-section col-xs-24">
      <div className="content-element__formatting-header">
        <div className="content-element__formatting-header-title">Insert objects</div>
      </div>
      <div className="content-element__formatting-row">
        <ImageButton
          disabled={disabled}
          isActive={imagesAllowed}
          isFocusable
          hasHiddenShortcut
          onClick={() => {
            _updateAllowedTableBlocks(TopLevelBlockCategoryFeature.Images, !imagesAllowed);
          }}
          tooltipPlacement="right"
          tooltipText={
            disabled
              ? tooltipText
              : imagesAllowed
                ? 'Disallow images in tables'
                : 'Allow images in tables'
          }
          {...getDataUiCollectionAttribute(DataUiCollection.LimitationFormatting)}
        />
      </div>
    </div>
  );
};

TableBlocks.displayName = 'TableBlocks';
