import { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router';
import { upsertUserProperty } from '../../../../_shared/actions/thunkSharedActions.ts';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { EditContentTypeRouteParams } from '../../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { LongProcessingChangesWarningDismissedServerKey } from '../../../../_shared/models/UserPropertiesServerKeys.ts';
import { compose } from '../../../../_shared/utils/func/compose.ts';
import { IContentType } from '../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { editorLeft } from '../../shared/actions/sharedContentModelsActions.ts';
import {
  focusFirstInvalidElement,
  validateTypeElement,
} from '../../shared/actions/thunkSharedContentModelsActions.ts';
import { getElementsWithAffectedLocalization } from '../../shared/utils/getElementsWithAffectedLocalization.ts';
import { isTypeValid } from '../../shared/utils/typeValidationUtils.ts';
import { contentTypeInvalidElementShowWarning } from '../actions/contentTypesActions.ts';
import {
  initContentTypeEditor,
  saveEditedContentType,
} from '../actions/thunkContentTypesActions.ts';
import { ContentTypeEditor as ContentTypeEditorComponent } from '../components/ContentTypeEditor.tsx';
import { UntitledContentTypeName } from '../constants/contentTypeConstants.ts';
import { getContentTypeEditorState } from '../selectors/getContentTypeEditorState.ts';
import { getEditedContentType } from '../selectors/getEditedContentType.ts';
import { shouldLongProcessingChangesWarningBeHidden } from '../selectors/shouldLongProcessingChangesWarningBeHidden.ts';

const getValidationResults = compose((t) => t.validationResults, getContentTypeEditorState);
const getHasUnsavedChanges = compose((t) => t.editedTypeIsModified, getContentTypeEditorState);
const getIsBeingSaved = compose((t) => t.typeIsBeingSaved, getContentTypeEditorState);
const getLoadingStatus = compose((t) => t.loadingStatus, getContentTypeEditorState);

export const ContentTypeEditor = () => {
  const dispatch = useDispatch();
  const { contentTypeId } = useParams<EditContentTypeRouteParams>();
  const history = useHistory();

  const [isInitThunkDone] = useThunkPromise(initContentTypeEditor, contentTypeId, history);

  useEffect(() => {
    return () => {
      dispatch(editorLeft());
    };
  }, []);

  const onFocusFirstInvalidElement = () => {
    dispatch(focusFirstInvalidElement());
  };

  const focusTypeName = useSelector(
    compose(
      (name) => name === UntitledContentTypeName,
      (type) => type.name,
      getEditedContentType,
    ),
  );
  const showContentTypeUsedWithPublishedItemWarning = useSelector(
    (state) => state.contentModelsApp.contentTypes.editor.isEditedContentTypeUsedInPublishedItem,
  );
  const hideLongProcessingChangesWarning = useSelector(shouldLongProcessingChangesWarningBeHidden);
  const validationResults = useSelector(getValidationResults);
  const hasUnsavedChanges = useSelector(getHasUnsavedChanges);
  const isBeingSaved = useSelector(getIsBeingSaved);
  const allSet = useSelector(
    (s) => isInitThunkDone && getLoadingStatus(s) === LoadingStatus.Loaded,
  );
  const editedType = useSelector((s) => s.contentModelsApp.typeEditor.editedType);
  const originalTypeElements = useSelector(
    (s) => s.data.contentTypes.byId.get(editedType.id)?.typeElements,
  );
  const elementsWithAffectedLocalization = useMemo(
    () => getElementsWithAffectedLocalization(editedType, originalTypeElements),
    [editedType, originalTypeElements],
  );

  const isValid = (editedContentType: IContentType): boolean => {
    const elementToValidationResults = new Map(
      editedContentType.typeElements.map((element) => [
        element.elementId,
        dispatch(validateTypeElement(element)),
      ]),
    );
    return isTypeValid(elementToValidationResults);
  };

  const saveContentType = async (): Promise<void> => {
    if (isValid(editedType)) {
      await dispatch(saveEditedContentType(editedType));
    } else {
      dispatch(contentTypeInvalidElementShowWarning());
    }
  };

  const unsavedNavigationHandler = async (
    onSuccess: () => void,
    onFail: () => void,
  ): Promise<void> => {
    try {
      if (isValid(editedType)) {
        await dispatch(saveEditedContentType(editedType));
        onSuccess();
      } else {
        dispatch(contentTypeInvalidElementShowWarning());
        onFail();
      }
    } catch {
      onFail();
    }
  };

  const dismissLongProcessingChangesWarning = useCallback(
    () => dispatch(upsertUserProperty(LongProcessingChangesWarningDismissedServerKey, 'true')),
    [],
  );

  if (!allSet) {
    return <Loader />;
  }

  return (
    <ContentTypeEditorComponent
      onDismissLongProcessingChangesWarning={dismissLongProcessingChangesWarning}
      saveContentType={saveContentType}
      onUnsavedNavigation={unsavedNavigationHandler}
      elementsWithAffectedLocalization={elementsWithAffectedLocalization}
      focusTypeName={focusTypeName}
      hasUnsavedChanges={hasUnsavedChanges}
      isBeingSaved={isBeingSaved}
      onFocusFirstInvalidElement={onFocusFirstInvalidElement}
      showContentTypeUsedWithPublishedItemWarning={showContentTypeUsedWithPublishedItemWarning}
      hideLongProcessingChangesWarning={hideLongProcessingChangesWarning}
      validationResults={validationResults}
    />
  );
};
