import { memoize } from '@kontent-ai/memoization';
import { supportedElementTypes } from '../../../itemEditor/features/ContentItemEditing/utils/getElementValueForCustomElement.ts';
import { IBaseTypeElementData } from '../../shared/models/elements/types/TypeElementData.ts';
import { withDefaultNameIfNotSet } from '../../shared/utils/typeElementsUtils.ts';

export const getAllowedElements = memoize.maxOne(
  (
    elements: ReadonlyArray<IBaseTypeElementData>,
    typeElementData: IBaseTypeElementData,
  ): ReadonlyArray<IBaseTypeElementData> =>
    elements
      .map((element) => withDefaultNameIfNotSet(element))
      .filter(
        (element) =>
          supportedElementTypes.includes(element.type) &&
          element.elementId !== typeElementData.elementId &&
          element.contentGroupId === typeElementData.contentGroupId,
      ),
);
