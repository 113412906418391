import { InnovationLabFeatureAccess } from '../types/InnovationLabFeatureAccess.ts';

export const isInnovationLabFeatureOnlyForEmployees = (
  status: InnovationLabFeatureAccess,
): boolean => status === InnovationLabFeatureAccess.EmployeeOnly;

export const isInnovationLabFeatureAvailable = (status: InnovationLabFeatureAccess): boolean => {
  switch (status) {
    case InnovationLabFeatureAccess.Available:
    case InnovationLabFeatureAccess.EmployeeOnly:
      return true;
    default:
      return false;
  }
};
