import { areShallowEqual } from '@kontent-ai/utils';
import { forwardRef, useCallback } from 'react';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { CommentThreadItemType } from '../../../../../models/comments/CommentThreadItem.ts';
import { CommentThreadType } from '../../../../../models/comments/CommentThreads.ts';
import { startNewInlineCommentThread } from '../../../actions/thunkContentItemEditingActions.ts';
import {
  getAssetCommentThreads,
  getDisplayedAssetCommentThreads,
} from '../../../selectors/inlineCommentSelectors.ts';
import { AssetTile, IAssetTileOwnProps } from './AssetTile.tsx';

export type IElementAssetTileProps = Omit<
  IAssetTileOwnProps,
  'hasFocusedComment' | 'onNewComment' | 'commentThreadId'
> & {
  readonly contentComponentId: Uuid | null;
};

export const ElementAssetTile = forwardRef<HTMLDivElement, IElementAssetTileProps>(
  (ownProps, ref) => {
    const { assetId, contentComponentId, elementId } = ownProps;

    const { firstUnresolvedThreadId, hasFocusedComment } = useSelector((state) => {
      const threads = getAssetCommentThreads(
        state.contentApp.editedContentItemVariantComments.commentThreads,
        assetId,
        elementId,
        contentComponentId,
      );
      const unresolvedThreads =
        threads && getDisplayedAssetCommentThreads(threads, assetId, elementId, contentComponentId);
      const focusedCommentThreadId =
        state.contentApp.editedContentItemVariantComments.focusedCommentThreadId;

      return {
        firstUnresolvedThreadId: unresolvedThreads?.[0]?.id ?? null,
        hasFocusedComment:
          !!focusedCommentThreadId &&
          threads.some((thread) => thread.id === focusedCommentThreadId),
      };
    }, areShallowEqual);

    const assetFilename = useSelector(
      (state) => state.data.assets.byId.get(assetId)?.filename ?? '',
    );

    const dispatch = useDispatch();
    const onNewComment = useCallback(() => {
      dispatch(
        startNewInlineCommentThread({
          externalSegmentId: assetId,
          elementId,
          itemType: CommentThreadItemType.Comment,
          threadType: CommentThreadType.Asset,
          contentComponentId,
          elementSegment: assetFilename,
        }),
      );
    }, [assetId, assetFilename, elementId, contentComponentId]);

    return (
      <AssetTile
        commentThreadId={firstUnresolvedThreadId}
        hasFocusedComment={hasFocusedComment}
        onNewComment={onNewComment}
        ref={ref}
        {...ownProps}
      />
    );
  },
);

ElementAssetTile.displayName = 'ElementAssetTile';
