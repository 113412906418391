import { memoize } from '@kontent-ai/memoization';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { getSelectedSubscription } from '../../../../_shared/selectors/subscriptionSelectors.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { IProjectDetails } from '../../../../data/models/projects/ProjectDetails.ts';
import { getAllProjectsMemoized } from '../../../projects/selectors/projectSelectors.ts';
import {
  getSubscriptionMasterEnvironments,
  getSubscriptionProjects,
} from '../../shared/utils/subscriptionProjectUtils.ts';

export type SubscriptionProjectsAndEnvironments = {
  projects: ReadonlyArray<IProjectDetails>;
  environments: ReadonlyArray<IProjectDetails>;
  byMasterEnvironmentId: ReadonlyMap<Uuid, ReadonlyArray<IProjectDetails>>;
};

export const getSubscriptionProjectsAndEnvironments = (
  state: IStore,
): SubscriptionProjectsAndEnvironments | undefined => {
  const projectsById = getAllProjectsMemoized(state.data.projects.byId);
  const areProjectsLoaded = state.data.projects.loadingStatus === LoadingStatus.Loaded;
  const selectedSubscription = getSelectedSubscription(state);

  if (!selectedSubscription || !areProjectsLoaded) {
    return undefined;
  }

  const selectedSubscriptionProjects = getSubscriptionProjects(
    projectsById,
    selectedSubscription.subscriptionId,
  );
  const selectedSubscriptionMasterEnvironments = getSubscriptionMasterEnvironments(
    projectsById,
    selectedSubscription.subscriptionId,
  );
  const selectedSubscriptionProjectsByMasterEnvironmentId = groupProjectsByMasterEnvironmentId(
    selectedSubscriptionProjects,
  );

  return getSubscriptionProjectsAndEnvironmentsMemoized(
    selectedSubscriptionMasterEnvironments,
    selectedSubscriptionProjects,
    selectedSubscriptionProjectsByMasterEnvironmentId,
  );
};

const groupProjectsByMasterEnvironmentId = memoize.weak(
  (projects: ReadonlyArray<IProjectDetails>): ReadonlyMap<Uuid, ReadonlyArray<IProjectDetails>> => {
    return projects.reduce((reduced, current) => {
      const masterEnvironmentId = current.masterEnvironmentId;
      const existingEntry = reduced.get(masterEnvironmentId);
      if (existingEntry) {
        reduced.set(masterEnvironmentId, [...existingEntry, current]);
      } else {
        reduced.set(masterEnvironmentId, [current]);
      }

      return reduced;
    }, new Map());
  },
);

const getSubscriptionProjectsAndEnvironmentsMemoized = memoize.weak(
  (
    selectedSubscriptionMasterEnvironments: ReadonlyArray<IProjectDetails>,
    selectedSubscriptionProjects: ReadonlyArray<IProjectDetails>,
    selectedSubscriptionProjectsByMasterEnvironmentId: ReadonlyMap<
      Uuid,
      ReadonlyArray<IProjectDetails>
    >,
  ): SubscriptionProjectsAndEnvironments => ({
    projects: selectedSubscriptionMasterEnvironments,
    environments: selectedSubscriptionProjects,
    byMasterEnvironmentId: selectedSubscriptionProjectsByMasterEnvironmentId,
  }),
);
