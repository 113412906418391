import { getAllProjectContainerEnvironmentsUserProjectInfo } from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { Capability, getUserCapability } from '../../../../_shared/utils/permissions/capability.ts';

export const hasCapabilityInAnyEnvironment = (state: IStore, capability: Capability): boolean => {
  const projectContainerEnvironmentsUserProjectInfo =
    getAllProjectContainerEnvironmentsUserProjectInfo(state);

  return projectContainerEnvironmentsUserProjectInfo.some((userProjectInfo) =>
    getUserCapability(userProjectInfo).can(capability),
  );
};
