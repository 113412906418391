import { Button } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import React, { ComponentProps } from 'react';

type ActivateFeatureButtonProps = {
  readonly featureName: string;
  readonly isDeactivating: boolean;
} & Omit<
  ComponentProps<typeof Button>,
  'disabled' | 'size' | 'tooltipText' | 'buttonStyle' | 'destructive' | 'destructiveIcon'
>;

export const DeactivateFeatureButton: React.FC<ActivateFeatureButtonProps> = ({
  featureName,
  isDeactivating,
  ...restProps
}) => (
  <Button
    buttonStyle="tertiary"
    destructive
    destructiveIcon={Icons.Drawers}
    disabled={isDeactivating}
    size="small"
    {...restProps}
  >
    {isDeactivating && <Button.ProgressIcon />}
    <Button.Label>
      {isDeactivating ? 'Deactivating' : 'Deactivate'} {featureName}
    </Button.Label>
  </Button>
);
