import { IBaseSelectItem } from '@kontent-ai/component-library/Selects';
import { useState } from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { getRelevantCollections } from '../../../../../_shared/selectors/getRelevantCollections.ts';
import { Capability } from '../../../../../_shared/utils/permissions/capability.ts';
import { isAssetCollectionMandatoryForCurrentSubscriptionOrProject } from '../../../../environmentSettings/selectors/allowedFeaturesSelectors.ts';
import { AssetsSelectCollectionDialog as AssetsSelectCollectionDialogComponent } from '../../components/AssetListing/AssetsSelectCollectionDialog.tsx';
import { notInCollectionSelectItem } from '../../constants/assetNullCollectionConstants.ts';
import { cannotCreateAssetsInCollection } from '../../constants/uiConstants.ts';
import { getCollectionItems } from '../../utils/getCollectionItems.ts';

type Props = {
  readonly onClose: () => void;
  readonly onSelect: (collectionId: Uuid | null) => void;
};

export const AssetsUploadToCollectionDialog = (props: Props) => {
  const isAssetCollectionMandatory = useSelector(
    isAssetCollectionMandatoryForCurrentSubscriptionOrProject,
  );

  const availableSelectItems = useSelector((s) =>
    getCollectionItems(
      getRelevantCollections(s, Capability.CreateAssets),
      !isAssetCollectionMandatory,
    ),
  );
  const disabledSelectItems = useSelector((s) =>
    getCollectionItems(getRelevantCollections(s, Capability.ViewAssets), false)
      .filter((item) => !availableSelectItems.some(({ id }) => item.id === id))
      .map((item) => ({ ...item, disabledTooltipText: cannotCreateAssetsInCollection })),
  );

  const collectionContext = useSelector((s) => s.contentApp.editedContentItem?.collectionId);

  const [selectedOption, setSelectedOption] = useState<IBaseSelectItem | null>(
    availableSelectItems.find((collection) => collection.id === collectionContext) ?? null,
  );

  return (
    <AssetsSelectCollectionDialogComponent
      collectionSelectItems={[...availableSelectItems, ...disabledSelectItems]}
      disabledCollectionSelectItemIds={disabledSelectItems.map((item) => item.id)}
      disabledTooltipText={selectedOption ? undefined : 'Select a collection first.'}
      handleClose={props.onClose}
      handleSelect={(option) =>
        props.onSelect(option.id === notInCollectionSelectItem.id ? null : option.id)
      }
      handleSelectedOptionChange={setSelectedOption}
      headlineText="Upload assets"
      labelText="Select a collection for the new assets"
      primaryActionText="Upload"
      selectedOption={selectedOption}
    />
  );
};
