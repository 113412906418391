import React from 'react';
import { connect } from 'react-redux';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { getCannotViewItemMessage } from '../../../../_shared/utils/permissions/getCannotViewItemMessage.ts';
import {
  IRelationsListingMessageProps,
  RelationsListingMessage as RelationsListingMessageComponent,
} from '../components/RelationsListingMessage.tsx';
import { getSelectedRootItem } from '../selectors/getSelectedRootItem.ts';

const mapStateToProps = (state: IStore): IRelationsListingMessageProps => {
  const currentRootItem = getSelectedRootItem(state);
  const cannotViewMessage = currentRootItem ? getCannotViewItemMessage(currentRootItem) : undefined;

  return {
    cannotViewMessage,
    currentRootItem,
  };
};

export const RelationsListingMessage: React.ComponentType = connect(mapStateToProps)(
  RelationsListingMessageComponent,
);
