import { WebSpotlightPreviewError } from '../../../../../../../webSpotlight/components/preview/WebSpotlightPreviewError.tsx';
import { PreviewError } from '../../../../../../utils/previewUtils.ts';
import { PreviewContentItemInfo } from '../../../../../../utils/selectDeepestItemWithPreview.ts';
import { ErrorPreviewNotAvailableTitle } from '../../../../constants/uiConstants.ts';
import { OpenSpaceDomainSettingsButton } from '../../containers/previewEmptyStates/OpenSpaceDomainSettingsButton.tsx';
import { PreviewEmptyStateDocumentationMessageLink } from './PreviewEmptyStateDocumentationMessageLink.tsx';
import { PreviewErrorState } from './PreviewErrorState.tsx';

type Props = Readonly<{
  hideDocumentationLink: boolean;
  previewContentItemInfo: PreviewContentItemInfo;
}>;

export const MissingSpaceDomain = ({ hideDocumentationLink, previewContentItemInfo }: Props) => {
  return (
    <PreviewErrorState
      actionButton={<OpenSpaceDomainSettingsButton />}
      title={ErrorPreviewNotAvailableTitle}
    >
      <WebSpotlightPreviewError
        error={PreviewError.MissingSpaceDomain}
        previewContentItemInfo={previewContentItemInfo}
      />
      {!hideDocumentationLink && <PreviewEmptyStateDocumentationMessageLink />}
    </PreviewErrorState>
  );
};
