import { Button } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../utils/dataAttributes/DataUiAttributes.ts';

export const FilterButton = ({
  isActive,
  onPress,
}: Readonly<{
  isActive: boolean;
  onPress: () => void;
}>) => (
  <Button
    activated={isActive}
    buttonStyle="tertiary"
    onClick={onPress}
    size="medium"
    {...getDataUiActionAttribute(DataUiAction.OpenFilter)}
  >
    <Button.Icon icon={Icons.Filter1} />
    filters
    <Button.Icon icon={isActive ? Icons.ChevronUp : Icons.ChevronDown} />
  </Button>
);
