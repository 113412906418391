import { assert } from '@kontent-ai/utils';
import React, { createContext, useContext } from 'react';
import { UseAssetSelectionReturnType } from '../hooks/useAssetSelection.ts';

type AssetLibrarySelection = UseAssetSelectionReturnType;

const AssetLibrarySelectionContext = createContext<AssetLibrarySelection | undefined>(undefined);

type Props = {
  readonly value: AssetLibrarySelection;
};

export const AssetLibrarySelectionContextProvider: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  value,
}) => (
  <AssetLibrarySelectionContext.Provider value={value}>
    {children}
  </AssetLibrarySelectionContext.Provider>
);

AssetLibrarySelectionContextProvider.displayName = 'AssetLibrarySelectionContextProvider';

export const useAssetLibrarySelection = (): AssetLibrarySelection => {
  const value = useContext(AssetLibrarySelectionContext);
  assert(
    value,
    () =>
      `useAssetLibrarySelection: ${AssetLibrarySelectionContextProvider.displayName} is missing in the current tree`,
  );
  return value;
};
