import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { ConnectDragSource } from 'react-dnd';
import { ActionItem } from '../../../../../_shared/components/ActionMenu.tsx';
import { DragAction } from '../../../../../_shared/components/DragDrop/DragAction.tsx';
import {
  SimpleTabTitle,
  SimpleTabTitleModifier,
} from '../../../../../_shared/components/TabMenu/SimpleTabTitle.tsx';
import { TabBadgeType } from '../../../../../_shared/components/TabMenu/TabBadgeType.tsx';
import { TabWrapper } from '../../../../../_shared/components/TabMenu/TabWrapper.tsx';
import {
  DataUiAction,
  DataUiElement,
  getDataUiElementAttribute,
  getDataUiObjectNameAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IContentTypeUsage } from '../../../../../data/models/contentModelsApp/contentTypes/ContentTypeUsage.ts';
import { ContentGroupDeleteDialog } from './ContentGroupDeleteDialog.tsx';
import { ContentGroupMoreActions } from './ContentGroupMoreActions.tsx';
import { ContentGroupDialogState, ContentGroupNameDialog } from './ContentGroupNameDialog.tsx';

export interface IContentTypeContentGroupTabStateProps {
  readonly childElementCount: number;
  readonly contentTypeUsage: IContentTypeUsage;
  readonly hasInvalidElements: boolean;
  readonly isLastContentGroup: boolean;
  readonly name: string;
  readonly selected: boolean;
}

export interface IContentTypeContentGroupTabDispatchProps {
  readonly onDelete: () => void;
  readonly onRename: (newContentGroupName: string) => void;
  readonly onSelect: () => void;
}

type ContentTypeContentGroupTabProps = IContentTypeContentGroupTabStateProps &
  IContentTypeContentGroupTabDispatchProps & {
    readonly connectDragSource?: ConnectDragSource;
    readonly isDragging: boolean;
  };

export const ContentTypeContentGroupTab: React.FC<ContentTypeContentGroupTabProps> = ({
  childElementCount,
  connectDragSource,
  contentTypeUsage,
  hasInvalidElements,
  isDragging,
  isLastContentGroup,
  name,
  onDelete,
  onRename,
  onSelect,
  selected,
}) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showRenameDialog, setShowRenameDialog] = useState(false);
  const wrapperElementRef = useRef<HTMLAnchorElement>(null);

  const moreActions: ReadonlyArray<ActionItem> = [
    {
      dataUiAction: DataUiAction.Rename,
      disabledState: { isDisabled: false },
      hasCapabilityToExecuteAction: true,
      isDestructive: false,
      onClick: () => setShowRenameDialog(true),
      text: 'Rename',
    },
    {
      dataUiAction: DataUiAction.Delete,
      disabledState: { isDisabled: false },
      hasCapabilityToExecuteAction: true,
      isDestructive: true,
      onClick: () => setShowDeleteDialog(true),
      text: 'Delete',
    },
  ];

  const activeTab: JSX.Element = (
    <div className="content-type-group-tab__inner">
      <DragAction connectDragSource={connectDragSource} />
      <div className="content-type-group-tab__title">{name}</div>
      <ContentGroupMoreActions actions={moreActions} />
    </div>
  );

  const onDeleteConfirmed = (): void => {
    onDelete();
    setShowDeleteDialog(false);
  };

  const onRenameConfirmed = (newContentGroupName: string): void => {
    onRename(newContentGroupName);
    setShowRenameDialog(false);
  };

  return (
    <>
      <div
        className={classNames('content-type-group-tab', {
          'content-type-group-tab--is-dragging': isDragging,
        })}
      >
        <TabWrapper
          className="tabbed-navigation__tab--in-content-type"
          dataUiAttributes={{
            ...getDataUiElementAttribute(DataUiElement.ContentGroup),
            ...getDataUiObjectNameAttribute(name),
          }}
          selected={selected}
          badgeType={hasInvalidElements ? TabBadgeType.Error : undefined}
          onClick={onSelect}
          ref={wrapperElementRef}
        >
          {selected ? (
            activeTab
          ) : (
            <SimpleTabTitle title={name} modifier={SimpleTabTitleModifier.IsInContentGroup} />
          )}
        </TabWrapper>
      </div>
      {showRenameDialog && (
        <ContentGroupNameDialog
          onCancel={() => setShowRenameDialog(false)}
          onConfirm={onRenameConfirmed}
          state={ContentGroupDialogState.Edited}
          value={name}
        />
      )}
      {showDeleteDialog && (
        <ContentGroupDeleteDialog
          contentTypeUsage={contentTypeUsage}
          childElementCount={childElementCount}
          contentGroupName={name}
          isLastContentGroup={isLastContentGroup}
          onClose={() => setShowDeleteDialog(false)}
          onConfirm={onDeleteConfirmed}
        />
      )}
    </>
  );
};

ContentTypeContentGroupTab.displayName = 'ContentTypeContentGroupTab';
