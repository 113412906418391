import { Dispatch, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { ISitemap } from '../../../../../data/models/contentModelsApp/sitemap/Sitemap.ts';
import { Sitemap_StopDraggingNode } from '../../constants/sitemapActionTypes.ts';

interface IDropNodeDependencies {
  readonly updateSitemap: (sitemap: ISitemap) => ThunkPromise;
}

const stopDraggingNode = () =>
  ({
    type: Sitemap_StopDraggingNode,
  }) as const;

export type DropNodeActionsType = ReturnType<typeof stopDraggingNode>;

export const dropNodeCreator =
  (deps: IDropNodeDependencies) =>
  (): ThunkPromise =>
  async (dispatch: Dispatch, getState: () => IStore): Promise<void> => {
    dispatch(stopDraggingNode());

    const sitemap = getState().sitemapApp.sitemap;
    if (sitemap) {
      await dispatch(deps.updateSitemap(sitemap));
    }
  };
