import { makeCancellablePromise, swallowCancelledPromiseError } from '@kontent-ai/utils';
import { useContext, useEffect, useState } from 'react';
import { loadProjectProperties } from '../../../../_shared/actions/thunkSharedActions.ts';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import { areAiAcceleratorsAvailable } from '../../../../_shared/selectors/AiAccelerators/areAiAcceleratosEnabled.ts';
import {
  isAiAuthorAssistEnabled,
  isAiImageDescriptionEnabled,
  isAiImageTaggingEnabled,
  isAiItemCategorizationEnabled,
  isAiLinkedItemsEnabled,
  isAiReviewEnabled,
  isAiSearchEnabled,
  isEnablingAiSearchActivated,
  isEnablingLinkedItemsActivated,
} from '../../../../_shared/selectors/aiSelectors.ts';
import { getCurrentProjectId } from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { isAssetReplaceFeatureAvailable } from '../../../contentInventory/assets/selectors/isAssetReplaceFeatureAvailable.ts';
import { areMissionControlEnhancementsEnabled } from '../../../missionControl/selectors/areMissionControlEnhancementsEnabled.ts';
import { RefinedNavigationContext } from '../../../refinedNavigation/contexts/RefinedNavigationContext.tsx';
import { AiTranslationsContext } from '../../../richText/plugins/ai/actions/TranslateContent/contexts/AiTranslationsContext.tsx';
import { isAssetCollectionMandatoryForCurrentSubscriptionOrProject } from '../../selectors/allowedFeaturesSelectors.ts';
import { InnovationLabAppFilterContext } from '../components/InnovationLabAppFilterContext.tsx';
import { InnovationLabEmptyState } from '../components/InnovationLabEmptyState.tsx';
import { InnovationLabFiltersEmptyState } from '../components/InnovationLabFiltersEmptyState.tsx';
import { InnovationLabActivationState } from '../types/InnovationLabActivationState.type.ts';
import { InnovationLabFeatureCodename } from '../types/InnovationLabFeatureCodenames.ts';
import { InnovationLabFeatureMetadata } from '../types/InnovationLabFeatureMetadata.type.ts';
import { InnovationLabFeatureSet } from '../types/InnovationLabFeatureSet.type.ts';
import { getInnovationLabFeatures } from '../utils/getInnovationLabFeatures.ts';
import { createInnovationLabFeaturesDomainModel } from '../utils/innovationLabModelConversion.ts';
import { AiImageDescriptionCard } from './featureCards/AiImageDescriptionCard.tsx';
import { AiItemCategorizationCard } from './featureCards/AiItemCategorizationCard.tsx';
import { AiLinkedItemsCard } from './featureCards/AiLinkedItemsCard.tsx';
import { AiReviewCard } from './featureCards/AiReviewCard.tsx';
import { AiSearchCard } from './featureCards/AiSearchCard.tsx';
import { AssetCollectionMandatoryCard } from './featureCards/AssetCollectionMandatoryCard.tsx';
import { AssetReplacementCard } from './featureCards/AssetReplacementCard.tsx';
import { AssetTaggingAiCard } from './featureCards/AssetTaggingAiCard.tsx';
import { AuthorAssistCard } from './featureCards/AuthorAssistCard.tsx';
import { ElementAiTranslationCard } from './featureCards/ElementAiTranslationCard.tsx';
import { MissionControlCard } from './featureCards/MissionControlCard.tsx';
import { RefinedNavigationCard } from './featureCards/RefinedNavigationCard.tsx';
import { SecureAssetsCard } from './featureCards/SecureAssetsCard.tsx';

const { innovationLabRepository } = repositoryCollection;

const isFeatureSetEmpty = (featureSet: InnovationLabFeatureSet): boolean =>
  Object.keys(featureSet).length === 0;

const useInnovationLabFeaturesLoader = (): null | InnovationLabFeatureSet => {
  const [featureSet, setFeatureSet] = useState<null | InnovationLabFeatureSet>(null);
  useEffect(() => {
    const { cancel } = makeCancellablePromise(() => innovationLabRepository.getFeatures())
      .then(createInnovationLabFeaturesDomainModel)
      .then(setFeatureSet)
      .catch(swallowCancelledPromiseError)
      .catch(() => setFeatureSet({}));

    return cancel;
  }, []);

  return featureSet;
};

export const InnovationLab = () => {
  const { sortBy, status } = useContext(InnovationLabAppFilterContext);
  const featureSet = useInnovationLabFeaturesLoader();

  const codenameToMetadata = useFeaturesMetadata();
  const features = featureSet
    ? getInnovationLabFeatures(featureSet, sortBy, status, codenameToMetadata)
    : [];

  const currentProjectId = useSelector(getCurrentProjectId);
  const [projectPropertiesLoaded] = useThunkPromise(loadProjectProperties, currentProjectId);

  if (!featureSet || !projectPropertiesLoaded) {
    return <Loader />;
  }

  if (isFeatureSetEmpty(featureSet)) {
    return <InnovationLabEmptyState />;
  }

  if (features.length === 0) {
    return <InnovationLabFiltersEmptyState />;
  }

  return (
    <>
      {features.map((feature) => {
        const Component = FeatureCodenameToComponent[feature.codename];
        return (
          <Component
            key={feature.codename}
            info={feature.info}
            name={feature.name}
            state={feature.state}
          />
        );
      })}
    </>
  );
};

const useFeaturesMetadata = (): ReadonlyRecord<
  InnovationLabFeatureCodename,
  InnovationLabFeatureMetadata
> => ({
  'ai-search': {
    name: 'Semantic search',
    releaseDate: '2025-01-01T00:00:00.000Z',
    state: getAiSearchActivationState(
      useSelector(isAiSearchEnabled),
      useSelector(isEnablingAiSearchActivated),
    ),
  },
  'ai-linked-items': {
    name: 'Suggest linked items with AI',
    releaseDate: '2024-05-16T00:00:00.000Z',
    state: getLinkedItemsActivationState(
      useSelector(isAiLinkedItemsEnabled),
      useSelector(isEnablingLinkedItemsActivated),
    ),
  },
  'ai-image-description': {
    name: 'Generate localized image descriptions with AI',
    releaseDate: '2024-04-23T00:00:00.000Z',
    state: getFeatureActivationState(useSelector(isAiImageDescriptionEnabled)),
  },
  'ai-item-categorization': {
    name: 'Categorize content items with AI',
    releaseDate: '2023-12-22T00:00:00.000Z',
    state: getFeatureActivationState(useSelector(isAiItemCategorizationEnabled)),
  },
  'ai-asset-tagging': {
    name: 'Classify images with AI',
    releaseDate: '2024-03-06T00:00:00.000Z',
    state: getFeatureActivationState(useSelector(isAiImageTaggingEnabled)),
  },
  'ai-element-translation': {
    name: 'Translate with AI',
    releaseDate: '2023-12-23T00:00:00.000Z',
    state: getFeatureActivationState(
      useContext(AiTranslationsContext).isTranslationsFeatureEnabled,
    ),
    shouldBeHidden: areAiAcceleratorsAvailable(),
  },
  'asset-collection-mandatory': {
    name: 'Mandatory collections for assets',
    releaseDate: '2023-06-14T00:00:00.000Z',
    state: getFeatureActivationState(
      useSelector(isAssetCollectionMandatoryForCurrentSubscriptionOrProject),
    ),
  },
  'secure-assets': {
    name: 'Secure access to assets',
    releaseDate: '2022-02-02T00:00:00.000Z',
    state: useSelector(getSecureAssetsActivationState),
  },
  'asset-replace': {
    name: 'Fast asset replacement',
    releaseDate: '2021-10-30T00:00:00.000Z',
    state: getFeatureActivationState(useSelector(isAssetReplaceFeatureAvailable)),
  },
  'content-status': {
    name: 'Mission Control',
    releaseDate: '2023-12-04T00:00:00.000Z',
    state: getFeatureActivationState(useSelector(areMissionControlEnhancementsEnabled)),
  },
  'ai-author-assist': {
    name: 'Author content with AI',
    releaseDate: '2023-10-30T00:00:00.000Z',
    state: getFeatureActivationState(useSelector(isAiAuthorAssistEnabled)),
  },
  'ai-review': {
    name: 'Review content with AI',
    releaseDate: '2024-10-17T00:00:00.000Z',
    state: getFeatureActivationState(useSelector(isAiReviewEnabled)),
  },
  'refined-navigation': {
    name: 'Streamlined product navigation',
    releaseDate: '2025-03-01T00:00:00.000Z',
    state: getFeatureActivationState(
      useContext(RefinedNavigationContext).isRefinedNavigationFeatureEnabled,
    ),
  },
});

const FeatureCodenameToComponent = {
  'ai-search': AiSearchCard,
  'ai-linked-items': AiLinkedItemsCard,
  'ai-image-description': AiImageDescriptionCard,
  'ai-item-categorization': AiItemCategorizationCard,
  'ai-asset-tagging': AssetTaggingAiCard,
  'ai-element-translation': ElementAiTranslationCard,
  'asset-collection-mandatory': AssetCollectionMandatoryCard,
  'secure-assets': SecureAssetsCard,
  'asset-replace': AssetReplacementCard,
  'content-status': MissionControlCard,
  'ai-author-assist': AuthorAssistCard,
  'ai-review': AiReviewCard,
  'refined-navigation': RefinedNavigationCard,
} as const;

const getFeatureActivationState = (isFeatureEnabled: boolean): InnovationLabActivationState =>
  isFeatureEnabled ? 'active' : 'inactive';

const getAiSearchActivationState = (
  isSearchActivated: boolean,
  isEnablingLinkedItems: boolean,
): InnovationLabActivationState => {
  if (isSearchActivated) {
    return 'active';
  }

  if (isEnablingLinkedItems) {
    return 'activation-pending';
  }

  return 'inactive';
};

const getLinkedItemsActivationState = (
  isLinkedItemsActivated: boolean,
  isEnablingLinkedItems: boolean,
): InnovationLabActivationState => {
  if (isLinkedItemsActivated) {
    return 'active';
  }

  if (isEnablingLinkedItems) {
    return 'activation-pending';
  }

  return 'inactive';
};

const getSecureAssetsActivationState = ({
  sharedApp: {
    projectProperties: { SecureAssets },
  },
}: IStore): InnovationLabActivationState => {
  const hasSecureAccess = SecureAssets ? SecureAssets === 'Enabled' : null;

  switch (hasSecureAccess) {
    case true:
      return 'active';

    case false:
      return 'inactive';

    case null:
      return 'unknown';
  }
};
