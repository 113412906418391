import { Collection } from '@kontent-ai/utils';
import React, { useCallback } from 'react';
import {
  BaseTextFormattingFeature,
  TextBlockTypeFeature,
  TextFormattingFeature,
  allTextBlockTypeFeatures,
  allTextFormattingFeatures,
} from '../../../../../../../richText/plugins/apiLimitations/api/editorLimitationUtils.ts';
import { IInlineFormattingWrapperProps } from '../../../../../types/IInlineFormattingWrapperProps.type.ts';
import { InlineFormatting } from './InlineFormatting.tsx';

export const TextInlineFormatting: React.FC<IInlineFormattingWrapperProps> = (props) => {
  const { disabled, typeElementData, onChange, tooltipText } = props;

  const { allowedTextBlocks, allowedTextFormatting } = typeElementData;

  const _selectAll = useCallback(() => {
    onChange({
      ...typeElementData,
      allowedTextBlocks: allTextBlockTypeFeatures,
      allowedTextFormatting: allTextFormattingFeatures,
    });
  }, [onChange, typeElementData]);

  const _deselectAll = useCallback(() => {
    onChange({
      ...typeElementData,
      allowedTextBlocks: new Set<TextBlockTypeFeature>(),
      allowedTextFormatting: new Set<TextFormattingFeature>([TextFormattingFeature.Unstyled]),
    });
  }, [onChange, typeElementData]);

  const _updateAllowedTextBlocks = useCallback(
    (feature: TextBlockTypeFeature, isAllowed: boolean): void => {
      const updatedAllowedTextBlocks: ReadonlySet<TextBlockTypeFeature> = isAllowed
        ? Collection.add(typeElementData.allowedTextBlocks, feature)
        : Collection.remove(typeElementData.allowedTextBlocks, feature);

      onChange({
        ...typeElementData,
        allowedTextBlocks: updatedAllowedTextBlocks,
      });
    },
    [onChange, typeElementData],
  );

  const _updateAllowedTextFormatting = useCallback(
    (feature: BaseTextFormattingFeature, isAllowed: boolean): void => {
      const updatedAllowedTextFormatting: ReadonlySet<TextFormattingFeature> = isAllowed
        ? Collection.add(typeElementData.allowedTextFormatting, feature)
        : Collection.remove(typeElementData.allowedTextFormatting, feature);

      onChange({
        ...typeElementData,
        allowedTextFormatting: updatedAllowedTextFormatting,
      });
    },
    [onChange, typeElementData],
  );

  return (
    <InlineFormatting
      allowedTextBlocks={allowedTextBlocks}
      allowedTextFormatting={allowedTextFormatting}
      disabled={disabled}
      onDeselectAll={_deselectAll}
      onSelectAll={_selectAll}
      onUpdateAllowedTextBlocks={_updateAllowedTextBlocks}
      onUpdateAllowedTextFormatting={_updateAllowedTextFormatting}
      tooltipText={tooltipText}
    />
  );
};

TextInlineFormatting.displayName = 'TextInlineFormatting';
