import { Box } from '@kontent-ai/component-library/Box';
import { Callout } from '@kontent-ai/component-library/Callout';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Label, LabelSize } from '@kontent-ai/component-library/Label';
import { Stack } from '@kontent-ai/component-library/Stack';
import {
  Spacing,
  Typography,
  colorTextDefault,
  colorTextHint,
  gridUnit,
} from '@kontent-ai/component-library/tokens';
import React, { useMemo, useState } from 'react';
import { ModalDialog } from '../../../../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import { AssignedSpacesMessage } from '../../../../../../_shared/components/Spaces/AssignedSpacesMessage.tsx';
import {
  DataUiAction,
  DataUiElement,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ICollection } from '../../../../../../data/models/collections/Collection.ts';
import { SpacesMap } from '../../../../../../data/models/space/space.ts';
import {
  spaceNames,
  spacesByCollectionId,
} from '../../../../../environmentSettings/spaces/utils/spacesUtils.ts';
import { CollectionSingleSelect } from '../../../../shared/components/CollectionSingleSelect.tsx';

type BulkMoveToCollectionDialogProps = {
  readonly getCollectionDisabledMessage: (collection: ICollection) => string | undefined;
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly onMove: (collectionId: Uuid) => void;
  readonly options: ReadonlyArray<ICollection>;
  readonly showWorkflowWarning: boolean;
  readonly spacesMap: SpacesMap | null;
};

export const BulkMoveToCollectionDialog: React.FC<BulkMoveToCollectionDialogProps> = ({
  getCollectionDisabledMessage,
  isOpen,
  onClose,
  onMove,
  options,
  showWorkflowWarning,
  spacesMap,
}) => {
  const [targetCollectionId, setTargetCollectionId] = useState<Uuid | null>(null);
  const contentWidth = 92 * gridUnit;

  const collectionSpaceNames = useMemo(() => {
    const spaceIds =
      targetCollectionId && spacesMap && spacesByCollectionId(spacesMap, targetCollectionId);
    return (
      spaceIds &&
      spaceNames(
        spacesMap,
        spaceIds.map((space) => space.id),
      )
    );
  }, [targetCollectionId, spacesMap]);

  const closeModal = (): void => {
    setTargetCollectionId(null);
    onClose();
  };

  const moveItems = (): void => {
    if (targetCollectionId) {
      onMove(targetCollectionId);
      closeModal();
    }
  };

  return (
    <ModalDialog
      cancelAction={{
        onClick: closeModal,
        ...getDataUiActionAttribute(DataUiAction.CloseDialog),
      }}
      primaryAction={{
        disabled: !targetCollectionId,
        onClick: moveItems,
        text: 'Move',
        tooltipPlacement: 'top-end',
        tooltipText: targetCollectionId ? undefined : 'Select a collection',
        ...getDataUiActionAttribute(DataUiAction.Move),
      }}
      headline="Change collection"
      isOpen={isOpen}
      isDismissable
      onClose={closeModal}
      {...getDataUiElementAttribute(DataUiElement.Dialog)}
    >
      <Box width={contentWidth}>
        <Stack spacing={Spacing.XL}>
          <Stack spacing={Spacing.M}>
            <Inline align="center" spacingX={Spacing.M}>
              <Label size={LabelSize.L}>Move selected items to</Label>
              <CollectionSingleSelect
                currentCollectionId={targetCollectionId}
                customClassName="move-to-collection-modal__selector"
                getCollectionDisabledMessage={getCollectionDisabledMessage}
                onChange={setTargetCollectionId}
                collectionOptions={options}
                shouldHighlightAsError={false}
              />
            </Inline>
            {collectionSpaceNames && (
              <Box color={colorTextHint} typography={Typography.BodyMedium}>
                <AssignedSpacesMessage
                  spaceData={collectionSpaceNames}
                  renderSpaceName={(space) => (
                    <Box
                      component="span"
                      color={colorTextDefault}
                      typography={Typography.BodyMediumEmphasis}
                    >
                      {space.name}
                    </Box>
                  )}
                />
              </Box>
            )}
          </Stack>
          {showWorkflowWarning && (
            <Callout calloutType="friendlyWarning">
              The items will continue in their current workflow. You can change the workflow
              manually later.
            </Callout>
          )}
          <Callout calloutType="friendlyWarning">
            Moving items to another collection also affects their published content in all
            languages.
            <br />
            This action may break your app.
          </Callout>
        </Stack>
      </Box>
    </ModalDialog>
  );
};

BulkMoveToCollectionDialog.displayName = 'BulkMoveToCollectionDialog';
