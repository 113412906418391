import React from 'react';
import { Redirect } from 'react-router';
import { RootRoute } from '../../../_shared/constants/routePaths.ts';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { getLanguage } from '../../../_shared/utils/languageUtils.ts';
import { ILanguage } from '../../../data/models/languages/Language.ts';
import { addInactiveLanguageSuffix } from '../../environmentSettings/localization/utils/languageUtils.ts';
import { AccessDenied } from '../components/AccessDenied.tsx';

export const AccessDeniedToLanguage: React.FC<{ readonly languageId?: Uuid }> = ({
  languageId,
}) => {
  const language: ILanguage | null = useSelector((state) =>
    getLanguage(state.data.languages, languageId ?? ''),
  );

  return language ? (
    <AccessDenied
      title={`Looks like you don’t have access to “${
        addInactiveLanguageSuffix(language).name
      }” anymore.`}
    />
  ) : (
    <Redirect to={RootRoute} />
  );
};
