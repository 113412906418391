import { useForm } from 'react-hook-form';
import { ModalDialog } from '../../../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import { isEmptyOrWhitespace } from '../../../../../_shared/utils/stringUtils.ts';
import { createFormValidationResolver } from '../../../../../_shared/utils/validation/createFormValidationResolver.ts';
import { AiGuidelines as AiGuidelinesModel } from '../../../data/models/aiGuidelines.ts';
import { AllFieldsAreRequiredMessage, DialogWidth } from '../constants/uiConstants.ts';
import { AiGuidelinesFormShape } from '../types/AiGuidelinesFormShape.type.ts';
import { aiGuidelinesValidationConfig } from '../validation/aiGuidelinesValidation.ts';
import { AiGuidelinesForm } from './AiGuidelinesForm.tsx';

type EditAiGuidelinesModalProps = {
  readonly aiGuidelines: ReadonlyArray<AiGuidelinesModel>;
  readonly editedAiGuidelinesId: Uuid;
  readonly onClose: () => void;
  readonly onEdit: (id: Uuid, aiGuidelines: AiGuidelinesFormShape) => void;
};

export const EditAiGuidelinesModal = ({
  aiGuidelines,
  editedAiGuidelinesId,
  onClose,
  onEdit,
}: EditAiGuidelinesModalProps) => {
  const editedAiGuidelines = aiGuidelines.find(
    (guidelines) => guidelines.id === editedAiGuidelinesId,
  );
  const otherExistingAiGuidelines = aiGuidelines.filter(
    (guidelines) => guidelines.id !== editedAiGuidelinesId,
  );

  const formProps = useForm<AiGuidelinesFormShape>({
    defaultValues: {
      name: editedAiGuidelines?.name,
      guidelines: editedAiGuidelines?.guidelines,
    },
    resolver: createFormValidationResolver(aiGuidelinesValidationConfig, {
      existingAiGuidelines: otherExistingAiGuidelines,
    }),
  });

  const { handleSubmit, watch } = formProps;
  const isAnyFieldEmpty =
    isEmptyOrWhitespace(watch('guidelines')) || isEmptyOrWhitespace(watch('name'));

  const submitForm = handleSubmit((guidelinesFormShape) => {
    onEdit(editedAiGuidelinesId, guidelinesFormShape);
  });

  return (
    <ModalDialog
      headline="Edit review guidelines"
      isOpen
      isDismissable
      primaryAction={{
        text: 'Save',
        onClick: submitForm,
        disabled: isAnyFieldEmpty,
        tooltipText: isAnyFieldEmpty ? AllFieldsAreRequiredMessage : null,
      }}
      cancelAction={{
        onClick: onClose,
      }}
      maxWidth={DialogWidth}
      minWidth={DialogWidth}
      onClose={onClose}
    >
      <AiGuidelinesForm formProps={formProps} />
    </ModalDialog>
  );
};
