import { useHotkeys } from '@kontent-ai/component-library/hooks';
import React, { useRef } from 'react';

/**
 * @deprecated Use useHotkeys with excludedElementRefs instead.
 */
export const EnterHotkeyDisabler: React.FC<React.PropsWithChildren> = ({ children }) => {
  const ref = useRef<HTMLDivElement>(null);

  useHotkeys(
    {
      enter: (keyboardEvent): void => {
        keyboardEvent.stopPropagation();
      },
    },
    { ref },
  );

  return <div ref={ref}>{children}</div>;
};
