import React from 'react';
import { Warning } from '../../../../../_shared/components/infos/Warning.tsx';

export const SitemapWarning: React.FC = () => (
  <Warning subtitle="Sitemap is used with published content">
    <p>You are about to make changes to the sitemap that is used with published content.</p>
    <p>
      Keep in mind that any change you make to this sitemap may affect how your published content is
      displayed on your website or mobile app.
    </p>
  </Warning>
);

SitemapWarning.displayName = 'SitemapWarning';
