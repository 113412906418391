import { Box } from '@kontent-ai/component-library/Box';
import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { noOperation, stopPropagation } from '@kontent-ai/utils';
import classNames from 'classnames';
import React, { useContext } from 'react';
import { ConnectDragSource } from 'react-dnd';
import { AssetDownloadButton } from '../../../../../../../_shared/components/AssetTile/AssetDownloadButton.tsx';
import { AssetUncategorizedLabel } from '../../../../../../../_shared/components/AssetTile/AssetUncategorizedLabel.tsx';
import { FailedAssetUploadActions } from '../../../../../../../_shared/components/AssetTile/FailedAssetUploadActions.tsx';
import { DragAction } from '../../../../../../../_shared/components/DragDrop/DragAction.tsx';
import { TrackUserEvent } from '../../../../../../../_shared/models/TrackUserEvent.type.ts';
import {
  DataUiAction,
  DataUiAssetRenditionAction,
  getDataUiActionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { compose } from '../../../../../../../_shared/utils/func/compose.ts';
import { IImageTransformation } from '../../../../../../../data/models/assetRenditions/AssetRendition.ts';
import { IAsset } from '../../../../../../../data/models/assets/Asset.ts';
import { CommentsContext } from '../../../../../components/CommentsContext.tsx';
import { CommentButton } from '../../../containers/elements/subComponents/CommentButton.tsx';

export interface IAssetTileActionsStateProps {
  readonly isDragDisabled?: boolean;
  readonly openRenditionDialogDisabledMessage: string | null;
}

export interface IAssetTileActionsDispatchProps {
  readonly trackUserEvent: TrackUserEvent;
}

export enum AssetTileAction {
  AddComment = 'addComment',
  Edit = 'edit',
  Delete = 'delete',
  Download = 'download',
  OpenRendition = 'openRendition',
}

export interface IAssetTileActionsOwnProps {
  readonly actions: ReadonlyArray<AssetTileAction>;
  readonly asset: IAsset;
  readonly commentThreadId: Uuid | null;
  readonly connectDragSource?: ConnectDragSource;
  readonly disabled: boolean;
  readonly disableTabulator?: boolean;
  readonly hasFocusedComment: boolean;
  readonly isUncategorized: boolean | null;
  readonly onNewComment: () => void;
  readonly onOpenRenditionDialog: (() => void) | undefined;
  readonly onRemove: (() => void) | undefined;
  readonly transformation?: IImageTransformation;
}

type AssetTileActionsProps = IAssetTileActionsStateProps &
  IAssetTileActionsDispatchProps &
  IAssetTileActionsOwnProps;

export const AssetTileActions: React.FC<AssetTileActionsProps> = ({
  actions,
  asset,
  commentThreadId,
  connectDragSource,
  disabled,
  disableTabulator,
  hasFocusedComment,
  isDragDisabled,
  isUncategorized,
  onNewComment,
  onOpenRenditionDialog,
  onRemove,
  openRenditionDialogDisabledMessage,
  trackUserEvent,
  transformation,
}) => {
  const { allowNewComments } = useContext(CommentsContext);

  if (asset._failed) {
    return <FailedAssetUploadActions onRemove={onRemove} />;
  }

  if (asset.archived || asset._uploading) {
    return null;
  }

  const hasComment = !!commentThreadId;
  const hasRendition = !!transformation;

  return (
    actions && (
      <div
        className={classNames('asset-thumbnail__actions-pane', {
          'asset-thumbnail__actions-pane--has-comment': hasComment,
          'asset-thumbnail__actions-pane--has-focused-comment': hasFocusedComment,
        })}
      >
        <div className="asset-thumbnail__actions-left">
          <DragAction connectDragSource={connectDragSource} disabled={isDragDisabled} />
          {isUncategorized && (
            <Box css="display: flex; align-items: center;" paddingLeft={Spacing.XS}>
              <AssetUncategorizedLabel />
            </Box>
          )}
        </div>
        <Inline align="center" spacing={Spacing.S} noWrap>
          {actions.includes(AssetTileAction.OpenRendition) && (
            <QuinaryButton
              onClick={onOpenRenditionDialog ? onOpenRenditionDialog : () => undefined}
              tooltipPlacement="bottom"
              tooltipText={openRenditionDialogDisabledMessage ?? 'Customize'}
              disabled={!!openRenditionDialogDisabledMessage}
              disableTabulator={disableTabulator}
              {...getDataUiActionAttribute(
                hasRendition
                  ? DataUiAssetRenditionAction.ChangeAssetRendition
                  : DataUiAssetRenditionAction.CreateAssetRendition,
              )}
            >
              <QuinaryButton.Icon icon={Icons.Crop} screenReaderText="Customize" />
            </QuinaryButton>
          )}

          {actions.includes(AssetTileAction.AddComment) && (allowNewComments || hasComment) && (
            <CommentButton
              commentThreadId={commentThreadId}
              hasFocusedComment={hasFocusedComment}
              onNewComment={onNewComment}
              disableTabulator={disableTabulator}
            />
          )}

          {actions.includes(AssetTileAction.Download) && (
            <AssetDownloadButton
              asset={asset}
              trackUserEvent={trackUserEvent}
              transformation={transformation}
              disableTabulator={disableTabulator}
            />
          )}

          {actions.includes(AssetTileAction.Delete) && !disabled && (
            <QuinaryButton
              tooltipText="Remove"
              tooltipPlacement="bottom"
              onClick={compose(onRemove || noOperation, stopPropagation)}
              aria-label="Remove"
              buttonStyle="destructive"
              disableTabulator={disableTabulator}
              {...getDataUiActionAttribute(DataUiAction.Delete)}
            >
              <QuinaryButton.Icon icon={Icons.Times} />
            </QuinaryButton>
          )}
        </Inline>
      </div>
    )
  );
};

AssetTileActions.displayName = 'AssetTileActions';
