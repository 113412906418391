import { Box } from '@kontent-ai/component-library/Box';
import { Checkbox } from '@kontent-ai/component-library/Checkbox';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import {
  BorderRadius,
  Spacing,
  Typography,
  colorTextDefault,
  colorWarningBackgroundInverse,
} from '@kontent-ai/component-library/tokens';
import classNames from 'classnames';
import React, { useState } from 'react';
import { ConnectDragSource } from 'react-dnd';
import { DragAction } from '../../../../../../_shared/components/DragDrop/DragAction.tsx';
import { ConfirmationDialog } from '../../../../../../_shared/components/ModalDialog/ConfirmationDialog.tsx';
import { IconName } from '../../../../../../_shared/constants/iconEnumGenerated.ts';
import { CodenameContentElementButton } from '../../../../../../_shared/containers/Codename/CodenameContentElementButton.tsx';
import { Icon } from '../../../../../../_shared/uiComponents/Icon/Icon.tsx';
import {
  DataUiAction,
  DataUiElement,
  DataUiInput,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
  getDataUiInputAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { isContentTypeUsed } from '../../../../../../data/models/contentModelsApp/contentTypes/ContentTypeUsage.ts';
import { ElementType } from '../../../../../contentInventory/content/models/ContentItemElementType.ts';
import { ContentTypeKind } from '../../../constants/contentTypeKind.ts';
import { IBaseTypeElementData } from '../../../models/elements/types/TypeElementData.ts';
import { TypeElementConfigurability } from '../../../models/elements/types/elementConfigurabilityOptions.ts';
import { ITypeElementOwnProps } from '../../../types/ITypeElementProps.type.ts';
import { getTypeElementSummaryComponent } from '../../../utils/getTypeElementSummary.tsx';
import { getUsageInfo } from '../../../utils/typeElementMessagesUtils.ts';
import { BaseTypeElementValidationResult } from '../../../utils/typeElementValidators/types/BaseTypeElementValidationResult.type.ts';
import { TypeElementConfigurationButton } from './TypeElementConfigurationButton.tsx';

export interface ITypeElementStateProps {
  readonly canBeSelected: boolean;
  readonly id: Uuid;
  readonly isDeleteButtonDisabled: boolean;
  readonly isFocused: boolean;
  readonly isSelected: boolean;
  readonly showCodename: boolean;
}

export interface ITypeElementDispatchProps {
  readonly firstIncompleteElementFocused: () => void;
  readonly onSelectedToggle: (elementId: Uuid, value: boolean) => void;
}

export interface ITypeElementExtendedOwnProps<TTypeElementData, TTypeElementValidationResult>
  extends ITypeElementOwnProps<TTypeElementData, TTypeElementValidationResult> {
  readonly className?: string;
  readonly generatesUrlSlug?: boolean;
}

interface ITypeElementDragProps {
  readonly connectDragSource?: ConnectDragSource;
  readonly isDragging: boolean;
}

export type ITypeElementProps = ITypeElementExtendedOwnProps<
  IBaseTypeElementData,
  BaseTypeElementValidationResult
> &
  ITypeElementStateProps &
  ITypeElementDispatchProps;

const deleteDialogMessageForAssetType = `You can’t take this action back. This element might be used in some assets.
  If you remove it, you’ll lose the contents of this element in each of those assets.`;

const getDeleteDialogMessageForItemTypes = (props: ITypeElementProps): string | undefined => {
  const {
    generatesUrlSlug,
    typeElementData: { type: elementType },
    numbersOfItemsUsedIn,
  } = props;

  if (!numbersOfItemsUsedIn) {
    return;
  }

  const { itemCount, contentComponentCount } = numbersOfItemsUsedIn;

  if (elementType === ElementType.Guidelines) {
    return `You can’t take this action back. This element is used in ${getUsageInfo(
      itemCount,
      contentComponentCount,
    )}. If you remove it, you’ll lose this element in each of those items.`;
  }
  if (itemCount > 0 && generatesUrlSlug) {
    return `You can’t take this action back. This element is used in ${getUsageInfo(itemCount, 0)}
      and to generate URL slug. Removing will cause loss of content in those items and
      you’ll need to select a new text element for URL slug.`;
  }
  if (itemCount > 0 || contentComponentCount > 0) {
    return `You can’t take this action back. This element is used in ${getUsageInfo(
      itemCount,
      contentComponentCount,
    )}.
      If you remove it, you’ll lose the contents of this element in each of those items.`;
  }
  if (generatesUrlSlug) {
    return `You can’t take this action back. This element is used to generate URL slug.
      If you remove this element you’ll need to select a new text element for URL slug.`;
  }
  return `You can’t take this action back. This element is not used in any content items,
      so you won’t lose any existing content.`;
};

const getDeleteBalloonMessage = (props: ITypeElementProps) => {
  if (props.cannotBeDeleted) {
    return props.deleteButtonTooltip;
  }

  if (props.isDeleteButtonDisabled) {
    return 'There is still some data loading, element cannot be deleted.';
  }

  return 'Remove element';
};

const getDeleteDialogTitle = (props: ITypeElementProps): string => {
  const { elementTypeName, typeElementData } = props;
  if (typeElementData.type === ElementType.Custom) {
    if (typeElementData.name) {
      return `Remove ${typeElementData.name} (${elementTypeName})?`;
    }
    return `Remove this ${elementTypeName}?`;
  }
  if (!typeElementData.name || typeElementData.type === ElementType.Guidelines) {
    return `Remove this ${elementTypeName} element?`;
  }
  return `Remove ${typeElementData.name} (${elementTypeName} element)?`;
};

export const TypeElement: React.FC<
  React.PropsWithChildren<ITypeElementProps & ITypeElementDragProps>
> = (props) => {
  const [showDialog, setShowDialog] = useState(false);

  const isForAssetType = props.contentTypeKind === ContentTypeKind.AssetType;
  const TypeElementSummary = getTypeElementSummaryComponent(props.typeElementData.type);
  const shouldShowDeleteDialog =
    isContentTypeUsed(props.numbersOfItemsUsedIn) || props.generatesUrlSlug || isForAssetType;
  const deleteIconOnClick = shouldShowDeleteDialog ? () => setShowDialog(true) : props.onRemove;
  const errorMessages = props.validationResult.errorMessages;
  const warningMessages = props.validationResult.warningMessages;
  const hasWarnings = !!(warningMessages && warningMessages.length > 0);
  const deleteDialogMessage = isForAssetType
    ? deleteDialogMessageForAssetType
    : getDeleteDialogMessageForItemTypes(props);
  const deleteBalloonMessage = getDeleteBalloonMessage(props);
  const isDeletionDisallowed = props.cannotBeDeleted || props.isDeleteButtonDisabled;

  return (
    <div
      className={classNames('content-element', {
        'content-element--dialog-pane-expanded': showDialog,
        'content-element--is-dragging': props.isDragging,
        'content-element--is-selected': props.isSelected,
      })}
      {...getDataUiElementAttribute(DataUiElement.ContentElement)}
    >
      <div className="content-element__action-bar">
        <DragAction connectDragSource={props.connectDragSource} />
        {props.canBeSelected && (
          <Checkbox
            aria-label="Select element"
            checkboxState="default"
            className="content-element__action-bar-checkbox"
            onToggle={() => {
              if (props.onSelectedToggle) {
                props.onSelectedToggle(props.typeElementData.elementId, !props.isSelected);
              }
            }}
            checked={props.isSelected}
            id={props.typeElementData.elementId}
            {...getDataUiInputAttribute(DataUiInput.TypeElementSelect)}
          />
        )}
        <div className="content-element__title">
          <div className="content-element__type">{props.elementTypeName}</div>
          {props.contentTypeKind !== ContentTypeKind.AssetType && (
            <TypeElementSummary
              typeElementData={props.typeElementData}
              isActive={props.isConfigDisplayed}
              onShowConfig={props.onShowConfig}
              onHideConfig={props.onHideConfig}
            />
          )}
        </div>
        <div className="content-element__actions-pane">
          {props.typeElementData._configurability !== TypeElementConfigurability.None &&
            props.onShowConfig &&
            props.onHideConfig && (
              <TypeElementConfigurationButton
                isActive={props.isConfigDisplayed}
                onClick={props.isConfigDisplayed ? props.onHideConfig : props.onShowConfig}
              />
            )}
          {props.showCodename && (
            <CodenameContentElementButton
              codename={props.typeElementData.codename}
              contentTypeKind={props.contentTypeKind}
              elementId={props.typeElementData.elementId}
            />
          )}
          <Tooltip text={deleteBalloonMessage} placement="top-end">
            <div
              className={
                isDeletionDisallowed
                  ? 'content-element__action content-element__action--is-disabled-delete'
                  : 'content-element__action content-element__action--is-delete'
              }
              onClick={isDeletionDisallowed ? undefined : deleteIconOnClick}
            >
              <Icon
                iconName={IconName.Bin}
                dataAttribute={getDataUiActionAttribute(DataUiAction.Delete)}
              />
            </div>
          </Tooltip>
        </div>
      </div>
      <div className="content-element__body">{props.children}</div>
      {errorMessages.length > 0 && (
        <div
          className={classNames('content-element__alert', {
            'content-element__alert--before-warning': hasWarnings,
          })}
          {...getDataUiElementAttribute(DataUiElement.NotificationAlert)}
        >
          {errorMessages.map((errorMessage, idx) => (
            <span key={idx} className="content-element-alert-message">
              {errorMessage}
            </span>
          ))}
        </div>
      )}
      {hasWarnings && (
        <Box
          backgroundColor={colorWarningBackgroundInverse}
          paddingX={Spacing.L}
          paddingY={Spacing.S}
          margin={Spacing.XL}
          marginTop={Spacing.None}
          borderRadius={BorderRadius.M}
          {...getDataUiElementAttribute(DataUiElement.ContentTypeElementValidationWarning)}
        >
          {warningMessages.map((warningMessage, idx) => (
            <Box
              key={idx}
              display="block"
              typography={Typography.LabelMedium}
              color={colorTextDefault}
            >
              {warningMessage}
            </Box>
          ))}
        </Box>
      )}
      {showDialog && (
        <ConfirmationDialog
          alert
          confirmButtonText="Remove"
          headerContent={getDeleteDialogTitle(props)}
          isOpen
          onConfirm={props.onRemove}
          onClose={() => setShowDialog(false)}
        >
          {deleteDialogMessage}
        </ConfirmationDialog>
      )}
    </div>
  );
};

TypeElement.displayName = 'TypeElement';
