import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import classNames from 'classnames';
import React from 'react';
import { TagColor } from '../../../data/constants/tagColor.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
  getDataUiObjectNameAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

export interface IMultipleOptionSelectPermanentOption<T> {
  readonly color: TagColor;
  readonly getOptionName: (option: T) => string;
  readonly getOptionTooltip?: (option: T) => string;
  readonly option: T;
}

export class MultipleOptionSelectPermanentOption<T> extends React.PureComponent<
  IMultipleOptionSelectPermanentOption<T>
> {
  static displayName = 'MultipleOptionSelectPermanentOption';

  static defaultProps: Pick<IMultipleOptionSelectPermanentOption<unknown>, 'color'> = {
    color: TagColor.LightBlue,
  };

  render() {
    const { getOptionName, getOptionTooltip, option } = this.props;

    const optionName = getOptionName(option);

    return (
      <Tooltip
        placement="bottom-start"
        text={getOptionTooltip?.(option)}
        {...getDataUiObjectNameAttribute(optionName)}
      >
        <div
          className={classNames(
            'multi-select__option',
            `multi-select__option--${this.props.color}`,
          )}
        >
          <div
            className="multi-select__option-name"
            data-hj-suppress=""
            {...getDataUiElementAttribute(DataUiElement.MultiSelectOptionName)}
          >
            {optionName}
          </div>
        </div>
      </Tooltip>
    );
  }
}
