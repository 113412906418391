import { isElement } from '@kontent-ai/DOM';
import { Direction } from '@kontent-ai/types';
import { Collection } from '@kontent-ai/utils';
import { EditorState } from 'draft-js';
import {
  getDomSelection,
  getSelectionDirection,
} from '../../../../../_shared/utils/selectionUtils.ts';
import {
  FirstBlockClassName,
  LastBlockClassName,
  TableCellClassName,
} from '../../../editorCore/utils/editorComponentUtils.ts';
import { BlockType } from '../../../utils/blocks/blockType.ts';
import {
  isCustomBlockSleeve,
  isInTable,
  isObjectBlock,
  isTableCell,
} from '../../../utils/blocks/blockTypeUtils.ts';
import { findBlockIndex } from '../../../utils/blocks/editorBlockUtils.ts';
import { getBlocksAtSelection } from '../../../utils/editorSelectionUtils.ts';
import { getBlocks } from '../../../utils/general/editorContentGetters.ts';

export const isFocusInCustomBlockSleeve = (editorState: EditorState): boolean => {
  const selection = editorState.getSelection();
  const content = editorState.getCurrentContent();
  const blocks = getBlocks(content);
  const selectionFocusIndex = findBlockIndex(blocks, selection.getFocusKey());
  return isCustomBlockSleeve(selectionFocusIndex, blocks);
};

export const isFocusAtStartOfTableContent = (editorState: EditorState): boolean => {
  const selection = editorState.getSelection();
  const content = editorState.getCurrentContent();
  const focusBlock = content.getBlockForKey(selection.getFocusKey());
  if (isInTable(focusBlock) && selection.getFocusOffset() === 0) {
    const previousBlock = content.getBlockBefore(focusBlock.getKey());
    return isTableCell(previousBlock) && previousBlock?.getDepth() === 0;
  }
  return false;
};

export const isFocusAtEndOfTableCell = (editorState: EditorState) => {
  const selection = editorState.getSelection();
  const content = editorState.getCurrentContent();
  const focusBlock = content.getBlockForKey(selection.getFocusKey());
  if (isInTable(focusBlock) && selection.getFocusOffset() === focusBlock.getLength()) {
    const nextBlock = content.getBlockAfter(focusBlock.getKey());
    const blocks = content.getBlocksAsArray();
    return (
      isTableCell(nextBlock) ||
      isCustomBlockSleeve(blocks.indexOf(focusBlock) + 1, blocks, BlockType.TableCell)
    );
  }
  return false;
};

export const isFocusAtStartOfTableCell = (editorState: EditorState) => {
  const selection = editorState.getSelection();
  const content = editorState.getCurrentContent();
  const focusBlock = content.getBlockForKey(selection.getFocusKey());
  if (isInTable(focusBlock) && selection.getFocusOffset() === 0) {
    const previousBlock = content.getBlockBefore(focusBlock.getKey());
    return isTableCell(previousBlock);
  }
  return false;
};

export const isFocusInTable = (editorState: EditorState) => {
  const selection = editorState.getSelection();
  const content = editorState.getCurrentContent();
  const focusBlock = content.getBlockForKey(selection.getFocusKey());
  return isInTable(focusBlock);
};

export const isSingleFocusableBlockSelected = (editorState: EditorState) => {
  const selection = editorState.getSelection();
  const content = editorState.getCurrentContent();

  const blocks = getBlocksAtSelection(content, selection);

  return (
    blocks.length === 3 &&
    isCustomBlockSleeve(0, blocks) &&
    isObjectBlock(blocks[1]) &&
    isCustomBlockSleeve(2, blocks)
  );
};

export const isFocusOnPerimeterLineInTableCell = (caretNavigationDirection: Direction): boolean => {
  // We detect perimeter (first / last) lines in table cell via DOM selection ranges
  // as otherwise we cannot tell where the line wraps occur in a continuous text
  const domSelection = getDomSelection();
  if (!domSelection?.range) {
    return false;
  }

  const range = domSelection.range.cloneRange();

  const isNavigatingBackward = caretNavigationDirection === Direction.Backward;
  const isSelectionUpward = range.collapsed
    ? isNavigatingBackward
    : getSelectionDirection(domSelection) === Direction.Backward;

  const node = domSelection?.focusNode?.parentNode;

  if (!isElement(node)) {
    return false;
  }

  const boundaryBlockElement = node.closest(
    `.${TableCellClassName} .${isNavigatingBackward ? FirstBlockClassName : LastBlockClassName}`,
  );

  range.collapse(isSelectionUpward);
  // Extra collapse for the sake of consistency, as the selection in Mac Chromium was acting differently than in Windows.
  domSelection.range.collapse(isSelectionUpward);
  if (!boundaryBlockElement) {
    return false;
  }

  const rects = [...range.getClientRects()];
  const boundaryBlockElementRect = boundaryBlockElement.getBoundingClientRect();
  if (rects.length === 0 && boundaryBlockElementRect.height === 0) {
    return false;
  }

  const computedStyle = window.getComputedStyle(boundaryBlockElement);
  const paddingTop = Number.parseFloat(computedStyle.paddingTop) || 0;
  const paddingBottom = Number.parseFloat(computedStyle.paddingBottom) || 0;
  let lineHeight = Number.parseFloat(computedStyle.lineHeight);
  if (Number.isNaN(lineHeight)) {
    // Default line height based on MDN Web Docs, https://developer.mozilla.org/en-US/docs/Web/CSS/line-height#values
    lineHeight = Number.parseFloat(computedStyle.fontSize) * 1.2;
  }

  // Compute the expected effective content area (excluding padding).
  const expectedTextTop = boundaryBlockElementRect.top + paddingTop;
  const expectedTextBottom = boundaryBlockElementRect.bottom - paddingBottom;

  // We place the threshold line to the estimated middle of the first/last line and detect whether
  // its edge is below or above it (based on the detected direction)
  const threshold = lineHeight * 0.5;

  // In case of caret in an empty block the rects may be empty because there is no text, just single BR
  // we fall back to focus node or the whole parent block instead
  const noRectsFallbackRect =
    domSelection.focusNode?.parentElement?.getBoundingClientRect() ?? boundaryBlockElementRect;

  if (isNavigatingBackward) {
    // Under normal circumstances there is only one rect representing the caret
    // But in case caret is at empty soft new line, the selection rects are at both at the end
    // of the previous line and start of the current line.
    // We skip all rects with zero width to only take the one representing the caret
    const eligibleRects = rects.filter((rect) => rect.width);
    const firstFocusRect = eligibleRects[0] ?? Collection.getLast(rects) ?? noRectsFallbackRect;

    return expectedTextTop + threshold >= firstFocusRect.top;
  }

  // In case of downward direction we don't need to handle the special case with empty line
  // and more rects, as the last rect is taken as the most significant anyway
  const lastFocusRect = Collection.getLast(rects) ?? noRectsFallbackRect;
  return expectedTextBottom - threshold <= lastFocusRect.bottom;
};
