import { InvariantException } from '@kontent-ai/errors';
import { memoize } from '@kontent-ai/memoization';
import { createCompare, naturally } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { ApiKeyType } from '../../../../_shared/models/ApiKeyType.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { IProjectDetails } from '../../../../data/models/projects/ProjectDetails.ts';
import { ApiStatus } from '../../../../data/reducers/apiKeys/ApiKeyStatus.type.ts';
import { CopyState } from '../../../projects/constants/copyState.ts';
import { AllEnvironmentsLabel } from '../constants/uiConstants.ts';

export type EnvironmentOption = {
  readonly id: Uuid;
  readonly label: string;
  readonly isApiEnabled?: boolean;
};

export const AllEnvironmentsTagId: Uuid = 'f49095f3-2cc3-4bcb-af16-da78849d7400';

export const allEnvironmentsTag: Omit<EnvironmentOption, 'isApiEnabled'> = {
  id: AllEnvironmentsTagId,
  label: AllEnvironmentsLabel,
};

export const getAllEnvironmentOptions = (state: IStore, apiKeyType: ApiKeyType) =>
  getAllEnvironmentOptionsMemoized(
    state.data.projects.byId,
    state.sharedApp.currentProjectContainerId,
    state.data.apiKeys.apiStatusPerEnvironment,
    apiKeyType,
  );

const isEnvironmentCopySuccessfullyCompleted = (environmentDetails: IProjectDetails): boolean => {
  return isCloned(environmentDetails) && environmentDetails?.copyState === CopyState.Done;
};

const isCloned = (environmentDetails: IProjectDetails): boolean => {
  return !!environmentDetails?.copyType;
};

const getAllEnvironmentOptionsMemoized = memoize.maxOne(
  (
    environments: Immutable.Map<Uuid, IProjectDetails>,
    projectContainerId: Uuid,
    apiStatus: ReadonlyMap<Uuid, ApiStatus>,
    apiKeyType: ApiKeyType,
  ): ReadonlyArray<EnvironmentOption> => {
    const environmentOptions = environments
      .filter(
        (environmentDetails) =>
          environmentDetails.projectContainerId === projectContainerId &&
          (!isCloned(environmentDetails) ||
            isEnvironmentCopySuccessfullyCompleted(environmentDetails)),
      )
      .map((projectDetails, environmentId): EnvironmentOption => {
        const environmentApiStatus = apiStatus.get(environmentId);

        return {
          id: environmentId,
          label: projectDetails.environmentName,
          isApiEnabled: environmentApiStatus && getIsApiEnabled(environmentApiStatus, apiKeyType),
        };
      })
      .sort(
        createCompare({
          compare: naturally,
          select: (option) => option.label,
        }),
      )
      .toArray();

    return [allEnvironmentsTag].concat(environmentOptions);
  },
);

const getIsApiEnabled = (apiStatus: ApiStatus, apiKeyType: ApiKeyType): boolean => {
  switch (apiKeyType) {
    case ApiKeyType.DAPI:
    case ApiKeyType.DAPIPrimary:
    case ApiKeyType.DAPISecondary:
      return apiStatus.isSecuredDeliveryApiEnabled;
    case ApiKeyType.MAPIPat:
    case ApiKeyType.MAPI:
      return apiStatus.isManagementApiEnabled;
    default:
      throw InvariantException('Unknown token type for API status.');
  }
};
