import { useIdWithPrefix } from '@kontent-ai/hooks';
import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { FullScreenModalDialog } from '../../../../../../component-library/components/Dialogs/ModalDialog/FullScreenModalDialog.tsx';
import {
  Column,
  DataTableHeadRow,
} from '../../../../../_shared/components/DataTable/DataTableHeadRow.tsx';
import {
  ItemColumnCode,
  translateColumnCodeToTitle,
} from '../../../../../_shared/constants/itemColumnCode.ts';
import { OrderByDirection } from '../../../../../_shared/models/OrderBy.ts';
import { stringifyContentItemId } from '../../../../../_shared/models/utils/contentItemIdUtils.ts';
import { DataUiCollection } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getContentItemPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { YourContentItem } from '../../../../../data/models/listingContentItems/YourContentItem.ts';
import { StyledDataTable } from '../../shared/components/StyledDataTable.tsx';
import { Widget } from '../../shared/components/Widget.tsx';
import { WidgetListingContentShowMoreButton } from '../../shared/components/WidgetListingContentShowMoreButton.tsx';
import { WidgetListingStateContent } from '../../shared/components/WidgetListingStateContent.tsx';
import {
  entityListDetailDisplayCount,
  entityListWidgetDisplayCount,
} from '../../shared/constants/widgetConstants.ts';
import { WidgetListingState } from '../../shared/types/WidgetListingState.type.ts';
import { RecentlyEditedByYouWidgetDetailContainer as RecentlyEditedByYouWidgetDetail } from '../containers/RecentlyEditedByYouWidgetDetailContainer.tsx';
import { RecentlyEditedByYouWidgetRow } from './RecentlyEditedByYouWidgetRow.tsx';
import { WidgetEmptyState } from './WidgetEmptyState.tsx';

type RecentlyEditedByYouWidgetProps = {
  readonly fetchMore: (count: number) => void;
  readonly isLivePreviewPreferred: boolean;
  readonly items: ReadonlyArray<YourContentItem>;
  readonly itemsState: WidgetListingState;
  readonly onDetailItemClick?: () => void;
  readonly onItemClick?: () => void;
  readonly onShowMoreClick?: () => void;
};

export const RecentlyEditedByYouWidget: React.FC<RecentlyEditedByYouWidgetProps> = ({
  fetchMore,
  isLivePreviewPreferred,
  items,
  itemsState,
  onDetailItemClick,
  onItemClick,
  onShowMoreClick,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const { pathname } = useLocation();

  const openDetail = () => {
    onShowMoreClick?.();
    fetchMore(entityListDetailDisplayCount);
    setIsDialogOpen(true);
  };

  const closeDetail = () => setIsDialogOpen(false);

  const widgetTitleId = useIdWithPrefix('recently-edited');

  return (
    <>
      <Widget>
        <Widget.Title text="Items recently edited by you" id={widgetTitleId} />
        <WidgetListingStateContent state={itemsState} renderEmptyState={() => <WidgetEmptyState />}>
          <Widget.Body>
            <StyledDataTable
              dataUiCollectionName={DataUiCollection.ItemsRecentlyEditedByYou}
              header={<DataTableHeadRow columns={tableHeadColumns} />}
              ariaLabelledBy={widgetTitleId}
            >
              {items.slice(0, entityListWidgetDisplayCount).map((item) => {
                const path = getContentItemPath(
                  pathname,
                  item.id.itemId,
                  isLivePreviewPreferred,
                  item.id.variantId,
                );

                return (
                  <RecentlyEditedByYouWidgetRow
                    key={stringifyContentItemId(item.id)}
                    onItemClick={onItemClick}
                    pathToItem={path}
                    recentlyEditedItem={item}
                    rowFocusLinkAriaLabel={`visit content item — ${item.name}`}
                  />
                );
              })}
            </StyledDataTable>
          </Widget.Body>

          <Widget.Footer>
            <Widget.CenterContent>
              <WidgetListingContentShowMoreButton
                onClick={openDetail}
                ariaDescribedBy={widgetTitleId}
              />
            </Widget.CenterContent>
          </Widget.Footer>
        </WidgetListingStateContent>
      </Widget>

      <FullScreenModalDialog
        headline="Items recently edited by you"
        isDismissable
        isOpen={isDialogOpen}
        onClose={closeDetail}
      >
        <RecentlyEditedByYouWidgetDetail
          isLivePreviewPreferred={isLivePreviewPreferred}
          onItemClick={onDetailItemClick}
          dataState={itemsState}
          recentlyEditedItems={items}
          ariaLabelledBy={widgetTitleId}
        />
      </FullScreenModalDialog>
    </>
  );
};

const tableHeadColumns: ReadonlyArray<Column> = [
  {
    columnName: translateColumnCodeToTitle(ItemColumnCode.Name),
    orderBy: OrderByDirection.None,
  },
  {
    columnName: translateColumnCodeToTitle(ItemColumnCode.WorkflowStep),
    orderBy: OrderByDirection.None,
  },
  {
    columnName: translateColumnCodeToTitle(ItemColumnCode.DueDate),
    orderBy: OrderByDirection.None,
    className: 'data-table__column--3',
  },
  {
    columnName: 'Your last edit',
    orderBy: OrderByDirection.Descending,
    className: 'data-table__column--4',
  },
];
