import { Icons } from '@kontent-ai/component-library/Icons';
import { MultiSelect } from '@kontent-ai/component-library/MultiSelect';
import { GeneralTag, IBaseSelectItem, ISelectSection } from '@kontent-ai/component-library/Selects';
import { SimpleStatusWarning } from '@kontent-ai/component-library/SimpleStatus';
import { Collection, alphabetically, createCompare } from '@kontent-ai/utils';
import { ComponentProps, useMemo } from 'react';
import { ElasticSearchUnavailableTaxonomyFilterMessage } from '../../../../../../../_shared/constants/uiConstants.ts';
import { useDataSelector } from '../../../../../../../_shared/hooks/useDataSelector.ts';
import { DataUiCollection } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  onTaxonomySelectionChange,
  toSelectSection,
} from '../../../../../../../_shared/utils/taxonomies/taxonomyUtils.ts';
import { useIsRefinedNavigationFeatureEnabled } from '../../../../../../refinedNavigation/contexts/RefinedNavigationContext.tsx';
import { ListingFilterCategory } from '../../../../../shared/components/filter/ListingFilterCategory.tsx';
import { createTaxonomyFilterItemId } from '../../../../../shared/utils/taxonomyFilterItemIdUtils.ts';
import { getTaxonomiesForItemFilterSelector } from '../../utils/taxonomyFilterSelectorUtils.ts';

type Props = {
  readonly displayWarningStatus: boolean;
  readonly onTaxonomySelectionChanged: (groupToTerms: ReadonlyMap<Uuid, ReadonlySet<Uuid>>) => void;
  readonly selectedTaxonomyNodes: ReadonlyMap<Uuid, ReadonlySet<Uuid>>;
};

const hasAnySelectableItem = (taxonomies: readonly ISelectSection<IBaseSelectItem>[]): boolean =>
  !!taxonomies.length && taxonomies.some((taxonomy) => taxonomy.items.length);

type GeneralTagProps = Pick<
  ComponentProps<typeof MultiSelect>,
  'generalTagThreshold' | 'renderGeneralTag'
>;

export const ItemsTaxonomyFilterSelector = (props: Props) => {
  const taxonomyGroupsById = useDataSelector((data) => data.taxonomyGroups.byId);
  const taxonomyGroups = useMemo(
    () => taxonomyGroupsById.valueSeq().toArray(),
    [taxonomyGroupsById],
  );

  const taxonomies = useMemo(
    () =>
      getTaxonomiesForItemFilterSelector(taxonomyGroups)
        .map(toSelectSection)
        .sort(createCompare({ compare: alphabetically, select: (item) => item.label })),
    [taxonomyGroups],
  );

  const selectedTaxonomyIds = useMemo(() => {
    return Collection.getEntries(props.selectedTaxonomyNodes).flatMap(([groupId, termIds]) =>
      [...termIds].map((termId) => createTaxonomyFilterItemId(groupId, termId)),
    );
  }, [props.selectedTaxonomyNodes]);

  const isRefinedNavigationFeatureEnabled = useIsRefinedNavigationFeatureEnabled();
  const refinedNavigationFeatureProps = isRefinedNavigationFeatureEnabled
    ? ({
        generalTagThreshold: 3,
        renderGeneralTag: (count, defaultTagProps) => (
          <GeneralTag
            countPlacement="before"
            countValue={count}
            label="Taxonomy terms"
            {...defaultTagProps}
          />
        ),
      } satisfies GeneralTagProps)
    : {};

  return hasAnySelectableItem(taxonomies) ? (
    <ListingFilterCategory
      title="Taxonomy"
      collectionName={DataUiCollection.Taxonomies}
      renderFilterCategoryStatus={() =>
        props.displayWarningStatus ? (
          <SimpleStatusWarning
            icon={Icons.ExclamationTriangle}
            label="Temporarily limited"
            tooltipText={ElasticSearchUnavailableTaxonomyFilterMessage}
            tooltipPlacement="top"
          />
        ) : null
      }
    >
      <MultiSelect
        placeholder={label}
        aria-label={label}
        items={taxonomies}
        selectedItemIds={selectedTaxonomyIds}
        onSelectionChange={(selectedIds) =>
          onTaxonomySelectionChange(selectedIds, props.onTaxonomySelectionChanged)
        }
        {...refinedNavigationFeatureProps}
      />
    </ListingFilterCategory>
  ) : null;
};

const label = 'Select a taxonomy term';
