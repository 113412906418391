import React from 'react';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { CustomContentTypeElement as CustomContentTypeElementComponent } from '../../shared/components/typeElements/individualTypeElements/CustomContentTypeElement.tsx';
import { ICustomTypeElementData } from '../../shared/models/elements/CustomTypeElementData.tsx';
import { ITypeElementOwnProps } from '../../shared/types/ITypeElementProps.type.ts';
import { CustomTypeElementValidationResult } from '../../shared/utils/typeElementValidators/types/CustomTypeElementValidationResult.type.ts';
import { getAllowedElements } from '../utils/customElementUtils.ts';

export const CustomContentTypeElement: React.FC<
  ITypeElementOwnProps<ICustomTypeElementData, CustomTypeElementValidationResult>
> = ({ typeElementData, ...props }) => {
  const allElements = useSelector((state) => {
    const {
      contentModelsApp: {
        typeEditor: { editedType },
      },
    } = state;

    return getAllowedElements(editedType.typeElements, typeElementData);
  });

  return (
    <CustomContentTypeElementComponent
      {...props}
      allElements={allElements}
      typeElementData={typeElementData}
    />
  );
};

CustomContentTypeElement.displayName = 'CustomContentTypeElementContainer';
