import { useEventListener } from '@kontent-ai/hooks';
import { RefObject } from 'react';

export const usePreventNativeContextMenu = <TTarget extends HTMLElement>({
  onContextMenu,
  ref,
}: {
  readonly ref: RefObject<TTarget>;
  readonly onContextMenu?: () => boolean;
}) => {
  useEventListener(
    'contextmenu',
    (e) => {
      if (e.ctrlKey) {
        return;
      }
      if (onContextMenu?.() !== false) {
        e.preventDefault();
      }
    },
    ref.current,
    true,
  );
};
