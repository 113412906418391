import { EditorState } from 'draft-js';
import React, { ClipboardEvent, useCallback } from 'react';
import { trackUserEvent } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { TrackUserEvent } from '../../../../../_shared/models/TrackUserEvent.type.ts';
import { ElementType } from '../../../../contentInventory/content/models/ContentItemElementType.ts';
import { loadRichTextReferences } from '../../../../itemEditor/features/LoadedItems/actions/thunkLoadedItemsActions.ts';
import { EmptyContentComponents } from '../../../../itemEditor/models/contentItem/ContentComponent.ts';
import {
  pasteContentToRichTextGuidelines,
  setRichTextClipboard,
} from '../../../../richText/actions/thunkRichTextActions.ts';
import {
  GuidelinesInput as GuidelinesInputComponent,
  GuidelinesInputProps,
} from '../../../../richText/editors/guidelines/GuidelinesInput.tsx';
import { PasteContentToRichTextParams } from '../../../../richText/plugins/clipboard/thunks/pasteContentToRichText.ts';
import { EmptyMetadata } from '../../../../richText/plugins/clipboard/thunks/setRichTextClipboard.ts';
import { TrackRTECommandUsed } from '../../../../richText/plugins/keyboardShortcuts/KeyboardShortcutsPlugin.tsx';

type IGuidelinesInputProps = Omit<
  GuidelinesInputProps,
  | 'contentItemCollectionId'
  | 'errorMessages'
  | 'focusableRef'
  | 'setRichTextClipboard'
  | 'trackRTECommandUsed'
  | 'trackUserEvent'
  | 'validationResult'
>;

export const GuidelinesInput: React.FC<IGuidelinesInputProps> = (props) => {
  const {
    element: { elementId, elementName },
  } = props;
  const dispatch = useDispatch();

  const onSetRichTextClipboard = useCallback(
    (e: ClipboardEvent, editorState: EditorState) =>
      dispatch(setRichTextClipboard(e, editorState, EmptyContentComponents, EmptyMetadata)),
    [],
  );
  const onPasteContent = useCallback(
    async (editorState: EditorState, params: PasteContentToRichTextParams) => {
      const result = dispatch(pasteContentToRichTextGuidelines(editorState, params));
      await dispatch(
        loadRichTextReferences([result.editorState.getCurrentContent()], EmptyContentComponents),
      );
      return result.editorState;
    },
    [],
  );

  const onTrackRTECommandUsed: TrackRTECommandUsed = useCallback(
    (commandInfo) =>
      dispatch(
        trackUserEvent(TrackedEvent.RTECommandUsed, {
          elementId,
          elementName,
          elementType: ElementType.Guidelines,
          ...commandInfo,
        }),
      ),
    [elementId, elementName],
  );
  const onTrackUserEventWithData: TrackUserEvent = useCallback(
    (...args) => dispatch(trackUserEvent(...args)),
    [],
  );

  return (
    <GuidelinesInputComponent
      {...props}
      setRichTextClipboard={onSetRichTextClipboard}
      onPasteContent={onPasteContent}
      trackRTECommandUsed={onTrackRTECommandUsed}
      trackUserEvent={onTrackUserEventWithData}
    />
  );
};

GuidelinesInput.displayName = 'GuidelinesInput';
