import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { ActiveCapabilityType } from '../../../../../../_shared/models/activeCapability.type.ts';
import { getWorkflow } from '../../../../../../_shared/selectors/workflowSelectors.ts';
import {
  isArchivedWorkflowStep,
  isPublishedWorkflowStep,
  isScheduledToPublishWorkflowStep,
} from '../../../../../../_shared/utils/contentItemUtils.ts';
import { getSelectableWorkflowStepsForEditedVariant } from '../../../../../contentInventory/content/features/ContentItemInventory/selectors/getSelectableWorkflowSteps.ts';
import { ChangeWorkflowStepButton } from './WorkflowStepActionButtons/ChangeWorkflowStepButton.tsx';
import { ChangeWorkflowStepOrPublishButton } from './WorkflowStepActionButtons/ChangeWorkflowStepOrPublishButton.tsx';
import { CreateNewVersionButton } from './WorkflowStepActionButtons/CreateNewVersionButton.tsx';
import { PublishButton } from './WorkflowStepActionButtons/PublishButton.tsx';
import { ReschedulePublishingButton } from './WorkflowStepActionButtons/ReschedulePublishingButton.tsx';
import { RestoreFromArchivedStepButton } from './WorkflowStepActionButtons/RestoreFromArchivedStepButton.tsx';

export const AssignmentSectionPrimaryAction = () => {
  const variantCapabilities = useSelector(
    (state) => state.contentApp.editorUi.activeCapabilities.variantCapabilities,
  );
  const editedContentItemVariant = useSelector(
    (state) => state.contentApp.editedContentItemVariant,
  );
  const selectableWorkflowSteps = useSelector((state) => {
    if (!state.contentApp.editedContentItemVariant) {
      return [];
    }
    const currentWorkflow = getWorkflow(
      state,
      state.contentApp.editedContentItemVariant.assignment.workflowStatus.workflowId,
    );
    return getSelectableWorkflowStepsForEditedVariant(
      state.contentApp.editedContentItemVariant,
      currentWorkflow ?? null,
    );
  });

  if (!editedContentItemVariant) {
    return null;
  }

  const canCreateNewVersion = variantCapabilities.includes(ActiveCapabilityType.CreateNewVersion);
  if (isPublishedWorkflowStep(editedContentItemVariant.assignment.workflowStatus)) {
    return canCreateNewVersion ? <CreateNewVersionButton /> : null;
  }

  // all the following need update assignment capability
  const canUpdateAssignment = variantCapabilities.includes(ActiveCapabilityType.UpdateAssignment);
  if (!canUpdateAssignment) {
    return null;
  }

  if (isScheduledToPublishWorkflowStep(editedContentItemVariant.assignment.workflowStatus)) {
    return <ReschedulePublishingButton />;
  }

  if (isArchivedWorkflowStep(editedContentItemVariant.assignment.workflowStatus)) {
    return <RestoreFromArchivedStepButton />;
  }

  const isPublishOrScheduleAvailable = selectableWorkflowSteps.find(
    (step) => isPublishedWorkflowStep(step) || isScheduledToPublishWorkflowStep(step),
  );

  if (!isPublishOrScheduleAvailable) {
    return <ChangeWorkflowStepButton />;
  }

  const isOtherThanPublishOrScheduleSelectable = selectableWorkflowSteps.find(
    (step) => !isPublishedWorkflowStep(step) && !isScheduledToPublishWorkflowStep(step),
  );

  return isOtherThanPublishOrScheduleSelectable ? (
    <ChangeWorkflowStepOrPublishButton />
  ) : (
    <PublishButton />
  );
};
