import classNames from 'classnames';
import React, { useRef } from 'react';
import { CreateNewBarItemHidingButton } from '../../../../_shared/components/BarItems/CreateNewBarItemHidingButton.tsx';
import { useScrollOnDragEvents } from '../../../../_shared/hooks/useScrollOnDragEvents.ts';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { isTaxonomyGroupFlat } from '../../../../_shared/utils/taxonomies/taxonomyUtils.ts';
import { ITaxonomyGroup } from '../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import { TaxonomyTerm } from '../containers/TaxonomyTerm.tsx';
import { TermCreator } from '../containers/termEditors/TermCreator.tsx';
import { ExpandCollapseAll } from './ExpandCollapseAll.tsx';

type Props = {
  readonly editedTaxonomyGroup: ITaxonomyGroup;
  readonly isCreateNewFormDisplayed: boolean;
  readonly isCreatingAllowed: boolean;
  readonly isDragging: boolean;
  readonly onAllTermsCollapsed: (taxonomyGroup: ITaxonomyGroup) => void;
  readonly onAllTermsExpanded: (taxonomyGroupId: Uuid) => void;
  readonly onCreateNewButtonClicked: () => void;
};

export const TaxonomyTermListing: React.FC<Props> = ({
  editedTaxonomyGroup,
  isCreateNewFormDisplayed,
  isCreatingAllowed,
  isDragging,
  onAllTermsCollapsed,
  onAllTermsExpanded,
  onCreateNewButtonClicked,
}) => {
  const dndScrollAreaRef = useRef<HTMLDivElement>(null);
  useScrollOnDragEvents(dndScrollAreaRef);

  const renderedTerms = editedTaxonomyGroup.childIds.map((termId) => (
    <TaxonomyTerm key={termId} nodeId={termId} />
  ));

  const expandCollapseAll = !isTaxonomyGroupFlat(editedTaxonomyGroup) && (
    <ExpandCollapseAll
      onCollapseAll={() => onAllTermsCollapsed(editedTaxonomyGroup)}
      onExpandAll={() => onAllTermsExpanded(editedTaxonomyGroup.id)}
    />
  );

  return (
    <div className="taxonomy" ref={dndScrollAreaRef}>
      <div className="taxonomy__list-title">Taxonomy terms</div>
      {expandCollapseAll}
      <div className="taxonomy__list">
        <ul
          className={classNames('bar-item__list', {
            'bar-item__list--is-dragging': isDragging,
          })}
          {...getDataUiCollectionAttribute(DataUiCollection.TaxonomyTerms)}
        >
          {renderedTerms}
          <CreateNewBarItemHidingButton
            isCreatingAllowed={isCreatingAllowed}
            isFormDisplayed={isCreateNewFormDisplayed}
            itemName="Taxonomy term"
            onCreateNewClick={onCreateNewButtonClicked}
          >
            <li className="bar-item__node">
              <TermCreator />
            </li>
          </CreateNewBarItemHidingButton>
        </ul>
      </div>
    </div>
  );
};

TaxonomyTermListing.displayName = 'TaxonomyTermListing';
