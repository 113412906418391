import { MultiSelect } from '@kontent-ai/component-library/MultiSelect';
import { GeneralTag } from '@kontent-ai/component-library/Selects';
import { Collection, alphabetically, createCompare } from '@kontent-ai/utils';
import { ComponentProps, useMemo } from 'react';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  onTaxonomySelectionChange,
  toSelectSection,
} from '../../../../../_shared/utils/taxonomies/taxonomyUtils.ts';
import { ITaxonomyGroup } from '../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import { useIsRefinedNavigationFeatureEnabled } from '../../../../refinedNavigation/contexts/RefinedNavigationContext.tsx';
import { createTaxonomyFilterItemId } from '../../utils/taxonomyFilterItemIdUtils.ts';

interface IProps {
  readonly taxonomyGroups: ReadonlyArray<ITaxonomyGroup>;
  readonly selectedTaxonomies: ReadonlyMap<Uuid, ReadonlySet<Uuid>>;
  readonly onFilterChanged: (selectedTermsByGroup: ReadonlyMap<Uuid, ReadonlySet<Uuid>>) => void;
}

const getNonEmptyTaxonomyGroups = (
  taxonomyGroups: ReadonlyArray<ITaxonomyGroup>,
): ReadonlyArray<ITaxonomyGroup> =>
  taxonomyGroups.filter((taxonomyGroup) => !!taxonomyGroup.childIds.length);

type GeneralTagProps = Pick<
  ComponentProps<typeof MultiSelect>,
  'generalTagThreshold' | 'renderGeneralTag'
>;

export const ConfigurableTaxonomyMultipleOptionSelect = ({
  taxonomyGroups,
  selectedTaxonomies,
  onFilterChanged,
}: IProps) => {
  const items = useMemo(
    () =>
      getNonEmptyTaxonomyGroups(taxonomyGroups)
        .map(toSelectSection)
        .sort(
          createCompare({
            compare: alphabetically,
            select: (item) => item.label,
          }),
        ),
    [taxonomyGroups],
  );

  const selectedTaxonomyIds = useMemo(() => {
    return Collection.getEntries(selectedTaxonomies).flatMap(([groupId, termIds]) =>
      [...termIds].map((termId) => createTaxonomyFilterItemId(groupId, termId)),
    );
  }, [selectedTaxonomies]);

  const isRefinedNavigationFeatureEnabled = useIsRefinedNavigationFeatureEnabled();
  const refinedNavigationFeatureProps = isRefinedNavigationFeatureEnabled
    ? ({
        generalTagThreshold: 3,
        renderGeneralTag: (count, defaultTagProps) => (
          <GeneralTag
            countPlacement="before"
            countValue={count}
            label="Taxonomies"
            {...defaultTagProps}
          />
        ),
      } satisfies GeneralTagProps)
    : {};

  return (
    <MultiSelect
      aria-label="Select a taxonomy term"
      placeholder="Select a taxonomy term"
      items={items}
      selectedItemIds={selectedTaxonomyIds}
      onSelectionChange={(selectedIds) => onTaxonomySelectionChange(selectedIds, onFilterChanged)}
      {...refinedNavigationFeatureProps}
      {...getDataUiCollectionAttribute(DataUiCollection.Taxonomies)}
    />
  );
};
