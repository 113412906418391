import Immutable from 'immutable';
import { forwardRef, memo, useCallback } from 'react';
import { ExpandCollapseAnimation } from '../../../../_shared/components/ExpandCollapseAnimation/ExpandCollapseAnimation.tsx';
import { HideOutsideViewport } from '../../../../_shared/components/HideOutsideViewport.tsx';
import { ScrollTableCellSkeleton } from '../../../../_shared/uiComponents/ScrollTable/ScrollTableCellSkeleton.tsx';
import { ScrollTableRow } from '../../../../_shared/uiComponents/ScrollTable/ScrollTableRow.tsx';
import {
  IContentType,
  emptyContentType,
} from '../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IListingContentItem } from '../../../../data/models/listingContentItems/IListingContentItem.ts';
import { RelatedItemDoesNotExistReason } from '../../../itemEditor/constants/errorMessages.ts';
import { getContentItemScrollTableRowHeightPx } from '../../content/constants/uiConstants.ts';
import { IExpandedNodeData } from '../reducers/expandedNodesData.ts';
import { getRelationsNodeId } from '../utils/relationsUtils.ts';
import { RelationsScrollTableOmittedRow } from './RelationsScrollTableOmittedRow.tsx';
import { RelationsScrollTableRedactedRow } from './RelationsScrollTableRedactedRow.tsx';
import { RelationsScrollTableRow } from './RelationsScrollTableRow.tsx';

type Props = {
  readonly contentItems: Immutable.Map<Uuid, IListingContentItem>;
  readonly contentTypes: Immutable.Map<Uuid, IContentType>;
  readonly depth: number;
  readonly expandedNodesData: Immutable.Map<string, IExpandedNodeData>;
  readonly getCannotOpenReasonForItem: (itemId: Uuid) => string | undefined;
  readonly getCannotViewMessageForItem: (itemId: Uuid) => string | undefined;
  readonly getLinkForItem: (itemId: Uuid) => string;
  readonly itemId: Uuid;
  readonly nodesBeingProcessed: Immutable.Set<string>;
  readonly onNodeCollapsed: (nodeId: string) => void;
  readonly onNodeExpanded: (nodeId: string, itemId: Uuid) => void;
  readonly parentId?: string;
};

export const RelationsScrollTableRowWithChildren = memo(
  forwardRef<HTMLDivElement, Props>(({ itemId, parentId, depth, ...generalProps }, ref) => {
    const {
      contentItems,
      contentTypes,
      expandedNodesData,
      getCannotOpenReasonForItem,
      getCannotViewMessageForItem,
      getLinkForItem,
      nodesBeingProcessed,
      onNodeCollapsed,
      onNodeExpanded,
    } = generalProps;

    const nodeId = getRelationsNodeId(itemId, parentId, depth);

    const isExpanded = expandedNodesData.has(nodeId);
    const isLoading = nodesBeingProcessed.has(nodeId);

    const handleToggleExpanded = useCallback(
      () => (isExpanded ? onNodeCollapsed(nodeId) : onNodeExpanded(nodeId, itemId)),
      [isExpanded, nodeId, itemId, onNodeExpanded, onNodeCollapsed],
    );

    const item = contentItems.get(itemId);
    if (!item) {
      return (
        <RelationsScrollTableOmittedRow
          depth={depth}
          objectName={itemId}
          ref={ref}
          text="Linked content item doesn’t exist"
          title={RelatedItemDoesNotExistReason}
        />
      );
    }

    if (item.item?.archived) {
      return (
        <RelationsScrollTableOmittedRow
          depth={depth}
          objectName={item.item.name}
          ref={ref}
          text="This content item has been deleted"
        />
      );
    }

    const cannotViewMessage = getCannotViewMessageForItem(itemId);
    if (cannotViewMessage) {
      return (
        <RelationsScrollTableRedactedRow
          depth={depth}
          item={item}
          ref={ref}
          tooltip={cannotViewMessage}
        />
      );
    }

    const cannotOpenMessage = getCannotOpenReasonForItem(itemId);
    const itemType = contentTypes.get(item.item.typeId) ?? emptyContentType;

    const childrenIds = item.variant?.modularItemIds;
    const hasChildren = !!childrenIds?.length;

    return (
      <>
        <RelationsScrollTableRow
          depth={depth}
          disabledClickMessage={cannotOpenMessage}
          expanded={isExpanded}
          hasChildren={hasChildren}
          item={item}
          itemLink={getLinkForItem(itemId)}
          itemType={itemType}
          loading={isLoading}
          onToggleExpanded={handleToggleExpanded}
          ref={ref}
        />
        {hasChildren && !isLoading && (
          <ExpandCollapseAnimation
            estimatedMaxHeightWhenExpanded={
              getContentItemScrollTableRowHeightPx() * (childrenIds?.length || 0)
            }
            heightWhenCollapsed={0}
            renderCollapsed={() => null}
            renderExpanded={() =>
              childrenIds.map((childId) => (
                <LazyRelationsScrollTableRowWithChildren
                  key={childId}
                  {...generalProps}
                  depth={depth + 1}
                  hideOutsideViewport={(childrenIds?.length ?? 0) > 10}
                  itemId={childId}
                  parentId={nodeId}
                />
              ))
            }
            shouldBeExpanded={isExpanded || depth === 0}
          />
        )}
      </>
    );
  }),
);

RelationsScrollTableRowWithChildren.displayName = 'RelationsScrollTableRowWithChildren';

type LazyRelationsScrollTableRowWithChildrenProps = Omit<Props, 'onExpandedChanged'> & {
  readonly hideOutsideViewport: boolean;
};

export const LazyRelationsScrollTableRowWithChildren = memo(
  ({ hideOutsideViewport, ...otherProps }: LazyRelationsScrollTableRowWithChildrenProps) => {
    const { depth, expandedNodesData, itemId, parentId } = otherProps;

    const nodeId = getRelationsNodeId(itemId, parentId, depth);
    const isExpanded = expandedNodesData.has(nodeId);

    if (!hideOutsideViewport) {
      return <RelationsScrollTableRowWithChildren {...otherProps} />;
    }

    return (
      <HideOutsideViewport
        alwaysVisible={isExpanded}
        renderVisible={(ref) => <RelationsScrollTableRowWithChildren ref={ref} {...otherProps} />}
        renderHidden={(ref) => (
          <ScrollTableRow objectName={otherProps.itemId} isSkeleton ref={ref}>
            {Array(otherProps.depth + 1)
              .fill(null)
              .map((_val, index) => (
                <ScrollTableCellSkeleton key={index} isEmpty size={1} />
              ))}
            <ScrollTableCellSkeleton size={15} />
            <ScrollTableCellSkeleton size={5} />
            <ScrollTableCellSkeleton size={5} />
            <ScrollTableCellSkeleton size={5} />
          </ScrollTableRow>
        )}
        rootMargin="200px 0px 200px 0px"
      />
    );
  },
);

LazyRelationsScrollTableRowWithChildren.displayName = 'LazyRelationsScrollTableRowWithChildren';
