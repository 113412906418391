import { Dispatch, GetState, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { ILivePreviewConfiguration } from '../../../../../data/reducers/projectEnvironmentSettings/ILivePreviewConfiguration.type.ts';
import { IProjectEnvironmentSettingsRepository } from '../../../../../repositories/interfaces/IProjectEnvironmentSettingsRepository.type.ts';
import { Environment_SetLivePreviewConfiguration_Finished } from '../../constants/generalSettingsActionTypes.ts';
import { convertToLivePreviewConfigurationDomainModel } from '../../utils/convertToLivePreviewConfigurationDomainModel.ts';

interface IDeps {
  readonly projectEnvironmentSettingsRepository: IProjectEnvironmentSettingsRepository;
}

const finished = (livePreviewConfiguration: ILivePreviewConfiguration) =>
  ({
    type: Environment_SetLivePreviewConfiguration_Finished,
    payload: {
      livePreviewConfiguration,
    },
  }) as const;

export type SetLivePreviewConfigurationActionsType = ReturnType<typeof finished>;

export const setLivePreviewConfigurationCreator =
  (deps: IDeps) =>
  (enableLivePreview: boolean): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState) => {
    const {
      sharedApp: { currentProjectId },
    } = getState();

    const updatedLivePreviewConfiguration =
      await deps.projectEnvironmentSettingsRepository.setLivePreviewConfiguration(
        currentProjectId,
        {
          status: enableLivePreview ? 'enabled' : 'disabled',
        },
      );
    dispatch(
      finished(convertToLivePreviewConfigurationDomainModel(updatedLivePreviewConfiguration)),
    );
  };
