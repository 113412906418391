import { getAbsoluteTopOffset, isElement } from '@kontent-ai/DOM';
import { useAttachRef, useEventListener } from '@kontent-ai/hooks';
import {
  assert,
  Collection,
  ICancellablePromise,
  areShallowEqual,
  delay,
  notNull,
  swallowCancelledPromiseError,
} from '@kontent-ai/utils';
import React, { useMemo, useRef, useState, useLayoutEffect, RefObject, useCallback } from 'react';
import { flushSync } from 'react-dom';
import { useDebouncedCallback } from 'use-debounce';
import { waitUntilFocusAndScrollAreNotDeferred } from '../../../../../../_shared/utils/autoScrollUtils.ts';
import {
  getDeepestCollapsedComponentPosition,
  getDeepestUnselectedContentGroupPositionInComponent,
} from '../../../../../richText/plugins/contentComponents/utils/contentComponentRenderingUtils.ts';
import {
  CommentThreadWithLocation,
  CommentThreadWithNavigation,
  isThreadResolvedWithoutUndo,
} from '../../../../utils/commentUtils.ts';
import { GetCommentThreadPosition } from '../../utils/inlineCommentUtils.ts';
import { getThreadHeight } from './CommentThread.tsx';
import { CommentThreadPositionerClassName } from './CommentThreadPositioner.tsx';
import {
  InlineCommentPaneView,
  InlineCommentPaneViewClassName,
  RegisterThreadElement,
} from './InlineCommentPaneView.tsx';

// Keep in sync with @comment-animation-duration in comment-thread.less
const CommentAnimationDuration = 300;

// When waiting for comment animations, we give it a minor buffer to make sure animation is finished
const CommentAnimationBufferDuration = 100;
const CommentPositionTransitionDuration = CommentAnimationDuration + CommentAnimationBufferDuration;

const PositionMaintenanceInterval = 500;
const WindowResizeAdjustInterval = 300;

const DefaultCommentSpace = 24;

export type ThreadOffsets = ReadonlyMap<Uuid, ThreadOffset>;
type ThreadElements = ReadonlyMap<Uuid, HTMLDivElement>;

function keepSpaceAfterRemovedComments(
  threadOffsets: ThreadOffsets,
  oldThreads: ReadonlyArray<CommentThreadWithLocation>,
  threads: ReadonlyArray<CommentThreadWithLocation>,
): ThreadOffsets {
  // Detect removed comments and move their added size to the next comment offset
  const compensateForRemovedThread = (
    updatedThreadOffsets: ReadonlyMap<Uuid, ThreadOffset>,
    oldThread: CommentThreadWithLocation,
    oldIndex: number,
  ): ThreadOffsets => {
    const oldThreadId = oldThread.commentThread.id;
    const isRemoved =
      threads.findIndex(
        (thread: CommentThreadWithLocation) => thread.commentThread.id === oldThreadId,
      ) < 0;
    if (!isRemoved) {
      return updatedThreadOffsets;
    }

    // Remove known offset to make sure the comment doesn't have a known position when re-created to ensure proper animation (e.g. after resolve + unresolve)
    const withRemovedThreadOffset = Collection.remove(updatedThreadOffsets, oldThreadId);

    // When removed, adjust offset of the next thread, if available
    const nextThread = oldThreads[oldIndex + 1];
    if (!nextThread) {
      return withRemovedThreadOffset;
    }

    const nextThreadId = nextThread.commentThread.id;

    const oldThreadOffset = updatedThreadOffsets.get(oldThreadId);
    const spaceTakenByOldThread =
      (oldThreadOffset ? oldThreadOffset.relativeOffset + oldThreadOffset.height : 0) +
      DefaultCommentSpace;

    return Collection.replaceWith(withRemovedThreadOffset, nextThreadId, (nextThreadOffset) => ({
      ...nextThreadOffset,
      relativeOffset: spaceTakenByOldThread + nextThreadOffset.relativeOffset,
    }));
  };

  const newThreadOffsets = oldThreads.reduce(compensateForRemovedThread, threadOffsets);

  return newThreadOffsets;
}

function getUpdatedOffsets(
  threadOffsets: ThreadOffsets,
  newThreadOffsets: ReadonlyArray<ThreadOffset>,
): ThreadOffsets {
  let changed = false;

  const updatedOffsets = newThreadOffsets.reduce(
    (aggregated: ThreadOffsets, threadOffset: ThreadOffset) => {
      const existing = aggregated.get(threadOffset.threadId);
      if (!existing || !areShallowEqual(threadOffset, existing)) {
        changed = true;
        return Collection.add(aggregated, [threadOffset.threadId, threadOffset]);
      }

      return aggregated;
    },
    threadOffsets,
  );

  return changed ? updatedOffsets : threadOffsets;
}

function getNewFocusedCommentThread(
  lastFocusedThreadId: Uuid | null,
  focusedThreadId: Uuid | null,
  threads: ReadonlyArray<CommentThreadWithLocation>,
): Uuid | null {
  // Keep last focused thread to hold the scrolling position when everything gets unfocused
  if (!focusedThreadId || lastFocusedThreadId === focusedThreadId) {
    return lastFocusedThreadId;
  }

  const isFocusedCommentThreadUnresolved = threads.some(
    (thread: CommentThreadWithLocation) =>
      !isThreadResolvedWithoutUndo(thread.commentThread) &&
      thread.commentThread.id === focusedThreadId,
  );
  if (isFocusedCommentThreadUnresolved) {
    // Adopt a new focused thread if it is being displayed to update the scrolling position to the new one
    return focusedThreadId;
  }

  // Keep the last focused comment thread if some totally unrelated thread or resolved thread got the focus to keep the scrolling position
  return lastFocusedThreadId;
}

function getCollapsedSegmentTopOffset(thread: CommentThreadWithLocation): number | null {
  if (!thread.componentPath) {
    return null;
  }

  // If comment is inside a collapsed component, its position defaults to that component (the closest content point to which the comment belongs)
  const collapsedComponentPosition = getDeepestCollapsedComponentPosition(thread.componentPath);
  if (collapsedComponentPosition !== null) {
    return collapsedComponentPosition;
  }

  // Alternatively, if comment is inside an expanded component but in a different content group than is currently selected, it also positions by default to that component
  const unselectedContentGroupInComponentPosition =
    getDeepestUnselectedContentGroupPositionInComponent(thread.componentPath);

  return unselectedContentGroupInComponentPosition;
}

export type ThreadOffset = {
  readonly relativeOffset: number;
  readonly height: number;
  readonly threadId: Uuid;
};

type OffsetCalculationResult = {
  focusedThreadId: Uuid | null;
  relativeOffsets: ReadonlyArray<ThreadOffset>;
  totalSpaceTaken: number;
};

type Props = {
  readonly allowKeyboardNavigation: boolean;
  readonly focusedCommentThreadId: Uuid | null;
  readonly getCommentThreadPosition: GetCommentThreadPosition;
  readonly preferredContainerRef?: React.RefObject<HTMLElement>;
  readonly scrollContainerRef?: React.RefObject<HTMLDivElement>;
  readonly threadListRef?: React.RefObject<HTMLDivElement>;
  readonly threads: ReadonlyArray<CommentThreadWithNavigation>;
};

export const InlineCommentPane = ({
  allowKeyboardNavigation,
  getCommentThreadPosition,
  threads,
  threadListRef,
  focusedCommentThreadId,
  scrollContainerRef,
}: Props) => {
  const threadElements = useRef<Map<Uuid, HTMLDivElement>>(new Map());
  const { refObject, refToForward } = useAttachRef(threadListRef);

  const positionHandlerDelay = useRef<ICancellablePromise | null>(null);
  const allowDeferredPositionUpdate = useRef(true);

  const [allowAnimation, setAllowAnimation] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [lastFocusedThreadId, setLastFocusedThreadId] = useState(focusedCommentThreadId);
  const [totalSpaceTaken, setTotalSpaceTaken] = useState<number | null>(null);

  const [relativeThreadOffsets, setRelativeThreadOffsets] = useState<
    ReadonlyMap<Uuid, ThreadOffset>
  >(new Map());
  const lastRelativeThreadOffsets = useRef(relativeThreadOffsets);

  const debouncedResetPendingAnimation = useDebouncedCallback(
    () => setIsAnimating(false),
    CommentPositionTransitionDuration,
  );
  const handlePendingAnimation = useCallback(() => {
    // We need to immediately propagate the pending flag so that scrolling depending on this is properly deferred
    flushSync(() => {
      setIsAnimating(true);
    });
    debouncedResetPendingAnimation();
  }, [debouncedResetPendingAnimation]);

  const updateRelativeThreadOffsets = useCallback(
    (updater: (prev: ReadonlyMap<Uuid, ThreadOffset>) => ReadonlyMap<Uuid, ThreadOffset>): void => {
      setRelativeThreadOffsets((prev) => {
        const newRelativeThreadOffsets = updater(prev);
        lastRelativeThreadOffsets.current = newRelativeThreadOffsets;
        return newRelativeThreadOffsets;
      });
    },
    [],
  );

  const unstableUpdateThreadOffsets = useCallback(
    (animate: boolean = true): void => {
      const newOffsetCalculation = calculateThreadOffsets(
        threads,
        focusedCommentThreadId,
        lastRelativeThreadOffsets.current,
        getCommentThreadPosition,
        threadElements.current,
        refObject,
        scrollContainerRef,
      );
      if (!newOffsetCalculation) {
        return;
      }

      setAllowAnimation(animate);
      setLastFocusedThreadId(newOffsetCalculation.focusedThreadId);
      updateRelativeThreadOffsets((prev) =>
        getUpdatedOffsets(prev, newOffsetCalculation.relativeOffsets),
      );
      setTotalSpaceTaken(newOffsetCalculation.totalSpaceTaken);
    },
    [
      getCommentThreadPosition,
      focusedCommentThreadId,
      refObject,
      scrollContainerRef,
      threads,
      updateRelativeThreadOffsets,
    ],
  );

  // We wrap unstableUpdateThreadOffsets in a ref to get a stable reference to it
  // that we can use in the scheduling code, otherwise its scheduling may be prematurely cancelled
  const updateThreadOffsets = useRef(unstableUpdateThreadOffsets);
  useLayoutEffect(() => {
    updateThreadOffsets.current = unstableUpdateThreadOffsets;
  }, [unstableUpdateThreadOffsets]);

  const deferredUpdateThreadOffsets = useMemo(
    () =>
      waitUntilFocusAndScrollAreNotDeferred(
        (animate: boolean = true): void => updateThreadOffsets.current(animate),
        // Allow the comment positioning to happen, even that not positioned comments are deferring the scroll
        [CommentThreadPositionerClassName, InlineCommentPaneViewClassName],
      ),
    [],
  );

  const clearPositionHandler = useCallback(() => {
    if (positionHandlerDelay.current) {
      positionHandlerDelay.current.cancel();
      positionHandlerDelay.current = null;
    }
  }, []);

  // When other position triggers are inactive, sync the position regularly to adapt to external changes such as (un)collapsing component etc.
  const scheduleMaintenance = useCallback(() => {
    schedulePositionHandler(deferredUpdateThreadOffsets, PositionMaintenanceInterval);
  }, [deferredUpdateThreadOffsets]);

  const schedulePositionHandler = useCallback(
    (action: () => void, ms: number) => {
      clearPositionHandler();
      positionHandlerDelay.current = delay(ms)
        .then(() => {
          positionHandlerDelay.current = null;
          action();
          scheduleMaintenance();
        })
        .catch(swallowCancelledPromiseError);
    },
    [clearPositionHandler, scheduleMaintenance],
  );

  const deferredUpdatePosition = useMemo(
    () =>
      waitUntilFocusAndScrollAreNotDeferred(
        (animate: boolean = true): void => {
          if (allowDeferredPositionUpdate.current) {
            updateThreadOffsets.current(animate);
            if (animate) {
              handlePendingAnimation();
            }
            scheduleMaintenance();
          }
        },
        // Allow the comment positioning to happen, even that not positioned comments are deferring the scroll
        [CommentThreadPositionerClassName, InlineCommentPaneViewClassName],
      ),
    [scheduleMaintenance, handlePendingAnimation],
  );

  const debouncedPositionOnWindowResize = useDebouncedCallback(
    deferredUpdatePosition,
    WindowResizeAdjustInterval,
  );

  useEventListener(
    'resize',
    () => {
      debouncedPositionOnWindowResize();
    },
    self,
  );

  const onThreadResized = useCallback(
    (resizedThreadId: Uuid, newHeight: number): void => {
      // We need to compensate (without animation) for expanding / shrinking threads (mostly due to focus changes)
      // in order to prevent unwanted extra animated movement after some thread within the positioning chain is resized
      const oldHeight = lastRelativeThreadOffsets.current.get(resizedThreadId)?.height;
      if (!oldHeight) return;

      const sizeChange = newHeight - oldHeight;

      const { newThreadOffsets, newTotalSpaceTaken } = updateThreadOffsetsForResizedThread(
        lastRelativeThreadOffsets.current,
        resizedThreadId,
        sizeChange,
        threads,
        getCommentThreadPosition,
        threadElements.current,
        scrollContainerRef,
      );

      // We need to apply the resize changes immediately, because when switching between focus
      // of two comments, the handler is triggered multiple times and follow-up recalculation needs
      // to start with the previous resize already applied
      flushSync(() => {
        setAllowAnimation(false);
        updateRelativeThreadOffsets((prev) => getUpdatedOffsets(prev, newThreadOffsets));
        setTotalSpaceTaken(newTotalSpaceTaken);
      });
    },
    [getCommentThreadPosition, scrollContainerRef, threads, updateRelativeThreadOffsets],
  );

  const registerThreadElement: RegisterThreadElement = useCallback((threadId, element) => {
    if (element) {
      threadElements.current.set(threadId, element);
    } else {
      threadElements.current.delete(threadId);
    }
  }, []);

  const previousThreads = useRef<ReadonlyArray<CommentThreadWithLocation>>(threads);

  useLayoutEffect(() => {
    const newLastFocusedThreadId = getNewFocusedCommentThread(
      lastFocusedThreadId,
      focusedCommentThreadId,
      threads,
    );

    if (threads === previousThreads.current && newLastFocusedThreadId !== lastFocusedThreadId) {
      setLastFocusedThreadId(newLastFocusedThreadId);
      deferredUpdatePosition();
    }
  }, [deferredUpdatePosition, focusedCommentThreadId, lastFocusedThreadId, threads]);

  useLayoutEffect(() => {
    const threadsAdded = threads.length > previousThreads.current.length;
    const threadsRemoved = threads.length < previousThreads.current.length;

    if (threadsAdded) {
      // If either old or new comments are empty, do not allow animation
      // this reduces the time to display / hide in case only active comments are displayed
      const newAllowAnimation = !!previousThreads.current.length && !!threads.length;

      clearPositionHandler();

      const newOffsetCalculation = calculateThreadOffsets(
        threads,
        focusedCommentThreadId,
        lastRelativeThreadOffsets.current,
        getCommentThreadPosition,
        threadElements.current,
        refObject,
        scrollContainerRef,
      );

      if (newOffsetCalculation) {
        const offsetsWithNewComments: ReadonlyArray<ThreadOffset> =
          newOffsetCalculation?.relativeOffsets ?? [];

        // Render with space for new comments and let it animate making space for a new comment
        const offsetsWithoutNewComments = getOffsetsWithoutNewComments(
          previousThreads.current,
          offsetsWithNewComments,
          threadElements.current,
        );

        allowDeferredPositionUpdate.current = false;
        updateRelativeThreadOffsets((prev) => getUpdatedOffsets(prev, offsetsWithoutNewComments));
        setAllowAnimation(true);
        setTotalSpaceTaken(newOffsetCalculation.totalSpaceTaken);
        setLastFocusedThreadId(newOffsetCalculation.focusedThreadId);

        // After the space is made and everything repositioned, re-render without animation and all comments displayed at their positions
        schedulePositionHandler(
          () => {
            setAllowAnimation(false);
            allowDeferredPositionUpdate.current = true;
            updateRelativeThreadOffsets((prev) => getUpdatedOffsets(prev, offsetsWithNewComments));
          },
          newAllowAnimation ? CommentPositionTransitionDuration : 0,
        );
      }
    } else if (threadsRemoved) {
      // Some threads might have been removed, we keep space for them so that animation to the updated state can occur
      const newThreadOffsets = keepSpaceAfterRemovedComments(
        lastRelativeThreadOffsets.current,
        previousThreads.current,
        threads,
      );

      setAllowAnimation(false);

      const newLastFocusedThreadId = getNewFocusedCommentThread(
        lastFocusedThreadId,
        focusedCommentThreadId,
        threads,
      );
      setLastFocusedThreadId(newLastFocusedThreadId);
      updateRelativeThreadOffsets(() => newThreadOffsets);

      deferredUpdatePosition();
    } else if (!commentIdsMatch(threads, previousThreads.current)) {
      // Comments were completely exchanged for another set of comments
      // this happens when we only display the highlighted comment
      deferredUpdatePosition(false);
    }

    previousThreads.current = threads;
  }, [
    clearPositionHandler,
    deferredUpdatePosition,
    focusedCommentThreadId,
    getCommentThreadPosition,
    lastFocusedThreadId,
    refObject,
    schedulePositionHandler,
    scrollContainerRef,
    threads,
    updateRelativeThreadOffsets,
  ]);

  useLayoutEffect(() => {
    // Initial positioning on mount
    deferredUpdatePosition(false);

    // Clear deferred callbacks on unmount
    return () => {
      deferredUpdatePosition.cancel();
      deferredUpdateThreadOffsets.cancel();
      clearPositionHandler();
    };
  }, [deferredUpdatePosition, deferredUpdateThreadOffsets, clearPositionHandler]);

  return (
    <InlineCommentPaneView
      allowAnimation={allowAnimation}
      allowKeyboardNavigation={allowKeyboardNavigation}
      isAnimating={isAnimating}
      registerThreadElement={registerThreadElement}
      minHeight={totalSpaceTaken ?? undefined}
      onThreadResized={onThreadResized}
      ref={refToForward}
      relativeThreadOffsets={relativeThreadOffsets}
      threads={threads}
    />
  );
};

const getCommentThreadLastKnownTopOffset = (
  listTopOffset: number,
  totalSpaceTaken: number,
  knownThreadOffsets: ReadonlyMap<Uuid, ThreadOffset>,
  threadId: string,
): number => {
  const lastKnownTopOffset =
    listTopOffset +
    totalSpaceTaken +
    (knownThreadOffsets.get(threadId) || { relativeOffset: 0 }).relativeOffset;

  return lastKnownTopOffset;
};

const getThreadListTopOffset = (
  threadListRef: RefObject<HTMLDivElement>,
  scrollContainerRef: RefObject<HTMLDivElement> | undefined,
): number | null => {
  const list = threadListRef.current;
  if (!isElement(list)) return null;

  const listTopOffset = getAbsoluteTopOffset(list, scrollContainerRef?.current);
  return listTopOffset ? Math.round(listTopOffset) : null;
};

const calculateThreadOffsets = (
  threads: ReadonlyArray<CommentThreadWithLocation>,
  lastFocusedThreadId: Uuid | null,
  knownThreadOffsets: ThreadOffsets,
  getCommentThreadPosition: GetCommentThreadPosition,
  threadElements: ThreadElements,
  threadListRef: RefObject<HTMLDivElement>,
  scrollContainerRef: RefObject<HTMLDivElement> | undefined,
): OffsetCalculationResult | null => {
  let focusedThreadId = lastFocusedThreadId;

  const listTopOffset = getThreadListTopOffset(threadListRef, scrollContainerRef);
  if (!listTopOffset) return null;

  const newRelativeThreadOffsets: Array<ThreadOffset> = [];
  let newTotalSpaceTaken = 0;
  let someThreadRendered = false;

  const itemIsFalsyMessage = (index: number) => () => `Item at index ${index} is falsy.`;

  threads.forEach((thread: CommentThreadWithLocation) => {
    const commentThread = thread.commentThread;
    const threadId = commentThread.id;

    const commentedSegmentTopOffset = getCommentThreadPosition(
      thread.commentThread,
      scrollContainerRef?.current ?? null,
    );

    // When focused comment position is no longer found, we let go the remembered focused comment
    // to prevent positioning back and forth in case of delete/undo or collapse/expand content component
    if (commentedSegmentTopOffset === null && threadId === focusedThreadId) {
      focusedThreadId = null;
    }

    // When there is no other focused comment treat resolve undo dialog as focused to keep its position in view
    if (focusedThreadId === null && commentThread.isInUndoResolvedState) {
      focusedThreadId = threadId;
    }

    const isFocused = threadId === focusedThreadId;
    const isResolved = isThreadResolvedWithoutUndo(commentThread);

    // Resolved thread is not rendered, but may still hold the position for other comments just after resolving until other thread is focused
    if (isResolved && !isFocused) {
      return;
    }

    const commentThreadTopOffset = Math.round(
      commentedSegmentTopOffset ||
        getCollapsedSegmentTopOffset(thread) ||
        // When some text with unresolved comment is deleted, the desired thread position may be unknown
        // In such case we fall back to its last known position to prevent it moving to 0 offset before it disappears
        getCommentThreadLastKnownTopOffset(
          listTopOffset,
          newTotalSpaceTaken,
          knownThreadOffsets,
          threadId,
        ),
    );

    const commentThreadRelativeOffset = commentThreadTopOffset - listTopOffset;
    const offsetTopDesiredPosition = commentThreadRelativeOffset - newTotalSpaceTaken;

    if (
      offsetTopDesiredPosition < 0 &&
      // If focused thread needs to be higher, try to push threads before it up
      (isFocused ||
        // If first found thread needs to be higher, try to push threads before it (threads without corresponding targets) up
        !someThreadRendered)
    ) {
      let remainingOffset = -offsetTopDesiredPosition;
      newTotalSpaceTaken -= remainingOffset;
      for (let i = newRelativeThreadOffsets.length - 1; i >= 0; i--) {
        const threadOffset = newRelativeThreadOffsets[i];
        assert(!!threadOffset, itemIsFalsyMessage(i));
        const previousOffset = threadOffset.relativeOffset;
        const newOffset =
          i === 0
            ? // First item can proceed with negative offset which determines the initial negative position of the whole comment threadListRef
              previousOffset - remainingOffset
            : // Other items cannot shrink their offset (extra space) to less than zero
              Math.max(previousOffset - remainingOffset, 0);
        newRelativeThreadOffsets[i] = {
          relativeOffset: newOffset,
          height: threadOffset.height,
          threadId: threadOffset.threadId,
        };
        remainingOffset -= previousOffset - newOffset;
        if (remainingOffset <= 0) {
          break;
        }
      }
    }

    // Resolved threads are not rendered, therefore do not occupy space
    if (isResolved) {
      return;
    }

    const threadHeight = getThreadHeight(threadElements.get(threadId));
    if (!threadHeight) {
      return;
    }

    someThreadRendered = true;

    // Push thread to its correct next available position
    const relativeOffset = Math.max(offsetTopDesiredPosition, 0);

    newTotalSpaceTaken += relativeOffset + threadHeight + DefaultCommentSpace;

    newRelativeThreadOffsets.push({
      height: threadHeight,
      relativeOffset,
      threadId,
    });
  });

  return {
    focusedThreadId,
    relativeOffsets: newRelativeThreadOffsets,
    totalSpaceTaken: newTotalSpaceTaken,
  };
};

const updateThreadOffsetsForResizedThread = (
  threadOffsets: ReadonlyMap<Uuid, ThreadOffset>,
  resizedThreadId: Uuid,
  sizeChange: number,
  threads: ReadonlyArray<CommentThreadWithLocation>,
  getCommentThreadPosition: GetCommentThreadPosition,
  threadElements: ThreadElements,
  scrollContainerRef: RefObject<HTMLDivElement> | undefined,
): {
  readonly newThreadOffsets: ReadonlyArray<ThreadOffset>;
  readonly newTotalSpaceTaken: number;
} => {
  let newTotalSpaceTaken = 0;
  let adjustNext = 0;

  const newThreadOffsets = threads
    .map((thread) => {
      const threadId = thread.commentThread.id;

      const current = threadOffsets.get(threadId);
      if (!current) {
        return null;
      }

      const threadHeight = getThreadHeight(threadElements.get(threadId));
      if (!threadHeight) {
        return null;
      }

      if (resizedThreadId === threadId) {
        adjustNext = -sizeChange;
        newTotalSpaceTaken += current.relativeOffset + threadHeight + DefaultCommentSpace;
        return current;
      }

      if (adjustNext < 0) {
        // Compensating for extra space taken by expanding thread
        const newOffset = Math.max(current.relativeOffset + adjustNext, 0);
        adjustNext += current.relativeOffset - newOffset;
        newTotalSpaceTaken += newOffset + threadHeight + DefaultCommentSpace;
        return {
          relativeOffset: newOffset,
          threadId,
          height: threadHeight,
        };
      }

      if (adjustNext > 0) {
        // Compensating for extra space made by shrinking thread
        const currentPosition = newTotalSpaceTaken + current.relativeOffset;
        const desiredPosition = getCommentThreadPosition(
          thread.commentThread,
          scrollContainerRef?.current ?? null,
        );
        if (currentPosition && desiredPosition) {
          const targetPosition = Math.round(desiredPosition);

          const offsetToDesiredPosition = currentPosition - targetPosition;
          if (offsetToDesiredPosition < 0) {
            // If higher than it should be, adjust up to the maximum adjustment, but not too much to not get below the desired position
            const adjust = Math.min(adjustNext, -offsetToDesiredPosition);
            const newOffset = current.relativeOffset + adjust;
            newTotalSpaceTaken += newOffset + threadHeight + DefaultCommentSpace;
            adjustNext += current.relativeOffset - newOffset;
            return {
              relativeOffset: newOffset,
              threadId,
              height: threadHeight,
            };
          }
        }
      }

      newTotalSpaceTaken += current.relativeOffset + threadHeight + DefaultCommentSpace;
      return current;
    })
    .filter(notNull);

  return {
    newThreadOffsets,
    newTotalSpaceTaken,
  };
};

const getOffsetsWithoutNewComments = (
  previousThreads: ReadonlyArray<CommentThreadWithLocation>,
  offsetsWithNewComments: ReadonlyArray<ThreadOffset>,
  threadElements: ThreadElements,
): ReadonlyArray<ThreadOffset> => {
  let addExtraSpace = 0;
  const offsetsWithoutNewComments = offsetsWithNewComments
    .map((item) => {
      const isNewThread = !previousThreads.find(
        (thread) => thread.commentThread.id === item.threadId,
      );
      if (isNewThread) {
        // Add the necessary space to next item(s) offset to allocate the space before the new comment is displayed
        const newThreadHeight = getThreadHeight(threadElements.get(item.threadId));
        if (newThreadHeight !== null) {
          const takenSpace = item.relativeOffset + newThreadHeight + DefaultCommentSpace;
          addExtraSpace += takenSpace;
        }

        return null;
      }

      if (addExtraSpace) {
        const withExtraSpace = {
          ...item,
          relativeOffset: item.relativeOffset + addExtraSpace,
        };
        addExtraSpace = 0;

        return withExtraSpace;
      }

      return item;
    })
    .filter(notNull);

  return offsetsWithoutNewComments;
};

const commentIdsMatch = (
  threads: ReadonlyArray<CommentThreadWithNavigation>,
  previousThreads: ReadonlyArray<CommentThreadWithLocation>,
): boolean =>
  areShallowEqual(
    threads.map((t) => t.commentThread.id),
    previousThreads.map((t) => t.commentThread.id),
  );
