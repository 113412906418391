import { InputState } from '@kontent-ai/component-library/Input';
import { MultiSelect } from '@kontent-ai/component-library/MultiSelect';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { ISelectItem } from '@kontent-ai/component-library/Selects';
import { DefaultTag, Tag } from '@kontent-ai/component-library/Tag';
import { Spacing, colorAlertBackgroundInverse } from '@kontent-ai/component-library/tokens';
import { Collection } from '@kontent-ai/utils';
import React, { useState } from 'react';
import { QuickTip } from '../../../../../../../_shared/components/infos/QuickTip.tsx';
import { IconName } from '../../../../../../../_shared/constants/iconEnumGenerated.ts';
import { gridUnit } from '../../../../../../../_shared/constants/styleConstants.ts';
import { WhenAiReviewFeatureEnabled } from '../../../../../../../_shared/features/AI/containers/WhenAiReviewFeatureEnabled.tsx';
import { areItemLinkTypesEnabled } from '../../../../../../../_shared/selectors/itemLinkTypes.ts';
import {
  DataUiCollection,
  DataUiElement,
  getDataUiCollectionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  IContentType,
  emptyContentType,
} from '../../../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { AiGuidelinesConfiguration } from '../../../../../../../paperModels/aiReview/contentModels/aiGuidelines/containers/AiGuidelinesConfiguration.tsx';
import { AssetFileTypeOption } from '../../../../../../contentInventory/content/models/assetFileTypeOptions.ts';
import { TopLevelBlockCategoryFeature } from '../../../../../../richText/plugins/apiLimitations/api/editorLimitationUtils.ts';
import {
  limitationsHeightPlaceholder,
  limitationsWidthPlaceholder,
} from '../../../../constants/uiConstants.ts';
import { ContentTypesWithUrlSlugSelector } from '../../../../containers/typeElements/richText/ContentTypesWithUrlSlugSelector.tsx';
import { IRichTextTypeElementData } from '../../../../models/elements/RichTextTypeElementData.ts';
import { ITypeElementOwnProps } from '../../../../types/ITypeElementProps.type.ts';
import { getDisallowedLimitationTooltipText } from '../../../../utils/typeElementMessagesUtils.ts';
import { RichTextTypeElementValidationResult } from '../../../../utils/typeElementValidators/types/RichTextTypeElementValidationResult.type.ts';
import { TypeElementConfigurationCategory } from '../../shared/TypeElementConfigurationCategory.tsx';
import { TypeElementConfigurationSection } from '../../shared/TypeElementConfigurationSection.tsx';
import { TypeElementWithTypedName } from '../../shared/TypeElementWithTypedName.tsx';
import { BaseTextTypeLimit } from '../../shared/configuration/BaseTextTypeLimit.tsx';
import { FileSizeLimit } from '../../shared/configuration/FileSizeLimit.tsx';
import { TableInlineFormatting } from '../../shared/configuration/InlineFormatting/TableInlineFormatting.tsx';
import { TextInlineFormatting } from '../../shared/configuration/InlineFormatting/TextInlineFormatting.tsx';
import { LimitImageType } from '../../shared/configuration/LimitImageType.tsx';
import { LimitationCategory } from '../../shared/configuration/LimitationCategory.tsx';
import { LimitationCategoryList } from '../../shared/configuration/LimitationCategoryList.tsx';
import { INumberLimit, NumberLimit } from '../../shared/configuration/NumberLimit.tsx';
import { TableBlocks } from '../../shared/configuration/TableBlocks.tsx';

export interface IContentTypeExtended extends ISelectItem<IContentTypeExtended> {
  readonly isArchived: boolean;
}

export type IRichTextTypeElementStateProps = ITypeElementOwnProps<
  IRichTextTypeElementData,
  RichTextTypeElementValidationResult
> & {
  readonly allContentTypes: ReadonlyArray<IContentTypeExtended>;
  readonly allContentItemLinkTypes: ReadonlyArray<IContentTypeExtended>;
  readonly selectedAllowedTypesOptions: ReadonlyArray<IContentType>;
  readonly selectedAllowedItemLinkTypesOptions: ReadonlyArray<IContentType>;
};

const allTypes: IContentType = {
  ...emptyContentType,
  id: 'allTypes',
  name: 'All types',
};

export const RichTextTypeElement: React.FC<IRichTextTypeElementStateProps> = (props) => {
  const {
    allContentTypes,
    allContentItemLinkTypes,
    selectedAllowedTypesOptions,
    selectedAllowedItemLinkTypesOptions,
    typeElementData,
    validationResult,
    onChange,
  } = props;

  const { allowedBlocks, allowedTextFormatting, allowedTableTextFormatting } = typeElementData;

  const [isTextLimitationExpanded, setIsTextLimitationExpanded] = useState(false);
  const [isTablesLimitationExpanded, setIsTablesLimitationExpanded] = useState(false);
  const [isImagesLimitationExpanded, setIsImagesLimitationExpanded] = useState(false);
  const [isComponentsAndItemsLimitationExpanded, setIsComponentsAndItemsLimitationExpanded] =
    useState(false);

  const textAllowed = allowedBlocks.has(TopLevelBlockCategoryFeature.Text);
  const tablesAllowed = allowedBlocks.has(TopLevelBlockCategoryFeature.Tables);
  const itemLinksEnabled = areItemLinkTypesEnabled(
    allowedBlocks,
    allowedTextFormatting,
    allowedTableTextFormatting,
  );
  const imagesAllowed = allowedBlocks.has(TopLevelBlockCategoryFeature.Images);
  const componentsAndItemsAllowed = allowedBlocks.has(
    TopLevelBlockCategoryFeature.ComponentsAndItems,
  );

  const textLimitsWithErrors =
    !validationResult.isMaxCharsValid ||
    !validationResult.isMaxWordsValid ||
    !validationResult.areAllowedTextBlocksValid;
  const tableLimitsWithErrors = !validationResult.areAllowedTableTextBlocksValid;
  const imagesLimitsWithErrors =
    !validationResult.isImageSizeValid ||
    !validationResult.isWidthValid ||
    !validationResult.isHeightValid;

  const textTooltip = getDisallowedLimitationTooltipText('text', textAllowed);
  const tablesTooltip = getDisallowedLimitationTooltipText('tables', tablesAllowed);
  const imagesTooltip = getDisallowedLimitationTooltipText('images', imagesAllowed);
  const componentsAndItemsTooltip = getDisallowedLimitationTooltipText(
    'components and items',
    componentsAndItemsAllowed,
  );

  const updateAssetWidth = (updatedLimit: INumberLimit): void => {
    onChange({
      ...typeElementData,
      minWidth: updatedLimit.minLimit,
      maxWidth: updatedLimit.maxLimit,
      _widthOption: updatedLimit._limitOption,
    });
  };

  const updateAssetHeight = (updatedLimit: INumberLimit): void => {
    onChange({
      ...typeElementData,
      minHeight: updatedLimit.minLimit,
      maxHeight: updatedLimit.maxLimit,
      _heightOption: updatedLimit._limitOption,
    });
  };

  const updateImageType = (newType: AssetFileTypeOption): void => {
    onChange({
      ...typeElementData,
      fileType: newType,
    });
  };

  const updateSelectedAllowedItemLinkTypesOptions = (updatedOptions: ReadonlySet<Uuid>) => {
    onChange({
      ...typeElementData,
      allowedItemLinkTypes: Array.from(updatedOptions ?? []),
    });
  };

  const updateSelectedAllowedTypesOptions = (updatedOptions: ReadonlySet<Uuid>) => {
    onChange({
      ...typeElementData,
      allowedTypes: Array.from(updatedOptions ?? []),
    });
  };

  const updateAllowedBlocks = (
    blockType: TopLevelBlockCategoryFeature,
    isAllowed: boolean,
  ): void => {
    const updatedAllowedBlocks = isAllowed
      ? Collection.add(allowedBlocks, blockType)
      : Collection.remove(allowedBlocks, blockType);
    onChange({
      ...typeElementData,
      allowedBlocks: updatedAllowedBlocks,
    });
  };

  const updateAiGuidelinesValue = (aiGuidelinesIds: ReadonlyArray<Uuid>) =>
    onChange({
      ...typeElementData,
      aiGuidelinesIds,
    });

  const linksNotAllowedMessage = itemLinksEnabled
    ? undefined
    : 'Links aren’t allowed in this element.';
  const selectedAiGuidelinesIds = typeElementData.aiGuidelinesIds;

  return (
    <TypeElementWithTypedName {...props}>
      <WhenAiReviewFeatureEnabled>
        <AiGuidelinesConfiguration
          onAiGuidelinesSelect={updateAiGuidelinesValue}
          selectedAiGuidelinesIds={selectedAiGuidelinesIds}
        />
      </WhenAiReviewFeatureEnabled>
      <LimitationCategoryList title="Allowed in this element">
        <LimitationCategory
          dataUiElement={DataUiElement.RteTextLimitationCategory}
          hasInvalidLimits={textLimitsWithErrors}
          isAllowed={textAllowed}
          isExpanded={isTextLimitationExpanded}
          title="Text"
          titleIconName={IconName.Paragraph}
          onCollapse={() => setIsTextLimitationExpanded(false)}
          onExpand={() => setIsTextLimitationExpanded(true)}
          onChange={(value) => updateAllowedBlocks(TopLevelBlockCategoryFeature.Text, value)}
        >
          <TypeElementConfigurationCategory>
            <TextInlineFormatting
              disabled={!textAllowed}
              onChange={onChange}
              typeElementData={typeElementData}
              tooltipText={textTooltip}
            />
          </TypeElementConfigurationCategory>
          <TypeElementConfigurationCategory>
            <TypeElementConfigurationSection
              title="Allowed content types for item links"
              contentWidth="wide"
            >
              <Row spacingX={Spacing.S} spacingY={Spacing.L}>
                <Column flexFactor={6} minWidth={42 * gridUnit}>
                  <MultiSelect
                    aria-label="Select a content item link type"
                    caption="Applies to item links used in tables as well."
                    inputState={itemLinksEnabled ? InputState.Default : InputState.Disabled}
                    items={allContentItemLinkTypes}
                    onSelectionChange={updateSelectedAllowedItemLinkTypesOptions}
                    placeholder={allTypes.name}
                    placeholderType="tag"
                    renderSelectedOption={(_id, selectedItem, defaultTagProps) =>
                      selectedItem.isArchived ? (
                        <Tag background={colorAlertBackgroundInverse} {...defaultTagProps} />
                      ) : (
                        <DefaultTag {...defaultTagProps} />
                      )
                    }
                    selectedItemIds={selectedAllowedItemLinkTypesOptions.map((opt) => opt.id)}
                    tooltipText={linksNotAllowedMessage}
                    tooltipPlacement="top"
                    disabledItemIds={allContentItemLinkTypes
                      .filter((type) => type.isArchived)
                      .map((item) => item.id)}
                    {...getDataUiCollectionAttribute(DataUiCollection.ItemLinkContentTypes)}
                  />
                </Column>
                <Column>
                  <ContentTypesWithUrlSlugSelector
                    isDisabled={!itemLinksEnabled}
                    onUpdateContentTypes={updateSelectedAllowedItemLinkTypesOptions}
                    allowedItemLinkTypes={typeElementData.allowedItemLinkTypes}
                    tooltipText={linksNotAllowedMessage}
                  />
                </Column>
              </Row>
            </TypeElementConfigurationSection>
          </TypeElementConfigurationCategory>
          <TypeElementConfigurationCategory>
            <BaseTextTypeLimit
              uiElement={DataUiElement.TextLengthLimitation}
              onChange={onChange}
              typeElementData={typeElementData}
              validationResult={validationResult}
              readOnly={!textAllowed}
              tooltipText={textTooltip}
              tooltipPlacement="top"
            />
          </TypeElementConfigurationCategory>
        </LimitationCategory>
        <LimitationCategory
          dataUiElement={DataUiElement.RteTablesLimitationCategory}
          hasInvalidLimits={tableLimitsWithErrors}
          isAllowed={tablesAllowed}
          isExpanded={isTablesLimitationExpanded}
          title="Tables"
          titleIconName={IconName.Table}
          onCollapse={() => setIsTablesLimitationExpanded(false)}
          onExpand={() => setIsTablesLimitationExpanded(true)}
          onChange={(value) => updateAllowedBlocks(TopLevelBlockCategoryFeature.Tables, value)}
        >
          <TypeElementConfigurationCategory>
            <TableInlineFormatting
              disabled={!tablesAllowed}
              onChange={onChange}
              typeElementData={typeElementData}
              tooltipText={tablesTooltip}
            />
          </TypeElementConfigurationCategory>
          <TypeElementConfigurationCategory>
            <TableBlocks
              disabled={!tablesAllowed}
              onChange={onChange}
              typeElementData={typeElementData}
              tooltipText={tablesTooltip}
            />
          </TypeElementConfigurationCategory>
        </LimitationCategory>
        <LimitationCategory
          dataUiElement={DataUiElement.RteImagesLimitationCategory}
          hasInvalidLimits={imagesLimitsWithErrors}
          isAllowed={imagesAllowed}
          isExpanded={isImagesLimitationExpanded}
          title="Images"
          titleIconName={IconName.Picture}
          onCollapse={() => setIsImagesLimitationExpanded(false)}
          onExpand={() => setIsImagesLimitationExpanded(true)}
          onChange={(value) => updateAllowedBlocks(TopLevelBlockCategoryFeature.Images, value)}
        >
          <TypeElementConfigurationCategory>
            <FileSizeLimit
              uiElement={DataUiElement.FileSizeLimitation}
              title="Limit image size"
              onChange={onChange}
              typeElementData={typeElementData}
              isValid={validationResult.isImageSizeValid}
              readOnly={!imagesAllowed}
              tooltipText={imagesTooltip}
              tooltipPlacement="top"
            />

            <NumberLimit
              title="Limit image width (px)"
              uiElement={DataUiElement.WidthLimitation}
              onChange={updateAssetWidth}
              limit={{
                minLimit: typeElementData.minWidth,
                maxLimit: typeElementData.maxWidth,
                _limitOption: typeElementData._widthOption,
              }}
              isValid={validationResult.isWidthValid}
              placeholder={limitationsWidthPlaceholder}
              readOnly={!imagesAllowed}
              tooltipText={imagesTooltip}
              tooltipPlacement="top"
            />

            <NumberLimit
              title="Limit image height (px)"
              uiElement={DataUiElement.HeightLimitation}
              onChange={updateAssetHeight}
              limit={{
                minLimit: typeElementData.minHeight,
                maxLimit: typeElementData.maxHeight,
                _limitOption: typeElementData._heightOption,
              }}
              isValid={validationResult.isHeightValid}
              placeholder={limitationsHeightPlaceholder}
              readOnly={!imagesAllowed}
              tooltipText={imagesTooltip}
              tooltipPlacement="top"
            />

            <LimitImageType
              uiElement={DataUiElement.FileTypeLimitation}
              title="Limit image types"
              typeElementData={typeElementData}
              onChange={updateImageType}
              readOnly={!imagesAllowed}
              tooltipText={imagesTooltip}
              tooltipPlacement="top"
            />
          </TypeElementConfigurationCategory>
        </LimitationCategory>
        <LimitationCategory
          dataUiElement={DataUiElement.RteComponentsAndItemsLimitationCategory}
          isAllowed={componentsAndItemsAllowed}
          isExpanded={isComponentsAndItemsLimitationExpanded}
          title="Components and Items"
          titleIconName={IconName.CollapseScheme}
          onCollapse={() => setIsComponentsAndItemsLimitationExpanded(false)}
          onExpand={() => setIsComponentsAndItemsLimitationExpanded(true)}
          onChange={(value) =>
            updateAllowedBlocks(TopLevelBlockCategoryFeature.ComponentsAndItems, value)
          }
        >
          <TypeElementConfigurationCategory>
            <TypeElementConfigurationSection title="Allowed content types" contentWidth="wide">
              <div className="row">
                <div className="col-sm-24 col-md-12">
                  <MultiSelect
                    aria-label="Select an option"
                    inputState={
                      componentsAndItemsAllowed ? InputState.Default : InputState.Disabled
                    }
                    items={allContentTypes}
                    onSelectionChange={updateSelectedAllowedTypesOptions}
                    placeholder={allTypes.name}
                    placeholderType="tag"
                    renderSelectedOption={(_id, selectedItem, defaultTagProps) =>
                      selectedItem.isArchived ? (
                        <Tag background={colorAlertBackgroundInverse} {...defaultTagProps} />
                      ) : (
                        <DefaultTag {...defaultTagProps} />
                      )
                    }
                    selectedItemIds={selectedAllowedTypesOptions.map((opt) => opt.id)}
                    tooltipText={componentsAndItemsTooltip}
                    tooltipPlacement="top"
                    disabledItemIds={allContentTypes
                      .filter((type) => type.isArchived)
                      .map((item) => item.id)}
                    {...getDataUiCollectionAttribute(DataUiCollection.ContentTypes)}
                  />
                </div>
                <div className="col-sm-24 col-md-12">
                  <QuickTip>
                    Components are not affected by role permissions. If you want to apply them, you
                    can convert them into linked items instead.
                  </QuickTip>
                </div>
              </div>
            </TypeElementConfigurationSection>
          </TypeElementConfigurationCategory>
        </LimitationCategory>
      </LimitationCategoryList>
    </TypeElementWithTypedName>
  );
};

RichTextTypeElement.displayName = 'RichTextTypeElement';
