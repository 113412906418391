import { Button } from '@kontent-ai/component-library/Button';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { ShortcutsConfig, useHotkeys } from '@kontent-ai/component-library/hooks';
import { EditorState } from 'draft-js';
import { ChangeEvent, FormEvent, useRef, useState } from 'react';
import {
  ControlShortcutTemplate,
  ShortcutSymbols,
} from '../../../../../../../_shared/constants/shortcutSymbols.ts';
import { LabelFor } from '../../../../../../../_shared/uiComponents/LabelFor/LabelFor.tsx';
import {
  DataUiCommentsAction,
  getDataUiCommentActionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { isEmptyOrWhitespace } from '../../../../../../../_shared/utils/stringUtils.ts';
import { IFocusable } from '../../../../../../richText/plugins/behavior/FocusPlugin.tsx';
import {
  DebouncedChanges,
  EditorChangeCallback,
} from '../../../../../../richText/plugins/behavior/OnChangePlugin.tsx';
import { emptyContentState } from '../../../../../../richText/utils/general/editorEmptyValues.ts';
import { isComment } from '../../../../../models/comments/Comment.ts';
import {
  CommentThreadItemType,
  ICommentThreadItem,
  ICommentThreadItemContentModel,
} from '../../../../../models/comments/CommentThreadItem.ts';
import { isSuggestion } from '../../../../../models/comments/Suggestion.ts';
import { CommentInput } from '../../../containers/comments/input/CommentInput.tsx';
import { SuggestionInput } from '../input/SuggestionInput.tsx';

type Props = {
  readonly areButtonsDisplayed: boolean;
  readonly commentThreadItem: ICommentThreadItem;
  readonly isButtonDisabled: boolean;
  readonly isEditing: boolean;
  readonly isInputDisabled: boolean;
  readonly onBlur?: (isCommentPending: boolean) => void;
  readonly onCancel: () => void;
  readonly onSubmit: (content: ICommentThreadItemContentModel) => Promise<void>;
  readonly submitButtonText: string;
};

export const CommentThreadItemEditor = (props: Props) => {
  const lastKnownHasFocus = useRef<boolean | null>(null);

  const isItemSuggestion = isSuggestion(props.commentThreadItem);
  const [commentContent, setCommentContent] = useState<EditorState>(() =>
    EditorState.createWithContent(
      isComment(props.commentThreadItem) ? props.commentThreadItem.content : emptyContentState,
    ),
  );
  const [suggestionText, setSuggestionText] = useState(() =>
    isItemSuggestion ? props.commentThreadItem.suggestedText : '',
  );

  const focusableRef = useRef<IFocusable>(null);
  const suggestionRef = useRef<SuggestionInput>(null);
  const debouncedChangesRef = useRef<DebouncedChanges>(null);

  const isTextValid = (): boolean =>
    !isEmptyOrWhitespace(
      isItemSuggestion ? suggestionText : commentContent?.getCurrentContent().getPlainText(),
    );

  const isSubmitButtonDisabled = (): boolean => !isTextValid() || props.isButtonDisabled;

  const onSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.stopPropagation();
    event.preventDefault();

    handleSubmit();
  };

  const onSuggestionChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    const inputValue = event.target.value;
    setSuggestionText(inputValue);
  };

  const onCommentChange: EditorChangeCallback = (editorState) => {
    setCommentContent(editorState);
    return Promise.resolve();
  };

  const onCancelShortcut = (): void => {
    cancelEditing();
    onBlur();
  };

  const handleSubmit = (): void => {
    if (isTextValid()) {
      submit();
      onBlur();
    }
  };

  const submit = async (): Promise<void> => {
    await debouncedChangesRef.current?.propagatePendingContentChanges();
    const content = isItemSuggestion
      ? {
          type: CommentThreadItemType.Suggestion,
          suggestedText: suggestionText,
        }
      : {
          type: CommentThreadItemType.Comment,
          content: commentContent.getCurrentContent(),
        };
    await props.onSubmit(content);
  };

  const cancelEditing = (): void => {
    props.onCancel();
  };

  const onBlur = (): void => {
    if (isItemSuggestion) {
      props.onBlur?.(!suggestionText);
    } else {
      props.onBlur?.(!commentContent.getCurrentContent().getPlainText());
    }
  };

  const onCommentSelectionChange: EditorChangeCallback = (editorState) => {
    const hasFocus = editorState.getSelection().getHasFocus();
    if (lastKnownHasFocus.current !== hasFocus) {
      lastKnownHasFocus.current = hasFocus;

      if (!hasFocus) {
        onBlur();
      }
    }
    return Promise.resolve();
  };

  const ref = useRef<HTMLFormElement>(null);
  useHotkeys(
    {
      [ShortcutsConfig.ControlEnter]: handleSubmit,
    },
    { ref },
  );

  return (
    <form className="form comment-editor__form" onSubmit={onSubmit} ref={ref}>
      <div className="comment-editor__guideline">
        {isItemSuggestion ? 'Editing suggested changes:' : 'Editing comment:'}
      </div>
      <LabelFor
        isHidden
        target={(id) =>
          isItemSuggestion ? (
            <SuggestionInput
              autoFocus
              ref={suggestionRef}
              inputId={id}
              inputValue={suggestionText}
              onChange={onSuggestionChange}
              onEscape={onCancelShortcut}
            />
          ) : (
            <CommentInput
              autoFocus
              debouncedChangesRef={debouncedChangesRef}
              disabled={props.isInputDisabled}
              editorState={commentContent}
              focusableRef={focusableRef}
              onContentChange={onCommentChange}
              onEscape={onCancelShortcut}
              onSelectionChange={onCommentSelectionChange}
            />
          )
        }
      >
        {isItemSuggestion ? 'Suggestion' : 'Comment'}
      </LabelFor>
      {props.areButtonsDisplayed && (
        <div className="comment-editor__actions">
          <Tooltip text="Cancel editing" placement="bottom-end" shortcuts={ShortcutSymbols.Escape}>
            <Button
              buttonStyle="secondary"
              size="small"
              disabled={props.isInputDisabled}
              onClick={props.isInputDisabled ? undefined : cancelEditing}
              {...getDataUiCommentActionAttribute(DataUiCommentsAction.Cancel)}
            >
              Cancel
            </Button>
          </Tooltip>
          <Tooltip
            text={isSubmitButtonDisabled() ? undefined : 'Save changes'}
            placement="bottom-end"
            shortcuts={ControlShortcutTemplate(ShortcutSymbols.Enter)}
          >
            <Button
              buttonStyle="primary"
              size="small"
              type="submit"
              disabled={isSubmitButtonDisabled()}
              {...getDataUiCommentActionAttribute(DataUiCommentsAction.SaveComment)}
            >
              {props.submitButtonText}
            </Button>
          </Tooltip>
        </div>
      )}
    </form>
  );
};
