import { memoize } from '@kontent-ai/memoization';
import { Collection, groupBy, notNullNorUndefined } from '@kontent-ai/utils';
import { compareUserEnvironments } from '../../applications/projectSettings/environments/utils/environmentsUtils.ts';
import { CopyState } from '../../applications/projects/constants/copyState.ts';
import { compareUserProjects } from '../../applications/projects/utils/compareUserProjects.ts';
import { isProjectInSettingUpUiTransitionState } from '../../applications/projects/utils/projectUtils.ts';
import { IStore } from '../stores/IStore.type.ts';
import { ProjectOption } from '../utils/projects/projectUtils.ts';
import {
  getEnvironmentSwitchUrlFactory,
  getProjectSwitchUrlFactory,
  isAtProjectContainerLevel,
} from '../utils/routing/projectMenuUtils.ts';
import { isProjectPlanExpired, isProjectPlanSuspended } from './userProjectsInfoSelectors.ts';

export const getProjectsForMenu = memoize.maxOne(
  (state: IStore, pathname: string): ReadonlyArray<ProjectOption> => {
    const {
      data: {
        user: { projectsInfoById },
        projects: { byId: projectsById },
      },
      projectsApp: { projectsUiTransitionState },
    } = state;

    const isProjectContainerLevel = isAtProjectContainerLevel(pathname);
    const buildProjectSwitchRoute = isProjectContainerLevel
      ? getProjectSwitchUrlFactory(pathname)
      : getEnvironmentSwitchUrlFactory(pathname);

    return Collection.getValues(
      groupBy(
        projectsInfoById
          .toArray()
          .filter((project) => projectsById.get(project.projectId)?.copyState !== CopyState.Failed)
          .filter(
            (project) =>
              !isProjectInSettingUpUiTransitionState(project.projectId, projectsUiTransitionState),
          ),
        (project) => project.masterEnvironmentId,
      ),
    )
      .map(
        (groupedProjects) => groupedProjects.toSorted((a, b) => compareUserEnvironments(a, b))[0],
      )
      .filter(notNullNorUndefined)
      .toSorted((a, b) => compareUserProjects(state, a, b))
      .map((project): ProjectOption => {
        const planIsExpired = isProjectPlanExpired(state, project.projectId);
        const planIsSuspended = isProjectPlanSuspended(state, project.projectId);

        return {
          projectName: project.projectName,
          masterEnvironmentId: project.masterEnvironmentId,
          planIsExpired,
          planIsSuspended,
          projectContainerId: project.projectContainerId,
          link: buildProjectSwitchRoute(
            isProjectContainerLevel ? project.projectContainerId : project.projectId,
            planIsExpired || planIsSuspended,
            true, //Environments are visible at all times
          ),
        };
      });
  },
  { maxArgs: 3 },
);
