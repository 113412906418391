import React from 'react';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { EmptyContentTypeUsage } from '../../../../../data/models/contentModelsApp/contentTypes/ContentTypeUsage.ts';
import { getTypeElementsInContentGroup } from '../../../shared/utils/typeElementsUtils.ts';
import { containsInvalidElements } from '../../../shared/utils/typeUtils.ts';
import {
  contentGroupMoveFinished,
  contentGroupMoveStarted,
  contentGroupMoved,
  contentGroupRenamed,
  selectedContentGroupChanged,
} from '../../actions/contentTypesActions.ts';
import { deleteContentGroup } from '../../actions/thunkContentTypesActions.ts';
import { DraggableContentTypeContentGroupTab as DraggableContentTypeContentGroupTabComponent } from '../../components/ContentGroups/DraggableContentTypeContentGroupTab.tsx';

interface IDraggableContentTypeContentGroupTabProps {
  readonly id: Uuid;
  readonly name: string;
  readonly position: number;
}

export const DraggableContentTypeContentGroupTab: React.FC<
  IDraggableContentTypeContentGroupTabProps
> = (props) => {
  const contentGroups = useSelector((s) => s.contentModelsApp.typeEditor.editedType.contentGroups);
  const contentTypeUsage = useSelector(
    (s) =>
      s.data.contentTypeUsages.byId.get(s.contentModelsApp.typeEditor.editedType.id) ||
      EmptyContentTypeUsage,
  );
  const isSelected = useSelector(
    (s) => s.contentModelsApp.contentTypes.editor.selectedContentGroupId === props.id,
  );
  const childElements = useSelector((s) =>
    getTypeElementsInContentGroup(s.contentModelsApp.typeEditor.editedType.typeElements, props.id),
  );
  const hasInvalidElements = useSelector((s) =>
    containsInvalidElements(childElements, s.contentModelsApp.typeEditor.validationResults),
  );

  const dispatch = useDispatch();

  return (
    <DraggableContentTypeContentGroupTabComponent
      {...props}
      childElementCount={childElements.length}
      contentTypeUsage={contentTypeUsage}
      hasInvalidElements={hasInvalidElements}
      isLastContentGroup={contentGroups.length === 1}
      onDelete={() => dispatch(deleteContentGroup(props.id))}
      onGroupDropped={() => dispatch(contentGroupMoveFinished())}
      onGroupPickedUp={() => dispatch(contentGroupMoveStarted(props.id))}
      onMove={(movedContentGroupId, targetContentGroupId) =>
        dispatch(contentGroupMoved(movedContentGroupId, targetContentGroupId))
      }
      onRename={(newContentGroupName) =>
        dispatch(contentGroupRenamed(props.id, newContentGroupName))
      }
      onSelect={() => dispatch(selectedContentGroupChanged(props.id))}
      selected={isSelected}
    />
  );
};

DraggableContentTypeContentGroupTab.displayName = 'DraggableContentTypeContentGroupTab';
