import React, { useState } from 'react';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { isCreatingRenditionsEnabled } from '../../../../../_shared/selectors/enhancedAssetManagement.ts';
import { getCurrentProjectPlan } from '../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { replaceAsset } from '../../actions/thunkAssetLibraryActions.ts';
import { AssetReplaceDialog as AssetReplaceDialogComponent } from '../../components/AssetEditing/AssetReplaceDialog.tsx';
import { AssetIdUpdate } from '../../types/assetIdUpdate.type.ts';

type Props = {
  readonly onClose: () => void;
  readonly updateAssetId: (assetIdUpdate: AssetIdUpdate) => void;
};

export const AssetReplaceDialog: React.FC<Props> = ({ onClose, updateAssetId }) => {
  const dispatch = useDispatch();
  const editedAssetId = useSelector((s) => s.assetLibraryApp.assetUiEditor.editedAsset?.id);
  const isImageCustomizationEnabled = useSelector((s) =>
    isCreatingRenditionsEnabled(getCurrentProjectPlan(s)),
  );
  const [isConfirmed, setIsConfirmed] = useState(false);

  if (!editedAssetId) {
    return null;
  }

  return (
    <AssetReplaceDialogComponent
      isConfirmed={isConfirmed}
      isImageCustomizationEnabled={isImageCustomizationEnabled}
      onClose={onClose}
      onReplace={async (files) => {
        onClose();

        const result = await dispatch(replaceAsset(editedAssetId, files));
        if (result) {
          updateAssetId({
            newAssetId: result.newAsset.id,
            oldAssetId: result.oldAssetId,
          });
        }
      }}
      onToggleConfirmation={() => setIsConfirmed((prevValue) => !prevValue)}
    />
  );
};
