import { Box } from '@kontent-ai/component-library/Box';
import { Divider, DividerDirection } from '@kontent-ai/component-library/Dividers';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Column, Row } from '@kontent-ai/component-library/Row';
import {
  BaseColor,
  IconSize,
  Spacing,
  Typography,
  colorTextHint,
} from '@kontent-ai/component-library/tokens';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { formatUserName } from '../../../../../../_shared/utils/users/usersUtils.ts';
import { IProjectContributor } from '../../../../../../data/models/users/ProjectContributor.ts';
import { TipsFromKontentId, TipsFromKontentUserName } from '../../../../utils/commentUtils.ts';
import { CommentContextMenu } from './CommentContextMenu.tsx';

type Props = {
  readonly commentLink?: string;
  readonly id: string;
  readonly onLinkCopied?: () => void;
  readonly onReopen: () => void;
  readonly resolvedByUserId: string | null;
  readonly resolvedBy: IProjectContributor | null;
};

export const ResolvedCommentThreadHeader = ({
  commentLink,
  id,
  onLinkCopied,
  onReopen,
  resolvedByUserId,
  resolvedBy,
}: Props) => {
  return (
    <Divider direction={DividerDirection.Horizontal} offsetBefore={Spacing.L} offsetAfter={0}>
      <Row noWrap alignY="center" spacing={Spacing.S}>
        <Column>
          <Inline spacing={Spacing.S} noWrap align="center">
            <Icons.CheckCircle
              color={BaseColor.NeonGreen50}
              size={IconSize.S}
              {...getDataUiElementAttribute(DataUiElement.CommentStatusIsResolved)}
            />
            <Box
              id={id}
              typography={Typography.LabelMedium}
              overflow="hidden"
              color={colorTextHint}
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              Resolved by {getUserName(resolvedByUserId, resolvedBy)}
            </Box>
          </Inline>
        </Column>
        <Column width="content">
          <CommentContextMenu
            commentLink={commentLink}
            onLinkCopied={onLinkCopied}
            onReopen={onReopen}
          />
        </Column>
      </Row>
    </Divider>
  );
};

const getUserName = (
  resolvedByUserId: string | null,
  resolvedBy: IProjectContributor | null,
): string => {
  if (resolvedByUserId === TipsFromKontentId) {
    return TipsFromKontentUserName;
  }

  return formatUserName(resolvedBy);
};
