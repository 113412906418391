import { History } from 'history';
import {
  ContentItemPreviewRoute,
  ContentItemPreviewRouteParams,
} from '../../../../../../../_shared/constants/routePaths.ts';
import {
  buildPath,
  matchPath,
  parseContentItemIds,
} from '../../../../../../../_shared/utils/routing/routeTransitionUtils.ts';

export const updateRouteWithNewRootItemId = (history: History, newRootItemId: Uuid) => {
  const currentPath = history.location.pathname;
  const previewRouteWithUpdatedRootId = getPreviewRouteWithUpdatedRootId(
    currentPath,
    newRootItemId,
  );

  if (!previewRouteWithUpdatedRootId) {
    return;
  }

  history.push(previewRouteWithUpdatedRootId);
};

export const getPreviewRouteWithUpdatedRootId = (currentPath: string, rootItemId: string) => {
  const matchParams = matchPath<ContentItemPreviewRouteParams<Uuid>>(
    currentPath,
    ContentItemPreviewRoute,
  );
  if (!matchParams) {
    return;
  }

  const contentItemIds = parseContentItemIds(matchParams.contentItemIds);

  const newContentItemIds =
    contentItemIds.length > 1 ? [rootItemId, ...contentItemIds.slice(1)] : contentItemIds;

  return buildPath<ContentItemPreviewRouteParams<UuidArray>>(ContentItemPreviewRoute, {
    app: matchParams.app,
    projectId: matchParams.projectId,
    spaceId: matchParams.spaceId,
    variantId: matchParams.variantId,
    contentItemIds: newContentItemIds,
  });
};
