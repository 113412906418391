import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { Button } from '@kontent-ai/component-library/Button';
import React from 'react';
import { EmptyState } from '../../../../_shared/components/EmptyState/EmptyState.tsx';
import { documentationLinks } from '../../../../_shared/constants/documentationLinks.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type AssetTypeEditorEmptyStateProps = {
  readonly onContactUs: () => void;
};

export const AssetTypeEditorEmptyState: React.FC<AssetTypeEditorEmptyStateProps> = (props) => (
  <EmptyState size="fullOffset">
    <EmptyState.Title>Want to keep your assets organized?</EmptyState.Title>
    <EmptyState.Content>
      <EmptyState.ContentGroup>
        <p>
          With your asset library growing each day, it’s easy to lose track of what’s actually in
          it, making
        </p>
        <p>
          asset governance difficult.{' '}
          <OutwardLink href={documentationLinks.advancedAssetManagement}>
            Advanced asset management
          </OutwardLink>{' '}
          is here to solve this problem for you.
        </p>
      </EmptyState.ContentGroup>
      <ul css="display: inline-block; text-align: left;">
        <li>Keep your assets organized</li>
        <li>Find relevant assets fast</li>
        <li>Avoid duplicate assets</li>
        <li>Keep your brand consistent</li>
      </ul>
      <EmptyState.ContentGroup>
        <p>
          Create an asset taxonomy based on your needs to organize assets by region, asset usage,
        </p>
        <p>licenses, and more. The choice is yours!</p>
      </EmptyState.ContentGroup>
    </EmptyState.Content>
    <EmptyState.Footer>
      <Button
        buttonStyle="primary"
        onClick={props.onContactUs}
        {...getDataUiActionAttribute(DataUiAction.OpenIntercom)}
      >
        Get in touch with us to learn more
      </Button>
    </EmptyState.Footer>
  </EmptyState>
);

AssetTypeEditorEmptyState.displayName = 'AssetTypeEditorEmptyState';
