import React, { useCallback } from 'react';
import { addDefaultLanguageSuffix } from '../../../../../applications/environmentSettings/localization/utils/languageUtils.ts';
import { DefaultVariantScheduledTooltip } from '../../../../../applications/itemEditor/features/CascadePublish/constants/uiConstants.ts';
import {
  PublishingValidationResult,
  validatePublishingConfiguration,
} from '../../../../../applications/itemEditor/features/ContentItemEditing/utils/workflow/publishingValidationUtils.ts';
import { getEditedContentItemType } from '../../../../../applications/itemEditor/selectors/getEditedContentItemType.ts';
import { OptionType } from '../../../../components/Options/createOptionsListComponent.tsx';
import { DefaultVariantId } from '../../../../constants/variantIdValues.ts';
import { useDispatch } from '../../../../hooks/useDispatch.ts';
import { useSelector } from '../../../../hooks/useSelector.ts';
import { ActiveCapabilityType } from '../../../../models/activeCapability.type.ts';
import { getEditedContentItemDefaultVariant } from '../../../../selectors/getEditedContentItemDefaultVariant.ts';
import { getSelectedLanguageId } from '../../../../selectors/getSelectedLanguageId.ts';
import { getSelectedLanguageNameOrPlaceholder } from '../../../../selectors/getSelectedLanguageNameOrPlaceholder.ts';
import { IStore } from '../../../../stores/IStore.type.ts';
import { isScheduleToPublishWorkflowStepSelected } from '../../../../utils/contentItemUtils.ts';
import { DataUiWorkflowAction } from '../../../../utils/dataAttributes/DataUiAttributes.ts';
import {
  formatDatetimeToReadable,
  parseDatetime,
} from '../../../../utils/dateTime/datetimeUtils.ts';
import { hasActiveVariantCapability } from '../../../../utils/permissions/activeCapabilities.ts';
import {
  changeScheduleToPublishMethod,
  scheduledToPublishChanged,
} from '../../actions/changeWorkflowStepModalActions.ts';
import { ScheduleMethodSection } from '../../components/Elements/ScheduleMethodSection.tsx';
import { ScheduleMethod } from '../../constants/scheduleMethods.ts';
import {
  DateIsInPastError,
  PublishDateAfterUnpublishDateError,
} from '../../constants/uiConstants.ts';
import { IChangeWorkflowStepModalData } from '../../reducers/IChangeWorkflowStepModalData.ts';
import { isPublishingAvailable } from '../../selectors/isPublishingAvailable.ts';

const getPublishingOptions = (state: IStore): ReadonlyArray<OptionType<DataUiWorkflowAction>> => {
  const scheduledToPublishAt =
    getEditedContentItemDefaultVariant(state)?.variant?.assignment.scheduledToPublishAt ?? null;
  const publishingAvailable = isPublishingAvailable(state);

  const scheduledAtFormatted = scheduledToPublishAt
    ? formatDatetimeToReadable(parseDatetime(scheduledToPublishAt, true), true)
    : null;

  const tooltipText =
    !publishingAvailable && scheduledAtFormatted
      ? DefaultVariantScheduledTooltip(
          getSelectedLanguageNameOrPlaceholder(state),
          addDefaultLanguageSuffix(state.data.languages.defaultLanguage).name,
          scheduledAtFormatted,
        )
      : undefined;

  return [
    {
      dataUiAttribute: DataUiWorkflowAction.SelectNow,
      disabled: !publishingAvailable,
      id: ScheduleMethod.Now,
      label: 'Publish now',
      tooltipText,
    },
    {
      dataUiAttribute: DataUiWorkflowAction.SelectSchedule,
      id: ScheduleMethod.Schedule,
      label: 'Schedule publish',
    },
  ];
};

const getScheduledToMinValue = (state: IStore): DateTimeStamp | null => {
  const itemWithDefaultVariant = getEditedContentItemDefaultVariant(state);

  if (!itemWithDefaultVariant?.variant) {
    return null;
  }

  const canPublishDefaultVariant = hasActiveVariantCapability(
    ActiveCapabilityType.UpdateAssignment,
    itemWithDefaultVariant,
  );
  const isScheduling = isScheduleToPublishWorkflowStepSelected(
    itemWithDefaultVariant.variant.assignment,
  );
  const itemType = getEditedContentItemType(state);

  if (canPublishDefaultVariant || !isScheduling || !itemType) {
    return null;
  }

  const isEditingDefaultVariant = getSelectedLanguageId(state) === DefaultVariantId;
  const canViewDefaultVariant = hasActiveVariantCapability(
    ActiveCapabilityType.ViewContent,
    itemWithDefaultVariant,
  );
  const dependsOnDefaultVariant =
    !isEditingDefaultVariant &&
    canViewDefaultVariant &&
    itemType.contentElements.some((element) => element.isNonLocalizable);

  return dependsOnDefaultVariant
    ? itemWithDefaultVariant.variant.assignment.scheduledToPublishAt
    : null;
};

const getError = (changeWorkflowStepModalData: IChangeWorkflowStepModalData): string | null => {
  const publishingValidationResult = validatePublishingConfiguration(changeWorkflowStepModalData);

  switch (publishingValidationResult) {
    case PublishingValidationResult.PublishDateIsInPast: {
      return DateIsInPastError;
    }

    case PublishingValidationResult.PublishDateIsSameOrAfterUnpublishDate: {
      return PublishDateAfterUnpublishDateError;
    }
    default: {
      return null;
    }
  }
};

export const PublishMethodSection: React.FC = () => {
  const dispatch = useDispatch();

  const scheduleToPublishAt = useSelector(
    (s) => s.contentApp.changeWorkflowStepModalData.scheduledToPublishAt,
  );
  const scheduledPublishDisplayTimeZone = useSelector(
    (s) => s.contentApp.changeWorkflowStepModalData.scheduledPublishDisplayTimeZone,
  );
  const scheduledToUnpublishAt = useSelector(
    (s) => s.contentApp.changeWorkflowStepModalData.scheduledToUnpublishAt,
  );
  const scheduleMethod = useSelector(
    (s) => s.contentApp.changeWorkflowStepModalData.scheduleMethod,
  );
  const error = useSelector((s) => getError(s.contentApp.changeWorkflowStepModalData));
  const scheduledToMinValue = useSelector(getScheduledToMinValue);
  const options = useSelector(getPublishingOptions);

  const onChangeScheduleMethod = useCallback(
    (method: ScheduleMethod) => dispatch(changeScheduleToPublishMethod(method)),
    [],
  );

  const onChangeScheduledTo = useCallback(
    (scheduledToPublishAt: string | null, timeZoneId: string) =>
      dispatch(scheduledToPublishChanged(scheduledToPublishAt, timeZoneId)),
    [],
  );

  return (
    <ScheduleMethodSection
      invalidDateError={error ?? undefined}
      onScheduleChange={onChangeScheduledTo}
      onScheduleMethodChange={onChangeScheduleMethod}
      options={options}
      scheduledAt={scheduleToPublishAt}
      scheduleDisplayTimeZone={scheduledPublishDisplayTimeZone}
      scheduledToMaxValue={scheduledToUnpublishAt}
      scheduledToMinValue={scheduledToMinValue}
      sectionTitle="Publishing"
      selectedScheduleMethod={scheduleMethod}
    />
  );
};

PublishMethodSection.displayName = 'PublishMethodSection';
