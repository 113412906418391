import { Box } from '@kontent-ai/component-library/Box';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Input, InputType } from '@kontent-ai/component-library/Input';
import {
  IconSize,
  Spacing,
  Typography,
  colorTextDefault,
} from '@kontent-ai/component-library/tokens';
import { clamp } from '@kontent-ai/utils';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { HotkeysHandler } from '../../../../../_shared/components/Hotkeys/HotkeysHandler.tsx';
import {
  DataUiInput,
  getDataUiInputAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  WebSpotlightPreviewMaxResolutionPx,
  WebSpotlightPreviewMinResolutionPx,
  WebSpotlightResolutionInputMaxWidth,
} from '../../../constants/uiConstants.ts';

const PxSuffix: React.FC = () => (
  <Box color={colorTextDefault} typography={Typography.BodyMedium}>
    px
  </Box>
);

interface IWebSpotlightPreviewResolutionInputsProps {
  readonly onChange: () => void;
  readonly onSubmit: (width: number, height: number) => void;
  readonly previewHeight: number;
  readonly previewWidth: number;
}

export const WebSpotlightPreviewResolutionInputs: React.FC<
  IWebSpotlightPreviewResolutionInputsProps
> = ({ onChange, onSubmit, previewHeight, previewWidth }) => {
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);

  const handleWidthChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      setWidth(value ? Number.parseInt(value, 10) : 0);
      onChange();
    },
    [onChange],
  );

  const handleHeightChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      setHeight(value ? Number.parseInt(value, 10) : 0);
      onChange();
    },
    [onChange],
  );

  const handleSubmit = useCallback(() => {
    const newWidth = clamp(
      width,
      WebSpotlightPreviewMinResolutionPx,
      WebSpotlightPreviewMaxResolutionPx,
    );
    const newHeight = clamp(
      height,
      WebSpotlightPreviewMinResolutionPx,
      WebSpotlightPreviewMaxResolutionPx,
    );

    setWidth(newWidth);
    setHeight(newHeight);

    onSubmit(newWidth, newHeight);
  }, [width, height, onSubmit]);

  useEffect(() => {
    setHeight(previewHeight);
  }, [previewHeight]);

  useEffect(() => {
    setWidth(previewWidth);
  }, [previewWidth]);

  return (
    <Inline spacingX={Spacing.S} align="center">
      <Box color={colorTextDefault} typography={Typography.LabelLarge}>
        Resolution:
      </Box>
      <HotkeysHandler handlers={{ onEnter: handleSubmit }}>
        <Inline align="center" spacingX={Spacing.S}>
          <Box maxWidth={WebSpotlightResolutionInputMaxWidth}>
            <Input
              aria-label="Width"
              max={WebSpotlightPreviewMaxResolutionPx}
              min={WebSpotlightPreviewMinResolutionPx}
              onBlur={handleSubmit}
              onChange={handleWidthChange}
              step={1}
              suffixes={[<PxSuffix key="suffix" />]}
              type={InputType.Number}
              value={width.toFixed(0)}
              {...getDataUiInputAttribute(DataUiInput.WebSpotlightPreviewWidth)}
            />
          </Box>
          <Icons.Times size={IconSize.XS} color={colorTextDefault} />
          <Box maxWidth={WebSpotlightResolutionInputMaxWidth}>
            <Input
              aria-label="Height"
              max={WebSpotlightPreviewMaxResolutionPx}
              min={WebSpotlightPreviewMinResolutionPx}
              onBlur={handleSubmit}
              onChange={handleHeightChange}
              step={1}
              suffixes={[<PxSuffix key="suffix" />]}
              type={InputType.Number}
              value={height.toFixed(0)}
              {...getDataUiInputAttribute(DataUiInput.WebSpotlightPreviewHeight)}
            />
          </Box>
        </Inline>
      </HotkeysHandler>
    </Inline>
  );
};

WebSpotlightPreviewResolutionInputs.displayName = 'WebSpotlightPreviewResolutionInputs';
