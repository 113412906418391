import { noOperation } from '@kontent-ai/utils';
import React from 'react';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { getSelectedLanguageId } from '../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { isProjectMultilingual } from '../../../../../../_shared/selectors/isProjectMultilingual.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { getLanguageName } from '../../../../../../_shared/utils/languageSwitchers/languageSwitcherUtils.ts';
import { getAllLanguagesWithDefaultSuffix } from '../../../../../../_shared/utils/languageUtils.ts';
import {
  areListingFilterAndSearchEmpty,
  isListingFilterEmpty,
} from '../../../models/filter/IListingFilter.ts';
import {
  clearContentItemListingFilter,
  updateFilterSearchPhrase,
} from '../actions/thunkContentItemInventoryActions.ts';
import { ContentItemScrollTableEmptyState } from '../components/ItemInventoryScrollTable/ContentItemScrollTableEmptyState.tsx';

const areFilteredResultsEmpty = (state: IStore) => {
  const {
    contentApp: {
      listingUi: { filter },
    },
    data: {
      contentTypeComponentsUsage: { items },
    },
  } = state;

  const isFilterActive = !areListingFilterAndSearchEmpty(filter);
  const viewIsEmpty = items?.length === 0;

  return isFilterActive && viewIsEmpty;
};

const selectedLanguageName = (state: IStore) => {
  const { languages } = state.data;
  const selectedLanguageId = getSelectedLanguageId(state);

  return selectedLanguageId
    ? getLanguageName(selectedLanguageId, getAllLanguagesWithDefaultSuffix(languages))
    : null;
};

export const ContentTypeUsageScrollTableEmptyState: React.FC = () => {
  const dispatch = useDispatch();
  const searchPhrase = useSelector((state) => state.contentApp.listingUi.filter.searchPhrase);
  const isFilterEmpty = useSelector((state) =>
    isListingFilterEmpty(state.contentApp.listingUi.filter),
  );
  const isMultilingualProject = useSelector(isProjectMultilingual);
  const languageName = useSelector(selectedLanguageName);
  const noFilteredResults = useSelector(areFilteredResultsEmpty);

  return languageName ? (
    <ContentItemScrollTableEmptyState
      canCreate={false}
      inventoryIsEmpty={false}
      isFilterEmpty={isFilterEmpty}
      isMultilingualProject={isMultilingualProject}
      noFilteredResults={noFilteredResults}
      searchPhrase={searchPhrase}
      selectedLanguageName={languageName}
      onClearFilter={() => dispatch(clearContentItemListingFilter())}
      onClearSearchPhrase={() => dispatch(updateFilterSearchPhrase('', true))}
      onCreateNewItem={noOperation}
      isSpaceAndCollectionMismatched={false}
    />
  ) : null;
};
