import { Button } from '@kontent-ai/component-library/Button';
import { Label, LabelSize } from '@kontent-ai/component-library/Label';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React, { MouseEventHandler } from 'react';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { getCurrentProjectPlan } from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { isEmptyOrWhitespace } from '../../../../_shared/utils/stringUtils.ts';
import { areContentCollectionsEnabledForCurrentProjectPlan } from '../../utils/allowedFeaturesUtils.ts';
import { openCreator } from '../actions/baseActions.ts';
import { areActiveElementsDisabled } from '../selectors/areActiveElementsDisabled.ts';

interface ICollectionCreatorWithButtonProps {
  readonly filteredCollectionsLength: number;
}

export const CollectionsHeader: React.FC<ICollectionCreatorWithButtonProps> = ({
  filteredCollectionsLength,
}) => {
  const dispatch = useDispatch();

  const isCreatorOpen = useSelector((s) => s.collectionsApp.isCreatorOpen);
  const isFilterActive = !isEmptyOrWhitespace(
    useSelector((state) => state.collectionsApp.filterSearchPhrase),
  );
  const collectionIds = useSelector((s) => s.collectionsApp.collectionIds);
  const disableActiveElements = useSelector(areActiveElementsDisabled);
  const isCollectionsFeatureEnabled = useSelector((state) =>
    areContentCollectionsEnabledForCurrentProjectPlan(getCurrentProjectPlan(state)),
  );

  const isCreatorDisabled = disableActiveElements || !isCollectionsFeatureEnabled;

  const onCreateClick: MouseEventHandler = () => {
    dispatch(openCreator());
  };

  if (isCreatorOpen) {
    return null;
  }

  const labelText = isFilterActive
    ? `${filteredCollectionsLength} results in collections`
    : `${collectionIds.length} collections`;

  return (
    <Row alignY="center" spacing={Spacing.L}>
      <Column>
        <Label size={LabelSize.L}>{labelText}</Label>
      </Column>
      <Column width="content">
        <Button
          disabled={isCreatorDisabled}
          buttonStyle="secondary"
          size="medium"
          onClick={onCreateClick}
          tooltipText={
            isCreatorDisabled ? 'Finish editing your collection to create a new one.' : undefined
          }
          {...getDataUiActionAttribute(DataUiAction.CreateNew)}
        >
          Create new collection
        </Button>
      </Column>
    </Row>
  );
};

CollectionsHeader.displayName = 'CollectionsHeader';
