import React, { useContext } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { AuthorizedSection } from '../../../../_shared/components/routing/AuthorizedSection.tsx';
import { useRedirectPropsWithSameSearch } from '../../../../_shared/components/routing/useRedirectPropsWithSameSearch.tsx';
import { IUserProjectInfo } from '../../../../data/models/user/UserProjectInfo.ts';
import { RefinedNavigationContext } from '../../../refinedNavigation/contexts/RefinedNavigationContext.tsx';
import { ApiKeysSettings } from '../../apiKeys/components/ApiKeysSettings.tsx';
import { ApiKeyTab, areAnyKeyTabsVisible } from '../../apiKeys/constants/ApiKeyTab.ts';
import { EnvironmentListing } from '../../environments/containers/EnvironmentListing.tsx';
import { GeneralSettings } from '../../general/components/GeneralSettings.tsx';
import { ProjectSettingsAppNames } from '../constants/ProjectSettingsAppNames.ts';
import {
  ApiKeyListingRouteSegment,
  EnvironmentsRouteSegment,
  GeneralRouteSegment,
} from '../constants/routeSegments.ts';
import { ProjectSettingsMenu } from '../containers/ProjectSettingsMenu.tsx';
import { ProjectSettingsWithMenuLayout } from './ProjectSettingsWithMenuLayout.tsx';

type ProjectSettingsProps = {
  readonly areGeneralSettingsVisible: boolean;
  readonly firstAccessibleRouteSegment: string;
  readonly project: IUserProjectInfo;
  readonly visibleApiKeyTabs: ReadonlyArray<ApiKeyTab>;
};

export const ProjectSettings: React.FC<ProjectSettingsProps> = ({
  areGeneralSettingsVisible,
  firstAccessibleRouteSegment,
  project,
  visibleApiKeyTabs,
}) => {
  const { path, url } = useRouteMatch();
  const getRedirectPropsWithSameSearch = useRedirectPropsWithSameSearch();
  const { isRefinedNavigationFeatureEnabled } = useContext(RefinedNavigationContext);

  return (
    <Switch>
      <Route path={`${path}/${GeneralRouteSegment}`}>
        <AuthorizedSection
          appName={ProjectSettingsAppNames.General}
          isAuthorized={areGeneralSettingsVisible}
          projectId={project.masterEnvironmentId}
          projectContainerId={project.projectContainerId}
        >
          <ProjectSettingsWithMenuLayout
            renderContent={() => <GeneralSettings />}
            renderSettingsMenu={() =>
              isRefinedNavigationFeatureEnabled ? null : <ProjectSettingsMenu url={url} />
            }
          />
        </AuthorizedSection>
      </Route>
      <Route path={`${path}/${EnvironmentsRouteSegment}`}>
        <ProjectSettingsWithMenuLayout
          renderContent={() => <EnvironmentListing />}
          renderSettingsMenu={() =>
            isRefinedNavigationFeatureEnabled ? null : <ProjectSettingsMenu url={url} />
          }
        />
      </Route>
      <Route path={`${path}/${ApiKeyListingRouteSegment}`}>
        <AuthorizedSection
          appName={ProjectSettingsAppNames.ApiKeyListing}
          isAuthorized={areAnyKeyTabsVisible(visibleApiKeyTabs)}
          projectId={project.masterEnvironmentId}
          projectContainerId={project.projectContainerId}
        >
          <ApiKeysSettings
            masterEnvironmentId={project.masterEnvironmentId}
            projectContainerId={project.projectContainerId}
            renderSettingsMenu={() =>
              isRefinedNavigationFeatureEnabled ? null : <ProjectSettingsMenu url={url} />
            }
            visibleApiKeyTabs={visibleApiKeyTabs}
          />
        </AuthorizedSection>
      </Route>
      <Route>
        <Redirect
          {...getRedirectPropsWithSameSearch({ to: `${url}/${firstAccessibleRouteSegment}` })}
        />
      </Route>
    </Switch>
  );
};

ProjectSettings.displayName = 'ProjectSettings';
