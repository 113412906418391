import { areShallowEqual } from '@kontent-ai/utils';
import React, { useCallback } from 'react';
import { useLocation } from 'react-router';
import { trackUserEvent } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { ContentEntryLinkedItemExpandedEventData } from '../../../../../_shared/models/TrackUserEventData.ts';
import { ActiveCapabilityType } from '../../../../../_shared/models/activeCapability.type.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { getSelectedLanguageNameOrPlaceholder } from '../../../../../_shared/selectors/getSelectedLanguageNameOrPlaceholder.ts';
import { getCurrentProjectId } from '../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import {
  canDuplicateItem,
  hasActiveVariantCapability,
} from '../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { getCannotViewItemMessage } from '../../../../../_shared/utils/permissions/getCannotViewItemMessage.ts';
import {
  getLinkedContentItemPath,
  pickContentItemRoute,
} from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { useLivePreviewPreferenceStorage } from '../../../../../localStorages/useLivePreviewPreferenceStorage.ts';
import {
  ILinkedItemOwnProps,
  LinkedItem,
} from '../../../../itemEditor/features/ContentItemEditing/components/elements/linkedItems/LinkedItem.tsx';
import {
  getDisplayedRichTextLinkedItemCommentThreads,
  getRichTextLinkedItemCommentThreads,
} from '../../../../itemEditor/features/ContentItemEditing/selectors/inlineCommentSelectors.ts';

interface IRichTextLinkedItemContainerProps extends Omit<ILinkedItemOwnProps, 'commentThreadId'> {
  readonly allowedTypeIds: ReadonlyArray<Uuid>;
  readonly commentSegmentId?: Uuid;
  readonly contentComponentId: Uuid | null;
  readonly contentItemId: Uuid;
  readonly elementId: Uuid;
  readonly onNewComment: () => void;
}

export const RichTextLinkedItem: React.FC<IRichTextLinkedItemContainerProps> = ({
  allowedTypeIds,
  commentSegmentId,
  contentComponentId,
  contentItemId,
  elementId,
  ...ownProps
}) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const listingContentItem = useSelector((state) =>
    state.data.listingContentItems.byId.get(contentItemId),
  );
  const currentProjectId = useSelector(getCurrentProjectId);
  const { isLivePreviewPreferred } = useLivePreviewPreferenceStorage(currentProjectId);
  const contentItemPath =
    listingContentItem &&
    getLinkedContentItemPath(
      currentPath,
      listingContentItem.item.id,
      pickContentItemRoute(isLivePreviewPreferred),
    );

  const { firstUnresolvedThreadId, hasFocusedComment } = useSelector((state) => {
    const threads = commentSegmentId
      ? getRichTextLinkedItemCommentThreads(
          state.contentApp.editedContentItemVariantComments.commentThreads,
          commentSegmentId,
          elementId,
          contentComponentId ?? null,
        )
      : undefined;
    const unresolvedThreads =
      commentSegmentId && threads
        ? getDisplayedRichTextLinkedItemCommentThreads(
            threads,
            commentSegmentId,
            elementId,
            contentComponentId ?? null,
          )
        : undefined;

    const focusedCommentThreadId =
      state.contentApp.editedContentItemVariantComments.focusedCommentThreadId;

    return {
      firstUnresolvedThreadId: unresolvedThreads?.[0]?.id ?? null,
      hasFocusedComment:
        !!focusedCommentThreadId &&
        !!threads?.some((thread) => thread.id === focusedCommentThreadId),
    };
  }, areShallowEqual);

  const canCreate =
    !!listingContentItem &&
    hasActiveVariantCapability(ActiveCapabilityType.CreateContent, listingContentItem);
  const canDuplicateInSomeCollection = useSelector(
    (state) =>
      !!listingContentItem &&
      canDuplicateItem(state, getSelectedLanguageIdOrThrow(state), listingContentItem.item.typeId),
  );
  const cannotViewDisabledMessage =
    listingContentItem && getCannotViewItemMessage(listingContentItem);
  const collectionName = useSelector(
    (state) =>
      (listingContentItem &&
        state.data.collections.byId.get(listingContentItem.item.collectionId)?.name) ??
      '',
  );
  const contentType = useSelector(
    (state) =>
      listingContentItem && state.data.contentTypes.byId.get(listingContentItem.item.typeId),
  );

  const isEntryTypeAllowed =
    allowedTypeIds.length === 0 ||
    (!!listingContentItem && allowedTypeIds.includes(listingContentItem.item.typeId));
  const isExpandable =
    !!listingContentItem && !!listingContentItem.variant && !listingContentItem.variant.isArchived;
  const currentLanguageName = useSelector(getSelectedLanguageNameOrPlaceholder);

  const dispatch = useDispatch();
  const onTrackLinkedItemExpanded = useCallback(
    (eventData: ContentEntryLinkedItemExpandedEventData) =>
      dispatch(trackUserEvent(TrackedEvent.ContentEntryLinkedItemExpanded, eventData)),
    [],
  );
  const onTrackLinkedItemEditOpened = useCallback(
    () => dispatch(trackUserEvent(TrackedEvent.ContentEntryLinkedItemEdited)),
    [],
  );

  return (
    <LinkedItem
      canCreate={canCreate}
      canDuplicateInSomeCollection={canDuplicateInSomeCollection}
      cannotViewDisabledMessage={cannotViewDisabledMessage}
      collectionName={collectionName}
      commentSegmentId={commentSegmentId}
      commentThreadId={firstUnresolvedThreadId}
      contentItemId={contentItemId}
      contentItemPath={contentItemPath}
      contentType={contentType}
      currentLanguageName={currentLanguageName}
      hasFocusedComment={hasFocusedComment}
      isEntryTypeAllowed={isEntryTypeAllowed}
      isExpandable={isExpandable}
      loadedContentItem={listingContentItem}
      onTrackLinkedItemEditOpened={onTrackLinkedItemEditOpened}
      onTrackLinkedItemExpanded={onTrackLinkedItemExpanded}
      {...ownProps}
    />
  );
};

RichTextLinkedItem.displayName = 'RichTextLinkedItem';
