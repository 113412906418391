import { assert } from '@kontent-ai/utils';
import { ContentBlock } from 'draft-js';
import { BaseBlockType, BlockType, getNestedBlockType } from '../../../utils/blocks/blockType.ts';
import {
  IRawBlock,
  createContentFromRawBlocks,
  createRawBlock,
} from '../../../utils/blocks/editorBlockUtils.ts';
import { getBlocks } from '../../../utils/general/editorContentGetters.ts';
import { ICoordinates, getDepthFromCoordinates } from './depthCoordinatesConversion.ts';

export interface ITableCell {
  readonly cellBlock: ContentBlock;
  readonly contentBlocks: ReadonlyArray<ContentBlock>;
}

export const getRawTableCellBlocks = (coords: ICoordinates): ReadonlyArray<IRawBlock> => [
  createRawBlock({
    type: BlockType.TableCell,
    depth: getDepthFromCoordinates(coords),
  }),
  createRawBlock({
    type: getNestedBlockType([BlockType.TableCell], BlockType.Unstyled),
  }),
];

export function createEmptyCell(
  parentBlockTypes: ReadonlyArray<BaseBlockType>,
  coords: ICoordinates,
): ITableCell {
  const blocks = getBlocks(
    createContentFromRawBlocks(parentBlockTypes, getRawTableCellBlocks(coords)),
  );
  const cellBlock = blocks[0];
  assert(
    cellBlock,
    () => `Item at index 0 is not a content block. Value: ${JSON.stringify(cellBlock)}`,
  );
  return {
    cellBlock,
    contentBlocks: blocks.slice(1),
  };
}

export function getRawTable(cols: number, rows: number): ReadonlyArray<IRawBlock> {
  if (cols >= 1000 || cols <= 0 || rows <= 0) {
    throw new Error('[getRawTable] The number of columns has to be lower than 1000.');
  }

  const blocks: Array<IRawBlock> = [];
  for (let y = 0; y < rows; y++) {
    for (let x = 0; x < cols; x++) {
      const cellBlocks = getRawTableCellBlocks({ x, y });
      blocks.push(...cellBlocks);
    }
  }

  return blocks;
}
