import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';

type Props = Readonly<{
  onClick: () => void;
}>;

export const ResetViewButton = ({ onClick }: Props) => (
  <QuinaryButton
    aria-label="Reset view"
    onClick={onClick}
    tooltipPlacement="top"
    tooltipText="Reset resolution and scale to fit screen"
  >
    <QuinaryButton.Icon icon={Icons.ArrowsCorners} />
  </QuinaryButton>
);
