import { memoize } from '@kontent-ai/memoization';
import { ICommentThread } from '../../models/comments/CommentThreads.ts';
import {
  CommentThreadWithLocation,
  EmptyThreadReferences,
  getCommentedElementSelector,
  isThreadInline,
} from '../commentUtils.ts';
import { IItemElementCommentManager } from './types/IItemElementCommentManager.type.ts';

const getSimpleElementCommentThreadReferences = memoize.allForever(
  (
    element: { elementId: Uuid },
    contentComponentId: Uuid | null,
    commentThreads: ReadonlyArray<ICommentThread>,
  ): ReadonlyArray<CommentThreadWithLocation> => {
    if (commentThreads.length === 0) {
      return EmptyThreadReferences;
    }

    const elementThreads = commentThreads
      .filter(isThreadInline)
      .filter(
        (commentThread) =>
          commentThread.elementId === element.elementId &&
          commentThread.contentComponentId === contentComponentId,
      )
      .map((commentThread) => ({
        commentThread,
        componentPath: null,
      }));

    return elementThreads;
  },
);

const getCommentTriggerCssSelector = (commentThread: ICommentThread): string | null =>
  getCommentedElementSelector(commentThread);

export const simpleItemElementCommentManager: IItemElementCommentManager = {
  getCommentThreadReferences: (element, threads, _loadedContentItemTypes, contentComponentId) =>
    getSimpleElementCommentThreadReferences(element, contentComponentId, threads),
  getCommentTriggerCssSelector,
};
