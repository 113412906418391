import { trackUserEvent } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import { repositoryCollection } from '../../../../_shared/repositories/repositories.ts';
import { confirmNodeEditingCreator } from './thunks/confirmNodeEditing.ts';
import { createNodeCreator } from './thunks/createNode.ts';
import { deleteNodeCreator } from './thunks/deleteNode.ts';
import { dropNodeCreator } from './thunks/dropNode.ts';
import { initSitemapCreator } from './thunks/initSitemap.ts';
import { moveNodeCreator } from './thunks/moveNode.ts';
import { createSitemapCreator, updateSitemapCreator } from './thunks/saveSitemap.ts';
import { undoDeleteNodeCreator } from './thunks/undoDeleteNode.ts';

const { sitemapRepository, contentItemRepository } = repositoryCollection;

export const updateSitemap = updateSitemapCreator({
  sitemapRepository,
});

export const createSitemap = createSitemapCreator({
  sitemapRepository,
});

export const createNode = createNodeCreator({
  updateSitemap,
  createSitemap,
  trackUserEvent,
});

export const deleteNode = deleteNodeCreator({
  updateSitemap,
});

export const undoDeleteNode = undoDeleteNodeCreator({
  updateSitemap,
});

export const initSitemap = initSitemapCreator({
  contentItemRepository,
  sitemapRepository,
});

export const moveNode = moveNodeCreator();

export const dropNode = dropNodeCreator({
  updateSitemap,
});

export const confirmNodeEditing = confirmNodeEditingCreator({
  updateSitemap,
});
