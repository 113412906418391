import { AnchorButton, Button } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ariaLabel, label } from '../constants/uiConstants.ts';

type Props = Readonly<{
  url: string;
}>;

export const OpenInNewTabPrimaryButton = ({ url }: Props) => {
  return (
    <AnchorButton
      aria-label={ariaLabel}
      buttonStyle="primary"
      href={url}
      {...getDataUiActionAttribute(DataUiAction.OpenInNewTab)}
    >
      <Button.Icon icon={Icons.ArrowRightTopSquare} />
      <Button.Label>{label}</Button.Label>
    </AnchorButton>
  );
};
