import { TypeElementType } from '../../applications/contentInventory/content/models/ContentItemElementType.ts';
import { AssetFileTypeOption } from '../../applications/contentInventory/content/models/assetFileTypeOptions.ts';
import { FileSizeUnit } from '../../applications/contentInventory/content/models/fileSizeOptions.ts';
import { CollectionSection } from '../../applications/itemEditor/features/NewContentItem/constants/collectionSection.ts';
import { ContentTypeSection } from '../../applications/itemEditor/features/NewContentItem/constants/contentTypeSection.ts';
import { TimeInterval } from '../../applications/missionControl/constants/timeIntervals.ts';
import { WorkflowStage } from '../../applications/missionControl/constants/workflowStage.ts';
import { SampleProjectType } from '../../applications/projects/constants/sampleProjectType.ts';
import { AddButtonAction } from '../../applications/webSpotlight/types/SmartLinkSdkApi.ts';
import { PublishingState } from '../../data/constants/PublishingState.ts';
import { FindRightAssetTrackedEvent, TrackedEvent } from '../constants/trackedEvent.ts';
import { ByStatus } from '../constants/userListingFilter.ts';
import { StructuredFeedbackPayload } from '../features/StructuredFeedback/structuredFeedback.ts';
import { ApiKeyType } from './ApiKeyType.ts';
import { AiActionEventData } from './events/AiActionEventData.type.ts';
import { AvatarTypeChangedEventData } from './events/AvatarTypeEventData.type.ts';
import { ColumnSettingsEventData } from './events/ColumnSettingsEventData.type.ts';
import { CommentEventData } from './events/CommentEventData.type.ts';
import {
  ContentItemEditingEventData,
  ContentItemEditingOpenedData,
} from './events/ContentItemEditingEventData.type.ts';
import {
  ContentEntryElementRefreshedEventData,
  ContentEntryElementSaveConflictEventData,
  ContentEntryLockedElementOverwrittenEventData,
  ContentEntryNameOrElementsChangedEventData,
  ContentEntryOverwrittenEventData,
  ContentItemLinkCopiedEventData,
  EditorStatisticsEventData,
  LinkVisitedEventData,
} from './events/ContentItemEventData.type.ts';
import { SavedFilterSelectedEventData } from './events/ContentItemFilterEventData.type.ts';
import { FeatureEventData } from './events/FeatureEventData.type.ts';
import {
  FindRightAssetEventActionEventData,
  FindRightContentEventActionEventData,
} from './events/FindRightContentEventData.type.ts';
import { RTECommandEventData } from './events/RTECommandEventData.type.ts';
import { TasksUserEventData } from './events/TaskEventData.type.ts';
import { UmuxSurveyEventData } from './events/UmuxSurveyEventData.type.ts';
import { UmuxFeedbackIntercomMessage } from './events/UserFeedbackEventData.type.ts';
import {
  CreateWorkflowEventData,
  DeleteWorkflowEventData,
  DocsVisitManageWorkflowEventData,
  RestoreWorkflowEventData,
  UpdateWorkflowEventData,
} from './events/WorkflowsEventData.type.ts';

export type ApiKeyCreatedEventData = {
  readonly type: ApiKeyType;
};

export type AppLoadedEventData = {
  readonly 'business-role': string;
  readonly 'business-type': string;
  readonly 'email-domain': string;
  readonly 'filled-business-role': string;
  readonly 'provided-company-name': string;
  readonly 'sign-up-date': string;
  readonly plan?: string;
};

export type ClientTimeZoneEventData = {
  readonly localTimeZoneId: string;
};

export enum ConfirmationDialogEventTypes {
  Cancel = 'cancel',
  Confirm = 'confirm',
}

export type ConfirmationDialogEventData = {
  readonly action: ConfirmationDialogEventTypes;
};

type AiImageTaggingEventData = {
  readonly action: AiImageTaggingAction;
  readonly matchedByTag: boolean;
};

export enum AiImageTaggingAction {
  TaggedAssetInserted = 'TaggedAssetInserted',
  TaggedAssetOpenedFromLibrary = 'TaggedAssetOpenedFromLibrary',
}

export enum AssetBulkActionEventTypes {
  Deleted = 'deleted',
  DeletedFolder = 'deletedFolder',
  MovedToCollection = 'movedToCollection',
  MovedToFolder = 'movedToFolder',
  MovedBackToCollection = 'movedBackToCollection',
  MovedBackToFolder = 'movedBackToFolder',
  Restored = 'restored',
  RestoredFolder = 'restoredFolder',
}

export type AssetBulkActionEventData = {
  readonly action: AssetBulkActionEventTypes;
  readonly count: number;
};

export type AssetEventData = {
  readonly 'asset-id': Uuid;
  readonly size?: number;
  readonly type: string;
};

export enum AssetDescriptionSource {
  GeneratedByAi = 'generated-by-ai',
  GeneratedByAiAndModified = 'generated-by-ai-and-modified',
}

export interface AssetUpdatedEventData extends AssetEventData {
  readonly 'default-language-description-source'?: AssetDescriptionSource;
}

export type AssetLimitsEventData = {
  readonly 'element-id': Uuid;
  readonly 'file-size': number | null;
  readonly 'file-size-unit-option': FileSizeUnit;
  readonly 'file-type': AssetFileTypeOption;
  readonly 'max-height': number | null;
  readonly 'max-width': number | null;
  readonly 'min-height': number | null;
  readonly 'min-width': number | null;
};

export type CollectionsUpdatedEventData = {
  readonly 'collections-count': number;
  readonly 'collections-ids': readonly Uuid[];
};

export type CodenameEventData =
  | {
      readonly target: Exclude<CodenameTargetType, CodenameTargetType.WorkflowStep>;
      readonly type: CodenameEventType;
    }
  | {
      readonly target: CodenameTargetType.WorkflowStep;
      readonly type: CodenameEventType;
      readonly workflowId: Uuid;
    };

export enum CodenameEventType {
  Copy = 'Copy',
  Edit = 'Edit',
}

export enum CodenameTargetAge {
  Existing = 'Existing',
  New = 'New',
}

export enum CodenameTargetType {
  Asset = 'Asset',
  ContentElementOption = 'ContentElementOption',
  ContentItem = 'ContentItem',
  ContentType = 'ContentType',
  ContentTypeElement = 'ContentTypeElement',
  ContentTypeSnippet = 'ContentTypeSnippet',
  TaxonomyGroup = 'TaxonomyGroup',
  TaxonomyTerm = 'TaxonomyTerm',
  WorkflowStep = 'WorkflowStep',
}

export enum ContentComponentActionType {
  CollapseAll = 'collapse-all',
  CollapseOne = 'collapse-one',
  ExpandAll = 'expand-all',
  ExpandOne = 'expand-one',
}

export type EnvironmentEventData =
  | {
      readonly type: EnvironmentEventType;
      readonly environmentId: Uuid;
    }
  | {
      readonly type: EnvironmentEventType.Swap;
      readonly environmentId: Uuid;
      readonly oldProductionId: Uuid;
    };

export enum EnvironmentEventType {
  Create = 'Create',
  Delete = 'Delete',
  Rename = 'Rename',
  Swap = 'Swap',
}

export type ContentComponentEventData = {
  readonly action: ContentComponentActionType;
  readonly 'content-component-id'?: Uuid;
  readonly 'item-id': Uuid;
  readonly 'variant-id': Uuid;
};

export type CustomTypeElementEventData = {
  readonly config: string | null;
  readonly 'element-id': Uuid;
  readonly url: string;
};

export type ItemSidebarToggleEventData = {
  readonly collapsed: boolean;
};

export type ContentItemBulkEventData = {
  readonly count: number;
};

export type ContentItemBulkMoveToCollectionEventData = ContentItemBulkEventData & {
  readonly 'failed-count': number;
};

export type ContentItemWorkflowStepBulkActionEventData = ContentItemBulkEventData & {
  readonly isRestore: boolean;
  readonly 'wf-step-id': Uuid;
  readonly 'wf-step-name': string;
};

export type ContentElementChangedEventData = {
  readonly 'element-action': 'updated' | 'created';
  readonly 'element-type': TypeElementType;
};

export type NavigationLinkEventData = {
  readonly 'link-id': string;
};

export type RevisionOpenedEventData = {
  readonly 'entry-id': Uuid;
  readonly 'revision-id': Uuid;
  // Event is actually tracked twice when revision is opened. At first, the age is tracked correctly. On the second time,
  // 'Existing' is always tracked making the age unreliable in this event – but that is a task for another time.
  readonly age: undefined;
};

export type RevisionRestoredEventData = {
  readonly 'entry-id': Uuid;
  readonly 'revision-created-at': DateTimeStamp;
  readonly 'revision-id': Uuid;
  readonly 'revision-index': number;
};

export type SitemapNodeCreatedEventData = {
  readonly 'item-name': string;
};

export type SpaceEventData = {
  readonly 'space-id': Uuid;
};

export type SpaceOpenedEventData = SpaceEventData & {
  readonly 'is-web-spotlight-enabled': boolean;
};

export type TaxonomyGroupEventData = {
  readonly 'taxonomy-group-id': Uuid;
};

export type TaxonomyGroupsEventData = {
  readonly 'taxonomy-group-count': number;
  readonly 'taxonomy-group-ids': string;
};

export type TaxonomyTermAssignedEventData = {
  readonly 'assigned-terms-count': number;
  readonly 'element-id': Uuid;
  readonly source: TaxonomyTermAssignedSourceType;
};

export enum TaxonomyTermAssignedSourceType {
  AiAssistant = 'ai-assistant',
  ManualSelection = 'manual-selection',
}

export type WebhookEventData = {
  readonly url: string;
};

export type EntityWebhookEventData = {
  readonly url: string;
};

export enum ProjectOverviewEventTypes {
  CategoryViewed = 'category-viewed',
  CreateContentItem = 'create-content-item',
  ItemsFiltered = 'items-filtered',
  MaxItemsLimitExceeded = 'max-items-limit-exceeded',
  NoItemsFound = 'no-items-found',
  PageEntered = 'page-entered',
  PageLeft = 'page-left',
}

export type ProjectOverviewEventData =
  | {
      readonly action: ProjectOverviewEventTypes;
    }
  | {
      readonly action: ProjectOverviewEventTypes.CategoryViewed;
      readonly category: string;
    }
  | {
      readonly action: ProjectOverviewEventTypes.CreateContentItem;
      readonly 'collection-section': CollectionSection;
      readonly 'content-type-section': ContentTypeSection;
    };

export enum ProjectSettingsSourceTypes {
  ProjectSelector = 'project-selector',
  ProjectTileMoreActions = 'project-tile-more-actions',
  EnvironmentSettingsQuickTip = 'environment-settings-quick-tip',
}

export type ProjectSettingsClickedEventData = {
  readonly source: ProjectSettingsSourceTypes;
};

export type SampleProjectCreatedEventData = {
  readonly createdProjectId: Uuid;
  readonly projectType: SampleProjectType;
};

export enum WebSpotlightEventTypes {
  CreateContentItem = 'create-content-item',
}

export type WebSpotlightEventData =
  | {
      readonly action: WebSpotlightEventTypes;
    }
  | {
      readonly action: WebSpotlightEventTypes.CreateContentItem;
      readonly 'collection-section': CollectionSection;
      readonly 'content-type-section': ContentTypeSection;
    };

export type ContentTypeEventData = {
  readonly 'content-type-count': number;
};

export type ContentTypeSnippetEventData = {
  readonly 'content-type-snippet-count': number;
};

export type ContributorEventData = {
  readonly 'user-id': Uuid;
};

export type FeedbackSubmittedEventData = {
  readonly feedbackedAction: string;
  readonly smileyFeedback: string | null;
  readonly textFeedback: string;
};

export enum FeedbackTextModalActionType {
  Open = 'open',
  Send = 'send',
}

export type FeedbackTextModalEventData =
  | {
      readonly action: FeedbackTextModalActionType.Open;
    }
  | {
      readonly action: FeedbackTextModalActionType.Send;
      readonly message: string;
      readonly isContactMeChecked: boolean;
    };

export enum GuidelinesEventTypes {
  Created = 'created',
  Opened = 'opened',
  Updated = 'updated',
}

export type GuidelinesEventData = {
  readonly action: GuidelinesEventTypes;
  readonly 'content-item-id'?: Uuid;
};

type ContentTypeContainsNonLocalizableElementsEventData = {
  readonly 'contains-non-localizable-elements': boolean;
};

export interface ContentTypeCreatedEventData
  extends ContentTypeContainsNonLocalizableElementsEventData {
  readonly 'added-elements': string | null;
  readonly 'contains-active-condition': boolean;
  readonly 'type-id': Uuid;
}

export type ContentTypeDuplicatedEventData = {
  readonly 'duplicated-elements': string | null;
};

export interface ContentTypeModifiedEventData extends ContentTypeCreatedEventData {
  readonly 'removed-elements': string | null;
}

export type ResourcesUsedEventData = {
  readonly resourceType: 'main' | 'e-learning' | 'keyboard-shortcuts' | 'roadmap' | 'ux-feedback';
};

export type CreateProjectEventData = {
  readonly 'project-template-id'?: Uuid;
};

export type TemplateContentLimitExceededEventData = {
  readonly 'has-custom-type-elements': boolean;
  readonly 'project-template-id': Uuid;
};

export type FindRightAssetEventData = {
  readonly name: FindRightAssetTrackedEvent;
};

export interface InsertAssetsEventData extends FindRightAssetEventData {
  readonly 'asset-ids': string;
  readonly 'opened-folder-id': Uuid;
}

export type CascadePublishNoSelectedItemsEventData = {
  readonly displayedItems: number;
  readonly formerPublishingState: PublishingState;
  readonly itemsAvailableForSelection: number;
};

export type CascadePublishUndoEventData = {
  readonly failedItemsCount: number;
};

export interface CascadePublishWithSelectedItemsEventData
  extends CascadePublishNoSelectedItemsEventData {
  readonly allAvailableItemsSelected: boolean;
  readonly failedItemsCount: number;
  readonly selectedItems: number;
}

export type DeleteContentItemEventData =
  | {
      readonly reason: 'no-changes';
    }
  | {
      readonly currentEditedItem: true;
      readonly workflowStepId: Uuid;
    };

export enum CalendarEventTypes {
  ArrangementSelected = 'arrangement-selected',
  CalendarLeft = 'calendar-left',
  CalendarOpened = 'calendar-opened',
  ContentItemSelected = 'content-item-selected',
  ItemDetailsSelected = 'item-details-selected',
  NextMonthSelected = 'next-month-selected',
  PreviousMonthSelected = 'previous-month-selected',
}

export enum ContentListingEventActionTypes {
  CreateContentItem = 'create-content-item',
}

export type ContentListingEventData =
  | {
      readonly action: ContentListingEventActionTypes;
    }
  | {
      readonly action: ContentListingEventActionTypes.CreateContentItem;
      readonly 'collection-section': CollectionSection;
      readonly 'content-type-section': ContentTypeSection;
    };

export type ContentStatusEventActionEventData = {
  readonly action: ContentStatusEventActionTypes.CreateContentItem;
  readonly 'collection-section': CollectionSection;
  readonly 'content-type-section': ContentTypeSection;
};

export enum ContentStatusEventActionTypes {
  CreateContentItem = 'create-content-item',
}

export enum ContentStatusContentItemClickOrigin {
  CommentButton = 'comment-button',
  ScrollTableRow = 'scroll-table-row',
  TaskButton = 'task-button',
}

export type ContentStatusContentItemClickedEventData = {
  readonly itemId: Uuid;
  readonly origin: ContentStatusContentItemClickOrigin;
  readonly variantId: Uuid | undefined;
};

export type CalendarEventData = {
  readonly action: CalendarEventTypes;
};

export type WebSpotlightPreviewAddButtonActionClickedEventData = {
  readonly action: AddButtonAction;
};

export type WebSpotlightUserChangesReadyOnPreviewApiEventData = {
  readonly msSinceChangeSaved: number;
};

export enum WebSpotlightPreviewResolutionChangeOrigin {
  FitScreenButton = 'fit-screen-button',
  Inputs = 'inputs',
  ResizeHandle = 'resize-handle',
  ResolutionTypeSelector = 'resolution-type-selector',
  RotateButton = 'rotate-button',
}

export type WebSpotlightPreviewResolutionChangedEventData = {
  readonly origin: WebSpotlightPreviewResolutionChangeOrigin;
};

export type RoleLanguagesEventData = {
  readonly roleId: Uuid;
  readonly languageIds: UuidArray;
};

export type CollectionGroupRolesChangedEventData = {
  readonly collectionIds: ReadonlyArray<Uuid>;
  readonly roles: ReadonlyArray<RoleLanguagesEventData>;
};

export type UserCollectionGroupsChangedEventData = {
  readonly collectionGroups: ReadonlyArray<CollectionGroupRolesChangedEventData>;
  readonly collectionGroupsCount: number;
  readonly languageSpecificRolesCount: number;
};

export type UserListingFilterChangedEventData = {
  readonly languageIds: UuidArray;
  readonly roleIds: UuidArray;
  readonly status: ByStatus;
};

export enum ExpandedLinkedItemLayoutType {
  Simple = 'simple', // SimpleLinkedItem component (only edit button is available)
  Regular = 'regular', // LinkedItem component (has more complex layout: duplicate, comment, edit buttons, etc.)
}

export type ContentEntryLinkedItemExpandedEventData = {
  readonly linkedItemLayoutType: ExpandedLinkedItemLayoutType;
  readonly nestedLevel: number;
};

export enum INonLocalizableElementsWarningType {
  DependentVariantsScheduledEarlierQuickTip = 'dependent-variants-scheduled-earlier-quick-tip',
  CancelDefaultVariantSchedulePublishFriendlyWarning = 'cancel-default-variant-schedule-publish-friendly-warning',
}

export type NonLocalizableElementsWarningDisplayedEventData = {
  readonly type: INonLocalizableElementsWarningType;
};

type ContentPaceOpenedEventData = Readonly<{
  contentTypeId: Uuid | null;
  timeInterval: TimeInterval;
  workflowStage: WorkflowStage;
}>;

type ContentPaceWorkflowDetailOpenedEventData = Readonly<{
  contentTypeId: Uuid | null;
  timeInterval: TimeInterval;
  workflowId: Uuid;
  workflowStage: WorkflowStage;
  workflowStepId: Uuid;
}>;

type ContentPaceContentItemClickedEventData = Readonly<{
  itemId: Uuid;
  variantId: Uuid;
}>;

type MissionControlWidgetLinkClickedEventData = Readonly<{
  widgetName: MissionControlTrackedWidgetName;
}>;

export enum MissionControlTrackedWidgetName {
  ItemsAssignedToYou = 'ItemsAssignedToYou',
  OverdueTasks = 'OverdueTasks',
  RecentlyEditedByYou = 'RecentlyEditedByYou',
  TasksAssignedByYou = 'TasksAssignedByYou',
  UnchangedPublishedItems = 'UnchangedPublishedItems',
  UnchangedUnpublishedItems = 'UnchangedUnpublishedItems',
  YourTasks = 'YourTasks',
  PublishedContent = 'PublishedContent',
}

type MissionControlWidgetShowMoreClickedEventData = Readonly<{
  widgetName: MissionControlTrackedWidgetName;
}>;

type MissionControlWidgetDetailItemClickedEventData = Readonly<{
  widgetName: MissionControlTrackedWidgetName;
}>;

export type MissionControlUserFeedbackDialogEventData = Readonly<{
  action: 'opened' | 'closed';
}>;

type MissionControlUserFeedbackDialogSentEventData = Readonly<{
  rating: string;
  comment: string;
}>;

export type TrackedEventToEventData = {
  [TrackedEvent.AiAction]: AiActionEventData;
  [TrackedEvent.AiImageTagging]: AiImageTaggingEventData;
  [TrackedEvent.ApiKeyCreated]: ApiKeyCreatedEventData;
  [TrackedEvent.AppLoaded]: AppLoadedEventData;
  [TrackedEvent.AssetBulkAction]: AssetBulkActionEventData;
  [TrackedEvent.AssetDeleted]: AssetEventData;
  [TrackedEvent.AssetDownloaded]: AssetEventData;
  [TrackedEvent.AssetLimitedInAssetElement]: AssetLimitsEventData;
  [TrackedEvent.AssetLimitedInRichText]: AssetLimitsEventData;
  [TrackedEvent.AssetReplaced]: AssetEventData;
  [TrackedEvent.AssetRestored]: AssetEventData;
  [TrackedEvent.AssetUpdated]: AssetUpdatedEventData;
  [TrackedEvent.AssetUploaded]: AssetEventData;
  [TrackedEvent.AvatarTypeChanged]: AvatarTypeChangedEventData;
  [TrackedEvent.Calendar]: CalendarEventData;
  [TrackedEvent.CascadePublishNoSelectedItems]: CascadePublishNoSelectedItemsEventData;
  [TrackedEvent.CascadePublishUndo]: CascadePublishUndoEventData;
  [TrackedEvent.CascadeScheduleUndo]: CascadePublishUndoEventData;
  [TrackedEvent.CascadePublishWithSelectedItems]: CascadePublishWithSelectedItemsEventData;
  [TrackedEvent.CascadeScheduleNoSelectedItems]: CascadePublishNoSelectedItemsEventData;
  [TrackedEvent.CascadeScheduleWithSelectedItems]: CascadePublishWithSelectedItemsEventData;
  [TrackedEvent.ClientTimeZoneUnsupported]: ClientTimeZoneEventData;
  [TrackedEvent.Codename]: CodenameEventData;
  [TrackedEvent.CollectionsUpdated]: CollectionsUpdatedEventData;
  [TrackedEvent.ColumnSettings]: ColumnSettingsEventData;
  [TrackedEvent.Comments]: CommentEventData;
  [TrackedEvent.ContentComponentCollapse]: ContentComponentEventData;
  [TrackedEvent.ContentEntryBulkAssignment]: ContentItemWorkflowStepBulkActionEventData;
  [TrackedEvent.ContentEntryBulkDeleted]: ContentItemBulkEventData;
  [TrackedEvent.ContentEntryBulkMoveToCollection]: ContentItemBulkMoveToCollectionEventData;
  [TrackedEvent.ContentEntryBulkPublished]: ContentItemBulkEventData;
  [TrackedEvent.ContentEntryBulkScheduled]: ContentItemBulkEventData;
  [TrackedEvent.ContentEntryBulkScheduleUnpublished]: ContentItemBulkEventData;
  [TrackedEvent.ContentEntryBulkUnpublished]: ContentItemBulkEventData;
  [TrackedEvent.ContentEntryElementRefreshed]: ContentEntryElementRefreshedEventData;
  [TrackedEvent.ContentEntryElementSaveConflict]: ContentEntryElementSaveConflictEventData;
  [TrackedEvent.ContentEntryLinkedItemExpanded]: ContentEntryLinkedItemExpandedEventData;
  [TrackedEvent.ContentEntryLockedElementOverwritten]: ContentEntryLockedElementOverwrittenEventData;
  [TrackedEvent.ContentEntryNameOrElementsChanged]: ContentEntryNameOrElementsChangedEventData;
  [TrackedEvent.ContentEntryOverwriteNotificationDisplayed]: ContentEntryOverwrittenEventData;
  [TrackedEvent.ContentEntryOverwriteNotificationRefreshed]: ContentEntryOverwrittenEventData;
  [TrackedEvent.ContentItemDeleted]: DeleteContentItemEventData;
  [TrackedEvent.ContentItemEditing]: ContentItemEditingEventData;
  [TrackedEvent.ContentItemEditingOpened]: ContentItemEditingOpenedData;
  [TrackedEvent.ContentItemLinkCopied]: ContentItemLinkCopiedEventData;
  [TrackedEvent.ContentListing]: ContentListingEventData;
  [TrackedEvent.ContentPaceContentItemClicked]: ContentPaceContentItemClickedEventData;
  [TrackedEvent.ContentPaceOpened]: ContentPaceOpenedEventData;
  [TrackedEvent.ContentPaceWorkflowDetailOpened]: ContentPaceWorkflowDetailOpenedEventData;
  [TrackedEvent.ContentStatusItemCreated]: ContentStatusEventActionEventData;
  [TrackedEvent.ContentStatusContentItemClicked]: ContentStatusContentItemClickedEventData;
  [TrackedEvent.ContentTypeCreated]: ContentTypeCreatedEventData;
  [TrackedEvent.ContentTypeDeleted]: ContentTypeEventData;
  [TrackedEvent.ContentTypeDuplicated]: ContentTypeDuplicatedEventData;
  [TrackedEvent.ContentTypeSnippetDeleted]: ContentTypeSnippetEventData;
  [TrackedEvent.ContentTypeUpdated]: ContentTypeModifiedEventData;
  [TrackedEvent.ContributorActivated]: ContributorEventData;
  [TrackedEvent.ContributorDeactivated]: ContributorEventData;
  [TrackedEvent.CustomElementTypeUpsert]: CustomTypeElementEventData;
  [TrackedEvent.DocsVisitManageWorkflows]: DocsVisitManageWorkflowEventData;
  [TrackedEvent.EntityWebhookSaved]: WebhookEventData;
  [TrackedEvent.Environment]: EnvironmentEventData;
  [TrackedEvent.FeatureUsed]: FeatureEventData;
  [TrackedEvent.FeedbackSubmitted]: FeedbackSubmittedEventData;
  [TrackedEvent.FeedbackTextModal]: FeedbackTextModalEventData;
  [TrackedEvent.FindRightAsset]: FindRightAssetEventActionEventData;
  [TrackedEvent.FindRightContent]: FindRightContentEventActionEventData;
  [TrackedEvent.Guidelines]: GuidelinesEventData;
  [TrackedEvent.ItemRevisionOpened]: RevisionOpenedEventData;
  [TrackedEvent.ItemRevisionRestored]: RevisionRestoredEventData;
  [TrackedEvent.ItemSidebarToggle]: ItemSidebarToggleEventData;
  [TrackedEvent.LinkVisited]: LinkVisitedEventData;
  [TrackedEvent.MissionControlWidgetDetailItemClicked]: MissionControlWidgetDetailItemClickedEventData;
  [TrackedEvent.MissionControlWidgetOverviewItemClicked]: MissionControlWidgetLinkClickedEventData;
  [TrackedEvent.MissionControlWidgetShowMoreClicked]: MissionControlWidgetShowMoreClickedEventData;
  [TrackedEvent.MissionControlUserFeedbackDialog]: MissionControlUserFeedbackDialogEventData;
  [TrackedEvent.MissionControlUserFeedbackDialogSent]: MissionControlUserFeedbackDialogSentEventData;
  [TrackedEvent.MoveItemsToArchivedStepConfirmationDialog]: ConfirmationDialogEventData;
  [TrackedEvent.MoveItemToArchivedStepConfirmationDialog]: ConfirmationDialogEventData;
  [TrackedEvent.NavigationLinkOpened]: NavigationLinkEventData;
  [TrackedEvent.NonLocalizableElementsWarningDisplayed]: NonLocalizableElementsWarningDisplayedEventData;
  [TrackedEvent.ProjectCreated]: CreateProjectEventData;
  [TrackedEvent.ProjectOverview]: ProjectOverviewEventData;
  [TrackedEvent.ProjectSettingsClicked]: ProjectSettingsClickedEventData;
  [TrackedEvent.SampleProjectCreated]: SampleProjectCreatedEventData;
  [TrackedEvent.ResourcesUsed]: ResourcesUsedEventData;
  [TrackedEvent.RolesChanged]: UserCollectionGroupsChangedEventData;
  [TrackedEvent.RTECommandUsed]: RTECommandEventData;
  [TrackedEvent.SavedFilterSelected]: SavedFilterSelectedEventData;
  [TrackedEvent.SitemapNodeCreated]: SitemapNodeCreatedEventData;
  [TrackedEvent.SpacePreviewOpened]: SpaceOpenedEventData;
  [TrackedEvent.SpaceWebSpotlightSwitchSpace]: SpaceEventData;
  [TrackedEvent.Statistics]: EditorStatisticsEventData;
  [TrackedEvent.StructuredUserFeedback]: StructuredFeedbackPayload;
  [TrackedEvent.Tasks]: TasksUserEventData;
  [TrackedEvent.TaxonomyGroupArchived]: TaxonomyGroupsEventData;
  [TrackedEvent.TaxonomyGroupCreated]: TaxonomyGroupEventData;
  [TrackedEvent.TaxonomyTermAssigned]: TaxonomyTermAssignedEventData;
  [TrackedEvent.TemplateContentLimitExceeded]: TemplateContentLimitExceededEventData;
  [TrackedEvent.UmuxSurvey]: UmuxSurveyEventData;
  [TrackedEvent.UserFeedback]: UmuxFeedbackIntercomMessage;
  [TrackedEvent.UserListingFilterChanged]: UserListingFilterChangedEventData;
  [TrackedEvent.WebhookArchived]: WebhookEventData;
  [TrackedEvent.WebhookDisabled]: WebhookEventData;
  [TrackedEvent.WebhookEnabled]: WebhookEventData;
  [TrackedEvent.WebhookReset]: WebhookEventData;
  [TrackedEvent.WebhookSaved]: WebhookEventData;
  [TrackedEvent.WebSpotlightSubpageItemCreated]: WebSpotlightEventData;
  [TrackedEvent.WebSpotlightPreviewAddButtonActionClicked]: WebSpotlightPreviewAddButtonActionClickedEventData;
  [TrackedEvent.WebSpotlightPreviewResolutionChanged]: WebSpotlightPreviewResolutionChangedEventData;
  [TrackedEvent.WebSpotlightUserChangesReadyOnPreviewApi]: WebSpotlightUserChangesReadyOnPreviewApiEventData;
  [TrackedEvent.WorkflowCreated]: CreateWorkflowEventData;
  [TrackedEvent.WorkflowDeleted]: DeleteWorkflowEventData;
  [TrackedEvent.WorkflowRestored]: RestoreWorkflowEventData;
  [TrackedEvent.WorkflowUpdated]: UpdateWorkflowEventData;
};
