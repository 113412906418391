import React, { useMemo } from 'react';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { DataUiElement } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IContentType } from '../../../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { isWebSpotlightEnabled } from '../../../../../../webSpotlight/selectors/webSpotlightSelectors.ts';
import { limitationsCountPlaceholder } from '../../../../constants/uiConstants.ts';
import { LinkedItemsDefaultValue } from '../../../../containers/typeElements/linkedItems/LinkedItemsDefaultValue.tsx';
import {
  ILinkedItemsTypeElementData,
  LinkedItemsRelationType,
} from '../../../../models/elements/LinkedItemsTypeElementData.ts';
import {
  ITypeElementDataProps,
  ITypeElementOwnProps,
} from '../../../../types/ITypeElementProps.type.ts';
import { ModularContentTypeElementValidationResult } from '../../../../utils/typeElementValidators/types/ModularContentTypeElementValidationResult.type.ts';
import { TypeElementConfigurationCategory } from '../../shared/TypeElementConfigurationCategory.tsx';
import { TypeElementConfigurationSection } from '../../shared/TypeElementConfigurationSection.tsx';
import { TypeElementWithTypedName } from '../../shared/TypeElementWithTypedName.tsx';
import { INumberLimit, NumberLimit } from '../../shared/configuration/NumberLimit.tsx';
import { ContentTypeMultiSelect, toContentTypeOption } from './ContentTypeMultiSelect.tsx';

export interface ILinkedItemsTypeElementDataProps
  extends ITypeElementDataProps<
    ILinkedItemsTypeElementData,
    ModularContentTypeElementValidationResult
  > {
  readonly allContentTypes: ReadonlyArray<IContentType>;
  readonly isWebSpotlightRootType: boolean;
  readonly selectedAllowedContentTypes: ReadonlySet<IContentType>;
}

type LinkedItemsTypeElementProps = ILinkedItemsTypeElementDataProps &
  ITypeElementOwnProps<ILinkedItemsTypeElementData, ModularContentTypeElementValidationResult>;

export const LinkedItemsTypeElement: React.FC<LinkedItemsTypeElementProps> = (props) => {
  const isWebSpotlightSubpagesElement = useSelector(
    (s) =>
      isWebSpotlightEnabled(s) &&
      props.typeElementData.relationType === LinkedItemsRelationType.Subpages,
  );
  const cannotBeDeleted = props.isWebSpotlightRootType && isWebSpotlightSubpagesElement;

  const contentTypeOptions = useMemo(
    () => props.allContentTypes.map(toContentTypeOption),
    [props.allContentTypes],
  );

  const selectedAllowedTypesIds = useMemo(
    () => new Set([...props.selectedAllowedContentTypes].map((type) => type.id)),
    [props.selectedAllowedContentTypes],
  );

  const selectedAllowedTypesImmutable = useMemo(
    () => Immutable.Set<IContentType>(props.selectedAllowedContentTypes),
    [props.selectedAllowedContentTypes],
  );

  const updateQuantityLimit = (newLimit: INumberLimit): void => {
    props.onChange({
      ...props.typeElementData,
      minItems: newLimit.minLimit,
      maxItems: newLimit.maxLimit,
      _quantityUnitOption: newLimit._limitOption,
    });
  };

  const updateSelectedAllowedContentTypes = (updatedIds: ReadonlySet<Uuid>): void => {
    props.onChange({
      ...props.typeElementData,
      allowedTypes: [...updatedIds],
    });
  };

  const updateDefaultValue = (linkedItemIds: ReadonlyArray<Uuid>): void => {
    props.onChange({
      ...props.typeElementData,
      defaultValue: linkedItemIds,
    });
  };

  return (
    <TypeElementWithTypedName
      {...props}
      cannotBeDeleted={cannotBeDeleted}
      deleteButtonTooltip="This element is used by Web Spotlight and cannot be deleted."
      hintContent={
        isWebSpotlightSubpagesElement
          ? 'Use this element to create relationships between pages, for example, Home → About page.'
          : 'Each content item can link to other content items. Use this element to define relationships between items, for example, Article → Author.'
      }
    >
      <TypeElementConfigurationCategory>
        <NumberLimit
          title="Limit number of items"
          uiElement={DataUiElement.ItemsCountLimitation}
          onChange={updateQuantityLimit}
          limit={{
            minLimit: props.typeElementData.minItems,
            maxLimit: props.typeElementData.maxItems,
            _limitOption: props.typeElementData._quantityUnitOption,
          }}
          isValid={props.validationResult.isNumberOfItemsValid}
          placeholder={limitationsCountPlaceholder}
        />
        <TypeElementConfigurationSection title="Allowed content types">
          <ContentTypeMultiSelect
            contentTypes={contentTypeOptions}
            selectedContentTypeIds={selectedAllowedTypesIds}
            onChange={updateSelectedAllowedContentTypes}
            placeholder="All types"
            placeholderType="tag"
          />
        </TypeElementConfigurationSection>
      </TypeElementConfigurationCategory>
      <TypeElementConfigurationCategory>
        <TypeElementConfigurationSection title="Default value" contentWidth="wide">
          <LinkedItemsDefaultValue
            allowedContentTypes={selectedAllowedTypesImmutable}
            isDisabled={!!props.disabled}
            onChange={updateDefaultValue}
            typeElementData={props.typeElementData}
          />
        </TypeElementConfigurationSection>
      </TypeElementConfigurationCategory>
    </TypeElementWithTypedName>
  );
};

LinkedItemsTypeElement.displayName = 'LinkedItemsTypeElement';
