import { MultiSelect } from '@kontent-ai/component-library/MultiSelect';
import { IBaseSelectItem, ItemId, findItem } from '@kontent-ai/component-library/Selects';
import { DefaultTag, Tag } from '@kontent-ai/component-library/Tag';
import { getA11yLabelProps } from '@kontent-ai/component-library/component-utils';
import { colorAlertBackgroundInverse } from '@kontent-ai/component-library/tokens';
import { Collection } from '@kontent-ai/utils';
import React, { ComponentProps, useEffect, useMemo, useState } from 'react';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IContentType } from '../../../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';

interface IContentTypeOption extends IBaseSelectItem {
  readonly isDeleted?: boolean;
}
type MultiSelectProps = ComponentProps<typeof MultiSelect<IContentTypeOption>>;

export const toContentTypeOption = (contentType: IContentType): IContentTypeOption => ({
  id: contentType.id,
  label: contentType.name,
});

const toDeletedContentTypeOption = (deletedContentTypeId: ItemId): IContentTypeOption => ({
  id: deletedContentTypeId,
  label: 'Deleted content type',
  isDeleted: true,
});

const getDeletedContentTypeIds = (
  contentTypes: ReadonlyArray<IContentTypeOption>,
  selectedContentTypeIds: ReadonlySet<ItemId>,
): ReadonlySet<ItemId> =>
  new Set(
    [...selectedContentTypeIds].filter(
      (selectedContentTypeId) =>
        !findItem(contentTypes, (contentType) => contentType.id === selectedContentTypeId),
    ),
  );

const renderSelectedOption = (
  _id: ItemId,
  selectedItem: IContentTypeOption,
  defaultTagProps: ComponentProps<typeof DefaultTag>,
) =>
  selectedItem.isDeleted ? (
    <Tag {...defaultTagProps} background={colorAlertBackgroundInverse} />
  ) : (
    <DefaultTag {...defaultTagProps} />
  );

const sortSelectedTypeIdsDeletedFirst = (
  selectedContentTypeIds: ReadonlySet<ItemId>,
  deletedContentTypeIds: ReadonlySet<ItemId>,
): ReadonlyArray<ItemId> =>
  [...selectedContentTypeIds].toSorted((selectedContentTypeId) =>
    deletedContentTypeIds.has(selectedContentTypeId) ? -1 : 1,
  );

interface IContentTypeMultiSelectProps
  extends Pick<MultiSelectProps, 'placeholder' | 'placeholderType'> {
  readonly contentTypes: ReadonlyArray<IContentTypeOption>;
  readonly selectedContentTypeIds: ReadonlySet<ItemId>;
  readonly onChange: (selectedContentTypeIds: ReadonlySet<ItemId>) => void;
  readonly label?: string;
}

export const ContentTypeMultiSelect: React.FC<IContentTypeMultiSelectProps> = ({
  contentTypes,
  label,
  selectedContentTypeIds,
  onChange,
  placeholder,
  placeholderType,
}) => {
  const [deselectedDeletedContentTypeIds, setDeselectedDeletedContentTypeIds] = useState<
    ReadonlySet<ItemId>
  >(new Set());
  const [deletedContentTypeIds, setDeletedContentTypeIds] = useState<ReadonlySet<Uuid>>(new Set());

  const deletedContentTypes = useMemo(
    () => [...deletedContentTypeIds].map(toDeletedContentTypeOption),
    [deletedContentTypeIds],
  );
  const allContentTypes = useMemo(
    () => [...deletedContentTypes, ...contentTypes],
    [contentTypes, deletedContentTypes],
  );
  const selectedContentTypeIdsSorted = useMemo(
    () => sortSelectedTypeIdsDeletedFirst(selectedContentTypeIds, deletedContentTypeIds),
    [selectedContentTypeIds, deletedContentTypeIds],
  );

  useEffect(() => {
    const newDeletedContentTypeIds = getDeletedContentTypeIds(contentTypes, selectedContentTypeIds);

    // Data is loaded and deletedContentTypes have not yet been set
    if (
      contentTypes.length > 0 &&
      deletedContentTypeIds.size === 0 &&
      newDeletedContentTypeIds.size > 0
    ) {
      setDeletedContentTypeIds(newDeletedContentTypeIds);
    }
  }, [contentTypes, selectedContentTypeIds, deletedContentTypeIds]);

  const handleSelectionChange = (changedTypeIds: ReadonlySet<ItemId>) => {
    setDeselectedDeletedContentTypeIds(
      Collection.removeMany(deletedContentTypeIds, [...changedTypeIds]),
    );
    onChange?.(changedTypeIds);
  };

  const labelingProps = getA11yLabelProps(label, 'Select a content type');

  return (
    <MultiSelect
      disabledItemIds={deselectedDeletedContentTypeIds}
      items={allContentTypes}
      onSelectionChange={handleSelectionChange}
      placeholder={placeholder}
      placeholderType={placeholderType}
      renderSelectedOption={renderSelectedOption}
      selectedItemIds={selectedContentTypeIdsSorted}
      {...labelingProps}
      {...getDataUiCollectionAttribute(DataUiCollection.ContentTypes)}
    />
  );
};
