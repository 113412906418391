import { useRef } from 'react';
import {
  DataUiAppName,
  DataUiElement,
  getDataUiAppNameAttribute,
  getDataUiElementAttribute,
} from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ContentItemSidebarPlaceholder } from '../../itemEditor/features/ContentItemEditing/components/sidebar/ContentItemSidebarPlaceholder.tsx';
import { ContentItemEditor } from '../../itemEditor/features/ContentItemEditing/containers/ContentItemEditor.tsx';
import { ContentItemNotifications } from '../../itemEditor/features/ContentItemEditing/containers/ContentItemNotifications.tsx';
import { WaitForEditedItemInitialized } from '../../itemEditor/features/ContentItemEditing/containers/WaitForEditedItemInitialized.tsx';
import { EditingActionsBar } from '../../itemEditor/features/ContentItemEditing/containers/editingActions/EditingActionsBar.tsx';
import { ContentItemSidebar } from '../../itemEditor/features/ContentItemEditing/containers/sidebar/ContentItemSidebar.tsx';
import { WebSpotlightTabs } from './WebSpotlightTabs.tsx';
import { WebSpotlightWorkspace } from './WebSpotlightWorkspace.tsx';

export const WebSpotlightEditorView = () => {
  const workspaceRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <WebSpotlightTabs />
      <WebSpotlightWorkspace withItemEditor workspaceRef={workspaceRef}>
        <div
          className="canvas"
          {...getDataUiAppNameAttribute(DataUiAppName.ContentItemEditing)}
          data-hj-suppress=""
        >
          <div className="canvas__workspace">
            <section
              className="canvas__content content-item-editor"
              {...getDataUiElementAttribute(DataUiElement.ContentItemEditor)}
            >
              <WaitForEditedItemInitialized>
                <ContentItemNotifications />
              </WaitForEditedItemInitialized>
              <ContentItemEditor />
              <EditingActionsBar />
            </section>
          </div>
        </div>
      </WebSpotlightWorkspace>
      <WaitForEditedItemInitialized
        renderWhileWaiting={() => <ContentItemSidebarPlaceholder isDisabled />}
      >
        <ContentItemSidebar hasPlaceholder outsideClickElementRefs={workspaceRef} />
        <ContentItemSidebarPlaceholder />
      </WaitForEditedItemInitialized>
    </>
  );
};
