import { Icons } from '@kontent-ai/component-library/Icons';
import { DataUiInput } from '../../../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  WebSpotlightPreviewMaxResolutionPx,
  WebSpotlightPreviewMinResolutionPx,
} from '../../../../../../../../webSpotlight/constants/uiConstants.ts';
import { PreviewSizeInput } from './PreviewSizeInput.tsx';

type Props = Readonly<{
  initialValue: number;
  onChange: () => void;
  onSubmit: (height: number) => void;
}>;

export const HeightInput = ({ initialValue, onChange, onSubmit }: Props) => {
  return (
    <PreviewSizeInput
      dataUiInput={DataUiInput.WebSpotlightPreviewHeight}
      icon={<Icons.ArrowsV />}
      max={WebSpotlightPreviewMaxResolutionPx}
      min={WebSpotlightPreviewMinResolutionPx}
      initialValue={initialValue}
      label="Height"
      onChange={onChange}
      onSubmit={onSubmit}
    />
  );
};
