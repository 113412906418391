import { addMonths, endOfMonth, endOfWeek, startOfMonth, startOfWeek } from 'date-fns';
import { currentLocale } from '../../../../../_shared/components/DatetimePicker/InternalFiles/localeUtils.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../../_shared/hooks/useThunkPromise.ts';
import { DateRange } from '../../../../../_shared/models/DateRange.type.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import { compose } from '../../../../../_shared/utils/func/compose.ts';
import { calendarPopupClosed } from '../../actions/calendarActions.ts';
import { initializeCalendar } from '../../actions/thunkCalendarActions.ts';
import { CalendarWrapper as CalendarWrapperComponent } from '../../components/Calendar/CalendarWrapper.tsx';
import { NavigateAction } from '../../constants/NavigateAction.ts';
import { CalendarArrangement } from '../../models/CalendarArrangement.ts';
import { ICalendarEvent } from '../../models/CalendarModels.type.ts';
import { getCurrentMonthFromRange } from '../../utils/calendarUtils.ts';
import { DayOfWeek } from '../../utils/useCalendarMatrix.ts';

const getRangeFromMonthToNavigate = (startOfMonthDate: Date): DateRange => {
  const weekStartsOn = currentLocale.options?.weekStartsOn ?? DayOfWeek.Sunday;
  const endOfMonthDate = endOfMonth(startOfMonthDate);

  return {
    from: startOfWeek(startOfMonthDate, { weekStartsOn }).toISOString(),
    to: endOfWeek(endOfMonthDate, { weekStartsOn }).toISOString(),
  };
};

const getStartOfMonthToNavigate = (startOfSelectedMonth: Date, action: NavigateAction): Date => {
  switch (action) {
    case NavigateAction.next: {
      return addMonths(startOfSelectedMonth, 1);
    }
    case NavigateAction.prev: {
      return addMonths(startOfSelectedMonth, -1);
    }
    case NavigateAction.today: {
      return startOfMonth(new Date());
    }
  }
};

type Props = {
  readonly events: readonly ICalendarEvent[];
  readonly range: DateRange;
  readonly selectedArrangement: CalendarArrangement;
  readonly onPeriodChanged: (period: DateRange) => void;
  readonly onDrillDownDateSelected: (date: DateTimeStamp) => void;
  readonly onItemDetailSelected: (id: Uuid, date: DateTimeStamp) => void;
  readonly renderFilter: (hideFilter: () => void) => JSX.Element;
};

export const CalendarWrapper = (props: Props) => {
  const dispatch = useDispatch();
  useThunkPromise(initializeCalendar);

  const closeCalendarPopup = () => dispatch(calendarPopupClosed());
  const isCalendarViewLoaded = useSelector(
    (state) => state.calendarApp.itemsLoadingStatus === LoadingStatus.Loaded,
  );
  const calendarView = useSelector((state) => state.calendarApp.calendarView);

  const onNavigate = compose(
    props.onPeriodChanged,
    getRangeFromMonthToNavigate,
    (action: NavigateAction) =>
      getStartOfMonthToNavigate(getCurrentMonthFromRange(props.range), action),
  );

  return (
    <CalendarWrapperComponent
      {...props}
      calendarView={calendarView}
      isCalendarViewLoaded={isCalendarViewLoaded}
      onDrillDownDateSelected={(date) => {
        closeCalendarPopup();
        props.onDrillDownDateSelected(date);
      }}
      onItemDetailSelected={(id, date) => {
        closeCalendarPopup();
        props.onItemDetailSelected(id, date);
      }}
      onNavigate={onNavigate}
    />
  );
};
