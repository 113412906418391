import { useCallback, useEffect } from 'react';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../_shared/hooks/useThunkPromise.ts';
import { getAllWorkflowStepsById } from '../../../_shared/selectors/workflowSelectors.ts';
import { createNewWebhookSetting, initWebhookListing } from '../actions/thunkWebhooksActions.ts';
import { webhookListingLeft } from '../actions/webhooksActions.ts';
import { WebhookListing } from '../components/WebhookListing.tsx';
import { getWebhooksWithNoTrigger, memoizedWebhooks } from '../utils/webhookSettingsTriggers.ts';

export const WebhookListingPage = () => {
  useThunkPromise(initWebhookListing);

  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(webhookListingLeft());
    };
  }, []);

  const editedWebhookId = useSelector((s) => s.webhooksApp.editedWebhookId);
  const memoizedWebhooksResult = useSelector((s) => memoizedWebhooks(s.data.webhooks.byId));
  const byIdLoadingStatus = useSelector((s) => s.data.webhooks.byIdLoadingStatus);
  const webhooksWithNoTrigger = useSelector((s) => {
    const allWorkflowSteps = getAllWorkflowStepsById(s.data.workflows.byId);
    return getWebhooksWithNoTrigger(s.data.webhooks.byId, Immutable.OrderedMap(allWorkflowSteps));
  });
  const createWebhook = useCallback(() => dispatch(createNewWebhookSetting()), []);
  const createIsEnabled = !editedWebhookId || !!memoizedWebhooksResult.newWebhook;

  return (
    <WebhookListing
      createIsEnabled={createIsEnabled}
      createWebhook={createWebhook}
      editedWebhookId={editedWebhookId}
      loadingStatus={byIdLoadingStatus}
      newWebhook={memoizedWebhooksResult.newWebhook}
      webhooks={memoizedWebhooksResult.webhooks}
      webhooksWithNoTrigger={webhooksWithNoTrigger}
    />
  );
};
