import { Placement } from '@kontent-ai/component-library/types';
import { FocusScope } from '@react-aria/focus';
import { forwardRef } from 'react';
import { IconName } from '../../constants/iconEnumGenerated.ts';
import { ArrowPosition } from '../../uiComponents/Popover/Popover.tsx';
import { SquareButton } from '../../uiComponents/SquareButton/SquareButton.tsx';
import { SquareButtonSize } from '../../uiComponents/SquareButton/squareButtonSize.ts';
import { SquareButtonStyle } from '../../uiComponents/SquareButton/squareButtonStyle.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { PopoverDialog, PopoverPlacement } from '../PopoverDialog/PopoverDialog.tsx';
import { ITriggerProps } from '../PopoverDialog/types/PopoverDialogContract.type.ts';
import { CodenamePopover } from './CodenamePopover.tsx';
import { OnSaveCodename } from './OnSaveCodename.type.ts';

type PopoverDialogTriggerProps = ITriggerProps & {
  readonly tooltipPlacement?: Placement;
  readonly triggerClassNames?: string;
};

const PopoverDialogTrigger = forwardRef<HTMLDivElement, PopoverDialogTriggerProps>(
  (triggerProps, forwardedRef) => (
    <div className={triggerProps.triggerClassNames} ref={forwardedRef}>
      <SquareButton
        iconName={IconName.BracesOctothorpe}
        ariaLabel="Change codename"
        style={SquareButtonStyle.Quinary}
        size={SquareButtonSize.Quinary}
        onClick={triggerProps.onToggle}
        tooltipPlacement={triggerProps.tooltipPlacement}
        tooltipText={triggerProps.isActive ? undefined : 'Codename'}
        isActive={triggerProps.isActive}
        {...getDataUiActionAttribute(DataUiAction.GetCodename)}
      />
      {triggerProps.popover}
    </div>
  ),
);

type Props = {
  readonly codename?: string | null;
  readonly codenamePopoverArrowPosition?: ArrowPosition;
  readonly isCodenameEditable?: boolean;
  readonly maxCodenameLength?: number;
  readonly onCodenameSave?: OnSaveCodename;
  readonly onCodenameChange?: () => void;
  readonly onCodenameCopy?: () => void;
  readonly onCodenameEdit?: () => void;
  readonly popoverDialogClassNames: string;
  readonly relatedCodenames?: ReadonlySet<string>;
  readonly snippetCodename?: string | null;
  readonly tooltipPlacement?: Placement;
  readonly triggerClassNames?: string;
};

export const CodenameContentTypeButton = ({
  codename,
  codenamePopoverArrowPosition,
  isCodenameEditable,
  maxCodenameLength,
  onCodenameSave,
  onCodenameCopy,
  onCodenameEdit,
  popoverDialogClassNames,
  relatedCodenames,
  snippetCodename,
  tooltipPlacement,
  triggerClassNames,
}: Props) => {
  if (!codename) {
    return null;
  }

  return (
    <PopoverDialog
      popoverClass={popoverDialogClassNames}
      popoverPlacement={PopoverPlacement.Child}
      trigger={(triggerProps) => (
        <PopoverDialogTrigger
          triggerClassNames={triggerClassNames}
          tooltipPlacement={tooltipPlacement}
          {...triggerProps}
        />
      )}
      popover={(togglePopover) => (
        <FocusScope contain>
          <CodenamePopover
            onAfterCopied={togglePopover}
            codename={codename}
            isEditable={isCodenameEditable}
            onSave={onCodenameSave}
            onEdited={onCodenameEdit}
            relatedCodenames={relatedCodenames}
            onCopied={onCodenameCopy}
            arrowPosition={codenamePopoverArrowPosition}
            maxLength={maxCodenameLength}
            snippetCodename={snippetCodename}
          />
        </FocusScope>
      )}
    />
  );
};
