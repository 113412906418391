import { memoize } from '@kontent-ai/memoization';
import { areCollectionsVisibleForCurrentUser } from '../../../../../_shared/selectors/contentCollections.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { Capability } from '../../../../../_shared/utils/permissions/capability.ts';
import { hasProjectMultipleWorkflows } from '../../../../../_shared/utils/workflow/hasProjectMultipleWorkflows.ts';
import { NewContentItemOrigin } from '../constants/newContentItemOrigin.ts';
import { SelectorType } from '../constants/orderedSelectors.ts';
import { getLanguageOptions } from '../selectors/getLanguageOptions.ts';
import { getNewContentItemDialogProperties } from '../selectors/newContentItemDialog.ts';

export const getNewContentItemVisibleSelectors = (state: IStore): ReadonlySet<SelectorType> => {
  const { origin, selectors: requiredSelectors } = getNewContentItemDialogProperties(state);
  const areCollectionsVisible = areCollectionsVisibleForCurrentUser(state);
  const areMultipleWorkflowsOnProject = hasProjectMultipleWorkflows(state.data.workflows.byId);
  const hasMultipleVisibleLanguageOptions =
    getLanguageOptions(state, Capability.ViewContent).length > 1;

  return _getNewContentItemVisibleSelectors(
    requiredSelectors,
    areCollectionsVisible,
    areMultipleWorkflowsOnProject,
    hasMultipleVisibleLanguageOptions,
    origin,
  );
};

const _getNewContentItemVisibleSelectors = memoize.maxOne(
  (
    requiredSelectors: ReadonlySet<SelectorType>,
    areCollectionsVisible: boolean,
    areMultipleWorkflowsOnProject: boolean,
    hasMultipleVisibleLanguageOptions: boolean,
    origin: NewContentItemOrigin,
  ): ReadonlySet<SelectorType> => {
    const visibilityMap: Record<SelectorType, boolean> = {
      [SelectorType.Name]: origin === NewContentItemOrigin.Space,
      [SelectorType.Collection]: areCollectionsVisible,
      [SelectorType.Language]: hasMultipleVisibleLanguageOptions,
      [SelectorType.Workflow]: areMultipleWorkflowsOnProject,
      [SelectorType.ContentType]: true,
    };

    const selectors = Array.from(requiredSelectors.values()).filter(
      (selector) => visibilityMap[selector],
    );

    return new Set(selectors);
  },
);
