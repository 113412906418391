import { SubheaderDivider } from '@kontent-ai/component-library/Dividers';
import React from 'react';
import { FeatureState } from '../../../../_shared/components/FeatureStateTag.tsx';
import { IconName } from '../../../../_shared/constants/iconEnumGenerated.ts';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { TypeElementType } from '../../../contentInventory/content/models/ContentItemElementType.ts';
import { DraggableTypeElementLibraryItem } from './DraggableTypeElementLibraryItem.tsx';

export type TypeElementItemConfig = {
  readonly disabledMessage?: string;
  readonly elementType: TypeElementType;
  readonly featureState?: FeatureState;
  readonly iconName: IconName;
  readonly isDisabled?: boolean;
  readonly name: string;
};

export interface ITypeElementLibraryStateProps {
  readonly auxiliaryElementToolbarItemConfigurations?: ReadonlyArray<TypeElementItemConfig>;
  readonly contentElementToolbarItemConfigurations?: ReadonlyArray<TypeElementItemConfig>;
}

export interface ITypeElementLibraryDispatchProps {
  readonly onContentElementClicked: (elementType: TypeElementType) => void;
  readonly onDragEnd: () => void;
  readonly onDragStart: () => void;
}

type Props = ITypeElementLibraryStateProps & ITypeElementLibraryDispatchProps;

export const TypeElementLibrary: React.FC<Props> = ({
  auxiliaryElementToolbarItemConfigurations,
  contentElementToolbarItemConfigurations,
  onContentElementClicked,
  onDragEnd,
  onDragStart,
}) => (
  <div className="canvas__sidebar-content canvas__sidebar-content--in-content-models">
    {contentElementToolbarItemConfigurations && (
      <div className="toolset">
        <div className="toolset__title">
          <SubheaderDivider text="Content elements" />
        </div>
        <div
          className="toolset__element-list"
          {...getDataUiCollectionAttribute(DataUiCollection.ContentElementList)}
        >
          {contentElementToolbarItemConfigurations.map((config: TypeElementItemConfig) => (
            <DraggableTypeElementLibraryItem
              disabledMessage={config.disabledMessage}
              elementType={config.elementType}
              featureState={config.featureState}
              iconName={config.iconName}
              isDisabled={config.isDisabled}
              key={config.elementType}
              name={config.name}
              onClick={onContentElementClicked}
              onDragEnd={onDragEnd}
              onDragStart={onDragStart}
            />
          ))}
        </div>
      </div>
    )}

    {auxiliaryElementToolbarItemConfigurations && (
      <div className="toolset">
        <div className="toolset__title">
          <SubheaderDivider text="Supporting elements" />
        </div>
        <div
          className="toolset__element-list"
          {...getDataUiCollectionAttribute(DataUiCollection.SupportingElementList)}
        >
          {auxiliaryElementToolbarItemConfigurations.map((config) => (
            <DraggableTypeElementLibraryItem
              disabledMessage={config.disabledMessage}
              elementType={config.elementType}
              iconName={config.iconName}
              isDisabled={config.isDisabled}
              key={config.elementType}
              name={config.name}
              onClick={onContentElementClicked}
              onDragEnd={onDragEnd}
              onDragStart={onDragStart}
            />
          ))}
        </div>
      </div>
    )}
  </div>
);

TypeElementLibrary.displayName = 'TypeElementLibrary';
