import { createGuid } from '@kontent-ai/utils';
import { repositoryCollection } from '../../_shared/repositories/repositories.ts';
import { initializeRequestTokenFactory } from '../../_shared/utils/requestTokenUtils.ts';
import { assetService } from '../../applications/contentInventory/content/features/Asset/services/assetService.ts';
import { buildFilterWithContinuationForListingContentItems } from '../../applications/contentInventory/content/utils/buildFilterWithContinuationForListingContentItems.ts';
import { createContentItemFilterServerModelFromFilter } from '../../applications/contentInventory/content/utils/contentItemListModelConverters.ts';
import { createLoadAssetFoldersAction } from './thunks/assets/loadAssetFolders.ts';
import { loadAssetsActionCreator } from './thunks/assets/loadAssets.ts';
import {
  createLoadListingAssetsAction,
  createTokenizedListingAssetsLoadingStarted,
} from './thunks/assets/loadListingAssets.ts';
import {
  createLoadAuditEventsAction,
  createTokenizedAuditLogEventsLoadingStarted,
} from './thunks/auditLog/loadAuditEvents.ts';
import { createLoadCollectionsAction } from './thunks/collections/loadCollections.ts';
import { createLoadCustomAppsAction } from './thunks/customApps/loadCustomApps.ts';
import { createLoadEntityWebhooksAction } from './thunks/entityWebhooks/loadEntityWebhooks.ts';
import { createLoadFiltersAction } from './thunks/filters/loadFilters.ts';
import { createLoadLanguageUsagesAction } from './thunks/languages/loadLanguageUsages.ts';
import { createLoadLanguagesAction } from './thunks/languages/loadLanguages.ts';
import {
  createLoadListingContentItemsAction,
  createTokenizedListingItemsLoadingStarted,
} from './thunks/listingContentItems/loadListingContentItems.ts';
import { createLoadListingContentItemsByIdsAction } from './thunks/listingContentItems/loadListingContentItemsByIds.ts';
import { createLoadContentItemUsagesAction } from './thunks/loadContentItemUsagesActionCreator.ts';
import { createLoadListingItemsWithAllVariantsAction } from './thunks/loadListingItemsWithAllVariants.ts';
import { createLoadAssetTypeAction } from './thunks/models/loadAssetType.ts';
import {
  createLoadContentTypeAsComponentUsageAction,
  createTokenizedContentTypeAsComponentUsageLoadingStarted,
} from './thunks/models/loadContentTypeAsComponentUsage.ts';
import { createLoadContentTypeAsComponentUsageCountAction } from './thunks/models/loadContentTypeAsComponentUsageCount.ts';
import { createLoadContentTypesAction } from './thunks/models/loadContentTypes.ts';
import { createLoadContentTypesUsageAction } from './thunks/models/loadContentTypesUsage.ts';
import { createLoadLatestProductUpdateAction } from './thunks/models/loadLatestProductUpdateInfo.ts';
import { createLoadProjectContainerActiveUsersAction } from './thunks/models/loadProjectContainerActiveUsers.ts';
import { createLoadProjectsAction } from './thunks/models/loadProjects.ts';
import { createLoadSitemapAction } from './thunks/models/loadSitemap.ts';
import { createLoadSnippetsAction } from './thunks/models/loadSnippets.ts';
import { createLoadSnippetsUsageAction } from './thunks/models/loadSnippetsUsage.ts';
import { createLoadTaxonomyGroupsAction } from './thunks/models/loadTaxonomyGroups.ts';
import { createLoadWorkflowsAction } from './thunks/models/loadWorkflows.ts';
import { createLoadWorkflowsUsageAction } from './thunks/models/loadWorkflowsUsage.ts';
import { createStartPollingProjectsAction } from './thunks/models/startPollingCopyState.ts';
import { createLoadPlansForSelectionAction } from './thunks/plans/loadPlansForSelection.ts';
import { createLoadRolesAction } from './thunks/roles/loadRoles.ts';
import { createLoadSpacesAction } from './thunks/spaces/loadSpaces.ts';
import { createLoadAdministratedSubscriptionsAction } from './thunks/subscriptions/loadAdministratedSubscriptions.ts';
import { createLoadNormalizedRoleWithSettingsAction } from './thunks/user/loadNormalizedRoleWithSettings.ts';
import { createLoadUserInfoAction } from './thunks/user/loadUserInfo.ts';
import { createLoadUserProjectsInfoAction } from './thunks/user/loadUserProjectsInfo.ts';
import { createLoadUsersAction } from './thunks/users/loadUsers.ts';
import { createLoadWebhooksAction } from './thunks/webhooks/loadWebhooks.ts';

const {
  assetFolderRepository,
  assetRenditionRepository,
  assetRepository,
  assetTypeRepository,
  auditLogRepository,
  collectionsRepository,
  contentItemRepository,
  contentTypeRepository,
  contentTypeSnippetRepository,
  customAppRepository,
  filterRepository,
  languageRepository,
  peopleRepository,
  productUpdateRepository,
  projectRepository,
  roleRepository,
  sitemapRepository,
  spacesRepository,
  subscriptionRepository,
  taxonomyRepository,
  userRepository,
  webhookRepository,
  entityWebhookRepository,
  workflowRepository,
} = repositoryCollection;

export const loadAdministratedSubscriptions = createLoadAdministratedSubscriptionsAction({
  subscriptionRepository,
});
export const loadAssetType = createLoadAssetTypeAction({ assetTypeRepository });
export const loadCollections = createLoadCollectionsAction({ collectionsRepository });
export const loadContentTypes = createLoadContentTypesAction({ contentTypeRepository });
export const loadContentTypesUsage = createLoadContentTypesUsageAction({ contentTypeRepository });
export const loadCustomApps = createLoadCustomAppsAction({ customAppRepository });
export const loadFilters = createLoadFiltersAction({ filterRepository });
export const loadLanguages = createLoadLanguagesAction({ languageRepository });
export const loadLanguageUsages = createLoadLanguageUsagesAction({ languageRepository });
export const loadLatestProductUpdateInfo = createLoadLatestProductUpdateAction({
  productUpdateRepository,
});
export const loadListingItemsWithAllVariants = createLoadListingItemsWithAllVariantsAction({
  contentItemRepository,
});
export const loadPlansForSelection = createLoadPlansForSelectionAction({ subscriptionRepository });
export const loadProjectContainerActiveUsers = createLoadProjectContainerActiveUsersAction({
  peopleRepository,
});
export const loadProjects = createLoadProjectsAction({ projectRepository });
export const loadRoles = createLoadRolesAction({ roleRepository });
export const loadSitemap = createLoadSitemapAction({ sitemapRepository });
export const loadSnippets = createLoadSnippetsAction({ contentTypeSnippetRepository });
export const loadSnippetsUsage = createLoadSnippetsUsageAction({ contentTypeSnippetRepository });
export const loadTaxonomyGroups = createLoadTaxonomyGroupsAction({ taxonomyRepository });
export const loadUserInfo = createLoadUserInfoAction({ userRepository });
export const loadUserProjectsInfo = createLoadUserProjectsInfoAction({ userRepository });
export const loadNormalizedRoleWithSettings = createLoadNormalizedRoleWithSettingsAction({
  roleRepository,
});
export const loadUsers = createLoadUsersAction({ peopleRepository });
export const loadWorkflows = createLoadWorkflowsAction({ workflowRepository });
export const loadWebhooks = createLoadWebhooksAction({
  webhookRepository,
  loadWorkflows,
});
export const loadEntityWebhooks = createLoadEntityWebhooksAction({ entityWebhookRepository });

export const loadWorkflowsUsage = createLoadWorkflowsUsageAction({ workflowRepository });
export const startPollingProjects = createStartPollingProjectsAction({
  projectRepository,
  loadProjects,
});

const listingRequestTokenFactory = initializeRequestTokenFactory(
  (state) => state.data.listingContentItems.raceConditionToken,
  createTokenizedListingItemsLoadingStarted,
  createGuid,
);

const assetsRequestTokenFactory = initializeRequestTokenFactory(
  (state) => state.data.assets.listingAssets.raceConditionToken,
  createTokenizedListingAssetsLoadingStarted,
  createGuid,
);

const auditLogEventsTokenFactory = initializeRequestTokenFactory(
  (state) => state.data.auditLog.cancellationToken,
  createTokenizedAuditLogEventsLoadingStarted,
  createGuid,
);

export const loadListingContentItems = createLoadListingContentItemsAction({
  contentItemRepository,
  buildFilterForListingContentItems: buildFilterWithContinuationForListingContentItems,
  requestTokenFactory: listingRequestTokenFactory,
});

export const loadListingAssets = createLoadListingAssetsAction({
  assetRepository,
  requestTokenFactory: assetsRequestTokenFactory,
});

export const loadAssetFolders = createLoadAssetFoldersAction({
  assetFolderRepository,
});

export const loadAssets = loadAssetsActionCreator({
  assetService,
  assetRenditionRepository,
});

export const loadAuditEvents = createLoadAuditEventsAction({
  auditLogRepository,
  requestTokenFactory: auditLogEventsTokenFactory,
});

export const loadContentItemUsage = createLoadContentItemUsagesAction({
  contentItemRepository,
});

const loadContentTypeAsComponentUsageTokenFactory = initializeRequestTokenFactory(
  (state) => state.data.contentTypeComponentsUsage.cancellationToken,
  createTokenizedContentTypeAsComponentUsageLoadingStarted,
  createGuid,
);

export const loadContentTypeAsComponentUsage = createLoadContentTypeAsComponentUsageAction({
  buildFilterForListingContentItems: buildFilterWithContinuationForListingContentItems,
  contentItemRepository,
  requestTokenFactory: loadContentTypeAsComponentUsageTokenFactory,
});

export const loadContentTypeAsComponentUsageCount =
  createLoadContentTypeAsComponentUsageCountAction({
    buildFilterForListingContentItems: createContentItemFilterServerModelFromFilter,
    contentItemRepository,
  });

export const loadSpaces = createLoadSpacesAction({
  spacesRepository,
});

export const loadListingContentItemsByIds = createLoadListingContentItemsByIdsAction({
  contentItemRepository,
});
