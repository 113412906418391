import { memoize } from '@kontent-ai/memoization';
import { createCompare, naturally } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { useMemo } from 'react';
import {
  CreateNewContentTypeSnippetsRoute,
  EnvironmentRouteParams,
} from '../../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import { getCurrentProjectId } from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { filterEntitiesMap } from '../../../../_shared/utils/filter/nameFilterUtils.ts';
import { compose } from '../../../../_shared/utils/func/compose.ts';
import { buildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { IContentTypeSnippet } from '../../../../data/models/contentModelsApp/snippets/ContentTypeSnippet.ts';
import { contentTypeSnippetListingFilterSearchPhraseChanged } from '../actions/snippetsActions.ts';
import {
  archiveContentTypeSnippets,
  initContentTypeSnippetListing,
  selectContentTypeSnippets,
  undoContentTypeSnippetListingOperation,
  unselectContentTypeSnippets,
} from '../actions/thunkSnippetsActions.ts';
import { ContentTypeSnippetListing as ContentTypeSnippetListingComponent } from '../components/ContentTypeSnippetListing.tsx';
import { getSnippetsAppListing } from '../selectors/getSnippetsAppListing.ts';
import { ContentTypeSnippetListingOperationType } from '../utils/contentTypeSnippetListingOperationStatus.ts';

const getUsageCountPerSnippet = memoize.weak(
  (snippetUsage: Immutable.Map<Uuid, ReadonlyArray<Uuid>>): Immutable.Map<Uuid, number> =>
    snippetUsage.reduce(
      (usageCount: Immutable.Map<Uuid, number>, usages: ReadonlyArray<Uuid>, snippetId: Uuid) =>
        usageCount.set(snippetId, usages.length),
      Immutable.Map<Uuid, number>(),
    ),
);

const getOrderedContentTypeSnippets = memoize.weak(
  (
    snippets: Immutable.Map<Uuid, IContentTypeSnippet>,
    filterSearchPhrase: string,
  ): ReadonlyArray<IContentTypeSnippet> => {
    const filteredSnippets = filterEntitiesMap<IContentTypeSnippet>(filterSearchPhrase, snippets, [
      (snippet) => snippet.name,
    ]);

    return filteredSnippets
      .sort(
        createCompare({
          compare: naturally,
          select: (t) => t.name,
        }),
      )
      .toArray();
  },
);

export const ContentTypeSnippetListing = () => {
  const contentTypeSnippets = useSelector((state) =>
    getOrderedContentTypeSnippets(
      state.data.snippets.byId,
      getSnippetsAppListing(state).filterSearchPhrase,
    ),
  );
  const usageCountPerContentTypeSnippet = useSelector((state) =>
    getUsageCountPerSnippet(state.data.snippets.snippetUsage),
  );
  const currentProjectId = useSelector(getCurrentProjectId);
  const contentTypeSnippetListingLoadingStatus = useSelector(
    (state) => getSnippetsAppListing(state).loadingStatus,
  );
  const filterSearchPhrase = useSelector(
    (state) => getSnippetsAppListing(state).filterSearchPhrase,
  );
  const listingOperationStatus = useSelector(
    (state) => getSnippetsAppListing(state).listingOperationStatus,
  );
  const selectedContentTypeSnippets = useSelector(
    (state) => getSnippetsAppListing(state).selectedContentTypeSnippets,
  );
  const createNewSnippetPath = buildPath<EnvironmentRouteParams>(
    CreateNewContentTypeSnippetsRoute,
    { projectId: currentProjectId },
  );

  const dispatch = useDispatch();
  const onFilterContentTypeSnippets = useMemo(
    () => compose(dispatch, contentTypeSnippetListingFilterSearchPhraseChanged),
    [],
  );
  const onSelectContentTypeSnippets = useMemo(
    () => compose(dispatch, selectContentTypeSnippets),
    [],
  );
  const onUnselectContentTypeSnippets = useMemo(
    () => compose(dispatch, unselectContentTypeSnippets),
    [],
  );
  const onArchiveContentTypeSnippets = useMemo(
    () => compose(dispatch, archiveContentTypeSnippets),
    [],
  );
  const onUndoOperation = useMemo(
    () => compose(dispatch, undoContentTypeSnippetListingOperation),
    [],
  );

  useThunkPromise(initContentTypeSnippetListing);

  return (
    <ContentTypeSnippetListingComponent
      contentTypeSnippetListingLoadingStatus={contentTypeSnippetListingLoadingStatus}
      contentTypeSnippets={contentTypeSnippets}
      createNewSnippetPath={createNewSnippetPath}
      currentProjectId={currentProjectId}
      filterSearchPhrase={filterSearchPhrase}
      listingOperationStatus={listingOperationStatus}
      onArchiveContentTypeSnippets={onArchiveContentTypeSnippets}
      onFilterContentTypeSnippets={onFilterContentTypeSnippets}
      onSelectContentTypeSnippets={onSelectContentTypeSnippets}
      onUndoOperation={
        listingOperationStatus.operationType === ContentTypeSnippetListingOperationType.Archived
          ? onUndoOperation
          : undefined
      }
      onUnselectContentTypeSnippets={onUnselectContentTypeSnippets}
      selectedContentTypeSnippets={selectedContentTypeSnippets}
      usageCountPerContentTypeSnippet={usageCountPerContentTypeSnippet}
    />
  );
};
