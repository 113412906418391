import React, { ComponentProps } from 'react';
import { DataTableRow } from '../../../../../_shared/components/DataTable/DataTableRow.tsx';
import { LinkDataTableCell } from '../../../../../_shared/components/DataTable/LinkDataTableCell.tsx';
import { formatDatetimeToReadable } from '../../../../../_shared/utils/dateTime/datetimeUtils.ts';
import { pluralizeWithCount } from '../../../../../_shared/utils/stringUtils.ts';
import { IListingVariantData } from '../../../../../data/models/listingContentItems/IListingVariantData.ts';
import { IProjectContributor } from '../../../../../data/models/users/ProjectContributor.ts';
import { ScrollTableItemContributors } from '../../../contentStatus/components/ScrollTableItemContributors.tsx';
import { daysSinceDateTimeStamp } from '../../../utils/daysSinceDate.ts';

type UnchangedPublishedItemsWidgetDetailRowProps = Readonly<{
  collectionName: string;
  contributors: ReadonlyArray<IProjectContributor>;
  itemName: string;
  linkPath: string | null;
  onClick?: () => void;
  variant: IListingVariantData;
  variantName: string;
}>;

export const UnchangedPublishedItemsWidgetDetailRow: React.FC<
  UnchangedPublishedItemsWidgetDetailRowProps
> = ({ collectionName, contributors, itemName, linkPath, onClick, variant, variantName }) => {
  const itemId = `${variant.id.itemId}:${variant.id.variantId}`;
  const lastPublishedAt =
    variant.lastPublishedAt && formatDatetimeToReadable(new Date(variant.lastPublishedAt), false);

  const commonCellProps = { linkPath, onClick } satisfies ComponentProps<typeof LinkDataTableCell>;

  return (
    <DataTableRow dataUiObjectName={itemId} id={itemId}>
      <LinkDataTableCell {...commonCellProps}>{itemName}</LinkDataTableCell>
      <LinkDataTableCell {...commonCellProps}>{variantName}</LinkDataTableCell>
      <LinkDataTableCell {...commonCellProps}>{collectionName}</LinkDataTableCell>
      <LinkDataTableCell {...commonCellProps}>
        <ScrollTableItemContributors contributors={contributors} />
      </LinkDataTableCell>
      <LinkDataTableCell {...commonCellProps}>{lastPublishedAt}</LinkDataTableCell>
      <LinkDataTableCell {...commonCellProps}>
        {pluralizeWithCount('day', daysSinceDateTimeStamp(variant.lastPublishedAt))}
      </LinkDataTableCell>
    </DataTableRow>
  );
};

UnchangedPublishedItemsWidgetDetailRow.displayName = 'UnchangedPublishedItemsWidgetDetailRow';
