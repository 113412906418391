import { ReactNode, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { loadSubscriptionProperties } from '../../../../_shared/actions/thunkSharedActions.ts';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { SubscriptionRouteParams } from '../../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import { getSelectedSubscription } from '../../../../_shared/selectors/subscriptionSelectors.ts';
import { isUuid } from '../../../../_shared/utils/validation/typeValidators.ts';
import { clearSelectedSubscription } from '../actions/subscriptionManagementActions.ts';
import { ensureSelectedSubscription } from '../actions/thunkSubscriptionManagementActions.ts';

type Props = {
  readonly children: ReactNode;
};

export const EnsureSelectedSubscription = ({ children }: Props) => {
  const history = useHistory();
  const { subscriptionId } = useParams<SubscriptionRouteParams>();

  const [isEnsureSelectedSubscriptionThunkDone] = useThunkPromise(
    ensureSelectedSubscription,
    subscriptionId,
    history,
  );

  const isStateEnsured = useSelector((state) => {
    return (
      isEnsureSelectedSubscriptionThunkDone &&
      isUuid(subscriptionId) &&
      getSelectedSubscription(state)?.subscriptionId === subscriptionId &&
      !!state.data.subscriptions.subscriptionUsages.size
    );
  });

  useThunkPromise(loadPropertiesForSubscription, subscriptionId ?? null, {
    canRun: isStateEnsured && !!subscriptionId,
  });

  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(clearSelectedSubscription());
    };
  }, []);

  return isStateEnsured ? children : <Loader />;
};

const loadPropertiesForSubscription =
  (subscriptionId: Uuid | null, abortSignal: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    if (subscriptionId) {
      await dispatch(loadSubscriptionProperties(subscriptionId, abortSignal));
    }
  };
