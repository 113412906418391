import { trackUserEvent } from '../../../_shared/actions/thunks/trackUserEvent.ts';
import { repositoryCollection } from '../../../_shared/repositories/repositories.ts';
import { loadEntityWebhooks, loadWebhooks } from '../../../data/actions/thunkDataActions.ts';
import { createArchiveWebhookAction } from './thunks/archiveWebhook.ts';
import { createNewWebhookSettingCreator } from './thunks/createNewWebhookSetting.ts';
import { createDisableWebhookAction } from './thunks/disableWebhook.ts';
import { createEnableWebhookAction } from './thunks/enableWebhook.ts';
import { createGenerateWebhookSecretAction } from './thunks/generateWebhookSecret.ts';
import { createInitWebhookListingAction } from './thunks/initWebhookListing.ts';
import { createLoadWebhookMessageContentCreator } from './thunks/loadWebhookMessageContent.ts';
import { createLoadWebhookMessageListingCreator } from './thunks/loadWebhookMessageListing.ts';
import { createResetWebhookAction } from './thunks/resetWebhook.ts';
import { createInsertWebhookAction, createUpdateSaveWebhookAction } from './thunks/saveWebhook.ts';

const { webhookRepository } = repositoryCollection;

export const initWebhookListing = createInitWebhookListingAction({
  loadWebhooks,
});

export const insertWebhook = createInsertWebhookAction({
  webhookRepository,
  trackUserEvent,
});

export const updateWebhook = createUpdateSaveWebhookAction({
  webhookRepository,
  trackUserEvent,
});

export const archiveWebhook = createArchiveWebhookAction({
  webhookRepository,
  trackUserEvent,
});

export const disableWebhook = createDisableWebhookAction({
  webhookRepository,
  trackUserEvent,
});

export const enableWebhook = createEnableWebhookAction({
  webhookRepository,
  trackUserEvent,
});

export const resetWebhook = createResetWebhookAction({
  webhookRepository,
  trackUserEvent,
});

export const generateWebhookSecret = createGenerateWebhookSecretAction({
  webhookRepository,
});

export const createNewWebhookSetting = createNewWebhookSettingCreator();

export const loadWebhookMessageListing = createLoadWebhookMessageListingCreator({
  webhookRepository,
  loadWebhooks,
  loadEntityWebhooks,
});

export const loadWebhookMessageContent = createLoadWebhookMessageContentCreator({
  webhookRepository,
});
