import { Box } from '@kontent-ai/component-library/Box';
import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Label, LabelSize } from '@kontent-ai/component-library/Label';
import { Menu } from '@kontent-ai/component-library/Menu';
import { useOurFocusRing } from '@kontent-ai/component-library/hooks';
import {
  BorderRadius,
  Size,
  Spacing,
  colorBackground,
  colorBackgroundSelected,
  colorBorderDefault,
  colorPrimaryBorder,
  colorPrimaryHoverInverse,
  colorTextDefault,
  offsetFocus,
  px,
  shadowFocusStyles,
  transition250,
} from '@kontent-ai/component-library/tokens';
import { useIdWithPrefix } from '@kontent-ai/hooks';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import classNames from 'classnames';
import { ChangeEventHandler, RefObject, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { DialogState } from '../../../../../../../../component-library/components/Dialogs/DialogStateEnum.ts';
import { usePopoverDialog } from '../../../../../../../../component-library/components/Dialogs/Popover/usePopoverDialog.tsx';
import { addFlipping } from '../../../../../../../../component-library/components/Dialogs/Popover/utils/tippyOptionsUtils.ts';
import { HotkeysHandler } from '../../../../../../../_shared/components/Hotkeys/HotkeysHandler.tsx';
import { ConfirmationDialog } from '../../../../../../../_shared/components/ModalDialog/ConfirmationDialog.tsx';
import { ValidationConstants } from '../../../../../../../_shared/constants/validationConstants.ts';
import { TextField } from '../../../../../../../_shared/uiComponents/TextField/TextField.tsx';
import {
  DataUiAction,
  DataUiElement,
  DataUiInput,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ISavedFilter } from '../../../../../../../data/models/filters/ISavedFilter.ts';
import { useIsRefinedNavigationFeatureEnabled } from '../../../../../../refinedNavigation/contexts/RefinedNavigationContext.tsx';
import { UntitledFilter } from '../../constants/uiConstants.ts';
import { SaveFilterModalDialog } from '../SaveFilterModalDialog.tsx';
import { EditSavedFilter } from './EditSavedFilter.tsx';

type Props = {
  readonly filter: ISavedFilter;
  readonly isActive: boolean;
  readonly isBeingDeleted: boolean;
  readonly isBeingSaved: boolean;
  readonly onFilterDelete: (filter: ISavedFilter) => void;
  readonly onFilterNameClick: (filter: ISavedFilter) => void;
  readonly onFilterRename: (filter: ISavedFilter) => void;
};

export const SavedFiltersListingItem = ({
  filter,
  isActive,
  isBeingDeleted,
  isBeingSaved,
  onFilterDelete,
  onFilterNameClick,
  onFilterRename,
}: Props) => {
  const [filterName, setFilterName] = useState<string>(filter.name);
  const inputId = useIdWithPrefix('FilterName');
  const filterNameInputRef = useRef<HTMLInputElement>(null);
  const handleNameClick = () => {
    if (!isBeingDeleted && !isActive) {
      onFilterNameClick(filter);
    }
  };
  const handleSave = () => {
    onFilterRename({
      ...filter,
      name: filterName,
    });
    closeDialog();
  };

  const isRefinedNavigationFeatureEnabled = useIsRefinedNavigationFeatureEnabled();
  const { isFocusVisible, focusProps } = useOurFocusRing();

  const {
    PopoverDialog,
    popoverDialogProps,
    isOpen: isEditOpen,
    openDialog,
    closeDialog,
    targetProps,
  } = usePopoverDialog({
    adjustTippyOptions: addFlipping,
    autoFocusRef: filterNameInputRef,
    dialogState: DialogState.Default,
    extraAction: {
      text: 'Delete',
      onClick: () => onFilterDelete(filter),
      destructive: true,
      ...getDataUiActionAttribute(DataUiAction.Delete),
    },
    headline: 'Filter name',
    onClose: () => setFilterName(filter.name),
    placement: 'right-start',
    primaryAction: {
      text: 'Save',
      onClick: handleSave,
      ...getDataUiActionAttribute(DataUiAction.Save),
    },
    cancelAction: { destructive: false, ...getDataUiActionAttribute(DataUiAction.Cancel) },
    shouldCloseOnBlur: false,
    shouldCloseOnInteractOutside: () => true,
  });

  const handleFilterNameChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setFilterName(event.target.value);
  };

  const [openedModalDialog, setOpenedModalDialog] = useState<'none' | 'rename' | 'delete'>('none');

  if (!isRefinedNavigationFeatureEnabled) {
    return (
      <div
        className={classNames('saved-filters__list-item', {
          'saved-filters__list-item--active': isActive,
          'saved-filters__list-item--disabled': isBeingDeleted,
        })}
        onClick={handleNameClick}
        {...getDataUiActionAttribute(DataUiAction.Select)}
      >
        <HotkeysHandler
          className={classNames('saved-filters__list-item-text', {
            'saved-filters__list-item-text--active': isActive,
            'saved-filters__list-item-text--disabled': isBeingDeleted,
          })}
          handlers={{
            onEnter: handleNameClick,
          }}
        >
          <span title={filter.name} tabIndex={isBeingDeleted || isActive ? undefined : 0}>
            {filter.name}
          </span>
        </HotkeysHandler>
        <div className="btn-wrapper" {...targetProps}>
          <EditSavedFilter
            isActive={isEditOpen}
            isAlwaysVisible={isEditOpen}
            isSaving={isBeingSaved || isBeingDeleted}
            onClick={openDialog}
          />
        </div>
        <PopoverDialog
          {...popoverDialogProps}
          {...getDataUiElementAttribute(DataUiElement.FilterForm)}
        >
          <HotkeysHandler
            handlers={{
              onEnter: handleSave,
              onEscape: closeDialog,
            }}
          >
            <TextField
              autoFocus
              dataUiInputName={DataUiInput.FilterName}
              disabled={isBeingSaved || isBeingDeleted}
              inputId={inputId}
              maxLength={ValidationConstants.FilterNameMaxLength}
              onChange={handleFilterNameChange}
              placeholder={UntitledFilter}
              ref={filterNameInputRef}
              value={filterName}
            />
          </HotkeysHandler>
        </PopoverDialog>
      </div>
    );
  }

  return (
    <>
      <SavedFilterPillWrapper
        isFocusVisible={isFocusVisible}
        onClick={handleNameClick}
        $isActive={isActive}
      >
        <VisuallyHidden>
          <button
            aria-label={`filter by ${filter.name}`}
            onClick={handleNameClick}
            tabIndex={isBeingDeleted || isActive ? -1 : undefined}
            type="button"
            {...focusProps}
          />
        </VisuallyHidden>
        <Inline noWrap spacing={Spacing.S} align="center" css="min-width: 0;">
          <Box
            display="flex"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            component="span"
          >
            <Label size={LabelSize.L} color={colorTextDefault} hasEmphasis={isActive}>
              {filter.name}
            </Label>
          </Box>
          {isActive && (
            <Menu>
              <Menu.Trigger>
                {(ref: RefObject<HTMLButtonElement>, triggerProps, isOpen) => (
                  <QuinaryButton
                    aria-label={`show more actions for ${filter.name}`}
                    tooltipText="More actions"
                    ref={ref}
                    {...triggerProps}
                    activated={isOpen}
                  >
                    <QuinaryButton.Icon icon={Icons.Cogwheel} />
                  </QuinaryButton>
                )}
              </Menu.Trigger>
              <Menu.List>
                <Menu.Item
                  label="Rename"
                  id="rename"
                  onAction={() => setOpenedModalDialog('rename')}
                />
                <Menu.Item
                  label="Delete"
                  id="delete"
                  onAction={() => setOpenedModalDialog('delete')}
                  menuItemState="destructive"
                />
              </Menu.List>
            </Menu>
          )}
        </Inline>
      </SavedFilterPillWrapper>
      {openedModalDialog === 'delete' && (
        <ConfirmationDialog
          alert
          confirmButtonText="Delete filter"
          headerContent={`Delete the ${filter.name} filter`}
          isOpen
          onClose={() => setOpenedModalDialog('none')}
          onConfirm={() => onFilterDelete(filter)}
        >
          Do you want to delete the &quot;{filter.name}&quot; filter?
        </ConfirmationDialog>
      )}
      {openedModalDialog === 'rename' && (
        <SaveFilterModalDialog
          headline="Rename filter"
          onSave={(name) =>
            onFilterRename({
              ...filter,
              name,
            })
          }
          onClose={() => setOpenedModalDialog('none')}
          name={filter.name}
        />
      )}
    </>
  );
};

export const SavedFilterPillWrapper = styled.div<{
  readonly isFocusVisible: boolean;
  readonly $isActive: boolean;
}>`
  position: relative;
  display: inline-flex;
  align-items: center;
  height: ${px(Size.S)};
  padding: 0 ${px(Spacing.L)};
  border: ${({ $isActive }) => ($isActive ? `2px solid ${colorPrimaryBorder}` : `1px solid ${colorBorderDefault}`)};
  border-radius: ${px(BorderRadius.Pill)};
  
  min-width: 0;

  transition: border-color ${transition250}, background-color ${transition250};
  cursor: ${({ $isActive }) => ($isActive ? undefined : 'pointer')};
  user-select: none;

  &::before {
    ${({ isFocusVisible }) => isFocusVisible && shadowFocusStyles};
    inset: ${px(-1 * offsetFocus)};
    content: ' ';
    position: absolute;
    border-radius: inherit;
    pointer-events: none;
  }

  ${({ $isActive }) =>
    $isActive
      ? css`background-color: ${colorBackgroundSelected};`
      : css`
        background-color: ${colorBackground};

        &:hover {
          background-color: ${colorPrimaryHoverInverse};
        }
      `};
`;
