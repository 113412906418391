import { Badge } from '@kontent-ai/component-library/Badge';
import { Icons } from '@kontent-ai/component-library/Icons';
import {
  Menu,
  TabbableMenuItem,
  TabbableMenuOutwardLink,
} from '@kontent-ai/component-library/Menu';
import { NavigationBarQuinaryMenuButton } from '@kontent-ai/component-library/NavigationBar';
import { ConditionalWrapper } from '@kontent-ai/component-library/component-utils';
import { useCallback, useContext } from 'react';
import { RefinedNavigationContext } from '../../../applications/refinedNavigation/contexts/RefinedNavigationContext.tsx';
import { loadLatestProductUpdateInfo } from '../../../data/actions/thunkDataActions.ts';
import { productUpdateLinkOpened } from '../../actions/thunkSharedActions.ts';
import { trackUserEvent } from '../../actions/thunks/trackUserEvent.ts';
import { documentationLinks } from '../../constants/documentationLinks.ts';
import { TrackedEvent } from '../../constants/trackedEvent.ts';
import { useDispatch } from '../../hooks/useDispatch.ts';
import { useSelector } from '../../hooks/useSelector.ts';
import { useThunkPromise } from '../../hooks/useThunkPromise.ts';
import {
  hasUserSeenLatestProjectUpdates,
  isProjectTrial,
} from '../../selectors/userProjectsInfoSelectors.ts';
import { IStore } from '../../stores/IStore.type.ts';
import {
  DataUiAction,
  DataUiCollection,
  DataUiExternalLinkName,
  getDataUiActionAttribute,
  getDataUiCollectionAttribute,
  getDataUiNavAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { HotjarTag, tagRecording } from '../../utils/hotjarUtils.ts';
import { isAccountLessThan30DaysOld } from '../../utils/trialUtils.ts';
import { FeedbackMenuItems } from './FeedbackMenuItems.tsx';

type Props = {
  readonly isExpanded?: boolean;
};

const shouldShowProductUpdatesItemBadge = (state: IStore): boolean =>
  hasUserSeenLatestProjectUpdates(state) &&
  !isProjectTrial(state, state.sharedApp.currentProjectId) &&
  !isAccountLessThan30DaysOld(state.data.user.info);

export const DocsMenu = ({ isExpanded }: Props) => {
  const { isRefinedNavigationFeatureEnabled } = useContext(RefinedNavigationContext);

  const productUpdatesUrl = useSelector((state) => state.data.latestProductUpdateInfo?.url);
  const shouldDisplayProductUpdatesItemBadge = useSelector(shouldShowProductUpdatesItemBadge);

  useThunkPromise(loadLatestProductUpdateInfo);

  const dispatch = useDispatch();

  const onClickOnHelpButton = () => {
    dispatch(trackUserEvent(TrackedEvent.FeatureUsed, { feature: 'help-button' }));
    tagRecording(HotjarTag.HelpButtonClicked);
  };

  const onClickOnProductUpdates = useCallback(
    () => productUpdatesUrl && dispatch(productUpdateLinkOpened(productUpdatesUrl)),
    [productUpdatesUrl],
  );
  const onClickOnDocsLink = () =>
    dispatch(trackUserEvent(TrackedEvent.ResourcesUsed, { resourceType: 'main' }));
  const onClickOnKeyboardShortcutsLink = () =>
    dispatch(trackUserEvent(TrackedEvent.ResourcesUsed, { resourceType: 'keyboard-shortcuts' }));

  return (
    <NavigationBarQuinaryMenuButton
      menuDataAttributes={getDataUiCollectionAttribute(DataUiCollection.DocsMenuOptions)}
      showBadge={shouldDisplayProductUpdatesItemBadge}
      onMenuOpenChange={onClickOnHelpButton}
      trigger={{
        name: isRefinedNavigationFeatureEnabled ? 'Resources' : 'Resources & News',
        icon: Icons.QuestionCircle,
        fullWidth: isRefinedNavigationFeatureEnabled && isExpanded,
        showName: isRefinedNavigationFeatureEnabled && isExpanded,
        ...getDataUiActionAttribute(DataUiAction.OpenDocsMenu),
      }}
      role="list"
    >
      {isRefinedNavigationFeatureEnabled && <FeedbackMenuItems />}

      <ConditionalWrapper
        condition={isRefinedNavigationFeatureEnabled}
        wrapper={(children) => (
          <Menu.Section label="Give us feedback" isFirst>
            {children}
          </Menu.Section>
        )}
      >
        {productUpdatesUrl && (
          <TabbableMenuItem
            id="product-updates"
            label="Product updates"
            href={productUpdatesUrl}
            trailingElements={
              shouldDisplayProductUpdatesItemBadge && <Badge badgeStyle="default" />
            }
            onAction={onClickOnProductUpdates}
            {...getDataUiNavAttribute(DataUiExternalLinkName.ProductUpdates)}
          />
        )}
        <TabbableMenuOutwardLink
          id="docs-tutorials"
          label="Learn Kontent.ai"
          href={documentationLinks.landingPage}
          onAction={onClickOnDocsLink}
          {...getDataUiNavAttribute(DataUiExternalLinkName.Documentation)}
        />
        <TabbableMenuOutwardLink
          id="keyboard-shortcuts"
          label="Keyboard shortcuts"
          href={documentationLinks.keyboardShortcuts}
          onAction={onClickOnKeyboardShortcutsLink}
          {...getDataUiNavAttribute(DataUiExternalLinkName.KeyboardShortcuts)}
        />
      </ConditionalWrapper>
    </NavigationBarQuinaryMenuButton>
  );
};
