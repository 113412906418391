import { useState } from 'react';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { activateFeature } from '../../actions/thunks/activateFeature.ts';
import { deactivateFeature } from '../../actions/thunks/deactivateFeature.ts';
import { AiTranslationFeature as AiTranslationFeatureComponent } from '../../components/Features/AiTranslationFeature.tsx';
import { AiAcceleratorsFeatureCodename } from '../../types/AiAcceleratorsFeatureCodenames.ts';

type Props = {
  readonly codename: AiAcceleratorsFeatureCodename;
  readonly name: string;
  readonly isEnabled: boolean;
};

export const AiTranslationFeature = ({ codename, ...otherProps }: Props) => {
  const [isInProgress, setIsInProgress] = useState(false);
  const dispatch = useDispatch();

  const activate = async () => {
    setIsInProgress(true);
    await dispatch(activateFeature(codename));
    setIsInProgress(false);
  };

  const deactivate = async () => {
    setIsInProgress(true);
    await dispatch(deactivateFeature(codename));
    setIsInProgress(false);
  };

  return (
    <AiTranslationFeatureComponent
      onActivate={activate}
      onDeactivate={deactivate}
      isActivationStateBeingChanged={isInProgress}
      {...otherProps}
    />
  );
};
