import React from 'react';
import { upsertUserProperty } from '../../../../_shared/actions/thunkSharedActions.ts';
import { trackUserEvent } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import {
  UpsellMultipleWorkflowsAfterCreatingStepDismissedServerKey,
  UpsellMultipleWorkflowsInitialDismissedServerKey,
} from '../../../../_shared/models/UserPropertiesServerKeys.ts';
import { getCurrentProjectPlan } from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { hasProjectMultipleWorkflows } from '../../../../_shared/utils/workflow/hasProjectMultipleWorkflows.ts';
import { CommonPlanName } from '../../../../data/constants/CommonPlanName.ts';
import { UpsellMultipleWorkflows as UpsellMultipleWorkflowsComponent } from '../components/UpsellMultipleWorkflows.tsx';

const hasProjectPremiumPlanSelector = (state: IStore) =>
  getCurrentProjectPlan(state).name.startsWith(CommonPlanName.Premium);

const isUpsellVisibleSelector =
  (
    hasDismissedFirstUpsell: boolean,
    hasDismissedUpsellAfterCreatingSeventhOrMoreStep: boolean,
    hasCreatedSeventhOrMoreWorkflowStep: boolean,
  ) =>
  (state: IStore) =>
    (!hasDismissedFirstUpsell ||
      (hasCreatedSeventhOrMoreWorkflowStep && !hasDismissedUpsellAfterCreatingSeventhOrMoreStep)) &&
    !hasProjectMultipleWorkflows(state.data.workflows.byId) &&
    hasProjectPremiumPlanSelector(state);

export const UpsellMultipleWorkflows: React.FC = () => {
  const hasDismissedFirstUpsell = useSelector(
    (state) => state.sharedApp.userProperties.upsellMultipleWorkflowsInitialDismissed,
  );
  const hasCreatedSeventhOrMoreStep = useSelector(
    (state) => state.sharedApp.userProperties.hasCreatedSeventhOrMoreWorkflowStep,
  );
  const hasDismissedUpsellAfterCreatingSeventhOrMoreStep = useSelector(
    (state) => state.sharedApp.userProperties.upsellMultipleWorkflowsAfterCreatingStepDismissed,
  );
  const isUpsellVisible = useSelector(
    isUpsellVisibleSelector(
      hasDismissedFirstUpsell,
      hasDismissedUpsellAfterCreatingSeventhOrMoreStep,
      hasCreatedSeventhOrMoreStep,
    ),
  );

  const dispatch = useDispatch();

  const dismissFirstUpsell = () => {
    // this condition represents a situation when the user didn't dismiss the initial upsell
    // but in the meantime created 7th (or more) workflow step; this condition ensures that the user
    // does not have to close the upsell twice, so it counts as viewing one upsell only
    if (hasCreatedSeventhOrMoreStep) {
      dispatch(
        upsertUserProperty(UpsellMultipleWorkflowsAfterCreatingStepDismissedServerKey, 'true'),
      );
    }

    dispatch(upsertUserProperty(UpsellMultipleWorkflowsInitialDismissedServerKey, 'true'));
    dispatch(trackUserEvent(TrackedEvent.MultipleWorkflowsUpsellViewed));
  };

  const dismissUpsellAfterCreatingWorkflowStep = () => {
    dispatch(
      upsertUserProperty(UpsellMultipleWorkflowsAfterCreatingStepDismissedServerKey, 'true'),
    );
    dispatch(trackUserEvent(TrackedEvent.MultipleWorkflowsUpsellViewed));
  };

  const trackVisitingManageWorkflowsDocs = () =>
    dispatch(
      trackUserEvent(TrackedEvent.DocsVisitManageWorkflows, {
        afterCreatingSevenOrMoreSteps: hasCreatedSeventhOrMoreStep,
      }),
    );

  return (
    <UpsellMultipleWorkflowsComponent
      headline="Create multiple workflows"
      isUpsellVisible={isUpsellVisible}
      onDismiss={
        hasDismissedFirstUpsell ? dismissUpsellAfterCreatingWorkflowStep : dismissFirstUpsell
      }
      trackVisitingManageWorkflowsDocs={trackVisitingManageWorkflowsDocs}
    />
  );
};

UpsellMultipleWorkflows.displayName = 'UpsellMultipleWorkflowsContainer';
