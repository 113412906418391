import React from 'react';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { isAdminOnSubscription } from '../../../../_shared/selectors/subscriptionSelectors.ts';
import {
  getCurrentProjectPlan,
  getSubscriptionIdFromCurrentProject,
} from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { MetricNames } from '../../../../data/models/plans/PaidMetric.ts';
import { getPaidMetric } from '../../../subscriptionManagement/shared/utils/planUtils.ts';
import { RoleConfiguratorMessage as RoleConfiguratorMessageComponent } from '../components/configurator/RoleConfiguratorMessage.tsx';
import { getCustomRolesLimitInfoFromPlan } from '../selectors/customRoleLimit.ts';

type Props = {
  readonly newRoleLink: string;
  readonly isRoleBeingCreated: boolean;
};

export const RoleConfiguratorMessage: React.FC<Props> = ({ newRoleLink, isRoleBeingCreated }) => {
  const projectPlan = useSelector(getCurrentProjectPlan);
  const customRolesLimitInfo = useSelector((s) =>
    getCustomRolesLimitInfoFromPlan(projectPlan, s.data.roles.rolesById),
  );
  const isSubscriptionAdmin = useSelector((s) =>
    isAdminOnSubscription(s, getSubscriptionIdFromCurrentProject(s)),
  );
  const editedRole = useSelector((s) => s.rolesApp.editorUi.editedRole);

  const customRolesPaidMetric = getPaidMetric(projectPlan, MetricNames.ProjectCustomRolesCount);

  return (
    <RoleConfiguratorMessageComponent
      currentPlan={projectPlan}
      customRolesLimitInfo={customRolesLimitInfo}
      customRolesPaidMetric={customRolesPaidMetric}
      isRoleBeingCreated={isRoleBeingCreated}
      isSubscriptionAdmin={isSubscriptionAdmin}
      newRoleLink={newRoleLink}
      role={editedRole}
    />
  );
};

RoleConfiguratorMessage.displayName = 'RoleConfiguratorMessage';
