import { Button } from '@kontent-ai/component-library/Button';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { noOperation, not } from '@kontent-ai/utils';
import { RefObject, useRef, useState } from 'react';
import { ContentItemsSearchArea } from '../../../applications/contentInventory/content/components/ContentItemsSearchArea.tsx';
import { EnsureInventoryRelatedData } from '../../../applications/contentInventory/content/features/ListingFilter/containers/EnsureInventoryRelatedData.tsx';
import { EnsureFullTextSearchStatus } from '../../../applications/contentInventory/shared/containers/EnsureFullTextSearchStatus.tsx';
import { ModalDialog } from '../../../applications/itemEditor/features/ContentItemEditing/components/modalDialog/ModalDialog.tsx';
import { useIsRefinedNavigationFeatureEnabled } from '../../../applications/refinedNavigation/contexts/RefinedNavigationContext.tsx';
import { Loader } from '../../components/Loader.tsx';
import { ShortcutSymbols } from '../../constants/shortcutSymbols.ts';
import { NotificationBar } from '../../containers/NotificationBar.tsx';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

type Props = {
  readonly getContentItemPath?: (contentItemId: Uuid) => string | null;
  readonly isFullTextSearchForceDisabled?: boolean;
  readonly isInitRetrieving: boolean;
  readonly onCancel: () => void;
  readonly renderItemFilter: (hideFilter: () => void) => JSX.Element;
  readonly renderScrollTable: (ref: RefObject<HTMLDivElement>) => JSX.Element;
  readonly titleBarText: string;
};

export const ModalContentItemSelectorLayout = (props: Props) => {
  const contentPaneRef = useRef<HTMLDivElement>(null);
  const isRefinedNavigationFeatureEnabled = useIsRefinedNavigationFeatureEnabled();

  return (
    <ModalDialog
      buttons={
        <Button
          tooltipText="Cancel"
          tooltipPlacement="top"
          tooltipShortcuts={ShortcutSymbols.Escape}
          buttonStyle="secondary"
          onClick={props.onCancel}
          {...getDataUiActionAttribute(DataUiAction.Cancel)}
        >
          Cancel
        </Button>
      }
      onCancel={props.onCancel}
      titleBarText={props.titleBarText}
    >
      <div className="canvas__workspace canvas__workspace--in-modal">
        <EnsureInventoryRelatedData>
          {!isRefinedNavigationFeatureEnabled && props.renderItemFilter(noOperation)}
          <section className="canvas__content">
            <div className="canvas__notifications">
              <NotificationBar />
            </div>
            <div
              ref={contentPaneRef}
              className="canvas__content-pane
                         canvas__content-pane--no-bottom-offset
                         canvas__content-pane--without-vertical-scrollbar
                         canvas__content-pane--in-modal"
            >
              <div className="row">
                <div className="col-sm-24">
                  <EnsureFullTextSearchStatus>
                    <Stack spacing={Spacing.L}>
                      <FilterArea
                        renderItemFilter={props.renderItemFilter}
                        isFullTextSearchForceDisabled={props.isFullTextSearchForceDisabled}
                      />
                      {props.isInitRetrieving ? (
                        <Loader />
                      ) : (
                        props.renderScrollTable(contentPaneRef)
                      )}
                    </Stack>
                  </EnsureFullTextSearchStatus>
                </div>
              </div>
            </div>
          </section>
        </EnsureInventoryRelatedData>
      </div>
    </ModalDialog>
  );
};

const FilterArea = ({
  isFullTextSearchForceDisabled,
  renderItemFilter,
}: {
  readonly isFullTextSearchForceDisabled?: boolean;
  readonly renderItemFilter: (hideFilter: () => void) => JSX.Element;
}) => {
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const isRefinedNavigationFeatureEnabled = useIsRefinedNavigationFeatureEnabled();

  return (
    <Stack spacing={Spacing.None}>
      <ContentItemsSearchArea
        isFilterActive={isFilterVisible}
        onToggleFilter={() => setIsFilterVisible(not)}
        isInDialog
        isFullTextSearchForceDisabled={isFullTextSearchForceDisabled}
      />
      {isRefinedNavigationFeatureEnabled &&
        isFilterVisible &&
        renderItemFilter(() => setIsFilterVisible(false))}
    </Stack>
  );
};
