import { Box } from '@kontent-ai/component-library/Box';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { WebSpotlightZIndex } from '../../../../../../webSpotlight/constants/WebSpotlightZIndex.ts';
import { ContentItemPreviewActionBar } from '../containers/ContentItemPreviewActionBar.tsx';
import { PreviewRefreshErrorNotification } from '../containers/PreviewRefreshErrorNotification.tsx';
import { ContentItemPreviewCanvas } from './ContentItemPreviewCanvas.tsx';

type ContentItemPreviewProps = Readonly<{
  contentItemId: Uuid;
  spaceId: Uuid | null;
}>;

export const ContentItemPreview = ({ contentItemId, spaceId }: ContentItemPreviewProps) => {
  return (
    <>
      <ContentItemPreviewActionBar spaceId={spaceId} />
      <Box width="100%" height="100%" position="relative" flexGrow={1}>
        <Box
          padding={Spacing.S}
          position="absolute"
          width="100%"
          zIndex={WebSpotlightZIndex.PreviewErrorNotification}
        >
          <PreviewRefreshErrorNotification key={spaceId} />
        </Box>
        <ContentItemPreviewCanvas contentItemId={contentItemId} spaceId={spaceId} />
      </Box>
    </>
  );
};
