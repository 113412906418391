import React, { ReactNode } from 'react';
import {
  AuditLogRoute,
  CollectionsRoute,
  CustomAppsConfigurationRoute,
  DefaultWorkflowRoute,
  GeneralEnvironmentSettingsRoute,
  LegacyWebhooksRoute,
  LocalizationRoute,
  PreviewURLsRoute,
  RolesRoute,
  SpacesRoute,
  UsersRoute,
  WebhooksRoute,
  WorkflowsRoute,
} from '../../../_shared/constants/routePaths.ts';
import { isLegacyWebhookConfigurationEnabled } from '../../../_shared/selectors/isLegacyWebhookConfigurationEnabled.ts';
import { IStore } from '../../../_shared/stores/IStore.type.ts';
import {
  DataUiAppName,
  ObjectWithDataAttribute,
  getDataUiNavAttribute,
} from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { Capability } from '../../../_shared/utils/permissions/capability.ts';
import { checkGivenRoutesForPathNameMatch } from '../../../_shared/utils/routing/navigationRouteUtils.ts';
import { AiAcceleratorsRoute } from '../aiAccelerators/constants/routePaths.ts';
import { InnovationLabSettingsRoute } from '../innovationLab/constants/routePaths.ts';
import { NewWebhooksOnboarding as NewWebhooksOnboardingComponent } from '../root/components/NewWebhooksOnboarding.tsx';
import { EnvironmentSettingsAppNames } from '../root/constants/EnvironmentSettingsAppNames.ts';

type EnvironmentSettingsMenuStructureGroupItemBase = {
  readonly dataAttributes: ObjectWithDataAttribute;
  readonly isDisabled?: (state: IStore) => boolean;
  readonly isActive?: boolean;
  readonly renderAuxElements?: () => React.ReactNode;
  readonly routePath: string;
  readonly text: string;
  readonly customRender?: (defaultItem: React.ReactNode) => React.ReactNode;
  readonly shouldCustomRender?: (state: IStore) => boolean;
};

export type EnvironmentSettingsMenuStructureGroupItemWithCapability =
  EnvironmentSettingsMenuStructureGroupItemBase & {
    readonly requiredCapability: Capability;
  };

export type EnvironmentSettingsMenuStructureGroupItem =
  | EnvironmentSettingsMenuStructureGroupItemBase
  | EnvironmentSettingsMenuStructureGroupItemWithCapability;

export const environmentSettingsMenuStructure = (
  pathName: string = '',
): ReadonlyArray<EnvironmentSettingsMenuStructureGroupItem> => [
  {
    routePath: GeneralEnvironmentSettingsRoute,
    text: EnvironmentSettingsAppNames.General,
    dataAttributes: getDataUiNavAttribute(DataUiAppName.General),
    isActive: checkGivenRoutesForPathNameMatch(pathName, [GeneralEnvironmentSettingsRoute]),
  },
  {
    routePath: AiAcceleratorsRoute,
    text: EnvironmentSettingsAppNames.AiAccelerators,
    dataAttributes: getDataUiNavAttribute(DataUiAppName.AiAccelerators),
    isActive: checkGivenRoutesForPathNameMatch(pathName, [AiAcceleratorsRoute]),
  },
  {
    requiredCapability: Capability.ManageUsers,
    routePath: UsersRoute,
    text: EnvironmentSettingsAppNames.Users,
    dataAttributes: getDataUiNavAttribute(DataUiAppName.Users),
    isActive: checkGivenRoutesForPathNameMatch(pathName, [UsersRoute]),
  },
  {
    requiredCapability: Capability.ManageRoles,
    routePath: RolesRoute,
    text: EnvironmentSettingsAppNames.Roles,
    dataAttributes: getDataUiNavAttribute(DataUiAppName.Roles),
    isActive: checkGivenRoutesForPathNameMatch(pathName, [RolesRoute]),
  },
  {
    requiredCapability: Capability.ConfigureWorkflow,
    routePath: WorkflowsRoute,
    text: EnvironmentSettingsAppNames.MultipleWorkflows,
    dataAttributes: getDataUiNavAttribute(DataUiAppName.MultipleWorkflows),
    isActive: checkGivenRoutesForPathNameMatch(pathName, [WorkflowsRoute]),
  },
  {
    requiredCapability: Capability.ConfigureWorkflow,
    routePath: DefaultWorkflowRoute,
    text: EnvironmentSettingsAppNames.Workflow,
    dataAttributes: getDataUiNavAttribute(DataUiAppName.WorkflowStepsListing),
    isActive: checkGivenRoutesForPathNameMatch(pathName, [DefaultWorkflowRoute], true),
  },
  {
    requiredCapability: Capability.ManageSpaces,
    routePath: SpacesRoute,
    text: EnvironmentSettingsAppNames.Spaces,
    dataAttributes: getDataUiNavAttribute(DataUiAppName.Spaces),
    isActive: checkGivenRoutesForPathNameMatch(pathName, [SpacesRoute]),
  },
  {
    requiredCapability: Capability.ManageCollections,
    routePath: CollectionsRoute,
    text: EnvironmentSettingsAppNames.Collections,
    dataAttributes: getDataUiNavAttribute(DataUiAppName.ContentCollections),
    isActive: checkGivenRoutesForPathNameMatch(pathName, [CollectionsRoute]),
  },
  {
    requiredCapability: Capability.ConfigureLanguages,
    routePath: LocalizationRoute,
    text: EnvironmentSettingsAppNames.Localization,
    dataAttributes: getDataUiNavAttribute(DataUiAppName.Localization),
    isActive: checkGivenRoutesForPathNameMatch(pathName, [LocalizationRoute]),
  },
  {
    requiredCapability: Capability.ConfigurePreviewUrl,
    routePath: PreviewURLsRoute,
    text: EnvironmentSettingsAppNames.PreviewConfiguration,
    dataAttributes: getDataUiNavAttribute(DataUiAppName.PreviewUrls),
    isActive: checkGivenRoutesForPathNameMatch(pathName, [PreviewURLsRoute]),
  },
  {
    requiredCapability: Capability.ManageWebhooks,
    routePath: WebhooksRoute,
    text: EnvironmentSettingsAppNames.Webhooks,
    dataAttributes: getDataUiNavAttribute(DataUiAppName.Webhooks),
    shouldCustomRender: isLegacyWebhookConfigurationEnabled,
    isActive: checkGivenRoutesForPathNameMatch(pathName, [WebhooksRoute]),
    customRender: (defaultItem: ReactNode) => (
      <div key={WebhooksRoute}>
        <NewWebhooksOnboardingComponent step={1} element={defaultItem} />
      </div>
    ),
  },
  {
    requiredCapability: Capability.ManageWebhooks,
    routePath: LegacyWebhooksRoute,
    text: EnvironmentSettingsAppNames.LegacyWebhooks,
    dataAttributes: getDataUiNavAttribute(DataUiAppName.LegacyWebhooks),
    shouldCustomRender: isLegacyWebhookConfigurationEnabled,
    isActive: checkGivenRoutesForPathNameMatch(pathName, [LegacyWebhooksRoute]),
    customRender: (defaultItem: ReactNode) => (
      <div key={LegacyWebhooksRoute}>
        <NewWebhooksOnboardingComponent step={2} element={defaultItem} />
      </div>
    ),
  },
  {
    requiredCapability: Capability.AccessAuditLog,
    routePath: AuditLogRoute,
    text: EnvironmentSettingsAppNames.AuditLog,
    dataAttributes: getDataUiNavAttribute(DataUiAppName.AuditLog),
    isActive: checkGivenRoutesForPathNameMatch(pathName, [AuditLogRoute]),
  },
  {
    routePath: InnovationLabSettingsRoute,
    text: EnvironmentSettingsAppNames.InnovationLab,
    dataAttributes: getDataUiNavAttribute(DataUiAppName.InnovationLab),
    isActive: checkGivenRoutesForPathNameMatch(pathName, [InnovationLabSettingsRoute]),
  },
  {
    requiredCapability: Capability.ManageCustomApps,
    routePath: CustomAppsConfigurationRoute,
    text: EnvironmentSettingsAppNames.CustomApps,
    dataAttributes: getDataUiNavAttribute(DataUiAppName.CustomAppsConfiguration),
    isActive: checkGivenRoutesForPathNameMatch(pathName, [CustomAppsConfigurationRoute]),
  },
];
