import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { trackUserEvent } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { AppNames } from '../../../../../_shared/constants/applicationNames.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { AiImageTaggingAction } from '../../../../../_shared/models/TrackUserEventData.ts';
import { isAiImageTaggingEnabled } from '../../../../../_shared/selectors/aiSelectors.ts';
import { getNode } from '../../../../contentModels/taxonomy/utils/taxonomyTreeUtils.ts';
import { contentItemsBreadcrumbsOriginChanged } from '../../../shared/actions/contentInventoryActions.ts';
import { AssetListing } from '../../components/AssetListing/AssetListing.tsx';
import { RootFolderId } from '../../constants/assetFolderConstants.ts';
import { useAssetLibrarySelection } from '../../context/AssetLibrarySelectionContext.tsx';
import { buildAssetListingRoutePath, buildAssetRoutePath } from '../../utils/assetsPathUtils.ts';
import { wasAssetMatchedOnlyByTag } from '../../utils/wasAssetMatchedOnlyByTag.ts';

const AssetListingContainer: React.FC = () => {
  const currentProjectId = useSelector((s) => s.sharedApp.currentProjectId);
  const openedFolderId = useSelector((s) => s.assetLibraryApp.openedFolderId);
  const openedFolderName = useSelector((s) =>
    openedFolderId !== RootFolderId
      ? (getNode(s.data.assetFolders.taxonomyGroup, openedFolderId)?.name ?? null)
      : null,
  );

  const trackImageTagUsage = useTrackImageTagUsage();

  const {
    clearAssetSelection,
    deselectAssets,
    selectAssets,
    selectedAssets,
    toggleAssetSelection,
  } = useAssetLibrarySelection();

  const dispatch = useDispatch();
  const history = useHistory();

  const onAssetClick = useCallback(
    (assetId: Uuid) => {
      const assetsPath = buildAssetListingRoutePath({
        projectId: currentProjectId,
        assetFolderId: openedFolderId,
      });
      const assetPath = buildAssetRoutePath({
        assetId,
        projectId: currentProjectId,
      });

      dispatch(
        contentItemsBreadcrumbsOriginChanged({
          path: assetsPath,
          title: AppNames.Assets,
          type: 'AssetFolder',
        }),
      );

      trackImageTagUsage(assetId);

      history.push(assetPath);
    },
    [currentProjectId, history, openedFolderId, trackImageTagUsage],
  );

  return (
    <AssetListing
      clearAssetSelection={clearAssetSelection}
      deselectAssets={deselectAssets}
      onAssetClick={onAssetClick}
      openedFolderName={openedFolderName}
      selectAssets={selectAssets}
      selectedAssets={selectedAssets}
      toggleAssetSelection={toggleAssetSelection}
    />
  );
};

AssetListingContainer.displayName = 'AssetListingContainer';

export { AssetListingContainer as AssetListing };

const useTrackImageTagUsage = (): ((assetId: Uuid) => void) => {
  const dispatch = useDispatch();

  const searchPhrase = useSelector((s) => s.assetLibraryApp.query.searchPhrase.trim());
  const assetsById = useSelector((s) => s.data.assets.byId);
  const isImageTaggingEnabled = useSelector(isAiImageTaggingEnabled);

  return useCallback(
    (assetId: Uuid) => {
      if (!isImageTaggingEnabled) {
        return;
      }

      const asset = assetsById.get(assetId);
      if (asset && asset.tags.length > 0) {
        dispatch(
          trackUserEvent(TrackedEvent.AiImageTagging, {
            action: AiImageTaggingAction.TaggedAssetOpenedFromLibrary,
            matchedByTag: wasAssetMatchedOnlyByTag(asset, searchPhrase),
          }),
        );
      }
    },
    [assetsById, isImageTaggingEnabled, searchPhrase],
  );
};
