import React, { ComponentPropsWithRef } from 'react';
import { withAutoDispatcher } from '../../../../_shared/components/AutoDispatcher.tsx';
import { NameEditorElement } from '../../../../_shared/components/NameEditorElement.tsx';
import { ValidationConstants } from '../../../../_shared/constants/validationConstants.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { getCurrentProject } from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { DataUiInput } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { environmentNameUpdated } from '../actions/generalEnvironmentSettingsActions.ts';
import { saveEnvironmentNameToServer } from '../actions/thunkEnvironmentSettingsGeneralActions.ts';
import { UntitledEnvironmentSurrogateName } from '../constants/UIConstants.ts';

const EnvironmentNameEditorContainer: React.FC = () => {
  const dispatch = useDispatch();
  const editedEnvironmentName = useSelector(
    (s) => s.environmentSettingsApp.generalSettings.editedEnvironmentName,
  );

  return (
    <NameEditorElement
      autoFocus
      className="name-editor--is-plain"
      dataUiInputName={DataUiInput.EntityName}
      maxLength={ValidationConstants.ProjectNameMaxLength}
      placeholder={UntitledEnvironmentSurrogateName}
      value={
        editedEnvironmentName === UntitledEnvironmentSurrogateName ? '' : editedEnvironmentName
      }
      onChange={(name: string) => dispatch(environmentNameUpdated(name))}
    />
  );
};

export type EnvironmentObservedState = {
  readonly environmentName: string;
};

const mapObservedState = (state: IStore): EnvironmentObservedState => {
  return {
    environmentName: state.environmentSettingsApp.generalSettings.editedEnvironmentName,
  };
};

const shouldDispatch = (oldState: IStore, newState: IStore): boolean => {
  const nextEnvironmentName = newState.environmentSettingsApp.generalSettings.editedEnvironmentName;
  const currentEnvironment = getCurrentProject(newState);
  const oldEnvironment = getCurrentProject(oldState);

  const isEnvironmentSwitched = currentEnvironment.projectId !== oldEnvironment.projectId;
  const isEnvironmentNameChanged = currentEnvironment.environmentName !== nextEnvironmentName;

  return !isEnvironmentSwitched && isEnvironmentNameChanged;
};

export const EnvironmentNameEditorElement = withAutoDispatcher<
  ComponentPropsWithRef<typeof EnvironmentNameEditorContainer>,
  EnvironmentObservedState
>(
  mapObservedState,
  (observedState) => saveEnvironmentNameToServer(observedState),
  1000,
  shouldDispatch,
)(EnvironmentNameEditorContainer);
