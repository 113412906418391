import { ActiveCapabilityType } from '../../../../../../../_shared/models/activeCapability.type.ts';
import { getEditedContentItemVariant } from '../../../../../../../_shared/selectors/getEditedContentItemVariant.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { getCurrentProject } from '../../../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { IStore } from '../../../../../../../_shared/stores/IStore.type.ts';
import {
  isArchivedWorkflowStepSelected,
  isPublishingStepSelected,
} from '../../../../../../../_shared/utils/contentItemUtils.ts';
import { hasActiveVariantCapabilityForEditedItem } from '../../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { getLanguageOptionsMemoized } from '../../../containers/selectors/copyContentFromVariantMenuOptions.ts';

export const isCopyFromLanguageAvailable = (state: IStore) => {
  const variant = getEditedContentItemVariant(state);
  if (
    !!variant &&
    (isPublishingStepSelected(variant.assignment) ||
      isArchivedWorkflowStepSelected(variant.assignment) ||
      !hasActiveVariantCapabilityForEditedItem(ActiveCapabilityType.UpdateContent, state))
  ) {
    return false;
  }

  const {
    contentApp: { contentItemVariants, editedContentItem },
    data: { languages, user },
  } = state;

  const selectedLanguageId = getSelectedLanguageIdOrThrow(state);
  const currentProject = getCurrentProject(state);
  const collectionId = editedContentItem?.collectionId ?? null;
  const copyContentLanguageOptions = getLanguageOptionsMemoized(
    languages,
    selectedLanguageId,
    contentItemVariants,
    user,
    currentProject.projectId,
    collectionId,
  );

  return copyContentLanguageOptions.length > 0;
};
