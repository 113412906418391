import { isElement } from '@kontent-ai/DOM';
import { useObserveElementPresence } from '@kontent-ai/hooks';
import React from 'react';
import { createPortal } from 'react-dom';
import {
  AiTaskProgress,
  useAiTaskProgress,
} from '../../../../../../../_shared/features/AI/hooks/aiTasks/useAiTaskProgress.ts';
import { useCancelAiTasks } from '../../../../../../../_shared/features/AI/hooks/aiTasks/useCancelAiTasks.ts';
import { AiOperationState } from '../../../../../../../_shared/features/AI/types/AiOperationState.ts';
import {
  AiError,
  getAiErrorMessage,
} from '../../../../../../../_shared/features/AI/types/aiErrors.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { getLanguageName } from '../../../../../../../_shared/selectors/getLanguageName.ts';
import { AiActionName } from '../../../../../../../repositories/serverModels/ai/AiActionName.type.ts';
import { ElementReference } from '../../../../../../itemEditor/features/ContentItemEditing/containers/hooks/useItemElementReference.ts';
import {
  AiActionStatus,
  getAiOperationStatus,
} from '../../../components/status/AiActionStatus.tsx';
import { getAiElementStatusPlaceholderId } from '../../../components/status/AiElementStatusPlaceholder.tsx';
import { matchElementAiTasks } from '../../../utils/matchElementAiTasks.ts';
import { translateContentErrorMessageByErrorCode } from '../../../utils/translateContentAiErrors.ts';
import { ActionStatusProps, AiTranslateButton } from './AiTranslateButton.tsx';
import { getAiTranslateButtonPlaceholderId } from './AiTranslateButtonPlaceholder.tsx';

type Props = Omit<ActionStatusProps, 'otherTasksProgress'> & {
  readonly element: ElementReference;
  readonly error: AiError | null;
  readonly isEmpty: boolean;
  readonly perform: (sourceLanguageId: string | null) => void;
};

export const AiTranslateAction: React.FC<Props> = ({
  aiOperationState,
  element,
  error,
  perform,
  ...otherProps
}) => {
  const matchOtherTasks = matchElementAiTasks(element, AiActionName.ReviewByGuidelines);
  const otherTasksProgress = useAiTaskProgress(matchOtherTasks);
  const cancelOtherTasks = useCancelAiTasks(matchOtherTasks);

  const { current: buttonPlaceholder } = useObserveElementPresence(
    getAiTranslateButtonPlaceholderId(element.elementId, element.contentComponentId),
  );

  const { current: statusPlaceholder } = useObserveElementPresence(
    getAiElementStatusPlaceholderId(element.elementId, element.contentComponentId),
  );

  const languageName = useSelector((state) => getLanguageName(state, element.itemId?.variantId));

  return (
    <>
      {otherTasksProgress === AiTaskProgress.NotStarted &&
        isElement(statusPlaceholder) &&
        createPortal(
          <AiActionStatus
            label={getStatusLabel(aiOperationState, error)}
            status={getAiOperationStatus(aiOperationState, error)}
          />,
          statusPlaceholder,
        )}
      {isElement(buttonPlaceholder) &&
        createPortal(
          <AiTranslateButton
            targetLanguageName={languageName}
            isInContentComponent={!!element.contentComponentId}
            aiOperationState={aiOperationState}
            otherTasksProgress={otherTasksProgress}
            perform={(sourceLanguageId) => {
              cancelOtherTasks();
              perform(sourceLanguageId);
            }}
            {...otherProps}
          />,
          buttonPlaceholder,
        )}
    </>
  );
};

const getStatusLabel = (aiOperationState: AiOperationState, error: AiError | null): string => {
  if (aiOperationState === AiOperationState.Pending) {
    return 'Translating';
  }

  if (error) {
    return getAiErrorMessage(error, translateContentErrorMessageByErrorCode);
  }

  return 'Translation finished';
};
