import { noOperation } from '@kontent-ai/utils';
import { DraftBlockRenderConfig, EditorProps as DraftJSEditorProps, EditorState } from 'draft-js';
import Immutable from 'immutable';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { DragSource } from '../../../../_shared/components/DragDrop/DragSource.tsx';
import { DndTypes } from '../../../../_shared/constants/dndTypes.ts';
import { IconName } from '../../../../_shared/constants/iconEnumGenerated.ts';
import { ControlAltShortcutTemplate } from '../../../../_shared/constants/shortcutSymbols.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { DataUiRteAction } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IContentItemWithVariantsServerModel } from '../../../../repositories/serverModels/INewContentItemServerModel.ts';
import { ContentComponentContextProvider } from '../../../itemEditor/features/ContentComponent/context/ContentComponentContext.tsx';
import { ElementReference } from '../../../itemEditor/features/ContentItemEditing/containers/hooks/useItemElementReference.ts';
import { ContentNestingContext } from '../../../itemEditor/features/ContentItemEditing/context/ContentNestingContext.tsx';
import { ContentOverlayPlaceholder } from '../../../itemEditor/features/LinkedItems/components/ContentOverlay.tsx';
import { NewContentItemDialog } from '../../../itemEditor/features/NewContentItem/containers/NewContentItemDialog.tsx';
import { EditorSizeHandler } from '../../components/utility/EditorSizeHandler.tsx';
import { useEditorApi } from '../../editorCore/hooks/useEditorApi.ts';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { BaseEditorProps } from '../../editorCore/types/Editor.base.type.ts';
import { PluginComponent } from '../../editorCore/types/Editor.composition.type.ts';
import { None, WithoutProps } from '../../editorCore/types/Editor.contract.type.ts';
import { DecoratedEditor } from '../../editorCore/types/Editor.decorated.type.ts';
import {
  Apply,
  PluginProps,
  PluginState,
  Render,
} from '../../editorCore/types/Editor.plugins.type.ts';
import { EditorChangeReason } from '../../editorCore/types/EditorChangeReason.ts';
import { DecorableFunction, Decorator, decorable } from '../../editorCore/utils/decorable.ts';
import {
  getContentOverlayClass,
  getContentOverlayId,
} from '../../editorCore/utils/editorComponentUtils.ts';
import { BaseBlockType, BlockType } from '../../utils/blocks/blockType.ts';
import { getBaseBlockType } from '../../utils/blocks/editorBlockGetters.ts';
import { IEditorBlockProps } from '../../utils/blocks/editorBlockUtils.ts';
import { decorateBlocksWithAdjacentBlockTypes } from '../../utils/consistency/editorConsistencyUtils.ts';
import { restoreSelectionWithFocus } from '../../utils/editorStateUtils.ts';
import { getBlocks, getContentComponentBlocks } from '../../utils/general/editorContentGetters.ts';
import { getNewBlockPlaceholderType } from '../../utils/general/editorContentUtils.ts';
import { ModalsPlugin } from '../ModalsPlugin.tsx';
import { LinkedItemsLimitations } from '../apiLimitations/api/EditorFeatureLimitations.ts';
import { CommentsPlugin } from '../comments/CommentsPlugin.tsx';
import { CustomBlocksPlugin } from '../customBlocks/CustomBlocksPlugin.tsx';
import { DraftJsEditorPlugin } from '../draftJs/DraftJsEditorPlugin.type.ts';
import { GetBaseBlockRenderMap, GetInitialState } from '../draftJs/DraftJsPlugin.type.ts';
import { BaseBlockRenderMap, mergeBlockRenderMaps } from '../draftJs/utils/draftJsEditorUtils.ts';
import { DragDropPlugin } from '../dragDrop/DragDropPlugin.tsx';
import { DroppableCustomBlockWrapper } from '../dragDrop/components/DroppableCustomBlockWrapper.tsx';
import {
  ExecuteCommand,
  KeyboardShortcutsPlugin,
} from '../keyboardShortcuts/KeyboardShortcutsPlugin.tsx';
import { RichTextInputCommand } from '../keyboardShortcuts/api/EditorCommand.ts';
import { LinkedItemsPlugin } from '../linkedItems/LinkedItemsPlugin.tsx';
import { BlockToolbarPlugin, GetInsertBlockMenuItems } from '../toolbars/BlockToolbarPlugin.tsx';
import { CommandToolbarMenuItem } from '../toolbars/components/menu/EditorCommandMenu.tsx';
import { OnHighlightedBlocksChanged } from '../visuals/StylesPlugin.tsx';
import {
  EditorContentComponentApi,
  InsertContentComponent,
} from './api/EditorContentComponentApi.type.ts';
import { editorContentComponentApi } from './api/editorContentComponentApi.ts';
import { getContentComponentId } from './api/editorContentComponentUtils.ts';
import { ContentComponentBlocks } from './components/block/ContentComponentBlocks.tsx';
import { ContentComponentItem } from './containers/block/ContentComponentItem.tsx';

type ContentComponentsPluginState = {
  readonly onContentComponentCreated: DecorableFunction<() => void>;
};

type ContentComponentsPluginProps = {
  readonly element: ElementReference;
  readonly initializeNewContentItemDialog?: (allowedContentTypesIds: Immutable.Set<Uuid>) => void;
  readonly limitations: LinkedItemsLimitations;
  readonly onConvertContentComponentToContentItemVariant?: (
    element: ElementReference,
    contentComponentId: Uuid,
    selectedWorkflowId?: Uuid,
  ) => Promise<IContentItemWithVariantsServerModel | null>;
  readonly onCreateContentComponent?: (
    element: ElementReference,
    insertContentComponentItem: InsertContentComponent,
    editorState: EditorState,
    placeholderBlockKey: string,
    preselectedContentTypeId?: Uuid,
  ) => Promise<EditorState>;
  readonly onRedirectToContentItem?: (contentItemId: Uuid) => void;
  readonly singleUsableContentTypeIdForComponent?: Uuid | null;
};

export type ContentComponentsPlugin = DraftJsEditorPlugin<
  ContentComponentsPluginState,
  ContentComponentsPluginProps,
  EditorContentComponentApi,
  None,
  [
    DragDropPlugin,
    CommentsPlugin,
    CustomBlocksPlugin,
    KeyboardShortcutsPlugin<RichTextInputCommand>,
    ModalsPlugin,
    BlockToolbarPlugin,
  ]
>;

type ConvertComponentToItemVariant = (blockKey: Uuid, selectedWorkflowId?: Uuid) => Promise<void>;

type ContentComponentBlockCustomProps = Pick<
  PluginState<ContentComponentsPlugin>,
  | 'deleteCustomBlock'
  | 'hoveringCollisionStrategy'
  | 'getEditorId'
  | 'onDragEnd'
  | 'onDragStart'
  | 'onMoveBlocks'
  | 'executeChange'
  | 'focus'
> &
  Pick<PluginProps<ContentComponentsPlugin>, 'disabled' | 'limitations'> & {
    readonly convertComponentToItemVariant: ConvertComponentToItemVariant;
  };

const ContentComponentItemBlock: React.FC<IEditorBlockProps<ContentComponentBlockCustomProps>> = (
  props,
) => {
  const {
    block,
    blockProps: {
      convertComponentToItemVariant,
      deleteCustomBlock,
      disabled,
      hoveringCollisionStrategy,
      getEditorId,
      limitations,
      onDragEnd,
      onDragStart,
      onMoveBlocks,
      executeChange,
      focus,
    },
    ...otherProps
  } = props;

  const editorId = getEditorId();
  const blockKey = block.getKey();
  const contentComponentId = getContentComponentId(block);
  if (!contentComponentId) {
    return null;
  }

  const onDelete = () => deleteCustomBlock(blockKey);
  const onConvertComponentToItemVariant = (selectedWorkflowId?: Uuid) =>
    convertComponentToItemVariant(blockKey, selectedWorkflowId);

  return (
    <DroppableCustomBlockWrapper
      block={block}
      canUpdate={!disabled}
      className="rte__component"
      hoveringCollisionStrategy={hoveringCollisionStrategy}
      key={blockKey}
      onMove={onMoveBlocks}
      parentId={editorId}
      {...otherProps}
    >
      <DragSource
        onDragEnd={onDragEnd}
        onDragStart={() => onDragStart(blockKey)}
        parentId={editorId}
        renderDraggable={(connectDragSource, isDragging) => (
          <ContentComponentContextProvider contentComponentId={contentComponentId}>
            <ContentComponentItem
              connectDragSource={connectDragSource}
              contentComponentId={contentComponentId}
              isDragging={isDragging}
              allowedTypeIds={limitations.allowedTypes}
              disabled={disabled}
              onDelete={onDelete}
              onConvertComponentToItemVariant={onConvertComponentToItemVariant}
              overlayId={getContentOverlayId(editorId, blockKey)}
              renderExpanded={() => (
                <ContentOverlayPlaceholder overlayId={getContentOverlayId(editorId, blockKey)} />
              )}
              onFocusEscape={() => {
                focus();
                executeChange(restoreSelectionWithFocus);
              }}
            />
          </ContentComponentContextProvider>
        )}
        renderPreview={() => (
          <ContentComponentItem
            isDragging={false}
            allowedTypeIds={limitations.allowedTypes}
            contentComponentId={contentComponentId}
            disabled={disabled}
            onDelete={noOperation}
            onConvertComponentToItemVariant={noOperation}
          />
        )}
        sourceId={blockKey}
        type={DndTypes.Rich_Text_ContentComponent}
      />
    </DroppableCustomBlockWrapper>
  );
};

ContentComponentItemBlock.displayName = 'ContentComponentItemBlock';

type EditorWithContentComponentsProps = Pick<
  ContentComponentsPluginProps,
  | 'limitations'
  | 'element'
  | 'onConvertContentComponentToContentItemVariant'
  | 'onRedirectToContentItem'
> &
  Pick<BaseEditorProps, 'disabled'>;

const EditorWithContentComponents: DecoratedEditor<
  WithoutProps<ContentComponentsPlugin>,
  EditorWithContentComponentsProps
> = ({
  baseRender,
  disabled,
  element,
  limitations,
  onConvertContentComponentToContentItemVariant,
  onRedirectToContentItem,
  state,
}) => {
  const {
    canUpdateContent,
    deleteCustomBlock,
    draggedBlockKey,
    executeExternalAction,
    executeChange,
    focus,
    getEditorId,
    editorProps: { blockRendererFn: baseBlockRendererFn },
    getApi,
    hoveringCollisionStrategy,
    getEditorState,
    onDragEnd,
    onDragStart,
    onMoveBlocks,
    propagatePendingContentChanges,
  } = state;

  const convertComponentToItemVariant = useCallback(
    async (blockKey: Uuid, selectedWorkflowId?: Uuid) => {
      if (canUpdateContent() && onConvertContentComponentToContentItemVariant) {
        const editorState = getEditorState();
        const content = editorState.getCurrentContent();
        const blocks = getBlocks(content);
        const contentComponentBlock = blocks.find((block) => block.getKey() === blockKey);

        const contentComponentId =
          contentComponentBlock && getContentComponentId(contentComponentBlock);
        if (!contentComponentId) {
          return;
        }

        let createdItemId: Uuid | null = null;
        await executeExternalAction(async () => {
          const createdItem = await onConvertContentComponentToContentItemVariant(
            element,
            contentComponentId,
            selectedWorkflowId,
          );
          if (createdItem) {
            createdItemId = createdItem.item.id;
            return getApi().convertContentComponent(editorState, blockKey, [createdItemId]);
          }
          return editorState;
        });
        await propagatePendingContentChanges();

        if (createdItemId && onRedirectToContentItem) {
          onRedirectToContentItem(createdItemId);
        }
      }
    },
    [
      canUpdateContent,
      element,
      executeExternalAction,
      getApi,
      getEditorState,
      onConvertContentComponentToContentItemVariant,
      onRedirectToContentItem,
      propagatePendingContentChanges,
    ],
  );

  const blockProps: ContentComponentBlockCustomProps = useMemo(
    () => ({
      deleteCustomBlock,
      disabled,
      draggedBlockKey,
      getEditorId,
      hoveringCollisionStrategy,
      limitations,
      onDragEnd,
      onDragStart,
      onMoveBlocks,
      convertComponentToItemVariant,
      executeChange,
      focus,
    }),
    [
      deleteCustomBlock,
      disabled,
      draggedBlockKey,
      getEditorId,
      hoveringCollisionStrategy,
      limitations,
      onDragEnd,
      onDragStart,
      onMoveBlocks,
      convertComponentToItemVariant,
      executeChange,
      focus,
    ],
  );

  const blockRendererFn = useCallback<Required<DraftJSEditorProps>['blockRendererFn']>(
    (block) => {
      const baseBlockType = getBaseBlockType(block);
      if (baseBlockType === BlockType.ContentComponent) {
        return {
          component: ContentComponentItemBlock,
          props: blockProps,
          editable: false,
        };
      }

      return baseBlockRendererFn?.(block) ?? null;
    },
    [baseBlockRendererFn, blockProps],
  );

  const stateWithContentComponents: PluginState<ContentComponentsPlugin> = {
    ...state,
    editorProps: {
      ...state.editorProps,
      blockRendererFn,
    },
  };

  return baseRender(stateWithContentComponents);
};

EditorWithContentComponents.displayName = 'EditorWithContentComponents';

const contentComponentRenderMap: BaseBlockRenderMap = Immutable.Map<
  BaseBlockType,
  DraftBlockRenderConfig
>({
  [BaseBlockType.ContentComponent]: {
    element: 'div',
  },
});

const getBaseBlockRenderMap: Decorator<GetBaseBlockRenderMap> = (baseGetBaseBlockRenderMap) => () =>
  mergeBlockRenderMaps(baseGetBaseBlockRenderMap(), contentComponentRenderMap);

const getInitialState: Decorator<GetInitialState> =
  (baseGetInitialState) => (initialEditorState) => {
    const state = baseGetInitialState(initialEditorState);

    return {
      ...state,
      content: decorateBlocksWithAdjacentBlockTypes(state.content),
    };
  };

const insertContentComponentMenuItem: CommandToolbarMenuItem = {
  label: 'Insert new component',
  command: RichTextInputCommand.InsertComponent,
  shortcuts: ControlAltShortcutTemplate('C'),
  iconName: IconName.CollapseScheme,
  uiAction: DataUiRteAction.InsertNewContentComponent,
};

export const ContentComponentsPlugin: PluginComponent<ContentComponentsPlugin> = (props) => {
  const {
    initializeNewContentItemDialog,
    onConvertContentComponentToContentItemVariant,
    onCreateContentComponent,
    onRedirectToContentItem,
    singleUsableContentTypeIdForComponent,
    disabled,
    element,
    limitations,
  } = props;

  const { isContentComponentCreationAllowed } = useContext(ContentNestingContext);

  const [newComponentBlockKey, setNewComponentBlockKey] = useState<string | null>(null);
  const resetNewComponentBlockKey = useCallback(() => setNewComponentBlockKey(null), []);

  const [highlightedBlockKeys, setHighlightedBlockKeys] = useState<ReadonlySet<string>>(new Set());

  const onHighlightedBlocksChanged: Decorator<OnHighlightedBlocksChanged> = useCallback(
    (baseOnHighlightedBlocksChanged) => (newHighlightedBlockKeys) => {
      setHighlightedBlockKeys(newHighlightedBlockKeys);
      baseOnHighlightedBlocksChanged(newHighlightedBlockKeys);
    },
    [],
  );

  const renderOverlays: Decorator<Render<ContentComponentsPlugin>> = useCallback(
    (baseRenderOverlays) => (state) => {
      const { draggedBlockKey, editorState, getEditorId } = state;
      const content = editorState.getCurrentContent();
      const contentComponentBlocks = getContentComponentBlocks(content);
      const editorId = getEditorId();

      return (
        <>
          {baseRenderOverlays(state)}
          <ContentComponentBlocks
            key={editorId}
            contentComponentBlocks={contentComponentBlocks}
            disabled={!!disabled}
            editorId={editorId}
            isDragging={!!draggedBlockKey}
            newComponentBlockKey={newComponentBlockKey}
            onNewContentComponentMounted={resetNewComponentBlockKey}
            highlightedBlockKeys={highlightedBlockKeys}
          />
          <EditorSizeHandler
            contentOverlayClassName={
              contentComponentBlocks.length > 0 ? getContentOverlayClass(editorId) : undefined
            }
            editorRef={state.getWrapperRef()}
          />
        </>
      );
    },
    [highlightedBlockKeys, disabled, newComponentBlockKey, resetNewComponentBlockKey],
  );

  const render: Decorator<Render<ContentComponentsPlugin>> = useCallback(
    (baseRender) => (state) => (
      <EditorWithContentComponents
        baseRender={baseRender}
        disabled={disabled}
        element={element}
        limitations={limitations}
        onConvertContentComponentToContentItemVariant={
          onConvertContentComponentToContentItemVariant
        }
        onRedirectToContentItem={onRedirectToContentItem}
        state={state}
      />
    ),
    [
      disabled,
      element,
      limitations,
      onConvertContentComponentToContentItemVariant,
      onRedirectToContentItem,
    ],
  );

  const singleUsableContentTypeNameForComponent = useSelector(
    (state) =>
      singleUsableContentTypeIdForComponent &&
      (state.data.contentTypes.byId.get(singleUsableContentTypeIdForComponent)?.name ?? null),
  );

  const getAddBlockMenuItems: Decorator<GetInsertBlockMenuItems> = useCallback(
    (baseGetAddBlockMenuItems) => () => [
      ...baseGetAddBlockMenuItems(),
      {
        ...insertContentComponentMenuItem,
        label: singleUsableContentTypeNameForComponent
          ? `Insert new ${singleUsableContentTypeNameForComponent}`
          : insertContentComponentMenuItem.label,
      },
    ],
    [singleUsableContentTypeNameForComponent],
  );

  const apply: Apply<ContentComponentsPlugin> = useCallback(
    (state) => {
      state.getInitialState.decorate(getInitialState);
      state.getBaseBlockRenderMap.decorate(getBaseBlockRenderMap);
      state.onHighlightedBlocksChanged.decorate(onHighlightedBlocksChanged);
      state.render.decorate(render);
      state.getInsertBlockMenuItems.decorate(getAddBlockMenuItems);
      state.renderOverlays.decorate(renderOverlays);

      const onContentComponentCreated = decorable(noOperation);

      const createContentComponent = async (
        placeholderBlockKey: string,
        preselectedContentTypeId?: Uuid,
      ) => {
        if (onCreateContentComponent && state.canUpdateContent(EditorChangeReason.Internal)) {
          await state.executeExternalAction((editorState) =>
            onCreateContentComponent(
              element,
              state.getApi().insertContentComponent,
              editorState,
              placeholderBlockKey,
              preselectedContentTypeId,
            ),
          );

          state.resetEditedBlockKey();
          setNewComponentBlockKey(placeholderBlockKey);

          onContentComponentCreated();
        }
      };

      const newContentComponent = async () => {
        const placeholderBlockKey = await state.createNewBlockPlaceholder(
          BlockType.ContentComponent,
        );
        if (placeholderBlockKey) {
          if (singleUsableContentTypeIdForComponent) {
            createContentComponent(placeholderBlockKey, singleUsableContentTypeIdForComponent);
          } else {
            initializeNewContentItemDialog?.(Immutable.Set(limitations.allowedTypes));
          }
        }
      };

      const executeCommand: Decorator<ExecuteCommand<RichTextInputCommand>> =
        (baseExecuteCommand) => (command, isShiftPressed) => {
          switch (command) {
            case RichTextInputCommand.InsertComponent: {
              if (isContentComponentCreationAllowed) {
                newContentComponent();
              }
              return true;
            }

            default:
              return baseExecuteCommand(command, isShiftPressed);
          }
        };

      state.executeCommand.decorate(executeCommand);

      const renderModal: Decorator<Render<LinkedItemsPlugin>> =
        (baseRenderModal) => (baseState) => {
          const { editedBlockKey, editorState, focus, onCloseModal } = baseState;

          // We don't trigger the dialog in case only one type is eligible, as in this case the content component is created immediately
          if (!singleUsableContentTypeIdForComponent && editedBlockKey) {
            const content = editorState.getCurrentContent();
            const block = content.getBlockForKey(editedBlockKey);
            const newBlockType = getNewBlockPlaceholderType(block);
            if (newBlockType === BlockType.ContentComponent) {
              return (
                <NewContentItemDialog
                  onClose={() => {
                    onCloseModal();
                    focus();
                  }}
                  isOpen={newBlockType === BlockType.ContentComponent}
                  onSubmit={async () => {
                    await createContentComponent(editedBlockKey);
                    focus();
                  }}
                />
              );
            }
          }

          return baseRenderModal(baseState);
        };

      state.renderModal.decorate(renderModal);

      return {
        onContentComponentCreated,
      };
    },
    [
      element,
      getAddBlockMenuItems,
      initializeNewContentItemDialog,
      isContentComponentCreationAllowed,
      limitations,
      onCreateContentComponent,
      onHighlightedBlocksChanged,
      render,
      renderOverlays,
      singleUsableContentTypeIdForComponent,
    ],
  );

  const { getApiMethods } = useEditorApi<ContentComponentsPlugin>(editorContentComponentApi);

  return useEditorWithPlugin(props, {
    apply,
    getApiMethods,
  });
};
