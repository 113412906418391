import { noOperation } from '@kontent-ai/utils';
import React from 'react';
import { useDispatch } from '../../../../../../../../_shared/hooks/useDispatch.ts';
import { ContentItemEditingExpandCollapseAction } from '../../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { compose } from '../../../../../../../../_shared/utils/func/compose.ts';
import { hideAllComments } from '../../../../actions/contentItemEditingActions.ts';
import {
  EditingActionDynamicDetailProvider,
  EditingActionDynamicDetailProviderOwnProps,
} from '../../../../components/editingActions/actions/EditingActionDynamicDetailProvider.tsx';
import { useIsLivePreviewOpen } from '../../../../hooks/useIsLivePreviewOpen.ts';
import { trackContentItemEditingAction } from '../../../../utils/editingActions/trackContentItemEditingAction.ts';

export const CloseInlineCommentsAction: React.FC<EditingActionDynamicDetailProviderOwnProps> = ({
  children,
  ...props
}) => {
  const dispatch = useDispatch();
  const isPreviewOpen = useIsLivePreviewOpen();
  const performAction = isPreviewOpen
    ? noOperation
    : compose(
        dispatch,
        trackContentItemEditingAction(
          hideAllComments(),
          ContentItemEditingExpandCollapseAction.HideAllComments,
        ),
      );

  return (
    <EditingActionDynamicDetailProvider
      {...props}
      isDisabled={isPreviewOpen}
      performAction={performAction}
      tooltip={isPreviewOpen ? 'To see all comments, turn off the Preview toggle.' : undefined}
    >
      {children}
    </EditingActionDynamicDetailProvider>
  );
};
