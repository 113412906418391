import { useState } from 'react';
import { ContentChangesWarningSubtitle } from '../../../../_shared/components/ModalDialog/ContentChangesWarningModal.tsx';
import { LongProcessingChangesWarning } from '../../../../_shared/components/ModalDialog/LongProcessingChangesWarning.tsx';
import { SaveElementsWithAffectedLocalizationWarning } from '../../../../_shared/components/ModalDialog/SaveElementsWithAffectedLocalizationWarning.tsx';
import { HandleUnsavedFormOnNavigation } from '../../../../_shared/containers/HandleUnsavedFormOnNavigation.tsx';
import { EditorPaperContextProvider } from '../../../../_shared/contexts/EditorPaperContext.tsx';
import { ContentModelNotifications } from '../../shared/containers/ContentModelNotifications.tsx';
import { TypeElementValidationResult } from '../../shared/utils/typeElementValidators/types/TypeElementValidationResult.type.ts';
import { ContentTypeEditorPane } from '../containers/ContentTypeEditorPane.tsx';

type Props = {
  readonly elementsWithAffectedLocalization: ReadonlyArray<{
    readonly elementId: Uuid;
    readonly name: string;
  }>;
  readonly focusTypeName?: boolean;
  readonly hasUnsavedChanges: boolean;
  readonly hideLongProcessingChangesWarning?: boolean;
  readonly isBeingSaved: boolean;
  readonly onDismissLongProcessingChangesWarning: () => void;
  readonly onFocusFirstInvalidElement: () => void;
  readonly onUnsavedNavigation: (onSuccess: () => void, onFail: () => void) => Promise<void>;
  readonly saveContentType: () => void;
  readonly showContentTypeUsedWithPublishedItemWarning: boolean;
  readonly validationResults: ReadonlyMap<Uuid, TypeElementValidationResult>;
};

export const ContentTypeEditor = ({
  elementsWithAffectedLocalization,
  focusTypeName,
  hasUnsavedChanges,
  hideLongProcessingChangesWarning,
  isBeingSaved,
  onDismissLongProcessingChangesWarning,
  onFocusFirstInvalidElement,
  onUnsavedNavigation,
  saveContentType,
  showContentTypeUsedWithPublishedItemWarning,
  validationResults,
}: Props) => {
  const [isLongProcessingWarningChecked, setIsLongProcessingWarningChecked] = useState(false);

  return (
    <>
      <HandleUnsavedFormOnNavigation
        hasUnsavedChanges={hasUnsavedChanges}
        isBeingSaved={isBeingSaved}
        onSaveChanges={async (onSuccess, onFail) => {
          if (isLongProcessingWarningChecked) {
            onDismissLongProcessingChangesWarning();
          }
          await onUnsavedNavigation(onSuccess, onFail);
        }}
        renderAdditionalContent={() =>
          !hideLongProcessingChangesWarning || elementsWithAffectedLocalization.length > 0 ? (
            <>
              <LongProcessingChangesWarning
                isLongProcessingWarningDismissed={isLongProcessingWarningChecked}
                hideLongProcessingChangesWarning={hideLongProcessingChangesWarning}
                onLongProcessingWarningDismiss={() =>
                  setIsLongProcessingWarningChecked(!isLongProcessingWarningChecked)
                }
                warningSubtitle={ContentChangesWarningSubtitle.ContentTypeEditing}
              />
              <SaveElementsWithAffectedLocalizationWarning
                elementsWithAffectedLocalization={elementsWithAffectedLocalization}
              />
            </>
          ) : null
        }
      />
      <EditorPaperContextProvider>
        <ContentModelNotifications
          onFocusFirstInvalidElement={onFocusFirstInvalidElement}
          validationResults={validationResults}
        />
        <ContentTypeEditorPane
          focusTypeName={focusTypeName}
          onSaveContentType={() => {
            if (isLongProcessingWarningChecked) {
              onDismissLongProcessingChangesWarning();
            }
            saveContentType();
          }}
          showContentTypeUsedWithPublishedItemWarning={showContentTypeUsedWithPublishedItemWarning}
        />
      </EditorPaperContextProvider>
    </>
  );
};
