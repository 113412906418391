import { useLocation } from 'react-router';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../../_shared/hooks/useThunkPromise.ts';
import { ContentItemId } from '../../../../../_shared/models/ContentItemId.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import { MissionControlTrackedWidgetName } from '../../../../../_shared/models/TrackUserEventData.ts';
import { getCurrentProjectId } from '../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { getContentItemPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { useLivePreviewPreferenceStorage } from '../../../../../localStorages/useLivePreviewPreferenceStorage.ts';
import {
  useTrackWidgetDetailItemClick,
  useTrackWidgetOverviewItemClick,
  useTrackWidgetShowMoreButtonClick,
} from '../../shared/hooks/tracking.ts';
import { WidgetListingState } from '../../shared/types/WidgetListingState.type.ts';
import { initYourTasks } from '../actions/thunkTasksAssignedToYouActions.ts';
import { TasksAssignedToYouWidget } from '../components/TasksAssignedToYouWidget.tsx';
import { YourTasks_GoToTask_Selected } from '../constants/yourTasksActionTypes.ts';
import { getYourTasks } from '../selectors/getYourTasks.ts';
import { YourTaskWithPathToItem } from '../types/YourTaskWithPathToItem.type.ts';

export type YourTasksGoToTaskActionsType = ReturnType<typeof navigateToTask>;

const navigateToTask = (pathToItem: string, taskId: Uuid) =>
  ({
    type: YourTasks_GoToTask_Selected,
    payload: {
      pathToItem,
      taskId,
    },
  }) as const;

const getWidgetListingState = (
  loadingStatus: LoadingStatus,
  taskCount: number,
): WidgetListingState => {
  switch (loadingStatus) {
    case LoadingStatus.InitialEmpty:
      return WidgetListingState.Init;
    case LoadingStatus.Loading:
      return WidgetListingState.Loading;
    case LoadingStatus.Loaded:
      return taskCount > 0 ? WidgetListingState.Loaded : WidgetListingState.Empty;
  }
};

const loadingStatuses: Set<LoadingStatus> = new Set([
  LoadingStatus.InitialEmpty,
  LoadingStatus.Loading,
]);
const isLoading = (loadingStatus: LoadingStatus): boolean => loadingStatuses.has(loadingStatus);

export const TasksAssignedToYouWidgetContainer = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const currentProjectId = useSelector(getCurrentProjectId);
  const { isLivePreviewPreferred } = useLivePreviewPreferenceStorage(currentProjectId);

  const yourTasks = useSelector((s) =>
    getYourTasks(s).map(
      (task): YourTaskWithPathToItem => ({
        ...task,
        contentItemPath: getContentItemPath(
          pathname,
          task.itemId,
          isLivePreviewPreferred,
          task.variantId,
        ),
      }),
    ),
  );

  const tasksState = useSelector((s): WidgetListingState => {
    const relatedContentItemsState = s.data.yourTasksContentItems.loadingStatus;
    if (isLoading(relatedContentItemsState)) {
      return WidgetListingState.Loading;
    }

    return getWidgetListingState(s.data.tasks.loadingStatus, s.data.tasks.byId.size);
  });

  useThunkPromise(initYourTasks);

  const trackWidgetOverviewItemClick = useTrackWidgetOverviewItemClick(
    MissionControlTrackedWidgetName.YourTasks,
  );
  const trackWidgetShowMoreButtonClick = useTrackWidgetShowMoreButtonClick(
    MissionControlTrackedWidgetName.YourTasks,
  );
  const trackWidgetDetailItemClick = useTrackWidgetDetailItemClick(
    MissionControlTrackedWidgetName.YourTasks,
  );

  const goToTask = (taskId: Uuid, itemIdentity: ContentItemId) => {
    const pathToItem = getContentItemPath(
      pathname,
      itemIdentity.itemId,
      isLivePreviewPreferred,
      itemIdentity.variantId,
    );

    dispatch(navigateToTask(pathToItem, taskId));
  };

  const onTaskClick = (taskId: Uuid, itemIdentity: ContentItemId) => {
    goToTask(taskId, itemIdentity);
    trackWidgetOverviewItemClick();
  };

  const onDetailTaskClick = (taskId: Uuid, itemIdentity: ContentItemId) => {
    goToTask(taskId, itemIdentity);
    trackWidgetDetailItemClick();
  };

  return (
    <TasksAssignedToYouWidget
      tasks={yourTasks}
      tasksState={tasksState}
      onTaskClick={onTaskClick}
      onShowMoreClick={trackWidgetShowMoreButtonClick}
      onDetailTaskClick={onDetailTaskClick}
    />
  );
};
