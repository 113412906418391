import { forwardRef, memo } from 'react';
import { ScrollTableCell } from '../../../../_shared/uiComponents/ScrollTable/ScrollTableCell.tsx';
import { ScrollTableCellWrappedByLink } from '../../../../_shared/uiComponents/ScrollTable/ScrollTableCellWrappedByLink.tsx';
import { ScrollTableExpandToggleCell } from '../../../../_shared/uiComponents/ScrollTable/ScrollTableExpandToggleCell.tsx';
import { ScrollTableRow } from '../../../../_shared/uiComponents/ScrollTable/ScrollTableRow.tsx';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IContentType } from '../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IListingContentItem } from '../../../../data/models/listingContentItems/IListingContentItem.ts';
import { PlaceholderForCheckboxCell } from '../../../itemEditor/features/CascadePublish/components/PlaceholderForCheckboxCell.tsx';
import {
  EmptyCellContent,
  ItemNameCellContent,
  ItemTypeCellContent,
  WorkflowStatusWithFallbacksCellContent,
  getTextForTableCell,
} from '../../content/features/ContentItemInventory/components/ItemInventoryScrollTable/Cells/ContentItemScrollTableCellsContents.tsx';
import { TableCellDueDate } from '../../content/features/ContentItemInventory/components/TableCellDueDate.tsx';

type Props = {
  readonly depth: number;
  readonly disabledClickMessage?: string;
  readonly expanded: boolean;
  readonly hasChildren: boolean;
  readonly item: IListingContentItem;
  readonly itemLink: string;
  readonly itemType: IContentType;
  readonly loading: boolean;
  readonly onToggleExpanded: () => void;
};

export const RelationsScrollTableRow = memo(
  forwardRef<HTMLDivElement, Props>(
    (
      {
        depth,
        disabledClickMessage,
        expanded,
        hasChildren,
        item,
        itemLink,
        itemType,
        loading,
        onToggleExpanded,
      },
      ref,
    ) => (
      <ScrollTableRow
        isLoading={loading}
        isPlaceholder={!!disabledClickMessage}
        objectName={item.item.name}
        ref={ref}
        rowFocusLinkPath={itemLink}
        rowFocusLinkAriaLabel={`visit content item — ${item.item.name}`}
      >
        {Array(depth)
          .fill(null)
          .map((_val, index) => (
            <PlaceholderForCheckboxCell key={index} />
          ))}
        <ScrollTableExpandToggleCell
          disabledMessage={depth === 0 ? 'Home item is always expanded' : undefined}
          isExpanded={expanded}
          isHidden={!hasChildren}
          onToggle={onToggleExpanded}
        />
        <ScrollTableCellWrappedByLink
          size={15}
          isGrowing
          title={item.item.name}
          linkPath={itemLink}
          allowNavigationWithOnClick={!disabledClickMessage}
          tooltipText={disabledClickMessage}
          dataUiAttribute={getDataUiElementAttribute(DataUiElement.ItemsTableColumnName)}
        >
          <ItemNameCellContent item={item} />
        </ScrollTableCellWrappedByLink>
        <ScrollTableCell size={5} dataUiElement={DataUiElement.ItemsTableColumnWorkflowStep}>
          <WorkflowStatusWithFallbacksCellContent variant={item.variant} itemId={item.item.id} />
        </ScrollTableCell>
        <ScrollTableCell
          size={5}
          dataUiElement={DataUiElement.ItemsTableColumnType}
          title={getTextForTableCell(itemType.name)}
        >
          <ItemTypeCellContent itemType={itemType} />
        </ScrollTableCell>
        <ScrollTableCell size={5} dataUiElement={DataUiElement.ItemsTableColumnDueDate}>
          {item.variant ? (
            <TableCellDueDate dueDate={item.variant.assignment.due} />
          ) : (
            <EmptyCellContent />
          )}
        </ScrollTableCell>
      </ScrollTableRow>
    ),
  ),
);
