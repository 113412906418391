import { assert, Collection } from '@kontent-ai/utils';
import { ContentBlock, ContentState, SelectionState } from 'draft-js';
import { ContentItemId } from '../../../../../_shared/models/ContentItemId.ts';
import {
  IFixedInsertPosition,
  InsertPositionPlacement,
  SmartLinkCommand,
  SmartLinkCommandType,
} from '../../../../../_shared/models/SmartLinkCommand.ts';
import { isRelativeInsertPosition } from '../../../../../_shared/models/utils/smartLinkCommandUtils.ts';
import { isEmptyParagraph } from '../../../utils/blocks/blockTypeUtils.ts';
import { getBlockKey } from '../../../utils/blocks/editorBlockGetters.ts';
import { getBlockHierarchy } from '../../../utils/blocks/editorHierarchyUtils.ts';
import { getBlockByTargetId } from '../../../utils/blocks/getBlockByTargetId.ts';
import { createSelection } from '../../../utils/editorSelectionUtils.ts';
import { getBlocks } from '../../../utils/general/editorContentGetters.ts';
import { RichTextInputCommand } from '../../keyboardShortcuts/api/EditorCommand.ts';
import { getModularContentItemId } from '../../linkedItems/api/editorModularUtils.ts';
import { PossiblyIncorrectPlacementWarningType } from '../containers/PossiblyIncorrectPlacementWarning.tsx';

export function getPossiblyIncorrectPlacementWarningTypeFromSmartLinkCommand(
  command: SmartLinkCommand,
): PossiblyIncorrectPlacementWarningType {
  switch (command.type) {
    case SmartLinkCommandType.InsertLinkedItem:
      return PossiblyIncorrectPlacementWarningType.LinkedItem;
    case SmartLinkCommandType.CreateComponent:
      return PossiblyIncorrectPlacementWarningType.ContentComponent;
    default:
      throw new Error(`Provided command is not supported by RTE: ${JSON.stringify(command)}`);
  }
}

export function smartLinkCommandToRichTextInputCommand(
  command: SmartLinkCommand,
): RichTextInputCommand {
  switch (command.type) {
    case SmartLinkCommandType.InsertLinkedItem:
      return RichTextInputCommand.InsertItem;
    case SmartLinkCommandType.CreateComponent:
      return RichTextInputCommand.InsertComponent;
    default:
      throw new Error(`Provided command is not supported by RTE: ${JSON.stringify(command)}`);
  }
}

export function isSmartLinkCommandRelevantToRTEs(command: SmartLinkCommand): boolean {
  return [SmartLinkCommandType.CreateComponent, SmartLinkCommandType.InsertLinkedItem].includes(
    command.type,
  );
}

function getFixedSmartLinkTargetBlock(
  content: ContentState,
  insertPosition: IFixedInsertPosition,
): ContentBlock {
  const blocks = getBlocks(content);
  const hierarchy = getBlockHierarchy(blocks);

  if (insertPosition.placement === InsertPositionPlacement.Start) {
    const firstBlock = hierarchy[0]?.block;
    assert(firstBlock, () => 'The first hierarchy block is not a content block.');
    return firstBlock;
  }

  const lastBlock = Collection.getLast(hierarchy)?.block;
  assert(lastBlock, () => 'The last hierarchy block is not a content block.');
  return lastBlock;
}

export async function getSmartLinkTargetSelection(
  content: ContentState,
  command: SmartLinkCommand,
  contentItemIdentifier: ContentItemId,
  rootRichTextElementId: Uuid,
): Promise<SelectionState> {
  const { insertPosition } = command.data;

  if (isRelativeInsertPosition(insertPosition)) {
    const targetBlock = await getBlockByTargetId(
      content,
      insertPosition.targetId,
      contentItemIdentifier,
      rootRichTextElementId,
    );

    if (targetBlock) {
      const placementBlock =
        insertPosition.placement === InsertPositionPlacement.After
          ? content.getBlockAfter(targetBlock.getKey())
          : content.getBlockBefore(targetBlock.getKey());

      if (placementBlock) {
        assert(
          isEmptyParagraph(placementBlock),
          () => 'Content components / Linked items should have empty blocks around them.',
        );

        return createSelection(placementBlock.getKey());
      }
    }

    const lastBlock = content.getLastBlock();
    return createSelection(lastBlock.getKey(), lastBlock.getLength());
  }

  const targetBlock = getFixedSmartLinkTargetBlock(content, insertPosition);

  return createSelection(
    targetBlock.getKey(),
    insertPosition.placement === InsertPositionPlacement.End ? targetBlock.getLength() : 0,
  );
}

export const getTargetContentModuleBlockKeys = (
  content: ContentState,
  targetId: string,
): ReadonlySet<string> =>
  new Set(
    getBlocks(content)
      .filter((block) => getModularContentItemId(block) === targetId)
      .map(getBlockKey),
  );

export const hasTargetContentModuleBlockDuplicates = (
  content: ContentState,
  targetId: string,
): boolean => getTargetContentModuleBlockKeys(content, targetId).size > 1;
