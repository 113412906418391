import { Callout } from '@kontent-ai/component-library/Callout';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { selectIntercomUtils } from '../../../../../_shared/selectors/selectIntercomUtils.ts';
import { InnovationLabFeatureCard } from '../../components/InnovationLabFeatureCard.tsx';
import { InnovationLabFeatureCardWrapper } from '../../components/InnovationLabFeatureCardWrapper.tsx';
import { InnovationLabFeatureDialogs } from '../../components/InnovationLabFeatureDialogs.tsx';
import { InnovationLabGenericConsent } from '../../components/InnovationLabGenericConsent.tsx';
import { useFeatureActivationDialog } from '../../hooks/useFeatureActivationDialog.ts';
import { activateInnovationLabFeature } from '../../thunks/activateInnovationLabFeature.ts';
import { deactivateInnovationLabFeature } from '../../thunks/deactivateInnovationLabFeature.ts';
import { InnovationLabCardProps } from '../../types/InnovationLabCardProps.type.ts';

export const AiReviewCard = ({ state, info, name }: InnovationLabCardProps) => {
  const intercomUtils = useSelector(selectIntercomUtils);

  const {
    dialogDisplayed,
    isAsyncOperationInProgress,
    onCardActionButtonClick,
    onDialogActionClick,
    onDialogClose,
  } = useFeatureActivationDialog(
    info,
    state,
    activateInnovationLabFeature('ai-review'),
    deactivateInnovationLabFeature('ai-review'),
  );

  const featureState = info.status;

  return (
    <>
      <InnovationLabFeatureCardWrapper featureState={featureState}>
        <InnovationLabFeatureCard
          activationState={state}
          featureName={name}
          featureState={featureState}
          isActionButtonLoading={isAsyncOperationInProgress}
          onCardActionButtonClick={onCardActionButtonClick}
          onContactSupport={intercomUtils.showIntercom}
          renderCardBody={() => (
            <p>
              Use AI to review your content based on your specific instructions. Define content
              review guidelines for individual content elements, so your content creators can check
              if what they wrote complies with the guidelines.
            </p>
          )}
        />
      </InnovationLabFeatureCardWrapper>
      <InnovationLabFeatureDialogs
        dialogFeatureName={name}
        dialogDisplayed={dialogDisplayed}
        isAsyncOperationInProgress={isAsyncOperationInProgress}
        onDialogActionClick={onDialogActionClick}
        onDialogClose={onDialogClose}
        renderActivationDialogBody={() => (
          <>
            <Callout calloutType="quickTip" hideSubheadline>
              <p>
                <b>Innovation Lab feature activation</b>
              </p>
              <p>You’re about to activate the {name}.</p>
              <p>
                The AI can analyze your written content and suggest relevant changes. You can
                provide feedback on the suggestions you receive from the AI to help us improve your
                experience.
              </p>
            </Callout>
            <InnovationLabGenericConsent />
          </>
        )}
      />
    </>
  );
};
