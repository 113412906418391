import Immutable from 'immutable';
import { useMemo } from 'react';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import { getSelectedSubscription } from '../../../../_shared/selectors/subscriptionSelectors.ts';
import { getSubscriptionAdministrators } from '../../../../_shared/utils/subscription/subscriptionUtils.ts';
import {
  SubscriptionStatus,
  emptySubscription,
} from '../../../../data/models/subscriptions/Subscription.ts';
import { StatusMessageStyle } from '../../../contentInventory/content/reducers/listingUi/statusInfo/selectors/statusMessageStyle.ts';
import { loadSubscriptionUsers } from '../../shared/actions/thunkSubscriptionManagementActions.ts';
import {
  selectAdmin,
  showAddNewSubscriptionAdminModal,
  unselectAdmin,
} from '../actions/adminsActions.ts';
import { deleteAdmins } from '../actions/thunkAdminsActions.ts';
import {
  SubscriptionAdminsManagement as SubscriptionAdminsManagementComponent,
  TableTitleMessage,
} from '../components/SubscriptionAdminsManagement.tsx';

const pluralizeAdmin = (adminCount: number): string => (adminCount > 1 ? 'admins' : 'admin');

const createTitleMessage = (
  selectedAdminsCount: number,
  isDeleteInProgress: boolean,
): TableTitleMessage => {
  if (selectedAdminsCount > 0) {
    if (isDeleteInProgress) {
      return {
        text: `Deleting ${selectedAdminsCount} subscription ${pluralizeAdmin(selectedAdminsCount)}`,
        style: StatusMessageStyle.Deleted,
      };
    }

    return {
      text: `${selectedAdminsCount} Subscription ${pluralizeAdmin(selectedAdminsCount)} selected`,
      style: StatusMessageStyle.Selected,
    };
  }

  return {
    text: 'Subscription admins',
    style: StatusMessageStyle.Headline,
  };
};

export const SubscriptionAdminsManagement = () => {
  const selectedAdminIds = useSelector((state) => state.subscriptionApp.admins.selectedAdminIds);
  const deletingSubscriptionAdmins = useSelector(
    (state) => state.subscriptionApp.admins.deletingSubscriptionAdmins,
  );
  const titleMessage = useMemo(
    () => createTitleMessage(selectedAdminIds.count(), deletingSubscriptionAdmins),
    [selectedAdminIds, deletingSubscriptionAdmins],
  );
  const selectedSubscription = useSelector(
    (state) => getSelectedSubscription(state) ?? emptySubscription,
  );
  const subscriptionAdmins = useSelector((state) =>
    getSubscriptionAdministrators(
      state.data.subscriptions.subscriptionAdministrators,
      selectedSubscription.subscriptionId,
    ),
  );
  const currentUserId = useSelector((state) => state.data.user.info.userId);

  const dispatch = useDispatch();
  const addSubscriptionAdmin = () => dispatch(showAddNewSubscriptionAdminModal());
  const onSelectAdmin = (userId: string) => dispatch(selectAdmin(userId));
  const onUnselectAdmin = (userId: string) => dispatch(unselectAdmin(userId));
  const onDeleteAdmins = (
    subscriptionId: Uuid,
    adminIds: Immutable.Set<UserId>,
    deactivateFromProjects: boolean,
  ) => dispatch(deleteAdmins(subscriptionId, adminIds, deactivateFromProjects));

  const [isLoadUsersThunkDone] = useThunkPromise(
    loadSubscriptionUsers,
    selectedSubscription.subscriptionId,
  );

  if (!isLoadUsersThunkDone || !subscriptionAdmins.length) {
    return <Loader />;
  }

  return (
    <SubscriptionAdminsManagementComponent
      currentUserId={currentUserId}
      deletingSubscriptionAdmins={deletingSubscriptionAdmins}
      isSubscriptionExpired={selectedSubscription.status === SubscriptionStatus.Expired}
      onAddSubscriptionAdmin={addSubscriptionAdmin}
      onDeleteAdmins={onDeleteAdmins}
      onSelectAdmin={onSelectAdmin}
      onUnselectAdmin={onUnselectAdmin}
      selectedAdminIds={selectedAdminIds}
      subscriptionAdmins={subscriptionAdmins}
      subscriptionId={selectedSubscription.subscriptionId}
      titleMessage={titleMessage}
    />
  );
};
