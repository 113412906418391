import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { createFormValidationResolver } from '../../../../../_shared/utils/validation/createFormValidationResolver.ts';
import { hideCreateNewTermForm, taxonomyTermCreated } from '../../actions/taxonomyActions.ts';
import { TermCreator } from '../../components/termEditors/TermCreator.tsx';
import { ITaxonomyTermFormShape } from '../../models/ITaxonomyTermFormShape.type.ts';
import { taxonomyTermValidationConfig } from '../../validation/taxonomyTermValidation.ts';

const TermCreatorContainer: React.FC = () => {
  const dispatch = useDispatch();
  const onCancel = () => dispatch(hideCreateNewTermForm());

  const formProps = useForm<ITaxonomyTermFormShape>({
    defaultValues: {
      codename: null,
      name: '',
    },
    resolver: createFormValidationResolver(taxonomyTermValidationConfig, {}),
  });

  const submitForm = formProps.handleSubmit((values) => dispatch(taxonomyTermCreated(values.name)));

  return <TermCreator formProps={formProps} onCancel={onCancel} onSubmit={submitForm} />;
};

TermCreatorContainer.displayName = 'TermCreatorContainer';
export { TermCreatorContainer as TermCreator };
