import { PropsWithChildren } from 'react';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { getCurrentProjectId } from '../../../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { NavigationTreeContextProvider } from '../context/NavigationTreeContext.tsx';

export const NavigationTreeContextProviderContainer = ({ children }: PropsWithChildren) => {
  const currentEnvironmentId = useSelector(getCurrentProjectId);

  return (
    <NavigationTreeContextProvider currentEnvironmentId={currentEnvironmentId}>
      {children}
    </NavigationTreeContextProvider>
  );
};
