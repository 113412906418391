import { trackUserEvent } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { CommentAction } from '../../../../../../_shared/models/events/CommentEventData.type.ts';
import { getEditedContentItemVariantId } from '../../../../../../_shared/selectors/getEditedContentItemVariant.ts';
import { getCurrentProjectId } from '../../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { getCommentLink } from '../../../../utils/commentUtils.ts';
import { commentThreadItemEditingStarted } from '../../actions/contentItemEditingActions.ts';
import { resolveCommentThread } from '../../actions/thunkContentItemEditingActions.ts';
import { CommentContextMenu as CommentContextMenuComponent } from '../../components/comments/CommentContextMenu.tsx';

type Props = {
  readonly allowCopyLink: boolean;
  readonly allowEdit: boolean;
  readonly allowResolve: boolean;
  readonly commentId: Uuid;
  readonly threadId: Uuid;
};

export const CommentContextMenu = ({
  allowCopyLink,
  allowEdit,
  allowResolve,
  commentId,
  threadId,
}: Props) => {
  const dispatch = useDispatch();
  const currentProjectId = useSelector(getCurrentProjectId);
  const editedItemVariantId = useSelector(getEditedContentItemVariantId);

  const onResolve = () => {
    dispatch(resolveCommentThread(threadId, true));
  };
  const onEdit = () => {
    dispatch(
      commentThreadItemEditingStarted({
        threadId,
        commentId,
      }),
    );
  };

  const onLinkCopied = () => {
    dispatch(trackUserEvent(TrackedEvent.Comments, { action: CommentAction.CopyLink }));
  };

  return (
    <CommentContextMenuComponent
      onResolve={allowResolve ? onResolve : undefined}
      onEdit={allowEdit ? onEdit : undefined}
      onLinkCopied={onLinkCopied}
      commentLink={
        allowCopyLink && editedItemVariantId
          ? getCommentLink(currentProjectId, editedItemVariantId, threadId)
          : undefined
      }
    />
  );
};
