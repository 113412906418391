import React, { memo } from 'react';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ICompiledContentType } from '../../../../contentInventory/content/models/CompiledContentType.ts';
import { ICustomTypeElement } from '../../../../contentInventory/content/models/contentTypeElements/CustomTypeElement.ts';
import { TypeElement } from '../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import {
  isCustomTypeElement,
  isGuidelinesTypeElement,
} from '../../../../contentInventory/content/models/contentTypeElements/compiledTypeElementTypeGuards.ts';
import { ICustomItemElement } from '../../../models/contentItemElements/CustomItemElement.ts';
import { ICompiledContentItemElementData } from '../../../models/contentItemElements/ICompiledContentItemElement.type.ts';
import { isCustomElement } from '../../../models/contentItemElements/compiledItemElementTypeGuards.ts';
import { getItemElementOrDefault } from '../../../stores/utils/contentItemElementsUtils.ts';
import {
  ItemElementsContextProvider,
  OriginalItemElementsContextProvider,
} from '../../ContentComponent/context/ItemElementsContext.tsx';
import { ContentItemPaneElementsGroup } from '../../ContentItemEditing/components/ContentItemPaneElementsGroup.tsx';
import { ContentGroupTabs } from '../../ContentItemEditing/containers/ContentGroupTabs.tsx';
import { CommentThreadHighlighter } from '../../ContentItemEditing/containers/comments/CommentThreadHighlighter.tsx';
import { FocusedCommentThreadHighlighter } from '../../ContentItemEditing/containers/comments/FocusedCommentThreadHighlighter.tsx';
import { CreateContentGroupTabsId } from '../../ContentItemEditing/utils/contentGroupTabsId.ts';
import { getElementValueForCustomElement } from '../../ContentItemEditing/utils/getElementValueForCustomElement.ts';
import { getItemElementRevisionComponent } from '../utils/itemElementRevisionComponentCreator.ts';
import { getUserForRevisionElement, shouldDisplayDiffForElement } from '../utils/revisionUtils.ts';
import { CustomElement } from './elements/CustomElement.tsx';
import { Guidelines } from './elements/Guidelines.tsx';

type RevisionItemElementsProps = {
  readonly areAnyContentGroupsVisible: boolean;
  readonly contentItemId: Uuid;
  readonly contentType: ICompiledContentType;
  readonly elements: ReadonlyArray<ICompiledContentItemElementData>;
  readonly elementsChangedBy?: ReadonlyMap<Uuid, UserId>;
  readonly elementsSnapshotTime: DateTimeStamp;
  readonly originalElements: ReadonlyArray<ICompiledContentItemElementData> | null;
  readonly originalSnapshotTime: DateTimeStamp | null;
  readonly selectedLanguageId: Uuid;
  readonly typeElementsInContentGroup: ReadonlyArray<TypeElement>;
};

const RevisionItemElements: React.FC<RevisionItemElementsProps> = ({
  areAnyContentGroupsVisible,
  contentItemId,
  contentType,
  elements,
  elementsChangedBy,
  elementsSnapshotTime,
  originalElements,
  originalSnapshotTime,
  selectedLanguageId,
  typeElementsInContentGroup,
}) => {
  const renderCustomElement = (
    element: ICustomItemElement,
    originalElement: ICustomItemElement | null,
    typeElement: ICustomTypeElement,
  ): JSX.Element => {
    return (
      <CustomElement
        key={typeElement.elementId}
        typeElement={typeElement}
        elementData={element}
        originalElementData={originalElement}
        getElementValue={(elementCodename) =>
          getElementValueForCustomElement(
            elements,
            typeElement.elementId,
            contentType.contentElements,
            elementCodename,
          )
        }
        revisionElementModifiedBy={getUserForRevisionElement(
          element.elementId,
          selectedLanguageId,
          typeElement,
          elementsChangedBy,
        )}
      />
    );
  };

  const renderElement = (typeElement: TypeElement): JSX.Element | null => {
    if (isGuidelinesTypeElement(typeElement)) {
      return <Guidelines key={typeElement.elementId} typeElement={typeElement} />;
    }

    const elementId = typeElement.elementId;
    const element = getItemElementOrDefault(typeElement, elements);

    const originalElement = shouldDisplayDiffForElement(typeElement, selectedLanguageId)
      ? originalElements && getItemElementOrDefault(typeElement, originalElements)
      : null;

    if (!element) {
      return null;
    }

    if (isCustomTypeElement(typeElement)) {
      return isCustomElement(element) && (!originalElement || isCustomElement(originalElement))
        ? renderCustomElement(element, originalElement, typeElement)
        : null;
    }

    const ElementComponent = getItemElementRevisionComponent(typeElement.type);

    return (
      <ElementComponent
        key={elementId}
        typeElement={typeElement}
        elementData={element}
        originalElementData={originalElement}
        revisionElementModifiedBy={getUserForRevisionElement(
          element.elementId,
          selectedLanguageId,
          typeElement,
          elementsChangedBy,
        )}
        hideValidationStatus
      />
    );
  };

  const contentGroupTabsId = CreateContentGroupTabsId.forContentItem(contentItemId);

  return (
    <>
      <CommentThreadHighlighter />
      <div
        className="content-item-pane__elements-list content-item-pane__elements-list--is-disabled"
        {...getDataUiCollectionAttribute(DataUiCollection.ContentElements)}
      >
        <ContentItemPaneElementsGroup areHeaderContentGroups isDisabled>
          <ContentGroupTabs
            canEditContent={false}
            contentGroupTabsId={contentGroupTabsId}
            contentType={contentType}
            hideValidationStatus
            hideComments
          />
        </ContentItemPaneElementsGroup>
        <ContentItemPaneElementsGroup
          isDisabled
          hasTopRoundedCorners={areAnyContentGroupsVisible}
          hasBottomRoundedCorners
        >
          <ItemElementsContextProvider elements={elements} snapshotTime={elementsSnapshotTime}>
            {originalElements ? (
              <OriginalItemElementsContextProvider
                elements={originalElements}
                snapshotTime={originalSnapshotTime}
              >
                {typeElementsInContentGroup.map(renderElement)}
              </OriginalItemElementsContextProvider>
            ) : (
              typeElementsInContentGroup.map(renderElement)
            )}
          </ItemElementsContextProvider>
        </ContentItemPaneElementsGroup>
      </div>
      <FocusedCommentThreadHighlighter />
    </>
  );
};

RevisionItemElements.displayName = 'RevisionItemElements';

const RevisionItemElementsMemo = memo(RevisionItemElements);
export { RevisionItemElementsMemo as RevisionItemElements };
