import { getScrollParent, isElementVisible } from '@kontent-ai/DOM';
import { MenuItem } from '@kontent-ai/component-library/MenuItem';
import { ShortcutsConfig, useHotkeys } from '@kontent-ai/component-library/hooks';
import { Spacing, px } from '@kontent-ai/component-library/tokens';
import { useEventListener } from '@kontent-ai/hooks';
import { FocusScope } from '@react-aria/focus';
import { ContentBlock } from 'draft-js';
import React, { CSSProperties, useCallback, useRef, useState, useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';
import { HandleClickOutside } from '../../../../../_shared/components/HandleClickOutside.tsx';
import { usePreventNativeContextMenu } from '../../../../../_shared/hooks/usePreventNativeContextMenu.ts';
import { DropDownOptionList } from '../../../../../_shared/uiComponents/DropDown/DropDownOptionList.tsx';
import { DropDownOptionsGroup } from '../../../../../_shared/uiComponents/DropDown/DropDownOptionsGroup.tsx';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  TableCellClassName,
  getBlockIdClassName,
} from '../../../editorCore/utils/editorComponentUtils.ts';
import { ToolbarOffsetFromContainer } from '../../toolbars/utils/inlineToolbarPositioningUtils.ts';
import { TableActionType } from '../api/EditorTableApi.type.ts';

type MenuPosition = {
  readonly top: number;
  readonly left: number;
};

const verticalMenuOffset = Spacing.S;
const horizontalMenuOffset = Spacing.S;

const getMenuPosition = (
  element: HTMLElement,
  menu: HTMLElement,
  editor: HTMLElement,
): MenuPosition => {
  const scrollParent = getScrollParent(element) ?? document.body;

  const containerRect = scrollParent.getBoundingClientRect();
  const elementRect = element.getBoundingClientRect();
  const menuRect = menu.getBoundingClientRect();
  const editorRect = editor.getBoundingClientRect();

  const maxTop = containerRect.bottom - menuRect.height - ToolbarOffsetFromContainer;
  const maxLeft = editorRect.right - menuRect.width;

  // Make sure the menu fits into the viewport
  const desiredTop = elementRect.top - verticalMenuOffset;
  const minTop = containerRect.top + ToolbarOffsetFromContainer;

  const top = Math.min(Math.max(desiredTop, minTop), maxTop);

  const desiredLeft = elementRect.left + elementRect.width - horizontalMenuOffset;
  const adjustedLeft = Math.min(desiredLeft, maxLeft);

  // If the menu covers the whole cell width (narrow cell at the right of the window), place the menu to the left
  const coversEntireCellWidth = adjustedLeft < elementRect.left;
  const left = coversEntireCellWidth ? elementRect.left - menuRect.width : adjustedLeft;

  return {
    top,
    left,
  };
};

export type TableAction = {
  readonly id: TableActionType | 'insert-delete-separator';
  readonly isSeparator?: boolean;
  readonly text: string;
};

type Props = {
  readonly actions: ReadonlyArray<TableAction>;
  readonly cellBlock: ContentBlock | null;
  readonly cellRef: React.RefObject<HTMLElement> | null;
  readonly editorRef: React.RefObject<HTMLElement>;
  readonly onAction: (action: string, cellBlock: ContentBlock) => void;
  readonly onHide: () => void;
  readonly visible: boolean;
};

export const TableContextMenu = ({
  actions,
  cellBlock,
  cellRef,
  editorRef,
  onAction,
  onHide,
  visible,
}: Props) => {
  const menuRef = useRef<HTMLUListElement>(null);
  const [position, setPosition] = useState<MenuPosition | null>(null);

  const updatePosition = useCallback((): void => {
    if (!visible) {
      return;
    }

    const cellElement = cellRef?.current;
    const menuElement = menuRef?.current;
    const editorElement = editorRef.current;

    if (
      cellElement &&
      menuElement &&
      editorElement &&
      isElementVisible(cellElement, ToolbarOffsetFromContainer, ToolbarOffsetFromContainer)
    ) {
      const newPosition = getMenuPosition(cellElement, menuElement, editorElement);
      setPosition(newPosition);
    } else {
      setPosition(null);
    }
  }, [visible, cellRef, editorRef]);

  useEventListener('scroll', updatePosition, self, true);
  useEventListener('resize', updatePosition, self);

  usePreventNativeContextMenu({
    ref: menuRef,
  });

  useHotkeys(
    {
      [ShortcutsConfig.ShiftF10]: (e) => {
        // Hide the context menu if the focus is inside it
        if (visible) {
          e.preventDefault();
          onHide();
        }
      },
    },
    { ref: menuRef },
  );

  const handleAction = (action: string) => {
    if (cellBlock) {
      onAction(action, cellBlock);
    }
    onHide();
  };

  const renderItems = () =>
    actions.map((action, index) => {
      if (action.isSeparator) {
        return <li key={action.id} className="dropdown-option-separator" role="separator" />;
      }

      return (
        <MenuItem
          className="dropdown-option"
          key={action.id}
          onPress={() => handleAction(action.id)}
          tabIndex={0}
          menuItemState="default"
          text={action.text}
          autoFocus={index === 0}
        />
      );
    });

  const renderHighlight = () => {
    const blockKey = cellBlock?.getKey();
    if (!blockKey) {
      return null;
    }
    const inlineStyleString = `.${TableCellClassName}.${getBlockIdClassName(blockKey)} {
      background-color: var(--element-selection-bg-color);
    }`;

    return <style dangerouslySetInnerHTML={{ __html: inlineStyleString }} />;
  };

  const renderMenu = () => {
    const style: CSSProperties | undefined = position
      ? {
          top: px(position.top),
          left: px(position.left),
        }
      : undefined;

    return createPortal(
      <HandleClickOutside observedRefs={menuRef} onClickOutside={onHide}>
        <FocusScope contain restoreFocus>
          <DropDownOptionList
            className="rte__table-menu"
            ref={menuRef}
            longer
            optionListDataUiAttributes={getDataUiCollectionAttribute(
              DataUiCollection.RteTableMenuOptions,
            )}
            style={style}
            onEscape={onHide}
          >
            <DropDownOptionsGroup>{renderItems()}</DropDownOptionsGroup>
          </DropDownOptionList>
        </FocusScope>
      </HandleClickOutside>,
      document.body,
    );
  };

  useLayoutEffect(() => {
    if (visible) {
      updatePosition();
    } else {
      setPosition(null);
    }
  }, [visible, updatePosition]);

  return visible ? (
    <>
      {renderMenu()}
      {renderHighlight()}
    </>
  ) : null;
};

export const deleteTableAction: TableAction = {
  text: 'Delete table',
  id: 'remove-table',
};

export const tableActions: ReadonlyArray<TableAction> = [
  {
    text: 'Insert row above',
    id: 'insert-row-above',
  },
  {
    text: 'Insert row below',
    id: 'insert-row-below',
  },
  {
    text: 'Insert column left',
    id: 'insert-column-left',
  },
  {
    text: 'Insert column right',
    id: 'insert-column-right',
  },
  {
    isSeparator: true,
    id: 'insert-delete-separator',
    text: '',
  },
  {
    text: 'Delete row',
    id: 'remove-row',
  },
  {
    text: 'Delete column',
    id: 'remove-column',
  },
  deleteTableAction,
];

export const deleteOnlyTableActions: ReadonlyArray<TableAction> = [deleteTableAction];
