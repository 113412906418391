import { Collection } from '@kontent-ai/utils';

export enum PredefinedRegexesTypeOption {
  Url = 'url',
  Email = 'email',
  Custom = 'custom-pattern',
}

export interface PredefinedRegex {
  title: string;
  regex: string;
  flags: string | null;
  validation_message: string;
}

export const PredefinedRegexesMap: ReadonlyMap<PredefinedRegexesTypeOption, PredefinedRegex> =
  new Map([
    [
      PredefinedRegexesTypeOption.Url,
      {
        title: 'Web URL',
        regex:
          '^(https?:\\/\\/(?:www\\.|(?!www)))?[a-z0-9]{1,63}([\\-\\.]{1}[a-z0-9]{1,63})*\\.[a-z]{2,63}(:[0-9]{1,5})?(\\/\\S*)?$',
        flags: null,
        validation_message: 'Type a valid web URL, such as https://example.org',
      },
    ],
    [
      PredefinedRegexesTypeOption.Email,
      {
        title: 'Email',
        regex:
          '^(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])$',
        flags: null,
        validation_message: 'Type a valid email address, such as name@example.org',
      },
    ],
    [
      PredefinedRegexesTypeOption.Custom,
      {
        title: 'Custom Pattern',
        regex: '',
        flags: null,
        validation_message:
          'Type a value matching the pattern required in this element. Contact your developers or content managers to learn more.',
      },
    ],
  ]);

export const PredefinedRegexesTypes = Collection.getKeys(PredefinedRegexesMap);

export const getPredefinedRegexesTypeOptionName = (value: PredefinedRegexesTypeOption): string =>
  PredefinedRegexesMap.get(value)?.title ?? '';
