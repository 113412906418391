import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from '../../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../../_shared/hooks/useSelector.ts';
import { ActiveCapabilityType } from '../../../../../../../../_shared/models/activeCapability.type.ts';
import { ContentItemEditingEventOrigins } from '../../../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { getCurrentProjectId } from '../../../../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { hasActiveVariantCapabilityForEditedItem } from '../../../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { useLivePreviewPreferenceStorage } from '../../../../../../../../localStorages/useLivePreviewPreferenceStorage.ts';
import { itemEditingModalOpened } from '../../../../../../actions/contentActions.ts';
import { ItemEditingModalDialogType } from '../../../../../../constants/itemEditingModalDialogType.ts';
import { duplicateItemWithoutContent } from '../../../../../DuplicateToCollection/actions/thunkDuplicateItemToCollectionActions.ts';
import {
  EditingActionDynamicDetailProvider,
  EditingActionDynamicDetailProviderOwnProps,
} from '../../../../components/editingActions/actions/EditingActionDynamicDetailProvider.tsx';
import { redirectToItem } from '../../../../utils/redirectToItem.ts';

export const DuplicateItemWithoutContentAction: React.FC<
  React.PropsWithChildren<EditingActionDynamicDetailProviderOwnProps>
> = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentProjectId = useSelector(getCurrentProjectId);
  const { isLivePreviewPreferred } = useLivePreviewPreferenceStorage(currentProjectId);

  const showModalDialog = useSelector(
    (state) => !hasActiveVariantCapabilityForEditedItem(ActiveCapabilityType.CreateContent, state),
  );

  const openModalDialog = () =>
    dispatch(itemEditingModalOpened(ItemEditingModalDialogType.DuplicateItemWithoutContent));
  const createItem = async (actionOrigin: ContentItemEditingEventOrigins): Promise<void> => {
    const duplicateItem = await dispatch(duplicateItemWithoutContent(actionOrigin));
    redirectToItem(history, duplicateItem.duplicateItemId, isLivePreviewPreferred);
  };

  const performAction = showModalDialog ? openModalDialog : createItem;

  return (
    <EditingActionDynamicDetailProvider performAction={performAction}>
      {children}
    </EditingActionDynamicDetailProvider>
  );
};
