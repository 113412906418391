import { assert, areMembersEqual, createCompare, naturally } from '@kontent-ai/utils';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { shallowEqual } from '../../../../../_shared/utils/shallowEqual.ts';
import { workflowCollectionsScopeChanged } from '../../actions/workflowActions.ts';
import {
  CollectionOption,
  CollectionsLimitations as CollectionsLimitationsComponent,
} from '../../components/limitations/CollectionsLimitations.tsx';
import { getSelectedCollections } from '../../utils/getSelectedCollections.ts';

interface ICollectionsLimitationsContainerProps {
  readonly label?: string;
  readonly scopeId: Uuid | undefined;
}

export const CollectionsLimitations: React.FC<ICollectionsLimitationsContainerProps> = ({
  label,
  scopeId,
}) => {
  const collections = useSelector((state) =>
    Array.from(state.data.collections.byId.values())
      .sort(
        createCompare({
          select: (item) => item.name,
          compare: naturally,
        }),
      )
      .map(
        (collection) => ({
          id: collection.id,
          label: collection.name,
          isArchived: false,
        }),
        shallowEqual,
      ),
  );

  const selectedCollections = useSelector((state) =>
    getSelectedCollections(
      state.workflowsApp.editorUi.editedWorkflowScopesById,
      scopeId,
      collections,
    ),
  );

  const [allCollections, setAllCollections] = useState<CollectionOption[]>([]);

  useEffect(() => {
    const deletedCollections = selectedCollections.filter(
      (c) => !collections.find((t) => t.id === c.id),
    );
    const updatedAllCollections = [...collections, ...deletedCollections];

    if (
      !areMembersEqual(
        updatedAllCollections.map((c) => c.id),
        allCollections.map((c) => c.id),
      )
    ) {
      setAllCollections(updatedAllCollections);
    }
  }, [collections, selectedCollections, allCollections]);

  const dispatch = useDispatch();

  const onSelectedCollectionsChanged = useCallback(
    (options: ReadonlySet<Uuid>) => {
      assert(scopeId, () => 'Cannot update options of undefined scope.');
      dispatch(workflowCollectionsScopeChanged(scopeId, options));
    },
    [scopeId],
  );

  return (
    <CollectionsLimitationsComponent
      allCollections={allCollections}
      isDisabled={!scopeId}
      label={label}
      onSelectedCollectionsChanged={onSelectedCollectionsChanged}
      selectedCollectionsIds={new Set(selectedCollections.map((c) => c.id))}
      tooltipText={scopeId ? undefined : 'The default workflow can only have one generic rule.'}
    />
  );
};

CollectionsLimitations.displayName = 'CollectionsLimitationsContainer';
