import { Inline } from '@kontent-ai/component-library/Inline';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { DragMoveHandler } from '../../../../../../../_shared/components/DragDrop/dragDrop.type.ts';
import { ActiveCapabilityType } from '../../../../../../../_shared/models/activeCapability.type.ts';
import { Asset, IAsset } from '../../../../../../../data/models/assets/Asset.ts';
import { AssetPicker } from '../../../../../../itemEditor/features/ContentItemEditing/components/elements/asset/AssetPicker.tsx';
import { TypeElementConfigurationSection } from '../../shared/TypeElementConfigurationSection.tsx';
import { DraggableAssetTile } from './DraggableAssetTile.tsx';

type AssetTypeElementDefaultValueProps = {
  readonly areAssetCollectionsVisible: boolean;
  readonly canViewAssets: boolean;
  readonly elementId: Uuid;
  readonly onMoveAsset: DragMoveHandler;
  readonly onOpenSelectAssetsDialog: () => void;
  readonly onOpenEditAssetDialog: (assetId: Uuid) => void;
  readonly onRemoveAsset: (assetId: Uuid) => void;
  readonly selectedAssets: readonly IAsset[];
};

export const AssetTypeElementDefaultValue: React.FC<AssetTypeElementDefaultValueProps> = ({
  areAssetCollectionsVisible,
  canViewAssets,
  elementId,
  onMoveAsset,
  onOpenEditAssetDialog,
  onOpenSelectAssetsDialog,
  onRemoveAsset,
  selectedAssets,
}) => {
  const disabledTooltipMessageText = areAssetCollectionsVisible
    ? 'You need permission to view assets so that you can select assets.'
    : 'You need permission to view content so that you can select assets.';
  return (
    <TypeElementConfigurationSection contentWidth="wide" title="Default value">
      <AssetPicker
        onPick={onOpenSelectAssetsDialog}
        onUpload={null}
        pickerDisabledTooltipMessage={canViewAssets ? undefined : disabledTooltipMessageText}
      >
        Select assets
      </AssetPicker>
      <Inline spacing={Spacing.XL} align="center" css={`margin-top: ${Spacing.M}px`}>
        {selectedAssets.map((asset) => (
          <DraggableAssetTile
            asset={asset}
            canViewAsset={Asset.hasCapability(asset, ActiveCapabilityType.ViewAssets)}
            elementId={elementId}
            key={asset.id}
            onMoveAsset={onMoveAsset}
            onOpenEditAssetDialog={onOpenEditAssetDialog}
            onRemoveAsset={onRemoveAsset}
          />
        ))}
      </Inline>
    </TypeElementConfigurationSection>
  );
};

AssetTypeElementDefaultValue.displayName = 'AssetTypeElementDefaultValue';
