import React from 'react';
import { Warning } from '../../../../_shared/components/infos/Warning.tsx';

export interface IContentModelsWarningProps {
  readonly heading: string;
  readonly hideTitle?: boolean;
  readonly firstParagraphText: string;
  readonly secondParagraphText?: string;
}

export const ContentModelsWarning: React.FC<IContentModelsWarningProps> = ({
  heading,
  hideTitle,
  firstParagraphText,
  secondParagraphText,
}) => {
  return (
    <div className="flex flex--center content-type-editing-callout">
      <div className="flex-item--max-width--1000">
        <Warning hideTitle={hideTitle} subtitle={heading}>
          <p>{firstParagraphText}</p>
          {secondParagraphText && <p>{secondParagraphText}</p>}
        </Warning>
      </div>
    </div>
  );
};

ContentModelsWarning.displayName = 'ContentModelsWarning';
