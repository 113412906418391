import { useRef } from 'react';
import {
  HotkeysHandler,
  HotkeysMode,
} from '../../../../_shared/components/Hotkeys/HotkeysHandler.tsx';
import { HtmlPageTitle } from '../../../../_shared/components/HtmlPageTitle.tsx';
import { AppNames } from '../../../../_shared/constants/applicationNames.ts';
import { useUpdatePaperContext } from '../../../../_shared/contexts/EditorPaperContext.tsx';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IContentTypeSnippet } from '../../../../data/models/contentModelsApp/snippets/ContentTypeSnippet.ts';
import { ContentModelsWarning } from '../../shared/components/ContentModelsWarning.tsx';
import { ContentTypeSnippetElementLibrary } from '../containers/ContentTypeSnippetElementLibrary.tsx';
import { ContentTypeSnippetElementList } from '../containers/ContentTypeSnippetElementList.tsx';
import { ContentTypeSnippetNameEditorElement } from '../containers/ContentTypeSnippetNameEditorElement.tsx';

interface Props {
  readonly editedContentTypeSnippet: IContentTypeSnippet;
  readonly focusSnippetName?: boolean;
  readonly isBeingSaved: boolean;
  readonly onSaveContentTypeSnippet: () => void;
  readonly showSnippetUsedWithPublishedItemWarning: boolean;
}

export const ContentTypeSnippetEditorPane = ({
  editedContentTypeSnippet,
  focusSnippetName,
  isBeingSaved,
  onSaveContentTypeSnippet,
  showSnippetUsedWithPublishedItemWarning,
}: Props) => {
  const contentTypePane = useRef<HTMLDivElement>(null);

  useUpdatePaperContext(contentTypePane);

  const onControlSHandler = (e: KeyboardEvent): void => {
    e.preventDefault();

    if (!isBeingSaved) {
      onSaveContentTypeSnippet();
    }
  };

  const warning = showSnippetUsedWithPublishedItemWarning ? (
    <ContentModelsWarning
      heading="Content type snippet is used with published content"
      firstParagraphText="You are about to make changes to the content type snippet that is used with published content."
      secondParagraphText="Keep in mind that any change you make to this content type snippet may affect how your published content is displayed on your website or mobile app."
    />
  ) : null;

  return (
    <HotkeysHandler
      className="canvas__wrapper"
      mode={HotkeysMode.Dual}
      handlers={{
        onControlS: onControlSHandler,
      }}
    >
      <div
        ref={contentTypePane}
        className="canvas__workspace canvas__workspace--detached-scrollbars canvas__workspace--in-content-model"
        {...getDataUiAppNameAttribute(DataUiAppName.SnippetEditing)}
      >
        <HtmlPageTitle
          appName={AppNames.ContentTypeSnippets}
          customName={editedContentTypeSnippet.name}
        />
        <section className="canvas__content">
          <div className="canvas__content-pane">
            <div className="content-snippet">
              {warning}
              <div className="canvas__inner-section canvas__inner-section--restricted-width">
                <ContentTypeSnippetNameEditorElement
                  shouldFocusSnippetName={focusSnippetName}
                  isMultiline
                />
                <ContentTypeSnippetElementList />
              </div>
            </div>
          </div>
        </section>
        <div className="canvas__sidebar">
          <ContentTypeSnippetElementLibrary />
        </div>
      </div>
    </HotkeysHandler>
  );
};
