import { InvariantException } from '@kontent-ai/errors';
import React from 'react';
import { useLocation } from 'react-router';
import { BackNavigation } from '../../../../_shared/components/AppBar/BackNavigation.tsx';
import {
  EnvironmentRouteParams,
  EnvironmentSettingsRoute,
  ProjectsInSubscriptionRoute,
  ProjectsRoute,
  SubscriptionEnvironmentSettingsRoute,
  SubscriptionEnvironmentSettingsRouteParams,
} from '../../../../_shared/constants/routePaths.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { getCurrentProjectPlan } from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { getGoBackLinkByPath } from '../../../../_shared/utils/appToolbar/appToolbarRouteUtils.ts';
import { buildPath, matchPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { isMultipleWorkflowsConfigurationVisible } from '../../../../_shared/utils/workflow/isMultipleWorkflowsConfigurationVisible.ts';

const getOriginPath = (path: string): string => {
  const matchEnvironmentSettings = matchPath<EnvironmentRouteParams>(
    path,
    EnvironmentSettingsRoute,
  );
  if (matchEnvironmentSettings) {
    return buildPath<EnvironmentRouteParams>(ProjectsRoute, {
      projectId: matchEnvironmentSettings.projectId,
    });
  }

  const matchSubscriptionEnvironmentSettings =
    matchPath<SubscriptionEnvironmentSettingsRouteParams>(
      path,
      SubscriptionEnvironmentSettingsRoute,
    );
  if (matchSubscriptionEnvironmentSettings) {
    return buildPath<SubscriptionEnvironmentSettingsRouteParams>(ProjectsInSubscriptionRoute, {
      projectId: matchSubscriptionEnvironmentSettings.projectId,
      subscriptionId: matchSubscriptionEnvironmentSettings.subscriptionId,
    });
  }

  throw InvariantException(
    'WorkflowBackNavigation: workflow path does not mach SettingsRoute or SubscriptionEnvironmentSettingsRoute',
  );
};

export const WorkflowBackNavigation: React.FC = () => {
  const location = useLocation();
  const status = useSelector((state) => state.workflowsApp.editorUi.workflowLoadingStatus);

  const isWorkflowListingEnabled = useSelector((state) =>
    isMultipleWorkflowsConfigurationVisible(
      state.data.workflows.byId,
      getCurrentProjectPlan(state),
    ),
  );

  const goBackLink = isWorkflowListingEnabled
    ? getGoBackLinkByPath(location.pathname)
    : getGoBackLinkByPath(location.pathname, getOriginPath(location.pathname));

  return <BackNavigation to={goBackLink} isDisabled={status === LoadingStatus.Loading} />;
};

WorkflowBackNavigation.displayName = 'WorkflowBackNavigation';
