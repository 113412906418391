import React, { useMemo } from 'react';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { getRelevantCollections } from '../../../../_shared/selectors/getRelevantCollections.ts';
import { Capability } from '../../../../_shared/utils/permissions/capability.ts';
import { isAssetCollectionMandatoryForCurrentSubscriptionOrProject } from '../../../environmentSettings/selectors/allowedFeaturesSelectors.ts';
import { setEditedAsset } from '../actions/assetLibraryActions.ts';
import { AssetCollection as AssetCollectionComponent } from '../components/AssetCollection.tsx';
import { notInCollectionSelectItem } from '../constants/assetNullCollectionConstants.ts';
import { cannotEditAssetsInCollection } from '../constants/uiConstants.ts';
import { getCollectionItems } from '../utils/getCollectionItems.ts';

type Props = {
  readonly isDisabled: boolean;
};

export const AssetCollection: React.FC<Props> = ({ isDisabled }) => {
  const editedAsset = useSelector((s) => s.assetLibraryApp.assetUiEditor.editedAsset);

  const viewCollections = useSelector((s) => getRelevantCollections(s, Capability.ViewAssets));
  const updateCollections = useSelector((s) => getRelevantCollections(s, Capability.UpdateAssets));

  const isAssetCollectionMandatory = useSelector(
    isAssetCollectionMandatoryForCurrentSubscriptionOrProject,
  );

  const originalAssetCollectionId = useSelector((s) =>
    editedAsset?.id ? (s.data.assets.byId.get(editedAsset.id)?.collectionId ?? null) : null,
  );
  const isNullCollectionCurrentlySelected = !editedAsset?.collectionId;
  const isNullCollectionSelectable = !isAssetCollectionMandatory || !originalAssetCollectionId;

  const availableSelectItems = useMemo(
    () =>
      getCollectionItems(
        updateCollections,
        !isNullCollectionCurrentlySelected && isNullCollectionSelectable,
      ),
    [isNullCollectionCurrentlySelected, isNullCollectionSelectable, updateCollections],
  );
  const disabledSelectItems = useMemo(
    () =>
      getCollectionItems(viewCollections, false)
        .filter((item) => !availableSelectItems.some(({ id }) => item.id === id))
        .map((item) => ({ ...item, disabledTooltipText: cannotEditAssetsInCollection })),
    [availableSelectItems, viewCollections],
  );

  const dispatch = useDispatch();
  const changeCollection = (collectionId: Uuid | null) => {
    if (editedAsset) {
      dispatch(
        setEditedAsset({
          ...editedAsset,
          collectionId: collectionId !== notInCollectionSelectItem.id ? collectionId : null,
        }),
      );
    }
  };

  return (
    <AssetCollectionComponent
      collectionItems={[...availableSelectItems, ...disabledSelectItems]}
      disabledItemIds={disabledSelectItems.map((item) => item.id)}
      onChange={changeCollection}
      selectedCollectionId={editedAsset?.collectionId ?? notInCollectionSelectItem.id}
      isDisabled={isDisabled}
    />
  );
};

AssetCollection.displayName = 'AssetCollection';
