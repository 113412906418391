import { useWrongPrevious } from '@kontent-ai/hooks';
import { useEffect } from 'react';

export const useOnFinishedAiActionTask = (isFinished: boolean, onFinished: () => void): void => {
  const previousIsFinished = useWrongPrevious(isFinished);

  useEffect(() => {
    if (!previousIsFinished && isFinished) {
      onFinished();
    }
  }, [isFinished, onFinished, previousIsFinished]);
};
