import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../../../@types/Dispatcher.type.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import {
  ITypeElementSummaryCallbackProps,
  ITypeElementSummaryDataProps,
  TypeElementSummary as TypeElementSummaryComponent,
} from '../../../components/typeElements/shared/TypeElementSummary.tsx';
import {
  DefaultValueSetSummary,
  LimitationsSetSummary,
} from '../../../constants/summaryMessageTemplates.ts';
import { ILinkedItemsTypeElementData } from '../../../models/elements/LinkedItemsTypeElementData.ts';
import { IBaseTypeElementSummaryProps } from '../../../types/IBaseTypeElementSummaryProps.type.ts';
import { hasLinkedItemsDefaultValue } from '../../../utils/typeElementDefaultValueUtils.ts';
import {
  getBaseTypeElementSummarySegments,
  joinSummarySegments,
} from '../../../utils/typeElementMessagesUtils.ts';

interface IModularContentTypeElementSummaryProps
  extends IBaseTypeElementSummaryProps<ILinkedItemsTypeElementData> {}

export function getModularContentTypeElementSummary(
  typeElement: ILinkedItemsTypeElementData,
): string {
  const summarySegments: Array<string> = [...getBaseTypeElementSummarySegments(typeElement)];
  const hasAnyLimitations =
    typeElement.minItems || typeElement.maxItems || typeElement.allowedTypes?.length;

  if (hasAnyLimitations) {
    summarySegments.push(LimitationsSetSummary);
  }

  if (hasLinkedItemsDefaultValue(typeElement)) {
    summarySegments.push(DefaultValueSetSummary);
  }

  return joinSummarySegments(summarySegments);
}

function mapStateToProps(
  _state: IStore,
  ownProps: IModularContentTypeElementSummaryProps,
): ITypeElementSummaryDataProps {
  const element = ownProps.typeElementData;
  const summaryText = getModularContentTypeElementSummary(element);

  return {
    isActive: ownProps.isActive,
    summaryText,
  };
}

function mapDispatchToProps(
  _dispatch: Dispatch,
  ownProps: IModularContentTypeElementSummaryProps,
): ITypeElementSummaryCallbackProps {
  return ownProps;
}

export const ModularContentTypeElementSummary: React.ComponentType<IModularContentTypeElementSummaryProps> =
  connect(mapStateToProps, mapDispatchToProps)(TypeElementSummaryComponent);
