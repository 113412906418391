import { Box } from '@kontent-ai/component-library/Box';
import { colorBackground, px } from '@kontent-ai/component-library/tokens';
import classNames from 'classnames';
import React, { useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { Loader } from '../../../../../../../_shared/components/Loader.tsx';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../../../../_shared/hooks/useThunkPromise.ts';
import { getSelectedLanguageCodename } from '../../../../../../../_shared/selectors/getSelectedLanguageCodename.ts';
import { getCurrentProjectId } from '../../../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { startPreviewApiChangesPolling } from '../../../../../../webSpotlight/actions/thunkWebSpotlightActions.ts';
import { WebSpotlightZIndex } from '../../../../../../webSpotlight/constants/WebSpotlightZIndex.ts';
import { WebSpotlightPreviewContainerOffset } from '../../../../../../webSpotlight/constants/uiConstants.ts';
import { useIsPreviewUrlOutdated } from '../../../../../../webSpotlight/hooks/useIsPreviewUrlOutdated.ts';
import { useSharedContentItemPreviewRouteState } from '../../../../../../webSpotlight/hooks/useSharedContentItemPreviewRouteState.ts';
import { WebSpotlightPreviewResolutionType } from '../../../../../../webSpotlight/models/webSpotlightPreviewResolutionType.ts';
import { useWebSpotlightInItemEditing } from '../context/WebSpotlightInItemEditingContext.tsx';
import { useWebSpotlightInItemEditingMessaging } from '../hooks/useWebSpotlightInItemEditingMessaging.ts';
import { useWebSpotlightPreviewInItemEditingInitialization } from '../hooks/useWebSpotlightPreviewInItemEditingInitialization.ts';
import { WebSpotlightPreviewResizeHandlesInItemEditing } from './WebSpotlightPreviewResizeHandles.tsx';

type IStyledIFrameProps = Readonly<{
  fitScreen: boolean;
  height: number;
  scale: number;
  width: number;
  display: 'initial' | 'none';
}>;

const StyledIFrame = styled.iframe.attrs((props: IStyledIFrameProps) => ({
  style: {
    display: props.display,
    width: props.fitScreen ? '100%' : px(props.width),
    height: props.fitScreen ? '100%' : px(props.height),
    transform: `scale(${props.fitScreen ? 1 : props.scale})`,
    transformOrigin: '0 0',
  },
}))<IStyledIFrameProps>`
  background-color: ${colorBackground};
  z-index: ${WebSpotlightZIndex.PreviewIFrame}
`;

type WebSpotlightPreviewSandboxProps = Readonly<{
  isResizable: boolean;
}>;

export const WebSpotlightPreviewSandboxInItemEditing: React.FC<WebSpotlightPreviewSandboxProps> = ({
  isResizable,
}) => {
  const {
    isIFrameContentLoading,
    isSmartLinkSdkInitialized,
    previewIFrameKey,
    previewIFrameResolution,
    previewIFrameResolutionType,
    previewMetadata: { url: previewUrl },
    setIsIFrameContentLoading,
    setPreviewIFrameRef,
  } = useWebSpotlightInItemEditing();

  const containerWidth = useMemo(
    () =>
      previewIFrameResolutionType !== WebSpotlightPreviewResolutionType.FitScreen &&
      previewIFrameResolution.scale < 1
        ? previewIFrameResolution.width * previewIFrameResolution.scale
        : 'auto',
    [previewIFrameResolutionType, previewIFrameResolution.scale, previewIFrameResolution.width],
  );

  useWebSpotlightInItemEditingMessaging();
  useWebSpotlightPreviewInItemEditingInitialization();

  usePreviewApiPolling();

  const [sharedPreviewState] = useSharedContentItemPreviewRouteState();

  const previewIframe = (
    <StyledIFrame
      onLoad={() => setIsIFrameContentLoading(false)}
      display={isIFrameContentLoading ? 'none' : 'initial'}
      key={previewIFrameKey}
      title="Preview"
      ref={setPreviewIFrameRef}
      src={sharedPreviewState?.url ?? previewUrl}
      fitScreen={previewIFrameResolutionType === WebSpotlightPreviewResolutionType.FitScreen}
      width={previewIFrameResolution.width}
      height={previewIFrameResolution.height}
      scale={previewIFrameResolution.scale}
      sandbox="allow-forms allow-modals allow-popups allow-same-origin allow-scripts allow-downloads allow-storage-access-by-user-activation"
      className={classNames(
        'web-spotlight-preview__sandbox',
        'web-spotlight__layout-guard-content',
        {
          'web-spotlight-preview__sandbox--is-initialized': isSmartLinkSdkInitialized,
        },
      )}
      {...getDataUiElementAttribute(DataUiElement.ContentItemPreviewContent)}
    />
  );

  return (
    <Box
      width={containerWidth}
      height="100%"
      position="absolute"
      css={
        isResizable
          ? `inset: 0 ${px(WebSpotlightPreviewContainerOffset)} ${px(WebSpotlightPreviewContainerOffset)} 0`
          : 'inset: 0 0 0 0'
      }
      marginX="auto"
      marginY={0}
      paddingRight={isResizable ? WebSpotlightPreviewContainerOffset : 0}
      paddingBottom={isResizable ? WebSpotlightPreviewContainerOffset : 0}
    >
      {isIFrameContentLoading && <Loader />}
      {isResizable ? (
        <WebSpotlightPreviewResizeHandlesInItemEditing isInitialized={!isIFrameContentLoading}>
          {previewIframe}
        </WebSpotlightPreviewResizeHandlesInItemEditing>
      ) : (
        previewIframe
      )}
    </Box>
  );
};

const usePreviewApiPolling = (): void => {
  const currentProjectId = useSelector(getCurrentProjectId);
  const selectedLanguageCodename = useSelector(getSelectedLanguageCodename);

  const { isAutoRefreshEnabledByUser, refreshPreview, supportedSmartLinkFeatures } =
    useWebSpotlightInItemEditing();

  const canPoll = useSelector(
    (s) =>
      isAutoRefreshEnabledByUser &&
      !!supportedSmartLinkFeatures?.refreshHandler &&
      !!s.webSpotlightApp.lastModifiedPreviewItems.size,
  );
  const isPreviewUrlOutdated = useIsPreviewUrlOutdated();

  const onChangeReady = useCallback(
    (itemCodename: string) => {
      refreshPreview({
        isManualRefresh: false,
        data: {
          projectId: currentProjectId,
          languageCodename: selectedLanguageCodename,
          updatedItemCodename: itemCodename,
        },
        isPreviewUrlOutdated,
      });
    },
    [refreshPreview, currentProjectId, selectedLanguageCodename, isPreviewUrlOutdated],
  );

  useThunkPromise(startPreviewApiChangesPolling, onChangeReady, { canRun: canPoll });
};
