import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { Box } from '@kontent-ai/component-library/Box';
import { Checkbox } from '@kontent-ai/component-library/Checkbox';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, Typography, colorTextHint } from '@kontent-ai/component-library/tokens';
import React, { useCallback, useContext, useState } from 'react';
import { documentationLinks } from '../../../../../../../_shared/constants/documentationLinks.ts';
import {
  DataUiInput,
  getDataUiInputAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { OriginalTypeContext } from '../../../../contexts/OriginalTypeContext.tsx';
import { IBaseTypeElementData } from '../../../../models/elements/types/TypeElementData.ts';
import { isTriggerElement, isTypeElementNew } from '../../../../utils/typeElementsUtils.ts';
import { canTypeElementBeNonLocalizable } from '../../../../utils/typeValidationUtils.ts';
import { MarkElementAsLocalizableModal } from './MarkElementAsLocalizableModal.tsx';

type Props = {
  readonly onChange: (isNonLocalizable: boolean) => void;
  readonly typeElementData: IBaseTypeElementData;
  readonly typeElements: ReadonlyArray<IBaseTypeElementData>;
};

export const NonLocalizableConfiguration: React.FC<Props> = ({
  onChange,
  typeElementData,
  typeElements,
}) => {
  const [isMarkAsLocalizableModalOpen, setIsMarkAsLocalizableModalOpen] = useState<boolean>(false);

  const handleOnChange = useCallback(
    (isNonLocalizable: boolean): void => {
      if (isNonLocalizable) {
        onChange(true);
      } else {
        setIsMarkAsLocalizableModalOpen(true);
      }
    },
    [onChange],
  );

  const { originalTypeElementsById } = useContext(OriginalTypeContext);
  const canSwitch = canSwitchLocalizability(
    typeElementData,
    typeElements,
    originalTypeElementsById,
  );

  return (
    <>
      <Stack align="start" spacing={Spacing.XS}>
        <Checkbox
          checkboxState={canSwitch === CanSwitchLocalizability.Can ? 'default' : 'disabled'}
          checked={typeElementData.isNonLocalizable}
          onToggle={handleOnChange}
          tooltipText={tooltipTexts[canSwitch]}
          tooltipPlacement="right"
          {...getDataUiInputAttribute(DataUiInput.NonLocalizable)}
        >
          Do not localize
        </Checkbox>
        <CheckboxCaption>
          <OutwardLink href={documentationLinks.nonLocalizableElements}>
            Non-localizable elements
          </OutwardLink>
          &nbsp;use the default language value across all languages.
        </CheckboxCaption>
        <CheckboxCaption>
          You can enable this setting only for newly added elements.
        </CheckboxCaption>
      </Stack>

      <MarkElementAsLocalizableModal
        isModalOpen={isMarkAsLocalizableModalOpen}
        typeElementData={typeElementData}
        onClose={() => setIsMarkAsLocalizableModalOpen(false)}
        onClickPrimaryAction={() => {
          onChange(false);
          setIsMarkAsLocalizableModalOpen(false);
        }}
      />
    </>
  );
};

NonLocalizableConfiguration.displayName = 'NonLocalizableConfiguration';

const CheckboxCaption: React.FC<React.PropsWithChildren<NoProps>> = ({ children }) => (
  <Box color={colorTextHint} paddingLeft={Spacing.XXL} typography={Typography.Caption}>
    {children}
  </Box>
);

enum CanSwitchLocalizability {
  Can = 'Can',
  CannotBecauseItHasCondition = 'CannotBecauseItHasCondition',
  CannotBecauseItsUsedAsConditionTrigger = 'CannotBecauseItsUsedAsConditionTrigger',
  CannotBecauseOfElementType = 'CannotBecauseOfElementType',
  CannotConvertExistingLocalizable = 'CannotConvertExistingLocalizable',
}

const canSwitchLocalizability = (
  typeElementData: IBaseTypeElementData,
  typeElements: ReadonlyArray<IBaseTypeElementData>,
  originalTypeElementsById: ReadonlyMap<Uuid, IBaseTypeElementData>,
): CanSwitchLocalizability => {
  if (!canTypeElementBeNonLocalizable(typeElementData)) {
    return CanSwitchLocalizability.CannotBecauseOfElementType;
  }

  const isOriginalElementLocalizable = !originalTypeElementsById.get(typeElementData.elementId)
    ?.isNonLocalizable;
  if (!isTypeElementNew(typeElementData) && isOriginalElementLocalizable) {
    return CanSwitchLocalizability.CannotConvertExistingLocalizable;
  }

  if (typeElementData.condition?.isActive) {
    return CanSwitchLocalizability.CannotBecauseItHasCondition;
  }

  if (isTriggerElement(typeElementData.elementId, typeElements)) {
    return CanSwitchLocalizability.CannotBecauseItsUsedAsConditionTrigger;
  }

  return CanSwitchLocalizability.Can;
};

const tooltipTexts: Record<CanSwitchLocalizability, string | undefined> = {
  Can: undefined,
  CannotBecauseItHasCondition:
    'You can’t change the localization setting because this element contains a condition.',
  CannotBecauseItsUsedAsConditionTrigger:
    'You can’t change the localization setting because this element is used in another condition.',
  CannotBecauseOfElementType: 'This element cannot be set as non-localizable.',
  CannotConvertExistingLocalizable:
    'This setting is permanent for this element. You can only convert non-localizable elements to localizable. See the note below the checkbox.',
};
