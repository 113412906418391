import React, { memo, useCallback } from 'react';
import { BarItemChildList } from '../../../../_shared/components/BarItems/BarItemChildList.tsx';
import { DropTarget } from '../../../../_shared/components/DragDrop/DropTarget.tsx';
import { DragMoveHandler } from '../../../../_shared/components/DragDrop/dragDrop.type.ts';
import { DndTypes } from '../../../../_shared/constants/dndTypes.ts';
import { getTreeNodeMoveDirections } from '../../../../_shared/utils/dragDrop/dragDropUtils.ts';
import { ISitemapNode } from '../../../../data/models/contentModelsApp/sitemap/Sitemap.ts';
import { DirectionItemEnum } from '../constants/DirectionItemEnum.ts';
import { SitemapNodeBar } from './SitemapNodeBar.tsx';

export interface ISitemapNodeOwnProps {
  readonly isBeingDragged: boolean;
  readonly nodeId: Uuid;
}

interface ISitemapNodeProps extends ISitemapNodeOwnProps {
  readonly childNodeComponent: React.ComponentType<ISitemapNodeOwnProps>;
  readonly isBeingEdited: boolean;
  readonly isCollapsed: boolean;
  readonly nodeData: ISitemapNode;
  readonly onNodeMoved: (targetNodeId: Uuid, direction: ReadonlySet<DirectionItemEnum>) => void;
}

export const SitemapNode: React.FC<ISitemapNodeProps> = memo(
  ({
    childNodeComponent,
    isBeingDragged,
    isBeingEdited,
    isCollapsed,
    nodeData,
    nodeId,
    onNodeMoved,
  }) => {
    const onMove: DragMoveHandler = useCallback(
      ({ pointer, targetBoundingRect, targetId }) => {
        const directions = getTreeNodeMoveDirections(pointer, targetBoundingRect);
        onNodeMoved(targetId, directions);
      },
      [onNodeMoved],
    );

    return (
      <DropTarget<HTMLLIElement>
        accept={DndTypes.Sitemap_Node_Move}
        canDrop={!isBeingEdited}
        canDropToSelf
        onMove={onMove}
        parentId=""
        targetId={nodeId}
        hoveringCollisionStrategy={({ monitor }) => monitor.isOver({ shallow: true })}
        renderDroppable={(ref) => (
          <li className="bar-item__node" ref={ref}>
            <SitemapNodeBar isBeingEdited={isBeingEdited} nodeId={nodeId} />
            {!isBeingDragged && (
              <BarItemChildList
                childComponent={childNodeComponent}
                childIds={nodeData.childIds}
                isCollapsed={isCollapsed}
                isBeingDragged={isBeingDragged}
              />
            )}
          </li>
        )}
      />
    );
  },
);

SitemapNode.displayName = 'SitemapNode';
