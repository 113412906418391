import { Box } from '@kontent-ai/component-library/Box';
import { Divider, DividerDirection } from '@kontent-ai/component-library/Dividers';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { ItemId } from '@kontent-ai/component-library/Selects';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { useCallback, useMemo, useState } from 'react';
import { trackUserEvent } from '../../../../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../../../../_shared/hooks/useDispatch.ts';
import { WebSpotlightPreviewResolutionChangeOrigin } from '../../../../../../../../../_shared/models/TrackUserEventData.ts';
import {} from '../../../../../../../../webSpotlight/constants/uiConstants.ts';
import {
  WebSpotlightPreviewResolutionType,
  fitToScreenResolution,
  webSpotlightPreviewResolutionTypeOptions,
} from '../../../../../../../../webSpotlight/models/webSpotlightPreviewResolutionType.ts';
import {
  getWebSpotlightPreviewScaleOptions,
  scaleIdToValue,
} from '../../../../../../../../webSpotlight/models/webSpotlightPreviewScale.ts';
import { useWebSpotlightInItemEditing } from '../../../context/WebSpotlightInItemEditingContext.tsx';
import { HeightInput } from '../components/HeightInput.tsx';
import { ResetViewButton } from '../components/ResetViewButton.tsx';
import { ResolutionTypeButton } from '../components/ResolutionTypeButton.tsx';
import { RotatePreviewButton } from '../components/RotatePreviewButton.tsx';
import { ScaleSelector } from '../components/ScaleSelector.tsx';
import { WidthInput } from '../components/WidthInput.tsx';

const spaceBetweenItems = Spacing.M;

export const ResolutionSettings = () => {
  const dispatch = useDispatch();

  const {
    previewIFrameResolution,
    previewIFrameResolutionType,
    rescalePreview,
    resizePreview,
    rotatePreview,
    selectPreviewResolutionType,
    setPreviewIFrameResolutionType,
  } = useWebSpotlightInItemEditing();

  const [formPreviewResolutionType, setFormPreviewResolutionType] =
    useState<WebSpotlightPreviewResolutionType | null>(null);

  const activeResolutionType = formPreviewResolutionType ?? previewIFrameResolutionType;

  const handleResolutionTypeChange = useCallback(
    (
      type: WebSpotlightPreviewResolutionType,
      origin: WebSpotlightPreviewResolutionChangeOrigin,
    ) => {
      setFormPreviewResolutionType(type);
      selectPreviewResolutionType(type);
      dispatch(
        trackUserEvent(TrackedEvent.WebSpotlightPreviewResolutionChanged, {
          origin,
        }),
      );
    },
    [selectPreviewResolutionType],
  );

  const handleResolutionSubmit = useCallback(
    (width: number, height: number) => {
      resizePreview(width, height);
      setPreviewIFrameResolutionType(WebSpotlightPreviewResolutionType.Responsive);
      dispatch(
        trackUserEvent(TrackedEvent.WebSpotlightPreviewResolutionChanged, {
          origin: WebSpotlightPreviewResolutionChangeOrigin.Inputs,
        }),
      );
    },
    [resizePreview, setPreviewIFrameResolutionType],
  );

  const [selectedScaleId, scaleOptions] = useMemo(
    () => getWebSpotlightPreviewScaleOptions(previewIFrameResolution.scale),
    [previewIFrameResolution.scale],
  );

  const handleScaleChange = useCallback(
    (scaleId: ItemId) => {
      const newScale = scaleId ? scaleIdToValue(scaleId) : fitToScreenResolution.scale;
      rescalePreview(newScale);
    },
    [rescalePreview],
  );

  return (
    <Row spacingY={Spacing.M} alignX="center" alignY="center">
      <Column width="content">
        <Box paddingY={Spacing.S}>
          <Inline spacingX={Spacing.M}>
            {webSpotlightPreviewResolutionTypeOptions.map((option) => (
              <ResolutionTypeButton
                key={option.id}
                isSelected={activeResolutionType === option.id}
                onChange={(type) =>
                  handleResolutionTypeChange(
                    type,
                    WebSpotlightPreviewResolutionChangeOrigin.ResolutionTypeSelector,
                  )
                }
                option={option}
              />
            ))}
            <Divider
              direction={DividerDirection.Vertical}
              offsetBefore={Spacing.None}
              offsetAfter={Spacing.None}
            />
            <RotatePreviewButton onClick={rotatePreview} />
          </Inline>
        </Box>
      </Column>
      <Column>
        <Row alignX="center" spacingX={spaceBetweenItems} spacingY={Spacing.M}>
          <Column width="content">
            <Inline spacingX={spaceBetweenItems}>
              <WidthInput
                initialValue={previewIFrameResolution.width}
                onChange={() =>
                  setFormPreviewResolutionType(WebSpotlightPreviewResolutionType.Responsive)
                }
                onSubmit={(width) => handleResolutionSubmit(width, previewIFrameResolution.height)}
              />
              <HeightInput
                initialValue={previewIFrameResolution.height}
                onChange={() =>
                  setFormPreviewResolutionType(WebSpotlightPreviewResolutionType.Responsive)
                }
                onSubmit={(height) => handleResolutionSubmit(previewIFrameResolution.width, height)}
              />
              <Divider
                direction={DividerDirection.Vertical}
                offsetBefore={Spacing.None}
                offsetAfter={Spacing.None}
              />
            </Inline>
          </Column>
          <Column width="content">
            <Inline align="center" spacingX={spaceBetweenItems}>
              <ScaleSelector
                onChange={handleScaleChange}
                options={scaleOptions}
                selectedOptionId={selectedScaleId}
              />
              <ResetViewButton
                onClick={() =>
                  handleResolutionTypeChange(
                    WebSpotlightPreviewResolutionType.FitScreen,
                    WebSpotlightPreviewResolutionChangeOrigin.FitScreenButton,
                  )
                }
              />
            </Inline>
          </Column>
        </Row>
      </Column>
    </Row>
  );
};
