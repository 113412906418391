import React, { memo } from 'react';
import {
  EditorProps,
  useEditor,
  usePluginComposition,
} from '../../../../editorCore/Editor.composition.tsx';
import { useComposition as useViewerComposition } from '../../../../editors/simpleText/SimpleMultilineTextViewer.tsx';
import { SimpleTextEditorLimitations } from '../../../apiLimitations/api/EditorFeatureLimitations.ts';
import { textKeyCommandMap } from '../../../keyboardShortcuts/api/editorKeyboardUtils.ts';
import { BorderlessPlugin } from '../../../visuals/BorderlessPlugin.tsx';

const useComposition = () => usePluginComposition(useViewerComposition(), BorderlessPlugin);

export type StringAiSuggestionViewerProps = EditorProps<typeof useComposition>;

export const StringAiSuggestionViewer: React.FC<StringAiSuggestionViewerProps> = memo((props) => {
  const composition = useComposition();

  return useEditor(composition, {
    keyCommandMap: textKeyCommandMap,
    limitations: SimpleTextEditorLimitations,
    ...props,
  });
});

StringAiSuggestionViewer.displayName = 'StringAiSuggestionViewer';
