import { Box } from '@kontent-ai/component-library/Box';
import { Button } from '@kontent-ai/component-library/Button';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Paper } from '@kontent-ai/component-library/Paper';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Stack } from '@kontent-ai/component-library/Stack';
import {
  BorderRadius,
  Spacing,
  Typography,
  borderWidthDefault,
  colorBackgroundLowEmphasis,
  colorBorderLowEmphasis,
  colorTextDefault,
  px,
  sizeFilterSidebarWidth,
  spacingElementEdgeHorizontal,
  spacingMainLayoutBottom,
  spacingMainLayoutTop,
} from '@kontent-ai/component-library/tokens';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { useIsRefinedNavigationFeatureEnabled } from '../../../../refinedNavigation/contexts/RefinedNavigationContext.tsx';
import { ClearFilterButton } from '../../../shared/components/filter/ClearFilterButton.tsx';
import { AssetsCollectionFilterSelector } from '../../containers/AssetListing/FilterSelectors/AssetCollectionFilterSelector.tsx';
import { AssetsTaxonomyFilterSelector } from '../../containers/AssetListing/FilterSelectors/AssetTaxonomyFilterSelector.tsx';

const FilterHeadline = styled.div`
  ${Typography.SubheadlineLarge}
  color: ${colorTextDefault};
`;

type AssetFilterProps = {
  readonly onFilterCleared: (() => void) | undefined;
  readonly onHideFilter?: () => void;
};

export const AssetFilter: React.FC<AssetFilterProps> = ({ onFilterCleared, onHideFilter }) => {
  const isRefinedNavigationFeatureEnabled = useIsRefinedNavigationFeatureEnabled();
  if (!isRefinedNavigationFeatureEnabled) {
    return (
      <Box
        paddingTop={spacingMainLayoutTop}
        paddingX={spacingElementEdgeHorizontal}
        paddingBottom={spacingMainLayoutBottom}
        css={`
          width: ${px(sizeFilterSidebarWidth)};
          min-height: 100%;
          max-height: 100%;
          border-right: ${px(borderWidthDefault)} solid ${colorBorderLowEmphasis};
          overflow-y: auto;
        `}
        {...getDataUiElementAttribute(DataUiElement.Filter)}
      >
        <Row alignY="center">
          <Column>
            <FilterHeadline>Refine results</FilterHeadline>
          </Column>

          <Column
            width="content"
            css={onFilterCleared ? undefined : 'visibility: hidden;'}
            aria-hidden={!onFilterCleared}
          >
            <ClearFilterButton onClick={() => onFilterCleared?.()} />
          </Column>
        </Row>

        <AssetsCollectionFilterSelector />
        <AssetsTaxonomyFilterSelector />
      </Box>
    );
  }

  return (
    <Paper
      borderRadius={BorderRadius.M}
      backgroundColor={colorBackgroundLowEmphasis}
      borderColor={colorBackgroundLowEmphasis}
      padding={Spacing.L}
      paddingTop={Spacing.None}
    >
      <Stack spacing={Spacing.L}>
        <Row spacingX={Spacing.XXL}>
          <SelectorColumn>
            <AssetsCollectionFilterSelector />
          </SelectorColumn>
          <SelectorColumn>
            <AssetsTaxonomyFilterSelector />
          </SelectorColumn>
        </Row>
        <Box display="flex" justifyContent="end">
          <Inline spacing={Spacing.S}>
            {onFilterCleared && (
              <Button buttonStyle="secondary" destructive onClick={onFilterCleared}>
                <Button.Label>Clear all filters</Button.Label>
              </Button>
            )}
            <Button buttonStyle="primary" onClick={onHideFilter}>
              <Button.Label>Hide filters</Button.Label>
            </Button>
          </Inline>
        </Box>
      </Stack>
    </Paper>
  );
};

const SelectorColumn = ({ children }: PropsWithChildren) => (
  <Column css="&:empty { display: none;}" width="1/3">
    {children}
  </Column>
);
