import { RefCallback } from '@kontent-ai/utils';
import classNames from 'classnames';
import { forwardRef, useCallback } from 'react';
import { TabSkipContainer } from '../../../../../../_shared/components/TabSkipContainer.tsx';
import { DeferAutoScrollCssClass } from '../../../../../../_shared/utils/autoScrollUtils.ts';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ICommentThread } from '../../../../models/comments/CommentThreads.ts';
import {
  CommentThreadNavigationData,
  CommentThreadWithNavigation,
  isThreadSaved,
} from '../../../../utils/commentUtils.ts';
import { CommentThread } from '../../containers/comments/CommentThread.tsx';
import { InlineCommentThreadHeader } from '../../containers/comments/InlineCommentThreadHeader.tsx';
import { UnsavedInlineCommentThread } from '../../containers/comments/UnsavedInlineCommentThread.tsx';
import { CommentThreadPositioner } from './CommentThreadPositioner.tsx';
import { ThreadOffset } from './InlineCommentPane.tsx';

export const InlineCommentPaneViewClassName = 'content-item-pane__comment-thread-list-pane';

export type RegisterThreadElement = (threadId: Uuid, element: HTMLDivElement | null) => void;

type PositionedThreadProps = {
  readonly absoluteOffset: number;
  readonly allowAnimation: boolean;
  readonly allowKeyboardNavigation: boolean;
  readonly commentThread: ICommentThread;
  readonly hasKnownPosition: boolean;
  readonly isNewComment: boolean;
  readonly onThreadResized: (resizedThreadId: Uuid, newHeight: number) => void;
  readonly registerThreadElement: RegisterThreadElement;
  readonly navigationData: CommentThreadNavigationData | null;
};

const PositionedThread = ({
  absoluteOffset,
  allowAnimation,
  allowKeyboardNavigation,
  commentThread,
  hasKnownPosition,
  isNewComment,
  onThreadResized,
  navigationData,
  registerThreadElement,
}: PositionedThreadProps) => {
  const refCallback = useCallback<RefCallback<HTMLDivElement>>(
    (element) => {
      registerThreadElement(commentThread.id, element);
    },
    [registerThreadElement, commentThread.id],
  );

  const renderHeader = useCallback(
    (statusId: string) =>
      navigationData && (
        <InlineCommentThreadHeader
          {...navigationData}
          allowKeyboardNavigation={allowKeyboardNavigation}
          statusId={statusId}
          threadId={commentThread.id}
        />
      ),
    [allowKeyboardNavigation, navigationData, commentThread.id],
  );

  return (
    <CommentThreadPositioner
      isNewComment={isNewComment}
      absoluteOffset={hasKnownPosition ? absoluteOffset : null}
      allowAnimation={allowAnimation}
    >
      {isNewComment ? (
        <UnsavedInlineCommentThread ref={refCallback} commentThread={commentThread} />
      ) : (
        <CommentThread
          className="comment-thread-list__thread"
          commentThread={commentThread}
          onResized={hasKnownPosition ? onThreadResized : undefined}
          ref={refCallback}
          renderHeader={renderHeader}
          showReferenceForInlineThreads={false}
          withManagedFocus
        />
      )}
    </CommentThreadPositioner>
  );
};

type CommentThreadsProps = Pick<
  InlineCommentPaneViewProps,
  | 'allowAnimation'
  | 'allowKeyboardNavigation'
  | 'onThreadResized'
  | 'registerThreadElement'
  | 'relativeThreadOffsets'
  | 'threads'
>;

const CommentThreads = ({
  allowAnimation,
  allowKeyboardNavigation,
  threads,
  registerThreadElement,
  onThreadResized,
  relativeThreadOffsets,
}: CommentThreadsProps) => {
  let absoluteOffset = 0;

  return (
    <>
      {threads.map((thread) => {
        const commentThread = thread.commentThread;
        const threadOffset: ThreadOffset | undefined = relativeThreadOffsets.get(commentThread.id);
        const hasKnownPosition = threadOffset !== undefined;
        absoluteOffset += threadOffset && hasKnownPosition ? threadOffset.relativeOffset : 0;
        const isNewComment = !isThreadSaved(commentThread);

        return (
          <PositionedThread
            absoluteOffset={absoluteOffset}
            allowAnimation={allowAnimation}
            allowKeyboardNavigation={allowKeyboardNavigation}
            commentThread={commentThread}
            hasKnownPosition={hasKnownPosition}
            isNewComment={isNewComment}
            key={commentThread.id}
            onThreadResized={onThreadResized}
            navigationData={thread.navigationData}
            registerThreadElement={registerThreadElement}
          />
        );
      })}
    </>
  );
};

type InlineCommentPaneViewProps = {
  readonly allowAnimation: boolean;
  readonly allowKeyboardNavigation: boolean;
  readonly isAnimating: boolean;
  readonly minHeight?: number;
  readonly onThreadResized: (resizedThreadId: Uuid, newHeight: number) => void;
  readonly registerThreadElement: RegisterThreadElement;
  readonly relativeThreadOffsets: ReadonlyMap<Uuid, ThreadOffset>;
  readonly threads: ReadonlyArray<CommentThreadWithNavigation>;
};

export const InlineCommentPaneView = forwardRef<HTMLDivElement, InlineCommentPaneViewProps>(
  (
    {
      allowAnimation,
      allowKeyboardNavigation,
      isAnimating,
      minHeight,
      onThreadResized,
      registerThreadElement,
      relativeThreadOffsets,
      threads,
    },
    ref,
  ) => (
    <div
      className={classNames(InlineCommentPaneViewClassName, {
        [DeferAutoScrollCssClass]: isAnimating,
      })}
    >
      <TabSkipContainer>
        <div
          className="content-item-pane__comment-thread-list"
          ref={ref}
          style={{ minHeight }}
          {...getDataUiCollectionAttribute(DataUiCollection.CommentInlineThreads)}
        >
          <CommentThreads
            allowAnimation={allowAnimation}
            allowKeyboardNavigation={allowKeyboardNavigation}
            onThreadResized={onThreadResized}
            registerThreadElement={registerThreadElement}
            relativeThreadOffsets={relativeThreadOffsets}
            threads={threads}
          />
        </div>
      </TabSkipContainer>
    </div>
  ),
);

InlineCommentPaneView.displayName = 'InlineCommentPaneView';
