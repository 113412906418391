import { History } from 'history';
import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import {
  EnsuredSubscriptionRouteParams,
  ProjectsInSubscriptionRoute,
  SubscriptionsRoute,
} from '../../../../../_shared/constants/routePaths.ts';
import { getSelectedSubscription } from '../../../../../_shared/selectors/subscriptionSelectors.ts';
import { getCurrentProject } from '../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { buildPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { IUserProjectInfo } from '../../../../../data/models/user/UserProjectInfo.ts';

interface IDeps {
  readonly updateCurrentProject: (
    project: IUserProjectInfo,
    abortSignal?: AbortSignal,
  ) => ThunkPromise;
}

export const createEnsureSubscriptionCurrentProjectAction =
  ({ updateCurrentProject }: IDeps) =>
  (desiredProjectId: Uuid, history: History, abortSignal: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    const {
      data: { user },
    } = getState();

    const selectedSubscription = getSelectedSubscription(getState());
    if (!selectedSubscription) {
      history.push(SubscriptionsRoute);
      return;
    }

    const projectToBeSet = user.projectsInfoById.get(desiredProjectId);
    if (!projectToBeSet) {
      history.push(
        buildPath<EnsuredSubscriptionRouteParams>(ProjectsInSubscriptionRoute, {
          subscriptionId: selectedSubscription.subscriptionId,
        }),
      );
      return;
    }

    if (getCurrentProject(getState()).projectId !== desiredProjectId) {
      await dispatch(updateCurrentProject(projectToBeSet, abortSignal));
      return;
    }
  };
