import { Button } from '@kontent-ai/component-library/Button';
import React from 'react';
import { EmptyState } from '../../../../../_shared/components/EmptyState/EmptyState.tsx';

interface ISitemapEmptyState {
  readonly onCreateButtonNewClick: () => void;
}

export const SitemapEmptyState: React.FC<ISitemapEmptyState> = ({ onCreateButtonNewClick }) => {
  return (
    <EmptyState>
      <EmptyState.Title>Sitemap helps you categorize your content.</EmptyState.Title>
      <EmptyState.Content>
        <EmptyState.ContentGroup>
          <p>Create, reorder, and nest sitemap items to outline your content structure.</p>
          <p>Start by adding the first sitemap item.</p>
        </EmptyState.ContentGroup>
      </EmptyState.Content>
      <EmptyState.Footer>
        <Button buttonStyle="primary" onClick={onCreateButtonNewClick}>
          Create new Sitemap item
        </Button>
      </EmptyState.Footer>
    </EmptyState>
  );
};

SitemapEmptyState.displayName = 'SitemapEmptyState';
