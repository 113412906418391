import { assert } from '@kontent-ai/utils';
import React from 'react';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import {
  getCurrentProject,
  getCurrentProjectSubscription,
} from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { compose } from '../../../../_shared/utils/func/compose.ts';
import {
  ICapability,
  getUserCapability,
} from '../../../../_shared/utils/permissions/capability.ts';
import { isSitemapEnabled } from '../../sitemap/utils/sitemapUtils.ts';
import { ContentModels } from '../components/ContentModels.tsx';
import {
  ContentModelsTabInfo,
  ContentModelsTabsStructure,
  getContentModelsTabsStructure,
} from '../utils/contentModelsTabsStructure.ts';

const getFirstAllowedTab = (
  capability: ICapability,
  tabs: ContentModelsTabsStructure,
): ContentModelsTabInfo | null =>
  tabs.find((tab) => capability.canOneOf([tab.requiredCapability])) || null;

type Props = {
  readonly projectId: Uuid;
};

const ContentModelsContainer: React.FC<Props> = ({ projectId }) => {
  const showSitemap = useSelector(compose(isSitemapEnabled, getCurrentProjectSubscription));

  const firstAllowedTab = useSelector((state) => {
    const capability = getUserCapability(getCurrentProject(state));
    const contentModelsTabs = getContentModelsTabsStructure(showSitemap);

    return getFirstAllowedTab(capability, contentModelsTabs);
  });

  assert(
    firstAllowedTab,
    () =>
      `Cannot render ${ContentModels.displayName} for a user who has not the capability to be here.`,
  );

  return (
    <ContentModels
      firstAllowedTab={firstAllowedTab}
      isSitemapEnabled={showSitemap}
      projectId={projectId}
    />
  );
};

ContentModelsContainer.displayName = 'ContentModelsContainer';

// biome-ignore lint/style/noDefaultExport: Necessary for bundle splitting
export default ContentModelsContainer;
