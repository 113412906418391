import React, { useContext } from 'react';
import { RefinedNavigationContext } from '../../../../../app/applications/refinedNavigation/contexts/RefinedNavigationContext.tsx';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { SubscriptionAdminInvitationModal } from '../../Admins/containers/invitationModal/SubscriptionAdminInvitationModal.tsx';
import { PromoteUsersModal } from '../../Admins/containers/promoteUsersModal/PromoteUsersModal.tsx';
import { ChangePlanModal } from '../../PlanSelection/containers/ChangePlanModal.tsx';
import { SubscriptionManagementTabs } from '../containers/tabs/SubscriptionManagementTabs.tsx';

export const SubscriptionEdit: React.FC<React.PropsWithChildren<NoProps>> = ({ children }) => {
  const { isRefinedNavigationFeatureEnabled } = useContext(RefinedNavigationContext);

  return (
    <div className="canvas" {...getDataUiAppNameAttribute(DataUiAppName.SubscriptionEditing)}>
      <div className="canvas__workspace">
        {isRefinedNavigationFeatureEnabled ? null : <SubscriptionManagementTabs />}
        <section className="canvas__content">{children}</section>
        <SubscriptionAdminInvitationModal />
        <PromoteUsersModal />
        <ChangePlanModal />
      </div>
    </div>
  );
};

SubscriptionEdit.displayName = 'SubscriptionEdit';
