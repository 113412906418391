import { FC, useContext } from 'react';
import { Route, Switch } from 'react-router';
import { IUserProjectInfo } from '../../../data/models/user/UserProjectInfo.ts';
import {
  ActiveProjectsRoute,
  ArchivedProjectsRoute,
  SubscriptionRoute,
  UserProfileRoute,
} from '../../constants/routePaths.ts';
import { RefinedNavigationMenu } from '../../containers/MainMenu/RefinedNavigationMenu.tsx';
import { SubscriptionsMenu } from '../../containers/MainMenu/SubscriptionsMenu.tsx';
import { MainNavigationContext } from '../../contexts/MainNavigationContext.tsx';
import { ICapability } from '../../utils/permissions/capability.ts';
import { MainLayoutGrid } from '../MainLayoutGrid.tsx';
import { ProjectRoutes } from '../Project.tsx';
import { ProjectsMenu } from './ProjectsMenu.tsx';
import { RefinedNavigationBar } from './RefinedNavigationBar.tsx';

export type RefinedMainMenuProps = {
  readonly capability: ICapability;
  readonly userProjectInfo: IUserProjectInfo;
  readonly projectEnvironmentRoutes: ProjectRoutes;
};

export const RefinedMainMenu: FC<RefinedMainMenuProps> = ({
  capability,
  userProjectInfo,
  projectEnvironmentRoutes,
}) => {
  const { navigationBarProps, navigationBarExpandedState } = useContext(MainNavigationContext);

  return (
    <MainLayoutGrid.NavMenu {...navigationBarProps}>
      <Switch>
        <Route path={SubscriptionRoute}>
          <RefinedNavigationBar>
            <SubscriptionsMenu />
          </RefinedNavigationBar>
        </Route>
        <Route path={[ActiveProjectsRoute, ArchivedProjectsRoute]}>
          <RefinedNavigationBar>
            <ProjectsMenu />
          </RefinedNavigationBar>
        </Route>
        <Route path={UserProfileRoute}>
          <RefinedNavigationBar />
        </Route>
        <Route>
          <RefinedNavigationBar>
            <RefinedNavigationMenu
              capability={capability}
              userProjectInfo={userProjectInfo}
              projectEnvironmentRoutes={projectEnvironmentRoutes}
              navigationState={navigationBarExpandedState}
            />
          </RefinedNavigationBar>
        </Route>
      </Switch>
    </MainLayoutGrid.NavMenu>
  );
};
