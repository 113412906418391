import React, { ReactNode } from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface ITypeElementHintProps {
  readonly children: ReactNode;
}

export const TypeElementHint: React.FC<ITypeElementHintProps> = ({ children }) => (
  <div className="content-element__hint" {...getDataUiElementAttribute(DataUiElement.ElementHint)}>
    {children}
  </div>
);

TypeElementHint.displayName = 'TypeElementHint';
