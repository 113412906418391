import { Box } from '@kontent-ai/component-library/Box';
import { Callout } from '@kontent-ai/component-library/Callout';
import { Card } from '@kontent-ai/component-library/Card';
import { Stack } from '@kontent-ai/component-library/Stack';
import {
  Spacing,
  Typography,
  colorTextLowEmphasis,
  spacingCard,
} from '@kontent-ai/component-library/tokens';
import { Collection, naturally } from '@kontent-ai/utils';
import React, { ReactNode } from 'react';
import { WorkflowWithApplicableScopes } from '../../../../_shared/utils/workflow/workflowLimitations.ts';
import { CollectionsMap } from '../../../../data/models/collections/Collection.ts';
import { getCollectionNames } from '../utils/getContentTypeApplicableWorkflows.ts';

const workflowLimitationsLabel = 'Workflows for this content type';

interface IContentTypeEditorWorkflowLimitationsProps {
  readonly bottomLabel: ReactNode;
  readonly collections: CollectionsMap;
  readonly limitedWorkflows: ReadonlyArray<WorkflowWithApplicableScopes>;
  readonly unlimitedWorkflows: ReadonlyArray<WorkflowWithApplicableScopes>;
}

export const ContentTypeEditorWorkflowLimitations: React.FC<
  IContentTypeEditorWorkflowLimitationsProps
> = ({ bottomLabel, collections, limitedWorkflows, unlimitedWorkflows }) => {
  const getInfixText = (collectionNames: ReadonlySet<string>) => {
    switch (collectionNames.size) {
      case 0:
        return 'in all collections';
      case 1:
        return 'in collection';
      default:
        return 'in collections';
    }
  };

  const getLimitedWorkflowRow = (workflow: WorkflowWithApplicableScopes) => {
    const collectionNames = getCollectionNames(workflow, collections);
    if (!collectionNames) {
      return null;
    }
    const infixText = getInfixText(collectionNames);
    const collectionsText = Collection.getValues(collectionNames).toSorted(naturally).join(', ');
    return (
      <Box key={workflow.id}>
        Workflow <b>{workflow.name}</b> {infixText} <b>{collectionsText}</b>
      </Box>
    );
  };

  const getUnlimitedWorkflowsRow = (workflows: ReadonlyArray<WorkflowWithApplicableScopes>) => {
    if (workflows.length === 0) {
      return null;
    }
    const workflowNames = workflows
      .map((workflow) => workflow.name)
      .sort(naturally)
      .join(', ');
    const infixText = limitedWorkflows.length === 0 ? 'in all' : 'in all remaining';
    return (
      <Box>
        Workflow <b>{workflowNames}</b> {infixText} collections
      </Box>
    );
  };

  return (
    <Card
      cardLabel={workflowLimitationsLabel}
      component="section"
      css={`margin-bottom: ${spacingCard}px`}
    >
      <Card.Body>
        <Stack spacing={Spacing.XL}>
          <Box typography={Typography.BodyMediumEmphasis} color={colorTextLowEmphasis}>
            {workflowLimitationsLabel}
          </Box>

          <Stack spacing={Spacing.S}>
            {limitedWorkflows.map(getLimitedWorkflowRow)}
            {getUnlimitedWorkflowsRow(unlimitedWorkflows)}
          </Stack>

          <Callout calloutType="quickTip" hideSubheadline>
            {bottomLabel}
          </Callout>
        </Stack>
      </Card.Body>
    </Card>
  );
};

ContentTypeEditorWorkflowLimitations.displayName = 'ContentTypeEditorWorkflowLimitations';
