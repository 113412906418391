import React, { memo, useCallback } from 'react';
import { useHistory } from 'react-router';
import { DefaultVariantId } from '../../../../../_shared/constants/variantIdValues.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { getCurrentProjectId } from '../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { getCannotCreateMessageForItem } from '../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { getContentItemPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { useLivePreviewPreferenceStorage } from '../../../../../localStorages/useLivePreviewPreferenceStorage.ts';
import { WorkflowStatusWithFallbacksCellContent } from '../../../../contentInventory/content/features/ContentItemInventory/components/ItemInventoryScrollTable/Cells/ContentItemScrollTableCellsContents.tsx';
import { isItemSelected } from '../../../../contentInventory/content/reducers/listingUi/itemsSelection/selectors/itemsSelectionSelectors.ts';
import { getCollection } from '../../../../contentInventory/content/selectors/getCollection.ts';
import { getContentItemListingHiddenColumns } from '../../../../contentInventory/content/selectors/getContentItemListingHiddenColumns.ts';
import { getContentType } from '../../../../contentInventory/content/selectors/getContentType.ts';
import {
  spaceNames as getSpaceNames,
  spacesByCollectionId,
} from '../../../../environmentSettings/spaces/utils/spacesUtils.ts';
import { RecommendedLinkedItemsScrollTableRow as RecommendedLinkedItemsScrollTableRowComponent } from '../components/RecommendedLinkedItemsScrollTableRow.tsx';
import { ListingContentItemWithScore } from '../types/ListingContentItemWithScore.type.ts';

type Props = {
  readonly item: ListingContentItemWithScore;
  readonly index: number;
  readonly onItemClick?: (contentItemId: Uuid, variantId: Uuid) => void;
  readonly onItemDoubleClick?: (contentItemId: Uuid, variantId: Uuid) => void;
  readonly showScoreColumn: boolean;
};

export const RecommendedLinkedItemsScrollTableRow: React.FC<Props> = memo((props) => {
  const history = useHistory();
  const currentProjectId = useSelector(getCurrentProjectId);

  const { isLivePreviewPreferred } = useLivePreviewPreferenceStorage(currentProjectId);
  const { item, onItemClick, onItemDoubleClick } = props;
  const itemId = item.item.id;
  const variantId = item.variant?.id.variantId ?? DefaultVariantId;
  const path = getContentItemPath(
    history.location.pathname,
    itemId,
    isLivePreviewPreferred,
    variantId,
  );

  const collectionName = useSelector((s) => getCollection(s, item.item.collectionId)?.name ?? '');
  const spaceNames = useSelector((s) => {
    const spaceIds = spacesByCollectionId(s.data.spaces.byId, props.item.item.collectionId).map(
      (space) => space.id,
    );
    return getSpaceNames(s.data.spaces.byId, spaceIds);
  });

  const onItemDoubleClickCallback = useCallback(
    () => !!onItemDoubleClick && onItemDoubleClick(itemId, variantId),
    [itemId, variantId, onItemDoubleClick],
  );
  const onItemClickCallback = useCallback(
    () => !!onItemClick && onItemClick(itemId, variantId),
    [itemId, variantId, onItemClick],
  );
  const itemType = useSelector((s) => getContentType(s, item.item.typeId));
  const isChecked = useSelector((s) =>
    isItemSelected(s.contentApp.listingUi.itemsSelection, itemId),
  );
  const hiddenColumns = useSelector(getContentItemListingHiddenColumns);

  if (!itemType) {
    return null;
  }

  return (
    <RecommendedLinkedItemsScrollTableRowComponent
      collectionName={collectionName}
      hiddenColumns={hiddenColumns}
      index={props.index}
      isItemChecked={isChecked}
      item={props.item}
      itemLinkPath={path}
      itemType={itemType}
      onItemClick={onItemClickCallback}
      onItemDoubleClick={onItemDoubleClickCallback}
      openInNewWindowDisabledMessage={getCannotCreateMessageForItem(props.item)}
      renderWorkflowStatus={() => (
        <WorkflowStatusWithFallbacksCellContent variant={item.variant} itemId={item.item.id} />
      )}
      showOpenInNewWindowCell
      showScoreColumn={props.showScoreColumn}
      spaceData={spaceNames}
    />
  );
});
