import { assert } from '@kontent-ai/utils';
import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { ContentItemId } from '../../../../../../_shared/models/ContentItemId.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import {
  IContentItemWithVariantsServerModel,
  RequiredItemCreationParams,
} from '../../../../../../repositories/serverModels/INewContentItemServerModel.ts';
import { CollectionSection } from '../../../NewContentItem/constants/collectionSection.ts';
import { ContentTypeSection } from '../../../NewContentItem/constants/contentTypeSection.ts';
import { getNewContentItemForm } from '../../../NewContentItem/selectors/newContentItemDialog.ts';
import { markEditedItemAsTemporary } from '../contentItemEditingActions.ts';

interface IDeps {
  readonly createNewLinkedItem: (
    createItemParams: RequiredItemCreationParams,
  ) => ThunkPromise<IContentItemWithVariantsServerModel>;
}

export const createNewItemFromLinkedItemsActionCreator =
  (deps: IDeps) =>
  (
    editedContentItemId: ContentItemId,
    elementId: Uuid,
    onAddNewItem: (
      itemId: Uuid,
      contentTypeSection: ContentTypeSection,
      collectionSection: CollectionSection,
    ) => void,
  ): ThunkPromise<IContentItemWithVariantsServerModel> =>
  async (dispatch, getState) => {
    const state = getState();

    const creationParams = getItemCreationParams(state);
    const contentItem = await dispatch(deps.createNewLinkedItem(creationParams));
    const itemId = contentItem.item.id;
    const { collectionSection, contentTypeSection } = getNewContentItemForm(state);

    onAddNewItem(itemId, contentTypeSection, collectionSection);

    dispatch(
      markEditedItemAsTemporary({
        itemId,
        variantId: editedContentItemId.variantId,
        parentItem: {
          itemId: editedContentItemId.itemId,
          elementId,
        },
      }),
    );

    return contentItem;
  };

const getItemCreationParams = (state: IStore): RequiredItemCreationParams => {
  const { collectionId, contentTypeId } = getNewContentItemForm(state);

  assert(collectionId, () => 'Can’t create a content item without selected collection');
  assert(contentTypeId, () => 'Can’t create a content item without selected content type.');

  return {
    collectionId,
    typeId: contentTypeId,
  };
};
