import React from 'react';
import { EmptyComponent } from '../../../../_shared/components/EmptyComponent.tsx';
import {
  ElementType,
  TypeElementType,
} from '../../../contentInventory/content/models/ContentItemElementType.ts';
import { ContentTypeSnippetTypeElement } from '../../contentTypes/containers/ContentTypeSnippetTypeElement.tsx';
import { CustomContentTypeElement } from '../../contentTypes/containers/CustomContentTypeElement.tsx';
import { TextTypeElement } from '../../contentTypes/containers/TextTypeElement.tsx';
import { UrlSlugTypeElement } from '../../contentTypes/containers/UrlSlugTypeElement.tsx';
import { MultipleChoiceTypeElement } from '../../contentTypes/containers/multipleChoiceTypeElement/MultipleChoiceTypeElement.tsx';
import { CustomContentTypeElementForSnippet } from '../../snippets/containers/CustomContentTypeElementForSnippet.tsx';
import { TextTypeElementForSnippet } from '../../snippets/containers/TextTypeElementForSnippet.tsx';
import { MultipleChoiceTypeElementForSnippet } from '../../snippets/containers/multipleChoiceTypeElement/MultipleChoiceTypeElementForSnippet.tsx';
import { DateTimeTypeElement } from '../components/typeElements/individualTypeElements/DateTimeTypeElement.tsx';
import { GuidelinesTypeElement } from '../components/typeElements/individualTypeElements/GuidelinesTypeElement.tsx';
import { NumberTypeElement } from '../components/typeElements/individualTypeElements/NumberTypeElement.tsx';
import { AssetTypeElement } from '../components/typeElements/individualTypeElements/asset/AssetTypeElement.tsx';
import { LinkedItemsTypeElement } from '../containers/typeElements/LinkedItemsTypeElement.tsx';
import { TaxonomyTypeElement } from '../containers/typeElements/TaxonomyTypeElement.tsx';
import { RichTextTypeElement } from '../containers/typeElements/richText/RichTextTypeElement.tsx';
import { IBaseTypeElementData } from '../models/elements/types/TypeElementData.ts';
import { ITypeElementOwnProps } from '../types/ITypeElementProps.type.ts';
import { elementTypeNameMap } from './typeElementsUtils.ts';

export interface ITypeElementUiItem {
  readonly component: React.ComponentType<ITypeElementOwnProps<IBaseTypeElementData, unknown>>;
  readonly typeName: string;
}

type TypeElementUiItemFactoryOptions = {
  // flag is necessary here since new snippet's codename is null until it is persisted at the server
  isItemForSnippet: boolean;
  snippetCodename?: string | null;
};

type TypeElementUiItemFactory = (options: TypeElementUiItemFactoryOptions) => ITypeElementUiItem;

type TypeElementUiItemFactoriesMap = ReadonlyRecord<TypeElementType, TypeElementUiItemFactory>;

const emptyElementUiItem: ITypeElementUiItem = {
  // the "any" here allows null-object-pattern with type check everywhere else
  component: EmptyComponent as any,
  typeName: '',
};

// Elements with same component for (non)snippet usage
const assetElement: ITypeElementUiItem = {
  component: AssetTypeElement,
  typeName: elementTypeNameMap[ElementType.Asset],
};
const subpagesElement: ITypeElementUiItem = {
  component: LinkedItemsTypeElement,
  typeName: elementTypeNameMap[ElementType.Subpages],
};
const dateTimeElement: ITypeElementUiItem = {
  component: DateTimeTypeElement,
  typeName: elementTypeNameMap[ElementType.DateTime],
};
const guidelinesElement: ITypeElementUiItem = {
  component: GuidelinesTypeElement,
  typeName: elementTypeNameMap[ElementType.Guidelines],
};
const modularContentElement: ITypeElementUiItem = {
  component: LinkedItemsTypeElement,
  typeName: elementTypeNameMap[ElementType.LinkedItems],
};
const numberElement: ITypeElementUiItem = {
  component: NumberTypeElement,
  typeName: elementTypeNameMap[ElementType.Number],
};
const customFactory: TypeElementUiItemFactory = (options) => ({
  component: options.isItemForSnippet
    ? CustomContentTypeElementForSnippet
    : CustomContentTypeElement,
  typeName: elementTypeNameMap[ElementType.Custom],
});
const richTextElement: ITypeElementUiItem = {
  component: RichTextTypeElement,
  typeName: elementTypeNameMap[ElementType.RichText],
};
const taxonomyElement: ITypeElementUiItem = {
  component: TaxonomyTypeElement,
  typeName: elementTypeNameMap[ElementType.Taxonomy],
};
// Elements with different components for snippet and non-snippet usage
const multipleChoiceFactory: TypeElementUiItemFactory = (options) => ({
  component: options.isItemForSnippet
    ? MultipleChoiceTypeElementForSnippet
    : MultipleChoiceTypeElement,
  typeName: elementTypeNameMap[ElementType.MultipleChoice],
});
const textFactory: TypeElementUiItemFactory = (options) => ({
  component: options.isItemForSnippet ? TextTypeElementForSnippet : TextTypeElement,
  typeName: elementTypeNameMap[ElementType.Text],
});

// Elements without snippet component
const contentTypeSnippetFactory: TypeElementUiItemFactory = (options) =>
  options.isItemForSnippet
    ? emptyElementUiItem
    : {
        component: ContentTypeSnippetTypeElement,
        typeName: elementTypeNameMap[ElementType.ContentTypeSnippet],
      };
const urlSlugFactory: TypeElementUiItemFactory = (options) =>
  options.isItemForSnippet
    ? emptyElementUiItem
    : {
        component: UrlSlugTypeElement,
        typeName: elementTypeNameMap[ElementType.UrlSlug],
      };

const typeElementComponentWithNameFactoriesMap: TypeElementUiItemFactoriesMap = {
  [ElementType.Asset]: () => assetElement,
  [ElementType.Subpages]: () => subpagesElement,
  [ElementType.ContentTypeSnippet]: contentTypeSnippetFactory,
  [ElementType.Custom]: customFactory,
  [ElementType.DateTime]: () => dateTimeElement,
  [ElementType.Guidelines]: () => guidelinesElement,
  [ElementType.LinkedItems]: () => modularContentElement,
  [ElementType.MultipleChoice]: multipleChoiceFactory,
  [ElementType.Number]: () => numberElement,
  [ElementType.RichText]: () => richTextElement,
  [ElementType.Taxonomy]: () => taxonomyElement,
  [ElementType.Text]: textFactory,
  [ElementType.UrlSlug]: urlSlugFactory,
};

export const getTypeElementUiItem =
  (options: TypeElementUiItemFactoryOptions) =>
  (type: TypeElementType): ITypeElementUiItem => {
    const typeElementComponentWithNameFactory = typeElementComponentWithNameFactoriesMap[type];

    return typeElementComponentWithNameFactory
      ? typeElementComponentWithNameFactory(options)
      : emptyElementUiItem;
  };
