import { useCallback, useRef } from 'react';
import { LastAction } from '../../applications/contentModels/shared/types/LastAction.type.ts';
import { useEventListener } from './useEventListener.ts';

export const useUndoKeyPress = <T extends (...args: any[]) => any>(
  callback: T,
  lastAction: LastAction,
) => {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  const handleKeyPress = useCallback(
    (event: KeyboardEvent): void => {
      if (event.ctrlKey || event.metaKey) {
        if (event.key === 'z') {
          callbackRef.current(event);
          if (lastAction !== null) {
            //prevent shortcut to be consumed in input elements by their default behavior
            event.preventDefault();
          }
        }
      }
    },
    [lastAction],
  );

  useEventListener('keydown', handleKeyPress, window, true);
};
