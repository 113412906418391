import { useEffect, useState } from 'react';
import { trackUserEvent } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import {
  getCurrentProject,
  getCurrentProjectId,
  getCurrentProjectPlan,
} from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { isDefined } from '../../../../_shared/utils/filter/isDefined.ts';
import { doesEntitySatisfyFilterPhrase } from '../../../../_shared/utils/filter/nameFilterUtils.ts';
import { Capability, getUserCapability } from '../../../../_shared/utils/permissions/capability.ts';
import { sortSpacesByName } from '../../../../_shared/utils/spaces/sortSpacesByName.ts';
import { ISpace } from '../../../../data/models/space/space.ts';
import { isWebSpotlightEnabled } from '../../../webSpotlight/selectors/webSpotlightSelectors.ts';
import { spacesListingLeft } from '../actions/spacesActions.ts';
import {
  createSpace,
  deleteSpace,
  preloadWebSpotlightRootItem,
  restoreSpace,
  updateSpace,
} from '../actions/thunkSpacesActions.ts';
import { SpaceDeleteConfirmationDialog } from '../components/SpaceDeleteConfirmationDialog.tsx';
import { SpacesEmptyState } from '../components/SpacesEmptyState.tsx';
import { SpacesListing as SpacesListingComponent } from '../components/SpacesListing.tsx';
import { useSpacesFilter } from '../hooks/useSpacesFilter.ts';
import { ActionInProgress } from '../types/acionInProgress.ts';
import {
  SpaceDeletion,
  SpaceDeletionData,
  SpaceDeletionState,
} from '../types/spaceDeletion.type.ts';

type Props = {
  readonly initiallyExpandedSpaceId: Uuid | null;
};

export const SpacesListing = ({ initiallyExpandedSpaceId }: Props) => {
  const [actionInProgress, setActionInProgress] = useState(ActionInProgress.None);
  const [editedSpaceId, setEditedSpaceId] = useState<Uuid | null>(initiallyExpandedSpaceId);
  const [initialNewSpaceName, setInitialNewSpaceName] = useState('');
  const [isCreateFormOpen, setIsCreateFormOpen] = useState(false);
  const [spaceDeletion, setSpaceDeletion] = useState<SpaceDeletion | null>(null);
  const isInEditMode = isCreateFormOpen || !!editedSpaceId;

  const canUserViewContent = useSelector((s) =>
    getUserCapability(getCurrentProject(s)).can(Capability.ViewContent),
  );
  const isWebSpotlightActive = useSelector(isWebSpotlightEnabled);
  const maxProjectSpaces = useSelector((s) => getCurrentProjectPlan(s).features.maxProjectSpaces);
  const spacesMap = useSelector((s) => s.data.spaces.byId);

  const filterSpaces = (searchPhrase: string): void => {
    const spaceListingIds = sortSpacesByName(spacesMap.values())
      .filter((space) => doesEntitySatisfyFilterPhrase(searchPhrase, space, [(s) => s.name]))
      .map((space) => space.id);

    setSpaceListingIds(spaceListingIds);
  };

  const projectId = useSelector(getCurrentProjectId);
  const { inputSearchPhrase, filterSearchPhrase, searchSpaces } = useSpacesFilter(
    projectId,
    filterSpaces,
  );

  const [spaceListingIds, setSpaceListingIds] = useState(() =>
    sortSpacesByName(spacesMap.values()).map(({ id }) => id),
  );

  const filteredSpaces = spaceListingIds.map((id) => spacesMap.get(id)).filter(isDefined);
  const isPlanLimitReached = maxProjectSpaces !== null && spacesMap.size >= maxProjectSpaces;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(trackUserEvent(TrackedEvent.SpacesAppVisited));

    return () => {
      dispatch(spacesListingLeft());
    };
  }, []);

  const onCreate = async (
    name: string,
    collectionIds: ReadonlyArray<Uuid>,
    rootItemId: Uuid | null,
    shouldCreateNewRootItem: boolean,
  ): Promise<void> => {
    setSpaceDeletion(null);
    setActionInProgress(ActionInProgress.Saving);

    const createdSpace = await dispatch(
      createSpace(name, collectionIds, rootItemId, shouldCreateNewRootItem),
    );

    setSpaceListingIds((prevIds) => [createdSpace.id, ...prevIds]);
    closeCreateSpace();
    setActionInProgress(ActionInProgress.None);
  };

  const onDelete = async (spaceDeletionData: SpaceDeletionData): Promise<void> => {
    setSpaceDeletion(null);
    setActionInProgress(ActionInProgress.Deleting);

    await dispatch(
      deleteSpace(spaceDeletionData.id, () => {
        setEditedSpaceId(null);
        setSpaceDeletion({ state: SpaceDeletionState.Done, data: spaceDeletionData });
      }),
    );

    setSpaceListingIds((prevIds) => prevIds.filter((id) => id !== spaceDeletionData.id));
    setActionInProgress(ActionInProgress.None);
  };

  const deleteOrShowDialog = async (space: ISpace): Promise<void> => {
    const hasRootItem = space.webSpotlightRootItemId !== null;
    const shouldShowDeleteDialog = hasRootItem && isWebSpotlightActive;
    const spaceDeletionData: SpaceDeletionData = { id: space.id, name: space.name };

    if (shouldShowDeleteDialog) {
      setSpaceDeletion({ state: SpaceDeletionState.Confirmation, data: spaceDeletionData });
    } else {
      await onDelete(spaceDeletionData);
    }
  };

  const onRestore = async (id: Uuid): Promise<void> => {
    setActionInProgress(ActionInProgress.Restoring);

    await dispatch(restoreSpace(id, () => setSpaceDeletion(null)));

    setSpaceListingIds((prevIds) => [id, ...prevIds]);
    setActionInProgress(ActionInProgress.None);
  };

  const onUpdate = async (space: ISpace, shouldCreateNewRootItem: boolean): Promise<void> => {
    setSpaceDeletion(null);
    setActionInProgress(ActionInProgress.Saving);

    await dispatch(updateSpace(space, shouldCreateNewRootItem));

    setEditedSpaceId(null);
    setActionInProgress(ActionInProgress.None);
  };

  const openEditingForm = async (spaceId: Uuid): Promise<void> => {
    setEditedSpaceId(spaceId);

    if (canUserViewContent) {
      setActionInProgress(ActionInProgress.RootItemLoading);
      await dispatch(preloadWebSpotlightRootItem(spaceId));
      setActionInProgress(ActionInProgress.None);
    }
  };

  if (!spacesMap.size && !isCreateFormOpen) {
    return (
      <SpacesEmptyState
        onCreate={() => setIsCreateFormOpen(true)}
        onRestore={onRestore}
        spaceDeletion={spaceDeletion}
      />
    );
  }

  const openCreateSpace = (spaceName: string): void => {
    setInitialNewSpaceName(spaceName);
    setIsCreateFormOpen(true);
  };

  const closeCreateSpace = () => {
    setInitialNewSpaceName('');
    setIsCreateFormOpen(false);
  };

  return (
    <>
      <SpacesListingComponent
        actionInProgress={actionInProgress}
        editedSpaceId={editedSpaceId}
        filterSearchPhrase={filterSearchPhrase}
        initialNewSpaceName={initialNewSpaceName}
        inputSearchPhrase={inputSearchPhrase}
        isCreateFormOpen={isCreateFormOpen}
        isEditingEnabled={!isInEditMode}
        isPlanLimitReached={isPlanLimitReached}
        isWebSpotlightActive={isWebSpotlightActive}
        onCloseCreateForm={closeCreateSpace}
        onCloseEditingForm={() => setEditedSpaceId(null)}
        onCreate={onCreate}
        onDelete={deleteOrShowDialog}
        onFilterChange={searchSpaces}
        onOpenCreateForm={openCreateSpace}
        onOpenEditingForm={openEditingForm}
        onRestore={onRestore}
        onUpdate={onUpdate}
        planLimit={maxProjectSpaces}
        spaceDeletion={spaceDeletion}
        spaces={filteredSpaces}
      />
      {spaceDeletion?.state === SpaceDeletionState.Confirmation && (
        <SpaceDeleteConfirmationDialog
          spaceName={spaceDeletion.data.name}
          onClose={() => setSpaceDeletion(null)}
          onConfirm={() => onDelete(spaceDeletion.data)}
        />
      )}
    </>
  );
};
