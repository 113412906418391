import { transition250 } from '@kontent-ai/component-library/tokens';
import styled, { css } from 'styled-components';
import { IAssetTileWithActionsProps } from '../AssetTileWithActions.tsx';
import { assetTileBorderRadius } from '../decisionTokens.ts';
import { getBackgroundColorForDiffState } from '../utils.ts';

interface IToolbarVisibilityHelperProps
  extends Pick<IAssetTileWithActionsProps, 'isDragging' | 'readonlyState'> {
  readonly isVisible?: boolean;
}

const getBackgroundColor = ({ isDragging, readonlyState }: IToolbarVisibilityHelperProps) => {
  if (readonlyState) {
    return getBackgroundColorForDiffState(readonlyState);
  }
  if (isDragging) {
    return 'transparent';
  }
  return 'inherit';
};

export const ToolbarVisibilityHelper = styled.div<IToolbarVisibilityHelperProps>`
  position: relative;
  background-color: ${getBackgroundColor};
  border-top-left-radius: ${assetTileBorderRadius};
  border-top-right-radius: ${assetTileBorderRadius};
  transition: opacity ${transition250};
  ${({ isVisible }) =>
    !isVisible &&
    css`
    opacity: 0;
  `};
`;
