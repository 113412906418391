import React, { useRef } from 'react';
import { HtmlPageTitle } from '../../../../_shared/components/HtmlPageTitle.tsx';
import { ModalViewer } from '../../../../_shared/components/Modal/ModalViewer.tsx';
import { ModalViewerPosition } from '../../../../_shared/components/Modal/ModalViewerPosition.ts';
import { AppNames } from '../../../../_shared/constants/applicationNames.ts';
import { NotificationBar } from '../../../../_shared/containers/NotificationBar.tsx';
import { IntersectionObserverRootContextProvider } from '../../../../_shared/hooks/IntersectionObserverRootContext.tsx';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ModalContentItemSelector } from '../../../features/ModalContentItemSelector/containers/ModalContentItemSelector.tsx';
import { RelationsEmptyState } from '../containers/RelationsEmptyState.tsx';
import { RelationsScrollTable } from '../containers/RelationsScrollTable.tsx';

export interface IRelationsListingStateProps {
  readonly itemSelectorVisible: boolean;
  readonly rootId: Uuid | null;
}

export interface IRelationsListingDispatchProps {
  readonly onRootChange: (rootId: Uuid) => void;
  readonly onSelectorClose: () => void;
}

export type RelationsListingProps = IRelationsListingStateProps & IRelationsListingDispatchProps;

export const RelationsListing: React.FC<RelationsListingProps> = ({
  itemSelectorVisible,
  onRootChange,
  onSelectorClose,
  rootId,
}) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  return (
    <div className="canvas__workspace" {...getDataUiAppNameAttribute(DataUiAppName.Relations)}>
      <HtmlPageTitle appName={AppNames.Relations} />
      <div className="canvas__content">
        <div className="canvas__notifications">
          <NotificationBar />
        </div>
        <IntersectionObserverRootContextProvider rootRef={scrollContainerRef}>
          <div className="canvas__content-pane relations-pane">
            <div className="canvas__inner-section canvas__inner-section--restricted-width canvas__inner-section--centered">
              {rootId ? <RelationsScrollTable rootId={rootId} /> : <RelationsEmptyState />}

              <ModalViewer
                dialogClassName="dialog"
                isDialogVisible={itemSelectorVisible}
                position={ModalViewerPosition.Center}
                onClose={onSelectorClose}
              >
                <ModalContentItemSelector
                  titleBarText="Select your home item"
                  onSelect={onRootChange}
                  onClose={onSelectorClose}
                />
              </ModalViewer>
            </div>
          </div>
        </IntersectionObserverRootContextProvider>
      </div>
    </div>
  );
};

RelationsListing.displayName = 'RelationsListing';
