import { Dispatch, GetState, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { logError } from '../../../../../_shared/utils/logError.ts';
import { loadSitemap } from '../../../../../data/actions/thunkDataActions.ts';
import {
  ISitemap,
  createSitemapFromServerModel,
  createSitemapServerModel,
} from '../../../../../data/models/contentModelsApp/sitemap/Sitemap.ts';
import { ISitemapRepository } from '../../../../../repositories/interfaces/ISitemapRepository.type.ts';
import { Sitemap_FinishSaving, Sitemap_StartSaving } from '../../constants/sitemapActionTypes.ts';

interface ISitemapDependencies {
  readonly sitemapRepository: ISitemapRepository;
}

const startSaving = (sitemap: ISitemap) =>
  ({
    type: Sitemap_StartSaving,
    payload: {
      sitemap,
    },
  }) as const;

const finishSaving = (sitemap: ISitemap) =>
  ({
    type: Sitemap_FinishSaving,
    payload: {
      sitemap,
    },
  }) as const;

export type SaveSitemapActionsType = ReturnType<typeof startSaving | typeof finishSaving>;

export const updateSitemapCreator =
  (deps: ISitemapDependencies) =>
  (sitemap: ISitemap): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    dispatch(startSaving(sitemap));
    const sitemapBeforeSave = getState().sitemapApp.sitemap;
    const rawSitemapForServer = createSitemapServerModel(sitemap);
    try {
      const rawSitemap = await deps.sitemapRepository.updateSitemap(rawSitemapForServer);
      const siteMapAfterSave = getState().sitemapApp.sitemap;
      if (siteMapAfterSave === sitemapBeforeSave) {
        // Only finish saving when sitemap hasn't changed since saving began.
        // Otherwise another save is in progress and this promise won't resolve.
        const newSitemap = createSitemapFromServerModel(rawSitemap);
        dispatch(loadSitemap());
        dispatch(finishSaving(newSitemap));
      }
    } catch (error) {
      logError(`An error occurred during updating sitemap: ${JSON.stringify(error, null, 4)}`);
      throw error;
    }
  };

export const createSitemapCreator =
  (deps: ISitemapDependencies) =>
  (sitemap: ISitemap): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    dispatch(startSaving(sitemap));
    const sitemapBeforeSave = getState().sitemapApp.sitemap;
    const rawSitemapForServer = createSitemapServerModel(sitemap);
    try {
      const rawSitemap = await deps.sitemapRepository.createSitemap(rawSitemapForServer);
      const siteMapAfterSave = getState().sitemapApp.sitemap;
      if (siteMapAfterSave === sitemapBeforeSave) {
        // Only finish saving when sitemap hasn't changed since saving began.
        // Otherwise another save is in progress and this promise won't resolve.
        const newSitemap = createSitemapFromServerModel(rawSitemap);
        dispatch(loadSitemap());
        dispatch(finishSaving(newSitemap));
      }
    } catch (error) {
      logError(`An error occurred during creating sitemap: ${JSON.stringify(error, null, 4)}`);
      throw error;
    }
  };
