import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { getCurrentProjectId } from '../../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { getContentItemRevisionViewerGoBackLink } from '../../../../../../_shared/utils/appToolbar/appToolbarRouteUtils.ts';
import { useLivePreviewPreferenceStorage } from '../../../../../../localStorages/useLivePreviewPreferenceStorage.ts';
import { ContentItemRevisionToolbarActions as ContentItemRevisionToolbarActionsComponent } from '../../components/statusBar/ContentItemRevisionToolbarActions.tsx';

export const ContentItemRevisionToolbarActions: React.FC = () => {
  const location = useLocation();
  const currentProjectId = useSelector(getCurrentProjectId);
  const { isLivePreviewPreferred } = useLivePreviewPreferenceStorage(currentProjectId);

  const goBackLink = useMemo(
    () => getContentItemRevisionViewerGoBackLink(location.pathname, isLivePreviewPreferred),
    [location.pathname, isLivePreviewPreferred],
  );

  return <ContentItemRevisionToolbarActionsComponent to={goBackLink} />;
};

ContentItemRevisionToolbarActions.displayName = 'ContentItemRevisionToolbarActions';
