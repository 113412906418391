import React from 'react';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { TextTypeElement as TextTypeElementComponent } from '../../shared/components/typeElements/individualTypeElements/TextTypeElement.tsx';
import { ITextTypeElementData } from '../../shared/models/elements/TextTypeElementData.ts';
import { ITypeElementOwnProps } from '../../shared/types/ITypeElementProps.type.ts';
import { TextTypeElementValidationResult } from '../../shared/utils/typeElementValidators/types/TextTypeElementValidationResult.type.ts';
import { textElementGeneratesUrlSlug } from '../../shared/utils/urlSlugUtils.ts';

export const TextTypeElement: React.FC<
  ITypeElementOwnProps<ITextTypeElementData, TextTypeElementValidationResult>
> = (props) => {
  const editedType = useSelector((s) => s.contentModelsApp.typeEditor.editedType);

  return (
    <TextTypeElementComponent
      {...props}
      generatesUrlSlug={textElementGeneratesUrlSlug(
        editedType.typeElements,
        props.typeElementData.elementId,
      )}
    />
  );
};
