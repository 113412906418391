import Immutable from 'immutable';
import { ItemColumnCode } from '../../../../../_shared/constants/itemColumnCode.ts';
import { OrderBy } from '../../../../../_shared/models/OrderBy.ts';
import { Notifications } from '../../../../../_shared/services/signalR/signalRClient.type.ts';
import { IValidationResult } from '../../../../../_shared/utils/validation/ValidationResult.ts';
import { SearchMethod } from '../../../../../repositories/serverModels/IListingContentItemServerModel.type.ts';
import {
  IContentItemServerModel,
  IContentItemWithVariantServerModel,
} from '../../../../../repositories/serverModels/INewContentItemServerModel.ts';
import { IServerApiError } from '../../../../../repositories/serverModels/ServerApiError.ts';
import {
  EditableTypeElement,
  TypeElement,
} from '../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { IListingFilter } from '../../../../contentInventory/content/models/filter/IListingFilter.ts';
import { TemporaryContentItemState } from '../../../../contentInventory/content/models/temporaryContentItemState.ts';
import {
  IEditedContentItemThreads,
  INewContentItemVariantInitState,
  NavigatedFromData,
} from '../../../../contentInventory/content/stores/IContentAppStoreState.ts';
import { ItemEditorOperationId } from '../../../../contentInventory/content/utils/itemEditorOperationIdUtils.ts';
import {
  Content_Editing_AssignmentSubmittingFailed,
  Content_Editing_AssignmentSubmittingFinished,
  Content_Editing_AssignmentSubmittingStarted,
  Content_Editing_CancelScheduledPublishingFailed,
  Content_Editing_CancelScheduledPublishingFinished,
  Content_Editing_CancelScheduledPublishingStarted,
  Content_Editing_CancelScheduledUnpublishingFailed,
  Content_Editing_CancelScheduledUnpublishingFinished,
  Content_Editing_CancelScheduledUnpublishingStarted,
  Content_Editing_PublishContentItemVariantFailed,
  Content_Editing_PublishContentItemVariantFinished,
  Content_Editing_PublishContentItemVariantStarted,
  Content_Editing_ScheduledPublishingFailed,
  Content_Editing_ScheduledPublishingFinished,
  Content_Editing_ScheduledPublishingStarted,
  Content_Editing_ScheduledUnpublishingFailed,
  Content_Editing_ScheduledUnpublishingFinished,
  Content_Editing_ScheduledUnpublishingStarted,
  Content_Editing_UnpublishContentItemVariantFailed,
  Content_Editing_UnpublishContentItemVariantFinished,
  Content_Editing_UnpublishContentItemVariantStarted,
} from '../../../constants/editorActionTypes.ts';
import { ItemEditingModalDialogType } from '../../../constants/itemEditingModalDialogType.ts';
import { ITemporaryContentItem } from '../../../models/ITemporaryContentItem.type.ts';
import { CommentThreadItemType } from '../../../models/comments/CommentThreadItem.ts';
import { ICommentThread } from '../../../models/comments/CommentThreads.ts';
import { IContentItemWithVariant } from '../../../models/contentItem/ContentItemWithVariant.ts';
import { EditedContentItemFailure } from '../../../models/contentItem/edited/EditedContentItemStatus.ts';
import { ICompiledContentItemElementData } from '../../../models/contentItemElements/ICompiledContentItemElement.type.ts';
import { ItemEditingModalProperties } from '../../../types/ItemEditingModalDialogProperties.type.ts';
import { AssignmentSections } from '../constants/AssignmentSections.ts';
import { ContentGroupSelectionReason } from '../constants/ContentGroupSelectionReason.ts';
import {
  ContentItemEditing_Action_Activated,
  ContentItemEditing_Action_Deactivated,
  ContentItemEditing_Archiving_Failed,
  ContentItemEditing_Archiving_Finished,
  ContentItemEditing_Archiving_Started,
  ContentItemEditing_AssignmentChange_Abandoned,
  ContentItemEditing_AutoDispatchSave_Cancelled,
  ContentItemEditing_Changes_Discarded,
  ContentItemEditing_CloseIncompleteDefaultVariantNotificationBar,
  ContentItemEditing_CloseIncompleteItemNotificationBar,
  ContentItemEditing_CodenameEditor_Changed,
  ContentItemEditing_CodenameEditor_ErrorStatusCleared,
  ContentItemEditing_CodenameEditor_NotUniqueError,
  ContentItemEditing_CodenameSaving_Failed,
  ContentItemEditing_CodenameSaving_Finished,
  ContentItemEditing_CommentThread_Focused,
  ContentItemEditing_CommentThread_HideAllComments,
  ContentItemEditing_CommentThread_InlineThreadAdded,
  ContentItemEditing_CommentThread_ItemEditingCanceled,
  ContentItemEditing_CommentThread_ItemEditingStarted,
  ContentItemEditing_CommentThread_ReplyCanceled,
  ContentItemEditing_CommentThread_ReplyFocusLost,
  ContentItemEditing_CommentThread_ReplyStarted,
  ContentItemEditing_CommentThread_ResolveUndoCancel,
  ContentItemEditing_CommentThread_Resolved,
  ContentItemEditing_CommentThread_ResolvingStarted,
  ContentItemEditing_CommentThread_ShowAllComments,
  ContentItemEditing_CommentThread_Unresolved,
  ContentItemEditing_CommentThread_UnresolvingStarted,
  ContentItemEditing_Comments_Loaded,
  ContentItemEditing_Comments_RegisterPositionCallback,
  ContentItemEditing_ContentComponentConvertedToItemNotification_Close,
  ContentItemEditing_ContentComponents_Expanded,
  ContentItemEditing_DiscussionsSidebarClosed,
  ContentItemEditing_DiscussionsSidebarOpened,
  ContentItemEditing_Duplicating_Failed,
  ContentItemEditing_Duplicating_Finished,
  ContentItemEditing_Duplicating_Started,
  ContentItemEditing_ElementRefresh_Failed,
  ContentItemEditing_ElementRefresh_Fetched,
  ContentItemEditing_ElementRefresh_Finished,
  ContentItemEditing_ElementRefresh_Started,
  ContentItemEditing_ElementValue_Changed,
  ContentItemEditing_Elements_FocusFirstIncomplete,
  ContentItemEditing_Elements_HighlightIncomplete,
  ContentItemEditing_Elements_ResetIsHighlighing,
  ContentItemEditing_ExpandModularContentItem_Finished,
  ContentItemEditing_FocusValidationWarningsBeforePublish,
  ContentItemEditing_HiddenElements_ClearValues,
  ContentItemEditing_HideElementsAreInaccessibleNotification,
  ContentItemEditing_HighlightedTask_Highlighted,
  ContentItemEditing_Init_VariantPrepared,
  ContentItemEditing_ItemElementsSaving_Failed,
  ContentItemEditing_ItemElementsSaving_Finished,
  ContentItemEditing_ItemElementsSaving_Started,
  ContentItemEditing_ItemLiveUserPresenceMonitor_Unmounted,
  ContentItemEditing_ItemLiveUsers_Changed,
  ContentItemEditing_ItemName_Changed,
  ContentItemEditing_ItemSaving_Finished,
  ContentItemEditing_LanguageVariantSwitched,
  ContentItemEditing_LinkedEntityChanged_ItemRefreshed,
  ContentItemEditing_LockedElements_Changed,
  ContentItemEditing_MarkAsTemporary,
  ContentItemEditing_NewlyCreatedItem_EditorMounted,
  ContentItemEditing_NotificationBar_Close,
  ContentItemEditing_PendingAiOperations_Discarded,
  ContentItemEditing_ResolvedCommentsSidebarClosed,
  ContentItemEditing_ResolvedCommentsSidebarOpened,
  ContentItemEditing_Saving_Failed,
  ContentItemEditing_Saving_Started,
  ContentItemEditing_ShouldAutoDispatchCheck_Finished,
  ContentItemEditing_ShouldAutoDispatchCheck_Pending,
  ContentItemEditing_ShowElementsAreInaccessibleNotification,
  ContentItemEditing_ShowIncompleteDefaultVariantNotificationBar,
  ContentItemEditing_Sidebar_Closed,
  ContentItemEditing_Sidebar_Initialized,
  ContentItemEditing_Sidebar_Section_Cleaned_Up,
  ContentItemEditing_SitemapLocationCollapsedState_Toggled,
  ContentItemEditing_SitemapLocation_Toggled,
  ContentItemEditing_SuggestionApprovalInitiated,
  ContentItemEditing_TaskEditing_Cancelled,
  ContentItemEditing_TaskEditing_Started,
  ContentItemEditing_UrlSlugContentElements_Update,
  ContentItemEditing_VariantQuickActionsNotification_Close,
  ContentItemEditing_VariantTranslation_Finished,
  ContentItemEditing_VariantTranslation_Started,
  ContentItemEditing_VisibleElements_AddDefaultValues,
} from '../constants/contentItemEditingActionTypes.ts';
import { ContentItemSavingOrigin } from '../models/ContentItemSavingOrigin.ts';
import { EditingAction } from '../models/EditingAction.ts';
import { IParsedItemVariant } from '../utils/parseContentItem.ts';

interface IContentItemPayload {
  readonly itemServerModel: IContentItemServerModel;
}

interface IContentItemWithVariantPayload {
  readonly itemWithVariant: IContentItemWithVariantServerModel;
}

interface IContentItemFilterPayload {
  readonly filter: IListingFilter;
  readonly usedSearchMethod: SearchMethod;
}

interface IContentItemSavingMetadata {
  readonly itemPreviouslyUpdatedAt: string | null;
  readonly origin: ContentItemSavingOrigin;
}

export type ItemWithVariantAndFilterPayload = IContentItemWithVariantPayload &
  IContentItemFilterPayload;

export type ItemWithVariantAndFilterAndDataPayload = ItemWithVariantAndFilterPayload & {
  readonly itemVariantData: IParsedItemVariant;
  readonly orderBy: OrderBy<ItemColumnCode>;
};

type ContentItemSavingFinishedPayload = IContentItemPayload &
  IContentItemFilterPayload &
  IContentItemSavingMetadata & {
    readonly operationId: ItemEditorOperationId;
    readonly orderBy: OrderBy<ItemColumnCode>;
  };

type ContentItemElementsSavingFinishedPayload = ItemWithVariantAndFilterPayload &
  IContentItemSavingMetadata & {
    readonly operationIds: Array<ItemEditorOperationId>;
  };

export const commentsLoaded = (comments: IEditedContentItemThreads) =>
  ({
    type: ContentItemEditing_Comments_Loaded,
    payload: {
      comments,
    },
  }) as const;

export const commentThreadItemEditingCanceled = (threadId: Uuid, commentId?: Uuid) =>
  ({
    type: ContentItemEditing_CommentThread_ItemEditingCanceled,
    payload: {
      threadId,
      commentId,
    },
  }) as const;

export const commentThreadReplyCanceled = (threadId: Uuid) =>
  ({
    type: ContentItemEditing_CommentThread_ReplyCanceled,
    payload: { threadId },
  }) as const;

export const commentThreadReplyFocusLost = (threadId: Uuid, isCommentPending: boolean) =>
  ({
    type: ContentItemEditing_CommentThread_ReplyFocusLost,
    payload: {
      threadId,
      isCommentPending,
    },
  }) as const;

export const commentThreadReplyStarted = (threadId: Uuid, type: CommentThreadItemType) =>
  ({
    type: ContentItemEditing_CommentThread_ReplyStarted,
    payload: {
      threadId,
      type,
    },
  }) as const;

export const commentThreadResolvingStarted = (
  threadId: Uuid,
  threadElementId: Uuid | undefined,
  enableUndo: boolean,
) =>
  ({
    type: ContentItemEditing_CommentThread_ResolvingStarted,
    payload: {
      threadId,
      threadElementId,
      enableUndo,
    },
  }) as const;

export const commentThreadResolveUndoCancel = (threadId: Uuid) =>
  ({
    type: ContentItemEditing_CommentThread_ResolveUndoCancel,
    payload: {
      threadId,
    },
  }) as const;

export const commentThreadResolved = (commentThread: ICommentThread, enableUndo: boolean) =>
  ({
    type: ContentItemEditing_CommentThread_Resolved,
    payload: {
      commentThread,
      enableUndo,
    },
  }) as const;

export const commentThreadUnresolvingStarted = (
  threadId: Uuid,
  threadElementId: Uuid | null | undefined,
) =>
  ({
    type: ContentItemEditing_CommentThread_UnresolvingStarted,
    payload: {
      threadId,
      threadElementId,
    },
  }) as const;

export const commentThreadUnresolved = (commentThread: ICommentThread) =>
  ({
    type: ContentItemEditing_CommentThread_Unresolved,
    payload: {
      commentThread,
    },
  }) as const;

export const inlineCommentThreadAdded = (
  inlineThread: ICommentThread,
  type: CommentThreadItemType,
) =>
  ({
    type: ContentItemEditing_CommentThread_InlineThreadAdded,
    payload: {
      inlineThread,
      type,
    },
  }) as const;

export const contentItemNameChanged = (newName: string, itemId: Uuid) =>
  ({
    type: ContentItemEditing_ItemName_Changed,
    payload: {
      newName,
      itemId,
    },
  }) as const;

export const contentItemCodenameNotUniqueError = () =>
  ({
    type: ContentItemEditing_CodenameEditor_NotUniqueError,
  }) as const;

export const contentItemCodenameChanged = () =>
  ({
    type: ContentItemEditing_CodenameEditor_Changed,
  }) as const;

interface ISavingFailedPayload {
  readonly failure?: EditedContentItemFailure;
  readonly apiError: IServerApiError | null;
}

export type ContentItemElementsSavingFailedPayload = ISavingFailedPayload & {
  readonly operationIds: Array<ItemEditorOperationId>;
};

export type ContentItemSavingFailedPayload = ISavingFailedPayload & {
  readonly operationId: ItemEditorOperationId;
};

export const contentItemElementsSavingFailed = (payload: ContentItemElementsSavingFailedPayload) =>
  ({
    type: ContentItemEditing_ItemElementsSaving_Failed,
    payload: { ...payload },
  }) as const;

export const contentItemSavingFailed = (payload: ContentItemSavingFailedPayload) =>
  ({
    type: ContentItemEditing_Saving_Failed,
    payload: { ...payload },
  }) as const;

export const contentItemSavingFinished = (payload: ContentItemSavingFinishedPayload) =>
  ({
    type: ContentItemEditing_ItemSaving_Finished,
    payload,
  }) as const;

export const contentItemCodenameSavingFinished = () =>
  ({
    type: ContentItemEditing_CodenameSaving_Finished,
  }) as const;

export const contentItemCodenameSavingFailed = () =>
  ({
    type: ContentItemEditing_CodenameSaving_Failed,
  }) as const;

export const contentItemSavingStarted = (operationId: ItemEditorOperationId) =>
  ({
    type: ContentItemEditing_Saving_Started,
    payload: { operationId },
  }) as const;

export const contentItemElementsSavingStarted = (operationIds: Array<ItemEditorOperationId>) =>
  ({
    type: ContentItemEditing_ItemElementsSaving_Started,
    payload: { operationIds },
  }) as const;

export const contentItemVariantArchivingFailed = () =>
  ({
    type: ContentItemEditing_Archiving_Failed,
  }) as const;

export type ItemArchivingFinishedPayload = IContentItemWithVariantPayload & {
  itemId: Uuid;
  variantId: Uuid;
};

export const contentItemVariantArchivingFinished = (payload: ItemArchivingFinishedPayload) =>
  ({
    type: ContentItemEditing_Archiving_Finished,
    payload,
  }) as const;

export const contentItemVariantArchivingStarted = () =>
  ({
    type: ContentItemEditing_Archiving_Started,
  }) as const;

export const contentItemElementsSavingFinished = (
  payload: ContentItemElementsSavingFinishedPayload,
) =>
  ({
    type: ContentItemEditing_ItemElementsSaving_Finished,
    payload,
  }) as const;

export const contentItemDuplicatingFailed = () =>
  ({
    type: ContentItemEditing_Duplicating_Failed,
  }) as const;

export const contentItemDuplicatingFinished = (payload: ItemWithVariantAndFilterPayload) =>
  ({
    type: ContentItemEditing_Duplicating_Finished,
    payload,
  }) as const;

export const contentItemDuplicatingStarted = () =>
  ({
    type: ContentItemEditing_Duplicating_Started,
  }) as const;

export const closeIncompleteItemNotificationBar = () =>
  ({
    type: ContentItemEditing_CloseIncompleteItemNotificationBar,
  }) as const;

export const closeRevisionRestoredNotificationBar = () =>
  ({
    type: ContentItemEditing_NotificationBar_Close,
  }) as const;

export const elementValueChanged = (payload: {
  elementData: ICompiledContentItemElementData;
  itemId: Uuid;
  typeElement: EditableTypeElement;
  validationResult: IValidationResult;
}) =>
  ({
    type: ContentItemEditing_ElementValue_Changed,
    payload,
  }) as const;

export const removeHiddenElementsValues = (idsOfElementsThatBecameHidden: ReadonlyArray<Uuid>) =>
  ({
    type: ContentItemEditing_HiddenElements_ClearValues,
    payload: { idsOfElementsThatBecameHidden },
  }) as const;

export const addDefaultValuesToVisibleElements = (
  visibleElements: ReadonlyArray<ICompiledContentItemElementData>,
  typeElements: ReadonlyArray<TypeElement>,
) =>
  ({
    type: ContentItemEditing_VisibleElements_AddDefaultValues,
    payload: { visibleElements, typeElements },
  }) as const;

export const focusedCommentThreadChanged = (
  threadId: Uuid | null,
  navigatedFrom?: NavigatedFromData,
) =>
  ({
    type: ContentItemEditing_CommentThread_Focused,
    payload: { threadId, navigatedFrom: navigatedFrom ?? null },
  }) as const;

export const hideAllComments = () =>
  ({
    type: ContentItemEditing_CommentThread_HideAllComments,
  }) as const;

export const showAllComments = () =>
  ({
    type: ContentItemEditing_CommentThread_ShowAllComments,
  }) as const;

export const updateAutogeneratedUrlSlugElements = (
  urlSlugElements: ReadonlyArray<ICompiledContentItemElementData>,
) =>
  ({
    type: ContentItemEditing_UrlSlugContentElements_Update,
    payload: {
      urlSlugElements,
    },
  }) as const;

export const variantTranslationStarted = (
  projectEnvironmentId: Uuid,
  itemId: Uuid,
  variantId: Uuid,
) =>
  ({
    type: ContentItemEditing_VariantTranslation_Started,
    payload: {
      projectEnvironmentId,
      itemId,
      variantId,
    },
  }) as const;

export const variantTranslationFinished = (
  projectEnvironmentId: Uuid,
  itemId: Uuid,
  variantId: Uuid,
) =>
  ({
    type: ContentItemEditing_VariantTranslation_Finished,
    payload: {
      projectEnvironmentId,
      itemId,
      variantId,
    },
  }) as const;

export const registerCommentThreadPositionCallback = (
  elementId: Uuid,
  callback: (commentThreadId: Uuid) => number | null,
) =>
  ({
    type: ContentItemEditing_Comments_RegisterPositionCallback,
    payload: {
      elementId,
      callback,
    },
  }) as const;

export const sitemapLocationCollapsedStateToggled = (toggledNodeId: Uuid) =>
  ({
    type: ContentItemEditing_SitemapLocationCollapsedState_Toggled,
    payload: { toggledNodeId },
  }) as const;

export const submittingVariantAssignmentFailed = (
  errorMessage: string,
  submittingSection: AssignmentSections | null,
  modalDialogType: ItemEditingModalDialogType,
  modalDialogProperties: ItemEditingModalProperties,
) =>
  ({
    type: Content_Editing_AssignmentSubmittingFailed,
    payload: {
      errorMessage,
      submittingSection,
      modalDialogType,
      modalDialogProperties,
    },
  }) as const;

export const unpublishContentItemVariantFailed = (
  errorMessage: string,
  modalDialogProperties: ItemEditingModalProperties,
) =>
  ({
    type: Content_Editing_UnpublishContentItemVariantFailed,
    payload: {
      errorMessage,
      modalDialogType: ItemEditingModalDialogType.ChangeWorkflowStepDialog,
      modalDialogProperties,
    },
  }) as const;

export const scheduledPublishingFailed = (
  errorMessage: string,
  modalDialogProperties: ItemEditingModalProperties,
) =>
  ({
    type: Content_Editing_ScheduledPublishingFailed,
    payload: {
      errorMessage,
      modalDialogType: ItemEditingModalDialogType.ChangeWorkflowStepDialog,
      modalDialogProperties,
    },
  }) as const;

export const scheduledUnpublishingFailed = (
  errorMessage: string,
  modalDialogProperties: ItemEditingModalProperties,
) =>
  ({
    type: Content_Editing_ScheduledUnpublishingFailed,
    payload: {
      errorMessage,
      modalDialogType: ItemEditingModalDialogType.ChangeWorkflowStepDialog,
      modalDialogProperties,
    },
  }) as const;

export const cancelScheduledPublishingFailed = (errorMessage: string) =>
  ({
    type: Content_Editing_CancelScheduledPublishingFailed,
    payload: { errorMessage },
  }) as const;

export const cancelScheduledUnpublishingFailed = (errorMessage: string) =>
  ({
    type: Content_Editing_CancelScheduledUnpublishingFailed,
    payload: { errorMessage },
  }) as const;

export const submittingVariantAssignmentFinished = (
  payload: ItemWithVariantAndFilterAndDataPayload,
) =>
  ({
    type: Content_Editing_AssignmentSubmittingFinished,
    payload,
  }) as const;

export const publishContentItemVariantFinished = (
  payload: ItemWithVariantAndFilterAndDataPayload,
) =>
  ({
    type: Content_Editing_PublishContentItemVariantFinished,
    payload,
  }) as const;

export const publishContentItemVariantFailed = (
  errorMessage: string,
  modalDialogProperties: ItemEditingModalProperties,
) =>
  ({
    type: Content_Editing_PublishContentItemVariantFailed,
    payload: {
      errorMessage,
      modalDialogProperties,
    },
  }) as const;

export const scheduledPublishingFinished = (payload: ItemWithVariantAndFilterAndDataPayload) =>
  ({
    type: Content_Editing_ScheduledPublishingFinished,
    payload,
  }) as const;

type ScheduledUnpublishingFinishedPayload = IContentItemWithVariantPayload & {
  readonly filter: IListingFilter;
  readonly itemVariantData: IParsedItemVariant;
};

export const scheduledUnpublishingFinished = (payload: ScheduledUnpublishingFinishedPayload) =>
  ({
    type: Content_Editing_ScheduledUnpublishingFinished,
    payload,
  }) as const;

export const unpublishContentItemVariantFinished = (
  payload: ItemWithVariantAndFilterAndDataPayload,
) =>
  ({
    type: Content_Editing_UnpublishContentItemVariantFinished,
    payload,
  }) as const;

export const cancelScheduledPublishingFinished = (
  payload: ItemWithVariantAndFilterAndDataPayload,
) =>
  ({
    type: Content_Editing_CancelScheduledPublishingFinished,
    payload,
  }) as const;

export const cancelScheduledUnpublishingFinished = (
  payload: ItemWithVariantAndFilterAndDataPayload,
) =>
  ({
    type: Content_Editing_CancelScheduledUnpublishingFinished,
    payload,
  }) as const;

export const publishingContentItemVariantStarted = () =>
  ({
    type: Content_Editing_PublishContentItemVariantStarted,
  }) as const;

export const submittingVariantAssignmentStarted = (submittingSection: AssignmentSections) =>
  ({
    type: Content_Editing_AssignmentSubmittingStarted,
    payload: {
      submittingSection,
    },
  }) as const;

export const unpublishContentItemVariantStarted = () =>
  ({
    type: Content_Editing_UnpublishContentItemVariantStarted,
  }) as const;

export const scheduledPublishingStarted = () =>
  ({
    type: Content_Editing_ScheduledPublishingStarted,
  }) as const;

export const scheduledUnpublishingStarted = () =>
  ({
    type: Content_Editing_ScheduledUnpublishingStarted,
  }) as const;

export const cancelScheduledPublishingStarted = () =>
  ({
    type: Content_Editing_CancelScheduledPublishingStarted,
  }) as const;

export const cancelScheduledUnpublishingStarted = () =>
  ({
    type: Content_Editing_CancelScheduledUnpublishingStarted,
  }) as const;

export const toggleContentItemSitemapLocation = (selectedNodeId: Uuid) =>
  ({
    type: ContentItemEditing_SitemapLocation_Toggled,
    payload: { selectedNodeId },
  }) as const;

export const contentItemEditingSidebarSectionCleanedUp = () =>
  ({
    type: ContentItemEditing_Sidebar_Section_Cleaned_Up,
  }) as const;

export const abandonAssignmentSectionChange = (section: AssignmentSections) =>
  ({
    type: ContentItemEditing_AssignmentChange_Abandoned,
    payload: {
      section,
    },
  }) as const;

export const variantQuickActionsNotificationClose = () =>
  ({
    type: ContentItemEditing_VariantQuickActionsNotification_Close,
  }) as const;

export const contentComponentsExpanded = (contentComponentIds: Immutable.Set<Uuid>) =>
  ({
    type: ContentItemEditing_ContentComponents_Expanded,
    payload: { contentComponentIds },
  }) as const;

export const closeConvertContentComponentToItemNotificationBar = (itemId: Uuid) =>
  ({
    type: ContentItemEditing_ContentComponentConvertedToItemNotification_Close,
    payload: { itemId },
  }) as const;

export const highlightIncompleteItemElements = () =>
  ({
    type: ContentItemEditing_Elements_HighlightIncomplete,
  }) as const;

export const resetIsHighlighting = () =>
  ({
    type: ContentItemEditing_Elements_ResetIsHighlighing,
  }) as const;

export const focusFirstIncompleteItemElement = () =>
  ({
    type: ContentItemEditing_Elements_FocusFirstIncomplete,
  }) as const;

export const focusValidationWarningsBeforePublish = (incompleteElementIds: ReadonlyArray<Uuid>) =>
  ({
    type: ContentItemEditing_FocusValidationWarningsBeforePublish,
    payload: {
      incompleteElementIds,
    },
  }) as const;

export const showIncompleteDefaultVariantNotification = () =>
  ({
    type: ContentItemEditing_ShowIncompleteDefaultVariantNotificationBar,
  }) as const;

export const closeIncompleteDefaultVariantNotification = () =>
  ({
    type: ContentItemEditing_CloseIncompleteDefaultVariantNotificationBar,
  }) as const;

export const showElementsAreInaccessibleNotification = (trigger: ContentGroupSelectionReason) =>
  ({
    type: ContentItemEditing_ShowElementsAreInaccessibleNotification,
    payload: { trigger },
  }) as const;

export const hideElementsAreInaccessibleNotification = () =>
  ({
    type: ContentItemEditing_HideElementsAreInaccessibleNotification,
  }) as const;

export const expandModularContentItemFinished = (contentItem: IContentItemWithVariant) =>
  ({
    type: ContentItemEditing_ExpandModularContentItem_Finished,
    payload: {
      contentItem,
    },
  }) as const;

export const commentThreadItemEditingStarted = (payload: {
  threadId: Uuid;
  commentId: Uuid;
}) =>
  ({
    type: ContentItemEditing_CommentThread_ItemEditingStarted,
    payload,
  }) as const;

interface ISuggestionApprovalInitiatedPayload {
  readonly commentThreadId: Uuid;
  readonly suggestionId: Uuid;
  readonly suggestedText: string;
}

export const suggestionApprovalInitiated = (payload: ISuggestionApprovalInitiatedPayload) =>
  ({
    type: ContentItemEditing_SuggestionApprovalInitiated,
    payload: { ...payload },
  }) as const;

export const markEditedItemAsTemporary = (
  temporaryItem: Omit<ITemporaryContentItem, 'itemState'>,
) =>
  ({
    type: ContentItemEditing_MarkAsTemporary,
    payload: {
      temporaryItem: {
        ...temporaryItem,
        itemState: TemporaryContentItemState.Default,
      },
    },
  }) as const;

export const newlyCreatedItemEditorMounted = () =>
  ({
    type: ContentItemEditing_NewlyCreatedItem_EditorMounted,
  }) as const;

export const languageVariantSwitched = () =>
  ({
    type: ContentItemEditing_LanguageVariantSwitched,
  }) as const;

export const discussionsSidebarClosed = () =>
  ({
    type: ContentItemEditing_DiscussionsSidebarClosed,
  }) as const;

export const discussionSidebarOpened = () =>
  ({
    type: ContentItemEditing_DiscussionsSidebarOpened,
  }) as const;

export const resolvedCommentsSidebarClosed = () =>
  ({
    type: ContentItemEditing_ResolvedCommentsSidebarClosed,
  }) as const;

export const resolvedCommentsSidebarOpened = () =>
  ({
    type: ContentItemEditing_ResolvedCommentsSidebarOpened,
  }) as const;

export const initializeContentItemEditingSidebar = () =>
  ({
    type: ContentItemEditing_Sidebar_Initialized,
  }) as const;

export const closeContentItemEditingSidebar = () =>
  ({
    type: ContentItemEditing_Sidebar_Closed,
  }) as const;

export const contentItemCodenameEditorErrorStatusCleared = () =>
  ({
    type: ContentItemEditing_CodenameEditor_ErrorStatusCleared,
  }) as const;

export const activateContentItemEditingAction = (activatedAction: EditingAction) =>
  ({
    type: ContentItemEditing_Action_Activated,
    payload: {
      activatedAction,
    },
  }) as const;

export const deactivateContentItemEditingAction = () =>
  ({
    type: ContentItemEditing_Action_Deactivated,
  }) as const;

export const cancelTaskEditing = (taskId: Uuid) =>
  ({
    type: ContentItemEditing_TaskEditing_Cancelled,
    payload: {
      taskId,
    },
  }) as const;

export const startTaskEditing = (taskId: Uuid) =>
  ({
    type: ContentItemEditing_TaskEditing_Started,
    payload: {
      taskId,
    },
  }) as const;

export const itemLiveUsersChanged = (liveUserIds: ReadonlySet<UserId>) =>
  ({
    type: ContentItemEditing_ItemLiveUsers_Changed,
    payload: {
      liveUserIds,
    },
  }) as const;

export const itemLiveUserPresenceMonitorUnmounted = () =>
  ({
    type: ContentItemEditing_ItemLiveUserPresenceMonitor_Unmounted,
  }) as const;

export const lockedElementsChanged = (
  lockedElements: Notifications['lockedElementsChange']['payload']['lockedElementSessions'],
) =>
  ({
    type: ContentItemEditing_LockedElements_Changed,
    payload: {
      lockedElements,
    },
  }) as const;

export const contentItemElementRefreshStarted = (elementId: Uuid) =>
  ({
    type: ContentItemEditing_ElementRefresh_Started,
    payload: {
      elementId,
    },
  }) as const;

export const contentItemElementRefreshFailed = () =>
  ({
    type: ContentItemEditing_ElementRefresh_Failed,
  }) as const;

export const contentItemElementRefreshFetched = (
  refreshedElements: ReadonlyArray<ICompiledContentItemElementData>,
) =>
  ({
    type: ContentItemEditing_ElementRefresh_Fetched,
    payload: {
      refreshedElements,
    },
  }) as const;

export const contentItemElementRefreshFinished = (
  elementData: ICompiledContentItemElementData,
  validationResult: IValidationResult,
) =>
  ({
    type: ContentItemEditing_ElementRefresh_Finished,
    payload: {
      elementData,
      validationResult,
    },
  }) as const;

export const itemRefreshedAfterLinkedEntityChange = (payload: ItemWithVariantAndFilterPayload) =>
  ({
    type: ContentItemEditing_LinkedEntityChanged_ItemRefreshed,
    payload,
  }) as const;

export const newContentItemVariantInitPrepared = (payload: INewContentItemVariantInitState) =>
  ({
    type: ContentItemEditing_Init_VariantPrepared,
    payload,
  }) as const;

export const changesDiscarded = () =>
  ({
    type: ContentItemEditing_Changes_Discarded,
  }) as const;

export const pendingAiOperationsDiscarded = () =>
  ({
    type: ContentItemEditing_PendingAiOperations_Discarded,
  }) as const;

export const autoDispatchSaveCancelled = (operationIds: ItemEditorOperationId[]) =>
  ({
    type: ContentItemEditing_AutoDispatchSave_Cancelled,
    payload: {
      operationIds,
    },
  }) as const;

export const shouldAutoDispatchCheckPending = (operationId: ItemEditorOperationId) =>
  ({
    type: ContentItemEditing_ShouldAutoDispatchCheck_Pending,
    payload: {
      operationId,
    },
  }) as const;

export const shouldAutoDispatchCheckFinished = (operationId: ItemEditorOperationId) =>
  ({
    type: ContentItemEditing_ShouldAutoDispatchCheck_Finished,
    payload: {
      operationId,
    },
  }) as const;

export const taskHighlighted = (taskId: Uuid) =>
  ({
    type: ContentItemEditing_HighlightedTask_Highlighted,
    payload: {
      taskId,
    },
  }) as const;
