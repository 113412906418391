import { AvatarSize } from '@kontent-ai/component-library/Avatar';
import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { MouseEvent } from 'react';
import { UserAvatar } from '../../../../../../../_shared/components/users/UserAvatar.tsx';
import { AiAvatar } from '../../../../../../../_shared/features/AI/components/AiAvatar.tsx';
import { OptionalTooltip } from '../../../../../../../_shared/uiComponents/Tooltip/OptionalTooltip.tsx';
import {
  DataUiCommentsAction,
  getDataUiCommentActionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  getUserFriendlyDateString,
  toLocalTime,
} from '../../../../../../../_shared/utils/dateTime/timeUtils.ts';
import { getUserName } from '../../../../../../../_shared/utils/users/usersUtils.ts';
import { IProjectContributor } from '../../../../../../../data/models/users/ProjectContributor.ts';
import {
  AiAssistantId,
  AiAssistantUserName,
} from '../../../../../../../paperModels/aiReview/constants/commentsConstants.ts';
import { ICommentThreadItem } from '../../../../../models/comments/CommentThreadItem.ts';
import { isSuggestion } from '../../../../../models/comments/Suggestion.ts';
import { TipsFromKontentId, TipsFromKontentUserName } from '../../../../../utils/commentUtils.ts';
import { CommentContextMenu } from '../../../containers/comments/CommentContextMenu.tsx';
import { TipsFromKontent } from './TipsFromKontent.tsx';

type ApproveSuggestionProps = {
  readonly approveInfo: string;
  readonly isLastApprovedSuggestion?: boolean;
  readonly onApproveSuggestion?: () => void;
};

const ApproveSuggestion = (props: ApproveSuggestionProps) => {
  if (props.isLastApprovedSuggestion) {
    return null;
  }

  const approveSuggestion = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    props.onApproveSuggestion?.();
  };

  return (
    <QuinaryButton
      aria-label="Approve"
      disabled={!props.onApproveSuggestion}
      onClick={approveSuggestion}
      tooltipText={props.approveInfo}
      {...getDataUiCommentActionAttribute(DataUiCommentsAction.Approve)}
    >
      <QuinaryButton.Icon icon={Icons.CheckCircle} />
    </QuinaryButton>
  );
};

type CommentThreadItemHeaderProps = {
  readonly allowCopyLink: boolean;
  readonly allowEdit: boolean;
  readonly allowResolve: boolean;
  readonly approveInfo: string;
  readonly commentThreadId: Uuid;
  readonly commentThreadItem: ICommentThreadItem;
  readonly createdBy: IProjectContributor | null;
  readonly isLastApprovedSuggestion?: boolean;
  readonly onApproveSuggestion?: () => void;
  readonly onResolve: () => void;
};

export const CommentThreadItemHeader = (props: CommentThreadItemHeaderProps) => {
  const { allowCopyLink, allowEdit, allowResolve, commentThreadItem, createdBy, commentThreadId } =
    props;

  const itemIsSuggestion = isSuggestion(commentThreadItem);

  const onResolve = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    props.onResolve();
  };

  const threadCreatedAt = toLocalTime(commentThreadItem.createdAt);
  const userFriendlyDate = threadCreatedAt
    ? getUserFriendlyDateString(threadCreatedAt, new Date())
    : '';
  const threadUpdatedAt = toLocalTime(commentThreadItem.updatedAt);
  const userFriendlyEditDate = threadUpdatedAt
    ? getUserFriendlyDateString(threadUpdatedAt, new Date())
    : undefined;
  const wasEdited = !!commentThreadItem.updatedAt;

  const userName = getCommentUserName(commentThreadItem.createdBy, createdBy);

  const renderAvatar = () => {
    switch (commentThreadItem.createdBy) {
      case AiAssistantId:
        return <AiAvatar size={AvatarSize.S} />;

      case TipsFromKontentId:
        return <TipsFromKontent size={AvatarSize.S} />;

      default:
        return <UserAvatar user={createdBy} size={AvatarSize.S} />;
    }
  };

  return (
    <header className="comment__header">
      {renderAvatar()}
      <div className="comment__author-timestamp">
        <OptionalTooltip text={userName} placement="top" className="comment__author">
          {userName}
        </OptionalTooltip>
        {!wasEdited && (
          <OptionalTooltip text={userFriendlyDate} className="comment__info" placement="top">
            <time dateTime={threadCreatedAt?.toString()}>{userFriendlyDate}</time>
          </OptionalTooltip>
        )}
        {wasEdited && (
          <Tooltip text={`${userFriendlyDate} (Edited: ${userFriendlyEditDate})`} placement="top">
            <div className="comment__info">
              <time dateTime={threadUpdatedAt?.toString()}>{userFriendlyDate}</time>(Edited)
            </div>
          </Tooltip>
        )}
      </div>
      <Inline align="center" spacing={Spacing.XS} noWrap>
        {itemIsSuggestion && (
          <ApproveSuggestion
            approveInfo={props.approveInfo}
            onApproveSuggestion={props.onApproveSuggestion}
            isLastApprovedSuggestion={props.isLastApprovedSuggestion}
          />
        )}
        {allowResolve && !itemIsSuggestion && (
          <QuinaryButton
            onClick={onResolve}
            tooltipText="Resolve"
            {...getDataUiCommentActionAttribute(DataUiCommentsAction.MarkAsResolved)}
          >
            Resolve
          </QuinaryButton>
        )}
        <CommentContextMenu
          allowCopyLink={allowCopyLink}
          allowEdit={allowEdit}
          allowResolve={allowResolve && itemIsSuggestion}
          commentId={commentThreadItem.id}
          threadId={commentThreadId}
        />
      </Inline>
    </header>
  );
};

const getCommentUserName = (createdBy: UserId, user: IProjectContributor | null) => {
  if (createdBy === TipsFromKontentId) {
    return TipsFromKontentUserName;
  }

  if (createdBy === AiAssistantId) {
    return AiAssistantUserName;
  }

  return getUserName(user);
};
