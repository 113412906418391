import { Callout } from '@kontent-ai/component-library/Callout';
import React from 'react';
import { Link } from 'react-router-dom';
import { AuditLogRoute, EnvironmentRouteParams } from '../../../../_shared/constants/routePaths.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { getCurrentProjectPlan } from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { Capability } from '../../../../_shared/utils/permissions/capability.ts';
import { currentUserHasCapabilities } from '../../../../_shared/utils/permissions/capabilityUtils.ts';
import { buildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { isAuditLogEnabledForCurrentProjectPlan } from '../../../environmentSettings/utils/allowedFeaturesUtils.ts';

export const AuditLogTip: React.FC = () => {
  const projectId = useSelector((state) => state.sharedApp.currentProjectId);
  const isAuditLogAccessibleSelector = (state: IStore) =>
    currentUserHasCapabilities(state, Capability.AccessAuditLog) &&
    isAuditLogEnabledForCurrentProjectPlan(getCurrentProjectPlan(state));
  const isAuditLogAccessible = useSelector(isAuditLogAccessibleSelector);

  const auditLogLink = buildPath<EnvironmentRouteParams>(AuditLogRoute, { projectId });

  return isAuditLogAccessible ? (
    <Callout calloutType="quickTip" headline="Something’s different?">
      Find out more about changes to your content model in <Link to={auditLogLink}>Audit Log</Link>.
    </Callout>
  ) : null;
};

AuditLogTip.displayName = 'AuditLogTip';
