import { IPlan } from '../../../data/models/plans/Plan.ts';

export const areMultipleWorkflowsEnabledForCurrentProject = (currentProjectPlan: IPlan): boolean =>
  currentProjectPlan.features.areMultipleWorkflowsEnabled;

export const areSpacesEnabledForCurrentProject = (currentProjectPlan: IPlan): boolean => {
  const { maxProjectSpaces } = currentProjectPlan.features;
  return maxProjectSpaces === null || maxProjectSpaces > 0;
};

export const areContentCollectionsEnabledForCurrentProjectPlan = (
  currentProjectPlan: IPlan,
): boolean => currentProjectPlan.features.areContentCollectionsEnabled;

export const isAuditLogEnabledForCurrentProjectPlan = (currentProjectPlan: IPlan): boolean =>
  currentProjectPlan.features.auditLogEnabled;
