import { InputState } from '@kontent-ai/component-library/Input';
import { Collection } from '@kontent-ai/utils';
import { useMemo } from 'react';
import { useController } from 'react-hook-form';
import { ValidatedMultipleOptionSelect } from '../../../../../_shared/components/input/ValidatedMultipleOptionSelect.tsx';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { HookFormProps } from '../../../../../_shared/types/hookFormProps.type.ts';
import {
  DataUiCollection,
  DataUiInput,
  getDataUiCollectionAttribute,
  getDataUiInputAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getActiveLanguagesWithDefaultSuffix } from '../../../../../_shared/utils/languageUtils.ts';
import { anyCollectionCollection } from '../../../constants/anyCollectionCollection.ts';
import { anyContentTypeContentType } from '../../../constants/anyContentTypeContentType.ts';
import { anyLanguageLanguage } from '../../../constants/anyLanguageLanguage.ts';
import { CollectionOption } from '../../../models/CollectionOption.ts';
import { ContentTypeOption } from '../../../models/ContentTypeOption.ts';
import { IEntityWebhookFormShape } from '../../../models/IEntityWebhookFormShape.type.ts';
import { LanguageOption } from '../../../models/LanguageOption.ts';
import { getSortedCollectionOptionsListWithAnyCollectionFirst } from '../../../selectors/getSortedCollectionOptionsListWithAnyCollectionFirst.ts';
import { getSortedContentTypeOptionsListWithAnyContentTypeFirst } from '../../../selectors/getSortedContentTypeOptionsListWithAnyContentTypeFirst.ts';
import { getSortedLanguageOptionsListWithAnyLanguageFirst } from '../../../selectors/getSortedLanguageOptionsListWithAnyLanguageFirst.ts';
import { handleAnyCollectionCollectionOnCollectionsChangedInFormInput } from '../../../utils/anyCollectionCollectionBehaviorUtils.ts';
import { handleAnyContentTypeContentTypeOnContentTypesChangedInFormInput } from '../../../utils/anyContentTypeContentTypeBehaviorUtils.ts';
import { handleAnyLanguageLanguageOnLanguagesChangedInFormInput } from '../../../utils/anyLanguageLanguageBehaviorUtils.ts';
import { renderFilterOption } from '../../../utils/renderFilterOption.tsx';
import { EntityWebhookValidationError } from '../EntityWebhookValidationError.tsx';

type Props = {
  readonly formProps: HookFormProps<IEntityWebhookFormShape>;
};

export const EntityWebhookContentItemFilterSelectors = ({ formProps }: Props) => {
  const { control } = formProps;
  const { field } = useController({ control, name: 'triggers.contentItemTrigger' });

  const contentTypes = useSelector((s) => s.data.contentTypes.byId);
  const contentTypeOptions = useMemo(
    () => getSortedContentTypeOptionsListWithAnyContentTypeFirst(contentTypes),
    [contentTypes],
  );

  const collections = useSelector((s) => s.data.collections.byId);
  const collectionOptions = useMemo(
    () => getSortedCollectionOptionsListWithAnyCollectionFirst(collections),
    [collections],
  );

  const activeLanguages = useSelector((s) => getActiveLanguagesWithDefaultSuffix(s.data.languages));
  const languageOptions = useMemo(
    () => getSortedLanguageOptionsListWithAnyLanguageFirst(activeLanguages),
    [activeLanguages],
  );

  const inputState =
    field.value.checked && !Collection.isEmpty(field.value.actions)
      ? InputState.Default
      : InputState.Disabled;

  const isEmptyContentTypeFilter = field.value.contentTypeIds.length < 1;
  const isEmptyCollectionFilter = field.value.collectionIds.length < 1;
  const isEmptyLanguageFilter = field.value.languageIds.length < 1;

  const normalizeContentTypes = (
    formValues: readonly ContentTypeOption[],
    previousValues: readonly ContentTypeOption[],
  ): readonly ContentTypeOption[] => {
    const newValues = handleAnyContentTypeContentTypeOnContentTypesChangedInFormInput(
      formValues,
      previousValues,
    );
    field.onChange({
      ...field.value,
      contentTypeIds: newValues.map((option) => option.id),
    });
    return newValues;
  };

  const normalizeCollections = (
    formValues: readonly CollectionOption[],
    previousValues: readonly CollectionOption[],
  ): readonly CollectionOption[] => {
    const newValues = handleAnyCollectionCollectionOnCollectionsChangedInFormInput(
      formValues,
      previousValues,
    );
    field.onChange({
      ...field.value,
      collectionIds: newValues.map((option) => option.id),
    });
    return newValues;
  };

  const normalizeLanguages = (
    formValues: readonly LanguageOption[],
    previousValues: readonly LanguageOption[],
  ): readonly LanguageOption[] => {
    const newValues = handleAnyLanguageLanguageOnLanguagesChangedInFormInput(
      formValues,
      previousValues,
    );
    field.onChange({
      ...field.value,
      languageIds: newValues.map((option) => option.id),
    });
    return newValues;
  };

  return (
    <>
      <ValidatedMultipleOptionSelect<IEntityWebhookFormShape, ContentTypeOption>
        formProps={formProps}
        inputState={inputState}
        items={contentTypeOptions}
        label="Content type"
        name="triggers.contentItemTrigger.contentTypeIds"
        normalize={normalizeContentTypes}
        placeholder="Select content types"
        renderSelectedOption={renderFilterOption(anyContentTypeContentType.id)}
        verticalMenuDataAttributes={getDataUiCollectionAttribute(
          DataUiCollection.ContentItemFilter,
        )}
        {...getDataUiInputAttribute(DataUiInput.EntityWebhookContentTypeFilter)}
      />
      {isEmptyContentTypeFilter && inputState === InputState.Default && (
        <EntityWebhookValidationError message="Please select at least one content type." />
      )}
      <ValidatedMultipleOptionSelect<IEntityWebhookFormShape, CollectionOption>
        formProps={formProps}
        inputState={inputState}
        items={collectionOptions}
        label="Collection"
        name="triggers.contentItemTrigger.collectionIds"
        normalize={normalizeCollections}
        placeholder="Select collections"
        renderSelectedOption={renderFilterOption(anyCollectionCollection.id)}
        verticalMenuDataAttributes={getDataUiCollectionAttribute(
          DataUiCollection.ContentItemFilter,
        )}
        {...getDataUiInputAttribute(DataUiInput.EntityWebhookCollectionFilter)}
      />
      {isEmptyCollectionFilter && inputState === InputState.Default && (
        <EntityWebhookValidationError message="Please select at least one collection." />
      )}
      <ValidatedMultipleOptionSelect<IEntityWebhookFormShape, LanguageOption>
        formProps={formProps}
        inputState={inputState}
        items={languageOptions}
        label="Language"
        name="triggers.contentItemTrigger.languageIds"
        normalize={normalizeLanguages}
        placeholder="Select languages"
        renderSelectedOption={renderFilterOption(anyLanguageLanguage.id)}
        verticalMenuDataAttributes={getDataUiCollectionAttribute(
          DataUiCollection.ContentItemFilter,
        )}
        {...getDataUiInputAttribute(DataUiInput.EntityWebhookLanguageFilter)}
      />
      {isEmptyLanguageFilter && inputState === InputState.Default && (
        <EntityWebhookValidationError message="Please select at least one language." />
      )}
    </>
  );
};

EntityWebhookContentItemFilterSelectors.displayName = 'EntityWebhookContentItemFilterFormFields';
