import { assert, Collection } from '@kontent-ai/utils';
import { EditorState } from 'draft-js';
import Immutable from 'immutable';
import { ClipboardEvent, forwardRef, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';
import { trackUserEvent } from '../../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { TrackUserEvent } from '../../../../../../../_shared/models/TrackUserEvent.type.ts';
import { ActiveCapabilityType } from '../../../../../../../_shared/models/activeCapability.type.ts';
import { getCurrentProjectId } from '../../../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { hasActiveVariantCapabilityForEditedItem } from '../../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { useLivePreviewPreferenceStorage } from '../../../../../../../localStorages/useLivePreviewPreferenceStorage.ts';
import { RequiredAssetCreationMetadata } from '../../../../../../../repositories/serverModels/AssetServerModels.type.ts';
import { removeTemporaryAssetIdentifiers } from '../../../../../../contentInventory/assets/actions/assetLibraryActions.ts';
import { uploadAssetsFromEditing } from '../../../../../../contentInventory/assets/actions/thunkAssetLibraryActions.ts';
import { IOnAssetFinished } from '../../../../../../contentInventory/assets/actions/thunks/createAssets.ts';
import { FileWithThumbnail } from '../../../../../../contentInventory/assets/models/FileWithThumbnail.type.ts';
import { ElementType } from '../../../../../../contentInventory/content/models/ContentItemElementType.ts';
import { IRichTextTypeElement } from '../../../../../../contentInventory/content/models/contentTypeElements/RichTextTypeElement.ts';
import {
  convertContentComponentToContentItemVariant,
  pasteContentToRichText,
} from '../../../../../../richText/actions/thunkRichTextActions.ts';
import {
  RichTextInput,
  RichTextInputProps,
} from '../../../../../../richText/editors/richText/RichTextInput.tsx';
import { getRichTextElementStatistics } from '../../../../../../richText/plugins/apiStatistics/api/editorStatistics.ts';
import { IFocusable } from '../../../../../../richText/plugins/behavior/FocusPlugin.tsx';
import {
  IRichTextContent,
  PasteContentToRichTextParams,
} from '../../../../../../richText/plugins/clipboard/thunks/pasteContentToRichText.ts';
import { OnAddComment } from '../../../../../../richText/plugins/comments/CommentsPlugin.tsx';
import { IApprovedSuggestion } from '../../../../../../richText/plugins/comments/api/editorSuggestionUtils.ts';
import { InsertContentComponent } from '../../../../../../richText/plugins/contentComponents/api/EditorContentComponentApi.type.ts';
import { TrackRTECommandUsed } from '../../../../../../richText/plugins/keyboardShortcuts/KeyboardShortcutsPlugin.tsx';
import { IContentComponent } from '../../../../../models/contentItem/ContentComponent.ts';
import {
  getCommentThreadIdByExternalSegmentIdMappingForElement,
  getFocusedCommentThreadIdForElement,
  getTextElementCommentThreadsByExternalSegmentId,
} from '../../../../../selectors/elementCommentThreads.ts';
import { CommentThreadState } from '../../../../../types/CommentThreadState.ts';
import { IRichTextWarningResult } from '../../../../../utils/itemElementWarningCheckers/types/IRichTextItemElementWarningResult.type.ts';
import { getValidationSelectorId } from '../../../../../utils/itemElementWarningCheckers/utils/getValidationSelectorId.ts';
import { loadRichTextReferences } from '../../../../LoadedItems/actions/thunkLoadedItemsActions.ts';
import { prepareNewContentItemDialogForContentComponent } from '../../../../NewContentItem/actions/thunkNewContentItemActions.ts';
import { getAvailableContentTypesForContentComponent } from '../../../../NewContentItem/utils/getAvailableContentTypesForContentComponent.ts';
import { focusedCommentThreadChanged } from '../../../actions/contentItemEditingActions.ts';
import {
  addCommentToRichTextElement,
  blurCommentThread,
  changeRichStringElementValue,
  createNewContentComponent,
  duplicateContentItem,
  markSuggestionAsApproved,
  setRichTextElementClipboard,
  trackContentComponentCreated,
} from '../../../actions/thunkContentItemEditingActions.ts';
import { redirectToLinkedItem } from '../../../actions/thunks/redirectToLinkedItem.ts';
import { ReadonlyEmptyElementPlaceholder } from '../../../models/ReadonlyEmptyElementPlaceholder.ts';
import { ElementReference, useItemElementReference } from '../../hooks/useItemElementReference.ts';

type IRichTextInputOwnProps = Pick<
  RichTextInputProps,
  | 'autoFocus'
  | 'className'
  | 'disabled'
  | 'editedEntityName'
  | 'editorState'
  | 'onContentChange'
  | 'onEscape'
>;

interface IContentItemRichTextInputContainerProps extends IRichTextInputOwnProps {
  readonly typeElement: IRichTextTypeElement;
}

const emptyCommentThreadIdMapping: ReadonlyMap<Uuid, Uuid> = new Map();
const emptyThreads: ReadonlyMap<Uuid, CommentThreadState> = new Map();

export const ContentItemRichTextInput = forwardRef<
  IFocusable,
  IContentItemRichTextInputContainerProps
>(({ typeElement, ...otherProps }, ref) => {
  const currentProjectId = useSelector(getCurrentProjectId);
  const { isLivePreviewPreferred } = useLivePreviewPreferenceStorage(currentProjectId);
  const { elementId } = typeElement;
  const element = useItemElementReference(typeElement);
  const {
    elementName = null,
    elementType = null,
    itemId = null,
    contentComponentId = null,
    rootRichTextElementId = null,
  } = element ?? {};

  const allowCreateCommentThread = useSelector((state) =>
    hasActiveVariantCapabilityForEditedItem(ActiveCapabilityType.ViewContent, state),
  );
  const approvedSuggestions = useSelector((state) => state.contentApp.editorUi.approvedSuggestions);

  const threads = useSelector((state) =>
    elementId
      ? getTextElementCommentThreadsByExternalSegmentId(
          state.contentApp.editedContentItemVariantComments.commentThreads,
          elementId,
          contentComponentId,
        )
      : emptyThreads,
  );
  const commentThreadIdMapping = useSelector((state) =>
    elementId
      ? getCommentThreadIdByExternalSegmentIdMappingForElement(
          state.contentApp.editedContentItemVariantComments.commentThreads,
          elementId,
          contentComponentId,
        )
      : emptyCommentThreadIdMapping,
  );
  const focusedCommentThreadId = useSelector((s) =>
    elementId
      ? getFocusedCommentThreadIdForElement(
          s.contentApp.editedContentItemVariantComments.commentThreads,
          elementId,
          contentComponentId ?? null,
          s.contentApp.editedContentItemVariantComments.focusedCommentThreadId,
        )
      : null,
  );
  const isPossiblyIncorrectPlacementWarningDismissed = useSelector(
    (state) => state.sharedApp.userProperties.dismissedPossiblyIncorrectPlacementWarning,
  );
  const statistics = useSelector((state) =>
    itemId ? getRichTextElementStatistics(state.sharedApp.currentProjectId, itemId) : undefined,
  );
  const validationResultSelectorId = getValidationSelectorId(
    elementId,
    contentComponentId ?? undefined,
  );
  const validationResult = useSelector(
    (state) =>
      state.contentApp.itemValidationWarnings.get(
        validationResultSelectorId,
      ) as IRichTextWarningResult,
  );
  const contentItemCollectionId = useSelector(
    (state) => state.contentApp.editedContentItem?.collectionId ?? '',
  );

  const singleUsableContentTypeIdForComponent = useSelector((state) => {
    const contentTypesForComponent = getAvailableContentTypesForContentComponent(state);

    return contentTypesForComponent.length === 1 ? (contentTypesForComponent[0]?.id ?? null) : null;
  });

  const dispatch = useDispatch();

  const initializeNewContentItemDialog = useCallback(
    (allowedContentTypeIds: Immutable.Set<Uuid>) =>
      dispatch(prepareNewContentItemDialogForContentComponent({ allowedContentTypeIds })),
    [],
  );

  const onBlurCommentThread = useCallback(() => dispatch(blurCommentThread()), []);

  const onConvertContentComponentToContentItemVariant = useCallback(
    (elementReference: ElementReference, componentId: Uuid, selectedWorkflowId?: Uuid) =>
      dispatch(
        convertContentComponentToContentItemVariant(
          elementReference,
          componentId,
          selectedWorkflowId,
        ),
      ),
    [],
  );

  const onAddComment: OnAddComment = useCallback(
    (editorState, type, api) => {
      assert(element, () => 'Missing edited element reference');
      return dispatch(addCommentToRichTextElement(element, editorState, type, api));
    },
    [element],
  );

  const onCreateContentComponent = useCallback(
    (
      elementReference: ElementReference,
      insertContentComponentItem: InsertContentComponent,
      editorState: EditorState,
      placeholderBlockKey: string,
      preselectedContentTypeId?: Uuid,
    ) => {
      dispatch(trackContentComponentCreated());
      return dispatch(
        createNewContentComponent(
          elementReference,
          insertContentComponentItem,
          editorState,
          placeholderBlockKey,
          preselectedContentTypeId,
        ),
      );
    },
    [],
  );

  const onDuplicateModularContentItem = useCallback(
    (contentItemId: Uuid, destinationCollectionId: Uuid) =>
      dispatch(duplicateContentItem(contentItemId, destinationCollectionId)),
    [],
  );

  const onFilesUpload = useCallback(
    (
      files: Map<Uuid, FileWithThumbnail>,
      metadata: RequiredAssetCreationMetadata,
      onAssetFinished: IOnAssetFinished,
    ) => dispatch(uploadAssetsFromEditing(files, metadata, onAssetFinished)),
    [],
  );

  const onFocusCommentThread = useCallback(
    (threadId: Uuid) => dispatch(focusedCommentThreadChanged(threadId)),
    [],
  );

  const onRichTextContentInserted = useCallback(
    async (content: IRichTextContent): Promise<void> => {
      if (itemId && rootRichTextElementId && content.contentComponents.size > 0) {
        dispatch(
          changeRichStringElementValue(itemId, rootRichTextElementId, (data) => ({
            ...data,
            contentComponents: new Map<Uuid, IContentComponent>([
              ...Collection.getEntries(data.contentComponents),
              ...Collection.getEntries(content.contentComponents),
            ]),
          })),
        );
      }

      await dispatch(
        loadRichTextReferences(
          [content.editorState.getCurrentContent()],
          content.contentComponents,
        ),
      );
    },
    [itemId, rootRichTextElementId],
  );

  const onPasteContent = useCallback(
    async (
      editorState: EditorState,
      params: PasteContentToRichTextParams,
    ): Promise<EditorState> => {
      if (elementType) {
        const result = dispatch(
          pasteContentToRichText(elementId, elementType)(editorState, params),
        );
        await onRichTextContentInserted(result);
        return result.editorState;
      }
      return editorState;
    },
    [elementId, elementType, onRichTextContentInserted],
  );

  const onPostprocessingAssetsFinished = useCallback(
    (oldAssetIds: UuidArray) => dispatch(removeTemporaryAssetIdentifiers(oldAssetIds)),
    [],
  );

  const currentPath = useLocation().pathname;
  const history = useHistory();

  const onRedirectToContentItem = useCallback(
    (contentItemId: Uuid) => {
      dispatch(redirectToLinkedItem(currentPath, contentItemId, isLivePreviewPreferred, history));
    },
    [currentPath, isLivePreviewPreferred, history],
  );

  const onSuggestionApplied = useCallback(
    (suggestion: IApprovedSuggestion) =>
      dispatch(markSuggestionAsApproved(suggestion.commentThreadId, suggestion.suggestionId)),
    [],
  );

  const onSetRichTextClipboard = useCallback(
    (e: ClipboardEvent, editorState: EditorState) => {
      if (element) {
        dispatch(setRichTextElementClipboard(e, editorState, element));
      }
    },
    [element],
  );

  const onTrackRTECommandUsed = useCallback<TrackRTECommandUsed>(
    (commandInfo) =>
      dispatch(
        trackUserEvent(TrackedEvent.RTECommandUsed, {
          elementId,
          elementName: elementName ?? '',
          elementType: ElementType.RichText,
          ...commandInfo,
        }),
      ),
    [elementId, elementName],
  );

  const onTrackUserEventWithData: TrackUserEvent = useCallback(
    (...args) => dispatch(trackUserEvent(...args)),
    [],
  );

  if (!element) {
    return null;
  }

  return (
    <RichTextInput
      {...otherProps}
      focusableRef={ref}
      aiGuidelinesIds={typeElement.aiGuidelinesIds}
      allowCreateCommentThread={allowCreateCommentThread}
      approvedSuggestions={approvedSuggestions}
      commentThreadIdMapping={commentThreadIdMapping}
      commentThreads={threads}
      contentItemCollectionId={contentItemCollectionId}
      element={element}
      focusedCommentThreadId={focusedCommentThreadId}
      isPossiblyIncorrectPlacementWarningDismissed={isPossiblyIncorrectPlacementWarningDismissed}
      limitations={typeElement}
      singleUsableContentTypeIdForComponent={singleUsableContentTypeIdForComponent}
      statistics={statistics ?? null}
      validationResult={validationResult}
      initializeNewContentItemDialog={initializeNewContentItemDialog}
      onBlurCommentThread={onBlurCommentThread}
      onConvertContentComponentToContentItemVariant={onConvertContentComponentToContentItemVariant}
      onAddComment={onAddComment}
      onCreateContentComponent={onCreateContentComponent}
      onDuplicateModularContentItem={onDuplicateModularContentItem}
      onFilesUpload={onFilesUpload}
      onFocusCommentThread={onFocusCommentThread}
      onPasteContent={onPasteContent}
      onPostprocessingAssetsFinished={onPostprocessingAssetsFinished}
      onRedirectToContentItem={onRedirectToContentItem}
      onRichTextContentInserted={onRichTextContentInserted}
      onSuggestionApplied={onSuggestionApplied}
      placeholder={otherProps.disabled ? ReadonlyEmptyElementPlaceholder.StringElement : undefined}
      setRichTextClipboard={onSetRichTextClipboard}
      trackRTECommandUsed={onTrackRTECommandUsed}
      trackUserEvent={onTrackUserEventWithData}
    />
  );
});

ContentItemRichTextInput.displayName = 'ContentItemRichTextInput';
