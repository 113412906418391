import classNames from 'classnames';
import React from 'react';
import { ConnectDragSource } from 'react-dnd';
import { BarItemDragAction } from '../../../../../_shared/components/BarItems/Actions/BarItemDragAction.tsx';
import { BarItemEditAction } from '../../../../../_shared/components/BarItems/Actions/BarItemEditAction.tsx';
import { BarItemToggleAction } from '../../../../../_shared/components/BarItems/Actions/BarItemToggleAction.tsx';
import { BarItemActions } from '../../../../../_shared/components/BarItems/BarItemActions.tsx';
import { BarItemBar } from '../../../../../_shared/components/BarItems/BarItemBar.tsx';
import { BarItemTitle } from '../../../../../_shared/components/BarItems/BarItemTitle.tsx';
import { CodenameBarItemButton } from '../../../../../_shared/components/Codename/CodenameBarItemButton.tsx';
import { OnSaveCodename } from '../../../../../_shared/components/Codename/OnSaveCodename.type.ts';
import { ValidationConstants } from '../../../../../_shared/constants/validationConstants.ts';
import { ITaxonomyTerm } from '../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyTerm.ts';

export interface ITermBarStateProps {
  readonly isBeingDragged: boolean;
  readonly isBeingSaved: boolean;
  readonly isEditable: boolean;
  readonly isExpandCollapseButtonDisabled: boolean;
  readonly allCodenames?: ReadonlySet<string>;
}

export interface ITermBarDispatchProps {
  readonly onCodenameSave?: OnSaveCodename;
  readonly onCodenameCopy?: () => void;
  readonly onCodenameEdit?: () => void;
  readonly onTermEditingInitiated: () => void;
  readonly onTermToggled: () => void;
}

export interface ITermBarBaseProps {
  readonly isCollapsed: boolean;
  readonly taxonomyTerm: ITaxonomyTerm;
}

interface ITermBarOwnProps extends ITermBarBaseProps {
  readonly connectDragSource?: ConnectDragSource;
  readonly isDragging: boolean;
}

type ITermBarProps = ITermBarStateProps & ITermBarDispatchProps & ITermBarOwnProps;

export const TermBar: React.FC<ITermBarProps> = ({
  connectDragSource,
  isBeingDragged,
  isCollapsed,
  isDragging,
  isEditable,
  isExpandCollapseButtonDisabled,
  onCodenameSave,
  onCodenameCopy,
  onCodenameEdit,
  onTermEditingInitiated,
  onTermToggled,
  allCodenames,
  taxonomyTerm,
}) => (
  <div className="bar-item__wrapper">
    <div
      className={classNames('bar-item__pane', {
        'bar-item__pane--is-dragging': isBeingDragged || isDragging,
      })}
    >
      <BarItemBar isClickable={isEditable} isDragging={isBeingDragged || isDragging}>
        <BarItemActions>
          <BarItemDragAction connectDragSource={connectDragSource} disabled={!isEditable} />
          {taxonomyTerm.childIds.some((term) => !!term) && (
            <BarItemToggleAction
              ariaLabel="term"
              disabled={isExpandCollapseButtonDisabled}
              isCollapsed={isCollapsed}
              onExpand={onTermToggled}
              onCollapse={onTermToggled}
            />
          )}
        </BarItemActions>
        <BarItemTitle onClick={isEditable ? onTermEditingInitiated : undefined}>
          {taxonomyTerm.name}
        </BarItemTitle>
        <BarItemActions>
          <CodenameBarItemButton
            codename={taxonomyTerm.codename}
            maxLength={ValidationConstants.TaxonomyTermCodenameMaxLength}
            onCodenameSave={onCodenameSave}
            onCodenameCopy={onCodenameCopy}
            onCodenameEdit={onCodenameEdit}
            relatedCodenames={allCodenames}
          />
          <BarItemEditAction onClick={onTermEditingInitiated} disabled={!isEditable} />
        </BarItemActions>
      </BarItemBar>
    </div>
  </div>
);
