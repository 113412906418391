import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { Loader } from '../../../../../_shared/components/Loader.tsx';
import { AuthorizedSection } from '../../../../../_shared/components/routing/AuthorizedSection.tsx';
import {
  ApiKeyDetailAccessDeniedRouteSegment,
  ApiKeyDetailRouteParams,
} from '../../../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../../_shared/hooks/useThunkPromise.ts';
import { ApiKeyType } from '../../../../../_shared/models/ApiKeyType.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import { getCurrentProjectContainer } from '../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { Capability } from '../../../../../_shared/utils/permissions/capability.ts';
import { buildPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { formatUserName } from '../../../../../_shared/utils/users/usersUtils.ts';
import { apiKeyDetailsAlertDismissed } from '../../actions/apiKeysActions.ts';
import { createApiKey, initNewApiKey } from '../../actions/thunkApiKeysActions.ts';
import { ApiKey, emptyApiKey } from '../../models/ApiKey.ts';
import { getAccessibleEnvironmentIds } from '../../selectors/getAccessibleEnvironmentIds.ts';
import { hasCapabilityInAnyEnvironment } from '../../selectors/hasCapabilityInAnyEnvironment.ts';
import { getApiKeyRedirectRoute } from '../../utils/getApiKeyRedirectRoute.ts';
import { PersonalMapiKeyFormShape } from '../../validation/personalMapiKeyValidationConfig.ts';
import { PersonalMapiKeyDetailForm } from './PersonalMapiKeyDetailForm.tsx';

export const NewPersonalMapiKeyDetail = () => {
  const [isInitThunkDone] = useThunkPromise(initNewApiKey, ApiKeyType.MAPIPat);

  const dispatch = useDispatch();
  const history = useHistory();
  const currentProjectContainer = useSelector(getCurrentProjectContainer);
  const isAuthorized = useSelector((state) =>
    hasCapabilityInAnyEnvironment(state, Capability.ManagePersonalApiKey),
  );
  const apiKeyName = `${useSelector((s) => formatUserName(s.data.user.info))}’s key`;
  const environments = useSelector(getAccessibleEnvironmentIds);
  const allSet = useSelector(
    (state) => isInitThunkDone && state.apiKeysApp.detailLoadingStatus === LoadingStatus.Loaded,
  );
  const actionStatus = useSelector((state) => state.apiKeysApp.detailActionStatus);

  const getApiKey = useCallback(
    (mapiKeyFormShape: PersonalMapiKeyFormShape): ApiKey => {
      return {
        ...emptyApiKey,
        name: apiKeyName,
        expiresAt: mapiKeyFormShape.expiresAt.value,
        type: ApiKeyType.MAPIPat,
      };
    },
    [apiKeyName],
  );

  const createNewApiKey = useCallback(
    async (mapiKeyFormShape: PersonalMapiKeyFormShape): Promise<void> => {
      const apiKey = getApiKey(mapiKeyFormShape);
      const createdApiKey = await dispatch(createApiKey(apiKey));
      history.push(
        buildPath<ApiKeyDetailRouteParams>(getApiKeyRedirectRoute(apiKey.type), {
          projectContainerId: currentProjectContainer.projectContainerId,
          tokenSeedId: createdApiKey.token_seed_id,
        }),
      );
    },
    [history, currentProjectContainer.projectContainerId, getApiKey],
  );

  const unsavedNavigationHandler = useCallback(
    async (
      onSuccess: () => void,
      onFail: () => void,
      mapiKeyFormShape: PersonalMapiKeyFormShape,
    ): Promise<void> => {
      try {
        await dispatch(createApiKey(getApiKey(mapiKeyFormShape)));
        onSuccess();
      } catch {
        onFail();
      }
    },
    [getApiKey],
  );

  if (!allSet) {
    return <Loader />;
  }

  return (
    <AuthorizedSection
      appName={ApiKeyDetailAccessDeniedRouteSegment}
      isAuthorized={isAuthorized}
      projectId={currentProjectContainer.masterEnvironmentId}
      projectContainerId={currentProjectContainer.projectContainerId}
    >
      <PersonalMapiKeyDetailForm
        apiKey={getNewApiKey(apiKeyName, environments)}
        apiKeyActionStatus={actionStatus}
        apiKeyName={apiKeyName}
        onAlertDismiss={() => dispatch(apiKeyDetailsAlertDismissed())}
        onSave={createNewApiKey}
        onUnsavedNavigation={unsavedNavigationHandler}
      />
    </AuthorizedSection>
  );
};

const getNewApiKey = (name: string, environments: ReadonlyArray<Uuid>): ApiKey => ({
  ...emptyApiKey,
  name,
  type: ApiKeyType.MAPIPat,
  environments,
});
