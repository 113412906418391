import { ThunkPromise } from '../../../@types/Dispatcher.type.ts';
import { getVariantIdToBeEnsuredOrAccessDeniedType } from '../../../applications/contentInventory/shared/utils/getVariantIdToBeEnsuredOrAccessDeniedType.ts';
import {
  Shared_TryEnsuringSelectedLanguageId_Ensured,
  Shared_TryEnsuringSelectedLanguageId_NoLanguageAvailable,
  Shared_TryEnsuringSelectedLanguageId_Started,
} from '../../constants/sharedActionTypes.ts';
import { getNormalizedRolesWithSettings } from '../../selectors/userProjectsInfoSelectors.ts';
import { isUuid } from '../../utils/validation/typeValidators.ts';

interface IDeps {
  readonly loadCollections: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadLanguages: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly contentVariantIdStorage: {
    readonly load: () => Uuid | null;
    readonly save: (variantId: Uuid) => void;
  };
}

const ensuringStarted = () => ({ type: Shared_TryEnsuringSelectedLanguageId_Started }) as const;

const ensured = (selectedLanguageId: Uuid) =>
  ({
    type: Shared_TryEnsuringSelectedLanguageId_Ensured,
    payload: { selectedLanguageId },
  }) as const;

const noLanguageAvailable = () =>
  ({ type: Shared_TryEnsuringSelectedLanguageId_NoLanguageAvailable }) as const;

export type TryEnsureSelectedLanguageIdActionsType = ReturnType<
  typeof ensuringStarted | typeof ensured | typeof noLanguageAvailable
>;

export const createTryEnsuringSelectedVariantAction =
  (deps: IDeps) =>
  (abortSignal: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    dispatch(ensuringStarted());
    await Promise.all([
      dispatch(deps.loadLanguages(abortSignal)),
      dispatch(deps.loadCollections(abortSignal)),
    ]);

    const {
      data: { collections, languages },
    } = getState();

    const ensuredVariantIdOrAccessDenied = getVariantIdToBeEnsuredOrAccessDeniedType(
      null,
      deps.contentVariantIdStorage.load,
      languages,
      getNormalizedRolesWithSettings(getState()),
      collections,
    );

    if (isUuid(ensuredVariantIdOrAccessDenied.id)) {
      dispatch(ensured(ensuredVariantIdOrAccessDenied.id));
    } else {
      dispatch(noLanguageAvailable());
    }
  };
