import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { Callout } from '@kontent-ai/component-library/Callout';
import { documentationLinks } from '../../../../../_shared/constants/documentationLinks.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { selectIntercomUtils } from '../../../../../_shared/selectors/selectIntercomUtils.ts';
import { InnovationLabFeatureCard } from '../../components/InnovationLabFeatureCard.tsx';
import { InnovationLabFeatureCardWrapper } from '../../components/InnovationLabFeatureCardWrapper.tsx';
import { InnovationLabFeatureDialogs } from '../../components/InnovationLabFeatureDialogs.tsx';
import { InnovationLabGenericConsent } from '../../components/InnovationLabGenericConsent.tsx';
import { useFeatureActivationDialog } from '../../hooks/useFeatureActivationDialog.ts';
import { activateInnovationLabFeature } from '../../thunks/activateInnovationLabFeature.ts';
import { deactivateInnovationLabFeature } from '../../thunks/deactivateInnovationLabFeature.ts';
import { InnovationLabCardProps } from '../../types/InnovationLabCardProps.type.ts';

export const AiLinkedItemsCard = ({ state, info, name }: InnovationLabCardProps) => {
  const {
    dialogDisplayed,
    isAsyncOperationInProgress,
    onCardActionButtonClick,
    onDialogActionClick,
    onDialogClose,
  } = useFeatureActivationDialog(
    info,
    state,
    activateInnovationLabFeature('ai-enabling-linked-items'),
    deactivateInnovationLabFeature('ai-linked-items'),
  );

  const featureState = info.status;

  const intercomUtils = useSelector(selectIntercomUtils);

  return (
    <>
      <InnovationLabFeatureCardWrapper featureState={featureState}>
        <InnovationLabFeatureCard
          featureName={name}
          isActionButtonLoading={isAsyncOperationInProgress}
          onCardActionButtonClick={onCardActionButtonClick}
          activationState={state}
          onContactSupport={intercomUtils.showIntercom}
          featureState={featureState}
          renderCardBody={() => (
            <p>
              Let AI analyze your content and suggest relevant content items. Make your content
              richer and more connected by{' '}
              <OutwardLink href={documentationLinks.aiSuggestedLinkedItems}>
                linking items together
              </OutwardLink>
              .
            </p>
          )}
          renderFooterBody={() =>
            state === 'activation-pending' && (
              <Callout calloutType="friendlyWarning">
                Feature is being activated, which could take from a couple of minutes to a few days,
                depending on your project’s complexity. While this is in progress, you can continue
                your work as usual.
              </Callout>
            )
          }
        />
      </InnovationLabFeatureCardWrapper>
      <InnovationLabFeatureDialogs
        dialogFeatureName={name}
        dialogDisplayed={dialogDisplayed}
        isAsyncOperationInProgress={isAsyncOperationInProgress}
        onDialogActionClick={onDialogActionClick}
        onDialogClose={onDialogClose}
        renderActivationDialogBody={() => (
          <>
            <Callout calloutType="quickTip" hideSubheadline>
              <p>
                <b>Innovation Lab feature activation</b>
              </p>
              <p>You’re about to activate the {name} feature.</p>
              <p>
                AI can analyze your content and suggest relevant linked items. You can provide
                feedback on the suggestions you receive from AI to help us improve your experience.
              </p>
            </Callout>
            <InnovationLabGenericConsent />
          </>
        )}
      />
    </>
  );
};
