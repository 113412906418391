import { Box } from '@kontent-ai/component-library/Box';
import React, { memo } from 'react';
import { DatetimePicker } from '../../../../../../_shared/components/DatetimePicker/DatetimePicker.tsx';
import { UtcTimeZoneId } from '../../../../../../_shared/utils/dateTime/timeZoneUtils.ts';
import { DatetimePickerPlaceholder } from '../../../../../projects/constants/UIConstants.ts';
import { IDateTimeTypeElementData } from '../../../models/elements/DateTimeTypeElementData.ts';
import { ITypeElementOwnProps } from '../../../types/ITypeElementProps.type.ts';
import { DatetimeElementValidationResult } from '../../../utils/typeElementValidators/types/DatetimeElementValidationResult.type.ts';
import { TypeElementConfigurationCategory } from '../shared/TypeElementConfigurationCategory.tsx';
import { TypeElementConfigurationSection } from '../shared/TypeElementConfigurationSection.tsx';
import { TypeElementWithTypedName } from '../shared/TypeElementWithTypedName.tsx';

type DateTimeTypeElementProps = ITypeElementOwnProps<
  IDateTimeTypeElementData,
  DatetimeElementValidationResult
>;

const DateTimeTypeElement: React.FC<DateTimeTypeElementProps> = (props) => (
  <TypeElementWithTypedName {...props}>
    <TypeElementConfigurationCategory>
      <TypeElementConfigurationSection title="Default value" contentWidth="medium">
        <Box maxWidth={350}>
          <DatetimePicker
            hasError={!props.validationResult.isDefaultValueValid}
            defaultTime="0:00"
            onChange={(defaultValue) => props.onChange({ ...props.typeElementData, defaultValue })}
            placeholder={DatetimePickerPlaceholder}
            timeZoneId={UtcTimeZoneId}
            value={props.typeElementData.defaultValue.value}
          />
        </Box>
      </TypeElementConfigurationSection>
    </TypeElementConfigurationCategory>
  </TypeElementWithTypedName>
);

DateTimeTypeElement.displayName = 'DateTimeTypeElement';

const DateTimeTypeElementMemo = memo(DateTimeTypeElement);
export { DateTimeTypeElementMemo as DateTimeTypeElement };
