import { RefinedNavigation } from '@kontent-ai/component-library/RefinedNavigation';
import { FC, useContext } from 'react';
import { SubscriptionTabName } from '../../../applications/subscriptionManagement/shared/constants/subscriptionTabName.ts';
import { isStandardPlan } from '../../../applications/subscriptionManagement/shared/utils/planUtils.ts';
import {
  PlanPrepaidStatus,
  getPlanPrepaidStatus,
} from '../../../applications/subscriptionManagement/shared/utils/subscriptionPlanUtils.ts';
import {
  SubscriptionStatus,
  emptySubscription,
} from '../../../data/models/subscriptions/Subscription.ts';
import {
  AdministratorsRoute,
  BillingHistoryRoute,
  BillingInformationRoute,
  EnsuredSubscriptionRouteParams,
  PlanSelectionRoute,
  ProjectsInSubscriptionRoute,
  SubscriptionApiKeysRoute,
  SubscriptionGeneralRoute,
  SubscriptionOverviewRoute,
  SubscriptionUsersRoute,
  TransactionsRoute,
} from '../../constants/routePaths.ts';
import {
  MainNavigationContext,
  NavigationBarExpandedState,
} from '../../contexts/MainNavigationContext.tsx';
import { useSelector } from '../../hooks/useSelector.ts';
import { getSelectedSubscription } from '../../selectors/subscriptionSelectors.ts';
import {
  DataUiAppName,
  DataUiElement,
  getDataUiElementAttribute,
  getDataUiObjectNameAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { buildPath, matchPath } from '../../utils/routing/routeTransitionUtils.ts';

export const SubscriptionsMenu: FC = () => {
  const { navigationBarExpandedState } = useContext(MainNavigationContext);
  const subscription = useSelector((state) => getSelectedSubscription(state) ?? emptySubscription);
  const plan = useSelector((state) => state.data.plans.byId.get(subscription.currentPlan.planId));
  const shouldDisplayTransactions = useSelector(
    (state) => state.sharedApp.subscriptionProperties.SendFinalReport === 'Enabled',
  );

  if (!plan) {
    return null;
  }

  const {
    subscriptionDetails: { hadPrepaidPlan },
    subscriptionId,
  } = subscription;

  const isMarketplaceSubscription = !!subscription.azureMarketplaceSubscriptionId;
  const prepaidStatus = getPlanPrepaidStatus(plan, hadPrepaidPlan);

  const isBillingHistoryVisible = !plan.isTrial && !isMarketplaceSubscription;

  const isBillingInformationVisible =
    prepaidStatus !== PlanPrepaidStatus.IsPrepaid ||
    subscription.status === SubscriptionStatus.Canceled ||
    subscription.isFsSubscriptionConnected;

  const isPlansTabVisible =
    (subscription.status === SubscriptionStatus.Suspended ||
      subscription.status === SubscriptionStatus.Expired ||
      (subscription.status === SubscriptionStatus.Active &&
        (plan.isTrial || isStandardPlan(plan.name)))) &&
    !isMarketplaceSubscription;

  const isTransactionsTabVisible =
    prepaidStatus !== PlanPrepaidStatus.None &&
    !isMarketplaceSubscription &&
    shouldDisplayTransactions;

  const isApiKeysTabVisible =
    subscription.status === SubscriptionStatus.Active ||
    subscription.status === SubscriptionStatus.Suspended;

  const usageUrl = buildPath<EnsuredSubscriptionRouteParams>(SubscriptionOverviewRoute, {
    subscriptionId,
  });
  const generalUrl = buildPath<EnsuredSubscriptionRouteParams>(SubscriptionGeneralRoute, {
    subscriptionId,
  });
  const billingHistoryUrl = buildPath<EnsuredSubscriptionRouteParams>(BillingHistoryRoute, {
    subscriptionId,
  });
  const billingInformationUrl = buildPath<EnsuredSubscriptionRouteParams>(BillingInformationRoute, {
    subscriptionId,
  });
  const plansUrl = buildPath<EnsuredSubscriptionRouteParams>(PlanSelectionRoute, {
    subscriptionId,
  });
  const transactionsUrl = buildPath<EnsuredSubscriptionRouteParams>(TransactionsRoute, {
    subscriptionId,
  });
  const adminsUrl = buildPath<EnsuredSubscriptionRouteParams>(AdministratorsRoute, {
    subscriptionId,
  });
  const usersUrl = buildPath<EnsuredSubscriptionRouteParams>(SubscriptionUsersRoute, {
    subscriptionId,
  });
  const projectsUrl = buildPath<EnsuredSubscriptionRouteParams>(ProjectsInSubscriptionRoute, {
    subscriptionId,
  });
  const apiKeysUrl = buildPath<EnsuredSubscriptionRouteParams>(SubscriptionApiKeysRoute, {
    subscriptionId,
  });

  const activeItemId = getActiveItemId(location.pathname, subscriptionId);

  return (
    <>
      <RefinedNavigation
        isVisuallyHidden={navigationBarExpandedState !== NavigationBarExpandedState.Minimized}
      >
        <RefinedNavigation.Icon iconName="ShieldDollar" />
      </RefinedNavigation>
      <RefinedNavigation
        isVisuallyHidden={navigationBarExpandedState === NavigationBarExpandedState.Minimized}
      >
        <RefinedNavigation.Toggle
          iconName="ShieldDollar"
          label={SubscriptionTabName.MySubscriptions}
          toggleDisabled
          isExpanded
        >
          <RefinedNavigation.SubLink
            to={usageUrl}
            isActive={activeItemId === usageUrl}
            label={SubscriptionTabName.Usage}
            {...getDataUiObjectNameAttribute(DataUiAppName.SubscriptionUsage)}
            {...getDataUiElementAttribute(DataUiElement.NavigationTab)}
          />

          <RefinedNavigation.SubLink
            to={generalUrl}
            isActive={activeItemId === generalUrl}
            label={SubscriptionTabName.General}
            {...getDataUiObjectNameAttribute(DataUiAppName.SubscriptionGeneral)}
            {...getDataUiElementAttribute(DataUiElement.NavigationTab)}
          />

          {isBillingHistoryVisible && (
            <RefinedNavigation.SubLink
              to={billingHistoryUrl}
              isActive={activeItemId === billingHistoryUrl}
              label={SubscriptionTabName.BillingHistory}
              {...getDataUiObjectNameAttribute(DataUiAppName.SubscriptionBillingHistory)}
              {...getDataUiElementAttribute(DataUiElement.NavigationTab)}
            />
          )}

          {isBillingInformationVisible && (
            <RefinedNavigation.SubLink
              to={billingInformationUrl}
              isActive={activeItemId === billingInformationUrl}
              label={SubscriptionTabName.BillingInfo}
              {...getDataUiObjectNameAttribute(DataUiAppName.SubscriptionBillingInfo)}
              {...getDataUiElementAttribute(DataUiElement.NavigationTab)}
            />
          )}

          {isPlansTabVisible && (
            <RefinedNavigation.SubLink
              to={plansUrl}
              isActive={activeItemId === plansUrl}
              label={SubscriptionTabName.Plans}
              {...getDataUiObjectNameAttribute(DataUiAppName.SubscriptionPlans)}
              {...getDataUiElementAttribute(DataUiElement.NavigationTab)}
            />
          )}

          {isTransactionsTabVisible && (
            <RefinedNavigation.SubLink
              to={transactionsUrl}
              isActive={activeItemId === transactionsUrl}
              label={SubscriptionTabName.Transactions}
              {...getDataUiObjectNameAttribute(DataUiAppName.SubscriptionTransactions)}
              {...getDataUiElementAttribute(DataUiElement.NavigationTab)}
            />
          )}

          {subscription.status !== SubscriptionStatus.Canceled && (
            <RefinedNavigation.SubLink
              to={adminsUrl}
              isActive={activeItemId === adminsUrl}
              label={SubscriptionTabName.Admins}
              {...getDataUiObjectNameAttribute(DataUiAppName.SubscriptionAdmins)}
              {...getDataUiElementAttribute(DataUiElement.NavigationTab)}
            />
          )}

          {subscription.status !== SubscriptionStatus.Canceled && (
            <RefinedNavigation.SubLink
              to={usersUrl}
              isActive={activeItemId === usersUrl}
              label={SubscriptionTabName.Users}
              {...getDataUiObjectNameAttribute(DataUiAppName.SubscriptionUsers)}
              {...getDataUiElementAttribute(DataUiElement.NavigationTab)}
            />
          )}

          {subscription.status !== SubscriptionStatus.Canceled && (
            <RefinedNavigation.SubLink
              to={projectsUrl}
              isActive={activeItemId === projectsUrl}
              label={SubscriptionTabName.Projects}
              {...getDataUiObjectNameAttribute(DataUiAppName.SubscriptionProjects)}
              {...getDataUiElementAttribute(DataUiElement.NavigationTab)}
            />
          )}

          {isApiKeysTabVisible && (
            <RefinedNavigation.SubLink
              isActive={activeItemId === apiKeysUrl}
              to={apiKeysUrl}
              label={SubscriptionTabName.ApiKeys}
              {...getDataUiObjectNameAttribute(DataUiAppName.SubscriptionApiKeys)}
              {...getDataUiElementAttribute(DataUiElement.NavigationTab)}
            />
          )}
        </RefinedNavigation.Toggle>
      </RefinedNavigation>
    </>
  );
};

const getActiveItemId = (pathname: string, subscriptionId: string) => {
  if (matchPath(pathname, SubscriptionUsersRoute)) {
    return buildPath<EnsuredSubscriptionRouteParams>(SubscriptionUsersRoute, { subscriptionId });
  }

  return pathname;
};
