import { ComponentType } from 'react';
import {
  ElementType,
  TypeElementType,
} from '../../../contentInventory/content/models/ContentItemElementType.ts';
import { AssetTypeElementSummary } from '../containers/typeElements/summary/AssetTypeElementSummary.tsx';
import { ContentTypeSnippetTypeElementSummary } from '../containers/typeElements/summary/ContentTypeSnippetTypeElementSummary.ts';
import { DateTimeTypeElementSummary } from '../containers/typeElements/summary/DateTimeTypeElementSummary.tsx';
import { ModularContentTypeElementSummary } from '../containers/typeElements/summary/ModularContentTypeElementSummary.tsx';
import { MultipleChoiceTypeElementSummary } from '../containers/typeElements/summary/MultipleChoiceTypeElementSummary.tsx';
import { NumberTypeElementSummary } from '../containers/typeElements/summary/NumberTypeElementSummary.tsx';
import { RichTextTypeElementSummary } from '../containers/typeElements/summary/RichTextTypeElementSummary.tsx';
import { SimpleTypeElementSummary } from '../containers/typeElements/summary/SimpleTypeElementSummary.tsx';
import { TaxonomyTypeElementSummary } from '../containers/typeElements/summary/TaxonomyTypeElementSummary.tsx';
import { TextTypeElementSummary } from '../containers/typeElements/summary/TextTypeElementSummary.tsx';
import { UrlSlugTypeElementSummary } from '../containers/typeElements/summary/UrlSlugTypeElementSummary.tsx';
import { IBaseTypeElementSummaryProps } from '../types/IBaseTypeElementSummaryProps.type.ts';

const convertersMap: ReadonlyRecord<
  TypeElementType,
  ComponentType<IBaseTypeElementSummaryProps>
> = {
  [ElementType.Asset]: AssetTypeElementSummary,
  [ElementType.Subpages]: ModularContentTypeElementSummary,
  [ElementType.ContentTypeSnippet]: ContentTypeSnippetTypeElementSummary,
  [ElementType.DateTime]: DateTimeTypeElementSummary,
  [ElementType.Guidelines]: SimpleTypeElementSummary,
  [ElementType.LinkedItems]: ModularContentTypeElementSummary,
  [ElementType.MultipleChoice]: MultipleChoiceTypeElementSummary,
  [ElementType.Number]: NumberTypeElementSummary,
  [ElementType.Custom]: SimpleTypeElementSummary,
  [ElementType.RichText]: RichTextTypeElementSummary,
  [ElementType.Text]: TextTypeElementSummary,
  [ElementType.Taxonomy]: TaxonomyTypeElementSummary,
  [ElementType.UrlSlug]: UrlSlugTypeElementSummary,
};

export function getTypeElementSummaryComponent(
  type: TypeElementType,
): ComponentType<IBaseTypeElementSummaryProps> {
  return convertersMap[type];
}
