import { BaseColor, Spacing, px } from '@kontent-ai/component-library/tokens';
import styled from 'styled-components';
import { assetTileBorderRadius, assetTilePreviewHeight } from '../decisionTokens.ts';

export const StyledImageWrapper = styled.div`
  height: ${px(assetTilePreviewHeight)};
  width: 100%;
  position: absolute;
  border-top-left-radius: ${assetTileBorderRadius};
  border-top-right-radius: ${assetTileBorderRadius};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${BaseColor.White};
`;

export const StyledNonExistingImageWrapper = styled(StyledImageWrapper)`
  padding-top: ${px(Spacing.L)};
  background-color: ${BaseColor.Rose10};
`;

export const StyledNoPreviewImageWrapper = styled(StyledImageWrapper)`
  padding-top: ${px(Spacing.L)};
  background-color: ${BaseColor.OceanBlue10};
`;

export const StyledNoAccessImageWrapper = styled(StyledImageWrapper)`
  padding-top: ${px(Spacing.L)};
  background-color: ${BaseColor.Yellow10};
`;
