import { useHotkeys } from '@kontent-ai/component-library/hooks';
import React, { useRef } from 'react';
import { BarItemActionButtons } from '../../../../../_shared/components/BarItems/BarItemActionButtons.tsx';
import { BarItemExpandedSimpleHeader } from '../../../../../_shared/components/BarItems/BarItemExpandedSimpleHeader.tsx';
import {
  ContentChangesWarningModal,
  ContentChangesWarningSubtitle,
} from '../../../../../_shared/components/ModalDialog/ContentChangesWarningModal.tsx';
import { CodenameEditor } from '../../../../../_shared/components/input/CodenameEditor.tsx';
import { ValidatedInput } from '../../../../../_shared/components/input/ValidatedInput.tsx';
import { ShortcutSymbols } from '../../../../../_shared/constants/shortcutSymbols.ts';
import { ValidationConstants } from '../../../../../_shared/constants/validationConstants.ts';
import { HookFormProps } from '../../../../../_shared/types/hookFormProps.type.ts';
import {
  DataUiAction,
  DataUiElement,
  DataUiInput,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { DefaultLanguageFormShape } from '../../models/languageFormShape.type.ts';

type DefaultLanguageEditorComponentProps = {
  readonly formProps: HookFormProps<DefaultLanguageFormShape>;
  readonly languageName: string;
  readonly onCancel: () => void;
  readonly onSubmit: () => void;
  readonly onDismissLongProcessingChangesWarning: () => void;
  readonly showLargeProjectSaveWarning: boolean;
};

export const DefaultLanguageEditor: React.FC<DefaultLanguageEditorComponentProps> = ({
  formProps,
  languageName,
  onCancel,
  onSubmit,
  onDismissLongProcessingChangesWarning,
  showLargeProjectSaveWarning,
}) => {
  const [showLongProcessingChangeWarningModal, setShowLongProcessingChangeWarningModal] =
    React.useState(false);

  const onSave = () =>
    showLargeProjectSaveWarning ? setShowLongProcessingChangeWarningModal(true) : onSubmit();

  const ref = useRef<HTMLDivElement>(null);
  useHotkeys(
    {
      enter: onSave,
      escape: onCancel,
    },
    { ref },
  );

  const onConfirmationDialogSave = () => {
    setShowLongProcessingChangeWarningModal(false);
    onSubmit();
  };

  const onConfirmationDialogCancel = () => {
    setShowLongProcessingChangeWarningModal(false);
  };

  const onDiscardChanges = () => {
    setShowLongProcessingChangeWarningModal(false);
    onCancel();
  };

  return (
    <div
      className="bar-item__wrapper"
      ref={ref}
      {...getDataUiElementAttribute(DataUiElement.BarItemForm)}
    >
      <div className="bar-item__pane bar-item__pane--is-expanded">
        <BarItemExpandedSimpleHeader
          dataUiObjectName={languageName}
          isDraggable
          renderTitle={() => languageName}
        />
        <div
          className="bar-item__expansion"
          {...getDataUiElementAttribute(DataUiElement.BarItemLanguageFields)}
        >
          <ValidatedInput<DefaultLanguageFormShape>
            autoFocus
            label="Language name"
            name="name"
            maxLength={ValidationConstants.LanguageNameMaxLength}
            dataUiInputName={DataUiInput.LanguageName}
            formProps={formProps}
          />
          <div className="language-settings-form-fields">
            <div className="language-settings-form-group">
              <CodenameEditor<DefaultLanguageFormShape> formProps={formProps} name="codename" />
            </div>
            <div className="language-settings-form-group" />
          </div>
        </div>
        <BarItemActionButtons
          secondaryAction={{
            handler: onCancel,
            text: 'Cancel',
            dataUIActionName: DataUiAction.Cancel,
            shortcut: ShortcutSymbols.Escape,
          }}
          primaryAction={{
            text: 'Save',
            handler: onSave,
            dataUIActionName: DataUiAction.Save,
            shortcut: ShortcutSymbols.Enter,
            renderModalComponent: () => (
              <ContentChangesWarningModal
                onCancel={onConfirmationDialogCancel}
                onConfirm={onConfirmationDialogSave}
                onDismissWarning={onDismissLongProcessingChangesWarning}
                onDiscardChanges={onDiscardChanges}
                isOpen={showLongProcessingChangeWarningModal}
                warningSubtitle={ContentChangesWarningSubtitle.LanguageSettingsEditing}
              />
            ),
          }}
        />
      </div>
    </div>
  );
};

DefaultLanguageEditor.displayName = 'DefaultLanguageEditor';
