import { ShortcutsConfig, useHotkeys } from '@kontent-ai/component-library/hooks';
import { ContentState } from 'draft-js';
import { useRef, useState } from 'react';
import { LabelFor } from '../../../../../../../_shared/uiComponents/LabelFor/LabelFor.tsx';
import {
  DataUiInput,
  getDataUiInputAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { isEmptyOrWhitespace } from '../../../../../../../_shared/utils/stringUtils.ts';
import { IFocusable } from '../../../../../../richText/plugins/behavior/FocusPlugin.tsx';
import { Resettable } from '../../../../../../richText/plugins/behavior/ManualResetPlugin.tsx';
import { DebouncedChanges } from '../../../../../../richText/plugins/behavior/OnChangePlugin.tsx';
import { emptyEditorState } from '../../../../../../richText/utils/general/editorEmptyValues.ts';
import { CommentInput } from '../../../containers/comments/input/CommentInput.tsx';
import { NewCommentThreadActionsBar } from './NewCommentThreadActionsBar.tsx';

type Props = {
  readonly autoFocus?: boolean;
  readonly isSubmitting: boolean;
  readonly onSubmit: (commentContent: ContentState) => Promise<void>;
};

export const NewCommentThread = (props: Props) => {
  const [newComment, setNewComment] = useState(emptyEditorState);

  const focusableRef = useRef<IFocusable>(null);
  const resettableRef = useRef<Resettable>(null);
  const debouncedChangesRef = useRef<DebouncedChanges>(null);

  const resetCommentInput = (): void => {
    resettableRef.current?.resetInput(emptyEditorState.getCurrentContent());
    setNewComment(emptyEditorState);
  };

  const submit = async (): Promise<void> => {
    await debouncedChangesRef.current?.propagatePendingContentChanges();
    await props.onSubmit(newComment.getCurrentContent());

    resetCommentInput();
    focus();
  };

  const cancelEditing = (): void => {
    resetCommentInput();
  };

  const ref = useRef<HTMLFormElement>(null);
  useHotkeys(
    {
      [ShortcutsConfig.ControlEnter]: submit,
    },
    { ref },
  );

  return (
    <form
      className="form comment-editor__form comment-editor__form--is-on-discussion-tab"
      onSubmit={(event) => {
        event.stopPropagation();
        event.preventDefault();
        submit();
      }}
      ref={ref}
    >
      <LabelFor
        isHidden
        target={() => (
          <CommentInput
            autoFocus={props.autoFocus ?? true}
            debouncedChangesRef={debouncedChangesRef}
            disabled={props.isSubmitting}
            editorState={emptyEditorState}
            focusableRef={focusableRef}
            onContentChange={(editorState) => {
              setNewComment(editorState);
              return Promise.resolve();
            }}
            onEscape={() => {
              cancelEditing();
              blur();
            }}
            resettableRef={resettableRef}
            {...getDataUiInputAttribute(DataUiInput.Comment)}
          />
        )}
      >
        Comment
      </LabelFor>
      <NewCommentThreadActionsBar
        isSubmitting={props.isSubmitting}
        onCancelEditing={cancelEditing}
        isSubmitButtonDisabled={
          isEmptyOrWhitespace(newComment.getCurrentContent().getPlainText()) || props.isSubmitting
        }
      />
    </form>
  );
};
