import { addDefaultLanguageSuffix } from '../../applications/environmentSettings/localization/utils/languageUtils.ts';
import { DefaultVariantId } from '../constants/variantIdValues.ts';
import { IStore } from '../stores/IStore.type.ts';
import { getLanguage } from '../utils/languageUtils.ts';

export const getLanguageName = (
  state: IStore,
  languageId: Uuid | null | undefined,
): string | null => {
  if (!languageId) {
    return null;
  }

  const language = getLanguage(state.data.languages, languageId);

  if (!language) {
    return null;
  }

  return languageId === DefaultVariantId ? addDefaultLanguageSuffix(language).name : language.name;
};
