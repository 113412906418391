import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { SidebarNames } from '../../../../../_shared/models/SidebarNames.ts';
import { getSelectedSubscription } from '../../../../../_shared/selectors/subscriptionSelectors.ts';
import { getSubscriptionAdministrators } from '../../../../../_shared/utils/subscription/subscriptionUtils.ts';
import {
  getNumberOfUsersInActivation,
  hasSubscriptionAvailableUserSlots,
} from '../../../../../_shared/utils/subscriptionUsageUtils.ts';
import { createFormValidationResolver } from '../../../../../_shared/utils/validation/createFormValidationResolver.ts';
import { cancelInvitingNewAdmin, showPromoteUsersModal } from '../../actions/adminsActions.ts';
import { inviteNewAdmin } from '../../actions/thunkAdminsActions.ts';
import { ISubscriptionAdminInvitationFormShape } from '../../components/invitationModal/SubscriptionAdminInvitationForm.tsx';
import { SubscriptionAdminInvitationModal } from '../../components/invitationModal/SubscriptionAdminsInvitationModal.tsx';
import { newAdminInvitationValidationConfig } from '../../validation/newAdminInvitationFormValidation.ts';

const SubscriptionAdminsInvitationModalContainer: React.FC = () => {
  const subscriptionId = useSelector(
    (state) => getSelectedSubscription(state)?.subscriptionId ?? '',
  );

  const canInviteNewAdmin = useSelector((state) => {
    const { subscriptionUsages } = state.data.subscriptions;
    const { plans } = state.data;
    const numberOfUsersInActivation = getNumberOfUsersInActivation(state);

    return hasSubscriptionAvailableUserSlots(
      subscriptionUsages,
      subscriptionId,
      plans.byId,
      numberOfUsersInActivation,
    );
  });

  const errorMessage = useSelector(
    (state) => state.subscriptionApp.admins.createNewAdminErrorMessage,
  );

  const showSidebar = useSelector((state) => {
    const { isVisible, sidebarName } = state.sharedApp.sidebarStatus;

    return isVisible && sidebarName === SidebarNames.SubscriptionAdminInvitationModal;
  });

  const subscriptionUsers = useSelector((state) => state.data.subscriptions.subscriptionUsers);

  const dispatch = useDispatch();

  const onCloseDialog = () => dispatch(cancelInvitingNewAdmin());
  const onShowPromoteUsersModal = () => dispatch(showPromoteUsersModal());

  const existingAdmins = useSelector((state) => {
    const { subscriptionAdministrators } = state.data.subscriptions;

    return getSubscriptionAdministrators(subscriptionAdministrators, subscriptionId);
  });

  const formProps = useForm<ISubscriptionAdminInvitationFormShape>({
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
    },
    resolver: createFormValidationResolver(newAdminInvitationValidationConfig, { existingAdmins }),
  });

  const isSubmittingSubscriptionAdmin = useSelector((state) => {
    const { promotingSubscriptionAdmins, sendingSubscriptionAdminInvitation } =
      state.subscriptionApp.admins;

    return promotingSubscriptionAdmins || sendingSubscriptionAdminInvitation;
  });

  const { handleSubmit, watch } = formProps;
  const email = watch('email');

  const onSubmit = handleSubmit((values) => {
    const newAdmin = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
    };

    return dispatch(inviteNewAdmin(subscriptionId || '', newAdmin));
  });

  return (
    <SubscriptionAdminInvitationModal
      canInviteNewAdmin={canInviteNewAdmin}
      email={email}
      errorMessage={errorMessage}
      formProps={formProps}
      isSubmittingSubscriptionAdmin={isSubmittingSubscriptionAdmin}
      onCloseDialog={onCloseDialog}
      onShowPromoteUsersModal={onShowPromoteUsersModal}
      onSubmit={onSubmit}
      showSidebar={showSidebar}
      subscriptionId={subscriptionId}
      subscriptionUsers={subscriptionUsers}
    />
  );
};

SubscriptionAdminsInvitationModalContainer.displayName =
  'SubscriptionAdminsInvitationModalContainer';

export { SubscriptionAdminsInvitationModalContainer as SubscriptionAdminInvitationModal };
