import styled from 'styled-components';
import { assetTileBorderRadius } from '../decisionTokens.ts';

export const StyledAssetTile = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: inherit;
  border-radius: ${assetTileBorderRadius};
`;
