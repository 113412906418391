import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import {
  IWebSpotlightPreviewResolutionTypeOption,
  WebSpotlightPreviewResolutionType,
} from '../../../../../../../../webSpotlight/models/webSpotlightPreviewResolutionType.ts';

type Props = Readonly<{
  isSelected: boolean;
  onChange: (type: WebSpotlightPreviewResolutionType) => void;
  option: IWebSpotlightPreviewResolutionTypeOption;
}>;

export const ResolutionTypeButton = ({ isSelected, onChange, option }: Props) => (
  <QuinaryButton
    activated={isSelected}
    aria-label={option.label}
    key={option.id}
    onClick={() => onChange(option.id)}
    tooltipPlacement="top"
    tooltipText={
      option.complementaryText ? `${option.label} (${option.complementaryText})` : `${option.label}`
    }
  >
    <QuinaryButton.Icon icon={Icons[option.iconName]} />
  </QuinaryButton>
);
