import React from 'react';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { newContentGroupCreated } from '../../actions/contentTypesActions.ts';
import { ContentTypeContentGroupTabs as ContentTypeContentGroupTabsComponent } from '../../components/ContentGroups/ContentTypeContentGroupTabs.tsx';

export const ContentTypeContentGroupTabs: React.FC = () => {
  const contentTypeGroups = useSelector(
    (state) => state.contentModelsApp.typeEditor.editedType.contentGroups,
  );
  const isDragging = useSelector(
    (state) => !!state.contentModelsApp.contentTypes.editor.draggedContentGroupId,
  );

  const dispatch = useDispatch();
  const createContentGroup = (contentGroupName: string) =>
    dispatch(newContentGroupCreated(contentGroupName));

  return (
    <ContentTypeContentGroupTabsComponent
      contentTypeGroups={contentTypeGroups}
      isDragging={isDragging}
      onCreateContentGroup={createContentGroup}
    />
  );
};

ContentTypeContentGroupTabs.displayName = 'ContentTypeContentGroupTabs';
