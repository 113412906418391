import {
  ElementType,
  TypeElementType,
} from '../../../../../../../../contentInventory/content/models/ContentItemElementType.ts';

export const isConditionConfigurationAvailableForElementType = (type: TypeElementType): boolean =>
  ConditionConfigurationEnabledElementTypes.includes(type);

const ConditionConfigurationEnabledElementTypes: ReadonlyArray<ElementType> = [
  ElementType.Asset,
  ElementType.Custom,
  ElementType.DateTime,
  ElementType.LinkedItems,
  ElementType.MultipleChoice,
  ElementType.Number,
  ElementType.RichText,
  ElementType.Taxonomy,
  ElementType.Text,
  ElementType.UrlSlug,
];
