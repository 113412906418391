import { ContentBlock } from 'draft-js';
import React, { memo } from 'react';
import { DeferAutoScrollCssClass } from '../../../../../../_shared/utils/autoScrollUtils.ts';
import { ContentComponentContextProvider } from '../../../../../itemEditor/features/ContentComponent/context/ContentComponentContext.tsx';
import { ContentOverlay } from '../../../../../itemEditor/features/LinkedItems/components/ContentOverlay.tsx';
import {
  getContentOverlayClass,
  getContentOverlayId,
} from '../../../../editorCore/utils/editorComponentUtils.ts';
import { ExpandedBlockStyles } from '../../../customBlocks/components/ExpandedBlockStyles.tsx';
import { getContentComponentId } from '../../api/editorContentComponentUtils.ts';
import { ContentComponentItemElements } from '../../containers/expanded/elements/ContentComponentItemElements.tsx';

interface IContentComponentBlockProps {
  readonly block: ContentBlock;
  readonly disabled: boolean;
  readonly editorId: string;
  readonly isDragging: boolean;
  readonly isHighlighted: boolean;
  readonly newComponentBlockKey: string | null;
  readonly onMount: () => void;
  readonly shouldAutoFocus: boolean;
}

const ContentComponentBlock: React.FC<IContentComponentBlockProps> = ({
  block,
  disabled,
  editorId,
  isDragging,
  isHighlighted,
  onMount,
  shouldAutoFocus,
}) => {
  const blockKey = block.getKey();

  const contentComponentId = getContentComponentId(block);
  if (!contentComponentId) {
    return null;
  }

  // We need to disable mouse interaction of ContentOverlay in order to prevent incorrect evaluation of mouse position
  // due to different DOM placement. This is needed for dragging and making selection with a mouse
  const ignoreMouseEvents = isDragging;

  return (
    <ContentOverlay
      className={getContentOverlayClass(editorId)}
      overlayId={getContentOverlayId(editorId, blockKey)}
      pendingClassName={DeferAutoScrollCssClass}
      ignoreMouseEvents={ignoreMouseEvents}
      renderContent={(ref) => (
        <ContentComponentContextProvider contentComponentId={contentComponentId}>
          <ContentComponentItemElements
            autoFocus={shouldAutoFocus}
            contentComponentId={contentComponentId}
            disabled={disabled}
            isHighlighted={isHighlighted}
            onMount={onMount}
            ref={ref}
          />
          <ExpandedBlockStyles editorId={editorId} blockKey={blockKey} />
        </ContentComponentContextProvider>
      )}
    />
  );
};

ContentComponentBlock.displayName = 'ContentComponentBlock';

const ContentComponentBlockMemo = memo(ContentComponentBlock);

export { ContentComponentBlockMemo as ContentComponentBlock };
