import { MouseEventHandler } from 'react';
import { trackUserEvent } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import {
  CodenameEventType,
  CodenameTargetType,
} from '../../../../../_shared/models/TrackUserEventData.ts';
import { IAsset } from '../../../../../data/models/assets/Asset.ts';
import { assetCodenameChanged } from '../../actions/assetLibraryActions.ts';
import { AssetCodenameDialog as AssetCodenameDialogComponent } from '../../components/AssetEditing/AppBar/AssetCodenameDialog.tsx';
import {
  getAssetCodenamesWithoutEditedAsset,
  isCodenameUnique,
} from '../../utils/assetCodenameUtils.ts';

type Props = {
  readonly asset: IAsset;
  readonly onClick: MouseEventHandler<HTMLElement>;
  readonly onCopyCompleted: () => void;
};

export const AssetCodenameDialog = ({ asset, onCopyCompleted, onClick }: Props) => {
  const dispatch = useDispatch();
  const relatedCodenames = useSelector((s) =>
    getAssetCodenamesWithoutEditedAsset(s.data.assets.byId, asset),
  );

  return (
    <AssetCodenameDialogComponent
      codename={asset.codename}
      isCodenameUnique={(codename) => isCodenameUnique(codename, relatedCodenames)}
      onCodenameSave={(codename, onSaved) => {
        dispatch(assetCodenameChanged(codename));
        onSaved();
      }}
      onClick={onClick}
      onCopyCompleted={onCopyCompleted}
      onCopied={() =>
        dispatch(
          trackUserEvent(TrackedEvent.Codename, {
            type: CodenameEventType.Copy,
            target: CodenameTargetType.Asset,
          }),
        )
      }
      onEdited={() =>
        dispatch(
          trackUserEvent(TrackedEvent.Codename, {
            type: CodenameEventType.Edit,
            target: CodenameTargetType.Asset,
          }),
        )
      }
    />
  );
};
