import { memoize } from '@kontent-ai/memoization';
import { getCurrentProjectPlan } from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { IPlan } from '../../../../data/models/plans/Plan.ts';
import { IRole } from '../../../../data/models/roles/IRole.ts';
import { getCustomRolesCount } from '../utils/customRolesUtils.ts';

export interface ICustomRolesLimitInfo {
  readonly customRoleLimit: number | null;
  readonly customRoleCount: number;
}

export const getCustomRolesLimitInfoFromPlan = memoize.maxOne(
  (plan: IPlan, roles: ReadonlyMap<Uuid, IRole>): ICustomRolesLimitInfo => {
    const customRoleCount = getCustomRolesCount(roles);
    const customRoleLimit = plan.features.maxCustomRoles;

    return {
      customRoleLimit,
      customRoleCount,
    };
  },
);

export const getCustomRolesLimitInfo = (state: IStore): ICustomRolesLimitInfo => {
  const {
    data: {
      roles: { rolesById },
    },
  } = state;

  const projectPlan = getCurrentProjectPlan(state);

  return getCustomRolesLimitInfoFromPlan(projectPlan, rolesById);
};
