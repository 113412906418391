import { createGuid, identity, noOperation } from '@kontent-ai/utils';
import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { DragSource } from '../../../../../../../_shared/components/DragDrop/DragSource.tsx';
import { DropTarget } from '../../../../../../../_shared/components/DragDrop/DropTarget.tsx';
import { DragMoveHandler } from '../../../../../../../_shared/components/DragDrop/dragDrop.type.ts';
import { DndTypes } from '../../../../../../../_shared/constants/dndTypes.ts';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { useCrossedHalfTargetHeight } from '../../../../../../../_shared/utils/dragDrop/hoveringCollisionStrategies.ts';
import { ElementType } from '../../../../../../contentInventory/content/models/ContentItemElementType.ts';
import { ElementLinkedItem } from '../../../../containers/typeElements/linkedItems/ElementLinkedItem.tsx';

interface ILinkedItemsListOwnProps {
  readonly contentItemIds: ReadonlyArray<Uuid>;
  readonly elementId: Uuid;
  readonly elementType: ElementType;
  readonly isDisabled: boolean;
  readonly onRelatedEntryDeleted: (contentItemId: Uuid) => void;
  readonly onRelatedEntryMoved: DragMoveHandler;
}

interface ILinkedItemsListProps extends ILinkedItemsListOwnProps {}

export const LinkedItemsList: React.FC<ILinkedItemsListProps> = ({
  contentItemIds,
  elementId,
  elementType,
  isDisabled,
  onRelatedEntryDeleted,
  onRelatedEntryMoved,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const { current: componentId } = useRef<Uuid>(createGuid());

  const linkedItemsDndIdentifier = `${DndTypes.Related_Content_Item_Dnd_Identifier}_ ${componentId}`;
  const displayDragButton = contentItemIds.length > 1;

  const hoveringCollisionStrategy = useCrossedHalfTargetHeight(contentItemIds, identity);

  return (
    <div
      className={classNames('bar-item__list', {
        'bar-item__list--is-dragging': isDragging,
      })}
      {...getDataUiCollectionAttribute(DataUiCollection.ContentModuleListing)}
    >
      {contentItemIds.map((contentItemId: Uuid) => (
        <DropTarget<HTMLDivElement>
          accept={linkedItemsDndIdentifier}
          canDrop={!isDisabled}
          hoveringCollisionStrategy={hoveringCollisionStrategy}
          key={contentItemId}
          onMove={onRelatedEntryMoved}
          parentId={elementId}
          renderDroppable={(ref) => (
            <div className="bar-item__drag-block" ref={ref}>
              <DragSource
                sourceId={contentItemId}
                parentId={elementId}
                type={linkedItemsDndIdentifier}
                onDragStart={() => setIsDragging(true)}
                onDragEnd={() => setIsDragging(false)}
                renderDraggable={(connectDragSource, isBeingDragged) => (
                  <ElementLinkedItem
                    connectDragSource={connectDragSource}
                    contentItemId={contentItemId}
                    displayDragButton={displayDragButton}
                    elementId={elementId}
                    elementType={elementType}
                    isDisabled={isDisabled}
                    isDragging={isBeingDragged}
                    onDelete={onRelatedEntryDeleted}
                  />
                )}
                renderPreview={() => (
                  <ElementLinkedItem
                    contentItemId={contentItemId}
                    displayDragButton
                    elementId={elementId}
                    elementType={elementType}
                    isDisabled={false}
                    isDragging={false}
                    onDelete={noOperation}
                  />
                )}
              />
            </div>
          )}
          targetId={contentItemId}
        />
      ))}
    </div>
  );
};

LinkedItemsList.displayName = 'LinkedItemsList';
