import { Box } from '@kontent-ai/component-library/Box';
import { Clipboard } from '@kontent-ai/component-library/Clipboard';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, Typography, colorTextDefault } from '@kontent-ai/component-library/tokens';
import { useEffect } from 'react';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { getSelectedLanguageId } from '../../../_shared/selectors/getSelectedLanguageId.ts';
import { getCurrentProjectId } from '../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { LabelFor } from '../../../_shared/uiComponents/LabelFor/LabelFor.tsx';
import {
  DataUiInput,
  getDataUiInputAttribute,
} from '../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getSharedWebSpotlightPreviewSmartLink } from '../../smartLink/utils/smartLinkUtils.ts';
import { useWebSpotlight } from '../context/WebSpotlightContext.tsx';
import {
  WebSpotlightPreviewResolutionType,
  webSpotlightPreviewResolutionTypeLabels,
} from '../models/webSpotlightPreviewResolutionType.ts';

export const WebSpotlightSharePreviewForm = () => {
  const {
    currentPreviewUrl,
    previewIFrameResolution,
    previewIFrameResolutionType,
    requestPreviewIFrameCurrentUrl,
    resetPreviewIFrameCurrentUrl,
  } = useWebSpotlight();

  const projectId = useSelector(getCurrentProjectId);
  const variantId = useSelector(getSelectedLanguageId);
  const spaceId = useSelector((s) => s.webSpotlightApp.spaceId);
  const previewContentItemInfo = useSelector(
    (s) => s.webSpotlightApp.itemPreviewInfo?.previewContentItemInfo,
  );

  useEffect(() => {
    requestPreviewIFrameCurrentUrl();
    return () => {
      resetPreviewIFrameCurrentUrl();
    };
  }, [requestPreviewIFrameCurrentUrl, resetPreviewIFrameCurrentUrl]);

  if (!variantId || !spaceId || !previewContentItemInfo) {
    return null;
  }

  const link = getSharedWebSpotlightPreviewSmartLink(
    projectId,
    variantId,
    spaceId,
    previewContentItemInfo.contentItemId,
    currentPreviewUrl,
    previewIFrameResolutionType !== WebSpotlightPreviewResolutionType.FitScreen
      ? previewIFrameResolution
      : null,
  );

  const resolutionTypeLabel = webSpotlightPreviewResolutionTypeLabels[previewIFrameResolutionType];

  return (
    <Box paddingX={Spacing.M} paddingY={Spacing.S} paddingRight={Spacing.XL}>
      <Stack spacing={Spacing.L}>
        <LabelFor
          target={(inputId: Uuid) => (
            <Clipboard id={inputId} value={link} {...getDataUiInputAttribute(DataUiInput.Url)} />
          )}
        >
          Share Web Spotlight preview
        </LabelFor>
        <Stack spacing={Spacing.S}>
          <Box typography={Typography.HeadlineMedium} color={colorTextDefault}>
            {resolutionTypeLabel}
          </Box>
          {previewIFrameResolutionType !== WebSpotlightPreviewResolutionType.FitScreen && (
            <Box typography={Typography.BodyMedium} color={colorTextDefault}>
              Resolution: {Math.floor(previewIFrameResolution.width)} x{' '}
              {Math.floor(previewIFrameResolution.height)} px
            </Box>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};
