import { assert } from '@kontent-ai/utils';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { createFormValidationResolver } from '../../../../../_shared/utils/validation/createFormValidationResolver.ts';
import {
  ISitemap,
  ISitemapNode,
} from '../../../../../data/models/contentModelsApp/sitemap/Sitemap.ts';
import { cancelNodeEditing } from '../../actions/sitemapActions.ts';
import { confirmNodeEditing, deleteNode } from '../../actions/thunkSitemapActions.ts';
import { NodeEditor } from '../../components/nodeEditors/NodeEditor.tsx';
import { ISitemapFormShape } from '../../models/ISitemapFormShape.type.ts';
import { sitemapValidationConfig } from '../../validation/sitemapValidation.ts';

interface IOwnProps {
  readonly nodeId: Uuid;
}

const getSitemapOrThrow = (state: IStore): ISitemap => {
  const { sitemap } = state.sitemapApp;

  assert(sitemap, () => 'Sitemap is not defined. Make sure not to render this component.');

  return sitemap;
};

const NodeEditorContainer: React.FC<IOwnProps> = ({ nodeId }) => {
  const node: ISitemapNode | undefined = useSelector((s) => getSitemapOrThrow(s).nodes.get(nodeId));

  const dispatch = useDispatch();
  const onDelete = () => dispatch(deleteNode(nodeId));
  const onCancelEditing = () => dispatch(cancelNodeEditing(nodeId));

  const formProps = useForm<ISitemapFormShape>({
    defaultValues: {
      name: node?.name,
      codename: node?.codename,
    },
    resolver: createFormValidationResolver(sitemapValidationConfig, {}),
  });

  const { handleSubmit, watch } = formProps;
  const name = watch('name');

  if (!node) {
    // When nodes are removed very quickly this situation may occur
    return null;
  }

  const submitForm = handleSubmit((values) => dispatch(confirmNodeEditing(nodeId, values)));

  return (
    <NodeEditor
      formProps={formProps}
      name={name}
      onCancelEditing={onCancelEditing}
      onSubmit={submitForm}
      onDelete={onDelete}
    />
  );
};

NodeEditorContainer.displayName = 'NodeEditorContainer';
export { NodeEditorContainer as NodeEditor };
