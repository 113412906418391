import { Box } from '@kontent-ai/component-library/Box';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { Spacing, Typography, colorTextDefault } from '@kontent-ai/component-library/tokens';
import classNames from 'classnames';
import React from 'react';
import {
  FeatureState,
  FeatureStateTag,
} from '../../../../../_shared/components/FeatureStateTag.tsx';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface IProps {
  readonly children: JSX.Element;
  readonly collectionName: DataUiCollection;
  readonly featureState?: FeatureState;
  readonly noHorizontalPadding?: boolean;
  readonly renderFilterCategoryStatus?: () => JSX.Element | null;
  readonly title: string;
  readonly titleTooltipText?: string;
}

export const ListingFilterCategory: React.FC<IProps> = (props) => {
  return (
    <div
      className={classNames(
        'facet__category',
        props.noHorizontalPadding && 'facet__category--no-horizontal-padding',
      )}
      {...getDataUiCollectionAttribute(props.collectionName)}
    >
      <Tooltip text={props.titleTooltipText} placement="right">
        <Box alignItems="center" display="inline-flex" paddingBottom={Spacing.S}>
          <Box typography={Typography.LabelLarge} color={colorTextDefault}>
            {props.title}
            {props.featureState && <FeatureStateTag featureState={props.featureState} />}
          </Box>
          {props.renderFilterCategoryStatus && (
            <Box paddingLeft={Spacing.S}>{props.renderFilterCategoryStatus()}</Box>
          )}
        </Box>
      </Tooltip>
      {props.children}
    </div>
  );
};

ListingFilterCategory.displayName = 'ListingFilterCategory';
