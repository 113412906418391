import { getAbsoluteTopOffset } from '@kontent-ai/DOM';
import { InvariantException } from '@kontent-ai/errors';
import { memoize } from '@kontent-ai/memoization';
import Immutable from 'immutable';
import { ICompiledContentType } from '../../../../contentInventory/content/models/CompiledContentType.ts';
import {
  CompiledTypeElementType,
  ElementType,
} from '../../../../contentInventory/content/models/ContentItemElementType.ts';
import { CommentThreadItemType } from '../../../models/comments/CommentThreadItem.ts';
import {
  CommentThreadType,
  ICommentThread,
  IInlineCommentThread,
} from '../../../models/comments/CommentThreads.ts';
import { getTypeElement } from '../../../stores/utils/contentItemElementsUtils.ts';
import {
  CommentThreadWithLocation,
  isThreadInline,
  isThreadResolved,
  isThreadResolvedWithoutUndo,
  isThreadSaved,
} from '../../../utils/commentUtils.ts';
import { getItemElementCommentManager } from '../../../utils/getItemElementCommentManager.ts';

export const isDisplayedInlineCommentThread = (
  thread: ICommentThread,
): thread is IInlineCommentThread => isThreadInline(thread) && !isThreadResolvedWithoutUndo(thread);

export const isUnresolvedSavedInlineCommentThread = (
  thread: ICommentThread,
): thread is IInlineCommentThread =>
  !isThreadResolved(thread) && isThreadInline(thread) && isThreadSaved(thread);

const typesWithCommentPossibility: ReadonlyArray<CompiledTypeElementType> = [
  ElementType.Number,
  ElementType.Taxonomy,
  ElementType.MultipleChoice,
  ElementType.DateTime,
  ElementType.Custom,
];

export const areCommentSupported = (elementType: CompiledTypeElementType): boolean => {
  return typesWithCommentPossibility.includes(elementType);
};

export const getNewCommentThreadItemType = (
  newCommentThreadItemTypeMapping: Immutable.Map<string, CommentThreadItemType>,
  commentThreadId: Uuid,
): CommentThreadItemType | undefined => newCommentThreadItemTypeMapping.get(commentThreadId);

export const getCommentThreadType = (elementType: CompiledTypeElementType): CommentThreadType => {
  switch (elementType) {
    case ElementType.Asset:
      return CommentThreadType.Asset;
    case ElementType.Number:
      return CommentThreadType.Number;
    case ElementType.Taxonomy:
      return CommentThreadType.Taxonomy;
    case ElementType.MultipleChoice:
      return CommentThreadType.MultipleChoice;
    case ElementType.DateTime:
      return CommentThreadType.DateTime;
    case ElementType.Custom:
      return CommentThreadType.CustomElement;
    default:
      throw InvariantException(
        `Content element of ${elementType} doesn’t support element comments.`,
      );
  }
};

export type GetCommentThreadPosition = (
  commentThread: IInlineCommentThread,
  scrollParent: HTMLElement | null,
) => number | null;

export const createGetCommentThreadPosition =
  (loadedContentItemTypes: Immutable.Map<Uuid, ICompiledContentType>): GetCommentThreadPosition =>
  (commentThread, scrollParent): number | null => {
    const element = getTypeElement(loadedContentItemTypes, commentThread.elementId);
    if (!element) return null;

    const commentManager = getItemElementCommentManager(element.type);
    const triggerCssSelector = commentManager.getCommentTriggerCssSelector(commentThread);
    if (!triggerCssSelector) return null;

    const triggerElement = document.querySelector<HTMLElement>(triggerCssSelector) ?? null;

    return triggerElement && getAbsoluteTopOffset(triggerElement, scrollParent);
  };

export const isCommentThreadActive = memoize.weak(
  (thread: CommentThreadWithLocation, focusedCommentThreadId: Uuid | null) => {
    const commentThread = thread.commentThread;

    return (
      !isThreadSaved(commentThread) ||
      commentThread.id === focusedCommentThreadId ||
      commentThread.isInUndoResolvedState ||
      commentThread.isReplying ||
      commentThread.isSubmitting ||
      commentThread.threadItems.some((item) => item.isEditing || item.isSubmitting)
    );
  },
);

export const getActiveCommentThreads = memoize.weak(
  <T extends CommentThreadWithLocation>(
    threads: ReadonlyArray<T>,
    focusedCommentThreadId: Uuid | null,
  ): ReadonlyArray<T> =>
    threads.filter((thread) => isCommentThreadActive(thread, focusedCommentThreadId)),
);
