import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { forwardRef } from 'react';
import { getDropDown } from '../../../../_shared/components/DropDown.tsx';
import { EnterHotkeyDisabler } from '../../../../_shared/components/Hotkeys/EnterHotkeyDisabler.tsx';
import { IProjectLocation } from '../../../../_shared/models/ProjectLocation.ts';
import { DataUiCollection } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type Props = {
  readonly availableProjectLocations: ReadonlyArray<IProjectLocation> | null;
  readonly disabled: boolean | undefined;
  readonly dropdownId: string;
  readonly onSelectionChanged: (projectLocationId: string) => void;
  readonly selectedProjectLocationId: string;
  readonly subscriptionName: string | undefined;
};

const DropDown = getDropDown<IProjectLocation>();

export const ProjectLocationSelector = forwardRef<HTMLDivElement, Props>(
  (
    {
      availableProjectLocations,
      disabled,
      dropdownId,
      onSelectionChanged,
      selectedProjectLocationId,
      subscriptionName,
    },
    ref,
  ): JSX.Element | null => {
    if (!availableProjectLocations) {
      return null;
    }

    const selectedOption = availableProjectLocations.find(
      (projectLocation) =>
        !!projectLocation && projectLocation.projectLocationId === selectedProjectLocationId,
    );

    return (
      <EnterHotkeyDisabler>
        <Tooltip
          text={
            disabled
              ? `Subscription ${subscriptionName} does not allow to select a data center`
              : undefined
          }
          placement="bottom"
        >
          <div>
            <DropDown
              ref={ref}
              id={dropdownId}
              className="combo-box"
              onSelect={(projectLocation) => onSelectionChanged(projectLocation.projectLocationId)}
              renderOptionName={(projectLocation) => projectLocation?.projectLocationName}
              getOptionId={(projectLocation) => projectLocation.projectLocationId}
              options={availableProjectLocations}
              disabled={disabled}
              selectedOption={selectedOption}
              dataUiCollectionName={DataUiCollection.ProjectLocations}
            />
          </div>
        </Tooltip>
      </EnterHotkeyDisabler>
    );
  },
);

ProjectLocationSelector.displayName = 'ProjectLocationSelector';
