import { Capability, getUserCapability } from '../../../_shared/utils/permissions/capability.ts';
import { isProjectManager } from '../../../_shared/utils/permissions/isProjectManager.ts';
import { IPlan } from '../../../data/models/plans/Plan.ts';
import { IUserProjectInfo } from '../../../data/models/user/UserProjectInfo.ts';
import { IUser } from '../../../data/reducers/user/IUser.type.ts';
import { isWebSpotlightIncludedInPlan } from './isWebSpotlightIncludedInPlan.ts';

export const isEnvironmentGeneralSettingsTabVisible = (
  user: IUser,
  projectInfo: IUserProjectInfo,
  currentProjectPlan: IPlan,
  isWebSpotlightEnabled: boolean,
): boolean =>
  canManageWebSpotlight(user, projectInfo, currentProjectPlan, isWebSpotlightEnabled) ||
  canManageEnvironment(projectInfo) ||
  canManageApiKey(projectInfo) ||
  canActivateApiStatus(user, projectInfo);

export const canManageEnvironment = (projectInfo: IUserProjectInfo): boolean =>
  getUserCapability(projectInfo).can(Capability.ManageEnvironments);

export const canManageApiKey = (projectInfo: IUserProjectInfo): boolean =>
  getUserCapability(projectInfo).can(Capability.ManagePersonalApiKey);

export const canManageWebSpotlight = (
  user: IUser,
  projectInfo: IUserProjectInfo,
  currentProjectPlan: IPlan,
  isWebSpotlightEnabled: boolean,
): boolean =>
  (isWebSpotlightIncludedInPlan(currentProjectPlan) || isWebSpotlightEnabled) &&
  isProjectManager(user, projectInfo);

export const canActivateApiStatus = (user: IUser, projectInfo: IUserProjectInfo): boolean =>
  isProjectManager(user, projectInfo);
