import { assert } from '@kontent-ai/utils';
import { Dispatch, GetState, ThunkPromise } from '../../../../../../../@types/Dispatcher.type.ts';
import { selectWorkflowStep } from '../../../../../../../_shared/features/ChangeWorkflowStepModal/actions/changeWorkflowStepModalActions.ts';
import { getWorkflowActionForUpdateStep } from '../../../../../../../_shared/features/ChangeWorkflowStepModal/utils/changeWorkflowStepModalActionUtils.ts';
import {
  getWorkflow,
  getWorkflowStep,
} from '../../../../../../../_shared/selectors/workflowSelectors.ts';
import { isPublishedWorkflowStep } from '../../../../../../../_shared/utils/contentItemUtils.ts';
import { isBulkPublishAllowed } from '../../../../../../../_shared/utils/validation/isBulkPublishAllowed.ts';
import { IAssignmentWorkflowStep } from '../../../../../../../data/models/workflow/WorkflowStep.ts';
import { SelectedItemsCannotBePublished } from '../../../../../../itemEditor/constants/errorMessages.ts';

interface IChangeWorkflowActionDeps {
  readonly checkPublishWarnings: () => ThunkPromise;
}

export const createSelectWorkflowStepForListingAction =
  (deps: IChangeWorkflowActionDeps) =>
  (workflowId: Uuid, selectedStepId: Uuid): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const state = getState();
    const {
      changeWorkflowStepModalData: { scheduleMethod },
      listingUi,
    } = state.contentApp;

    const workflow = getWorkflow(state, workflowId);
    const workflowStep = workflow && getWorkflowStep(workflow, selectedStepId);

    assert(
      workflowStep,
      () =>
        `Did not find workflow step for selected step id: ${selectedStepId}, workflow id: ${workflowId}.`,
    );

    const workflowAction = getWorkflowActionForUpdateStep(workflowStep, scheduleMethod);

    const assignmentWorkflowStep: IAssignmentWorkflowStep = { ...workflowStep, workflowId };
    if (isPublishedWorkflowStep(workflowStep)) {
      if (!isBulkPublishAllowed(listingUi.bulkActionCheckResult)) {
        dispatch(
          selectWorkflowStep(
            workflowAction,
            assignmentWorkflowStep,
            SelectedItemsCannotBePublished,
          ),
        );
        return;
      }
      await dispatch(deps.checkPublishWarnings());
    }

    dispatch(selectWorkflowStep(workflowAction, assignmentWorkflowStep));
  };
