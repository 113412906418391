import { MiddlewareAPI } from '@reduxjs/toolkit';
import { Action } from '../../../@types/Action.type.ts';
import { Dispatch } from '../../../@types/Dispatcher.type.ts';
import { Data_UserInfo_Success } from '../../../data/constants/dataActionTypes.ts';
import { Shared_CurrentProjectId_Updated } from '../../constants/sharedActionTypes.ts';
import { isProjectTrial } from '../../selectors/userProjectsInfoSelectors.ts';
import { ensureHotjar } from '../../utils/hotjarUtils.ts';
import { logError } from '../../utils/logError.ts';
import { isAccountLessThan30DaysOld } from '../../utils/trialUtils.ts';
import { IStore } from '../IStore.type.ts';

export const trialMiddleware = ({ getState }: MiddlewareAPI<Dispatch, IStore>) => {
  return (next: Dispatch) =>
    (action: Action): Action => {
      try {
        switch (action.type) {
          case Shared_CurrentProjectId_Updated: {
            if (isProjectTrial(getState(), action.payload.currentProjectId)) {
              ensureHotjar();
            }
            break;
          }

          case Data_UserInfo_Success: {
            if (isAccountLessThan30DaysOld(action.payload.data)) {
              ensureHotjar();
            }
            break;
          }

          default:
            break;
        }
      } catch (ex) {
        logError(ex);
      }

      return next(action);
    };
};
