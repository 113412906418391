import React from 'react';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { AssetLibrarySelectionContextProvider } from '../context/AssetLibrarySelectionContext.tsx';
import { useAssetSelection } from '../hooks/useAssetSelection.ts';

export const AssetLibrarySelection: React.FC<React.PropsWithChildren<NoProps>> = ({ children }) => {
  const dispatch = useDispatch();

  const listingAssetsIds = useSelector((s) => s.data.assets.listingAssets.listingIds);
  const assetSelection = useAssetSelection({
    listingAssetsIds,
    reduxDispatch: dispatch,
  });

  return (
    <AssetLibrarySelectionContextProvider value={assetSelection}>
      {children}
    </AssetLibrarySelectionContextProvider>
  );
};
