import { useMemo } from 'react';
import { useParams } from 'react-router';
import { useThunkPromise } from '../../../_shared/hooks/useThunkPromise.ts';
import { useLivePreviewPreferenceStorage } from '../../../localStorages/useLivePreviewPreferenceStorage.ts';
import { editItemVariant } from '../actions/thunkSmartLinkActions.ts';
import { FocusTarget } from '../actions/thunks/editItemVariant.ts';
import { SmartLink } from '../components/SmartLink.tsx';
import { TaskRouteParams } from '../constants/routePaths.ts';

export const TaskSmartLink = () => {
  const {
    projectId,
    variantGuid: variantId,
    taskId,
    itemGuid: itemId,
  } = useParams<TaskRouteParams>();
  const { isLivePreviewPreferred } = useLivePreviewPreferenceStorage(projectId);

  const params = useMemo(
    () => ({
      projectId,
      variantId,
      path: [{ itemId }],
      predefinedFocus: {
        target: FocusTarget.Task,
        taskId,
      } as const,
      isLivePreviewPreferred,
    }),
    [projectId, variantId, itemId, taskId, isLivePreviewPreferred],
  );

  useThunkPromise(editItemVariant, params);

  return <SmartLink />;
};
