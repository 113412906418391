import { assert, Collection, createGuid } from '@kontent-ai/utils';
import { EditorState } from 'draft-js';
import { Dispatch, ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { ElementType } from '../../../../../contentInventory/content/models/ContentItemElementType.ts';
import { isEditableElement } from '../../../../../contentInventory/content/models/contentTypeElements/compiledTypeElementTypeGuards.ts';
import { InsertContentComponent } from '../../../../../richText/plugins/contentComponents/api/EditorContentComponentApi.type.ts';
import { UrlSlugMode } from '../../../../constants/urlSlugMode.ts';
import { createContentComponent } from '../../../../models/contentItem/ContentComponent.ts';
import { ICompiledContentItemElementData } from '../../../../models/contentItemElements/ICompiledContentItemElement.type.ts';
import { IUrlSlugItemElement } from '../../../../models/contentItemElements/UrlSlugItemElement.ts';
import { createItemElementWithDefaultValue } from '../../../../utils/itemElementCreator.ts';
import { IEnsureLoadedContentTypesAction } from '../../../LoadedItems/actions/thunks/ensureLoadedContentTypes.ts';
import { NewContentItem_ContentItemCreatedInRichText } from '../../../NewContentItem/constants/newContentItemActionTypes.ts';
import { getNewContentItemForm } from '../../../NewContentItem/selectors/newContentItemDialog.ts';
import { ElementReference } from '../../containers/hooks/useItemElementReference.ts';
import { areIdsEquivalent } from '../../utils/itemEditingUtils.ts';
import { IChangeRichStringElementValueAction } from './changeRichStringElementValue.ts';

export type ICreateNewContentComponentAction = (
  element: ElementReference,
  insertContentComponentItem: InsertContentComponent,
  editorState: EditorState,
  placeholderBlockKey: string,
  preselectedContentTypeId: Uuid | undefined,
) => ThunkPromise<EditorState>;

export interface ICreateNewContentComponentActionDependencies {
  readonly changeRichStringElementValue: IChangeRichStringElementValueAction;
  readonly ensureLoadedContentTypes: IEnsureLoadedContentTypesAction;
  readonly updateTypesPreference: (contentTypeId: Uuid) => ThunkPromise;
}

const mapUrlSlugToCustomMode = (
  element: ICompiledContentItemElementData,
): ICompiledContentItemElementData => {
  if (element.type === ElementType.UrlSlug) {
    // Url slugs are in the custom mode by default for components
    const urlSlugItemElement: IUrlSlugItemElement = {
      ...(element as IUrlSlugItemElement),
      mode: UrlSlugMode.Custom,
    };

    return urlSlugItemElement;
  }
  return element;
};

const newContentItemCreated = () =>
  ({
    type: NewContentItem_ContentItemCreatedInRichText,
  }) as const;

export type CreateNewContentComponentActionTypes = ReturnType<typeof newContentItemCreated>;

export const createNewContentComponentActionCreator =
  ({
    ensureLoadedContentTypes,
    updateTypesPreference,
    changeRichStringElementValue,
  }: ICreateNewContentComponentActionDependencies): ICreateNewContentComponentAction =>
  (
    element,
    insertContentComponentItem,
    editorState,
    placeholderBlockKey,
    preselectedContentTypeId,
  ) => {
    return async (dispatch: Dispatch, getState: () => IStore): Promise<EditorState> => {
      const state = getState();
      const {
        contentApp: { editedContentItemVariant },
      } = state;

      if (
        !element.itemId ||
        !editedContentItemVariant ||
        !areIdsEquivalent(element.itemId, editedContentItemVariant.id)
      ) {
        return editorState;
      }

      const contentTypeId = preselectedContentTypeId ?? getNewContentItemForm(state).contentTypeId;
      assert(contentTypeId, () => 'Can’t create a content item without selected content type.');

      const typeIdsToEnsure = new Set([contentTypeId]);
      const contentType = (await dispatch(ensureLoadedContentTypes(typeIdsToEnsure)))?.[0];
      assert(contentType, () => 'Could not load selected content type.');

      const elements = contentType.contentElements
        .filter(isEditableElement)
        .map(createItemElementWithDefaultValue)
        .map(mapUrlSlugToCustomMode);

      const contentComponentId = createGuid();
      const contentComponent = createContentComponent({
        id: contentComponentId,
        contentTypeId,
        elements,
      });

      const newEditorState = insertContentComponentItem(
        editorState,
        placeholderBlockKey,
        contentComponentId,
        false,
      );
      if (editorState !== newEditorState) {
        dispatch(
          changeRichStringElementValue(
            element.itemId,
            element.rootRichTextElementId ?? element.elementId,
            (elementData) => ({
              ...elementData,
              contentComponents: Collection.add(elementData.contentComponents, [
                contentComponentId,
                contentComponent,
              ]),
            }),
          ),
        );

        dispatch(newContentItemCreated());
        dispatch(updateTypesPreference(contentTypeId));

        return newEditorState;
      }

      return editorState;
    };
  };
