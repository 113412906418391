import { trackUserEvent } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import {
  ContentItemEditingEventOrigins,
  ContentItemEditingExpandCollapseAction,
} from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { ContentDetailsButton as ContentDetailsButtonComponent } from '../../components/statusBar/ContentDetailsButton.tsx';
import { ContentItemEditing_ContentDetailsButton_Toggled } from '../../constants/contentItemEditingActionTypes.ts';

const toggleContentDetailsSidebar = () =>
  ({
    type: ContentItemEditing_ContentDetailsButton_Toggled,
  }) as const;

export type ContentDetailsButtonActionsType = ReturnType<typeof toggleContentDetailsSidebar>;

type Props = Readonly<{
  isQuinary?: boolean;
}>;

export const ContentDetailsButton = ({ isQuinary }: Props) => {
  const dispatch = useDispatch();

  const isSidebarOpen = useSelector((s) => s.contentApp.editorUi.contentItemSidebar.isOpen);
  const isSidebarToggleActive = useSelector(
    (s) =>
      s.contentApp.editorUi.contentItemSidebar.isOpen &&
      s.contentApp.editorUi.contentItemSidebar.isInitialized,
  );

  const toggleSidebar = (isOpen: boolean) => {
    dispatch(toggleContentDetailsSidebar());

    dispatch(
      trackUserEvent(TrackedEvent.ContentItemEditing, {
        action: isOpen
          ? ContentItemEditingExpandCollapseAction.ContentItemDetailsSidebarClosed
          : ContentItemEditingExpandCollapseAction.ContentItemDetailsSidebarOpened,
        origin: ContentItemEditingEventOrigins.StatusBar,
      }),
    );
  };

  return (
    <ContentDetailsButtonComponent
      isQuinary={isQuinary}
      isSidebarOpen={isSidebarOpen}
      isSidebarToggleActive={isSidebarToggleActive}
      onSidebarToggleClick={toggleSidebar}
    />
  );
};
