import { Box } from '@kontent-ai/component-library/Box';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { Spacing } from '@kontent-ai/component-library/tokens';
import classNames from 'classnames';
import React from 'react';
import { ItemPublishedWithNewVersionTooltipText } from '../../../applications/contentInventory/content/constants/uiConstants.ts';
import { WorkflowStepColor } from '../../../data/constants/WorkflowStepColor.ts';
import { IListingContentItem } from '../../../data/models/listingContentItems/IListingContentItem.ts';
import { renderWorkflowStepColor } from '../../../data/models/workflow/utils/renderWorkflowStepColor.ts';
import { isPublishedWorkflowStep } from '../../utils/contentItemUtils.ts';
import { isVariantArchived, isVariantPublished } from '../../utils/contentItemVariantUtils.ts';

type WorkflowBadgesProps = {
  readonly item: IListingContentItem;
  readonly renderNotTranslatedBadge: () => React.ReactElement;
};

type WorkflowBadgeProps = Readonly<{
  icon: React.ReactNode;
  tooltipText: string;
}>;

const WorkflowBadge: React.FC<WorkflowBadgeProps> = ({ icon, tooltipText }) => (
  <Tooltip text={tooltipText} placement="top">
    <Box>{icon}</Box>
  </Tooltip>
);

export const WorkflowBadges: React.FC<WorkflowBadgesProps> = ({
  item,
  renderNotTranslatedBadge,
}) => {
  const workflowStatus =
    item.variant?.actualWorkflowStatus || item.variant?.assignment.workflowStatus;
  const hasPublishedVersion = isVariantPublished(item.variant);
  const isInPublishedStep = isPublishedWorkflowStep(workflowStatus);
  const isTranslated = item.variant && !isVariantArchived(item.variant);

  if (!workflowStatus) {
    return null;
  }

  return (
    <div className={classNames('workflow-badges')}>
      {isTranslated ? (
        <Inline noWrap spacingX={Spacing.XS}>
          {!isInPublishedStep && (
            <WorkflowBadge
              icon={<Icons.CircleInverted color={renderWorkflowStepColor(workflowStatus.color)} />}
              tooltipText={workflowStatus.name}
            />
          )}
          {hasPublishedVersion && (
            <WorkflowBadge
              icon={
                <Icons.CheckCircleInverted
                  color={renderWorkflowStepColor(WorkflowStepColor.PersianGreen)}
                />
              }
              tooltipText={ItemPublishedWithNewVersionTooltipText}
            />
          )}
        </Inline>
      ) : (
        renderNotTranslatedBadge()
      )}
    </div>
  );
};
WorkflowBadges.displayName = 'WorkflowBadges';
