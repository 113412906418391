import { memoize } from '@kontent-ai/memoization';
import { notNull } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { CommentThreadItemType } from '../../../../models/comments/CommentThreadItem.ts';
import { ICommentThread } from '../../../../models/comments/CommentThreads.ts';
import {
  hasCommentThreadSuggestions,
  isThreadResolvedWithoutUndo,
  isThreadSaved,
} from '../../../../utils/commentUtils.ts';
import {
  CommentThreadHighlighter,
  ICommentThreadHighlighterProps,
} from '../../components/comments/CommentThreadHighlighter.tsx';

const getThreadById = (
  threadId: string,
  threads: ReadonlyArray<ICommentThread>,
): ICommentThread | null => threads.find((t) => t.id === threadId) ?? null;

const separateSuggestionThreadAndFilterOutResolvedThreads = memoize.weak(
  (
    threads: ReadonlyArray<ICommentThread>,
    newCommentThreadItemTypeMapping: Immutable.Map<string, CommentThreadItemType>,
  ): ICommentThreadHighlighterProps => {
    const unresolvedThreads = threads.filter((thread) => !isThreadResolvedWithoutUndo(thread));
    const savedThreads = unresolvedThreads.filter(isThreadSaved);
    const unsavedThreads = unresolvedThreads.filter((t) => t.isUnsaved || t.isReplying);

    const unsavedSuggestions = newCommentThreadItemTypeMapping
      .filter((t) => t === CommentThreadItemType.Suggestion)
      .keySeq()
      .toArray()
      .map((threadId: string) => getThreadById(threadId, unsavedThreads))
      .filter(notNull);

    const unsavedComments = newCommentThreadItemTypeMapping
      .filter((t) => t === CommentThreadItemType.Comment)
      .keySeq()
      .toArray()
      .map((threadId) => getThreadById(threadId, unsavedThreads))
      .filter(notNull);

    const suggestionThreads = [
      ...new Set(savedThreads.filter(hasCommentThreadSuggestions).concat(unsavedSuggestions)),
    ];

    const commentOnlyThreads = [
      ...new Set(
        savedThreads
          .filter((thread) => !hasCommentThreadSuggestions(thread))
          .concat(unsavedComments),
      ),
    ];

    return {
      commentThreads: commentOnlyThreads,
      suggestionThreads,
    };
  },
);

function mapStateToProps(state: IStore): ICommentThreadHighlighterProps {
  const {
    editedContentItemVariantComments,
    editorUi: { newCommentThreadItemTypeMappings },
  } = state.contentApp;

  return separateSuggestionThreadAndFilterOutResolvedThreads(
    editedContentItemVariantComments.commentThreads,
    newCommentThreadItemTypeMappings,
  );
}

const ConnectedFocusedCommentThreadHighlighter = connect(mapStateToProps)(CommentThreadHighlighter);

export { ConnectedFocusedCommentThreadHighlighter as CommentThreadHighlighter };
