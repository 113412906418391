import React from 'react';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { getSelectedSubscription } from '../../../../_shared/selectors/subscriptionSelectors.ts';
import { getUrlFactory } from '../../../../_shared/utils/urlFactory.ts';
import { fastSpringAccountConnectionCompleted } from '../actions/thunkBillingInformationActions.ts';
import {
  FastSpringWidget as FastSpringWidgetComponent,
  IFastSpringWidgetOwnProps,
} from '../components/FastSpringWidget.tsx';
import { getFastSpringBaseUrl, isFastSpringInDevelopMode } from '../utils/fastSpringUtils.ts';

interface IContainerProps extends IFastSpringWidgetOwnProps {
  readonly onFastSpringAccountConnected?: (subscriptionId: Uuid) => void;
}

const FastSpringWidget: React.FC<IContainerProps> = (ownProps) => {
  const selectedSubscription = useSelector((s) => getSelectedSubscription(s));
  const currentUserId = useSelector((s) => s.data.user.info.userId);
  const clientUrl = getUrlFactory().getDraftUiRootUrl();

  const dispatch = useDispatch();

  const onFastSpringAccountConnectedAction = ownProps.onFastSpringAccountConnected
    ? ownProps.onFastSpringAccountConnected
    : (subscriptionId: Uuid) => dispatch(fastSpringAccountConnectionCompleted(subscriptionId));

  return selectedSubscription ? (
    <FastSpringWidgetComponent
      {...ownProps}
      baseUrl={getFastSpringBaseUrl()}
      currentUserId={currentUserId}
      onFastSpringAccountConnected={onFastSpringAccountConnectedAction}
      subscriptionId={selectedSubscription.subscriptionId}
      isDevelop={isFastSpringInDevelopMode()}
      clientUrl={clientUrl}
    />
  ) : (
    <Loader />
  );
};

FastSpringWidget.displayName = 'FastSpringWidget';
export { FastSpringWidget };
