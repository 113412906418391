import Immutable from 'immutable';
import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { ILoadListingItemsAction } from '../../../itemEditor/features/LoadedItems/actions/thunks/loadListingItems.ts';
import { WebSpotlight_SelectedNodeAncestors_Expanded } from '../../constants/webSpotlightActionTypes.ts';
import { IExpandedNodesData } from '../../reducers/expandedNodesData.ts';
import { NodeId } from '../../types/nodeId.type.ts';
import { canDisplayItem, getNodeIdFromIdsByIndex } from '../../utils/webSpotlightUtils.ts';

interface IDeps {
  readonly loadListingItems: ILoadListingItemsAction;
  readonly loadSubpagesIds: (contentItemIds: UuidArray, abortSignal?: AbortSignal) => ThunkPromise;
}

const expanded = (expandedNodesData: Immutable.Map<NodeId, IExpandedNodesData>) =>
  ({
    type: WebSpotlight_SelectedNodeAncestors_Expanded,
    payload: {
      expandedNodesData,
    },
  }) as const;

export type ExpandSelectedWebSpotlightNodeAncestorsActionsType = ReturnType<typeof expanded>;

export const expandSelectedWebSpotlightNodeAncestorsActionCreator =
  (deps: IDeps) =>
  (nodeItemIdsTreePath: ReadonlyArray<Uuid>, abortSignal?: AbortSignal): ThunkPromise =>
  async (dispatch, getState) => {
    await Promise.all([
      dispatch(deps.loadListingItems(nodeItemIdsTreePath, abortSignal)),
      dispatch(deps.loadSubpagesIds(nodeItemIdsTreePath, abortSignal)),
    ]);

    const {
      data: { listingContentItems },
      webSpotlightApp: { subpagesById, expandedNodesData },
    } = getState();

    const currentlyExpandedNodesItemIds = expandedNodesData.toArray().map((value) => value.itemId);

    const { itemIdsToLoad, nodesToExpand } = nodeItemIdsTreePath.reduce(
      (acc, itemId, index) => {
        if (!currentlyExpandedNodesItemIds.includes(itemId)) {
          const item = listingContentItems.byId.get(itemId);

          if (item && canDisplayItem(item)) {
            const subpagesIds = subpagesById.get(itemId);

            if (subpagesIds) {
              const nodeId = getNodeIdFromIdsByIndex(nodeItemIdsTreePath, index);
              return {
                itemIdsToLoad: [...acc.itemIdsToLoad, ...subpagesIds],
                nodesToExpand: acc.nodesToExpand.set(nodeId, {
                  itemId,
                  subpagesIds,
                }),
              };
            }
          }
        }
        return acc;
      },
      {
        nodesToExpand: Immutable.Map<NodeId, IExpandedNodesData>(),
        itemIdsToLoad: [],
      },
    );

    if (itemIdsToLoad.length > 0) {
      await Promise.all([
        dispatch(deps.loadListingItems(itemIdsToLoad, abortSignal)),
        dispatch(deps.loadSubpagesIds(itemIdsToLoad, abortSignal)),
      ]);
    }

    dispatch(expanded(nodesToExpand));
  };
