import { IconButton } from '@kontent-ai/component-library/Button';
import React, { MouseEventHandler, RefObject } from 'react';
import { ActionItem, ActionMenu } from '../../../../_shared/components/ActionMenu.tsx';
import {
  IDropdownTippyOptions,
  defaultDropdownTippyOptions,
} from '../../../../_shared/uiComponents/DropDown/dropDownTippyOptions.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { DefaultItemIsSelectedMessage, ProjectHasNoDefaultItem } from '../constants/uiConstants.ts';

const tippyOptions: IDropdownTippyOptions = {
  ...defaultDropdownTippyOptions,
  placement: 'bottom-start',
};

export interface IRelationsActionsStateProps {
  readonly canChangeSettings: boolean;
  readonly hasProjectDefault: boolean;
  readonly isDefaultSelected: boolean;
  readonly isDefaultRootBeingChanged: boolean;
  readonly isDefaultRootBeingFetched: boolean;
}

export interface IRelationsActionsDispatchProps {
  readonly onBackToDefaultClick: () => void;
  readonly onChangeClick: () => void;
  readonly onMenuOpen: () => void;
  readonly onSetAsDefaultClick: () => void;
}

type RelationsActionsProps = IRelationsActionsStateProps & IRelationsActionsDispatchProps;

export const RelationsActions: React.FC<RelationsActionsProps> = ({
  canChangeSettings,
  hasProjectDefault,
  isDefaultSelected,
  isDefaultRootBeingChanged,
  isDefaultRootBeingFetched,
  onBackToDefaultClick,
  onChangeClick,
  onMenuOpen,
  onSetAsDefaultClick,
}) => {
  const actions: ReadonlyArray<ActionItem> = [
    {
      dataUiAction: DataUiAction.Select,
      disabledState: { isDisabled: false },
      hasCapabilityToExecuteAction: true,
      isDestructive: false,
      onClick: onChangeClick,
      text: 'Change your home item',
    },
    {
      dataUiAction: DataUiAction.Save,
      disabledState: {
        disabledMessage: getDisabledMessage(isDefaultSelected),
        isDisabled: isDefaultSelected || isDefaultRootBeingChanged,
      },
      hasCapabilityToExecuteAction: canChangeSettings,
      isDestructive: false,
      loading: isDefaultRootBeingFetched || isDefaultRootBeingChanged,
      onClick: onSetAsDefaultClick,
      text: 'Set as default',
    },
    {
      dataUiAction: DataUiAction.Reset,
      disabledState: {
        disabledMessage: getDisabledMessage(isDefaultSelected, hasProjectDefault),
        isDisabled: !hasProjectDefault || isDefaultSelected || isDefaultRootBeingChanged,
      },
      hasCapabilityToExecuteAction: true,
      isDestructive: false,
      loading: isDefaultRootBeingFetched || isDefaultRootBeingChanged,
      onClick: onBackToDefaultClick,
      text: 'Back to default',
    },
  ];

  return (
    <ActionMenu
      actions={actions}
      tippyOptions={tippyOptions}
      renderToggleButton={(
        ref: RefObject<HTMLButtonElement>,
        onClick: MouseEventHandler<HTMLElement>,
        isOpen: boolean,
      ) => (
        <IconButton
          iconName="ThreeDotsVertical"
          size="small"
          buttonStyle="tertiary"
          tooltipText="More actions"
          tooltipPlacement="bottom"
          onClick={(event) => {
            if (!isOpen) {
              onMenuOpen();
            }

            onClick(event);
          }}
          activated={isOpen}
          aria-label="More actions"
          ref={ref}
          {...getDataUiActionAttribute(DataUiAction.MoreActions)}
        />
      )}
    />
  );
};

RelationsActions.displayName = 'RelationsActions';

function getDisabledMessage(
  isDefaultSelected: boolean,
  hasProjectDefault: boolean = true,
): string | undefined {
  if (isDefaultSelected) {
    return DefaultItemIsSelectedMessage;
  }

  if (!hasProjectDefault) {
    return ProjectHasNoDefaultItem;
  }

  return undefined;
}
