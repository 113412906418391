import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import {
  BaseColor,
  BorderRadius,
  Spacing,
  Typography,
  colorPrimary,
  px,
} from '@kontent-ai/component-library/tokens';
import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import { IsSelected, useIsSelected } from '../../../../../../../_shared/hooks/useIsSelected.ts';
import { getDataAttribute } from '../../../../../../../_shared/utils/dataAttributes/DataAttributes.ts';
import { DataDraftJsAttributes } from '../../../../../../../_shared/utils/dataAttributes/DataDraftJsAttributes.ts';
import {
  DataUiInput,
  getDataUiInputAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { formatUserName } from '../../../../../../../_shared/utils/users/usersUtils.ts';
import { IProjectContributor } from '../../../../../../../data/models/users/ProjectContributor.ts';

const UserMentionContent = styled.span<{
  readonly text: string;
  readonly isCurrentUser: boolean;
}>`
  display: inline-block;
  max-width: 100%;
  // Allow click-through to allow placing caret by mouse click at the mention which is not editable
  pointer-events: none;
  
  // Avoid native highlight in Safari, as we use explicit highlight via atomic entity support
  & *::selection {
    background-color: transparent !important;
  }
  
  &::before {
    content: "${(props) => props.text}";
    display: inline-block;
    vertical-align: bottom;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    user-select: none;

    ${(props) =>
      props.isCurrentUser
        ? css`
      ${Typography.BodyMedium}
      padding: 0 ${px(Spacing.S)};
      color: ${BaseColor.White};
      background-color: ${colorPrimary};
      border-radius: ${px(BorderRadius.Pill)};
    `
        : css`
      ${Typography.BodyMediumEmphasis}
      color: ${colorPrimary};
    `}
  }
`;

export type UserMentionProps = {
  readonly children: React.ReactNode;
  readonly isCurrentUser: boolean;
  readonly offsetKey: string;
  readonly user?: IProjectContributor;
  readonly wasPinged?: boolean;
};

export const UserMention: React.FC<UserMentionProps> = ({
  children,
  isCurrentUser,
  offsetKey,
  user,
  wasPinged,
}) => {
  const userName = formatUserName(user);

  const ref = useRef<HTMLSpanElement>(null);
  const isSelected = useIsSelected(ref) === IsSelected.Fully;

  const tooltipUserName = isCurrentUser && wasPinged ? 'you' : userName;
  const tooltipText = wasPinged ? `We’ve notified ${tooltipUserName}` : tooltipUserName;

  return (
    <Tooltip text={tooltipText} placement="top">
      <UserMentionContent
        className={isSelected ? 'rte__entity--is-selected' : undefined}
        contentEditable={false}
        isCurrentUser={isCurrentUser}
        ref={ref}
        text={`@${userName}`}
        {...getDataAttribute(DataDraftJsAttributes.OffsetKey, offsetKey)}
        {...getDataUiInputAttribute(DataUiInput.Mention)}
      >
        {children}
      </UserMentionContent>
    </Tooltip>
  );
};

UserMention.displayName = 'UserMention';
