import { ReactNode, useContext } from 'react';
import { MainLayoutGrid } from '../components/MainLayoutGrid.tsx';
import { MainNavigationContext } from '../contexts/MainNavigationContext.tsx';

type Props = {
  readonly children: ReactNode;
};

export const MainLayoutGridAppView = ({ children }: Props) => {
  const { navigationBarExpandedState } = useContext(MainNavigationContext);

  return (
    <MainLayoutGrid.AppView navigationBarExpandedState={navigationBarExpandedState}>
      {children}
    </MainLayoutGrid.AppView>
  );
};
