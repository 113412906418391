import React from 'react';
import { DragSource } from '../../../../../../../_shared/components/DragDrop/DragSource.tsx';
import { DropTarget } from '../../../../../../../_shared/components/DragDrop/DropTarget.tsx';
import { DragMoveHandler } from '../../../../../../../_shared/components/DragDrop/dragDrop.type.ts';
import { DndTypes } from '../../../../../../../_shared/constants/dndTypes.ts';
import { isWithinTargetInset20 } from '../../../../../../../_shared/utils/dragDrop/hoveringCollisionStrategies.ts';
import { IAsset } from '../../../../../../../data/models/assets/Asset.ts';
import { AssetTile } from './AssetTile.tsx';

interface IDraggableAssetTileProps {
  readonly asset: IAsset;
  readonly canViewAsset: boolean;
  readonly elementId: Uuid;
  readonly onMoveAsset: DragMoveHandler;
  readonly onOpenEditAssetDialog: (assetId: Uuid) => void;
  readonly onRemoveAsset: (assetId: Uuid) => void;
}

export const DraggableAssetTile: React.FC<IDraggableAssetTileProps> = ({
  asset,
  canViewAsset,
  elementId,
  onMoveAsset,
  onOpenEditAssetDialog,
  onRemoveAsset,
}) => {
  return (
    <DropTarget<HTMLDivElement>
      targetId={asset.id}
      onMove={onMoveAsset}
      canDrop
      parentId={elementId}
      accept={DndTypes.Asset_Type_Element_Dnd_Identifier}
      hoveringCollisionStrategy={isWithinTargetInset20}
      renderDroppable={(ref) => (
        <div ref={ref} className="asset-tile-wrapper">
          <DragSource
            parentId={elementId}
            sourceId={asset.id}
            type={DndTypes.Asset_Type_Element_Dnd_Identifier}
            renderDraggable={(connectDragSource, isDragging) => (
              <AssetTile
                asset={asset}
                canViewAsset={canViewAsset}
                connectDragSource={connectDragSource}
                isDragging={isDragging}
                onOpenEditAssetDialog={onOpenEditAssetDialog}
                onRemoveAsset={onRemoveAsset}
              />
            )}
            renderPreview={() => (
              <AssetTile asset={asset} canViewAsset={canViewAsset} isDragging={false} />
            )}
          />
        </div>
      )}
    />
  );
};

DraggableAssetTile.displayName = 'DraggableAssetTile';
