import { Button } from '@kontent-ai/component-library/Button';
import React from 'react';
import { EmptyState } from '../../../../../_shared/components/EmptyState/EmptyState.tsx';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface ITaxonomyEmptyStateProps {
  readonly onCreateNewButtonClicked: () => void;
}

export const TaxonomyEmptyState: React.FC<ITaxonomyEmptyStateProps> = ({
  onCreateNewButtonClicked,
}) => (
  <EmptyState>
    <EmptyState.Title>One more thing…</EmptyState.Title>
    <EmptyState.Content>
      Create a few taxonomy terms in the group and start using it.
    </EmptyState.Content>
    <EmptyState.Footer>
      <Button
        buttonStyle="primary"
        onClick={onCreateNewButtonClicked}
        {...getDataUiActionAttribute(DataUiAction.CreateFirst)}
      >
        Create new Taxonomy term
      </Button>
    </EmptyState.Footer>
  </EmptyState>
);

TaxonomyEmptyState.displayName = 'TaxonomyEmptyState';
