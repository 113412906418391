import React, { MouseEventHandler } from 'react';
import { ClipboardFormField } from '../../../../../../_shared/uiComponents/Clipboard/ClipboardFormField.tsx';
import { DropDownCaptionOption } from '../../../../../../_shared/uiComponents/DropDown/DropDownCaptionOption.tsx';
import { DropDownOptionSeparator } from '../../../../../../_shared/uiComponents/DropDown/DropDownOptionSeparator.tsx';
import { DropDownOptionsGroup } from '../../../../../../_shared/uiComponents/DropDown/DropDownOptionsGroup.tsx';
import { IAsset } from '../../../../../../data/models/assets/Asset.ts';
import { codenameFormWidth } from '../../../constants/uiConstants.ts';

interface IAssetUrlDialogProps {
  readonly asset: IAsset;
  readonly onClick: MouseEventHandler<HTMLElement>;
  readonly onCopyCompleted: () => void;
}

export const AssetUrlDialog: React.FC<IAssetUrlDialogProps> = ({
  asset,
  onCopyCompleted,
  onClick,
}) => (
  <DropDownOptionsGroup>
    <DropDownCaptionOption name="Copy asset URL" onClick={onClick} />
    <DropDownOptionSeparator />
    <div className="dropdown-item">
      <div style={{ width: codenameFormWidth }}>
        <ClipboardFormField
          onCopyCompleted={onCopyCompleted}
          inputName="asset_url"
          inputText={asset._deliveryLink}
        />
      </div>
    </div>
  </DropDownOptionsGroup>
);

AssetUrlDialog.displayName = 'AssetUrlDialog';
