import React from 'react';

interface ITypeElementConfigurationCategoryProps {
  readonly children: React.ReactNode;
}

export const TypeElementConfigurationCategory: React.FC<ITypeElementConfigurationCategoryProps> = ({
  children,
}) => (
  <div className="content-element__config-category">
    <div className="row">{children}</div>
  </div>
);

TypeElementConfigurationCategory.displayName = 'TypeElementConfigurationCategory';
