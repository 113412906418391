import { ReactNode, useContext } from 'react';
import { NotificationBar } from '../../../../_shared/containers/NotificationBar.tsx';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { RefinedNavigationContext } from '../../../refinedNavigation/contexts/RefinedNavigationContext.tsx';
import { EnvironmentSettingsMenu } from '../containers/EnvironmentSettingsMenu.tsx';

type Props = {
  readonly children: ReactNode;
};

export const EnvironmentSettingsApp = ({ children }: Props) => {
  const { isRefinedNavigationFeatureEnabled } = useContext(RefinedNavigationContext);
  return (
    <div className="canvas" {...getDataUiAppNameAttribute(DataUiAppName.EnvironmentSettings)}>
      <div className="canvas__workspace">
        {!isRefinedNavigationFeatureEnabled && <EnvironmentSettingsMenu />}
        <section className="canvas__content">
          <div className="canvas__notifications">
            <NotificationBar />
          </div>
          <div className="canvas__content-pane">{children}</div>
        </section>
      </div>
    </div>
  );
};
