import {
  HotkeysHandler,
  HotkeysMode,
} from '../../../../_shared/components/Hotkeys/HotkeysHandler.tsx';
import { HtmlPageTitle } from '../../../../_shared/components/HtmlPageTitle.tsx';
import { AppNames } from '../../../../_shared/constants/applicationNames.ts';
import { NotificationBar } from '../../../../_shared/containers/NotificationBar.tsx';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { TaxonomyGroupNameEditorElement } from '../containers/TaxonomyGroupNameEditorElement.tsx';
import { TaxonomyTermListing } from '../containers/TaxonomyTermListing.tsx';
import { TaxonomyEmptyState } from './messages/TaxonomyEmptyState.tsx';
import { TaxonomyWarning } from './messages/TaxonomyWarning.tsx';

type Props = {
  readonly isUsedInPublishedContent: boolean;
  readonly onCreateNewButtonClicked: () => void;
  readonly onSave: () => void;
  readonly showEmptyStatePage: boolean;
  readonly taxonomyGroupName: string;
};

export const TaxonomyGroupEditor = ({
  isUsedInPublishedContent,
  onCreateNewButtonClicked,
  onSave,
  showEmptyStatePage,
  taxonomyGroupName,
}: Props) => {
  return (
    <>
      <div
        className="canvas__workspace"
        {...getDataUiAppNameAttribute(DataUiAppName.TaxonomyGroupEditing)}
      >
        <HtmlPageTitle appName={AppNames.Taxonomies} customName={taxonomyGroupName} />
        <section className="canvas__content">
          <div className="canvas__notifications">
            <NotificationBar />
          </div>
          <div className="canvas__content-pane">
            <HotkeysHandler
              mode={HotkeysMode.Dual}
              handlers={{
                onControlS: (e: KeyboardEvent): void => {
                  e.preventDefault();
                  onSave();
                },
              }}
            >
              {isUsedInPublishedContent && (
                <div className="flex flex--center">
                  <div className="flex-item--max-width--1000">
                    <TaxonomyWarning />
                  </div>
                </div>
              )}
              <div className="flex flex--center">
                <div className="flex-item--max-width--1000">
                  <TaxonomyGroupNameEditorElement />
                </div>
              </div>
              <div className="flex flex--center">
                <div className="flex-item--max-width--1000">
                  {showEmptyStatePage ? (
                    <TaxonomyEmptyState onCreateNewButtonClicked={onCreateNewButtonClicked} />
                  ) : (
                    <TaxonomyTermListing />
                  )}
                </div>
              </div>
            </HotkeysHandler>
          </div>
        </section>
      </div>
    </>
  );
};
