import React from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { IProjectContributorRole } from '../../../../../data/models/users/ProjectContributor.ts';
import { getSortedRolesMemoized } from '../../../roles/selectors/getSortedRolesList.ts';
import { areLanguageRolesEnabledForCurrentProject } from '../../../selectors/allowedFeaturesSelectors.ts';
import { RoleBuilder } from '../../components/roleBuilder/RoleBuilder.tsx';
import {
  createDisabledAddButtonTooltip,
  createDisabledLanguageDropDownTooltip,
} from '../../selectors/disabledTooltips.ts';
import { getBasicRoleBuilderErrorMessage } from '../../selectors/getBasicRoleBuilderErrorMessage.ts';
import { getRemainingRoles } from '../../utils/getRemainingRoles.ts';

type Props = {
  readonly contributorId?: Uuid | undefined;
  readonly disabledTooltip: string | undefined;
  readonly isProjectManagerSelectionValid: boolean;
  readonly onChange: (userRoles: readonly IProjectContributorRole[]) => void;
  readonly userRoles: readonly IProjectContributorRole[];
};

export const CollectionGroupRoleBuilder: React.FC<Props> = ({
  contributorId,
  disabledTooltip,
  onChange,
  userRoles,
  isProjectManagerSelectionValid,
}) => {
  const roles = useSelector((state) => state.data.roles);
  const allRoles = getSortedRolesMemoized(roles.rolesById);
  const areLanguageRolesEnabled = useSelector(areLanguageRolesEnabledForCurrentProject);
  const errorMessage = getBasicRoleBuilderErrorMessage(userRoles, areLanguageRolesEnabled);
  const disabledAddTooltip = createDisabledAddButtonTooltip(userRoles, allRoles);
  const remainingRoles = getRemainingRoles(userRoles, allRoles);
  const languages = useSelector((state) => state.data.languages);

  const disabledLanguageDropDownTooltip = createDisabledLanguageDropDownTooltip({
    areLanguageRolesEnabled,
    isRoleBuilderValid: !errorMessage,
    languages,
  });

  return (
    <RoleBuilder
      areLanguageRolesEnabled={areLanguageRolesEnabled}
      contributorId={contributorId}
      disabledAddTooltip={disabledAddTooltip}
      disabledLanguageDropDownTooltip={disabledLanguageDropDownTooltip}
      disabledTooltip={disabledTooltip}
      isProjectManagerSelectionValid={isProjectManagerSelectionValid}
      onChange={onChange}
      remainingRoles={remainingRoles}
      userRoles={userRoles}
    />
  );
};

CollectionGroupRoleBuilder.displayName = 'CollectionGroupRoleBuilderContainer';
