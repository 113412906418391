import {
  colorBackgroundSelected,
  colorPrimaryHoverInverse,
  colorTextDefault,
} from '@kontent-ai/component-library/tokens';
import styled from 'styled-components';
import { assetTileBorderRadius } from '../../decisionTokens.ts';
import { AssetTileReadonlyState } from '../../enums/assetTileReadonlyStateEnum.ts';
import { AssetTileType } from '../../enums/assetTileTypeEnum.ts';
import { getBackgroundColorForDiffState } from '../../utils.ts';

interface IStyledInfoBarProps {
  readonly isSelected?: boolean;
  readonly isTileHovered?: boolean;
  readonly readonlyState?: AssetTileReadonlyState;
  readonly tileType: AssetTileType;
}

const getStyledInfoBarBackground = ({
  isSelected,
  isTileHovered,
  readonlyState,
  tileType,
}: IStyledInfoBarProps) => {
  switch (tileType) {
    case AssetTileType.Selectable: {
      if (isSelected) {
        return isTileHovered ? colorPrimaryHoverInverse : colorBackgroundSelected;
      }
      break;
    }
    case AssetTileType.WithActions: {
      if (readonlyState) {
        return getBackgroundColorForDiffState(readonlyState);
      }
      break;
    }
  }
  return 'inherit';
};

export const StyledInfoBar = styled.div<IStyledInfoBarProps>`
  color: ${colorTextDefault};
  background-color: ${getStyledInfoBarBackground};
  border-bottom-left-radius: ${assetTileBorderRadius};
  border-bottom-right-radius: ${assetTileBorderRadius};
  margin-top: auto;
  position: relative;
`;
