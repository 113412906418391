import { assert } from '@kontent-ai/utils';
import React from 'react';
import { useForm } from 'react-hook-form';
import { upsertUserProperty } from '../../../../../_shared/actions/thunkSharedActions.ts';
import { variantCountWarningLimit } from '../../../../../_shared/constants/limitConstants.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { LongProcessingChangesWarningDismissedServerKey } from '../../../../../_shared/models/UserPropertiesServerKeys.ts';
import { isLanguageActive } from '../../../../../_shared/models/utils/isLanguageActive.ts';
import { getCurrentProject } from '../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { createFormValidationResolver } from '../../../../../_shared/utils/validation/createFormValidationResolver.ts';
import { ILanguage, Languages } from '../../../../../data/models/languages/Language.ts';
import { areLanguageRolesEnabledForCurrentProject } from '../../../selectors/allowedFeaturesSelectors.ts';
import {
  activateLanguage,
  deactivateLanguage,
  hideLanguageCreateForm,
  hideLanguageEditor,
  saveEditedLanguage,
} from '../../actions/localizationActions.ts';
import { createNewLanguage } from '../../actions/thunkLocalizationActions.ts';
import { LanguageEditor } from '../../components/languageEditors/LanguageEditor.tsx';
import { LanguageFormShape } from '../../models/languageFormShape.type.ts';
import {
  getFallbackLanguageOptions,
  getOtherLanguageOptions,
} from '../../utils/getLanguageOptions.ts';
import { languageValidationConfig } from '../../validation/languageValidation.ts';

interface ILanguageEditorOwnProps {
  readonly editorLanguages: Languages;
  readonly language: ILanguage;
}

const getIsLanguageLimitReached = (state: IStore): boolean => {
  const {
    data: {
      plans,
      subscriptions: { subscriptionUsages },
    },
    localizationApp: { languages: editorLanguages },
    sharedApp: { currentProjectId },
  } = state;

  const currentProject = getCurrentProject(state);
  const subscriptionUsage = subscriptionUsages.get(currentProject.subscriptionId);

  assert(subscriptionUsage, () => `Subscription usage was not defined: '${currentProjectId}'.`);

  const plan = plans.byId.get(subscriptionUsage.planId);
  // +1 for the default language
  const numberOfActiveLanguages = editorLanguages.valueSeq().count(isLanguageActive) + 1;
  const maxLanguages = plan?.features.maxActiveLanguages;

  return !!maxLanguages && numberOfActiveLanguages >= maxLanguages;
};

const LanguageEditorContainer: React.FC<ILanguageEditorOwnProps> = ({
  editorLanguages,
  language,
}) => {
  const areLanguageRolesEnabled = useSelector(areLanguageRolesEnabledForCurrentProject);

  const fallbackLanguageOptions = useSelector((s) => {
    const { defaultLanguage } = s.localizationApp;

    return getFallbackLanguageOptions(editorLanguages, language.id, defaultLanguage);
  });

  const isEditedLanguageNew = useSelector((s) => s.localizationApp.isEditedLanguageNew);
  const languages = useSelector((s) => s.data.languages);
  const showLargeProjectSaveWarning = useSelector(
    (s) =>
      s.localizationApp.activeLanguagesItemVariantCount > variantCountWarningLimit &&
      !s.sharedApp.userProperties.longProcessingChangesWarningDismissed,
  );

  const isLanguageAlreadyUsed = isEditedLanguageNew
    ? false
    : languages.usages.get(language.id, false);

  const isLanguageLimitReached = useSelector(getIsLanguageLimitReached);

  const isSetAsFallbackLanguage = useSelector((s) =>
    isEditedLanguageNew
      ? false
      : s.localizationApp.languages.some(
          (lang: ILanguage) => lang.fallbackLanguageId === language.id,
        ),
  );

  const otherLanguages = useSelector((s) => {
    const { defaultLanguage } = s.localizationApp;

    return getOtherLanguageOptions(editorLanguages, language.id, defaultLanguage);
  });

  const dispatch = useDispatch();

  const onActivate = isEditedLanguageNew
    ? null
    : () => dispatch(activateLanguage(language.id, editorLanguages));

  const onDeactivate = isEditedLanguageNew ? null : () => dispatch(deactivateLanguage(language.id));

  const onCancel = isEditedLanguageNew
    ? () => dispatch(hideLanguageCreateForm(language.id))
    : () => dispatch(hideLanguageEditor(language.id));

  const onSaveAction = isEditedLanguageNew ? createNewLanguage : saveEditedLanguage;

  const onSave = (languageId: Uuid, formValues: LanguageFormShape) =>
    dispatch<any>(onSaveAction(languageId, formValues));

  const onDismissLongProcessingChangesWarning = () =>
    dispatch(upsertUserProperty(LongProcessingChangesWarningDismissedServerKey, 'true'));

  const formProps = useForm<LanguageFormShape>({
    defaultValues: {
      codename: language.codename,
      fallbackLanguageId: language.fallbackLanguageId,
      name: language.name,
    },
    resolver: createFormValidationResolver(languageValidationConfig, { otherLanguages }),
  });

  const { formState, handleSubmit, watch } = formProps;
  const submitForm = handleSubmit((values) =>
    onSave(language.id, {
      ...values,
      name: values.name.trim(),
    }),
  );

  const isCodenameModified = formState.dirtyFields.codename || false;
  const isFallbackLanguageChanged = formState.dirtyFields.fallbackLanguageId || false;
  const languageName = watch('name') ?? '';

  return (
    <LanguageEditor
      areLanguageRolesEnabled={areLanguageRolesEnabled}
      fallbackLanguageOptions={fallbackLanguageOptions}
      formProps={formProps}
      isCodenameOrFallbackModified={isCodenameModified || isFallbackLanguageChanged}
      isEditedLanguageNew={isEditedLanguageNew}
      isLanguageAlreadyUsed={isLanguageAlreadyUsed}
      isLanguageLimitReached={isLanguageLimitReached}
      isSetAsFallbackLanguage={isSetAsFallbackLanguage}
      language={language}
      languageName={languageName}
      onActivate={onActivate}
      onCancel={onCancel}
      onDeactivate={onDeactivate}
      onSubmit={submitForm}
      showLargeProjectSaveWarning={showLargeProjectSaveWarning}
      onDismissLongProcessingChangesWarning={onDismissLongProcessingChangesWarning}
    />
  );
};

LanguageEditorContainer.displayName = 'LanguageEditorContainer';
export { LanguageEditorContainer as LanguageEditor };
