import { Collection } from '@kontent-ai/utils';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import { isLegacyWebhookConfigurationEnabled as isLegacyWebhookConfigurationEnabledSelector } from '../../../../_shared/selectors/isLegacyWebhookConfigurationEnabled.ts';
import { getSelectedSubscription } from '../../../../_shared/selectors/subscriptionSelectors.ts';
import {
  getCurrentProject,
  getCurrentProjectPlan,
  getProjectInfo,
} from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { isCollectionsConfigurationVisible } from '../../../../_shared/utils/collections/isCollectionsConfigurationVisible.ts';
import { projectIdStorage } from '../../../../localStorages/projectIdStorage.ts';
import { isWebSpotlightEnabled as isWebSpotlightEnabledSelector } from '../../../webSpotlight/selectors/webSpotlightSelectors.ts';
import { useGetEnvironmentSettingsMenuFeatureAvailability } from '../../hooks/useGetEnvironmentSettingsMenuFeatureAvailability.ts';
import { canAccessInnovationLab } from '../../innovationLab/utils/canAccessInnovationLab.ts';
import { isEnvironmentGeneralSettingsTabVisibleSelector } from '../../selectors/environmentSettingsMenuCustomVisibilitiesSelectors.ts';
import {
  areSpacesEnabledForCurrentProject,
  isAuditLogEnabledForCurrentProjectPlan,
} from '../../utils/allowedFeaturesUtils.ts';
import {
  EnvironmentSettingsMenuStructureGroupItem,
  environmentSettingsMenuStructure,
} from '../../utils/environmentSettingsMenuStructure.tsx';
import { getFilteredOutMenuStructure } from '../../utils/environmentSettingsMenuStructureUtils.tsx';
import { initEnvironmentSettings } from '../actions/thunkEnvironmentSettingsActions.ts';
import { EnvironmentSettings as EnvironmentSettingsComponent } from '../components/EnvironmentSettings.tsx';

const findFirstAllowedChildRoute = (
  menuStructure: ReadonlyArray<EnvironmentSettingsMenuStructureGroupItem>,
): string | null => {
  return menuStructure[0]?.routePath ?? null;
};

export const EnvironmentSettings = () => {
  useThunkPromise(initEnvironmentSettings);

  const projectId = useSelector((s) => getCurrentProject(s).projectId);
  const subscriptionId = useSelector(
    (state) => getSelectedSubscription(state)?.subscriptionId ?? '',
  );
  const projectContainerId = useSelector((s) => getCurrentProject(s).projectContainerId);
  const areCollectionsEnabled = useSelector((s) =>
    isCollectionsConfigurationVisible(
      getCurrentProjectPlan(s),
      Collection.getValues(s.data.collections.byId),
    ),
  );
  const areGeneralSettingsVisible = useSelector(isEnvironmentGeneralSettingsTabVisibleSelector);
  const currentProjectPlan = useSelector((state) => getCurrentProjectPlan(state));
  const areSpacesEnabled = areSpacesEnabledForCurrentProject(currentProjectPlan);
  const isAuditLogEnabled = isAuditLogEnabledForCurrentProjectPlan(currentProjectPlan);
  const currentEnvironment = useSelector(getCurrentProject);

  const projectInfo = useSelector((state) =>
    getProjectInfo(state, projectId ?? String(projectIdStorage.load())),
  );
  const areLegacyWebhooksEnabled = useSelector(isLegacyWebhookConfigurationEnabledSelector);
  const isWebSpotlightEnabled = useSelector(isWebSpotlightEnabledSelector);
  const environmentSettingsMenuFeatureAvailability =
    useGetEnvironmentSettingsMenuFeatureAvailability(
      projectInfo,
      currentProjectPlan,
      areLegacyWebhooksEnabled,
      isWebSpotlightEnabled,
    );

  const firstAllowedChildRoute = findFirstAllowedChildRoute(
    getFilteredOutMenuStructure(
      currentEnvironment,
      environmentSettingsMenuStructure(),
      environmentSettingsMenuFeatureAvailability,
    ),
  );
  const isInnovationLabAccessible = useSelector((state) =>
    canAccessInnovationLab(state.data.user, getCurrentProject(state)),
  );

  return firstAllowedChildRoute ? (
    <EnvironmentSettingsComponent
      areCollectionsEnabled={areCollectionsEnabled}
      areLegacyWebhooksEnabled={areLegacyWebhooksEnabled}
      areGeneralSettingsVisible={areGeneralSettingsVisible}
      areSpacesEnabled={areSpacesEnabled}
      firstAllowedChildRoute={firstAllowedChildRoute}
      isAuditLogEnabled={isAuditLogEnabled}
      isInnovationLabAccessible={isInnovationLabAccessible}
      projectId={projectId}
      subscriptionId={subscriptionId}
      projectContainerId={projectContainerId}
    />
  ) : null;
};
