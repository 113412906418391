import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { ActiveCapabilityType } from '../../../../../../_shared/models/activeCapability.type.ts';
import { getCurrentProjectId } from '../../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { hasActiveVariantCapability } from '../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { canUserViewAnyActiveLanguage } from '../../../../../../_shared/utils/permissions/canUserViewAnyActiveLanguage.ts';
import { getCannotViewItemMessage } from '../../../../../../_shared/utils/permissions/getCannotViewItemMessage.ts';
import {
  getLinkedContentItemPath,
  pickContentItemRoute,
} from '../../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { useLivePreviewPreferenceStorage } from '../../../../../../localStorages/useLivePreviewPreferenceStorage.ts';
import { CannotCreateNewVariantForItemError } from '../../../../../itemEditor/constants/errorMessages.ts';
import { getItemSmartLink } from '../../../../../smartLink/utils/smartLinkUtils.ts';
import {
  ContentLinkDetail as ContentLinkDetailComponent,
  ContentLinkDetailProps,
} from '../components/ContentLinkDetail.tsx';

type ContentLinkDetailContainerProps = ContentLinkDetailProps &
  Readonly<{
    currentPath: string;
    itemId: Uuid;
  }>;

export const ContentLinkDetail = (props: ContentLinkDetailContainerProps) => {
  const currentProjectId = useSelector(getCurrentProjectId);
  const { isLivePreviewPreferred } = useLivePreviewPreferenceStorage(currentProjectId);

  const contentItem = useSelector((s) => s.data.listingContentItems.byId.get(props.itemId));
  const contentTypeName = useSelector(
    (s) =>
      (contentItem ? s.data.contentTypes.byId.get(contentItem.item.typeId)?.name : null) ?? null,
  );
  const contentItemPath = props.isViewOnly
    ? getItemSmartLink(props.currentPath, props.itemId)
    : getLinkedContentItemPath(
        props.currentPath,
        props.itemId,
        pickContentItemRoute(isLivePreviewPreferred),
      );

  const canCreateContent = contentItem
    ? hasActiveVariantCapability(ActiveCapabilityType.CreateContent, contentItem)
    : false;
  const canViewContent = useSelector(canUserViewAnyActiveLanguage);

  return (
    <ContentLinkDetailComponent
      canCreateContent={canCreateContent}
      cannotCreateDisabledMessage={
        canCreateContent ? undefined : CannotCreateNewVariantForItemError
      }
      cannotViewDisabledMessage={contentItem ? getCannotViewItemMessage(contentItem) : undefined}
      canViewContent={canViewContent}
      contentItem={contentItem}
      contentItemPath={contentItemPath}
      contentTypeName={contentTypeName}
      disabled={props.disabled}
      isViewOnly={props.isViewOnly}
      onEdit={props.onEdit}
      onUnlink={props.onUnlink}
      scrollId={props.scrollId}
      status={props.status}
    />
  );
};
