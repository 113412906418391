import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { WorkflowsRoute, WorkflowsRouteParams } from '../../../../_shared/constants/routePaths.ts';
import { useDataSelector } from '../../../../_shared/hooks/useDataSelector.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { getCurrentProject } from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { Capability, getUserCapability } from '../../../../_shared/utils/permissions/capability.ts';
import { buildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { hasProjectMultipleWorkflows } from '../../../../_shared/utils/workflow/hasProjectMultipleWorkflows.ts';
import { CollectionWorkflowLimitations as CollectionWorkflowLimitationsComponent } from '../components/BarItems/CollectionWorkflowLimitations.tsx';
import { splitCollectionApplicableWorkflows } from '../utils/getCollectionApplicableWorkflows.ts';

interface ICollectionWorkflowLimitationsProps {
  readonly collectionId: Uuid;
}

export const CollectionWorkflowLimitations: React.FC<
  React.PropsWithChildren<ICollectionWorkflowLimitationsProps>
> = ({ collectionId }) => {
  const canManageWorkflows = useSelector((s) =>
    getUserCapability(getCurrentProject(s)).can(Capability.ConfigureWorkflow),
  );
  const isVisible = useDataSelector((state) => hasProjectMultipleWorkflows(state.workflows.byId));
  const workflows = useSelector((s) => s.data.workflows.byId);
  const workflowsPath = useSelector((s) =>
    buildPath<WorkflowsRouteParams>(WorkflowsRoute, { projectId: s.sharedApp.currentProjectId }),
  );
  const contentTypes = useSelector((s) => s.data.contentTypes.byId);

  const { limitedWorkflows, unlimitedWorkflows } = useMemo(
    () => splitCollectionApplicableWorkflows(workflows, collectionId),
    [workflows, collectionId],
  );

  if (!isVisible) {
    return null;
  }

  const bottomLabel = canManageWorkflows ? (
    <>
      To change workflows for collections, go to <Link to={workflowsPath}>Workflow settings</Link>.
    </>
  ) : (
    <>To change workflows for collections, ask your Project manager.</>
  );

  return (
    <CollectionWorkflowLimitationsComponent
      bottomLabel={bottomLabel}
      contentTypes={contentTypes}
      limitedWorkflows={limitedWorkflows}
      unlimitedWorkflows={unlimitedWorkflows}
    />
  );
};

CollectionWorkflowLimitations.displayName = 'CollectionWorkflowLimitations';
