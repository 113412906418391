import { Stack } from '@kontent-ai/component-library/Stack';
import { ShortcutsConfig, useHotkeys } from '@kontent-ai/component-library/hooks';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { useAttachRef } from '@kontent-ai/hooks';
import React from 'react';
import { ObjectWithDataAttribute } from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type Props = Readonly<{
  dataUiAttribute?: ObjectWithDataAttribute;
  onReset: () => void;
  onSubmit: (event: React.FormEvent | KeyboardEvent) => void;
  submitUsingControlEnter?: boolean;
}>;

export const FormPane = React.forwardRef<HTMLFormElement, React.PropsWithChildren<Props>>(
  ({ children, dataUiAttribute, onReset, onSubmit, submitUsingControlEnter }, ref) => {
    const { refObject, refToForward } = useAttachRef(ref);
    useHotkeys(
      {
        [ShortcutsConfig.ControlEnter]: submitUsingControlEnter ? onSubmit : undefined,
        [ShortcutsConfig.Enter]: submitUsingControlEnter ? undefined : onSubmit,
        [ShortcutsConfig.Escape]: onReset,
      },
      { ref: refObject },
    );

    return (
      <form onReset={onReset} onSubmit={onSubmit} ref={refToForward} {...dataUiAttribute}>
        <Stack spacing={Spacing.XL}>{children}</Stack>
      </form>
    );
  },
);
