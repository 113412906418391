import {
  getSubscriptionAvailableProjectLocations,
  getSubscriptionDefaultProjectLocation,
} from '../../../_shared/selectors/subscriptionSelectors.ts';
import { IStore } from '../../../_shared/stores/IStore.type.ts';
import { ISubscription } from '../../../data/models/subscriptions/Subscription.ts';
import { INewProjectFormShape } from '../models/INewProjectFormShape.type.ts';
import { createNameForClonedProject, getProjectDetail } from '../utils/copyProjectUtils.ts';

export const getCloneProjectFormDefaultValues = (
  state: IStore,
  sourceProjectId: Uuid,
  activeSubscriptions: ReadonlyArray<ISubscription>,
): INewProjectFormShape => {
  const sourceProjectDetail = sourceProjectId
    ? getProjectDetail(state.data.projects.byId, sourceProjectId)
    : undefined;

  const sourceProjectSubscriptionId = sourceProjectDetail?.subscriptionId;

  const availableDestinationSubscriptions = sourceProjectSubscriptionId
    ? activeSubscriptions.filter(
        (sub: ISubscription) => sub.subscriptionId === sourceProjectSubscriptionId,
      )
    : activeSubscriptions;

  const defaultDestinationSubscriptionId =
    availableDestinationSubscriptions[0]?.subscriptionId ?? '';

  const availableProjectLocations = getSubscriptionAvailableProjectLocations(
    state,
    defaultDestinationSubscriptionId,
  );

  const sourceProjectLocation = availableProjectLocations.find(
    (loc) => loc.projectLocationId === sourceProjectDetail?.projectLocationId,
  );

  const projectLocation = sourceProjectLocation
    ? sourceProjectLocation
    : getSubscriptionDefaultProjectLocation(state, defaultDestinationSubscriptionId);

  const defaultProjectLocationId = projectLocation?.projectLocationId || '';
  const sourceProjectName = sourceProjectDetail?.projectName;
  const defaultProjectName =
    (sourceProjectName && createNameForClonedProject(sourceProjectName)) || '';

  return {
    projectLocationId: defaultProjectLocationId || '',
    subscriptionId: defaultDestinationSubscriptionId || '',
    includeContent: true,
    projectName: defaultProjectName,
    projectTemplateId: sourceProjectId,
  };
};
