import { useCallback } from 'react';
import { contentGroupCodenameRenamed } from '../../../applications/contentModels/contentTypes/actions/contentTypesActions.ts';
import { CodenameContentTypeButton } from '../../components/Codename/CodenameContentTypeButton.tsx';
import { ValidationConstants } from '../../constants/validationConstants.ts';
import { useDispatch } from '../../hooks/useDispatch.ts';
import { useSelector } from '../../hooks/useSelector.ts';
import { IStore } from '../../stores/IStore.type.ts';
import { ArrowPosition } from '../../uiComponents/Popover/Popover.tsx';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';
import { shallowEqual } from '../../utils/shallowEqual.ts';

const getSelectedContentGroupCodename = (
  s: IStore,
  selectedContentGroupId: string | null,
): string | null | undefined => {
  const contentGroups = s.contentModelsApp.typeEditor.editedType.contentGroups;
  return contentGroups.find((contentGroup) => contentGroup?.id === selectedContentGroupId)
    ?.codename;
};

const getContentGroupCodenames = (s: IStore): ReadonlySet<string> => {
  const contentGroups = s.contentModelsApp.typeEditor.editedType.contentGroups;
  return new Set(contentGroups.map((contentGroup) => contentGroup?.codename || ''));
};

export const CodenameContentGroupButton = () => {
  const selectedContentGroupId = useSelector(
    (s) => s.contentModelsApp.contentTypes.editor.selectedContentGroupId,
  );
  const selectedContentGroupCodename = useSelector((s) =>
    getSelectedContentGroupCodename(s, selectedContentGroupId),
  );
  const contentGroupCodenames = useSelector((s) => getContentGroupCodenames(s), shallowEqual);

  const dispatch = useDispatch();

  const onCodenameSave = useCallback(
    (newContentGroupCodename: string, onSaved: () => void): void => {
      if (selectedContentGroupId) {
        dispatch(contentGroupCodenameRenamed(selectedContentGroupId, newContentGroupCodename));
      }
      onSaved();
    },
    [selectedContentGroupId],
  );

  if (!selectedContentGroupId) {
    return null;
  }

  return (
    <CodenameContentTypeButton
      codename={selectedContentGroupCodename}
      codenamePopoverArrowPosition={ArrowPosition.Start}
      isCodenameEditable
      maxCodenameLength={ValidationConstants.ContentGroupCodeNameMaxLength}
      onCodenameSave={onCodenameSave}
      popoverDialogClassNames="popover__dialog--is-over-content-group"
      relatedCodenames={contentGroupCodenames}
      tooltipPlacement="top-start"
      triggerClassNames="content-type-group-tab__codename-button"
      {...getDataUiActionAttribute(DataUiAction.GetCodename)}
    />
  );
};
