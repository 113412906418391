import { Button } from '@kontent-ai/component-library/Button';
import React from 'react';
import { EmptyState } from '../../../../_shared/components/EmptyState/EmptyState.tsx';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

export interface IRelationsEmptyStateStateProps {
  readonly projectName: string;
}

export interface IRelationsEmptyStateDispatchProps {
  readonly onCallToAction: () => void;
}

export type RelationsEmptyStateProps = IRelationsEmptyStateStateProps &
  IRelationsEmptyStateDispatchProps;

export const RelationsEmptyState: React.FC<RelationsEmptyStateProps> = ({
  onCallToAction,
  projectName,
}) => (
  <EmptyState>
    <EmptyState.Title>Wondering how your content relates to each other?</EmptyState.Title>
    <EmptyState.Content>
      <EmptyState.ContentGroup>
        <p>
          Add more clarity to your linked content by selecting a home item for{' '}
          <strong>{projectName}</strong>. The home item is a content item representing your
          homepage, main screen of your app, or basically anything that’s on the top of your content
          structure.
        </p>
        <p>Try it out! You can always change the home item later.</p>
      </EmptyState.ContentGroup>
    </EmptyState.Content>
    <EmptyState.Footer>
      <Button
        buttonStyle="primary"
        onClick={onCallToAction}
        {...getDataUiActionAttribute(DataUiAction.CreateFirst)}
      >
        Select a home item
      </Button>
    </EmptyState.Footer>
  </EmptyState>
);

RelationsEmptyState.displayName = 'RelationsEmptyState';
