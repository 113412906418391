import { Box } from '@kontent-ai/component-library/Box';
import { MultiSelect } from '@kontent-ai/component-library/MultiSelect';
import { ISelectItem, ItemId, Option } from '@kontent-ai/component-library/Selects';
import { SingleSelect } from '@kontent-ai/component-library/SingleSelect';
import { BaseStackProps, Stack } from '@kontent-ai/component-library/Stack';
import { DefaultTag, Tag } from '@kontent-ai/component-library/Tag';
import {
  Spacing,
  colorAlertBackgroundInverse,
  sizeSelectMaxWidth,
} from '@kontent-ai/component-library/tokens';
import { animated, useTransition } from '@react-spring/web';
import React, { useState } from 'react';
import { MultipleChoiceMode } from '../../../../../../../../../_shared/models/MultipleChoiceModeEnum.ts';
import {
  ConditionAction,
  MultipleChoiceConditionOperator,
} from '../../../../../../../../../_shared/models/utils/TypeElementCondition.ts';
import {
  DataUiCollection,
  DataUiElement,
  getDataUiCollectionAttribute,
} from '../../../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { TypeElementConditionValidationResult } from '../../../../../../utils/typeElementValidators/types/TypeElementWithConditionValidationResult.ts';
import { LimitationCategory } from '../../LimitationCategory.tsx';
import { LimitationCategoryList } from '../../LimitationCategoryList.tsx';
import { TriggerElement, TriggerElementOption } from '../types/TriggerElement.type.ts';
import { getUiState } from '../utils/getUiState.ts';

type Props = {
  readonly action: ConditionAction;
  readonly conditionValidationResult: TypeElementConditionValidationResult;
  readonly isActive: boolean;
  readonly isElementNonLocalizable: boolean;
  readonly isTrigger: boolean;
  readonly onActionChange: (action: ConditionAction) => void;
  readonly onOperatorChange: (operator: MultipleChoiceConditionOperator) => void;
  readonly onOptionIdsChange: (optionIds: UuidArray) => void;
  readonly onToggleIsActive: () => void;
  readonly onTriggerSelectionChange: (triggerId: Uuid | null) => void;
  readonly operator: MultipleChoiceConditionOperator;
  readonly selectedOptionIds: UuidArray;
  readonly selectedTriggerElement: TriggerElement | null;
  readonly possibleTriggerElements: readonly TriggerElement[];
};

export const ConditionConfiguration: React.FC<Props> = ({
  action,
  conditionValidationResult,
  isActive,
  isElementNonLocalizable,
  isTrigger,
  onActionChange,
  onOperatorChange,
  onOptionIdsChange,
  onToggleIsActive,
  onTriggerSelectionChange,
  operator,
  selectedOptionIds,
  selectedTriggerElement,
  possibleTriggerElements,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const triggerItems = possibleTriggerElements.map(
    (trigger): TriggerItem => ({
      id: trigger.id,
      label: trigger.name,
      disabledTooltipText: getDisabledTooltipTextForTrigger(trigger, isElementNonLocalizable),
    }),
  );

  const disabledTriggerIds = triggerItems
    .filter((triggerItem) => !!triggerItem.disabledTooltipText)
    .map((el) => el.id);

  const showContainsItemsSelect =
    selectedTriggerElement && selectedTriggerElement.mode === MultipleChoiceMode.Multiple;

  const noPossibleTrigger = possibleTriggerElements.length === 0;
  const noOptionsInSelectedTrigger = selectedTriggerElement?.options.length === 0;

  const uiState = getUiState(
    isTrigger,
    isActive,
    noPossibleTrigger,
    noOptionsInSelectedTrigger,
    conditionValidationResult,
  );

  const transitions = useTransition(showContainsItemsSelect, {
    config: { duration: 500 },
    from: {
      opacity: 0,
      maxHeight: 0,
    },
    enter: [
      {
        opacity: 1,
        maxHeight: 500,
      },
      {
        // Prevent component to overflow
        maxHeight: 'none',
      },
    ],
  });

  const handleVisibilityChange = (newVisibilityId: string | null): void => {
    if (newVisibilityId && isConditionActionType(newVisibilityId)) {
      onActionChange(newVisibilityId);
    }
  };

  const handleOperatorChange = (updatedOperator: string | null): void => {
    if (updatedOperator && isMultipleChoiceConditionOperator(updatedOperator)) {
      onOperatorChange(updatedOperator);
    }
  };

  const handleOptionsChange = (itemIds: ReadonlySet<ItemId> | null): void => {
    onOptionIdsChange(itemIds ? Array.from(itemIds) : []);
  };

  return (
    <LimitationCategoryList>
      <LimitationCategory
        dataUiElement={DataUiElement.ContentTypeElementConditionConfiguration}
        isAllowed={isActive}
        isDisabled={uiState.condition.isDisabled}
        title="Set visibility condition for this element"
        tooltipText={uiState.condition.tooltipText}
        onChange={() => onToggleIsActive()}
        onExpand={() => setIsExpanded(true)}
        onCollapse={() => setIsExpanded(false)}
        isExpanded={isExpanded}
      >
        <Box paddingTop={Spacing.XL} paddingX={Spacing.S}>
          <Box maxWidth={sizeSelectMaxWidth}>
            <Stack {...stackProps}>
              <SingleSelect<VisibilityItem>
                inputState={uiState.visibility.inputState}
                items={VisibilityItems}
                label="Visibility condition"
                onSelectionChange={handleVisibilityChange}
                selectedItemId={action}
                tooltipText={uiState.visibility.tooltipText}
                {...getDataUiCollectionAttribute(DataUiCollection.ConditionAction)}
              />
              <SingleSelect<TriggerItem>
                disabledItemIds={disabledTriggerIds}
                inputState={uiState.trigger.inputState}
                items={triggerItems}
                label="if"
                onSelectionChange={onTriggerSelectionChange}
                placeholder="Select element"
                renderMenuOption={(optionProps) => (
                  <Option {...optionProps} tooltipPlacement="right" />
                )}
                selectedItemId={selectedTriggerElement?.id || null}
                tooltipText={uiState.trigger.tooltipText}
                {...getDataUiCollectionAttribute(DataUiCollection.ConditionTriggerElement)}
              />
              {transitions((style) => (
                // Use the "roll-out" animation only if an extra option select is to be displayed
                <animated.div style={showContainsItemsSelect ? style : undefined}>
                  <Stack {...stackProps}>
                    {showContainsItemsSelect && (
                      <SingleSelect<OperatorItem>
                        inputState={uiState.operator.inputState}
                        items={Operators}
                        label="contains"
                        onSelectionChange={handleOperatorChange}
                        selectedItemId={operator}
                        tooltipText={uiState.operator.tooltipText}
                        {...getDataUiCollectionAttribute(DataUiCollection.ConditionOperator)}
                      />
                    )}
                    {selectedTriggerElement && (
                      <MultiSelect<TriggerElementOption>
                        inputState={uiState.triggerOptions.inputState}
                        items={selectedTriggerElement?.options}
                        label={
                          selectedTriggerElement.mode === MultipleChoiceMode.Single
                            ? 'contains one of the selected options:'
                            : 'of the selected options:'
                        }
                        onSelectionChange={handleOptionsChange}
                        placeholder="Select options"
                        renderSelectedOption={(_id, option, defaultTagProps) =>
                          option.isDeleted ? (
                            <Tag background={colorAlertBackgroundInverse} {...defaultTagProps} />
                          ) : (
                            <DefaultTag {...defaultTagProps} />
                          )
                        }
                        selectedItemIds={selectedOptionIds}
                        tooltipText={uiState.triggerOptions.tooltipText}
                        {...getDataUiCollectionAttribute(DataUiCollection.ConditionTriggerOptions)}
                      />
                    )}
                  </Stack>
                </animated.div>
              ))}
            </Stack>
          </Box>
        </Box>
      </LimitationCategory>
    </LimitationCategoryList>
  );
};

ConditionConfiguration.displayName = 'ConditionConfiguration';

const stackProps: BaseStackProps = {
  align: 'start',
  spacing: Spacing.L,
};

const isConditionActionType = (value: string): value is ConditionAction =>
  Object.values(ConditionAction).includes(value as ConditionAction);

const isMultipleChoiceConditionOperator = (
  operator: string,
): operator is MultipleChoiceConditionOperator =>
  Object.values(MultipleChoiceConditionOperator).includes(
    operator as MultipleChoiceConditionOperator,
  );

interface VisibilityItem extends ISelectItem<VisibilityItem> {}
const VisibilityItems: readonly VisibilityItem[] = [
  {
    id: ConditionAction.Show,
    label: 'Show this element',
  },
  {
    id: ConditionAction.Hide,
    label: 'Hide this element',
  },
];

interface TriggerItem extends ISelectItem<TriggerItem> {
  readonly disabledTooltipText?: string;
}

interface OperatorItem extends ISelectItem<OperatorItem> {}
const Operators: readonly OperatorItem[] = [
  {
    id: MultipleChoiceConditionOperator.Any,
    label: 'at least one',
  },
  {
    id: MultipleChoiceConditionOperator.All,
    label: 'all',
  },
];

const getDisabledTooltipTextForTrigger = (
  triggerElement: TriggerElement,
  isCurrentElementNonLocalizable: boolean,
): string | undefined => {
  if (
    triggerElement.hasActiveCondition &&
    triggerElement.isNonLocalizable !== isCurrentElementNonLocalizable
  ) {
    return 'You can’t use this element because it contains different localization settings and a visibility condition.';
  }
  if (triggerElement.hasActiveCondition) {
    return 'You can’t use this element because it contains a visibility condition.';
  }
  if (triggerElement.isNonLocalizable !== isCurrentElementNonLocalizable) {
    return 'You can’t use this element because it contains different localization settings.';
  }

  return undefined;
};
