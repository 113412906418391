import React from 'react';
import { BarItemAnimation } from '../../../../_shared/components/BarItems/BarItemAnimation.tsx';
import {
  DraggableNodeBar,
  IDraggableNodeBarContainerOwnProps,
} from '../containers/nodeBarItems/DraggableNodeBar.tsx';
import { NodeEditor } from '../containers/nodeEditors/NodeEditor.tsx';

interface ISitemapNodeBarProps extends IDraggableNodeBarContainerOwnProps {
  readonly isBeingEdited: boolean;
}

export const SitemapNodeBar: React.FC<ISitemapNodeBarProps> = ({
  isBeingEdited,
  ...nodeBarProps
}) => (
  <BarItemAnimation
    estimatedMaxHeightWhenExpanded={250}
    renderCollapsed={() => <DraggableNodeBar {...nodeBarProps} />}
    renderExpanded={() => <NodeEditor nodeId={nodeBarProps.nodeId} />}
    shouldBeExpanded={isBeingEdited}
  />
);

SitemapNodeBar.displayName = 'SitemapNodeBar';
