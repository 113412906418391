import 'focus-visible';
import 'styles';
import './configs/config.ts';
import { assert } from '@kontent-ai/utils';
import { createRoot } from 'react-dom/client';
import { Root } from './app/Root.tsx';

const reactContainerElementId = 'react-container';
const reactContainerElement = document.getElementById(reactContainerElementId);
assert(reactContainerElement, () => `Element #${reactContainerElementId} was not found.`);
createRoot(reactContainerElement).render(<Root />);
