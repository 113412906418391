import React, { useCallback, useState, useContext } from 'react';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { LivePreviewConfigurationContext } from '../../../itemEditor/features/ContentItemEditing/features/contentItemPreview/context/LivePreviewConfigurationContext.tsx';
import { setLivePreviewConfiguration } from '../actions/thunkEnvironmentSettingsGeneralActions.ts';
import { LivePreviewStatusTile as Component } from '../components/LivePreviewStatusTile.tsx';

export const LivePreviewStatusTile: React.FC = () => {
  const dispatch = useDispatch();
  const [isSettingInProgress, setIsSettingInProgress] = useState<boolean>(false);
  const { isLivePreviewEnabled } = useContext(LivePreviewConfigurationContext);

  const toggleLivePreviewStatus = useCallback(async () => {
    setIsSettingInProgress(true);
    await dispatch(setLivePreviewConfiguration(!isLivePreviewEnabled));
    setIsSettingInProgress(false);
  }, [isLivePreviewEnabled]);

  return (
    <Component
      isSettingToggleInProgress={isSettingInProgress}
      isLivePreviewEnabled={isLivePreviewEnabled}
      toggleLivePreviewStatus={toggleLivePreviewStatus}
    />
  );
};

LivePreviewStatusTile.displayName = 'LivePreviewStatusTile';
