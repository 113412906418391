import { getCurrentProject } from '../../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import {
  Capability,
  getUserCapability,
} from '../../../../../../_shared/utils/permissions/capability.ts';

export const canUserCreateContentTypes = (state: IStore): boolean => {
  const userCapability = getUserCapability(getCurrentProject(state));
  return userCapability.can(Capability.ConfigureContentTypes);
};
