import React from 'react';
import { Warning } from '../../../../../_shared/components/infos/Warning.tsx';

export const TaxonomyWarning: React.FC = () => (
  <div className="taxonomy-warning">
    <Warning subtitle="Taxonomy group is used with published content">
      <p>
        You are about to make changes to the taxonomy group that is used with published content.
      </p>
      <p>
        Keep in mind that any change you make to this taxonomy group may affect how your published
        content is displayed on your website or mobile app.
      </p>
    </Warning>
  </div>
);

TaxonomyWarning.displayName = 'TaxonomyWarning';
