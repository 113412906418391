import { IconButton } from '@kontent-ai/component-library/Button';
import React, { RefObject, useState } from 'react';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { getCurrentProject } from '../../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { DropDownFrame } from '../../../../../../_shared/uiComponents/DropDown/DropDownFrame.tsx';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  Capability,
  getUserCapability,
} from '../../../../../../_shared/utils/permissions/capability.ts';
import { IAsset } from '../../../../../../data/models/assets/Asset.ts';
import { AssetCodenameDialog as AssetCodenameDialogComponent } from '../../../containers/AssetEditing/AssetCodenameDialog.tsx';
import { AssetActionDropdown as AssetActionDropdownComponent } from './AssetActionDropdown.tsx';
import { AssetUrlDialog as AssetUrlDialogComponent } from './AssetUrlDialog.tsx';

enum AssetActionMenuSection {
  CopyAssetUrl = 'CopyAssetUrl',
  Disambiguation = 'Disambiguation',
  Codename = 'Codename',
}

type Props = {
  readonly asset: IAsset;
  readonly assetDeleteDisabledMessage: string;
  readonly assetReplaceDisabledMessage: string | undefined;
  readonly deleteDisabled?: boolean;
  readonly isAssetReplaceAvailable: boolean;
  readonly onDelete: () => void;
  readonly onDownload: (asset: IAsset) => void;
  readonly onReplace: () => void;
};

enum AssetActionContent {
  AssetUrlDialog = 'AssetUrlDialog',
  AssetActionDropdown = 'AssetActionDropdown',
  AssetCodenameDialog = 'AssetCodenameDialog',
}

export const AssetActionMenu: React.FC<Props> = ({
  asset,
  deleteDisabled,
  onDelete,
  onDownload,
  onReplace,
  isAssetReplaceAvailable,
  assetDeleteDisabledMessage,
  assetReplaceDisabledMessage,
}) => {
  const [currentMenu, setCurrentMenu] = useState(AssetActionMenuSection.Disambiguation);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const hideMenu = () => setIsMenuOpen(false);

  const showMainMenu = () => {
    setCurrentMenu(AssetActionMenuSection.Disambiguation);
    setIsMenuOpen(true);
  };

  const currentProject = useSelector(getCurrentProject);
  const isUserAuthorizedToEditAsset = getUserCapability(currentProject).canOneOf([
    Capability.CreateAssets,
    Capability.ManageAssets,
    Capability.UpdateAssets,
  ]);

  const handleDownload = () => {
    onDownload(asset);
  };

  const handleDelete = () => {
    if (!deleteDisabled) {
      onDelete();
    }
  };

  const renderSelector = (ref: RefObject<HTMLButtonElement>): JSX.Element => (
    <IconButton
      aria-label="Open more actions"
      buttonState="default"
      buttonStyle="secondary"
      iconName="ThreeDotsVertical"
      ref={ref}
      size="medium"
      tooltipPlacement="bottom-end"
      tooltipText="More actions"
      onClick={isMenuOpen ? hideMenu : showMainMenu}
      {...getDataUiActionAttribute(DataUiAction.MoreActions)}
    />
  );

  const renderModalContent = (): JSX.Element | null => {
    switch (currentMenu) {
      case AssetActionMenuSection.CopyAssetUrl: {
        return (
          <AssetUrlDialogComponent
            key={AssetActionContent.AssetUrlDialog}
            asset={asset}
            onClick={showMainMenu}
            onCopyCompleted={hideMenu}
          />
        );
      }

      case AssetActionMenuSection.Disambiguation: {
        return (
          <AssetActionDropdownComponent
            key={AssetActionContent.AssetActionDropdown}
            onClick={hideMenu}
            onDelete={handleDelete}
            asset={asset}
            deleteDisabled={deleteDisabled}
            onDownloadClick={handleDownload}
            onReplace={onReplace}
            isAssetReplaceAvailable={isAssetReplaceAvailable}
            isUserAuthorizedToEditAsset={isUserAuthorizedToEditAsset}
            assetDeleteDisabledMessage={assetDeleteDisabledMessage}
            assetReplaceDisabledMessage={assetReplaceDisabledMessage}
            onCopyAssetUrl={() => setCurrentMenu(AssetActionMenuSection.CopyAssetUrl)}
            onCodenameClick={() => setCurrentMenu(AssetActionMenuSection.Codename)}
          />
        );
      }

      case AssetActionMenuSection.Codename: {
        return (
          <AssetCodenameDialogComponent
            key={AssetActionContent.AssetCodenameDialog}
            asset={asset}
            onClick={showMainMenu}
            onCopyCompleted={hideMenu}
          />
        );
      }
    }
  };

  return (
    <DropDownFrame
      hasTrailingElements
      isOptionListVisible={isMenuOpen}
      onClickOutside={hideMenu}
      optionListDataUiAttributes={getDataUiActionAttribute(DataUiAction.MoreActions)}
      renderContent={renderModalContent}
      renderSelector={renderSelector}
    />
  );
};
