import { RouterLink } from '@kontent-ai/component-library/Anchor';
import styled from 'styled-components';

export const StyledRouterLink = styled(RouterLink)`
  z-index: 2;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
`;
