import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { queryFilterCleared } from '../../actions/assetLibraryActions.ts';
import { AssetFilter as AssetFilterComponent } from '../../components/AssetListing/AssetFilter.tsx';
import { isAssetLibraryVisibleFilterEmpty } from '../../models/IAssetLibraryQuery.ts';

export const AssetFilter = (props: Readonly<{ onHideFilter?: () => void }>) => {
  const isFilterEmpty = useSelector((s) =>
    isAssetLibraryVisibleFilterEmpty(s.assetLibraryApp.query),
  );
  const dispatch = useDispatch();
  const onFilterCleared = isFilterEmpty ? undefined : () => dispatch(queryFilterCleared());

  return <AssetFilterComponent onFilterCleared={onFilterCleared} {...props} />;
};
