import { Action } from '../../../@types/Action.type.ts';
import { SubscriptionPropertyServerModel } from '../../../repositories/serverModels/SubscriptionPropertyServerModel.type.ts';
import { Shared_LoadSubscriptionProperties_Finished } from '../../constants/sharedActionTypes.ts';
import {
  SubscriptionPropertiesModel,
  SubscriptionPropertyStateKeysByServerKeys,
  SubscriptionPropertyTypeGuardsByServerKeys,
  isKnownSubscriptionPropertyServerKey,
} from '../../models/SubscriptionPropertiesModel.ts';

const initialState: SubscriptionPropertiesModel = {
  AssetCollectionMandatory: null,
  LegacyWebhooks: null,
  SendFinalReport: null,
  AreAutomaticPopupsDisabled: null,
  IsProjectDataRestrictedToSpecificDatacenter: null,
};

const mapPropertyToState = (
  state: SubscriptionPropertiesModel,
  { key, value }: SubscriptionPropertyServerModel,
): SubscriptionPropertiesModel => {
  if (
    !isKnownSubscriptionPropertyServerKey(key) ||
    !SubscriptionPropertyTypeGuardsByServerKeys[key](value)
  ) {
    return state;
  }

  return {
    ...state,
    [SubscriptionPropertyStateKeysByServerKeys[key]]: value,
  };
};

export const subscriptionProperties = (
  state = initialState,
  action: Action,
): SubscriptionPropertiesModel => {
  switch (action.type) {
    case Shared_LoadSubscriptionProperties_Finished: {
      return action.payload.properties.reduce(mapPropertyToState, initialState);
    }

    default:
      return state;
  }
};
