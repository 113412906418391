import {
  getCurrentProjectPlan,
  getProjectInfo,
} from '../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { IStore } from '../../../_shared/stores/IStore.type.ts';
import { projectIdStorage } from '../../../localStorages/projectIdStorage.ts';
import { isWebSpotlightEnabled as isWebSpotlightEnabledSelector } from '../../webSpotlight/selectors/webSpotlightSelectors.ts';
import { isEnvironmentGeneralSettingsTabVisible } from '../utils/environmentSettingsMenuCustomVisibilities.ts';

export const isEnvironmentGeneralSettingsTabVisibleSelector = (
  state: IStore,
  environmentId?: Uuid,
): boolean => {
  const projectInfo = getProjectInfo(state, environmentId ?? String(projectIdStorage.load()));
  const currentProjectPlan = getCurrentProjectPlan(state);
  const isWebSpotlightEnabled = isWebSpotlightEnabledSelector(state);
  return isEnvironmentGeneralSettingsTabVisible(
    state.data.user,
    projectInfo,
    currentProjectPlan,
    isWebSpotlightEnabled,
  );
};
