import { Card } from '@kontent-ai/component-library/Card';
import { TextSkeleton } from '@kontent-ai/component-library/Skeleton';
import { Collection } from '@kontent-ai/utils';
import React from 'react';
import { EmptyState } from '../../../../_shared/components/EmptyState/EmptyState.tsx';
import { getWorkflowStepsById } from '../../../../_shared/selectors/workflowSelectors.ts';
import { Workflow } from '../../../../data/models/workflow/Workflow.ts';
import {
  ArchivedWorkflowStepId,
  PublishedWorkflowStepId,
} from '../../../../data/models/workflow/WorkflowStep.ts';
import { renderWorkflowStepColor } from '../../../../data/models/workflow/utils/renderWorkflowStepColor.ts';
import { IHorizontalStackedBarData } from '../../types/IHorizontalStackedBarData.type.ts';
import { TimeSpentInWorkflowStepByWorkflow } from '../getTimeSpentInWorkflowStepByWorkflowFromServerModel.ts';
import { getContentPaceLoadingState } from '../utils/getContentPaceLoadingState.ts';
import { ContentPaceBarChart } from './ContentPaceBarChart/ContentPaceBarChart.tsx';

const getChartDataForWorkflow = (
  workflow: Workflow,
  totalDurationInWorkflowStep: TimeSpentInWorkflowStepByWorkflow | null,
): ReadonlyArray<IHorizontalStackedBarData> | null => {
  if (totalDurationInWorkflowStep === null) {
    return null;
  }

  const workflowStepMap = Collection.removeMany(getWorkflowStepsById(workflow), [
    PublishedWorkflowStepId,
    ArchivedWorkflowStepId,
  ]);
  const steps = [...workflowStepMap.values()];

  return steps.map((workflowStep) => {
    const durationInSeconds = totalDurationInWorkflowStep[workflow.id]?.[workflowStep.id] ?? 0;
    const durationInHours = Math.floor(durationInSeconds / 60 / 60);

    return {
      id: workflowStep.id,
      value: durationInHours,
      label: workflowStep.name,
      color: renderWorkflowStepColor(workflowStep.color),
      isValueRoundedToZero: durationInHours === 0 && durationInSeconds !== 0,
    } satisfies IHorizontalStackedBarData;
  });
};

export type ContentPaceBodyProps = Readonly<{
  onChartItemClick?: (
    averageTimeInSeconds: number,
    workflowId: Uuid,
    workflowStepId: Uuid,
    workflowStepName: string,
  ) => void;
  timeSpentInWorkflowStepByWorkflow: TimeSpentInWorkflowStepByWorkflow | null;
  workflows: ReadonlyArray<Workflow>;
}>;

export const ContentPaceBody: React.FC<ContentPaceBodyProps> = ({
  onChartItemClick,
  timeSpentInWorkflowStepByWorkflow,
  workflows,
}) => {
  const loadingState = getContentPaceLoadingState(workflows, timeSpentInWorkflowStepByWorkflow);

  switch (loadingState) {
    case 'loading':
      return (
        <Card component="section">
          <Card.Headline>
            <TextSkeleton width="300px" />
          </Card.Headline>
          <Card.Body>
            <ContentPaceBarChart
              chartLabel="A horizontal stacked bar chart showing how many items fell in which category in the last 90 days."
              data={null}
              isLoading
            />
          </Card.Body>
        </Card>
      );
    case 'loaded':
      return workflows.map((workflow) => {
        const handleItemClick = (itemId: string, item: IHorizontalStackedBarData) => {
          const averageTimeInSeconds =
            timeSpentInWorkflowStepByWorkflow?.[workflow.id]?.[item.id] ?? 0;

          return onChartItemClick?.(averageTimeInSeconds, workflow.id, itemId, item.label);
        };

        const chartData = getChartDataForWorkflow(workflow, timeSpentInWorkflowStepByWorkflow);
        return (
          <Card component="section" key={workflow.id}>
            <Card.Headline>{workflow.name}</Card.Headline>
            <Card.Body>
              <ContentPaceBarChart
                chartLabel="A horizontal stacked bar chart showing how many items fell in which category in the last 90 days."
                data={chartData}
                isLoading={false}
                onItemClick={handleItemClick}
              />
            </Card.Body>
          </Card>
        );
      });
    case 'no-data':
      return (
        <EmptyState size="full">
          <EmptyState.Title>No insights yet.</EmptyState.Title>
          <EmptyState.Content>
            <p>Insights for workflows are not available yet for the set time frame.</p>

            <p>Check back later after you make progress with your content items.</p>
          </EmptyState.Content>
        </EmptyState>
      );
  }
};
