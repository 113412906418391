import { Inline } from '@kontent-ai/component-library/Inline';
import { OpenPreviewUrlSettingsButton } from '../../containers/previewEmptyStates/OpenPreviewUrlSettingsButton.tsx';
import { PreviewErrorState } from './PreviewErrorState.tsx';

export const NoPreviewProjectNotSetup = () => {
  return (
    <PreviewErrorState actionButton={<OpenPreviewUrlSettingsButton />} title="Preview not set up">
      <Inline>To preview your content, set up a preview URL first.</Inline>
    </PreviewErrorState>
  );
};
