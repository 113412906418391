import React, { useCallback } from 'react';
import { withAutoDispatcher } from '../../../../_shared/components/AutoDispatcher.tsx';
import { NameEditorElement } from '../../../../_shared/components/NameEditorElement.tsx';
import { ValidationConstants } from '../../../../_shared/constants/validationConstants.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { getSelectedSubscription } from '../../../../_shared/selectors/subscriptionSelectors.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { DataUiInput } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { UntitledSubscription } from '../../shared/constants/UIConstants.ts';
import { changeSubscriptionName } from '../actions/subscriptionGeneralActions.ts';
import { saveSubscriptionNameToServer } from '../actions/thunkSubscriptionGeneralActions.ts';

const SubscriptionNameEditorContainer: React.FC = () => {
  const dispatch = useDispatch();
  const onChange = useCallback((name: string): void => {
    dispatch(changeSubscriptionName(name));
  }, []);
  const editedSubscriptionName = useSelector(
    (state) => state.subscriptionApp.general.editedSubscriptionName,
  );
  const selectedSubscriptionId = useSelector(
    (state) => state.subscriptionApp.selectedSubscriptionId,
  );

  return (
    <NameEditorElement
      autoFocus
      className="name-editor--is-plain"
      dataUiInputName={DataUiInput.SubscriptionName}
      maxLength={ValidationConstants.SubscriptionNameMaxLength}
      placeholder={UntitledSubscription}
      value={selectedSubscriptionId ? editedSubscriptionName : ''}
      onChange={onChange}
    />
  );
};

type ObservedState = {
  readonly editedSubscriptionName: string | null;
};

function mapObservedState(state: IStore): ObservedState {
  const { editedSubscriptionName } = state.subscriptionApp.general;

  return {
    editedSubscriptionName,
  };
}

function shouldDispatch(oldState: IStore, newState: IStore): boolean {
  const currentSelectedSubscription = getSelectedSubscription(oldState);
  const nextSelectedSubscription = getSelectedSubscription(newState);

  return (
    !!currentSelectedSubscription &&
    !!nextSelectedSubscription &&
    currentSelectedSubscription.subscriptionId === nextSelectedSubscription.subscriptionId
  );
}

export const SubscriptionNameEditor = withAutoDispatcher<NoProps, ObservedState>(
  mapObservedState,
  saveSubscriptionNameToServer,
  1000,
  shouldDispatch,
)(SubscriptionNameEditorContainer);
