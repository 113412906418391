import { Inline } from '@kontent-ai/component-library/Inline';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React, { useMemo } from 'react';
import { useSelector } from '../../hooks/useSelector.ts';
import { getSelectedLanguageId } from '../../selectors/getSelectedLanguageId.ts';
import { getTranslatedVariants } from '../../selectors/getTranslatedVariants.ts';
import {
  getActiveLanguagesWithFallbackChain,
  getFallbackLanguageIdForItem,
} from '../../utils/languageUtils.ts';
import { NotTranslatedBadge } from '../Workflow/NotTranslatedBadge.tsx';
import { FallbackVariantWorkflowStepBadge } from './FallbackVariantWorkflowStepBadge.tsx';
import { FallbacksStatus } from './FallbacksStatus.tsx';

export const FallbacksStatusWithBadges: React.FC<{ readonly itemId: Uuid }> = ({ itemId }) => {
  const translatedVariants = useSelector((s) => getTranslatedVariants(itemId, s));
  const selectedLanguageId = useSelector(getSelectedLanguageId);
  const activeLanguages = useSelector((s) => getActiveLanguagesWithFallbackChain(s.data.languages));

  const fallbackLanguageId = useMemo(
    () =>
      selectedLanguageId &&
      translatedVariants &&
      getFallbackLanguageIdForItem(translatedVariants, activeLanguages, selectedLanguageId),
    [selectedLanguageId, translatedVariants, activeLanguages],
  );

  const fallbackVariant = translatedVariants?.find((v) => v.id.variantId === fallbackLanguageId);

  return (
    <Inline spacing={Spacing.S} align="center">
      <Inline spacing={Spacing.XS}>
        <NotTranslatedBadge size={Spacing.L} tooltipText="This item is not translated." />
        {fallbackVariant && <FallbackVariantWorkflowStepBadge fallbackVariant={fallbackVariant} />}
      </Inline>

      <FallbacksStatus itemId={itemId} />
    </Inline>
  );
};

FallbacksStatusWithBadges.displayName = 'FallbacksStatusWithBadges';
