import { AssetDataType } from '../../../../../../component-library/components/AssetTile/types/assetData.type.ts';
import { IAsset } from '../../../../../data/models/assets/Asset.ts';

export const getAssetData = (from: IAsset): AssetDataType => {
  return {
    assetId: from.id,
    assetIsArchived: from.archived,
    assetTitle: from.title ?? from.filename,
    assetUploadDate: from.created ?? new Date().toISOString(),
    assetFileSizeInBytes: from.fileSize,
    assetFileType: from.filename.substring(from.filename.lastIndexOf('.') + 1),
    assetPreview:
      from._thumbnailLink !== null
        ? {
            assetPreviewUrl: from._thumbnailLink,
            assetAlt: from.eTag ?? '-',
            assetDimension: {
              width: from.width ?? 0,
              height: from.height ?? 0,
            },
          }
        : null,
  };
};
