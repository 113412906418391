import React from 'react';

type AssetEditorToolbarActionsProps = {
  readonly renderSaveButton: () => React.ReactNode;
  readonly renderActionMenu: () => React.ReactNode;
};

export const AssetEditorToolbarActions: React.FC<AssetEditorToolbarActionsProps> = ({
  renderSaveButton,
  renderActionMenu,
}) => (
  <>
    <div className="status-bar__button status-bar__save-button">{renderSaveButton()}</div>
    <div className="status-bar__button status-bar__action-button">{renderActionMenu()}</div>
  </>
);

AssetEditorToolbarActions.displayName = 'AssetEditorToolbarActions';
