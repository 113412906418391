import { Action } from '../../../../@types/Action.type.ts';
import { IEditedContentItemThreads } from '../../../contentInventory/content/stores/IContentAppStoreState.ts';
import {
  ContentItemEditing_CommentThread_AiInlineThreadAdded,
  ContentItemEditing_CommentThread_Blurred,
  ContentItemEditing_CommentThread_Focused,
  ContentItemEditing_CommentThread_HideAllComments,
  ContentItemEditing_CommentThread_InlineSubmittingFinished,
  ContentItemEditing_CommentThread_InlineSubmittingStarted,
  ContentItemEditing_CommentThread_InlineThreadAdded,
  ContentItemEditing_CommentThread_ItemEditingCanceled,
  ContentItemEditing_CommentThread_ItemEditingStarted,
  ContentItemEditing_CommentThread_NewItemCreationFinished,
  ContentItemEditing_CommentThread_NewItemCreationStarted,
  ContentItemEditing_CommentThread_ReplyCanceled,
  ContentItemEditing_CommentThread_ReplyFocusLost,
  ContentItemEditing_CommentThread_ReplyStarted,
  ContentItemEditing_CommentThread_ResolveUndoCancel,
  ContentItemEditing_CommentThread_Resolved,
  ContentItemEditing_CommentThread_ResolvingStarted,
  ContentItemEditing_CommentThread_SubmittingFinished,
  ContentItemEditing_CommentThread_SubmittingStarted,
  ContentItemEditing_CommentThread_Unresolved,
  ContentItemEditing_CommentThread_UnresolvingStarted,
  ContentItemEditing_CommentThread_UpdateItemFinished,
  ContentItemEditing_CommentThread_UpdateItemStarted,
  ContentItemEditing_Comments_Loaded,
  ContentItemEditing_ContentGroup_Selected,
  ContentItemEditing_DiscussionsSidebarClosed,
  ContentItemEditing_DiscussionsSidebarOpened,
  ContentItemEditing_SuggestionApproved,
} from '../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import {
  filterUnsavedComments,
  removeUnsavedThread,
  setEditedThreadItem,
  updateCommentThread,
  updateCommentThreadItem,
} from '../../utils/commentUtils.ts';
import { shouldResetEditedItemState } from '../utils/shouldResetEditedItemState.ts';

const initialState: IEditedContentItemThreads = {
  commentThreads: [],
  focusedCommentThreadId: null,
  navigatedFrom: null,
  newCommentThreadForm: {},
};

export function editedContentItemVariantComments(
  state = initialState,
  action: Action,
): IEditedContentItemThreads {
  if (shouldResetEditedItemState(action)) {
    return initialState;
  }

  switch (action.type) {
    case ContentItemEditing_Comments_Loaded: {
      return action.payload.comments;
    }

    case ContentItemEditing_CommentThread_InlineThreadAdded: {
      const { inlineThread } = action.payload;
      return {
        ...state,
        commentThreads: [...state.commentThreads, inlineThread],
        focusedCommentThreadId: inlineThread.id,
        navigatedFrom: null,
      };
    }

    case ContentItemEditing_CommentThread_ResolvingStarted: {
      const { threadId, enableUndo } = action.payload;
      const newCommentThreads = updateCommentThread(state.commentThreads, threadId, (t) => ({
        ...t,
        resolvedAt: 'Resolving...',
        isInUndoResolvedState: enableUndo,
      }));

      return {
        ...state,
        commentThreads: newCommentThreads,
        // Unfocus only when resolved thread is the focused one, otherwise keep focused comment to prevent unwanted extra animation
        focusedCommentThreadId:
          state.focusedCommentThreadId === threadId ? null : state.focusedCommentThreadId,
        navigatedFrom: null,
      };
    }

    case ContentItemEditing_CommentThread_ResolveUndoCancel: {
      const { threadId } = action.payload;
      const newCommentThreads = updateCommentThread(state.commentThreads, threadId, (t) => ({
        ...t,
        isInUndoResolvedState: false,
      }));

      return {
        ...state,
        commentThreads: newCommentThreads,
      };
    }

    case ContentItemEditing_CommentThread_UnresolvingStarted: {
      const { threadId } = action.payload;
      const newCommentThreads = updateCommentThread(state.commentThreads, threadId, (t) => ({
        ...t,
        resolvedAt: null,
        resolvedBy: null,
      }));

      return {
        ...state,
        commentThreads: newCommentThreads,
        focusedCommentThreadId: threadId,
        navigatedFrom: null,
      };
    }

    case ContentItemEditing_CommentThread_Focused: {
      return {
        ...state,
        focusedCommentThreadId: action.payload.threadId,
        navigatedFrom: action.payload.navigatedFrom,
      };
    }

    case ContentItemEditing_CommentThread_Blurred: {
      return {
        ...state,
        focusedCommentThreadId: null,
        navigatedFrom: null,
      };
    }

    case ContentItemEditing_CommentThread_ReplyStarted: {
      const { threadId } = action.payload;
      const newCommentThreads = updateCommentThread(state.commentThreads, threadId, (t) => ({
        ...t,
        isReplying: true,
      }));

      return {
        ...state,
        commentThreads: newCommentThreads,
      };
    }

    case ContentItemEditing_CommentThread_ReplyCanceled: {
      const { threadId } = action.payload;
      const newCommentThreads = updateCommentThread(state.commentThreads, threadId, (t) => ({
        ...t,
        isReplying: false,
      }));

      return {
        ...state,
        commentThreads: newCommentThreads,
      };
    }

    case ContentItemEditing_CommentThread_HideAllComments: {
      return {
        ...state,
        commentThreads: filterUnsavedComments(state.commentThreads),
        focusedCommentThreadId: null,
        navigatedFrom: null,
      };
    }

    case ContentItemEditing_CommentThread_ReplyFocusLost: {
      const { threadId, isCommentPending } = action.payload;
      const newCommentThreads = updateCommentThread(state.commentThreads, threadId, (t) => ({
        ...t,
        isReplying: isCommentPending,
      }));

      return {
        ...state,
        commentThreads: newCommentThreads,
      };
    }

    case ContentItemEditing_CommentThread_InlineSubmittingStarted:
    case ContentItemEditing_CommentThread_NewItemCreationStarted: {
      const { threadId } = action.payload;
      const newCommentThreads = updateCommentThread(state.commentThreads, threadId, (t) => ({
        ...t,
        isSubmitting: true,
      }));

      return {
        ...state,
        commentThreads: newCommentThreads,
      };
    }

    case ContentItemEditing_CommentThread_NewItemCreationFinished: {
      const { threadId, updatedCommentThread } = action.payload;
      const newCommentThreads = updateCommentThread(
        state.commentThreads,
        threadId,
        () => updatedCommentThread,
      );

      return {
        ...state,
        commentThreads: newCommentThreads,
        focusedCommentThreadId: threadId,
        navigatedFrom: null,
      };
    }

    case ContentItemEditing_CommentThread_UpdateItemStarted: {
      const { threadId, commentId } = action.payload;
      const newCommentThreads = updateCommentThreadItem(
        state.commentThreads,
        threadId,
        commentId,
        (commentThread) => ({
          ...commentThread,
          isSubmitting: true,
        }),
      );

      return {
        ...state,
        commentThreads: newCommentThreads,
      };
    }

    case ContentItemEditing_CommentThread_ItemEditingCanceled: {
      const { threadId } = action.payload;
      const withUnsavedThreadsRemoved = removeUnsavedThread(state.commentThreads, threadId);
      const withCancelledItemEditing = setEditedThreadItem(
        withUnsavedThreadsRemoved,
        threadId,
        null,
      );

      return {
        ...state,
        commentThreads: withCancelledItemEditing,
      };
    }

    case ContentItemEditing_CommentThread_InlineSubmittingFinished:
    case ContentItemEditing_CommentThread_UpdateItemFinished: {
      const { threadId, updatedThread } = action.payload;
      const newCommentThreads = updateCommentThread(
        state.commentThreads,
        threadId,
        () => updatedThread,
      );

      return {
        ...state,
        commentThreads: newCommentThreads,
        focusedCommentThreadId: updatedThread.id,
        navigatedFrom: null,
      };
    }

    case ContentItemEditing_CommentThread_AiInlineThreadAdded: {
      return {
        ...state,
        commentThreads: [...state.commentThreads, action.payload.createdThread],
      };
    }

    case ContentItemEditing_SuggestionApproved: {
      const { commentThread } = action.payload;
      const newCommentThreads = updateCommentThread(
        state.commentThreads,
        commentThread.id,
        () => commentThread,
      );

      return {
        ...state,
        commentThreads: newCommentThreads,
      };
    }

    case ContentItemEditing_CommentThread_Resolved: {
      const { commentThread, enableUndo } = action.payload;
      const newCommentThreads = updateCommentThread(state.commentThreads, commentThread.id, () => ({
        ...commentThread,
        isInUndoResolvedState: enableUndo,
      }));

      return {
        ...state,
        commentThreads: newCommentThreads,
      };
    }

    case ContentItemEditing_CommentThread_Unresolved: {
      const { commentThread } = action.payload;
      const newCommentThreads = updateCommentThread(state.commentThreads, commentThread.id, () => ({
        ...commentThread,
        isInUndoResolvedState: false,
      }));

      return {
        ...state,
        commentThreads: newCommentThreads,
        focusedCommentThreadId: commentThread.id,
        navigatedFrom: null,
      };
    }

    case ContentItemEditing_CommentThread_SubmittingStarted: {
      return {
        ...state,
        newCommentThreadForm: {
          ...state.newCommentThreadForm,
          isSubmitting: true,
        },
      };
    }

    case ContentItemEditing_CommentThread_SubmittingFinished: {
      const { newCommentThread } = action.payload;
      return {
        ...state,
        newCommentThreadForm: {
          ...state.newCommentThreadForm,
          isSubmitting: false,
        },
        commentThreads: [newCommentThread, ...state.commentThreads],
      };
    }

    case ContentItemEditing_CommentThread_ItemEditingStarted: {
      const { threadId, commentId } = action.payload;
      const newCommentThreads = setEditedThreadItem(state.commentThreads, threadId, commentId);

      return {
        ...state,
        commentThreads: newCommentThreads,
        focusedCommentThreadId: threadId,
        navigatedFrom: null,
      };
    }

    case ContentItemEditing_ContentGroup_Selected: {
      if (!action.payload.isChanging || !action.payload.isInEditedContentItem) {
        return state;
      }
      return {
        ...state,
        commentThreads: filterUnsavedComments(state.commentThreads),
        focusedCommentThreadId: null,
        navigatedFrom: null,
      };
    }

    case ContentItemEditing_DiscussionsSidebarOpened:
    case ContentItemEditing_DiscussionsSidebarClosed: {
      return {
        ...state,
        commentThreads: filterUnsavedComments(state.commentThreads),
      };
    }

    default:
      return state;
  }
}
