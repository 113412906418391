import { Box } from '@kontent-ai/component-library/Box';
import { Divider, DividerDirection } from '@kontent-ai/component-library/Dividers';
import { AppMenuItem, NavigationBar } from '@kontent-ai/component-library/NavigationBar';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { useContext } from 'react';
import { DocsMenu } from '../../containers/MainMenu/DocsMenu.tsx';
import { FeedbackMenu } from '../../containers/MainMenu/FeedbackMenu.tsx';
import { IntercomLauncherButton } from '../../containers/MainMenu/IntercomLauncherButton.tsx';
import { ReportBugButton } from '../../containers/MainMenu/ReportBugButton.tsx';
import { ToggleMenuButton } from '../../containers/MainMenu/ToggleMenuButton.tsx';
import { UserMenu } from '../../containers/MainMenu/UserMenu.tsx';
import {
  MainNavigationContext,
  NavigationBarExpandedState,
} from '../../contexts/MainNavigationContext.tsx';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

type NavigationBarProps = {
  readonly appMenuItems: ReadonlyArray<AppMenuItem>;
};

export const StandaloneMenuItems = () => (
  <Stack spacing={Spacing.XL} align="center">
    <IntercomLauncherButton />
    <ReportBugButton />
    <FeedbackMenu />
    <DocsMenu />
    <UserMenu />
  </Stack>
);

export const RefinedStandaloneMenuItems = () => {
  const { navigationBarExpandedState, toggleIsNavigationBarExpanded } =
    useContext(MainNavigationContext);
  const isExpanded = navigationBarExpandedState !== NavigationBarExpandedState.Minimized;

  return (
    <>
      <Divider
        direction={DividerDirection.Horizontal}
        offsetBefore={Spacing.XS}
        offsetAfter={Spacing.M}
      />
      <Box paddingX={Spacing.M}>
        <Stack spacing={Spacing.L} align="normal">
          <ToggleMenuButton
            onClick={toggleIsNavigationBarExpanded}
            navigationBarExpandedState={navigationBarExpandedState}
          />
          <IntercomLauncherButton isExpanded={isExpanded} />
          <DocsMenu isExpanded={isExpanded} />
          <UserMenu usernameVisible={isExpanded} />
        </Stack>
      </Box>
    </>
  );
};

export const MainMenuNavigationBar = ({ appMenuItems }: NavigationBarProps) => (
  <NavigationBar
    appMenuItems={appMenuItems}
    {...getDataUiElementAttribute(DataUiElement.NavigationBar)}
  >
    <StandaloneMenuItems />
  </NavigationBar>
);
