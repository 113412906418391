import { assert, Collection } from '@kontent-ai/utils';
import { RawDraftContentState } from 'draft-js';
import Immutable from 'immutable';
import { KontentObjectType, ObjectDataType } from '../../../utils/export/html/elements/objects.ts';
import { DraftJSInlineStyle } from '../../inlineStyles/api/inlineStyles.ts';
import {
  IRawStateBuilderDependencies,
  RawStateBuilder,
} from './rawStateBuilder/RawStateBuilder.ts';
import { OutputLock, StackItem, WriteContext } from './rawStateBuilder/StackItem.ts';
import {
  IEndHandler,
  IStartHandler,
  OutOfFragmentLockKey,
  TagNames,
  endHandlers,
  isNodeEndFragment,
  isNodeStartFragment,
  startHandlers,
} from './rawStateBuilder/handlers.ts';

export interface IImportedRawState extends RawDraftContentState {
  readonly importedMetadata: ReadonlyMap<ObjectDataType, Uuid>;
}

function getKenticoObjectNode(
  nodes: ReadonlyArray<Node>,
  dataType: ObjectDataType,
): Node | undefined {
  return nodes.find(
    (node: Node) =>
      node.nodeType === document.ELEMENT_NODE &&
      (node as HTMLObjectElement).type === KontentObjectType &&
      (node as HTMLObjectElement).getAttribute('data-type') === dataType,
  );
}

function getMetadata(
  nodes: ReadonlyArray<Node>,
  metadataKeys: ReadonlyArray<ObjectDataType>,
): ReadonlyMap<ObjectDataType, Uuid> {
  return metadataKeys.reduce((map, key) => {
    const KCProjectIdObject = getKenticoObjectNode(nodes, key);
    if (KCProjectIdObject) {
      const attribute = (KCProjectIdObject as HTMLObjectElement).getAttribute('data-id');
      if (attribute) {
        map.set(key, attribute);
      }
    }

    return map;
  }, new Map<ObjectDataType, Uuid>());
}

function getStartFragmentNode(nodes: ReadonlyArray<Node>): Node | null {
  const queue = [...nodes];

  while (queue.length > 0) {
    const node = queue.shift();
    if (node && isNodeStartFragment(node)) {
      return node;
    }
    node?.childNodes.forEach((child) => queue.push(child));
  }

  return null;
}

function createInitialStack(nodes: ReadonlyArray<Node>, currentProjectId: Uuid): Array<StackItem> {
  const metadata = getMetadata(nodes, [ObjectDataType.Project, ObjectDataType.AiSession]);
  const startFragmentExists = !!getStartFragmentNode(nodes);

  return [
    {
      currentProjectId,
      inlineStyleContext: {
        allowedStyles: [...Object.values(DraftJSInlineStyle)],
        contextualStyles: [],
      },
      linkContext: null,
      listContext: null,
      metadata,
      nestedContentContext: {
        parentBlockTypes: [],
      },
      newBlockLock: new OutputLock(
        startFragmentExists ? Immutable.Set.of(OutOfFragmentLockKey) : undefined,
      ),
      sourceProjectId: metadata.get(ObjectDataType.Project) ?? null,
      tableContext: null,
      textBlockContext: null,
      writeContext: new WriteContext(),
      writeLock: new OutputLock(
        startFragmentExists ? Immutable.Set.of(OutOfFragmentLockKey) : undefined,
      ),
    },
  ];
}

function createIFrame(): HTMLIFrameElement {
  const iframe = document.createElement('IFRAME') as HTMLIFrameElement;
  // Make the element invisible and not influencing the layout
  iframe.style.left = '0';
  iframe.style.opacity = '0';
  iframe.style.position = 'absolute';
  iframe.style.top = '0';
  iframe.setAttribute('sandbox', 'allow-same-origin'); // Sandboxing the iframe reduces security issues.

  document.body.appendChild(iframe);
  return iframe;
}

function createNodesFromPlainText(
  iframe: HTMLIFrameElement,
  pastedText: string,
): ReadonlyArray<Node> {
  const contentDocument = iframe.contentDocument;
  if (!contentDocument) {
    return [];
  }

  const windowsLineBreaksReplaced = pastedText.replace(/\r\n/gm, '\n'); // Windows (CR LF) => Unix (LF)
  const standardizedText = windowsLineBreaksReplaced.replace(/\r/gm, '\n'); // Macintosh (CR) => Unix (LF)

  const textSegments = standardizedText.split('\n');

  const contentNodes: Array<HTMLElement> = textSegments.map((segment: string) => {
    if (!segment) {
      return contentDocument.createElement(TagNames.BR);
    }

    const paragraph = contentDocument.createElement(TagNames.P);
    paragraph.style.whiteSpace = 'pre'; // preserve
    paragraph.innerText = segment;
    return paragraph;
  });

  const rawNodes = [
    contentDocument.createComment('StartFragment'),
    ...contentNodes,
    contentDocument.createComment('EndFragment'),
  ];
  rawNodes.forEach((node) => contentDocument.body.appendChild(node));

  return rawNodes;
}

function createNodesFromHtmlText(iframe: HTMLIFrameElement, pastedString: string): Array<Node> {
  if (iframe.contentWindow) {
    iframe.contentWindow.document.open();
    iframe.contentWindow.document.write(pastedString);
    iframe.contentWindow.document.close();
  }

  const iframeContentDocument = iframe.contentDocument as Document;
  const rawNodes = Array.from(iframeContentDocument.body.childNodes);

  return rawNodes;
}

export function convertClipboardToRawState(
  pastedString: string,
  isPlainText: boolean,
  currentProjectId: Uuid,
  dependencies: IRawStateBuilderDependencies,
): IImportedRawState {
  const iframe = createIFrame();
  const rawNodes = isPlainText
    ? createNodesFromPlainText(iframe, pastedString)
    : createNodesFromHtmlText(iframe, pastedString);

  const stack = createInitialStack(rawNodes, currentProjectId);
  const builder = new RawStateBuilder(dependencies);

  const getTop = (): StackItem => {
    const top = Collection.getLast(stack);
    assert(!!top, () => 'Top stack item is falsy.');
    return top;
  };

  const processStartHandlers = (node: Node) => {
    const newTop = startHandlers.reduce(
      (lastStackItem: StackItem, handler: IStartHandler) => handler(node, lastStackItem, builder),
      getTop(),
    );

    stack.push(newTop);
  };

  const processEndHandlers = (node: Node) => {
    endHandlers.forEach((handler: IEndHandler) => handler(node, getTop(), builder));

    stack.pop();
  };

  const processNodes = (nodes: ReadonlyArray<Node>): void =>
    nodes.forEach((node: Node) => {
      if (isNodeStartFragment(node)) {
        processStartHandlers(node);
      } else if (isNodeEndFragment(node)) {
        processEndHandlers(node);
      } else {
        processStartHandlers(node);
        processNodes(Array.from(node.childNodes));
        processEndHandlers(node);
      }
    });

  processNodes(rawNodes);

  const importedMetadata = getTop().metadata;

  iframe.remove();

  return {
    importedMetadata,
    ...builder.getRawState(),
  };
}
