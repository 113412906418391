import classNames from 'classnames';
import React from 'react';
import { getDropDown } from '../../../../../_shared/components/DropDown.tsx';
import { DataUiCollection } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IContentGroup } from '../../../../contentInventory/content/models/contentTypeElements/types/ContentGroup.ts';

interface ISendToContentGroupOption {
  readonly id: Uuid;
  readonly name: string;
}

const DropDown = getDropDown<ISendToContentGroupOption>();

const newGroupOptionId = 'new-group';
const newGroupOptionName = '+ Create new content group';

const selectId = (option: ISendToContentGroupOption) => option.id;
const selectName = (option: ISendToContentGroupOption) => option.name;

const getOptions = (
  contentGroups: ReadonlyArray<IContentGroup>,
): ReadonlyArray<ISendToContentGroupOption> => {
  return [
    ...contentGroups.map((group) => ({ id: group.id, name: group.name })),
    { id: newGroupOptionId, name: newGroupOptionName },
  ];
};

export interface ISendToContentGroupDropdownStateProps {
  readonly className?: string;
  readonly contentGroups: ReadonlyArray<IContentGroup>;
  readonly onSendToContentGroup: (contentGroupId: Uuid) => void;
  readonly onSendToNewContentGroup: () => void;
}

export const SendToContentGroupDropdown: React.FC<ISendToContentGroupDropdownStateProps> = ({
  className,
  contentGroups,
  onSendToContentGroup,
  onSendToNewContentGroup,
}): JSX.Element => {
  const onSelect = (selectedOption: ISendToContentGroupOption): void => {
    if (selectedOption.id === newGroupOptionId) {
      onSendToNewContentGroup();
    } else {
      onSendToContentGroup(selectedOption.id);
    }
  };

  return (
    <DropDown
      className={classNames('combo-box', className)}
      dataUiCollectionName={DataUiCollection.SendToContentGroupOptions}
      defaultText="Move to a content group:"
      getOptionId={selectId}
      onSelect={onSelect}
      options={getOptions(contentGroups)}
      renderOptionName={selectName}
    />
  );
};

SendToContentGroupDropdown.displayName = 'SendToContentGroupDropdown';
