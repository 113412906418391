import { InvariantException } from '@kontent-ai/errors';
import { Dispatch, GetState, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { ITaxonomyGroupServerModel } from '../../../../../repositories/serverModels/contentModels/TaxonomyGroupServerModel.type.ts';
import {
  ITaxonomyPatchOperation,
  TaxonomyPatchOperationType,
} from '../../../../../repositories/utils/createTaxonomyPatchDocument.ts';
import { RootFolderId } from '../../constants/assetFolderConstants.ts';
import {
  AssetLibrary_Folders_MovingBackCompleted,
  AssetLibrary_Folders_MovingBackFailed,
  AssetLibrary_Folders_MovingBackStarted,
} from '../../constants/assetLibraryActionTypes.ts';
import { StatusMessageAction } from '../../stores/IAssetLibraryState.ts';
import { isMoveFolderAction } from '../../types/lastAssetsAction.ts';

interface IDeps {
  readonly assetFolderRepository: {
    readonly patch: (operation: ITaxonomyPatchOperation) => Promise<ITaxonomyGroupServerModel>;
  };
}

const started = () =>
  ({
    type: AssetLibrary_Folders_MovingBackStarted,
  }) as const;

const completed = (taxonomyGroup: ITaxonomyGroupServerModel) =>
  ({
    type: AssetLibrary_Folders_MovingBackCompleted,
    payload: {
      taxonomyGroup,
    },
  }) as const;

const failed = () =>
  ({
    type: AssetLibrary_Folders_MovingBackFailed,
  }) as const;

export type MoveOpenedFolderBackActionsType = ReturnType<
  typeof started | typeof completed | typeof failed
>;

export const createMoveOpenedAssetFolderBackAction =
  (deps: IDeps) =>
  (): ThunkPromise =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const {
      assetLibraryApp: { lastAction, openedFolderId },
    } = getState();

    if (!isMoveFolderAction(lastAction)) {
      throw new Error(
        `Expected last action to be ${
          StatusMessageAction.MovedFolder
        }, but was ${JSON.stringify(lastAction)}.`,
      );
    }

    if (openedFolderId === RootFolderId) {
      throw InvariantException('No opened folder was found');
    }

    try {
      dispatch(started());

      const operation: ITaxonomyPatchOperation = {
        operation: TaxonomyPatchOperationType.Move,
        reference:
          lastAction.originalParentFolderId === RootFolderId
            ? null
            : lastAction.originalParentFolderId,
        source: openedFolderId,
      };

      const taxonomyGroup = await deps.assetFolderRepository.patch(operation);

      dispatch(completed(taxonomyGroup));
    } catch (error) {
      dispatch(failed());
      throw error;
    }
  };
