import React, { useMemo } from 'react';
import { LabelFor } from '../../../../../../_shared/uiComponents/LabelFor/LabelFor.tsx';
import { Capability } from '../../../../../../_shared/utils/permissions/capability.ts';
import { ElementType } from '../../../../../contentInventory/content/models/ContentItemElementType.ts';
import {
  ElementReference,
  EmptyElementReference,
} from '../../../../../itemEditor/features/ContentItemEditing/containers/hooks/useItemElementReference.ts';
import { EditorChangeCallback } from '../../../../../richText/plugins/behavior/OnChangePlugin.tsx';
import { DraggableTypeElement } from '../../../containers/typeElements/DraggableTypeElement.tsx';
import { GuidelinesInput } from '../../../containers/typeElements/GuidelinesInput.tsx';
import { IGuidelinesTypeElementData } from '../../../models/elements/GuidelinesTypeElementData.ts';
import { ITypeElementOwnProps } from '../../../types/ITypeElementProps.type.ts';
import { GuidelinesTypeElementValidationResult } from '../../../utils/typeElementValidators/types/GuidelinesTypeElementValidationResult.type.ts';

type GuidelinesTypeElementProps = ITypeElementOwnProps<
  IGuidelinesTypeElementData,
  GuidelinesTypeElementValidationResult
>;

export const GuidelinesTypeElement: React.FC<GuidelinesTypeElementProps> = (props) => {
  const { capability, disabled, typeElementData, validationResult, onChange } = props;

  const element = useMemo(
    (): ElementReference => ({
      ...EmptyElementReference,
      elementId: typeElementData.elementId,
      elementName: typeElementData.name,
      elementCodename: typeElementData.codename,
      elementType: ElementType.Guidelines,
    }),
    [typeElementData],
  );

  const onContentChange: EditorChangeCallback = (newEditorState) => {
    const newData: IGuidelinesTypeElementData = {
      ...typeElementData,
      _editorState: newEditorState,
    };
    onChange(newData);
    return Promise.resolve();
  };

  return (
    <DraggableTypeElement {...props}>
      <div className="content-element__value content-element__value--in-guidelines-element">
        <LabelFor
          isHidden
          target={(id: Uuid) => (
            <GuidelinesInput
              disabled={disabled || !capability.can(Capability.ConfigureContentTypes)}
              editedEntityName="Guidelines"
              editorState={typeElementData._editorState}
              element={element}
              id={id}
              isInvalid={!validationResult.isMaxCharsValid}
              onContentChange={onContentChange}
            />
          )}
        />
      </div>
    </DraggableTypeElement>
  );
};

GuidelinesTypeElement.displayName = 'GuidelinesTypeElement';
