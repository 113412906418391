import { shadowAlertFocusStyles, shadowFocusStyles } from '@kontent-ai/component-library/tokens';
import styled, { css } from 'styled-components';
import { IBaseAssetTileProps } from '../BaseAssetTile.tsx';
import { assetTileBorderRadius } from '../decisionTokens.ts';

interface IStyledFocusHelperProps extends Pick<IBaseAssetTileProps, 'isAlert'> {
  readonly isFocusVisible: boolean;
}

export const StyledFocusHelper = styled.div<IStyledFocusHelperProps>`
  border-radius: ${assetTileBorderRadius};
  z-index: 1;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  ${({ isFocusVisible, isAlert }) =>
    isFocusVisible && css`${isAlert ? shadowAlertFocusStyles : shadowFocusStyles}`};
`;
