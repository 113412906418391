import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { selectIntercomUtils } from '../../../../../_shared/selectors/selectIntercomUtils.ts';
import { InnovationLabFeatureCard } from '../../components/InnovationLabFeatureCard.tsx';
import { InnovationLabFeatureCardWrapper } from '../../components/InnovationLabFeatureCardWrapper.tsx';
import { InnovationLabFeatureDialogs } from '../../components/InnovationLabFeatureDialogs.tsx';
import { InnovationLabGenericActivationCallout } from '../../components/InnovationLabGenericActivationCallout.tsx';
import { InnovationLabGenericConsent } from '../../components/InnovationLabGenericConsent.tsx';
import { useFeatureActivationDialog } from '../../hooks/useFeatureActivationDialog.ts';
import { activateInnovationLabFeature } from '../../thunks/activateInnovationLabFeature.ts';
import { deactivateInnovationLabFeature } from '../../thunks/deactivateInnovationLabFeature.ts';
import { InnovationLabCardProps } from '../../types/InnovationLabCardProps.type.ts';

export const AuthorAssistCard = ({ state, info, name }: InnovationLabCardProps) => {
  const intercomUtils = useSelector(selectIntercomUtils);

  const {
    dialogDisplayed,
    isAsyncOperationInProgress,
    onCardActionButtonClick,
    onDialogActionClick,
    onDialogClose,
  } = useFeatureActivationDialog(
    info,
    state,
    activateInnovationLabFeature('ai-author-assist'),
    deactivateInnovationLabFeature('ai-author-assist'),
  );

  const featureState = info.status;

  return (
    <>
      <InnovationLabFeatureCardWrapper featureState={featureState}>
        <InnovationLabFeatureCard
          activationState={state}
          featureName={name}
          buttonFeatureName="Author content with AI"
          featureState={featureState}
          isActionButtonLoading={isAsyncOperationInProgress}
          onCardActionButtonClick={onCardActionButtonClick}
          onContactSupport={intercomUtils.showIntercom}
          renderCardBody={() => (
            <>
              <p>
                Tap into the{' '}
                <OutwardLink href="https://kontent.ai/learn/docs/content-items/author-assist">
                  capabilities of AI in Kontent.ai
                </OutwardLink>{' '}
                to take the tedium out of content creation and craft exceptional content
                effortlessly.
              </p>
              <p>
                Utilize <b>AI skills</b> to generate new content based on your instructions,
                condense text, craft captivating headlines, or adjust the voice and tone. All that
                without leaving Kontent.ai for 3rd-party services.
              </p>
            </>
          )}
        />
      </InnovationLabFeatureCardWrapper>
      <InnovationLabFeatureDialogs
        dialogDisplayed={dialogDisplayed}
        dialogFeatureName={name}
        isAsyncOperationInProgress={isAsyncOperationInProgress}
        onDialogActionClick={onDialogActionClick}
        onDialogClose={onDialogClose}
        renderActivationDialogBody={() => (
          <>
            <InnovationLabGenericActivationCallout featureName="Author content with AI" />
            <InnovationLabGenericConsent />
          </>
        )}
      />
    </>
  );
};
