export const Shared_AccessUnauthorized = 'Shared_AccessUnauthorized';
export const Shared_Breadcrumbs_Changed = 'Shared_Breadcrumbs_Changed';
export const Shared_Browser_IsOffline = 'Shared_Browser_IsOffline';
export const Shared_Browser_IsOnline = 'Shared_Browser_IsOnline';
export const Shared_CurrentProjectContainerId_Updated = 'Shared_CurrentProjectContainerId_Updated';
export const Shared_CurrentProjectId_Updated = 'Shared_CurrentProjectId_Updated';
export const Shared_Editor_Left = 'Shared_Editor_Left';
export const Shared_EmailNotifications_Changed = 'Shared_EmailNotifications_Changed';
export const Shared_EmailNotifications_Saving = 'Shared_EmailNotifications_Saving';
export const Shared_FeedbackModal_Submitted = 'Shared_FeedbackModal_Submitted';
export const Shared_FallbacksForLinkedContentStatus_Loaded =
  'Shared_FallbacksForLinkedContentStatus_Loaded';
export const Shared_ModalDismissed = 'Shared_ModalDismissed';
export const Shared_ModalOpened = 'Shared_ModalOpened';
export const Shared_ModalOpenedWithProperties = 'Shared_ModalOpenedWithProperties';
export const Shared_NotificationBar_Close = 'Shared_NotificationBar_Close';
export const Shared_NotificationBar_ShowError = 'Shared_NotificationBar_ShowError';
export const Shared_NotificationBar_ShowSuccess = 'Shared_NotificationBar_ShowSuccess';
export const Shared_OnboardingNotification_Closed = 'Shared_OnboardingNotification_Closed';
export const Shared_ProjectLocations_LoadingFinished = 'Shared_ProjectLocations_LoadingFinished';
export const Shared_ProjectLocations_LoadingInit = 'Shared_ProjectLocations_LoadingInit';
export const Shared_ScrollId_Forget = 'Shared_ScrollId_Forget';
export const Shared_ScrollId_Remember = 'Shared_ScrollId_Remember';
export const Shared_ReturnScrollId_Apply = 'Shared_ReturnScrollId_Apply';
export const Shared_ReturnScrollId_Remember = 'Shared_ReturnScrollId_Remember';
export const Shared_TaxonomyTermsCollapsion_GroupCollapsed =
  'Shared_TaxonomyTermsCollapsion_GroupCollapsed';
export const Shared_TaxonomyTermsCollapsion_GroupExpanded =
  'Shared_TaxonomyTermsCollapsion_GroupExpanded';
export const Shared_TaxonomyTermsCollapsion_TermToggled =
  'Shared_TaxonomyTermsCollapsion_TermToggled';
export const Shared_TryEnsuringSelectedLanguageId_Ensured =
  'Shared_TryEnsuringSelectedLanguageId_Ensured';
export const Shared_TryEnsuringSelectedLanguageId_NoLanguageAvailable =
  'Shared_TryEnsuringSelectedLanguageId_NoLanguageAvailable';
export const Shared_TryEnsuringSelectedLanguageId_Started =
  'Shared_TryEnsuringSelectedLanguageId_Started';
export const Shared_UserProperties_LoadingFinished = 'Shared_UserProperties_LoadingFinished';
export const Shared_UserProperties_UpsertStarted = 'Shared_UserProperties_UpsertStarted';
export const Shared_UserProperties_UpsertSucceeded = 'Shared_UserProperties_UpsertSucceeded';
export const Shared_LoadProjectProperties_Finished = 'Shared_LoadProjectProperties_Finished';
export const Shared_LoadProjectFeatureFlags_Finished = 'Shared_LoadProjectFeatureFlags_Finished';
export const Shared_SmartLinkCommand_Remember = 'Shared_SmartLinkCommand_Remember';
export const Shared_SmartLinkCommand_Forget = 'Shared_SmartLinkCommand_Forget';
export const Shared_LoadSubscriptionProperties_Finished =
  'Shared_LoadSubscriptionProperties_Finished';
export const Shared_GeneralProperties_LoadingFinished = 'Shared_GeneralProperties_LoadingFinished';
export const Shared_SignalR_Connected = 'Shared_SignalR_Connected';
export const Shared_SignalR_ConnectingFailed = 'Shared_SignalR_ConnectingFailed';
export const Shared_UiBlockingMessage_Dismissed = 'Shared_UiBlockingMessage_Dismissed';
