import classNames from 'classnames';
import React from 'react';
import { getDropDown } from '../../../../../../_shared/components/DropDown.tsx';
import { DataUiCollection } from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IBaseTypeElementData } from '../../../models/elements/types/TypeElementData.ts';

const DropDown = getDropDown<IBaseTypeElementData>();

export interface ISingleOptionDropdownDataProps {
  readonly options: ReadonlyArray<IBaseTypeElementData>;
  readonly disabled: boolean;
  readonly selectedOption?: IBaseTypeElementData;
  readonly defaultText: string;
}

interface ISingleOptionDropdownCallbackProps {
  readonly onSelect: (option: IBaseTypeElementData | undefined) => void;
}

const selectId = (option: IBaseTypeElementData) => option.elementId;
const selectName = (option: IBaseTypeElementData) => option.name || 'untitled-content-item';

export const DependentTextDropdown: React.FC<
  ISingleOptionDropdownDataProps & ISingleOptionDropdownCallbackProps
> = ({ options, disabled, selectedOption, onSelect, defaultText }) => (
  <DropDown
    selectedOption={selectedOption}
    onSelect={onSelect}
    className={classNames('combo-box')}
    renderOptionName={selectName}
    getOptionId={selectId}
    options={options}
    disabled={disabled}
    dataUiCollectionName={DataUiCollection.DependentContentElements}
    defaultText={defaultText}
  />
);

DependentTextDropdown.displayName = 'DependentTextDropdown';
