import { assert } from '@kontent-ai/utils';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { IContentTypeConfig } from '../reducers/INewContentItemStoreState.type.ts';
import {
  getRecentlyUsedContentTypeIdsForEditing,
  getRecentlyUsedContentTypeIdsForListing,
} from './getRecentlyUsedContentTypeIds.ts';
import { getSuggestedContentTypeIdsForListing } from './getSuggestedContentTypeIds.ts';

export const getContentTypeSelectorConfigForEditing = (
  state: IStore,
  allowedContentTypeIds: Immutable.Set<Uuid> | null,
): IContentTypeConfig => {
  assert(allowedContentTypeIds, () => 'Missing allowed content type ids in content item editing.');

  return {
    allowedContentTypeIds,
    recentlyUsedContentTypeIds: getRecentlyUsedContentTypeIdsForEditing(
      state,
      allowedContentTypeIds,
    ),
    suggestedContentTypeIds: [],
  };
};

export const getContentTypeSelectorConfigForWebSpotlight = (
  _state: IStore,
  allowedContentTypeIds: Immutable.Set<Uuid> | null,
): IContentTypeConfig => ({
  allowedContentTypeIds,
  recentlyUsedContentTypeIds: null,
  suggestedContentTypeIds: [],
});

export const getContentTypeSelectorConfigForSpaces = (
  _state: IStore,
  allowedContentTypeIds: Immutable.Set<Uuid> | null,
): IContentTypeConfig => ({
  allowedContentTypeIds,
  recentlyUsedContentTypeIds: null,
  suggestedContentTypeIds: [],
});

export const getContentTypeSelectorConfigForListing = (
  state: IStore,
  allowedContentTypeIds: Immutable.Set<Uuid> | null,
): IContentTypeConfig => ({
  allowedContentTypeIds,
  recentlyUsedContentTypeIds: getRecentlyUsedContentTypeIdsForListing(state),
  suggestedContentTypeIds: getSuggestedContentTypeIdsForListing(state),
});
