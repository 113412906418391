import { RouterLinkButton } from '@kontent-ai/component-library/Button';
import React from 'react';
import { EmptyState } from '../../../../_shared/components/EmptyState/EmptyState.tsx';
import {
  NewDapiKeyDetailRoute,
  ProjectRouteParams,
} from '../../../../_shared/constants/routePaths.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { getCurrentProjectContainerId } from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { ListingMessage } from '../../../../_shared/uiComponents/ListingMessage/ListingMessage.tsx';
import { buildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { pluralizeWithCount } from '../../../../_shared/utils/stringUtils.ts';
import { isProjectManagerInAnyEnvironment } from '../../root/selectors/isProjectManagerInAnyEnvironment.ts';
import { ApiKeyDataTable } from '../components/ApiKeyDataTable.tsx';
import { ApiKeyEmptyTable } from '../components/ApiKeyEmptyTable.tsx';
import { ApiKeyExpirationStatus } from '../components/ApiKeyExpirationStatus.tsx';
import { ApiKeyList as ApiKeyListComponent } from '../components/ApiKeyList.tsx';
import { getApiKeyTabsWithLink } from '../constants/ApiKeyTabWithLink.ts';
import { getDapiKeyListingData } from '../selectors/getDapiKeyListingData.ts';
import { getEnvironmentName } from '../selectors/getEnvironmentName.ts';
import { getEnvironmentsDetails } from '../selectors/getEnvironmentsDetails.ts';
import { getVisibleApiKeyTabs } from '../selectors/getVisibleApiKeyTabs.ts';

const getStatusMessage = (count: number): JSX.Element => (
  <ListingMessage
    statusInfoMessage={{
      text: pluralizeWithCount('Delivery API key', count),
    }}
  />
);

export const DeliveryKeysListing: React.FC = () => {
  const apiKeyListingData = useSelector(getDapiKeyListingData);
  const visibleApiKeyTabs = useSelector(getVisibleApiKeyTabs);
  const environmentDetails = useSelector(getEnvironmentsDetails);
  const projectContainerId = useSelector(getCurrentProjectContainerId);
  const isProjectManager = useSelector(isProjectManagerInAnyEnvironment);

  const tabsWithLink = getApiKeyTabsWithLink(visibleApiKeyTabs);

  const newDapiKeyPath = buildPath<ProjectRouteParams>(NewDapiKeyDetailRoute, {
    projectContainerId,
  });
  const createNewItemLinkPath = isProjectManager ? newDapiKeyPath : undefined;

  return (
    <ApiKeyListComponent
      renderTabsContent={() =>
        apiKeyListingData.length !== 0 ? (
          <ApiKeyDataTable
            apiKeyListingData={apiKeyListingData}
            createNewItemLabel="Create Delivery API key"
            createNewItemLinkPath={createNewItemLinkPath}
            getEnvironmentName={(environmentId) =>
              getEnvironmentName(environmentId, environmentDetails)
            }
            renderExpiration={(expiresAt) => <ApiKeyExpirationStatus expiresAt={expiresAt} />}
            statusMessage={getStatusMessage(apiKeyListingData.length)}
          />
        ) : (
          <ApiKeyEmptyTable
            infoMessage={
              <EmptyState>
                <EmptyState.Title>There aren’t any Delivery API keys yet</EmptyState.Title>
                <EmptyState.Content>
                  Create a Delivery API key to retrieve content via API.
                </EmptyState.Content>
                <EmptyState.Footer>
                  <RouterLinkButton buttonStyle="primary" to={newDapiKeyPath}>
                    Create Delivery API key
                  </RouterLinkButton>
                </EmptyState.Footer>
              </EmptyState>
            }
          />
        )
      }
      tabs={tabsWithLink}
    />
  );
};

DeliveryKeysListing.displayName = 'DeliveryKeysListing';
