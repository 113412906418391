import { getDefinedValueOrDefault } from '@kontent-ai/utils';
import { Action } from '../../../../@types/Action.type.ts';
import {
  Content_Editing_AssignmentSubmittingFinished,
  Content_Editing_CancelScheduledPublishingFinished,
  Content_Editing_CancelScheduledUnpublishingFinished,
  Content_Editing_CurrentItemOverwritten,
  Content_Editing_DiscardNewVersionFinished,
  Content_Editing_PublishContentItemVariantFinished,
  Content_Editing_ScheduledPublishingFinished,
  Content_Editing_ScheduledUnpublishingFinished,
  Content_Editing_UnpublishContentItemVariantFinished,
} from '../../constants/editorActionTypes.ts';
import {
  ContentEditing_CascadeAction_UndoFinished,
  ContentEditing_CascadeModal_PublishingFinished,
  ContentEditing_CascadeModal_SchedulingFinished,
} from '../../features/CascadePublish/constants/cascadeModalActionTypes.ts';
import {
  ContentItemEditing_CreateNewVersion_Finished,
  ContentItemEditing_Init_Ready,
  ContentItemEditing_ItemElementsSaving_Finished,
} from '../../features/ContentItemEditing/constants/contentItemEditingActionTypes.ts';
import { ContentItemChangeReason } from '../../models/contentItem/ContentItemChangeReason.type.ts';
import {
  EditedContentItemVariant,
  getContentItemVariantFromServerModel,
} from '../../models/contentItem/edited/EditedContentItemVariant.ts';
import { shouldResetEditedItemState } from '../utils/shouldResetEditedItemState.ts';

const initialState: EditedContentItemVariant | null = null;

export function editedContentItemVariant(
  state = initialState,
  action: Action,
): EditedContentItemVariant | null {
  if (shouldResetEditedItemState(action)) {
    return initialState;
  }

  switch (action.type) {
    case ContentItemEditing_Init_Ready: {
      return action.payload.editedContentItemVariant;
    }

    case Content_Editing_AssignmentSubmittingFinished:
    case Content_Editing_CancelScheduledPublishingFinished:
    case Content_Editing_CancelScheduledUnpublishingFinished:
    case Content_Editing_DiscardNewVersionFinished:
    case Content_Editing_PublishContentItemVariantFinished:
    case Content_Editing_ScheduledPublishingFinished:
    case Content_Editing_ScheduledUnpublishingFinished:
    case Content_Editing_UnpublishContentItemVariantFinished:
    case ContentEditing_CascadeModal_PublishingFinished:
    case ContentEditing_CascadeModal_SchedulingFinished:
    case ContentEditing_CascadeAction_UndoFinished:
    case ContentItemEditing_CreateNewVersion_Finished: {
      if (!state) {
        throw new Error(`${action.type} event dispatched when no edited variant present.`);
      }

      const { itemVariantData } = action.payload;
      return itemVariantData.editedContentItemVariant;
    }

    case ContentItemEditing_ItemElementsSaving_Finished: {
      const { itemWithVariant } = action.payload;
      const newItem = getContentItemVariantFromServerModel(itemWithVariant.variant);

      return newItem;
    }

    case Content_Editing_CurrentItemOverwritten: {
      const changeReason = action.payload.params.changeReason;
      const difference = action.payload.params.difference;
      const isAssignmentChanged =
        changeReason === ContentItemChangeReason.Update || !!difference.workflowStep;

      if (state && isAssignmentChanged) {
        return (
          state && {
            ...state,
            lastPublishedAt: getDefinedValueOrDefault(
              difference.workflowStep?.lastPublishedAt,
              state.lastPublishedAt,
            ),
            lastPublishedBy: getDefinedValueOrDefault(
              difference.workflowStep?.lastPublishedBy,
              state.lastPublishedBy,
            ),
            publishingState: getDefinedValueOrDefault(
              difference.workflowStep?.publishingState,
              state.publishingState,
            ),
            assignment: {
              ...state.assignment,
              assignees: getDefinedValueOrDefault(difference.assignees, state.assignment.assignees),
              createdAt: getDefinedValueOrDefault(
                difference.workflowStep?.createdAt,
                state.assignment.createdAt,
              ),
              due: getDefinedValueOrDefault(difference.dueDate, state.assignment.due),
              note: getDefinedValueOrDefault(difference.note, state.assignment.note),
              scheduledToPublishAt: getDefinedValueOrDefault(
                difference.publishScheduleTime,
                state.assignment.scheduledToUnpublishAt,
              ),
              scheduledToUnpublishAt:
                changeReason === ContentItemChangeReason.Unpublish
                  ? null
                  : getDefinedValueOrDefault(
                      difference.unpublishScheduleTime,
                      state.assignment.scheduledToUnpublishAt,
                    ),
              workflowStatus: getDefinedValueOrDefault(
                difference.workflowStep?.workflowStatus,
                state.assignment.workflowStatus,
              ),
            },
          }
        );
      }

      return state;
    }

    default:
      return state;
  }
}
