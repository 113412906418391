import React, { RefObject } from 'react';
import { ActionItem, ActionMenu } from '../../../../../_shared/components/ActionMenu.tsx';
import { IconName } from '../../../../../_shared/constants/iconEnumGenerated.ts';
import { CodenameContentGroupButton } from '../../../../../_shared/containers/Codename/CodenameContentGroupButton.tsx';
import { IDropdownTippyOptions } from '../../../../../_shared/uiComponents/DropDown/dropDownTippyOptions.ts';
import { SquareButton } from '../../../../../_shared/uiComponents/SquareButton/SquareButton.tsx';
import { SquareButtonSize } from '../../../../../_shared/uiComponents/SquareButton/squareButtonSize.ts';
import { SquareButtonStyle } from '../../../../../_shared/uiComponents/SquareButton/squareButtonStyle.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

export interface IContentGroupMoreActionsStateProps {
  readonly actions: ReadonlyArray<ActionItem>;
}

const moreActionsText = 'More actions';
const tippyOptions: IDropdownTippyOptions = {
  placement: 'bottom-start',
  offset: [-8, 0],
};

export const ContentGroupMoreActions: React.FC<IContentGroupMoreActionsStateProps> = ({
  actions,
}) => (
  <ActionMenu
    actions={actions}
    renderToggleButton={(ref: RefObject<HTMLDivElement>, onClick, isOpen) => (
      <>
        <SquareButton
          iconName={IconName.Cogwheel}
          ariaLabel={moreActionsText}
          style={SquareButtonStyle.Quinary}
          size={SquareButtonSize.Quinary}
          ref={ref}
          onClick={onClick}
          tooltipPlacement="top-start"
          tooltipText={isOpen ? undefined : moreActionsText}
          {...getDataUiActionAttribute(DataUiAction.MoreActions)}
        />
        <CodenameContentGroupButton />
      </>
    )}
    tippyOptions={tippyOptions}
  />
);

ContentGroupMoreActions.displayName = 'ContentGroupMoreActions';
