import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventAction } from '../../../../_shared/models/TrackUserEvent.type.ts';
import { IProjectRepository } from '../../../../repositories/interfaces/IProjectRepository.type.ts';
import { IProjectServerModel } from '../../../../repositories/serverModels/IProjectServerModel.type.ts';
import {
  Project_CreateSampleProject_Failed,
  Project_CreateSampleProject_Finished,
  Project_CreateSampleProject_Started,
} from '../../constants/projectActionTypes.ts';
import { SampleProjectType } from '../../constants/sampleProjectType.ts';
import { createSampleProject } from '../../utils/createSampleProjectUtils.ts';
import { projectCreationInitialized } from '../projectsActions.ts';

interface ICreateSampleProjectCreatorDependencies {
  readonly loadProjects: () => ThunkPromise;
  readonly loadUserProjectsInfo: () => ThunkPromise;
  readonly projectRepository: IProjectRepository;
  readonly trackUserEvent: TrackUserEventAction;
}

const start = () =>
  ({
    type: Project_CreateSampleProject_Started,
  }) as const;

const fail = () =>
  ({
    type: Project_CreateSampleProject_Failed,
  }) as const;

const finish = (projectId: Uuid) =>
  ({
    type: Project_CreateSampleProject_Finished,
    payload: {
      projectId,
    },
  }) as const;

export type CreateSampleProjectActionsType = ReturnType<typeof start | typeof fail | typeof finish>;

export const createSampleProjectCreator =
  (deps: ICreateSampleProjectCreatorDependencies) =>
  (
    subscriptionId: Uuid,
    type: SampleProjectType,
    projectName: string,
    authToken: string,
  ): ThunkPromise =>
  async (dispatch) => {
    dispatch(start());
    try {
      const onEmptyProjectCreated = (project: IProjectServerModel) =>
        dispatch(projectCreationInitialized(project.projectGuid));

      const createdProject = await createSampleProject({
        onEmptyProjectCreated,
        subscriptionId,
        type,
        projectName,
        authToken,
      });

      await dispatch(deps.loadUserProjectsInfo());
      await dispatch(deps.loadProjects());

      const feature = 'create-new-sample-project-modal--submitted';
      dispatch(deps.trackUserEvent(TrackedEvent.FeatureUsed, { feature }));

      dispatch(
        deps.trackUserEvent(TrackedEvent.SampleProjectCreated, {
          projectType: type,
          createdProjectId: createdProject.projectGuid,
        }),
      );

      dispatch(finish(createdProject.projectGuid));
    } catch (error) {
      dispatch(fail());

      throw error;
    }
  };
