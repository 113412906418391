import { Callout } from '@kontent-ai/component-library/Callout';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { selectIntercomUtils } from '../../../../../_shared/selectors/selectIntercomUtils.ts';
import { InnovationLabFeatureCard } from '../../components/InnovationLabFeatureCard.tsx';
import { InnovationLabFeatureCardWrapper } from '../../components/InnovationLabFeatureCardWrapper.tsx';
import { InnovationLabFeatureDialogs } from '../../components/InnovationLabFeatureDialogs.tsx';
import { InnovationLabGenericConsent } from '../../components/InnovationLabGenericConsent.tsx';
import { useFeatureActivationDialog } from '../../hooks/useFeatureActivationDialog.ts';
import { activateInnovationLabFeature } from '../../thunks/activateInnovationLabFeature.ts';
import { deactivateInnovationLabFeature } from '../../thunks/deactivateInnovationLabFeature.ts';
import { InnovationLabCardProps } from '../../types/InnovationLabCardProps.type.ts';

export const AiSearchCard = ({ state, info, name }: InnovationLabCardProps) => {
  const intercomUtils = useSelector(selectIntercomUtils);

  const {
    dialogDisplayed,
    isAsyncOperationInProgress,
    onCardActionButtonClick,
    onDialogActionClick,
    onDialogClose,
  } = useFeatureActivationDialog(
    info,
    state,
    activateInnovationLabFeature('ai-enabling-search'),
    deactivateInnovationLabFeature('ai-search'),
  );

  const featureState = info.status;

  return (
    <>
      <InnovationLabFeatureCardWrapper featureState={featureState}>
        <InnovationLabFeatureCard
          activationState={state}
          featureName={name}
          featureState={featureState}
          isActionButtonLoading={isAsyncOperationInProgress}
          onCardActionButtonClick={onCardActionButtonClick}
          onContactSupport={intercomUtils.showIntercom}
          renderCardBody={() => (
            <>
              <p>
                Spend less time guessing the right words and more time finding the content you need.
              </p>
              <p>
                You don’t need to remember the exact name of a content item or use specific
                keywords—semantic search helps you find what you’re looking for, even if you
                describe it differently.
              </p>
            </>
          )}
          renderFooterBody={() =>
            state === 'activation-pending' && (
              <Callout calloutType="friendlyWarning">
                Feature is being activated, which could take from a couple of minutes to a few days,
                depending on your project’s complexity. While this is in progress, you can continue
                your work as usual.
              </Callout>
            )
          }
        />
      </InnovationLabFeatureCardWrapper>
      <InnovationLabFeatureDialogs
        dialogFeatureName={name}
        dialogDisplayed={dialogDisplayed}
        isAsyncOperationInProgress={isAsyncOperationInProgress}
        onDialogActionClick={onDialogActionClick}
        onDialogClose={onDialogClose}
        renderActivationDialogBody={() => (
          <>
            <Callout calloutType="quickTip" hideSubheadline>
              <p>
                <b>Innovation Lab feature activation</b>
              </p>
              <p>You’re about to activate {name}.</p>
              <p>
                The AI analyzes your written content and suggests relevant content items based on
                your search terms.
              </p>
            </Callout>
            <InnovationLabGenericConsent />
          </>
        )}
      />
    </>
  );
};
