import {
  AreAutomaticPopupsDisabled,
  AssetCollectionMandatory,
  IsProjectDataRestrictedToSpecificDatacenter,
  LegacyWebhooks,
  SendFinalReport,
  SubscriptionPropertiesKnownServerKeys,
} from './SubcriptionPropertiesServerKeys.ts';

type SubscriptionPropertiesModelValue = 'Enabled' | 'Disabled' | null;
type BooleanSubscriptionPropertiesModelValue = 'true' | 'false' | null;

export type SubscriptionPropertiesModel = {
  AssetCollectionMandatory: SubscriptionPropertiesModelValue;
  LegacyWebhooks: SubscriptionPropertiesModelValue;
  SendFinalReport: SubscriptionPropertiesModelValue;
  AreAutomaticPopupsDisabled: BooleanSubscriptionPropertiesModelValue;
  IsProjectDataRestrictedToSpecificDatacenter: BooleanSubscriptionPropertiesModelValue;
};

const enableDisableTypeGuard = (value: any): value is SubscriptionPropertiesModelValue =>
  value === 'Enabled' || value === 'Disabled' || value === null;
const booleanTypeGuard = (value: any): value is BooleanSubscriptionPropertiesModelValue =>
  value === 'true' || value === 'false' || value === null;

export const isKnownSubscriptionPropertyServerKey = (
  serverKey: any,
): serverKey is SubscriptionPropertiesKnownServerKeys =>
  Object.keys(SubscriptionPropertyStateKeysByServerKeys).includes(serverKey);

/**
 * Ensure that the default value of added property won't affect UI tests or Screen Comparison.
 * You can set the right value in KenticoCloud.UI.Tests repository at TestDataFactory\Provider\ProjectProvider.cs.
 * If you are removing the property from here, make sure code managing the property in UI tests is also removed.
 */
export const SubscriptionPropertyStateKeysByServerKeys: Record<
  SubscriptionPropertiesKnownServerKeys,
  string
> = {
  [AssetCollectionMandatory]: 'AssetCollectionMandatory',
  [LegacyWebhooks]: 'LegacyWebhooks',
  [SendFinalReport]: 'SendFinalReport',
  [AreAutomaticPopupsDisabled]: 'AreAutomaticPopupsDisabled',
  [IsProjectDataRestrictedToSpecificDatacenter]: 'IsProjectDataRestrictedToSpecificDatacenter',
};

export const SubscriptionPropertyTypeGuardsByServerKeys: Record<
  SubscriptionPropertiesKnownServerKeys,
  typeof enableDisableTypeGuard | typeof booleanTypeGuard
> = {
  [AssetCollectionMandatory]: enableDisableTypeGuard,
  [LegacyWebhooks]: enableDisableTypeGuard,
  [SendFinalReport]: enableDisableTypeGuard,
  [AreAutomaticPopupsDisabled]: booleanTypeGuard,
  [IsProjectDataRestrictedToSpecificDatacenter]: booleanTypeGuard,
};
