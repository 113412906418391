import { assert } from '@kontent-ai/utils';
import { Location } from 'history';
import React from 'react';
import { useLocation } from 'react-router';
import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { withAutoDispatcher } from '../../../../../../_shared/components/AutoDispatcher.tsx';
import { ValidationConstants } from '../../../../../../_shared/constants/validationConstants.ts';
import { useDataSelector } from '../../../../../../_shared/hooks/useDataSelector.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { ActiveCapabilityType } from '../../../../../../_shared/models/activeCapability.type.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { DataUiInput } from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { hasActiveVariantCapabilityForEditedItem } from '../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import {
  getCurrentUserRoleForCollectionForLanguage,
  getCurrentUserRoleForCollectionForLanguageOrThrow,
} from '../../../../../../_shared/utils/permissions/getContributorRole.ts';
import { getPathForContentItemDetailLanguageSwitcher } from '../../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { untitledContentItem } from '../../../../../contentInventory/content/constants/uiConstants.ts';
import { itemEditorOperationIds } from '../../../../../contentInventory/content/utils/itemEditorOperationIdUtils.ts';
import { addDefaultLanguageSuffix } from '../../../../../environmentSettings/localization/utils/languageUtils.ts';
import { ContentItemChangeReason } from '../../../../models/contentItem/ContentItemChangeReason.type.ts';
import {
  autoDispatchSaveCancelled,
  languageVariantSwitched,
} from '../../actions/contentItemEditingActions.ts';
import {
  autoDispatchSavePending,
  saveContentItemNameToServer,
  saveEditedContentItemName,
} from '../../actions/thunkContentItemEditingActions.ts';
import { ItemNameElement as ItemNameElementComponent } from '../../components/elements/itemNameElement/ItemNameElement.tsx';
import {
  ItemNameGuidelineStatus,
  getItemNameGuidelinesStatus,
  isContentItemNameAndCodenameEditingAllowed,
} from '../../utils/itemEditingUtils.ts';

const editedContentItemSelector = (s: IStore) => {
  const editedContentItem = s.contentApp.editedContentItem;

  assert(editedContentItem, () => 'ItemNameElement.tsx: "editedContentItem" is not loaded.');

  return editedContentItem;
};

const defaultLanguagePathSelector = (s: IStore, location: Location): string => {
  const defaultLanguageId = s.data.languages.defaultLanguage.id;
  return getPathForContentItemDetailLanguageSwitcher(location.pathname, defaultLanguageId, false);
};

const guidelinesStatusSelector = (s: IStore): ItemNameGuidelineStatus => {
  const contentItemVariants = s.contentApp.contentItemVariants;
  const editedContentItem = editedContentItemSelector(s);
  const typeId = editedContentItem.editedContentItemTypeId;
  const selectedLanguageId = getSelectedLanguageIdOrThrow(s);
  const selectedLanguageRoleSettings = getCurrentUserRoleForCollectionForLanguageOrThrow(
    s,
    editedContentItem.collectionId,
    selectedLanguageId,
  ).settings;
  const defaultLanguageId = s.data.languages.defaultLanguage.id;
  const defaultLanguageRoleSettings = getCurrentUserRoleForCollectionForLanguage(
    s,
    editedContentItem.collectionId,
    defaultLanguageId,
  )?.settings;
  return getItemNameGuidelinesStatus(
    contentItemVariants,
    typeId,
    selectedLanguageId,
    selectedLanguageRoleSettings,
    defaultLanguageId,
    defaultLanguageRoleSettings,
  );
};

const isNameEditingAllowedSelector = (s: IStore): boolean => {
  const contentItemVariants = s.contentApp.contentItemVariants;
  const defaultLanguageId = s.data.languages.defaultLanguage.id;
  const selectedLanguageId = getSelectedLanguageIdOrThrow(s);
  return isContentItemNameAndCodenameEditingAllowed(
    contentItemVariants,
    defaultLanguageId,
    selectedLanguageId,
  );
};

const ConnectedItemNameElement: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const editedContentItemName = useSelector((s) => editedContentItemSelector(s).name);
  const defaultLanguageName = useDataSelector(
    (data) => addDefaultLanguageSuffix(data.languages.defaultLanguage).name,
  );
  const defaultLanguagePath = useSelector((s) => defaultLanguagePathSelector(s, location));
  const guidelinesStatus = useSelector(guidelinesStatusSelector);
  const isLoading = useSelector((s) => !s.contentApp.editedContentItemVariant);
  const isNameEditingAllowed = useSelector(isNameEditingAllowedSelector);
  const readOnly = useSelector(
    (s) =>
      !isNameEditingAllowed ||
      !hasActiveVariantCapabilityForEditedItem(ActiveCapabilityType.UpdateContent, s),
  );

  const handleChange = (name: string) => dispatch(saveEditedContentItemName(name));
  const handleLanguageSwitch = () => dispatch(languageVariantSwitched());

  return (
    <ItemNameElementComponent
      autoFocus={!editedContentItemName}
      dataUiInputName={DataUiInput.EntityName}
      defaultLanguageName={defaultLanguageName}
      defaultLanguagePath={defaultLanguagePath}
      guidelinesStatus={guidelinesStatus}
      isLoading={isLoading}
      isNameEditingAllowed={isNameEditingAllowed}
      maxLength={ValidationConstants.ContentItemNameMaxLength}
      onChange={handleChange}
      onLanguageSwitch={handleLanguageSwitch}
      placeholder={untitledContentItem}
      readOnly={readOnly}
      title="Content item name"
      value={editedContentItemName}
    />
  );
};

type ObservedState = {
  readonly itemName: string;
};

const mapObservedState = ({ contentApp: { editedContentItem } }: IStore): ObservedState => ({
  itemName: editedContentItem?.name ?? '',
});

const shouldDispatch = (oldState: IStore, newState: IStore): boolean => {
  const { contentApp } = newState;
  const currentItem = oldState.contentApp.editedContentItem;
  const nextItem = contentApp.editedContentItem;
  const overwrittenItem = newState.contentApp.editedContentItemOverwritten;

  const wasAutoRefreshed =
    !!overwrittenItem &&
    !!nextItem &&
    overwrittenItem.changeReason === ContentItemChangeReason.Update &&
    overwrittenItem.difference.name === nextItem.name;

  return (
    !contentApp.itemValidationErrors.size &&
    !!currentItem &&
    !!nextItem &&
    currentItem.id === nextItem.id &&
    !wasAutoRefreshed
  );
};

const nameDispatchPending = (): ThunkFunction => (dispatch) =>
  dispatch(autoDispatchSavePending([itemEditorOperationIds.name]));

const nameDispatchCancelled = (): ThunkFunction => (dispatch) =>
  dispatch(autoDispatchSaveCancelled([itemEditorOperationIds.name]));

export const ItemNameElement = withAutoDispatcher<NoProps, ObservedState>(
  mapObservedState,
  () => saveContentItemNameToServer(window.location.pathname),
  1000,
  shouldDispatch,
  nameDispatchPending,
  nameDispatchCancelled,
)(ConnectedItemNameElement);
