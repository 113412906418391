import React from 'react';
import { IPopoverTargetProps } from '../../../../../../../component-library/components/Dialogs/Popover/usePopover.tsx';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { getCurrentProjectPlan } from '../../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { shallowEqual } from '../../../../../../_shared/utils/shallowEqual.ts';
import { isMultipleWorkflowsConfigurationVisible } from '../../../../../../_shared/utils/workflow/isMultipleWorkflowsConfigurationVisible.ts';
import {
  getHintForWorkflowsCurrentUserCanCreateVariantIn,
  getWorkflowsCurrentUserCanCreateVariantIn,
} from '../../../../../itemEditor/selectors/workflows/getWorkflowsCurrentUserCanCreateVariantIn.ts';
import { getTooltipForWorkflowsCurrentUserCanCreateVariantIn } from '../../../../../itemEditor/utils/workflowTooltipUtils.ts';
import { ContentComponentConversionConfirmationDialog as ContentComponentConversionConfirmationDialogComponent } from '../../components/dialogs/ContentComponentConversionConfirmationDialog.tsx';

type Props = {
  readonly contentTypeId: Uuid | undefined;
  readonly isOpen: boolean;
  readonly onCancel: () => void;
  readonly onConvert: (selectedWorkflowId?: Uuid) => void;
  readonly renderTarget: (targetProps: IPopoverTargetProps) => React.ReactElement;
};

export const ContentComponentConversionConfirmationDialogContainer: React.FC<
  React.PropsWithChildren<Props>
> = ({ contentTypeId, children, ...props }) => {
  const parentCollectionId = useSelector(
    (state) => state.contentApp.editedContentItem?.collectionId ?? null,
  );
  const selectedLanguageId = useSelector(getSelectedLanguageIdOrThrow);
  const workflows = useSelector(
    (state) =>
      getWorkflowsCurrentUserCanCreateVariantIn(
        state,
        selectedLanguageId,
        parentCollectionId,
        contentTypeId ?? null,
      ),
    shallowEqual,
  );
  const workflowsHint = useSelector((state) =>
    getHintForWorkflowsCurrentUserCanCreateVariantIn(
      state,
      selectedLanguageId,
      parentCollectionId,
      contentTypeId ?? null,
    ),
  );

  return (
    <ContentComponentConversionConfirmationDialogComponent
      {...props}
      requireWorkflowSelection={useSelector((state) =>
        isMultipleWorkflowsConfigurationVisible(
          state.data.workflows.byId,
          getCurrentProjectPlan(state),
        ),
      )}
      workflowTooltipText={getTooltipForWorkflowsCurrentUserCanCreateVariantIn(workflowsHint)}
      workflows={workflows}
    />
  );
};

ContentComponentConversionConfirmationDialogContainer.displayName =
  'ContentComponentConversionConfirmationDialogContainer';
export {
  ContentComponentConversionConfirmationDialogContainer as ContentComponentConversionConfirmationDialog,
};
