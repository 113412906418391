import { Box } from '@kontent-ai/component-library/Box';
import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Inline } from '@kontent-ai/component-library/Inline';
import { ShortcutsConfig, useHotkeys } from '@kontent-ai/component-library/hooks';
import { Spacing, Typography, colorTextDefault } from '@kontent-ai/component-library/tokens';
import { useRef } from 'react';
import {
  AltShortcutTemplate,
  ShortcutSymbols,
} from '../../../../../../../_shared/constants/shortcutSymbols.ts';
import { NavigatedFromData } from '../../../../../../contentInventory/content/stores/IContentAppStoreState.ts';
import { CommentThreadNavigationData } from '../../../../../utils/commentUtils.ts';
import { CommentThreadCssClass } from '../../../constants/uiConstants.ts';

export type CommentThreadNavigationProps = CommentThreadNavigationData & {
  readonly allowKeyboardNavigation: boolean;
  readonly isFocused: boolean;
  readonly threadId: Uuid;
  readonly statusId: string;
  readonly onNavigate: (threadId: Uuid, navigatedFrom: NavigatedFromData) => void;
};

export const CommentThreadNavigation = ({
  allowKeyboardNavigation,
  isFocused,
  nextThreadId,
  onNavigate,
  previousThreadId,
  statusId,
  threadId,
  threadIndex,
  totalThreads,
}: CommentThreadNavigationProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useHotkeys(
    {
      [ShortcutsConfig.AltUp]: (e: KeyboardEvent): void => {
        e.preventDefault();
        onNavigate(previousThreadId, {
          threadId,
          threadClientTop: getThreadClientTop(ref.current),
        });
      },
      [ShortcutsConfig.AltDown]: (e: KeyboardEvent): void => {
        e.preventDefault();
        onNavigate(nextThreadId, {
          threadId,
          threadClientTop: getThreadClientTop(ref.current),
        });
      },
    },
    { isDisabled: !allowKeyboardNavigation || !isFocused, ref: null },
  );

  return (
    <Inline align="center" spacing={Spacing.S} ref={ref}>
      {totalThreads > 1 && (
        <Inline align="center" spacing={Spacing.XS}>
          <QuinaryButton
            aria-label="Previous comment"
            tooltipText="Previous comment"
            tooltipShortcuts={
              allowKeyboardNavigation ? AltShortcutTemplate(ShortcutSymbols.ArrowUp) : undefined
            }
            onClick={(e) => {
              e.stopPropagation();
              onNavigate(previousThreadId, {
                threadId,
                threadClientTop: getThreadClientTop(ref.current),
              });
            }}
          >
            <QuinaryButton.Icon icon={Icons.ChevronUp} />
          </QuinaryButton>
          <QuinaryButton
            aria-label="Next comment"
            tooltipText="Next comment"
            tooltipShortcuts={
              allowKeyboardNavigation ? AltShortcutTemplate(ShortcutSymbols.ArrowDown) : undefined
            }
            onClick={(e) => {
              e.stopPropagation();
              onNavigate(nextThreadId, {
                threadId,
                threadClientTop: getThreadClientTop(ref.current),
              });
            }}
          >
            <QuinaryButton.Icon icon={Icons.ChevronDown} />
          </QuinaryButton>
        </Inline>
      )}
      <Box id={statusId} typography={Typography.LabelLarge} color={colorTextDefault}>
        {threadIndex + 1} of {totalThreads}
      </Box>
    </Inline>
  );
};

const getThreadClientTop = (navigationElement: HTMLElement | null): number | null =>
  navigationElement?.closest(`.${CommentThreadCssClass}`)?.getBoundingClientRect().top ?? null;
