import { memoize } from '@kontent-ai/memoization';
import Immutable from 'immutable';
import { ILanguageSwitcherOption } from '../../../applications/contentInventory/content/models/ILanguageSwitcherOption.type.ts';
import { IContentItemVariantReference } from '../../../applications/itemEditor/models/contentItem/ContentItemVariantReference.ts';
import { IEditedContentItem } from '../../../applications/itemEditor/models/contentItem/edited/EditedContentItem.ts';
import { PublishingState } from '../../../data/constants/PublishingState.ts';
import { ILanguage, Languages } from '../../../data/models/languages/Language.ts';
import { emptyAssignmentWorkflowStep } from '../../../data/models/workflow/WorkflowStep.ts';
import { IUser } from '../../../data/reducers/user/IUser.type.ts';
import { IStore } from '../../stores/IStore.type.ts';
import { getAllActiveLanguagesForCurrentUserForCollection } from '../languageUtils.ts';
import { canCreateItemVariantForUser } from '../permissions/activeCapabilities.ts';
import { Capability } from '../permissions/capability.ts';
import { getCannotViewItemMessage } from '../permissions/getCannotViewItemMessage.ts';
import { getPathForContentItemDetailLanguageSwitcher } from '../routing/routeTransitionUtils.ts';

function getOptionErrorMessage(
  canCreate: boolean,
  cannotViewMessage: string | undefined,
  doesVariantExist: boolean,
): string | undefined {
  if (!canCreate && !doesVariantExist) {
    return 'Your role prevents you from creating content in this language.';
  }

  if (cannotViewMessage && doesVariantExist) {
    return cannotViewMessage;
  }

  return undefined;
}

export const getEditingLanguageSwitcherOptionsMemoized = memoize.maxOne(
  (
    languages: Languages,
    contentItemVariants: Immutable.Map<Uuid, IContentItemVariantReference>,
    currentPathname: string,
    canCreateLanguageVariants: Immutable.Map<Uuid, boolean>,
  ): ReadonlyArray<ILanguageSwitcherOption> => {
    const createLanguageVariantOption = (language: ILanguage): ILanguageSwitcherOption => {
      const variant = contentItemVariants.get(language.id);
      const workflowStatus = variant?.assignment.workflowStatus ?? emptyAssignmentWorkflowStep;
      const publishingState = variant?.publishingState ?? PublishingState.None;
      const doesVariantExist = !!variant && !variant.isArchived;
      const canCreateVariant = canCreateLanguageVariants.get(language.id) ?? false;
      const cannotViewMessage = variant && getCannotViewItemMessage(variant);

      return {
        codename: language.codename,
        languageId: language.id,
        languageName: language.name,
        doesVariantExist,
        // Not translated variant needs to redirect to the editor to make sure the user gets the correct UI and prompt
        // for copying from existing language after the variant is automatically created
        linkPath: getPathForContentItemDetailLanguageSwitcher(
          currentPathname,
          language.id,
          doesVariantExist,
        ),
        isDisabled:
          (!canCreateVariant && !doesVariantExist) || (!!cannotViewMessage && doesVariantExist),
        publishingState,
        workflowStatus,
        tooltipText: getOptionErrorMessage(canCreateVariant, cannotViewMessage, doesVariantExist),
      };
    };

    return languages.toArray().map(createLanguageVariantOption);
  },
);

const getCanCreateLanguageVariants = memoize.maxOne(
  (
    userState: IUser,
    projectId: Uuid,
    activeLanguages: Languages,
    editedContentItem: IEditedContentItem | null,
  ) =>
    activeLanguages
      .map(
        (language: ILanguage) =>
          !!editedContentItem &&
          canCreateItemVariantForUser(
            editedContentItem.editedContentItemTypeId,
            editedContentItem.collectionId,
            language.id,
            userState,
            projectId,
          ),
      )
      .toOrderedMap(),
);

export const getEditingLanguageSwitcherOptions = (state: IStore, currentPathname: string) => {
  const {
    sharedApp: { currentProjectId },
    contentApp: { contentItemVariants, editedContentItem },
    data: { languages, user },
  } = state;

  const collectionId = editedContentItem?.collectionId ?? null;
  const activeLanguages = getAllActiveLanguagesForCurrentUserForCollection(
    user,
    currentProjectId,
    collectionId,
    languages,
    Capability.ViewContent,
  );
  const canCreateLanguageVariants = getCanCreateLanguageVariants(
    user,
    currentProjectId,
    activeLanguages,
    editedContentItem,
  );

  return getEditingLanguageSwitcherOptionsMemoized(
    activeLanguages,
    contentItemVariants,
    currentPathname,
    canCreateLanguageVariants,
  );
};
