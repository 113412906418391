import React, { useMemo } from 'react';
import { IBaseTypeElementData } from '../models/elements/types/TypeElementData.ts';

interface ITypeElementConfigurationContextState {
  readonly originalTypeElementsById: ReadonlyMap<Uuid, IBaseTypeElementData>;
}

const defaultContext: ITypeElementConfigurationContextState = {
  originalTypeElementsById: new Map(),
};

interface ITypeElementConfigurationContextProps {
  readonly children: React.ReactNode;
  readonly originalTypeElements: ReadonlyArray<IBaseTypeElementData>;
}

export const OriginalTypeContext =
  React.createContext<ITypeElementConfigurationContextState>(defaultContext);

export const OriginalTypeContextProvider: React.FC<ITypeElementConfigurationContextProps> = ({
  originalTypeElements,
  children,
}) => {
  const value = useMemo(
    () => ({
      originalTypeElementsById: new Map(
        originalTypeElements.map((originalElement) => [originalElement.elementId, originalElement]),
      ),
    }),
    [originalTypeElements],
  );
  return <OriginalTypeContext.Provider value={value}>{children}</OriginalTypeContext.Provider>;
};

OriginalTypeContextProvider.displayName = 'OriginalTypeContextProvider';
