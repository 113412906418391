import { IconButton, QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { useContext, useEffect, useState } from 'react';
import { RefinedNavigationContext } from '../../../applications/refinedNavigation/contexts/RefinedNavigationContext.tsx';
import { useSelector } from '../../hooks/useSelector.ts';
import { selectIntercomUtils } from '../../selectors/selectIntercomUtils.ts';
const launcherButtonId = 'intercomLauncher';

type Props = {
  readonly isExpanded?: boolean;
};

export const IntercomLauncherButton = ({ isExpanded }: Props) => {
  const { isRefinedNavigationFeatureEnabled } = useContext(RefinedNavigationContext);

  const [isChatOpen, setIsChatOpen] = useState(false);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const intercomUtils = useSelector(selectIntercomUtils);

  useEffect(() => {
    intercomUtils.update({
      alignment: 'left',
      horizontal_padding: isExpanded ? 248 : 68,
      vertical_padding: 20,
      custom_launcher_selector: `#${launcherButtonId}`,
      hide_default_launcher: true,
    });
    intercomUtils.onUnreadCountChange(setUnreadMessageCount);
    intercomUtils.onShow(() => setIsChatOpen(true));
    intercomUtils.onHide(() => setIsChatOpen(false));

    return () => {
      intercomUtils.update({
        alignment: 'right',
        horizontal_padding: 20,
        vertical_padding: 20,
        custom_launcher_selector: '',
        hide_default_launcher: false,
      });
    };
  }, [intercomUtils, isExpanded]);

  if (isRefinedNavigationFeatureEnabled) {
    return (
      <QuinaryButton
        tooltipText={isChatOpen ? 'Close chat' : 'Got any questions? Drop us a line!'}
        tooltipPlacement="right"
        aria-label="Open chat with customer support."
        id={launcherButtonId}
        activated={isChatOpen}
        fullWidth={isExpanded}
      >
        <QuinaryButton.Icon icon={Icons.Intercom} screenReaderText="Drag asset button" />
        {isExpanded && 'Intercom'}
        <QuinaryButton.Badge value={isChatOpen ? 0 : unreadMessageCount} />
      </QuinaryButton>
    );
  }

  return (
    <IconButton
      id={launcherButtonId}
      activated={isChatOpen}
      badgeValue={isChatOpen ? 0 : unreadMessageCount}
      buttonStyle="secondary"
      iconName="Intercom"
      aria-label="Open chat with customer support."
      size="medium"
      tooltipPlacement="right"
      tooltipText={isChatOpen ? 'Close chat' : 'Got any questions? Drop us a line!'}
    />
  );
};
