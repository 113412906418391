import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { MultiSelect } from '@kontent-ai/component-library/MultiSelect';
import { IBaseSelectItem, ItemId } from '@kontent-ai/component-library/Selects';
import React, { ChangeEvent, memo, useCallback, useMemo } from 'react';
import { documentationLinks } from '../../../../../../_shared/constants/documentationLinks.ts';
import { ValidationConstants } from '../../../../../../_shared/constants/validationConstants.ts';
import {
  DataUiCollection,
  DataUiElement,
  getDataUiCollectionAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ICustomTypeElementData } from '../../../models/elements/CustomTypeElementData.tsx';
import { IBaseTypeElementData } from '../../../models/elements/types/TypeElementData.ts';
import {
  ITypeElementDataProps,
  ITypeElementOwnProps,
} from '../../../types/ITypeElementProps.type.ts';
import { CustomTypeElementValidationResult } from '../../../utils/typeElementValidators/types/CustomTypeElementValidationResult.type.ts';
import { getSortedElementsByName } from '../../../utils/typeUtils.ts';
import { TypeElementConfigurationCategory } from '../shared/TypeElementConfigurationCategory.tsx';
import { TypeElementConfigurationSection } from '../shared/TypeElementConfigurationSection.tsx';
import { TypeElementWithTypedName } from '../shared/TypeElementWithTypedName.tsx';
import { TextInput } from '../shared/configuration/TextInput.tsx';

type ICustomContentTypeElementProps = ITypeElementDataProps<
  ICustomTypeElementData,
  CustomTypeElementValidationResult
> &
  ITypeElementOwnProps<ICustomTypeElementData, CustomTypeElementValidationResult> & {
    readonly allElements: ReadonlyArray<IBaseTypeElementData>;
  };

const CustomContentTypeElement: React.FC<ICustomContentTypeElementProps> = ({
  allElements,
  ...props
}) => {
  const { typeElementData, onChange } = props;

  const sortedElements = getSortedElementsByName(allElements);

  const updateSourceUrl = (event: ChangeEvent<HTMLInputElement>): void => {
    props.onChange({
      ...typeElementData,
      sourceUrl: event.target.value,
    });
  };

  const updateConfig = (event: ChangeEvent<HTMLInputElement>): void => {
    props.onChange({
      ...typeElementData,
      config: event.target.value,
    });
  };

  const updateSelectedOptions = useCallback(
    (selectedOptionIds: ReadonlySet<ItemId>): void => {
      const newData: ICustomTypeElementData = {
        ...typeElementData,
        allowedElements: [...selectedOptionIds],
      };
      onChange(newData);
    },
    [typeElementData, onChange],
  );

  const elementItems: ReadonlyArray<IBaseSelectItem> = useMemo(
    () =>
      sortedElements.map(
        (element: IBaseTypeElementData): IBaseSelectItem => ({
          id: element.elementId,
          label: element.name,
        }),
      ),
    [sortedElements],
  );

  return (
    <TypeElementWithTypedName
      {...props}
      hintContent={
        <>
          See 
          <OutwardLink href={documentationLinks.customElements}>
            how to use custom elements
          </OutwardLink>
           to extend the default Kontent.ai authoring experience.
        </>
      }
    >
      <TypeElementConfigurationCategory>
        <TextInput
          value={typeElementData.sourceUrl}
          isValid={props.validationResult.isSourceUrlValid}
          title="Hosted code URL (HTTPS)"
          onChange={updateSourceUrl}
          placeholder="https://example.com"
          maxLength={ValidationConstants.UrlMaxLength}
          uiElement={DataUiElement.CustomElementUrl}
        />
        <TypeElementConfigurationSection
          title="Allow the custom element to read values of specific elements"
          className="content-element__config-section-control--full-width"
          uiElement={DataUiElement.CustomElementAllowedElements}
        >
          <MultiSelect
            aria-label="Select elements"
            items={elementItems}
            onSelectionChange={updateSelectedOptions}
            placeholder="Select elements"
            selectedItemIds={typeElementData.allowedElements}
            {...getDataUiCollectionAttribute(DataUiCollection.ContentTypeElements)}
          />
        </TypeElementConfigurationSection>
        <TextInput
          value={typeElementData.config || undefined}
          isValid={props.validationResult.isConfigValid}
          title="Parameters {JSON}"
          onChange={updateConfig}
          placeholder="Place your JSON here"
          allowMultiline
          maxLength={ValidationConstants.CustomTypeElementConfigMaxLength}
          uiElement={DataUiElement.CustomElementParameters}
        />
      </TypeElementConfigurationCategory>
    </TypeElementWithTypedName>
  );
};

CustomContentTypeElement.displayName = 'CustomContentTypeElement';

const CustomContentTypeElementMemoized = memo(CustomContentTypeElement);
export { CustomContentTypeElementMemoized as CustomContentTypeElement };
