import { BaseColor, Size, colorOverlayBackground, px } from '@kontent-ai/component-library/tokens';
import { rgba } from '@kontent-ai/component-library/utils';
import { animated, useTransition } from '@react-spring/web';
import React from 'react';
import ReactFocusLock from 'react-focus-lock';
import styled from 'styled-components';
import { UiBlockingMessage as UiBlockingMessageType } from '../reducers/ISharedState.type.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../utils/dataAttributes/DataUiAttributes.ts';
import { joinWithLinebreaks } from '../utils/renderUtils.tsx';
import { Loader, LoaderType } from './Loader.tsx';

const LogoImg = styled.img`
  height: ${px(Size.M)};
`;

LogoImg.displayName = 'LogoImg';

export interface IUiBlockingMessageDataProps {
  readonly uiBlockingMessage: UiBlockingMessageType | null;
}

const transparentOverlayColor = rgba(BaseColor.OceanBlue70, 0);

export const UiBlockingMessage: React.FC<IUiBlockingMessageDataProps> = ({ uiBlockingMessage }) => {
  const transitions = useTransition(uiBlockingMessage, {
    from: {
      background: transparentOverlayColor,
    },
    enter: {
      background: colorOverlayBackground,
    },
    leave: {
      background: transparentOverlayColor,
    },
  });

  const animatedMaskModal = transitions(
    (style, item) =>
      item && (
        <animated.div
          className="mask-modal"
          style={style}
          tabIndex={0}
          {...getDataUiElementAttribute(DataUiElement.MaskModal)}
        >
          <div
            className="ui-blocking-message__wrapper"
            {...getDataUiElementAttribute(DataUiElement.UiBlockingMessage)}
          >
            <div className="ui-blocking-message ui-blocking-message--in-progress">
              <div className="ui-blocking-message__pane" data-hj-suppress="">
                <div className="ui-blocking-message__logo">
                  <LogoImg alt="Kontent.ai logo" src="/images/kontent-ai-symbol-rgb-v2.svg" />
                </div>

                {item.textLines && (
                  <h1 className="ui-blocking-message__content-title">
                    {joinWithLinebreaks(item.textLines)}
                  </h1>
                )}

                {item.subtextLines && (
                  <h3 className="ui-blocking-message__content-subtitle">
                    {joinWithLinebreaks(item.subtextLines)}
                  </h3>
                )}
                <Loader type={LoaderType.MinimumHeight} />
              </div>
              <div className="ui-blocking-message__filler" />
            </div>
          </div>
        </animated.div>
      ),
  );

  return (
    <ReactFocusLock>
      <div className="dialog-viewer" aria-live="polite">
        {animatedMaskModal}
      </div>
    </ReactFocusLock>
  );
};

UiBlockingMessage.displayName = 'UiBlockingMessage';
