import React, { memo } from 'react';
import { AssetTypeElementDefaultValue } from '../../../../containers/typeElements/asset/AssetTypeElementDefaultValue.tsx';
import { AssetTypeElementLimitation } from '../../../../containers/typeElements/asset/AssetTypeElementLimitation.tsx';
import { IAssetTypeElementData } from '../../../../models/elements/AssetTypeElementData.ts';
import { ITypeElementOwnProps } from '../../../../types/ITypeElementProps.type.ts';
import { AssetTypeElementValidationResult } from '../../../../utils/typeElementValidators/types/AssetTypeElementValidationResult.type.ts';
import { TypeElementConfigurationCategory } from '../../shared/TypeElementConfigurationCategory.tsx';
import { TypeElementWithTypedName } from '../../shared/TypeElementWithTypedName.tsx';

const AssetTypeElement: React.FC<
  ITypeElementOwnProps<IAssetTypeElementData, AssetTypeElementValidationResult>
> = (props) => {
  const { onChange, typeElementData, validationResult } = props;

  return (
    <TypeElementWithTypedName
      {...props}
      hintContent="You can include assets in a content item via this content element. The assets you upload are automatically part of the project’s Asset library."
    >
      <TypeElementConfigurationCategory>
        <AssetTypeElementLimitation
          onChange={onChange}
          typeElementData={typeElementData}
          validationResult={validationResult}
        />
        <AssetTypeElementDefaultValue onChange={onChange} typeElementData={typeElementData} />
      </TypeElementConfigurationCategory>
    </TypeElementWithTypedName>
  );
};

AssetTypeElement.displayName = 'AssetTypeElement';

const AssetTypeElementMemo = memo(AssetTypeElement);
export { AssetTypeElementMemo as AssetTypeElement };
