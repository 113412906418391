import { AvatarSize } from '@kontent-ai/component-library/Avatar';
import { useAttachRef } from '@kontent-ai/hooks';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useFocusRing } from '@react-aria/focus';
import { chain, mergeProps } from '@react-aria/utils';
import classNames from 'classnames';
import React, { forwardRef } from 'react';
import { UserAvatar } from '../../../../../../_shared/components/users/UserAvatar.tsx';
import {
  DataUiCollection,
  DataUiElement,
  getDataUiCollectionAttribute,
  getDataUiElementAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { formatUserName } from '../../../../../../_shared/utils/users/usersUtils.ts';
import { ICurrentUserInfo } from '../../../../../../data/models/user/CurrentUserInfo.ts';
import { AutoFocusBehavior } from '../../../../../richText/plugins/behavior/FocusPlugin.tsx';
import {
  CommentThreadItemType,
  ICommentThreadItemContentModel,
} from '../../../../models/comments/CommentThreadItem.ts';
import { ICommentThread } from '../../../../models/comments/CommentThreads.ts';
import { getCommentThreadTriggerElement } from '../../../../utils/commentUtils.ts';
import { FocusedCommentThreadScroller } from '../../components/comments/FocusedCommentThreadScroller.tsx';
import { CommentThreadCssClass } from '../../constants/uiConstants.ts';
import { CommentThreadFocusRing } from './CommentThreadFocusRing.tsx';
import {
  NewCommentThreadItem,
  NewCommentThreadItemHandle,
} from './threadItem/NewCommentThreadItem.tsx';
import { useAccessibleCommentThread } from './useAccessibleCommentThread.ts';

const isThreadType = (type?: string): type is CommentThreadItemType => !!type;

type Props = {
  readonly commentThread: ICommentThread;
  readonly currentUser: ICurrentUserInfo;
  readonly type: string | undefined;
  readonly isHighlighted: boolean;
  readonly onBlur: () => void;
  readonly onCancel: () => void;
  readonly onSubmit: (
    type: CommentThreadItemType,
    content: ICommentThreadItemContentModel,
  ) => Promise<void>;
  readonly onFocus: () => void;
};

export const UnsavedInlineCommentThread = forwardRef<HTMLDivElement, Props>(
  (
    { commentThread, currentUser, isHighlighted, onBlur, onCancel, onFocus, onSubmit, type },
    forwardedRef,
  ) => {
    const commentEditorRef = React.useRef<NewCommentThreadItemHandle>(null);
    const { refObject, refToForward } = useAttachRef(forwardedRef);

    const onInteractOutside = (): void => {
      if (!commentThread.isSubmitting) {
        const commentEditor = commentEditorRef.current;

        if (isHighlighted) {
          onBlur();
        }

        if (commentEditor && !commentEditor.isTextValid()) {
          onCancel();
        }
      }
    };

    const { commentThreadProps, onClose } = useAccessibleCommentThread(
      {
        'aria-label': 'New comment thread',
        isDisabled: false,
        onLeave: (_, isOutsideInteraction) => {
          if (isOutsideInteraction) {
            onInteractOutside();
          } else {
            onCancel();
            getCommentThreadTriggerElement(commentThread.id)?.focus();
          }
        },
      },
      refObject,
    );

    const { isFocusVisible, focusProps: focusRingProps } = useFocusRing();

    return (
      <div
        id={commentThread.id}
        className={classNames(CommentThreadCssClass, {
          'comment-thread--is-highlighted': isHighlighted,
        })}
        onClick={onFocus}
        ref={refToForward}
        {...mergeProps(focusRingProps, commentThreadProps)}
        {...getDataUiCollectionAttribute(DataUiCollection.CommentThreadItems)}
        {...getDataUiElementAttribute(DataUiElement.CommentThread)}
      >
        <CommentThreadFocusRing isFocusVisible={isFocusVisible} />
        <div className="comment-thread__comment comment">
          <div className="comment__header">
            <UserAvatar user={currentUser} size={AvatarSize.S} />
            <div className="comment__author-timestamp">
              <div className="comment__author">{formatUserName(currentUser)}</div>
              <div className="comment__info">{type}</div>
            </div>
          </div>
          {isThreadType(type) && (
            <NewCommentThreadItem
              // We don't focus until the comment input is in view
              // to not interfere with the positioning of newly focused comment
              autoFocusBehavior={AutoFocusBehavior.WhenInView}
              elementSegment={commentThread.elementSegment}
              inputValue=""
              isEditing
              isSubmitting={commentThread.isSubmitting}
              onCancel={chain(onCancel, onClose)}
              onSubmit={onSubmit}
              ref={commentEditorRef}
              type={type}
            />
          )}
        </div>
        {isHighlighted && (
          <FocusedCommentThreadScroller focusedThread={commentThread} navigatedFrom={null} />
        )}
      </div>
    );
  },
);

UnsavedInlineCommentThread.displayName = 'UnsavedInlineCommentThread';
