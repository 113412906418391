import React from 'react';
import { ItemColumnCode } from '../../../../../../../_shared/constants/itemColumnCode.ts';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { ColumnSettingsSource } from '../../../../../../../_shared/models/events/ColumnSettingsEventData.type.ts';
import { isEmptyOrWhitespace } from '../../../../../../../_shared/utils/stringUtils.ts';
import { getContentItemListingOrderBy } from '../../../../reducers/listingUi/selectors/getContentItemListingOrderBy.ts';
import { getContentItemListingHiddenColumns } from '../../../../selectors/getContentItemListingHiddenColumns.ts';
import {
  selectAllContentItems,
  unselectAllContentItems,
} from '../../actions/contentItemInventoryActions.ts';
import {
  loadListingContentItemsForInventory,
  orderContentItems,
} from '../../actions/thunkContentItemInventoryActions.ts';
import { ContentItemScrollTableHead as ContentItemScrollTableHeadComponent } from '../../components/ItemInventoryScrollTable/ContentItemScrollTableHead.tsx';
import { CantBulkUpdateFullTextSearchFoundItems } from '../../constants/uiConstants.ts';

export const ContentItemScrollTableHead: React.FC = () => {
  const dispatch = useDispatch();

  const filter = useSelector((s) => s.contentApp.listingUi.filter);
  const isSelectAllOn = useSelector((s) => {
    const { itemsSelection } = s.contentApp.listingUi;
    return itemsSelection.selectAll && !itemsSelection.unselectedItemIds.length;
  });
  const contentItemListingOrderBy = useSelector(getContentItemListingOrderBy);
  const notAllItemsAreLoaded = useSelector(
    (s) => !!s.data.listingContentItems.nextContinuationToken,
  );
  const hiddenColumns = useSelector(getContentItemListingHiddenColumns);

  const isFullTextUsed = !isEmptyOrWhitespace(filter.searchPhrase);
  const selectAllDisabledMessage =
    isFullTextUsed && notAllItemsAreLoaded ? CantBulkUpdateFullTextSearchFoundItems : undefined;

  const onColumnHeadClicked = (columnCode: ItemColumnCode) => {
    dispatch(orderContentItems(columnCode, true));
    dispatch(loadListingContentItemsForInventory());
  };

  const onCheckboxClick = isSelectAllOn
    ? () => dispatch(unselectAllContentItems())
    : () => dispatch(selectAllContentItems());

  return (
    <ContentItemScrollTableHeadComponent
      contentListingOrderBy={contentItemListingOrderBy}
      hiddenColumns={hiddenColumns}
      isSelectAllOn={isSelectAllOn}
      onCheckboxClick={onCheckboxClick}
      onColumnHeadClicked={onColumnHeadClicked}
      selectAllDisabledMessage={selectAllDisabledMessage}
      source={ColumnSettingsSource.ContentInventory}
    />
  );
};
