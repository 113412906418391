import { Collection } from '@kontent-ai/utils';
import { useEffect, useMemo } from 'react';
import { useDataSelector } from '../../../../../../_shared/hooks/useDataSelector.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { ContentItemFilterOrigin } from '../../../../../../_shared/models/events/ContentItemFilterEventData.type.ts';
import { getCurrentProject } from '../../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { getFilterStorage } from '../../../../../../localStorages/getFilterStorage.ts';
import { IListingFilter, emptyListingFilter } from '../../../models/filter/IListingFilter.ts';
import { ConsistencyFilterEntities, getConsistentFilter } from '../utils/consistencyUtils.ts';

export type PreselectedFilter = Partial<
  Pick<
    IListingFilter,
    'selectedContentTypesNodes' | 'selectedCollectionsNodes' | 'selectedSpacesNodes'
  >
>;

export const useSetUpContentItemFilter = (
  origin: ContentItemFilterOrigin,
  filterSetUp: (filter: IListingFilter) => void,
  filterLeft: () => void,
  preselected: PreselectedFilter | undefined,
  forcedContentTypeIds: ReadonlySet<Uuid> | undefined,
): void => {
  const { projectId } = useSelector(getCurrentProject);
  const collectionsById = useDataSelector((data) => data.collections.byId);
  const contentTypesById = useDataSelector((data) => data.contentTypes.byId);
  const usersById = useDataSelector((data) => data.users.usersById);
  const workflowsById = useDataSelector((data) => data.workflows.byId);
  const sitemap = useDataSelector((data) => data.sitemap.data);
  const spacesById = useDataSelector((data) => data.spaces.byId);
  const taxonomyGroupsById = useDataSelector((data) => data.taxonomyGroups.byId);

  const entities = useMemo((): ConsistencyFilterEntities => {
    return {
      collectionIds: Collection.getKeys(collectionsById),
      contentTypeIds: contentTypesById.keySeq().toArray(),
      taxonomyGroups: taxonomyGroupsById.valueSeq().toArray(),
      workflows: Collection.getValues(workflowsById),
      sitemap,
      spaceIds: Collection.getKeys(spacesById),
      users: Collection.getValues(usersById),
    };
  }, [
    collectionsById,
    contentTypesById,
    taxonomyGroupsById,
    workflowsById,
    sitemap,
    spacesById,
    usersById,
  ]);

  const filterFromStorage = useLoadFilterFromStorage(projectId, entities, origin);
  const preselectedFilter = usePreselectFilter(
    entities,
    filterFromStorage,
    preselected,
    forcedContentTypeIds,
  );

  useEffect(() => {
    filterSetUp(preselectedFilter);
    return () => {
      filterLeft();
    };
  }, [filterSetUp, filterLeft, preselectedFilter]);
};

const useLoadFilterFromStorage = (
  projectId: Uuid,
  entities: ConsistencyFilterEntities,
  origin: ContentItemFilterOrigin,
): IListingFilter => {
  return useMemo(() => {
    const potentiallyInconsistentFilter =
      getFilterStorage(origin)?.load(projectId) ?? emptyListingFilter;
    return getConsistentFilter(entities, potentiallyInconsistentFilter);
  }, [origin, entities, projectId]);
};

const getFilterNodes = (
  preselected: ReadonlySet<Uuid> | undefined,
  allowedIds: ReadonlyArray<Uuid>,
  defaultValue: ReadonlySet<Uuid>,
): ReadonlySet<Uuid> =>
  new Set(
    preselected
      ? Collection.getValues(preselected).filter((preselectedId) =>
          allowedIds.includes(preselectedId),
        )
      : defaultValue,
  );

const usePreselectFilter = (
  entities: ConsistencyFilterEntities,
  filterFromStorage: IListingFilter,
  preselected: PreselectedFilter | undefined,
  forcedContentTypeIds: ReadonlySet<Uuid> | undefined,
): IListingFilter => {
  return useMemo(() => {
    const preselectedFilter = {
      selectedContentTypesNodes:
        forcedContentTypeIds ||
        getFilterNodes(
          preselected?.selectedContentTypesNodes,
          entities.contentTypeIds,
          filterFromStorage.selectedContentTypesNodes,
        ),
      selectedCollectionsNodes: getFilterNodes(
        preselected?.selectedCollectionsNodes,
        entities.collectionIds,
        filterFromStorage.selectedCollectionsNodes,
      ),
      selectedSpacesNodes: getFilterNodes(
        preselected?.selectedSpacesNodes,
        entities.spaceIds,
        filterFromStorage.selectedSpacesNodes,
      ),
    };

    return {
      ...filterFromStorage,
      ...preselectedFilter,
    };
  }, [filterFromStorage, entities, preselected, forcedContentTypeIds]);
};
