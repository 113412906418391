import { memoize } from '@kontent-ai/memoization';
import { assert, Collection } from '@kontent-ai/utils';
import { getLanguageCodename } from '../../../_shared/utils/languageUtils.ts';
import { isAbsoluteWebUrl } from '../../../_shared/utils/urlUtils.ts';
import { CollectionsMap } from '../../../data/models/collections/Collection.ts';
import { ILanguage, Languages } from '../../../data/models/languages/Language.ts';
import {
  CodenameMacro,
  CollectionMacro,
  ItemIdMacro,
  SpaceMacro,
  UrlSlugMacro,
  VariantMacro,
} from '../../previewConfiguration/constants/macros.ts';
import { IPreviewConfiguration } from '../../previewConfiguration/models/PreviewConfiguration.type.ts';
import { getSimpleTextValue } from '../../richText/utils/editorSimpleTextValueUtils.ts';
import { ICompiledContentItemElementData } from '../models/contentItemElements/ICompiledContentItemElement.type.ts';
import { isUrlSlugElement } from '../models/contentItemElements/compiledItemElementTypeGuards.ts';

type MacroReplacement = [string, string];

export function getUrlSlug(
  elements: ReadonlyArray<ICompiledContentItemElementData>,
): string | undefined {
  const element = elements.find(isUrlSlugElement);
  if (element) {
    return getSimpleTextValue(element._editorState).trim();
  }

  return undefined;
}

function getCollectionCodeName(collections: CollectionsMap, collectionId: Uuid): string {
  const collection = collections.get(collectionId);
  assert(collection, () => `Collection '${collectionId}' not found.`);
  return collection.codeName;
}

function getSpaceDomain(
  previewConfiguration: IPreviewConfiguration,
  spaceId: Uuid | null,
): string | undefined {
  const spaceDomain = previewConfiguration.spaceDomains.find(
    (domain) => domain.spaceId === spaceId,
  );
  return spaceDomain?.domain;
}

export function getContentItemPreviewUrlPattern(
  contentItemTypeId: Uuid,
  previewConfiguration: IPreviewConfiguration,
  spaceId: Uuid | null,
): string | undefined {
  const previewUrlPatterns = previewConfiguration.previewUrlPatterns.get(contentItemTypeId) ?? [];

  const previewUrlPattern = spaceId
    ? previewUrlPatterns.find((pattern) => pattern.spaces.has(spaceId)) ||
      previewUrlPatterns.find((pattern) => !pattern.spaces.size)
    : previewUrlPatterns.find((pattern) => !pattern.spaces.size) ||
      Collection.getFirst(previewUrlPatterns);

  if (previewUrlPattern?.enabled) {
    return previewUrlPattern.urlPattern;
  }

  return undefined;
}

export enum PreviewError {
  AccessDenied = 'AccessDenied',
  HttpInIFrame = 'HttpInIFrame',
  LoadFailed = 'LoadFailed',
  MissingSpaceDomain = 'MissingSpaceDomain',
  MissingUrlSlugElement = 'MissingUrlSlugElement',
  MissingUrlSlugValue = 'MissingUrlSlugValue',
  NoPreview = 'NoPreview',
  NotAbsoluteUrl = 'NotAbsoluteUrl',
  NotHttps = 'NotHttps',
  NotTranslated = 'NotTranslated',
  TooManyPageContentItemsLinked = 'TooManyPageContentItemsLinked',
}

export function getPreviewErrorMessage(error: PreviewError | undefined): string | undefined {
  switch (error) {
    case PreviewError.LoadFailed:
      return 'Item preview failed to load.';

    case PreviewError.MissingSpaceDomain:
      return 'To preview the item, add a space domain.';

    case PreviewError.MissingUrlSlugElement:
      return 'To preview the item, add the URL slug element to the content type.';

    case PreviewError.MissingUrlSlugValue:
      return 'To preview the item, fill in the URL slug element.';

    case PreviewError.NotAbsoluteUrl:
      return 'Preview URL must be an absolute URL.';

    case PreviewError.NotHttps:
      return 'Preview URL has to start with “https://”.';

    case PreviewError.HttpInIFrame:
      return 'Your preview URL starts with “http://”. To use preview, ensure your preview URL starts with “https://”.';

    case PreviewError.NotTranslated:
      return 'The item is not translated.';

    case PreviewError.NoPreview:
      return 'Preview is not configured.';

    case PreviewError.TooManyPageContentItemsLinked:
      return 'You can link only one item representing the content of your page.';

    default:
      return 'Unknown preview error';
  }
}

export type PreviewUrlInfo = {
  readonly error: PreviewError | undefined;
  readonly shouldShowPreviewLink: boolean;
  readonly spaceId: Uuid | null;
  readonly url: string | undefined;
  readonly usedUrlSlug: string | null;
};

export const getContentItemPreviewUrlInfo = memoize.maxOne(
  (
    contentItemId: Uuid | undefined | null,
    contentItemCodename: string | undefined | null,
    contentItemTypeId: Uuid | undefined | null,
    contentItemCollectionId: Uuid | undefined | null,
    variantId: Uuid | undefined | null,
    elements: ReadonlyArray<ICompiledContentItemElementData>,
    previewConfiguration: IPreviewConfiguration | null,
    defaultLanguage: ILanguage,
    languages: Languages,
    collections: CollectionsMap,
    spaceId: Uuid | null,
  ): PreviewUrlInfo => {
    if (
      contentItemId &&
      contentItemCodename &&
      contentItemTypeId &&
      contentItemCollectionId &&
      variantId &&
      !!previewConfiguration
    ) {
      const urlPattern = getContentItemPreviewUrlPattern(
        contentItemTypeId,
        previewConfiguration,
        spaceId,
      );
      if (urlPattern) {
        const urlSlug = getUrlSlug(elements);
        if (!urlSlug && new RegExp(UrlSlugMacro, 'ig').test(urlPattern)) {
          return {
            error:
              typeof urlSlug === 'string'
                ? PreviewError.MissingUrlSlugValue
                : PreviewError.MissingUrlSlugElement,
            shouldShowPreviewLink: true,
            spaceId,
            url: undefined,
            usedUrlSlug: null,
          };
        }

        const spaceDomain = getSpaceDomain(previewConfiguration, spaceId);

        if (!spaceDomain && new RegExp(SpaceMacro, 'ig').test(urlPattern)) {
          return {
            error: PreviewError.MissingSpaceDomain,
            shouldShowPreviewLink: true,
            spaceId,
            url: undefined,
            usedUrlSlug: null,
          };
        }

        const languageCodename = getLanguageCodename(variantId, defaultLanguage, languages);
        const collectionName = getCollectionCodeName(collections, contentItemCollectionId);

        const macroReplacements: ReadonlyArray<MacroReplacement> = [
          ...(urlSlug ? [[UrlSlugMacro, urlSlug] satisfies MacroReplacement] : []),
          [CodenameMacro, contentItemCodename],
          [ItemIdMacro, contentItemId],
          [CollectionMacro, collectionName],
          [VariantMacro, languageCodename],
          ...(spaceDomain ? [[SpaceMacro, spaceDomain] satisfies MacroReplacement] : []),
        ];

        /*
         * New regexes are created each time because of the 'g' mode.
         * 'g' mode makes the regex keep track of last index it has matched. And it has to be reset to 0 every time the regex is used.
         */
        const url = macroReplacements.reduce(
          (reducedPattern, [macro, value]) =>
            reducedPattern.replace(new RegExp(macro, 'ig'), value),
          urlPattern,
        );

        if (!isAbsoluteWebUrl(url)) {
          return {
            error: PreviewError.NotAbsoluteUrl,
            shouldShowPreviewLink: true,
            spaceId,
            url: undefined,
            usedUrlSlug: null,
          };
        }

        return {
          error: undefined,
          shouldShowPreviewLink: true,
          spaceId,
          url,
          usedUrlSlug: urlSlug ?? null,
        };
      }
    }

    return {
      error: undefined,
      shouldShowPreviewLink: false,
      spaceId,
      url: undefined,
      usedUrlSlug: null,
    };
  },
);
