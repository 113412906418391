import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { useOurFocusRing } from '@kontent-ai/component-library/hooks';
import { BorderRadius, px, shadowFocusStyles } from '@kontent-ai/component-library/tokens';
import { css } from 'styled-components';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ariaLabel, label } from '../constants/uiConstants.ts';

type Props = Readonly<{
  isCompact: boolean;
  trackButtonClick: () => void;
  url: string;
}>;

export const OpenInNewTabQuinaryButton = ({ isCompact, trackButtonClick, url }: Props) => {
  const { isFocusVisible, focusProps } = useOurFocusRing();

  const styles = css`
    display: inline-flex;
  
    && {
      border-radius: ${px(BorderRadius.S)};
    }
  
    &:focus-visible {
      outline: none;
    }
  
    ${isFocusVisible && shadowFocusStyles}
  `;

  // This is not exactly ok, but we don't have AnchorQuinaryButtons
  return (
    <OutwardLink
      aria-label={ariaLabel}
      // eslint-disable-next-line @typescript-eslint/no-base-to-string
      css={`${styles}`}
      href={url}
      onClick={trackButtonClick}
      {...focusProps}
    >
      <QuinaryButton
        aria-hidden
        aria-label={ariaLabel}
        disableTabulator
        tooltipPlacement="top"
        tooltipText={label}
        {...getDataUiActionAttribute(DataUiAction.OpenInNewTab)}
      >
        <QuinaryButton.Icon icon={Icons.ArrowRightTopSquare} />
        {!isCompact && label}
      </QuinaryButton>
    </OutwardLink>
  );
};
