import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { ConnectDragSource } from 'react-dnd';
import { AssetTileWithActions } from '../../../../../../../../component-library/components/AssetTile/AssetTileWithActions.tsx';
import { NoAccessAssetTile } from '../../../../../../../../component-library/components/AssetTile/NoAccessAssetTile.tsx';
import { NonExistingAssetTile } from '../../../../../../../../component-library/components/AssetTile/NonExistingAssetTile.tsx';
import { DragAction } from '../../../../../../../_shared/components/DragDrop/DragAction.tsx';
import { Asset, IAsset } from '../../../../../../../data/models/assets/Asset.ts';
import { getAssetData } from '../../../../utils/asset/assetDataUtils.ts';

type DragButtonProps = {
  readonly connectDragSource: ConnectDragSource | undefined;
};

const DragButton: React.FC<DragButtonProps> = ({ connectDragSource }) => {
  if (!connectDragSource) {
    return null;
  }

  return <DragAction connectDragSource={connectDragSource} />;
};

type DownloadButtonProps = {
  readonly downloadLink: string | null | undefined;
};

const DownloadButton: React.FC<DownloadButtonProps> = ({ downloadLink }) => {
  if (!downloadLink) {
    return null;
  }

  return (
    <QuinaryButton
      tooltipText="Download"
      tooltipPlacement="bottom"
      onClick={() => window.open(downloadLink)}
    >
      <QuinaryButton.Icon icon={Icons.ArrowDownLine} screenReaderText="Download" />
    </QuinaryButton>
  );
};

type RemoveButtonProps = {
  readonly onRemove: (() => void) | undefined;
};

const RemoveButton: React.FC<RemoveButtonProps> = ({ onRemove }) => {
  if (!onRemove) {
    return null;
  }

  return (
    <QuinaryButton tooltipText="Remove" tooltipPlacement="bottom" onClick={onRemove}>
      <QuinaryButton.Icon icon={Icons.ModalClose} screenReaderText="Remove" />
    </QuinaryButton>
  );
};

type AssetTileProps = {
  readonly asset: IAsset;
  readonly canViewAsset: boolean;
  readonly connectDragSource?: ConnectDragSource;
  readonly isDragging: boolean;
  readonly onOpenEditAssetDialog?: (assetId: Uuid) => void;
  readonly onRemoveAsset?: (assetId: Uuid) => void;
};

export const AssetTile: React.FC<AssetTileProps> = ({
  asset,
  canViewAsset,
  connectDragSource,
  isDragging,
  onOpenEditAssetDialog,
  onRemoveAsset,
}) => {
  if (!canViewAsset) {
    return (
      <NoAccessAssetTile
        isDragging={isDragging}
        renderDragButton={() => <DragButton connectDragSource={connectDragSource} />}
        renderToolbarActions={() => <RemoveButton onRemove={() => onRemoveAsset?.(asset.id)} />}
      />
    );
  }

  if (!Asset.exists(asset)) {
    return (
      <NonExistingAssetTile
        isDragging={isDragging}
        renderDragButton={() => <DragButton connectDragSource={connectDragSource} />}
        renderToolbarActions={() => <RemoveButton onRemove={() => onRemoveAsset?.(asset.id)} />}
      />
    );
  }

  return (
    <AssetTileWithActions
      assetData={getAssetData(asset)}
      isDragging={isDragging}
      onClick={() => onOpenEditAssetDialog?.(asset.id)}
      renderDragButton={() => <DragButton connectDragSource={connectDragSource} />}
      renderToolbarActions={() => (
        <Inline spacing={Spacing.S}>
          <DownloadButton downloadLink={asset._downloadLink} />
          <RemoveButton onRemove={() => onRemoveAsset?.(asset.id)} />
        </Inline>
      )}
    />
  );
};

AssetTile.displayName = 'AssetTile';
