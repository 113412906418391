import { noOperation } from '@kontent-ai/utils';
import { useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { EditContentTypeSnippetRouteParams } from '../../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { editorLeft } from '../../shared/actions/sharedContentModelsActions.ts';
import { getElementsWithAffectedLocalization } from '../../shared/utils/getElementsWithAffectedLocalization.ts';
import { snippetSetFirstInvalidElement } from '../actions/snippetsActions.ts';
import {
  initContentTypeSnippetEditor,
  saveEditedContentTypeSnippet,
} from '../actions/thunkSnippetsActions.ts';
import { ContentTypeSnippetEditor as ContentTypeSnippetEditorComponent } from '../components/ContentTypeSnippetEditor.tsx';
import { getSnippetsAppEditor } from '../selectors/getSnippetsAppEditor.ts';

export const ContentTypeSnippetEditor = () => {
  const history = useHistory();
  const { contentTypeSnippetId: editedContentTypeSnippetId } =
    useParams<EditContentTypeSnippetRouteParams>();
  const params = useMemo(
    () => ({
      editedContentTypeSnippetId,
      history,
    }),
    [editedContentTypeSnippetId, history],
  );
  const [isInitThunkDone] = useThunkPromise(initContentTypeSnippetEditor, params);

  useEffect(() => {
    return () => {
      dispatch(editorLeft());
    };
  }, []);

  const editedContentTypeSnippet = useSelector(
    (state) => getSnippetsAppEditor(state).editedContentTypeSnippet,
  );
  const hasUnsavedChanges = useSelector(
    (state) => getSnippetsAppEditor(state).editedContentTypeSnippetIsModified,
  );
  const isBeingSaved = useSelector(
    (state) => getSnippetsAppEditor(state).contentTypeSnippetIsBeingSaved,
  );
  const allSet = useSelector(
    (state) =>
      isInitThunkDone &&
      getSnippetsAppEditor(state).contentTypeSnippetEditingLoadingStatus === LoadingStatus.Loaded,
  );
  const showSnippetUsedWithPublishedItemWarning = useSelector(
    (state) => getSnippetsAppEditor(state).isEditedContentTypeSnippetUsedInPublishedItem,
  );
  const validationResults = useSelector((state) => getSnippetsAppEditor(state).validationResults);

  const dispatch = useDispatch();

  const unsavedNavigationHandler = async (
    onSuccess: () => void,
    onFail: () => void,
  ): Promise<void> => {
    try {
      await dispatch(saveEditedContentTypeSnippet());
      onSuccess();
    } catch {
      onFail();
    }
  };
  const onSetFirstInvalidElement = (elementId: Uuid) => {
    dispatch(snippetSetFirstInvalidElement(elementId));
  };

  const editedTypeSnippet = useSelector(
    (s) => s.contentModelsApp.snippets.editor.editedContentTypeSnippet,
  );
  const originalTypeSnippetElements = useSelector(
    (s) => s.data.snippets.byId.get(editedTypeSnippet.id)?.typeElements,
  );

  const elementsWithAffectedLocalization = useMemo(
    () => getElementsWithAffectedLocalization(editedTypeSnippet, originalTypeSnippetElements),
    [editedTypeSnippet, originalTypeSnippetElements],
  );

  if (!allSet) {
    return <Loader />;
  }

  return (
    <ContentTypeSnippetEditorComponent
      editedContentTypeSnippet={editedContentTypeSnippet}
      elementsWithAffectedLocalization={elementsWithAffectedLocalization}
      hasUnsavedChanges={hasUnsavedChanges}
      isBeingSaved={isBeingSaved}
      onSaveContentTypeSnippet={() => dispatch(saveEditedContentTypeSnippet()).catch(noOperation)}
      onSetFirstInvalidElement={onSetFirstInvalidElement}
      onUnsavedNavigation={unsavedNavigationHandler}
      showSnippetUsedWithPublishedItemWarning={showSnippetUsedWithPublishedItemWarning}
      validationResults={validationResults}
    />
  );
};
