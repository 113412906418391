import { InvariantException } from '@kontent-ai/errors';
import { addDays, subDays } from 'date-fns';
import { useCallback } from 'react';
import { DefaultWorkflowId } from '../../../../../_shared/constants/variantIdValues.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { getCurrentUserId } from '../../../../../_shared/selectors/getCurrentUser.ts';
import { PublishedStep } from '../../../../../data/models/workflow/WorkflowStep.ts';
import { IListingContentItemServerModel } from '../../../../../repositories/serverModels/IListingContentItemServerModel.type.ts';
import { UnchangedPublishedItemsWidget } from '../components/UnchangedPublishedItemsWidget.tsx';
import { ContentTypeOption } from '../types/ContentTypeOption.type.ts';
import { UnchangedPublishedItemsListingRequestOptionsModel } from '../types/UnchangedPublishedItemsListingRequestOptionsModel.type.ts';
import { createDemoUnchangedPublishedItem } from '../utils/createDemoUnchangedPublishedItem.ts';

const demoItemNames: ReadonlyArray<string> = [
  'AI Boosts Physical Therapy',
  'Big Data’s Health Influence',
  'Boost Efficiency in Content Creation',
  'Streamline Content Workflow: A Guide',
  '2024 Healthtech Trends Overview',
  'AI Impact on Healthcare: 2024 Report',
  'Data-Driven Health: Tech Fighting Global Health Crises',
  'The Digital Doctor: VR in Mental Health Therapy',
  'Tech in Health Monitoring',
  'Telemedicine’s Patient Impact',
];

export const demoContentTypeOptions: ContentTypeOption = [
  {
    label: 'Articles',
    id: 'c47ebba7-9cfe-4f68-8edc-3e5bed923360',
  },
  {
    label: 'Blogposts',
    id: '7d3b0266-9493-4558-be7f-8fd20a5882e0',
  },
  {
    label: 'Success Stories',
    id: '45cf9923-fd84-41a0-9c83-1f87be1b04c8',
  },
  {
    label: 'Tutorials',
    id: '04344d4f-443c-465f-bbd2-3f27703811c4',
  },
  {
    label: 'Reports',
    id: '28c692cc-6182-4ed7-a92a-5a8e962b009d',
  },
];

export const getDemoUnchangedPublishedItemsData = (
  contentTypeIds: UuidArray,
  assignee: string,
  publishedWorkflowStep: PublishedStep,
): ReadonlyArray<IListingContentItemServerModel> => {
  const oldestPublishDate = subDays(new Date(), 150);

  return demoItemNames
    .map((name, index) => {
      return createDemoUnchangedPublishedItem(
        name,
        demoContentTypeOptions[index % demoContentTypeOptions.length]?.id ?? '',
        publishedWorkflowStep,
        assignee,
        addDays(oldestPublishDate, index * 4),
      );
    })
    .filter(({ item }) => contentTypeIds.length === 0 || contentTypeIds.includes(item.type._id));
};

export const UnchangedPublishedItemsWidgetDemoContainer: React.FC = () => {
  const workflows = useSelector((s) => s.data.workflows.byId);
  const currentUserId = useSelector(getCurrentUserId);

  const publishedWorkflowStep = workflows.get(DefaultWorkflowId)?.publishedStep;
  if (publishedWorkflowStep === undefined) {
    throw InvariantException('Loading published workflow step failed');
  }

  const itemsFetcher = useCallback(
    (requestOptions: UnchangedPublishedItemsListingRequestOptionsModel) =>
      Promise.resolve({
        data: getDemoUnchangedPublishedItemsData(
          requestOptions.typeIds,
          currentUserId,
          publishedWorkflowStep,
        ),
        continuationToken: null,
      }),
    [currentUserId, publishedWorkflowStep],
  );

  return (
    <UnchangedPublishedItemsWidget
      contentTypeOptions={demoContentTypeOptions}
      isLivePreviewPreferred={false}
      itemsFetcher={itemsFetcher}
      useRowLinks={false}
    />
  );
};

UnchangedPublishedItemsWidgetDemoContainer.displayName =
  'UnchangedPublishedItemsWidgetDemoContainer';
