import { Box } from '@kontent-ai/component-library/Box';
import { inputMinHeight } from '@kontent-ai/component-library/Input';
import { borderWidthDefault } from '@kontent-ai/component-library/tokens';
import React, { memo } from 'react';
import { DataUiInput } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  EditorProps,
  emptyPluginComposition,
  useEditor,
  usePluginComposition,
} from '../../editorCore/Editor.composition.tsx';
import { ApiLimitationsPlugin } from '../../plugins/apiLimitations/ApiLimitationsPlugin.tsx';
import { SimpleTextEditorLimitations } from '../../plugins/apiLimitations/api/EditorFeatureLimitations.ts';
import { CompositionModeAdjustmentsPlugin } from '../../plugins/behavior/CompositionModeAdjustmentsPlugin.tsx';
import { FocusPlugin } from '../../plugins/behavior/FocusPlugin.tsx';
import { ForceSelectionOnBlurPlugin } from '../../plugins/behavior/ForceSelectionOnBlurPlugin.tsx';
import { ForceSingleUnstyledBlockPlugin } from '../../plugins/behavior/ForceSingleUnstyledBlockPlugin.tsx';
import { ManualResetPlugin } from '../../plugins/behavior/ManualResetPlugin.tsx';
import { OnChangePlugin } from '../../plugins/behavior/OnChangePlugin.tsx';
import { SpellCheckPlugin } from '../../plugins/behavior/SpellCheckPlugin.tsx';
import { ClipboardPlugin } from '../../plugins/clipboard/ClipboardPlugin.tsx';
import {
  CustomInputHandlingPlugin,
  EnterKeyBehavior,
} from '../../plugins/customInputHandling/CustomInputHandlingPlugin.tsx';
import { DraftJsPlugin } from '../../plugins/draftJs/DraftJsPlugin.tsx';
import { EntityApiPlugin } from '../../plugins/entityApi/EntityApiPlugin.tsx';
import { IgnoreCtrlEnterPlugin } from '../../plugins/keyboardShortcuts/IgnoreCtrlEnterPlugin.tsx';
import { KeyboardShortcutsPlugin } from '../../plugins/keyboardShortcuts/KeyboardShortcutsPlugin.tsx';
import { textKeyCommandMap } from '../../plugins/keyboardShortcuts/api/editorKeyboardUtils.ts';
import { AutomaticWebLinkConversionPlugin } from '../../plugins/links/web/AutomaticWebLinkConversionPlugin.tsx';
import { SimpleWebLinksPlugin } from '../../plugins/links/web/SimpleWebLinksPlugin.tsx';
import { MentionsPlugin } from '../../plugins/mentions/MentionsPlugin.tsx';
import { UndoRedoPlugin } from '../../plugins/undoRedo/UndoRedoPlugin.tsx';
import { PlaceholderPlugin } from '../../plugins/visuals/PlaceholderPlugin.tsx';
import { StylesPlugin } from '../../plugins/visuals/StylesPlugin.tsx';
import { WrapperPlugin } from '../../plugins/visuals/WrapperPlugin.tsx';
import { CommentInputStylesPlugin } from './plugins/CommentInputStylesPlugin.tsx';

export const minCommentInputHeight = inputMinHeight + 2 * borderWidthDefault;

const useComposition = () =>
  usePluginComposition(
    emptyPluginComposition,
    DraftJsPlugin,
    PlaceholderPlugin,
    WrapperPlugin,
    CompositionModeAdjustmentsPlugin,
    ApiLimitationsPlugin,
    OnChangePlugin,
    KeyboardShortcutsPlugin,
    CustomInputHandlingPlugin,
    FocusPlugin,
    ClipboardPlugin,
    UndoRedoPlugin,
    StylesPlugin,
    EntityApiPlugin,
    MentionsPlugin,
    ForceSelectionOnBlurPlugin,
    ForceSingleUnstyledBlockPlugin,
    // Handled by parent within Ctrl/Command + Enter for submitting comment
    IgnoreCtrlEnterPlugin,
    SpellCheckPlugin,
    AutomaticWebLinkConversionPlugin,
    SimpleWebLinksPlugin,
    ManualResetPlugin,
    CommentInputStylesPlugin,
  );

export type CommentInputProps = EditorProps<typeof useComposition>;

export const CommentInput: React.FC<CommentInputProps> = memo((props) => {
  const composition = useComposition();

  return (
    // Editor is not rendered at the first render due to initialization phase, but we need proper
    // size calculation for newly created comment so we ensure minimum size even when editor is not rendered
    <Box minHeight={minCommentInputHeight}>
      {useEditor(composition, {
        ...props,
        dataUiInput: props.dataUiInput ?? DataUiInput.Comment,
        enterKeyBehavior: EnterKeyBehavior.AlwaysSoftNewLine,
        keyCommandMap: textKeyCommandMap,
        limitations: SimpleTextEditorLimitations,
        placeholder: props.placeholder ?? 'Type “@” to mention other users.',
      })}
    </Box>
  );
});

CommentInput.displayName = 'CommentInput';
