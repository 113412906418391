import React from 'react';
import { DragSource } from '../../../../_shared/components/DragDrop/DragSource.tsx';
import { DndTypes } from '../../../../_shared/constants/dndTypes.ts';
import {
  ITypeElementLibraryItemBaseProps,
  TypeElementLibraryItem,
} from './TypeElementLibraryItem.tsx';

export interface IDraggableTypeElementLibraryItemProps extends ITypeElementLibraryItemBaseProps {
  readonly onDragStart: () => void;
  readonly onDragEnd: () => void;
}

export const DraggableTypeElementLibraryItem: React.FC<IDraggableTypeElementLibraryItemProps> = ({
  onDragStart,
  onDragEnd,
  ...props
}) => {
  if (props.isDisabled) {
    return (
      <div className="toolset__element-wrapper">
        <TypeElementLibraryItem {...props} />
      </div>
    );
  }

  return (
    <DragSource
      className="toolset__element-wrapper"
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}
      parentId=""
      sourceId={props.elementType}
      renderDraggable={(connectDragSource) => (
        <TypeElementLibraryItem {...props} connectDragSource={connectDragSource} />
      )}
      renderPreview={() => <TypeElementLibraryItem {...props} />}
      type={DndTypes.Content_Element_Add}
    />
  );
};

DraggableTypeElementLibraryItem.displayName = 'DraggableTypeElementLibraryItem';
