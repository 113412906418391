import React from 'react';
import { DeletedLinkedItemContent } from '../../../../../../../_shared/components/linkedItems/DeletedLinkedItemContent.tsx';
import { NonexistentLinkedItemContent } from '../../../../../../../_shared/components/linkedItems/NonexistentLinkedItemContent.tsx';
import { LoadedLinkedItemContent } from '../../../../../../../_shared/containers/linkedItems/LoadedLinkedItemContent.tsx';
import { ElementType } from '../../../../../../contentInventory/content/models/ContentItemElementType.ts';

type Props = {
  readonly cannotViewDisabledMessage: string | undefined;
  readonly collectionId: Uuid | undefined;
  readonly itemDoesntExist: boolean;
  readonly typeDoesntExist: boolean;
  readonly elementType: ElementType;
  readonly isArchived: boolean;
  readonly isCompact?: boolean;
  readonly isInvalid: boolean;
  readonly itemName: string;
  readonly typeName: string;
};

export const LinkedItemContent: React.FC<Props> = ({
  cannotViewDisabledMessage,
  collectionId,
  itemDoesntExist,
  typeDoesntExist,
  elementType,
  isArchived,
  isCompact,
  isInvalid,
  itemName,
  typeName,
}) => {
  if (itemDoesntExist || typeDoesntExist) {
    const message = itemDoesntExist
      ? `This ${elementType === ElementType.Subpages ? 'page' : 'linked item'} doesn’t exist`
      : `Content type of this ${
          elementType === ElementType.Subpages ? 'page' : 'linked item'
        } doesn’t exist`;

    return <NonexistentLinkedItemContent message={message} />;
  }

  if (isArchived) {
    return (
      <DeletedLinkedItemContent
        cannotViewDisabledMessage={cannotViewDisabledMessage}
        name={itemName}
      />
    );
  }

  return (
    <LoadedLinkedItemContent
      cannotViewDisabledMessage={cannotViewDisabledMessage}
      collectionId={collectionId}
      contentItemName={itemName}
      contentTypeName={typeName}
      isCompact={isCompact}
      isInvalid={isInvalid}
    />
  );
};
