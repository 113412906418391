import React, { useMemo } from 'react';
import { IMultipleChoiceOptionData } from '../../../../../../../_shared/models/MultipleChoiceOption.type.ts';
import {
  ConditionAction,
  MultipleChoiceConditionOperator,
  TypeElementCondition,
  TypeElementConditionTrigger,
  emptyTypeElementCondition,
} from '../../../../../../../_shared/models/utils/TypeElementCondition.ts';
import { getOrderedOptions } from '../../../../../../../_shared/selectors/multipleChoiceOptions.ts';
import { IMultipleChoiceTypeElementData } from '../../../../models/elements/MultipleChoiceTypeElementData.ts';
import { IBaseTypeElementData } from '../../../../models/elements/types/TypeElementData.ts';
import { isMultipleChoiceTypeElement } from '../../../../types/typeElementTypeGuards.ts';
import { TypeElementConditionValidationResult } from '../../../../utils/typeElementValidators/types/TypeElementWithConditionValidationResult.ts';
import { isTriggerElement } from '../../../../utils/typeElementsUtils.ts';
import { ConditionConfiguration as ConditionConfigurationComponent } from './condition/components/ConditionConfiguration.tsx';
import { TriggerElement, TriggerElementOption } from './condition/types/TriggerElement.type.ts';

export type ConditionConfigurationProps = {
  readonly condition: TypeElementCondition;
  readonly conditionValidationResult: TypeElementConditionValidationResult;
  readonly elementId: Uuid;
  readonly isElementNonLocalizable: boolean;
  readonly onChange: (condition: TypeElementCondition) => void;
  readonly typeElements: ReadonlyArray<IBaseTypeElementData>;
};

export const ConditionConfiguration: React.FC<ConditionConfigurationProps> = ({
  condition,
  conditionValidationResult,
  elementId,
  isElementNonLocalizable,
  onChange,
  typeElements,
}) => {
  const triggerElements = typeElements
    .filter(isMultipleChoiceTypeElement)
    .filter((element) => element.elementId !== elementId)
    .map(createTriggerElement(condition.trigger));

  const isTrigger = isTriggerElement(elementId, typeElements);

  const selectedTriggerElement = useMemo(
    () => triggerElements.find((element) => element.id === condition.trigger.elementId) || null,
    [condition.trigger.elementId, triggerElements],
  );

  const handleToggleIsActive = (): void => {
    onChange({
      ...condition,
      isActive: !condition.isActive,
    });
  };

  const handleActionChange = (action: ConditionAction): void => {
    onChange({
      ...condition,
      action,
    });
  };

  const handleTriggerSelectionChange = (triggerId: Uuid | null): void => {
    onChange({
      ...condition,
      trigger: {
        ...emptyTypeElementCondition.trigger,
        elementId: triggerId,
      },
    });
  };

  const handleOperatorChange = (operator: MultipleChoiceConditionOperator): void => {
    onChange({
      ...condition,
      trigger: {
        ...condition.trigger,
        operator,
      },
    });
  };

  const handleOptionIdsChange = (optionIds: UuidArray): void => {
    onChange({
      ...condition,
      trigger: {
        ...condition.trigger,
        optionIds,
      },
    });
  };

  return (
    <ConditionConfigurationComponent
      action={condition.action}
      conditionValidationResult={conditionValidationResult}
      isActive={condition.isActive}
      isElementNonLocalizable={isElementNonLocalizable}
      isTrigger={isTrigger}
      onActionChange={handleActionChange}
      onOperatorChange={handleOperatorChange}
      onOptionIdsChange={handleOptionIdsChange}
      onToggleIsActive={handleToggleIsActive}
      onTriggerSelectionChange={handleTriggerSelectionChange}
      operator={condition.trigger.operator}
      selectedOptionIds={condition.trigger.optionIds}
      selectedTriggerElement={selectedTriggerElement}
      possibleTriggerElements={triggerElements}
    />
  );
};

ConditionConfiguration.displayName = 'ConditionConfiguration';

const createTriggerElement =
  (selectedTrigger: TypeElementConditionTrigger) =>
  (possibleTrigger: IMultipleChoiceTypeElementData): TriggerElement => ({
    hasActiveCondition: !!possibleTrigger.condition?.isActive,
    id: possibleTrigger.elementId,
    isNonLocalizable: possibleTrigger.isNonLocalizable,
    mode: possibleTrigger.mode,
    name: possibleTrigger.name || 'Untitled multiple choice',
    options: createTriggerElementOptions(possibleTrigger, selectedTrigger),
  });

const createTriggerElementOptions = (
  triggerElement: IMultipleChoiceTypeElementData,
  selectedTrigger: TypeElementConditionTrigger,
): ReadonlyArray<TriggerElementOption> => {
  const existingOptions = getOrderedOptions(triggerElement.options, triggerElement.optionsOrder)
    .filter(filterOutEmptyOptions)
    .map(mapExistingOption);

  const isTriggerSelected = selectedTrigger.elementId === triggerElement.elementId;

  return isTriggerSelected
    ? addDeletedOptions(existingOptions, selectedTrigger.optionIds)
    : existingOptions;
};

const filterOutEmptyOptions = (option: IMultipleChoiceOptionData) => option.label.length > 0;

const addDeletedOptions = (
  existingOptions: ReadonlyArray<TriggerElementOption>,
  selectedOptionIds: UuidArray,
): ReadonlyArray<TriggerElementOption> => {
  const deletedOptions = getDeletedOptionIds(existingOptions, selectedOptionIds).map(
    mapDeletedOption,
  );

  return [...deletedOptions, ...existingOptions];
};

const getDeletedOptionIds = (
  existingOptions: ReadonlyArray<TriggerElementOption>,
  selectedOptionIds: UuidArray,
): UuidArray => {
  const existingOptionIds = new Set(existingOptions.map((o) => o.id));

  return selectedOptionIds.filter((id) => !existingOptionIds.has(id));
};

const mapExistingOption = ({ id, label }: IMultipleChoiceOptionData): TriggerElementOption => ({
  id,
  isDeleted: false,
  label,
});

const mapDeletedOption = (id: Uuid): TriggerElementOption => ({
  id,
  isDeleted: true,
  label: 'Deleted option',
});
