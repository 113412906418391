import { assert } from '@kontent-ai/utils';
import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventAction } from '../../../../_shared/models/TrackUserEvent.type.ts';
import { IPreviewConfigurationRepository } from '../../../../repositories/interfaces/IPreviewConfigurationRepository.type.ts';
import {
  PreviewConfiguration_Saving_Finished,
  PreviewConfiguration_Saving_Started,
} from '../../constants/previewConfigurationActionTypes.ts';
import { IPreviewConfiguration } from '../../models/PreviewConfiguration.type.ts';
import { createPreviewConfigurationServerModel } from '../../utils/createPreviewConfigurationServerModel.ts';
import { getPreviewConfigurationFromServerModel } from '../../utils/getPreviewConfigurationFromServerModel.ts';
import { showServerErrorMessage } from '../previewConfigurationActions.ts';

interface IDeps {
  readonly previewConfigurationRepository: Pick<IPreviewConfigurationRepository, 'save'>;
  readonly trackUserEvent: TrackUserEventAction;
}

const started = () => ({ type: PreviewConfiguration_Saving_Started }) as const;

const succeeded = (previewConfiguration: IPreviewConfiguration) =>
  ({
    type: PreviewConfiguration_Saving_Finished,
    payload: {
      previewConfiguration,
    },
  }) as const;

export type UpdatePreviewConfigurationActionsType = ReturnType<typeof started | typeof succeeded>;

export const updatePreviewConfigurationActionCreator =
  (deps: IDeps) => (): ThunkPromise => async (dispatch, getState) => {
    const {
      previewConfigurationApp: { editedPreviewConfiguration, previewConfiguration },
      sharedApp: { currentProjectId },
      data: {
        contentTypes: { byId: typesById },
      },
    } = getState();

    assert(editedPreviewConfiguration, () => 'Edited preview configuration was not found.');

    dispatch(started());

    const configurationToSave = createPreviewConfigurationServerModel(
      editedPreviewConfiguration,
      typesById,
    );

    try {
      const previewConfigurationServerModel = await deps.previewConfigurationRepository.save(
        currentProjectId,
        configurationToSave,
      );
      const previewConfigurationFromServer = getPreviewConfigurationFromServerModel(
        previewConfigurationServerModel,
        previewConfiguration,
        true,
      );

      dispatch(deps.trackUserEvent(TrackedEvent.PreviewConfigurationUpdated));
      dispatch(succeeded(previewConfigurationFromServer));
    } catch (error) {
      dispatch(showServerErrorMessage());
      throw error;
    }
  };
