import { Box } from '@kontent-ai/component-library/Box';
import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Toggle } from '@kontent-ai/component-library/Toggle';
import { noOperation } from '@kontent-ai/utils';
import { Location } from 'history';
import { useContext } from 'react';
import { useHistory, useLocation } from 'react-router';
import {
  ContentItemPreviewRoute,
  ContentItemRoute,
  ContentItemRouteParams,
} from '../../../../../../_shared/constants/routePaths.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { getCurrentProjectId } from '../../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  buildPath,
  matchPath,
  parseContentItemIds,
} from '../../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { useLivePreviewPreferenceStorage } from '../../../../../../localStorages/useLivePreviewPreferenceStorage.ts';
import { EditingActionsButton } from '../../components/editingActions/actions/EditingActionsButton.tsx';
import { ItemEditingNewDesignContext } from '../../context/ItemEditingNewDesignContext.tsx';
import { useIsLivePreviewOpen } from '../../hooks/useIsLivePreviewOpen.ts';
import { SideBySidePreviewOnboarding } from './SideBySidePreviewOnboarding.tsx';

export const PreviewToggle = () => {
  const isPreviewOpen = useIsLivePreviewOpen();
  const isNewDesign = useContext(ItemEditingNewDesignContext);

  const environmentId = useSelector(getCurrentProjectId);
  const history = useHistory();

  const location = useLocation();

  const { setIsLivePreviewPreferred } = useLivePreviewPreferenceStorage(environmentId);

  const handleToggleOn = () => {
    const previewPath = getPath(location, ContentItemPreviewRoute);
    if (previewPath) {
      setIsLivePreviewPreferred(true);
      history.push(previewPath);
    }
  };

  const handleToggleOff = () => {
    const editorPath = getPath(location, ContentItemRoute);
    if (editorPath) {
      setIsLivePreviewPreferred(false);
      history.push(editorPath);
    }
  };

  const label = isPreviewOpen ? 'Hide preview' : 'Show preview';

  return isNewDesign ? (
    <SideBySidePreviewOnboarding
      renderTarget={(targetProps) => (
        <QuinaryButton
          activated={isPreviewOpen}
          aria-label={label}
          aria-pressed={isPreviewOpen}
          onClick={isPreviewOpen ? handleToggleOff : handleToggleOn}
          tooltipPlacement="top"
          tooltipText={label}
          {...targetProps}
          {...getDataUiElementAttribute(DataUiElement.ContentItemPreviewToggle)}
        >
          <QuinaryButton.Icon icon={Icons.Eye} />
        </QuinaryButton>
      )}
    />
  ) : (
    <Box display="grid" alignItems="center" css={`grid-template-areas: "stack";`}>
      {/* needed for proper height when only the preview toggle is displayed in the bar */}
      <Box css="grid-area: stack;" visibility="hidden" aria-hidden="true">
        <EditingActionsButton
          buttonStyle="tertiary"
          disableTabulator
          iconName="Ellipsis"
          isActive={false}
          onClick={noOperation}
          text="x"
        />
      </Box>
      <Box css="grid-area: stack;">
        <SideBySidePreviewOnboarding
          renderTarget={(targetProps) => (
            <Toggle
              labelText="Preview"
              status={isPreviewOpen ? 'on' : 'off'}
              tooltipPlacement="bottom-start"
              onToggleOn={handleToggleOn}
              onToggleOff={handleToggleOff}
              {...targetProps}
              {...getDataUiElementAttribute(DataUiElement.ContentItemPreviewToggle)}
            />
          )}
        />
      </Box>
    </Box>
  );
};

const getPath = (
  location: Location,
  route: typeof ContentItemRoute | typeof ContentItemPreviewRoute,
) => {
  const matchedParams = matchPath<ContentItemRouteParams<string>>(
    location.pathname,
    ContentItemRoute,
  );
  if (!matchedParams) {
    return null;
  }

  const params = {
    ...matchedParams,
    contentItemIds: parseContentItemIds(matchedParams.contentItemIds),
  };

  return buildPath<ContentItemRouteParams<UuidArray>>(route, params);
};
