import { secondsToHours } from 'date-fns';
import React from 'react';
import { useHistory } from 'react-router';
import { trackUserEvent } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import { ContentItemTimelineLatestPublishedRoute } from '../../../../_shared/constants/routePaths.ts';
import { TrackedEvent } from '../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { IUserIdentifier } from '../../../../_shared/models/UserIdentifier.ts';
import { getCurrentProjectId } from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import {
  getContentItemPath,
  pickContentItemRoute,
} from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { getUsersInfo } from '../../../../_shared/utils/users/usersUtils.ts';
import { WorkflowStepAction } from '../../../../data/models/workflow/WorkflowStep.ts';
import { useLivePreviewPreferenceStorage } from '../../../../localStorages/useLivePreviewPreferenceStorage.ts';
import { getLanguage } from '../../contentStatus/containers/ContentStatusScrollTable/ContentStatusScrollTableRow.tsx';
import { ContentPaceDetailTableRow as ContentPaceDetailTableRowComponent } from '../components/ContentPaceDetailTableRow.tsx';
import { ContentPaceDetailContentItem } from '../getDetailContentItemsFromServerModel.ts';

type Props = Readonly<{
  data: ContentPaceDetailContentItem;
}>;

export const ContentPaceDetailTableRow: React.FC<Props> = ({ data }) => {
  const dispatch = useDispatch();
  const { itemWithVariant, durationInSeconds } = data;
  const { variant } = itemWithVariant;

  const history = useHistory();
  const contentType = useSelector((s) => s.data.contentTypes.byId.get(itemWithVariant.item.typeId));

  const currentProjectId = useSelector(getCurrentProjectId);
  const { isLivePreviewPreferred } = useLivePreviewPreferenceStorage(currentProjectId);

  const usersById = useSelector((s) => s.data.users.usersById);
  const assigneesIds = variant?.assignment.assignees ?? new Set<IUserIdentifier>();
  const contributors = Array.from(getUsersInfo(assigneesIds, usersById));

  //  In case this item is a published version and there already exists a new version for this item
  const isOlderPublishedVersion =
    variant &&
    variant.assignment.workflowStatus.action === WorkflowStepAction.Publish &&
    variant.actualWorkflowStatus &&
    variant.actualWorkflowStatus.id !== variant.assignment.workflowStatus.id;
  const route = isOlderPublishedVersion
    ? ContentItemTimelineLatestPublishedRoute
    : pickContentItemRoute(isLivePreviewPreferred);
  const linkTo = getContentItemPath(
    history.location.pathname,
    itemWithVariant.item.id,
    isLivePreviewPreferred,
    variant?.id.variantId,
    { route },
  );

  const language = useSelector(getLanguage(variant?.id.variantId ?? null));

  const trackItemOpened = (): void => {
    if (!itemWithVariant.variant) {
      return;
    }

    const { itemId, variantId } = itemWithVariant.variant.id;

    dispatch(
      trackUserEvent(TrackedEvent.ContentPaceContentItemClicked, {
        itemId,
        variantId,
      }),
    );
  };

  return (
    <ContentPaceDetailTableRowComponent
      contentType={contentType?.name ?? ''}
      contributors={contributors}
      id={itemWithVariant.item.id}
      languageName={language.name}
      linkTo={linkTo}
      name={itemWithVariant.item.name}
      timeInHours={secondsToHours(durationInSeconds)}
      onClick={trackItemOpened}
    />
  );
};

ContentPaceDetailTableRow.displayName = 'ContentPaceDetailTableRow';
