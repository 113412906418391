import { Collection } from '@kontent-ai/utils';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { Loader } from '../../../../../_shared/components/Loader.tsx';
import { AuthorizedSection } from '../../../../../_shared/components/routing/AuthorizedSection.tsx';
import {
  ApiKeyDetailAccessDeniedRouteSegment,
  ApiKeyDetailRouteParams,
} from '../../../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../../_shared/hooks/useThunkPromise.ts';
import { ApiKeyType } from '../../../../../_shared/models/ApiKeyType.ts';
import { LoadingStatus } from '../../../../../_shared/models/LoadingStatusEnum.ts';
import { getCurrentProjectContainer } from '../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { buildPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { isProjectManagerInAnyEnvironment } from '../../../root/selectors/isProjectManagerInAnyEnvironment.ts';
import { apiKeyDetailsAlertDismissed } from '../../actions/apiKeysActions.ts';
import { createApiKey, initNewApiKey } from '../../actions/thunkApiKeysActions.ts';
import { ApiKey, emptyApiKey } from '../../models/ApiKey.ts';
import {
  AllEnvironmentsTagId,
  getAllEnvironmentOptions,
} from '../../selectors/getSelectedEnvironmentOptions.ts';
import { isSecureAccessAvailable } from '../../selectors/isSecuredDeliveryApiAvailable.ts';
import { getApiKeyRedirectRoute } from '../../utils/getApiKeyRedirectRoute.ts';
import { DapiKeyFormShape } from '../../validation/dapiKeyValidationConfig.ts';
import { DapiKeyDetailForm } from './DapiKeyDetailForm.tsx';

export const NewDapiKeyDetail = () => {
  const [isInitThunkDone] = useThunkPromise(initNewApiKey, ApiKeyType.DAPI);

  const dispatch = useDispatch();
  const history = useHistory();
  const isProjectManager = useSelector(isProjectManagerInAnyEnvironment);
  const currentProject = useSelector(getCurrentProjectContainer);
  const allEnvironmentOptions = useSelector((state) =>
    getAllEnvironmentOptions(state, ApiKeyType.DAPI),
  );
  const isDapiSecureAccessAvailable = useSelector(isSecureAccessAvailable);
  const allSet = useSelector(
    (state) => isInitThunkDone && state.apiKeysApp.detailLoadingStatus === LoadingStatus.Loaded,
  );
  const actionStatus = useSelector((state) => state.apiKeysApp.detailActionStatus);
  const projectContainerActiveUsersById = useSelector(
    (state) => state.data.projectContainerActiveUsers.byId,
  );

  const projectContainerActiveUsers = useMemo(
    () => Collection.getValues(projectContainerActiveUsersById),
    [projectContainerActiveUsersById],
  );

  const getApiKey = useCallback((dapiKeyFormShape: DapiKeyFormShape): ApiKey => {
    const hasAccessToAllEnvironments = dapiKeyFormShape.environments.includes(AllEnvironmentsTagId);
    return {
      ...emptyApiKey,
      environments: hasAccessToAllEnvironments ? [] : dapiKeyFormShape.environments,
      expiresAt: dapiKeyFormShape.expiresAt.value,
      hasAccessToAllEnvironments,
      hasSecureDeliveryAccess: dapiKeyFormShape.access.hasSecureDeliveryAccess,
      hasPreviewDeliveryAccess: dapiKeyFormShape.access.hasPreviewDeliveryAccess,
      name: dapiKeyFormShape.name,
      sharedWithUsers: dapiKeyFormShape.sharedWithUsers,
      type: ApiKeyType.DAPI,
    };
  }, []);

  const createNewApiKey = useCallback(
    async (dapiKeyFormShape: DapiKeyFormShape): Promise<void> => {
      const apiKey = getApiKey(dapiKeyFormShape);
      const createdApiKey = await dispatch(createApiKey(apiKey));
      history.push(
        buildPath<ApiKeyDetailRouteParams>(getApiKeyRedirectRoute(apiKey.type), {
          projectContainerId: currentProject.projectContainerId,
          tokenSeedId: createdApiKey.token_seed_id,
        }),
      );
    },
    [history, currentProject.projectContainerId, getApiKey],
  );

  const unsavedNavigationHandler = useCallback(
    async (
      onSuccess: () => void,
      onFail: () => void,
      dapiKeyFormShape: DapiKeyFormShape,
    ): Promise<void> => {
      try {
        await dispatch(createApiKey(getApiKey(dapiKeyFormShape)));
        onSuccess();
      } catch {
        onFail();
      }
    },
    [getApiKey],
  );

  if (!allSet) {
    return <Loader />;
  }

  return (
    <AuthorizedSection
      appName={ApiKeyDetailAccessDeniedRouteSegment}
      isAuthorized={isProjectManager}
      projectId={currentProject.masterEnvironmentId}
      projectContainerId={currentProject.projectContainerId}
    >
      <DapiKeyDetailForm
        allEnvironmentOptions={allEnvironmentOptions}
        apiKey={getNewDapiKey(isDapiSecureAccessAvailable)}
        apiKeyActionStatus={actionStatus}
        hiddenEnvironmentOption={null}
        isProjectManager={isProjectManager}
        isSecureAccessAvailable={isDapiSecureAccessAvailable}
        projectContainerActiveUsers={projectContainerActiveUsers}
        onAlertDismiss={() => dispatch(apiKeyDetailsAlertDismissed())}
        onSave={createNewApiKey}
        onUnsavedNavigation={unsavedNavigationHandler}
      />
    </AuthorizedSection>
  );
};

const getNewDapiKey = (isDapiSecureAccessAvailable: boolean): ApiKey => ({
  ...emptyApiKey,
  hasPreviewDeliveryAccess: !isDapiSecureAccessAvailable,
  type: ApiKeyType.DAPI,
});
