import { useCallback } from 'react';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { showCreateNewForm } from '../actions/sitemapActions.ts';
import { initSitemap } from '../actions/thunkSitemapActions.ts';
import { SitemapListing as SitemapListingComponent } from '../components/SitemapListing.tsx';

export const SitemapListing = () => {
  const projectId = useSelector((state) => state.sharedApp.currentProjectId);
  const showNotificationBar = useSelector((state) => !!state.sharedApp.notificationBar.text);
  const projectContainsPublishedItems = useSelector(
    (state) => state.sitemapApp.projectContainsPublishedItems,
  );
  const showEmptyStatePage = useSelector(shouldShowEmptyStatePage);

  const dispatch = useDispatch();
  const onCreateNewButtonClicked = useCallback(() => dispatch(showCreateNewForm()), []);

  const [isInitThunkDone] = useThunkPromise(initSitemap);
  const allSet = useSelector((state) => isInitThunkDone && !!state.sitemapApp.sitemap);

  return (
    <SitemapListingComponent
      isLoading={!allSet}
      onCreateNewButtonClicked={onCreateNewButtonClicked}
      projectContainsPublishedItems={projectContainsPublishedItems}
      projectId={projectId}
      showEmptyStatePage={showEmptyStatePage}
      showNotificationBar={showNotificationBar}
    />
  );
};

const shouldShowEmptyStatePage = (state: IStore): boolean => {
  const {
    sitemapApp: { isCreateNewFormDisplayed, sitemap },
  } = state;

  const rootNodeIds = sitemap?.childIds;

  return (!rootNodeIds || !rootNodeIds.length) && !isCreateNewFormDisplayed;
};
