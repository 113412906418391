import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { ActiveCapabilityType } from '../../../../../_shared/models/activeCapability.type.ts';
import { areCollectionsVisibleForCurrentUser } from '../../../../../_shared/selectors/contentCollections.ts';
import { getSelectedLanguageId } from '../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { hasActiveVariantCapabilityForEditedItem } from '../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { areSpacesInCollectionsEnabled } from '../../../../environmentSettings/selectors/allowedFeaturesSelectors.ts';
import { getAreAnyContentGroupsVisible } from '../../../selectors/getAreAnyContentGroupsVisible.ts';
import { ContentItemHeader as ContentItemHeaderComponent } from '../components/ContentItemHeader.tsx';
import { ContentItemHeaderCompact as ContentItemHeaderCompactComponent } from '../components/ContentItemHeaderCompact.tsx';
import { useIsLivePreviewOpen } from '../hooks/useIsLivePreviewOpen.ts';
import { isContentItemNameAndCodenameEditingAllowed } from '../utils/itemEditingUtils.ts';

export const ContentItemHeader = () => {
  const isPreviewOpen = useIsLivePreviewOpen();

  const isCollectionInMainPaneEnabled = useSelector(
    (s) => areSpacesInCollectionsEnabled(s) && areCollectionsVisibleForCurrentUser(s),
  );
  const areAnyContentGroupsVisible = useSelector(getAreAnyContentGroupsVisible);

  const canEditName = useSelector((s) => {
    const selectedLanguageId = getSelectedLanguageId(s);
    return (
      !!selectedLanguageId &&
      isContentItemNameAndCodenameEditingAllowed(
        s.contentApp.contentItemVariants,
        s.data.languages.defaultLanguage.id,
        selectedLanguageId,
      ) &&
      hasActiveVariantCapabilityForEditedItem(ActiveCapabilityType.UpdateContent, s)
    );
  });

  return isPreviewOpen ? (
    <ContentItemHeaderCompactComponent
      canEditName={canEditName}
      isCollectionInMainPaneEnabled={isCollectionInMainPaneEnabled}
    />
  ) : (
    <ContentItemHeaderComponent
      areAnyContentGroupsVisible={areAnyContentGroupsVisible}
      canEditName={canEditName}
      isCollectionInMainPaneEnabled={isCollectionInMainPaneEnabled}
    />
  );
};
