import { EnhancedStore } from '@reduxjs/toolkit';
import { rootReducer } from '../reducers/rootReducer.ts';
import { IStore } from './IStore.type.ts';
import { createReduxStore } from './createReduxStore.ts';
import { sentryEnhancer } from './enhancers/sentryEnhancer.ts';
import { assetUploadMiddleware } from './middleware/assetUploadMiddleware.ts';
import { notificationMiddleware } from './middleware/notificationMiddleware.ts';
import { trialMiddleware } from './middleware/trialMiddleware.ts';

export const createClientReduxStore = (): EnhancedStore<IStore> => {
  const store = createReduxStore<IStore>({
    reducer: rootReducer,
    customMiddlewares: [notificationMiddleware, trialMiddleware, assetUploadMiddleware],
    customEnhancers: [sentryEnhancer],
  });

  return store;
};
