import { Box } from '@kontent-ai/component-library/Box';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Spacing, borderWidthDefault, px } from '@kontent-ai/component-library/tokens';
import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import { ConnectDragSource } from 'react-dnd';
import { BarItemAction } from '../../../../../../../_shared/components/BarItems/Actions/BarItemAction.tsx';
import { BarItemDragAction } from '../../../../../../../_shared/components/BarItems/Actions/BarItemDragAction.tsx';
import { BarItemActions } from '../../../../../../../_shared/components/BarItems/BarItemActions.tsx';
import { BarItemBar } from '../../../../../../../_shared/components/BarItems/BarItemBar.tsx';
import { BarItemContent } from '../../../../../../../_shared/components/BarItems/BarItemContent.tsx';
import { BarItemTitle } from '../../../../../../../_shared/components/BarItems/BarItemTitle.tsx';
import { CodenameBarItemButton } from '../../../../../../../_shared/components/Codename/CodenameBarItemButton.tsx';
import { OnSaveCodename } from '../../../../../../../_shared/components/Codename/OnSaveCodename.type.ts';
import { EventKeys } from '../../../../../../../_shared/constants/eventKeys.ts';
import { ValidationConstants } from '../../../../../../../_shared/constants/validationConstants.ts';
import { IMultipleChoiceOptionData } from '../../../../../../../_shared/models/MultipleChoiceOption.type.ts';
import {
  DataUiAction,
  DataUiInput,
  getDataUiInputAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type Props = {
  readonly editedMultipleChoiceOptionLabel: string;
  readonly isBeingEdited: boolean;
  readonly multipleChoiceOption: IMultipleChoiceOptionData;
  readonly onBlur: () => void;
  readonly onCancelChanges: () => void;
  readonly onCodenameCopy?: () => void;
  readonly onCodenameEdit?: () => void;
  readonly onCodenameSave: OnSaveCodename;
  readonly onDelete: (optionId: Uuid) => void;
  readonly onLabelChange: (label: string) => void;
  readonly onLabelSubmit: () => void;
  readonly onSelect: (option: IMultipleChoiceOptionData) => void;
  readonly placeholder: string;
  readonly relatedCodenames: ReadonlySet<string>;
  readonly connectDragSource?: ConnectDragSource;
  readonly isDragging: boolean;
};

const moveCursorToTheEnd = (event: any): void => {
  // biome-ignore lint/correctness/noSelfAssign: https://stackoverflow.com/questions/511088/use-javascript-to-place-cursor-at-end-of-text-in-text-input-element
  event.target.value = event.target.value;
};

// When editing the option, we want to keep the text in the same place, but display it in an input
// so we add negative offset to it
const offsetToInputText = borderWidthDefault + Spacing.L;

export const MultipleChoiceOption = ({
  connectDragSource,
  editedMultipleChoiceOptionLabel,
  isBeingEdited,
  isDragging,
  multipleChoiceOption,
  onBlur,
  onCancelChanges,
  onCodenameSave,
  onCodenameCopy,
  onCodenameEdit,
  onDelete,
  onLabelChange,
  onLabelSubmit,
  onSelect,
  placeholder,
  relatedCodenames,
}: Props) => {
  const inputElementRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isBeingEdited) {
      inputElementRef.current?.focus();
    }
  }, [isBeingEdited]);

  return (
    <div className="bar-item__wrapper">
      <div
        className={classNames('bar-item__pane', {
          'bar-item__pane--is-dragging': isDragging,
        })}
      >
        <BarItemBar isClickable isDragging={isDragging}>
          <BarItemActions>
            <BarItemDragAction connectDragSource={connectDragSource} />
          </BarItemActions>
          {isBeingEdited ? (
            <BarItemContent>
              <Box marginLeft={px(-1 * offsetToInputText)}>
                <input
                  ref={inputElementRef}
                  autoComplete="off"
                  type="text"
                  className="form__text-field form__text-field--400"
                  onBlur={onBlur}
                  onKeyUp={(event) => {
                    if (event.key === EventKeys.Enter) {
                      onLabelSubmit();
                    } else if (event.key === EventKeys.Escape) {
                      onCancelChanges();
                    }
                  }}
                  maxLength={ValidationConstants.MultipleChoiceOptionNameMaxLength}
                  onFocus={moveCursorToTheEnd}
                  onChange={(event) => onLabelChange(event.target.value)}
                  placeholder={placeholder}
                  value={editedMultipleChoiceOptionLabel}
                  {...getDataUiInputAttribute(DataUiInput.MultipleChoiceOption)}
                />
              </Box>
            </BarItemContent>
          ) : (
            <BarItemTitle
              title={multipleChoiceOption.label}
              onClick={() => onSelect(multipleChoiceOption)}
            >
              {multipleChoiceOption.label}
            </BarItemTitle>
          )}
          <BarItemActions>
            {multipleChoiceOption.codename && (
              <CodenameBarItemButton
                codename={multipleChoiceOption.codename}
                maxLength={ValidationConstants.MultipleChoiceOptionCodenameMaxLength}
                onCodenameSave={onCodenameSave}
                onCodenameCopy={onCodenameCopy}
                onCodenameEdit={onCodenameEdit}
                relatedCodenames={relatedCodenames}
              />
            )}
            <BarItemAction
              dataUiActionName={DataUiAction.Remove}
              icon={Icons.Bin}
              isDestructive
              onClick={() => onDelete(multipleChoiceOption.id)}
              tooltipText="Remove"
            />
          </BarItemActions>
        </BarItemBar>
      </div>
    </div>
  );
};
