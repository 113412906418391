import { DOMRectLike } from '@kontent-ai/DOM';
import { paperBorderRadius } from '@kontent-ai/component-library/Paper';
import { Direction } from '@kontent-ai/types';
import { getMax, getMin } from '../../../../../_shared/utils/arrayUtils/arrayUtils.ts';
import {
  DOMSelectionLike,
  getSelectionDirection,
} from '../../../../../_shared/utils/selectionUtils.ts';
import {
  NodePredicate,
  getRelevantRectanglesForPositioning,
  getTargetSelectionRectangle,
  isObjectBlockWrapper,
  isTextBlockContent,
} from './toolbarPositioningUtils.ts';

export const ToolbarOffsetFromContainer = 16;

export enum ToolbarAttachedTo {
  TextFromAbove = 'TextFromAbove',
  TextFromBelow = 'TextFromBelow',
}

export type VerticalPosition = {
  readonly top: number;
  readonly attachedTo: ToolbarAttachedTo;
};

type HorizontalPosition = {
  readonly left: number;
};

export type InlineToolbarPosition = VerticalPosition & HorizontalPosition;

const isNodeRelevantForInlineToolbar: NodePredicate = (node) =>
  isObjectBlockWrapper(node) || isTextBlockContent(node);

/**
 * This function adds position reference needed for corresponding positioning which eliminates scroll lag.
 */
export const adjustPositionToReference = (
  position: InlineToolbarPosition,
  referenceRectangle: DOMRectLike,
): InlineToolbarPosition => {
  return {
    ...position,
    top: position.top - referenceRectangle.top,
    left: position.left - referenceRectangle.left,
  };
};

export const getVerticalPosition = (
  toolbarRectangle: DOMRectLike,
  scrollParentRectangle: DOMRectLike,
  selectionRectangles: ReadonlyArray<DOMRectLike>,
  selectionDirection: Direction,
  preferUprightPositionForOneLineSelection: boolean,
): VerticalPosition => {
  const mostTopsideSelectionRectangle = getMin(selectionRectangles, (rect) => rect.top);
  const lowestSelectionRectangle = getMax(selectionRectangles, (rect) => rect.bottom);
  const selectionIsOneLine = selectionRectangles.length <= 1;

  const supposedBelowTextToolbarBottom = lowestSelectionRectangle.bottom + toolbarRectangle.height;
  const supposedAboveTextToolbarTop = mostTopsideSelectionRectangle.top - toolbarRectangle.height;

  const minAllowedToolbarTop = scrollParentRectangle.top + ToolbarOffsetFromContainer;
  const maxAllowedToolbarBottom =
    scrollParentRectangle.top +
    scrollParentRectangle.height -
    toolbarRectangle.height -
    ToolbarOffsetFromContainer;

  const isEnoughSpaceAboveSelection = supposedAboveTextToolbarTop >= minAllowedToolbarTop;
  const isEnoughSpaceBelowSelection = supposedBelowTextToolbarBottom <= maxAllowedToolbarBottom;

  const preferUprightPosition =
    selectionDirection === Direction.Backward ||
    (preferUprightPositionForOneLineSelection && selectionIsOneLine);

  if (preferUprightPosition) {
    if (isEnoughSpaceAboveSelection) {
      return {
        attachedTo: ToolbarAttachedTo.TextFromAbove,
        top: mostTopsideSelectionRectangle.top,
      };
    }

    return {
      attachedTo: ToolbarAttachedTo.TextFromBelow,
      top: lowestSelectionRectangle.bottom,
    };
  }

  if (isEnoughSpaceBelowSelection) {
    return {
      attachedTo: ToolbarAttachedTo.TextFromBelow,
      top: lowestSelectionRectangle.bottom,
    };
  }

  if (isEnoughSpaceAboveSelection) {
    return {
      attachedTo: ToolbarAttachedTo.TextFromAbove,
      top: mostTopsideSelectionRectangle.top,
    };
  }

  return {
    attachedTo: ToolbarAttachedTo.TextFromBelow,
    top: lowestSelectionRectangle.bottom,
  };
};

const getHorizontalPosition = (
  verticalPosition: VerticalPosition,
  toolbarRectangle: DOMRectLike,
  editorRectangle: DOMRectLike,
  selectionRectangles: ReadonlyArray<DOMRectLike>,
): HorizontalPosition => {
  const isUpright = verticalPosition.attachedTo === ToolbarAttachedTo.TextFromAbove;
  const targetSelectionRectangle = getTargetSelectionRectangle(selectionRectangles, isUpright);

  const defaultLeft = targetSelectionRectangle.left - paperBorderRadius;
  const maxLeft = editorRectangle.right - toolbarRectangle.width + paperBorderRadius;
  const minLeft = editorRectangle.left - paperBorderRadius;

  return {
    left: Math.max(minLeft, Math.min(defaultLeft, maxLeft)),
  };
};

export const getInlineToolbarPosition = (
  domSelection: DOMSelectionLike,
  toolbarRectangle: DOMRectLike,
  editorRectangle: DOMRectLike,
  scrollContainerRectangle: DOMRectLike,
  preferUprightPositionForOneLineSelection: boolean,
): InlineToolbarPosition | null => {
  if (domSelection.isCollapsed) {
    return null;
  }
  const selectionDirection = getSelectionDirection(domSelection);
  const selectionRepresentingRectangles = getRelevantRectanglesForPositioning(
    domSelection,
    isNodeRelevantForInlineToolbar,
  );
  if (selectionRepresentingRectangles.length === 0) {
    return null;
  }

  const verticalPosition = getVerticalPosition(
    toolbarRectangle,
    scrollContainerRectangle,
    selectionRepresentingRectangles,
    selectionDirection,
    preferUprightPositionForOneLineSelection,
  );

  const horizontalPosition = getHorizontalPosition(
    verticalPosition,
    toolbarRectangle,
    editorRectangle,
    selectionRepresentingRectangles,
  );

  return {
    ...verticalPosition,
    ...horizontalPosition,
  };
};
