import { isAbsoluteWebUrl } from '../../../_shared/utils/urlUtils.ts';
import { IContentType } from '../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { ElementType } from '../../contentInventory/content/models/ContentItemElementType.ts';
import {
  excessiveUrlSlugMacro,
  invalidUrlPattern,
  urlPatternWithWhiteSpaces,
} from '../constants/errorMessageTemplates.ts';
import { UrlSlugMacro } from '../constants/macros.ts';
import { IPreviewUrlPattern } from '../models/IPreviewUrlPattern.type.ts';

const lowerCasedUrlSlugMacro = UrlSlugMacro.toLowerCase();

const urlPatternValidator = (urlPattern: string): string | undefined => {
  if (/\s/g.test(urlPattern)) {
    return urlPatternWithWhiteSpaces;
  }

  if (!isAbsoluteWebUrl(urlPattern)) {
    return invalidUrlPattern;
  }

  return undefined;
};

const absentUrlSlugValidator = (
  { urlPattern }: IPreviewUrlPattern,
  contentTypeName: string,
): string | undefined => {
  const containsUrlSlugMacro = urlPattern?.toLowerCase().includes(lowerCasedUrlSlugMacro);

  return containsUrlSlugMacro ? excessiveUrlSlugMacro(contentTypeName) : undefined;
};

export const contentTypeUrlPatternValidatorBuilder = (
  type: IContentType | undefined,
): ((previewUrlPattern: IPreviewUrlPattern) => string | undefined) => {
  if (type) {
    const typeHasUrlSlugElement = type.typeElements.some(
      (element) => !!element && element.type === ElementType.UrlSlug,
    );

    if (!typeHasUrlSlugElement) {
      return (field) =>
        urlPatternValidator(field.urlPattern) || absentUrlSlugValidator(field, type.name);
    }
  }

  return (field) => urlPatternValidator(field.urlPattern);
};
