import { getRelevantSpacesForWebSpotlightSelector } from '../../../applications/webSpotlight/utils/getRelevantSpacesForWebSpotlightSelector.ts';
import { ISpace, SpacesMap } from '../../../data/models/space/space.ts';
import { SpaceMenuItem } from '../../components/ProjectMenu/selects/SpaceSelect.tsx';
import {
  ContentItemRouteParams,
  ContentItemsAppRouteSegment,
  WebSpotlightRoute,
  WebSpotlightRouteParams,
} from '../../constants/routePaths.ts';
import { DataUiAppName, getDataUiNavAttribute } from '../dataAttributes/DataUiAttributes.ts';
import { createSpaceListingLink } from '../routing/projectSubscriptionRoutingUtils.ts';
import { buildPath, pickContentItemRoute } from '../routing/routeTransitionUtils.ts';
import { sortSpacesByName } from './sortSpacesByName.ts';

export const manageSpacesItemId = 'spaces-pinned';

export const getSpacesItems = (
  spacesMap: SpacesMap,
  currentSpaceId: Uuid,
  projectId: Uuid,
  variantId: Uuid,
  canManageSpaces: boolean,
  isLivePreviewPreferred: boolean,
  onClick: (space: ISpace, targetPath: string) => void,
): ReadonlyArray<SpaceMenuItem> => {
  const relevantSpaces = getRelevantSpacesForWebSpotlightSelector(
    spacesMap.values(),
    canManageSpaces,
  );

  const spaceItems = sortSpacesByName(relevantSpaces).map((space): SpaceMenuItem => {
    const link = space.webSpotlightRootItemId
      ? buildPath<ContentItemRouteParams<UuidArray>>(pickContentItemRoute(isLivePreviewPreferred), {
          app: ContentItemsAppRouteSegment.WebSpotlight,
          projectId,
          variantId,
          spaceId: space.id,
          contentItemIds: [space.webSpotlightRootItemId],
        })
      : buildPath<WebSpotlightRouteParams>(WebSpotlightRoute, {
          projectId,
          variantId,
          spaceId: space.id,
        });

    return {
      dataAttributes: getDataUiNavAttribute(space.name as DataUiAppName),
      id: space.id,
      isSelected: space.id === currentSpaceId,
      label: space.name,
      link,
      onClick: () => onClick(space, link),
      type: 'item',
    };
  });

  return canManageSpaces
    ? spaceItems.concat([
        {
          dataAttributes: getDataUiNavAttribute(DataUiAppName.Spaces),
          id: manageSpacesItemId,
          isSelected: false,
          label: 'Manage spaces',
          link: createSpaceListingLink({ projectId }),
          type: 'item',
        },
      ])
    : spaceItems;
};
