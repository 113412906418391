import React from 'react';
import { Redirect } from 'react-router';
import {
  DefaultWorkflowRoute,
  SubscriptionEnvironmentDefaultWorkflowRoute,
  SubscriptionEnvironmentSettingsRouteParams,
  WorkflowsRouteParams,
} from '../../../../_shared/constants/routePaths.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { getSelectedSubscription } from '../../../../_shared/selectors/subscriptionSelectors.ts';
import {
  getCurrentProject,
  getCurrentProjectPlan,
} from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { buildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { isMultipleWorkflowsConfigurationVisible } from '../../../../_shared/utils/workflow/isMultipleWorkflowsConfigurationVisible.ts';

const getWorkflowEditingLink = (state: IStore): string => {
  const subscriptionId = getSelectedSubscription(state)?.subscriptionId;
  const projectId = getCurrentProject(state).projectId;

  return subscriptionId
    ? buildPath<SubscriptionEnvironmentSettingsRouteParams>(
        SubscriptionEnvironmentDefaultWorkflowRoute,
        {
          projectId,
          subscriptionId,
        },
      )
    : buildPath<WorkflowsRouteParams>(DefaultWorkflowRoute, {
        projectId,
      });
};

export const RedirectIfMultipleWorkflowsAreNotAvailable: React.FC<
  React.PropsWithChildren<NoProps>
> = ({ children }) => {
  const isWorkflowListingEnabled = useSelector((state) =>
    isMultipleWorkflowsConfigurationVisible(
      state.data.workflows.byId,
      getCurrentProjectPlan(state),
    ),
  );
  const workflowEditingLink = useSelector(getWorkflowEditingLink);

  return isWorkflowListingEnabled ? <>{children}</> : <Redirect to={workflowEditingLink} />;
};
RedirectIfMultipleWorkflowsAreNotAvailable.displayName =
  'RedirectIfMultipleWorkflowsAreNotAvailable';
