import React from 'react';
import { NameEditorElement } from '../../../../_shared/components/NameEditorElement.tsx';
import { UntitledTaxonomyGroup } from '../../../../_shared/constants/defaultNames.ts';
import { ValidationConstants } from '../../../../_shared/constants/validationConstants.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { DataUiInput } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { isEmptyOrWhitespace } from '../../../../_shared/utils/stringUtils.ts';
import { taxonomyGroupNameChanged } from '../actions/taxonomyActions.ts';

export const TaxonomyGroupNameEditorElement: React.FC = () => {
  const dispatch = useDispatch();
  const name = useSelector((s) => s.taxonomyApp.editedTaxonomyGroup.name);

  return (
    <NameEditorElement
      autoFocus={isEmptyOrWhitespace(name)}
      dataUiInputName={DataUiInput.EntityName}
      maxLength={ValidationConstants.ElementNameMaxLength}
      placeholder={UntitledTaxonomyGroup}
      value={name === UntitledTaxonomyGroup ? '' : name}
      onChange={(groupName: string) => dispatch(taxonomyGroupNameChanged(groupName))}
      title="Taxonomy group name"
    />
  );
};

TaxonomyGroupNameEditorElement.displayName = 'TaxonomyGroupNameEditorElement';
