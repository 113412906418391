import classNames from 'classnames';
import { useRef } from 'react';
import { ScrollContainerContextProvider } from '../../../../../component-library/components/ScrollContainer/ScrollContainerContext.tsx';
import {
  HotkeysHandler,
  HotkeysMode,
} from '../../../../_shared/components/Hotkeys/HotkeysHandler.tsx';
import { HtmlPageTitle } from '../../../../_shared/components/HtmlPageTitle.tsx';
import { AppNames } from '../../../../_shared/constants/applicationNames.ts';
import { useUpdatePaperContext } from '../../../../_shared/contexts/EditorPaperContext.tsx';
import { useFixAutoCompleteOnScroll } from '../../../../_shared/hooks/useFixAutoCompleteOnScroll.ts';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ContentModelsWarning } from '../../shared/components/ContentModelsWarning.tsx';
import { ContentGroupSelectionBar } from '../containers/ContentGroups/ContentGroupSelectionBar.tsx';
import { ContentTypeContentGroupTabs } from '../containers/ContentGroups/ContentTypeContentGroupTabs.tsx';
import { ContentTypeEditorWorkflowLimitations } from '../containers/ContentTypeEditorWorkflowLimitations.tsx';
import { ContentTypeElementLibrary } from '../containers/ContentTypeElementLibrary.tsx';
import { ContentTypeElementList } from '../containers/ContentTypeElementList.tsx';
import { ContentTypeNameEditorElement } from '../containers/ContentTypeNameEditorElement.tsx';

interface Props {
  readonly editedContentTypeName: string;
  readonly focusTypeName?: boolean;
  readonly hasActiveGroupWithElements: boolean;
  readonly isBeingSaved: boolean;
  readonly onSaveContentType: () => void;
  readonly showContentTypeUsedWithPublishedItemWarning: boolean;
}

export const ContentTypeEditorPane = ({
  editedContentTypeName,
  focusTypeName,
  hasActiveGroupWithElements,
  isBeingSaved,
  onSaveContentType,
  showContentTypeUsedWithPublishedItemWarning,
}: Props) => {
  const contentTypePaneRef = useRef<HTMLDivElement>(null);

  useFixAutoCompleteOnScroll(contentTypePaneRef);
  useUpdatePaperContext(contentTypePaneRef);

  const onControlSHandler = (e: KeyboardEvent): void => {
    e.preventDefault();

    if (!isBeingSaved) {
      onSaveContentType();
    }
  };

  const warning = showContentTypeUsedWithPublishedItemWarning ? (
    <ContentModelsWarning
      firstParagraphText="You’re about to make changes to a content type that’s used with published content."
      heading="Content type is used with published content"
      secondParagraphText="Keep in mind that any change you make to this content type may affect how your published content is displayed on your website or mobile app."
    />
  ) : null;

  return (
    <HotkeysHandler
      handlers={{
        onControlS: onControlSHandler,
      }}
      mode={HotkeysMode.Dual}
      className="canvas__wrapper"
    >
      <ScrollContainerContextProvider
        scrollContainerRef={contentTypePaneRef}
        tippyBoundaryRef={contentTypePaneRef}
      >
        <div
          ref={contentTypePaneRef}
          className="canvas__workspace canvas__workspace--detached-scrollbars canvas__workspace--in-content-model"
          {...getDataUiAppNameAttribute(DataUiAppName.ContentTypeEditing)}
        >
          <HtmlPageTitle appName={AppNames.ContentTypes} customName={editedContentTypeName} />
          <section className="canvas__content">
            <div className="canvas__content-pane">
              <div className="content-type__wrapper">
                {warning}
                <div className="canvas__inner-section canvas__inner-section--restricted-width">
                  <ContentTypeNameEditorElement shouldFocusTypeName={focusTypeName} isMultiline />
                  <ContentTypeEditorWorkflowLimitations />
                  <div
                    className={classNames('content-group-pane', {
                      'content-group-pane--has-active-group-with-elements':
                        hasActiveGroupWithElements,
                    })}
                  >
                    <ContentTypeContentGroupTabs />
                    <ContentGroupSelectionBar />
                  </div>
                  <ContentTypeElementList />
                </div>
              </div>
            </div>
          </section>
          <div className="canvas__sidebar">
            <ContentTypeElementLibrary />
          </div>
        </div>
      </ScrollContainerContextProvider>
    </HotkeysHandler>
  );
};
