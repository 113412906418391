import { PropsWithChildren } from 'react';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { RootItemContextProvider } from '../context/RootItemContext.tsx';

export const RootItemProviderContainer = ({ children }: PropsWithChildren) => {
  const availableSpaces = useSelector((s) => s.data.spaces.byId);

  return (
    <RootItemContextProvider availableSpaces={availableSpaces}>{children}</RootItemContextProvider>
  );
};
