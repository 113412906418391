import { ContentBlock, ContentState, EntityInstance } from 'draft-js';
import { IProjectContributor } from '../../../../../data/models/users/ProjectContributor.ts';
import { isAiInstruction } from '../../../plugins/inlineAi/utils/InstructionEntity.ts';
import { isFinishedMention } from '../../../plugins/mentions/api/MentionEntity.ts';
import { BlockType, isListBlockType } from '../../blocks/blockType.ts';
import {
  isCustomBlockSleeve,
  isEmptyParagraph,
  isNewBlockPlaceholder,
  isObjectBlock,
  isTableCell,
  isTableCellContent,
} from '../../blocks/blockTypeUtils.ts';
import { getBaseBlockType } from '../../blocks/editorBlockGetters.ts';
import { EntityMap, getBlocks } from '../../general/editorContentGetters.ts';
import { getInstructionText, getMentionText } from './entities.ts';

const INDENT = '\t';
const NEW_LINE = '\n';
const DOUBLE_NEW_LINE = '\n\n';

interface IContext {
  readonly list: OrderedListContext;
}

class OrderedListContext {
  private orderedListItemCounters: Array<number> = [0];

  public getCurrentItemCounter = () =>
    this.orderedListItemCounters[this.orderedListItemCounters.length - 1];
  public reset = () => {
    this.orderedListItemCounters = [0];
  };
  public incrementListItemCounter = () => {
    const topIndex = this.orderedListItemCounters.length - 1;
    this.orderedListItemCounters[topIndex] = (this.orderedListItemCounters[topIndex] || 0) + 1;
  };
  public indent = (levels: number) => {
    for (let i = 0; i < levels; i++) {
      this.orderedListItemCounters.push(0);
    }
  };
  public removeIndent = (levels: number) =>
    this.orderedListItemCounters.splice(this.orderedListItemCounters.length - levels);
}

function updateListContext(
  previousBlock: ContentBlock | null,
  currentBlockDepth: number,
  context: IContext,
) {
  const previousBlockType = previousBlock && getBaseBlockType(previousBlock);
  const previousBlockDepth = previousBlock?.getDepth() ?? 0;

  if (previousBlockType !== BlockType.OrderedListItem) {
    context.list.reset();
  }

  if (previousBlockDepth < currentBlockDepth) {
    context.list.indent(currentBlockDepth - previousBlockDepth);
  }
  if (previousBlockDepth > currentBlockDepth) {
    context.list.removeIndent(previousBlockDepth - currentBlockDepth);
  }

  context.list.incrementListItemCounter();
}

const getContentBeforeBlock = (
  previousBlock: ContentBlock | null,
  currentBlock: ContentBlock,
  context: IContext,
): string => {
  const currentBlockType = getBaseBlockType(currentBlock);
  const currentBlockDepth = currentBlock.getDepth();

  switch (currentBlockType) {
    case BlockType.UnorderedListItem:
      return `${INDENT.repeat(currentBlockDepth)}* `;

    case BlockType.OrderedListItem: {
      updateListContext(previousBlock, currentBlockDepth, context);
      return `${INDENT.repeat(currentBlockDepth)}${context.list.getCurrentItemCounter()}. `;
    }

    case BlockType.TableCell: {
      if (currentBlockDepth) {
        return currentBlockDepth % 1000 === 0 ? NEW_LINE : INDENT;
      }
      break;
    }

    default:
      break;
  }

  return '';
};

const getContentAfterBlock = (
  currentBlock: ContentBlock,
  nextBlock: ContentBlock | null,
): string => {
  // Do not make extra extra new lines inside the tables to keep the table output consistent
  if (isTableCellContent(currentBlock) && isTableCell(nextBlock)) {
    return '';
  }

  const currentBlockType = getBaseBlockType(currentBlock);
  const nextBlockType = nextBlock && getBaseBlockType(nextBlock);

  const isLastBlock = !nextBlock;
  if (isLastBlock) {
    return '';
  }

  const isEmptyBlock = isEmptyParagraph(currentBlock) || isEmptyParagraph(nextBlock);

  switch (currentBlockType) {
    case BlockType.HeadingOne:
    case BlockType.HeadingTwo:
    case BlockType.HeadingThree:
    case BlockType.HeadingFour:
    case BlockType.HeadingFive:
    case BlockType.HeadingSix:
    case BlockType.Unstyled:
      return isEmptyBlock ? NEW_LINE : DOUBLE_NEW_LINE;

    case BlockType.Image:
    case BlockType.ContentModule:
    case BlockType.ContentComponent:
      return DOUBLE_NEW_LINE;

    case BlockType.OrderedListItem:
    case BlockType.UnorderedListItem:
      return nextBlockType && isListBlockType(nextBlockType) ? NEW_LINE : DOUBLE_NEW_LINE;

    default:
      return '';
  }
};

const getPreviousBlock = (
  currentBlockIndex: number,
  blocks: ReadonlyArray<ContentBlock>,
): ContentBlock | null => {
  return blocks[currentBlockIndex - 1] ?? null;
};

const getNextBlock = (
  currentBlockIndex: number,
  blocks: ReadonlyArray<ContentBlock>,
): ContentBlock | null => {
  return blocks[currentBlockIndex + 1] ?? null;
};

type GetEntityText = (entity: EntityInstance, entityText: string) => string | null;

type GetBlockText = (block: ContentBlock) => string | null;

const getBlockText = (
  block: ContentBlock,
  entityMap: EntityMap,
  getEntityText: GetEntityText,
): string | null => {
  if (isObjectBlock(block) || isNewBlockPlaceholder(block)) return null;

  const output: Array<string> = [];
  let processedOffset = 0;
  const text = block.getText();

  block.findEntityRanges(
    (ch) => !!ch.getEntity(),
    (start, end) => {
      const textBeforeEntity = text.slice(processedOffset, start);
      output.push(textBeforeEntity);

      const entityText = text.slice(start, end);
      processedOffset = end;

      const entityKey = block.getEntityAt(start);
      if (entityKey) {
        const entity = entityMap.__get(entityKey);
        if (entity) {
          const entityResult = getEntityText(entity, entityText);
          if (entityResult !== null) {
            output.push(entityResult);
            return;
          }
        }
      }

      output.push(entityText);
    },
  );

  const remainingText = text.slice(processedOffset, text.length);
  output.push(remainingText);

  return output.join('').trim();
};

const composePlainTextString = (
  blocks: ReadonlyArray<ContentBlock>,
  entityMap: EntityMap,
  options: ExportToPlainTextOptions,
) => {
  const context = {
    list: new OrderedListContext(),
  };

  const plainText: Array<string> = [];

  blocks.forEach((block: ContentBlock, currentBlockIndex: number) => {
    const previousBlock = getPreviousBlock(currentBlockIndex, blocks);
    const nextBlock = getNextBlock(currentBlockIndex, blocks);

    const contentBeforeBlock = getContentBeforeBlock(previousBlock, block, context);
    const blockText =
      options.getBlockText(block) ?? getBlockText(block, entityMap, options.getEntityText);
    const contentAfterBlock = getContentAfterBlock(block, nextBlock);

    if (blockText !== null) {
      plainText.push(contentBeforeBlock, blockText, contentAfterBlock);
    }
  });

  return plainText.join('').trim();
};

const filterUnrenderedBlocks = (
  blocks: ReadonlyArray<ContentBlock>,
): ReadonlyArray<ContentBlock> => {
  return blocks.filter((_, index: number) => !isCustomBlockSleeve(index, blocks));
};

type ExportToPlainTextOptions = {
  readonly getEntityText: GetEntityText;
  readonly getBlockText: GetBlockText;
};

export const exportToPlainText = (
  content: ContentState,
  options: ExportToPlainTextOptions,
): string => {
  const blocks = getBlocks(content);
  const renderedBlocks = filterUnrenderedBlocks(blocks);

  return composePlainTextString(renderedBlocks, content.getEntityMap(), options);
};

export type GetUsers = () => ReadonlyMap<UserId, IProjectContributor>;

type GetEntityTextForClipboardDependencies = {
  readonly getUsers: GetUsers;
};

const getEntityTextForClipboard = (
  entity: EntityInstance,
  entityText: string,
  deps?: GetEntityTextForClipboardDependencies,
): string | null => {
  if (isFinishedMention(entity) && deps) {
    return getMentionText(entity, (userId) => deps.getUsers().get(userId) ?? null);
  }

  if (isAiInstruction(entity)) {
    return getInstructionText(entity, entityText);
  }

  return null;
};

const keepDefaultBlockText: GetBlockText = () => null;

export const exportToClipboardPlainText = (
  content: ContentState,
  deps?: GetEntityTextForClipboardDependencies,
): string =>
  exportToPlainText(content, {
    getEntityText: (entity, entityText) => getEntityTextForClipboard(entity, entityText, deps),
    getBlockText: keepDefaultBlockText,
  });

const getEntityTextForServer = (entity: EntityInstance): string | null =>
  isFinishedMention(entity) || isAiInstruction(entity) ? '' : null;

export const exportToServerPlainText = (content: ContentState): string =>
  exportToPlainText(content, {
    getEntityText: (entity) => getEntityTextForServer(entity),
    getBlockText: keepDefaultBlockText,
  });

const getEntityTextForScreenReader: GetEntityText = (_, entityText) => entityText;

const getBlockTextForScreenReader: GetBlockText = (block) => {
  switch (getBaseBlockType(block)) {
    case BlockType.Image:
      return '[image]';

    case BlockType.ContentModule:
      return '[linked item]';

    case BlockType.ContentComponent:
      return '[content component]';

    default:
      return keepDefaultBlockText(block);
  }
};

export const exportToScreenReaderPlainText = (content: ContentState) =>
  exportToPlainText(content, {
    getEntityText: getEntityTextForScreenReader,
    getBlockText: getBlockTextForScreenReader,
  });
