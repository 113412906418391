import { isElement } from '@kontent-ai/DOM';
import { ShortcutsConfig, useHotkeys } from '@kontent-ai/component-library/hooks';
import { getFocusableTreeWalker } from '@react-aria/focus';
import { RefObject } from 'react';
import {
  DataAttributes,
  getDataAttributeSelector,
} from '../utils/dataAttributes/DataAttributes.ts';

type Props = {
  readonly ref: RefObject<HTMLElement>;
};

export function useSkipTabNavigationInElement({ ref }: Props) {
  const skipTabOrderWithinSelector = getDataAttributeSelector(
    DataAttributes.SkipTabOrderWithin,
    'true',
  );
  const elementToTabsSkip = ref.current?.querySelector(skipTabOrderWithinSelector);
  const treeWalkerWithoutSkippedTabs = ref.current
    ? getFocusableTreeWalker(ref.current, {
        tabbable: true,
        accept: (node) =>
          !node.closest(getDataAttributeSelector(DataAttributes.SkipTabOrderWithin, 'true')),
      })
    : null;

  useHotkeys(
    {
      [ShortcutsConfig.Tab]: (event) => {
        if (!treeWalkerWithoutSkippedTabs || !ref.current || !isElement(event.target)) {
          return;
        }

        const treeWalker = getFocusableTreeWalker(ref.current, {
          tabbable: true,
        });
        treeWalker.currentNode = event.target;
        const nextNode = treeWalker.nextNode();
        // use native tab order
        if (
          !isElement(nextNode) ||
          !nextNode.closest(skipTabOrderWithinSelector) ||
          event.target.closest(skipTabOrderWithinSelector)
        ) {
          return;
        }

        event.preventDefault();

        treeWalkerWithoutSkippedTabs.currentNode = event.target;
        const nextNodeOutsideSkippedTabs = treeWalkerWithoutSkippedTabs.nextNode();

        if (nextNodeOutsideSkippedTabs && isElement(nextNodeOutsideSkippedTabs)) {
          nextNodeOutsideSkippedTabs.focus();
        }
      },
      [ShortcutsConfig.ShiftTab]: (e) => {
        if (!treeWalkerWithoutSkippedTabs || !ref.current) {
          return;
        }

        const targetNode = e.target as HTMLElement;
        const treeWalker = getFocusableTreeWalker(ref.current, {
          tabbable: true,
        });
        treeWalker.currentNode = targetNode;
        const previousNode = treeWalker.previousNode();
        // use native tab order
        if (
          !isElement(previousNode) ||
          !previousNode.closest(skipTabOrderWithinSelector) ||
          targetNode.closest(skipTabOrderWithinSelector)
        ) {
          return;
        }

        e.preventDefault();

        treeWalkerWithoutSkippedTabs.currentNode = targetNode;
        const previousNodeOutsideSkippedTabs = treeWalkerWithoutSkippedTabs.previousNode();

        if (previousNodeOutsideSkippedTabs && isElement(previousNodeOutsideSkippedTabs)) {
          previousNodeOutsideSkippedTabs.focus();
        }
      },
    },
    { ref, isDisabled: !elementToTabsSkip },
  );
}
