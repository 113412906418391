import { Box } from '@kontent-ai/component-library/Box';
import {
  Spacing,
  Typography,
  colorTextDefault,
  gridUnit,
} from '@kontent-ai/component-library/tokens';
import classNames from 'classnames';
import React, { RefObject, useEffect, useState } from 'react';
import { DropDownPositioner } from '../../../../_shared/uiComponents/DropDown/DropDownPositioner.tsx';
import {
  IDropdownTippyOptions,
  defaultDropdownTippyOptions,
} from '../../../../_shared/uiComponents/DropDown/dropDownTippyOptions.ts';
import { renderTimeString } from '../../../../_shared/utils/dateTime/timeUtils.ts';
import { ItemDetails } from '../containers/Popups/ItemDetails.tsx';
import { ICalendarEvent } from '../models/CalendarModels.type.ts';
import { AgendaItem } from './AgendaItem.tsx';
import { CalendarPopupType } from './Popups/CalendarPopupType.ts';

export type AgendaItemsListingOwnProps = Readonly<{
  className?: string;
  items: readonly ICalendarEvent[];
  onDismiss: () => void;
  popupType?: CalendarPopupType;
}>;

export type AgendaItemsListingDispatchProps = {
  readonly onAgendaItemClicked: (detailItemId: Uuid, date: DateTimeStamp) => void;
};

type AgendaItemsListingProps = AgendaItemsListingOwnProps & AgendaItemsListingDispatchProps;

export const AgendaItemsListing: React.FC<React.PropsWithChildren<AgendaItemsListingProps>> = (
  props,
) => {
  const [clickedItemId, setClickedItemId] = useState('');
  const [clickedItemStatus, setClickedItemStatus] = useState('');
  const [popupBoundary, setPopupBoundary] = useState<Element | null>(null);

  const tippyOptions: IDropdownTippyOptions = {
    ...defaultDropdownTippyOptions,
    placement: 'bottom-end',
    popperOptions: {
      modifiers: [
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['top-end', 'right', 'left'],
          },
        },
        {
          name: 'preventOverflow',
          options: {
            mainAxis: true,
            altAxis: true,
            boundary: popupBoundary,
            padding: {
              bottom: Spacing.L,
            },
          },
        },
      ],
    },
  };

  useEffect(() => {
    setPopupBoundary(document.querySelector('.canvas'));
  }, []);

  const onAgendaItemClicked = (item: ICalendarEvent): void => {
    setClickedItemId(item.itemId);
    setClickedItemStatus(item.status);
    props.onAgendaItemClicked(item.itemId, item.date);
  };

  const handleClickOutside = (): void => {
    props.onDismiss();
    setClickedItemId('');
    setClickedItemStatus('');
  };

  return (
    <ul className={classNames('agenda-listing', props.className)}>
      {props.items.map((item: ICalendarEvent) => {
        const isItemSelected = item.itemId === clickedItemId && item.status === clickedItemStatus;

        return (
          <DropDownPositioner
            key={item.itemId + item.status}
            isOptionListVisible={isItemSelected}
            onClickOutside={handleClickOutside}
            renderContent={() =>
              props.popupType === CalendarPopupType.ItemDetail ? (
                <ItemDetails onDismiss={props.onDismiss} />
              ) : undefined
            }
            renderSelector={(ref) => (
              <li
                className={classNames('agenda-listing__item', {
                  'agenda-listing__item--is-selected': isItemSelected,
                })}
                onClick={() => onAgendaItemClicked(item)}
                ref={ref as RefObject<HTMLLIElement>}
              >
                <Box
                  minWidth={10 * gridUnit}
                  typography={Typography.UIParagraph}
                  color={colorTextDefault}
                >
                  {renderTimeString(item.date)}
                </Box>
                <AgendaItem event={item} />
              </li>
            )}
            tippyOptions={tippyOptions}
          />
        );
      })}
    </ul>
  );
};

AgendaItemsListing.displayName = 'AgendaItemsListing';
