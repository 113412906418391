import { getContentItemPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { IListingVariantData } from '../../../../../data/models/listingContentItems/IListingVariantData.ts';

export const getVariantLinkPath = (variant: IListingVariantData, isLivePreviewPreferred: boolean) =>
  getContentItemPath(
    window.location.pathname,
    variant.id.itemId,
    isLivePreviewPreferred,
    variant.id.variantId,
  );
