import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { InputState, Textarea } from '@kontent-ai/component-library/Input';
import { identity } from '@kontent-ai/utils';
import { animated, useTransition } from '@react-spring/web';
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { getDropDown } from '../../../../../../../_shared/components/DropDown.tsx';
import { OptionWithInputAttribute } from '../../../../../../../_shared/components/Options/Option.tsx';
import { BlockVariant } from '../../../../../../../_shared/components/Options/createOptionComponent.tsx';
import { documentationLinks } from '../../../../../../../_shared/constants/documentationLinks.ts';
import { OptionMode } from '../../../../../../../_shared/models/optionMode.ts';
import {
  DataUiInput,
  getDataUiInputAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  PredefinedRegex,
  PredefinedRegexesMap,
  PredefinedRegexesTypeOption,
  PredefinedRegexesTypes,
  getPredefinedRegexesTypeOptionName,
} from '../../../../../../contentInventory/content/models/predefinedRegexesOptions.ts';
import {
  ITypeElementDataWithValidationRegex,
  ValidationRegex,
  emptyValidationRegex,
} from '../../../../models/elements/types/TypeElementData.ts';
import { getDisallowedLimitationTooltipText } from '../../../../utils/typeElementMessagesUtils.ts';
import { getFlags } from '../../../../utils/validationRegexFlagsUtils.ts';
import { TypeElementConfigurationSection } from '../TypeElementConfigurationSection.tsx';
import { TypeElementHint } from '../TypeElementHint.tsx';

const DropDown = getDropDown<PredefinedRegexesTypeOption>();

interface IValidationRegexProps {
  readonly typeElementData: ITypeElementDataWithValidationRegex;
  readonly showPredefinedRegexes: boolean;
}

interface IValidationRegexCallbackProps {
  readonly onChange: (data: ValidationRegex) => void;
}

type PredefinedRegexState = PredefinedRegexesTypeOption | undefined;

export const ValidationRegexConfig: React.FC<
  IValidationRegexProps & IValidationRegexCallbackProps
> = ({ typeElementData: { validationRegex }, showPredefinedRegexes, onChange }) => {
  const inputRegex = validationRegex ?? emptyValidationRegex;
  const initialOptionState = showPredefinedRegexes ? undefined : PredefinedRegexesTypeOption.Custom;
  const { current: initialValidationRegex } = useRef<ValidationRegex>(inputRegex);
  const [selectedOption, setSelectedOption] = useState<PredefinedRegexState>(initialOptionState);
  const [isAnimationEnabled, setIsAnimationEnabled] = useState<boolean>(false);

  const transitions = useTransition(selectedOption === PredefinedRegexesTypeOption.Custom, {
    from: {
      maxHeight: 0,
      opacity: 0,
    },
    enter: {
      maxHeight: 350,
      opacity: 1,
    },
  });

  const setPredefinedRegexOption = useCallback(() => {
    if (!showPredefinedRegexes) {
      return;
    }
    const option = Array.from(PredefinedRegexesMap.entries())
      .filter(([key]) => key !== PredefinedRegexesTypeOption.Custom)
      .reduce(
        (prev, [key, value]) =>
          value?.regex === initialValidationRegex.regex &&
          value?.flags === initialValidationRegex.flags
            ? key
            : prev,
        undefined,
      );
    const resultOption =
      option === undefined &&
      (initialValidationRegex.regex !== '' || initialValidationRegex.flags !== null)
        ? PredefinedRegexesTypeOption.Custom
        : option;

    setSelectedOption(resultOption);
  }, [showPredefinedRegexes]);

  useEffect(() => {
    setPredefinedRegexOption();
  }, [setPredefinedRegexOption]);

  const updateRegexValue = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    onChange({
      ...inputRegex,
      regex: event.target.value,
    });
  };

  const updateFlags = (isIgnoreCaseSelected: boolean): void => {
    onChange({
      ...inputRegex,
      flags: isIgnoreCaseSelected ? 'i' : null,
    });
  };

  const updateValidationMessage = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ): void => {
    onChange({
      ...inputRegex,
      validationMessage: event.target.value,
    });
  };

  const onPredefinedRegexChanged = (value: PredefinedRegexesTypeOption): void => {
    if (value === selectedOption || !PredefinedRegexesMap.has(value)) {
      return;
    }
    setIsAnimationEnabled(true);

    const predefinedRegex = PredefinedRegexesMap.get(value) as PredefinedRegex;

    onChange({
      ...inputRegex,
      regex: predefinedRegex.regex,
      flags: predefinedRegex.flags,
      validationMessage:
        value === PredefinedRegexesTypeOption.Custom ? '' : predefinedRegex.validation_message,
    });
    setSelectedOption(value);
  };

  const inputState = inputRegex.isActive ? InputState.Default : InputState.Disabled;
  const validationMessageInputState =
    !inputRegex.isActive || selectedOption === undefined ? InputState.Disabled : InputState.Default;
  const validationMessagePlaceholder =
    selectedOption === undefined
      ? 'Select a pattern first'
      : selectedOption === PredefinedRegexesTypeOption.Custom
        ? 'Describe what format this field expects'
        : PredefinedRegexesMap.get(selectedOption)?.validation_message;
  const tooltipText = getDisallowedLimitationTooltipText(
    'matching a specific pattern',
    inputRegex.isActive,
  );

  const renderDocumentationLink = () => {
    return (
      <TypeElementHint>
        <OutwardLink href={documentationLinks.regexLimitations}>
          Discover various regular expressions
        </OutwardLink>{' '}
        you can use here.
      </TypeElementHint>
    );
  };

  const renderHeader = () => {
    if (showPredefinedRegexes) {
      return (
        <TypeElementConfigurationSection
          title="Pattern"
          className="content-element__config-section-control--full-width"
        >
          <div className="content-element__config-predefined-regexes">
            <div className="content-element__config-predefined-regexes-dropdown-item">
              <DropDown
                className="combo-box"
                selectedOption={selectedOption}
                options={PredefinedRegexesTypes}
                onSelect={onPredefinedRegexChanged}
                renderOptionName={getPredefinedRegexesTypeOptionName}
                getOptionId={identity}
                defaultText="Select a pattern"
                disabled={!inputRegex.isActive}
                disabledTooltipMessage={tooltipText}
                disabledTooltipPlacement="top"
              />
            </div>
            <div className="content-element__config-predefined-regexes-hint-item">
              {renderDocumentationLink()}
            </div>
          </div>
        </TypeElementConfigurationSection>
      );
    }
    return (
      <TypeElementConfigurationSection
        title=""
        className="content-element__config-section-control--full-width"
      >
        {renderDocumentationLink()}
      </TypeElementConfigurationSection>
    );
  };

  const renderRegex = () => {
    if (isAnimationEnabled) {
      return transitions((style, item) => (
        <animated.div className="content-element__config-section-control--full-width" style={style}>
          {item && renderRegexSection()}
        </animated.div>
      ));
    }

    return selectedOption === PredefinedRegexesTypeOption.Custom && renderRegexSection();
  };

  const renderRegexSection = () => {
    return (
      <TypeElementConfigurationSection
        title="Regular expression"
        className="content-element__config-section-control--full-width"
      >
        <Textarea
          inputState={inputState}
          placeholder="No validation regex has been set yet"
          value={inputRegex.regex}
          onChange={updateRegexValue}
          tooltipText={tooltipText}
          tooltipPlacement="top"
          {...getDataUiInputAttribute(DataUiInput.Text)}
        />

        <OptionWithInputAttribute
          label="Case-insensitive matching"
          isSelected={getFlags(inputRegex).ignoreCase}
          disabled={!inputRegex.isActive}
          mode={OptionMode.Multiple}
          onOptionSelected={updateFlags}
          showFullSize={false}
          blockVariant={BlockVariant.InlineBlock}
          tooltipText={tooltipText}
          tooltipPlacement="right"
        />
      </TypeElementConfigurationSection>
    );
  };

  return (
    <>
      {renderHeader()}
      {renderRegex()}

      <TypeElementConfigurationSection
        title="Validation message"
        className="content-element__config-section-control--full-width"
        tooltip="The validation message appears in content items based on this type."
      >
        <Textarea
          inputState={validationMessageInputState}
          placeholder={validationMessagePlaceholder}
          value={inputRegex.validationMessage || ''}
          onChange={updateValidationMessage}
          tooltipText={tooltipText}
          tooltipPlacement="top"
          {...getDataUiInputAttribute(DataUiInput.Text)}
        />
      </TypeElementConfigurationSection>
    </>
  );
};
