import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { Collection, not } from '@kontent-ai/utils';
import { useCallback, useState } from 'react';
import { trackUserEvent } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import {
  FindRightContentTrackedEvent,
  TrackedEvent,
} from '../../../../../../_shared/constants/trackedEvent.ts';
import { useDataSelector } from '../../../../../../_shared/hooks/useDataSelector.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { ContentItemFilterOrigin } from '../../../../../../_shared/models/events/ContentItemFilterEventData.type.ts';
import { useIsRefinedNavigationFeatureEnabled } from '../../../../../refinedNavigation/contexts/RefinedNavigationContext.tsx';
import { ContentItemsSearchArea } from '../../../components/ContentItemsSearchArea.tsx';
import { IListingFilter } from '../../../models/filter/IListingFilter.ts';
import {
  contentItemFilterLeft,
  contentItemFilterSetUp,
} from '../../ListingFilter/actions/listingFilterActions.ts';
import { ContentItemFilter } from '../../ListingFilter/containers/ContentItemFilter.tsx';
import { useFilterChanged } from '../../ListingFilter/hooks/useFilterChanged.ts';
import { useSetUpContentItemFilter } from '../../ListingFilter/hooks/useSetUpContentItemFilter.ts';
import { filterContentItems } from '../actions/thunkContentItemInventoryActions.ts';
import { getFindRightContentFilterEventData } from '../utils/getFindRightContentTrackingPayloads.ts';

export const FilterArea = ({
  clearFilter,
}: {
  readonly clearFilter: () => void;
}) => {
  const dispatch = useDispatch();
  const filter = useSelector((s) => s.contentApp.listingUi.filter);
  const listingItemsLoadingStatus = useSelector((s) => s.data.listingContentItems.loadingStatus);
  const workflows = useDataSelector((data) => data.workflows.byId);
  const filterSetUp = useCallback(
    (listingFilter: IListingFilter) => {
      dispatch(contentItemFilterSetUp(listingFilter, ContentItemFilterOrigin.ContentInventory));
      dispatch(
        trackUserEvent(TrackedEvent.FindRightContent, {
          name: FindRightContentTrackedEvent.OpenedContentItemInventoryPage,
          ...getFindRightContentFilterEventData(
            listingFilter,
            ContentItemFilterOrigin.ContentInventory,
            Collection.getValues(workflows),
          ),
        }),
      );
    },
    [workflows],
  );
  const filterLeft = useCallback(
    () => dispatch(contentItemFilterLeft(ContentItemFilterOrigin.ContentInventory)),
    [],
  );

  useSetUpContentItemFilter(
    ContentItemFilterOrigin.ContentInventory,
    filterSetUp,
    filterLeft,
    undefined,
    undefined,
  );

  const onFilterChange = () => dispatch(filterContentItems());
  useFilterChanged(onFilterChange, filter, listingItemsLoadingStatus);

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const isRefinedNavigationFeatureEnabled = useIsRefinedNavigationFeatureEnabled();

  return (
    <Stack spacing={Spacing.None}>
      <ContentItemsSearchArea
        isFilterActive={isFilterVisible}
        onToggleFilter={() => setIsFilterVisible(not)}
        isInDialog={false}
      />
      {isRefinedNavigationFeatureEnabled && isFilterVisible && (
        <ContentItemFilter
          clearFilter={clearFilter}
          hideFilter={() => setIsFilterVisible(false)}
          origin={ContentItemFilterOrigin.ContentInventory}
        />
      )}
    </Stack>
  );
};
