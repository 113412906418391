import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../../../@types/Dispatcher.type.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { AssetFileTypeOption } from '../../../../../contentInventory/content/models/assetFileTypeOptions.ts';
import {
  ITypeElementSummaryCallbackProps,
  ITypeElementSummaryDataProps,
  TypeElementSummary as TypeElementSummaryComponent,
} from '../../../components/typeElements/shared/TypeElementSummary.tsx';
import {
  DefaultValueSetSummary,
  LimitationsSetSummary,
} from '../../../constants/summaryMessageTemplates.ts';
import { IAssetTypeElementData } from '../../../models/elements/AssetTypeElementData.ts';
import { IBaseTypeElementSummaryProps } from '../../../types/IBaseTypeElementSummaryProps.type.ts';
import { hasAssetDefaultValue } from '../../../utils/typeElementDefaultValueUtils.ts';
import {
  getBaseTypeElementSummarySegments,
  joinSummarySegments,
} from '../../../utils/typeElementMessagesUtils.ts';

interface IAssetTypeElementSummaryProps
  extends IBaseTypeElementSummaryProps<IAssetTypeElementData> {}

export function getAssetTypeElementSummary(typeElement: IAssetTypeElementData): string {
  const summarySegments: Array<string> = [...getBaseTypeElementSummarySegments(typeElement)];
  const hasAnyLimitations =
    typeElement.minItems ||
    typeElement.maxItems ||
    typeElement.fileSize ||
    typeElement.minWidth ||
    typeElement.maxWidth ||
    typeElement.minHeight ||
    typeElement.maxHeight ||
    typeElement.fileType !== AssetFileTypeOption.Any;

  if (hasAnyLimitations) {
    summarySegments.push(LimitationsSetSummary);
  }

  if (hasAssetDefaultValue(typeElement)) {
    summarySegments.push(DefaultValueSetSummary);
  }

  return joinSummarySegments(summarySegments);
}

function mapStateToProps(
  _state: IStore,
  ownProps: IAssetTypeElementSummaryProps,
): ITypeElementSummaryDataProps {
  const element = ownProps.typeElementData;
  const summaryText = getAssetTypeElementSummary(element);

  return {
    isActive: ownProps.isActive,
    summaryText,
  };
}

function mapDispatchToProps(
  _dispatch: Dispatch,
  ownProps: IAssetTypeElementSummaryProps,
): ITypeElementSummaryCallbackProps {
  return ownProps;
}

export const AssetTypeElementSummary: React.ComponentType<
  React.PropsWithChildren<IAssetTypeElementSummaryProps>
> = connect(mapStateToProps, mapDispatchToProps)(TypeElementSummaryComponent);
