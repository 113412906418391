import React, { memo, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { WorkflowsRoute, WorkflowsRouteParams } from '../../../../_shared/constants/routePaths.ts';
import { useDataSelector } from '../../../../_shared/hooks/useDataSelector.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { getCurrentProject } from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { Capability, getUserCapability } from '../../../../_shared/utils/permissions/capability.ts';
import { buildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { hasProjectMultipleWorkflows } from '../../../../_shared/utils/workflow/hasProjectMultipleWorkflows.ts';
import { ContentTypeEditorWorkflowLimitations } from '../components/ContentTypeEditorWorkflowLimitations.tsx';
import { splitContentTypeApplicableWorkflows } from '../utils/getContentTypeApplicableWorkflows.ts';

const ContentTypeEditorWorkflowLimitationsContainer: React.FC = () => {
  const canManageWorkflows = useSelector((s) =>
    getUserCapability(getCurrentProject(s)).can(Capability.ConfigureWorkflow),
  );
  const editedTypeId = useSelector((s) => s.contentModelsApp.typeEditor.editedType.id);
  const isVisible = useDataSelector(
    (s) => !!editedTypeId && hasProjectMultipleWorkflows(s.workflows.byId),
  );
  const workflows = useSelector((s) => s.data.workflows.byId);
  const workflowsPath = useSelector((s) =>
    buildPath<WorkflowsRouteParams>(WorkflowsRoute, { projectId: s.sharedApp.currentProjectId }),
  );
  const collections = useSelector((s) => s.data.collections.byId);

  const { limitedWorkflows, unlimitedWorkflows } = useMemo(
    () => splitContentTypeApplicableWorkflows(workflows, editedTypeId),
    [workflows, editedTypeId],
  );

  if (!isVisible) {
    return null;
  }

  const bottomLabel = canManageWorkflows ? (
    <>
      To change workflows for content types, go to <Link to={workflowsPath}>Workflow settings</Link>
      .
    </>
  ) : (
    <>To change workflows for content types, ask your Project manager.</>
  );

  return (
    <ContentTypeEditorWorkflowLimitations
      bottomLabel={bottomLabel}
      collections={collections}
      limitedWorkflows={limitedWorkflows}
      unlimitedWorkflows={unlimitedWorkflows}
    />
  );
};

ContentTypeEditorWorkflowLimitationsContainer.displayName =
  'ContentTypeEditorWorkflowLimitationsContainer';

const ContentTypeEditorWorkflowLimitationsContainerMemoized = memo(
  ContentTypeEditorWorkflowLimitationsContainer,
);
export {
  ContentTypeEditorWorkflowLimitationsContainerMemoized as ContentTypeEditorWorkflowLimitations,
};
