import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { QuickstartType, getProjectQuickstartType } from '../../../../_shared/utils/trialUtils.ts';
import { KickstartProjectQuickstart } from '../components/KickstartProjectQuickstart.tsx';

export const Quickstart = () => {
  const quickstartType = useSelector(getProjectQuickstartType);

  switch (quickstartType) {
    case QuickstartType.Kickstart:
      return <KickstartProjectQuickstart />;
    case null:
      return null;
  }
};

Quickstart.displayName = 'Quickstart';
