import { useMemo } from 'react';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import { compose } from '../../../../_shared/utils/func/compose.ts';
import {
  archiveTaxonomyGroups,
  initTaxonomyGroupsListing,
  restoreArchivedTaxonomyGroups,
  selectTaxonomyGroups,
  unselectTaxonomyGroups,
} from '../actions/thunkTaxonomyActions.ts';
import { TaxonomyGroupListing as TaxonomyGroupListingComponent } from '../components/TaxonomyGroupListing.tsx';

export const TaxonomyGroupListing = () => {
  const currentProjectId = useSelector((state) => state.sharedApp.currentProjectId);
  const users = useSelector((state) => state.data.users.usersById);
  const deletedTaxonomyGroupIds = useSelector((state) => state.taxonomyApp.deletedTaxonomyGroupIds);
  const selectedTaxonomyGroupIds = useSelector(
    (state) => state.taxonomyApp.selectedTaxonomyGroupIds,
  );
  const taxonomyGroupListStatusInfoMessage = useSelector(
    (state) => state.taxonomyApp.taxonomyGroupListStatusInfoMessage,
  );
  const taxonomyGroups = useSelector((state) => state.taxonomyApp.taxonomyGroups);

  const dispatch = useDispatch();
  const onTaxonomyGroupsRestored = useMemo(
    () => compose(dispatch, restoreArchivedTaxonomyGroups),
    [],
  );
  const onTaxonomyGroupsArchived = useMemo(() => compose(dispatch, archiveTaxonomyGroups), []);
  const onTaxonomyGroupSelect = useMemo(() => compose(dispatch, selectTaxonomyGroups), []);
  const onTaxonomyGroupUnselect = useMemo(() => compose(dispatch, unselectTaxonomyGroups), []);

  useThunkPromise(initTaxonomyGroupsListing);

  return (
    <TaxonomyGroupListingComponent
      currentProjectId={currentProjectId}
      deletedTaxonomyGroupIds={deletedTaxonomyGroupIds}
      onTaxonomyGroupSelect={onTaxonomyGroupSelect}
      onTaxonomyGroupUnselect={onTaxonomyGroupUnselect}
      onTaxonomyGroupsArchived={onTaxonomyGroupsArchived}
      onTaxonomyGroupsRestored={onTaxonomyGroupsRestored}
      selectedTaxonomyGroupIds={selectedTaxonomyGroupIds}
      taxonomyGroupListStatusInfoMessage={taxonomyGroupListStatusInfoMessage}
      taxonomyGroups={taxonomyGroups}
      users={users}
    />
  );
};
