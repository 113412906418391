import { RefObject } from 'react';
import { HtmlPageTitle } from '../../../../../_shared/components/HtmlPageTitle.tsx';
import { AppNames } from '../../../../../_shared/constants/applicationNames.ts';
import { EditorPaperContextProvider } from '../../../../../_shared/contexts/EditorPaperContext.tsx';
import { untitledContentItem } from '../../../../contentInventory/content/constants/uiConstants.ts';
import { EditedContentItemContextProvider } from '../../ContentComponent/context/ContentItemContext.tsx';
import { ContentItemEditorPane } from '../containers/ContentItemEditorPane.tsx';
import { DiscussionsSidebar } from '../containers/comments/DiscussionsSidebar.tsx';
import { ResolvedCommentsSidebar } from '../containers/comments/ResolvedCommentsSidebar.tsx';
import { ContentItemChangeIntercomTracker } from './ContentItemChangeIntercomTracker.tsx';

type Props = Readonly<{
  contentItemCanvasRef?: RefObject<HTMLDivElement>;
  contentItemName: string | null;
  disabled: boolean;
  isLivePreviewOpen: boolean;
}>;

export const ContentItemEditor = ({
  contentItemCanvasRef,
  contentItemName,
  disabled,
  isLivePreviewOpen,
}: Props) => {
  return (
    <>
      <HtmlPageTitle
        appName={AppNames.ContentItemEditor}
        customName={contentItemName || untitledContentItem}
      />
      <ContentItemChangeIntercomTracker />

      <EditedContentItemContextProvider>
        <EditorPaperContextProvider>
          <ContentItemEditorPane
            disabled={disabled}
            isCompact={isLivePreviewOpen}
            ref={contentItemCanvasRef}
          />
        </EditorPaperContextProvider>
        <ResolvedCommentsSidebar />
        <DiscussionsSidebar />
      </EditedContentItemContextProvider>
    </>
  );
};
