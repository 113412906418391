import { Icons } from '@kontent-ai/component-library/Icons';
import {
  SimpleStatusDefault,
  SimpleStatusSuccess,
  SimpleStatusWarning,
} from '@kontent-ai/component-library/SimpleStatus';
import React from 'react';
import { InnovationLabActivationState } from '../types/InnovationLabActivationState.type.ts';

type Props = {
  readonly activationState: InnovationLabActivationState;
};
export const InnovationLabStatus: React.FC<Props> = ({ activationState }) => {
  switch (activationState) {
    case 'active':
      return (
        <SimpleStatusSuccess
          label="Active"
          icon={Icons.CbCheckPreview}
          tooltipText="This feature is active for you and other users in this project."
        />
      );

    case 'activation-pending':
      return (
        <SimpleStatusWarning
          label="Activating…"
          icon={Icons.ExclamationTriangle}
          tooltipText="Activation is in progress."
        />
      );

    case 'inactive':
      return (
        <SimpleStatusDefault
          label="Not active"
          icon={Icons.ICircle}
          tooltipText="This feature is currently inactive and can’t be used by you or other users in this project."
        />
      );

    case 'unknown':
      return (
        <SimpleStatusDefault
          label="Unknown"
          icon={Icons.ModalQuestion}
          tooltipText="The status of this feature in your project is currently unknown. Reach out to us to learn more."
        />
      );
  }
};
