import { Collection } from '@kontent-ai/utils';
import classNames from 'classnames';
import { DraftBlockRenderMap } from 'draft-js';
import React, { CSSProperties, MouseEvent, forwardRef, useCallback, useRef } from 'react';

import {
  SelectableContainerClassName,
  TableCellClassName,
  getBlockIdClassName,
} from '../../../editorCore/utils/editorComponentUtils.ts';
import { isEmptyParagraph } from '../../../utils/blocks/blockTypeUtils.ts';
import { getBlockFromBlockElement } from '../../draftJs/utils/draftJsEditorUtils.ts';
import { ApplyNestedBlockWrappers } from './ApplyNestedBlockWrappers.tsx';
import { TableColumnResizer } from './TableColumnResizer.tsx';

export interface ITableCellProps {
  readonly blockKey: string;
  readonly blockRenderMap: DraftBlockRenderMap;
  readonly children: ReadonlyArray<React.ReactElement<any>>;
  readonly columnIndex: number;
  readonly hasResizer: boolean;
  readonly onContextMenu?: (
    e: MouseEvent,
    blockKey: string,
    cellRef: React.RefObject<HTMLDivElement>,
  ) => void;
  readonly style?: CSSProperties;
}

export const TableCell = forwardRef<HTMLDivElement, ITableCellProps>((props, ref) => {
  const contentRef = useRef<HTMLDivElement>(null);

  const { blockKey, blockRenderMap, children, columnIndex, hasResizer, onContextMenu, style } =
    props;

  const handleContextMenu = useCallback(
    (e: MouseEvent): void => {
      if (onContextMenu) {
        onContextMenu(e, blockKey, contentRef);
      }
    },
    [blockKey, onContextMenu],
  );

  const lastContentBlockElement = Collection.getLast(children);
  const lastContentBlock =
    lastContentBlockElement && getBlockFromBlockElement(lastContentBlockElement);

  const isEmptyCell = children.length <= 1 && isEmptyParagraph(lastContentBlock);

  return (
    <div
      className={classNames(
        SelectableContainerClassName,
        TableCellClassName,
        getBlockIdClassName(blockKey),
        { [`${TableCellClassName}--is-empty`]: isEmptyCell },
      )}
      ref={ref}
      onContextMenu={handleContextMenu}
      style={style}
    >
      <div className={`${TableCellClassName}-content-wrapper`}>
        <div ref={contentRef} className={`${TableCellClassName}-content`}>
          <ApplyNestedBlockWrappers blockRenderMap={blockRenderMap}>
            {children}
          </ApplyNestedBlockWrappers>
        </div>
        {hasResizer && <TableColumnResizer columnIndex={columnIndex} />}
      </div>
    </div>
  );
});

TableCell.displayName = 'TableCell';
