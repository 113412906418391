import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { Collection, not } from '@kontent-ai/utils';
import { useCallback, useContext, useState } from 'react';
import { trackUserEvent } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import { FilterButton } from '../../../../_shared/components/FilterButton.tsx';
import {
  FindRightContentTrackedEvent,
  TrackedEvent,
} from '../../../../_shared/constants/trackedEvent.ts';
import { useDataSelector } from '../../../../_shared/hooks/useDataSelector.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { ContentItemFilterOrigin } from '../../../../_shared/models/events/ContentItemFilterEventData.type.ts';
import { getFindRightContentFilterEventData } from '../../../contentInventory/content/features/ContentItemInventory/utils/getFindRightContentTrackingPayloads.ts';
import {
  contentItemFilterLeft,
  contentItemFilterSetUp,
} from '../../../contentInventory/content/features/ListingFilter/actions/listingFilterActions.ts';
import { useFilterChanged } from '../../../contentInventory/content/features/ListingFilter/hooks/useFilterChanged.ts';
import { useSetUpContentItemFilter } from '../../../contentInventory/content/features/ListingFilter/hooks/useSetUpContentItemFilter.ts';
import { IListingFilter } from '../../../contentInventory/content/models/filter/IListingFilter.ts';
import { filterContentItems } from '../actions/thunkContentStatusActions.ts';
import { LanguageFilterContext } from '../contexts/LanguageFilter.tsx';
import { ContentStatusFilter } from './ContentStatusFilter.tsx';

export const FilterArea = ({
  clearFilter,
}: {
  readonly clearFilter: () => void;
}) => {
  const dispatch = useDispatch();
  const listingItemsLoadingStatus = useSelector(
    (s) => s.data.contentStatusContentItems.loadingStatus,
  );
  const workflows = useDataSelector((data) => data.workflows.byId);
  const filterSetUp = useCallback(
    (listingFilter: IListingFilter) => {
      dispatch(contentItemFilterSetUp(listingFilter, ContentItemFilterOrigin.ContentStatus));
      dispatch(
        trackUserEvent(TrackedEvent.FindRightContent, {
          name: FindRightContentTrackedEvent.OpenedContentItemInventoryPage,
          ...getFindRightContentFilterEventData(
            listingFilter,
            ContentItemFilterOrigin.ContentStatus,
            Collection.getValues(workflows),
          ),
        }),
      );
    },
    [workflows],
  );
  const filterLeft = useCallback(
    () => dispatch(contentItemFilterLeft(ContentItemFilterOrigin.ContentStatus)),
    [],
  );

  useSetUpContentItemFilter(
    ContentItemFilterOrigin.ContentStatus,
    filterSetUp,
    filterLeft,
    undefined,
    undefined,
  );

  const { selectedLanguageIds } = useContext(LanguageFilterContext);
  const onFilterChange = useCallback(
    () => dispatch(filterContentItems(selectedLanguageIds)),
    [selectedLanguageIds],
  );
  const filter = useSelector((s) => s.contentApp.listingUi.filter);
  useFilterChanged(onFilterChange, filter, listingItemsLoadingStatus);

  const [isFilterVisible, setIsFilterVisible] = useState(false);

  return (
    <Stack spacing={Spacing.None} align="start">
      <FilterButton isActive={isFilterVisible} onPress={() => setIsFilterVisible(not)} />
      {isFilterVisible && <ContentStatusFilter clearFilter={clearFilter} />}
    </Stack>
  );
};
