import { forwardRef } from 'react';
import { useRouteMatch } from 'react-router';
import { modalDismissed } from '../../../actions/sharedActions.ts';
import { RestoreRevisionConfirmationDialog as RestoreRevisionConfirmationDialogComponent } from '../../../components/StatusBar/revisions/RestoreRevisionConfirmationDialog.tsx';
import { ContentItemRevisionCompareRoute } from '../../../constants/routePaths.ts';
import { useRestoreRevision } from '../../../hooks/StatusBar/revisions/useRestoreRevision.ts';
import { useDispatch } from '../../../hooks/useDispatch.ts';
import { useSelector } from '../../../hooks/useSelector.ts';
import { getRestoreConfirmation } from '../../../selectors/StatusBar/revisions/revisionSelectors.ts';

type Props = {
  readonly isOpen: boolean;
};

export const RestoreRevisionConfirmationDialog = forwardRef<HTMLDivElement, Props>(
  ({ isOpen }, ref) => {
    const dispatch = useDispatch();

    const restoreRevision = useRestoreRevision();
    const comparingVersions = !!useRouteMatch(ContentItemRevisionCompareRoute);

    return (
      <RestoreRevisionConfirmationDialogComponent
        comparingVersions={comparingVersions}
        confirmation={useSelector(getRestoreConfirmation)}
        isOpen={isOpen}
        onCancel={() => dispatch(modalDismissed())}
        onConfirm={() => {
          dispatch(modalDismissed());
          restoreRevision();
        }}
        ref={ref}
      />
    );
  },
);
