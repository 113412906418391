import { clamp } from '@kontent-ai/utils';
import {
  WebSpotlightPreviewContainerOffset,
  WebSpotlightPreviewMaxResolutionPx,
  WebSpotlightPreviewMinResolutionPx,
} from '../constants/uiConstants.ts';
import {
  IWebSpotlightPreviewResolution,
  fitToScreenResolution,
} from '../models/webSpotlightPreviewResolutionType.ts';

export const calculateContainerFitResolution = (
  previewContainer: HTMLElement | null,
  currentResolution: IWebSpotlightPreviewResolution,
  scale: number,
): IWebSpotlightPreviewResolution => {
  const { width: currentWidth, height: currentHeight } = currentResolution;
  if (!previewContainer || currentWidth === 0 || currentHeight === 0) {
    return fitToScreenResolution;
  }

  const containerRect = getPreviewContainerUsableBoundingClientRect(previewContainer);

  let newScale = Number.parseFloat(scale.toFixed(2));
  let newWidth = currentWidth;
  let newHeight = currentHeight;

  const scaledWidth = newWidth * newScale;
  const scaledHeight = newHeight * newScale;

  if (scaledWidth > containerRect.width || scaledHeight > containerRect.height) {
    const scaleToFitWidth = containerRect.width / scaledWidth;
    const scaleToFitHeight = containerRect.height / scaledHeight;
    const scaleToFit = Math.min(scaleToFitWidth, scaleToFitHeight);

    newWidth = Math.floor(newWidth * scaleToFit);
    newHeight = Math.floor(newHeight * scaleToFit);

    if (
      newWidth < WebSpotlightPreviewMinResolutionPx ||
      newHeight < WebSpotlightPreviewMinResolutionPx
    ) {
      newWidth = clamp(
        newWidth,
        WebSpotlightPreviewMinResolutionPx,
        WebSpotlightPreviewMaxResolutionPx,
      );
      newHeight = clamp(
        newHeight,
        WebSpotlightPreviewMinResolutionPx,
        WebSpotlightPreviewMaxResolutionPx,
      );

      const previewAspectRatio = containerRect.width / containerRect.height;
      const iframeAspectRation = newWidth / newHeight;

      newScale =
        iframeAspectRation > previewAspectRatio
          ? containerRect.width / newWidth
          : containerRect.height / newHeight;
    } else {
      newWidth = clamp(
        newWidth,
        WebSpotlightPreviewMinResolutionPx,
        WebSpotlightPreviewMaxResolutionPx,
      );
      newHeight = clamp(
        newHeight,
        WebSpotlightPreviewMinResolutionPx,
        WebSpotlightPreviewMaxResolutionPx,
      );
    }
  }

  return {
    scale: newScale,
    width: newWidth,
    height: newHeight,
  };
};

export const calculateContainerFitScale = (
  previewContainer: HTMLElement | null,
  newWidth: number,
  newHeight: number,
  newScale?: number,
): IWebSpotlightPreviewResolution => {
  if (!previewContainer || newWidth === 0 || newHeight === 0) {
    return fitToScreenResolution;
  }

  const containerRect = getPreviewContainerUsableBoundingClientRect(previewContainer);

  const previewAspectRatio = containerRect.width / containerRect.height;
  const iframeAspectRation = newWidth / newHeight;

  const computedScale =
    iframeAspectRation > previewAspectRatio
      ? containerRect.width / newWidth
      : containerRect.height / newHeight;

  const scale = newScale && computedScale >= newScale ? newScale : computedScale;

  return {
    scale,
    width: newWidth,
    height: newHeight,
  };
};

export const doesPreviewResolutionFitInside = (
  { width, height, scale }: IWebSpotlightPreviewResolution,
  container: HTMLElement,
): boolean => {
  const containerRect = getPreviewContainerUsableBoundingClientRect(container);

  const actualWidth = scale * width;
  const actualHeight = scale * height;

  return containerRect.height >= actualHeight && containerRect.width >= actualWidth;
};

const getPreviewContainerUsableBoundingClientRect = (container: HTMLElement): DOMRect => {
  const containerRect = container.getBoundingClientRect();

  return {
    ...containerRect,
    width: containerRect.width - WebSpotlightPreviewContainerOffset,
    height: containerRect.height - WebSpotlightPreviewContainerOffset,
  };
};
