import { alphabetically } from '@kontent-ai/utils';
import { isProjectPlanExpired } from '../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { IStore } from '../../../_shared/stores/IStore.type.ts';
import { IUserProjectInfo } from '../../../data/models/user/UserProjectInfo.ts';

export function compareUserProjects(
  state: IStore,
  projectA: IUserProjectInfo,
  projectB: IUserProjectInfo,
): number {
  const isProjectAExpired = isProjectPlanExpired(state, projectA.projectId);
  const isProjectBExpired = isProjectPlanExpired(state, projectB.projectId);

  if (isProjectAExpired === isProjectBExpired) {
    return alphabetically(projectA.projectName, projectB.projectName);
  }

  return isProjectAExpired ? 1 : -1;
}
