import { isElement, isWholeNodeVisibleVertically, scrollToView } from '@kontent-ai/DOM';
import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { useDrop } from 'react-dnd';
import { DragSource } from '../../../../../../_shared/components/DragDrop/DragSource.tsx';
import { DragObject } from '../../../../../../_shared/components/DragDrop/dragDrop.type.ts';
import { DndTypes } from '../../../../../../_shared/constants/dndTypes.ts';
import {
  getDataUiElementAttribute,
  getDataUiObjectNameAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { dragMoveThrottleIntervalMs } from '../../../../../../_shared/utils/dragDrop/dragDropUtils.ts';
import { throttle } from '../../../../../../_shared/utils/func/throttle.ts';
import { TypeElementType } from '../../../../../contentInventory/content/models/ContentItemElementType.ts';
import { TypeElementLibraryCollectedProps } from '../../../types/typeElementLibraryDndTypes.type.ts';
import { ShadowContentElement } from './ShadowContentElement.tsx';
import { ITypeElementProps, TypeElement } from './TypeElement.tsx';

const move = throttle(
  (onMove: (sourceId: Uuid, targetId: Uuid) => void, sourceKey: Uuid, targetKey: Uuid): void => {
    onMove(sourceKey, targetKey);
  },
  dragMoveThrottleIntervalMs,
);

const scrollToElementIfNotVisible = (
  typeElementCurrent: HTMLElement | null | undefined,
  behavior: ScrollBehavior,
) => {
  if (typeElementCurrent && !isWholeNodeVisibleVertically(typeElementCurrent)) {
    scrollToView(typeElementCurrent, undefined, behavior);
  }
};

export const DraggableTypeElement: React.FC<ITypeElementProps> = (props) => {
  const typeElementRef = useRef<HTMLDivElement>(null);

  const {
    firstIncompleteElementFocused,
    hoveringCollisionStrategy,
    id,
    isFocused,
    lastAddedElementId,
    lastMovedElementId,
    onDragEnd,
    onDragStart,
    onMove,
  } = props;

  const [{ canDrop, isOver }, connectDropTarget] = useDrop<
    DragObject,
    void,
    TypeElementLibraryCollectedProps
  >({
    accept: [DndTypes.Content_Element_Add, DndTypes.Content_Element_Move],
    hover: (dragObject, monitor) => {
      if (monitor.getItemType() === DndTypes.Content_Element_Add) {
        // Adding new content element, do nothing on hover()
        return;
      }

      const pointer = monitor.getClientOffset();
      const contentElement = typeElementRef.current;
      if (
        !dragObject.sourceId ||
        dragObject.sourceId === id ||
        !isElement(contentElement) ||
        !pointer
      ) {
        return;
      }

      const params = {
        monitor,
        pointer,
        sourceId: dragObject.sourceId,
        targetBoundingRect: contentElement.getBoundingClientRect(),
        targetId: id,
      };
      if (!hoveringCollisionStrategy(params)) {
        return;
      }

      move(onMove, dragObject.sourceId, id);
    },
    drop: (_, monitor) => {
      const dndItemType = monitor.getItemType();
      if (dndItemType === DndTypes.Content_Element_Move) {
        // Moving existing content element, do nothing on drop();
        return;
      }

      if (dndItemType === DndTypes.Content_Element_Add) {
        // Type of content element that dropping
        const draggedElementType = monitor.getItem().sourceId;
        props.onAdd(draggedElementType as TypeElementType);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver() && monitor.getItemType() === DndTypes.Content_Element_Add,
      canDrop: monitor.canDrop() && monitor.getItemType() === DndTypes.Content_Element_Add,
    }),
  });

  const isActiveForDroppingNewElement = canDrop && isOver;

  useEffect(() => {
    if (isFocused) {
      scrollToElementIfNotVisible(typeElementRef.current, 'smooth');
      firstIncompleteElementFocused();
    }
  }, [isFocused, firstIncompleteElementFocused]);

  useEffect(() => {
    if (lastAddedElementId === id || lastMovedElementId === id) {
      scrollToElementIfNotVisible(typeElementRef.current, 'auto');
    }
  }, [lastMovedElementId, lastAddedElementId, id]);

  return connectDropTarget(
    <div
      ref={typeElementRef}
      className={classNames(props.className, 'content-element-wrapper', {
        'content-element-wrapper--is-add-element-over': isActiveForDroppingNewElement,
      })}
      {...getDataUiElementAttribute(props.typeElementData.type)}
      {...getDataUiObjectNameAttribute(props.typeElementData.name)}
    >
      <DragSource
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
        parentId=""
        sourceId={props.id}
        renderDraggable={(connectDragSource, isDragging) => (
          <>
            {(isActiveForDroppingNewElement || isDragging) && (
              <ShadowContentElement
                className={undefined}
                isFirstListElement={props.isFirstListElement}
                isGroupItem={props.isInGroup}
              />
            )}
            {!isDragging && (
              <TypeElement
                {...props}
                connectDragSource={connectDragSource}
                isDragging={isDragging}
              />
            )}
          </>
        )}
        renderPreview={() => <TypeElement {...props} isDragging={false} />}
        type={DndTypes.Content_Element_Move}
      />
    </div>,
  );
};

DraggableTypeElement.displayName = 'DraggableTypeElement';
