import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { IContentType } from '../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { ElementType } from '../../../contentInventory/content/models/ContentItemElementType.ts';

export const getContentTypesWithUrlSlugIds = (state: IStore): ReadonlySet<Uuid> =>
  new Set(
    state.data.contentTypes.byId
      .filter((contentType: IContentType) =>
        contentType.typeElements.some((elementData) => elementData.type === ElementType.UrlSlug),
      )
      .toArray()
      .map((type) => type.id),
  );
