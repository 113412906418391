import { Direction } from '@kontent-ai/types';
import { announce } from '@react-aria/live-announcer';
import { EditorState, SelectionState } from 'draft-js';
import { useCallback } from 'react';
import { getFocusEntryElement } from '../../../../_shared/utils/focusUtils.ts';
import { useEditorWithPlugin } from '../../editorCore/hooks/useEditorWithPlugin.tsx';
import { PluginComponent } from '../../editorCore/types/Editor.composition.type.ts';
import { None } from '../../editorCore/types/Editor.contract.type.ts';
import { Apply } from '../../editorCore/types/Editor.plugins.type.ts';
import { Decorator } from '../../editorCore/utils/decorable.ts';
import {
  BlockClassName,
  getBlockIdClassName,
} from '../../editorCore/utils/editorComponentUtils.ts';
import { isObjectBlockType } from '../../utils/blocks/blockType.ts';
import { getBaseBlockType } from '../../utils/blocks/editorBlockGetters.ts';
import { getFullySelectedBlocks, moveSelectionFocus } from '../../utils/editorSelectionUtils.ts';
import { DraftJsEditorPlugin } from '../draftJs/DraftJsEditorPlugin.type.ts';
import {
  ExecuteCommand,
  KeyboardShortcutsPlugin,
} from '../keyboardShortcuts/KeyboardShortcutsPlugin.tsx';
import { RichTextInputCommand } from '../keyboardShortcuts/api/EditorCommand.ts';
import { getCommandDirection } from '../keyboardShortcuts/api/editorCommandUtils.ts';
import {
  findKeyByCommand,
  richTextKeyCommandMap,
} from '../keyboardShortcuts/api/editorKeyboardUtils.ts';
import { WrapperPlugin } from '../visuals/WrapperPlugin.tsx';

export type CustomSelectionHandlingPlugin = DraftJsEditorPlugin<
  None,
  None,
  None,
  None,
  [WrapperPlugin, KeyboardShortcutsPlugin<RichTextInputCommand>]
>;

export const CustomSelectionHandlingPlugin: PluginComponent<CustomSelectionHandlingPlugin> = (
  props,
) => {
  const apply: Apply<CustomSelectionHandlingPlugin> = useCallback((state) => {
    const executeCommand: Decorator<ExecuteCommand<RichTextInputCommand>> =
      (baseExecuteCommand) => (command, isShiftPressed) => {
        switch (command) {
          case RichTextInputCommand.Focus: {
            state.executeChange((editorState) => {
              const content = editorState.getCurrentContent();
              const selection = editorState.getSelection();
              const selectedBlocks = getFullySelectedBlocks(content, selection);

              for (const block of selectedBlocks) {
                const blockKey = block.getKey();
                const editorElement = state.getWrapperRef().current;

                const blockElement = editorElement?.querySelector(
                  `.${BlockClassName}.${getBlockIdClassName(blockKey)}`,
                );

                if (!blockElement) {
                  return editorState;
                }

                const focusElement = getFocusEntryElement(blockElement);
                if (focusElement) {
                  focusElement.focus();
                  return editorState;
                }
              }

              return editorState;
            });

            return true;
          }

          case RichTextInputCommand.MoveCaretToNextBlock:
          case RichTextInputCommand.MoveCaretToPreviousBlock: {
            const direction = getCommandDirection(command);

            let isCommandUnhandled = false;
            state.executeChange((editorState) => {
              const content = editorState.getCurrentContent();
              const selection = editorState.getSelection();
              const focusBlockKey = selection.getFocusKey();
              const focusBlock = content.getBlockForKey(focusBlockKey);

              const anchorSelectionBlock =
                direction === Direction.Backward
                  ? content.getBlockAfter(focusBlock.getKey())
                  : content.getBlockBefore(focusBlock.getKey());
              const focusSelectionBlock =
                direction === Direction.Backward
                  ? content.getBlockBefore(focusBlock.getKey())
                  : content.getBlockAfter(focusBlock.getKey());

              const shouldUnselectBlock =
                anchorSelectionBlock && isObjectBlockType(getBaseBlockType(anchorSelectionBlock));
              const hasSelectedBlock = selection.getAnchorKey() !== selection.getFocusKey();
              if (shouldUnselectBlock && hasSelectedBlock) {
                const newSelection = SelectionState.createEmpty(focusBlock.getKey());
                return EditorState.forceSelection(editorState, newSelection);
              }

              const shouldSelectBlock =
                focusSelectionBlock && isObjectBlockType(getBaseBlockType(focusSelectionBlock));
              if (focusSelectionBlock && shouldSelectBlock) {
                const newSelection = moveSelectionFocus(
                  content,
                  selection,
                  focusSelectionBlock.getKey(),
                  1,
                );

                announce(
                  `Press ${findKeyByCommand(RichTextInputCommand.Focus, richTextKeyCommandMap)} to focus ${getBaseBlockType(focusSelectionBlock)}`,
                );

                return EditorState.forceSelection(editorState, newSelection);
              }

              isCommandUnhandled = true;
              return editorState;
            });

            if (isCommandUnhandled) {
              return baseExecuteCommand(command, isShiftPressed);
            }

            return true;
          }

          default:
            return baseExecuteCommand(command, isShiftPressed);
        }
      };

    state.executeCommand.decorate(executeCommand);

    return {};
  }, []);

  return useEditorWithPlugin(props, { apply });
};
