import { Box } from '@kontent-ai/component-library/Box';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, Typography, colorTextLowEmphasis } from '@kontent-ai/component-library/tokens';
import { Collection } from '@kontent-ai/utils';
import { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import { trackUserEvent } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { HtmlPageTitle } from '../../../../../../_shared/components/HtmlPageTitle.tsx';
import { Loader } from '../../../../../../_shared/components/Loader.tsx';
import { PageTitle } from '../../../../../../_shared/components/PageTitle.tsx';
import { AppNames } from '../../../../../../_shared/constants/applicationNames.ts';
import {
  FindRightContentTrackedEvent,
  TrackedEvent,
} from '../../../../../../_shared/constants/trackedEvent.ts';
import { NotificationBar } from '../../../../../../_shared/containers/NotificationBar.tsx';
import { useDataSelector } from '../../../../../../_shared/hooks/useDataSelector.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { ContentItemFilterOrigin } from '../../../../../../_shared/models/events/ContentItemFilterEventData.type.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getItemListForScrollTable } from '../../../../../../_shared/utils/getItemListForScrollTable.ts';
import {
  HeatMapTrigger,
  HotjarTag,
  triggerHeatMap,
  triggerRecording,
} from '../../../../../../_shared/utils/hotjarUtils.ts';
import { isItemsInitialLoadFinished } from '../../../../../../_shared/utils/isItemsInitialLoadFinished.ts';
import { useIsRefinedNavigationFeatureEnabled } from '../../../../../refinedNavigation/contexts/RefinedNavigationContext.tsx';
import { EnsureFullTextSearchStatus } from '../../../../shared/containers/EnsureFullTextSearchStatus.tsx';
import { IListingFilter } from '../../../models/filter/IListingFilter.ts';
import { getNumberOfItemsInViewport } from '../../../reducers/listingUi/selectors/getNumberOfItemsInViewport.ts';
import {
  contentItemFilterLeft,
  contentItemFilterSetUp,
} from '../../ListingFilter/actions/listingFilterActions.ts';
import {
  ContentItemFilter,
  isContentItemFilterInitialized,
} from '../../ListingFilter/containers/ContentItemFilter.tsx';
import { EnsureInventoryRelatedData } from '../../ListingFilter/containers/EnsureInventoryRelatedData.tsx';
import { useFilterChanged } from '../../ListingFilter/hooks/useFilterChanged.ts';
import { useSetUpContentItemFilter } from '../../ListingFilter/hooks/useSetUpContentItemFilter.ts';
import {
  clearContentItemListingFilter,
  filterContentItems,
  initializeContentListing,
  loadListingContentItemsForInventory,
} from '../actions/thunkContentItemInventoryActions.ts';
import { FilterArea } from '../containers/FilterArea.tsx';
import { ContentItemScrollTable } from '../containers/ItemInventoryScrollTable/ContentItemScrollTable.tsx';
import { ContentItemScrollTableActions } from '../containers/ItemInventoryScrollTable/ContentItemScrollTableActions.tsx';
import { ContentItemScrollTableEmptyState } from '../containers/ItemInventoryScrollTable/ContentItemScrollTableEmptyState.tsx';
import { ContentItemScrollTableHead } from '../containers/ItemInventoryScrollTable/ContentItemScrollTableHead.tsx';
import { ContentItemScrollTableRow } from '../containers/ItemInventoryScrollTable/ContentItemScrollTableRow.tsx';
import { ContentItemScrollTableTitle } from '../containers/ItemInventoryScrollTable/ContentItemScrollTableTitle.tsx';
import { getFindRightContentFilterEventData } from '../utils/getFindRightContentTrackingPayloads.ts';
import { MoveToCollectionDialogType } from './ItemInventoryScrollTable/ContentItemScrollTableActions.tsx';

const getItemListSelector = (state: IStore) =>
  getItemListForScrollTable(
    state.data.listingContentItems,
    getNumberOfItemsInViewport(state.contentApp.listingUi.contentItemListingScrollTableState),
  );

export const ContentItemInventory = () => {
  const contentPaneRef = useRef<HTMLDivElement>(null);
  const [moveToCollectionDialogType, setMoveToCollectionDialogType] = useState(
    MoveToCollectionDialogType.None,
  );

  const items = useSelector(getItemListSelector);
  const shouldRenderScrollTableRow = useSelector(
    (s) =>
      isItemsInitialLoadFinished(s.data.listingContentItems) &&
      isContentItemFilterInitialized(
        ContentItemFilterOrigin.ContentInventory,
        s.contentApp.listingUi.filterStatus,
      ),
  );
  const dispatch = useDispatch();

  useEffect(() => {
    triggerRecording(HotjarTag.ContentInventoryOpened);
    triggerHeatMap(HeatMapTrigger.ContentInventoryListing);
    dispatch(initializeContentListing());
  }, []);

  const isRefinedNavigationFeatureEnabled = useIsRefinedNavigationFeatureEnabled();

  const clearFilter = () => dispatch(clearContentItemListingFilter());

  return (
    <div className="canvas__workspace">
      <EnsureInventoryRelatedData>
        {!isRefinedNavigationFeatureEnabled && (
          <SetupContentItemFilter>
            <ContentItemFilter
              clearFilter={clearFilter}
              origin={ContentItemFilterOrigin.ContentInventory}
            />
          </SetupContentItemFilter>
        )}
        <div className="canvas__content">
          <div className="canvas__notifications">
            <NotificationBar />
          </div>
          <div
            className="canvas__content-pane canvas__content-pane--no-bottom-offset canvas__content-pane--without-vertical-scrollbar"
            ref={contentPaneRef}
            {...getDataUiAppNameAttribute(DataUiAppName.ContentItemListing)}
          >
            <HtmlPageTitle appName={AppNames.ContentItems} />
            <EnsureFullTextSearchStatus>
              <Stack spacing={Spacing.L}>
                {!isRefinedNavigationFeatureEnabled && <FilterArea clearFilter={clearFilter} />}
                <ContentItemScrollTable
                  ariaLabel="content item"
                  items={items}
                  onLoadContentItems={loadListingContentItemsForInventory(true)}
                  parentContainerRef={contentPaneRef}
                  renderEmptyState={() =>
                    shouldRenderScrollTableRow ? (
                      <ContentItemScrollTableEmptyState isInDialog={false} />
                    ) : (
                      <Loader />
                    )
                  }
                  renderRowItem={(params) => (
                    <ContentItemScrollTableRow
                      key={params.item.item.id}
                      index={params.index}
                      item={params.item}
                    />
                  )}
                  renderTableActions={() =>
                    isRefinedNavigationFeatureEnabled ? (
                      <FilterArea clearFilter={clearFilter} />
                    ) : (
                      <ContentItemScrollTableActions
                        moveToCollectionDialogType={moveToCollectionDialogType}
                        onMoveToCollectionDialogClose={() =>
                          setMoveToCollectionDialogType(MoveToCollectionDialogType.None)
                        }
                        onMoveToCollectionDialogOpen={() =>
                          setMoveToCollectionDialogType(MoveToCollectionDialogType.Move)
                        }
                        onMoveToCollectionDialogFail={() =>
                          setMoveToCollectionDialogType(MoveToCollectionDialogType.Fail)
                        }
                      />
                    )
                  }
                  isStatusVertical={isRefinedNavigationFeatureEnabled}
                  renderTableHead={() => <ContentItemScrollTableHead />}
                  renderTableTitle={() =>
                    isRefinedNavigationFeatureEnabled ? (
                      <Row>
                        <Column>
                          <Inline align="center" spacing={Spacing.S}>
                            <PageTitle>{AppNames.Content}</PageTitle>
                            <Box color={colorTextLowEmphasis} typography={Typography.LabelLarge}>
                              <ContentItemScrollTableTitle announceMoreActionStatus />{' '}
                            </Box>
                          </Inline>
                        </Column>

                        <Column width="fit-content">
                          <ContentItemScrollTableActions
                            moveToCollectionDialogType={moveToCollectionDialogType}
                            onMoveToCollectionDialogClose={() =>
                              setMoveToCollectionDialogType(MoveToCollectionDialogType.None)
                            }
                            onMoveToCollectionDialogOpen={() =>
                              setMoveToCollectionDialogType(MoveToCollectionDialogType.Move)
                            }
                            onMoveToCollectionDialogFail={() =>
                              setMoveToCollectionDialogType(MoveToCollectionDialogType.Fail)
                            }
                          />
                        </Column>
                      </Row>
                    ) : (
                      <ContentItemScrollTableTitle announceMoreActionStatus />
                    )
                  }
                  withColumnSettings
                />
              </Stack>
            </EnsureFullTextSearchStatus>
          </div>
        </div>
      </EnsureInventoryRelatedData>
    </div>
  );
};

const SetupContentItemFilter = ({ children }: PropsWithChildren) => {
  const dispatch = useDispatch();
  const filter = useSelector((s) => s.contentApp.listingUi.filter);
  const listingItemsLoadingStatus = useSelector((s) => s.data.listingContentItems.loadingStatus);
  const workflows = useDataSelector((data) => data.workflows.byId);
  const filterSetUp = useCallback(
    (listingFilter: IListingFilter) => {
      dispatch(contentItemFilterSetUp(listingFilter, ContentItemFilterOrigin.ContentInventory));
      dispatch(
        trackUserEvent(TrackedEvent.FindRightContent, {
          name: FindRightContentTrackedEvent.OpenedContentItemInventoryPage,
          ...getFindRightContentFilterEventData(
            listingFilter,
            ContentItemFilterOrigin.ContentInventory,
            Collection.getValues(workflows),
          ),
        }),
      );
    },
    [workflows],
  );
  const filterLeft = useCallback(
    () => dispatch(contentItemFilterLeft(ContentItemFilterOrigin.ContentInventory)),
    [],
  );

  useSetUpContentItemFilter(
    ContentItemFilterOrigin.ContentInventory,
    filterSetUp,
    filterLeft,
    undefined,
    undefined,
  );

  const onFilterChange = () => dispatch(filterContentItems());
  useFilterChanged(onFilterChange, filter, listingItemsLoadingStatus);

  return children;
};
