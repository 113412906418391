import { Column, Row } from '@kontent-ai/component-library/Row';
import { PropsWithChildren } from 'react';
import { IUser } from '../../../../../../data/reducers/user/IUser.type.ts';
import { IContentItemStatus } from '../../../models/filter/IContentItemStatus.ts';
import { IListingFilter } from '../../../models/filter/IListingFilter.ts';
import { IPublishingState } from '../../../models/filter/IPublishingState.ts';
import { CollectionsFilterSelector } from '../containers/filterCategory/CollectionsFilterSelector.tsx';
import { ContentItemStatusSelector } from '../containers/filterCategory/ContentItemStatusSelector.tsx';
import { ContentTypesFilterSelector } from '../containers/filterCategory/ContentTypesFilterSelector.tsx';
import { ContributorsFilterSelector } from '../containers/filterCategory/ContributorsFilterSelector.tsx';
import { PublishingStatusFilterSelector } from '../containers/filterCategory/PublishingStatusFilterSelector.tsx';
import { SitemapFilterSelector } from '../containers/filterCategory/SitemapFilterSelector.tsx';
import { SpacesFilterSelector } from '../containers/filterCategory/SpacesFilterSelector.tsx';
import { ItemsTaxonomyFilterSelector } from '../containers/filterCategory/TaxonomyFilterSelector.tsx';
import { WorkflowFilterSelector } from '../containers/filterCategory/WorkflowFilterSelector.tsx';

type Props = {
  readonly areSpacesInCollectionsEnabled: boolean;
  readonly displaySpacesFilter: boolean;
  readonly displayTaxonomyWarningStatus: boolean;
  readonly filter: IListingFilter;
  readonly forceContentTypesTooltipText: string | undefined;
  readonly forcedContentTypeIds: ReadonlySet<Uuid> | undefined;
  readonly hideVariantSpecificInfo?: boolean;
  readonly isAiSearchUsed: boolean;
  readonly languageId: Uuid;
  readonly onCollectionSelectionChanged: (ids: ReadonlySet<Uuid>) => void;
  readonly onContentItemStatusSelectionChanged: (status: IContentItemStatus) => void;
  readonly onContentTypesSelectionChanged: (ids: ReadonlySet<Uuid>) => void;
  readonly onContributorsSelectionChanged: (ids: ReadonlySet<Uuid>) => void;
  readonly onPublishingStatusSelectionChanged: (status: IPublishingState) => void;
  readonly onSitemapSelectionChanged: (selectedSitemapNodes: ReadonlySet<Uuid>) => void;
  readonly onSpaceSelectionChanged: (ids: ReadonlySet<Uuid>) => void;
  readonly onTaxonomySelectionChanged: (groupToTerms: ReadonlyMap<Uuid, ReadonlySet<Uuid>>) => void;
  readonly onWorkflowsSelectionChanged: (
    workflowToSelectedStepIds: ReadonlyMap<Uuid, ReadonlySet<Uuid>>,
    workflowToAllStepIds: ReadonlyMap<Uuid, ReadonlyArray<Uuid>>,
  ) => void;
  readonly user: IUser;
};

export const ContentItemFilterSelectorsOld = (props: Props) => (
  <>
    {props.displaySpacesFilter && (
      <SpacesFilterSelector
        onSpaceSelectionChanged={props.onSpaceSelectionChanged}
        selectedLanguageId={props.languageId}
        selectedSpaces={props.filter.selectedSpacesNodes}
        user={props.user}
      />
    )}
    <CollectionsFilterSelector
      areSpacesInCollectionsEnabled={props.areSpacesInCollectionsEnabled}
      onCollectionSelectionChanged={props.onCollectionSelectionChanged}
      selectedCollectionIds={props.filter.selectedCollectionsNodes}
      selectedLanguageId={props.languageId}
      selectedSpaceIds={props.filter.selectedSpacesNodes}
      user={props.user}
    />
    <ContentTypesFilterSelector
      forceContentTypesTooltipText={props.forceContentTypesTooltipText}
      forcedContentTypeIds={props.forcedContentTypeIds}
      onContentTypesSelectionChanged={props.onContentTypesSelectionChanged}
      selectedContentTypeIds={props.filter.selectedContentTypesNodes}
      selectedLanguageId={props.languageId}
      user={props.user}
    />
    <SitemapFilterSelector
      isAiSearchUsed={props.isAiSearchUsed}
      onSitemapSelectionChanged={props.onSitemapSelectionChanged}
      selectedSitemapNodes={props.filter.selectedSitemapNodes}
    />
    {!props.hideVariantSpecificInfo && (
      <>
        <WorkflowFilterSelector
          filter={props.filter}
          onWorkflowsSelectionChanged={props.onWorkflowsSelectionChanged}
        />
        <ContributorsFilterSelector
          currentUserId={props.user.info.userId}
          onContributorsSelectionChanged={props.onContributorsSelectionChanged}
          selectedContributors={props.filter.selectedContributorsNodes}
        />
        <ItemsTaxonomyFilterSelector
          displayWarningStatus={props.displayTaxonomyWarningStatus}
          onTaxonomySelectionChanged={props.onTaxonomySelectionChanged}
          selectedTaxonomyNodes={props.filter.selectedTaxonomyNodes}
        />
        <ContentItemStatusSelector
          isAiSearchUsed={props.isAiSearchUsed}
          onContentItemStatusSelectionChanged={props.onContentItemStatusSelectionChanged}
          selectedContentItemStatus={props.filter.selectedContentItemStatus}
          selectedLanguageId={props.languageId}
          user={props.user}
        />
        <PublishingStatusFilterSelector
          onPublishingStatusSelectionChanged={props.onPublishingStatusSelectionChanged}
          selectedPublishingStateNodes={props.filter.selectedPublishingStateNodes}
        />
      </>
    )}
  </>
);

export const ContentItemFilterSelectors = (props: Props) => (
  <Row>
    {props.displaySpacesFilter && (
      <SelectorColumn>
        <SpacesFilterSelector
          onSpaceSelectionChanged={props.onSpaceSelectionChanged}
          selectedLanguageId={props.languageId}
          selectedSpaces={props.filter.selectedSpacesNodes}
          user={props.user}
        />
      </SelectorColumn>
    )}
    <SelectorColumn>
      <CollectionsFilterSelector
        areSpacesInCollectionsEnabled={props.areSpacesInCollectionsEnabled}
        onCollectionSelectionChanged={props.onCollectionSelectionChanged}
        selectedCollectionIds={props.filter.selectedCollectionsNodes}
        selectedLanguageId={props.languageId}
        selectedSpaceIds={props.filter.selectedSpacesNodes}
        user={props.user}
      />
    </SelectorColumn>
    <SelectorColumn>
      <ContentTypesFilterSelector
        forceContentTypesTooltipText={props.forceContentTypesTooltipText}
        forcedContentTypeIds={props.forcedContentTypeIds}
        onContentTypesSelectionChanged={props.onContentTypesSelectionChanged}
        selectedContentTypeIds={props.filter.selectedContentTypesNodes}
        selectedLanguageId={props.languageId}
        user={props.user}
      />
    </SelectorColumn>
    <SelectorColumn>
      <SitemapFilterSelector
        isAiSearchUsed={props.isAiSearchUsed}
        onSitemapSelectionChanged={props.onSitemapSelectionChanged}
        selectedSitemapNodes={props.filter.selectedSitemapNodes}
      />
    </SelectorColumn>
    {!props.hideVariantSpecificInfo && (
      <>
        <SelectorColumn>
          <WorkflowFilterSelector
            filter={props.filter}
            onWorkflowsSelectionChanged={props.onWorkflowsSelectionChanged}
          />
        </SelectorColumn>
        <SelectorColumn>
          <ContributorsFilterSelector
            currentUserId={props.user.info.userId}
            onContributorsSelectionChanged={props.onContributorsSelectionChanged}
            selectedContributors={props.filter.selectedContributorsNodes}
          />
        </SelectorColumn>
        <SelectorColumn>
          <ItemsTaxonomyFilterSelector
            displayWarningStatus={props.displayTaxonomyWarningStatus}
            onTaxonomySelectionChanged={props.onTaxonomySelectionChanged}
            selectedTaxonomyNodes={props.filter.selectedTaxonomyNodes}
          />
        </SelectorColumn>
        <SelectorColumn>
          <ContentItemStatusSelector
            isAiSearchUsed={props.isAiSearchUsed}
            onContentItemStatusSelectionChanged={props.onContentItemStatusSelectionChanged}
            selectedContentItemStatus={props.filter.selectedContentItemStatus}
            selectedLanguageId={props.languageId}
            user={props.user}
          />
        </SelectorColumn>
        <SelectorColumn>
          <PublishingStatusFilterSelector
            key="publishing-status"
            onPublishingStatusSelectionChanged={props.onPublishingStatusSelectionChanged}
            selectedPublishingStateNodes={props.filter.selectedPublishingStateNodes}
          />
        </SelectorColumn>
      </>
    )}
  </Row>
);

const SelectorColumn = ({ children }: PropsWithChildren) => (
  <Column css="&:empty { display: none;}" width="1/3">
    {children}
  </Column>
);
