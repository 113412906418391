import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import {
  AnchorButton,
  Button,
  ButtonStyle,
  IconButton,
} from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import React from 'react';
import { trackUserEvent } from '../../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { isWebSpotlightEnabled } from '../../../../../../webSpotlight/selectors/webSpotlightSelectors.ts';
import { ariaLabel, label } from '../constants/uiConstants.ts';
import { useWebSpotlightInItemEditing } from '../context/WebSpotlightInItemEditingContext.tsx';

type OldOpenInNewTabButtonProps = Readonly<{
  buttonStyle: ButtonStyle;
  isCompact?: boolean;
  spaceId: Uuid | null;
}>;

export const OldOpenInNewTabButton: React.FC<OldOpenInNewTabButtonProps> = ({
  buttonStyle,
  isCompact,
  spaceId,
}) => {
  const dispatch = useDispatch();
  const isWebSpotlightActive = useSelector(isWebSpotlightEnabled);
  const {
    previewMetadata: { url },
  } = useWebSpotlightInItemEditing();

  const trackAction = () => {
    dispatch(
      trackUserEvent(TrackedEvent.SpacePreviewOpened, {
        'space-id': spaceId ?? '',
        'is-web-spotlight-enabled': isWebSpotlightActive,
      }),
    );
  };

  if (!url) {
    return null;
  }

  if (isCompact) {
    // This is not exactly ok, but we don't have AnchorIconButtons
    return (
      <Tooltip text={label} placement="bottom-end">
        <OutwardLink
          aria-label={ariaLabel}
          css={`
            display: inline-flex;

            &:focus-visible {
              border-radius: 50%;
            }
          `}
          href={url}
          onClick={trackAction}
        >
          <IconButton
            aria-label={ariaLabel}
            buttonStyle={buttonStyle}
            disableTabulator
            iconName="ArrowRightTopSquare"
            size="medium"
            tooltipPlacement="bottom-end"
            tooltipText=""
            {...getDataUiActionAttribute(DataUiAction.OpenInNewTab)}
          />
        </OutwardLink>
      </Tooltip>
    );
  }

  return (
    <AnchorButton
      aria-label={ariaLabel}
      buttonStyle={buttonStyle}
      href={url}
      onPress={trackAction}
      {...getDataUiActionAttribute(DataUiAction.OpenInNewTab)}
    >
      <Button.Icon icon={Icons.ArrowRightTopSquare} />
      <Button.Label>{label}</Button.Label>
    </AnchorButton>
  );
};
