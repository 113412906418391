import { Box } from '@kontent-ai/component-library/Box';
import { Icons } from '@kontent-ai/component-library/Icons';
import { ItemId } from '@kontent-ai/component-library/Selects';
import { SingleSelect } from '@kontent-ai/component-library/SingleSelect';
import { gridUnit } from '@kontent-ai/component-library/tokens';
import { IWebSpotlightPreviewScaleOption } from '../../../../../../../../webSpotlight/models/webSpotlightPreviewScale.ts';

const scaleInputMaxWidth = gridUnit * 16;

type Props = Readonly<{
  onChange: (scaleId: ItemId) => void;
  options: ReadonlyArray<IWebSpotlightPreviewScaleOption>;
  selectedOptionId: string;
}>;

export const ScaleSelector = ({ onChange, options, selectedOptionId }: Props) => {
  return (
    <Box maxWidth={scaleInputMaxWidth}>
      <SingleSelect
        aria-label="Scale"
        items={options}
        onSelectionChange={onChange}
        renderMenuOption={(optionProps) => (optionProps.item.value?.hidden ? '' : undefined)}
        renderPrefix={() => <Icons.DocArrows />}
        selectedItemId={selectedOptionId}
      />
    </Box>
  );
};
