import { AvatarSize } from '@kontent-ai/component-library/Avatar';
import { Box } from '@kontent-ai/component-library/Box';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import React from 'react';
import { UserAvatar } from '../../../../_shared/components/users/UserAvatar.tsx';
import { formatUserName } from '../../../../_shared/utils/users/usersUtils.ts';
import { IProjectContributor } from '../../../../data/models/users/ProjectContributor.ts';

type AvatarOverlapProps = {
  readonly overlappedUserNames: string;
  readonly overlapCount: number;
};

const AvatarOverlap: React.FC<AvatarOverlapProps> = ({ overlappedUserNames, overlapCount }) => (
  <Tooltip text={overlappedUserNames} placement="bottom">
    <div className="avatar-chain__overlap">{`+${overlapCount}`}</div>
  </Tooltip>
);

type ScrollTableItemContributorsProps = {
  readonly contributors: ReadonlyArray<IProjectContributor>;
};

const MaxDisplayedContributorsCount = 3;

export const ScrollTableItemContributors: React.FC<ScrollTableItemContributorsProps> = ({
  contributors,
}) => {
  const displayedUsers = contributors.slice(0, MaxDisplayedContributorsCount);
  const overlapCount = contributors.length - MaxDisplayedContributorsCount;
  const overlappedUserNames = contributors
    .slice(MaxDisplayedContributorsCount, contributors.length)
    .map(formatUserName)
    .join('\n');

  return (
    <Box display="flex">
      {displayedUsers.map((user) => (
        <div className="avatar-chain__avatar" key={user.userId}>
          <UserAvatar user={user} size={AvatarSize.S} />
        </div>
      ))}
      {overlapCount > 0 && (
        <AvatarOverlap overlapCount={overlapCount} overlappedUserNames={overlappedUserNames} />
      )}
    </Box>
  );
};
