import {
  colorBorderAlert,
  colorBorderDefault,
  colorDiffNegativeBorder,
  colorDiffPositiveBorder,
  px,
  transition250,
} from '@kontent-ai/component-library/tokens';
import styled, { css } from 'styled-components';
import { IBaseAssetTileProps } from '../BaseAssetTile.tsx';
import {
  assetTileAlertBorderWidth,
  assetTileBorderRadius,
  assetTileHeight,
  assetTileReadonlyBorderWidth,
  assetTileWidth,
} from '../decisionTokens.ts';
import { AssetTileReadonlyState } from '../enums/assetTileReadonlyStateEnum.ts';

interface IAssetTileFocusWrapperProps
  extends Pick<IBaseAssetTileProps, 'readonlyState' | 'isAlert' | 'isDragging'> {}

const getReadonlyBorderColor = (readonlyState: AssetTileReadonlyState) => {
  switch (readonlyState) {
    case AssetTileReadonlyState.Default:
      return colorBorderDefault;
    case AssetTileReadonlyState.Positive:
      return colorDiffPositiveBorder;
    case AssetTileReadonlyState.Negative:
      return colorDiffNegativeBorder;
  }
};

export const StyledBorders = styled.div<IAssetTileFocusWrapperProps>`
  border-radius: ${assetTileBorderRadius};
  background-color: inherit;
  position: relative;
  width: ${px(assetTileWidth)};
  height: ${px(assetTileHeight)};
  cursor: pointer;
  transition: opacity ${transition250};
  
  ${({ readonlyState }) =>
    readonlyState &&
    css`
    box-shadow: 0 0 0 ${px(assetTileReadonlyBorderWidth)} ${getReadonlyBorderColor(readonlyState)};
  `};
  
  ${({ isAlert }) =>
    isAlert &&
    css`
    box-shadow: 0 0 0 ${px(assetTileAlertBorderWidth)} ${colorBorderAlert};
  `};
  
  ${({ isDragging }) =>
    isDragging &&
    css`
    opacity: 0.7;
  `};
`;
