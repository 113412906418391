import { IStore } from '../../../_shared/stores/IStore.type.ts';

export const isUrlPatternSetForEditedContentItemSelector = (state: IStore): boolean => {
  const contentTypeId = state.contentApp.editedContentItem?.editedContentItemTypeId;

  return (
    !!contentTypeId &&
    !!state.contentApp.previewConfiguration?.previewUrlPatterns.has(contentTypeId)
  );
};
