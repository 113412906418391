import { ComponentProps } from 'react';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { ContentTypeEditorPane as ContentTypeEditorPaneComponent } from '../components/ContentTypeEditorPane.tsx';
import { getEditedContentType } from '../selectors/getEditedContentType.ts';

type Props = Pick<
  ComponentProps<typeof ContentTypeEditorPaneComponent>,
  'onSaveContentType' | 'showContentTypeUsedWithPublishedItemWarning' | 'focusTypeName'
>;

export const ContentTypeEditorPane = ({
  onSaveContentType,
  showContentTypeUsedWithPublishedItemWarning,
  focusTypeName,
}: Props) => {
  const editedContentTypeName = useSelector((s) => getEditedContentType(s).name);
  const hasActiveGroupWithElements = useSelector((state) => {
    const editedType = getEditedContentType(state);
    const { selectedContentGroupId } = state.contentModelsApp.contentTypes.editor;
    return (
      !!selectedContentGroupId &&
      !!editedType.typeElements.find(
        (typeElement) => typeElement.contentGroupId === selectedContentGroupId,
      )
    );
  });
  const isBeingSaved = useSelector((state) => state.contentModelsApp.typeEditor.typeIsBeingSaved);

  return (
    <ContentTypeEditorPaneComponent
      editedContentTypeName={editedContentTypeName}
      hasActiveGroupWithElements={hasActiveGroupWithElements}
      isBeingSaved={isBeingSaved}
      onSaveContentType={onSaveContentType}
      showContentTypeUsedWithPublishedItemWarning={showContentTypeUsedWithPublishedItemWarning}
      focusTypeName={focusTypeName}
    />
  );
};
