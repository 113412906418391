import { Collection } from '@kontent-ai/utils';
import {
  ElementTranslationStatus,
  VariantTranslationStatus,
} from '../../../../../_shared/utils/translations/variantTranslationStatus.ts';

export const getTranslatedElementIds = (
  translationStatusPerElement: ReadonlyMap<string, ElementTranslationStatus> | null,
) =>
  translationStatusPerElement
    ? Collection.getEntries(translationStatusPerElement)
        .filter(([, status]) => status === ElementTranslationStatus.Succeeded)
        .map(([id]) => id)
    : [];

export const areAllTranslatedElementsLoaded = (
  translationStatusPerElement: ReadonlyMap<string, ElementTranslationStatus> | null,
  pendingRefreshElementIds: ReadonlyArray<Uuid>,
) =>
  getTranslatedElementIds(translationStatusPerElement).every((elementId) =>
    pendingRefreshElementIds.includes(elementId),
  );

export const isTranslationCompletedWithSuccess = (
  previousStatus: VariantTranslationStatus | null,
  translationStatus: VariantTranslationStatus | null,
) => {
  if (previousStatus !== VariantTranslationStatus.InProgress) {
    return false;
  }

  return (
    translationStatus === VariantTranslationStatus.Completed ||
    translationStatus === VariantTranslationStatus.PartiallyFailed
  );
};
