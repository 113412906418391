import { ComponentProps } from 'react';
import { CreateNewVersion } from '../../../../../../_shared/constants/itemActions.ts';
import {
  DefaultCollectionId,
  DefaultLanguageId,
} from '../../../../../../_shared/constants/variantIdValues.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { ActiveCapabilityType } from '../../../../../../_shared/models/activeCapability.type.ts';
import { getCurrentUserId } from '../../../../../../_shared/selectors/getCurrentUser.ts';
import { getEditedContentItem } from '../../../../../../_shared/selectors/getEditedContentItem.ts';
import { getSelectedLanguageId } from '../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import {
  isArchivedWorkflowStepSelected,
  isDisabled,
  isPublishingStepSelected,
} from '../../../../../../_shared/utils/contentItemUtils.ts';
import { hasActiveVariantCapabilityForEditedItem } from '../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { getCurrentUserRoleForCollectionForLanguage } from '../../../../../../_shared/utils/permissions/getContributorRole.ts';
import { ICommentThreadItemContentModel } from '../../../../models/comments/CommentThreadItem.ts';
import { isSuggestion } from '../../../../models/comments/Suggestion.ts';
import { getTypeElement } from '../../../../stores/utils/contentItemElementsUtils.ts';
import { isThreadInline, isThreadResolvedWithoutUndo } from '../../../../utils/commentUtils.ts';
import { commentThreadItemEditingCanceled } from '../../actions/contentItemEditingActions.ts';
import {
  approveSuggestion,
  resolveCommentThread,
  updateCommentThreadItem,
} from '../../actions/thunkContentItemEditingActions.ts';
import { CommentThreadItem as CommentThreadItemComponent } from '../../components/comments/threadItem/CommentThreadItem.tsx';
import { getCommentThreadTopLevelElementId } from '../../utils/getCommentThreadTopLevelElementId.ts';

type CommentThreadItemContainerProps = Pick<
  ComponentProps<typeof CommentThreadItemComponent>,
  'className' | 'commentThreadItem' | 'commentThread' | 'isInlineThreadWithRemovedContent'
> & {
  readonly allowCopyLink: boolean;
  readonly allowResolve?: boolean;
  readonly isThreadRoot: boolean;
  readonly showReferenceForInlineThreads: boolean;
  readonly isLastApprovedSuggestion?: boolean;
};

const getApproveInfo = (
  canApprove: boolean,
  roleName: string,
  isInArchivingStep: boolean,
  isInPublishingStep: boolean,
  isCommentThreadResolved: boolean,
  isInlineThreadWithRemovedContent: boolean | undefined,
): string => {
  if (!canApprove) {
    return `As a ${roleName} you cannot approve suggestions`;
  }

  if (isInlineThreadWithRemovedContent) {
    return 'The content related to this suggestion has been removed.';
  }

  if (isCommentThreadResolved) {
    return 'Reopen comment thread to approve the suggestion';
  }

  if (isInPublishingStep) {
    return `${CreateNewVersion} of this item to approve the suggestion`;
  }

  if (isInArchivingStep) {
    return 'Restore the item to approve the suggestion';
  }

  return 'Approve the suggestion to replace the highlighted text';
};

export const CommentThreadItem = (props: CommentThreadItemContainerProps) => {
  const {
    allowCopyLink,
    allowResolve,
    commentThread,
    commentThreadItem,
    isInlineThreadWithRemovedContent,
    isThreadRoot,
    showReferenceForInlineThreads,
  } = props;

  const currentUserId = useSelector(getCurrentUserId);

  const isCommentThreadResolved = isThreadResolvedWithoutUndo(commentThread);
  const showLocation = showReferenceForInlineThreads && isThreadInline(commentThread);

  const element = useSelector(
    (state) =>
      showLocation &&
      getTypeElement(state.contentApp.loadedContentItemTypes, commentThread.elementId),
  );

  const createdBy = useSelector(
    (state) => state.data.users.usersById.get(commentThreadItem.createdBy) ?? null,
  );

  const suggestionApprovedByUserId =
    isSuggestion(commentThreadItem) && commentThreadItem.suggestionApprovedBy;
  const suggestionApprovedBy = useSelector(
    (state) => state.data.users.usersById.get(suggestionApprovedByUserId || '') ?? null,
  );

  const disabled = useSelector((state) =>
    isDisabled(false, state.contentApp.editedContentItemVariant),
  );
  const canUpdateContent = useSelector((state) =>
    hasActiveVariantCapabilityForEditedItem(ActiveCapabilityType.UpdateContent, state),
  );

  const topLevelElementId = useSelector((state) =>
    getCommentThreadTopLevelElementId(
      getEditedContentItem(state),
      state.contentApp.editedContentItemVariantElements,
      commentThread,
    ),
  );
  const isTopElementLocked = useSelector(
    (state) =>
      !!state.contentApp.editorUi.lockedElements.find(
        (lockedElement) =>
          !!lockedElement &&
          lockedElement.elementId === topLevelElementId &&
          lockedElement.userId !== currentUserId,
      ),
  );
  const isTopElementOutdated = useSelector(
    (state) =>
      !!topLevelElementId &&
      state.contentApp.editorUi.outdatedElementIds.contains(topLevelElementId),
  );

  const canApproveSuggestion =
    !disabled &&
    !isTopElementLocked &&
    !isTopElementOutdated &&
    !isCommentThreadResolved &&
    isSuggestion(commentThreadItem) &&
    canUpdateContent &&
    !isInlineThreadWithRemovedContent;

  const isInArchivingStep = useSelector((state) =>
    isArchivedWorkflowStepSelected(state.contentApp.editedContentItemVariant?.assignment),
  );
  const isInPublishingStep = useSelector((state) =>
    isPublishingStepSelected(state.contentApp.editedContentItemVariant?.assignment),
  );
  const roleName = useSelector(
    (state) =>
      getCurrentUserRoleForCollectionForLanguage(
        state,
        getEditedContentItem(state)?.collectionId ?? DefaultCollectionId,
        getSelectedLanguageId(state) ?? DefaultLanguageId,
      )?.name,
  );
  const approveInfo = getApproveInfo(
    canUpdateContent,
    roleName ?? '',
    isInArchivingStep,
    isInPublishingStep,
    isCommentThreadResolved,
    isInlineThreadWithRemovedContent,
  );
  const shouldDisplaySegmentInfo =
    isThreadRoot &&
    (isThreadResolvedWithoutUndo(commentThread) || !!isInlineThreadWithRemovedContent);

  const dispatch = useDispatch();
  const onSubmitEdit = (content: ICommentThreadItemContentModel) =>
    dispatch(updateCommentThreadItem(commentThread.id, commentThreadItem.id, content));
  const onCancelEdit = () =>
    dispatch(commentThreadItemEditingCanceled(commentThread.id, commentThreadItem.id));
  const onApproveSuggestion = () => dispatch(approveSuggestion(commentThread, commentThreadItem));
  const onResolveThreadAfterSuggestionWasApproved = () =>
    dispatch(resolveCommentThread(commentThread.id, false, false));
  const onResolve = () => dispatch(resolveCommentThread(commentThread.id, true));

  return (
    <CommentThreadItemComponent
      {...props}
      allowEdit={currentUserId === commentThreadItem.createdBy && !isCommentThreadResolved}
      allowResolve={(allowResolve ?? true) && isThreadRoot && !isCommentThreadResolved}
      allowCopyLink={allowCopyLink}
      approveInfo={approveInfo}
      canApproveSuggestion={canApproveSuggestion}
      commentThreadType={commentThread.threadType}
      createdBy={createdBy}
      elementName={element ? element.name : null}
      elementSegment={commentThread.elementSegment}
      onApproveSuggestion={onApproveSuggestion}
      onCancelEdit={onCancelEdit}
      onResolve={onResolve}
      onResolveAfterSuggestionWasApproved={onResolveThreadAfterSuggestionWasApproved}
      onSubmitEdit={onSubmitEdit}
      shouldDisplaySegmentInfo={shouldDisplaySegmentInfo}
      suggestionApprovedBy={suggestionApprovedBy}
    />
  );
};
