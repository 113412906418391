import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Menu } from '@kontent-ai/component-library/Menu';
import { IconSize } from '@kontent-ai/component-library/tokens';
import { RefObject } from 'react';
import { getDataAttribute } from '../../../../../../_shared/utils/dataAttributes/DataAttributes.ts';
import {
  DataUiCollection,
  DataUiCommentsAction,
  getDataUiCollectionAttribute,
  getDataUiCommentActionAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ElementAttributes } from '../../../../constants/elementAttributes.ts';

type Props = {
  readonly commentLink?: string;
  readonly onEdit?: () => void;
  readonly onLinkCopied?: () => void;
  readonly onReopen?: () => void;
  readonly onResolve?: () => void;
};

export const CommentContextMenu = ({
  commentLink,
  onEdit,
  onLinkCopied,
  onReopen,
  onResolve,
}: Props) => {
  if (!commentLink && !onResolve && !onEdit) {
    return null;
  }

  return (
    <Menu>
      <Menu.Trigger>
        {(ref: RefObject<HTMLButtonElement>, triggerProps, isOpen) => (
          <QuinaryButton
            ref={ref}
            activated={isOpen}
            tooltipText="More actions"
            {...triggerProps}
            {...getDataUiCommentActionAttribute(DataUiCommentsAction.OpenCommentMenu)}
          >
            <QuinaryButton.Icon icon={Icons.Ellipsis} screenReaderText="More actions" />
          </QuinaryButton>
        )}
      </Menu.Trigger>
      <Menu.List
        isNonModal
        {...getDataUiCollectionAttribute(DataUiCollection.CommentMenuItems)}
        // We need to prevent comment blur upon clicking on its context menu (outside the comment)
        // to make sure the active comment in floating editor doesn't hide upon this action
        {...getDataAttribute(ElementAttributes.BlurCommentThreadOnClick, 'false')}
      >
        {commentLink && (
          <Menu.ClipboardItem
            id="link"
            label="Copy link"
            value={commentLink}
            onCopySuccess={onLinkCopied}
            {...getDataUiCommentActionAttribute(DataUiCommentsAction.GetLink)}
          />
        )}
        {onResolve && (
          <Menu.Item
            id="resolve"
            leadingElement={<Icons.CheckCircle size={IconSize.S} />}
            label="Resolve"
            onAction={onResolve}
            {...getDataUiCommentActionAttribute(DataUiCommentsAction.MarkAsResolved)}
          />
        )}
        {onEdit && (
          <Menu.Item
            id="edit"
            leadingElement={<Icons.Edit size={IconSize.S} />}
            label="Edit"
            onAction={onEdit}
            {...getDataUiCommentActionAttribute(DataUiCommentsAction.Edit)}
          />
        )}
        {onReopen && (
          <Menu.Item
            id="reopen"
            leadingElement={<Icons.RotateDoubleRight size={IconSize.S} />}
            label="Reopen"
            onAction={onReopen}
            {...getDataUiCommentActionAttribute(DataUiCommentsAction.MarkAsUnresolved)}
          />
        )}
      </Menu.List>
    </Menu>
  );
};
