export const AssetCollectionMandatory = 'AssetCollectionMandatory';
export const LegacyWebhooks = 'LegacyWebhooks';
export const SendFinalReport = 'SendFinalReport';
export const AreAutomaticPopupsDisabled = 'AreAutomaticPopupsDisabled';
export const IsProjectDataRestrictedToSpecificDatacenter =
  'IsProjectDataRestrictedToSpecificDatacenter';

export type SubscriptionPropertiesKnownServerKeys =
  | typeof AssetCollectionMandatory
  | typeof LegacyWebhooks
  | typeof SendFinalReport
  | typeof AreAutomaticPopupsDisabled
  | typeof IsProjectDataRestrictedToSpecificDatacenter;
