import Immutable from 'immutable';
import React from 'react';
import { DragMoveHandler } from '../../../../../../_shared/components/DragDrop/dragDrop.type.ts';
import { DefaultVariantId } from '../../../../../../_shared/constants/variantIdValues.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { isProjectMultilingual } from '../../../../../../_shared/selectors/isProjectMultilingual.ts';
import { moveItem } from '../../../../../../_shared/utils/dragDrop/dragDropUtils.ts';
import { hasCapabilityInLanguage } from '../../../../../../_shared/utils/permissions/capabilitiesInLanguageUtils.ts';
import { Capability } from '../../../../../../_shared/utils/permissions/capability.ts';
import { IContentType } from '../../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { loadNewLinkedItemsDefaultValueReferences } from '../../../actions/thunkSharedContentModelsActions.ts';
import { LinkedItemsDefaultValue as LinkedItemsDefaultValueComponent } from '../../../components/typeElements/individualTypeElements/linkedItems/LinkedItemsDefaultValue.tsx';
import {
  linkedItemsDefaultValueDescription,
  linkedItemsDefaultValueDescriptionForMultilingualProjects,
} from '../../../constants/uiConstants.ts';
import { ILinkedItemsTypeElementData } from '../../../models/elements/LinkedItemsTypeElementData.ts';

type Props = {
  readonly allowedContentTypes: Immutable.Set<IContentType>;
  readonly isDisabled: boolean;
  readonly onChange: (linkedItemIds: ReadonlyArray<Uuid>) => void;
  readonly typeElementData: ILinkedItemsTypeElementData;
};

export const LinkedItemsDefaultValue: React.FC<Props> = ({
  allowedContentTypes,
  isDisabled,
  onChange,
  typeElementData,
}) => {
  const dispatch = useDispatch();

  const canAddExistingItems = useSelector((s) =>
    hasCapabilityInLanguage(s, Capability.ViewContent, DefaultVariantId),
  );
  const description = useSelector(isProjectMultilingual)
    ? linkedItemsDefaultValueDescriptionForMultilingualProjects
    : linkedItemsDefaultValueDescription;

  const onUpdateDefaultValue = (addedItemIds: ReadonlyArray<Uuid>) => {
    const updatedItemIds = typeElementData.defaultValue.concat(addedItemIds);
    dispatch(loadNewLinkedItemsDefaultValueReferences(addedItemIds));
    onChange(updatedItemIds);
  };

  const onRemoveDefaultValue = (removedItemId: Uuid) => {
    const updatedItemIds = typeElementData.defaultValue.filter((id) => id !== removedItemId);
    onChange(updatedItemIds);
  };

  const onMoveDefaultValue: DragMoveHandler = ({ sourceId, targetId }) => {
    const updatedItemIds = moveItem(
      typeElementData.defaultValue,
      sourceId,
      targetId,
      (elementId) => elementId,
    );
    onChange(updatedItemIds);
  };

  return (
    <LinkedItemsDefaultValueComponent
      allowedContentTypes={allowedContentTypes}
      canAddExistingItems={canAddExistingItems}
      description={description}
      isDisabled={isDisabled}
      onMoveDefaultValue={onMoveDefaultValue}
      onRemoveDefaultValue={onRemoveDefaultValue}
      onUpdateDefaultValue={onUpdateDefaultValue}
      typeElementData={typeElementData}
    />
  );
};

LinkedItemsDefaultValue.displayName = 'LinkedItemsDefaultValue';
