import { Input, InputState, InputType } from '@kontent-ai/component-library/Input';
import React, { memo } from 'react';
import {
  DataUiInput,
  getDataUiInputAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { INumberTypeElementData } from '../../../models/elements/NumberTypeElementData.ts';
import { ITypeElementOwnProps } from '../../../types/ITypeElementProps.type.ts';
import { NumberTypeElementValidationResult } from '../../../utils/typeElementValidators/types/NumberTypeElementValidationResult.type.ts';
import { TypeElementConfigurationCategory } from '../shared/TypeElementConfigurationCategory.tsx';
import { TypeElementConfigurationSection } from '../shared/TypeElementConfigurationSection.tsx';
import { TypeElementWithTypedName } from '../shared/TypeElementWithTypedName.tsx';

type NumberTypeElementProps = ITypeElementOwnProps<
  INumberTypeElementData,
  NumberTypeElementValidationResult
>;

const NumberTypeElement: React.FC<NumberTypeElementProps> = (props) => {
  return (
    <TypeElementWithTypedName {...props}>
      <TypeElementConfigurationCategory>
        <TypeElementConfigurationSection title="Default value">
          <Input
            {...getDataUiInputAttribute(DataUiInput.Number)}
            inputState={
              props.validationResult.isDefaultValueValid ? InputState.Default : InputState.Alert
            }
            onInput={(e) =>
              props.onChange({
                ...props.typeElementData,
                defaultValue: {
                  isValid: e.currentTarget.validity.valid,
                  value: e.currentTarget.value,
                },
              })
            }
            placeholder="Set default value for this element"
            step="any"
            type={InputType.Number}
            value={props.typeElementData.defaultValue.value}
          />
        </TypeElementConfigurationSection>
      </TypeElementConfigurationCategory>
    </TypeElementWithTypedName>
  );
};

NumberTypeElement.displayName = 'NumberTypeElement';

const NumberTypeElementMemo = memo(NumberTypeElement);
export { NumberTypeElementMemo as NumberTypeElement };
