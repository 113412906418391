import { isAbortError } from '@kontent-ai/errors';
import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { IAiGuidelinesRepository } from '../../../../repositories/IAiGuidelinesRepository.type.ts';
import {
  IAiGuidelinesRequestServerModel,
  IAiGuidelinesResponseServerModel,
} from '../../../../repositories/serverModels/AiGuidelinesServerModels.type.ts';
import {
  AiGuidelines_Creating_Failed,
  AiGuidelines_Creating_Finished,
  AiGuidelines_Creating_Started,
} from '../../constants/aiGuidelinesActionTypes.ts';
import { AiGuidelinesFormShape } from '../../types/AiGuidelinesFormShape.type.ts';

const started = () =>
  ({
    type: AiGuidelines_Creating_Started,
  }) as const;

const failed = () =>
  ({
    type: AiGuidelines_Creating_Failed,
  }) as const;

const success = (aiGuidelines: IAiGuidelinesResponseServerModel) =>
  ({
    type: AiGuidelines_Creating_Finished,
    payload: { aiGuidelines },
  }) as const;

interface IDeps {
  readonly aiGuidelinesRepository: Pick<IAiGuidelinesRepository, 'createAiGuidelines'>;
}

export type CreateAiGuidelinesActionsType = ReturnType<
  typeof started | typeof failed | typeof success
>;

export const createCreateAiGuidelinesAction =
  ({ aiGuidelinesRepository }: IDeps) =>
  (aiGuidelinesFormShape: AiGuidelinesFormShape, abortSignal?: AbortSignal): ThunkPromise<Uuid> =>
  async (dispatch): Promise<Uuid> => {
    try {
      dispatch(started());

      const aiGuidelinesRequestServerModel: IAiGuidelinesRequestServerModel = {
        name: aiGuidelinesFormShape.name,
        guidelines: aiGuidelinesFormShape.guidelines,
      };

      const aiGuidelinesResponseServerModel = await aiGuidelinesRepository.createAiGuidelines(
        aiGuidelinesRequestServerModel,
        abortSignal,
      );

      dispatch(success(aiGuidelinesResponseServerModel));
      return aiGuidelinesResponseServerModel.id;
    } catch (error) {
      if (!isAbortError(error)) {
        dispatch(failed());
      }

      throw error;
    }
  };
