import { Icons } from '@kontent-ai/component-library/Icons';
import {
  SimpleStatusDefault,
  SimpleStatusSuccess,
} from '@kontent-ai/component-library/SimpleStatus';

type Props = {
  readonly isEnabled: boolean;
};

export const FeatureStatus = ({ isEnabled }: Props) =>
  isEnabled ? (
    <SimpleStatusSuccess
      label="Active"
      icon={Icons.CbCheckPreview}
      tooltipText="This feature is active for you and other users in this project."
    />
  ) : (
    <SimpleStatusDefault
      label="Not active"
      icon={Icons.ICircle}
      tooltipText="This feature is currently inactive and can’t be used by you or other users in this project."
    />
  );
