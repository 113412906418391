import {
  CompiledTypeElementType,
  ElementType,
} from '../../contentInventory/content/models/ContentItemElementType.ts';
import { assetItemElementCommentManager } from './itemElementCommentManagers/assetItemElementCommentManager.ts';
import { getBaseTextItemElementCommentManager } from './itemElementCommentManagers/baseTextItemElementCommentManager.ts';
import { linkedItemCommentManager } from './itemElementCommentManagers/linkedItemElementCommentManager.ts';
import { simpleItemElementCommentManager } from './itemElementCommentManagers/simpleItemElementCommentManager.ts';
import { IItemElementCommentManager } from './itemElementCommentManagers/types/IItemElementCommentManager.type.ts';

export interface IItemElementCommentManagerDependencies {
  getItemElementCommentManager: (type: CompiledTypeElementType) => IItemElementCommentManager;
}

const baseTextItemElementCommentManager = getBaseTextItemElementCommentManager({
  getItemElementCommentManager,
});

const noCommentsManager: IItemElementCommentManager = {
  getCommentTriggerCssSelector: () => null,
  getCommentThreadReferences: () => [],
};

const managersMap: ReadonlyRecord<CompiledTypeElementType, IItemElementCommentManager> = {
  [ElementType.Asset]: assetItemElementCommentManager,
  [ElementType.Custom]: simpleItemElementCommentManager,
  [ElementType.DateTime]: simpleItemElementCommentManager,
  [ElementType.Guidelines]: noCommentsManager,
  [ElementType.LinkedItems]: linkedItemCommentManager,
  [ElementType.MultipleChoice]: simpleItemElementCommentManager,
  [ElementType.Number]: simpleItemElementCommentManager,
  [ElementType.RichText]: baseTextItemElementCommentManager,
  [ElementType.Subpages]: linkedItemCommentManager,
  [ElementType.Taxonomy]: simpleItemElementCommentManager,
  [ElementType.Text]: baseTextItemElementCommentManager,
  [ElementType.UrlSlug]: baseTextItemElementCommentManager,
};

export function getItemElementCommentManager(
  type: CompiledTypeElementType,
): IItemElementCommentManager {
  return managersMap[type];
}
