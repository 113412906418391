import { memoize } from '@kontent-ai/memoization';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { getTypeElementsInContentGroup } from '../utils/typeElementsUtils.ts';

export const memoizeLastElementIds = memoize.maxNWithTransformedArgs(
  (elementIds: ReadonlyArray<Uuid>): ReadonlyArray<Uuid> => elementIds,
  (args) => args[0],
  1,
);

export const getElementIds = (state: IStore): ReadonlyArray<Uuid> => {
  const {
    typeEditor: { editedType },
    contentTypes: {
      editor: { selectedContentGroupId },
    },
  } = state.contentModelsApp;
  const contentTypeElementsInGroup = getTypeElementsInContentGroup(
    editedType.typeElements,
    selectedContentGroupId,
  );

  return memoizeLastElementIds(contentTypeElementsInGroup.map((type) => type.elementId));
};
