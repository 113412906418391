import { InvariantException } from '@kontent-ai/errors';
import { useHistory, useLocation } from 'react-router';
import {
  EnvironmentRoute,
  EnvironmentRouteParams,
  PreviewURLsRoute,
} from '../../../../../../../../_shared/constants/routePaths.ts';
import {
  buildPath,
  matchPath,
} from '../../../../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { OpenSpaceDomainSettingsButton as OpenSpaceDomainSettingsButtonComponent } from '../../components/previewEmptyStates/OpenSpaceDomainSettingsButton.tsx';

export const OpenSpaceDomainSettingsButton = () => {
  const location = useLocation();
  const history = useHistory();

  const matchParams = matchPath<EnvironmentRouteParams>(location.pathname, EnvironmentRoute);
  if (!matchParams) {
    throw InvariantException('Current route does not belong to ProjectRoute routes');
  }

  const settingsPath = buildPath<EnvironmentRouteParams>(PreviewURLsRoute, {
    projectId: matchParams.projectId,
  });

  const openSettings = () => history.push(settingsPath);

  return <OpenSpaceDomainSettingsButtonComponent onClick={openSettings} />;
};
