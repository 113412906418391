import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import {
  sharedEditorLeft,
  showErrorNotification,
} from '../../../../_shared/actions/sharedActions.ts';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { EditTaxonomyGroupRouteParams } from '../../../../_shared/constants/routePaths.ts';
import { HandleUnsavedFormOnNavigation } from '../../../../_shared/containers/HandleUnsavedFormOnNavigation.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import { TaxonomyTermEditorIsNotConfirmed } from '../../shared/constants/errorMessageTemplates.ts';
import { showCreateNewTermForm } from '../actions/taxonomyActions.ts';
import {
  initTaxonomyGroupEdit,
  updateEditedTaxonomyGroup,
} from '../actions/thunkTaxonomyActions.ts';
import { TaxonomyGroupEditor as TaxonomyGroupEditorComponent } from '../components/TaxonomyGroupEditor.tsx';
import { TaxonomyEditorStatus } from '../models/TaxonomyEditorStatus.ts';
import { isTaxonomyAppBeingSaved } from '../selectors/isTaxonomyAppBeingSaved.ts';
import { isTaxonomyAppLoading } from '../selectors/isTaxonomyAppLoading.ts';
import { isTaxonomyTermEditorClosed } from '../selectors/isTaxonomyTermEditorClosed.ts';
import { hasAnyTerms } from '../utils/taxonomyUtils.ts';

export const TaxonomyGroupEditor = () => {
  const history = useHistory();
  const { taxonomyGroupId } = useParams<EditTaxonomyGroupRouteParams>();
  const [isInitThunkDone] = useThunkPromise(initTaxonomyGroupEdit, taxonomyGroupId, history);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(sharedEditorLeft());
    };
  }, []);

  const onCreateNewButtonClicked = () => {
    dispatch(showCreateNewTermForm());
  };

  const hasUnsavedChanges = useSelector(
    (state) =>
      state.taxonomyApp.status === TaxonomyEditorStatus.Modified ||
      state.taxonomyApp.isCreateNewFormDisplayed ||
      !!state.taxonomyApp.editedTaxonomyTermId,
  );
  const isBeingSaved = useSelector(isTaxonomyAppBeingSaved);
  const isLoading = useSelector(isTaxonomyAppLoading) || !isInitThunkDone;
  const isUsedInPublishedContent = useSelector(
    (state) => state.taxonomyApp.editedTaxonomyGroupIsUsedInPublishedItems,
  );
  const showEmptyStatePage = useSelector(
    (state) =>
      !hasAnyTerms(state.taxonomyApp.editedTaxonomyGroup) &&
      !state.taxonomyApp.isCreateNewFormDisplayed,
  );
  const taxonomyGroupName = useSelector((state) => state.taxonomyApp.editedTaxonomyGroup.name);
  const isTermEditingFinished = useSelector(isTaxonomyTermEditorClosed);

  const saveTaxonomyGroup = async (): Promise<void> => {
    if (isTermEditingFinished) {
      await dispatch(updateEditedTaxonomyGroup());
    } else {
      dispatch(showErrorNotification(TaxonomyTermEditorIsNotConfirmed));
    }
  };

  const unsavedNavigationHandler = async (
    onSuccess: () => void,
    onFail: () => void,
  ): Promise<void> => {
    try {
      if (isTermEditingFinished) {
        await dispatch(updateEditedTaxonomyGroup());
        onSuccess();
      } else {
        dispatch(showErrorNotification(TaxonomyTermEditorIsNotConfirmed));
        onFail();
      }
    } catch {
      onFail();
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <HandleUnsavedFormOnNavigation
        hasUnsavedChanges={hasUnsavedChanges}
        isBeingSaved={isBeingSaved}
        onSaveChanges={unsavedNavigationHandler}
      />
      <TaxonomyGroupEditorComponent
        isUsedInPublishedContent={isUsedInPublishedContent}
        onCreateNewButtonClicked={onCreateNewButtonClicked}
        onSave={saveTaxonomyGroup}
        showEmptyStatePage={showEmptyStatePage}
        taxonomyGroupName={taxonomyGroupName}
      />
    </>
  );
};
