import { Collection } from '@kontent-ai/utils';
import useLocalStorageState from 'use-local-storage-state';
import { RefinedNavigationToggleId } from '../constants/RefinedNavigationToggleId.ts';
import { NavigationStateStorageKey } from '../constants/localStorageKeys.ts';

const toggleIdValues = Object.values(RefinedNavigationToggleId);

const filterOutNonExistentToggleIds = (toggleIds: ReadonlySet<RefinedNavigationToggleId>) =>
  Collection.filter(toggleIds, (toggleId) => toggleIdValues.includes(toggleId));

type NavigationState = Readonly<{
  expandedNavigationToggleIds: ReadonlySet<RefinedNavigationToggleId>;
  isNavigationBarExpanded: boolean;
}>;

export const useNavigationState = (): ReturnType<typeof useLocalStorageState<NavigationState>> => {
  return useLocalStorageState<NavigationState>(NavigationStateStorageKey, {
    defaultValue: {
      expandedNavigationToggleIds: new Set<RefinedNavigationToggleId>(),
      isNavigationBarExpanded: false,
    },
    serializer: {
      stringify: (value: NavigationState) =>
        JSON.stringify({
          ...value,
          expandedNavigationToggleIds: Array.from(value.expandedNavigationToggleIds),
        }),
      parse: (value) => {
        const parsedValue = JSON.parse(value);
        return {
          ...parsedValue,
          expandedNavigationToggleIds: filterOutNonExistentToggleIds(
            new Set(parsedValue.expandedNavigationToggleIds),
          ),
        };
      },
    },
  });
};
