import React, { useMemo } from 'react';
import {
  formatDateTimeToReadable,
  parseDatetime,
} from '../../../../../../../_shared/components/DatetimePicker/InternalFiles/datetimeUtils.ts';
import {
  ensureBrowserCompatibleTimeZone,
  fromUtcToZonedTime,
  getFormattedTimeZoneOffset,
} from '../../../../../../../_shared/utils/dateTime/timeZoneUtils.ts';
import { DateTimeItemElement } from '../../../../../models/contentItemElements/DateTimeItemElement.ts';
import { ensureTimeZoneId } from '../../../../../utils/itemElementDataConverters/dateTimeItemElementDataConverter.ts';
import { ReadonlyEmptyElementPlaceholder } from '../../../../ContentItemEditing/models/ReadonlyEmptyElementPlaceholder.ts';
import { useRenderPlaceholderText } from '../../../hooks/useRenderPlaceholderText.ts';
import { IRevisionDiffElementProps } from '../../../types/IRevisionDiffElementProps.type.ts';
import { SimpleValueDiff } from '../../SimpleValueDiff.tsx';

interface IDateTimeDiffProps extends IRevisionDiffElementProps<DateTimeItemElement> {}

export const DateTimeDiff: React.FC<IDateTimeDiffProps> = (props) => {
  const { elementData, originalElementData, typeElement } = props;
  const value = useReadableValue(elementData);
  const originalValue = useReadableValue(originalElementData ?? null);
  const placeholderText = useRenderPlaceholderText(
    ReadonlyEmptyElementPlaceholder.StringElement,
    originalElementData,
    typeElement,
  );

  return (
    <SimpleValueDiff
      value={value}
      originalValue={originalElementData ? originalValue : undefined}
      placeholderText={placeholderText ?? undefined}
    />
  );
};

DateTimeDiff.displayName = 'DateTimeDiff';

const useReadableValue = (elementData: DateTimeItemElement | null): string => {
  const { value: parsedValue } = parseDatetime(elementData?.value ?? null);
  const elementTimeZone = ensureTimeZoneId(
    elementData?.displayTimeZone ?? null,
    parsedValue?.toISOString() ?? null,
  );
  const ensuredBrowserCompatibleTimeZone = useMemo(
    () => ensureBrowserCompatibleTimeZone(elementTimeZone),
    [elementTimeZone],
  );

  return parsedValue && ensuredBrowserCompatibleTimeZone
    ? `${formatDateTimeToReadable(
        fromUtcToZonedTime(parsedValue, ensuredBrowserCompatibleTimeZone),
      )} - (UTC ${getFormattedTimeZoneOffset(
        parsedValue.toISOString(),
        ensuredBrowserCompatibleTimeZone,
      )}) - ${elementTimeZone}`
    : '';
};
