import { Button, IconButton, QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { useIsPreviewUrlOutdated } from '../../../../../../webSpotlight/hooks/useIsPreviewUrlOutdated.ts';
import { useWebSpotlightInItemEditing } from '../context/WebSpotlightInItemEditingContext.tsx';

const label = 'Refresh';
const ariaLabel = 'Refresh preview';

export const OldRefreshPreviewButton = ({ isCompact }: { readonly isCompact?: boolean }) => {
  const { refreshPreview } = useWebSpotlightInItemEditing();
  const isPreviewUrlOutdated = useIsPreviewUrlOutdated();

  const onClick = () => {
    refreshPreview({
      isManualRefresh: true,
      isPreviewUrlOutdated,
    });
  };

  return isCompact ? (
    <IconButton
      aria-label={ariaLabel}
      buttonStyle="secondary"
      iconName="RotateDoubleRight"
      onClick={onClick}
      size="medium"
      tooltipPlacement="bottom-end"
      tooltipText={label}
      {...getDataUiActionAttribute(DataUiAction.Refresh)}
    />
  ) : (
    <Button
      aria-label={ariaLabel}
      buttonStyle="secondary"
      onClick={onClick}
      {...getDataUiActionAttribute(DataUiAction.Refresh)}
    >
      <Button.Icon icon={Icons.RotateDoubleRight} />
      <Button.Label>{label}</Button.Label>
    </Button>
  );
};

export const RefreshPreviewButton = ({ isCompact }: { readonly isCompact?: boolean }) => {
  const { refreshPreview } = useWebSpotlightInItemEditing();
  const isPreviewUrlOutdated = useIsPreviewUrlOutdated();

  return (
    <QuinaryButton
      aria-label={ariaLabel}
      onClick={() => {
        refreshPreview({
          isManualRefresh: true,
          isPreviewUrlOutdated,
        });
      }}
      tooltipPlacement="top"
      tooltipText={label}
      {...getDataUiActionAttribute(DataUiAction.Refresh)}
    >
      <QuinaryButton.Icon icon={Icons.RotateDoubleRight} />
      {!isCompact && label}
    </QuinaryButton>
  );
};
