import React, { memo, useCallback } from 'react';
import { BarItemAnimation } from '../../../../_shared/components/BarItems/BarItemAnimation.tsx';
import { BarItemChildList } from '../../../../_shared/components/BarItems/BarItemChildList.tsx';
import { DropTarget } from '../../../../_shared/components/DragDrop/DropTarget.tsx';
import { DragMoveHandler } from '../../../../_shared/components/DragDrop/dragDrop.type.ts';
import { DndTypes } from '../../../../_shared/constants/dndTypes.ts';
import { getDataUiObjectNameAttribute } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getTreeNodeMoveDirections } from '../../../../_shared/utils/dragDrop/dragDropUtils.ts';
import { ITaxonomyTerm } from '../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyTerm.ts';
import { DirectionItemEnum } from '../../sitemap/constants/DirectionItemEnum.ts';
import { DraggableTermBar } from '../containers/termBarItems/DraggableTermBar.tsx';
import { TermEditor } from '../containers/termEditors/TermEditor.tsx';

type ConnectedTaxonomyTermProps = {
  readonly isBeingDragged?: boolean;
  readonly nodeId: Uuid;
};

type TaxonomyTermProps = {
  readonly childNodeComponent: React.FC<ConnectedTaxonomyTermProps>;
  readonly isBeingDragged?: boolean;
  readonly isBeingEdited: boolean;
  readonly isCollapsed: boolean;
  readonly onTermMoved: (targetTermId: Uuid, directions: ReadonlySet<DirectionItemEnum>) => void;
  readonly taxonomyTerm: ITaxonomyTerm;
};

export const TaxonomyTerm: React.FC<TaxonomyTermProps> = memo(
  ({
    childNodeComponent,
    isBeingDragged,
    isBeingEdited,
    isCollapsed,
    onTermMoved,
    taxonomyTerm,
  }) => {
    const onMove: DragMoveHandler = useCallback(
      ({ pointer, targetBoundingRect, targetId }) => {
        const directions = getTreeNodeMoveDirections(pointer, targetBoundingRect);
        onTermMoved(targetId, directions);
      },
      [onTermMoved],
    );

    return (
      <DropTarget<HTMLLIElement>
        accept={DndTypes.Taxonomy_Node_Move}
        canDrop={!isBeingEdited}
        canDropToSelf
        onMove={onMove}
        parentId=""
        targetId={taxonomyTerm.id}
        hoveringCollisionStrategy={({ monitor }) => monitor.isOver({ shallow: true })}
        renderDroppable={(ref) => (
          <li
            ref={ref}
            className="bar-item__node"
            {...getDataUiObjectNameAttribute(taxonomyTerm.name)}
            data-hj-suppress=""
          >
            <BarItemAnimation
              estimatedMaxHeightWhenExpanded={250}
              renderCollapsed={() => (
                <DraggableTermBar isCollapsed={isCollapsed} taxonomyTerm={taxonomyTerm} />
              )}
              renderExpanded={() => <TermEditor term={taxonomyTerm} />}
              shouldBeExpanded={isBeingEdited}
            />
            {!isBeingDragged && (
              <BarItemChildList
                childComponent={childNodeComponent}
                childIds={taxonomyTerm.childIds}
                isCollapsed={isCollapsed}
                isBeingDragged={!!isBeingDragged}
              />
            )}
          </li>
        )}
      />
    );
  },
);

TaxonomyTerm.displayName = 'TaxonomyTerm';
