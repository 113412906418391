import React from 'react';
import { RedirectIf } from '../../../../_shared/components/routing/RedirectIf.tsx';
import { ProjectsRoute } from '../../../../_shared/constants/routePaths.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import {
  areAllSubscriptionsCanceled,
  getAdministratedSubscriptions,
} from '../../../../_shared/utils/subscription/subscriptionUtils.ts';
import { IProjectDetails } from '../../../../data/models/projects/ProjectDetails.ts';

const noSubscriptionsAvailable = (state: IStore) => {
  const {
    data: { subscriptions, projects, user },
  } = state;
  const projectSubscriptions = getAdministratedSubscriptions(
    projects.byId.map((project: IProjectDetails) => project.subscriptionId).toSet(),
    subscriptions.byId,
  );
  const administratedSubscriptionsAreEmpty =
    getAdministratedSubscriptions(subscriptions.administratedIds, subscriptions.byId).length === 0;

  return (
    (administratedSubscriptionsAreEmpty && !areAllSubscriptionsCanceled(projectSubscriptions)) ||
    (administratedSubscriptionsAreEmpty && !user.info.hadTrial)
  );
};

export const RedirectIfNoSubscriptionsAvailable: React.FC<React.PropsWithChildren<NoProps>> = ({
  children,
}) => {
  const shouldRedirect = useSelector(noSubscriptionsAvailable);

  return (
    <RedirectIf redirectTo={ProjectsRoute} doRedirect={shouldRedirect}>
      {children}
    </RedirectIf>
  );
};
RedirectIfNoSubscriptionsAvailable.displayName = 'RedirectIfNoSubscriptionsAvailable';
