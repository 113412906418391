import { assert } from '@kontent-ai/utils';
import { ThunkPromise } from '../../../../../../@types/Dispatcher.type.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventAction } from '../../../../../../_shared/models/TrackUserEvent.type.ts';
import { ContentItemEditingChangeAction } from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { logError } from '../../../../../../_shared/utils/logError.ts';
import { IContentItemWithVariantServerModel } from '../../../../../../repositories/serverModels/INewContentItemServerModel.ts';
import { getModalDialogActionOrigin } from '../../../../selectors/getModalDialogActionOrigin.ts';
import { CancelItemScheduledUnpublishErrorMessage } from '../../constants/uiConstants.ts';
import { IParsedItemVariant } from '../../utils/parseContentItem.ts';
import { prepareWorkflowStepTrackingData } from '../../utils/prepareWorkflowStepTrackingData.ts';
import {
  cancelScheduledUnpublishingFailed,
  cancelScheduledUnpublishingFinished,
  cancelScheduledUnpublishingStarted,
  contentItemEditingSidebarSectionCleanedUp,
} from '../contentItemEditingActions.ts';
import { ILoadRelatedContentItemElementsDataAction } from './loadRelatedContentItemElementsData.ts';

interface IDeps {
  readonly contentItemRepository: {
    readonly cancelVariantScheduledUnpublish: (
      contentItemId: Uuid,
      variantId: Uuid,
    ) => Promise<IContentItemWithVariantServerModel>;
  };
  readonly loadRelatedContentItemElementsData: ILoadRelatedContentItemElementsDataAction;
  readonly parseContentItemVariant: (
    contentItemWithVariant: IContentItemWithVariantServerModel,
  ) => IParsedItemVariant;
  readonly trackUserEvent: TrackUserEventAction;
}

export const cancelContentItemVariantScheduledUnpublishCreator =
  (deps: IDeps) =>
  (): ThunkPromise =>
  async (dispatch, getState): Promise<void> => {
    const state = getState();
    const {
      contentApp: {
        editedContentItemVariant,
        listingUi: { filter, orderBy },
      },
      data: {
        listingContentItems: { usedSearchMethod },
      },
    } = state;

    assert(editedContentItemVariant, () => 'editedContentItemVariant is falsy');

    const { itemId, variantId } = editedContentItemVariant.id;

    dispatch(cancelScheduledUnpublishingStarted());
    try {
      const itemWithVariant = await deps.contentItemRepository.cancelVariantScheduledUnpublish(
        itemId,
        variantId,
      );
      const variantData = deps.parseContentItemVariant(itemWithVariant);
      await dispatch(
        deps.loadRelatedContentItemElementsData(
          itemId,
          variantId,
          variantData.editedContentItemVariantElements,
          null,
        ),
      );
      const compiledVariant = {
        ...variantData,
        editedContentItemVariantElements: variantData.editedContentItemVariantElements,
      };
      dispatch(
        cancelScheduledUnpublishingFinished({
          itemWithVariant,
          itemVariantData: compiledVariant,
          filter,
          orderBy,
          usedSearchMethod,
        }),
      );
      dispatch(
        deps.trackUserEvent(
          TrackedEvent.ContentItemEditing,
          prepareWorkflowStepTrackingData(compiledVariant, {
            action: ContentItemEditingChangeAction.CancelScheduledUnpublish,
            origin: getModalDialogActionOrigin(state),
          }),
        ),
      );
      dispatch(contentItemEditingSidebarSectionCleanedUp());
    } catch (error) {
      logError('Error during cancelling of scheduled unpublish of an item variant', error);
      dispatch(cancelScheduledUnpublishingFailed(CancelItemScheduledUnpublishErrorMessage));
    }
  };
