import { identity } from '@kontent-ai/utils';
import React from 'react';
import { getDropDown } from '../../../../../../../_shared/components/DropDown.tsx';
import { DataUiElement } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  AssetFileTypeOption,
  AssetFileTypes,
  ResponsiveImagesExplanation,
  getAssetFileTypeOptionName,
} from '../../../../../../contentInventory/content/models/assetFileTypeOptions.ts';
import { ITypeElementDataWithAssetTypeLimitation } from '../../../../models/elements/types/TypeElementData.ts';
import { TypeElementConfigurationSection } from '../TypeElementConfigurationSection.tsx';

const DropDown = getDropDown<AssetFileTypeOption>();

type Props = {
  readonly onChange: (data: ITypeElementDataWithAssetTypeLimitation) => void;
  readonly title: string;
  readonly typeElementData: ITypeElementDataWithAssetTypeLimitation;
  readonly uiElement?: DataUiElement;
};

export const AssetType: React.FC<Props> = ({ onChange, title, typeElementData, uiElement }) => {
  const updateSelectedType = (type: AssetFileTypeOption): void => {
    onChange({
      ...typeElementData,
      fileType: type,
    });
  };

  return (
    <TypeElementConfigurationSection
      title={title}
      uiElement={uiElement}
      tooltip={ResponsiveImagesExplanation}
    >
      <DropDown
        className="combo-box"
        selectedOption={typeElementData.fileType}
        options={AssetFileTypes}
        onSelect={updateSelectedType}
        renderOptionName={getAssetFileTypeOptionName}
        getOptionId={identity}
      />
    </TypeElementConfigurationSection>
  );
};

AssetType.displayName = 'AssetType';
