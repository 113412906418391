import { memoize } from '@kontent-ai/memoization';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { DefaultWorkflowId } from '../../../../_shared/constants/variantIdValues.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import {
  getCurrentProject,
  getCurrentProjectPlan,
} from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import {
  createNewWorkflowLink,
  createWorkflowEditingLink,
} from '../../../../_shared/utils/routing/projectSubscriptionRoutingUtils.ts';
import { Workflow } from '../../../../data/models/workflow/Workflow.ts';
import { StatusMessageStyle } from '../../../contentInventory/content/reducers/listingUi/statusInfo/selectors/statusMessageStyle.ts';
import { getWorkflowsSortedByName } from '../../../itemEditor/selectors/workflows/getWorkflowsSortedByName.ts';
import { EnvironmentSettingsApp } from '../../root/components/EnvironmentSettingsApp.tsx';
import { areMultipleWorkflowsEnabledForCurrentProject } from '../../utils/allowedFeaturesUtils.ts';
import { initWorkflowsListing, restoreWorkflow } from '../actions/thunkWorkflowActions.ts';
import {
  WorkflowItem,
  WorkflowsListing as WorkflowsListingComponent,
} from '../components/WorkflowsListing.tsx';
import {
  IWorkflowListingOperationStatus,
  WorkflowListingOperationType,
} from '../model/WorkflowListingOperation.ts';

const getWorkflowsMemoized = memoize.maxOne(
  (
    workflows: ReadonlyMap<Uuid, Workflow>,
    usage: ReadonlyMap<Uuid, number>,
    projectId: Uuid,
    subscriptionId: Uuid | undefined,
  ): readonly WorkflowItem[] =>
    getWorkflowsSortedByName(workflows).map(
      (workflow): WorkflowItem => ({
        id: workflow.id,
        name: workflow.name,
        isDefault: workflow.id === DefaultWorkflowId,
        itemsUsage: usage.get(workflow.id),
        workflowEditorPath: createWorkflowEditingLink({
          projectId,
          workflowId: workflow.id,
          subscriptionId,
        }),
      }),
    ),
);

const getWorkflows = (state: IStore, subscriptionId: Uuid | undefined): readonly WorkflowItem[] => {
  const { projectId } = getCurrentProject(state);

  return getWorkflowsMemoized(
    state.data.workflows.byId,
    state.data.workflows.usage,
    projectId,
    subscriptionId,
  );
};

type Props = {
  readonly subscriptionId?: Uuid;
  readonly operationStatus: IWorkflowListingOperationStatus;
  readonly setOperationStatus: Dispatch<SetStateAction<IWorkflowListingOperationStatus>>;
};

export const WorkflowsListing = (props: Props) => {
  const [isInitThunkDone] = useThunkPromise(initWorkflowsListing);

  const dispatch = useDispatch();
  const { projectId } = useSelector(getCurrentProject);

  const workflows = useSelector((state) => getWorkflows(state, props.subscriptionId));

  const onRestoreWorkflow = useCallback(async (): Promise<void> => {
    if (
      props.operationStatus.affectedWorkflowId &&
      props.operationStatus.operationType === WorkflowListingOperationType.Deleted
    ) {
      props.setOperationStatus({
        message: 'Undoing',
        messageStyle: StatusMessageStyle.Progress,
        operationType: WorkflowListingOperationType.Deleting,
        affectedWorkflowId: null,
      });
      await dispatch(restoreWorkflow(props.operationStatus.affectedWorkflowId));
      props.setOperationStatus({
        message: 'Workflow restored',
        messageStyle: StatusMessageStyle.Updated,
        operationType: WorkflowListingOperationType.Restored,
        affectedWorkflowId: null,
      });
    }
  }, [props.operationStatus, props.setOperationStatus]);

  const areMultipleWorkflowsEnabled = useSelector((state) =>
    areMultipleWorkflowsEnabledForCurrentProject(getCurrentProjectPlan(state)),
  );
  const createNewWorkflowPath = areMultipleWorkflowsEnabled
    ? createNewWorkflowLink({ projectId, subscriptionId: props.subscriptionId })
    : undefined;

  return (
    <EnvironmentSettingsApp>
      {isInitThunkDone ? (
        <WorkflowsListingComponent
          createNewWorkflowPath={createNewWorkflowPath}
          onRestoreWorkflow={onRestoreWorkflow}
          operationStatus={props.operationStatus}
          workflows={workflows}
        />
      ) : (
        <Loader />
      )}
    </EnvironmentSettingsApp>
  );
};
