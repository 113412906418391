import { MouseEventHandler } from 'react';
import { Codename } from '../../../../../../_shared/components/Codename/Codename.tsx';
import { OnSaveCodename } from '../../../../../../_shared/components/Codename/OnSaveCodename.type.ts';
import { DropDownCaptionOption } from '../../../../../../_shared/uiComponents/DropDown/DropDownCaptionOption.tsx';
import { DropDownOptionSeparator } from '../../../../../../_shared/uiComponents/DropDown/DropDownOptionSeparator.tsx';
import { DropDownOptionsGroup } from '../../../../../../_shared/uiComponents/DropDown/DropDownOptionsGroup.tsx';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { codenameFormWidth } from '../../../constants/uiConstants.ts';

type Props = {
  readonly codename: string;
  readonly isCodenameUnique: (codename: string) => boolean;
  readonly onClick: MouseEventHandler<HTMLElement>;
  readonly onCodenameSave?: OnSaveCodename;
  readonly onCopied?: () => void;
  readonly onCopyCompleted?: () => void;
  readonly onEdited?: () => void;
};

export const AssetCodenameDialog = (props: Props) => (
  <DropDownOptionsGroup>
    <DropDownCaptionOption name="Codename" onClick={props.onClick} />
    <DropDownOptionSeparator />
    <div className="dropdown-item">
      <div
        style={{ width: codenameFormWidth }}
        {...getDataUiElementAttribute(DataUiElement.CodenameForm)}
      >
        <Codename
          codename={props.codename}
          hideTitle
          isCodenameUnique={props.isCodenameUnique}
          isEditable
          mode="confirm"
          onCopied={props.onCopied}
          onCopyCompleted={props.onCopyCompleted}
          onEdited={props.onEdited}
          onSave={props.onCodenameSave}
        />
      </div>
    </div>
  </DropDownOptionsGroup>
);
