import React from 'react';
import { NameEditorElement } from '../../../../_shared/components/NameEditorElement.tsx';
import { ValidationConstants } from '../../../../_shared/constants/validationConstants.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { DataUiInput } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { changeContentTypeSnippetName } from '../actions/snippetsActions.ts';
import { UntitledContentTypeSnippetName } from '../constants/UntitledContentTypeSnippetName.ts';

type Props = {
  readonly shouldFocusSnippetName?: boolean;
  readonly isMultiline: boolean;
};

export const ContentTypeSnippetNameEditorElement: React.FC<Props> = ({
  shouldFocusSnippetName,
  isMultiline,
}) => {
  const dispatch = useDispatch();
  const editedContentTypeSnippetName = useSelector(
    (s) => s.contentModelsApp.snippets.editor.editedContentTypeSnippet.name,
  );

  return (
    <NameEditorElement
      autoFocus={shouldFocusSnippetName}
      isMultiLine={isMultiline}
      className="snippet-name-editor"
      dataUiInputName={DataUiInput.EntityName}
      maxLength={ValidationConstants.SnippetNameMaxLength}
      placeholder={UntitledContentTypeSnippetName}
      value={
        editedContentTypeSnippetName === UntitledContentTypeSnippetName
          ? ''
          : editedContentTypeSnippetName
      }
      title="Content type snippet name"
      onChange={(name: string) => dispatch(changeContentTypeSnippetName(name))}
    />
  );
};

ContentTypeSnippetNameEditorElement.displayName = 'ContentTypeSnippetNameEditorElement';
