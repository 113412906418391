import { IStore } from '../../../../../../../_shared/stores/IStore.type.ts';
import { renderDateString } from '../../../../../../../_shared/utils/dateTime/timeUtils.ts';
import {
  pluralizeWithCount,
  pluralizeWithoutCount,
} from '../../../../../../../_shared/utils/stringUtils.ts';
import {
  IContentAppStoreState,
  StatusMessageAction,
} from '../../../../stores/IContentAppStoreState.ts';
import {
  ItemSelectionState,
  getItemSelectionState,
  isSomethingSelected,
  totalNumberOfSelectedItems,
} from '../../itemsSelection/selectors/itemsSelectionSelectors.ts';

const getContentItemsWithCount = (count: number) => pluralizeWithCount('content item', count);

const getFailedItemsMessage = (count: number) =>
  count > 0 ? `, ${getContentItemsWithCount(count)} failed` : '';

const getUnmodifiedItemsMessage = (count: number) =>
  count > 0 ? `, ${getContentItemsWithCount(count)} not modified` : '';

const constructMessage = (app: IContentAppStoreState, action: string): string => {
  const numberOfSuccessfulItems = app.listingUi.statusInfo.successfulIds.count();
  const numberOfFailedItems = app.listingUi.statusInfo.failedIds.count();
  const numberOfUnmodifiedItems = app.listingUi.statusInfo.unmodifiedIds.count();

  return `${getContentItemsWithCount(numberOfSuccessfulItems)} ${action}${getFailedItemsMessage(
    numberOfFailedItems,
  )}${getUnmodifiedItemsMessage(numberOfUnmodifiedItems)}`;
};

const getItemsSelectionMessage = (state: IStore): string => {
  switch (getItemSelectionState(state)) {
    case ItemSelectionState.AllSelected:
      return 'All content items selected';

    case ItemSelectionState.SomeSelected:
      return 'Some content items selected';

    case ItemSelectionState.ExactNumberSelected:
      return `${pluralizeWithCount('content item', totalNumberOfSelectedItems(state))} selected`;
  }
};

// Note that the order is super-important for message priority
export const getStatusMessageText = (state: IStore): string => {
  const { contentApp: app, data } = state;
  const lastAction = app.listingUi.statusInfo.lastAction.lastActionType;
  // First display progress message
  switch (lastAction) {
    case StatusMessageAction.Archiving:
      return 'Deleting selected content items';

    case StatusMessageAction.Moving:
      return 'Moving selected content items';

    case StatusMessageAction.Publishing:
      return 'Publishing selected content items';

    case StatusMessageAction.SchedulingToPublish:
      return 'Scheduling publish of selected content items';

    case StatusMessageAction.SchedulingToUnpublish:
      return 'Setting the unpublishing date of selected content items';

    case StatusMessageAction.Updating:
      return 'Updating selected content items';

    case StatusMessageAction.Undoing:
      return 'Undoing';

    case StatusMessageAction.Unpublishing:
      return 'Unpublishing selected content items';

    case StatusMessageAction.Cancelling:
      return 'Cancelling scheduled publish of selected content items';

    default:
      break;
  }

  // Then current selection
  if (isSomethingSelected(state)) {
    return getItemsSelectionMessage(state);
  }

  // Last the result of an action
  switch (lastAction) {
    case StatusMessageAction.Moved:
      return constructMessage(app, 'moved');

    case StatusMessageAction.Published:
      return constructMessage(app, 'published');

    case StatusMessageAction.ScheduledToPublish:
      return constructMessage(app, 'scheduled to publish');

    case StatusMessageAction.ScheduledToUnpublish: {
      const unpublishDate = renderDateString(
        state.contentApp.changeWorkflowStepModalData.scheduledToUnpublishAt,
      );
      return constructMessage(app, `set to unpublish on ${unpublishDate}`);
    }

    case StatusMessageAction.Unpublished:
    case StatusMessageAction.PublishingUndone:
      return constructMessage(app, 'unpublished');

    case StatusMessageAction.Cancelled:
    case StatusMessageAction.SchedulingToPublishUndone:
      return constructMessage(app, 'unscheduled');

    case StatusMessageAction.Updated:
      return constructMessage(app, 'updated');

    case StatusMessageAction.Archived:
      return constructMessage(app, 'deleted');

    case StatusMessageAction.Restored:
      return constructMessage(app, 'restored');

    default: {
      const itemsCount = data.listingContentItems.allIds?.length ?? 0;
      const countMessage = `${itemsCount}${
        data.listingContentItems.nextContinuationToken ? '+' : ''
      }`;

      return `${countMessage} ${pluralizeWithoutCount('content item', itemsCount)}`;
    }
  }
};
