import { assert } from '@kontent-ai/utils';
import { isString } from '../../../../../_shared/utils/stringUtils.ts';
import { ITextDiffPart } from './diffDataUtils.ts';
import { isWordChar } from './diffUnicodeUtils.ts';

function getMutualWhitespacePrefix(first: string, second: string): string | null {
  let i = 0;
  while (first[i] === second[i] && !isWordChar(first[i])) {
    i++;
  }

  if (i === 0) {
    return null;
  }
  return first.substr(0, i);
}

function normalizeSpaces(result: ReadonlyArray<ITextDiffPart>): ReadonlyArray<ITextDiffPart> {
  // Text-based diff result must be normalized, because the current implementation treats white space larger than one character as an atomic value
  // We need it to represent white space which is present in both old and new as unchanged to properly match block endings
  const normalized: ITextDiffPart[] = [];
  let previousPart: ITextDiffPart | null = null;

  result.forEach((diffPart) => {
    if (
      previousPart &&
      ((previousPart.removed && diffPart.added) || (previousPart.added && diffPart.removed))
    ) {
      const commonPrefix = getMutualWhitespacePrefix(previousPart.value, diffPart.value);
      if (commonPrefix) {
        normalized.pop();

        normalized.push({
          value: commonPrefix,
        });

        previousPart.value = previousPart.value.substr(commonPrefix.length);
        if (previousPart.value) {
          normalized.push(previousPart);
        }

        diffPart.value = diffPart.value.substr(commonPrefix.length);
      }
    }

    if (diffPart.value) {
      normalized.push(diffPart);
      previousPart = diffPart;
    }
  });

  return normalized;
}

// Any segments below may be treated as insignificant
const MAX_INSIGNIFICANT_LENGTH = 10;
// Any segments below 5% of total changed length (of either removed or deleted) are treated as insignificant
const MAX_INSIGNIFICANT_PERCENT = 5;

function mergeChanges(changes: ReadonlyArray<ITextDiffPart>): ITextDiffPart {
  if (changes.length === 1) {
    const firstChange = changes[0];
    assert(firstChange, () => 'Change at index 0 is falsy.');
    return firstChange;
  }

  const mergedChange = changes.reduce(
    (merged, item) => {
      const mergedItem: ITextDiffPart = {
        value: merged.value + item.value,
      };
      if (item.added) {
        mergedItem.added = true;
      } else if (item.removed) {
        mergedItem.removed = true;
      }
      return mergedItem;
    },
    { value: '' },
  );
  return mergedChange;
}

function orderAndMergeAdjacentChangesOfSameType(
  changes: ReadonlyArray<ITextDiffPart>,
): ReadonlyArray<ITextDiffPart> {
  const normalized: ITextDiffPart[] = [];
  let pendingRemoved: ITextDiffPart[] = [];
  let pendingAdded: ITextDiffPart[] = [];
  let pendingUnchanged: ITextDiffPart[] = [];

  changes.forEach((diffPart) => {
    if (diffPart.removed) {
      if (pendingUnchanged.length > 0) {
        normalized.push(mergeChanges(pendingUnchanged));
        pendingUnchanged = [];
      }
      pendingRemoved.push(diffPart);
    } else if (diffPart.added) {
      if (pendingUnchanged.length > 0) {
        normalized.push(mergeChanges(pendingUnchanged));
        pendingUnchanged = [];
      }
      pendingAdded.push(diffPart);
    } else {
      if (pendingRemoved.length > 0) {
        normalized.push(mergeChanges(pendingRemoved));
        pendingRemoved = [];
      }
      if (pendingAdded.length > 0) {
        normalized.push(mergeChanges(pendingAdded));
        pendingAdded = [];
      }
      pendingUnchanged.push(diffPart);
    }
  });

  if (pendingUnchanged.length > 0) {
    normalized.push(mergeChanges(pendingUnchanged));
  }
  if (pendingRemoved.length > 0) {
    normalized.push(mergeChanges(pendingRemoved));
  }
  if (pendingAdded.length > 0) {
    normalized.push(mergeChanges(pendingAdded));
  }

  return normalized;
}

export const DiffObjectBlockType = {
  Module: 'module',
  Table: 'table',
  Image: 'image',
  Component: 'component',
} as const;
export type DiffObjectBlockType = (typeof DiffObjectBlockType)[keyof typeof DiffObjectBlockType];
export const DiffObjectBlockTypes: ReadonlyArray<DiffObjectBlockType> =
  Object.values(DiffObjectBlockType);

export function getObjectBlockWord(type: DiffObjectBlockType, id: Uuid): string {
  return `${type}${id?.replace(/-/g, '')}${type}`;
}

const fakeWordsRegexps = DiffObjectBlockTypes.map((type) =>
  getObjectBlockWord(type, '[a-f0-9]{32}'),
);
// eslint-disable-next-line no-useless-escape
const fakeWordsRegex = new RegExp(fakeWordsRegexps.map((word) => `${word}\s?`).join('|'), 'ig');

function omitFakeWords(text: string): string {
  return text.replace(fakeWordsRegex, '');
}

function normalizePendingChanges(
  changes: ReadonlyArray<ITextDiffPart>,
): ReadonlyArray<ITextDiffPart> {
  if (changes.length === 0) {
    return changes;
  }

  const mergedChanges = orderAndMergeAdjacentChangesOfSameType(changes);

  const addedLength = mergedChanges.reduce(
    (length, item) => length + (item.added ? omitFakeWords(item.value).length : 0),
    0,
  );
  const removedLength = mergedChanges.reduce(
    (length, item) => length + (item.removed ? omitFakeWords(item.value).length : 0),
    0,
  );
  const largerChangeLength = addedLength > removedLength ? addedLength : removedLength;

  const maxInsignificantChangeLength = (largerChangeLength * MAX_INSIGNIFICANT_PERCENT) / 100;

  const normalized: ITextDiffPart[] = [];
  let pendingRemoved: ITextDiffPart[] = [];
  let pendingAdded: ITextDiffPart[] = [];

  mergedChanges.forEach((diffPart) => {
    if (diffPart.removed) {
      pendingRemoved.push(diffPart);
    } else if (diffPart.added) {
      pendingAdded.push(diffPart);
    } else {
      const isInsignificant = diffPart.value.length < maxInsignificantChangeLength;
      if (isInsignificant && (pendingRemoved.length > 0 || pendingAdded.length > 0)) {
        pendingRemoved.push({
          value: diffPart.value,
          removed: true,
        });
        pendingAdded.push({
          value: diffPart.value,
          added: true,
        });
      } else {
        if (pendingRemoved.length > 0) {
          pendingRemoved.forEach((part) => normalized.push(part));
          pendingRemoved = [];
        }
        if (pendingAdded.length > 0) {
          pendingAdded.forEach((part) => normalized.push(part));
          pendingAdded = [];
        }
        normalized.push(diffPart);
      }
    }
  });

  pendingRemoved.forEach((part) => normalized.push(part));
  pendingAdded.forEach((part) => normalized.push(part));

  return normalized;
}

function containsOnlyNonWordCharacters(text: string): boolean {
  for (let i = 0; i < text.length; i++) {
    const char = text[i];
    if (isString(char) && isWordChar(char)) {
      return false;
    }
  }
  return true;
}

function groupRelatedChanges(changes: ReadonlyArray<ITextDiffPart>): ReadonlyArray<ITextDiffPart> {
  // Normalizes the diff output in a way that:
  // 1) Removed is always before added
  // 2) Unchanged segments without non-alphanumerics (spaces, punctuation) are changed to coupled removed / added if next to alphanumeric changes
  //    this helps produce more consistent results in terms of completely changed sentences
  const normalized: ITextDiffPart[] = [];
  let pending: ITextDiffPart[] = [];

  changes.forEach((diffPart) => {
    if (diffPart.removed || diffPart.added) {
      pending.push(diffPart);
    } else if (containsOnlyNonWordCharacters(diffPart.value) && pending.length > 0) {
      // Non-word is always treated as insignificant when found after removed or added
      pending.push({
        value: diffPart.value,
        removed: true,
      });
      pending.push({
        value: diffPart.value,
        added: true,
      });
    } else {
      // But some of the normal words, e.g. " and " may be also insignificant
      // We delegate that to the next level of heuristics once we collect all related removed / added changes
      const mayBeInsignificant = diffPart.value.length <= MAX_INSIGNIFICANT_LENGTH;
      if (mayBeInsignificant && pending.length > 0) {
        pending.push(diffPart);
      } else {
        const normalizedPending = normalizePendingChanges(pending);
        normalizedPending.forEach((part) => normalized.push(part));
        pending = [];
        normalized.push(diffPart);
      }
    }
  });

  const normalizedRemainingPending = normalizePendingChanges(pending);
  normalizedRemainingPending.forEach((part) => normalized.push(part));

  return normalized;
}

export function normalizeTextDiffResult(
  changes: ReadonlyArray<ITextDiffPart>,
): ReadonlyArray<ITextDiffPart> {
  const spacesNormalized = normalizeSpaces(changes);
  const changesGrouped = groupRelatedChanges(spacesNormalized);

  return changesGrouped;
}
