import { isElement } from '@kontent-ai/DOM';
import { FocusManagerOptions, getFocusableTreeWalker } from '@react-aria/focus';
import { DataAttributes, getDataAttributeSelector } from './dataAttributes/DataAttributes.ts';

export const defaultTabbingOptions = {
  tabbable: true,
  // filter out elements with contenteditable="false", mainly in RTE
  accept: (node) => node.getAttribute('contenteditable') !== 'false',
} satisfies FocusManagerOptions;

export function getFocusEntryElement(parentElement: Element): HTMLElement | null {
  const focusableElement = parentElement.querySelector(
    getDataAttributeSelector(DataAttributes.FocusEntryPoint, 'true'),
  );

  if (isElement(focusableElement)) {
    return focusableElement;
  }

  const blockFocusableTreeWalker = getFocusableTreeWalker(parentElement, {
    tabbable: true,
  });
  const firstFocusableElement = blockFocusableTreeWalker.firstChild();

  if (isElement(firstFocusableElement)) {
    return firstFocusableElement;
  }

  return null;
}
