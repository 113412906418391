import { useHotkeys } from '@kontent-ai/component-library/hooks';
import {
  BorderRadius,
  Size,
  borderWidthDefault,
  colorBackgroundHover,
  colorBorderDefault,
  offsetFocus,
  px,
  shadowFocusStyles,
  transition250,
} from '@kontent-ai/component-library/tokens';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useFocusRing } from '@react-aria/focus';
import React, { MouseEvent, useRef } from 'react';
import styled from 'styled-components';
import { IconName } from '../../../../../_shared/constants/iconEnumGenerated.ts';
import { Icon } from '../../../../../_shared/uiComponents/Icon/Icon.tsx';
import { SquareButton } from '../../../../../_shared/uiComponents/SquareButton/SquareButton.tsx';
import { SquareButtonSize } from '../../../../../_shared/uiComponents/SquareButton/squareButtonSize.ts';
import { SquareButtonStyle } from '../../../../../_shared/uiComponents/SquareButton/squareButtonStyle.ts';
import {
  DataUiAction,
  DataUiElement,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
  getDataUiObjectNameAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ITaxonomyTerm } from '../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyTerm.ts';
import { FlexingTile } from '../AssetTile/FlexingTile.tsx';

type StyledAssetFolderPane = {
  readonly assetFolderName: string;
  readonly isFocusVisible: boolean;
};

const StyledAssetFolderPane = styled.div.attrs<StyledAssetFolderPane>(({ assetFolderName }) => ({
  ...getDataUiElementAttribute(DataUiElement.AssetFolder),
  ...getDataUiObjectNameAttribute(assetFolderName),
}))<StyledAssetFolderPane>`
  display: flex;
  position: relative;
  height: ${px(Size.M)};
  align-items: center;
  justify-content: center;
  border: ${px(borderWidthDefault)} solid ${colorBorderDefault};
  border-radius: ${px(BorderRadius.M)};
  cursor: pointer;
  transition: all ${transition250};

  &:hover,
  &:active,
  &:visited {
    background-color: ${colorBackgroundHover};
  }

  &::before {
    content: '';
    position: absolute;
    inset: ${px(-1 * offsetFocus)};
    border-radius: inherit;
    pointer-events: none;
    ${({ isFocusVisible }) => isFocusVisible && shadowFocusStyles};
  }
`;

type Props = {
  readonly assetFolder: ITaxonomyTerm;
  readonly onRename?: () => void;
  readonly onOpen: () => void;
};

export const AssetFolder: React.FC<Props> = (props) => {
  const { assetFolder, onOpen, onRename } = props;
  const { isFocusVisible, focusProps } = useFocusRing();

  const buttonRef = React.useRef(null);
  const ref = useRef<HTMLDivElement>(null);

  useHotkeys(
    {
      enter: onOpen,
    },
    { ref, excludedElementRefs: [buttonRef] },
  );

  const initiateRenaming = (event: MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    onRename?.();
  };

  const renderRenameButton = (): JSX.Element | null => {
    if (!onRename) {
      return null;
    }

    return (
      <SquareButton
        ref={buttonRef}
        className="asset-folder__action"
        onClick={initiateRenaming}
        ariaLabel={`Rename ${assetFolder.name} folder`}
        style={SquareButtonStyle.Quinary}
        size={SquareButtonSize.Quinary}
        tooltipPlacement="bottom"
        tooltipText="Rename"
        iconName={IconName.Edit}
        {...getDataUiActionAttribute(DataUiAction.Edit)}
      />
    );
  };

  return (
    <FlexingTile className="asset-folder">
      <StyledAssetFolderPane
        isFocusVisible={isFocusVisible}
        assetFolderName={assetFolder.name}
        onClick={onOpen}
        role="link"
        tabIndex={0}
        aria-label={`${assetFolder.name} folder`}
        ref={ref}
        {...focusProps}
      >
        <div className="asset-folder__icon">
          <Icon iconName={IconName.FolderInverted} />
        </div>
        <span className="asset-folder__name" title={assetFolder.name}>
          {assetFolder.name}
        </span>
        {renderRenameButton()}
      </StyledAssetFolderPane>
    </FlexingTile>
  );
};

AssetFolder.displayName = 'AssetFolder';
