import React from 'react';
import { CreateNewBarItemButton } from './CreateNewBarItemButton.tsx';

interface ICreateNewBarItemHidingButtonDataProps {
  readonly isFormDisplayed: boolean;
  readonly isCreatingAllowed?: boolean;
  readonly itemName: string;
}

interface CreateNewBarItemHidingButtonCallbacksProps {
  readonly onCreateNewClick: () => void;
}

interface CreateNewBarItemHidingButtonProps
  extends ICreateNewBarItemHidingButtonDataProps,
    CreateNewBarItemHidingButtonCallbacksProps {
  readonly children: React.ReactElement;
}

export const CreateNewBarItemHidingButton: React.FC<CreateNewBarItemHidingButtonProps> = (
  props,
) => {
  if (!props.isCreatingAllowed) {
    return null;
  }

  if (props.isFormDisplayed) {
    return props.children;
  }

  return (
    <CreateNewBarItemButton itemName={props.itemName} onCreateNewClick={props.onCreateNewClick} />
  );
};

CreateNewBarItemHidingButton.displayName = 'CreateNewBarItemHidingButton';
