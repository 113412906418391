import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import classNames from 'classnames';
import React from 'react';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { CommentThreadType } from '../../../../../models/comments/CommentThreads.ts';
import {
  commentThreadTypeWithTextSegmentMessage,
  commentThreadTypeWithoutTextSegmentMessage,
  isCommentThreadTypeWithTextSegment,
  isCommentThreadTypeWithoutTextSegment,
  isSimpleCommentThreadType,
  simpleCommentThreadTypeMessage,
} from '../../../utils/commentThreadItemSegmentInfoUtils.ts';

interface ICommentThreadItemSegmentInfoProps {
  readonly elementName: string | null;
  readonly commentThreadType: CommentThreadType;
  readonly elementSegment: string | null;
  readonly isInlineThreadWithRemovedContent?: boolean;
}

interface ISimpleCommentThreadSegmentInfo {
  readonly message: string;
}

const SimpleCommentThreadSegmentInfo: React.FC<ISimpleCommentThreadSegmentInfo> = (props) => {
  return (
    <div
      className="comment__reference"
      {...getDataUiElementAttribute(DataUiElement.CommentReference)}
    >
      <div className="comment__ref-anchor">{props.message}</div>
    </div>
  );
};

export const CommentThreadItemSegmentInfo: React.FC<ICommentThreadItemSegmentInfoProps> = (
  props,
) => {
  const { commentThreadType, elementName, elementSegment, isInlineThreadWithRemovedContent } =
    props;

  if (isSimpleCommentThreadType(commentThreadType)) {
    return (
      <SimpleCommentThreadSegmentInfo
        message={simpleCommentThreadTypeMessage(commentThreadType, elementName)}
      />
    );
  }

  if (isCommentThreadTypeWithoutTextSegment(commentThreadType)) {
    return (
      <SimpleCommentThreadSegmentInfo
        message={commentThreadTypeWithoutTextSegmentMessage(
          commentThreadType,
          elementName,
          elementSegment,
          isInlineThreadWithRemovedContent,
        )}
      />
    );
  }

  if (isCommentThreadTypeWithTextSegment(commentThreadType) && elementSegment) {
    return (
      <div
        className="comment__reference"
        {...getDataUiElementAttribute(DataUiElement.CommentReference)}
      >
        <div className="comment__ref-anchor">
          {commentThreadTypeWithTextSegmentMessage(
            commentThreadType,
            elementName,
            isInlineThreadWithRemovedContent,
          )}
        </div>
        <Tooltip placement="left" text={elementSegment}>
          <div className="comment__ref-preview">
            <span
              className={classNames({
                'comment__ref-preview-text--isInlineThreadWithRemovedContent':
                  isInlineThreadWithRemovedContent,
              })}
            >
              {elementSegment}
            </span>
          </div>
        </Tooltip>
      </div>
    );
  }

  return null;
};

CommentThreadItemSegmentInfo.displayName = 'CommentThreadItemSegmentInfo';
