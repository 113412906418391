import { Checkbox } from '@kontent-ai/component-library/Checkbox';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import classNames from 'classnames';
import React from 'react';
import { IconName } from '../../../../../../../_shared/constants/iconEnumGenerated.ts';
import {
  AnimatedChevron,
  RestDirection,
} from '../../../../../../../_shared/uiComponents/AnimatedChevron/AnimatedChevron.tsx';
import { Icon } from '../../../../../../../_shared/uiComponents/Icon/Icon.tsx';
import { SquareButtonShell } from '../../../../../../../_shared/uiComponents/SquareButton/SquareButtonShell.tsx';
import { SquareButtonSize } from '../../../../../../../_shared/uiComponents/SquareButton/squareButtonSize.ts';
import { SquareButtonStyle } from '../../../../../../../_shared/uiComponents/SquareButton/squareButtonStyle.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

export interface ILimitationCategoryStateProps {
  readonly dataUiElement: DataUiElement;
  readonly hasInvalidLimits?: boolean;
  readonly isAllowed: boolean;
  readonly isDisabled?: boolean;
  readonly isExpanded?: boolean;
  readonly title: string;
  readonly titleIconName?: IconName;
  readonly tooltipText?: string;
}

export interface ILimitationCategoryDispatchProps {
  readonly onCollapse?: () => void;
  readonly onExpand?: () => void;
  readonly onChange: (value: boolean) => void;
}

type Props = ILimitationCategoryStateProps & ILimitationCategoryDispatchProps;

export const LimitationCategory: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  dataUiElement,
  hasInvalidLimits,
  isAllowed,
  isDisabled,
  isExpanded,
  title,
  titleIconName,
  tooltipText,
  onCollapse,
  onExpand,
  onChange,
}) => {
  const isExpandable = !!children;
  const isIconDisplayed = titleIconName !== undefined;

  const onCheckboxSelectionChanged = (): void => {
    onChange(!isAllowed);
  };

  const onToggleExpanded = (): void => {
    if (isExpandable) {
      if (isExpanded) {
        onCollapse?.();
      } else {
        onExpand?.();
      }
    }
  };

  return (
    <div
      className={classNames('limitation-category', {
        'limitation-category--has-errors': hasInvalidLimits,
        'limitation-category--is-expanded': isExpanded,
      })}
      {...getDataUiElementAttribute(dataUiElement)}
    >
      <div
        className={classNames('limitation-category__header', {
          'limitation-category__header--is-expandable': isExpandable,
        })}
      >
        <Checkbox
          aria-label={title}
          checkboxState={isDisabled ? 'disabled' : 'default'}
          checked={isAllowed}
          onToggle={onCheckboxSelectionChanged}
          tooltipText={tooltipText}
        />
        <div
          className="limitation-category__title"
          {...getDataUiElementAttribute(DataUiElement.LimitationCategoryHeader)}
          onClick={onToggleExpanded}
        >
          {isIconDisplayed && (
            <span className="limitation-category__title-icon">
              <Icon iconName={titleIconName} />
            </span>
          )}
          <span className="limitation-category__title-text">{title}</span>
        </div>
        {isExpandable && (
          <Tooltip text={isExpanded ? 'Collapse settings' : 'Expand settings'} placement="left">
            <SquareButtonShell
              ariaLabel={isExpanded ? 'Collapse settings' : 'Expand settings'}
              style={SquareButtonStyle.Quinary}
              size={SquareButtonSize.Quinary}
              onClick={onToggleExpanded}
            >
              <AnimatedChevron isTurned={isExpanded || false} restDirection={RestDirection.Down} />
            </SquareButtonShell>
          </Tooltip>
        )}
      </div>
      {isExpanded && (
        <div
          className="limitation-category__content"
          {...getDataUiElementAttribute(DataUiElement.LimitationCategoryContent)}
        >
          {children}
        </div>
      )}
    </div>
  );
};

LimitationCategory.displayName = 'LimitationCategory';
