import { Paper, PaperLevel } from '@kontent-ai/component-library/Paper';
import { spacingPopupDistance } from '@kontent-ai/component-library/tokens';
import { Placement } from '@kontent-ai/component-library/types';
import { areShallowEqual, preventDefault } from '@kontent-ai/utils';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  DropDownMenuPositioner,
  DropdownTippyOptions,
} from '../../../../../../../component-library/components/DropDownMenu/DropDownMenuPositioner.tsx';
import { ScrollContainerContext } from '../../../../../../../component-library/components/ScrollContainer/ScrollContainerContext.tsx';
import { usePreventOverflowFromScrollContainer } from '../../../../../../../component-library/components/ScrollContainer/usePreventOverflowFromScrollContainer.ts';
import { useEventListener } from '../../../../../../_shared/hooks/useEventListener.ts';
import {
  Callback,
  RegisterCallback,
} from '../../../../../../_shared/types/RegisterCallback.type.ts';
import {
  DataUiElement,
  Popovers,
  getDataUiElementAttribute,
  getDataUiObjectNameAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getDomSelection } from '../../../../../../_shared/utils/selectionUtils.ts';
import {
  InlineToolbarPosition,
  ToolbarAttachedTo,
  adjustPositionToReference,
  getInlineToolbarPosition,
} from '../../utils/inlineToolbarPositioningUtils.ts';
import { InlineToolbarAnchor } from '../InlineToolbarAnchor.tsx';

function getToolbarPlacement(state: IInlineToolbarState): Placement {
  switch (state.attachedTo) {
    case ToolbarAttachedTo.TextFromBelow:
      return 'bottom-start';
    default:
      return 'top-start';
  }
}

interface IInlineToolbarState extends InlineToolbarPosition {}

type InlineToolbarProps = {
  readonly editorRef: React.RefObject<HTMLDivElement>;
  readonly registerUpdateToolbarPosition: RegisterCallback<Callback>;
  readonly renderContent: () => React.ReactElement;
};

const initialToolbarState: IInlineToolbarState = {
  attachedTo: ToolbarAttachedTo.TextFromBelow,
  left: 0,
  top: 0,
};

const updatePositionState = (
  toolbarRef: React.RefObject<HTMLDivElement>,
  editorContentRef: React.RefObject<HTMLDivElement>,
  scrollContainerRef: React.RefObject<Element>,
  updateState: React.Dispatch<React.SetStateAction<IInlineToolbarState>>,
) => {
  updateState((oldState) => {
    const domSelection = getDomSelection();

    if (
      !toolbarRef.current ||
      !editorContentRef.current ||
      !domSelection ||
      domSelection.isCollapsed
    ) {
      return oldState;
    }
    const editorRectangle = editorContentRef.current.getBoundingClientRect();
    const toolbarRectangle = toolbarRef.current.getBoundingClientRect();
    const scrollContainerRectangle = (
      scrollContainerRef.current ?? document.body
    ).getBoundingClientRect();

    const position = getInlineToolbarPosition(
      domSelection,
      toolbarRectangle,
      editorRectangle,
      scrollContainerRectangle,
      true,
    );

    if (!position) {
      return oldState;
    }

    const relativePosition = adjustPositionToReference(position, editorRectangle);
    return areShallowEqual(oldState, relativePosition) ? oldState : relativePosition;
  });
};

export const InlineToolbar: React.FC<InlineToolbarProps> = ({
  editorRef,
  registerUpdateToolbarPosition,
  renderContent,
}) => {
  const [toolbarState, setToolbarState] = useState<IInlineToolbarState>(initialToolbarState);
  const toolbarRef = useRef<HTMLDivElement | null>(null);
  const { scrollContainerRef, tippyBoundaryRef } = useContext(ScrollContainerContext);

  const updatePositionStateCallback = useCallback(
    () => updatePositionState(toolbarRef, editorRef, tippyBoundaryRef, setToolbarState),
    [editorRef, tippyBoundaryRef],
  );

  const toolbarRefToForward = useCallback(
    (toolbarElement: HTMLDivElement | null) => {
      toolbarRef.current = toolbarElement;

      if (toolbarElement) {
        // Initial positioning when the toolbar is mounted
        updatePositionStateCallback();
      }
    },
    [updatePositionStateCallback],
  );

  // Position is changed from sticking to text to sticking to view port based on scrolling.
  useEventListener('scroll', updatePositionStateCallback, scrollContainerRef.current);
  useEventListener('resize', updatePositionStateCallback, self);

  useEffect(
    () => registerUpdateToolbarPosition(updatePositionStateCallback),
    [registerUpdateToolbarPosition, updatePositionStateCallback],
  );

  const { preventOverflowModifier } = usePreventOverflowFromScrollContainer();

  const tippyOptions: DropdownTippyOptions = {
    placement: getToolbarPlacement(toolbarState),
    offset: [0, spacingPopupDistance],
    popperOptions: {
      modifiers: [
        preventOverflowModifier,
        {
          name: 'flip',
          enabled: false,
        },
      ],
    },
  };

  return (
    <DropDownMenuPositioner
      allowAnimation={false}
      isDropDownVisible
      renderTrigger={({ ref }) => (
        <InlineToolbarAnchor left={toolbarState.left} top={toolbarState.top} ref={ref} />
      )}
      renderDropDown={() => (
        <Paper component="section" level={PaperLevel.Popout} ref={toolbarRefToForward}>
          <div
            // Prevent editor focus lost when toolbar button is clicked.
            onMouseDown={preventDefault}
            onMouseUp={preventDefault}
            {...getDataUiObjectNameAttribute(Popovers.RteInlineToolbar)}
            {...getDataUiElementAttribute(DataUiElement.Popover)}
          >
            {renderContent()}
          </div>
        </Paper>
      )}
      tippyOptions={tippyOptions}
    />
  );
};

InlineToolbar.displayName = 'InlineToolbar';
