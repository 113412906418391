import React, { ComponentProps } from 'react';
import { LinkDataTableCell } from '../../../../../_shared/components/DataTable/LinkDataTableCell.tsx';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { Task } from '../../../../../_shared/models/Task.ts';
import { MissionControlTrackedWidgetName } from '../../../../../_shared/models/TrackUserEventData.ts';
import { getCurrentProjectId } from '../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { getContentItemPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { getUsersByIds } from '../../../../../_shared/utils/users/usersUtils.ts';
import { useLivePreviewPreferenceStorage } from '../../../../../localStorages/useLivePreviewPreferenceStorage.ts';
import { TableCellDueDate } from '../../../../contentInventory/content/features/ContentItemInventory/components/TableCellDueDate.tsx';
import { ScrollTableItemContributors } from '../../../contentStatus/components/ScrollTableItemContributors.tsx';
import { useIsDemoModeEnabled } from '../../../contexts/MissionControlDemoContext.tsx';
import {
  StyledDataTableCell,
  StyledDataTableRow,
} from '../../shared/components/StyledDataTable.tsx';
import { useTrackWidgetOverviewItemClick } from '../../shared/hooks/tracking.ts';

interface ITasksAssignedByYouWidgetRowProps {
  readonly task: Task;
  readonly rowFocusLinkAriaLabel: string;
}

export const TasksAssignedByYouWidgetRow: React.FC<ITasksAssignedByYouWidgetRowProps> = ({
  task,
  rowFocusLinkAriaLabel,
}) => {
  const isDemoModeEnabled = useIsDemoModeEnabled();
  const currentProjectId = useSelector(getCurrentProjectId);
  const { isLivePreviewPreferred } = useLivePreviewPreferenceStorage(currentProjectId);

  const trackWidgetOverviewItemClick = useTrackWidgetOverviewItemClick(
    MissionControlTrackedWidgetName.TasksAssignedByYou,
  );
  const commonCellProps = {
    linkPath: isDemoModeEnabled
      ? null // Disable redirect in demo mode
      : getContentItemPath(
          window.location.pathname,
          task.contentItemId.itemId,
          isLivePreviewPreferred,
          task.contentItemId.variantId,
        ),
    onClick: isDemoModeEnabled ? undefined : trackWidgetOverviewItemClick,
  } satisfies ComponentProps<typeof LinkDataTableCell>;

  const usersById = useSelector((s) => s.data.users.usersById);
  const assignees = getUsersByIds(usersById, Array.from(task.assignees));

  return (
    <StyledDataTableRow dataUiObjectName={task.description} id={task.id}>
      <StyledDataTableCell focusableRowLinkAriaLabel={rowFocusLinkAriaLabel} {...commonCellProps}>
        {task.description}
      </StyledDataTableCell>
      <StyledDataTableCell {...commonCellProps}>
        <ScrollTableItemContributors contributors={assignees} />
      </StyledDataTableCell>
      <StyledDataTableCell {...commonCellProps}>
        <TableCellDueDate dueDate={task.dueDate} />
      </StyledDataTableCell>
    </StyledDataTableRow>
  );
};

TasksAssignedByYouWidgetRow.displayName = 'TasksAssignedByYouWidgetRow';
