import React, { memo, useCallback } from 'react';
import { useHistory } from 'react-router';
import { AppNames } from '../../../../../../../_shared/constants/applicationNames.ts';
import {
  ContentItemTimelineLatestPublishedRoute,
  ContentItemsAppRouteSegment,
  ContentItemsRoute,
  ContentItemsRouteParams,
} from '../../../../../../../_shared/constants/routePaths.ts';
import { VariantIdMacro } from '../../../../../../../_shared/constants/variantIdValues.ts';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { ContentItemFilterOrigin } from '../../../../../../../_shared/models/events/ContentItemFilterEventData.type.ts';
import { getCurrentProjectId } from '../../../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { getCannotCreateMessageForItem } from '../../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import {
  buildPath,
  getContentItemPath,
  pickContentItemRoute,
} from '../../../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { IListingContentItem } from '../../../../../../../data/models/listingContentItems/IListingContentItem.ts';
import { WorkflowStepAction } from '../../../../../../../data/models/workflow/WorkflowStep.ts';
import { useLivePreviewPreferenceStorage } from '../../../../../../../localStorages/useLivePreviewPreferenceStorage.ts';
import { useSpaceData } from '../../../../../../environmentSettings/spaces/hooks/useSpaceData.ts';
import { contentItemsBreadcrumbsOriginChanged } from '../../../../../shared/actions/contentInventoryActions.ts';
import { isItemSelected } from '../../../../reducers/listingUi/itemsSelection/selectors/itemsSelectionSelectors.ts';
import { getCollection } from '../../../../selectors/getCollection.ts';
import { getContentItemListingHiddenColumns } from '../../../../selectors/getContentItemListingHiddenColumns.ts';
import { setContentItemSelection } from '../../actions/thunkContentItemInventoryActions.ts';
import { WorkflowStatusCellContent } from '../../components/ItemInventoryScrollTable/Cells/ContentItemScrollTableCellsContents.tsx';
import { ContentItemScrollTableRow as ContentItemScrollTableRowComponent } from '../../components/ItemInventoryScrollTable/ContentItemScrollTableRow.tsx';

type ContentItemScrollTableRowContainerProps = {
  readonly item: IListingContentItem;
  readonly index: number;
};

export const ContentItemScrollTableRow: React.FC<ContentItemScrollTableRowContainerProps> = memo(
  (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const itemType = useSelector((s) => s.data.contentTypes.byId.get(props.item.item.typeId));
    const currentProjectId = useSelector(getCurrentProjectId);
    const { isLivePreviewPreferred } = useLivePreviewPreferenceStorage(currentProjectId);
    const collectionName = useSelector(
      (s) => getCollection(s, props.item.item.collectionId)?.name ?? '',
    );
    const spaceNames = useSpaceData(props.item.item.collectionId);
    const hiddenColumns = useSelector(getContentItemListingHiddenColumns);
    const searchPhrase = useSelector((s) => s.contentApp.listingUi.filter.searchPhrase);
    const isHighlighted = useSelector((s) =>
      s.contentApp.listingUi.highlightedContentItems.contains(props.item.item.id),
    );

    const cannotOpenWithClickMessage = getCannotCreateMessageForItem(props.item);
    const isSelected = useSelector((s) =>
      isItemSelected(s.contentApp.listingUi.itemsSelection, props.item.item.id),
    );

    const variant = props.item.variant;
    //  In case this item is a published version and there already exists a new version for this item
    const isOlderPublishedVersion =
      variant &&
      variant.assignment.workflowStatus.action === WorkflowStepAction.Publish &&
      variant.actualWorkflowStatus &&
      variant.actualWorkflowStatus.id !== variant.assignment.workflowStatus.id;

    const route = isOlderPublishedVersion
      ? ContentItemTimelineLatestPublishedRoute
      : pickContentItemRoute(isLivePreviewPreferred);
    const itemLinkPath = getContentItemPath(
      history.location.pathname,
      props.item.item.id,
      isLivePreviewPreferred,
      undefined,
      { route, origin: ContentItemFilterOrigin.ContentInventory },
    );

    const onItemSelectionChanged = useCallback(
      (shiftPressed: boolean) =>
        dispatch(
          setContentItemSelection(props.item.item.id, !isSelected, shiftPressed, () => true),
        ),
      [props.item.item.id, isSelected],
    );
    const onItemClick = useCallback(
      (shiftPressed: boolean) =>
        !shiftPressed &&
        dispatch(
          contentItemsBreadcrumbsOriginChanged({
            path: buildPath<ContentItemsRouteParams>(ContentItemsRoute, {
              projectId: currentProjectId,
              variantId: VariantIdMacro,
              spaceId: undefined,
              app: ContentItemsAppRouteSegment.Content,
            }),
            title: AppNames.ContentItems,
          }),
        ),
      [currentProjectId],
    );

    if (!itemType) {
      return null;
    }

    return (
      <ContentItemScrollTableRowComponent
        allowNavigationWithOnClick={!cannotOpenWithClickMessage}
        collectionName={collectionName}
        disabledClickMessage={cannotOpenWithClickMessage}
        searchPhrase={searchPhrase}
        hiddenColumns={hiddenColumns}
        index={props.index}
        isHighlighted={isHighlighted}
        isItemChecked={isSelected}
        item={props.item}
        itemLinkPath={itemLinkPath}
        itemType={itemType}
        onCheckboxClick={onItemSelectionChanged}
        onItemClick={cannotOpenWithClickMessage ? undefined : onItemClick}
        renderWorkflowStatus={() => <WorkflowStatusCellContent variant={props.item.variant} />}
        spaceData={spaceNames}
      />
    );
  },
);

ContentItemScrollTableRow.displayName = 'ContentItemScrollTableRow';
