import React from 'react';
import { NameEditorElement } from '../../../../_shared/components/NameEditorElement.tsx';
import { ValidationConstants } from '../../../../_shared/constants/validationConstants.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { DataUiInput } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { changeContentTypeName } from '../actions/contentTypesActions.ts';
import { UntitledContentTypeName } from '../constants/contentTypeConstants.ts';

type Props = {
  readonly shouldFocusTypeName?: boolean;
  readonly isMultiline: boolean;
};

export const ContentTypeNameEditorElement: React.FC<Props> = ({
  shouldFocusTypeName,
  isMultiline,
}) => {
  const dispatch = useDispatch();
  const editedTypeName = useSelector((s) => s.contentModelsApp.typeEditor.editedType.name);

  return (
    <NameEditorElement
      autoFocus={shouldFocusTypeName}
      isMultiLine={isMultiline}
      className="content-models-name-editor"
      dataUiInputName={DataUiInput.EntityName}
      maxLength={ValidationConstants.TypeNameMaxLength}
      placeholder={UntitledContentTypeName}
      value={editedTypeName === UntitledContentTypeName ? '' : editedTypeName}
      title="Content type name"
      onChange={(name: string) => dispatch(changeContentTypeName(name))}
    />
  );
};

ContentTypeNameEditorElement.displayName = 'ContentTypeNameEditorElement';
