import { Button } from '@kontent-ai/component-library/Button';
import { preventDefault } from '@kontent-ai/utils';
import { FC, useId, useState } from 'react';
import { HotkeysHandler } from '../../../../../_shared/components/Hotkeys/HotkeysHandler.tsx';
import { ModalDialog } from '../../../../../_shared/components/ModalDialog/ModalDialog.tsx';
import { ShortcutSymbols } from '../../../../../_shared/constants/shortcutSymbols.ts';
import { TextField } from '../../../../../_shared/uiComponents/TextField/TextField.tsx';
import {
  DataUiAction,
  getDataUiActionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { compose } from '../../../../../_shared/utils/func/compose.ts';
import {
  editedContentGroupCopy,
  newContentGroupCopy,
} from '../../constants/contentTypeConstants.ts';

export enum ContentGroupDialogState {
  New = 'New',
  Edited = 'Edited',
}

type Props = {
  readonly onCancel: () => void;
  readonly onConfirm: (name: string) => void;
  readonly state: ContentGroupDialogState;
  readonly value?: string;
};

const getConstants = (state: ContentGroupDialogState) => {
  switch (state) {
    case ContentGroupDialogState.New:
      return newContentGroupCopy;

    case ContentGroupDialogState.Edited:
      return editedContentGroupCopy;
  }
};

export const ContentGroupNameDialog: FC<Props> = ({ onCancel, onConfirm, state, value }) => {
  const inputId = useId();
  const [newContentGroupName, setNewContentGroupName] = useState(value || '');

  const confirmName = (): void => {
    const trimmedContentGroupName = newContentGroupName.trim();

    if (trimmedContentGroupName && trimmedContentGroupName !== value) {
      onConfirm(newContentGroupName);
    }
  };

  const constants = getConstants(state);
  const trimmedNewContentGroupName = newContentGroupName.trim();
  const hasNameChanged = trimmedNewContentGroupName !== value;
  const isConfirmDisabled = !trimmedNewContentGroupName || !hasNameChanged;
  const disabledConfirmationTooltip =
    (!newContentGroupName && constants.emptyNameConfirmTooltip) ||
    (!hasNameChanged && constants.equalNamesConfirmTooltip) ||
    undefined;

  return (
    <ModalDialog
      onClose={onCancel}
      headerContent={constants.header}
      bodyContent={
        <HotkeysHandler
          className="content-group-name-dialog"
          handlers={{
            onEnter: compose(confirmName, preventDefault),
          }}
        >
          <TextField
            autoFocus
            placeholder={constants.placeholder}
            value={newContentGroupName}
            maxLength={50}
            onChange={(e) => setNewContentGroupName(e.currentTarget.value)}
            inputId={inputId}
          />
        </HotkeysHandler>
      }
      footerContentRight={
        <>
          <Button
            tooltipText={constants.cancelTooltip}
            tooltipShortcuts={ShortcutSymbols.Escape}
            tooltipPlacement="bottom-end"
            buttonStyle="secondary"
            onClick={onCancel}
            {...getDataUiActionAttribute(DataUiAction.Cancel)}
          >
            {constants.cancelText}
          </Button>
          <Button
            tooltipText={isConfirmDisabled ? disabledConfirmationTooltip : constants.confirmTooltip}
            tooltipShortcuts={isConfirmDisabled ? undefined : ShortcutSymbols.Enter}
            tooltipPlacement="bottom-start"
            buttonStyle="primary"
            disabled={isConfirmDisabled}
            onClick={confirmName}
            {...getDataUiActionAttribute(DataUiAction.Confirm)}
          >
            {constants.confirmText}
          </Button>
        </>
      }
    />
  );
};

ContentGroupNameDialog.displayName = 'ContentGroupNameDialog';
