import { useLocation } from 'react-router';
import { trackUserEvent } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import {
  ContentItemEditingEventOrigins,
  ContentItemEditingNavigationAction,
} from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { getCurrentProjectId } from '../../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { getCannotViewItemMessage } from '../../../../../../_shared/utils/permissions/getCannotViewItemMessage.ts';
import { createBuildLinkForVariantReference } from '../../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { UsageItemReference } from '../../../../../../_shared/utils/usage/usageUtils.ts';
import { useLivePreviewPreferenceStorage } from '../../../../../../localStorages/useLivePreviewPreferenceStorage.ts';
import { SingleContentItemUsage as SingleContentItemUsageComponent } from '../../components/details/SingleContentItemUsage.tsx';

type Props = Readonly<{
  contentItemId: Uuid | undefined;
  usage: UsageItemReference;
}>;

export const SingleContentItemUsage = ({ contentItemId, usage }: Props) => {
  const dispatch = useDispatch();

  const location = useLocation();
  const currentProjectId = useSelector(getCurrentProjectId);
  const { isLivePreviewPreferred } = useLivePreviewPreferenceStorage(currentProjectId);
  const buildLink = createBuildLinkForVariantReference(
    currentProjectId,
    location.pathname,
    isLivePreviewPreferred,
  );

  const typeName = useSelector(
    (state) => state.data.contentTypes.byId.get(usage.primary.typeId)?.name ?? null,
  );

  const navigateToItem = () => {
    dispatch(
      trackUserEvent(TrackedEvent.ContentItemEditing, {
        action: ContentItemEditingNavigationAction.UsedIn,
        origin: ContentItemEditingEventOrigins.ItemDetails,
      }),
    );
  };

  return (
    <SingleContentItemUsageComponent
      buildLink={buildLink}
      contentItemId={contentItemId}
      disabledMessage={getCannotViewItemMessage(usage.primary)}
      onNavigateToItem={navigateToItem}
      typeName={typeName}
      usage={usage}
    />
  );
};
