import { alphabetically } from '@kontent-ai/utils';
import { getProjectInfo } from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { getSubscriptionUsage } from '../../../../_shared/utils/subscriptionUsageUtils.ts';
import { IProjectDetails } from '../../../../data/models/projects/ProjectDetails.ts';
import { IUserProjectInfo } from '../../../../data/models/user/UserProjectInfo.ts';
import {
  EnvironmentsLimitReachedTooltipMessage,
  NoEnvironmentWithCapabilityToCloneFromTooltipMessage,
} from '../constants/uiConstants.ts';
import { IBaseEnvironment } from '../models/IBaseEnvironment.type.ts';

export const getEnvironmentsCount = (state: IStore, projectId: Uuid): number => {
  const project = getProjectInfo(state, projectId);
  const subscriptionUsage = getSubscriptionUsage(
    state.data.subscriptions.subscriptionUsages,
    project.subscriptionId,
  );
  const projectContainer = subscriptionUsage?.projectContainers?.find(
    (c) => !!c?.projects?.keySeq().contains(project.projectId),
  );
  return projectContainer?.projects.count() ?? 0;
};

export function compareUserEnvironments(
  projectA: IUserProjectInfo,
  projectB: IUserProjectInfo,
): number {
  const isProjectAMasterEnv = projectA.masterEnvironmentId === projectA.projectId;
  const isProjectBMasterEnv = projectB.masterEnvironmentId === projectB.projectId;

  if (!isProjectAMasterEnv && !isProjectBMasterEnv) {
    return alphabetically(projectA.environmentName, projectB.environmentName);
  }

  return isProjectAMasterEnv ? -1 : 1;
}

export function compareEnvironments(
  environmentA: IProjectDetails,
  environmentB: IProjectDetails,
): number {
  const isEnvironmentAProduction = environmentA.masterEnvironmentId === environmentA.projectId;
  const isEnvironmentBProduction = environmentB.masterEnvironmentId === environmentB.projectId;

  if (!isEnvironmentAProduction && !isEnvironmentBProduction) {
    return alphabetically(environmentA.environmentName, environmentB.environmentName);
  }

  return isEnvironmentAProduction ? -1 : 1;
}

export const getDisabledCreateButtonTooltipText = (
  planLimitReached: boolean,
  hasAnyEnvironmentToCloneFrom: boolean,
): string | undefined => {
  return planLimitReached
    ? EnvironmentsLimitReachedTooltipMessage
    : hasAnyEnvironmentToCloneFrom
      ? undefined
      : NoEnvironmentWithCapabilityToCloneFromTooltipMessage;
};

export const getDefaultEnvironmentToCloneFromId = (
  environments: ReadonlyArray<IBaseEnvironment>,
  masterEnvironmentId: Uuid,
): Uuid | null => {
  const defaultEnvironment =
    environments.find((e: IBaseEnvironment) => e.environmentId === masterEnvironmentId) ??
    environments[0];
  return defaultEnvironment?.environmentId ?? null;
};

export const isEnvironmentsLimitReached = (limit: number | null, usage: number): boolean => {
  if (limit === null) {
    return false;
  }

  return usage >= limit;
};
