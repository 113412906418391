import { Location } from 'history';
import {
  EnvironmentRouteParams,
  EnvironmentSettingsRoute,
  GeneralEnvironmentSettingsRoute,
  SubscriptionEnvironmentSettingsRoute,
  SubscriptionEnvironmentSettingsRouteParams,
} from '../../../../_shared/constants/routePaths.ts';
import { Breadcrumb } from '../../../../_shared/models/Breadcrumb.type.ts';
import { IBreadcrumbResolverInfo } from '../../../../_shared/utils/breadcrumbs/breadcrumbResolvers.ts';
import { buildPath, matchPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { InnovationLabApplicationName } from './uiConstants.ts';

export const innovationLabRouteSegment = 'innovation-lab';
export const InnovationLabSettingsRoute = `${EnvironmentSettingsRoute}/${innovationLabRouteSegment}`;
export const SubscriptionInnovationLabSettingsRoute = `${SubscriptionEnvironmentSettingsRoute}/${innovationLabRouteSegment}`;
export const ObsoleteEarlyAccessProgramSettingsRoute = `${GeneralEnvironmentSettingsRoute}/early-access-program`;

const createInnovationLabBreadcrumbResolverInfo = (
  resolver: (location: Location) => Promise<ReadonlyArray<Breadcrumb>>,
): IBreadcrumbResolverInfo => ({
  pattern: new RegExp(`^${innovationLabRouteSegment}$`),
  resolver,
  childResolvers: [],
});

export const InnovationLabBreadcrumbResolverInfo: IBreadcrumbResolverInfo =
  createInnovationLabBreadcrumbResolverInfo((location) => {
    const match =
      matchPath<EnvironmentRouteParams>(location.pathname, InnovationLabSettingsRoute) ?? {};
    const breadcrumb = {
      title: InnovationLabApplicationName,
      path: buildPath(InnovationLabSettingsRoute, match),
      useProjectIdPrefix: false,
    };
    const breadcrumbs = [breadcrumb];

    return Promise.resolve(breadcrumbs);
  });

export const SubscriptionInnovationLabBreadcrumbResolverInfo: IBreadcrumbResolverInfo =
  createInnovationLabBreadcrumbResolverInfo((location) => {
    const match =
      matchPath<SubscriptionEnvironmentSettingsRouteParams>(
        location.pathname,
        SubscriptionInnovationLabSettingsRoute,
      ) ?? {};
    const breadcrumb = {
      title: InnovationLabApplicationName,
      path: buildPath(SubscriptionInnovationLabSettingsRoute, match),
      useProjectIdPrefix: false,
    };
    const breadcrumbs = [breadcrumb];

    return Promise.resolve(breadcrumbs);
  });
