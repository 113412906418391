import { ActiveCapabilityType } from '../../../../../_shared/models/activeCapability.type.ts';
import { ScrollTableCell } from '../../../../../_shared/uiComponents/ScrollTable/ScrollTableCell.tsx';
import { ScrollTableDefaultRowSkelet } from '../../../../../_shared/uiComponents/ScrollTable/ScrollTableDefaultRowSkelet.tsx';
import { ScrollTableRow } from '../../../../../_shared/uiComponents/ScrollTable/ScrollTableRow.tsx';
import { ICollection } from '../../../../../data/models/collections/Collection.ts';
import { IContentType } from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { IListingContentItem } from '../../../../../data/models/listingContentItems/IListingContentItem.ts';
import { RedactedItemName } from '../../constants/uiConstants.ts';
import {
  DeletedItemNameCellContent,
  ItemCollectionCellContent,
  ItemNameCellContent,
  ItemTypeCellContent,
  OpenInNewTabCell,
  RedactedItemNameCellContent,
  WorkflowStatusWithFallbacksCellContent,
  getTextForTableCell,
} from '../../features/ContentItemInventory/components/ItemInventoryScrollTable/Cells/ContentItemScrollTableCellsContents.tsx';

export type FailedItemRowProps = Readonly<{
  showCollectionColumn: boolean;
}>;

type FailedItemRowComponentProps = FailedItemRowProps &
  Readonly<{
    item: IListingContentItem | null;
    itemCollection: ICollection | undefined;
    itemPath: string | null;
    itemType: IContentType | null;
  }>;

export const FailedItemRow = ({
  item,
  itemCollection,
  itemPath,
  itemType,
  showCollectionColumn,
}: FailedItemRowComponentProps) => {
  if (!item) {
    return <ScrollTableDefaultRowSkelet />;
  }

  if (item.item.archived) {
    return (
      <ScrollTableRow objectName={item.item.name}>
        <ScrollTableCell size={10} isGrowing title={item.item.name}>
          <DeletedItemNameCellContent item={item} />
        </ScrollTableCell>
      </ScrollTableRow>
    );
  }

  const canView = item.activeCapabilities.variantCapabilities.includes(
    ActiveCapabilityType.ViewContent,
  );
  if (!canView) {
    return (
      <ScrollTableRow objectName={RedactedItemName}>
        <ScrollTableCell size={10} isGrowing title={RedactedItemName}>
          <RedactedItemNameCellContent />
        </ScrollTableCell>
      </ScrollTableRow>
    );
  }

  const collectionName = itemCollection?.name;

  return (
    <ScrollTableRow objectName={item.item.name}>
      <ScrollTableCell size={10} isGrowing title={item.item.name}>
        <ItemNameCellContent item={item} variantId={item.variant?.id.variantId} />
      </ScrollTableCell>

      <OpenInNewTabCell path={itemPath ?? ''} />

      <ScrollTableCell size={4} isGrowing>
        <WorkflowStatusWithFallbacksCellContent variant={item.variant} itemId={item.item.id} />
      </ScrollTableCell>

      <ScrollTableCell size={4} isGrowing title={getTextForTableCell(itemType?.name)}>
        <ItemTypeCellContent itemType={itemType} />
      </ScrollTableCell>

      {showCollectionColumn && (
        <ScrollTableCell size={4} isGrowing title={getTextForTableCell(collectionName)}>
          <ItemCollectionCellContent collectionName={collectionName} />
        </ScrollTableCell>
      )}
    </ScrollTableRow>
  );
};
