import classNames from 'classnames';
import React from 'react';

interface IShadowContentElementProps {
  readonly className?: string;
  readonly isFirstListElement?: boolean;
  readonly isGroupItem?: boolean;
  readonly isLastGroupItem?: boolean;
}

export const ShadowContentElement: React.FC<IShadowContentElementProps> = ({
  className,
  isFirstListElement,
  isGroupItem,
  isLastGroupItem,
}) => (
  <div
    className={classNames(className, 'shadow-content-element', {
      'shadow-content-element--is-group-item': isGroupItem,
      'shadow-content-element--is-last-group-item': isLastGroupItem,
      'shadow-content-element--is-first-list-element': isFirstListElement,
    })}
  >
    <div className="shadow-content-element__object">
      <p className="shadow-content-element__message">Drop your element here</p>
    </div>
  </div>
);

ShadowContentElement.displayName = 'ShadowContentElement';
