import { IStore } from '../../../../../../../_shared/stores/IStore.type.ts';
import { isValidUrlInfo } from '../../../../../../../_shared/utils/validation/isValidUrlInfo.ts';
import { canShowNotSetupPreview } from '../../../containers/selectors/canShowNotSetupPreview.ts';
import { selectPreviewLinks } from '../../../containers/selectors/selectPreviewLinks.ts';

export const isPreviewItemAvailable = (state: IStore, isLivePreviewToggleVisible: boolean) =>
  !isLivePreviewToggleVisible && hasSingleValidPreviewUrl(state);

export const isPreviewItemInMultipleSpacesAvailable = (
  state: IStore,
  isLivePreviewToggleVisible: boolean,
) => !isLivePreviewToggleVisible && hasValidPreviewUrlsForAllSpaces(state);

export const isDisabledPreviewItemAvailable = (
  state: IStore,
  isLivePreviewToggleVisible: boolean,
) => !isLivePreviewToggleVisible && hasInvalidPreviewUrl(state);

export const isSetupItemPreviewAvailable = (state: IStore, isLivePreviewToggleVisible: boolean) =>
  !isLivePreviewToggleVisible && canShowNotSetupPreview(state);

const hasSingleValidPreviewUrl = (state: IStore): boolean => {
  const previewLinks = selectPreviewLinks(state);

  return previewLinks.length === 1 && isValidUrlInfo(previewLinks[0]);
};

const hasValidPreviewUrlsForAllSpaces = (state: IStore): boolean => {
  const previewLinks = selectPreviewLinks(state);

  return previewLinks.length > 1 && previewLinks.every((linkInfo) => isValidUrlInfo(linkInfo));
};

const hasInvalidPreviewUrl = (state: IStore): boolean => {
  const previewLinks = selectPreviewLinks(state);

  return previewLinks.some(({ error }) => !!error);
};
