import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import React from 'react';
import {
  ItemEditingForms,
  getDataUiObjectNameAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

interface ISectionProps {
  readonly dataUiNameAttribute: ItemEditingForms;
  readonly tooltipText?: string;
}

export const ReadonlySection: React.FC<React.PropsWithChildren<ISectionProps>> = ({
  children,
  dataUiNameAttribute,
  tooltipText,
}) => (
  <Tooltip placement="top" text={tooltipText}>
    <div
      className="sidebar__section-item sidebar__section-item--is-disabled"
      {...getDataUiObjectNameAttribute(dataUiNameAttribute || '')}
    >
      {children}
    </div>
  </Tooltip>
);

ReadonlySection.displayName = 'ReadonlySection';
