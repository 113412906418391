import { SampleProjectType } from '../../../applications/projects/constants/sampleProjectType.ts';
import { BusinessRole } from '../BusinessRole.ts';
import { ProjectPropertiesModel } from '../ProjectPropertiesModel.ts';

const technicalRoles: readonly BusinessRole[] = [BusinessRole.CTO, BusinessRole.Developer];

export const isTechnicalRole = (role: BusinessRole): boolean => technicalRoles.includes(role);

export const getSampleProjectType = (
  projectProperties: ProjectPropertiesModel,
): SampleProjectType | null => {
  switch (projectProperties.SampleProjectType) {
    case 'Kickstart':
      return SampleProjectType.Kickstart;
    case 'FictoHealthtech':
      return SampleProjectType.HealthTech;
    case null:
      return null;
  }
};
