import { Collection } from '@kontent-ai/utils';
import { getNormalizedRolesWithSettings } from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { IRoleWithSettings } from '../../../../data/models/roles/IRoleWithSettings.ts';
import {
  IProjectContributor,
  getRoleIdsInAllCollectionsAndLanguages,
} from '../../../../data/models/users/ProjectContributor.ts';

export const hasRole = (roleId: Uuid) => (user: IProjectContributor) =>
  getRoleIdsInAllCollectionsAndLanguages(user).includes(roleId);

export const isRoleUsedByAnyUser = (
  role: IRoleWithSettings,
  users: ReadonlyMap<Uuid, IProjectContributor>,
): boolean => Collection.getValues(users).some(hasRole(role.id));

export const isRoleUsedByCurrentUser = (roleId: Uuid, state: IStore): boolean =>
  getNormalizedRolesWithSettings(state)
    .collectionGroups.flatMap((g) => g.roles)
    .some((languageBoundRole) => languageBoundRole?.role?.id === roleId);
