import React, { memo, useMemo, useRef } from 'react';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { IContentType } from '../../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import {
  IContentTypeExtended,
  RichTextTypeElement as RichTextTypeElementComponent,
} from '../../../components/typeElements/individualTypeElements/richText/RichTextTypeElement.tsx';
import { IRichTextTypeElementData } from '../../../models/elements/RichTextTypeElementData.ts';
import { getAllowedContentTypesSelectedOptions } from '../../../selectors/contentTypeElementSelector.ts';
import { ITypeElementOwnProps } from '../../../types/ITypeElementProps.type.ts';
import { RichTextTypeElementValidationResult } from '../../../utils/typeElementValidators/types/RichTextTypeElementValidationResult.type.ts';
import { getSortedContentTypesByName } from '../../../utils/typeUtils.ts';

/* Do not copy any of this code - MultiSelect should have implemented memoization in itself and this is only a temporary solution */
export const RichTextTypeElement: React.FC<
  ITypeElementOwnProps<IRichTextTypeElementData, RichTextTypeElementValidationResult>
> = memo((props) => {
  const { typeElementData: richTypeElementData } = props;
  const contentTypesById = useSelector((s) => s.data.contentTypes.byId);
  const sortedContentTypes = useMemo(
    () => getSortedContentTypesByName(contentTypesById),
    [contentTypesById],
  );

  const isTypeDeleted = (type: IContentType) => !sortedContentTypes.includes(type);

  const selectedAllowedTypes = Array.from(
    getAllowedContentTypesSelectedOptions(contentTypesById, richTypeElementData.allowedTypes),
  );
  const deletedTypesRef = useRef<IContentType[]>(selectedAllowedTypes.filter(isTypeDeleted));

  const selectedAllowedItemLinkTypes = Array.from(
    getAllowedContentTypesSelectedOptions(
      contentTypesById,
      richTypeElementData.allowedItemLinkTypes,
    ),
  );
  const deletedItemLinkTypes = useRef<IContentType[]>(
    selectedAllowedItemLinkTypes.filter(isTypeDeleted),
  );

  const allTypes: readonly IContentTypeExtended[] = useMemo(() => {
    const allTypesWithDeleted = [...new Set([...deletedTypesRef.current, ...sortedContentTypes])];
    return [
      ...allTypesWithDeleted.filter((i) => i.isArchived),
      ...allTypesWithDeleted.filter((i) => !i.isArchived),
    ].map((opt) => ({
      id: opt.id,
      label: opt.name,
      isArchived: opt.isArchived,
    }));
  }, [sortedContentTypes]);

  const allItemLinkTypes: readonly IContentTypeExtended[] = useMemo(() => {
    const allTypesWithDeleted = [
      ...new Set([...deletedItemLinkTypes.current, ...sortedContentTypes]),
    ];
    return [
      ...allTypesWithDeleted.filter((i) => i.isArchived),
      ...allTypesWithDeleted.filter((i) => !i.isArchived),
    ].map((opt) => ({
      id: opt.id,
      label: opt.name,
      isArchived: opt.isArchived,
    }));
  }, [sortedContentTypes]);

  return (
    <RichTextTypeElementComponent
      {...props}
      allContentTypes={allTypes}
      allContentItemLinkTypes={allItemLinkTypes}
      selectedAllowedTypesOptions={selectedAllowedTypes}
      selectedAllowedItemLinkTypesOptions={selectedAllowedItemLinkTypes}
      typeElementData={richTypeElementData}
      validationResult={props.validationResult}
    />
  );
});

RichTextTypeElement.displayName = 'RichTextTypeElement';
