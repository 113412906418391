import { useWrongPrevious } from '@kontent-ai/hooks';
import { useContext, useEffect } from 'react';
import { LoadingStatus } from '../../../../../../_shared/models/LoadingStatusEnum.ts';
import { LanguageFilterContext } from '../../../../../missionControl/contentStatus/contexts/LanguageFilter.tsx';
import { IListingFilter, areListingFiltersEqual } from '../../../models/filter/IListingFilter.ts';

export const useFilterChanged = (
  onFilterChange: () => void,
  filter: IListingFilter,
  listingItemsLoadingStatus: LoadingStatus,
): void => {
  const { selectedLanguageIds } = useContext(LanguageFilterContext);
  const previousFilter = useWrongPrevious(filter);
  const previousSelectedLanguageIds = useWrongPrevious(selectedLanguageIds);

  useEffect(() => {
    if (
      listingItemsLoadingStatus === LoadingStatus.InitialEmpty ||
      !areListingFiltersEqual(filter, previousFilter) ||
      selectedLanguageIds !== previousSelectedLanguageIds
    ) {
      onFilterChange();
    }
  }, [
    onFilterChange,
    filter,
    previousFilter,
    listingItemsLoadingStatus,
    selectedLanguageIds,
    previousSelectedLanguageIds,
  ]);
};
