import { assert, Collection } from '@kontent-ai/utils';
import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { trackUserEvent } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { TrackUserEventAction } from '../../../../../../_shared/models/TrackUserEvent.type.ts';
import { IUserIdentifier } from '../../../../../../_shared/models/UserIdentifier.ts';
import {
  ContentItemEditingChangeAction,
  ContentItemEditingEventData,
  ContentItemEditingEventOrigins,
} from '../../../../../../_shared/models/events/ContentItemEditingEventData.type.ts';
import { getWorkflow } from '../../../../../../_shared/selectors/workflowSelectors.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { Workflow } from '../../../../../../data/models/workflow/Workflow.ts';
import { IWorkflowStep } from '../../../../../../data/models/workflow/WorkflowStep.ts';
import { IAssignment } from '../../../../models/contentItem/Assignment.ts';
import { prepareWorkflowStepTrackingDataWithFeatures } from '../../utils/prepareWorkflowStepTrackingData.ts';

interface IDeps {
  readonly trackUserEvent: TrackUserEventAction;
}

const isWorkflowChanged = (oldAssignment: IAssignment, newAssignment: IAssignment): boolean =>
  oldAssignment.workflowStatus !== newAssignment.workflowStatus;

const getChangedAssignees = (
  oldAssignment: IAssignment,
  newAssignment: IAssignment,
): { addedAssignees: ReadonlySet<UserId>; removedAssignees: ReadonlySet<UserId> } => {
  const oldAssignees = oldAssignment.assignees;
  const newAssignees = newAssignment.assignees;

  if (newAssignees !== oldAssignees) {
    const oldIds = [...oldAssignees].map((u: IUserIdentifier) => u.userId);
    const newIds = [...newAssignees].map((u: IUserIdentifier) => u.userId);

    const addedAssignees = new Set(newIds.filter((id) => !oldIds.includes(id)));
    const removedAssignees = new Set(oldIds.filter((id) => !newIds.includes(id)));

    return {
      addedAssignees,
      removedAssignees,
    };
  }

  return {
    addedAssignees: new Set<UserId>(),
    removedAssignees: new Set<UserId>(),
  };
};

const isNewUserAssigned = (oldAssignment: IAssignment, newAssignment: IAssignment): boolean => {
  const { addedAssignees } = getChangedAssignees(oldAssignment, newAssignment);

  return !Collection.isEmpty(addedAssignees);
};

const haveAssignedUsersChanged = (
  oldAssignment: IAssignment,
  newAssignment: IAssignment,
): boolean => {
  const { addedAssignees, removedAssignees } = getChangedAssignees(oldAssignment, newAssignment);

  return !Collection.isEmpty(addedAssignees) || !Collection.isEmpty(removedAssignees);
};

const isDueDateUpdated = (oldAssignment: IAssignment, newAssignment: IAssignment): boolean => {
  const oldDueDate = oldAssignment.due;
  const newDueDate = newAssignment.due;

  return !!(newDueDate || oldDueDate) && oldDueDate !== newDueDate;
};

const isNoteUpdated = (oldAssignment: IAssignment, newAssignment: IAssignment): boolean =>
  oldAssignment.note !== newAssignment.note;

const getRegularStepIndex = (workflow: Workflow, workflowStatus: IWorkflowStep): number =>
  workflow.steps.findIndex((step: IWorkflowStep) => step.id === workflowStatus.id);

const getWorkflowChangedEventData = (
  state: IStore,
  newAssignment: IAssignment,
  oldAssignment: IAssignment,
  origin: ContentItemEditingEventOrigins,
): Extract<
  ContentItemEditingEventData,
  { action: ContentItemEditingChangeAction.ChangeWorkflowStep }
> => {
  const currentWorkflow = getWorkflow(state, newAssignment.workflowStatus.workflowId);
  assert(
    currentWorkflow,
    () => `Did not find workflow with id ${newAssignment.workflowStatus.workflowId}.`,
  );

  const assigneesCount = newAssignment.assignees.size;
  const regularStepIndex = getRegularStepIndex(currentWorkflow, newAssignment.workflowStatus);
  const secondsSinceLastAssignmentUpdate = getSecondsSinceTime(oldAssignment.createdAt);

  return prepareWorkflowStepTrackingDataWithFeatures(newAssignment, {
    action: ContentItemEditingChangeAction.ChangeWorkflowStep,
    assigneesCount,
    oldWorkflowId: oldAssignment.workflowStatus.workflowId,
    oldWorkflowStepId: oldAssignment.workflowStatus.id,
    origin,
    regularStepIndex,
    secondsSinceLastAssignmentUpdate,
  });
};

const getAssignedUserIds = (assignment: IAssignment): ReadonlyArray<UserId> =>
  [...assignment.assignees].map((assignee: IUserIdentifier) => assignee.userId);

const getSecondsSinceTime = (dateTimeString: DateTimeStamp | null): number => {
  if (!dateTimeString) {
    return 0;
  }

  return (Date.now() - Date.parse(dateTimeString)) / 1000;
};

export const createTrackAssignmentEvents =
  (deps: IDeps) =>
  (
    oldAssigment: IAssignment,
    newAssignment: IAssignment,
    actionOrigin: ContentItemEditingEventOrigins,
  ): ThunkFunction =>
  (dispatch, getState) => {
    if (isWorkflowChanged(oldAssigment, newAssignment)) {
      const eventData = getWorkflowChangedEventData(
        getState(),
        newAssignment,
        oldAssigment,
        actionOrigin,
      );
      dispatch(trackUserEvent(TrackedEvent.ContentItemEditing, eventData));
    }

    if (isNewUserAssigned(oldAssigment, newAssignment)) {
      dispatch(deps.trackUserEvent(TrackedEvent.ContributorAssigned));
    }

    if (haveAssignedUsersChanged(oldAssigment, newAssignment)) {
      dispatch(
        trackUserEvent(TrackedEvent.ContentItemEditing, {
          action: ContentItemEditingChangeAction.AssignContributors,
          origin: actionOrigin,
          assignees: getAssignedUserIds(newAssignment),
        }),
      );
    }

    if (isDueDateUpdated(oldAssigment, newAssignment)) {
      dispatch(
        trackUserEvent(TrackedEvent.ContentItemEditing, {
          action: ContentItemEditingChangeAction.ChangeDueDate,
          origin: actionOrigin,
        }),
      );
    }

    if (isNoteUpdated(oldAssigment, newAssignment)) {
      dispatch(
        trackUserEvent(TrackedEvent.ContentItemEditing, {
          action: ContentItemEditingChangeAction.Note,
          origin: actionOrigin,
        }),
      );
    }
  };
