import React, { createContext, useMemo } from 'react';

type Props = {
  readonly isLivePreviewEnabled: boolean;
};

export const LivePreviewConfigurationContext = createContext<Props>({
  isLivePreviewEnabled: false,
});
LivePreviewConfigurationContext.displayName = 'LivePreviewConfigurationContext';

export const LivePreviewConfigurationContextProvider: React.FC<React.PropsWithChildren<Props>> = ({
  isLivePreviewEnabled,
  children,
}) => {
  const contextValue = useMemo(
    () => ({
      isLivePreviewEnabled,
    }),
    [isLivePreviewEnabled],
  );

  return (
    <LivePreviewConfigurationContext.Provider value={contextValue}>
      {children}
    </LivePreviewConfigurationContext.Provider>
  );
};
