import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { Card } from '@kontent-ai/component-library/Card';
import { documentationLinks } from '../../../../../_shared/constants/documentationLinks.ts';
import { ActivateFeatureButton } from '../ActivateFeatureButton.tsx';
import { DeactivateFeatureButton } from '../DeactivateFeatureButton.tsx';
import { FeatureStatus } from '../FeatureStatus.tsx';

type Props = {
  readonly name: string;
  readonly isEnabled: boolean;
  readonly isActivationStateBeingChanged: boolean;
  readonly onActivate: () => void;
  readonly onDeactivate: () => void;
};

export const AiTranslationFeature = ({
  name,
  isEnabled,
  onActivate,
  onDeactivate,
  isActivationStateBeingChanged,
}: Props) => (
  <Card component="section">
    <Card.Headline renderAuxiliaryElements={() => <FeatureStatus isEnabled={isEnabled} />}>
      {name}
    </Card.Headline>

    <Card.Body>
      <p>
        Expand your reach and connect with a global audience effortlessly, using{' '}
        <OutwardLink href={documentationLinks.aiPoweredTranslation}>
          AI-powered translation directly in Kontent.ai
        </OutwardLink>
        . Translate your message and break language barriers with just a few clicks.
      </p>
    </Card.Body>

    <Card.Footer>
      {isEnabled ? (
        <DeactivateFeatureButton
          featureName={name}
          isDeactivating={isActivationStateBeingChanged}
          onClick={onDeactivate}
        />
      ) : (
        <ActivateFeatureButton
          featureName={name}
          isActivating={isActivationStateBeingChanged}
          onClick={onActivate}
        />
      )}
    </Card.Footer>
  </Card>
);
