import { Box } from '@kontent-ai/component-library/Box';
import { Card } from '@kontent-ai/component-library/Card';
import { SimpleStatusDefault } from '@kontent-ai/component-library/SimpleStatus';
import { Stack } from '@kontent-ai/component-library/Stack';
import {
  Spacing,
  Typography,
  colorTextDefault,
  colorTextHint,
} from '@kontent-ai/component-library/tokens';
import React from 'react';

interface IProps {
  readonly name: string;
  readonly description?: string;
}

export const ReadonlyTypeElement: React.FC<IProps> = ({ name, description }) => (
  <Card component="section" variant="disabled">
    <Card.Body>
      <Stack spacing={Spacing.M} align="start">
        <SimpleStatusDefault
          label="Predefined"
          tooltipText="This element can’t be changed."
          tooltipPlacement="right"
        />

        <Box component="h1" color={colorTextDefault} typography={Typography.HeadlineLarge}>
          {name}
        </Box>

        {description && (
          <Box color={colorTextHint} typography={Typography.Subheadline}>
            {description}
          </Box>
        )}
      </Stack>
    </Card.Body>
  </Card>
);

ReadonlyTypeElement.displayName = 'ReadonlyTypeElement';
