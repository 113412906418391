import React from 'react';
import { Link } from 'react-router-dom';
import { QuickTip } from '../../../../../_shared/components/infos/QuickTip.tsx';
import {
  EnvironmentRouteParams,
  TaxonomyGroupsRoute,
} from '../../../../../_shared/constants/routePaths.ts';
import { buildPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';

type Props = {
  readonly projectId: Uuid;
};

export const SitemapProductivityTip: React.FC<Props> = ({ projectId }) => (
  <QuickTip subtitle="Sitemap helps you categorize your content">
    <p>
      Create, reorder, and nest sitemap items to outline your content structure. Once you’re
      satisfied with the sitemap, you can use it to categorize and filter your content. This means
      you can assign each content item to a position in the sitemap so that everyone on your team
      knows where the item belongs.
    </p>
    <p>
      If you need to add business-specific content categories, use{' '}
      <Link to={buildPath<EnvironmentRouteParams>(TaxonomyGroupsRoute, { projectId })}>
        Taxonomy
      </Link>{' '}
      instead.
    </p>
  </QuickTip>
);
