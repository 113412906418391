import { ScrollAlignment, scrollElementsToView } from '@kontent-ai/DOM';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { useAttachRef } from '@kontent-ai/hooks';
import { Collection } from '@kontent-ai/utils';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useFocusRing } from '@react-aria/focus';
import { useFocusWithin, useInteractionModality } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import classNames from 'classnames';
import { forwardRef, memo, useContext, useEffect, useId, useRef } from 'react';
import useResizeObserver from 'use-resize-observer';
import { ScrollOptionsContext } from '../../../../../../_shared/components/AutoScroll/ScrollOptionsContext.tsx';
import {
  DataAttributes,
  getDataAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataAttributes.ts';
import {
  DataUiCollection,
  DataUiElement,
  getDataUiCollectionAttribute,
  getDataUiElementAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  getUserFriendlyDateString,
  toLocalTime,
} from '../../../../../../_shared/utils/dateTime/timeUtils.ts';
import { formatUserName } from '../../../../../../_shared/utils/users/usersUtils.ts';
import { IProjectContributor } from '../../../../../../data/models/users/ProjectContributor.ts';
import { NavigatedFromData } from '../../../../../contentInventory/content/stores/IContentAppStoreState.ts';
import {
  CommentThreadItemType,
  ICommentThreadItem,
  ICommentThreadItemContentModel,
} from '../../../../models/comments/CommentThreadItem.ts';
import { ICommentThread } from '../../../../models/comments/CommentThreads.ts';
import { ISuggestion, isSuggestion } from '../../../../models/comments/Suggestion.ts';
import { dateComparer, getCommentThreadTriggerElement } from '../../../../utils/commentUtils.ts';
import { CommentThreadCssClass } from '../../constants/uiConstants.ts';
import { CommentThreadItem } from '../../containers/comments/CommentThreadItem.tsx';
import { CommentThreadFocusRing } from './CommentThreadFocusRing.tsx';
import { FocusedCommentThreadScroller } from './FocusedCommentThreadScroller.tsx';
import { Reply } from './Reply.tsx';
import { NewCommentThreadItemHandle } from './threadItem/NewCommentThreadItem.tsx';
import { useAccessibleCommentThread } from './useAccessibleCommentThread.ts';

export function getThreadHeight(element: HTMLDivElement | undefined): number | null {
  if (!element) {
    return null;
  }

  if (element instanceof HTMLDivElement) {
    const threadElementRect = element.getBoundingClientRect();
    const threadHeight = Math.round(threadElementRect.height);
    return threadHeight;
  }

  return null;
}

type CommentThreadProps = {
  readonly canSuggest: boolean;
  readonly className: string;
  readonly commentThread: ICommentThread;
  readonly createdBy: IProjectContributor | null;
  readonly isHighlighted: boolean;
  readonly isInlineThreadWithRemovedContent?: boolean;
  readonly isResolved: boolean;
  readonly navigatedFrom: NavigatedFromData | null;
  readonly onBlur: () => void;
  readonly onBlurNewItemText: (isCommentPending: boolean) => void;
  readonly onCancelNewItem: () => void;
  readonly onFocus: () => void;
  readonly onHighlighted?: () => void;
  readonly onResized?: (threadId: Uuid, newHeight: number) => void;
  readonly onStartNewItem: (type: CommentThreadItemType) => void;
  readonly onSubmitNewItem: (
    type: CommentThreadItemType,
    content: ICommentThreadItemContentModel,
  ) => Promise<void>;
  readonly renderHeader?: (statusId: string) => React.ReactNode;
  readonly showReferenceForInlineThreads: boolean;
  readonly type: CommentThreadItemType | undefined;
  readonly withManagedFocus?: boolean;
};

export const CommentThread = memo(
  forwardRef<HTMLDivElement, CommentThreadProps>(
    (
      {
        canSuggest,
        className,
        commentThread,
        createdBy,
        isHighlighted,
        isInlineThreadWithRemovedContent,
        isResolved,
        navigatedFrom,
        onBlur,
        onBlurNewItemText,
        onCancelNewItem,
        onFocus,
        onResized,
        onStartNewItem,
        onSubmitNewItem,
        renderHeader,
        showReferenceForInlineThreads,
        type,
        withManagedFocus,
      },
      ref,
    ) => {
      const newCommentThreadItemRef = useRef<NewCommentThreadItemHandle>(null);
      const { refObject: commentThreadRef, refToForward } = useAttachRef(ref);

      const commentThreadStatusId = useId();

      const [rootComment, ...replies] = commentThread.threadItems;

      const threadCreatedAt = toLocalTime(rootComment?.createdAt);
      const userFriendlyDate = threadCreatedAt
        ? getUserFriendlyDateString(threadCreatedAt, new Date())
        : null;

      const ariaLabel = rootComment
        ? `${isSuggestion(rootComment) ? 'Suggestion' : 'Comment'} by ${formatUserName(createdBy)}${userFriendlyDate ? ` from ${userFriendlyDate}` : ''}`
        : null;

      const { commentThreadProps } = useAccessibleCommentThread(
        {
          'aria-label': ariaLabel ?? 'Comment thread',
          isDisabled: !isHighlighted || !withManagedFocus,
          onLeave: (returnFocus) => {
            if (returnFocus) {
              getCommentThreadTriggerElement(commentThread.id)?.focus();
            }
            onBlur();
          },
        },
        commentThreadRef,
      );

      const { isFocusVisible, focusProps: focusRingProps } = useFocusRing();

      const modality = useInteractionModality();

      const { focusWithinProps } = useFocusWithin({
        onFocusWithin: () => {
          // When someone navigates inside a comment with keyboard, we want to make sure that the comment gets focused
          // We don't want this in case of clicking into the comment, as it might reposition the
          // comment during the click to its buttons (e.g. Resolve), not allowing the click to finish
          if (!isHighlighted && modality !== 'pointer') {
            onFocus();
          }
        },
      });

      const { scrollOptions } = useContext(ScrollOptionsContext);

      useEffect(() => {
        if (commentThread.isReplying && commentThreadRef.current) {
          scrollElementsToView([commentThreadRef.current], {
            ...scrollOptions,
            // We prefer bottom alignment in case of reply to prevent it hiding behind the fold in case the comment thread is long
            alignment: ScrollAlignment.Bottom,
          });
        }
      }, [commentThread.isReplying, commentThreadRef, scrollOptions]);

      useResizeObserver({
        ref: commentThreadRef,
        onResize: ({ height }) => {
          if (height) {
            onResized?.(commentThread.id, height);
          }
        },
      });

      const onClick = (): void => {
        if (!isHighlighted) {
          onFocus();
          commentThreadRef.current?.focus();
        }
      };

      const lastApprovedSuggestion = Collection.getLast(
        commentThread.threadItems
          .filter(
            (comment: ICommentThreadItem) =>
              isSuggestion(comment) && !!comment.suggestionApprovedAt,
          )
          .sort((a: ISuggestion, b: ISuggestion) =>
            dateComparer(a.suggestionApprovedAt, b.suggestionApprovedAt),
          ),
      );

      const itemsBeingEdited = commentThread.threadItems.filter(
        (comment: ICommentThreadItem) => comment.isEditing,
      );
      const shouldRenderNewCommentThreadItem =
        !itemsBeingEdited.length &&
        !isResolved &&
        (isHighlighted ||
          commentThread.isReplying ||
          commentThread.isSubmitting ||
          !withManagedFocus);

      return (
        <div
          id={commentThread.id}
          className={classNames(
            CommentThreadCssClass,
            {
              'comment-thread--is-resolved': commentThread.resolvedAt,
              'comment-thread--is-highlighted': isHighlighted,
            },
            className,
          )}
          aria-describedby={commentThreadStatusId}
          onClick={onClick}
          ref={refToForward}
          {...mergeProps(focusWithinProps, commentThreadProps, focusRingProps)}
          {...getDataUiCollectionAttribute(DataUiCollection.CommentThreadItems)}
          {...getDataUiElementAttribute(DataUiElement.CommentThread)}
          {...getDataAttribute(DataAttributes.CommentThreadId, commentThread.id)}
        >
          <CommentThreadFocusRing isFocusVisible={isFocusVisible} />
          <Stack spacing={Spacing.L}>
            {renderHeader?.(commentThreadStatusId)}
            {rootComment && (
              <CommentThreadItem
                allowCopyLink={!renderHeader}
                allowResolve={!renderHeader}
                className="comment-thread__comment"
                commentThreadItem={rootComment}
                commentThread={commentThread}
                isThreadRoot
                showReferenceForInlineThreads={showReferenceForInlineThreads}
                isLastApprovedSuggestion={rootComment === lastApprovedSuggestion}
                isInlineThreadWithRemovedContent={isInlineThreadWithRemovedContent}
              />
            )}
            {!!replies.length && (
              <Stack component="ol" spacing={Spacing.L} aria-label="Replies">
                {replies.map((comment) => (
                  <li key={comment.id}>
                    <CommentThreadItem
                      allowCopyLink={!renderHeader}
                      className="comment-thread__comment"
                      commentThread={commentThread}
                      commentThreadItem={comment}
                      isInlineThreadWithRemovedContent={isInlineThreadWithRemovedContent}
                      isLastApprovedSuggestion={comment === lastApprovedSuggestion}
                      isThreadRoot={false}
                      showReferenceForInlineThreads={false}
                    />
                  </li>
                ))}
              </Stack>
            )}
          </Stack>

          {shouldRenderNewCommentThreadItem && (
            <Reply
              ref={newCommentThreadItemRef}
              canSuggest={canSuggest}
              commentThread={commentThread}
              isResolved={isResolved}
              onBlurNewItemText={onBlurNewItemText}
              onCancelNewItem={onCancelNewItem}
              onStartNewItem={onStartNewItem}
              onSubmitNewItem={onSubmitNewItem}
              type={type}
            />
          )}
          {isHighlighted && (
            <FocusedCommentThreadScroller
              focusedThread={commentThread}
              navigatedFrom={navigatedFrom}
            />
          )}
        </div>
      );
    },
  ),
);

CommentThread.displayName = 'CommentThread';
