import { ContentState } from 'draft-js';
import { ComponentProps, useCallback } from 'react';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { saveNewCommentThreadToServer } from '../../../actions/thunkContentItemEditingActions.ts';
import { NewCommentThread as NewCommentThreadComponent } from '../../../components/comments/threadItem/NewCommentThread.tsx';

export const NewCommentThread = (
  props: Pick<ComponentProps<typeof NewCommentThreadComponent>, 'autoFocus'>,
) => {
  const isSubmitting = useSelector(
    (s) => !!s.contentApp.editedContentItemVariantComments.newCommentThreadForm.isSubmitting,
  );

  const dispatch = useDispatch();

  const submit = useCallback(
    (commentContent: ContentState) => dispatch(saveNewCommentThreadToServer(commentContent)),
    [],
  );

  return <NewCommentThreadComponent {...props} onSubmit={submit} isSubmitting={isSubmitting} />;
};
