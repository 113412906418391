import { Typography, colorAlertText } from '@kontent-ai/component-library/tokens';
import styled, { css } from 'styled-components';

export const StyledInfoBarMetadata = styled.div<{ readonly isValid: boolean }>`
  ${Typography.Subheadline};
  ${({ isValid }) =>
    !isValid &&
    css`
    color: ${colorAlertText};
  `};
`;
