import { Box } from '@kontent-ai/component-library/Box';
import React, { memo } from 'react';
import { HtmlPageTitle } from '../../../../_shared/components/HtmlPageTitle.tsx';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { AppNames } from '../../../../_shared/constants/applicationNames.ts';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { SitemapNodesList } from '../containers/SitemapNodesList.tsx';
import { SitemapNotificationBar } from '../containers/SitemapNotificationBar.tsx';
import { SitemapEmptyState } from './messages/SitemapEmptyState.tsx';
import { SitemapProductivityTip } from './messages/SitemapProductivityTip.tsx';
import { SitemapSunsetWarning } from './messages/SitemapSunsetWarning.tsx';
import { SitemapWarning } from './messages/SitemapWarning.tsx';

type Props = {
  readonly isLoading: boolean;
  readonly onCreateNewButtonClicked: () => void;
  readonly projectContainsPublishedItems: boolean;
  readonly projectId: Uuid;
  readonly showEmptyStatePage: boolean;
  readonly showNotificationBar: boolean;
};

const SitemapListing: React.FC<Props> = ({
  isLoading,
  onCreateNewButtonClicked,
  projectContainsPublishedItems,
  projectId,
  showEmptyStatePage,
  showNotificationBar,
}) => {
  const renderContentPane = () => {
    if (isLoading) {
      return <Loader />;
    }

    if (showEmptyStatePage) {
      return (
        <div className="sitemap-listing__empty-state">
          <SitemapSunsetWarning projectId={projectId} />
          <SitemapEmptyState onCreateButtonNewClick={onCreateNewButtonClicked} />
        </div>
      );
    }

    return (
      <div className="row">
        <div className="col-md-24 col-lg-14">
          <div className="setting-section">
            <h2 className="setting-section__title">Sitemap</h2>
            <SitemapNodesList />
          </div>
        </div>
        <div className="col-md-24 col-lg-10">
          <Box position="sticky" top={0}>
            <SitemapSunsetWarning projectId={projectId} />
            {projectContainsPublishedItems ? (
              <SitemapWarning />
            ) : (
              <SitemapProductivityTip projectId={projectId} />
            )}
          </Box>
        </div>
      </div>
    );
  };

  return (
    <div className="canvas__workspace">
      <div className="canvas__content" {...getDataUiAppNameAttribute(DataUiAppName.SitemapListing)}>
        <HtmlPageTitle appName={AppNames.Sitemap} />
        {showNotificationBar && <SitemapNotificationBar />}
        <div className="canvas__content-pane">{renderContentPane()}</div>
      </div>
    </div>
  );
};

SitemapListing.displayName = 'SitemapListing';

const SitemapListingMemoized = memo(SitemapListing);
export { SitemapListingMemoized as SitemapListing };
