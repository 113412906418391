import { ItemId } from '@kontent-ai/component-library/Selects';
import { assert } from '@kontent-ai/utils';

export const getSectionItemId = (itemId: string, section: string): ItemId => `${section}_${itemId}`;

export const getOriginalItemId = (sectionItemId: ItemId): string => {
  const itemId = /_(.*)$/.exec(sectionItemId)?.[1];

  assert(
    itemId !== undefined,
    () =>
      `Failed to parse the original item id from the SingleSelect section. Given sectionItemId: ${sectionItemId}.`,
  );

  return itemId;
};
