import React from 'react';
import { TypeElementSummary } from '../../../components/typeElements/shared/TypeElementSummary.tsx';
import { DefaultValueSetSummary } from '../../../constants/summaryMessageTemplates.ts';
import { IDateTimeTypeElementData } from '../../../models/elements/DateTimeTypeElementData.ts';
import { IBaseTypeElementSummaryProps } from '../../../types/IBaseTypeElementSummaryProps.type.ts';
import { hasDateTimeDefaultValue } from '../../../utils/typeElementDefaultValueUtils.ts';
import {
  getBaseTypeElementSummarySegments,
  joinSummarySegments,
} from '../../../utils/typeElementMessagesUtils.ts';

interface IDateTimeTypeElementSummaryProps
  extends IBaseTypeElementSummaryProps<IDateTimeTypeElementData> {}

export const getDateTimeTypeElementSummary = (
  typeElementData: IDateTimeTypeElementData,
): string => {
  const summarySegments: Array<string> = [...getBaseTypeElementSummarySegments(typeElementData)];

  if (hasDateTimeDefaultValue(typeElementData)) {
    summarySegments.push(DefaultValueSetSummary);
  }

  return joinSummarySegments(summarySegments);
};

export const DateTimeTypeElementSummary: React.FC<IDateTimeTypeElementSummaryProps> = (props) => {
  const summaryText = getDateTimeTypeElementSummary(props.typeElementData);

  return <TypeElementSummary summaryText={summaryText} {...props} />;
};
