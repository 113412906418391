import React from 'react';
import { BarItemActionButtons } from '../../../../../_shared/components/BarItems/BarItemActionButtons.tsx';
import { BarItemExpandedSimpleHeader } from '../../../../../_shared/components/BarItems/BarItemExpandedSimpleHeader.tsx';
import { HotkeysHandler } from '../../../../../_shared/components/Hotkeys/HotkeysHandler.tsx';
import { ShortcutSymbols } from '../../../../../_shared/constants/shortcutSymbols.ts';
import { useScrollIntoViewOnMount } from '../../../../../_shared/hooks/useScrollIntoViewOnMount.ts';
import { HookFormProps } from '../../../../../_shared/types/hookFormProps.type.ts';
import {
  DataUiAction,
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ITaxonomyTermFormShape } from '../../models/ITaxonomyTermFormShape.type.ts';
import { TermFormFields } from './TermFormFields.tsx';

type Props = {
  readonly formProps: HookFormProps<ITaxonomyTermFormShape>;
  readonly onCancel: () => void;
  readonly onSubmit: () => void;
};

export const TermCreator: React.FC<Props> = ({ formProps, onCancel, onSubmit }) => {
  const scrollRef = useScrollIntoViewOnMount<HTMLDivElement>();
  return (
    <HotkeysHandler
      className="bar-item__wrapper"
      handlers={{
        onEscape: onCancel,
        onEnter: onSubmit,
      }}
      {...getDataUiElementAttribute(DataUiElement.BarItemForm)}
    >
      <div className="bar-item__pane bar-item__pane--is-expanded" ref={scrollRef}>
        <BarItemExpandedSimpleHeader dataUiObjectName="" isDraggable renderTitle={() => ''} />
        <TermFormFields formProps={formProps} />
        <BarItemActionButtons
          secondaryAction={{
            handler: onCancel,
            text: 'Cancel',
            dataUIActionName: DataUiAction.Cancel,
            shortcut: ShortcutSymbols.Escape,
          }}
          primaryAction={{
            text: 'Confirm',
            handler: onSubmit,
            dataUIActionName: DataUiAction.Confirm,
            shortcut: ShortcutSymbols.Enter,
          }}
        />
      </div>
    </HotkeysHandler>
  );
};

TermCreator.displayName = 'TermCreator';
