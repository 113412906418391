import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { showErrorNotification } from '../../../../_shared/actions/sharedActions.ts';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import {
  EditTaxonomyGroupRoute,
  EditTaxonomyGroupRouteParams,
} from '../../../../_shared/constants/routePaths.ts';
import { HandleUnsavedFormOnNavigation } from '../../../../_shared/containers/HandleUnsavedFormOnNavigation.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { compose } from '../../../../_shared/utils/func/compose.ts';
import { buildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { TaxonomyTermEditorIsNotConfirmed } from '../../shared/constants/errorMessageTemplates.ts';
import { showCreateNewTermForm, taxonomyCreatorInitialized } from '../actions/taxonomyActions.ts';
import { createNewTaxonomyGroup } from '../actions/thunkTaxonomyActions.ts';
import { TaxonomyGroupEditor as TaxonomyGroupCreatorComponent } from '../components/TaxonomyGroupEditor.tsx';
import { TaxonomyEditorStatus } from '../models/TaxonomyEditorStatus.ts';
import { getTaxonomyAppState } from '../selectors/getTaxonomyAppState.ts';
import { isTaxonomyAppBeingSaved } from '../selectors/isTaxonomyAppBeingSaved.ts';
import { isTaxonomyAppLoading } from '../selectors/isTaxonomyAppLoading.ts';
import { isTaxonomyTermEditorClosed } from '../selectors/isTaxonomyTermEditorClosed.ts';
import { hasAnyTerms } from '../utils/taxonomyUtils.ts';

export const TaxonomyGroupCreator = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(taxonomyCreatorInitialized());
  }, []);

  const history = useHistory();
  const projectId = useSelector((s) => s.sharedApp.currentProjectId);

  const onCreateNewButtonClicked = () => {
    dispatch(showCreateNewTermForm());
  };

  const isBeingSaved = useSelector(isTaxonomyAppBeingSaved);
  const isLoading = useSelector(isTaxonomyAppLoading);
  const hasUnsavedChanges = useSelector(
    compose(({ status }) => status === TaxonomyEditorStatus.Modified, getTaxonomyAppState),
  );
  const showEmptyStatePage = useSelector(
    (state) =>
      !hasAnyTerms(state.taxonomyApp.editedTaxonomyGroup) &&
      !state.taxonomyApp.isCreateNewFormDisplayed,
  );
  const taxonomyGroupName = useSelector((state) => state.taxonomyApp.editedTaxonomyGroup.name);
  const isTermEditingFinished = useSelector(isTaxonomyTermEditorClosed);

  const unsavedNavigationHandler = async (
    onSuccess: () => void,
    onFail: () => void,
  ): Promise<void> => {
    try {
      if (isTermEditingFinished) {
        await dispatch(createNewTaxonomyGroup());
        onSuccess();
      } else {
        dispatch(showErrorNotification(TaxonomyTermEditorIsNotConfirmed));
        onFail();
      }
    } catch {
      onFail();
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <HandleUnsavedFormOnNavigation
        hasUnsavedChanges={hasUnsavedChanges}
        isBeingSaved={isBeingSaved}
        onSaveChanges={unsavedNavigationHandler}
      />
      <TaxonomyGroupCreatorComponent
        isUsedInPublishedContent={false}
        onCreateNewButtonClicked={onCreateNewButtonClicked}
        onSave={async () => {
          try {
            if (isTermEditingFinished) {
              const createdTaxonomyGroup = await dispatch(createNewTaxonomyGroup());
              history.push(
                buildPath<EditTaxonomyGroupRouteParams>(EditTaxonomyGroupRoute, {
                  projectId,
                  taxonomyGroupId: createdTaxonomyGroup.id,
                }),
              );
            } else {
              dispatch(showErrorNotification(TaxonomyTermEditorIsNotConfirmed));
            }
          } catch {
            return;
          }
        }}
        showEmptyStatePage={showEmptyStatePage}
        taxonomyGroupName={taxonomyGroupName}
      />
    </>
  );
};
