import { History } from 'history';
import { trackUserEvent } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { ProjectOverviewEventTypes } from '../../../../../_shared/models/TrackUserEventData.ts';
import { getContentItemPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { createNewContentItem } from '../../../../itemEditor/features/NewContentItem/actions/thunkNewContentItemActions.ts';
import { createContentItemFromContentTypeCreator } from '../../../../itemEditor/features/NewContentItem/actions/thunks/createContentItemFromContentType.ts';

const redirectToItem = (history: History, itemId: Uuid, isLivePreviewPreferred: boolean): void => {
  const contentItemPath = getContentItemPath(
    history.location.pathname,
    itemId,
    isLivePreviewPreferred,
  );
  history.push(contentItemPath);
};

export const createNewContentItemFromProjectOverview = createContentItemFromContentTypeCreator({
  createNewContentItem,
  redirectToItem,
  trackItemCreated: (contentTypeSection, collectionSection) =>
    trackUserEvent(TrackedEvent.ProjectOverview, {
      action: ProjectOverviewEventTypes.CreateContentItem,
      'collection-section': collectionSection,
      'content-type-section': contentTypeSection,
    }),
});
