import { Box } from '@kontent-ai/component-library/Box';
import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { noOperation } from '@kontent-ai/utils';
import React from 'react';
import { StyledAssetTileToolbar } from './components/StyledAssetTileToolbar.tsx';

export interface IToolbarPublicProps {
  readonly isDragging?: boolean;
  readonly renderToolbarActions: () => React.ReactNode;
  readonly renderDragButton?: () => React.ReactNode;
}

interface IAssetTileToolbarProps extends IToolbarPublicProps {}

export const AssetTileToolbar: React.FC<IAssetTileToolbarProps> = ({
  isDragging,
  renderToolbarActions,
  renderDragButton,
}) => {
  const handleToolbarClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <StyledAssetTileToolbar onClick={handleToolbarClick}>
      <Box padding={Spacing.S}>
        <Row>
          <Column width="content">
            {renderDragButton ? (
              renderDragButton()
            ) : (
              <QuinaryButton onClick={() => noOperation()} disabled tooltipText="">
                <QuinaryButton.Icon
                  icon={Icons.DotsVertical}
                  screenReaderText="Drag asset button"
                />
              </QuinaryButton>
            )}
          </Column>
          <Column>{!isDragging && <Row alignX="end">{renderToolbarActions()}</Row>}</Column>
        </Row>
      </Box>
    </StyledAssetTileToolbar>
  );
};

AssetTileToolbar.displayName = 'AssetTileToolbar';
