import { Inline } from '@kontent-ai/component-library/Inline';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { useHover } from '@react-aria/interactions';
import classNames from 'classnames';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { NotTranslatedBadge } from '../../../../_shared/components/Workflow/NotTranslatedBadge.tsx';
import { IconName } from '../../../../_shared/constants/iconEnumGenerated.ts';
import { Icon } from '../../../../_shared/uiComponents/Icon/Icon.tsx';
import { WorkflowBadges } from '../../../../_shared/uiComponents/WorkflowBadges/WorkflowBadges.tsx';
import {
  isPublishedWorkflowStep,
  isScheduledToPublishWorkflowStep,
} from '../../../../_shared/utils/contentItemUtils.ts';
import {
  VariantCompletionStatus,
  getContentVariantState,
  isVariantPublished,
} from '../../../../_shared/utils/contentItemVariantUtils.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IListingContentItem } from '../../../../data/models/listingContentItems/IListingContentItem.ts';
import { UnfinishedStatusIcon } from '../../../contentInventory/content/features/ContentItemInventory/components/ItemInventoryScrollTable/Cells/UnfinishedStatusIcon.tsx';
import { IEditedContentItem } from '../../../itemEditor/models/contentItem/edited/EditedContentItem.ts';
import { getNotEmptyItemName } from '../../../itemEditor/stores/utils/contentItemHelperMethods.ts';
import { TreeItemMoreActions } from '../../containers/TreeItemMoreActions.tsx';
import { NodeId } from '../../types/nodeId.type.ts';
import { TreeItemExpandButton } from './TreeItemExpandButton.tsx';

export type WebSpotlightTreeItemProps = Readonly<{
  depth: number;
  editedContentItem: IEditedContentItem | null;
  hasSubpages: boolean;
  isExpanded: boolean;
  isFocused: boolean;
  isLoading: boolean;
  isSelected: boolean;
  isTranslated: boolean;
  item: IListingContentItem;
  itemPath: string;
  nodeId: NodeId;
  onNodeSelected: () => void;
  onToggle: () => void;
  selectedLanguageId: Uuid;
}>;

const getTreeItemName = (
  editedContentItem: IEditedContentItem | null,
  item: IListingContentItem,
) =>
  editedContentItem && editedContentItem.id === item.item.id
    ? getNotEmptyItemName(editedContentItem.name)
    : getNotEmptyItemName(item.item.name);

const WebSpotlightTreeItem: React.FC<WebSpotlightTreeItemProps> = ({
  depth,
  editedContentItem,
  hasSubpages,
  isExpanded,
  isFocused,
  isLoading,
  isSelected,
  isTranslated,
  item,
  itemPath,
  nodeId,
  onNodeSelected,
  onToggle,
  selectedLanguageId,
}) => {
  const { isHovered, hoverProps } = useHover({});
  const itemName = getTreeItemName(editedContentItem, item);
  const hasPublishedVersion = isVariantPublished(item.variant);
  const isInPublishedStep = isPublishedWorkflowStep(item.variant?.assignment.workflowStatus);
  const isInScheduledToPublishStep = isScheduledToPublishWorkflowStep(
    item.variant?.assignment.workflowStatus,
  );
  const isVariantUnfinished =
    getContentVariantState(item.variant) === VariantCompletionStatus.unfinished;

  const handleNodeSelected = (e: React.MouseEvent): void => {
    e.stopPropagation();
    e.preventDefault();
    onNodeSelected();
  };

  return (
    <Link
      className={classNames('web-spotlight-tree__item', {
        'web-spotlight-tree__item--is-disabled': isLoading,
        'web-spotlight-tree__item--is-focused': isFocused,
        'web-spotlight-tree__item--is-selected': isSelected,
      })}
      onClick={handleNodeSelected}
      to={itemPath}
      {...getDataUiElementAttribute(DataUiElement.WebSpotlightTreeItem)}
      {...hoverProps}
    >
      <div className="web-spotlight-tree__item-name">
        {depth !== 0 &&
          (isLoading ? (
            <Icon
              iconName={IconName.Spinner}
              className="spin-500 web-spotlight-tree__item-expand-loader"
            />
          ) : (
            <TreeItemExpandButton
              hasSubpages={hasSubpages}
              isExpanded={isExpanded}
              isVariantTranslated={isTranslated}
              onClick={onToggle}
            />
          ))}
        {isVariantUnfinished && <UnfinishedStatusIcon tooltipPlacement="top" />}
        <div className="web-spotlight-tree__item-name-text-wrapper">
          <span title={itemName} className="web-spotlight-tree__item-name-text">
            {itemName}
          </span>
        </div>
      </div>
      <Inline align="center" noWrap spacingX={Spacing.XS}>
        <TreeItemMoreActions
          isPublished={hasPublishedVersion && isInPublishedStep}
          isScheduledToPublish={isInScheduledToPublishStep}
          isTranslated={isTranslated}
          itemId={item.item.id}
          itemPath={itemPath}
          nodeId={nodeId}
          onNodeSelected={onNodeSelected}
          typeId={item.item.typeId}
          variantId={item.variant?.id.variantId ?? selectedLanguageId}
          visible={isHovered || isFocused || isSelected}
        />
        <WorkflowBadges item={item} renderNotTranslatedBadge={() => <NotTranslatedBadge />} />
      </Inline>
    </Link>
  );
};

WebSpotlightTreeItem.displayName = 'WebSpotlightTreeItem';

const WebSpotlightTreeItemMemo = memo(WebSpotlightTreeItem);

export { WebSpotlightTreeItemMemo as WebSpotlightTreeItem };
