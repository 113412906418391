import { getCurrentProject } from '../../selectors/userProjectsInfoSelectors.ts';
import { IStore } from '../../stores/IStore.type.ts';
import { Capability, ICapability, getUserCapability } from './capability.ts';

export const withCapabilityFactory =
  (userCapability: ICapability) =>
  (
    capability: Capability,
    allowedElement: JSX.Element,
    notAllowedElement: JSX.Element | null = null,
  ) =>
    userCapability.can(capability) ? allowedElement : notAllowedElement;

export const currentUserHasCapabilities = (
  state: IStore,
  ...requiredCapabilities: ReadonlyArray<Capability>
): boolean => {
  const currentProject = getCurrentProject(state);
  const capabilities = getUserCapability(currentProject);

  return requiredCapabilities.every(capabilities.can);
};
