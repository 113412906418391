import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import classNames from 'classnames';
import React from 'react';
import { ConnectDragSource } from 'react-dnd';
import { FeatureState, FeatureStateTag } from '../../../../_shared/components/FeatureStateTag.tsx';
import { IconName } from '../../../../_shared/constants/iconEnumGenerated.ts';
import { Icon } from '../../../../_shared/uiComponents/Icon/Icon.tsx';
import { getDataUiObjectNameAttribute } from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { TypeElementType } from '../../../contentInventory/content/models/ContentItemElementType.ts';

export interface ITypeElementLibraryItemBaseProps {
  readonly disabledMessage?: string;
  readonly elementType: TypeElementType;
  readonly featureState?: FeatureState;
  readonly iconName: IconName;
  readonly isDisabled?: boolean;
  readonly name: string;
  readonly onClick: (elementType: TypeElementType) => void;
}

interface ITypeElementLibraryItemProps extends ITypeElementLibraryItemBaseProps {
  readonly connectDragSource?: ConnectDragSource;
}

export const TypeElementLibraryItem: React.FC<ITypeElementLibraryItemProps> = ({
  connectDragSource,
  disabledMessage,
  elementType,
  featureState,
  iconName,
  isDisabled,
  name,
  onClick,
}) => (
  <Tooltip placement="bottom" text={isDisabled ? disabledMessage : undefined}>
    <div
      ref={connectDragSource}
      onClick={isDisabled ? undefined : () => onClick(elementType)}
      className={classNames('toolset__element', {
        'toolset__element--disabled': isDisabled,
      })}
      {...getDataUiObjectNameAttribute(elementType)}
    >
      <div className="toolset__element-pane">
        <Icon iconName={iconName} className="toolset__element-icon" />
        <span className="toolset__element-text">{name}</span>
        {featureState && <FeatureStateTag featureState={featureState} />}
      </div>
    </div>
  </Tooltip>
);

TypeElementLibraryItem.displayName = 'TypeElementLibraryItem';
