import { AvatarSize } from '@kontent-ai/component-library/Avatar';
import { ComponentProps } from 'react';
import { DataTableRow } from '../../../../../_shared/components/DataTable/DataTableRow.tsx';
import { LinkDataTableCell } from '../../../../../_shared/components/DataTable/LinkDataTableCell.tsx';
import { LastUpdatedAt } from '../../../../../_shared/components/LastUpdatedAt.tsx';
import { UserAvatar } from '../../../../../_shared/components/users/UserAvatar.tsx';
import { CommonUser } from '../../../../../_shared/utils/users/usersUtils.ts';
import { PublishedListingContentItemRecord } from '../../../../../data/models/listingContentItems/PublishedListingContentItemRecord.ts';

type PublishedItemsWidgetDetailRowProps = Readonly<{
  collectionName: string;
  contentTypeName: string;
  item: PublishedListingContentItemRecord;
  language: string;
  onItemClick?: () => void;
  pathToItem: string;
  publisher: CommonUser;
  rowFocusLinkAriaLabel: string;
}>;

export const PublishedItemsWidgetDetailRow = ({
  collectionName,
  contentTypeName,
  item,
  language,
  onItemClick,
  pathToItem,
  publisher,
  rowFocusLinkAriaLabel,
}: PublishedItemsWidgetDetailRowProps) => {
  const linkProps = {
    linkPath: pathToItem,
    onClick: onItemClick,
  } satisfies ComponentProps<typeof LinkDataTableCell>;

  return (
    <DataTableRow id={item.id} key={item.id} dataUiObjectName={item.name}>
      <LinkDataTableCell {...linkProps} focusableRowLinkAriaLabel={rowFocusLinkAriaLabel}>
        {' '}
        {item.name}{' '}
      </LinkDataTableCell>
      <LinkDataTableCell {...linkProps}>{contentTypeName}</LinkDataTableCell>
      <LinkDataTableCell {...linkProps}>{collectionName}</LinkDataTableCell>
      <LinkDataTableCell {...linkProps}>{language}</LinkDataTableCell>
      <LinkDataTableCell {...linkProps}>
        <UserAvatar user={publisher} size={AvatarSize.S} />
      </LinkDataTableCell>
      <LinkDataTableCell {...linkProps}>
        <LastUpdatedAt time={item.lastPublishedAt} />
      </LinkDataTableCell>
    </DataTableRow>
  );
};
