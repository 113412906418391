import { Box } from '@kontent-ai/component-library/Box';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Paper } from '@kontent-ai/component-library/Paper';
import { useOurFocusRing } from '@kontent-ai/component-library/hooks';
import { BaseColor, IconSize, Spacing, ZIndex } from '@kontent-ai/component-library/tokens';
import { useFocusWithin, useHover } from '@react-aria/interactions';
import React, { useState } from 'react';
import { StyledInfoBar } from './AssetTileInfoBar/components/StyledInfoBar.tsx';
import { StyledInfoBarMetadata } from './AssetTileInfoBar/components/StyledInfoBarMetadata.tsx';
import { AssetTileToolbar, IToolbarPublicProps } from './AssetTileToolbar/AssetTileToolbar.tsx';
import { StyledAssetTile } from './components/StyledAssetTile.tsx';
import { StyledBackground } from './components/StyledBackground.tsx';
import { StyledBorders } from './components/StyledBorders.tsx';
import { StyledDraggingPlaceholder } from './components/StyledDraggingPlaceholder.tsx';
import { StyledFocusHelper } from './components/StyledFocusHelper.tsx';
import { StyledNoAccessImageWrapper } from './components/StyledImageWrapper.tsx';
import { StyledInteractionHelper } from './components/StyledInteractionHelper.tsx';
import { StyledShadowAnimationHelper } from './components/StyledShadowAnimationHelper.tsx';
import { ToolbarVisibilityHelper } from './components/ToolbarVisibilityHelper.tsx';
import { AssetTileReadonlyState } from './enums/assetTileReadonlyStateEnum.ts';
import { AssetTileType } from './enums/assetTileTypeEnum.ts';

interface INoAccessAssetTileWithoutHTMlAttrsProps extends IToolbarPublicProps {
  readonly children?: never;
  readonly readonlyState?: AssetTileReadonlyState;
}

export const NoAccessAssetTile = React.forwardRef<
  HTMLDivElement,
  INoAccessAssetTileWithoutHTMlAttrsProps
>(({ isDragging, renderToolbarActions, renderDragButton, readonlyState }, forwardedRef) => {
  const [isFocusedWithin, setIsFocusedWithin] = useState(false);
  const { focusWithinProps } = useFocusWithin({
    onFocusWithinChange: (isFocusWithin) => setIsFocusedWithin(isFocusWithin),
  });

  const { isFocusVisible, focusProps } = useOurFocusRing();

  const { isHovered, hoverProps } = useHover({});

  return (
    <StyledDraggingPlaceholder isDragging={isDragging}>
      <StyledBorders
        isDragging={isDragging}
        readonlyState={readonlyState}
        ref={forwardedRef}
        {...hoverProps}
      >
        <StyledInteractionHelper tabIndex={0} {...focusProps} />
        <StyledFocusHelper isFocusVisible={isFocusVisible} />
        <StyledShadowAnimationHelper isHovered={isHovered} />
        <StyledBackground as={Paper} readonlyState={readonlyState}>
          <StyledAssetTile>
            <StyledNoAccessImageWrapper>
              <Icons.DocBanSign color={BaseColor.Yellow30} size={IconSize.XXXL} />
            </StyledNoAccessImageWrapper>
            <ToolbarVisibilityHelper
              isVisible={isHovered || isFocusVisible || isFocusedWithin || isDragging}
              isDragging={isDragging}
              readonlyState={readonlyState}
              {...focusWithinProps}
            >
              <AssetTileToolbar
                isDragging={isDragging}
                renderToolbarActions={renderToolbarActions}
                renderDragButton={renderDragButton}
              />
            </ToolbarVisibilityHelper>
            <StyledInfoBar
              isTileHovered={isHovered}
              readonlyState={readonlyState}
              tileType={AssetTileType.WithActions}
            >
              <Box padding={Spacing.L} position="relative" zIndex={ZIndex.OneHundred}>
                <StyledInfoBarMetadata isValid>You can’t view this asset.</StyledInfoBarMetadata>
                <br />
                <br />
              </Box>
            </StyledInfoBar>
          </StyledAssetTile>
        </StyledBackground>
      </StyledBorders>
    </StyledDraggingPlaceholder>
  );
});

NoAccessAssetTile.displayName = 'NoAccessAssetTile';
