import styled from 'styled-components';
import { IUserInfo } from '../../../../../../../_shared/models/UserInfo.ts';
import {
  formatCurrentUserName,
  userLastNameComparer,
} from '../../../../../../../_shared/utils/users/usersUtils.ts';

const StyledList = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
`;

const StyledItem = styled.li`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

type Props = {
  readonly contributors: ReadonlySet<IUserInfo>;
  readonly currentUserId: UserId;
};

export const ContributorsList = ({ contributors, currentUserId }: Props) => (
  <StyledList>
    {[...contributors].sort(userLastNameComparer).map((contributor) => {
      const userName = formatCurrentUserName(currentUserId, false)(contributor);

      return (
        <StyledItem key={contributor.userId} title={userName}>
          {userName}
        </StyledItem>
      );
    })}
  </StyledList>
);
