import { memoize } from '@kontent-ai/memoization';
import { alphabetically, notNull } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { ISubscriptionAdmin } from '../../../applications/subscriptionManagement/shared/models/SubscriptionAdmin.ts';
import {
  ISubscription,
  SubscriptionStatus,
} from '../../../data/models/subscriptions/Subscription.ts';

export const getAdministratedSubscriptions = memoize.maxOne(
  (
    administratedIds: Immutable.Set<Uuid>,
    subscriptions: Immutable.Map<Uuid, ISubscription>,
  ): ReadonlyArray<ISubscription> =>
    administratedIds
      .toArray()
      .map((subscriptionId) => subscriptions.get(subscriptionId) ?? null)
      .filter(notNull),
);

export const getSubscriptionsSortedByName = memoize.maxOne(
  (subscriptions: ReadonlyArray<ISubscription>): ReadonlyArray<ISubscription> => {
    return [...subscriptions].sort((a, b) =>
      alphabetically(a.subscriptionName, b.subscriptionName),
    );
  },
);

export const getActiveSubscriptions = memoize.maxOne(
  (subscriptions: ReadonlyArray<ISubscription>): ReadonlyArray<ISubscription> =>
    subscriptions.filter((subscription) => subscription.isActive),
);

export const getExpiredSubscriptions = memoize.maxOne(
  (subscriptions: ReadonlyArray<ISubscription>): ReadonlyArray<ISubscription> =>
    subscriptions.filter(isSubscriptionExpired),
);

export const getSubscriptionAdministrators = (
  administrators: Immutable.Map<Uuid, ReadonlyArray<ISubscriptionAdmin>>,
  subscriptionId: Uuid,
): ReadonlyArray<ISubscriptionAdmin> => {
  return administrators.get(subscriptionId) || [];
};

export function isSubscriptionCanceled(subscription: ISubscription): boolean {
  return subscription.status === SubscriptionStatus.Canceled;
}

export function isSubscriptionExpired(subscription: ISubscription): boolean {
  return subscription.status === SubscriptionStatus.Expired;
}

export function isSubscriptionSuspended(subscription: ISubscription): boolean {
  return subscription.status === SubscriptionStatus.Suspended;
}

export function areAllSubscriptionsExpired(subscriptions: ReadonlyArray<ISubscription>): boolean {
  if (subscriptions.length === 0) {
    return false;
  }

  return !subscriptions.some((subscription: ISubscription) => !isSubscriptionExpired(subscription));
}

export function areAllSubscriptionsCanceled(subscriptions: ReadonlyArray<ISubscription>): boolean {
  if (subscriptions.length === 0) {
    return true;
  }

  return !subscriptions.some(
    (subscription: ISubscription) => !isSubscriptionCanceled(subscription),
  );
}
