import styled from 'styled-components';
import {
  assetTileBorderRadius,
  assetTilePreviewHeight,
  assetTileWidth,
} from '../decisionTokens.ts';
import { AssetDimension } from '../types/assetDimension.type.ts';

interface IStyledImageContainerProps {
  readonly url: string;
  readonly assetDimension: AssetDimension;
}

const getBackgroundSizePropValue = ({ assetDimension }: IStyledImageContainerProps) => {
  if (assetDimension.height > assetTilePreviewHeight && assetDimension.width > assetTileWidth) {
    return 'cover';
  }
  if (assetDimension.height < assetTilePreviewHeight && assetDimension.width < assetTileWidth) {
    return 'initial';
  }
  return 'contain';
};

export const StyledImage = styled.div<IStyledImageContainerProps>`
  height: 100%;
  width: 100%;
  border-top-left-radius: ${assetTileBorderRadius};
  border-top-right-radius: ${assetTileBorderRadius};
  background-image: url("${({ url }) => url}");
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: ${getBackgroundSizePropValue};
`;
