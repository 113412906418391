import {
  IBaseSelectItem,
  RenderSelectMenuOptionProps,
} from '@kontent-ai/component-library/Selects';
import React from 'react';
import { areSpacesInCollectionsEnabled } from '../../applications/environmentSettings/selectors/allowedFeaturesSelectors.ts';
import { useSpaceData } from '../../applications/environmentSettings/spaces/hooks/useSpaceData.ts';
import { areSpacesEnabledForCurrentProject } from '../../applications/environmentSettings/utils/allowedFeaturesUtils.ts';
import { CollectionSelectOption } from '../components/CollectionSelectOption.tsx';
import { useSelector } from '../hooks/useSelector.ts';
import { getCurrentProjectPlan } from '../selectors/userProjectsInfoSelectors.ts';

type Props = RenderSelectMenuOptionProps<IBaseSelectItem> & {
  readonly collectionId: Uuid;
};

const CollectionSelectOptionContainer: React.FC<Props> = ({ collectionId, ...restProps }) => {
  const spaceNamesByCollections = useSpaceData(collectionId);
  const areSpacesEnabled = useSelector(
    (s) =>
      areSpacesEnabledForCurrentProject(getCurrentProjectPlan(s)) &&
      areSpacesInCollectionsEnabled(s),
  );

  return (
    <CollectionSelectOption
      collectionId={collectionId}
      spaceData={areSpacesEnabled ? spaceNamesByCollections : undefined}
      {...restProps}
    />
  );
};

CollectionSelectOptionContainer.displayName = 'CollectionSelectOptionContainer';

export { CollectionSelectOptionContainer as CollectionSelectOption };
