import { Box } from '@kontent-ai/component-library/Box';
import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { SingleSelect } from '@kontent-ai/component-library/SingleSelect';
import { GridListMenu, useGridListMenuState } from '@kontent-ai/component-library/VerticalMenu';
import { gridUnit } from '@kontent-ai/component-library/tokens';
import { RefObject } from 'react';
import {
  optionMaxWidth,
  projectMenuTippyOptions,
} from '../../../../../../../_shared/components/ProjectMenu/constants.ts';
import { StatefulDropDown } from '../../../../../../../_shared/uiComponents/DropDown/StatefulDropDown.tsx';
import {
  DataUiAction,
  DataUiCollection,
  DataUiElement,
  getDataUiActionAttribute,
  getDataUiCollectionAttribute,
  getDataUiElementAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

type SpaceMenuItem = {
  id: string;
  label: string;
};

type Props = {
  readonly selectedSpaceId: Uuid | null;
  readonly onSelectionChange: (spaceId: Uuid | null) => void;
  readonly spaceMenuItems: ReadonlyArray<SpaceMenuItem>;
};

const spaceSelectorMaxWidth = 33 * gridUnit;

export const OldSpaceSelector = ({ onSelectionChange, selectedSpaceId, spaceMenuItems }: Props) => {
  return (
    <Box maxWidth={spaceSelectorMaxWidth}>
      <SingleSelect
        aria-label="Select space"
        items={spaceMenuItems}
        onSelectionChange={onSelectionChange}
        selectedItemId={selectedSpaceId}
        {...getDataUiElementAttribute(DataUiElement.SpaceSelector)}
      />
    </Box>
  );
};

export const SpaceSelector = ({ onSelectionChange, selectedSpaceId, spaceMenuItems }: Props) => {
  const { state, items } = useGridListMenuState([
    {
      id: 'spaces',
      items: spaceMenuItems.map((item) => ({
        id: item.id,
        isSelected: item.id === selectedSpaceId,
        label: item.label,
        type: 'item',
      })),
      label: 'Spaces',
      type: 'section',
    },
  ]);

  const selectedSpaceLabel = spaceMenuItems.find((item) => item.id === selectedSpaceId)?.label;

  return (
    <StatefulDropDown
      optionListDataUiAttributes={getDataUiCollectionAttribute(DataUiCollection.Spaces)}
      renderContent={(hideMenu) => (
        <GridListMenu
          aria-label="Space select"
          state={state}
          items={items}
          maxWidth={optionMaxWidth}
          renderItem={({ item }) => (
            <GridListMenu.Item
              item={item}
              state={state}
              onPress={() => {
                hideMenu();
                if (item.value) {
                  onSelectionChange(item.value.id);
                }
              }}
            />
          )}
        />
      )}
      renderSelectedOption={(ref: RefObject<HTMLButtonElement>, toggleListVisibility, isOpen) => (
        <QuinaryButton
          activated={isOpen}
          aria-label={selectedSpaceLabel ? 'Space' : 'Select space'}
          onClick={toggleListVisibility}
          ref={ref}
          tooltipText="Space"
          {...getDataUiActionAttribute(DataUiAction.SelectSpace)}
        >
          <QuinaryButton.Icon icon={Icons.DialogWindow} />
          {selectedSpaceLabel || 'Select space'}
          <QuinaryButton.Chevron direction={isOpen ? 'up' : 'down'} />
        </QuinaryButton>
      )}
      tippyOptions={projectMenuTippyOptions}
    />
  );
};
