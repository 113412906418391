import { useFocusWithin } from '@react-aria/interactions';
import React, { useState } from 'react';
import { AssetTileToolbar, IToolbarPublicProps } from './AssetTileToolbar/AssetTileToolbar.tsx';
import { BaseAssetTile, IBaseAssetTilePublicProps } from './BaseAssetTile.tsx';
import { ToolbarVisibilityHelper } from './components/ToolbarVisibilityHelper.tsx';
import { AssetTileType } from './enums/assetTileTypeEnum.ts';

interface IAssetTileWithActionsWithoutHTMlAttrsProps extends IToolbarPublicProps {
  readonly children?: never;
}

export interface IAssetTileWithActionsProps
  extends IAssetTileWithActionsWithoutHTMlAttrsProps,
    Omit<IBaseAssetTilePublicProps, 'isSelected'> {}

export const AssetTileWithActions = React.forwardRef<HTMLDivElement, IAssetTileWithActionsProps>(
  (
    {
      isDragging,
      renderToolbarActions,
      renderDragButton,
      assetData,
      onClick,
      isAlert,
      validation,
      readonlyState,
      ...otherProps
    },
    forwardedRef,
  ) => {
    const [isFocusedWithin, setIsFocusedWithin] = useState(false);
    const { focusWithinProps } = useFocusWithin({
      onFocusWithinChange: (isFocusWithin) => setIsFocusedWithin(isFocusWithin),
    });

    return (
      <BaseAssetTile
        tileType={AssetTileType.WithActions}
        isDragging={isDragging}
        assetData={assetData}
        onClick={onClick}
        isAlert={isAlert}
        validation={validation}
        readonlyState={readonlyState}
        ref={forwardedRef}
        {...otherProps}
        renderHeader={(isFocused, isHovered) => (
          <ToolbarVisibilityHelper
            isVisible={isHovered || isFocused || isFocusedWithin || isDragging}
            isDragging={isDragging}
            readonlyState={readonlyState}
            {...focusWithinProps}
          >
            <AssetTileToolbar
              isDragging={isDragging}
              renderToolbarActions={renderToolbarActions}
              renderDragButton={renderDragButton}
            />
          </ToolbarVisibilityHelper>
        )}
      />
    );
  },
);

AssetTileWithActions.displayName = 'AssetTileWithActions';
