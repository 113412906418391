import { assert } from '@kontent-ai/utils';
import { ContentBlock } from 'draft-js';
import {
  BaseBlockType,
  getNestedBlockType,
  isBlockTypeAllowedInTableCell,
  isBlockTypeWithSleeves,
  parseBlockType,
} from '../../../utils/blocks/blockType.ts';
import {
  CustomBlockSleevePosition,
  getCustomBlockSleevePosition,
  getParentBlockTypes,
  isTableCell,
} from '../../../utils/blocks/blockTypeUtils.ts';
import { getBaseBlockType, getFullBlockType } from '../../../utils/blocks/editorBlockGetters.ts';
import {
  createContent,
  findBlockIndex,
  setBlockType,
} from '../../../utils/blocks/editorBlockUtils.ts';
import { createSelection, setContentSelection } from '../../../utils/editorSelectionUtils.ts';
import { getBlocks } from '../../../utils/general/editorContentGetters.ts';
import {
  IContentChangeInput,
  IContentChangeResult,
} from '../../../utils/general/editorContentUtils.ts';

interface IShiftBlockTarget {
  readonly index: number;
  readonly parentBlockTypes: ReadonlyArray<BaseBlockType>;
}

function getShiftBlockTarget(
  shiftBlockIndex: number,
  overBlockIndex: number,
  blocks: ReadonlyArray<ContentBlock>,
): IShiftBlockTarget {
  const overBlock = blocks[overBlockIndex];
  assert(overBlock, () => 'Over block is not a content block.');
  const overBlockType = getBaseBlockType(overBlock);
  const overCustomBlockType = isBlockTypeWithSleeves(overBlockType) ? overBlockType : undefined;

  if (shiftBlockIndex < overBlockIndex) {
    // Moving forward - Place after the over index (after the target block and its empty paragraph(s))
    const nextIsSleeveOfOverBlock =
      overCustomBlockType &&
      getCustomBlockSleevePosition(overBlockIndex + 1, blocks, overCustomBlockType) ===
        CustomBlockSleevePosition.AfterOwner;

    const targetIndexAfter = nextIsSleeveOfOverBlock ? overBlockIndex + 2 : overBlockIndex + 1;

    if (!isTableCell(overBlock)) {
      return {
        index: targetIndexAfter,
        parentBlockTypes: overBlockIndex < blocks.length ? getParentBlockTypes(overBlock) : [],
      };
    }

    // If the block is not allowed in table cell, do not let it place after the table cell block, fallback to the position before
    const shiftBlock = blocks[shiftBlockIndex];
    assert(shiftBlock, () => 'Shift block is not a content block.');
    if (isBlockTypeAllowedInTableCell(getBaseBlockType(shiftBlock))) {
      return {
        index: targetIndexAfter,
        parentBlockTypes: parseBlockType(getFullBlockType(overBlock)),
      };
    }
  }

  // Moving backward - Place at the over index (before the target block and its empty paragraph(s))
  const previousIsSleeveOfOverBlock =
    overCustomBlockType &&
    getCustomBlockSleevePosition(overBlockIndex - 1, blocks, overCustomBlockType) ===
      CustomBlockSleevePosition.BeforeOwner;

  const targetIndexBefore = previousIsSleeveOfOverBlock ? overBlockIndex - 1 : overBlockIndex;

  return {
    index: targetIndexBefore,
    parentBlockTypes: overBlockIndex < blocks.length ? getParentBlockTypes(overBlock) : [],
  };
}

export const shiftCustomBlock = (
  input: IContentChangeInput,
  shiftBlockKey: string,
  overBlockKey: string,
): IContentChangeResult => {
  const { content, selection } = input;

  const blocks = getBlocks(content);

  const shiftBlockIndex = findBlockIndex(blocks, shiftBlockKey);
  const overBlockIndex = findBlockIndex(blocks, overBlockKey);
  if (shiftBlockIndex < 0 || overBlockIndex < 0 || shiftBlockIndex === overBlockIndex) {
    return input;
  }

  // Include extra empty paragraphs owned by the moved block
  const startIndex =
    getCustomBlockSleevePosition(shiftBlockIndex - 1, blocks) ===
    CustomBlockSleevePosition.BeforeOwner
      ? shiftBlockIndex - 1
      : shiftBlockIndex;

  const target = getShiftBlockTarget(shiftBlockIndex, overBlockIndex, blocks);
  if (target.index === startIndex) {
    return input;
  }

  const endIndex =
    getCustomBlockSleevePosition(shiftBlockIndex + 1, blocks) ===
    CustomBlockSleevePosition.AfterOwner
      ? shiftBlockIndex + 2
      : shiftBlockIndex + 1;

  // Rebase the shifted blocks to the correct hierarchy parent
  const shiftedBlocks = blocks.slice(startIndex, endIndex);
  const rebasedShiftedBlocks = shiftedBlocks.map((block) =>
    setBlockType(block, getNestedBlockType(target.parentBlockTypes, getBaseBlockType(block))),
  );

  const newBlocks =
    target.index >= endIndex
      ? // Shift forward
        [
          ...blocks.slice(0, startIndex),
          ...blocks.slice(endIndex, target.index),
          ...rebasedShiftedBlocks,
          ...blocks.slice(target.index),
        ]
      : // Shift backward
        [
          ...blocks.slice(0, target.index),
          ...rebasedShiftedBlocks,
          ...blocks.slice(target.index, startIndex),
          ...blocks.slice(endIndex),
        ];

  const newContent = createContent(newBlocks);
  const newSelection = createSelection(shiftBlockKey);
  const newContentWithSelection = setContentSelection(newContent, selection, newSelection);

  return {
    wasModified: true,
    content: newContentWithSelection,
    selection: newSelection,
  };
};
