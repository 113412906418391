import { ComponentProps, forwardRef, useContext, useMemo } from 'react';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../../../../_shared/hooks/useThunkPromise.ts';
import { ActiveCapabilityType } from '../../../../../../../_shared/models/activeCapability.type.ts';
import { hasActiveVariantCapabilityForEditedItem } from '../../../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { RichTextItemElementContext } from '../../../../../../itemEditor/features/ContentItemEditing/context/RichTextItemElementContext.tsx';
import { getContentComponent } from '../../../../../../itemEditor/features/ContentItemEditing/utils/contentComponentUtils.ts';
import { CreateContentGroupTabsId } from '../../../../../../itemEditor/features/ContentItemEditing/utils/contentGroupTabsId.ts';
import { ensureLoadedContentTypes } from '../../../../../../itemEditor/features/LoadedItems/actions/thunkLoadedItemsActions.ts';
import { getSelectedContentGroupIdFromStateOrFirst } from '../../../../../../itemEditor/stores/utils/contentItemElementsUtils.ts';
import { useContentComponentType } from '../../../../../hooks/useContentComponentType.ts';
import { ContentComponentItemElements as ContentComponentItemElementsComponent } from '../../../components/expanded/elements/ContentComponentItemElements.tsx';

type Props = Omit<
  ComponentProps<typeof ContentComponentItemElementsComponent>,
  'canUpdateContent' | 'contentType' | 'selectedContentGroupId'
>;

export const ContentComponentItemElements = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const contentType = useContentComponentType(props.contentComponentId);
  const { rootRichTextElementId } = useContext(RichTextItemElementContext);

  const missingContentTypeId = useSelector((state) =>
    !contentType && rootRichTextElementId
      ? (getContentComponent(
          state.contentApp.editedContentItemVariantElements,
          rootRichTextElementId,
          props.contentComponentId,
        )?.contentTypeId ?? null)
      : null,
  );

  const missingContentTypeIds = useMemo(
    () => new Set(missingContentTypeId ? [missingContentTypeId] : []),
    [missingContentTypeId],
  );
  useThunkPromise(ensureLoadedContentTypes, missingContentTypeIds);

  const selectedContentGroupId = useSelector(
    (state) =>
      contentType &&
      getSelectedContentGroupIdFromStateOrFirst(
        CreateContentGroupTabsId.forContentComponent(props.contentComponentId),
        contentType.contentGroups,
        state,
      ),
  );
  const canUpdateContent = useSelector((state) =>
    hasActiveVariantCapabilityForEditedItem(ActiveCapabilityType.UpdateContent, state),
  );

  if (!contentType) {
    return null;
  }

  return (
    <ContentComponentItemElementsComponent
      {...props}
      canUpdateContent={canUpdateContent}
      contentType={contentType}
      selectedContentGroupId={selectedContentGroupId}
      ref={ref}
    />
  );
});

ContentComponentItemElements.displayName = 'ContentComponentItemElements';
