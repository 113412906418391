import { useHistory, useParams } from 'react-router';
import { SubscriptionEnvironmentRoleEditorRouteParams } from '../../../../_shared/constants/routePaths.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { getCurrentProjectId } from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { createRoleEditingBackLink } from '../../../../_shared/utils/routing/projectSubscriptionRoutingUtils.ts';
import {
  roleValidationFailed,
  roleValidationFailedNoSelectedCapabilities,
} from '../actions/rolesActions.ts';
import { deleteEditedRole, updateRole } from '../actions/thunkRolesActions.ts';
import { RoleEditorToolbarActions as RoleEditorToolbarActionsComponent } from '../components/RoleEditorToolbarActions.tsx';
import { PredefinedRoles } from '../constants/predefinedRoles.ts';
import { getCustomRolesLimitInfo } from '../selectors/customRoleLimit.ts';
import { isRoleUsedByAnyUser, isRoleUsedByCurrentUser } from '../selectors/isRoleUsed.ts';
import { isRoleUsedByCustomApp } from '../selectors/isRoleUsedByCustomApp.ts';
import { isRoleUsedByWorkflowStep as isRoleUsedByWorkflowStepSelector } from '../selectors/isRoleUsedByWorkflowStep.ts';
import { areCustomRolesOverLimit } from '../utils/customRolesUtils.ts';
import { getUpdateServerModelFromEditedRole } from '../utils/getUpdateServerModelFromEditedRole.ts';
import { isRoleValid } from '../utils/validationUtils.ts';

export const SubscriptionManagerRoleEditorToolbarActions = () => {
  const { projectId, subscriptionId } = useParams<SubscriptionEnvironmentRoleEditorRouteParams>();
  const history = useHistory();
  const dispatch = useDispatch();

  const status = useSelector((s) => s.rolesApp.editorUi.status);
  const isEditedRoleReadOnly = useSelector((s) => {
    const { editedRole } = s.rolesApp.editorUi;
    return editedRole.isReadonly && editedRole.codename !== PredefinedRoles.LocalProjectManager;
  });
  const isRoleUsedByUser = useSelector((s) =>
    isRoleUsedByAnyUser(s.rolesApp.editorUi.editedRole, s.data.users.usersById),
  );
  const isRoleUsedByWorkflowStep = useSelector((s) =>
    isRoleUsedByWorkflowStepSelector(s.rolesApp.editorUi.editedRole, s.data.workflows.byId),
  );
  const isRoleUsedByCustomApps = useSelector((s) =>
    isRoleUsedByCustomApp(s.rolesApp.editorUi.editedRole, s.data.customApps.byId),
  );
  const isReadonly = useSelector(
    (s) =>
      areCustomRolesOverLimit(getCustomRolesLimitInfo(s)) ||
      s.rolesApp.editorUi.editedRole.isReadonly,
  );
  const contentTypesById = useSelector((s) => s.data.contentTypes.byId);
  const editedRole = useSelector((s) => s.rolesApp.editorUi.editedRole);
  const roleIsUsedByCurrentUser = useSelector((s) => isRoleUsedByCurrentUser(editedRole.id, s));
  const currentProjectId = useSelector(getCurrentProjectId);

  const handleSave = async (): Promise<void> => {
    const roleToUpdate = getUpdateServerModelFromEditedRole(editedRole, contentTypesById);
    if (!isRoleValid(editedRole)) {
      dispatch(roleValidationFailed());
      return;
    }

    if (!roleToUpdate.capabilities.length) {
      dispatch(roleValidationFailedNoSelectedCapabilities());
      return;
    }

    await dispatch(
      updateRole(
        editedRole.id,
        roleToUpdate,
        roleIsUsedByCurrentUser,
        contentTypesById,
        currentProjectId,
      ),
    );
  };

  const originalEditedRole = useSelector((s) => s.rolesApp.editorUi.originalEditedRole);

  const handleDelete = async (): Promise<void> => {
    await dispatch(deleteEditedRole(originalEditedRole));
    history.push(
      createRoleEditingBackLink({
        projectId,
        subscriptionId,
      }),
    );
  };

  return (
    <RoleEditorToolbarActionsComponent
      status={status}
      isEditedRoleReadOnly={isEditedRoleReadOnly}
      isRoleUsedByUser={isRoleUsedByUser}
      isRoleUsedByWorkflowStep={isRoleUsedByWorkflowStep}
      isRoleUsedByCustomApp={isRoleUsedByCustomApps}
      readOnly={isReadonly}
      onSave={handleSave}
      onDelete={handleDelete}
    />
  );
};
