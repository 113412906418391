import { useContext } from 'react';
import { ItemEditingNewDesignContext } from '../../../context/ItemEditingNewDesignContext.tsx';
import { OldOpenInNewTabButton } from '../components/OldOpenInNewTabButton.tsx';
import { OpenInNewTabPrimaryButton as OpenInNewTabPrimaryButtonComponent } from '../components/OpenInNewTabPrimaryButton.tsx';
import { useWebSpotlightInItemEditing } from '../context/WebSpotlightInItemEditingContext.tsx';

type Props = Readonly<{
  spaceId: Uuid | null;
}>;

export const OpenInNewTabPrimaryButton = ({ spaceId }: Props) => {
  const isNewDesign = useContext(ItemEditingNewDesignContext);
  const url = useWebSpotlightInItemEditing().previewMetadata.url;

  if (!url) {
    return null;
  }

  if (!isNewDesign) {
    return <OldOpenInNewTabButton spaceId={spaceId} buttonStyle="primary" />;
  }

  return <OpenInNewTabPrimaryButtonComponent url={url} />;
};
