import useLocalStorageState from 'use-local-storage-state';
import { IsNavigationTreeOpenedStorageKey } from '../_shared/constants/localStorageKeys.ts';

type NavigationTreeState = Readonly<{
  isTreeOpened: boolean;
  setIsTreeOpened: (value: boolean) => void;
}>;

const getStorageKey = (environmentId: Uuid): string => {
  return `${IsNavigationTreeOpenedStorageKey}_${environmentId}`;
};

export const useIsNavigationTreeOpenStorage = (environmentId: Uuid): NavigationTreeState => {
  const storageKey = getStorageKey(environmentId);

  const [isTreeOpened, setIsTreeOpened] = useLocalStorageState<boolean>(storageKey, {
    defaultValue: true,
  });

  return {
    isTreeOpened,
    setIsTreeOpened,
  };
};
