import { SortBy, Status } from '../constants/filters.ts';
import {
  AiAcceleratorsFeatureCodename,
  aiAcceleratorsFeatureCodenames,
} from '../types/AiAcceleratorsFeatureCodenames.ts';
import { AiAcceleratorsFeatureMetadata } from '../types/AiAcceleratorsFeatureMetadata.type.ts';

const sortFeatures =
  (sortBy: SortBy) =>
  <T extends { readonly releaseDate: string; readonly name: string }>(
    featureA: T,
    featureB: T,
  ): number => {
    switch (sortBy) {
      case SortBy.Newest:
        return -featureA.releaseDate.localeCompare(featureB.releaseDate);
      case SortBy.Oldest:
        return featureA.releaseDate.localeCompare(featureB.releaseDate);
      case SortBy.ByNameAsc:
        return featureA.name.localeCompare(featureB.name);
      case SortBy.ByNameDesc:
        return -featureA.name.localeCompare(featureB.name);
    }
  };

const filterByStatus =
  (
    status: Status,
    codenameToMetadata: ReadonlyRecord<
      AiAcceleratorsFeatureCodename,
      AiAcceleratorsFeatureMetadata
    >,
  ) =>
  <T extends { readonly codename: AiAcceleratorsFeatureCodename }>(feature: T): boolean => {
    switch (status) {
      case Status.All:
        return true;
      case Status.Active:
        return codenameToMetadata[feature.codename].isEnabled;
      case Status.Inactive:
        return !codenameToMetadata[feature.codename].isEnabled;
    }
  };

export const getAiAcceleratorsFeatures = (
  codenameToMetadata: ReadonlyRecord<AiAcceleratorsFeatureCodename, AiAcceleratorsFeatureMetadata>,
  sortBy: SortBy,
  status: Status,
): ReadonlyArray<
  AiAcceleratorsFeatureMetadata & { readonly codename: AiAcceleratorsFeatureCodename }
> =>
  aiAcceleratorsFeatureCodenames
    .map((codename) => ({ ...codenameToMetadata[codename], codename }))
    .filter(filterByStatus(status, codenameToMetadata))
    .sort(sortFeatures(sortBy));
