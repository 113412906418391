import { identity } from '@kontent-ai/utils';
import classNames from 'classnames';
import Immutable from 'immutable';
import React, { useRef } from 'react';
import { useDrop } from 'react-dnd';
import { DragObject } from '../../../../_shared/components/DragDrop/dragDrop.type.ts';
import { DndTypes } from '../../../../_shared/constants/dndTypes.ts';
import { useScrollOnDragEvents } from '../../../../_shared/hooks/useScrollOnDragEvents.ts';
import {
  DataUiCollection,
  DataUiElement,
  getDataUiCollectionAttribute,
  getDataUiElementAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { useCrossedHalfTargetHeight } from '../../../../_shared/utils/dragDrop/hoveringCollisionStrategies.ts';
import { ICapability } from '../../../../_shared/utils/permissions/capability.ts';
import { IContentTypeUsage } from '../../../../data/models/contentModelsApp/contentTypes/ContentTypeUsage.ts';
import { TypeElementType } from '../../../contentInventory/content/models/ContentItemElementType.ts';
import { ContentTypeKind } from '../constants/contentTypeKind.ts';
import { TypeElement } from '../containers/TypeElement.tsx';
import { IBaseTypeElementData } from '../models/elements/types/TypeElementData.ts';
import { TypeElementLibraryCollectedProps } from '../types/typeElementLibraryDndTypes.type.ts';
import { ShadowContentElement } from './typeElements/shared/ShadowContentElement.tsx';

interface IBottomSpaceMessageProps {
  readonly message: string;
}

const BottomSpaceMessage: React.FC<IBottomSpaceMessageProps> = ({ message }) => (
  <div className="content-type-elements-pane__bottom-space-message">
    <p className="content-type-elements-pane__initial-hint-message">{message}</p>
    <img alt="Drag and drop" src="/images/content/drag-and-drop.svg" />
  </div>
);

BottomSpaceMessage.displayName = 'BottomSpaceMessage';

type Props = {
  readonly addContentElement: (elementType: TypeElementType) => void;
  readonly capability: ICapability;
  readonly contentTypeKind: ContentTypeKind;
  readonly elementIds: ReadonlyArray<string>;
  readonly firstIncompleteElementId: Uuid | null;
  readonly insertContentElementBefore: (
    elementType: TypeElementType,
    insertBeforeElement: IBaseTypeElementData,
  ) => void;
  readonly isDragging: boolean;
  readonly isInGroup?: boolean;
  readonly lastAddedElementId: Uuid | null;
  readonly lastMovedElementId?: Uuid | null;
  readonly moveContentElement: (sourceId: Uuid, targetId: Uuid) => void;
  readonly numbersOfItemsUsedIn?: IContentTypeUsage;
  readonly onDragEnd: () => void;
  readonly onDragStart: (typeElementId: Uuid) => void;
  readonly onHideConfig: (typeElementId: Uuid) => void;
  readonly onShowConfig: (typeElementId: Uuid) => void;
  readonly removeContentElement: (typeElementId: Uuid) => void;
  readonly showConfigurationForElements: Immutable.Map<Uuid, boolean>;
  readonly snippetCodename?: string | null;
  readonly updateContentElement: (updatedElement: IBaseTypeElementData) => void;
};

export const TypeElementList: React.FC<Props> = ({
  addContentElement,
  capability,
  contentTypeKind,
  elementIds,
  firstIncompleteElementId,
  insertContentElementBefore,
  isDragging,
  isInGroup,
  lastAddedElementId,
  lastMovedElementId,
  moveContentElement,
  numbersOfItemsUsedIn,
  onDragEnd,
  onDragStart,
  onHideConfig,
  onShowConfig,
  removeContentElement,
  showConfigurationForElements,
  snippetCodename,
  updateContentElement,
}) => {
  const dndScrollAreaRef = useRef(null);
  useScrollOnDragEvents(dndScrollAreaRef);

  const [{ canDrop, isOver: isDropOver }, connectDropTarget] = useDrop<
    DragObject,
    void,
    TypeElementLibraryCollectedProps
  >({
    accept: DndTypes.Content_Element_Add,
    collect: (monitor) => {
      return {
        isOver: monitor.isOver({ shallow: true }),
        canDrop: monitor.canDrop(),
      };
    },
    drop: (_, monitor) => {
      // Type of content element that dropping
      const draggedElementType = monitor.getItem().sourceId;
      addContentElement(draggedElementType as TypeElementType);
    },
    canDrop: (_, monitor) => monitor.isOver({ shallow: true }),
  });

  const isActiveForDroppingNewElement = canDrop && isDropOver;

  const hoveringCollisionStrategy = useCrossedHalfTargetHeight(elementIds, identity);

  return (
    <section
      className="content-type-elements-pane"
      ref={dndScrollAreaRef}
      {...getDataUiCollectionAttribute(DataUiCollection.ContentElements)}
    >
      <div
        className={classNames('content-type-elements-pane__elements-list', {
          'content-type-elements-pane__elements-list--is-dragging': isDragging,
        })}
      >
        <div
          className={classNames('content-type-elements-pane__group', {
            'content-type-elements-pane__group--is-in-group': isInGroup,
          })}
        >
          {elementIds.map((elementId, index: number) => (
            <TypeElement
              capability={capability}
              contentTypeKind={contentTypeKind}
              elementId={elementId}
              firstIncompleteElementId={firstIncompleteElementId}
              hoveringCollisionStrategy={hoveringCollisionStrategy}
              insertContentElementBefore={insertContentElementBefore}
              isConfigDisplayed={showConfigurationForElements.get(elementId) ?? false}
              isFirstListElement={index === 0}
              isInGroup={!!isInGroup}
              key={elementId}
              lastAddedElementId={lastAddedElementId}
              lastMovedElementId={lastMovedElementId}
              moveContentElement={moveContentElement}
              numbersOfItemsUsedIn={numbersOfItemsUsedIn}
              onDragEnd={onDragEnd}
              onDragStart={onDragStart}
              onHideConfig={onHideConfig}
              onShowConfig={onShowConfig}
              removeContentElement={removeContentElement}
              snippetCodename={snippetCodename}
              updateContentElement={updateContentElement}
            />
          ))}
          <div
            className="content-type-elements-pane__bottom-space"
            ref={connectDropTarget}
            {...getDataUiElementAttribute(DataUiElement.Dropzone)}
          >
            {isActiveForDroppingNewElement && <ShadowContentElement isGroupItem isLastGroupItem />}
            <BottomSpaceMessage
              message={
                isActiveForDroppingNewElement || elementIds.length
                  ? 'Continue dropping elements here'
                  : 'Start by dropping elements here'
              }
            />
          </div>
        </div>
      </div>
    </section>
  );
};

TypeElementList.displayName = 'TypeElementList';
