import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { mergeAriaDescribedBy } from '@kontent-ai/component-library/component-utils';
import { useOurFocusRing } from '@kontent-ai/component-library/hooks';
import { SrOnly } from '@kontent-ai/component-library/styles';
import { useAttachRef } from '@kontent-ai/hooks';
import { not } from '@kontent-ai/utils';
import { useButton } from '@react-aria/button';
import { useFocusWithin } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import classNames from 'classnames';
import { forwardRef, useId, useRef, useState } from 'react';
import { ConnectDragSource } from 'react-dnd';
import { AssetTilePreview } from '../../../../../../../_shared/components/AssetTile/AssetTilePreview.tsx';
import { AssetTileProgressBar } from '../../../../../../../_shared/components/AssetTile/AssetTileProgressBar.tsx';
import { AssetTileSummary } from '../../../../../../../_shared/components/AssetTile/AssetTileSummary.tsx';
import { useTabKeyNavigationWithin } from '../../../../../../../_shared/hooks/useTabKeyNavigationWithin.ts';
import { getAssetDescriptionBySelectedLanguage } from '../../../../../../../_shared/selectors/AssetTile/getAssetDescription.ts';
import {
  DataAttributes,
  getDataAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataAttributes.ts';
import {
  DataUiAction,
  getDataUiActionAttribute,
  getDataUiObjectNameAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { pluralizeWithCount } from '../../../../../../../_shared/utils/stringUtils.ts';
import { IAssetRendition } from '../../../../../../../data/models/assetRenditions/AssetRendition.ts';
import { Asset, IAsset } from '../../../../../../../data/models/assets/Asset.ts';
import { AssetThumbnailBottomClassname } from '../../../../../../contentInventory/assets/components/AssetTile/AssetTile.tsx';
import { CannotViewAssetsMessage } from '../../../../../../contentInventory/content/constants/cannotViewMessages.ts';
import {
  ElementAttributes,
  getAssetIdAttribute,
} from '../../../../../constants/elementAttributes.ts';
import { AssetTileActions } from '../../../containers/elements/asset/AssetTileActions.tsx';
import { AssetTileAction } from './AssetTileActions.tsx';

export interface IAssetValidationResult {
  readonly isAssetFileSizeValid: boolean;
  readonly isAssetFileTypeValid: boolean;
  readonly isAssetHeightValid: boolean;
  readonly isAssetWidthValid: boolean;
}

export interface IAssetTileOwnProps {
  readonly actions: ReadonlyArray<AssetTileAction>;
  readonly className?: string;
  readonly commentSegmentId?: Uuid;
  readonly commentThreadId: Uuid | null;
  readonly connectDragSource?: ConnectDragSource;
  readonly disabled: boolean;
  readonly elementId: Uuid;
  readonly hasFocusedComment: boolean;
  readonly isDragging: boolean;
  readonly isFocusable?: boolean;
  readonly onEdit?: () => void;
  readonly onFocusEscape?: () => void;
  readonly onNewComment: () => void;
  readonly onOpenRenditionDialog?: () => void;
  readonly onRemove?: () => void;
  readonly renditionId?: Uuid;
}

export interface IAssetTileStateProps {
  readonly actions: ReadonlyArray<AssetTileAction>;
  readonly asset: IAsset;
  readonly canViewAnyActiveLanguage: boolean;
  readonly canViewAsset: boolean;
  readonly collectionName: string | null;
  readonly isHeightLimitationSet: boolean;
  readonly isUncategorized: boolean | null;
  readonly isWidthLimitationSet: boolean;
  readonly onEdit?: () => void;
  readonly onOpenRenditionDialog?: () => void;
  readonly rendition?: IAssetRendition;
  readonly searchPhrase: string;
  readonly selectedLanguageId: Uuid | null;
  readonly validationResult: IAssetValidationResult;
}

interface IAssetTileProps extends IAssetTileOwnProps, IAssetTileStateProps {}

export const AssetTile = forwardRef<HTMLDivElement, IAssetTileProps>(
  (
    {
      actions,
      asset,
      canViewAnyActiveLanguage,
      canViewAsset,
      collectionName,
      commentThreadId,
      className,
      commentSegmentId,
      connectDragSource,
      disabled,
      hasFocusedComment,
      isDragging,
      isFocusable = true,
      isHeightLimitationSet,
      isUncategorized,
      isWidthLimitationSet,
      onEdit,
      onFocusEscape,
      onNewComment,
      onOpenRenditionDialog,
      onRemove,
      rendition,
      renditionId,
      searchPhrase,
      selectedLanguageId,
      validationResult,
    },
    ref,
  ) => {
    const { refObject: assetTileRef, refToForward } = useAttachRef(ref);
    const [isAssetFocusedWithin, setIsAssetFocusedWithin] = useState(false);

    useTabKeyNavigationWithin({
      ref: assetTileRef,
      isDisabled: disabled || isFocusable,
      onEscape: onFocusEscape,
    });

    const isClickable =
      canViewAnyActiveLanguage && canViewAsset && !asset._failed && !asset._uploading;

    const { isFocusVisible, focusProps } = useOurFocusRing(
      disabled || !!asset._uploading || !isClickable,
    );

    const { focusWithinProps } = useFocusWithin({
      onFocusWithinChange: (isFocusWithin) => setIsAssetFocusedWithin(isFocusWithin),
    });

    const remove = (): void => {
      if (onRemove) {
        assetTileRef.current?.blur();
        onRemove();
      }
    };

    const altText =
      (selectedLanguageId &&
        getAssetDescriptionBySelectedLanguage(asset.descriptions, selectedLanguageId)) ??
      '';

    const titleId = useId();
    const descriptionId = useId();

    const assetContentWrapperRef = useRef(null);
    const { buttonProps } = useButton(
      {
        elementType: 'div',
        onPress: isClickable ? onEdit : undefined,
        'aria-labelledby': titleId,
        'aria-describedby': descriptionId,
      },
      assetContentWrapperRef,
    );

    const errorsCount = Object.values(validationResult).filter(not).length;
    const assetErrorsDescription = errorsCount > 0 ? pluralizeWithCount('error', errorsCount) : '';

    return (
      <Tooltip
        text={canViewAnyActiveLanguage ? undefined : CannotViewAssetsMessage}
        placement="right"
      >
        <div
          className={classNames('asset-thumbnail', className, {
            'asset-thumbnail--is-disabled': disabled || asset._uploading,
            'asset-thumbnail--is-not-clickable': !isClickable,
            'asset-thumbnail--is-invalid': !Asset.exists(asset),
            'asset-thumbnail--is-dragging': isDragging,
            'asset-thumbnail--is-not-allowed': !validationResult.isAssetFileTypeValid,
            'asset-thumbnail--is-focused ': isFocusVisible,
          })}
          {...getDataUiObjectNameAttribute(asset.title || asset.filename)}
          {...getAssetIdAttribute(asset.id)}
          {...getDataAttribute(
            ElementAttributes.RichTextCommentSegmentId,
            commentSegmentId ?? undefined,
          )}
          ref={refToForward}
        >
          <div {...focusWithinProps}>
            <AssetTileProgressBar asset={asset} />
            <div
              {...mergeProps(buttonProps, focusProps)}
              {...getDataAttribute(DataAttributes.FocusEntryPoint, 'true')}
              tabIndex={!isFocusable && !isAssetFocusedWithin ? -1 : buttonProps.tabIndex}
              {...getDataUiActionAttribute(DataUiAction.EditAsset)}
            >
              <SrOnly id={descriptionId}>
                {mergeAriaDescribedBy(
                  'Asset tile,',
                  assetErrorsDescription && `${assetErrorsDescription},`,
                  'opens asset details modal',
                )}
              </SrOnly>
              <AssetTilePreview
                asset={asset}
                altText={altText}
                canViewAsset={canViewAsset}
                transformation={rendition?.transformation}
              />
            </div>

            <div
              className={classNames(
                AssetThumbnailBottomClassname,
                `${AssetThumbnailBottomClassname}--with-padding`,
              )}
            >
              <AssetTileSummary
                asset={asset}
                canViewAsset={canViewAsset}
                collectionName={collectionName}
                titleId={titleId}
                isHeightLimitationSet={isHeightLimitationSet}
                isWidthLimitationSet={isWidthLimitationSet}
                rendition={rendition}
                renditionId={renditionId}
                searchPhrase={searchPhrase}
                validationResult={validationResult}
              />
            </div>

            <div>
              <AssetTileActions
                actions={actions}
                asset={asset}
                commentThreadId={commentThreadId}
                disableTabulator={!isAssetFocusedWithin && !isFocusVisible}
                disabled={disabled}
                hasFocusedComment={hasFocusedComment}
                isUncategorized={canViewAsset && isUncategorized}
                onNewComment={onNewComment}
                transformation={rendition?.transformation}
                onOpenRenditionDialog={onOpenRenditionDialog}
                onRemove={remove}
                connectDragSource={connectDragSource}
              />
            </div>
          </div>
        </div>
      </Tooltip>
    );
  },
);

AssetTile.displayName = 'AssetTile';
