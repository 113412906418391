import { NotificationBarFriendlyWarning } from '@kontent-ai/component-library/NotificationBar';
import { useEffect, useState } from 'react';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { getIsAiSearchUsed } from '../../../../paperModels/aiSearch/utils/getIsAiSearchUsed.ts';
import { IListingFilter } from '../models/filter/IListingFilter.ts';

export const FilterInconsistentWithSemanticSearchWarning = () => {
  const isFilterInconsistent = useSelector((s) =>
    isFilterInConflictWithAiSearch(s.contentApp.listingUi.filter),
  );
  const [isWarningVisible, setIsWarningVisible] = useState(isFilterInconsistent);
  useEffect(() => {
    setIsWarningVisible(isFilterInconsistent);
  }, [isFilterInconsistent]);

  return (
    isWarningVisible && (
      <NotificationBarFriendlyWarning hideIcon onDismiss={() => setIsWarningVisible(false)}>
        Your filter selection was adjusted. Semantic search does not support filtering by the ‘Not
        translated’ status.
      </NotificationBarFriendlyWarning>
    )
  );
};

const isFilterInConflictWithAiSearch = (filter: IListingFilter): boolean => {
  const isUsingAiSearch = getIsAiSearchUsed(filter);
  const isNotTranslatedFilterSelected = filter.selectedContentItemStatus.notTranslated;

  return isUsingAiSearch && isNotTranslatedFilterSelected;
};
