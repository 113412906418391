export const Environment_EditedEnvironmentName_Updated =
  'Environment_EditedEnvironmentName_Updated';
export const Environment_RenameEnvironment_Failed = 'Environment_RenameEnvironment_Failed';
export const Environment_RenameEnvironment_Finished = 'Environment_RenameEnvironment_Finished';
export const Environment_RenameEnvironment_Started = 'Environment_RenameEnvironment_Started';
export const Environment_ApiStatus_Loaded = 'Environment_ApiStatus_Loaded';
export const Environment_DeliveryApiSecureAccessActivation_Finished =
  'Environment_DeliveryApiSecureAccessActivation_Finished';
export const Environment_DeliveryApiSecureAccessDeactivation_Finished =
  'Environment_DeliveryApiSecureAccessDeactivation_Finished';
export const Environment_ContentManagementApiActivation_Finished =
  'Environment_ContentManagementApiActivation_Finished';
export const Environment_ContentManagementApiDeactivation_Finished =
  'Environment_ContentManagementApiDeactivation_Finished';
export const Environment_LoadLivePreviewConfiguration_Finished =
  'Environment_LoadLivePreviewConfiguration_Finished';
export const Environment_SetLivePreviewConfiguration_Finished =
  'Environment_SetLivePreviewConfiguration_Finished';
