import { Column, Row } from '@kontent-ai/component-library/Row';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import React, { useState } from 'react';
import { BarItemAnimation } from '../../../../../_shared/components/BarItems/BarItemAnimation.tsx';
import { FilterButton } from '../../../../../_shared/components/FilterButton.tsx';
import { UserListingFilterClearButton } from '../../../../../_shared/components/users/UserListingFilterClearButton.tsx';
import { SubscriptionUsersFilterTags } from '../../containers/ListingFilter/SubscriptionUsersFilterTags.tsx';
import { SubscriptionUsersNameFilter } from '../../containers/SubscriptionUsersNameFilter.tsx';
import {
  ISubscriptionUsersListingFilter,
  isFilterSet,
} from '../../models/SubscriptionUserListingFilter.ts';
import { ExpandedSubscriptionUserListingFilter } from './ExpandedSubscriptionUserListingFilter.tsx';

type SubscriptionUserListingFilterProps = {
  readonly filter: ISubscriptionUsersListingFilter;
  readonly onClearFilter: () => void;
};

export const SubscriptionUserListingFilter: React.FC<SubscriptionUserListingFilterProps> = ({
  filter,
  onClearFilter,
}) => {
  const [areFiltersExpanded, setAreFiltersExpanded] = useState(false);
  const switchAreFiltersExpanded = () => setAreFiltersExpanded(!areFiltersExpanded);

  return (
    <Stack spacing={Spacing.L}>
      <Row spacingX={Spacing.M}>
        <Column width="fit-content">
          <FilterButton isActive={areFiltersExpanded} onPress={switchAreFiltersExpanded} />
        </Column>
        <Column>
          <SubscriptionUsersNameFilter />
        </Column>
        {isFilterSet(filter) && (
          <Column width="fit-content">
            <UserListingFilterClearButton onClear={onClearFilter} />
          </Column>
        )}
      </Row>
      <BarItemAnimation
        estimatedMaxHeightWhenExpanded={152}
        renderCollapsed={() => (
          <SubscriptionUsersFilterTags
            filter={filter}
            onTagClick={() => setAreFiltersExpanded(true)}
          />
        )}
        renderExpanded={() => <ExpandedSubscriptionUserListingFilter />}
        shouldBeExpanded={areFiltersExpanded}
      />
    </Stack>
  );
};

SubscriptionUserListingFilter.displayName = 'SubscriptionUserListingFilter';
