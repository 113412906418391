import { Box } from '@kontent-ai/component-library/Box';
import { Button } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Spacing, gridUnit, px } from '@kontent-ai/component-library/tokens';
import classNames from 'classnames';
import React, { useState } from 'react';
import { TabMenu } from '../../../../../_shared/components/TabMenu/TabMenu.tsx';
import {
  DataUiAction,
  DataUiCollection,
  getDataUiActionAttribute,
  getDataUiCollectionAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { IContentGroup } from '../../../../contentInventory/content/models/contentTypeElements/types/ContentGroup.ts';
import { DraggableContentTypeContentGroupTab } from '../../containers/ContentGroups/DraggableContentTypeContentGroupTab.tsx';
import { ContentGroupDialogState, ContentGroupNameDialog } from './ContentGroupNameDialog.tsx';

type ContentTypeContentGroupTabsProps = {
  readonly contentTypeGroups: ReadonlyArray<IContentGroup>;
  readonly isDragging: boolean;
  readonly onCreateContentGroup: (contentGroupName: string) => void;
};

const tabHeight = px(6 * gridUnit);

export const ContentTypeContentGroupTabs: React.FC<ContentTypeContentGroupTabsProps> = ({
  contentTypeGroups,
  isDragging,
  onCreateContentGroup,
}) => {
  const [showCreateDialog, setShowCreateDialog] = useState(false);

  const confirmCreation = (contentGroupName: string): void => {
    onCreateContentGroup(contentGroupName);
    setShowCreateDialog(false);
  };

  return (
    <>
      <TabMenu
        className={classNames('tabbed-navigation--in-type-editor', {
          'tabbed-navigation--is-dragging': isDragging,
          'tabbed-navigation--is-empty': !contentTypeGroups.length,
        })}
        dataUiAttributes={getDataUiCollectionAttribute(DataUiCollection.ContentGroups)}
      >
        {contentTypeGroups.map((tab, index) => (
          <DraggableContentTypeContentGroupTab
            id={tab.id}
            key={tab.id}
            name={tab.name}
            position={index}
          />
        ))}
        <Box
          className="content-type-group-tab"
          marginLeft={contentTypeGroups.length ? Spacing.S : undefined}
          minHeight={contentTypeGroups.length ? tabHeight : undefined}
          display="flex"
          alignItems="center"
        >
          <Button
            buttonStyle="secondary"
            size="small"
            onClick={() => setShowCreateDialog(true)}
            {...getDataUiActionAttribute(DataUiAction.CreateNew)}
          >
            <Button.Icon icon={Icons.Plus} />
            <Button.Label>Create new content group</Button.Label>
          </Button>
        </Box>
      </TabMenu>
      {showCreateDialog && (
        <ContentGroupNameDialog
          onConfirm={confirmCreation}
          onCancel={() => setShowCreateDialog(false)}
          state={ContentGroupDialogState.New}
        />
      )}
    </>
  );
};

ContentTypeContentGroupTabs.displayName = 'ContentTypeContentGroupTabs';
