import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { Box } from '@kontent-ai/component-library/Box';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing, Typography, colorTextDefault } from '@kontent-ai/component-library/tokens';
import { HtmlSettingsPageTitle } from '../../../../_shared/components/HtmlSettingsPageTitle.tsx';
import { PageTitle } from '../../../../_shared/components/PageTitle.tsx';
import { documentationLinks } from '../../../../_shared/constants/documentationLinks.ts';
import {
  DataUiAppName,
  getDataUiAppNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { EnvironmentSettingsAppNames } from '../../root/constants/EnvironmentSettingsAppNames.ts';
import { AiAcceleratorsFeatures } from '../containers/AiAcceleratorsFeatures.tsx';
import { AiAcceleratorsAppFilterContextProvider } from '../contexts/AiAcceleratorsAppFilterContext.tsx';
import { Filters } from './Filters.tsx';

const Header = () => (
  <Stack spacing={Spacing.L}>
    <PageTitle>{EnvironmentSettingsAppNames.AiAccelerators}</PageTitle>
    <Box typography={Typography.SubheadlineLarge} color={colorTextDefault}>
      <p>
        Supercharge your content operations with{' '}
        <OutwardLink href={documentationLinks.aiAccelerators}>native AI accelerators</OutwardLink>.
        Use AI to author, govern, and organize your content to boost your productivity! Our native
        AI is fully developed and ready to help you achieve more in less time. Once activated, they
        become available to all users in your environment.
      </p>
    </Box>
  </Stack>
);

export const AiAcceleratorsApp = () => (
  <div
    className="canvas__inner-section canvas__inner-section--restricted-width"
    {...getDataUiAppNameAttribute(DataUiAppName.AiAccelerators)}
  >
    <HtmlSettingsPageTitle
      settingsAppName={EnvironmentSettingsAppNames.AiAccelerators}
      customName="AI accelerators"
    />
    <AiAcceleratorsAppFilterContextProvider>
      <Stack spacing={Spacing.XL}>
        <Header />
        <Filters />
        <AiAcceleratorsFeatures />
      </Stack>
    </AiAcceleratorsAppFilterContextProvider>
  </div>
);
