import { memoize } from '@kontent-ai/memoization';
import { Collection, always } from '@kontent-ai/utils';
import {
  getFilterValidationResultForRichText,
  getRemoveAllValidationResultsWithinRichText,
} from '../../features/ContentItemEditing/utils/itemValidationUtils.ts';
import { ItemElementValidationResult } from '../../utils/ItemElementValidationResult.type.ts';

const alwaysTrue = always(true);

const areEntriesShallowEqual = (
  a: readonly [UuidPath, ItemElementValidationResult],
  b: readonly [UuidPath, ItemElementValidationResult] | undefined,
) => a === b || (a?.[0] === b?.[0] && a?.[1] === b?.[1]);

export const associateResultWithTopLevelRichText = memoize.weak(
  <TResult extends ItemElementValidationResult>(
    warning: TResult,
    topLevelRichTextId: Uuid,
  ): TResult => ({
    ...warning,
    topLevelRichTextId,
  }),
);

export const updateStateForRichTextElement = memoize.weak(
  <TResult extends ItemElementValidationResult>(
    allResults: ReadonlyMap<UuidPath, TResult>,
    updatedResults: ReadonlyMap<UuidPath, TResult>,
    elementId: Uuid,
    isResultValid: (result: TResult) => boolean = alwaysTrue,
  ): ReadonlyMap<UuidPath, TResult> => {
    const filterPreviousResults = getFilterValidationResultForRichText(elementId);

    const allWarningEntries = Collection.getEntries(allResults);
    const previousResults = allWarningEntries.filter(([, result]) => filterPreviousResults(result));

    const resultsToAdd = Collection.getEntries(updatedResults)
      .filter(([, result]) => isResultValid(result))
      .map(([selector, result]): [UuidPath, TResult] => [
        selector,
        associateResultWithTopLevelRichText(result, elementId),
      ]);

    // state has not changed semantically, returns the original object to prevent re-rendering and to keep memoizing
    const areEqual =
      previousResults.length === resultsToAdd.length &&
      previousResults.every((result, index) => areEntriesShallowEqual(result, resultsToAdd[index]));

    if (areEqual) {
      return allResults;
    }

    const removePreviousResults = getRemoveAllValidationResultsWithinRichText(elementId);

    return new Map<string, TResult>([
      ...allWarningEntries.filter(([, result]) => removePreviousResults(result)),
      ...resultsToAdd,
    ]);
  },
);

export const updateStateForNonRichTextElement = <TResult extends ItemElementValidationResult>(
  results: ReadonlyMap<UuidPath, TResult>,
  updatedResults: ReadonlyMap<UuidPath, TResult>,
  elementId: Uuid,
  isResultValid: (result: TResult) => boolean = alwaysTrue,
): ReadonlyMap<UuidPath, TResult> => {
  const newResult = Collection.getValues(updatedResults)[0];
  const previousResult = results.get(elementId);

  const isNewResultValid = !newResult || isResultValid(newResult);
  if (newResult === previousResult || (!isNewResultValid && !previousResult)) {
    return results;
  }

  return isNewResultValid && newResult
    ? Collection.add(results, [elementId, newResult])
    : Collection.remove(results, elementId);
};
