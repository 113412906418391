import { Button, RouterLinkButton } from '@kontent-ai/component-library/Button';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import classNames from 'classnames';
import { Pathname } from 'history';
import Immutable from 'immutable';
import React, { memo, ReactNode } from 'react';
import { DataTable } from '../../../../_shared/components/DataTable/DataTable.tsx';
import { DataTableAction } from '../../../../_shared/components/DataTable/DataTableActions.tsx';
import {
  Column,
  DataTableHeadRow,
} from '../../../../_shared/components/DataTable/DataTableHeadRow.tsx';
import { DataTableRow } from '../../../../_shared/components/DataTable/DataTableRow.tsx';
import { LinkDataTableCell } from '../../../../_shared/components/DataTable/LinkDataTableCell.tsx';
import { EmptyState } from '../../../../_shared/components/EmptyState/EmptyState.tsx';
import { SearchPhraseHighlighter } from '../../../../_shared/components/Highlighting/SearchPhraseHighlighter.tsx';
import { HtmlPageTitle } from '../../../../_shared/components/HtmlPageTitle.tsx';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { TextFilter } from '../../../../_shared/components/TextFilter.tsx';
import { AppNames } from '../../../../_shared/constants/applicationNames.ts';
import {
  EditContentTypeSnippetRoute,
  EditContentTypeSnippetRouteParams,
} from '../../../../_shared/constants/routePaths.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { OrderByDirection } from '../../../../_shared/models/OrderBy.ts';
import { ListingMessage } from '../../../../_shared/uiComponents/ListingMessage/ListingMessage.tsx';
import {
  DataUiAction,
  DataUiAppName,
  DataUiCollection,
  getDataUiAppNameAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getUpperBoundedNumber } from '../../../../_shared/utils/getUpperBoundedNumber.ts';
import { buildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { isEmptyOrWhitespace } from '../../../../_shared/utils/stringUtils.ts';
import { IContentTypeSnippet } from '../../../../data/models/contentModelsApp/snippets/ContentTypeSnippet.ts';
import { maxNumberOfContentTypeUsage } from '../../shared/constants/uiConstants.ts';
import { AuditLogTip } from '../../shared/containers/AuditLogTip.tsx';
import { ContentTypeSnippetListingOperationStatus } from '../utils/contentTypeSnippetListingOperationStatus.ts';

const tableHeadColumns: ReadonlyArray<Column> = [
  {
    columnName: 'Name',
    orderBy: OrderByDirection.None,
  },
  {
    columnName: 'Content types',
    orderBy: OrderByDirection.None,
    className: 'data-table__column--4',
  },
];

type Props = {
  readonly contentTypeSnippetListingLoadingStatus: LoadingStatus;
  readonly contentTypeSnippets: ReadonlyArray<IContentTypeSnippet>;
  readonly createNewSnippetPath: Pathname;
  readonly currentProjectId: Uuid;
  readonly filterSearchPhrase: string;
  readonly listingOperationStatus: ContentTypeSnippetListingOperationStatus;
  readonly onArchiveContentTypeSnippets: (contentTypeIds: Immutable.Set<Uuid>) => void;
  readonly onFilterContentTypeSnippets: (name: string) => void;
  readonly onSelectContentTypeSnippets: (selectedIds: Immutable.Set<Uuid>) => void;
  readonly onUndoOperation?: () => void;
  readonly onUnselectContentTypeSnippets: (unselectedIds: Immutable.Set<Uuid>) => void;
  readonly selectedContentTypeSnippets: Immutable.Set<Uuid>;
  readonly usageCountPerContentTypeSnippet: Immutable.Map<Uuid, number>;
};

const ContentTypeSnippetListing: React.FC<Props> = ({
  contentTypeSnippetListingLoadingStatus,
  contentTypeSnippets,
  createNewSnippetPath,
  currentProjectId,
  filterSearchPhrase,
  listingOperationStatus,
  onArchiveContentTypeSnippets,
  onFilterContentTypeSnippets,
  onSelectContentTypeSnippets,
  onUndoOperation,
  onUnselectContentTypeSnippets,
  selectedContentTypeSnippets,
  usageCountPerContentTypeSnippet,
}) => {
  const getSelectableTypeIdsOutOf = (): Immutable.Set<Uuid> => {
    return Immutable.Set<Uuid>(
      contentTypeSnippets
        .filter((type) => !!type && usageCountPerContentTypeSnippet.get(type.id, 0) === 0)
        .map((type: IContentTypeSnippet) => type.id),
    );
  };

  const selectAllCheckboxClick = (unselect: boolean): void => {
    const selectableTypeIds = getSelectableTypeIdsOutOf();
    if (unselect) {
      onUnselectContentTypeSnippets(selectableTypeIds);
    } else {
      onSelectContentTypeSnippets(selectableTypeIds);
    }
  };

  const archiveContentTypeSnippets = (): void => {
    // Unselect archived Content types, since they can't be accessed via the UI yet
    onUnselectContentTypeSnippets(selectedContentTypeSnippets);
    onArchiveContentTypeSnippets(selectedContentTypeSnippets);
  };

  const renderSnippetsList = (): Array<JSX.Element> => {
    return contentTypeSnippets.map((listingContentSnippetItem: IContentTypeSnippet) => {
      const typeId = listingContentSnippetItem.id;
      // Selection data
      const isSelected = selectedContentTypeSnippets.includes(typeId);
      const selectionAction = isSelected
        ? () => onUnselectContentTypeSnippets(Immutable.Set.of<Uuid>(typeId))
        : () => onSelectContentTypeSnippets(Immutable.Set.of<Uuid>(typeId));

      const numberOfContentItemsUsedIn = usageCountPerContentTypeSnippet.get(typeId, 0);
      const linkPath = buildPath<EditContentTypeSnippetRouteParams>(EditContentTypeSnippetRoute, {
        projectId: currentProjectId,
        contentTypeSnippetId: typeId,
      });

      const tableCellClassNames = classNames({ 'data-table__cell-item--is-disabled': !linkPath });

      return (
        <DataTableRow
          key={typeId}
          id={typeId}
          isSelected={isSelected}
          selectionDisabled={numberOfContentItemsUsedIn > 0}
          onSelectionChange={selectionAction}
          showCheckboxes
          dataUiObjectName={listingContentSnippetItem.name}
          disabledCheckboxBalloonText="Snippet is used in content types."
        >
          <LinkDataTableCell
            linkPath={linkPath}
            className={tableCellClassNames}
            focusableRowLinkAriaLabel={`visit content type snippet — ${listingContentSnippetItem.name}`}
          >
            <SearchPhraseHighlighter
              searchPhrase={filterSearchPhrase}
              text={listingContentSnippetItem.name}
            />
          </LinkDataTableCell>
          <LinkDataTableCell linkPath={linkPath} className={tableCellClassNames}>
            {getUpperBoundedNumber(numberOfContentItemsUsedIn, maxNumberOfContentTypeUsage)}
          </LinkDataTableCell>
        </DataTableRow>
      );
    });
  };

  const renderDataTableGridComponent = (): JSX.Element => {
    const actions: ReadonlyArray<DataTableAction> =
      selectedContentTypeSnippets && !selectedContentTypeSnippets.isEmpty()
        ? [
            {
              dataUiAction: DataUiAction.Delete,
              iconName: 'Bin',
              isDestructive: true,
              isDisabled: false,
              onClick: archiveContentTypeSnippets,
              text: 'Delete',
            },
          ]
        : [];

    const selectableTypeIds = getSelectableTypeIdsOutOf();
    const isMainCheckboxChecked =
      !selectableTypeIds.isEmpty() && selectableTypeIds.isSubset(selectedContentTypeSnippets);

    let infoMessage: ReactNode = null;
    let headRow: ReactNode = null;

    if (
      contentTypeSnippetListingLoadingStatus === LoadingStatus.Loaded &&
      !contentTypeSnippets.length
    ) {
      if (isEmptyOrWhitespace(filterSearchPhrase)) {
        infoMessage = (
          <EmptyState>
            <EmptyState.Title>Create reusable groups of content elements</EmptyState.Title>
            <EmptyState.Content>
              <EmptyState.ContentGroup>
                <p>
                  Ever wanted to use the same group of elements in multiple content types? Snippets
                  give you just that!
                </p>
                <p>Tip: Use the snippets for reusable content such as metadata.</p>
              </EmptyState.ContentGroup>
            </EmptyState.Content>
            <EmptyState.Footer>
              <RouterLinkButton buttonStyle="primary" to={createNewSnippetPath}>
                Create new Content type snippet
              </RouterLinkButton>
            </EmptyState.Footer>
          </EmptyState>
        );
      } else {
        infoMessage = (
          <EmptyState>
            <EmptyState.Title>No Content type snippets found.</EmptyState.Title>
            <EmptyState.Content>
              Try rephrasing your search phrase or start over.
            </EmptyState.Content>
            <EmptyState.Footer>
              <Button buttonStyle="primary" onClick={() => onFilterContentTypeSnippets('')}>
                Clear your search phrase
              </Button>
            </EmptyState.Footer>
          </EmptyState>
        );
      }
    } else {
      headRow = (
        <DataTableHeadRow
          columns={tableHeadColumns}
          allEntriesSelected={isMainCheckboxChecked}
          isAllEntriesSelectorDisabled={selectableTypeIds.isEmpty()}
          onCheckboxChange={() => selectAllCheckboxClick(isMainCheckboxChecked)}
          showCheckboxes
        />
      );
    }

    return (
      <DataTable
        createNewItemLinkPath={createNewSnippetPath}
        actions={actions}
        dataUiCollectionName={DataUiCollection.Snippets}
        title={
          <ListingMessage
            statusInfoMessage={{
              text: listingOperationStatus.message,
              style: listingOperationStatus.messageStyle,
            }}
            onUndo={onUndoOperation}
          />
        }
        header={headRow}
        infoMessage={infoMessage}
      >
        {renderSnippetsList()}
      </DataTable>
    );
  };

  const listing =
    contentTypeSnippetListingLoadingStatus !== LoadingStatus.Loaded ? (
      <Loader />
    ) : (
      <>
        {renderDataTableGridComponent()}
        {!!contentTypeSnippets.length && <AuditLogTip />}
      </>
    );

  return (
    <div className="canvas__workspace">
      <div className="canvas__content">
        <div
          className="canvas__content-pane"
          {...getDataUiAppNameAttribute(DataUiAppName.SnippetListing)}
        >
          <HtmlPageTitle appName={AppNames.ContentTypeSnippets} />
          <div className="canvas__inner-section canvas__inner-section--restricted-width canvas__inner-section--centered">
            <Stack spacing={Spacing.XL}>
              {(contentTypeSnippets.length || !isEmptyOrWhitespace(filterSearchPhrase)) && (
                <TextFilter
                  onChange={onFilterContentTypeSnippets}
                  text={filterSearchPhrase}
                  autofocus
                  ariaLabel="Search content type snippets"
                />
              )}
              {listing}
            </Stack>
          </div>
        </div>
      </div>
    </div>
  );
};

ContentTypeSnippetListing.displayName = 'ContentTypeSnippetListing';

const ContentTypeSnippetListingMemoized = memo(ContentTypeSnippetListing);
export { ContentTypeSnippetListingMemoized as ContentTypeSnippetListing };
