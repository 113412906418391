import React, { useState } from 'react';
import { DataUiElement } from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  ITypeElementDataWithValidationRegex,
  ValidationRegex,
  emptyValidationRegex,
} from '../../../models/elements/types/TypeElementData.ts';
import {
  ITypeElementDataProps,
  ITypeElementOwnProps,
} from '../../../types/ITypeElementProps.type.ts';
import { TypeElementRegexValidationResult } from '../../../utils/typeElementValidators/types/TypeElementRegexValidationResult.type.ts';
import { TypeElementConfigurationCategory } from './TypeElementConfigurationCategory.tsx';
import { LimitationCategory } from './configuration/LimitationCategory.tsx';
import { LimitationCategoryList } from './configuration/LimitationCategoryList.tsx';
import { ValidationRegexConfig } from './configuration/ValidationRegexConfig.tsx';

interface ITypeElementRegexProps
  extends ITypeElementDataProps<
    ITypeElementDataWithValidationRegex,
    TypeElementRegexValidationResult
  > {
  readonly showPredefinedRegexes: boolean;
}

type TypeElementRegexProps = ITypeElementOwnProps<
  ITypeElementDataWithValidationRegex,
  TypeElementRegexValidationResult
> &
  ITypeElementRegexProps;

export const TypeElementRegex: React.FC<TypeElementRegexProps> = (props: TypeElementRegexProps) => {
  const { onChange, validationResult, typeElementData, showPredefinedRegexes } = props;

  const { validationRegex } = typeElementData;

  const [isExpanded, setIsExpanded] = useState(false);

  const regexLimitWithError =
    !validationResult.isRegexLengthValid ||
    !validationResult.isRegexPatternValid ||
    !validationResult.isRegexValidationMessageLengthValid;
  const isRegexAllowed = validationRegex?.isActive ?? false;

  const updateElement = (updatedElement: ITypeElementDataWithValidationRegex): void =>
    onChange(updatedElement);

  const updateValidationRegex = (regexValue: ValidationRegex | null): void =>
    updateElement({
      ...typeElementData,
      validationRegex: regexValue,
    });

  const updateIsRegexAllowed = (isAllowed: boolean) => {
    const newValidationRegex = {
      ...(typeElementData.validationRegex ?? emptyValidationRegex),
      isActive: isAllowed,
    };

    updateValidationRegex(newValidationRegex);
  };

  return (
    <LimitationCategoryList>
      <LimitationCategory
        dataUiElement={DataUiElement.TextValidationRegexLimitationCategory}
        hasInvalidLimits={regexLimitWithError}
        isAllowed={isRegexAllowed}
        isExpanded={isExpanded}
        title="Match a specific pattern"
        onCollapse={() => setIsExpanded(false)}
        onExpand={() => setIsExpanded(true)}
        onChange={updateIsRegexAllowed}
      >
        <TypeElementConfigurationCategory>
          <ValidationRegexConfig
            typeElementData={typeElementData}
            showPredefinedRegexes={showPredefinedRegexes}
            onChange={updateValidationRegex}
          />
        </TypeElementConfigurationCategory>
      </LimitationCategory>
    </LimitationCategoryList>
  );
};
