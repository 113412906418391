import { Button } from '@kontent-ai/component-library/Button';
import { Collection, groupBy } from '@kontent-ai/utils';
import React, { useState } from 'react';
import { useLocation } from 'react-router';
import {
  NotificationBar,
  NotificationBarType,
} from '../../../../../../_shared/components/NotificationBar.tsx';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { parseElementOperationId } from '../../../../../contentInventory/content/utils/itemEditorOperationIdUtils.ts';
import { getIsOnline } from '../../../../selectors/getIsOnline.ts';
import { saveElementValuesToServer } from '../../actions/thunkContentItemEditingActions.ts';

type ResaveNotificationBar = {
  readonly elementName: string | null;
};

const hasOperationIdAndElementId = (
  elementOperationIdParams: ReturnType<typeof parseElementOperationId>,
): elementOperationIdParams is NonNullableProps<
  Required<ReturnType<typeof parseElementOperationId>>
> => {
  return !!elementOperationIdParams.operationId && !!elementOperationIdParams.elementId;
};

const getFailedElementOperationData = (state: IStore) => {
  const {
    contentApp: {
      editedContentItemStatus: { failures },
      editedContentItemVariantElements,
    },
  } = state;

  const operationParamsGroupedByOperationId = Collection.getEntries(
    groupBy(
      Collection.getKeys(failures).map(parseElementOperationId).filter(hasOperationIdAndElementId),
      (operationParams) => operationParams.operationId,
    ),
  );

  return operationParamsGroupedByOperationId.map(([operationId, operationParamArray]) => {
    const elementIds = operationParamArray.map((operationParams) => operationParams.elementId);
    const elementData = editedContentItemVariantElements.filter((element) =>
      elementIds.includes(element.elementId),
    );

    return { operationId, elementData };
  });
};

const useResaveElements = () => {
  const dispatch = useDispatch();
  const failedElementOperationData = useSelector(getFailedElementOperationData);
  const [isResaveInProgress, setIsResaveInProgress] = useState(false);
  const { pathname } = useLocation();

  const resave = async () => {
    try {
      setIsResaveInProgress(true);
      const savingPromises = failedElementOperationData.map(({ elementData, operationId }) => {
        return dispatch(
          saveElementValuesToServer({
            elementsData: elementData,
            operationId,
            pathname,
          }),
        );
      });

      await Promise.allSettled(savingPromises);
    } finally {
      setIsResaveInProgress(false);
    }
  };

  return { resave, isResaveInProgress };
};

export const ResaveNotificationBar: React.FC<ResaveNotificationBar> = ({ elementName }) => {
  const isOnline = useSelector(getIsOnline);
  const { resave, isResaveInProgress } = useResaveElements();

  return (
    <NotificationBar
      type={NotificationBarType.Error}
      message={`We can’t save your changes in ${elementName || 'one of the elements'}.${
        isOnline ? '' : ' You are offline.'
      }`}
      tools={
        <Button
          size="small"
          buttonStyle="primary-inverse"
          onClick={isResaveInProgress ? undefined : resave}
          disabled={isResaveInProgress}
        >
          {isResaveInProgress && <Button.ProgressIcon />}
          {isResaveInProgress ? 'Saving' : 'Retry save'}
        </Button>
      }
    />
  );
};
