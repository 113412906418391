import { assert, noOperation } from '@kontent-ai/utils';
import classNames from 'classnames';
import { DraftBlockRenderMap } from 'draft-js';
import Immutable from 'immutable';
import React, {
  CSSProperties,
  MouseEvent,
  ReactNode,
  RefObject,
  useRef,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { flushSync } from 'react-dom';
import { useEventListener } from '../../../../../_shared/hooks/useEventListener.ts';
import {
  DeferAutoScrollCssClass,
  waitUntilFocusAndScrollAreNotDeferred,
} from '../../../../../_shared/utils/autoScrollUtils.ts';
import { logError } from '../../../../../_shared/utils/logError.ts';
import { CommentThreadPositionerClassName } from '../../../../itemEditor/features/ContentItemEditing/components/comments/CommentThreadPositioner.tsx';
import {
  SelectableContainerClassName,
  TableClassName,
} from '../../../editorCore/utils/editorComponentUtils.ts';
import { isBlockNestedIn } from '../../../utils/blocks/blockTypeUtils.ts';
import { getBlockFromBlockElement } from '../../draftJs/utils/draftJsEditorUtils.ts';
import { getCoordinatesFromDepth } from '../api/depthCoordinatesConversion.ts';
import { RowCells, Table } from './Table.tsx';
import { TableCell } from './TableCell.tsx';
import { isPointerApiEnabled } from './TableColumnResizer.tsx';
import { resizeTableColumns } from './tableColumnResizeHandlers.ts';

const ExtraSpaceForTableCarets = 3;

function getWidthStyle(width: number | undefined): CSSProperties | undefined {
  if (width === undefined) {
    return undefined;
  }
  return { width: `${Math.max(Math.round(width), 0)}px` };
}

type Props = {
  readonly children?: ReactNode;
  readonly beforeTable?: ReactNode;
  readonly getRenderMap: () => DraftBlockRenderMap;
  readonly onContextMenu?: (blockKey: string, cellRef: React.RefObject<HTMLElement>) => void;
};

type State = {
  readonly colSizes: Immutable.Map<number, number>;
  readonly reachedMaxWidth: boolean;
  readonly reachedMaxWidthChecked: boolean;
};

const initialState: State = {
  colSizes: Immutable.Map(),
  reachedMaxWidth: false,
  reachedMaxWidthChecked: false,
};

export const TableCellWrapper = ({ children, beforeTable, getRenderMap, onContextMenu }: Props) => {
  const headingCellRefs = useRef<Map<number, RefObject<HTMLDivElement>>>(new Map());
  const tableWrapperRef = useRef<HTMLDivElement>(null);

  const [state, setState] = useState(initialState);

  const trackReachedMaxWidth = useCallback(() => {
    setState((prevState) => {
      const wrapper = tableWrapperRef.current;
      if (!wrapper || !wrapper.parentElement) {
        return prevState;
      }

      const hasMaxWidth =
        wrapper.parentElement.getBoundingClientRect().width -
          wrapper.getBoundingClientRect().width <=
        ExtraSpaceForTableCarets;
      if (!hasMaxWidth || prevState.reachedMaxWidth) {
        return prevState.reachedMaxWidthChecked
          ? prevState
          : {
              reachedMaxWidth: false,
              reachedMaxWidthChecked: true,
              colSizes: prevState.colSizes,
            };
      }

      // When table reaches max width and some column sizes are set, capture current column widths to prevent column sizes to jump unexpectedly
      const colSizes = prevState.colSizes.isEmpty()
        ? prevState.colSizes
        : Immutable.Map<number, number>().withMutations((result) => {
            for (let colIndex = 0; colIndex < 1000; colIndex++) {
              const colWidth = getColumnWidth(colIndex);
              if (colWidth !== null) {
                result.set(colIndex, colWidth);
              }
            }
          });

      return {
        colSizes,
        reachedMaxWidth: true,
        reachedMaxWidthChecked: true,
      };
    });
  }, []);

  const deferredTrackReachedMaxWidth = useMemo(
    () =>
      waitUntilFocusAndScrollAreNotDeferred(trackReachedMaxWidth, [
        // Allow the initial table layout calculation
        TableClassName,
        // Do not wait for comments to be positioned, as they are waiting for this (and other) content to properly layout
        CommentThreadPositionerClassName,
      ]),
    [trackReachedMaxWidth],
  );

  const handleContextMenu = (
    e: MouseEvent,
    blockKey: string,
    cellContentRef: React.RefObject<HTMLDivElement>,
  ) => {
    // Keep native browser behavior with CTRL key
    if (e.ctrlKey) {
      return;
    }

    if (onContextMenu) {
      e.stopPropagation();
      e.preventDefault();
      onContextMenu(blockKey, cellContentRef);
    }
  };

  const onResizeStart = (event: PointerEvent) => resizeTableColumns(event, onResizeColumn);

  const onResizeColumn = (colIndex: number, delta: number): Promise<number> => {
    return new Promise((resolve) => {
      // Phase 1 - Try to set the desired width
      let resizedOriginalWidth: number | null = null;
      let nextOriginalWidth: number | null = null;
      let appliedDelta = 0;
      let isNextColumnResized = false;

      flushSync(() => {
        setState((prevState) => {
          resizedOriginalWidth = getColumnWidth(colIndex);
          if (resizedOriginalWidth === null) {
            resolve(0);
            return prevState;
          }

          const desiredNewWidth = Math.max(resizedOriginalWidth + delta, 0);
          appliedDelta = desiredNewWidth - resizedOriginalWidth;

          const withNewWidth = prevState.colSizes.set(colIndex, desiredNewWidth);

          if (!prevState.reachedMaxWidth) {
            return { ...prevState, colSizes: withNewWidth };
          }

          // Compensate the next column size to keep the table consistent and not influence other cells
          // but only in case the table is already at the full available size
          nextOriginalWidth = getColumnWidth(colIndex + 1);

          isNextColumnResized = prevState.reachedMaxWidth && nextOriginalWidth !== null;
          const withCompensatedNext =
            isNextColumnResized && nextOriginalWidth !== null
              ? withNewWidth.set(colIndex + 1, Math.max(nextOriginalWidth - appliedDelta, 0))
              : withNewWidth;

          return { ...prevState, colSizes: withCompensatedNext };
        });
      });

      // Phase 2 - Verify what was applied, if necessary ensure proper value is remembered and rollback unwanted influence on next column
      const resizedActualWidth = getColumnWidth(colIndex);
      if (resizedOriginalWidth === null || resizedActualWidth === null) {
        resolve(0);
        return;
      }

      const resizedActuallyAppliedDelta = resizedActualWidth - resizedOriginalWidth;
      if (resizedActuallyAppliedDelta === appliedDelta) {
        resolve(appliedDelta);
        return;
      }

      const nextActualWidth = getColumnWidth(colIndex + 1);
      const nextActuallyAppliedDelta =
        isNextColumnResized && nextOriginalWidth !== null && nextActualWidth !== null
          ? nextActualWidth - nextOriginalWidth
          : resizedActuallyAppliedDelta;

      // The actually applied delta is the smaller of the changes in resize and next column
      // because table layout may weight desired sizes in case all columns have some size specified
      // We need to prevent scenario where the cell resizes just because its desired size grows
      // but there is no more space for the growth because the next cell cannot shrink any more
      const actuallyAppliedDelta =
        isNextColumnResized &&
        Math.abs(resizedActuallyAppliedDelta) > Math.abs(nextActuallyAppliedDelta)
          ? nextActuallyAppliedDelta
          : resizedActuallyAppliedDelta;

      // Adjust the column widths back based on actually applied widths
      flushSync(() => {
        setState((prevState) => {
          const withActualWidth = prevState.colSizes.set(colIndex, resizedActualWidth);
          const newNextColumnWidth = prevState.colSizes.get(colIndex + 1);
          const withRestoredNext =
            isNextColumnResized && newNextColumnWidth !== undefined
              ? withActualWidth.set(
                  colIndex + 1,
                  Math.max(newNextColumnWidth + appliedDelta - actuallyAppliedDelta),
                )
              : withActualWidth;

          return { ...prevState, colSizes: withRestoredNext };
        });
      });

      // Phase 3 - When the actual values needed adjustment, double-check the actual change because neither of the previous deltas may be 100% correct
      const finalWidth = getColumnWidth(colIndex);
      if (resizedOriginalWidth === null || finalWidth === null) {
        resolve(0);
        return;
      }

      // Report back finally applied delta to keep it consistent in the part which actually tracks the mouse offset
      const finallyAppliedDelta = finalWidth - resizedOriginalWidth;
      resolve(finallyAppliedDelta);
    });
  };

  const getRefForHeadingCell = (colIndex: number): React.RefObject<HTMLDivElement> => {
    const existingRef = headingCellRefs.current.get(colIndex);
    if (existingRef) {
      return existingRef;
    }

    const newRef = React.createRef<HTMLDivElement>();
    headingCellRefs.current.set(colIndex, newRef);
    return newRef;
  };

  const getColumnWidth = (colIndex: number): number | null => {
    const cell = headingCellRefs.current.get(colIndex)?.current;
    if (!cell) {
      return null;
    }
    return cell.getBoundingClientRect().width;
  };

  const renderRow = (cells: RowCells, rowIndex: number): JSX.Element => {
    const totalColumns = cells.length;
    const defaultWidthStyle = state.reachedMaxWidth
      ? { width: `${Math.floor(100 / totalColumns)}%` }
      : undefined;

    const isFirstRow = rowIndex === 0;

    return (
      <div className="rte__table-row" key={rowIndex}>
        {cells.map((cellChildren: ReadonlyArray<React.ReactElement>, depth: number) => {
          const colIndex = getCoordinatesFromDepth(depth).x;
          const isLastColumn = colIndex === totalColumns - 1;

          const firstCellChild = cellChildren[0];
          assert(firstCellChild, () => 'Child at index 0 is falsy.');
          const cellBlock = getBlockFromBlockElement(firstCellChild);
          const cellContentBlocks = cellChildren.filter((child) => {
            const childBlock = getBlockFromBlockElement(child);
            return !!childBlock && isBlockNestedIn(childBlock, cellBlock);
          });

          const style = isFirstRow
            ? (getWidthStyle(state.colSizes.get(colIndex)) ?? defaultWidthStyle)
            : undefined;

          if (!cellContentBlocks.length) {
            logError('Table cell is missing content blocks');
          }

          return (
            <TableCell
              key={colIndex}
              ref={getRefForHeadingCell(colIndex)}
              blockKey={cellBlock.getKey()}
              columnIndex={colIndex}
              hasResizer={!isLastColumn || !state.reachedMaxWidth}
              blockRenderMap={getRenderMap()}
              onContextMenu={handleContextMenu}
              style={style}
            >
              {cellContentBlocks}
            </TableCell>
          );
        })}
      </div>
    );
  };

  // Observe mouse events and start resizing table columns when column edge is dragged
  useEventListener(
    'pointerdown',
    isPointerApiEnabled ? onResizeStart : noOperation,
    tableWrapperRef.current,
  );

  return (
    <div
      ref={tableWrapperRef}
      className={classNames(SelectableContainerClassName, TableClassName, {
        'rte__table--reached-max-width': state.reachedMaxWidth,
        [DeferAutoScrollCssClass]: !state.reachedMaxWidthChecked,
      })}
    >
      {beforeTable}
      <Table onResize={deferredTrackReachedMaxWidth} renderRow={renderRow}>
        {children}
      </Table>
    </div>
  );
};
