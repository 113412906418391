import { useLocation } from 'react-router-dom';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { ContentItemId } from '../../../../../_shared/models/ContentItemId.ts';
import { getListingContentItem } from '../../../../../_shared/selectors/getListingContentItem.ts';
import { getCurrentProjectId } from '../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { getContentItemPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { IContentType } from '../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { useLivePreviewPreferenceStorage } from '../../../../../localStorages/useLivePreviewPreferenceStorage.ts';
import {
  FailedItemRow as FailedItemRowComponent,
  FailedItemRowProps,
} from '../../components/FailedItemsModal/FailedItemRow.tsx';

type FailedItemRowContainerProps = FailedItemRowProps &
  Readonly<{
    contentItemId: ContentItemId;
  }>;

export const FailedItemRow = ({
  contentItemId,
  showCollectionColumn,
}: FailedItemRowContainerProps) => {
  const { pathname } = useLocation();
  const currentProjectId = useSelector(getCurrentProjectId);
  const { isLivePreviewPreferred } = useLivePreviewPreferenceStorage(currentProjectId);

  const item = useSelector((s) => getListingContentItem(s, contentItemId));
  const itemType = useSelector((s) =>
    s.data.contentTypes.byId.get(item?.item.typeId ?? ''),
  ) as IContentType | null;
  const itemCollection = useSelector((s) =>
    s.data.collections.byId.get(item?.item.collectionId ?? ''),
  );
  const itemPath = item
    ? getContentItemPath(pathname, item.item.id, isLivePreviewPreferred, contentItemId.variantId)
    : null;

  return (
    <FailedItemRowComponent
      item={item}
      itemCollection={itemCollection}
      itemPath={itemPath}
      itemType={itemType}
      showCollectionColumn={showCollectionColumn}
    />
  );
};
