import { useCallback } from 'react';
import { taxonomyTermCollapsionToggled } from '../../../../../_shared/actions/sharedActions.ts';
import { trackUserEvent } from '../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import {
  CodenameEventType,
  CodenameTargetType,
} from '../../../../../_shared/models/TrackUserEventData.ts';
import { isSelfOrAnyChildBeingEdited } from '../../../../../_shared/utils/treeUtils.ts';
import {
  taxonomyTermCodenameChanged,
  taxonomyTermDropped,
  taxonomyTermEditingInitiated,
} from '../../actions/taxonomyActions.ts';
import { pickUpTaxonomyTerm } from '../../actions/thunkTaxonomyActions.ts';
import {
  DraggableTermBar as DraggableTermBarComponent,
  IDraggableTermBarOwnProps,
} from '../../components/termBarItems/DraggableTermBar.tsx';
import { TaxonomyEditorStatus } from '../../models/TaxonomyEditorStatus.ts';
import { getTaxonomyTermCodenames } from '../../utils/taxonomyCodenameUtils.ts';

export const DraggableTermBar = (props: IDraggableTermBarOwnProps) => {
  const { taxonomyTerm } = props;

  const isBeingDragged = useSelector((s) => s.taxonomyApp.draggedTermId === taxonomyTerm.id);
  const isBeingSaved = useSelector(
    (s) => s.taxonomyApp.status === TaxonomyEditorStatus.IsBeingSaved,
  );
  const isEditable = useSelector(
    (s) => !s.taxonomyApp.editedTaxonomyTermId && !s.taxonomyApp.isCreateNewFormDisplayed,
  );
  const isExpandCollapseButtonDisabled = useSelector(
    ({ taxonomyApp: { editedTaxonomyTermId, editedTaxonomyGroup } }) =>
      isSelfOrAnyChildBeingEdited(
        taxonomyTerm.id,
        editedTaxonomyTermId,
        (termId) => editedTaxonomyGroup.terms.get(termId)?.childIds ?? [],
      ),
  );
  const allCodenames = useSelector((s) =>
    getTaxonomyTermCodenames(s.taxonomyApp.editedTaxonomyGroup.terms),
  );
  const editedTaxonomyGroupId = useSelector((s) => s.taxonomyApp.editedTaxonomyGroup.id);

  const dispatch = useDispatch();
  const onCodenameSave = useCallback(
    (codename: string, onSaved: () => void) => {
      dispatch(taxonomyTermCodenameChanged(taxonomyTerm.id, codename));
      onSaved();
    },
    [taxonomyTerm.id],
  );
  const onCodenameCopy = useCallback(
    () =>
      dispatch(
        trackUserEvent(TrackedEvent.Codename, {
          type: CodenameEventType.Copy,
          target: CodenameTargetType.TaxonomyTerm,
        }),
      ),
    [],
  );
  const onCodenameEdit = useCallback(
    () =>
      dispatch(
        trackUserEvent(TrackedEvent.Codename, {
          type: CodenameEventType.Edit,
          target: CodenameTargetType.TaxonomyTerm,
        }),
      ),
    [],
  );
  const onTermDropped = useCallback(() => dispatch(taxonomyTermDropped()), []);
  const onTermEditingInitiated = useCallback(
    () => dispatch(taxonomyTermEditingInitiated(taxonomyTerm.id)),
    [taxonomyTerm.id],
  );
  const onTermPickedUp = useCallback(
    () => dispatch(pickUpTaxonomyTerm(taxonomyTerm.id)),
    [taxonomyTerm.id],
  );
  const onTermToggled = useCallback(
    () => dispatch(taxonomyTermCollapsionToggled(editedTaxonomyGroupId, taxonomyTerm.id)),
    [editedTaxonomyGroupId, taxonomyTerm.id],
  );

  return (
    <DraggableTermBarComponent
      allCodenames={allCodenames}
      isBeingDragged={isBeingDragged}
      isBeingSaved={isBeingSaved}
      isCollapsed={props.isCollapsed}
      isEditable={isEditable}
      isExpandCollapseButtonDisabled={isExpandCollapseButtonDisabled}
      onCodenameSave={onCodenameSave}
      onCodenameCopy={onCodenameCopy}
      onCodenameEdit={onCodenameEdit}
      onTermDropped={onTermDropped}
      onTermEditingInitiated={onTermEditingInitiated}
      onTermPickedUp={onTermPickedUp}
      onTermToggled={onTermToggled}
      taxonomyTerm={props.taxonomyTerm}
    />
  );
};
