import { Collection } from '@kontent-ai/utils';
import React, { useMemo } from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { isLanguageActive } from '../../../../../_shared/models/utils/isLanguageActive.ts';
import { getNormalizedRolesWithSettings } from '../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { VariantCompletionStatus } from '../../../../../_shared/utils/contentItemVariantUtils.ts';
import { DataUiCollection } from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getAllLanguageIds } from '../../../../../_shared/utils/languageUtils.ts';
import { Capability } from '../../../../../_shared/utils/permissions/capability.ts';
import { getLanguageIdsWithAllowedCapabilityInAnyCollection } from '../../../../../_shared/utils/permissions/getLanguageIdsWithAllowedCapability.ts';
import { IUser } from '../../../../../data/reducers/user/IUser.type.ts';
import { ListingFilterCategorySelector } from '../../../../contentInventory/content/features/ListingFilter/components/ListingFilterCategorySelector.tsx';
import {
  ContentItemStatusWithTranslationOptions,
  ContentItemStatusWithoutTranslationOptions,
  IContentItemStatus,
  convertContentItemStatusToQueryParams,
  getContentItemStatusFromIds,
} from '../../../../contentInventory/content/models/filter/IContentItemStatus.ts';

type Props = Readonly<{
  onContentItemStatusSelectionChanged: (itemStatus: IContentItemStatus) => void;
  selectedContentItemStatus: IContentItemStatus;
  user: IUser;
}>;

export const ContentItemStatusSelector: React.FC<Props> = (props) => {
  const isFilterCategoryVisible = useSelector(
    (state) => !Collection.isEmpty(state.data.users.usersById),
  );

  const showTranslationOptionsInStatusCategory = useSelector((s) => {
    const normalizedRoles = getNormalizedRolesWithSettings(s);
    const allLanguageIds = getAllLanguageIds(s.data.languages);
    const canCreateContent = !getLanguageIdsWithAllowedCapabilityInAnyCollection(
      normalizedRoles,
      allLanguageIds,
      Capability.CreateContent,
      s.data.collections.byId,
    ).isEmpty();
    const hasAnyActiveCustomLanguage = s.data.languages.byId.some(isLanguageActive);
    return canCreateContent && hasAnyActiveCustomLanguage;
  });

  const publishStateOptions = showTranslationOptionsInStatusCategory
    ? ContentItemStatusWithTranslationOptions
    : ContentItemStatusWithoutTranslationOptions;

  const selectedOptionIds: ReadonlySet<VariantCompletionStatus> = useMemo(() => {
    const selectedContentItemStatusIds = convertContentItemStatusToQueryParams(
      props.selectedContentItemStatus,
    );
    return new Set(
      publishStateOptions
        .filter((option) => selectedContentItemStatusIds.includes(option.id))
        .map((option) => option.id),
    );
  }, [props.selectedContentItemStatus, publishStateOptions]);

  return isFilterCategoryVisible ? (
    <ListingFilterCategorySelector
      options={publishStateOptions}
      collection={DataUiCollection.Statuses}
      placeholder="Select a status"
      title="Status"
      onChange={(selectedIds) => {
        const selectedContentItemStatusRecord = getContentItemStatusFromIds(new Set(selectedIds));
        props.onContentItemStatusSelectionChanged(selectedContentItemStatusRecord);
      }}
      selectedOptionIds={selectedOptionIds}
    />
  ) : null;
};
