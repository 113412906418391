import { ComponentProps } from 'react';
import { DragSource } from '../../../../../../../_shared/components/DragDrop/DragSource.tsx';
import { DropTarget } from '../../../../../../../_shared/components/DragDrop/DropTarget.tsx';
import { DndTypes } from '../../../../../../../_shared/constants/dndTypes.ts';
import { isWithinTargetInset20 } from '../../../../../../../_shared/utils/dragDrop/hoveringCollisionStrategies.ts';
import { MultipleChoiceOption } from './MultipleChoiceOption.tsx';

type Props = Omit<
  ComponentProps<typeof MultipleChoiceOption>,
  'isDragging' | 'connectDragSource'
> & {
  readonly onDropMultipleChoiceOption: () => void;
  readonly onPickUpMultipleChoiceOption: (id: Uuid) => void;
  readonly onMove: (draggedOptionId: Uuid, targetOptionId: Uuid) => void;
};

export const DraggableMultipleChoiceOption = (props: Props) => {
  const { onDropMultipleChoiceOption, onMove, onPickUpMultipleChoiceOption, ...optionProps } =
    props;

  return (
    <DropTarget<HTMLLIElement>
      accept={DndTypes.Multiple_Choice_Option_Move}
      canDrop={!optionProps.isBeingEdited}
      hoveringCollisionStrategy={isWithinTargetInset20}
      onMove={({ sourceId, targetId }) => onMove(sourceId, targetId)}
      parentId=""
      renderDroppable={(ref) => (
        <li className="bar-item__node" ref={ref}>
          <DragSource
            parentId=""
            sourceId={optionProps.multipleChoiceOption.id}
            onDragStart={() => onPickUpMultipleChoiceOption(optionProps.multipleChoiceOption.id)}
            onDragEnd={onDropMultipleChoiceOption}
            renderDraggable={(connectDragSource, isDragging) => (
              <MultipleChoiceOption
                {...optionProps}
                connectDragSource={connectDragSource}
                isDragging={isDragging}
              />
            )}
            renderPreview={() => <MultipleChoiceOption {...optionProps} isDragging={false} />}
            type={DndTypes.Multiple_Choice_Option_Move}
          />
        </li>
      )}
      targetId={optionProps.multipleChoiceOption.id}
    />
  );
};
