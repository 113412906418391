import { getWorkflow, getWorkflowStepName } from '../../../_shared/selectors/workflowSelectors.ts';
import { IStore } from '../../../_shared/stores/IStore.type.ts';

const fallback = '';

export const getNameOfTheFirstWorkflowStepCurrentRoleCanWorkWith = (state: IStore): string => {
  const {
    contentApp: {
      editorUi: { firstWorkflowStepCurrentRoleCanWorkWithId },
      editedContentItemVariant,
    },
  } = state;

  if (!editedContentItemVariant) {
    return fallback;
  }

  const workflow = getWorkflow(
    state,
    editedContentItemVariant.assignment.workflowStatus.workflowId,
  );

  if (!workflow) {
    return fallback;
  }

  return getWorkflowStepName(workflow, firstWorkflowStepCurrentRoleCanWorkWithId) || fallback;
};
