import { memoize } from '@kontent-ai/memoization';
import { Collection, createCompare, naturally } from '@kontent-ai/utils';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import { ISpace, SpacesMap } from '../../../../../../data/models/space/space.ts';
import { ICollectionsData } from '../../../../../../data/reducers/collections/ICollections.type.ts';
import { ILanguagesData } from '../../../../../../data/reducers/languages/ILanguagesData.type.ts';
import { areSpacesInCollectionsEnabled } from '../../../../../environmentSettings/selectors/allowedFeaturesSelectors.ts';
import { spacesByCollectionId } from '../../../../../environmentSettings/spaces/utils/spacesUtils.ts';
import { IPreviewConfiguration } from '../../../../../previewConfiguration/models/PreviewConfiguration.type.ts';
import { IEditedContentItem } from '../../../../models/contentItem/edited/EditedContentItem.ts';
import { EditedContentItemVariant } from '../../../../models/contentItem/edited/EditedContentItemVariant.ts';
import { ICompiledContentItemElementData } from '../../../../models/contentItemElements/ICompiledContentItemElement.type.ts';
import { PreviewUrlInfo, getContentItemPreviewUrlInfo } from '../../../../utils/previewUtils.ts';

export type PreviewLink = PreviewUrlInfo & {
  readonly spaceName: string | undefined;
};

export const selectFirstErrorInPreviewLinks = (state: IStore) => {
  const previewLinks = selectPreviewLinks(state);

  return previewLinks.find(({ error }) => !!error)?.error;
};

export const selectFirsLinkInfo = (state: IStore): PreviewLink | undefined => {
  const previewLinks = selectPreviewLinks(state);

  return previewLinks[0];
};

export const selectPreviewLinks = (state: IStore): ReadonlyArray<PreviewLink> => {
  const {
    contentApp: {
      editedContentItemVariantElements,
      editedContentItemVariant,
      previewConfiguration,
      editedContentItem,
    },
    data: {
      languages,
      collections,
      spaces: { byId: spacesMap },
    },
  } = state;

  const enabledSpacesInCollections = areSpacesInCollectionsEnabled(state);

  return selectPreviewLinksMemoized(
    editedContentItemVariantElements,
    editedContentItemVariant,
    previewConfiguration,
    editedContentItem,
    languages,
    collections,
    spacesMap,
    enabledSpacesInCollections,
  );
};

type SpaceInfo = {
  readonly id: Uuid | null;
  readonly name: string | undefined;
};

const noSpaceInfo: SpaceInfo = {
  id: null,
  name: undefined,
};

const selectPreviewLinksMemoized = memoize.maxOne(
  (
    editedContentItemVariantElements: ReadonlyArray<ICompiledContentItemElementData>,
    editedContentItemVariant: EditedContentItemVariant | null,
    previewConfiguration: IPreviewConfiguration | null,
    editedContentItem: IEditedContentItem | null,
    languages: ILanguagesData,
    collections: ICollectionsData,
    spaces: SpacesMap,
    enabledSpacesInCollections: boolean,
  ): ReadonlyArray<PreviewLink> => {
    const editedContentItemVariantId = editedContentItemVariant?.id.variantId;
    const editedContentItemId = editedContentItem?.id;
    const editedContentItemCodename = editedContentItem?.codename;
    const editedContentItemTypeId = editedContentItem?.editedContentItemTypeId;
    const editedContentItemCollectionId = editedContentItem?.collectionId;

    const previewUrlPatterns = previewConfiguration?.previewUrlPatterns.get(
      editedContentItemTypeId ?? '',
    );
    const usedSpaceIds = new Set(
      previewUrlPatterns?.flatMap((previewUrlPattern) =>
        Collection.getValues(previewUrlPattern.spaces),
      ) ?? [],
    );
    const spacesOfItem = editedContentItem?.collectionId
      ? spacesByCollectionId(spaces, editedContentItem.collectionId)
      : [];

    const filteredSpacesMap: SpacesMap =
      enabledSpacesInCollections && spacesOfItem.length
        ? new Map<Uuid, ISpace>(spacesOfItem.map((space) => [space.id, space]))
        : spaces;

    const spacesInPatterns =
      previewUrlPatterns
        ?.flatMap((previewUrlPattern): ReadonlyArray<SpaceInfo> => {
          const spaceIds = previewUrlPattern.spaces.size
            ? Collection.getValues(previewUrlPattern.spaces)
            : Collection.getKeys(filteredSpacesMap).filter((spaceId) => !usedSpaceIds.has(spaceId));

          return spaceIds.reduce<ReadonlyArray<ISpace>>((reducedSpaces, spaceId) => {
            const space = filteredSpacesMap.get(spaceId);

            if (!space) {
              return reducedSpaces;
            }

            return [...reducedSpaces, space];
          }, []);
        })
        .sort(
          createCompare({
            compare: naturally,
            select: (space) => space.name,
          }),
        ) ?? [];

    const spaceInfos = spacesInPatterns.length ? spacesInPatterns : [noSpaceInfo];

    return spaceInfos.map((spaceInfo) => ({
      ...getContentItemPreviewUrlInfo(
        editedContentItemId,
        editedContentItemCodename,
        editedContentItemTypeId,
        editedContentItemCollectionId,
        editedContentItemVariantId,
        editedContentItemVariantElements,
        previewConfiguration,
        languages.defaultLanguage,
        languages.byId,
        collections.byId,
        spaceInfo.id,
      ),
      spaceName: spaceInfo.name,
    }));
  },
);
