import { memoize } from '@kontent-ai/memoization';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../@types/Dispatcher.type.ts';
import { IconName } from '../../../../_shared/constants/iconEnumGenerated.ts';
import { IFeatures } from '../../../../_shared/models/Features.ts';
import { getProjectPlan } from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { ITaxonomyGroups } from '../../../../data/reducers/taxonomyGroups/ITaxonomyGroups.type.ts';
import {
  ElementType,
  TypeElementType,
} from '../../../contentInventory/content/models/ContentItemElementType.ts';
import {
  TypeElementLibrary as ContentTypeElementLibraryComponent,
  ITypeElementLibraryDispatchProps,
  ITypeElementLibraryStateProps,
  TypeElementItemConfig,
} from '../../shared/components/TypeElementLibrary.tsx';
import { NewElementId } from '../../shared/reducers/typeEditor/draggedElementId.ts';
import { elementTypeNameMap } from '../../shared/utils/typeElementsUtils.ts';
import { typeElementDropped, typeElementPickedUp } from '../actions/snippetsActions.ts';
import { addNewSnippetTypeElementAction } from '../actions/thunkSnippetsActions.ts';

const getItemElementsConfigs = memoize.maxOne(
  (features: IFeatures): ReadonlyArray<TypeElementItemConfig> => {
    return [
      {
        name: elementTypeNameMap[ElementType.Text],
        elementType: ElementType.Text,
        iconName: IconName.ParagraphShort,
      },
      {
        name: elementTypeNameMap[ElementType.RichText],
        elementType: ElementType.RichText,
        iconName: IconName.Paragraph,
      },
      {
        name: elementTypeNameMap[ElementType.Number],
        elementType: ElementType.Number,
        iconName: IconName.Octothorpe,
      },
      {
        name: elementTypeNameMap[ElementType.MultipleChoice],
        elementType: ElementType.MultipleChoice,
        iconName: IconName.CbCheck,
      },
      {
        name: elementTypeNameMap[ElementType.DateTime],
        elementType: ElementType.DateTime,
        iconName: IconName.Calendar,
      },
      {
        name: elementTypeNameMap[ElementType.Asset],
        elementType: ElementType.Asset,
        iconName: IconName.Picture,
      },
      {
        name: elementTypeNameMap[ElementType.LinkedItems],
        elementType: ElementType.LinkedItems,
        iconName: IconName.Puzzle,
      },
      {
        name: elementTypeNameMap[ElementType.Custom],
        elementType: ElementType.Custom,
        iconName: IconName.CustomElement,
        isDisabled: !features.areCustomElementsEnabled,
        disabledMessage:
          'Custom elements are not available \nin your plan.\nUpgrade your subscription.',
      },
    ];
  },
);

const getAuxiliaryElementsConfig = memoize.maxOne(
  (taxonomyGroups: ITaxonomyGroups): ReadonlyArray<TypeElementItemConfig> => {
    const taxonomyGroupExists = !taxonomyGroups.byId.isEmpty();
    return [
      {
        name: elementTypeNameMap[ElementType.Guidelines],
        elementType: ElementType.Guidelines,
        iconName: IconName.SchemePathCircles,
      },
      {
        name: elementTypeNameMap[ElementType.Taxonomy],
        elementType: ElementType.Taxonomy,
        iconName: IconName.Drawers,
        isDisabled: !taxonomyGroupExists,
        disabledMessage: 'Create at least one taxonomy group\nto use this element.',
      },
    ];
  },
);

function mapStateToProps(state: IStore): ITypeElementLibraryStateProps {
  const {
    sharedApp: { currentProjectId },
    data: { taxonomyGroups },
  } = state;

  const currentProjectPlan = getProjectPlan(state, currentProjectId);

  return {
    contentElementToolbarItemConfigurations: getItemElementsConfigs(currentProjectPlan.features),
    auxiliaryElementToolbarItemConfigurations: getAuxiliaryElementsConfig(taxonomyGroups),
  };
}

function mapDispatchToProps(dispatch: Dispatch): ITypeElementLibraryDispatchProps {
  return {
    onContentElementClicked: (elementType: TypeElementType) =>
      dispatch(addNewSnippetTypeElementAction(elementType)),
    onDragEnd: () => dispatch(typeElementDropped()),
    onDragStart: () => dispatch(typeElementPickedUp(NewElementId)),
  };
}

export const ContentTypeSnippetElementLibrary: React.ComponentType = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContentTypeElementLibraryComponent);
