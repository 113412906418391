import { History } from 'history';
import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { SubscriptionsRoute } from '../../../../../_shared/constants/routePaths.ts';
import { getAdministratedSubscriptions } from '../../../../../_shared/utils/subscription/subscriptionUtils.ts';
import { ISubscription } from '../../../../../data/models/subscriptions/Subscription.ts';
import { Subscription_SelectSubscription } from '../../constants/subscriptionManagementActionTypes.ts';

interface IDeps {
  readonly loadAdministratedSubscriptions: (abortSignal?: AbortSignal) => ThunkPromise;
  readonly loadSubscriptionUsage: (subscriptionId: Uuid, abortSignal?: AbortSignal) => ThunkPromise;
}

const selectSubscription = (subscription: ISubscription) =>
  ({
    type: Subscription_SelectSubscription,
    payload: {
      subscription,
    },
  }) as const;

export type EnsureSelectedSubscriptionActionsType = ReturnType<typeof selectSubscription>;

export const createEnsureSelectedSubscriptionAction =
  ({ loadAdministratedSubscriptions, loadSubscriptionUsage }: IDeps) =>
  (
    desiredSubscriptionId: Uuid | undefined,
    history: History,
    abortSignal: AbortSignal,
  ): ThunkPromise =>
  async (dispatch, getState) => {
    await dispatch(loadAdministratedSubscriptions(abortSignal));
    const {
      data: { subscriptions },
    } = getState();
    const desiredSubscription = getAdministratedSubscriptions(
      subscriptions.administratedIds,
      subscriptions.byId,
    ).find((sub: ISubscription) => sub.subscriptionId === desiredSubscriptionId);

    if (desiredSubscription) {
      dispatch(selectSubscription(desiredSubscription));
      await dispatch(loadSubscriptionUsage(desiredSubscription.subscriptionId, abortSignal));
    } else {
      history.push(SubscriptionsRoute);
    }
  };
