import Immutable from 'immutable';
import { forwardRef, useMemo } from 'react';
import { IAnimatedModalDialogProps } from '../../../../../_shared/components/ModalDialog/IAnimatedModalDialogProps.type.ts';
import { useDataSelector } from '../../../../../_shared/hooks/useDataSelector.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import {
  getCurrentProjectId,
  getCurrentProjectPlan,
} from '../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { compose } from '../../../../../_shared/utils/func/compose.ts';
import { getContentItemVariantsWithLanguageNames } from '../../../../../_shared/utils/getContentItemVariantsWithLanguageNames.tsx';
import { getAllLanguagesWithDefaultSuffix } from '../../../../../_shared/utils/languageUtils.ts';
import { canMoveItemVariant } from '../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { Capability } from '../../../../../_shared/utils/permissions/capability.ts';
import { hasProjectMultipleWorkflows } from '../../../../../_shared/utils/workflow/hasProjectMultipleWorkflows.ts';
import { useLivePreviewPreferenceStorage } from '../../../../../localStorages/useLivePreviewPreferenceStorage.ts';
import { useAvailableCollections } from '../../../../contentInventory/content/features/ContentItemInventory/selectors/useAvailableCollections.ts';
import { areSpacesInCollectionsEnabled } from '../../../../environmentSettings/selectors/allowedFeaturesSelectors.ts';
import { areSpacesEnabledForCurrentProject } from '../../../../environmentSettings/utils/allowedFeaturesUtils.ts';
import { itemEditingModalDismissed } from '../../../actions/contentActions.ts';
import { IContentItemVariantReference } from '../../../models/contentItem/ContentItemVariantReference.ts';
import { moveItemToCollection } from '../actions/thunkMoveToCollectionDialogActions.ts';
import { MoveToCollectionDialog } from '../components/MoveToCollectionDialog.tsx';

const getEditedItemCollectionId = (state: IStore): Uuid | undefined =>
  state.contentApp.editedContentItem?.collectionId;

export const getUnmovableVariantsIds = (
  contentItemVariants: Immutable.Map<Uuid, IContentItemVariantReference>,
  collectionId: Uuid,
  state: IStore,
): ReadonlyArray<Uuid> =>
  contentItemVariants
    .toArray()
    .filter((contentItem) => !contentItem.isArchived)
    .filter(
      (contentItem) =>
        !canMoveItemVariant(
          contentItem.typeId,
          collectionId,
          contentItem.id.variantId,
          contentItem.assignment,
          state,
        ),
    )
    .map((contentItem) => contentItem.id.variantId);

export const MoveItemToCollectionDialog = forwardRef<HTMLDivElement, IAnimatedModalDialogProps>(
  ({ isOpen }, ref) => {
    const dispatch = useDispatch();
    const onClose = () => dispatch(itemEditingModalDismissed());

    const collectionsUserCanView = useAvailableCollections(Capability.ViewContent);
    const editedItemCollectionId = useSelector(getEditedItemCollectionId);
    const showWorkflowWarning = useDataSelector((state) =>
      hasProjectMultipleWorkflows(state.workflows.byId),
    );
    const spacesMap = useSelector((s) => s.data.spaces.byId);
    const areSpacesEnabled = useSelector(
      (s) =>
        areSpacesEnabledForCurrentProject(getCurrentProjectPlan(s)) &&
        areSpacesInCollectionsEnabled(s),
    );
    const currentProjectId = useSelector(getCurrentProjectId);
    const { isLivePreviewPreferred } = useLivePreviewPreferenceStorage(currentProjectId);

    const editedItemCollectionName =
      collectionsUserCanView.find((collection) => collection.id === editedItemCollectionId)?.name ??
      '';

    const collectionsOptions = useMemo(
      () => collectionsUserCanView.filter((collection) => collection.id !== editedItemCollectionId),
      [collectionsUserCanView, editedItemCollectionId],
    );

    const contentItemVariantsWithLanguagesNames = useSelector((state) =>
      getContentItemVariantsWithLanguageNames(
        state.contentApp.contentItemVariants,
        getAllLanguagesWithDefaultSuffix(state.data.languages),
        state.sharedApp.currentProjectId,
        isLivePreviewPreferred,
      ),
    );

    const unmovableVariantIdsPerCollection: ReadonlyMap<Uuid, ReadonlyArray<Uuid>> = useSelector(
      (state) =>
        new Map(
          collectionsUserCanView.map((collection) => [
            collection.id,
            getUnmovableVariantsIds(state.contentApp.contentItemVariants, collection.id, state),
          ]),
        ),
    );

    const variantIdsUnmovableFromSource =
      (editedItemCollectionId
        ? unmovableVariantIdsPerCollection.get(editedItemCollectionId)
        : []) ?? [];

    const moveCollection = compose(dispatch, moveItemToCollection);

    return editedItemCollectionId ? (
      <MoveToCollectionDialog
        contentItemVariants={contentItemVariantsWithLanguagesNames}
        editedItemCollectionName={editedItemCollectionName}
        isOpen={isOpen}
        onClose={onClose}
        onMove={moveCollection}
        options={collectionsOptions}
        ref={ref}
        unmovableVariantIdsPerCollection={unmovableVariantIdsPerCollection}
        spacesMap={areSpacesEnabled ? spacesMap : null}
        variantIdsUnmovableFromSource={variantIdsUnmovableFromSource}
        showWorkflowWarning={showWorkflowWarning}
      />
    ) : null;
  },
);

MoveItemToCollectionDialog.displayName = 'MoveItemToCollectionDialog';
