import {
  Menu,
  TabbableMenuItem,
  TabbableMenuOutwardLink,
} from '@kontent-ai/component-library/Menu';

import { Badge } from '@kontent-ai/component-library/Badge';
import { loadLatestProductUpdateInfo } from '../../../data/actions/thunkDataActions.ts';
import { modalOpened } from '../../actions/sharedActions.ts';
import { trackUserEvent } from '../../actions/thunks/trackUserEvent.ts';
import { feedbackLinks } from '../../constants/feedbackLinks.ts';
import { ModalDialogType } from '../../constants/modalDialogType.ts';
import { TrackedEvent } from '../../constants/trackedEvent.ts';
import { useDispatch } from '../../hooks/useDispatch.ts';
import { useSelector } from '../../hooks/useSelector.ts';
import { useThunkPromise } from '../../hooks/useThunkPromise.ts';
import { FeedbackTextModalActionType } from '../../models/TrackUserEventData.ts';
import { UmuxSurveyEventTypes } from '../../models/events/UmuxSurveyEventData.type.ts';
import { UserFeedbackSource } from '../../models/events/UserFeedbackEventData.type.ts';
import { shouldShowHelpUsImproveSurveyReminder } from '../../selectors/umuxSurveySelectors.ts';
import {} from '../../selectors/userProjectsInfoSelectors.ts';
import {
  DataUiElement,
  DataUiExternalLinkName,
  getDataUiElementAttribute,
  getDataUiNavAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

export function FeedbackMenuItems() {
  useThunkPromise(loadLatestProductUpdateInfo);

  const dispatch = useDispatch();

  const shouldDisplayHelpUsItem = useSelector((s) =>
    shouldShowHelpUsImproveSurveyReminder(s, new Date()),
  );
  const onClickHelpUsImproveKc = () => {
    dispatch(
      trackUserEvent(TrackedEvent.UmuxSurvey, {
        action: UmuxSurveyEventTypes.Open,
        source: UserFeedbackSource.HelpUsImprove,
      }),
    );
    dispatch(modalOpened(ModalDialogType.HelpUsImproveSurveyDialog));
  };
  const onClickOnRoadmap = () =>
    dispatch(trackUserEvent(TrackedEvent.ResourcesUsed, { resourceType: 'roadmap' }));
  const onClickOnUxFeedback = () =>
    dispatch(trackUserEvent(TrackedEvent.ResourcesUsed, { resourceType: 'ux-feedback' }));
  const onClickSendYourFeedback = () => {
    dispatch(
      trackUserEvent(TrackedEvent.FeedbackTextModal, {
        action: FeedbackTextModalActionType.Open,
      }),
    );
    dispatch(modalOpened(ModalDialogType.SendYourFeedbackDialog));
  };

  return (
    <Menu.Section label="Resources & news" isFirst>
      {shouldDisplayHelpUsItem && (
        <TabbableMenuItem
          id="rate-your-experience"
          label="Rate your experience"
          onAction={onClickHelpUsImproveKc}
          trailingElements={<Badge badgeStyle="default" />}
          {...getDataUiElementAttribute(DataUiElement.UmuxFeedbackItem)}
        />
      )}
      <TabbableMenuOutwardLink
        id="vote-for-feature"
        label="Vote for a feature"
        href={feedbackLinks.roadmap}
        onAction={onClickOnRoadmap}
        {...getDataUiNavAttribute(DataUiExternalLinkName.Roadmap)}
      />
      <TabbableMenuOutwardLink
        id="join-expert-group"
        label="Join our expert group"
        href={feedbackLinks.uxFeedback}
        onAction={onClickOnUxFeedback}
        tooltipText="Try new features and share your thoughts to help us shape the future of Kontent.ai."
        {...getDataUiNavAttribute(DataUiExternalLinkName.UxFeedback)}
      />
      <TabbableMenuItem
        id="send-your-feedback"
        label="Send your feedback"
        onAction={onClickSendYourFeedback}
      />
    </Menu.Section>
  );
}
