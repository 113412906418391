import { noOperation } from '@kontent-ai/utils';
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ISpace } from '../../../../../../../data/models/space/space.ts';
import { SpaceSelectorInWebSpotlightStorage } from '../../../../../../../localStorages/spaceSelectorInWebSpotlightStorage.ts';

type PreviewSpaceContextState = Readonly<{
  spaceId: Uuid | null;
  setSpaceId: (spaceId: Uuid) => void;
}>;

const PreviewSpaceContext = createContext<PreviewSpaceContextState>({
  spaceId: null,
  setSpaceId: noOperation,
});

type Props = PropsWithChildren &
  Readonly<{
    environmentId: Uuid;
    availableSpaces: ReadonlyArray<ISpace>;
  }>;

export const PreviewSpaceContextProvider = ({
  availableSpaces,
  environmentId,
  children,
}: Props) => {
  const [spaceId, setSpaceId] = useState<Uuid | null>(null);

  const setAndStoreSpaceId = useCallback(
    (newSpaceId: Uuid): void => {
      setSpaceId(newSpaceId);
      SpaceSelectorInWebSpotlightStorage.save(newSpaceId, environmentId);
    },
    [environmentId],
  );

  useEffect(() => {
    const lastSelectedSpaceId = SpaceSelectorInWebSpotlightStorage.load(environmentId);

    if (lastSelectedSpaceId && availableSpaces.find((s) => s.id === lastSelectedSpaceId)) {
      setSpaceId(lastSelectedSpaceId);
    } else if (availableSpaces[0]) {
      setAndStoreSpaceId(availableSpaces[0].id);
    }
  }, [availableSpaces, environmentId, setAndStoreSpaceId]);

  const contextState: PreviewSpaceContextState = useMemo(
    () => ({
      spaceId,
      setSpaceId: setAndStoreSpaceId,
    }),
    [spaceId, setAndStoreSpaceId],
  );

  return (
    <PreviewSpaceContext.Provider value={contextState}>{children}</PreviewSpaceContext.Provider>
  );
};

export const usePreviewSpace = () => useContext(PreviewSpaceContext);
