import {
  ItemEditingNewDesign,
  SpacesInDeliver,
  WebSpotlightIntegration,
  WebSpotlightIntegrationTree,
} from './ProjectFeatureFlagsServerKeys.ts';

export type ProjectFeatureFlagValue = boolean;

export type ProjectFeatureFlagsModel = {
  ItemEditingNewDesign: ProjectFeatureFlagValue;
  SpacesInDeliver: ProjectFeatureFlagValue;
  WebSpotlightIntegration: ProjectFeatureFlagValue;
  WebSpotlightIntegrationTree: ProjectFeatureFlagValue;
};

export const ProjectFeatureFlagsStateKeysByServerKeys: Dictionary<keyof ProjectFeatureFlagsModel> =
  {
    [ItemEditingNewDesign]: 'ItemEditingNewDesign',
    [SpacesInDeliver]: 'SpacesInDeliver',
    [WebSpotlightIntegration]: 'WebSpotlightIntegration',
    [WebSpotlightIntegrationTree]: 'WebSpotlightIntegrationTree',
  };
