import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { Placement } from '@kontent-ai/component-library/types';
import { useAttachRef } from '@kontent-ai/hooks';
import { Direction } from '@kontent-ai/types';
import classNames from 'classnames';
import { forwardRef, useCallback, useEffect, useState } from 'react';
import { createDropDown } from '../uiComponents/DropDown/DropDown.tsx';
import { DropDownOption } from '../uiComponents/DropDown/DropDownOption.tsx';
import { DropDownOptionName } from '../uiComponents/DropDown/DropDownOptionName.tsx';
import { DropDownSelected } from '../uiComponents/DropDown/DropDownSelected.tsx';
import { IDropdownTippyOptions } from '../uiComponents/DropDown/dropDownTippyOptions.ts';
import {
  DataUiCollection,
  DataUiElement,
  getDataUiCollectionAttribute,
  getDataUiElementAttribute,
  getDataUiObjectNameAttribute,
} from '../utils/dataAttributes/DataUiAttributes.ts';
import { getNewSelectedOption } from '../utils/dropdownOptionsUtils.ts';
import { HotkeysHandler } from './Hotkeys/HotkeysHandler.tsx';

export type DropDownValueType = AnyObject | string | number;

export interface IDropDownStateProps<TOption extends DropDownValueType> {
  readonly className?: string;
  readonly id?: string;
  readonly options: ReadonlyArray<TOption>;
  readonly selectedOption?: TOption;
  readonly tippyOptions?: IDropdownTippyOptions;
  readonly defaultText?: string;
  readonly defaultTextClassName?: string;
  readonly disabled?: boolean;
  readonly disabledTooltipMessage?: string;
  readonly disabledTooltipPlacement?: Placement;
  readonly dataUiCollectionName?: DataUiCollection;
  readonly renderOptionName: (option: TOption) => string;
  readonly getOptionId: (option: TOption) => string;
  readonly getOptionClassName?: (option: TOption) => string;
  readonly getOptionDisabledMessage?: (option: TOption) => string | undefined;
}

export interface IDropDownDispatchProps<TOption extends DropDownValueType> {
  readonly onSelect: (selectedOption: TOption) => void;
}

export type DropDownProps<TOption extends DropDownValueType> = IDropDownStateProps<TOption> &
  IDropDownDispatchProps<TOption>;

interface IHighlightedOption {
  readonly id: string;
  readonly scrollIntoView?: boolean;
}

export function getDropDown<TOption extends DropDownValueType>() {
  const DropDown = createDropDown<TOption>();

  const SingleOptionSelect = forwardRef<HTMLDivElement, DropDownProps<TOption>>(
    (
      {
        className,
        dataUiCollectionName,
        defaultText,
        defaultTextClassName,
        disabled,
        disabledTooltipMessage,
        disabledTooltipPlacement = 'top',
        getOptionClassName,
        getOptionId,
        id,
        getOptionDisabledMessage,
        onSelect,
        options,
        renderOptionName,
        selectedOption,
        tippyOptions,
      },
      ref,
    ) => {
      const { refObject, refToForward } = useAttachRef(ref);
      const [highlightedOption, setHighlightedOption] = useState<IHighlightedOption | null>(null);

      const [isDropdownOpen, setIsDropdownOpen] = useState(false);

      const selectedOptionId = selectedOption && getOptionId(selectedOption);

      const openDropdown = useCallback(() => {
        if (!disabled) {
          const firstEnabledOption = options.find(
            (option: TOption) => !getOptionDisabledMessage?.(option),
          );
          const optionToHighlight = selectedOption ?? firstEnabledOption;
          if (optionToHighlight) {
            setHighlightedOption({
              id: getOptionId(optionToHighlight),
              scrollIntoView: optionToHighlight === selectedOption,
            });
          }
          setIsDropdownOpen(true);
        }
      }, [options, getOptionId, selectedOption, disabled, getOptionDisabledMessage]);

      const closeDropdown = useCallback(() => {
        setIsDropdownOpen(false);
        setHighlightedOption(null);
      }, []);

      useEffect(() => {
        if (disabled && isDropdownOpen) {
          closeDropdown();
        }
      }, [disabled, isDropdownOpen, closeDropdown]);

      const closeOnEscape = useCallback(
        (event: KeyboardEvent): void => {
          event.preventDefault();
          event.stopPropagation();
          closeDropdown();
        },
        [closeDropdown],
      );

      const openOnEnter = useCallback(
        (event: KeyboardEvent): void => {
          event.preventDefault();
          event.stopPropagation();
          openDropdown();
        },
        [openDropdown],
      );

      const selectOption = useCallback(
        (option: TOption) => {
          onSelect(option);
          refObject.current?.focus();
          closeDropdown();
        },
        [onSelect, closeDropdown, refObject],
      );

      const selectHighlightedOptionOnEnter = useCallback(
        (event: KeyboardEvent): void => {
          event.preventDefault();
          event.stopPropagation();
          const currentHighlightedOption = options.find(
            (option: TOption) => getOptionId(option) === highlightedOption?.id,
          );
          if (currentHighlightedOption && !getOptionDisabledMessage?.(currentHighlightedOption)) {
            selectOption(currentHighlightedOption);
          }
        },
        [options, selectOption, getOptionId, highlightedOption, getOptionDisabledMessage],
      );

      const changeHighlightedOption = useCallback(
        (direction: Direction) => {
          const newSelectedOption = getNewSelectedOption(
            direction,
            options,
            options.find((option: TOption) => getOptionId(option) === highlightedOption?.id),
            (option: TOption) => !getOptionDisabledMessage?.(option),
          );

          if (newSelectedOption) {
            setHighlightedOption({
              id: getOptionId(newSelectedOption),
              scrollIntoView: true,
            });
          }
        },
        [options, highlightedOption, getOptionId, getOptionDisabledMessage],
      );

      const highlightPreviousOption = useCallback(
        (event: KeyboardEvent): void => {
          event.preventDefault();
          event.stopPropagation();
          changeHighlightedOption(Direction.Backward);
        },
        [changeHighlightedOption],
      );
      const highlightNextOption = useCallback(
        (event: KeyboardEvent): void => {
          event.preventDefault();
          event.stopPropagation();
          changeHighlightedOption(Direction.Forward);
        },
        [changeHighlightedOption],
      );
      const onOptionMouseOver = (option: TOption) => {
        const optionId = getOptionId(option);
        if (highlightedOption?.id !== optionId) {
          setHighlightedOption({ id: getOptionId(option) });
        }
      };

      return (
        <HotkeysHandler
          handlers={{
            onDown: isDropdownOpen ? highlightNextOption : undefined,
            onEnter: isDropdownOpen ? selectHighlightedOptionOnEnter : openOnEnter,
            onEscape: isDropdownOpen ? closeOnEscape : undefined,
            onUp: isDropdownOpen ? highlightPreviousOption : undefined,
          }}
          className="combo-box-wrapper"
        >
          <DropDown
            isOpen={isDropdownOpen}
            optionListClassName="dropdown-options--is-selector"
            onClose={closeDropdown}
            onOpen={openDropdown}
            options={options}
            optionListDataUiAttributes={{
              ...(dataUiCollectionName && getDataUiCollectionAttribute(dataUiCollectionName)),
              ...getDataUiElementAttribute(DataUiElement.SingleSelectDropdown),
            }}
            renderOption={(_, option) => {
              const isHighlighted = getOptionId(option) === highlightedOption?.id;
              const isSelected = getOptionId(option) === selectedOptionId;
              const optionDisabledMessage = getOptionDisabledMessage?.(option);

              return (
                <DropDownOption
                  className={getOptionClassName?.(option)}
                  dataUiAttributes={{
                    ...getDataUiObjectNameAttribute(getOptionId(option)),
                    ...getDataUiElementAttribute(DataUiElement.SingleSelectDropdownOption),
                  }}
                  isDisabled={!!optionDisabledMessage}
                  //  Fix mispositioned tooltip on mouseLeave
                  tooltipText={isHighlighted ? optionDisabledMessage : undefined}
                  isSelected={isSelected}
                  isHighlighted={isHighlighted}
                  key={getOptionId(option)}
                  onClick={() => selectOption(option)}
                  onMouseDown={(e) => {
                    //  Prevent blur from closing the dropdown on click. If mousedown is not prevented, blur is subsequently triggered, which results in the dropdown being closed, and the click event never being fired.
                    e.preventDefault();
                  }}
                  onMouseOver={() => onOptionMouseOver(option)}
                  scrollIntoView={isHighlighted && highlightedOption?.scrollIntoView}
                >
                  <DropDownOptionName text={renderOptionName(option)} />
                </DropDownOption>
              );
            }}
            renderSelectedOption={(optionRef, onClick, isOpen) => (
              <Tooltip text={disabledTooltipMessage} placement={disabledTooltipPlacement}>
                <div
                  className={classNames('combo-box', className, {
                    'combo-box--is-disabled': !!disabled,
                  })}
                  id={id}
                  onBlur={closeDropdown}
                  ref={refToForward}
                  tabIndex={disabled ? undefined : 0}
                  {...(dataUiCollectionName && getDataUiCollectionAttribute(dataUiCollectionName))}
                  {...getDataUiElementAttribute(DataUiElement.SingleSelectDropdownInput)}
                >
                  <DropDownSelected
                    className={
                      selectedOption
                        ? undefined
                        : classNames('dropdown-selected--is-placeholder', defaultTextClassName)
                    }
                    dataUiAttributes={{
                      ...(selectedOptionId && getDataUiObjectNameAttribute(selectedOptionId)),
                      ...getDataUiElementAttribute(
                        DataUiElement.SingleSelectDropdownSelectedOptionPane,
                      ),
                    }}
                    isOpen={isOpen}
                    onClick={onClick}
                    ref={optionRef}
                  >
                    <div
                      className="dropdown-selected__text"
                      {...getDataUiElementAttribute(
                        DataUiElement.SingleSelectDropdownSelectedOptionText,
                      )}
                    >
                      {selectedOption ? renderOptionName(selectedOption) : defaultText}
                    </div>
                  </DropDownSelected>
                </div>
              </Tooltip>
            )}
            tippyOptions={tippyOptions}
          />
        </HotkeysHandler>
      );
    },
  );

  SingleOptionSelect.displayName = 'SingleSelect';

  return SingleOptionSelect;
}
