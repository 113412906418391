import { Box } from '@kontent-ai/component-library/Box';
import { Column, Row } from '@kontent-ai/component-library/Row';
import {
  Spacing,
  borderWidthDefault,
  colorBorderLowEmphasis,
  px,
} from '@kontent-ai/component-library/tokens';
import { RefObject, useLayoutEffect, useRef, useState } from 'react';
import useResizeObserver from 'use-resize-observer';
import { SpaceSelector } from '../containers/SpaceSelector.tsx';
import { OldResolutionSettings } from '../features/resolutionSettings/containers/OldResolutionSettings.tsx';
import { ResolutionSettings } from '../features/resolutionSettings/containers/ResolutionSettings.tsx';
import { OldOpenInNewTabButton } from './OldOpenInNewTabButton.tsx';
import { OldPreviewActionsMenu } from './PreviewActionsMenu.tsx';
import { PreviewRefreshStatus } from './PreviewRefreshStatus.tsx';
import { OldRefreshPreviewButton } from './RefreshPreviewButton.tsx';

type Props = Readonly<{
  isAutoRefreshAvailable: boolean;
  isAutoRefreshEnabled: boolean;
  isRefreshing: boolean;
  isWebSpotlightIntegrationEnabled: boolean;
  spaceId: Uuid | null;
}>;

export const OldContentItemPreviewActionBar = ({
  isAutoRefreshAvailable,
  isAutoRefreshEnabled,
  isRefreshing,
  isWebSpotlightIntegrationEnabled,
  spaceId,
}: Props) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const itemsIdentity = calculateItemsIdentity(isRefreshing, isAutoRefreshAvailable);
  const shouldCollapse = useCollapse(containerRef, itemsIdentity);
  const shouldShowLoader = isAutoRefreshEnabled && isRefreshing;

  return (
    <Box
      paddingX={Spacing.L}
      paddingY={Spacing.S}
      css={`border-bottom: ${px(borderWidthDefault)} solid ${colorBorderLowEmphasis}`}
    >
      <Row ref={containerRef} spacingX={Spacing.M} spacingY={Spacing.S}>
        <Column css={`min-width: ${px(inputMinWidthPx)}`}>
          <SpaceSelector />
        </Column>
        {isWebSpotlightIntegrationEnabled && (
          <Column>
            <OldResolutionSettings />
          </Column>
        )}
        {shouldShowLoader && (
          <Column width="content" css="display: flex">
            <PreviewRefreshStatus />
          </Column>
        )}
        <Column width="content">
          <OldOpenInNewTabButton
            spaceId={spaceId}
            buttonStyle="secondary"
            isCompact={shouldCollapse}
          />
        </Column>
        {isAutoRefreshAvailable ? (
          <Column width="content">
            <OldPreviewActionsMenu
              isWebSpotlightIntegrationEnabled={isWebSpotlightIntegrationEnabled}
              isCompact={shouldCollapse}
            />
          </Column>
        ) : (
          <Column width="content">
            <OldRefreshPreviewButton isCompact={shouldCollapse} />
          </Column>
        )}
      </Row>
    </Box>
  );
};

export const ContentItemPreviewActionBar = ({ isWebSpotlightIntegrationEnabled }: Props) => {
  return (
    isWebSpotlightIntegrationEnabled && (
      <Box
        paddingX={Spacing.L}
        paddingY={Spacing.S}
        css={`border-bottom: ${px(borderWidthDefault)} solid ${colorBorderLowEmphasis}`}
      >
        <ResolutionSettings />
      </Box>
    )
  );
};

const inputMinWidthPx = 200;
const initialContainerWidth = 0;

const calculateItemsIdentity = (isLoaderVisible: boolean, isAutoRefreshAvailable: boolean) =>
  `${isLoaderVisible}-${isAutoRefreshAvailable}`;

const useCollapse = (containerRef: RefObject<HTMLDivElement>, itemsIdentity: string) => {
  const controlsMaximumWidth = useRef(Number.MIN_VALUE);
  const [isCompact, setIsCompact] = useState(false);
  const [containerWidth, setContainerWidth] = useState(initialContainerWidth);
  const [previousItemsIdentity, setPreviousItemsIdentity] = useState(itemsIdentity);

  useLayoutEffect(() => {
    if (!containerRef.current) {
      return;
    }

    if (!isCompact || previousItemsIdentity !== itemsIdentity) {
      const childElements = Array.from(containerRef.current.childNodes ?? []) as HTMLElement[];
      controlsMaximumWidth.current = childElements.reduce(
        (total, currentElement, index) =>
          index === 0 ? total : total + currentElement.offsetWidth,
        0,
      );
      setPreviousItemsIdentity(itemsIdentity);
    }

    let cw = containerWidth;

    if (cw === initialContainerWidth) {
      cw = containerRef.current.clientWidth;
    }

    setIsCompact(cw <= inputMinWidthPx + controlsMaximumWidth.current);
  }, [containerRef, isCompact, containerWidth, itemsIdentity, previousItemsIdentity]);

  useResizeObserver({
    ref: containerRef,
    onResize: ({ width = 0 }) => {
      setContainerWidth(width);
    },
  });

  return isCompact;
};
