import { Box } from '@kontent-ai/component-library/Box';
import { Input } from '@kontent-ai/component-library/Input';
import { gridUnit } from '@kontent-ai/component-library/tokens';
import { ChangeEvent, useRef, useState } from 'react';
import { ModalDialog } from '../../../../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import { ValidationConstants } from '../../../../../../_shared/constants/validationConstants.ts';
import {
  DataUiInput,
  getDataUiInputAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { UntitledFilter } from '../constants/uiConstants.ts';

export const SaveFilterModalDialog = ({
  headline = 'Save filter',
  onSave,
  onClose,
  name,
}: Readonly<{
  headline?: string;
  name?: string;
  onClose: () => void;
  onSave: (name: string) => void;
}>) => {
  const [newName, setNewName] = useState(name ?? '');
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => setNewName(event.target.value);
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <ModalDialog
      autoFocusRef={inputRef}
      headline={headline}
      isDismissable
      isOpen
      onClose={onClose}
      primaryAction={{
        onClick: () => {
          onSave(newName);
          onClose();
          setNewName('');
        },
        text: 'Save',
      }}
    >
      <Box maxWidth={64 * gridUnit} width="80vw">
        <Input
          label="Filter name"
          maxLength={ValidationConstants.FilterNameMaxLength}
          onChange={handleChange}
          placeholder={UntitledFilter}
          ref={inputRef}
          value={newName}
          {...getDataUiInputAttribute(DataUiInput.FilterName)}
        />
      </Box>
    </ModalDialog>
  );
};
