export function getInputValidationClass(isValid?: boolean): string {
  if (isValid === true) {
    return 'content-element__name-input--has-success';
  }

  if (isValid === false) {
    return 'content-element__name-input--has-error';
  }

  return 'content-element__name-input--is-loading';
}
