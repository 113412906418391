import React from 'react';
import { useLivePreviewPreferenceStorage } from '../../../localStorages/useLivePreviewPreferenceStorage.ts';
import { ContentItemUsagesLinks } from '../../components/ContentItemUsagesLinks/ContentItemUsagesLinks.tsx';
import { useSelector } from '../../hooks/useSelector.ts';
import { getCurrentProjectId } from '../../selectors/userProjectsInfoSelectors.ts';

export type ContentItemUsagesLinksContainerProps = {
  readonly contentItemId: Uuid;
};

const ContentItemUsagesLinksContainer: React.FC<ContentItemUsagesLinksContainerProps> = (props) => {
  const contentItemUsages = useSelector((s) => s.data.listingContentItems.contentItemUsages);
  const currentProjectId = useSelector(getCurrentProjectId);
  const { isLivePreviewPreferred } = useLivePreviewPreferenceStorage(currentProjectId);

  return (
    <ContentItemUsagesLinks
      {...props}
      contentItemUsages={contentItemUsages}
      isLivePreviewPreferred={isLivePreviewPreferred}
    />
  );
};

ContentItemUsagesLinksContainer.displayName = 'ContentItemUsagesLinksContainer';
export { ContentItemUsagesLinksContainer as ContentItemUsagesLinks };
