import { BaseColor } from '@kontent-ai/component-library/tokens';
import styled from 'styled-components';
import { InnovationLabFeatureAccess } from '../types/InnovationLabFeatureAccess.ts';
import { isInnovationLabFeatureOnlyForEmployees } from '../utils/innovationLabFeatureStatusUtils.ts';

export const InnovationLabFeatureCardWrapper = styled('div')<{
  featureState: InnovationLabFeatureAccess;
}>`
  background-image: ${(props) =>
    isInnovationLabFeatureOnlyForEmployees(props.featureState) &&
    `repeating-linear-gradient(-45deg, ${BaseColor.Rose10}, ${BaseColor.White} 20px, ${BaseColor.White} 20px, ${BaseColor.Rose10} 40px);`};

  > * {
    background-color: unset;
  }
`;
