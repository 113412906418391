import { Box } from '@kontent-ai/component-library/Box';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Stack } from '@kontent-ai/component-library/Stack';
import { OptionalTooltip, Tooltip } from '@kontent-ai/component-library/Tooltip';
import { Spacing, ZIndex } from '@kontent-ai/component-library/tokens';
import { useHover } from '@react-aria/interactions';
import { format } from 'date-fns';
import React from 'react';
import { getFileSizeObject } from '../../../../app/_shared/utils/fileSizeUtils.ts';
import { AssetTileReadonlyState } from '../enums/assetTileReadonlyStateEnum.ts';
import { AssetTileType } from '../enums/assetTileTypeEnum.ts';
import { AssetDataType } from '../types/assetData.type.ts';
import { AssetValidation } from '../types/assetValidation.type.ts';
import { StyledInfoBar } from './components/StyledInfoBar.tsx';
import { StyledInfoBarMetadata } from './components/StyledInfoBarMetadata.tsx';
import { StyledInfoBarMetadataDivider } from './components/StyledInfoBarMetadataDivider.tsx';
import { StyledInfoBarTitle } from './components/StyledInfoBarTitle.tsx';
import {
  computeAssetSizeLabelLength,
  formatFileSize,
  formatFileType,
  parseSizeValue,
} from './utils.ts';

const metadataDateFormat = 'MMM dd, yyyy';
const metadataAssetSizeMaxLength = 7;
const metadataAssetSizeDimensionMaxLength = 6;
const metadataTitleMaxLines = 3;
const metadataTitleMinLines = 1;

export interface IAssetTileInfoBarPublicProps {
  readonly isSelected?: boolean;
  readonly validation?: AssetValidation;
  readonly readonlyState?: AssetTileReadonlyState;
  readonly assetData: AssetDataType;
}

interface IAssetTileInfoBarProps extends IAssetTileInfoBarPublicProps {
  readonly isTileHovered?: boolean;
  readonly isTileFocused?: boolean;
  readonly isAssetPlaying?: boolean;
  readonly tileType: AssetTileType;
}

export const AssetTileInfoBar: React.FC<IAssetTileInfoBarProps> = ({
  isTileFocused,
  isTileHovered,
  isAssetPlaying,
  isSelected,
  readonlyState,
  validation = {
    isAssetFileSizeValid: true,
    isAssetFileTypeValid: true,
    isAssetWidthValid: true,
    isAssetHeightValid: true,
  },
  tileType,
  assetData: {
    assetIsArchived,
    assetPreview,
    assetFileSizeInBytes,
    assetFileType,
    assetTitle,
    assetUploadDate,
  },
}) => {
  const infoBarHover = useHover({});
  const titleHover = useHover({});
  const { width, height } = assetPreview?.assetDimension || {
    width: null,
    height: null,
  };
  const showAssetDimensions =
    !!assetPreview && ((!!width && width > 0) || (!!height && height > 0));
  const isSizeLineOverflowing =
    computeAssetSizeLabelLength(width, height) > metadataAssetSizeMaxLength;
  const widthParseResult = parseSizeValue(width, metadataAssetSizeDimensionMaxLength);
  const heightParseResult = parseSizeValue(height, metadataAssetSizeDimensionMaxLength);
  const metadataWidth = `${isSizeLineOverflowing ? 'w' : 'width'}: ${widthParseResult.value} px`;
  const metadataHeight = `${isSizeLineOverflowing ? 'h' : 'height'}: ${heightParseResult.value} px`;
  const uploadDate = format(new Date(assetUploadDate), metadataDateFormat);
  const parsedFileSize = formatFileSize(getFileSizeObject(assetFileSizeInBytes));
  const fileType = formatFileType(assetFileType);
  const isExpanded =
    !isAssetPlaying &&
    (isTileFocused ||
      (isTileHovered && tileType === AssetTileType.Selectable) ||
      infoBarHover.isHovered);
  const { isAssetFileSizeValid, isAssetFileTypeValid, isAssetWidthValid, isAssetHeightValid } =
    validation;

  return (
    <StyledInfoBar
      isSelected={isSelected}
      isTileHovered={isTileHovered}
      readonlyState={readonlyState}
      tileType={tileType}
      {...infoBarHover.hoverProps}
    >
      <Box padding={Spacing.L} position="relative" zIndex={ZIndex.OneHundred}>
        <OptionalTooltip
          text={assetTitle}
          placement="top"
          visible={isTileFocused || titleHover.isHovered}
          customRenderText={(ref) => (
            <StyledInfoBarTitle
              ref={ref}
              maxLines={isExpanded ? metadataTitleMaxLines : metadataTitleMinLines}
              {...titleHover.hoverProps}
            >
              {assetTitle}
            </StyledInfoBarTitle>
          )}
        />
        <Box paddingTop={Spacing.S}>
          <Stack spacing={Spacing.XS}>
            {assetIsArchived && (
              <Box>
                <StyledInfoBarMetadata isValid={false}>(Deleted)</StyledInfoBarMetadata>
              </Box>
            )}
            {showAssetDimensions && (
              <Row noWrap>
                <Column width="content">
                  <Tooltip
                    text={widthParseResult.isTruncated ? width?.toString() : undefined}
                    placement="top"
                  >
                    <StyledInfoBarMetadata isValid={isAssetWidthValid}>
                      {metadataWidth}
                    </StyledInfoBarMetadata>
                  </Tooltip>
                </Column>
                <Column width="content">
                  <StyledInfoBarMetadataDivider />
                </Column>
                <Column width="content">
                  <Tooltip
                    text={heightParseResult.isTruncated ? height?.toString() : undefined}
                    placement="top"
                  >
                    <StyledInfoBarMetadata isValid={isAssetHeightValid}>
                      {metadataHeight}
                    </StyledInfoBarMetadata>
                  </Tooltip>
                </Column>
              </Row>
            )}
            <Row noWrap>
              <Column width="content">
                <StyledInfoBarMetadata isValid>{uploadDate}</StyledInfoBarMetadata>
              </Column>
              <Column width="content">
                <StyledInfoBarMetadataDivider />
              </Column>
              <Column width="content">
                <StyledInfoBarMetadata isValid={isAssetFileSizeValid}>
                  {parsedFileSize}
                </StyledInfoBarMetadata>
              </Column>
              <Column width="content">
                <StyledInfoBarMetadataDivider />
              </Column>
              <Column width="content">
                <StyledInfoBarMetadata isValid={isAssetFileTypeValid}>
                  {fileType}
                </StyledInfoBarMetadata>
              </Column>
            </Row>
            {/*Empty line when asset has no width/height*/}
            {!showAssetDimensions && (
              <StyledInfoBarMetadata isValid>
                <br />
              </StyledInfoBarMetadata>
            )}
          </Stack>
        </Box>
      </Box>
    </StyledInfoBar>
  );
};

AssetTileInfoBar.displayName = 'AssetTileInfoBar';
