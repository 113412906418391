import { ISelectItem } from '@kontent-ai/component-library/Selects';
import { InvariantException } from '@kontent-ai/errors';
import { Collection } from '@kontent-ai/utils';
import { useMemo } from 'react';
import { useDataSelector } from '../../../../../../../_shared/hooks/useDataSelector.ts';
import { DataUiCollection } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getAllWorkflowSteps } from '../../../../../../../_shared/utils/workflow/getAllWorkflowSteps.ts';
import { IListingFilter } from '../../../../models/filter/IListingFilter.ts';
import { ListingFilterCategorySelector } from '../../components/ListingFilterCategorySelector.tsx';

type Props = {
  readonly collection: DataUiCollection;
  readonly filter: IListingFilter;
  readonly onWorkflowsStepSelectionChanged: (
    workflowToSelectedStepIds: ReadonlyMap<Uuid, ReadonlySet<Uuid>>,
    workflowToAllStepIds: ReadonlyMap<Uuid, ReadonlyArray<Uuid>>,
  ) => void;
  readonly placeholder: string;
  readonly title: string;
};

interface IStepSelectItem extends ISelectItem<IStepSelectItem> {
  readonly workflowId: Uuid;
}

export const WorkflowStepFilterSelector = (props: Props) => {
  const workflowsById = useDataSelector((data) => data.workflows.byId);
  const workflows = useMemo(() => Collection.getValues(workflowsById), [workflowsById]);

  const options = useMemo((): ReadonlyArray<IStepSelectItem> => {
    const firstWorkflow = workflows[0];
    if (!firstWorkflow) {
      return [];
    }

    return getAllWorkflowSteps(firstWorkflow).map((step) => ({
      type: 'item',
      id: step.id,
      label: step.name,
      workflowId: firstWorkflow.id,
    }));
  }, [workflows]);

  const selectedOptionIds: ReadonlySet<Uuid> = useMemo(
    () =>
      new Set(
        options
          .filter((option) => {
            const workflowSteps = props.filter.selectedWorkflowNodes.get(option.workflowId);
            if (!workflowSteps) {
              return false;
            }

            return workflowSteps.size === 0 || workflowSteps.has(option.id);
          })
          .map((option) => option.id),
      ),
    [options, props.filter],
  );

  const onFilterChanged = (selectedWorkflowStepIds: ReadonlySet<Uuid>): void => {
    const workflow = workflows[0];
    if (!workflow) {
      throw InvariantException('Workflow could not be found.');
    }
    const workflowToSelectedStepIds = new Map([[workflow.id, selectedWorkflowStepIds]]);
    const workflowToAllStepIds = new Map([
      [workflow.id, getAllWorkflowSteps(workflow).map((s) => s.id)],
    ]);
    props.onWorkflowsStepSelectionChanged(workflowToSelectedStepIds, workflowToAllStepIds);
  };

  return (
    <ListingFilterCategorySelector
      {...props}
      ariaLabel="Select a workflow step"
      options={options}
      selectedOptionIds={selectedOptionIds}
      onChange={onFilterChanged}
    />
  );
};
