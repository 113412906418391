import React from 'react';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { getCurrentUserId } from '../../../../../../_shared/selectors/getCurrentUser.ts';
import { getCurrentProjectId } from '../../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { isUuid } from '../../../../../../_shared/utils/validation/typeValidators.ts';
import { getEditedContentItemType } from '../../../../selectors/getEditedContentItemType.ts';
import { TranslationNotificationBar } from '../../components/contentItemEditorNotifications/TranslationProgressNotificationBar.tsx';

export const TranslationProgressNotificationBar: React.FC = () => {
  const contentItemId = useSelector((s) => s.contentApp.editedContentItemVariant?.id);
  const projectEnvironmentId = useSelector(getCurrentProjectId);
  const userId = useSelector(getCurrentUserId);
  const typeElements = useSelector(getEditedContentItemType)?.contentElements;

  if (!contentItemId || !isUuid(projectEnvironmentId) || !typeElements) {
    return null;
  }

  return (
    <TranslationNotificationBar
      contentItemId={contentItemId}
      projectEnvironmentId={projectEnvironmentId}
      userId={userId}
      typeElements={typeElements}
    />
  );
};
