import { ComponentProps } from 'react';
import { useDataSelector } from '../../../../../_shared/hooks/useDataSelector.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { getCurrentProjectId } from '../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { sortAndOrderAssetUsages } from '../../../../../_shared/utils/assets/assetUtils.ts';
import { addDefaultLanguageSuffix } from '../../../../environmentSettings/localization/utils/languageUtils.ts';
import { AssetUsage as AssetUsageComponent } from '../../components/AssetEditing/AssetUsage.tsx';

export const AssetEditorUsage = (
  props: Pick<ComponentProps<typeof AssetUsageComponent>, 'isInDialog'>,
) => {
  const assetId = useSelector(
    (state) => state.assetLibraryApp.assetUiEditor.editedAsset?.id ?? null,
  );
  const assetUsage = useSelector((state) =>
    assetId ? state.assetLibraryApp.usage.get(assetId) : null,
  );

  const languages = useDataSelector((data) => data.languages.byId);
  const sortedLanguageUsage = assetUsage ? sortAndOrderAssetUsages(assetUsage, languages) : [];

  const defaultLanguage = useDataSelector((data) =>
    addDefaultLanguageSuffix(data.languages.defaultLanguage),
  );
  const projectId = useSelector(getCurrentProjectId);

  if (!assetId) return null;

  return (
    <AssetUsageComponent
      assetId={assetId}
      defaultLanguage={defaultLanguage}
      isInDialog={props.isInDialog}
      isUsageLoaded={!!assetUsage}
      languages={languages}
      projectId={projectId}
      sortedLanguageUsage={sortedLanguageUsage}
    />
  );
};
