import { Collection } from '@kontent-ai/utils';
import { useCallback, useEffect } from 'react';
import { modalOpened } from '../../../../_shared/actions/sharedActions.ts';
import { trackUserEvent } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import { ModalDialogType } from '../../../../_shared/constants/modalDialogType.ts';
import {
  FindRightContentTrackedEvent,
  TrackedEvent,
} from '../../../../_shared/constants/trackedEvent.ts';
import { useDataSelector } from '../../../../_shared/hooks/useDataSelector.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { ProjectOverviewEventTypes } from '../../../../_shared/models/TrackUserEventData.ts';
import { ContentItemFilterOrigin } from '../../../../_shared/models/events/ContentItemFilterEventData.type.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { getFindRightContentFilterEventData } from '../../../contentInventory/content/features/ContentItemInventory/utils/getFindRightContentTrackingPayloads.ts';
import {
  contentItemFilterLeft,
  contentItemFilterSetUp,
} from '../../../contentInventory/content/features/ListingFilter/actions/listingFilterActions.ts';
import { useFilterChanged } from '../../../contentInventory/content/features/ListingFilter/hooks/useFilterChanged.ts';
import { useSetUpContentItemFilter } from '../../../contentInventory/content/features/ListingFilter/hooks/useSetUpContentItemFilter.ts';
import {
  IListingFilter,
  areListingFilterAndSearchEmpty,
} from '../../../contentInventory/content/models/filter/IListingFilter.ts';
import { clearListingFilter } from '../../../itemEditor/actions/contentActions.ts';
import { prepareNewContentItemDialogForProjectOverview } from '../../../itemEditor/features/NewContentItem/actions/thunkNewContentItemActions.ts';
import { projectOverviewLeft } from '../actions/projectOverviewActions.ts';
import { filterProjectOverview } from '../actions/thunkContentPlanningActions.ts';
import { ProjectOverviewPage as ProjectOverviewPageComponent } from '../components/ProjectOverviewPage.tsx';
import { ProjectOverviewPageType } from '../components/ProjectOverviewPageContent.tsx';
import { AggregationsState } from '../reducers/internalReducers/aggregations.ts';

export const ProjectOverviewPage = () => {
  const projectOverviewPageType = useSelector(getProjectOverviewPageType);
  const projectOverviewLoadingStatus = useSelector((s) => s.projectOverviewApp.loadingStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      trackUserEvent(TrackedEvent.ProjectOverview, {
        action: ProjectOverviewEventTypes.PageEntered,
      }),
    );

    return () => {
      dispatch(projectOverviewLeft());
      dispatch(
        trackUserEvent(TrackedEvent.ProjectOverview, {
          action: ProjectOverviewEventTypes.PageLeft,
        }),
      );
    };
  }, []);

  const openCreateNewItemDialog = () => {
    dispatch(
      prepareNewContentItemDialogForProjectOverview({
        allowedContentTypeIds: null,
      }),
    );
    dispatch(modalOpened(ModalDialogType.NewContentItemDialog));
  };

  const onFilterChange = useCallback(() => dispatch(filterProjectOverview()), []);
  const onClearFilter = () => dispatch(clearListingFilter());

  const filter = useSelector((s) => s.contentApp.listingUi.filter);
  const listingItemsLoadingStatus = useSelector((s) => s.data.listingContentItems.loadingStatus);
  const workflows = useDataSelector((data) => data.workflows.byId);
  const filterSetUp = useCallback(
    (listingFilter: IListingFilter) => {
      dispatch(contentItemFilterSetUp(listingFilter, ContentItemFilterOrigin.ProjectOverview));
      dispatch(
        trackUserEvent(TrackedEvent.FindRightContent, {
          name: FindRightContentTrackedEvent.OpenedContentItemInventoryPage,
          ...getFindRightContentFilterEventData(
            listingFilter,
            ContentItemFilterOrigin.ProjectOverview,
            Collection.getValues(workflows),
          ),
        }),
      );
    },
    [workflows],
  );
  const filterLeft = useCallback(
    () => dispatch(contentItemFilterLeft(ContentItemFilterOrigin.ProjectOverview)),
    [],
  );

  useSetUpContentItemFilter(
    ContentItemFilterOrigin.ProjectOverview,
    filterSetUp,
    filterLeft,
    undefined,
    undefined,
  );

  useFilterChanged(onFilterChange, filter, listingItemsLoadingStatus);

  return (
    <ProjectOverviewPageComponent
      listingItemsLoadingStatus={projectOverviewLoadingStatus}
      onClearFilter={onClearFilter}
      onFilterChange={onFilterChange}
      onOpenCreateNewItemDialog={openCreateNewItemDialog}
      projectOverviewPageType={projectOverviewPageType}
    />
  );
};

const noRelevantItemsForProjectOverviewFound = (
  aggregations: AggregationsState,
  loadingStatus: LoadingStatus,
): boolean => {
  const planningStatusTilesTotalCount =
    aggregations.itemsOnTrackIds.size +
    aggregations.delayedItemsIds.size +
    aggregations.scheduledToPublishItemsIds.size;
  const isLoading = loadingStatus !== LoadingStatus.Loaded;
  return !aggregations.itemsByWorkflowByStep.size && !planningStatusTilesTotalCount && !isLoading;
};

const getProjectOverviewPageType = (state: IStore): ProjectOverviewPageType => {
  const { aggregations, loadingStatus, itemsLimitExceeded } = state.projectOverviewApp;
  const {
    listingUi: { filter },
  } = state.contentApp;

  if (itemsLimitExceeded) {
    return ProjectOverviewPageType.LimitExceeded;
  }

  if (noRelevantItemsForProjectOverviewFound(aggregations, loadingStatus)) {
    if (areListingFilterAndSearchEmpty(filter)) {
      return ProjectOverviewPageType.EmptyProject;
    }

    return ProjectOverviewPageType.NoItemsFound;
  }

  return ProjectOverviewPageType.ProjectOverview;
};
