import { Collection, not } from '@kontent-ai/utils';
import Immutable from 'immutable';
import { Invitation } from '../../applications/environmentSettings/users/reducers/IUsersAppState.type.ts';
import { ISubscriptionUsage } from '../../applications/subscriptionManagement/shared/models/SubscriptionUsage.ts';
import { SubscriptionUserSlotStats } from '../../applications/subscriptionManagement/shared/types/SubscriptionUserSlotStats.type.ts';
import { getPlanUserPaidMetric } from '../../applications/subscriptionManagement/shared/utils/planUtils.ts';
import { IPlan } from '../../data/models/plans/Plan.ts';
import { IProjectContributor } from '../../data/models/users/ProjectContributor.ts';
import { getSelectedSubscription } from '../selectors/subscriptionSelectors.ts';
import { getSubscriptionIdFromCurrentProject } from '../selectors/userProjectsInfoSelectors.ts';
import { IStore } from '../stores/IStore.type.ts';
import { areEmailsSame } from './emailUtils.ts';
import { compose } from './func/compose.ts';

export function hasSubscriptionAvailableUserSlots(
  subscriptionUsages: Immutable.Map<Uuid, ISubscriptionUsage>,
  subscriptionId: Uuid | undefined | null,
  plans: Immutable.Map<Uuid, IPlan>,
  numberOfUsersInActivation: number,
): boolean {
  return hasAvailableUserSlots(
    getSubscriptionUsage(subscriptionUsages, subscriptionId),
    plans,
    numberOfUsersInActivation,
  );
}

export function hasAvailableUserSlots(
  subscriptionUsage: ISubscriptionUsage | null,
  plans: Immutable.Map<Uuid, IPlan>,
  numberOfUsersInActivation: number,
): boolean {
  return countAvailableUserSlots(subscriptionUsage, plans, numberOfUsersInActivation) > 0;
}

export function getMaxActiveUsers(
  subscriptionUsage: ISubscriptionUsage | null,
  plans: Immutable.Map<Uuid, IPlan>,
): number {
  const currentPlan = plans.get(subscriptionUsage?.planId || '');
  if (!currentPlan) {
    return 0;
  }

  return currentPlan.features.maxActiveUsers || 10_000_000;
}

export function countAvailableUserSlots(
  subscriptionUsage: ISubscriptionUsage | null,
  plans: Immutable.Map<Uuid, IPlan>,
  numberOfUsersInActivation: number,
): number {
  if (!subscriptionUsage) {
    return 0;
  }
  const currentPlan = plans.get(subscriptionUsage.planId);
  if (!currentPlan) {
    return 0;
  }

  return currentPlan.features.maxActiveUsers
    ? currentPlan.features.maxActiveUsers -
        subscriptionUsage.currentActiveUsers -
        numberOfUsersInActivation
    : 10_000_000;
}

export function getSubscriptionUsage(
  subscriptionUsages: Immutable.Map<Uuid, ISubscriptionUsage>,
  subscriptionId: Uuid | undefined | null,
): ISubscriptionUsage | null {
  if (!subscriptionId) {
    return null;
  }
  return subscriptionUsages.get(subscriptionId) ?? null;
}

export const getNumberOfUsersInActivation = (state: IStore) => {
  const usersInActivationOnSubscriptionUsersOverview =
    state.data.subscriptions.subscriptionUsersUsage?.subscriptionUsers.filter(
      (user) =>
        !user.isEmployee &&
        (user.isActivating ||
          user.userProjectsSettings.some((projectSettings) => projectSettings.isActivating)),
    ).length || 0;

  const usersInActivationOnProjectUsersOverview = Collection.getValues(
    state.usersApp.projectContributors,
  ).filter(
    (user: IProjectContributor) => !user.isVirtual && !isEmployee(user.email) && user.isActivating,
  ).length;

  // User can be activated either on ProjectUsersSettings page or on SubscriptionUsersSettings page
  // Take the value which is > 0 or return 0
  return Math.max(
    usersInActivationOnSubscriptionUsersOverview,
    usersInActivationOnProjectUsersOverview,
  );
};

const employeeEmailSuffixes = ['@kentico.com', '@kontent.ai'];

export const isEmployee = (userEmail: string): boolean => {
  return employeeEmailSuffixes.some((suffix) =>
    userEmail.toLowerCase().endsWith(suffix.toLowerCase()),
  );
};

export const isLearnRobot = (userEmail: string): boolean =>
  userEmail.toLowerCase() === 'learn.robot@sso-kontent.com';

export const getSelectedSubscriptionCurrentActiveUsers = (
  state: IStore,
): ISubscriptionUsage | null => {
  const {
    data: {
      subscriptions: { subscriptionUsages },
    },
  } = state;

  const selectedSubscription = getSelectedSubscription(state);

  const subscriptionId =
    selectedSubscription?.subscriptionId || getSubscriptionIdFromCurrentProject(state);
  return getSubscriptionUsage(subscriptionUsages, subscriptionId);
};

export const getSubscriptionUserSlotStats = (state: IStore): SubscriptionUserSlotStats => {
  const {
    data: { plans },
  } = state;

  const subscriptionUsage = getSelectedSubscriptionCurrentActiveUsers(state);
  if (!subscriptionUsage) {
    return {
      availableFreeUserSlots: 0,
      usedUserSlots: 0,
      userSlotLimit: 0,
    };
  }

  const plan = plans.byId.get(subscriptionUsage.planId) ?? null;
  const activeUsersPaidMetric = getPlanUserPaidMetric(plan);
  const numberOfUsersInActivation = getNumberOfUsersInActivation(state);

  if (!activeUsersPaidMetric) {
    return {
      availableFreeUserSlots: 0,
      usedUserSlots: 0,
      userSlotLimit: 0,
    };
  }

  // In case of trial the limit is taken from plan features, otherwise from paid metric.
  // Can be removed once the logic is unified.
  const userSlotLimit = plan?.isTrial
    ? plan.features.maxActiveUsers || 0
    : activeUsersPaidMetric.includedUnits;
  const usedUserSlots = subscriptionUsage.currentActiveUsers + numberOfUsersInActivation;
  const availableFreeUserSlots = Math.max(0, userSlotLimit - usedUserSlots);

  return {
    availableFreeUserSlots,
    usedUserSlots,
    userSlotLimit,
  };
};

export const filterByEmailsCountedInSubscriptionUserLimits = <T>(
  items: ReadonlyArray<T>,
  emailSelector: (i: T) => string,
): ReadonlyArray<T> =>
  items
    .filter(compose(not, isEmployee, emailSelector))
    .filter(
      (item, _, array) =>
        array.find((item2) => areEmailsSame(emailSelector(item), emailSelector(item2))) === item,
    );

export const filterInvitationsCountedInSubscriptionUserLimits = (
  invitations: ReadonlyArray<Invitation>,
) => filterByEmailsCountedInSubscriptionUserLimits(invitations, (i) => i.email);

export const filterEmailsCountedInSubscriptionUserLimits = (emails: ReadonlyArray<string>) =>
  filterByEmailsCountedInSubscriptionUserLimits(emails, (e) => e);

export const hasToUsePaidUserSlot = (state: IStore, newEmails: ReadonlyArray<string>): boolean => {
  const {
    data: { plans },
  } = state;

  const subscriptionUsage = getSelectedSubscriptionCurrentActiveUsers(state);
  const numberOfUsersInActivation = getNumberOfUsersInActivation(state);
  const numberOfNeededUserSlots = filterEmailsCountedInSubscriptionUserLimits(newEmails).length;
  const slotStats = getSubscriptionUserSlotStats(state);

  return (
    numberOfNeededUserSlots > 0 &&
    slotStats.availableFreeUserSlots < numberOfNeededUserSlots &&
    countAvailableUserSlots(subscriptionUsage, plans.byId, numberOfUsersInActivation) >=
      numberOfNeededUserSlots + numberOfUsersInActivation
  );
};

export const isUserCountedInUsage = (state: IStore, userId: string): boolean => {
  const {
    usersApp: { usersCountedInUsage },
  } = state;
  return usersCountedInUsage.some((u) => u === userId);
};
