import { InvariantException } from '@kontent-ai/errors';
import { assert } from '@kontent-ai/utils';
import { getSelectableWorkflowStepsForBulk } from '../../../../applications/contentInventory/content/features/ContentItemInventory/selectors/getSelectableWorkflowSteps.ts';
import {
  getNextWorkflowStepForVariantWithPublishedVersion,
  getNextWorkflowStepForVariantWithoutPublishedVersion,
} from '../../../../applications/itemEditor/features/ContentItemEditing/utils/getNextWorkflowStep.ts';
import { Workflow } from '../../../../data/models/workflow/Workflow.ts';
import { IWorkflowStep } from '../../../../data/models/workflow/WorkflowStep.ts';
import {
  getWorkflow,
  getWorkflowStep,
  getWorkflowStepsById,
} from '../../../selectors/workflowSelectors.ts';
import { IStore } from '../../../stores/IStore.type.ts';
import { isVariantPublished } from '../../../utils/contentItemVariantUtils.ts';
import { WorkflowStepWithWorkflow } from '../types/WorkflowStepWithWorkflow.type.ts';

const createWorkflowStepWithWorkflowOrUndefined = (
  workflow: Workflow | undefined,
  step: IWorkflowStep | undefined,
): WorkflowStepWithWorkflow | undefined => (workflow && step ? { workflow, step } : undefined);

export const getPreselectedStepForPublish = (
  state: IStore,
  workflowId: Uuid,
): WorkflowStepWithWorkflow | undefined => {
  const workflow = getWorkflow(state, workflowId);
  const step = workflow?.publishedStep;

  return createWorkflowStepWithWorkflowOrUndefined(workflow, step);
};

export const getPreselectedStepForUnpublish = (
  state: IStore,
  workflowId: Uuid,
): WorkflowStepWithWorkflow | undefined => {
  const workflow = getWorkflow(state, workflowId);
  const step = workflow?.archivedStep;

  return createWorkflowStepWithWorkflowOrUndefined(workflow, step);
};

export const getPreselectedStepForBulkRestoreFromArchivedStep = (
  state: IStore,
  workflowId: Uuid,
): WorkflowStepWithWorkflow | undefined => {
  const workflow = getWorkflow(state, workflowId);
  const step = workflow?.steps[0];

  return createWorkflowStepWithWorkflowOrUndefined(workflow, step);
};

export const getPreselectedStepForRestoreFromArchivedStepInEditing = (
  state: IStore,
  workflowId: Uuid,
): WorkflowStepWithWorkflow | undefined => {
  const { firstWorkflowStepCurrentRoleCanWorkWithId } = state.contentApp.editorUi;

  assert(
    firstWorkflowStepCurrentRoleCanWorkWithId,
    () => 'The first workflow step that the role can work with is not loaded.',
  );

  const workflow = getWorkflow(state, workflowId);
  const step = workflow && getWorkflowStep(workflow, firstWorkflowStepCurrentRoleCanWorkWithId);

  return createWorkflowStepWithWorkflowOrUndefined(workflow, step);
};

export const getPreselectedStepForUpdateStep = (
  state: IStore,
  workflowId: Uuid,
): WorkflowStepWithWorkflow | undefined => {
  const variant = state.contentApp.editedContentItemVariant;
  if (!variant) {
    throw InvariantException('getPreselectedWorkflowStep.ts: editedContentItemVariant is falsy.');
  }

  const workflow = getWorkflow(state, workflowId);

  if (!workflow) {
    return undefined;
  }

  const steps = getWorkflowStepsById(workflow);
  const step = isVariantPublished(variant)
    ? getNextWorkflowStepForVariantWithPublishedVersion(variant.assignment.workflowStatus, steps)
    : getNextWorkflowStepForVariantWithoutPublishedVersion(
        variant.assignment.workflowStatus,
        steps,
      );

  return createWorkflowStepWithWorkflowOrUndefined(workflow, step);
};

export const getPreselectedStepForBulkUpdateStep = (
  state: IStore,
  workflowId: Uuid,
): IWorkflowStep | undefined => {
  const { bulkActionCheckResult } = state.contentApp.listingUi;

  const currentWorkflow = getWorkflow(state, workflowId);
  const selectableSteps = getSelectableWorkflowStepsForBulk(bulkActionCheckResult, currentWorkflow);

  return selectableSteps[0];
};
