import React from 'react';
import { isSitemapEnabled } from '../../applications/contentModels/sitemap/utils/sitemapUtils.ts';
import { canAccessAnyCustomApp as canAccessAnyCustomAppSelector } from '../../applications/customApps/utils/customAppsUtils.ts';
import { canAccessEnvironmentSettings as canAccessEnvironmentSettingsSelector } from '../../applications/environmentSettings/utils/environmentSettingsMenuStructureUtils.tsx';
import { isWebSpotlightEnabled } from '../../applications/webSpotlight/selectors/webSpotlightSelectors.ts';
import { IUserProjectInfo } from '../../data/models/user/UserProjectInfo.ts';
import { Project as ProjectComponent, ProjectRoutes } from '../components/Project.tsx';
import { EnvironmentRouteParams, ProjectsRoute } from '../constants/routePaths.ts';
import { useProjectNotificationService } from '../hooks/useProjectNotificationService.ts';
import { useSelector } from '../hooks/useSelector.ts';
import {
  getCurrentProject,
  getCurrentProjectId,
  getCurrentProjectName,
  getCurrentProjectSubscription,
} from '../selectors/userProjectsInfoSelectors.ts';
import { getUserCapability } from '../utils/permissions/capability.ts';
import { getProjectEnvironmentRoutes } from '../utils/routing/getProjectEnvironmentRoutes.ts';
import { buildPath } from '../utils/routing/routeTransitionUtils.ts';

function getFirstAccessibleRoute(currentProject: IUserProjectInfo, routes: ProjectRoutes): string {
  const capability = getUserCapability(currentProject);

  const firstAccessibleRoute = Object.values(routes).find((route) =>
    capability.canOneOf(route.requiredCapabilities),
  );

  if (!firstAccessibleRoute) {
    return ProjectsRoute;
  }

  return buildPath<EnvironmentRouteParams>(firstAccessibleRoute.path, {
    projectId: currentProject.projectId,
  });
}

export const Project: React.FC = () => {
  const currentProjectName = useSelector(getCurrentProjectName);
  const routes = useSelector((state) => {
    const currentProjectId = getCurrentProjectId(state);
    const canAccessAnyCustomApp = canAccessAnyCustomAppSelector(state);
    const canAccessEnvironmentSettings = canAccessEnvironmentSettingsSelector(
      state,
      currentProjectId,
    );
    const currentProjectSubscription = getCurrentProjectSubscription(state);
    const webSpotlightEnabled = isWebSpotlightEnabled(state);
    const sitemapEnabled = isSitemapEnabled(currentProjectSubscription);
    return getProjectEnvironmentRoutes(
      canAccessAnyCustomApp,
      sitemapEnabled,
      webSpotlightEnabled,
      canAccessEnvironmentSettings,
    );
  });

  const firstAccessibleRoute = useSelector((state) =>
    getFirstAccessibleRoute(getCurrentProject(state), routes),
  );

  useProjectNotificationService();

  return (
    <ProjectComponent
      firstAccessibleRoute={firstAccessibleRoute}
      projectName={currentProjectName}
      routes={routes}
    />
  );
};

Project.displayName = 'Project';
