import React from 'react';
import { TypeElementSummary } from '../../../components/typeElements/shared/TypeElementSummary.tsx';
import { DefaultValueSetSummary } from '../../../constants/summaryMessageTemplates.ts';
import { INumberTypeElementData } from '../../../models/elements/NumberTypeElementData.ts';
import { IBaseTypeElementSummaryProps } from '../../../types/IBaseTypeElementSummaryProps.type.ts';
import { hasNumberDefaultValue } from '../../../utils/typeElementDefaultValueUtils.ts';
import {
  getBaseTypeElementSummarySegments,
  joinSummarySegments,
} from '../../../utils/typeElementMessagesUtils.ts';

interface INumberTypeElementSummaryProps
  extends IBaseTypeElementSummaryProps<INumberTypeElementData> {}

export const getNumberTypeElementSummary = (typeElementData: INumberTypeElementData): string => {
  const summarySegments: Array<string> = [...getBaseTypeElementSummarySegments(typeElementData)];

  if (hasNumberDefaultValue(typeElementData)) {
    summarySegments.push(DefaultValueSetSummary);
  }

  return joinSummarySegments(summarySegments);
};

export const NumberTypeElementSummary: React.FC<INumberTypeElementSummaryProps> = (props) => {
  const summaryText = getNumberTypeElementSummary(props.typeElementData);

  return <TypeElementSummary summaryText={summaryText} {...props} />;
};
