import { ReactNode, createContext, useMemo } from 'react';

type Translations = {
  readonly isTranslationsFeatureEnabled: boolean;
};

type Props = Translations & {
  readonly children: ReactNode;
};

export const AiTranslationsContext = createContext<Translations>({
  isTranslationsFeatureEnabled: false,
});
AiTranslationsContext.displayName = 'AiTranslationsContext';

export const AiTranslationsContextProvider = (props: Props) => {
  const contextValue = useMemo(
    () => ({
      isTranslationsFeatureEnabled: props.isTranslationsFeatureEnabled,
    }),
    [props.isTranslationsFeatureEnabled],
  );

  return (
    <AiTranslationsContext.Provider value={contextValue}>
      {props.children}
    </AiTranslationsContext.Provider>
  );
};
