import Immutable from 'immutable';
import { IOnboardingNotificationsServerModel } from '../../../data/models/onboardingNotifications/OnboardingNotificationsServerModel.type.ts';
import {
  OnboardingNotification,
  OnboardingNotificationsInfo,
} from '../../../data/models/user/OnboardingNotification.ts';
import { AiFeatureSet } from '../../constants/AiFeatureSet.ts';
import {
  UserPropertiesModel,
  UserPropertiesStateKeysByServerKeys,
} from '../../models/UserPropertiesModel.ts';
import {
  AiFeatureSetServerKey,
  AiFeedbackTimestamps,
  AreIntercomInteractionsDisabled,
  ContentModelingFeedbackServerKey,
  DismissedMissionControlOnboardingServerKey,
  DismissedNewWebhooksOnboardingServerKey,
  DismissedSemanticSearchOnboardingServerKey,
  DismissedSideBySidePreviewOnboardingKey,
  HasCreatedSeventhOrMoreWorkflowStep,
  IsSentryBugReportWidgetEnabledServerKey,
  LongProcessingChangesWarningDismissedServerKey,
  OnboardingNotificationsSettingsServerKey,
  PossiblyIncorrectPlacementWarningDismissedServerKey,
  PrefersClosedEditingSidebar,
  UpsellMultipleWorkflowsAfterCreatingStepDismissedServerKey,
  UpsellMultipleWorkflowsInitialDismissedServerKey,
  UserFeedbackServerKey,
  WebSpotlightTreeCollapsedServerKey,
} from '../../models/UserPropertiesServerKeys.ts';
import { parseBooleanFromValue } from '../parseUtils.ts';
import { matchingStrategies, parseArrayOfSpecificStrings } from './../parseJson.ts';

const resolveOnboardingNotificationSettings = (
  propertyValue: string,
): Immutable.Map<OnboardingNotification, boolean> | null => {
  const mapFromServerModel = (
    serverModel: IOnboardingNotificationsServerModel,
  ): OnboardingNotificationsInfo => ({
    ContentPublishedNotification: serverModel.showForItemPublishing || false,
    ContentTypeCreatedNotification: serverModel.showForTypeEditing || false,
  });

  const serverData = resolveAsJson(propertyValue) as IOnboardingNotificationsServerModel | null;
  return serverData
    ? Immutable.Map<OnboardingNotification, boolean>(mapFromServerModel(serverData))
    : null;
};

const resolveAsJson = (propertyValue: string): unknown => {
  try {
    return JSON.parse(propertyValue);
  } catch {
    return null;
  }
};

export const resolvePropertyValue = <PropName extends keyof UserPropertiesModel>(
  propName: PropName,
  propertyValue: string,
): UserPropertiesModel[PropName] | null => {
  switch (propName) {
    case UserPropertiesStateKeysByServerKeys[OnboardingNotificationsSettingsServerKey]: {
      return resolveOnboardingNotificationSettings(propertyValue) as
        | UserPropertiesModel[PropName]
        | null;
    }

    case UserPropertiesStateKeysByServerKeys[AiFeedbackTimestamps]:
    case UserPropertiesStateKeysByServerKeys[ContentModelingFeedbackServerKey]:
    case UserPropertiesStateKeysByServerKeys[UserFeedbackServerKey]: {
      return resolveAsJson(propertyValue) as UserPropertiesModel[PropName] | null;
    }

    case UserPropertiesStateKeysByServerKeys[DismissedNewWebhooksOnboardingServerKey]:
    case UserPropertiesStateKeysByServerKeys[DismissedMissionControlOnboardingServerKey]:
    case UserPropertiesStateKeysByServerKeys[DismissedSemanticSearchOnboardingServerKey]:
    case UserPropertiesStateKeysByServerKeys[DismissedSideBySidePreviewOnboardingKey]:
    case UserPropertiesStateKeysByServerKeys[AreIntercomInteractionsDisabled]:
    case UserPropertiesStateKeysByServerKeys[IsSentryBugReportWidgetEnabledServerKey]:
    case UserPropertiesStateKeysByServerKeys[HasCreatedSeventhOrMoreWorkflowStep]:
    case UserPropertiesStateKeysByServerKeys[LongProcessingChangesWarningDismissedServerKey]:
    case UserPropertiesStateKeysByServerKeys[PossiblyIncorrectPlacementWarningDismissedServerKey]:
    case UserPropertiesStateKeysByServerKeys[PrefersClosedEditingSidebar]:
    case UserPropertiesStateKeysByServerKeys[
      UpsellMultipleWorkflowsAfterCreatingStepDismissedServerKey
    ]:
    case UserPropertiesStateKeysByServerKeys[UpsellMultipleWorkflowsInitialDismissedServerKey]:
    case UserPropertiesStateKeysByServerKeys[WebSpotlightTreeCollapsedServerKey]: {
      return parseBooleanFromValue(propertyValue) as UserPropertiesModel[PropName];
    }

    case UserPropertiesStateKeysByServerKeys[AiFeatureSetServerKey]: {
      return parseArrayOfSpecificStrings(
        propertyValue,
        Object.values(AiFeatureSet),
        matchingStrategies.caseInsensitive,
      ) as UserPropertiesModel[PropName];
    }

    default:
      return propertyValue as UserPropertiesModel[PropName];
  }
};
