import { IconButton } from '@kontent-ai/component-library/Button';
import { Callout } from '@kontent-ai/component-library/Callout';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import Immutable from 'immutable';
import React from 'react';
import { Link } from 'react-router-dom';
import { EnvironmentRouteParams, RolesRoute } from '../../../../../_shared/constants/routePaths.ts';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { getCurrentProjectId } from '../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import {
  DataUiAction,
  DataUiElement,
  getDataUiActionAttribute,
  getDataUiElementAttribute,
} from '../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getAllLanguagesWithDefaultSuffix } from '../../../../../_shared/utils/languageUtils.ts';
import { buildPath } from '../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import {
  ICollectionGroupRoles,
  IProjectContributorRole,
} from '../../../../../data/models/users/ProjectContributor.ts';
import { PredefinedRoles } from '../../../roles/constants/predefinedRoles.ts';
import { getSortedRolesMemoized } from '../../../roles/selectors/getSortedRolesList.ts';
import { areLanguageRolesEnabledForCurrentProject } from '../../../selectors/allowedFeaturesSelectors.ts';
import { roleBuilderAnyLanguageOptionId } from '../../constants/roleBuilder.ts';
import { getRoleBuilderLanguageOptions } from '../../utils/getRoleBuilderLanguageOptions.ts';
import { getRoleBuilderRoleOptions } from '../../utils/getRoleBuilderRoleOptions.ts';
import { LanguagesSelector } from './LanguagesSelector.tsx';
import { IRoleOption, RoleSelector } from './RoleSelector.tsx';

type Props = {
  readonly anyLanguageOptionName: string;
  readonly autoFocus: boolean;
  readonly contributorId?: Uuid | undefined;
  readonly disabledLanguageDropDownTooltip: string | undefined;
  readonly disabledTooltip: string | undefined;
  readonly isProjectManagerSelectionValid: boolean;
  readonly onLanguagesChange: (languageIds: ReadonlyArray<Uuid>) => void;
  readonly onRemove: (() => void) | undefined;
  readonly onRoleChange: (roleId: Uuid) => void;
  readonly selectedLanguageOptionIds: ReadonlySet<Uuid>;
  readonly usedRoleIds: Immutable.Set<Uuid>;
  readonly userRole: IProjectContributorRole;
};

export const RoleBuilderRow: React.FC<Props> = ({
  anyLanguageOptionName,
  autoFocus,
  contributorId,
  disabledLanguageDropDownTooltip,
  disabledTooltip,
  isProjectManagerSelectionValid,
  onLanguagesChange,
  onRemove,
  onRoleChange,
  selectedLanguageOptionIds,
  usedRoleIds,
  userRole,
}) => {
  const currentProjectId = useSelector(getCurrentProjectId);
  const allProjectRoles = useSelector((s) => s.data.roles.rolesById);
  const sortedProjectRoles = getSortedRolesMemoized(allProjectRoles);
  const userRoles = useSelector((s) =>
    contributorId ? s.usersApp.projectContributors.get(contributorId)?.collectionGroups || [] : [],
  );
  const isProjectManager = userRoles.some((collectionRoles: ICollectionGroupRoles) =>
    collectionRoles.roles.some(
      (r) => allProjectRoles.get(r.roleId)?.codename === PredefinedRoles.ProjectManager,
    ),
  );

  const languages = useSelector((state) =>
    getAllLanguagesWithDefaultSuffix(state.data.languages),
  ).toArray();
  const areLanguageRolesEnabled = useSelector(areLanguageRolesEnabledForCurrentProject);

  const roleOptions = getRoleBuilderRoleOptions(userRole, sortedProjectRoles, usedRoleIds);
  const selectedRoleOption = roleOptions.find(
    (option: IRoleOption) => option.id === userRole.roleId,
  );

  const languageOptions = getRoleBuilderLanguageOptions({
    anyLanguageOptionName,
    areLanguageRolesEnabled,
    languages,
    selectedLanguageOptionIds,
    userRole,
  });

  const selectedLanguageIds = languageOptions
    .filter((option) => userRole.languageIds.includes(option.id))
    .map((language) => language.id);

  const onSelectedLanguagesChange = (newSelectedIds: ReadonlySet<Uuid>): void => {
    if (newSelectedIds.has(roleBuilderAnyLanguageOptionId)) {
      // If "any" option was previously selected and there are additionally selected standard options, dismiss the "any" option
      if (newSelectedIds.size > 1 && selectedLanguageIds.includes(roleBuilderAnyLanguageOptionId)) {
        onLanguagesChange(
          [...newSelectedIds].filter((id) => id !== roleBuilderAnyLanguageOptionId),
        );
      } else {
        // If the "any" option is new, keep just the "any" option
        onLanguagesChange([roleBuilderAnyLanguageOptionId]);
      }
    } else {
      onLanguagesChange([...newSelectedIds]);
    }
  };

  const removeButtonTooltip = onRemove
    ? 'Remove this role.'
    : 'At least one role must be assigned.';

  const RolesLink = buildPath<EnvironmentRouteParams>(RolesRoute, {
    projectId: currentProjectId,
  });

  return (
    <Stack spacing={Spacing.XL}>
      <Row spacingX={Spacing.L} {...getDataUiElementAttribute(DataUiElement.RoleBuilderRow)}>
        <Column flexBasis={0} flexFactor={1}>
          <RoleSelector
            autoFocus={autoFocus}
            disabledTooltip={disabledTooltip}
            onChange={(role) => onRoleChange(role.id)}
            roles={roleOptions}
            selectedRole={selectedRoleOption}
          />
        </Column>
        <Column flexBasis={0} flexFactor={1}>
          <LanguagesSelector
            disabled={!!disabledTooltip || !!disabledLanguageDropDownTooltip}
            languages={languageOptions}
            onChange={onSelectedLanguagesChange}
            selectedLanguageIds={selectedLanguageIds}
            tooltipText={disabledTooltip || disabledLanguageDropDownTooltip}
          />
        </Column>
        <Column width="fit-content">
          <IconButton
            aria-label={disabledTooltip ?? removeButtonTooltip}
            buttonState={!!disabledTooltip || !onRemove ? 'disabled' : 'default'}
            buttonStyle="tertiary"
            iconName="Bin"
            onClick={onRemove}
            size="medium"
            tooltipPlacement="bottom"
            tooltipText={disabledTooltip ?? removeButtonTooltip}
            {...getDataUiActionAttribute(DataUiAction.RemoveRoleBuilderRow)}
          />
        </Column>
      </Row>
      {selectedRoleOption?.isProjectManagerRole &&
        (isProjectManagerSelectionValid ? (
          <Callout
            calloutType="friendlyWarning"
            headline="Project manager role is global"
            maxWidth={494}
          >
            Project managers have access to the whole project. They can manage content and settings
            in all collections, languages, and environments.
            <br />
            The Project manager role cannot be combined with other roles.
          </Callout>
        ) : (
          <Callout
            calloutType="warning"
            headline="Project manager role requires access to the whole project"
            maxWidth={494}
          >
            The predefined Project manager role is global and must be assigned to Any collection and
            Any language.
            <br />
            The Project manager role cannot be combined with other roles.
            <br />
            You can create a <Link to={RolesLink}>custom role</Link> with the desired permissions
            and access restrictions.
          </Callout>
        ))}
      {!selectedRoleOption?.isProjectManagerRole && isProjectManager && (
        <Callout
          calloutType="friendlyWarning"
          headline="This user is a Project manager"
          maxWidth={494}
        >
          If you downgrade their role in this environment, they’ll be deactivated in other
          environments and keep the Project manager role.
        </Callout>
      )}
    </Stack>
  );
};

RoleBuilderRow.displayName = 'RoleBuilderRow';
