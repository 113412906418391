import React from 'react';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { getSelectedLanguageIdOrRouteMacro } from '../../../../_shared/selectors/getSelectedLanguageId.ts';
import { getCurrentProject } from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { ContentInventoryTabs as ContentInventoryTabsComponent } from '../components/ContentInventoryTabs.tsx';
import { getContentInventoryTabsStructure } from '../utils/contentInventoryTabsStructure.ts';

type ContentInventoryTabsProps = {
  readonly containerRef: React.RefObject<HTMLDivElement>;
};

export const ContentInventoryTabs: React.FC<ContentInventoryTabsProps> = ({ containerRef }) => {
  const tabsStructure = useSelector((s) => {
    const selectedLanguageId = getSelectedLanguageIdOrRouteMacro(s);
    const currentProject = getCurrentProject(s);
    return getContentInventoryTabsStructure(currentProject, selectedLanguageId);
  });

  return (
    <ContentInventoryTabsComponent containerRef={containerRef} tabsStructure={tabsStructure} />
  );
};

ContentInventoryTabs.displayName = 'ContentInventoryTabs';
