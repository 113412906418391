import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { Box } from '@kontent-ai/component-library/Box';
import { documentationLinks } from '../../../../../../../../_shared/constants/documentationLinks.ts';

export const PreviewEmptyStateDocumentationMessageLink = () => {
  return (
    <Box>
      Learn{' '}
      <OutwardLink href={documentationLinks.configuringPreview}>how to set up preview</OutwardLink>{' '}
      for your content items.
    </Box>
  );
};
