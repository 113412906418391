import { noOperation } from '@kontent-ai/utils';
import { FC, PropsWithChildren, createContext, useCallback, useMemo, useState } from 'react';
import { SortBy, Status } from '../constants/filters.ts';

type Filter = {
  readonly status: Status;
  readonly setStatus: (status: Status) => void;
  readonly sortBy: SortBy;
  readonly setSortBy: (sortBy: SortBy) => void;
  readonly clearFilter: () => void;
};

export const AiAcceleratorsAppFilterContext = createContext<Filter>({
  status: Status.All,
  sortBy: SortBy.Newest,
  setStatus: noOperation,
  setSortBy: noOperation,
  clearFilter: noOperation,
});

export const AiAcceleratorsAppFilterContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [sortBy, setSortBy] = useState<SortBy>(SortBy.Newest);
  const [status, setStatus] = useState<Status>(Status.All);

  const clearFilter = useCallback(() => {
    setSortBy(SortBy.Newest);
    setStatus(Status.All);
  }, []);

  const filter: Filter = useMemo(
    () => ({ status, sortBy, setSortBy, setStatus, clearFilter }),
    [status, sortBy, clearFilter],
  );

  return (
    <AiAcceleratorsAppFilterContext.Provider value={filter}>
      {children}
    </AiAcceleratorsAppFilterContext.Provider>
  );
};
