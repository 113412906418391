import { InvariantException } from '@kontent-ai/errors';
import React from 'react';
import { VariantIdMacro } from '../../../../_shared/constants/variantIdValues.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { getCurrentProject } from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { getUserCapability } from '../../../../_shared/utils/permissions/capability.ts';
import { RootFolderId } from '../../assets/constants/assetFolderConstants.ts';
import { buildAssetListingRoutePath } from '../../assets/utils/assetsPathUtils.ts';
import { ContentInventory as ContentInventoryComponent } from '../components/ContentInventory.tsx';
import { getContentInventoryIndexTabRoute } from '../utils/contentInventoryTabsStructure.ts';

const ContentInventory: React.FC = () => {
  const currentProjectId = useSelector((s) => s.sharedApp.currentProjectId);

  const indexRoute = useSelector((s) => {
    const capability = getUserCapability(getCurrentProject(s));
    return getContentInventoryIndexTabRoute(capability, currentProjectId, VariantIdMacro);
  });

  if (!indexRoute) {
    throw InvariantException('User does not have capabilities for Content inventory index route');
  }

  const rootAssetFolderRoute = buildAssetListingRoutePath({
    projectId: currentProjectId,
    assetFolderId: RootFolderId,
  });

  return (
    <ContentInventoryComponent
      indexRoute={indexRoute}
      rootAssetFolderRoute={rootAssetFolderRoute}
    />
  );
};

ContentInventory.displayName = 'ContentInventory';

// biome-ignore lint/style/noDefaultExport: Necessary for bundle splitting
export default ContentInventory;
