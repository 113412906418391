import { useState } from 'react';
import { TypeElementConfigurationSection } from '../../../../../applications/contentModels/shared/components/typeElements/shared/TypeElementConfigurationSection.tsx';
import { AiGuidelines as AiGuidelinesModel } from '../../../data/models/aiGuidelines.ts';
import { AiGuidelinesFormShape } from '../types/AiGuidelinesFormShape.type.ts';
import { AiGuidelinesMenu } from './AiGuidelinesMenu.tsx';
import { AiGuidelinesSelect } from './AiGuidelinesSelect.tsx';
import { CreateAiGuidelinesModal } from './CreateAiGuidelinesModal.tsx';
import { EditAiGuidelinesModal } from './EditAiGuidelinesModal.tsx';

type AiGuidelinesConfigurationProps = {
  readonly aiGuidelines: ReadonlyArray<AiGuidelinesModel>;
  readonly onAiGuidelinesCreate: (aiGuidelines: AiGuidelinesFormShape) => void;
  readonly onAiGuidelinesEdit: (id: Uuid, aiGuidelines: AiGuidelinesFormShape) => void;
  readonly onAiGuidelinesSelect: (ids: ReadonlyArray<Uuid>) => void;
  readonly selectedAiGuidelines: AiGuidelinesModel | null;
};

export const AiGuidelinesConfiguration = ({
  aiGuidelines,
  onAiGuidelinesCreate,
  onAiGuidelinesEdit,
  onAiGuidelinesSelect,
  selectedAiGuidelines,
}: AiGuidelinesConfigurationProps) => {
  const [editedGuidelinesId, setEditedGuidelinesId] = useState<Uuid | null>(null);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState<boolean>(false);

  const resetEditedGuidelinesId = () => setEditedGuidelinesId(null);

  return (
    <TypeElementConfigurationSection
      contentWidth="wide"
      title="Content review guidelines"
      tooltip="Provide a custom set of guidelines to follow so content creators can use AI to review content."
    >
      {isCreateDialogOpen && (
        <CreateAiGuidelinesModal
          aiGuidelines={aiGuidelines}
          onClose={() => setIsCreateDialogOpen(false)}
          onCreate={(aiGuidelinesFormShape) => {
            onAiGuidelinesCreate(aiGuidelinesFormShape);
            setIsCreateDialogOpen(false);
          }}
        />
      )}
      {editedGuidelinesId && (
        <EditAiGuidelinesModal
          aiGuidelines={aiGuidelines}
          editedAiGuidelinesId={editedGuidelinesId}
          onClose={resetEditedGuidelinesId}
          onEdit={(id, aiGuidelinesFormShape) => {
            onAiGuidelinesEdit(id, aiGuidelinesFormShape);
            resetEditedGuidelinesId();
          }}
        />
      )}
      <AiGuidelinesSelect
        selectedAiGuidelines={selectedAiGuidelines}
        renderAiGuidelinesMenu={(hideMenu) => (
          <AiGuidelinesMenu
            aiGuidelines={aiGuidelines}
            onCreate={() => {
              hideMenu();
              setIsCreateDialogOpen(true);
            }}
            onEdit={(guidelinesId) => {
              hideMenu();
              setEditedGuidelinesId(guidelinesId);
            }}
            onSelect={(aiGuidelinesIds) => {
              hideMenu();
              onAiGuidelinesSelect(aiGuidelinesIds);
            }}
            selectedAiGuidelinesId={selectedAiGuidelines?.id}
          />
        )}
      />
    </TypeElementConfigurationSection>
  );
};
