import { ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { loadSitemap } from '../../../../../data/actions/thunkDataActions.ts';
import {
  ISitemap,
  createSitemapFromServerModel,
} from '../../../../../data/models/contentModelsApp/sitemap/Sitemap.ts';
import { IContentItemRepository } from '../../../../../repositories/interfaces/IContentItemRepository.type.ts';
import { ISitemapRepository } from '../../../../../repositories/interfaces/ISitemapRepository.type.ts';
import { Sitemap_InitSitemap } from '../../constants/sitemapActionTypes.ts';

interface ISitemapInitDependencies {
  readonly contentItemRepository: IContentItemRepository;
  readonly sitemapRepository: ISitemapRepository;
}

const init = (sitemap: ISitemap, projectContainsPublishedItems: boolean) =>
  ({
    type: Sitemap_InitSitemap,
    payload: {
      sitemap,
      projectContainsPublishedItems,
    },
  }) as const;

export type InitSitemapActionsType = ReturnType<typeof init>;

export const initSitemapCreator =
  (deps: ISitemapInitDependencies) =>
  (abortSignal: AbortSignal): ThunkPromise =>
  async (dispatch) => {
    const [sitemapServerModel, containsPublishedItem] = await Promise.all([
      deps.sitemapRepository.getSitemap(abortSignal),
      deps.contentItemRepository.projectContainsPublishedItems({}, abortSignal),
      dispatch(loadSitemap(abortSignal)),
    ]);

    const sitemapRecord = createSitemapFromServerModel(sitemapServerModel);
    dispatch(init(sitemapRecord, containsPublishedItem));
  };
