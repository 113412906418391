import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { PreviewError, PreviewUrlInfo } from '../../../utils/previewUtils.ts';
import { ItemEditingLayout as ItemEditingLayoutComponent } from '../components/ItemEditingLayout.tsx';
import { useNavigationTree } from '../features/contentItemPreview/context/NavigationTreeContext.tsx';
import { usePreviewSpace } from '../features/contentItemPreview/context/PreviewSpaceContext.tsx';
import {
  PreviewMetadata,
  WebSpotlightInItemEditingContextProvider,
} from '../features/contentItemPreview/context/WebSpotlightInItemEditingContext.tsx';
import { useIsLivePreviewOpen } from '../hooks/useIsLivePreviewOpen.ts';
import { getSpacesForPreviewUrls } from '../utils/getAvailableSpacesForPreviewUrls.ts';
import { getAvailablePreviewLinksForUser } from './selectors/getAvailablePreviewLinksForUser.tsx';

export const ItemEditingLayout = () => {
  const isPreviewOpen = useIsLivePreviewOpen();
  const { isTreeOpened } = useNavigationTree();

  const { spaceId } = usePreviewSpace();
  const previewMetadata = useSelector((state) => getPreviewMetadata(state, spaceId));

  return (
    <WebSpotlightInItemEditingContextProvider previewMetadata={previewMetadata}>
      <ItemEditingLayoutComponent isLivePreviewOpen={isPreviewOpen} isTreeOpen={isTreeOpened} />
    </WebSpotlightInItemEditingContextProvider>
  );
};

const noPreviewUrlMetadata: PreviewMetadata = {
  url: '',
  usedUrlSlug: null,
  error: PreviewError.NoPreview,
};

const getPreviewMetadata = (state: IStore, selectedSpaceId: Uuid | null): PreviewMetadata => {
  const availableSpaces = getSpacesForPreviewUrls(state);
  const availablePreviewLinks = getAvailablePreviewLinksForUser(state);

  const previewLink =
    availableSpaces.length > 0 && selectedSpaceId
      ? (availablePreviewLinks.find((l) => l.spaceId === selectedSpaceId) ??
        availablePreviewLinks[0])
      : availablePreviewLinks[0];

  return isPreviewMetadata(previewLink) ? previewLink : noPreviewUrlMetadata;
};

const isPreviewMetadata = (
  previewUrlInfo: Pick<PreviewUrlInfo, 'error' | 'url' | 'usedUrlSlug'> | undefined,
): previewUrlInfo is PreviewMetadata =>
  !!previewUrlInfo && (!!previewUrlInfo.url || !!previewUrlInfo.error);
