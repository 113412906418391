import { Collection } from '@kontent-ai/utils';
import { useMemo } from 'react';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { getCurrentProjectId } from '../../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { Capability } from '../../../../../../_shared/utils/permissions/capability.ts';
import {
  filterCollectionsByCapability,
  filterCollectionsByCapabilityForTypeInLanguage,
  filterCollectionsByCapabilityInLanguage,
} from '../../../../../../_shared/utils/permissions/filterCollectionsByCapability.ts';
import { ICollection } from '../../../../../../data/models/collections/Collection.ts';

export const useAvailableCollectionsForSelectedLanguage = (
  capability: Capability,
  typeId?: Uuid,
): ReadonlyArray<ICollection> => {
  const collectionsById = useSelector((s) => s.data.collections.byId);
  const allCollections = useMemo(() => Collection.getValues(collectionsById), [collectionsById]);
  const selectedLanguageId = useSelector(getSelectedLanguageIdOrThrow);
  const projectId = useSelector(getCurrentProjectId);
  const userData = useSelector((s) => s.data.user);

  return typeId
    ? filterCollectionsByCapabilityForTypeInLanguage(
        allCollections,
        selectedLanguageId,
        projectId,
        capability,
        userData,
        typeId,
      )
    : filterCollectionsByCapabilityInLanguage(
        allCollections,
        selectedLanguageId,
        capability,
        projectId,
        userData,
      );
};

export const useAvailableCollections = (capability: Capability): ReadonlyArray<ICollection> => {
  const allCollections = useSelector((s) => s.data.collections.byId);
  const projectId = useSelector(getCurrentProjectId);
  const userData = useSelector((s) => s.data.user);

  return filterCollectionsByCapability(capability, allCollections, projectId, userData);
};
