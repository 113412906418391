import { ThunkFunction } from '../../../../../@types/Dispatcher.type.ts';
import { ISitemap } from '../../../../../data/models/contentModelsApp/sitemap/Sitemap.ts';
import { DirectionItemEnum } from '../../constants/DirectionItemEnum.ts';
import { Sitemap_MoveNode } from '../../constants/sitemapActionTypes.ts';
import { moveSitemapNode as moveNodeInSitemap } from '../../utils/sitemapUtils.ts';

const moveSitemapNode = (sitemap: ISitemap) =>
  ({
    type: Sitemap_MoveNode,
    payload: {
      sitemap,
    },
  }) as const;

export type MoveNodeActionsType = ReturnType<typeof moveSitemapNode>;

export const moveNodeCreator =
  () =>
  (targetNodeId: Uuid, direction: ReadonlySet<DirectionItemEnum>): ThunkFunction =>
  (dispatch, getState) => {
    const { sitemap, collapsedNodeIds, draggedNodeId } = getState().sitemapApp;
    if (sitemap && draggedNodeId) {
      const newSitemapTree = moveNodeInSitemap(
        sitemap,
        collapsedNodeIds,
        draggedNodeId,
        targetNodeId,
        direction,
      );
      dispatch(moveSitemapNode(newSitemapTree));
    }
  };
