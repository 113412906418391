import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { Callout } from '@kontent-ai/component-library/Callout';
import { documentationLinks } from '../../../../../_shared/constants/documentationLinks.ts';
import { InnovationLabFeatureCard } from '../../components/InnovationLabFeatureCard.tsx';
import { InnovationLabFeatureCardWrapper } from '../../components/InnovationLabFeatureCardWrapper.tsx';
import { InnovationLabFeatureDialogs } from '../../components/InnovationLabFeatureDialogs.tsx';
import { InnovationLabGenericConsent } from '../../components/InnovationLabGenericConsent.tsx';
import { useFeatureActivationDialog } from '../../hooks/useFeatureActivationDialog.ts';
import { activateInnovationLabFeature } from '../../thunks/activateInnovationLabFeature.ts';
import { deactivateInnovationLabFeature } from '../../thunks/deactivateInnovationLabFeature.ts';
import { InnovationLabCardProps } from '../../types/InnovationLabCardProps.type.ts';

export const RefinedNavigationCard = ({ state, info, name }: InnovationLabCardProps) => {
  const {
    dialogDisplayed,
    isAsyncOperationInProgress,
    onCardActionButtonClick,
    onDialogActionClick,
    onDialogClose,
  } = useFeatureActivationDialog(
    info,
    state,
    activateInnovationLabFeature('refined-navigation'),
    deactivateInnovationLabFeature('refined-navigation'),
  );

  const featureState = info.status;

  return (
    <>
      <InnovationLabFeatureCardWrapper featureState={featureState}>
        <InnovationLabFeatureCard
          activationState={state}
          featureName={name}
          featureState={featureState}
          isActionButtonLoading={isAsyncOperationInProgress}
          onCardActionButtonClick={onCardActionButtonClick}
          onContactSupport={() => null}
          renderCardBody={() => (
            <>
              <p>
                Get a sneak peek at the{' '}
                <OutwardLink href={documentationLinks.streamlinedProductNavigation}>
                  new product navigation
                </OutwardLink>{' '}
                designed for faster access to every part of your project.
              </p>
              <p>
                With the new navigation, you’ll see subsections directly in the left panel, such as
                Assets under Content & assets, so you can find what you need in one place.
              </p>
            </>
          )}
        />
      </InnovationLabFeatureCardWrapper>
      <InnovationLabFeatureDialogs
        dialogDisplayed={dialogDisplayed}
        dialogFeatureName={name}
        isAsyncOperationInProgress={isAsyncOperationInProgress}
        onDialogActionClick={onDialogActionClick}
        onDialogClose={onDialogClose}
        renderActivationDialogBody={() => (
          <>
            <Callout calloutType="quickTip" hideSubheadline>
              <p>
                <b>Innovation Lab feature activation</b>
              </p>
              <p>
                You’re about to activate the {name} enhancements. If you deactivate, Refined
                Navigation won’t disappear, but only the enhancements that are being piloted before
                they’re available to everyone.
              </p>
            </Callout>
            <InnovationLabGenericConsent subject="the enhancements" />
          </>
        )}
      />
    </>
  );
};

RefinedNavigationCard.displayName = 'RefinedNavigationCard';
