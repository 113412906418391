import { AutoScrollId } from '../../../../../_shared/components/AutoScroll/AutoScrollId.ts';
import { DisabledUsageInItemVariant } from '../../../../../_shared/components/Usages/DisabledUsageInItemVariant.tsx';
import { EnabledUsageInItemVariant } from '../../../../../_shared/components/Usages/EnabledUsageInItemVariant.tsx';
import { UsageItemReference } from '../../../../../_shared/utils/usage/usageUtils.ts';
import { IContentItemVariantReference } from '../../../../itemEditor/models/contentItem/ContentItemVariantReference.ts';

type Props = {
  readonly assetScrollId: AutoScrollId;
  readonly disabledMessage: string | undefined;
  readonly onUsageClick: (() => void) | undefined;
  readonly scrollId?: AutoScrollId;
  readonly typeName: string | null;
  readonly usage: UsageItemReference;
  readonly variantReferenceBuildLink: (variantReference: IContentItemVariantReference) => string;
};

export const SingleAssetUsage = (props: Props) => {
  const {
    disabledMessage,
    assetScrollId,
    onUsageClick,
    scrollId,
    typeName,
    usage,
    variantReferenceBuildLink,
  } = props;

  if (disabledMessage) {
    return (
      <DisabledUsageInItemVariant message={disabledMessage} variantReference={usage.primary} />
    );
  }

  return (
    <EnabledUsageInItemVariant
      buildLink={variantReferenceBuildLink}
      onClick={onUsageClick}
      scrollId={scrollId}
      toScrollId={assetScrollId}
      typeName={typeName}
      usage={usage}
    />
  );
};
