import { Button } from '@kontent-ai/component-library/Button';
import React from 'react';
import { IPopoverTargetProps } from '../../../../../../../../component-library/components/Dialogs/Popover/usePopover.tsx';

type SelectContentTypesWithUrlSlugButtonProps = {
  readonly isDisabled: boolean;
  readonly onClick: () => void;
  readonly tooltipText: string | undefined;
  readonly targetProps: IPopoverTargetProps;
};

export const SelectContentTypesWithUrlSlugButton: React.FC<
  SelectContentTypesWithUrlSlugButtonProps
> = ({ isDisabled, onClick, tooltipText, targetProps }) => (
  <Button
    buttonStyle="tertiary"
    disabled={isDisabled}
    onClick={onClick}
    tooltipText={tooltipText}
    {...targetProps}
  >
    Select content types with URL slug
  </Button>
);

SelectContentTypesWithUrlSlugButton.displayName = 'SelectContentTypesWithUrlSlugButton';
