import { Button } from '@kontent-ai/component-library/Button';
import { assert } from '@kontent-ai/utils';
import { FC, MouseEvent } from 'react';
import { useHistory } from 'react-router';
import {
  ContentItemPreviewWithEditorRoute,
  ContentItemPreviewWithEditorRouteParams,
} from '../../../../../../_shared/constants/routePaths.ts';
import { matchPath } from '../../../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { getSmartLinkTarget } from '../../../../../smartLink/utils/smartLinkUtils.ts';

export const OpenInEditorAction: FC = () => {
  const history = useHistory();

  const currentPath = history.location.pathname;
  const match = matchPath<ContentItemPreviewWithEditorRouteParams<string>>(
    currentPath,
    ContentItemPreviewWithEditorRoute,
  );
  assert(match, () => 'Current route does not fit to ContentItemPreviewWithEditorRoute');

  const onOpenInEditor = (e: MouseEvent): void => {
    e.stopPropagation();
    const targetPath = getSmartLinkTarget(
      undefined,
      undefined,
      match.editedItemId,
      currentPath,
    ).path;
    history.push(targetPath);
  };

  return (
    <Button onClick={onOpenInEditor} buttonStyle="secondary" size="small">
      Open in editor
    </Button>
  );
};

OpenInEditorAction.displayName = 'OpenInEditorAction';
