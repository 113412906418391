import React from 'react';

interface IBaseChildComponentProps {
  readonly nodeId?: Uuid;
  readonly isBeingDragged?: boolean;
}

interface IBarItemChildListProps {
  readonly childComponent: React.ComponentType<IBaseChildComponentProps>;
  readonly childIds: ReadonlyArray<Uuid>;
  readonly isCollapsed: boolean;
  readonly isBeingDragged: boolean;
}

export const BarItemChildList: React.FC<IBarItemChildListProps> = (
  props: IBarItemChildListProps,
) => {
  const ChildComponent = props.childComponent;
  const childIds = props.childIds;

  if (props.isCollapsed || !childIds.length) {
    return null;
  }

  const childNodes = childIds
    .map((nodeId) => {
      return (
        nodeId && (
          <ChildComponent nodeId={nodeId} isBeingDragged={props.isBeingDragged} key={nodeId} />
        )
      );
    })
    .filter((x) => !!x);

  return <ul className="bar-item__list">{childNodes}</ul>;
};

BarItemChildList.displayName = 'BarItemChildList';
