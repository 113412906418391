import React, { useCallback, useState } from 'react';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { getCurrentProjectId } from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { createSpaceListingLink } from '../../../../_shared/utils/routing/projectSubscriptionRoutingUtils.ts';
import { setWebSpotlightStatus } from '../../../webSpotlight/actions/thunkWebSpotlightActions.ts';
import { isWebSpotlightEnabledAndInitializedForCurrentProject } from '../../../webSpotlight/selectors/webSpotlightSelectors.ts';
import { WebSpotlightStatusTile as Component } from '../components/WebSpotlightStatusTile.tsx';

export const WebSpotlightStatusTile: React.FC = () => {
  const dispatch = useDispatch();
  const [isSettingInProgress, setIsSettingInProgress] = useState<boolean>(false);
  const isWebSpotlightEnabled = useSelector(isWebSpotlightEnabledAndInitializedForCurrentProject);
  const spacesAppRoute = useSelector((s) =>
    createSpaceListingLink({
      projectId: getCurrentProjectId(s),
      subscriptionId: s.subscriptionApp.selectedSubscriptionId,
    }),
  );

  const toggleWebSpotlightStatusCallback = useCallback(async () => {
    setIsSettingInProgress(true);
    await dispatch(setWebSpotlightStatus(!isWebSpotlightEnabled));
    setIsSettingInProgress(false);
  }, [isWebSpotlightEnabled]);

  return (
    <Component
      isSettingToggleInProgress={isSettingInProgress}
      isWebSpotlightEnabled={isWebSpotlightEnabled}
      toggleWebSpotlightStatus={toggleWebSpotlightStatusCallback}
      spacesAppRoute={spacesAppRoute}
    />
  );
};

WebSpotlightStatusTile.displayName = 'WebSpotlightStatusTile';
