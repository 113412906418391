import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../../../@types/Dispatcher.type.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import {
  ITypeElementSummaryCallbackProps,
  ITypeElementSummaryDataProps,
  TypeElementSummary as TypeElementSummaryComponent,
} from '../../../components/typeElements/shared/TypeElementSummary.tsx';
import { IBaseTypeElementData } from '../../../models/elements/types/TypeElementData.ts';
import { IBaseTypeElementSummaryProps } from '../../../types/IBaseTypeElementSummaryProps.type.ts';
import {
  getBaseTypeElementSummarySegments,
  joinSummarySegments,
} from '../../../utils/typeElementMessagesUtils.ts';

interface ISimpleTypeElementSummaryProps extends IBaseTypeElementSummaryProps {}

export function getSimpleTypeElementSummary(typeElement: IBaseTypeElementData): string {
  const summarySegments = getBaseTypeElementSummarySegments(typeElement);

  return joinSummarySegments(summarySegments);
}

function mapStateToProps(
  _state: IStore,
  ownProps: ISimpleTypeElementSummaryProps,
): ITypeElementSummaryDataProps {
  const element = ownProps.typeElementData;
  const summaryText = getSimpleTypeElementSummary(element);

  return {
    isActive: ownProps.isActive,
    summaryText,
  };
}

function mapDispatchToProps(
  _dispatch: Dispatch,
  ownProps: ISimpleTypeElementSummaryProps,
): ITypeElementSummaryCallbackProps {
  return ownProps;
}

export const SimpleTypeElementSummary: React.ComponentType<ISimpleTypeElementSummaryProps> =
  connect(mapStateToProps, mapDispatchToProps)(TypeElementSummaryComponent);
