import { Box } from '@kontent-ai/component-library/Box';
import { Button } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Paper } from '@kontent-ai/component-library/Paper';
import { Stack } from '@kontent-ai/component-library/Stack';
import {
  BorderRadius,
  Spacing,
  colorBackgroundLowEmphasis,
  gridUnit,
} from '@kontent-ai/component-library/tokens';
import { not } from '@kontent-ai/utils';
import classNames from 'classnames';
import { useRef, useState } from 'react';
import { ModalDialog } from '../../../../../../../component-library/components/Dialogs/ModalDialog/ModalDialog.tsx';
import { ContentItemFilterOrigin } from '../../../../../../_shared/models/events/ContentItemFilterEventData.type.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ISavedFilter } from '../../../../../../data/models/filters/ISavedFilter.ts';
import { useIsRefinedNavigationFeatureEnabled } from '../../../../../refinedNavigation/contexts/RefinedNavigationContext.tsx';
import { ClearFilterButton } from '../../../../shared/components/filter/ClearFilterButton.tsx';
import { IListingFilter, isListingFilterEmpty } from '../../../models/filter/IListingFilter.ts';
import { ISavedFiltersUi } from '../../../reducers/listingUi/reducers/savedFiltersUi/ISavedFiltersUi.type.ts';
import { filterHeadlineIdentifier } from '../constants/listingFilterConstants.ts';
import { SavedFiltersListing } from '../containers/savedFilters/SavedFiltersListing.tsx';
import { useSelectedFilterId } from '../hooks/useSelectedFilterId.ts';
import { SaveFilterModalDialog } from './SaveFilterModalDialog.tsx';
import { FilterActionsMenu } from './savedFilters/FilterActionsMenu.tsx';
import { ShareFilterLinkForm } from './savedFilters/ShareFilterLinkForm.tsx';

type Props = {
  readonly filter: IListingFilter;
  readonly filters: ReadonlyArray<ISavedFilter>;
  readonly forcedContentTypeIds: ReadonlySet<Uuid> | undefined;
  readonly hideVariantSpecificInfo?: boolean;
  readonly isFilterBeingCreated: boolean;
  readonly onClearFilter: () => void;
  readonly onHideFilter?: () => void;
  readonly onCreateLinkedFilter: () => void;
  readonly onSaveFilter: (name: string) => void;
  readonly origin: ContentItemFilterOrigin;
  readonly renderSelectors: () => JSX.Element;
  readonly savedFilters: ReadonlyMap<Uuid, ISavedFilter>;
  readonly savedFiltersUi: ISavedFiltersUi;
};

const getSaveButtonTooltip = (canSaveCurrentFilter: boolean) => {
  if (!canSaveCurrentFilter) {
    return 'Filter is already saved';
  }
  return '';
};

export const ContentItemFilter = (props: Props) => {
  const selectedFilterId = useSelectedFilterId(props.filter, props.savedFilters);
  const isAnyOptionSelected = !isListingFilterEmpty(props.filter, props.forcedContentTypeIds);
  const canSaveCurrentFilter =
    !selectedFilterId && !isListingFilterEmpty(props.filter) && !props.isFilterBeingCreated;
  const isFilterInModal =
    props.origin === ContentItemFilterOrigin.ModalContentItemSelector ||
    props.origin === ContentItemFilterOrigin.ModalMultipleContentItemsSelector;

  const isRefinedNavigationFeatureEnabled = useIsRefinedNavigationFeatureEnabled();

  if (!isRefinedNavigationFeatureEnabled) {
    return (
      <div
        aria-labelledby={filterHeadlineIdentifier}
        className={classNames('content-item-filter', 'canvas__sidebar', {
          'content-item-filter--in-modal': isFilterInModal,
        })}
      >
        <div className="content-item-filter__content">
          {!props.hideVariantSpecificInfo && (
            <SavedFiltersListing
              filters={props.filters}
              forcedContentTypeIds={props.forcedContentTypeIds}
              isAnyOptionSelected={undefined}
              onClearFilter={undefined}
              origin={props.origin}
              savedFiltersUi={props.savedFiltersUi}
              selectedFilterId={selectedFilterId}
            />
          )}
          <div className="facet" {...getDataUiElementAttribute(DataUiElement.Filter)}>
            <div className="facet__title">
              <div id={filterHeadlineIdentifier} className="facet__title-text">
                Refine results
              </div>
              {isAnyOptionSelected && (
                <div className="facet__title-actions">
                  <ClearFilterButton onClick={props.onClearFilter} />
                  {!props.hideVariantSpecificInfo && (
                    <FilterActionsMenu
                      canSaveCurrentFilter={canSaveCurrentFilter}
                      savedFiltersUi={props.savedFiltersUi}
                      onCreateLinkedFilter={props.onCreateLinkedFilter}
                      onSaveFilter={props.onSaveFilter}
                    />
                  )}
                </div>
              )}
            </div>
            {props.renderSelectors()}
          </div>
        </div>
      </div>
    );
  }

  const isSaveDisabled =
    !canSaveCurrentFilter ||
    props.savedFiltersUi.isFilterBeingCreated ||
    isListingFilterEmpty(props.filter, props.forcedContentTypeIds);

  return (
    <Paper
      borderRadius={BorderRadius.M}
      backgroundColor={colorBackgroundLowEmphasis}
      borderColor={colorBackgroundLowEmphasis}
      paddingBottom={Spacing.L}
      paddingTop={Spacing.L}
    >
      <Stack spacing={Spacing.None}>
        {!props.hideVariantSpecificInfo && (
          <SavedFiltersListing
            filters={props.filters}
            forcedContentTypeIds={props.forcedContentTypeIds}
            isAnyOptionSelected={isAnyOptionSelected}
            onClearFilter={props.onClearFilter}
            origin={props.origin}
            savedFiltersUi={props.savedFiltersUi}
            selectedFilterId={selectedFilterId}
          />
        )}
        {props.renderSelectors()}
      </Stack>
      <Box display="flex" justifyContent="end" paddingX={Spacing.L} paddingTop={Spacing.L}>
        <Inline spacing={Spacing.S}>
          {isAnyOptionSelected && (
            <Button buttonStyle="secondary" destructive onClick={props.onClearFilter}>
              <Button.Label>Clear all filters</Button.Label>
            </Button>
          )}
          {isAnyOptionSelected && (
            <ShareButtonWithModal
              link={props.savedFiltersUi.currentFilterLink}
              loading={props.savedFiltersUi.currentFilterLinkLoading}
              onCreateLinkedFilter={props.onCreateLinkedFilter}
            />
          )}
          {isAnyOptionSelected && (
            <SaveButtonWithModal
              disabled={isSaveDisabled}
              onSave={props.onSaveFilter}
              tooltipText={getSaveButtonTooltip(canSaveCurrentFilter)}
            />
          )}
          <Button buttonStyle="primary" onClick={props.onHideFilter}>
            <Button.Label>Hide filters</Button.Label>
          </Button>
        </Inline>
      </Box>
    </Paper>
  );
};

const SaveButtonWithModal = ({
  disabled,
  onSave,
  tooltipText,
}: Readonly<{ disabled: boolean; onSave: (name: string) => void; tooltipText: string }>) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <>
      <Button
        buttonStyle="secondary"
        onClick={() => setIsModalVisible(not)}
        tooltipText={disabled ? tooltipText : undefined}
        disabled={disabled}
      >
        <Button.Icon icon={Icons.PlusSquare} />
        <Button.Label>Save filter</Button.Label>
      </Button>
      {isModalVisible && (
        <SaveFilterModalDialog onClose={() => setIsModalVisible(false)} onSave={onSave} />
      )}
    </>
  );
};

const ShareButtonWithModal = ({
  link,
  loading,
  onCreateLinkedFilter,
}: Readonly<{
  link: string | null;
  loading: boolean;
  onCreateLinkedFilter: () => void;
}>) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const closeModal = () => setIsModalVisible(false);
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <Button buttonStyle="secondary" onClick={() => setIsModalVisible(true)}>
        <Button.Icon icon={Icons.ShareApple} />
        <Button.Label>Share filter</Button.Label>
      </Button>
      {isModalVisible && (
        <ModalDialog
          autoFocusRef={inputRef}
          headline="Share filter"
          isDismissable
          isOpen
          onClose={closeModal}
        >
          <Box maxWidth={64 * gridUnit} width="80vw">
            <ShareFilterLinkForm
              link={link}
              loading={loading}
              onCreateLinkedFilter={onCreateLinkedFilter}
              onCopyCompleted={closeModal}
              ref={inputRef}
            />
          </Box>
        </ModalDialog>
      )}
    </>
  );
};
