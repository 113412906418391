import { useContext } from 'react';
import { useSelector } from '../../../../../_shared/hooks/useSelector.ts';
import { AiTranslationsContext } from '../../../../richText/plugins/ai/actions/TranslateContent/contexts/AiTranslationsContext.tsx';
import { EditingAction } from '../models/EditingAction.ts';
import {
  isAssignPeopleAvailable,
  isChangeDueDateAvailable,
  isChangeNoteAvailable,
  isChangeWorkflowAvailable,
  isChangeWorkflowStepAvailable,
  isPublishAvailable,
  isSetDueDateAvailable,
  isSetNoteAvailable,
} from '../utils/editingActions/availabilityUtils/areAssignmentChangesAvailable.ts';
import {
  isCloseInlineCommentsAvailable,
  isOpenInlineCommentsAvailable,
  isOpenResolvedCommentsAvailable,
} from '../utils/editingActions/availabilityUtils/areCommentsAvailable.ts';
import {
  isCloseComponentsAvailable,
  isOpenComponentsAvailable,
} from '../utils/editingActions/availabilityUtils/areComponentsAvailable.ts';
import {
  isDeleteItemAvailable,
  isPublishPreventsDeletingItemAvailable,
  isWebSpotlightPreventsDeletingItemAvailable,
} from '../utils/editingActions/availabilityUtils/areDeletesAvailable.ts';
import {
  isDuplicateItemAvailable,
  isDuplicateItemWithContentAvailable,
  isDuplicateItemWithoutContentAvailable,
} from '../utils/editingActions/availabilityUtils/areDuplicationsAvailable.ts';
import {
  isDisabledPreviewItemAvailable,
  isPreviewItemAvailable,
  isPreviewItemInMultipleSpacesAvailable,
  isSetupItemPreviewAvailable,
} from '../utils/editingActions/availabilityUtils/arePreviewsAvailable.ts';
import { isChangeCollectionAvailable } from '../utils/editingActions/availabilityUtils/isChangeCollectionAvailable.ts';
import { isCopyFromLanguageAvailable } from '../utils/editingActions/availabilityUtils/isCopyFromLanguageAvailable.ts';
import { isDiscardNewVersionAvailable } from '../utils/editingActions/availabilityUtils/isDiscardNewVersionAvailable.ts';
import { isDiscussionAvailable } from '../utils/editingActions/availabilityUtils/isDiscussionAvailable.ts';
import {
  isVariantTranslationAvailable,
  isVariantTranslationUnavailable,
} from '../utils/editingActions/availabilityUtils/isVariantTranslationAvailable.ts';
import { isViewCodenameAvailable } from '../utils/editingActions/availabilityUtils/isViewCodenameAvailable.ts';
import { useIsLivePreviewFeatureAvailable } from './useIsLivePreviewFeatureAvailable.ts';

type ActionsAvailabilityMap = ReadonlyRecord<EditingAction, boolean>;

const isAlwaysAvailable = () => true;
const isNeverAvailable = () => false;

export const useAvailableContentItemEditingActions = (
  isUrlPatternSetForEditedContentItem: boolean,
): ReadonlyArray<EditingAction> => {
  const { isTranslationsFeatureEnabled } = useContext(AiTranslationsContext);
  const isLivePreviewAvailable = useIsLivePreviewFeatureAvailable(
    isUrlPatternSetForEditedContentItem,
  );

  const actionsAvailabilityMap: ActionsAvailabilityMap = {
    [EditingAction.addTask]: isAlwaysAvailable(),
    [EditingAction.assignPeople]: useSelector(isAssignPeopleAvailable),
    [EditingAction.changeCollection]: useSelector(isChangeCollectionAvailable),
    [EditingAction.changeDueDate]: useSelector(isChangeDueDateAvailable),
    [EditingAction.changeNote]: useSelector(isChangeNoteAvailable),
    [EditingAction.changeStep]: useSelector(isChangeWorkflowStepAvailable),
    [EditingAction.changeWorkflow]: useSelector(isChangeWorkflowAvailable),
    [EditingAction.closeComponents]: useSelector(isCloseComponentsAvailable),
    [EditingAction.closeInlineComments]: useSelector(isCloseInlineCommentsAvailable),
    [EditingAction.compareVersions]: isAlwaysAvailable(),
    [EditingAction.copyFromLanguage]: useSelector(isCopyFromLanguageAvailable),
    [EditingAction.deleteItem]: useSelector(isDeleteItemAvailable),
    [EditingAction.demonstrateDisabledPreviewItem]: useSelector((state) =>
      isDisabledPreviewItemAvailable(state, isLivePreviewAvailable),
    ),
    [EditingAction.demonstratePublishPreventsDeletingItem]: useSelector(
      isPublishPreventsDeletingItemAvailable,
    ),
    [EditingAction.demonstrateWebSpotlightPreventsDeletingItem]: useSelector(
      isWebSpotlightPreventsDeletingItemAvailable,
    ),
    [EditingAction.demonstrateDisabledTranslation]: useSelector((state) =>
      isVariantTranslationUnavailable(state, isTranslationsFeatureEnabled),
    ),
    [EditingAction.discardNewVersion]: useSelector(isDiscardNewVersionAvailable),
    [EditingAction.duplicateItemWithContent]: useSelector(isDuplicateItemWithContentAvailable),
    [EditingAction.duplicateItemWithoutContent]: useSelector(
      isDuplicateItemWithoutContentAvailable,
    ),
    [EditingAction.none]: isNeverAvailable(),
    [EditingAction.openComponents]: useSelector(isOpenComponentsAvailable),
    [EditingAction.openDiscussions]: useSelector(isDiscussionAvailable),
    [EditingAction.openInlineComments]: useSelector(isOpenInlineCommentsAvailable),
    [EditingAction.openResolvedComments]: useSelector(isOpenResolvedCommentsAvailable),
    [EditingAction.previewItem]: useSelector((state) =>
      isPreviewItemAvailable(state, isLivePreviewAvailable),
    ),
    [EditingAction.previewItemInMultipleSpaces]: useSelector((state) =>
      isPreviewItemInMultipleSpacesAvailable(state, isLivePreviewAvailable),
    ),
    [EditingAction.publishItem]: useSelector(isPublishAvailable),
    [EditingAction.setDueDate]: useSelector(isSetDueDateAvailable),
    [EditingAction.setNote]: useSelector(isSetNoteAvailable),
    [EditingAction.setupItemPreview]: useSelector((state) =>
      isSetupItemPreviewAvailable(state, isLivePreviewAvailable),
    ),
    [EditingAction.shareLink]: isAlwaysAvailable(),
    [EditingAction.showDuplicationOptions]: useSelector(isDuplicateItemAvailable),
    [EditingAction.showMainMenu]: isAlwaysAvailable(),
    [EditingAction.translateVariant]: useSelector((state) =>
      isVariantTranslationAvailable(state, isTranslationsFeatureEnabled),
    ),
    [EditingAction.viewCodename]: useSelector(isViewCodenameAvailable),
  };

  const availableActions = Object.keys(actionsAvailabilityMap)
    .filter((action) => actionsAvailabilityMap[action])
    .map((action) => EditingAction[action]);

  return availableActions;
};
