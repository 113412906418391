import { Collection } from '@kontent-ai/utils';
import { useEffect } from 'react';
import { Loader } from '../../../../_shared/components/Loader.tsx';
import { HandleUnsavedFormOnNavigation } from '../../../../_shared/containers/HandleUnsavedFormOnNavigation.tsx';
import { EditorPaperContextProvider } from '../../../../_shared/contexts/EditorPaperContext.tsx';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { useThunkPromise } from '../../../../_shared/hooks/useThunkPromise.ts';
import { LoadingStatus } from '../../../../_shared/models/LoadingStatusEnum.ts';
import { areCollectionsVisibleForAssets } from '../../../../_shared/selectors/contentCollections.ts';
import {
  isAssetFeatureDowngraded,
  isAssetTypeEnabled,
} from '../../../../_shared/selectors/enhancedAssetManagement.ts';
import { selectIntercomUtils } from '../../../../_shared/selectors/selectIntercomUtils.ts';
import { getCurrentProjectPlan } from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { IContentType } from '../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { editorLeft } from '../../shared/actions/sharedContentModelsActions.ts';
import {
  focusFirstInvalidElement,
  validateTypeElement,
} from '../../shared/actions/thunkSharedContentModelsActions.ts';
import { ContentModelNotifications } from '../../shared/containers/ContentModelNotifications.tsx';
import { isTypeValid } from '../../shared/utils/typeValidationUtils.ts';
import { assetTypeInvalidElementShowWarning } from '../actions/assetTypeActions.ts';
import { initAssetTypeEditor, saveEditedAssetType } from '../actions/thunkAssetTypeActions.ts';
import { AssetTypeEditorEmptyState } from '../components/AssetTypeEditorEmptyState.tsx';
import { AssetTypeEditorPane } from '../components/AssetTypeEditorPane.tsx';

export const AssetTypeEditor = () => {
  const [isInitThunkDone] = useThunkPromise(initAssetTypeEditor);

  const areCollectionsVisible = useSelector((state) =>
    areCollectionsVisibleForAssets(state, Collection.getValues(state.data.collections.byId)),
  );
  const editedType = useSelector((state) => state.contentModelsApp.typeEditor.editedType);
  const hasUnsavedChanges = useSelector(
    (state) => state.contentModelsApp.typeEditor.editedTypeIsModified,
  );
  const isBeingSaved = useSelector((state) => state.contentModelsApp.typeEditor.typeIsBeingSaved);

  const allSet = useSelector(
    (state) =>
      isInitThunkDone && state.contentModelsApp.typeEditor.loadingStatus === LoadingStatus.Loaded,
  );

  const validationResults = useSelector(
    (state) => state.contentModelsApp.typeEditor.validationResults,
  );
  const showWarning = useSelector((s) =>
    isAssetFeatureDowngraded(getCurrentProjectPlan(s), s.data.assetTypes.defaultAssetType),
  );
  const shouldShowEmptyState = useSelector(
    (state) =>
      !isAssetTypeEnabled(getCurrentProjectPlan(state), state.data.assetTypes.defaultAssetType),
  );

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(editorLeft());
    };
  }, []);

  const isValid = (editedAssetType: IContentType): boolean => {
    const elementToValidationResults = new Map(
      editedAssetType.typeElements.map((element) => [
        element.elementId,
        dispatch(validateTypeElement(element)),
      ]),
    );
    return isTypeValid(elementToValidationResults);
  };

  const unsavedNavigationHandler = async (
    onSuccess: () => void,
    onFail: () => void,
  ): Promise<void> => {
    try {
      if (isValid(editedType)) {
        await dispatch(saveEditedAssetType(editedType));
        onSuccess();
      } else {
        dispatch(assetTypeInvalidElementShowWarning());
        onFail();
      }
    } catch {
      onFail();
    }
  };

  const saveContentType = async (): Promise<void> => {
    if (isValid(editedType)) {
      await dispatch(saveEditedAssetType(editedType));
    } else {
      dispatch(assetTypeInvalidElementShowWarning());
    }
  };

  const intercomUtils = useSelector(selectIntercomUtils);

  if (shouldShowEmptyState) {
    return <AssetTypeEditorEmptyState onContactUs={intercomUtils.showIntercom} />;
  }

  if (!allSet) {
    return <Loader />;
  }

  return (
    <>
      <HandleUnsavedFormOnNavigation
        hasUnsavedChanges={hasUnsavedChanges}
        isBeingSaved={isBeingSaved}
        onSaveChanges={unsavedNavigationHandler}
      />
      <EditorPaperContextProvider>
        <ContentModelNotifications
          onFocusFirstInvalidElement={() => dispatch(focusFirstInvalidElement())}
          validationResults={validationResults}
        />
        <AssetTypeEditorPane
          areCollectionsVisible={areCollectionsVisible}
          editedContentType={editedType}
          isBeingSaved={isBeingSaved}
          showWarning={showWarning}
          onSaveContentType={saveContentType}
        />
      </EditorPaperContextProvider>
    </>
  );
};
