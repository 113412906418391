import { Stack } from '@kontent-ai/component-library/Stack';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { ReactNode } from 'react';
import { FeatureActivationDialogProps } from '../hooks/useFeatureActivationDialog.ts';
import { FeatureActivationDialog } from './FeatureActivationDialog.tsx';
import { FeatureDeactivationDialog } from './FeatureDeactivationDialog.tsx';
import { InnovationLabGenericDeactivationWarning } from './InnovationLabGenericDeactivationWarning.tsx';

type Props = FeatureActivationDialogProps & {
  readonly dialogFeatureName: string;
  readonly isAsyncOperationInProgress: boolean;
  readonly renderActivationDialogBody: () => ReactNode;
};

export const InnovationLabFeatureDialogs = ({
  dialogDisplayed,
  dialogFeatureName,
  isAsyncOperationInProgress,
  onDialogActionClick,
  onDialogClose,
  renderActivationDialogBody,
}: Props) => (
  <>
    <FeatureActivationDialog
      headline={`Activate ${dialogFeatureName}`}
      isOpen={dialogDisplayed === 'activation'}
      isActivating={isAsyncOperationInProgress}
      onClose={onDialogClose}
      onActivate={onDialogActionClick}
    >
      <Stack spacing={Spacing.M}>{renderActivationDialogBody()}</Stack>
    </FeatureActivationDialog>
    <FeatureDeactivationDialog
      headline={`Deactivate ${dialogFeatureName}`}
      isOpen={dialogDisplayed === 'deactivation'}
      isDeactivating={isAsyncOperationInProgress}
      onClose={onDialogClose}
      onDeactivate={onDialogActionClick}
    >
      <InnovationLabGenericDeactivationWarning featureName={dialogFeatureName} />
    </FeatureDeactivationDialog>
  </>
);
