import { assert } from '@kontent-ai/utils';
import { ThunkFunction } from '../../../../../@types/Dispatcher.type.ts';
import { IUserInfo } from '../../../../models/UserInfo.ts';
import { getWorkflow } from '../../../../selectors/workflowSelectors.ts';
import { getHiddenAssignedContributors } from '../../../../utils/permissions/getHiddenAssignedContributors.ts';
import { allRolesCanDoSomethingInStep } from '../../../../utils/permissions/roleInWorkflowStepUtils.ts';
import { selectedContributorsChanged } from '../changeWorkflowStepModalActions.ts';

const emptyContributors: ReadonlyArray<IUserInfo> = [];

export const changeSelectedContributors =
  (selectedAssignees: ReadonlyArray<IUserInfo>): ThunkFunction =>
  (dispatch, getState) => {
    const state = getState();
    const { showAllContributors, workflowStep } = state.contentApp.changeWorkflowStepModalData;

    const currentWorkflow = getWorkflow(state, workflowStep.workflowId);

    assert(
      currentWorkflow,
      () =>
        `changeSelectedContributors.ts: Did not find workflow with id ${workflowStep.workflowId}.`,
    );

    const allContributorsAreShown =
      allRolesCanDoSomethingInStep(workflowStep.id, currentWorkflow) || showAllContributors;
    const hiddenAssignees = allContributorsAreShown
      ? emptyContributors
      : getHiddenAssignedContributors(state);

    const assignees = hiddenAssignees.concat(selectedAssignees);

    dispatch(selectedContributorsChanged(new Set(assignees)));
  };
