import React from 'react';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { UrlSlugTypeElement as UrlSlugTypeElementComponent } from '../../shared/components/typeElements/individualTypeElements/UrlSlugTypeElement.tsx';
import { IUrlSlugTypeElementData } from '../../shared/models/elements/UrlSlugTypeElementData.ts';
import {
  getAllTextElementsSortedByName,
  getAutogenerateFromOptions,
  getUrlSlugSelectedOption,
} from '../../shared/selectors/contentTypeElementSelector.ts';
import { ITypeElementOwnProps } from '../../shared/types/ITypeElementProps.type.ts';
import { UrlSlugTypeElementValidationResult } from '../../shared/utils/typeElementValidators/types/UrlSlugTypeElementValidationResult.type.ts';

export const UrlSlugTypeElement: React.FC<
  ITypeElementOwnProps<IUrlSlugTypeElementData, UrlSlugTypeElementValidationResult>
> = (props) => {
  const editedType = useSelector((s) => s.contentModelsApp.typeEditor.editedType);
  const snippets = useSelector((s) => s.data.snippets);

  const showDefaultOption = props.typeElementData._canBeGeneratedFromDeprecatedItemName;
  const textElements = getAllTextElementsSortedByName(editedType.typeElements, snippets);
  const selectedOption = getUrlSlugSelectedOption(props.typeElementData, textElements, snippets);
  const autogenerateFromOptions = getAutogenerateFromOptions(textElements, showDefaultOption);

  return (
    <UrlSlugTypeElementComponent
      {...props}
      autogenerateFromOptions={autogenerateFromOptions}
      selectedOption={selectedOption}
    />
  );
};
