import React from 'react';
import { useLocation } from 'react-router';
import {
  EnvironmentRouteParams,
  SubscriptionEnvironmentSettingsRouteParams,
} from '../../../../_shared/constants/routePaths.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { isLegacyWebhookConfigurationEnabled as isLegacyWebhookConfigurationEnabledSelector } from '../../../../_shared/selectors/isLegacyWebhookConfigurationEnabled.ts';
import {
  getCurrentProject,
  getCurrentProjectPlan,
  getProjectInfo,
} from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import {
  DataUiElement,
  getDataUiElementAttribute,
} from '../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { buildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { projectIdStorage } from '../../../../localStorages/projectIdStorage.ts';
import { isWebSpotlightEnabled as isWebSpotlightEnabledSelector } from '../../../webSpotlight/selectors/webSpotlightSelectors.ts';
import {
  EnvironmentSettingsMenuStructureFeatureAvailabilityItem,
  useGetEnvironmentSettingsMenuFeatureAvailability,
} from '../../hooks/useGetEnvironmentSettingsMenuFeatureAvailability.ts';
import { convertAppNameToId } from '../../utils/convertAppNameToId.ts';
import { environmentSettingsMenuStructure } from '../../utils/environmentSettingsMenuStructure.tsx';
import { getFilteredOutMenuStructure } from '../../utils/environmentSettingsMenuStructureUtils.tsx';
import {
  EnvironmentSettingsMenu as EnvironmentSettingsMenuComponent,
  IFirstLevelEnvironmentSettingsTabItemInfo,
} from '../components/EnvironmentSettingsMenu.tsx';

const targetWithReplacedUrlIdentifiers = (
  projectId: string,
  routePath: string,
  subscriptionId: string | null,
) =>
  subscriptionId
    ? buildPath<SubscriptionEnvironmentSettingsRouteParams>(routePath, {
        projectId,
        subscriptionId,
      })
    : buildPath<EnvironmentRouteParams>(routePath, { projectId });

const convertStaticMenuStructureToVerticalTabsMenuStructure = (
  state: IStore,
  environmentSettingsMenuFeatureAvailability: ReadonlyArray<EnvironmentSettingsMenuStructureFeatureAvailabilityItem>,
): ReadonlyArray<IFirstLevelEnvironmentSettingsTabItemInfo> => {
  const currentEnvironment = getCurrentProject(state);

  const filteredMenu = getFilteredOutMenuStructure(
    currentEnvironment,
    environmentSettingsMenuStructure(),
    environmentSettingsMenuFeatureAvailability,
  );

  return filteredMenu.map(
    (item): IFirstLevelEnvironmentSettingsTabItemInfo => ({
      id: convertAppNameToId(item.text),
      isDisabled: item.isDisabled?.(state) ?? false,
      label: item.text,
      renderAuxElements: item.renderAuxElements,
      renderCustomItem:
        !!item.customRender && (item.shouldCustomRender?.(state) ?? true)
          ? item.customRender
          : undefined,
      url: targetWithReplacedUrlIdentifiers(
        currentEnvironment.projectId,
        item.routePath,
        currentEnvironment.subscriptionId,
      ),
      ...item.dataAttributes,
      ...getDataUiElementAttribute(DataUiElement.SettingGroupItem),
    }),
  );
};

export const EnvironmentSettingsMenu: React.FC = () => {
  const location = useLocation();
  const currentProjectPlan = useSelector((state) => getCurrentProjectPlan(state));
  const projectInfo = useSelector((state) =>
    getProjectInfo(state, getCurrentProject(state).projectId ?? String(projectIdStorage.load())),
  );
  const areLegacyWebhooksEnabled = useSelector(isLegacyWebhookConfigurationEnabledSelector);
  const isWebSpotlightEnabled = useSelector(isWebSpotlightEnabledSelector);
  const environmentSettingsMenuFeatureAvailability =
    useGetEnvironmentSettingsMenuFeatureAvailability(
      projectInfo,
      currentProjectPlan,
      areLegacyWebhooksEnabled,
      isWebSpotlightEnabled,
    );
  const settingsMenuStructure = useSelector((s) =>
    convertStaticMenuStructureToVerticalTabsMenuStructure(
      s,
      environmentSettingsMenuFeatureAvailability,
    ),
  );
  return (
    <EnvironmentSettingsMenuComponent pathname={location.pathname} items={settingsMenuStructure} />
  );
};
