import { InputState } from '@kontent-ai/component-library/Input';
import { MultiSelect } from '@kontent-ai/component-library/MultiSelect';
import { emptySelectionItem } from '@kontent-ai/component-library/Selects';
import { SingleSelect } from '@kontent-ai/component-library/SingleSelect';
import React from 'react';
import { MultipleChoiceMode } from '../../../../../../../_shared/models/MultipleChoiceModeEnum.ts';
import { IMultipleChoiceOptionData } from '../../../../../../../_shared/models/MultipleChoiceOption.type.ts';
import {
  DataUiCollection,
  DataUiInput,
  getDataUiCollectionAttribute,
  getDataUiInputAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';

const disabledTooltipText = 'Create some options first.';

const noneOption: IMultipleChoiceOptionData = {
  ...emptySelectionItem,
  codename: '',
};

type Props = {
  readonly onChange: (selectedOptionIds: ReadonlyArray<Uuid>) => void;
  readonly options: ReadonlyArray<IMultipleChoiceOptionData>;
  readonly optionsMode: MultipleChoiceMode;
  readonly selectedOptionIds: ReadonlyArray<Uuid>;
};

export const MultipleChoiceDefaultValueSelector: React.FC<Props> = ({
  onChange,
  options,
  optionsMode,
  selectedOptionIds,
}) => {
  const isDisabled = !options.length;
  const tooltipText = isDisabled ? disabledTooltipText : undefined;

  if (optionsMode === MultipleChoiceMode.Multiple) {
    return (
      <MultiSelect
        aria-label="Select a default option"
        inputState={isDisabled ? InputState.Disabled : InputState.Default}
        items={options}
        onSelectionChange={(selectedItemIds) => onChange([...(selectedItemIds ?? [])])}
        placeholder="Set default options"
        selectedItemIds={selectedOptionIds}
        tooltipText={tooltipText}
        {...getDataUiInputAttribute(DataUiInput.DefaultValue)}
        {...getDataUiCollectionAttribute(DataUiCollection.MultipleChoiceOptions)}
      />
    );
  }

  const singleSelectOptions = [noneOption].concat(options);

  return (
    <SingleSelect
      aria-label="Set default option"
      inputState={isDisabled ? InputState.Disabled : InputState.Default}
      items={singleSelectOptions}
      onSelectionChange={(selectedOptionId) =>
        onChange(selectedOptionId === null ? [] : [selectedOptionId])
      }
      placeholder="Set default option"
      selectedItemId={selectedOptionIds.length ? selectedOptionIds[0] : null}
      tooltipText={tooltipText}
      {...getDataUiInputAttribute(DataUiInput.DefaultValue)}
    />
  );
};

MultipleChoiceDefaultValueSelector.displayName = 'MultipleChoiceDefaultValueSelector';
