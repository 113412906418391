import React from 'react';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { isAssetUncategorized } from '../utils/assetTaxonomyUtils.ts';
import { AssetDetail } from './AssetDetail.tsx';

export const AssetEditorDetail: React.FC = () => {
  const editedAssetId = useSelector((state) => state.assetLibraryApp.assetUiEditor.editedAsset?.id);
  const taxonomyGroups = useSelector((state) => state.data.taxonomyGroups.byId);
  const isUncategorized = useSelector((state) => {
    const editedAsset = state.assetLibraryApp.assetUiEditor.editedAsset;
    return (
      editedAsset &&
      isAssetUncategorized(editedAsset, state.data.assetTypes.defaultAssetType, taxonomyGroups)
    );
  });

  return <AssetDetail assetId={editedAssetId} isUncategorized={isUncategorized} />;
};

AssetEditorDetail.displayName = 'AssetEditorDetail';
