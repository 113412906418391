import React from 'react';
import { Sidebar } from '../../../../../../_shared/components/Sidebar/Sidebar.tsx';
import {
  DataUiCollection,
  DataUiElement,
  getDataUiCollectionAttribute,
  getDataUiElementAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { ICommentThread } from '../../../../models/comments/CommentThreads.ts';
import { Comments } from './Comments.tsx';

export interface IResolvedCommentsSidebarStateProps {
  readonly isOpened: boolean;
  readonly commentThreads: ReadonlyArray<ICommentThread>;
}

export interface IResolvedCommentsSidebarDispatchProps {
  readonly onClose: () => void;
}

export type IResolvedCommentsSidebarProps = IResolvedCommentsSidebarStateProps &
  IResolvedCommentsSidebarDispatchProps;

export const ResolvedCommentsSidebar: React.FC<IResolvedCommentsSidebarProps> = (props) => (
  <Sidebar
    dataUiAttribute={getDataUiElementAttribute(DataUiElement.ResolvedCommentsSidebar)}
    isVisible={props.isOpened}
    onClose={props.onClose}
    titleElement="Resolved comments"
  >
    <div
      className="sidebar__section"
      {...getDataUiCollectionAttribute(DataUiCollection.CommentThreads)}
    >
      <Comments threads={props.commentThreads} loaderText="Loading resolved comments" />
    </div>
  </Sidebar>
);

ResolvedCommentsSidebar.displayName = 'ResolvedCommentsSidebar';
