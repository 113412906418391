import { memoize } from '@kontent-ai/memoization';
import { createCompare, naturally, notNull } from '@kontent-ai/utils';
import Immutable from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { IContentTypeSnippet } from '../../../../data/models/contentModelsApp/snippets/ContentTypeSnippet.ts';
import {
  ContentTypeSnippetTypeElement as ContentTypeSnippetTypeElementComponent,
  IContentTypeSnippetTypeElementDataProps,
} from '../../shared/components/typeElements/individualTypeElements/ContentTypeSnippetTypeElement.tsx';
import { IContentTypeSnippetTypeElementData } from '../../shared/models/elements/ContentTypeSnippetTypeElementData.ts';
import { ITypeElementOwnProps } from '../../shared/types/ITypeElementProps.type.ts';
import { isSnippetTypeElement } from '../../shared/types/typeElementTypeGuards.ts';
import { ContentTypeSnippetTypeElementValidationResult } from '../../shared/utils/typeElementValidators/types/ContentTypeSnippetTypeElementValidationResult.type.ts';
import { contentTypeSnippetElementGeneratesUrlSlug } from '../../shared/utils/urlSlugUtils.ts';

const getContentTypeSnippets = (
  snippetsById: Immutable.Map<Uuid, IContentTypeSnippet>,
  editedContentTypeSnippets: ReadonlyArray<IContentTypeSnippetTypeElementData>,
  selectedSnippetId?: Uuid,
): ReadonlyArray<IContentTypeSnippet> => {
  const editedSnippetElementsIds = editedContentTypeSnippets
    .map((element) => element.snippetId)
    .filter(notNull);
  return snippetsById
    .toArray()
    .filter(
      (snippet) =>
        !editedSnippetElementsIds.includes(snippet.id) || snippet.id === selectedSnippetId,
    )
    .sort(
      createCompare({
        compare: naturally,
        select: (t) => t.name,
      }),
    );
};
const getContentTypeSnippetsMemoized = memoize.weak(getContentTypeSnippets);

const getEditedTypeSnippets = (state: IStore): ReadonlyArray<IContentTypeSnippetTypeElementData> =>
  state.contentModelsApp.typeEditor.editedType.typeElements.filter(isSnippetTypeElement);

const memoizeEditedTypeSnippets = memoize.weak(
  (...contentTypeElements: ReadonlyArray<IContentTypeSnippetTypeElementData>) =>
    contentTypeElements,
);

const mapStateToProps = (
  state: IStore,
  {
    typeElementData,
    validationResult,
  }: ITypeElementOwnProps<
    IContentTypeSnippetTypeElementData,
    ContentTypeSnippetTypeElementValidationResult
  >,
): IContentTypeSnippetTypeElementDataProps => {
  const typeElements = state.contentModelsApp.typeEditor.editedType.typeElements;
  const generatesUrlSlug = contentTypeSnippetElementGeneratesUrlSlug(
    typeElements,
    state.data.snippets.byId,
    typeElementData.snippetId,
  );

  const editedContentTypeSnippets = memoizeEditedTypeSnippets(...getEditedTypeSnippets(state));
  const possibleContentTypeSnippets = getContentTypeSnippetsMemoized(
    state.data.snippets.byId,
    editedContentTypeSnippets,
    typeElementData.snippetId || undefined,
  );

  return {
    possibleContentTypeSnippets,
    generatesUrlSlug,
    typeElementData,
    validationResult,
  };
};

export const ContentTypeSnippetTypeElement: React.ComponentType<
  React.PropsWithChildren<
    ITypeElementOwnProps<
      IContentTypeSnippetTypeElementData,
      ContentTypeSnippetTypeElementValidationResult
    >
  >
> = connect(mapStateToProps)(ContentTypeSnippetTypeElementComponent);
