import { Dispatch, ThunkPromise } from '../../../../../@types/Dispatcher.type.ts';
import { showSuccessNotification } from '../../../../../_shared/actions/sharedActions.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { ISaveSitemap } from '../../models/ISaveSitemapNode.type.ts';
import { rollbackSitemap } from '../sitemapActions.ts';

interface IDeleteNodeDependencies {
  readonly updateSitemap: ISaveSitemap;
}

export const undoDeleteNodeCreator = (deps: IDeleteNodeDependencies) => (): ThunkPromise => {
  return async (dispatch: Dispatch, getState: () => IStore): Promise<void> => {
    const newSitemap = getState().sitemapApp.sitemap;
    const originalSitemap = getState().sitemapApp.originalSitemap;

    if (!originalSitemap || !newSitemap) {
      return Promise.resolve();
    }

    try {
      await dispatch(deps.updateSitemap(originalSitemap));
      dispatch(showSuccessNotification('The deletion has been undone.'));
    } catch (error) {
      dispatch(rollbackSitemap(newSitemap));
      throw error;
    }
  };
};
