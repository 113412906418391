import { ComponentProps, useCallback, useEffect } from 'react';
import { trackUserEvent } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { CommentAction } from '../../../../../../_shared/models/events/CommentEventData.type.ts';
import { getEditedContentItemVariantId } from '../../../../../../_shared/selectors/getEditedContentItemVariant.ts';
import { getCurrentProjectId } from '../../../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { NavigatedFromData } from '../../../../../contentInventory/content/stores/IContentAppStoreState.ts';
import { getCommentThreadElement } from '../../../../utils/commentUtils.ts';
import { hideAllComments } from '../../actions/contentItemEditingActions.ts';
import {
  focusCommentThread,
  resolveCommentThread,
} from '../../actions/thunkContentItemEditingActions.ts';
import { InlineCommentThreadHeader as InlineCommentThreadHeaderComponent } from '../../components/comments/InlineCommentThreadHeader.tsx';

type Props = Omit<
  ComponentProps<typeof InlineCommentThreadHeaderComponent>,
  | 'currentProjectId'
  | 'editedItemVariantId'
  | 'isFocused'
  | 'onNavigate'
  | 'onHideComments'
  | 'onLinkCopied'
  | 'onResolve'
>;

export const InlineCommentThreadHeader = (props: Props) => {
  const currentProjectId = useSelector(getCurrentProjectId);
  const editedContentItemVariantId = useSelector(getEditedContentItemVariantId);

  const dispatch = useDispatch();
  const onNavigate = useCallback(
    (threadId: Uuid, navigatedFromData: NavigatedFromData) =>
      dispatch(focusCommentThread(threadId, navigatedFromData)),
    [],
  );
  const onHideComments = useCallback(() => dispatch(hideAllComments()), []);
  const onLinkCopied = useCallback(
    () => dispatch(trackUserEvent(TrackedEvent.Comments, { action: CommentAction.CopyLink })),
    [],
  );
  const onResolve = useCallback(
    () => dispatch(resolveCommentThread(props.threadId, true, true)),
    [props.threadId],
  );

  const isFocused = useSelector(
    (s) => s.contentApp.editedContentItemVariantComments.focusedCommentThreadId === props.threadId,
  );

  // If the comment focus came from navigation, we autofocus the thread element to make sure
  // the screen reader announces it properly and tab navigation can continue in the newly focused comment
  const autoFocus = useSelector(
    (s) =>
      s.contentApp.editedContentItemVariantComments.focusedCommentThreadId === props.threadId &&
      s.contentApp.editedContentItemVariantComments.navigatedFrom,
  );

  useEffect(() => {
    if (autoFocus) {
      getCommentThreadElement(props.threadId)?.focus({ preventScroll: true });
    }
  }, [autoFocus, props.threadId]);

  return (
    <InlineCommentThreadHeaderComponent
      currentProjectId={currentProjectId}
      editedItemVariantId={editedContentItemVariantId}
      isFocused={isFocused}
      onNavigate={onNavigate}
      onHideComments={onHideComments}
      onLinkCopied={onLinkCopied}
      onResolve={onResolve}
      {...props}
    />
  );
};
