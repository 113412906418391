import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../@types/Dispatcher.type.ts';
import { trackUserEvent } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../_shared/constants/trackedEvent.ts';
import { IStore } from '../../../../_shared/stores/IStore.type.ts';
import { hideRelationsRootSelector } from '../actions/relationsActions.ts';
import { setRelationsRoot } from '../actions/thunkRelationsActions.ts';
import {
  IRelationsListingDispatchProps,
  IRelationsListingStateProps,
  RelationsListing as RelationsListingComponent,
} from '../components/RelationsListing.tsx';

const mapStateToProps = (state: IStore): IRelationsListingStateProps => {
  const {
    relationsApp: { itemSelectorVisible, selectedRootId },
  } = state;

  return {
    itemSelectorVisible,
    rootId: selectedRootId,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IRelationsListingDispatchProps => ({
  onRootChange: (rootId: Uuid) => {
    dispatch(setRelationsRoot({ rootId }));
    dispatch(trackUserEvent(TrackedEvent.RelationsTabRootItemChanged));
  },
  onSelectorClose: () => dispatch(hideRelationsRootSelector()),
});

export const RelationsListing: React.ComponentType = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RelationsListingComponent);
