import React, { useId } from 'react';
import { TextField } from '../../../../../../../_shared/uiComponents/TextField/TextField.tsx';
import { DataUiElement } from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { TypeElementConfigurationSection } from '../TypeElementConfigurationSection.tsx';

type Props = {
  readonly allowMultiline?: boolean;
  readonly isValid: boolean;
  readonly maxLength?: number;
  readonly onChange: (event: any) => void;
  readonly placeholder: string;
  readonly title: string;
  readonly uiElement?: DataUiElement;
  readonly value?: string;
};

export const TextInput: React.FC<Props> = ({
  allowMultiline,
  isValid,
  maxLength,
  onChange,
  placeholder,
  title,
  uiElement,
  value,
}) => {
  const id = useId();

  return (
    <TypeElementConfigurationSection
      title={title}
      uiElement={uiElement}
      className="content-element__config-section-control--full-width"
      labelFor={id}
    >
      <TextField
        isMultiline={allowMultiline}
        hasError={!isValid}
        value={value || ''}
        onChange={onChange}
        placeholder={placeholder}
        inputId={id}
        maxLength={maxLength}
      />
    </TypeElementConfigurationSection>
  );
};

TextInput.displayName = 'TextInput';
