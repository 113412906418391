import { RouterLinkProps } from '@kontent-ai/component-library/Anchor';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Paper } from '@kontent-ai/component-library/Paper';
import { useOurFocusRing } from '@kontent-ai/component-library/hooks';
import { SrOnly } from '@kontent-ai/component-library/styles';
import { BaseColor, IconSize } from '@kontent-ai/component-library/tokens';
import { useFocusWithin, useHover } from '@react-aria/interactions';
import React, { useEffect, useRef, useState } from 'react';
import { EventKeys } from '../../../app/_shared/constants/eventKeys.ts';
import {
  AssetTileInfoBar,
  IAssetTileInfoBarPublicProps,
} from './AssetTileInfoBar/AssetTileInfoBar.tsx';
import { StyledAssetTile } from './components/StyledAssetTile.tsx';
import { StyledBackground } from './components/StyledBackground.tsx';
import { StyledBorders } from './components/StyledBorders.tsx';
import { StyledDraggingPlaceholder } from './components/StyledDraggingPlaceholder.tsx';
import { StyledFocusHelper } from './components/StyledFocusHelper.tsx';
import { StyledImage } from './components/StyledImage.tsx';
import {
  StyledImageWrapper,
  StyledNoPreviewImageWrapper,
} from './components/StyledImageWrapper.tsx';
import { StyledInteractionHelper } from './components/StyledInteractionHelper.tsx';
import { StyledPlayButton } from './components/StyledPlayButton.tsx';
import { StyledPlayIconWrapper } from './components/StyledPlayIconWrapper.tsx';
import { StyledRouterLink } from './components/StyledRouterLink.tsx';
import { StyledShadowAnimationHelper } from './components/StyledShadowAnimationHelper.tsx';
import { AssetTileType } from './enums/assetTileTypeEnum.ts';
import { AssetPreview } from './types/assetPreview.type.ts';

const playAssetTileForMilliseconds = 5000;

export type RouterLinkData = Omit<RouterLinkProps, 'onClick'>;

export interface IBaseAssetTilePublicProps extends IAssetTileInfoBarPublicProps {
  readonly isAlert?: boolean;
  readonly isDragging?: boolean;
  readonly onClick?: (
    event: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>,
  ) => void;
}

export interface IBaseAssetTileProps extends IBaseAssetTilePublicProps {
  readonly renderHeader: (isFocused: boolean, isHovered: boolean) => React.ReactNode;
  readonly routerLinkData?: RouterLinkData;
  readonly tileType: AssetTileType;
}

let stopAssetPlayTimeoutId: number | null = null;

export const BaseAssetTile = React.forwardRef<HTMLDivElement, IBaseAssetTileProps>(
  (
    {
      assetData,
      isAlert,
      isDragging,
      isSelected,
      onClick,
      readonlyState,
      renderHeader,
      routerLinkData,
      tileType,
      validation,
      ...otherProps
    },
    forwardedRef,
  ) => {
    const { isFocusVisible, focusProps } = useOurFocusRing();

    const [isFocusedPlayButton, setIsFocusedPlayButton] = useState(false);
    const { focusWithinProps } = useFocusWithin({
      onFocusWithinChange: (isFocusWithin) => setIsFocusedPlayButton(isFocusWithin),
    });

    const playButtonRef = useRef<HTMLButtonElement>(null);

    const { isHovered, hoverProps } = useHover({});

    const assetPreview = assetData.assetPreview;
    const [assetIsPlaying, setAssetIsPlaying] = useState(false);
    const isAssetPlayable = !!assetPreview?.assetPlayUrl;

    useEffect(() => {
      return () => {
        if (stopAssetPlayTimeoutId) {
          clearTimeout(stopAssetPlayTimeoutId);
        }
      };
    }, []);

    const getPreviewUrl = (assetPreviewData: AssetPreview) => {
      if (isAssetPlayable && assetPreviewData.assetPlayUrl && assetIsPlaying) {
        return assetPreviewData.assetPlayUrl;
      }
      return assetPreviewData.assetPreviewUrl;
    };

    const handleTileClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (onClick) {
        e.persist();
        onClick(e);
      }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
      if (e.key === EventKeys.Enter && onClick) {
        e.persist();
        onClick(e);
      }
    };

    const handlePlayButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      playButtonRef.current?.blur();

      if (assetIsPlaying) {
        setAssetIsPlaying(false);
        if (stopAssetPlayTimeoutId) {
          clearTimeout(stopAssetPlayTimeoutId);
        }
      } else {
        setAssetIsPlaying(true);
        stopAssetPlayTimeoutId = window.setTimeout(() => {
          setAssetIsPlaying(false);
        }, playAssetTileForMilliseconds);
      }
    };

    return (
      <StyledDraggingPlaceholder isDragging={isDragging}>
        <StyledBorders
          isAlert={isAlert}
          isDragging={isDragging}
          readonlyState={readonlyState}
          ref={forwardedRef}
          {...hoverProps}
          {...otherProps}
        >
          {assetData && routerLinkData ? (
            <StyledRouterLink onClick={handleTileClick} {...routerLinkData} {...focusProps}>
              <SrOnly>{`Go to the detail of the asset ${assetData.assetTitle}`}</SrOnly>
            </StyledRouterLink>
          ) : (
            <StyledInteractionHelper
              onClick={handleTileClick}
              onKeyDown={handleKeyDown}
              tabIndex={0}
              {...focusProps}
            />
          )}
          <StyledFocusHelper isAlert={isAlert} isFocusVisible={isFocusVisible} />
          <StyledShadowAnimationHelper isHovered={isHovered} />
          <StyledBackground as={readonlyState ? undefined : Paper} readonlyState={readonlyState}>
            <StyledAssetTile>
              {assetPreview ? (
                <StyledImageWrapper>
                  <StyledImage
                    assetDimension={assetPreview.assetDimension}
                    title={assetPreview.assetAlt}
                    url={getPreviewUrl(assetPreview)}
                  />
                </StyledImageWrapper>
              ) : (
                <StyledNoPreviewImageWrapper>
                  <Icons.Doc color={BaseColor.OceanBlue40} size={IconSize.XXXL} />
                </StyledNoPreviewImageWrapper>
              )}
              {renderHeader(isFocusVisible, isHovered)}
              {isAssetPlayable && (
                <StyledPlayIconWrapper {...focusWithinProps}>
                  <StyledPlayButton
                    aria-label="Play media"
                    buttonStyle="primary-inverse"
                    iconName="Placeholder"
                    isVisible={
                      !assetIsPlaying || isHovered || isFocusVisible || isFocusedPlayButton
                    }
                    onClick={handlePlayButtonClick}
                    ref={playButtonRef}
                    size="large"
                    tooltipPlacement="top"
                    tooltipText={`${assetIsPlaying ? 'Stop' : 'Play'} the asset`}
                    css={`
                  z-index: 2;
                `}
                  />
                </StyledPlayIconWrapper>
              )}
              <AssetTileInfoBar
                assetData={assetData}
                isAssetPlaying={assetIsPlaying}
                isSelected={isSelected}
                isTileFocused={isFocusVisible}
                isTileHovered={isHovered}
                readonlyState={readonlyState}
                tileType={tileType}
                validation={validation}
              />
            </StyledAssetTile>
          </StyledBackground>
        </StyledBorders>
      </StyledDraggingPlaceholder>
    );
  },
);

BaseAssetTile.displayName = 'BaseAssetTile';
