import { RefinedNavigation } from '@kontent-ai/component-library/RefinedNavigation';
import { FC, useContext } from 'react';
import { AppNames } from '../../constants/applicationNames.ts';
import { ActiveProjectsRoute, ArchivedProjectsRoute } from '../../constants/routePaths.ts';
import {
  MainNavigationContext,
  NavigationBarExpandedState,
} from '../../contexts/MainNavigationContext.tsx';
import {
  DataUiAppName,
  DataUiElement,
  getDataUiElementAttribute,
  getDataUiObjectNameAttribute,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

export const ProjectsMenu: FC = () => {
  const { navigationBarExpandedState } = useContext(MainNavigationContext);

  return (
    <>
      <RefinedNavigation
        isVisuallyHidden={navigationBarExpandedState !== NavigationBarExpandedState.Minimized}
      >
        <RefinedNavigation.Icon iconName="Layers" />
      </RefinedNavigation>
      <RefinedNavigation
        isVisuallyHidden={navigationBarExpandedState === NavigationBarExpandedState.Minimized}
      >
        <RefinedNavigation.Toggle
          iconName="Layers"
          label={AppNames.Projects}
          toggleDisabled
          isExpanded
        >
          <RefinedNavigation.SubLink
            to={ActiveProjectsRoute}
            isActive={location.pathname === ActiveProjectsRoute}
            label="Active"
            {...getDataUiObjectNameAttribute(DataUiAppName.ActiveProjects)}
            {...getDataUiElementAttribute(DataUiElement.NavigationTab)}
          />

          <RefinedNavigation.SubLink
            to={ArchivedProjectsRoute}
            isActive={location.pathname === ArchivedProjectsRoute}
            label="Archived"
            {...getDataUiObjectNameAttribute(DataUiAppName.ArchivedProjects)}
            {...getDataUiElementAttribute(DataUiElement.NavigationTab)}
          />
        </RefinedNavigation.Toggle>
      </RefinedNavigation>
    </>
  );
};
