import { ThunkFunction } from '../../../../../../@types/Dispatcher.type.ts';
import { trackUserEvent } from '../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../_shared/constants/trackedEvent.ts';
import { CommentAction } from '../../../../../../_shared/models/events/CommentEventData.type.ts';
import { ICommentThreadItem } from '../../../../models/comments/CommentThreadItem.ts';
import { ICommentThread } from '../../../../models/comments/CommentThreads.ts';
import { isSuggestion } from '../../../../models/comments/Suggestion.ts';
import { isThreadResolvedWithoutUndo } from '../../../../utils/commentUtils.ts';
import { suggestionApprovalInitiated } from '../contentItemEditingActions.ts';

export const createApproveSuggestionAction =
  () =>
  (commentThread: ICommentThread, commentThreadItem: ICommentThreadItem): ThunkFunction =>
  (dispatch, getState) => {
    const {
      contentApp: { editedContentItemVariant, editedContentItemVariantElements },
    } = getState();

    if (
      !editedContentItemVariant ||
      !isSuggestion(commentThreadItem) ||
      isThreadResolvedWithoutUndo(commentThread)
    ) {
      return;
    }

    dispatch(
      suggestionApprovalInitiated({
        commentThreadId: commentThread.id,
        suggestionId: commentThreadItem.id,
        suggestedText: commentThreadItem.suggestedText,
      }),
    );

    const commentedElement = editedContentItemVariantElements.find(
      (element) => element.elementId === commentThread.elementId,
    );

    dispatch(
      trackUserEvent(TrackedEvent.Comments, {
        action: CommentAction.SuggestionApproved,
        'element-type': commentedElement?.type, // Elements in nested components are not directly in editedContentItemVariantElements state but in specific element
      }),
    );
  };
