import React from 'react';
import { DragSource } from '../../../../../_shared/components/DragDrop/DragSource.tsx';
import { DndTypes } from '../../../../../_shared/constants/dndTypes.ts';
import { INodeBarDispatchProps, INodeBarStateProps, NodeBar } from './NodeBar.tsx';

export interface IDraggableNodeBarDispatchProps extends INodeBarDispatchProps {
  readonly onNodeDropped: () => void;
  readonly onNodePickedUp: () => void;
}

export type IDraggableNodeBarProps = INodeBarStateProps & IDraggableNodeBarDispatchProps;

export const DraggableNodeBar: React.FC<IDraggableNodeBarProps> = ({
  onNodeDropped,
  onNodePickedUp,
  ...nodeBarProps
}) => {
  const nodeId = nodeBarProps.nodeData.id;

  return (
    <DragSource
      parentId=""
      sourceId={nodeId}
      onDragStart={onNodePickedUp}
      onDragEnd={onNodeDropped}
      renderDraggable={(connectDragSource, isDragging) => (
        <NodeBar {...nodeBarProps} connectDragSource={connectDragSource} isDragging={isDragging} />
      )}
      renderPreview={() => (
        <NodeBar {...nodeBarProps} isBeingDragged={false} isCollapsed isDragging={false} />
      )}
      type={DndTypes.Sitemap_Node_Move}
    />
  );
};

DraggableNodeBar.displayName = 'DraggableSitemapNodeBar';
