import { Collection } from '@kontent-ai/utils';
import Immutable from 'immutable';
import React, { useMemo, useState } from 'react';
import { DragMoveHandler } from '../../../../../../_shared/components/DragDrop/dragDrop.type.ts';
import { ModalViewer } from '../../../../../../_shared/components/Modal/ModalViewer.tsx';
import { ModalViewerPosition } from '../../../../../../_shared/components/Modal/ModalViewerPosition.ts';
import { useSelector } from '../../../../../../_shared/hooks/useSelector.ts';
import { areCollectionsVisibleForAssets } from '../../../../../../_shared/selectors/contentCollections.ts';
import { distinctFilter } from '../../../../../../_shared/utils/arrayUtils/arrayUtils.ts';
import { moveItem } from '../../../../../../_shared/utils/dragDrop/dragDropUtils.ts';
import { Capability } from '../../../../../../_shared/utils/permissions/capability.ts';
import { currentUserHasCapabilities } from '../../../../../../_shared/utils/permissions/capabilityUtils.ts';
import { EmptyAsset, IAsset } from '../../../../../../data/models/assets/Asset.ts';
import { ModalMultipleAssetsSelector } from '../../../../../contentInventory/assets/features/ModalAssetSelector/containers/ModalMultipleAssetsSelector.tsx';
import { AssetFileTypeOption } from '../../../../../contentInventory/content/models/assetFileTypeOptions.ts';
import { ModalAssetEditor } from '../../../../../itemEditor/features/ModalAssetEdit/containers/ModalAssetEditor.tsx';
import { AssetReference } from '../../../../../itemEditor/models/contentItemElements/AssetItemElement.ts';
import { AssetLimitations } from '../../../../../richText/plugins/apiLimitations/api/EditorFeatureLimitations.ts';
import { AssetTypeElementDefaultValue as AssetTypeElementDefaultValueComponent } from '../../../components/typeElements/individualTypeElements/asset/AssetTypeElementDefaultValue.tsx';
import { IAssetTypeElementData } from '../../../models/elements/AssetTypeElementData.ts';

type AssetTypeElementDefaultValueProps = {
  readonly onChange: (typeElementData: IAssetTypeElementData) => void;
  readonly typeElementData: IAssetTypeElementData;
};

enum AssetDialogType {
  None = 0,
  SelectAssets = 1,
  EditAsset = 2,
}

const noAssetLimitations: AssetLimitations = {
  fileSize: null,
  fileType: AssetFileTypeOption.Any,
  maxHeight: null,
  maxWidth: null,
  minHeight: null,
  minWidth: null,
};

const mergeAssetIds = (
  newAssetIds: ReadonlyArray<Uuid>,
  currentSelectedAssets: ReadonlyArray<IAsset>,
): ReadonlyArray<Uuid> => {
  return currentSelectedAssets
    .map((asset) => asset.id)
    .concat(newAssetIds)
    .filter(distinctFilter);
};

const getAssetReferences = (assetIds: ReadonlyArray<Uuid>): ReadonlyArray<AssetReference> => {
  return assetIds.map((id) => ({ id, renditions: [] }));
};

export const getAssetsData = (
  assetsById: Immutable.Map<Uuid, IAsset>,
  selectedAssets: ReadonlyArray<AssetReference>,
): ReadonlyArray<IAsset> => {
  return selectedAssets.map((assetReference) => {
    const asset = assetsById.get(assetReference.id);
    return asset
      ? asset
      : {
          ...EmptyAsset,
          id: assetReference.id,
        };
  });
};

export const AssetTypeElementDefaultValue: React.FC<AssetTypeElementDefaultValueProps> = ({
  onChange,
  typeElementData,
}) => {
  const [editedAssetId, setEditedAssetId] = useState<Uuid | null>(null);
  const [openedDialogType, setOpenedDialogType] = useState<AssetDialogType>(AssetDialogType.None);

  const areAssetCollectionsVisible = useSelector((state) =>
    areCollectionsVisibleForAssets(state, Collection.getValues(state.data.collections.byId)),
  );
  const canViewAssets = useSelector((state) =>
    currentUserHasCapabilities(state, Capability.ViewAssets),
  );

  const assetsById = useSelector((state) => state.data.assets.byId);
  const selectedAssets = useMemo(
    () => getAssetsData(assetsById, typeElementData.defaultValue),
    [assetsById, typeElementData.defaultValue],
  );

  const openEditDialog = (assetId: Uuid) => {
    setOpenedDialogType(AssetDialogType.EditAsset);
    setEditedAssetId(assetId);
  };

  const openSelectDialog = () => {
    setOpenedDialogType(AssetDialogType.SelectAssets);
    setEditedAssetId(null);
  };

  const closeDialog = () => {
    setOpenedDialogType(AssetDialogType.None);
    setEditedAssetId(null);
  };

  const updateAssets = (assets: ReadonlyArray<AssetReference>) => {
    onChange({
      ...typeElementData,
      defaultValue: assets,
    });
  };

  const addAssets = (assetIds: ReadonlyArray<Uuid>): void => {
    closeDialog();
    const mergedAssetIds = mergeAssetIds(assetIds, selectedAssets);
    updateAssets(getAssetReferences(mergedAssetIds));
  };

  const removeAsset = (assetId: Uuid): void => {
    const mergedAssetIds = selectedAssets
      .filter((asset) => asset.id !== assetId)
      .map((asset) => asset.id);

    updateAssets(getAssetReferences(mergedAssetIds));
  };

  const moveAsset: DragMoveHandler = ({ sourceId, targetId }): void => {
    const swappedReferences = moveItem(
      typeElementData.defaultValue,
      sourceId,
      targetId,
      (element) => element.id,
    );
    updateAssets(swappedReferences);
  };

  return (
    <>
      <AssetTypeElementDefaultValueComponent
        areAssetCollectionsVisible={areAssetCollectionsVisible}
        canViewAssets={canViewAssets}
        elementId={typeElementData.elementId}
        onMoveAsset={moveAsset}
        onOpenSelectAssetsDialog={openSelectDialog}
        onOpenEditAssetDialog={openEditDialog}
        onRemoveAsset={removeAsset}
        selectedAssets={selectedAssets}
      />
      <ModalMultipleAssetsSelector
        isOpen={openedDialogType === AssetDialogType.SelectAssets}
        limitations={noAssetLimitations}
        onClose={closeDialog}
        onSelect={addAssets}
        primaryButtonText="Add selected assets as default value"
        showImagesOnly={false}
        titleBarText={`Select assets to use as default value in ${
          typeElementData.name || 'Untitled asset'
        }`}
      />
      <ModalViewer
        dialogClassName="dialog"
        isDialogVisible={openedDialogType === AssetDialogType.EditAsset}
        onClose={closeDialog}
        position={ModalViewerPosition.Center}
      >
        {editedAssetId && (
          <ModalAssetEditor
            key={editedAssetId}
            assetId={editedAssetId}
            onAssetEditingFinished={closeDialog}
          />
        )}
      </ModalViewer>
    </>
  );
};

AssetTypeElementDefaultValue.displayName = 'AssetTypeElementDefaultValue';
