import { OutwardLink } from '@kontent-ai/component-library/Anchor';
import { Box } from '@kontent-ai/component-library/Box';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Label, LabelSize } from '@kontent-ai/component-library/Label';
import { Tooltip } from '@kontent-ai/component-library/Tooltip';
import { Spacing, colorAlertText, colorWarningIcon } from '@kontent-ai/component-library/tokens';
import React from 'react';
import { AutoScrollLink } from '../../../../../../_shared/components/AutoScroll/AutoScrollLink.tsx';
import { AutoScrollProps } from '../../../../../../_shared/components/AutoScroll/autoScrollProps.type.ts';
import { BarItemContent } from '../../../../../../_shared/components/BarItems/BarItemContent.tsx';
import { BarItemTitle } from '../../../../../../_shared/components/BarItems/BarItemTitle.tsx';
import { FallbacksStatus } from '../../../../../../_shared/components/FallbacksForLinkedContent/FallbacksStatus.tsx';
import { NotTranslatedOrFallbacksStatus } from '../../../../../../_shared/containers/FallbacksForLinkedContent/NotTranslatedOrFallbacksStatus.tsx';
import { WorkflowStatusTagForVariant } from '../../../../../../_shared/containers/Workflow/WorkflowStatusTagForVariant.tsx';
import { ContentItemStatus } from '../../../../../../_shared/uiComponents/ContentItemStatus/ContentItemStatus.tsx';
import { getContentVariantState } from '../../../../../../_shared/utils/contentItemVariantUtils.ts';
import { PublishingState } from '../../../../../../data/constants/PublishingState.ts';
import { IListingContentItem } from '../../../../../../data/models/listingContentItems/IListingContentItem.ts';
import { IListingVariantData } from '../../../../../../data/models/listingContentItems/IListingVariantData.ts';
import { IAssignmentWorkflowStep } from '../../../../../../data/models/workflow/WorkflowStep.ts';
import { CannotViewItemsMessage } from '../../../../../contentInventory/content/constants/cannotViewMessages.ts';
import { RelatedItemDoesNotExistReason } from '../../../../../itemEditor/constants/errorMessages.ts';

const getTitleText = (itemName: string, typeName: string | null): string =>
  typeName ? `${itemName} (${typeName})` : itemName;

export const NoPermissionsContentLink: React.FC = () => (
  <BarItemTitle>{CannotViewItemsMessage}</BarItemTitle>
);

export const NonExistingContentLink: React.FC = () => (
  <BarItemTitle tooltipText={RelatedItemDoesNotExistReason}>
    Linked content item doesn’t exist
  </BarItemTitle>
);

type NonTranslatedContentLinkProps = AutoScrollProps & {
  readonly contentItem: IListingContentItem;
  readonly contentItemPath?: string;
  readonly contentTypeName: string | null;
  readonly isViewOnly?: boolean;
};

export const NonTranslatedContentLink: React.FC<NonTranslatedContentLinkProps> = (props) => {
  const name = props.contentItem.item.name;
  const typName = props.contentTypeName;

  if (!props.contentItemPath) {
    return (
      <BarItemTitle title={getTitleText(name, typName)}>
        {name}
        <Status itemId={props.contentItem.item.id} />
      </BarItemTitle>
    );
  }

  if (props.isViewOnly) {
    return (
      <BarItemContent isCompact>
        <OutwardLink href={props.contentItemPath} title={getTitleText(name, typName)}>
          {name}
        </OutwardLink>
        <Status itemId={props.contentItem.item.id} />
      </BarItemContent>
    );
  }

  return (
    <BarItemContent isCompact>
      <AutoScrollLink
        alternativeScrollIds={props.alternativeScrollIds}
        scrollId={props.scrollId}
        scrollOptions={props.scrollOptions}
        title={getTitleText(name, typName)}
        to={props.contentItemPath}
      >
        {name}
      </AutoScrollLink>
      <Status itemId={props.contentItem.item.id} />
    </BarItemContent>
  );
};

const NotTranslatedStatus: React.FC = () => (
  <Inline spacingX={Spacing.S}>
    <Icons.ExclamationTriangle color={colorWarningIcon} />
    <Label size={LabelSize.L} color={colorWarningIcon}>
      Content item not translated
    </Label>
  </Inline>
);

const Status = (props: { readonly itemId: Uuid }) => (
  <NotTranslatedOrFallbacksStatus
    itemId={props.itemId}
    renderFallbacksStatus={(itemId) => <FallbacksStatus itemId={itemId} />}
    renderNotTranslatedStatus={() => <NotTranslatedStatus />}
  />
);

type ArchivedContentLinkProps = {
  readonly contentItem: IListingContentItem;
  readonly contentTypeName: string | null;
};

export const ArchivedContentLink: React.FC<ArchivedContentLinkProps> = ({
  contentTypeName,
  contentItem,
}) => (
  <BarItemTitle
    title={getTitleText(contentItem.item.name, contentTypeName)}
    suffix={<Box color={colorAlertText}>(Content item deleted)</Box>}
  >
    {contentItem.item.name}
  </BarItemTitle>
);

type ValidContentLinkProps = AutoScrollProps & {
  readonly contentItem: IListingContentItem;
  readonly contentItemPath: string;
  readonly contentTypeName: string | null;
  readonly isViewOnly?: boolean;
  readonly publishingState: PublishingState | null;
  readonly workflowStatus: IAssignmentWorkflowStep | null;
};

export const ValidContentLink: React.FC<ValidContentLinkProps> = (props) => {
  const name = props.contentItem.item.name;

  const status = props.workflowStatus && props.publishingState && (
    <WorkflowStatusTagForVariant
      workflowStatus={props.workflowStatus}
      publishingState={props.publishingState}
      scheduledToPublishAt={props.contentItem.variant?.assignment.scheduledToPublishAt || null}
      scheduledToUnpublishAt={props.contentItem.variant?.assignment.scheduledToUnpublishAt || null}
    />
  );

  if (props.isViewOnly) {
    return (
      <BarItemContent isCompact>
        <a
          href={props.contentItemPath}
          target="_blank"
          rel="noopener noreferrer"
          title={getTitleText(name, props.contentTypeName)}
        >
          {name}
        </a>
        {status}
      </BarItemContent>
    );
  }

  return (
    <BarItemContent isCompact>
      <AutoScrollLink
        alternativeScrollIds={props.alternativeScrollIds}
        scrollId={props.scrollId}
        scrollOptions={props.scrollOptions}
        title={getTitleText(name, props.contentTypeName)}
        to={props.contentItemPath}
      >
        {name}
      </AutoScrollLink>
      {status}
    </BarItemContent>
  );
};

type ReadonlyContentLinkProps = {
  readonly contentItemVariant: IListingVariantData | null;
  readonly itemName: string;
  readonly cannotViewMessage: string | undefined;
  readonly cannotCreateMessage: string | undefined;
  readonly typeName: string | null;
};

export const ReadonlyContentLink: React.FC<ReadonlyContentLinkProps> = (props) => (
  <Tooltip text={props.cannotViewMessage || props.cannotCreateMessage} placement="top">
    <BarItemContent isCompact>
      <BarItemTitle
        title={getTitleText(props.itemName, props.typeName)}
        titleStyle={props.cannotViewMessage ? 'primary-redacted' : 'primary'}
      >
        {props.itemName}
      </BarItemTitle>
      {props.contentItemVariant && !props.contentItemVariant.isArchived ? (
        <WorkflowStatusTagForVariant
          workflowStatus={props.contentItemVariant.assignment.workflowStatus}
          publishingState={props.contentItemVariant.publishingState}
          scheduledToPublishAt={props.contentItemVariant.assignment.scheduledToPublishAt}
          scheduledToUnpublishAt={props.contentItemVariant.assignment.scheduledToUnpublishAt}
        />
      ) : (
        <ContentItemStatus status={getContentVariantState(props.contentItemVariant)} />
      )}
    </BarItemContent>
  </Tooltip>
);
