import React from 'react';
import {
  taxonomyGroupCollapsed,
  taxonomyGroupExpanded,
} from '../../../../_shared/actions/sharedActions.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { showCreateNewTermForm } from '../actions/taxonomyActions.ts';
import { TaxonomyTermListing as TaxonomyTermListingComponent } from '../components/TaxonomyTermListing.tsx';

export const TaxonomyTermListing: React.FC = () => {
  const editedTaxonomyGroup = useSelector((s) => s.taxonomyApp.editedTaxonomyGroup);
  const isCreateNewFormDisplayed = useSelector((s) => s.taxonomyApp.isCreateNewFormDisplayed);
  const isCreatingAllowed = useSelector((s) => !s.taxonomyApp.editedTaxonomyTermId);
  const isDragging = useSelector((s) => !!s.taxonomyApp.draggedTermId);

  const dispatch = useDispatch();

  return (
    <TaxonomyTermListingComponent
      editedTaxonomyGroup={editedTaxonomyGroup}
      isCreateNewFormDisplayed={isCreateNewFormDisplayed}
      isCreatingAllowed={isCreatingAllowed}
      isDragging={isDragging}
      onAllTermsCollapsed={(taxonomyGroup) => dispatch(taxonomyGroupCollapsed(taxonomyGroup))}
      onAllTermsExpanded={(taxonomyGroupId) => dispatch(taxonomyGroupExpanded(taxonomyGroupId))}
      onCreateNewButtonClicked={() => dispatch(showCreateNewTermForm())}
    />
  );
};

TaxonomyTermListing.displayName = 'TaxonomyTermListing';
