import { InputState } from '@kontent-ai/component-library/Input';
import { MultiSelect } from '@kontent-ai/component-library/MultiSelect';
import { ISelectItem, RenderSelectMenuOptionProps } from '@kontent-ai/component-library/Selects';
import { DefaultTag, Tag } from '@kontent-ai/component-library/Tag';
import { colorAlertBackgroundInverse } from '@kontent-ai/component-library/tokens';
import React, { ComponentProps, useEffect, useMemo, useState } from 'react';
import {
  DataUiCollection,
  DataUiInput,
  getDataUiCollectionAttribute,
  getDataUiInputAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getTaxonomyTermByIdOrThrow } from '../../../../../../../_shared/utils/taxonomies/taxonomyUtils.ts';
import { ITaxonomyGroup } from '../../../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyGroup.ts';
import { areItemsEqual } from '../../../../../../itemEditor/features/ContentItemEditing/utils/selectItemUtils.ts';

type TaxonomyDefaultValueSelectorProps = {
  readonly isDefaultValueValid: boolean;
  readonly onChange: (defaultValue: UuidArray) => void;
  readonly selectedTermIds: UuidArray;
  readonly taxonomyGroup: ITaxonomyGroup | undefined;
};

const NoTaxonomyGroupSelectedTooltip = 'Select a taxonomy group first.';
const EmptyTaxonomyGroupTooltip =
  'Selected taxonomy group is empty. Fill the taxonomy with terms first.';

interface ITaxonomyTermOption extends ISelectItem<ITaxonomyTermOption> {
  isDeleted: boolean;
}

const toSelectItem = (termId: Uuid, group: ITaxonomyGroup): ITaxonomyTermOption => {
  const term = getTaxonomyTermByIdOrThrow(group, termId);
  return {
    id: term.id,
    label: term.name,
    type: 'item',
    items: term.childIds.map((id) => toSelectItem(id, group)),
    isDeleted: false,
  };
};

const getDeletedTermOption = (id: Uuid): ITaxonomyTermOption => {
  return {
    id,
    label: 'Deleted taxonomy term',
    isDeleted: true,
  };
};

const getInputState = (
  group: ITaxonomyGroup | undefined,
  isDefaultValueValid: boolean,
): InputState => {
  if (!group?.childIds.length) {
    return InputState.Disabled;
  }
  return isDefaultValueValid ? InputState.Default : InputState.Alert;
};

const renderSelectedOption = (
  _: Uuid,
  item: ITaxonomyTermOption,
  defaultTagProps: ComponentProps<typeof DefaultTag>,
) =>
  item.isDeleted ? (
    <Tag {...defaultTagProps} background={colorAlertBackgroundInverse} />
  ) : (
    <DefaultTag {...defaultTagProps} />
  );

const renderMenuOption = (optionProps: RenderSelectMenuOptionProps<ITaxonomyTermOption>) =>
  optionProps.item.value?.isDeleted ? '' : undefined;

const getTooltipText = (group: ITaxonomyGroup | undefined): string | undefined => {
  if (!group) {
    return NoTaxonomyGroupSelectedTooltip;
  }
  return group.childIds.length === 0 ? EmptyTaxonomyGroupTooltip : undefined;
};

export const TaxonomyDefaultValueSelector: React.FC<TaxonomyDefaultValueSelectorProps> = ({
  isDefaultValueValid,
  onChange,
  selectedTermIds,
  taxonomyGroup,
}) => {
  const [taxonomyTermOptions, setTaxonomyTermOptions] = useState<
    ReadonlyArray<ITaxonomyTermOption>
  >([]);

  const taxonomyTerms = useMemo(() => {
    return taxonomyGroup ? taxonomyGroup.childIds.map((id) => toSelectItem(id, taxonomyGroup)) : [];
  }, [taxonomyGroup]);

  useEffect(() => {
    const deletedTerms = selectedTermIds
      .filter((id) => !taxonomyGroup?.terms.has(id))
      .map((id) => getDeletedTermOption(id));
    const updatedTerms = [...taxonomyTerms, ...deletedTerms];

    if (!areItemsEqual(taxonomyTermOptions, updatedTerms)) {
      setTaxonomyTermOptions(updatedTerms);
    }
  }, [taxonomyGroup, taxonomyTerms, selectedTermIds, taxonomyTermOptions]);

  return (
    <MultiSelect
      aria-label="Set default taxonomy term"
      inputState={getInputState(taxonomyGroup, isDefaultValueValid)}
      placeholder="Set default taxonomy term"
      items={taxonomyTermOptions}
      selectedItemIds={selectedTermIds}
      onSelectionChange={(selectedIds) => {
        const ids = [...selectedIds];
        onChange(ids);
      }}
      renderSelectedOption={renderSelectedOption}
      renderMenuOption={renderMenuOption}
      tooltipText={getTooltipText(taxonomyGroup)}
      {...getDataUiInputAttribute(DataUiInput.DefaultValue)}
      {...getDataUiCollectionAttribute(DataUiCollection.TaxonomyTerms)}
    />
  );
};

TaxonomyDefaultValueSelector.displayName = 'TaxonomyDefaultValueSelector';
