import { Box } from '@kontent-ai/component-library/Box';
import { TabView } from '@kontent-ai/component-library/TabView';
import { ConditionalWrapper } from '@kontent-ai/component-library/component-utils';
import { Spacing } from '@kontent-ai/component-library/tokens';
import classNames from 'classnames';
import { forwardRef, useEffect } from 'react';
import { Feature } from '../../../../../../../../@types/FeatureEnum.ts';
import { Loader } from '../../../../../../../_shared/components/Loader.tsx';
import {
  DataUiCollection,
  DataUiElement,
  getDataUiCollectionAttribute,
  getDataUiComponentIdAttribute,
  getDataUiElementAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { isFeatureEnabled } from '../../../../../../../_shared/utils/featureToggles.ts';
import { ICompiledContentType } from '../../../../../../contentInventory/content/models/CompiledContentType.ts';
import { ElementType } from '../../../../../../contentInventory/content/models/ContentItemElementType.ts';
import { IMultipleChoiceTypeElement } from '../../../../../../contentInventory/content/models/contentTypeElements/MultipleChoiceTypeElement.ts';
import { TypeElement } from '../../../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { isEditableElement } from '../../../../../../contentInventory/content/models/contentTypeElements/compiledTypeElementTypeGuards.ts';
import { NoElementsElement } from '../../../../../../itemEditor/features/ContentItemEditing/components/NoElementsElement.tsx';
import { ContentGroupTabsInComponent } from '../../../../../../itemEditor/features/ContentItemEditing/containers/ContentGroupTabsInComponent.tsx';
import { ContentNestingContextProvider } from '../../../../../../itemEditor/features/ContentItemEditing/context/ContentNestingContext.tsx';
import {
  getItemElementOrDefault,
  getTypeElementsInContentGroup,
} from '../../../../../../itemEditor/stores/utils/contentItemElementsUtils.ts';
import { isElementVisible } from '../../../../../../itemEditor/utils/itemElementConditionUtils.ts';
import { useContentComponent } from '../../../../../hooks/useContentComponent.ts';
import { ContentComponentItemElement } from './ContentComponentItemElement.tsx';

const NoElements: ReadonlyArray<TypeElement> = [];

const useGetVisibleElementsForComponent = (
  typeElements: ReadonlyArray<TypeElement>,
  contentComponentId: Uuid,
): ReadonlyArray<TypeElement> => {
  const component = useContentComponent(contentComponentId);
  if (!component) {
    return NoElements;
  }

  if (!isFeatureEnabled(Feature.ConditionalElements)) {
    return typeElements;
  }

  const consistentEditableItemElements = typeElements
    .filter(isEditableElement)
    .map((typeElement: IMultipleChoiceTypeElement) =>
      getItemElementOrDefault(typeElement, component.elements),
    );
  return typeElements.filter((typeElement) =>
    isElementVisible(typeElement, consistentEditableItemElements),
  );
};

type WrapperProps = {
  readonly autoFocus: boolean;
  readonly contentComponentId: Uuid;
  readonly disabled: boolean;
  readonly isHighlighted: boolean;
  readonly onMount: () => void;
  readonly canUpdateContent: boolean;
  readonly contentType: ICompiledContentType;
  readonly selectedContentGroupId: Uuid | null;
};

const ContentComponentItemElementsWrapper = forwardRef<HTMLDivElement, WrapperProps>(
  ({ onMount, ...props }, ref) => {
    useEffect(() => {
      onMount();
    }, [onMount]);

    if (!props.contentType) {
      return <Loader ref={ref} />;
    }

    return <ComponentItemElements {...props} ref={ref} />;
  },
);

const ComponentItemElements = forwardRef<HTMLDivElement, Omit<WrapperProps, 'onMount'>>(
  (
    { autoFocus, contentComponentId, contentType, disabled, isHighlighted, selectedContentGroupId },
    ref,
  ) => {
    const contentTypeElements = contentType.contentElements;
    const indexOfFirstFocusableElement = contentTypeElements.findIndex(
      (typeElement) => typeElement.type !== ElementType.Guidelines,
    );

    const typeElementsInGroup = getTypeElementsInContentGroup(
      contentTypeElements,
      selectedContentGroupId,
    );
    const visibleElements = useGetVisibleElementsForComponent(
      typeElementsInGroup,
      contentComponentId,
    );

    return (
      <div
        className={classNames('content-component__content', {
          'content-component__content--is-highlighted': isHighlighted,
        })}
        {...getDataUiElementAttribute(DataUiElement.ContentComponentContent)}
        {...getDataUiComponentIdAttribute(contentComponentId)}
        ref={ref}
      >
        <ConditionalWrapper
          condition={!!contentType.contentGroups.length}
          wrapper={(children) => (
            <ContentGroupTabsInComponent
              contentComponentId={contentComponentId}
              contentType={contentType}
            >
              <Box
                paddingTop={Spacing.L}
                {...getDataUiCollectionAttribute(DataUiCollection.ContentGroups)}
              >
                <TabView.TabGroup />
              </Box>
              {children}
            </ContentGroupTabsInComponent>
          )}
        >
          <div
            className="content-component__elements-pane"
            {...getDataUiElementAttribute(DataUiElement.ContentComponentElements)}
          >
            {visibleElements.length === 0 ? (
              <NoElementsElement isDisabled={disabled} />
            ) : (
              <ContentNestingContextProvider isCurrentLevelContentComponent>
                {visibleElements.map((typeElement, index) => (
                  <ContentComponentItemElement
                    key={typeElement.elementId}
                    autoFocus={autoFocus && index === indexOfFirstFocusableElement}
                    contentComponentId={contentComponentId}
                    disabled={disabled}
                    typeElement={typeElement}
                  />
                ))}
              </ContentNestingContextProvider>
            )}
          </div>
        </ConditionalWrapper>
      </div>
    );
  },
);

ContentComponentItemElementsWrapper.displayName = 'ContentComponentItemElements';

export { ContentComponentItemElementsWrapper as ContentComponentItemElements };
