import { ICommentThread } from '../../models/comments/CommentThreads.ts';
import { IAssetItemElement } from '../../models/contentItemElements/AssetItemElement.ts';
import { getCommentedAssetSelector } from '../commentUtils.ts';
import { getAssetElementCommentThreadReferences } from './assetCommentUtils.ts';
import { IItemElementCommentManager } from './types/IItemElementCommentManager.type.ts';

const getCommentTriggerCssSelector = (commentThread: ICommentThread): string | null =>
  getCommentedAssetSelector(commentThread);

export const assetItemElementCommentManager: IItemElementCommentManager<IAssetItemElement> = {
  getCommentThreadReferences: (element, threads, _loadedContentItemTypes, contentComponentId) =>
    getAssetElementCommentThreadReferences(element, contentComponentId, threads),
  getCommentTriggerCssSelector,
};
