import { ActiveCapabilityType } from '../../../../../_shared/models/activeCapability.type.ts';
import { IStore } from '../../../../../_shared/stores/IStore.type.ts';
import { isPublishedWorkflowStepSelected } from '../../../../../_shared/utils/contentItemUtils.ts';
import { isVariantPublished } from '../../../../../_shared/utils/contentItemVariantUtils.ts';
import { hasActiveVariantCapability } from '../../../../../_shared/utils/permissions/activeCapabilities.ts';
import { TypeElement } from '../../../../contentInventory/content/models/contentTypeElements/TypeElement.type.ts';
import { isEditableElement } from '../../../../contentInventory/content/models/contentTypeElements/compiledTypeElementTypeGuards.ts';
import { addDefaultLanguageSuffix } from '../../../../environmentSettings/localization/utils/languageUtils.ts';
import { isElementValidIfEmpty } from '../../../utils/getItemElementValidationResult.ts';
import {
  NonLocalizableElementCannotViewDefaultVariantTooltip,
  NonLocalizableElementLatestContentUsedTooltip,
  NonLocalizableElementPublishedContentUsedTooltip,
  NonLocalizableElementRequiredTooltip,
  NonLocalizableElementRevisionsTooltip,
  NonLocalizableElementStaysEmptyTooltip,
} from '../constants/uiConstants.ts';

export const getNonLocalizableElementInfoTooltip = (
  state: IStore,
  isInRevisions: boolean,
  itemId: Uuid,
  typeElement: TypeElement,
): string => {
  const defaultLanguageName = addDefaultLanguageSuffix(state.data.languages.defaultLanguage).name;

  if (isInRevisions) {
    return NonLocalizableElementRevisionsTooltip(defaultLanguageName);
  }

  const defaultListingItem = state.data.listingContentItems.defaultById.get(itemId);
  const canViewDefaultLanguage =
    defaultListingItem &&
    hasActiveVariantCapability(ActiveCapabilityType.ViewContent, defaultListingItem);

  if (!canViewDefaultLanguage) {
    return NonLocalizableElementCannotViewDefaultVariantTooltip(defaultLanguageName);
  }

  const isDefaultVariantPublished = isVariantPublished(defaultListingItem.variant);
  const isDefaultVariantPublishedStepSelected = isPublishedWorkflowStepSelected(
    defaultListingItem.variant?.assignment,
  );
  const isDefaultVariantPublishedWithNewVersion =
    isDefaultVariantPublished && !isDefaultVariantPublishedStepSelected;

  if (isDefaultVariantPublishedWithNewVersion) {
    return NonLocalizableElementLatestContentUsedTooltip(defaultLanguageName);
  }

  if (isDefaultVariantPublished) {
    return NonLocalizableElementPublishedContentUsedTooltip(defaultLanguageName);
  }

  const isElementRequired = isEditableElement(typeElement) && !isElementValidIfEmpty(typeElement);

  return isElementRequired
    ? NonLocalizableElementRequiredTooltip(defaultLanguageName)
    : NonLocalizableElementStaysEmptyTooltip(defaultLanguageName);
};
