import { forwardRef, memo } from 'react';
import { ScrollTableCell } from '../../../../_shared/uiComponents/ScrollTable/ScrollTableCell.tsx';
import { ScrollTableRow } from '../../../../_shared/uiComponents/ScrollTable/ScrollTableRow.tsx';
import { IListingContentItem } from '../../../../data/models/listingContentItems/IListingContentItem.ts';
import { PlaceholderForCheckboxCell } from '../../../itemEditor/features/CascadePublish/components/PlaceholderForCheckboxCell.tsx';
import { RedactedItemName } from '../../content/constants/uiConstants.ts';
import {
  EmptyCellContent,
  RedactedItemNameCellContent,
  WorkflowStatusWithFallbacksCellContent,
} from '../../content/features/ContentItemInventory/components/ItemInventoryScrollTable/Cells/ContentItemScrollTableCellsContents.tsx';

interface IRelationsScrollTableDeletedItemProps {
  readonly depth: number;
  readonly item: IListingContentItem;
  readonly tooltip: string;
}

export const RelationsScrollTableRedactedRow = memo(
  forwardRef<HTMLDivElement, IRelationsScrollTableDeletedItemProps>(
    ({ item, depth, tooltip }, ref) => (
      <ScrollTableRow objectName={item.item.name} isPlaceholder ref={ref}>
        {Array(depth + 1)
          .fill(null)
          .map((_val, index) => (
            <PlaceholderForCheckboxCell key={index} />
          ))}

        <ScrollTableCell size={15} isGrowing title={RedactedItemName} disabledTooltip={tooltip}>
          <RedactedItemNameCellContent />
        </ScrollTableCell>

        <ScrollTableCell size={5}>
          <WorkflowStatusWithFallbacksCellContent variant={item.variant} itemId={item.item.id} />
        </ScrollTableCell>

        <ScrollTableCell size={5}>
          <EmptyCellContent />
        </ScrollTableCell>

        <ScrollTableCell size={5}>
          <EmptyCellContent />
        </ScrollTableCell>
      </ScrollTableRow>
    ),
  ),
);

RelationsScrollTableRedactedRow.displayName = 'RelationsScrollTableRedactedRow';
