import { SaveElementsWithAffectedLocalizationWarning } from '../../../../_shared/components/ModalDialog/SaveElementsWithAffectedLocalizationWarning.tsx';
import { HandleUnsavedFormOnNavigation } from '../../../../_shared/containers/HandleUnsavedFormOnNavigation.tsx';
import { EditorPaperContextProvider } from '../../../../_shared/contexts/EditorPaperContext.tsx';
import { IContentTypeSnippet } from '../../../../data/models/contentModelsApp/snippets/ContentTypeSnippet.ts';
import { ContentModelNotifications } from '../../shared/containers/ContentModelNotifications.tsx';
import { TypeElementValidationResult } from '../../shared/utils/typeElementValidators/types/TypeElementValidationResult.type.ts';
import { ContentTypeSnippetEditorPane } from './ContentTypeSnippetEditorPane.tsx';

type Props = {
  readonly editedContentTypeSnippet: IContentTypeSnippet;
  readonly elementsWithAffectedLocalization: ReadonlyArray<{
    readonly elementId: Uuid;
    readonly name: string;
  }>;
  readonly focusSnippetName?: boolean;
  readonly hasUnsavedChanges: boolean;
  readonly isBeingSaved: boolean;
  readonly onSaveContentTypeSnippet: () => void;
  readonly onSetFirstInvalidElement: (elementId: Uuid) => void;
  readonly onUnsavedNavigation: (onSuccess: () => void, onFail: () => void) => Promise<void>;
  readonly showSnippetUsedWithPublishedItemWarning: boolean;
  readonly validationResults: ReadonlyMap<Uuid, TypeElementValidationResult>;
};

export const ContentTypeSnippetEditor = ({
  editedContentTypeSnippet,
  elementsWithAffectedLocalization,
  focusSnippetName,
  hasUnsavedChanges,
  isBeingSaved,
  onSaveContentTypeSnippet,
  onSetFirstInvalidElement,
  onUnsavedNavigation,
  showSnippetUsedWithPublishedItemWarning,
  validationResults,
}: Props) => {
  const onShowInvalidElements = (): void => {
    const firstInvalidElement = editedContentTypeSnippet.typeElements.find((element) => {
      if (!element) {
        return false;
      }
      const result = validationResults.get(element.elementId);
      return result && result.errorMessages.length > 0;
    });

    if (firstInvalidElement) {
      onSetFirstInvalidElement(firstInvalidElement.elementId);
    }
  };

  return (
    <>
      <HandleUnsavedFormOnNavigation
        hasUnsavedChanges={hasUnsavedChanges}
        isBeingSaved={isBeingSaved}
        onSaveChanges={onUnsavedNavigation}
        renderAdditionalContent={() => (
          <SaveElementsWithAffectedLocalizationWarning
            elementsWithAffectedLocalization={elementsWithAffectedLocalization}
          />
        )}
      />
      <EditorPaperContextProvider>
        <ContentModelNotifications
          validationResults={validationResults}
          onFocusFirstInvalidElement={onShowInvalidElements}
        />
        <ContentTypeSnippetEditorPane
          editedContentTypeSnippet={editedContentTypeSnippet}
          focusSnippetName={focusSnippetName}
          isBeingSaved={isBeingSaved}
          onSaveContentTypeSnippet={onSaveContentTypeSnippet}
          showSnippetUsedWithPublishedItemWarning={showSnippetUsedWithPublishedItemWarning}
        />
      </EditorPaperContextProvider>
    </>
  );
};
