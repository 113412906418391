import { useEffect } from 'react';
import { modalOpened } from '../../actions/sharedActions.ts';
import { showUserFeedback } from '../../actions/thunks/showUserFeedback.ts';
import { ModalDialogType } from '../../constants/modalDialogType.ts';
import { useDispatch } from '../../hooks/useDispatch.ts';
import { useSelector } from '../../hooks/useSelector.ts';
import { UserFeedbackServerKey } from '../../models/UserPropertiesServerKeys.ts';
import { UserFeedbackSource } from '../../models/events/UserFeedbackEventData.type.ts';
import { shouldShowHelpUsImproveSurveyAutomatically } from '../../selectors/umuxSurveySelectors.ts';

export const ShowHelpUsImproveSurveyDialogAutomatically = () => {
  const shouldShowDialog = useSelector((s) =>
    shouldShowHelpUsImproveSurveyAutomatically(s, new Date()),
  );
  const feedbackTimestamps = useSelector((s) => s.sharedApp.userProperties.userFeedback);

  const dispatch = useDispatch();

  useEffect(() => {
    if (shouldShowDialog) {
      dispatch(
        showUserFeedback(
          UserFeedbackSource.HelpUsImprove,
          UserFeedbackServerKey,
          feedbackTimestamps,
        ),
      );
      dispatch(modalOpened(ModalDialogType.HelpUsImproveSurveyDialog));
    }
  }, [shouldShowDialog, feedbackTimestamps]);

  return null;
};
