import { focusAtTheEnd } from '@kontent-ai/DOM';
import classNames from 'classnames';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { LabelFor } from '../../../../../../_shared/uiComponents/LabelFor/LabelFor.tsx';
import {
  DataUiInput,
  getDataUiInputAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { getInputValidationClass } from '../../../utils/inputValidationUtils.ts';
import { TypeElementNameStatus } from './TypeElementNameStatus.tsx';

interface ITypeElementNameProps {
  readonly autoFocus?: boolean;
  readonly dataUiInput?: DataUiInput;
  readonly elementTypeName: string;
  readonly highlightEmpty: boolean;
  readonly isNameValid: boolean;
  readonly maxLength: number;
  readonly name: string;
  readonly onChange: (name: string) => void;
  readonly placeholder?: string;
}

export const TypeElementName: React.FC<ITypeElementNameProps> = ({
  highlightEmpty,
  name,
  autoFocus,
  elementTypeName,
  isNameValid,
  placeholder,
  dataUiInput,
  maxLength,
  onChange,
}) => {
  const [isStatusHidden, setIsStatusHidden] = useState(true);
  const inputElement = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (autoFocus) {
      focusAtTheEnd(inputElement.current);
    }
  }, [autoFocus]);

  const updateName = (event: ChangeEvent<HTMLInputElement>): void => {
    const newName = event.target.value;
    onChange(newName);
  };

  return (
    <>
      <div className="content-element__name">
        <LabelFor
          isHidden
          target={(id: Uuid) => (
            <input
              id={id}
              autoComplete="off"
              type="text"
              ref={inputElement}
              className={classNames(
                'content-element__name-input',
                'text-input',
                getInputValidationClass(isNameValid),
                { 'content-element__name-input--is-empty': highlightEmpty && !name },
              )}
              onChange={updateName}
              placeholder={placeholder || `Untitled ${elementTypeName.toLowerCase()}`}
              value={name}
              onFocus={() => setIsStatusHidden(false)}
              onBlur={() => setIsStatusHidden(true)}
              {...getDataUiInputAttribute(dataUiInput || DataUiInput.TypeElementName)}
            />
          )}
        >
          Content element name
        </LabelFor>
      </div>
      <TypeElementNameStatus
        isHidden={isStatusHidden}
        maxLength={maxLength}
        currentValue={name.length}
      />
    </>
  );
};

TypeElementName.displayName = 'TypeElementName';
