import React, { useState } from 'react';
import { modalOpened } from '../../../../../../../_shared/actions/sharedActions.ts';
import { ModalDialogType } from '../../../../../../../_shared/constants/modalDialogType.ts';
import { getFirstWorkflowIdOfItemSelection } from '../../../../../../../_shared/features/ChangeWorkflowStepModal/selectors/getFirstWorkflowIdOfItemSelection.ts';
import { getPreselectedStepForBulkRestoreFromArchivedStep } from '../../../../../../../_shared/features/ChangeWorkflowStepModal/selectors/getPreselectedWorkflowStep.ts';
import { OpenWorkflowStepModalAction } from '../../../../../../../_shared/features/ChangeWorkflowStepModal/types/workflowStepModalActionTypes.ts';
import { useDataSelector } from '../../../../../../../_shared/hooks/useDataSelector.ts';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../../../../_shared/hooks/useSelector.ts';
import { areCollectionsVisibleForCurrentUser } from '../../../../../../../_shared/selectors/contentCollections.ts';
import { getSelectedLanguageIdOrThrow } from '../../../../../../../_shared/selectors/getSelectedLanguageId.ts';
import { IStore } from '../../../../../../../_shared/stores/IStore.type.ts';
import { hasCapabilityInLanguage } from '../../../../../../../_shared/utils/permissions/capabilitiesInLanguageUtils.ts';
import { Capability } from '../../../../../../../_shared/utils/permissions/capability.ts';
import { addDefaultLanguageSuffix } from '../../../../../../environmentSettings/localization/utils/languageUtils.ts';
import { prepareNewContentItemDialogForListing } from '../../../../../../itemEditor/features/NewContentItem/actions/thunkNewContentItemActions.ts';
import {
  IItemListingBulkActionsCheck,
  ItemListingBulkActionStatus,
} from '../../../../models/ItemListingBulkActionStatus.ts';
import { isSomethingSelected } from '../../../../reducers/listingUi/itemsSelection/selectors/itemsSelectionSelectors.ts';
import {
  cancelScheduledPublishSelectedContentItems,
  loadOperationsStatuses,
  openBulkPublishModal,
} from '../../actions/thunkContentItemInventoryActions.ts';
import { openBulkChangeWorkflowStepModal } from '../../actions/thunks/bulkActions/openBulkChangeWorkflowStepModal.ts';
import {
  ContentItemScrollTableActions,
  ContentItemScrollTableActionsSharedProps,
  IDisabledState,
} from '../../components/ItemInventoryScrollTable/ContentItemScrollTableActions.tsx';

const itemInArchivedStepMessage = 'Content is archived.';
const loadingMessage = 'Loading available actions.';
const genericMessage = 'Something went wrong.';
const noTranslatedItemsMessage = 'Content is not translated.';
const tooManyItemsMessage = 'Select 200 items or fewer.';
const notPublishedMessage = 'Content is not published.';
const notScheduledMessage = 'Content is not scheduled.';
const publishedMessage = 'Content is published.';
const publishedOrScheduledMessage = 'Content is scheduled or published.';
const alreadyPublishedOrScheduledMessage = 'Content is already published or scheduled.';
const alreadyDeletedMessage = 'Content is already deleted.';
const unfinishedItemsMessage =
  'Cannot publish unfinished items. Make sure the selected items are filled in correctly.';
const scheduledMessage = 'Content is scheduled.';
const oneOrMoreItemsAreInStepPreventingPublishing = 'Content workflow prevents publishing.';
const noAvailableTransitions = 'Selected items have no workflow transitions in common.';
const differentWorkflows = 'Selected items are in different workflows.';
const allItemsArchived =
  'You cannot change the workflow step of archived items. You need to restore them first.';
const actionCannotBeAppliedOnSelectedItems =
  'The action cannot be applied to any of the selected items.';
const someItemIsNotInArchivedStep = 'Select only the archived items to enable this action.';
const rolePreventsPublish = 'Your role prevents publishing content.';
const rolePreventsUnpublish = 'Your role prevents unpublishing content.';
const rolePreventsDelete = 'Your role prevents deleting content.';
const rolePreventsCanceling = 'Your role prevents canceling content scheduling.';
const rolePreventsMoveToCollection = 'Your role prevents moving items between collections.';
const rolePreventsChangingWorkflowStep = 'Your role prevents changing workflow step.';
const rootItemDeleteMessage = 'Deselect the Web Spotlight root items to delete your items.';

enum BulkAction {
  Archive = 'Archive',
  Cancel = 'Cancel',
  ChangeWorkflowStep = 'ChangeWorkflowStep',
  MoveToCollection = 'MoveToCollection',
  Publish = 'Publish',
  RestoreFromArchivedStep = 'RestoreFromArchivedStep',
  Unpublish = 'Unpublish',
}

const getMessage = (
  bulkAction: BulkAction,
  check: IItemListingBulkActionsCheck | null,
  defaultLanguageName: string,
): string | undefined => {
  if (!check) {
    return loadingMessage;
  }

  switch (bulkAction) {
    case BulkAction.Publish:
      return getMessageForPublish(check, defaultLanguageName);
    case BulkAction.Cancel:
      return getMessageForCancel(check);
    case BulkAction.MoveToCollection:
      return getMessageForMoveToCollection(check);
    case BulkAction.Archive:
      return getMessageForArchive(check);
    case BulkAction.RestoreFromArchivedStep:
      return getMessageForRestoreFromArchivedStep(check);
    case BulkAction.ChangeWorkflowStep:
      return getMessageForChangeWorkflowStep(check);
    case BulkAction.Unpublish:
      return getMessageForUnpublish(check);
  }
};

function getMessageForPublish(check: IItemListingBulkActionsCheck, defaultLanguageName: string) {
  switch (check.publish.status) {
    case ItemListingBulkActionStatus.Ok:
      return undefined;
    case ItemListingBulkActionStatus.TooManyItems:
      return tooManyItemsMessage;
    case ItemListingBulkActionStatus.NoItemsForOperation:
      return alreadyPublishedOrScheduledMessage;
    case ItemListingBulkActionStatus.IncompleteItem:
      return unfinishedItemsMessage;
    case ItemListingBulkActionStatus.NoAvailableTransitions:
      return oneOrMoreItemsAreInStepPreventingPublishing;
    case ItemListingBulkActionStatus.MissingPermission:
      return rolePreventsPublish;
    case ItemListingBulkActionStatus.NoTranslatedItems:
      return noTranslatedItemsMessage;
    case ItemListingBulkActionStatus.ItemInArchivedStep:
      return itemInArchivedStepMessage;
    case ItemListingBulkActionStatus.MustPublishNonLocalizableElements:
      return `Selected items contain required non-localizable elements. Publish these items in ${defaultLanguageName} first.`;
    default:
      return genericMessage;
  }
}

function getMessageForUnpublish(check: IItemListingBulkActionsCheck) {
  switch (check.unpublish.status) {
    case ItemListingBulkActionStatus.Ok:
      return undefined;
    case ItemListingBulkActionStatus.TooManyItems:
      return tooManyItemsMessage;
    case ItemListingBulkActionStatus.NoItemsForOperation:
      return notPublishedMessage;
    case ItemListingBulkActionStatus.MissingPermission:
      return rolePreventsUnpublish;
    case ItemListingBulkActionStatus.NoTranslatedItems:
      return noTranslatedItemsMessage;
    case ItemListingBulkActionStatus.ItemInScheduledStep:
      return scheduledMessage;
    default:
      return genericMessage;
  }
}

function getMessageForCancel(check: IItemListingBulkActionsCheck) {
  switch (check.cancelSchedulePublish.status) {
    case ItemListingBulkActionStatus.Ok:
      return undefined;
    case ItemListingBulkActionStatus.TooManyItems:
      return tooManyItemsMessage;
    case ItemListingBulkActionStatus.NoItemsForOperation:
      return notScheduledMessage;
    case ItemListingBulkActionStatus.MissingPermission:
      return rolePreventsCanceling;
    case ItemListingBulkActionStatus.NoTranslatedItems:
      return noTranslatedItemsMessage;
    default:
      return genericMessage;
  }
}

function getMessageForMoveToCollection(check: IItemListingBulkActionsCheck) {
  switch (check.moveToCollection.status) {
    case ItemListingBulkActionStatus.Ok:
      return undefined;
    case ItemListingBulkActionStatus.TooManyItems:
      return tooManyItemsMessage;
    case ItemListingBulkActionStatus.MissingPermission:
      return rolePreventsMoveToCollection;
    case ItemListingBulkActionStatus.NoTranslatedItems:
      return noTranslatedItemsMessage;
    default:
      return genericMessage;
  }
}

function getMessageForArchive(check: IItemListingBulkActionsCheck) {
  switch (check.archive.status) {
    case ItemListingBulkActionStatus.Ok:
      return undefined;
    case ItemListingBulkActionStatus.TooManyItems:
      return tooManyItemsMessage;
    case ItemListingBulkActionStatus.NoItemsForOperation:
      return alreadyDeletedMessage;
    case ItemListingBulkActionStatus.MissingPermission:
      return rolePreventsDelete;
    case ItemListingBulkActionStatus.NoTranslatedItems:
      return noTranslatedItemsMessage;
    case ItemListingBulkActionStatus.ItemInPublishedStep:
      return publishedMessage;
    case ItemListingBulkActionStatus.NotSupportedForHomePageItem:
      return rootItemDeleteMessage;
    default:
      return genericMessage;
  }
}

function getMessageForChangeWorkflowStep(check: IItemListingBulkActionsCheck) {
  switch (check.updateWorkflowStep.status) {
    case ItemListingBulkActionStatus.Ok:
      return undefined;
    case ItemListingBulkActionStatus.TooManyItems:
      return tooManyItemsMessage;
    case ItemListingBulkActionStatus.NoItemsForOperation:
      return actionCannotBeAppliedOnSelectedItems;
    case ItemListingBulkActionStatus.ItemInPublishedStep:
      return publishedOrScheduledMessage;
    case ItemListingBulkActionStatus.DifferentWorkflows:
      return differentWorkflows;
    case ItemListingBulkActionStatus.NoAvailableTransitions:
      return noAvailableTransitions;
    case ItemListingBulkActionStatus.AllItemsInArchivedStep:
      return allItemsArchived;
    case ItemListingBulkActionStatus.MissingPermission:
      return rolePreventsChangingWorkflowStep;
    case ItemListingBulkActionStatus.NoTranslatedItems:
      return noTranslatedItemsMessage;
    default:
      return genericMessage;
  }
}

function getMessageForRestoreFromArchivedStep(check: IItemListingBulkActionsCheck) {
  switch (check.restoreFromArchivedStep.status) {
    case ItemListingBulkActionStatus.Ok:
      return undefined;
    case ItemListingBulkActionStatus.TooManyItems:
      return tooManyItemsMessage;
    case ItemListingBulkActionStatus.ItemNotInArchivedStep:
      return someItemIsNotInArchivedStep;
    case ItemListingBulkActionStatus.MissingPermission:
      return rolePreventsChangingWorkflowStep;
    case ItemListingBulkActionStatus.NoTranslatedItems:
      return noTranslatedItemsMessage;
    case ItemListingBulkActionStatus.DifferentWorkflows:
      return differentWorkflows;
    default:
      return genericMessage;
  }
}

const getRestoreToStepName = (state: IStore): string => {
  const workflowId = getFirstWorkflowIdOfItemSelection(state);

  return (
    (workflowId &&
      getPreselectedStepForBulkRestoreFromArchivedStep(state, workflowId)?.step.name) ||
    ''
  );
};

const ContentItemScrollTableActionsContainer: React.FC<ContentItemScrollTableActionsSharedProps> = (
  sharedProps,
) => {
  const areCollectionsVisible = useSelector(areCollectionsVisibleForCurrentUser);
  const restoreToStepName = useSelector(getRestoreToStepName);

  const canCreate = useSelector((state) => {
    const selectedLanguageId = getSelectedLanguageIdOrThrow(state);

    return hasCapabilityInLanguage(state, Capability.CreateContent, selectedLanguageId);
  });

  const defaultLanguage = useDataSelector((data) =>
    addDefaultLanguageSuffix(data.languages.defaultLanguage),
  );

  const showOtherActions = useSelector(isSomethingSelected);
  const bulkActionCheckResult = useSelector(
    (state) => state.contentApp.listingUi.bulkActionCheckResult,
  );
  const areOperationStatusesLoaded = !!bulkActionCheckResult;

  const getDisabledMessage = (action: BulkAction) =>
    getMessage(action, bulkActionCheckResult, defaultLanguage.name);

  const publishDisabledState: IDisabledState = {
    isDisabled:
      !areOperationStatusesLoaded ||
      bulkActionCheckResult?.publish.status !== ItemListingBulkActionStatus.Ok,
    disabledMessage: getDisabledMessage(BulkAction.Publish),
  };

  const unPublishDisabledState: IDisabledState = {
    isDisabled:
      !areOperationStatusesLoaded ||
      bulkActionCheckResult?.unpublish.status !== ItemListingBulkActionStatus.Ok,
    disabledMessage: getDisabledMessage(BulkAction.Unpublish),
  };

  const cancelDisabledState: IDisabledState = {
    isDisabled:
      !areOperationStatusesLoaded ||
      bulkActionCheckResult?.cancelSchedulePublish.status !== ItemListingBulkActionStatus.Ok,
    disabledMessage: getDisabledMessage(BulkAction.Cancel),
  };

  const deleteDisabledState: IDisabledState = {
    isDisabled:
      !areOperationStatusesLoaded ||
      bulkActionCheckResult?.archive.status !== ItemListingBulkActionStatus.Ok,
    disabledMessage: getDisabledMessage(BulkAction.Archive),
  };

  const changeWorkflowStepDisabledState: IDisabledState = {
    isDisabled:
      !areOperationStatusesLoaded ||
      bulkActionCheckResult?.updateWorkflowStep.status !== ItemListingBulkActionStatus.Ok,
    disabledMessage: getDisabledMessage(BulkAction.ChangeWorkflowStep),
  };

  const moveToCollectionDisabledState: IDisabledState = {
    isDisabled:
      !areOperationStatusesLoaded ||
      bulkActionCheckResult?.moveToCollection.status !== ItemListingBulkActionStatus.Ok,
    disabledMessage: getDisabledMessage(BulkAction.MoveToCollection),
  };

  const restoreFromArchivedStepDisabledState: IDisabledState = {
    isDisabled:
      !areOperationStatusesLoaded ||
      bulkActionCheckResult?.restoreFromArchivedStep.status !== ItemListingBulkActionStatus.Ok,
    disabledMessage: getDisabledMessage(BulkAction.RestoreFromArchivedStep),
  };

  const [
    isRemoveSchedulePublishConfirmationDialogOpen,
    setIsRemoveSchedulePublishConfirmationDialogOpen,
  ] = useState(false);

  const dispatch = useDispatch();
  const checkOperations = () => dispatch(loadOperationsStatuses());
  const onCancelScheduleSelected = () => setIsRemoveSchedulePublishConfirmationDialogOpen(true);
  const onRemoveSchedulePublishConfirm = () =>
    dispatch(cancelScheduledPublishSelectedContentItems());
  const onRemoveSchedulePublishCancel = () =>
    setIsRemoveSchedulePublishConfirmationDialogOpen(false);
  const onChangeWorkflowStepSelected = () =>
    dispatch(openBulkChangeWorkflowStepModal(OpenWorkflowStepModalAction.UpdateStep));
  const onCreateNew = () => {
    dispatch(
      prepareNewContentItemDialogForListing({
        allowedContentTypeIds: null,
      }),
    );
    dispatch(modalOpened(ModalDialogType.NewContentItemDialog));
  };
  const onDeleteSelected = () =>
    dispatch(modalOpened(ModalDialogType.BulkItemsDeleteConfirmationDialog));
  const onPublishSelected = () => dispatch(openBulkPublishModal());
  const onRestoreFromArchivedStep = () =>
    dispatch(openBulkChangeWorkflowStepModal(OpenWorkflowStepModalAction.RestoreFromArchivedStep));
  const onUnpublishSelected = () =>
    dispatch(openBulkChangeWorkflowStepModal(OpenWorkflowStepModalAction.UnpublishAndArchive));

  return (
    <ContentItemScrollTableActions
      {...sharedProps}
      areCollectionsVisible={areCollectionsVisible}
      cancelDisabledState={cancelDisabledState}
      canCreate={canCreate}
      changeWorkflowStepDisabledState={changeWorkflowStepDisabledState}
      checkOperations={checkOperations}
      deleteDisabledState={deleteDisabledState}
      isRemoveSchedulePublishConfirmationDialogOpen={isRemoveSchedulePublishConfirmationDialogOpen}
      loadingOperationStatuses={!areOperationStatusesLoaded}
      moveToCollectionDisabledState={moveToCollectionDisabledState}
      onCancelScheduleSelected={onCancelScheduleSelected}
      onChangeWorkflowStepSelected={onChangeWorkflowStepSelected}
      onCreateNew={onCreateNew}
      onDeleteSelected={onDeleteSelected}
      onPublishSelected={onPublishSelected}
      onRemoveSchedulePublishCancel={onRemoveSchedulePublishCancel}
      onRemoveSchedulePublishConfirm={onRemoveSchedulePublishConfirm}
      onRestoreFromArchivedStep={onRestoreFromArchivedStep}
      onUnpublishSelected={onUnpublishSelected}
      publishDisabledState={publishDisabledState}
      restoreFromArchivedStepDisabledState={restoreFromArchivedStepDisabledState}
      restoreToStepName={restoreToStepName}
      showOtherActions={showOtherActions}
      unPublishDisabledState={unPublishDisabledState}
    />
  );
};

ContentItemScrollTableActionsContainer.displayName = 'ContentItemScrollTableActionsContainer';

export { ContentItemScrollTableActionsContainer as ContentItemScrollTableActions };
