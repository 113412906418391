import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { MouseEventHandler } from 'react';
import { NavigationBarExpandedState } from '../../contexts/MainNavigationContext.tsx';

type Props = {
  readonly navigationBarExpandedState: NavigationBarExpandedState;
  readonly onClick: MouseEventHandler;
};

const getTextByNavigationState = (navigationBarExpandedState: NavigationBarExpandedState) => {
  if (navigationBarExpandedState === NavigationBarExpandedState.Expanded) {
    return 'Minimize menu';
  }
  if (navigationBarExpandedState === NavigationBarExpandedState.MinimizedExpanded) {
    return 'Expand menu';
  }
  return '';
};

export function ToggleMenuButton({ navigationBarExpandedState, onClick }: Props) {
  return (
    <QuinaryButton
      tooltipText={getTextByNavigationState(navigationBarExpandedState)}
      fullWidth={navigationBarExpandedState !== NavigationBarExpandedState.Minimized}
      tooltipPlacement="right-end"
      onClick={onClick}
    >
      <QuinaryButton.Icon
        icon={
          navigationBarExpandedState === NavigationBarExpandedState.Minimized ||
          navigationBarExpandedState === NavigationBarExpandedState.MinimizedExpanded
            ? Icons.ArrowRightCircle
            : Icons.ArrowLeftCircle
        }
      />
      {getTextByNavigationState(navigationBarExpandedState)}
    </QuinaryButton>
  );
}
