import React from 'react';
import { PlanLimitationWarning as PlanLimitationWarningComponent } from '../../components/infos/PlanLimitationWarning.tsx';
import { useSelector } from '../../hooks/useSelector.ts';
import { isAdminOnSubscription } from '../../selectors/subscriptionSelectors.ts';
import { getCurrentProject } from '../../selectors/userProjectsInfoSelectors.ts';

type Props = {
  readonly description?: string;
  readonly introMessage: string;
  readonly showSimplified?: boolean;
  readonly title?: string;
};

export const PlanLimitationWarning: React.FC<Props> = ({
  description,
  introMessage,
  showSimplified,
  title,
}) => {
  const editedSubscriptionId = useSelector((s) => getCurrentProject(s).subscriptionId);
  const userCanChangePlan = useSelector((s) => isAdminOnSubscription(s, editedSubscriptionId));

  return (
    <PlanLimitationWarningComponent
      description={description}
      editedSubscriptionId={editedSubscriptionId}
      introMessage={introMessage}
      showSimplified={showSimplified}
      title={title}
      userCanChangePlan={userCanChangePlan}
    />
  );
};

PlanLimitationWarning.displayName = 'PlanLimitationWarning';
