import { QuinaryButton } from '@kontent-ai/component-library/Button';
import { Icons } from '@kontent-ai/component-library/Icons';
import { Menu } from '@kontent-ai/component-library/Menu';
import { mergeProps } from '@react-aria/utils';
import { RefObject, useCallback } from 'react';
import { useDispatch } from '../../../../../../../_shared/hooks/useDispatch.ts';
import {
  DataUiCollection,
  getDataUiCollectionAttribute,
} from '../../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import {
  activateContentItemEditingAction,
  deactivateContentItemEditingAction,
} from '../../../../../../itemEditor/features/ContentItemEditing/actions/contentItemEditingActions.ts';
import { useAvailableContentItemEditingActions } from '../../../../../../itemEditor/features/ContentItemEditing/hooks/useAvailableContentItemEditingActions.ts';
import { EditingAction } from '../../../../../../itemEditor/features/ContentItemEditing/models/EditingAction.ts';
import { MoreActionsMainMenu } from './MoreActionsMainMenu.tsx';

type Props = Readonly<{
  isUrlPatternSetForEditedContentItem: boolean;
}>;

export const MoreActions = ({ isUrlPatternSetForEditedContentItem }: Props) => {
  const dispatch = useDispatch();

  const availableActions = useAvailableContentItemEditingActions(
    isUrlPatternSetForEditedContentItem,
  );

  const onHideMenu = useCallback(() => {
    dispatch(deactivateContentItemEditingAction());
  }, []);

  const onShowMenu = useCallback(() => {
    dispatch(activateContentItemEditingAction(EditingAction.showMainMenu));
  }, []);

  return (
    <Menu>
      <Menu.Trigger>
        {(ref: RefObject<HTMLButtonElement>, triggerProps, isOpen) => (
          <QuinaryButton
            ref={ref}
            tooltipText="More actions"
            {...mergeProps(triggerProps, {
              onClick: isOpen ? onHideMenu : onShowMenu,
            })}
          >
            <QuinaryButton.Icon icon={Icons.Ellipsis} />
          </QuinaryButton>
        )}
      </Menu.Trigger>
      <Menu.List {...getDataUiCollectionAttribute(DataUiCollection.MoreActionsDropdown)}>
        <MoreActionsMainMenu actions={availableActions} />
      </Menu.List>
    </Menu>
  );
};
