import { PropsWithChildren } from 'react';
import { useSelector } from '../../../hooks/useSelector.ts';
import { isAiReviewEnabled } from '../../../selectors/aiSelectors.ts';

export const WhenAiReviewFeatureEnabled = ({ children }: PropsWithChildren<NoProps>) => {
  const isAiReviewFeatureEnabled = useSelector(isAiReviewEnabled);

  if (!isAiReviewFeatureEnabled) return null;

  return children;
};
