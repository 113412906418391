import { ThunkPromise } from '../../../../@types/Dispatcher.type.ts';
import { logError } from '../../../../_shared/utils/logError.ts';
import {
  IEntityWebhookSetting,
  createEntityWebhookSettingDomainModel,
} from '../../../../data/models/webhooks/EntityWebhookSetting.ts';
import { IEntityWebhookServerModel } from '../../../../repositories/serverModels/IEntityWebhookServerModel.type.ts';
import { EntityWebhooks_DisableWebhook_Finished } from '../../constants/entityWebhooksActionTypes.ts';

interface IDeps {
  readonly entityWebhookRepository: {
    readonly disable: (webhookId: Uuid) => Promise<IEntityWebhookServerModel>;
  };
}

const success = (webhook: IEntityWebhookSetting) =>
  ({
    type: EntityWebhooks_DisableWebhook_Finished,
    payload: { webhook },
  }) as const;

export type DisableEntityWebhookActionsType = ReturnType<typeof success>;

export const createDisableEntityWebhookAction =
  (deps: IDeps) =>
  (webhookId: Uuid): ThunkPromise =>
  async (dispatch) => {
    try {
      const serverModel = await deps.entityWebhookRepository.disable(webhookId);
      const domainModel = createEntityWebhookSettingDomainModel(serverModel);
      dispatch(success(domainModel));
    } catch (e) {
      logError(`Failed to disable webhook with id ${webhookId}.`, e);
      throw e;
    }
  };
