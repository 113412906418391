import React from 'react';
import { useForm } from 'react-hook-form';
import { modalOpened } from '../../../../../_shared/actions/sharedActions.ts';
import { ModalDialogType } from '../../../../../_shared/constants/modalDialogType.ts';
import { useDispatch } from '../../../../../_shared/hooks/useDispatch.ts';
import { createFormValidationResolver } from '../../../../../_shared/utils/validation/createFormValidationResolver.ts';
import { ITaxonomyTerm } from '../../../../../data/models/contentModelsApp/taxonomyGroups/TaxonomyTerm.ts';
import {
  taxonomyTermEditingAbandoned,
  taxonomyTermNameChanged,
  taxonomyTermRemoved,
} from '../../actions/taxonomyActions.ts';
import { TermEditor } from '../../components/termEditors/TermEditor.tsx';
import { ITaxonomyTermFormShape } from '../../models/ITaxonomyTermFormShape.type.ts';
import { taxonomyTermValidationConfig } from '../../validation/taxonomyTermValidation.ts';

type Props = {
  readonly term: ITaxonomyTerm | undefined;
};

const TermEditorContainer: React.FC<Props> = ({ term }) => {
  const dispatch = useDispatch();

  const formProps = useForm<ITaxonomyTermFormShape>({
    defaultValues: {
      name: term?.name,
      codename: term?.codename,
    },
    resolver: createFormValidationResolver(taxonomyTermValidationConfig, {}),
  });

  if (!term) {
    // When nodes are removed very quickly this situation may occur
    return null;
  }

  const onCancelEditing = () => dispatch(taxonomyTermEditingAbandoned());

  const onRemove = () => {
    if (term.childIds.length) {
      dispatch(modalOpened(ModalDialogType.RemoveTaxonomyTermConfirmationDialog));
    } else {
      dispatch(taxonomyTermRemoved(term.id));
    }
  };

  const submitForm = formProps.handleSubmit((values) =>
    dispatch(taxonomyTermNameChanged(term.id, values.name)),
  );

  return (
    <TermEditor
      formProps={formProps}
      onCancelEditing={onCancelEditing}
      onRemove={onRemove}
      onSubmit={submitForm}
    />
  );
};

TermEditorContainer.displayName = 'TermEditorContainer';
export { TermEditorContainer as TermEditor };
