import { memoize } from '@kontent-ai/memoization';
import Immutable from 'immutable';
import { addDefaultLanguageSuffix } from '../../applications/environmentSettings/localization/utils/languageUtils.ts';
import { ILanguage, Languages } from '../../data/models/languages/Language.ts';
import { IListingVariantData } from '../../data/models/listingContentItems/IListingVariantData.ts';
import { ILanguagesData } from '../../data/reducers/languages/ILanguagesData.type.ts';
import { IUser } from '../../data/reducers/user/IUser.type.ts';
import { DefaultVariantId } from '../constants/variantIdValues.ts';
import { isLanguageActive } from '../models/utils/isLanguageActive.ts';
import { getNormalizedRolesWithSettingsForUser } from '../selectors/userProjectsInfoSelectors.ts';
import { isVariantPublished } from './contentItemVariantUtils.ts';
import { Capability } from './permissions/capability.ts';
import {
  getLanguageIdsWithAllowedCapabilityForCollection,
  getLanguageIdsWithAllowedCapabilityInAnyCollection,
} from './permissions/getLanguageIdsWithAllowedCapability.ts';

export function getLanguageCodename(
  languageId: Uuid,
  defaultLanguage: ILanguage,
  languages: Languages,
): string {
  const language = languages.get(languageId);
  return language ? language.codename : defaultLanguage.codename;
}

export const findNearestTranslatedFallbackLanguageId = (
  translatedLanguageIds: ReadonlyArray<Uuid>,
  projectLanguages: ReadonlyArray<ILanguage>,
  startFromLanguageId: Uuid,
): Uuid | null => {
  if (!translatedLanguageIds.length) {
    return null;
  }

  const evaluatedLanguageIds = new Set<Uuid>();
  let currentlyEvaluatedLanguage: ILanguage | undefined = getLanguageWithMatchingId(
    projectLanguages,
    startFromLanguageId,
  );

  while (currentlyEvaluatedLanguage) {
    if (evaluatedLanguageIds.has(currentlyEvaluatedLanguage.id)) {
      return null; // A cycle in the fallback path detected
    }

    if (translatedLanguageIds.includes(currentlyEvaluatedLanguage.id)) {
      return currentlyEvaluatedLanguage.id;
    }

    evaluatedLanguageIds.add(currentlyEvaluatedLanguage.id);
    currentlyEvaluatedLanguage = getLanguageWithMatchingId(
      projectLanguages,
      currentlyEvaluatedLanguage.fallbackLanguageId,
    );
  }

  return null;
};

const getLanguageWithMatchingId = (
  languages: ReadonlyArray<ILanguage>,
  languageId: Uuid | null,
): ILanguage | undefined => languages.find((l) => l.id === languageId);

export const getFallbackLanguageIdForItem = (
  translatedVariants: ReadonlyArray<IListingVariantData>,
  activeLanguages: ReadonlyArray<ILanguage>,
  selectedLanguageId: Uuid,
): Uuid | null => {
  const publishedItemVariantIds = translatedVariants
    .filter(isVariantPublished)
    .map((v) => v.id.variantId);
  const translatedLanguageIds = translatedVariants.map((v) => v.id.variantId);

  return (
    findNearestTranslatedFallbackLanguageId(
      publishedItemVariantIds,
      activeLanguages,
      selectedLanguageId,
    ) ??
    findNearestTranslatedFallbackLanguageId(
      translatedLanguageIds,
      activeLanguages,
      selectedLanguageId,
    )
  );
};

enum Suffix {
  Default = 0,
  None = 1,
}

export const getLanguage = (languages: ILanguagesData, languageId: Uuid): ILanguage | null => {
  if (languages.defaultLanguage.id === languageId) {
    return languages.defaultLanguage;
  }

  return languages.byId.get(languageId) ?? null;
};

const getAllLanguages = (languageData: ILanguagesData, suffixStrategy: Suffix): Languages =>
  Immutable.OrderedMap<Uuid, ILanguage>()
    .set(
      languageData.defaultLanguage.id,
      suffixStrategy === Suffix.Default
        ? addDefaultLanguageSuffix(languageData.defaultLanguage)
        : languageData.defaultLanguage,
    )
    .merge(languageData.byId);

const getLanguageIds = (languages: Languages): Immutable.OrderedSet<Uuid> =>
  languages.map((language: ILanguage) => language.id).toOrderedSet();

export const getAllLanguagesWithDefaultSuffix = memoize.maxOne((languageData: ILanguagesData) =>
  getAllLanguages(languageData, Suffix.Default),
);

const getActiveLanguages = (languageData: ILanguagesData, suffixStrategy: Suffix): Languages =>
  getAllLanguages(languageData, suffixStrategy).filter(isLanguageActive).toOrderedMap();

export const getActiveLanguagesWithDefaultSuffix = memoize.maxOne(
  (languageData: ILanguagesData): Languages => getActiveLanguages(languageData, Suffix.Default),
);

export const getActiveLanguagesWithFallbackChain = memoize.maxOne(
  (languageData: ILanguagesData): ReadonlyArray<ILanguage> =>
    getAllLanguages(languageData, Suffix.None)
      .map((language) =>
        language.id !== DefaultVariantId && language.fallbackLanguageId === null
          ? { ...language, fallbackLanguageId: DefaultVariantId }
          : language,
      )
      .toArray(),
);

export const getAllLanguageIds = memoize.maxOne(
  (languages: ILanguagesData): Immutable.OrderedSet<Uuid> =>
    getLanguageIds(getAllLanguages(languages, Suffix.None)),
);

export const getActiveLanguageIds = memoize.maxOne(
  (languages: ILanguagesData): Immutable.OrderedSet<Uuid> =>
    getLanguageIds(getActiveLanguages(languages, Suffix.None)),
);

export const getAllActiveLanguagesForCurrentUserInAnyCollection = memoize.maxOne(
  (user: IUser, projectId: Uuid, languages: ILanguagesData, capability: Capability): Languages => {
    const allActiveLanguages = getActiveLanguages(languages, Suffix.Default);
    const allowedIds = getLanguageIdsWithAllowedCapabilityInAnyCollection(
      getNormalizedRolesWithSettingsForUser(user, projectId),
      getLanguageIds(allActiveLanguages),
      capability,
      null,
    );
    return allActiveLanguages
      .filter((language: ILanguage) => allowedIds.has(language.id))
      .toOrderedMap();
  },
);

export const getAllActiveLanguagesForCurrentUserForCollection = memoize.maxOne(
  (
    user: IUser,
    projectId: Uuid,
    collectionId: Uuid | null,
    languages: ILanguagesData,
    capability: Capability,
  ): Languages => {
    const allActiveLanguages = getActiveLanguages(languages, Suffix.Default);
    const allowedIds = getLanguageIdsWithAllowedCapabilityForCollection(
      getNormalizedRolesWithSettingsForUser(user, projectId),
      collectionId,
      getLanguageIds(allActiveLanguages),
      capability,
    );
    return allActiveLanguages
      .filter((language: ILanguage) => allowedIds.has(language.id))
      .toOrderedMap();
  },
);
