import { memoize } from '@kontent-ai/memoization';
import { Direction } from '@kontent-ai/types';
import { assert, Collection } from '@kontent-ai/utils';
import { ContentBlock, ContentState, EntityInstance, SelectionState } from 'draft-js';
import Immutable from 'immutable';
import { BaseBlockType, BlockType, getBaseType } from './blocks/blockType.ts';
import {
  findSiblingBlock,
  isBlockNestedIn,
  isCustomBlockSleeve,
  isNotEmptyTextBlock,
  isTextBlock,
} from './blocks/blockTypeUtils.ts';
import { getBlockKey, getFullBlockType } from './blocks/editorBlockGetters.ts';
import {
  BlockEdgeStatus,
  findBlockIndex,
  getBlockEdgeStatus,
  getBlocksDirection,
} from './blocks/editorBlockUtils.ts';
import { EntityMap, getBlocks, getEntityMap } from './general/editorContentGetters.ts';
import { IContentChangeInput, IContentChangeResult } from './general/editorContentUtils.ts';
import {
  IAggregatedMetadata,
  aggregateMetadata,
  getEntities,
  getMemoizedAggregatedMetadata,
  getMetadataAtCaretPosition,
  getMetadataForBlockChars,
} from './metadata/editorMetadataUtils.ts';

const appendSelection = (selections: Array<SelectionState>, newSelection: SelectionState) => {
  const lastSelection = selections[selections.length - 1];
  const blockKey = newSelection.getStartKey();
  const start = newSelection.getStartOffset();
  const end = newSelection.getEndOffset();
  const isImmediatelyAfterLastRange =
    lastSelection &&
    lastSelection.getStartKey() === blockKey &&
    lastSelection.getEndOffset() === start;

  if (isImmediatelyAfterLastRange) {
    // Style ranges may be split by another nested or overlapping styles, we need to detect this and merge such ranges in the output
    const selection = createSelection(blockKey, lastSelection.getStartOffset(), blockKey, end);
    selections[selections.length - 1] = selection;
  } else {
    const selection = createSelection(blockKey, start, blockKey, end);
    selections.push(selection);
  }
};

export const getSelectionOfAllContent = (content: ContentState): SelectionState =>
  createSelection(
    content.getFirstBlock().getKey(),
    0,
    content.getLastBlock().getKey(),
    content.getLastBlock().getLength(),
  );

export function createSelection(
  anchorKey: string,
  anchorOffset: number = 0,
  focusKey?: string,
  focusOffset?: number,
  direction?: Direction,
  hasFocus?: boolean,
): SelectionState {
  return SelectionState.createEmpty(anchorKey).merge({
    anchorOffset,
    focusKey: focusKey || anchorKey,
    focusOffset: focusOffset || (focusKey ? 0 : anchorOffset),
    isBackward: direction === Direction.Backward,
    hasFocus: !!hasFocus,
  });
}

export const createCollapsedSelectionAtBlockEdge = (
  block: ContentBlock,
  edgePosition: 'start' | 'end',
  hasFocus: boolean,
): SelectionState => {
  const offset = edgePosition === 'end' ? block.getLength() : 0;
  const key = getBlockKey(block);

  return createSelection(key, offset, key, offset, Direction.Forward, hasFocus);
};

export function getSelectionForEntity(
  content: ContentState,
  entityKey: string,
): SelectionState | null {
  const blocks = getBlocks(content);

  for (let i = 0; i < blocks.length; i++) {
    const block = blocks[i];
    let entitySelection: SelectionState | null = null;
    block?.findEntityRanges(
      (character) => character.getEntity() === entityKey,
      (start, end) => {
        const blockKey = block.getKey();
        if (!entitySelection) {
          entitySelection = createSelection(blockKey, start, blockKey, end);
        }
      },
    );

    if (entitySelection) {
      return entitySelection;
    }
  }

  return null;
}

export function isSingleWholeBlockSelection(
  selection: SelectionState,
  content: ContentState,
): boolean {
  if (selection.getStartOffset() || !isSelectionWithinOneBlock(selection)) {
    return false;
  }

  const block = content.getBlockForKey(selection.getFocusKey());
  if (!block) {
    return false;
  }

  return selection.getEndOffset() === block.getLength();
}

export function isSelectionWithinOneBlock(selection: SelectionState): boolean {
  return selection.getFocusKey() === selection.getAnchorKey();
}

const extractBlocks = memoize.weak(
  (
    blocks: ReadonlyArray<ContentBlock>,
    start: number,
    end: number,
    predicate?: (block: ContentBlock, index: number) => boolean,
  ): ReadonlyArray<ContentBlock> => {
    const result = Array<ContentBlock>();

    for (let i = start; i <= end; i++) {
      const block = blocks[i];
      if (block && (!predicate || predicate(block, i))) {
        result.push(block);
      }
    }

    return result;
  },
);

export function getBlocksAtSelection(
  content: ContentState,
  selection: SelectionState,
  predicate?: (block: ContentBlock, index: number) => boolean,
): ReadonlyArray<ContentBlock> {
  const startKey = selection.getStartKey();
  const endKey = selection.getEndKey();

  if (startKey === endKey) {
    const block = content.getBlockForKey(startKey);
    return block ? [block] : [];
  }

  const startBlock = content.getBlockForKey(startKey);
  const endBlock = content.getBlockForKey(endKey);

  const blocks = getBlocks(content);
  const startIndex = blocks.indexOf(startBlock);
  const endIndex = blocks.indexOf(endBlock);

  return extractBlocks(blocks, startIndex, endIndex, predicate);
}

export function getMetadataAtSelection(
  content: ContentState,
  selection: SelectionState,
): IAggregatedMetadata | null {
  const startBlock = content.getBlockForKey(selection.getStartKey());
  const startOffset = selection.getStartOffset();

  if (selection.isCollapsed()) {
    const metadata = getMetadataAtCaretPosition(startBlock, startOffset);
    return getMemoizedAggregatedMetadata(metadata);
  }

  const endOffset = selection.getEndOffset();
  if (isSelectionWithinOneBlock(selection)) {
    const metadata = getMetadataForBlockChars(startBlock, startOffset, endOffset);
    return getMemoizedAggregatedMetadata(metadata);
  }

  const blocksAtSelection = getBlocksAtSelection(content, selection);

  const firstBlockAtSelection = blocksAtSelection[0];
  assert(
    firstBlockAtSelection,
    () =>
      `The first block at selection is not a content block. Value: ${JSON.stringify(
        firstBlockAtSelection,
      )}`,
  );

  const lastBlockAtSelection = blocksAtSelection[blocksAtSelection.length - 1];
  if (!lastBlockAtSelection) {
    return null;
  }

  const middleBlocksAtSelection = blocksAtSelection.slice(1, blocksAtSelection.length - 1);

  const metadataForBlocks = [
    getMetadataForBlockChars(firstBlockAtSelection, startOffset, firstBlockAtSelection.getLength()),
    ...middleBlocksAtSelection.map((block) =>
      getMetadataForBlockChars(block, 0, block.getLength()),
    ),
    getMetadataForBlockChars(lastBlockAtSelection, 0, endOffset),
  ];
  const aggregated = aggregateMetadata(metadataForBlocks);
  return getMemoizedAggregatedMetadata(aggregated);
}

export function getEntitiesAtSelection(
  metadata: IAggregatedMetadata | null,
  entityMap: EntityMap,
): {
  readonly topLevelEntities: ReadonlyArray<EntityInstance>;
  readonly tableEntities: ReadonlyArray<EntityInstance>;
} {
  const topLevelEntities = getEntities(
    entityMap,
    Immutable.OrderedSet<string>(metadata?.entityKeyAtAnyTopLevelChars?.toArray() ?? []),
  );
  const tableEntities = getEntities(
    entityMap,
    Immutable.OrderedSet<string>(metadata?.entityKeyAtAnyTableChars?.toArray() ?? []),
  );

  return { topLevelEntities, tableEntities };
}

export function getAllEntitiesAtSelection(
  content: ContentState,
  selection: SelectionState,
): ReadonlyArray<EntityInstance> {
  const metadataAtSelection = getMetadataAtSelection(content, selection);
  const entityMap = getEntityMap(content);

  const { topLevelEntities, tableEntities } = getEntitiesAtSelection(
    metadataAtSelection,
    entityMap,
  );
  return Collection.getValues(new Set([...topLevelEntities, ...tableEntities]));
}

const getMemoizedBlockTypes = memoize.maxN(
  (...blockTypes: ReadonlyArray<BlockType>): ReadonlySet<BlockType> => new Set(blockTypes),
  // We currently recognize several tens of block types (including nested ones in tables) so the limit of 100 seems enough for typical active combinations
  100,
);

export function getFullBlockTypesAtSelection(
  content: ContentState,
  selection: SelectionState,
): ReadonlySet<BlockType> {
  const blocks = getBlocks(content);
  const validBlocks = getBlocksAtSelection(
    content,
    selection,
    (_block, index) => !isCustomBlockSleeve(index, blocks),
  );
  const validBlockTypes = new Set(validBlocks.map(getFullBlockType));

  return getMemoizedBlockTypes(...validBlockTypes);
}

export const getBaseBlockTypes = memoize.weak(
  (fullBlockTypes: ReadonlySet<BlockType>): ReadonlySet<BaseBlockType> =>
    new Set(Collection.getValues(fullBlockTypes).map(getBaseType)),
);

export function getFullySelectedBlocks(
  content: ContentState,
  selection: SelectionState,
  includeParent: boolean = true,
): ReadonlyArray<ContentBlock> {
  if (selection.isCollapsed()) {
    return [];
  }
  const blocks = getBlocks(content);

  let selectionStartIndex = findBlockIndex(blocks, selection.getStartKey());
  let selectionEndIndex = findBlockIndex(blocks, selection.getEndKey());

  // If the selection is somehow inconsistent (not found blocks or wrong order), we can't evaluate fully selected blocks
  if (selectionStartIndex < 0 || selectionEndIndex < 0 || selectionStartIndex > selectionEndIndex) {
    return [];
  }

  const skipFirstBlockAtSelection = selection.getStartOffset() > 0;
  if (skipFirstBlockAtSelection) {
    selectionStartIndex++;
  }

  const selectionEndBlock = blocks[selectionEndIndex];
  assert(selectionEndBlock, () => 'Selection end block is not a content block.');
  const skipLastBlockAtSelection = selection.getEndOffset() < selectionEndBlock.getLength();
  if (skipLastBlockAtSelection) {
    selectionEndIndex--;
  }

  // The range of fully selected blocks could have collapsed into nothing after previous adjustments
  if (selectionStartIndex > selectionEndIndex) {
    return [];
  }

  if (includeParent) {
    const parentCandidate = blocks[selectionStartIndex - 1];
    if (parentCandidate) {
      const selectionStartBlock = blocks[selectionStartIndex];
      assert(selectionStartBlock, () => 'Selection start block is not a content block.');
      const includeParentAtSelectionStart = isBlockNestedIn(selectionStartBlock, parentCandidate);
      if (includeParentAtSelectionStart) {
        selectionStartIndex--;
      }
    }
  }

  const fullySelectedBlockCandidates = blocks.slice(selectionStartIndex, selectionEndIndex + 1);

  if (selectionEndIndex >= blocks.length - 1) {
    return fullySelectedBlockCandidates;
  }

  // Exclude parents whose content (sub-tree) goes over the selection
  const fullySelectedBlocks =
    selectionEndIndex < blocks.length
      ? fullySelectedBlockCandidates.filter((_block, index) => {
          const next = findSiblingBlock(selectionStartIndex + index, blocks, Direction.Forward);
          const subtreeEndsWithinSelection = next.index <= selectionEndIndex + 1;
          return subtreeEndsWithinSelection;
        })
      : fullySelectedBlockCandidates;

  return fullySelectedBlocks;
}

export function setHasFocus(selection: SelectionState, hasFocus?: boolean): SelectionState {
  return hasFocus !== undefined ? selection.merge({ hasFocus }) : selection;
}

export function setContentSelection(
  content: ContentState,
  selectionBefore: SelectionState,
  selectionAfter: SelectionState,
  hasFocus?: boolean,
): ContentState {
  // Keep focus upon content modification if focus was previously there
  const setFocusFlag =
    hasFocus !== undefined ? hasFocus : selectionBefore.getHasFocus() ? true : undefined;
  const updatedSelectionAfter = setHasFocus(selectionAfter, setFocusFlag);

  return content.merge({
    selectionBefore,
    selectionAfter: updatedSelectionAfter,
  }) as ContentState;
}

export function isSameSelectionRange(first: SelectionState, second: SelectionState): boolean {
  return (
    first.getStartKey() === second.getStartKey() &&
    first.getStartOffset() === second.getStartOffset() &&
    first.getEndKey() === second.getEndKey() &&
    first.getEndOffset() === second.getEndOffset()
  );
}

export function moveCaretToSelectionEnd(input: IContentChangeInput): IContentChangeResult {
  const selection = createSelection(input.selection.getEndKey(), input.selection.getEndOffset());
  const content = setContentSelection(input.content, input.content.getSelectionBefore(), selection);

  return {
    wasModified: true,
    content,
    selection,
  };
}

export function moveCaretToBlock(
  input: IContentChangeInput,
  blockKey: string,
): IContentChangeResult {
  const selection = createSelection(blockKey);
  const content = setContentSelection(input.content, input.content.getSelectionBefore(), selection);

  return {
    wasModified: true,
    content,
    selection,
  };
}

export const isTextInSelectionRange = (
  selection: SelectionState,
  contentState: ContentState,
): boolean => {
  const startBlockKey = selection.getStartKey();
  const startOffset = selection.getStartOffset();
  const endBlockKey = selection.getEndKey();
  const endOffset = selection.getEndOffset();

  const startBlock = contentState.getBlockForKey(startBlockKey);
  const isTextSelectedInStartBlock =
    startOffset < startBlock.getText().length && isTextBlock(startBlock);
  if (isTextSelectedInStartBlock) {
    return true;
  }

  const endBlock = contentState.getBlockForKey(endBlockKey);
  const isTextSelectedInEndBlock = endOffset > 0 && isTextBlock(endBlock);
  if (isTextSelectedInEndBlock) {
    return true;
  }

  const remainingSelectedBlocks = getFullySelectedBlocks(contentState, selection);
  return (
    remainingSelectedBlocks.length > 0 &&
    remainingSelectedBlocks.some((block: ContentBlock) => isNotEmptyTextBlock(block))
  );
};

export function getSelectionsForInlineStyle(
  content: ContentState,
  style: string,
): ReadonlyArray<SelectionState> {
  const blocks = getBlocks(content);
  const styleSelections: Array<SelectionState> = [];

  blocks.forEach((block) => {
    block.findStyleRanges(
      (character) => character.hasStyle(style),
      (start, end) => {
        const blockKey = block.getKey();
        const newStyleSelection = createSelection(blockKey, start, blockKey, end);
        appendSelection(styleSelections, newStyleSelection);
      },
    );
  });

  return styleSelections;
}

export function getSelectedText(contentState: ContentState, selection: SelectionState): string {
  let selectedText = '';

  if (isSelectionWithinOneBlock(selection)) {
    const contentBlock = contentState.getBlockForKey(selection.getStartKey());
    const start = selection.getStartOffset();
    const end = selection.getEndOffset();
    selectedText = contentBlock.getText().slice(start, end);
  } else {
    const startBlock = contentState.getBlockForKey(selection.getStartKey());
    const start = selection.getStartOffset();
    selectedText += startBlock.getText().slice(start);

    let blockAfter = contentState.getBlockAfter(startBlock.getKey());
    while (blockAfter && blockAfter.getKey() !== selection.getEndKey()) {
      selectedText += `\n${blockAfter.getText()}`;
      blockAfter = contentState.getBlockAfter(blockAfter.getKey());
    }

    if (blockAfter) {
      const end = selection.getEndOffset();
      selectedText += `\n${blockAfter.getText().slice(0, end)}`;
    }
  }

  return selectedText;
}

export type SelectionEdgeStatus = {
  readonly start: BlockEdgeStatus;
  readonly end: BlockEdgeStatus;
};

const getSelectionEdgeStatusResult = memoize.allForever(
  (start: BlockEdgeStatus, end: BlockEdgeStatus): SelectionEdgeStatus => {
    return {
      start,
      end,
    };
  },
);

export function getSelectionEdgeStatus(
  content: ContentState,
  selection: SelectionState,
): SelectionEdgeStatus {
  const start = getBlockEdgeStatus(
    content.getBlockForKey(selection.getStartKey()),
    selection.getStartOffset(),
  );
  const end = getBlockEdgeStatus(
    content.getBlockForKey(selection.getEndKey()),
    selection.getEndOffset(),
  );

  return getSelectionEdgeStatusResult(start, end);
}

export const filterSelection = (
  content: ContentState,
  selection: SelectionState,
  predicate: (block: ContentBlock) => boolean,
): ReadonlyArray<SelectionState> => {
  if (selection.isCollapsed()) {
    return [selection];
  }

  const blocksAtSelection = getBlocksAtSelection(content, selection);
  const styleSelections: Array<SelectionState> = [];

  blocksAtSelection.forEach((block, index) => {
    const blockKey = block.getKey();
    const start = index === 0 ? selection.getStartOffset() : 0;
    const end =
      index === blocksAtSelection.length - 1 ? selection.getEndOffset() : block.getLength();

    const newStyleSelection = createSelection(blockKey, start, blockKey, end);
    if (predicate(block)) {
      appendSelection(styleSelections, newStyleSelection);
    }
  });

  return styleSelections;
};

export const doesSelectionContainText = (
  selection: SelectionState,
  metadataAtSelection: IAggregatedMetadata | null,
): boolean => {
  return !selection.isCollapsed() && metadataAtSelection !== null;
};

export const getEditorSelectionDirection = (
  content: ContentState,
  anchorKey: string,
  anchorOffset: number,
  focusKey: string,
  focusOffset: number,
): Direction => {
  if (anchorKey === focusKey) {
    return anchorOffset <= focusOffset ? Direction.Forward : Direction.Backward;
  }
  return getBlocksDirection(content, anchorKey, focusKey) ?? Direction.Forward;
};

// This is needed when a content which is located before your new selection (selectionToPreserve) is changed.
// It is needed only when the content is located in the same block as the selection.
// For example
// @Instruction-in-progress the block text continues <text selected while the instruction is in progress>
// When the instruction in progress is replaced by the AI result, the selection done in the meantime would move.
// This method modifies the selection so that the same text stays selected.
export const preserveSelectionOverContentChange = (
  selectionToPreserve: SelectionState,
  modifiedContentSelection: SelectionState,
  selectionAfterContentModification: SelectionState,
): SelectionState => {
  // Whether content is added, removed or replaced by shorter/longer
  // 1) We can ignore any selection points in blocks outside the selection (with different block key)
  // 2) We can keep any selection point before the modified content selection, because the modification shouldn't affect it in any way
  // 3) We need to adjust any selection point in the same block as the modified content that is after the modified content selection
  const adjustSelectionAnchor =
    modifiedContentSelection.getEndKey() === selectionToPreserve.getAnchorKey() &&
    modifiedContentSelection.getEndOffset() <= selectionToPreserve.getAnchorOffset();
  const adjustSelectionFocus =
    modifiedContentSelection.getEndKey() === selectionToPreserve.getFocusKey() &&
    modifiedContentSelection.getEndOffset() <= selectionToPreserve.getFocusOffset();

  const endOffsetChange =
    selectionAfterContentModification.getEndOffset() - modifiedContentSelection.getEndOffset();

  const preservedSelection =
    adjustSelectionAnchor || adjustSelectionFocus
      ? createSelection(
          adjustSelectionAnchor
            ? selectionAfterContentModification.getEndKey()
            : selectionToPreserve.getAnchorKey(),

          adjustSelectionAnchor
            ? selectionToPreserve.getAnchorOffset() + endOffsetChange
            : selectionToPreserve.getAnchorOffset(),

          adjustSelectionFocus
            ? selectionAfterContentModification.getEndKey()
            : selectionToPreserve.getFocusKey(),

          adjustSelectionFocus
            ? selectionToPreserve.getFocusOffset() + endOffsetChange
            : selectionToPreserve.getFocusOffset(),

          selectionToPreserve.getIsBackward() ? Direction.Backward : Direction.Forward,

          selectionToPreserve.getHasFocus(),
        )
      : selectionToPreserve;

  return preservedSelection;
};

export const preserveSelectionOverContentChanges = (
  selectionToPreserve: SelectionState,
  ...modificationSelections: ReadonlyArray<SelectionState>
): SelectionState => {
  return modificationSelections.reduce<{
    readonly preservedSelection: SelectionState;
    readonly modifiedContentSelection: SelectionState | null;
  }>(
    (previous, selectionAfterContentModification) => ({
      preservedSelection: previous.modifiedContentSelection
        ? preserveSelectionOverContentChange(
            previous.preservedSelection,
            previous.modifiedContentSelection,
            selectionAfterContentModification,
          )
        : previous.preservedSelection,
      modifiedContentSelection: selectionAfterContentModification,
    }),
    {
      preservedSelection: selectionToPreserve,
      modifiedContentSelection: null,
    },
  ).preservedSelection;
};

export const getBoundingSelection = (selections: ReadonlyArray<SelectionState>): SelectionState => {
  const first = Collection.getFirst(selections);
  const last = Collection.getLast(selections);
  assert(first, () => 'First selection is falsy');
  assert(last, () => 'Last selection is falsy');

  return createSelection(
    first.getStartKey(),
    first.getStartOffset(),
    last.getEndKey(),
    last.getEndOffset(),
  );
};

export const updateBlockKeyInSelection = (
  selection: SelectionState,
  blockKey: string,
  newBlockKey: string,
): SelectionState => {
  if (newBlockKey === blockKey) {
    return selection;
  }

  const anchorKey = selection.getAnchorKey();
  const focusKey = selection.getFocusKey();

  return createSelection(
    anchorKey === blockKey ? newBlockKey : anchorKey,
    selection.getAnchorOffset(),
    focusKey === blockKey ? newBlockKey : focusKey,
    selection.getFocusOffset(),
    selection.getIsBackward() ? Direction.Backward : Direction.Forward,
    selection.getHasFocus(),
  );
};

export function moveSelectionFocus(
  content: ContentState,
  selection: SelectionState,
  blockKey: string,
  offset: number,
): SelectionState {
  return createSelection(
    selection.getAnchorKey(),
    selection.getAnchorOffset(),
    blockKey,
    offset,
    getEditorSelectionDirection(
      content,
      selection.getAnchorKey(),
      selection.getAnchorOffset(),
      blockKey,
      offset,
    ),
    selection.getHasFocus(),
  );
}
