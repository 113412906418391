import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../../../../../../@types/Dispatcher.type.ts';
import { IStore } from '../../../../../../_shared/stores/IStore.type.ts';
import {
  ITypeElementSummaryCallbackProps,
  ITypeElementSummaryDataProps,
  TypeElementSummary as TypeElementSummaryComponent,
} from '../../../components/typeElements/shared/TypeElementSummary.tsx';
import {
  DefaultValueSetSummary,
  LimitationsSetSummary,
} from '../../../constants/summaryMessageTemplates.ts';
import { ITaxonomyTypeElementData } from '../../../models/elements/TaxonomyTypeElementData.ts';
import { IBaseTypeElementSummaryProps } from '../../../types/IBaseTypeElementSummaryProps.type.ts';
import { hasTaxonomyDefaultValue } from '../../../utils/typeElementDefaultValueUtils.ts';
import {
  getBaseTypeElementSummarySegments,
  joinSummarySegments,
} from '../../../utils/typeElementMessagesUtils.ts';

interface ITaxonomyTypeElementSummaryProps
  extends IBaseTypeElementSummaryProps<ITaxonomyTypeElementData> {}

export function getTaxonomyTypeElementSummary(typeElement: ITaxonomyTypeElementData): string {
  const summarySegments: Array<string> = [...getBaseTypeElementSummarySegments(typeElement)];
  const hasAnyLimitations = typeElement.minItems || typeElement.maxItems;

  if (hasAnyLimitations) {
    summarySegments.push(LimitationsSetSummary);
  }

  if (hasTaxonomyDefaultValue(typeElement)) {
    summarySegments.push(DefaultValueSetSummary);
  }

  return joinSummarySegments(summarySegments);
}

function mapStateToProps(
  _state: IStore,
  ownProps: ITaxonomyTypeElementSummaryProps,
): ITypeElementSummaryDataProps {
  const element = ownProps.typeElementData;
  const summaryText = getTaxonomyTypeElementSummary(element);

  return {
    isActive: ownProps.isActive,
    summaryText,
  };
}

function mapDispatchToProps(
  _dispatch: Dispatch,
  ownProps: ITaxonomyTypeElementSummaryProps,
): ITypeElementSummaryCallbackProps {
  return ownProps;
}

export const TaxonomyTypeElementSummary: React.ComponentType<ITaxonomyTypeElementSummaryProps> =
  connect(mapStateToProps, mapDispatchToProps)(TypeElementSummaryComponent);

TaxonomyTypeElementSummary.displayName = 'TaxonomyTypeElementSummary';
