import { assert } from '@kontent-ai/utils';
import Immutable from 'immutable';
import {
  ICoordinates,
  getCoordinatesFromDepth,
  getDepthFromCoordinates,
} from '../../../../richText/plugins/tables/api/depthCoordinatesConversion.ts';
import { BlockType } from '../../../../richText/utils/blocks/blockType.ts';
import { DiffType } from './DiffType.ts';
import { IDiffInputBlock, IDiffResultBlock } from './diffDataUtils.ts';

interface ITableSize {
  readonly rows: number;
  readonly cols: number;
}

export function getTableSize(blocks: ReadonlyArray<IDiffInputBlock>): ITableSize {
  let maxX = 0;
  let maxY = 0;

  const blockIsFalsyMessage = (index: number) => () => `Block at index ${index} is falsy.`;

  for (let i = 0; i < blocks.length; i++) {
    const block = blocks[i];
    assert(!!block, blockIsFalsyMessage(i));
    const depth = block.depth;
    const coords = getCoordinatesFromDepth(depth);
    if (coords.x > maxX) {
      maxX = coords.x;
    }
    if (coords.y > maxY) {
      maxY = coords.y;
    }
  }

  return {
    rows: maxY + 1,
    cols: maxX + 1,
  };
}

export function createMissingOutputCell(coords: ICoordinates): IDiffResultBlock {
  return {
    type: BlockType.TableCell,
    characters: [],
    depth: getDepthFromCoordinates(coords),
    diffType: DiffType.Dummy,
    data: Immutable.Map(),
    blocks: [
      {
        type: BlockType.Unstyled,
        characters: [],
        depth: 0,
        diffType: DiffType.Dummy,
        data: Immutable.Map(),
      },
    ],
  };
}

export type DiffTableResult = (IDiffResultBlock | undefined)[][];

export function insertTableRow(table: DiffTableResult, atIndex: number): void {
  table.splice(atIndex, 0, Array(table[0]?.length ?? 0));
  table.forEach((row, rowIndex) => {
    if (rowIndex > atIndex) {
      row.forEach((cell, colIndex) => {
        if (cell) {
          row[colIndex] = {
            ...cell,
            depth: cell.depth + 1000,
          };
        }
      });
    }
  });
}

export function insertTableColumn(resultTable: DiffTableResult, atIndex: number): void {
  resultTable.forEach((row) => {
    row.splice(atIndex, 0, undefined);
    row.forEach((cell, colIndex) => {
      if (cell && colIndex > atIndex) {
        row[colIndex] = {
          ...cell,
          depth: cell.depth + 1,
        };
      }
    });
  });
}

export function createEmptyTable(newTableSize: ITableSize): DiffTableResult {
  return Array(newTableSize.rows)
    .fill(undefined)
    .map(() => Array<IDiffResultBlock | undefined>(newTableSize.cols).fill(undefined));
}
