import styled, { css } from 'styled-components';
import { IBaseAssetTileProps } from '../BaseAssetTile.tsx';
import { assetTileBorderRadius } from '../decisionTokens.ts';

interface IStyledPaperProps extends Pick<IBaseAssetTileProps, 'readonlyState'> {}

export const StyledBackground = styled.div<IStyledPaperProps>`
  border-radius: ${assetTileBorderRadius};
  width: 100%;
  height: 100%;
  position: relative;
  display: inline-block;
  ${({ readonlyState }) =>
    readonlyState &&
    css`
    background-color: inherit;
  `};
`;
