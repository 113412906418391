import { memoize } from '@kontent-ai/memoization';
import { AiFeatureSet } from '../constants/AiFeatureSet.ts';
import { IStore } from '../stores/IStore.type.ts';

export const isInlineAiEnabled = (store: IStore): boolean =>
  selectEnabledAiFeatureSets(store).includes(AiFeatureSet.Inline);

export const isAiImageTaggingEnabled = (store: IStore): boolean =>
  selectEnabledAiFeatureSets(store).includes(AiFeatureSet.ImageTagging);

export const isAiImageDescriptionEnabled = (store: IStore): boolean =>
  selectEnabledAiFeatureSets(store).includes(AiFeatureSet.ImageDescription);

export const isAiItemCategorizationEnabled = (store: IStore): boolean =>
  selectEnabledAiFeatureSets(store).includes(AiFeatureSet.Taxonomies);

export const isEnablingLinkedItemsActivated = (store: IStore): boolean =>
  selectEnabledAiFeatureSets(store).includes(AiFeatureSet.EnablingLinkedItems);

export const isAiLinkedItemsEnabled = (store: IStore): boolean =>
  selectEnabledAiFeatureSets(store).includes(AiFeatureSet.LinkedItems);

export const isAiLinkedItemsScoreEnabled = (store: IStore): boolean =>
  selectEnabledAiFeatureSets(store).includes(AiFeatureSet.LinkedItemsScore);

export const isAiReviewEnabled = (store: IStore): boolean =>
  selectEnabledAiFeatureSets(store).includes(AiFeatureSet.Review);

export const isEnablingAiSearchActivated = (store: IStore): boolean =>
  selectEnabledAiFeatureSets(store).includes(AiFeatureSet.EnablingSearch);

export const isAiSearchEnabled = (store: IStore): boolean =>
  selectEnabledAiFeatureSets(store).includes(AiFeatureSet.Search);

export const isAiChangeToneEnabled = (store: IStore): boolean =>
  selectEnabledAiFeatureSets(store).includes(AiFeatureSet.ChangeTone);

export const isAiCreateTitleEnabled = (store: IStore): boolean =>
  selectEnabledAiFeatureSets(store).includes(AiFeatureSet.CreateTitle);

export const isAiImproveContentEnabled = (store: IStore): boolean =>
  selectEnabledAiFeatureSets(store).includes(AiFeatureSet.ImproveContent);

export const isAiMakeShorterEnabled = (store: IStore): boolean =>
  selectEnabledAiFeatureSets(store).includes(AiFeatureSet.MakeShorter);

export const isAiMatchWritingStyleEnabled = (store: IStore): boolean =>
  isAiChangeToneEnabled(store) &&
  selectEnabledAiFeatureSets(store).includes(AiFeatureSet.MatchWritingStyle);

export const isAiSummarizeEnabled = (store: IStore): boolean =>
  selectEnabledAiFeatureSets(store).includes(AiFeatureSet.Summarize);

export const isAnyAskAiFeatureEnabled = (store: IStore): boolean =>
  isAiChangeToneEnabled(store) ||
  isAiCreateTitleEnabled(store) ||
  isAiImproveContentEnabled(store) ||
  isAiMakeShorterEnabled(store) ||
  isAiMatchWritingStyleEnabled(store) ||
  isAiSummarizeEnabled(store);

export const isAiAuthorAssistEnabled = (store: IStore): boolean =>
  isInlineAiEnabled(store) || isAnyAskAiFeatureEnabled(store);

export const selectEnabledAiFeatureSets = ({
  sharedApp: { userProperties, projectProperties },
}: IStore): readonly AiFeatureSet[] => {
  const joinedFeatureSets = joinFeatureSets(
    userProperties.aiFeatureSet,
    projectProperties.AiFeatureSet,
  );
  return filterOutNonAiFeatureSetValues(joinedFeatureSets);
};

const joinFeatureSets = memoize.maxOne(
  <T>(userSet: readonly T[], projectSet: readonly T[]): readonly T[] => [
    ...new Set([...userSet, ...projectSet]),
  ],
);

const filterOutNonAiFeatureSetValues = memoize.maxOne(
  (featureSets: readonly unknown[]): readonly AiFeatureSet[] =>
    featureSets.filter((feature): feature is AiFeatureSet => isAiFeatureSetValue(feature)),
);

const isAiFeatureSetValue = (feature: unknown): feature is AiFeatureSet =>
  typeof feature === 'string' && (Object.values(AiFeatureSet) as string[]).includes(feature);
