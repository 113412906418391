import { IconButton } from '@kontent-ai/component-library/Button';
import { Inline } from '@kontent-ai/component-library/Inline';
import { Column, Row } from '@kontent-ai/component-library/Row';
import { ItemId } from '@kontent-ai/component-library/Selects';
import { Spacing } from '@kontent-ai/component-library/tokens';
import { useCallback, useMemo, useState } from 'react';
import { trackUserEvent } from '../../../../../../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../../../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../../../../../../_shared/hooks/useDispatch.ts';
import { WebSpotlightPreviewResolutionChangeOrigin } from '../../../../../../../../../_shared/models/TrackUserEventData.ts';
import { WebSpotlightPreviewResolutionInputs } from '../../../../../../../../webSpotlight/components/preview/Controls/WebSpotlightPreviewResolutionInputs.tsx';
import { WebSpotlightPreviewResolutionTypeSelect } from '../../../../../../../../webSpotlight/components/preview/Controls/WebSpotlightPreviewResolutionTypeSelect.tsx';
import { WebSpotlightPreviewScaleSelect } from '../../../../../../../../webSpotlight/components/preview/Controls/WebSpotlightPreviewScaleSelect.tsx';
import {
  WebSpotlightPreviewResolutionType,
  fitToScreenResolution,
  webSpotlightPreviewResolutionTypeOptions,
} from '../../../../../../../../webSpotlight/models/webSpotlightPreviewResolutionType.ts';
import {
  getWebSpotlightPreviewScaleOptions,
  scaleIdToValue,
} from '../../../../../../../../webSpotlight/models/webSpotlightPreviewScale.ts';
import { useWebSpotlightInItemEditing } from '../../../context/WebSpotlightInItemEditingContext.tsx';

export const OldResolutionSettings = () => {
  const dispatch = useDispatch();

  const {
    previewIFrameResolution,
    previewIFrameResolutionType,
    rescalePreview,
    resizePreview,
    rotatePreview,
    selectPreviewResolutionType,
    setPreviewIFrameResolutionType,
  } = useWebSpotlightInItemEditing();

  const [formPreviewResolutionType, setFormPreviewResolutionType] =
    useState<WebSpotlightPreviewResolutionType | null>(null);

  const resolutionType = formPreviewResolutionType ?? previewIFrameResolutionType;

  const [selectedScaleId, scaleOptions] = useMemo(
    () => getWebSpotlightPreviewScaleOptions(previewIFrameResolution.scale),
    [previewIFrameResolution.scale],
  );

  const handleResolutionTypeChange = useCallback(
    (
      type: WebSpotlightPreviewResolutionType,
      origin: WebSpotlightPreviewResolutionChangeOrigin,
    ) => {
      setFormPreviewResolutionType(type);
      selectPreviewResolutionType(type);
      dispatch(trackUserEvent(TrackedEvent.WebSpotlightPreviewResolutionChanged, { origin }));
    },
    [selectPreviewResolutionType],
  );

  const handleScaleChange = useCallback(
    (scaleId: ItemId) => {
      const newScale = scaleId ? scaleIdToValue(scaleId) : fitToScreenResolution.scale;
      rescalePreview(newScale);
    },
    [rescalePreview],
  );

  const handleResolutionSubmit = useCallback(
    (width: number, height: number) => {
      resizePreview(width, height);
      setPreviewIFrameResolutionType(WebSpotlightPreviewResolutionType.Responsive);
      dispatch(
        trackUserEvent(TrackedEvent.WebSpotlightPreviewResolutionChanged, {
          origin: WebSpotlightPreviewResolutionChangeOrigin.Inputs,
        }),
      );
    },
    [resizePreview, setPreviewIFrameResolutionType],
  );

  return (
    <Row spacingY={Spacing.S}>
      <Column>
        <Inline spacingX={Spacing.S}>
          <WebSpotlightPreviewResolutionTypeSelect
            onSelect={(type) =>
              handleResolutionTypeChange(
                type,
                WebSpotlightPreviewResolutionChangeOrigin.ResolutionTypeSelector,
              )
            }
            resolutionTypes={webSpotlightPreviewResolutionTypeOptions}
            selectedResolutionType={resolutionType}
          />
          <RotatePreviewButton onClick={rotatePreview} />
        </Inline>
      </Column>
      <Column>
        <Row spacingX={Spacing.S} spacingY={Spacing.S}>
          <Column width="fit-content">
            <WebSpotlightPreviewResolutionInputs
              onChange={() =>
                setFormPreviewResolutionType(WebSpotlightPreviewResolutionType.Responsive)
              }
              onSubmit={handleResolutionSubmit}
              previewHeight={previewIFrameResolution.height}
              previewWidth={previewIFrameResolution.width}
            />
          </Column>
          <Column width="fit-content">
            <Inline spacingX={Spacing.S}>
              <WebSpotlightPreviewScaleSelect
                onSelect={handleScaleChange}
                scales={scaleOptions}
                selectedScale={selectedScaleId}
              />
              <ResetViewButton
                onClick={() =>
                  handleResolutionTypeChange(
                    WebSpotlightPreviewResolutionType.FitScreen,
                    WebSpotlightPreviewResolutionChangeOrigin.FitScreenButton,
                  )
                }
              />
            </Inline>
          </Column>
        </Row>
      </Column>
    </Row>
  );
};

type ButtonProps = Readonly<{
  onClick: () => void;
}>;

const RotatePreviewButton = ({ onClick }: ButtonProps) => (
  <IconButton
    aria-label="Rotate preview"
    buttonStyle="tertiary"
    iconName="DeviceRotate"
    onClick={onClick}
    size="medium"
    tooltipPlacement="top"
    tooltipText="Rotate preview"
  />
);

const ResetViewButton = ({ onClick }: ButtonProps) => (
  <IconButton
    aria-label="Reset view"
    buttonStyle="tertiary"
    iconName="ArrowsCorners"
    onClick={onClick}
    size="medium"
    tooltipPlacement="top"
    tooltipText="Reset resolution and scale to fit screen"
  />
);
