import { InputState, Textarea } from '@kontent-ai/component-library/Input';
import React, { useId } from 'react';
import {
  DataUiInput,
  getDataUiInputAttribute,
} from '../../../../../../_shared/utils/dataAttributes/DataUiAttributes.ts';
import { TypeElementConfigurationCategory } from './TypeElementConfigurationCategory.tsx';
import { TypeElementConfigurationSection } from './TypeElementConfigurationSection.tsx';

type Props = {
  readonly disabled?: boolean;
  readonly guidelines: string;
  readonly isGuidelinesValid: boolean;
  readonly onChange: (guidelinesText: string) => void;
};

const getInputState = (params: Pick<Props, 'disabled' | 'isGuidelinesValid'>): InputState => {
  if (params.disabled) {
    return InputState.Disabled;
  }

  return params.isGuidelinesValid ? InputState.Default : InputState.Alert;
};

export const TypeElementGuidelines: React.FC<Props> = ({
  disabled,
  guidelines,
  isGuidelinesValid,
  onChange,
}) => {
  const inputId = useId();

  return (
    <TypeElementConfigurationCategory>
      <TypeElementConfigurationSection
        title="Element guidelines"
        contentWidth="wide"
        labelFor={inputId}
      >
        <Textarea
          id={inputId}
          inputState={getInputState({
            disabled,
            isGuidelinesValid,
          })}
          onChange={(e) => onChange(e.target.value)}
          placeholder="Write optional guidelines for this element"
          value={guidelines}
          {...getDataUiInputAttribute(DataUiInput.TypeElementGuidelines)}
        />
      </TypeElementConfigurationSection>
    </TypeElementConfigurationCategory>
  );
};
