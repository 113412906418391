import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { isUrlPatternSetForEditedContentItemSelector } from '../../itemEditor/selectors/isUrlPatternSetForEditedContentItem.ts';
import { MoreActions as MoreActionsComponent } from '../components/preview/FloatingEditor/Header/MoreActions/MoreActions.tsx';

export const MoreActions = () => {
  const isUrlPatternSetForEditedContentItem = useSelector(
    isUrlPatternSetForEditedContentItemSelector,
  );

  return (
    <MoreActionsComponent
      isUrlPatternSetForEditedContentItem={isUrlPatternSetForEditedContentItem}
    />
  );
};
