import { makeCancellablePromise, swallowCancelledPromiseError } from '@kontent-ai/utils';
import React, { useEffect } from 'react';
import { trackUserEvent } from '../../../../_shared/actions/thunks/trackUserEvent.ts';
import { TrackedEvent } from '../../../../_shared/constants/trackedEvent.ts';
import { useDispatch } from '../../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { getCurrentUserId } from '../../../../_shared/selectors/getCurrentUser.ts';
import { getCurrentProjectId } from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import {
  loadContentTypes,
  loadUsers,
  loadWorkflows,
} from '../../../../data/actions/thunkDataActions.ts';
import { useIsDemoModeEnabled } from '../../contexts/MissionControlDemoContext.tsx';
import { ContentPace } from '../components/ContentPace.tsx';
import { useContentPaceContentType } from '../hooks/useContentPaceContentType.ts';
import { useContentPaceTimeInterval } from '../hooks/useContentPaceTimeInterval.ts';
import { useContentPaceWorkflowStage } from '../hooks/useContentPaceWorkflowStage.ts';
import { ContentPaceBodyDemoContainer } from './ContentPaceBodyDemoContainer.tsx';
import { ContentPaceBodyLiveContainer } from './ContentPaceBodyLiveContainer.tsx';
import { ContentPaceDetailDemoContainer } from './ContentPaceDetailDemoContainer.tsx';
import { ContentPaceDetailLiveContainer } from './ContentPaceDetailLiveContainer.tsx';

const ContentPaceContainer: React.FC = () => {
  const dispatch = useDispatch();
  const isDemoMode = useIsDemoModeEnabled();

  const projectId = useSelector(getCurrentProjectId);
  const userId = useSelector(getCurrentUserId);

  const { timeInterval, setTimeInterval } = useContentPaceTimeInterval(userId, projectId);
  const { contentTypeId, setContentTypeId } = useContentPaceContentType(userId, projectId);
  const { workflowStage, setWorkflowStage } = useContentPaceWorkflowStage(userId, projectId);

  useEffect(() => {
    const { cancel } = makeCancellablePromise(
      async () =>
        await Promise.all([
          dispatch(loadWorkflows()),
          dispatch(loadContentTypes()),
          dispatch(loadUsers()),
        ]),
    ).catch(swallowCancelledPromiseError);

    return cancel;
  }, []);

  useEffect(() => {
    dispatch(
      trackUserEvent(TrackedEvent.ContentPaceOpened, {
        contentTypeId,
        timeInterval,
        workflowStage,
      }),
    );
  }, [contentTypeId, timeInterval, workflowStage]);

  return (
    <ContentPace
      timeInterval={timeInterval}
      contentTypeId={contentTypeId}
      workflowStage={workflowStage}
      onContentTypeIdChange={setContentTypeId}
      onTimeIntervalChange={setTimeInterval}
      onWorkflowStageChange={setWorkflowStage}
      BodyComponent={isDemoMode ? ContentPaceBodyDemoContainer : ContentPaceBodyLiveContainer}
      DetailComponent={isDemoMode ? ContentPaceDetailDemoContainer : ContentPaceDetailLiveContainer}
    />
  );
};

ContentPaceContainer.displayName = 'ContentPaceContainer';
export { ContentPaceContainer as ContentPace };
