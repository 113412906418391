import { IProjectDetails } from '../../../data/models/projects/ProjectDetails.ts';
import { logoutAction } from '../../actions/thunks/logout.ts';
import { trackUserEvent } from '../../actions/thunks/trackUserEvent.ts';
import { UserMenu as UserMenuComponent } from '../../components/MainMenu/UserMenu.tsx';
import { TrackedEvent } from '../../constants/trackedEvent.ts';
import { useDispatch } from '../../hooks/useDispatch.ts';
import { useSelector } from '../../hooks/useSelector.ts';
import { useTrackAvatarTypeChanged } from '../../hooks/users/useTrackAvatarTypeChanged.ts';
import { getCurrentUser } from '../../selectors/getCurrentUser.ts';
import {
  areAllSubscriptionsCanceled,
  getAdministratedSubscriptions,
} from '../../utils/subscription/subscriptionUtils.ts';

type Props = {
  readonly usernameVisible?: boolean;
};

export const UserMenu = ({ usernameVisible }: Props) => {
  const showSubscriptionLink = useSelector((state) => {
    const { projects, subscriptions, user } = state.data;

    const projectSubscriptions = getAdministratedSubscriptions(
      projects.byId.map((project: IProjectDetails) => project.subscriptionId).toSet(),
      subscriptions.byId,
    );

    const allSubscriptionsCanceled = areAllSubscriptionsCanceled(projectSubscriptions);

    return (
      getAdministratedSubscriptions(subscriptions.administratedIds, subscriptions.byId).length >
        0 ||
      (allSubscriptionsCanceled && user.info.hadTrial)
    );
  });

  const currentUser = useSelector(getCurrentUser);

  const dispatch = useDispatch();
  const trackMenuOpened = () => dispatch(trackUserEvent(TrackedEvent.UserMenuOpened));
  const onSignOutClick = () => dispatch(logoutAction);

  useTrackAvatarTypeChanged();

  return (
    <UserMenuComponent
      currentUser={currentUser}
      showSubscriptionLink={showSubscriptionLink}
      trackMenuOpened={trackMenuOpened}
      onSignOutClick={onSignOutClick}
      usernameVisible={usernameVisible}
    />
  );
};
