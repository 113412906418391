import { Box } from '@kontent-ai/component-library/Box';
import { Spacing, Typography, colorTextHint } from '@kontent-ai/component-library/tokens';
import Immutable from 'immutable';
import React from 'react';
import { DragMoveHandler } from '../../../../../../../_shared/components/DragDrop/dragDrop.type.ts';
import { IContentType } from '../../../../../../../data/models/contentModelsApp/contentTypes/ContentType.ts';
import { ElementType } from '../../../../../../contentInventory/content/models/ContentItemElementType.ts';
import { LinkedItemsDefaultValueActionsPane } from '../../../../../contentTypes/components/LinkedItemsDefaultValueActionsPane.tsx';
import { ILinkedItemsTypeElementData } from '../../../../models/elements/LinkedItemsTypeElementData.ts';
import { LinkedItemsList } from './LinkedItemsList.tsx';

type Props = {
  readonly allowedContentTypes: Immutable.Set<IContentType>;
  readonly canAddExistingItems: boolean;
  readonly description: string;
  readonly isDisabled: boolean;
  readonly onMoveDefaultValue: DragMoveHandler;
  readonly onRemoveDefaultValue: (itemId: Uuid) => void;
  readonly onUpdateDefaultValue: (itemIds: ReadonlyArray<Uuid>) => void;
  readonly typeElementData: ILinkedItemsTypeElementData;
};

export const LinkedItemsDefaultValue: React.FC<Props> = ({
  allowedContentTypes,
  canAddExistingItems,
  description,
  isDisabled,
  onMoveDefaultValue,
  onRemoveDefaultValue,
  onUpdateDefaultValue,
  typeElementData,
}) => (
  <>
    <Box
      color={colorTextHint}
      marginBottom={Spacing.M}
      marginTop={Spacing.XS}
      typography={Typography.Caption}
    >
      {description}
    </Box>
    <Box marginBottom={Spacing.M}>
      <LinkedItemsList
        contentItemIds={typeElementData.defaultValue}
        elementId={typeElementData.elementId}
        elementType={ElementType.LinkedItems}
        isDisabled={isDisabled}
        onRelatedEntryDeleted={onRemoveDefaultValue}
        onRelatedEntryMoved={onMoveDefaultValue}
      />
    </Box>
    <LinkedItemsDefaultValueActionsPane
      allowedContentTypes={allowedContentTypes}
      alreadyLinkedItemIds={typeElementData.defaultValue}
      canAddExistingItems={canAddExistingItems}
      onAddExistingItems={(itemsIds) => onUpdateDefaultValue(itemsIds)}
      typeElementData={typeElementData}
    />
  </>
);
