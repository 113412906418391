import React from 'react';
import { Link } from 'react-router-dom';
import { QuickTip } from '../../../../_shared/components/infos/QuickTip.tsx';
import {
  PlanSelectionRoute,
  SubscriptionRouteParams,
} from '../../../../_shared/constants/routePaths.ts';
import { useSelector } from '../../../../_shared/hooks/useSelector.ts';
import { getSubscriptionIdFromCurrentProject } from '../../../../_shared/selectors/userProjectsInfoSelectors.ts';
import { buildPath } from '../../../../_shared/utils/routing/routeTransitionUtils.ts';
import { areLanguageRolesEnabledForCurrentProject } from '../../selectors/allowedFeaturesSelectors.ts';
import { hasProjectMultipleRolesAndLanguages } from '../selectors/hasProjectMultipleRolesAndLanguages.ts';

type Props = {
  readonly isRoleBuilderValid: boolean;
};

export const LanguageRolesQuickTip: React.FC<Props> = ({ isRoleBuilderValid }) => {
  const subscriptionId = useSelector(getSubscriptionIdFromCurrentProject);
  const areLanguageRolesEnabled = useSelector(areLanguageRolesEnabledForCurrentProject);
  const hasMultipleRolesAndLanguages = useSelector(hasProjectMultipleRolesAndLanguages);

  if (!hasMultipleRolesAndLanguages || areLanguageRolesEnabled || !isRoleBuilderValid) {
    return null;
  }

  const subscriptionDetailsLink = buildPath<SubscriptionRouteParams>(PlanSelectionRoute, {
    subscriptionId,
  });

  return (
    <QuickTip>
      <p>
        If you need to assign different roles for multiple languages,{' '}
        <Link to={subscriptionDetailsLink}>upgrade to a higher plan</Link>.
      </p>
    </QuickTip>
  );
};
